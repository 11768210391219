import React, { MouseEventHandler } from 'react';

import IconButton from '../IconButton/IconButton';

import styles from './SnackbarCloseButton.module.scss';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const SnackbarCloseButton: React.FC<Props> = ({ onClick }) => (
  <IconButton className={styles.root} iconName="Clear" size="xsmall" onClick={onClick} />
);

export default SnackbarCloseButton;
