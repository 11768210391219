import React, { FC } from 'react';
import moment from 'moment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { WarningOutlined } from '@material-ui/icons';
import styles from './DeedExpensesDeedDateFormatter.module.scss';

const DeedExpensesDeedDateFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row }) => {
  return (
    <div className={styles.DeedExpensesDeedDateFormatter}>
      {moment(Number(value)).format('MMM-YYYY')}
      {row.deedDateIsAfterMaximumCollectionDate && <WarningOutlined className={styles.Icon} />}
    </div>
  );
};

export default DeedExpensesDeedDateFormatter;
