import React, { FC } from 'react';
import { FormControl, InputLabel, InputLabelProps, Select, SelectProps } from '@material-ui/core';
import styles from './QuotationConfigSelect.module.scss';

interface Props {
  selectProps?: SelectProps;
  labelProps?: InputLabelProps;
  label?: string;
}

const QuotationConfigSelect: FC<Props> = ({ selectProps, label, labelProps, children }) => (
  <FormControl variant="outlined" fullWidth className={styles.Select} size="small">
    <Select {...selectProps}>{children}</Select>
  </FormControl>
);

export default QuotationConfigSelect;
