import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import resolvePropertiesChanged from 'helpers/resolvePropertiesChanged';
import { AgentModel } from 'App/Agents/Core/models/AgentModel';
import { ClientAddressSection } from '../interfaces/ClientAddressSection';
import { ClientCompanyModel } from '../models/ClientCompanyModel';
import { ClientInformationModel } from '../models/ClientInformationModel';
import { ClientModel } from '../models/ClientModel';
import useClient from './useClient';
import useClientService from './useClientService';

export interface UpdateClientModalContentProps {
  client: ClientModel;
  agents: AgentModel[];
  onChangeClient: (client: ClientModel) => void;
  openDetails: (client: ClientModel) => void;
}

const useUpdateClientModalContent = (props: UpdateClientModalContentProps) => {
  const { onChangeClient } = props;
  const { updateClient, isFetch: isFetchUpdate } = useClientService();
  const { towerId } = useParams<{ towerId: string }>();
  const { client: originalClient, isFetch, fetchClient } = useClient(props.client?.id);
  const [originalClientCompany, setOriginalClientCompany] = useState<ClientCompanyModel>();
  const [client, setClient] = useState<ClientModel>();
  const [clientCompany, setClientCompany] = useState<ClientCompanyModel>();
  const [clientCompanyAgent, setClientCompanyAgent] = useState<ClientCompanyModel>();
  const [clientInformation, setClientInformation] = useState<ClientInformationModel>();
  const [clientAddressSection, setClientAddressSection] = useState<ClientAddressSection>();
  const [validAgent, setValidAgent] = useState(false);
  const [validQuestionOne, setValidQuestionOne] = useState(false);
  const [validClient, setValidClient] = useState(false);
  const [infoType, setInfoType] = useState<string>('personalInformation');

  const resolveAdditionalInformation = (client: ClientModel) => {
    if (client.clientAdditionalInfo) {
      const dateOfBirth = moment(+(client.clientAdditionalInfo?.dateOfBirth || new Date().getTime())).toDate();
      const mobileNumber = client.mobileNumber;
      setClientInformation({ ...client.clientAdditionalInfo, dateOfBirth, mobileNumber });
    }
  };

  const resolveAddressSection = (client: ClientModel) => {
    const addressSection: ClientAddressSection = {};
    const { clientAddress, clientAdditionalInfo } = client;

    if (typeof clientAddress !== 'string' && clientAddress?.addressType) addressSection.address = clientAddress;

    if (typeof clientAdditionalInfo?.clientAddress !== 'string' && clientAdditionalInfo?.clientAddress?.addressType)
      addressSection.additionalAddress = clientAdditionalInfo?.clientAddress;
    setClientAddressSection(addressSection);
  };

  const resolveClientCompany = (client: ClientModel) => {
    if (client.clientCompany) {
      setOriginalClientCompany(client.clientCompany);
      setClientCompany({ howDidFindAboutProjectId: client.clientCompany?.howDidFindAboutProjectId });
      setClientCompanyAgent({ assignedAgentId: client.clientCompany?.assignedAgentId });
    }
  };

  useEffect(() => {
    if (originalClient && originalClient.id) {
      setClient(originalClient);
      resolveAdditionalInformation(originalClient);
      resolveAddressSection(originalClient);
      resolveClientCompany(originalClient);
      setValidAgent(false);
      setValidClient(false);
      setValidQuestionOne(false);
    }
  }, [originalClient]);

  const updateInformation = useCallback(async () => {
    const payload: { client?: Partial<ClientModel>; clientCompany?: Partial<ClientCompanyModel> } = {};

    if (originalClient?.id && client) {
      const { haveChanges, updatedData } = resolvePropertiesChanged(originalClient, client);
      if (haveChanges) payload.client = updatedData;
    }
    const payloadClientCompany: Partial<ClientCompanyModel> = {
      ...clientCompany,
      ...clientCompanyAgent,
    };
    if (originalClientCompany && payloadClientCompany) {
      const { haveChanges: haveChangesInClientCompany, updatedData: updatedDataClientCompany } =
        resolvePropertiesChanged(originalClientCompany, payloadClientCompany);
      if (haveChangesInClientCompany) payload.clientCompany = updatedDataClientCompany;
    }

    if (Object.values(payload).length && originalClient?.id) {
      const result = await updateClient(originalClient.id, payload);
      if (result && payload.clientCompany && Object.values(payload.clientCompany).length)
        setOriginalClientCompany(clientCompany);
      await fetchClient();
    }
  }, [client, clientCompany, clientCompanyAgent]);

  useEffect(() => {
    if (!clientCompanyAgent?.assignedAgentId) setValidAgent(false);
    else if (!clientCompany?.howDidFindAboutProjectId) setValidQuestionOne(false);
    else if (validAgent && validQuestionOne && validClient) updateInformation();
  }, [validAgent, validQuestionOne, validClient, client, clientCompany, clientCompanyAgent]);

  const handleClientChange = async (newClient?: ClientModel) => {
    if (newClient && originalClient?.id && client) {
      setClient({ ...client, ...newClient });
      setValidClient(true);
    }
  };

  const handleClientCompanyChange =
    (prop: keyof Pick<ClientCompanyModel, 'howDidFindAboutProjectId' | 'assignedAgentId'>) =>
    async (newClientCompany?: ClientCompanyModel) => {
      if (newClientCompany && originalClient?.id && clientCompany) {
        if (prop === 'assignedAgentId') setClientCompanyAgent({ assignedAgentId: newClientCompany.assignedAgentId });
        else setClientCompany({ howDidFindAboutProjectId: newClientCompany.howDidFindAboutProjectId });
      }
      if (newClientCompany?.assignedAgentId && !validAgent) setValidAgent(true);
      if (newClientCompany?.howDidFindAboutProjectId && !validQuestionOne) setValidQuestionOne(true);
    };

  const handleClientInformationChange = async (newClientInformation?: ClientInformationModel) => {
    if (newClientInformation && originalClient?.id && clientInformation) {
      const { haveChanges, updatedData } = resolvePropertiesChanged(clientInformation, newClientInformation);
      if (haveChanges) {
        const result = await updateClient(originalClient.id, {
          clientInformation: updatedData,
        });
        if (result)
          setClientInformation({
            ...clientInformation,
            ...newClientInformation,
          });
      }
    }
  };

  const handleClientAddressSectionChange = async (newClientAddressSection?: ClientAddressSection) => {
    if (newClientAddressSection && originalClient?.id && clientAddressSection) {
      const { haveChanges, updatedData } = resolvePropertiesChanged(
        clientAddressSection || {
          address: {},
          additionalAddress: {},
        },
        newClientAddressSection,
      );
      if (haveChanges) {
        const result = await updateClient(originalClient.id, {
          clientAddressSection: updatedData,
        });
        if (result)
          setClientAddressSection({
            ...clientAddressSection,
            ...newClientAddressSection,
          });
      }
    }
  };

  return {
    towerId,
    originalClient,
    client,
    clientCompany,
    clientCompanyAgent,
    clientInformation,
    clientAddressSection,
    isFetch: isFetch || isFetchUpdate,
    infoType,
    setInfoType,
    handleClientChange,
    handleClientCompanyChange,
    handleClientInformationChange,
    handleClientAddressSectionChange,
  };
};

export default useUpdateClientModalContent;
