import Services from 'services/services';
import { GetViewDataResponse, UpdateMaximumRotationResponse } from '../core/types/SalesSpeedHelper.types';
import SalesSpeedHelperServicesDefinitions from './SalesSpeedHelperServicesDefinitions';

class SalesSpeedHelperServices extends Services {
  getViewData(towerId: string): Promise<GetViewDataResponse> {
    return this.get(SalesSpeedHelperServicesDefinitions.getTowerIdUrl(towerId));
  }

  updateMaximumRotationMonths(towerId: string, maximumRotationMonths: number): Promise<UpdateMaximumRotationResponse> {
    return this.put(SalesSpeedHelperServicesDefinitions.getStrategiesUrl(towerId), { maximumRotationMonths });
  }
}

export default SalesSpeedHelperServices;
