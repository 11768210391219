import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Receipt from '@material-ui/icons/ReceiptRounded';
import { makeStyles, styled } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { Typography, Box, Grid } from '@material-ui/core';
import { validateRealPriceAndCurrentOne } from './Dialog/helpers/customHooks/useAssignmentQuotation';
import QuotationViewerDialog from './Dialog';
import { colorForState } from '../../../../../App/BuyerCostumers/UI/views/customViews/StyledBuyerCustomer';
import AssignedPaymentEditionMainView from '../../../../../App/AssignedPaymentEdition/UI/AssignedPaymentEditionMainView';

const CustomAvatar = ({ state, children }) => {
  const useStyles = makeStyles(() => ({
    gray: {
      backgroundColor: '#f4f4ff',
      color: colorForState(state),
    },
  }));

  return <Avatar className={useStyles().gray}>{children}</Avatar>;
};

const QuotationViewer = ({
  quotation,
  isAssignment,
  currentQuotation,
  closeDialog,
  fetchUserList,
}) => {
  const [open, setOpen] = useState(currentQuotation);

  const openDialogHandler = () => {
    setOpen(true);
  };

  const closeDialogHandler = (forceClose = false) => {
    setOpen(false);
    if (closeDialog && (currentQuotation || forceClose)) closeDialog();
  };

  let currentQuotationState = '';
  const { isError } = validateRealPriceAndCurrentOne(quotation);
  if (quotation.isAssigned) {
    currentQuotationState = 'completed';
  } else if (quotation.isPaymentEdition) {
    currentQuotationState = 'editing';
  } else if (!isError) {
    currentQuotationState = 'missing';
  }

  return (
    <>
      {!currentQuotation && (
        <Box ml={10} mr={10}>
          <Grid
            container
            autoFocus
            button
            onClick={openDialogHandler}
            xs={13}
            alignItems="center"
            justify="center"
          >
            <Grid item xs={1}>
              <ListItemAvatar>
                <CustomAvatar state={currentQuotationState}>
                  <Receipt />
                </CustomAvatar>
              </ListItemAvatar>
            </Grid>
            <Grid item xs={1}>
              <Typography>{quotation.index}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{quotation.property.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {moment(quotation.createdAt).format('DD MMMM YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <NumberFormat
                  thousandSeparator
                  prefix="$"
                  displayType="text"
                  value={Math.round(
                    quotation.propertyWithAreas.priceWithAdditionalAreas,
                  )}
                />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <NumberFormat
                  thousandSeparator
                  prefix="$"
                  displayType="text"
                  value={Math.round(quotation.discount)}
                />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <NumberFormat
                  thousandSeparator
                  prefix="$"
                  displayType="text"
                  value={Math.round(quotation.discountPrice)}
                />
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>
                <NumberFormat
                  thousandSeparator
                  prefix="$"
                  displayType="text"
                  value={Math.round(
                    quotation.priceWithAdditionalAreas -
                      quotation.discount -
                      quotation.discountPrice,
                  )}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {quotation.isPaymentEdition ? (
        <AssignedPaymentEditionMainView
          id={quotation.id}
          open={open}
          closeHandler={closeDialogHandler}
        />
      ) : (
        <QuotationViewerDialog
          id={quotation.id}
          open={open}
          closeHandler={closeDialogHandler}
          isAssignment={isAssignment && !currentQuotation}
          fetchUserList={fetchUserList}
        />
      )}
    </>
  );
};

QuotationViewer.defaultProps = {
  isAssignment: false,
};

QuotationViewer.propTypes = {
  quotation: PropTypes.shape({
    id: PropTypes.string,
    isAssigned: PropTypes.bool,
    property: PropTypes.shape({
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    priceWithAdditionalAreas: PropTypes.number,
    discount: PropTypes.number,
    discountPrice: PropTypes.number,
    index: PropTypes.string,
    isPaymentEdition: PropTypes.bool,
  }).isRequired,
  isAssignment: PropTypes.bool,
  currentQuotation: PropTypes.string,
  closeDialog: PropTypes.func,
  fetchUserList: PropTypes.func,
};

CustomAvatar.propTypes = {
  state: PropTypes.string,
  children: PropTypes.node,
};

export default QuotationViewer;
