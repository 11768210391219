import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';
import styles from './SliderSelector.module.scss';
import useSliderSelectorUpdater from '../../Core/CustomHooks/useSliderSelectorUpdater';

export default function SliderSelector({
  name,
  unitSymbol,
  maxValue,
  minValue,
  isSuffix = false,
}) {
  const { sliderState, onChangeSliderState } = useSliderSelectorUpdater(
    minValue,
    maxValue,
    name,
  );

  const valueText = (unit, value, suffix) => {
    return suffix ? `${value}${unit}` : `${unit}${value}`;
  };

  return (
    <div className={styles.container}>
      <Slider
        className={styles.slider}
        value={sliderState}
        onChange={onChangeSliderState}
        getAriaValueText={(value) => valueText(unitSymbol, value, isSuffix)}
        min={minValue || 0}
        step={1}
        valueLabelDisplay="auto"
        max={maxValue}
      />
    </div>
  );
}

SliderSelector.propTypes = {
  name: PropTypes.string,
  unitSymbol: PropTypes.string,
  maxValue: PropTypes.number,
  isSuffix: PropTypes.bool,
  minValue: PropTypes.number,
};
