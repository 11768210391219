import React, { useState, Fragment } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import sideMenuStyle from '../SideMenu.module.scss';
import menuStyle from '../menu.module.scss';
import INTEGRATIONS from '../../../config/integrations';

const Section = (props) => {
  const ENV = process.env.NODE_ENV;
  const style = INTEGRATIONS[ENV].uniqueSideBar ? menuStyle : sideMenuStyle;
  const { items, title, validation, display, icon, scrollToBottom, isBadge } =
    props;
  const [expanded, setExpanded] = useState(validation);

  const onExpanded = () => {
    setExpanded(!expanded);
    if (expanded && title === 'Contratos') scrollToBottom();
  };

  return (
    <Fragment>
      {display && (
        <Accordion
          classes={{ root: style.expansionPanel }}
          onClick={onExpanded}
          expanded={expanded}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={style.iconColor} />}
            classes={{ root: style.expansionPanelSummary }}
          >
            <div
              className={`${style.collapseHeader} ${
                expanded ? style.expansionPanelActive : style.expansionPanel
              }`}
            >
              {isBadge && <span className={style.principalBadge} />}
              <div className={style.titleContainer}>
                <Icon className={`${icon} ${style.iconLabel}`} />
                {title}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails classes={{ root: style.expansionPanelDetails }}>
            <div className={style.linkContainer}>{items}</div>
          </AccordionDetails>
        </Accordion>
      )}
    </Fragment>
  );
};

export default Section;
