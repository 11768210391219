import { FC } from 'react';
import { LinearProgress, LinearProgressProps } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../assets/styles/settings/colors.module.scss';

const BorderLinearProgress: FC<LinearProgressProps> = styled(LinearProgress)<LinearProgressProps>`
  && {
    border-radius: 5px;
    background-color: ${colors.gray400};
    height: 10px;
    & > .MuiLinearProgress-barColorPrimary {
      background-color: ${(props) => {
        return Number(props.value) === 100 ? colors.stateSuccess600 : colors.stateError600;
      }};
    }
  }
`;

export default BorderLinearProgress;
