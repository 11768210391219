import React, { FC } from 'react';
import { Box, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useAssignedAgent from 'App/Clients/Core/customHooks/useAssignedAgent';
import { ClientCompanyProps } from 'App/Clients/Core/interfaces/ClientCompanyProps';
import Typography from 'shared/components/Typography/Typography';

const AssignedAgent: FC<ClientCompanyProps> = (props) => {
  const { agents } = props;
  const { data, errors, handleSubmit, handleChangeValue } = useAssignedAgent(props);
  return (
    <>
      <form onSubmit={handleSubmit} id={props.formId} />
      <FormControl fullWidth error={!!errors.agent}>
        <Autocomplete
          options={agents}
          value={data.agent}
          onChange={(_, value) => handleChangeValue('agent', value)}
          renderOption={(props) => (
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">{props.email}</Typography>
              <Typography variant="caption">{props.name}</Typography>
            </Box>
          )}
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Asesor responsable *"
              placeholder="Buscar agente"
              variant="outlined"
              error={!!errors.agent}
              fullWidth
            />
          )}
        />
        <FormHelperText>{errors?.agent}</FormHelperText>
      </FormControl>
    </>
  );
};

export default AssignedAgent;
