import { useState, useEffect } from 'react';

function useRealPaymentsErrorHandling(lastPaymentsResponse) {
  const [isDialogOpen, setOpen] = useState(false);
  const [chargeNumberList, setFoundList] = useState({ error: [], success: [] });

  const filterNotFoundList = () => {
    return lastPaymentsResponse?.payments.reduce(
      (accumulated, payment) => {
        if (payment.error) {
          return {
            ...accumulated,
            error: [...accumulated.error, payment.chargeNumber],
          };
        }
        return {
          ...accumulated,
          success: [...accumulated.success, payment.chargeNumber],
        };
      },
      { error: [], success: [] },
    );
  };

  useEffect(() => {
    if (lastPaymentsResponse?.warningMessage) {
      setOpen(true);
      setFoundList(filterNotFoundList());
    }
  }, [lastPaymentsResponse]);

  return {
    isDialogOpen,
    chargeNumberList,
    closeHandler: () => setOpen(false),
  };
}

export default useRealPaymentsErrorHandling;
