import React, { FC } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { CheckCircleOutline, CancelOutlined } from '@material-ui/icons';
import styles from './ClientListAssociatedFormatter.module.scss';

const ClientListAssociatedFormatter: FC<
  DataTypeProvider.ValueFormatterProps
> = ({ row }) => {
  return (
    <div className={styles.container}>
      {!!row?.properties?.length ? (
        <CheckCircleOutline className={styles.success} />
      ) : (
        <CancelOutlined className={styles.warning} />
      )}
    </div>
  );
};
export default ClientListAssociatedFormatter;
