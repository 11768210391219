import FeatureFieldService from '../services/FeatureFieldServices';
import { FieldCell } from './interfaces/featureField.types';
import { UpsertData, UpsertResponse } from './interfaces/FeatureFieldServices.types';

class FieldCellCreator {
  private readonly repository = new FeatureFieldService();

  private readonly getUpsertData = ({ featureFieldId, propertyId, selectedOptions }: FieldCell): UpsertData => ({
    featureFieldId,
    propertyId,
    optionsIds: selectedOptions,
  });

  upsert(changedCell: FieldCell): Promise<UpsertResponse> {
    return this.repository.upsertCell(this.getUpsertData(changedCell));
  }
}

export default FieldCellCreator;
