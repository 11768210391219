import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import styles from './QuotationConfigCollapseContentDivider.module.scss';
import Typography from 'shared/components/Typography/Typography';

const QuotationConfigCollapseContentDivider: FC<{ content: string }> = ({ content }) => {
  return (
    <div className={styles.Divider}>
      <Box display="flex" justifyContent="center" padding="40px" paddingY="10px">
        <Grid container item xs={10}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{content}</Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default QuotationConfigCollapseContentDivider;
