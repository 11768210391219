import React, { FC } from 'react';
import Styles from './MonthlyInterestRates.module.scss';
import Typography from 'shared/components/Typography/Typography';
import Numbers from '../../../../../helpers/numbers';
import { WalletPaymentFees } from '../../../core/DTO/WalletMonthlyReportDTO';
import useUpdateWalletSetup from '../../../../GeneralSetup/Core/customHooks/useUpdateWalletSetup';

const MonthlyInterestRates: FC<Partial<WalletPaymentFees>> = (props) => {
  const { monthly: monthlyFinancialFee } = useUpdateWalletSetup('financialFee', props);
  const { monthly: monthlyRecognitionFee } = useUpdateWalletSetup('recognitionFee', props);

  return (
    <div className={Styles.Container}>
      <Typography variant="subtitle1" color="gray900">
        Tasa de financiación vigente
      </Typography>
      <div className={Styles.FeesContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.MonthlyFeeTitle}>
          Efectivo mensual
        </Typography>
        <Typography variant="body1" color="gray700">
          {Numbers.toFixed(monthlyFinancialFee, 2, true)}
        </Typography>
      </div>
      <Typography variant="subtitle1" color="gray900">
        Tasa de financiación vigente
      </Typography>
      <div className={Styles.FeesContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.MonthlyFeeTitle}>
          Efectivo mensual
        </Typography>
        <Typography variant="body1" color="gray700">
          {Numbers.toFixed(monthlyRecognitionFee, 2, true)}
        </Typography>
      </div>
    </div>
  );
};

export default MonthlyInterestRates;
