import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import styled from 'styled-components';

import table from '../../assets/styles/components/table.module.scss';
import colors from '../../assets/styles/settings/colors.module.scss';
import fonts from '../../assets/styles/settings/fonts.module.scss';

const TableSummaryRowCell: React.FC = styled(TableHeaderRow.Cell)<TableHeaderRow.CellProps>`
  /* Box-model */
  padding: 11px 11px 11px ${table.cellWidhtPadding};

  /* Typography */
  color: ${colors.gray800};
  font-family: ${fonts.body};
  font-size: ${fonts['body1-font-size']};
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;

  /* Visual */
  border-bottom: 2px solid rgba(105, 133, 161, 0.24);

  .MuiTableSortLabel-root:focus,
  .MuiTableSortLabel-root.MuiTableSortLabel-active,
  .MuiTableSortLabel-root.MuiTableSortLabel-active * {
    /* Visual */
    outline: none;
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    /* Typograpy */
    color: ${colors.gray900};
  }

  .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
    /* Visual */
    opacity: 1;
  }
  > div {
    display: initial;
  }
`;

export default TableSummaryRowCell;
