import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { DashboardRoutes } from 'routes/local/routes';
import { Button } from 'shared/components/Button/Button';
import Typography from 'shared/components/Typography/Typography';
import useGoTo from 'shared/customHooks/useGoTo';
import styles from './AreasNonIdealState.module.scss';

interface IAreasNonIdealStateProps {
  towerId: string;
}

const AreasNonIdealState: FC<IAreasNonIdealStateProps> = ({ towerId }) => {
  const goto = useGoTo(`${DashboardRoutes.base}${DashboardRoutes.building.value}${towerId}`);
  return (
    <div className={styles.MainContainer}>
      <Box maxWidth="450px">
        <Typography variant="body1">
          Aún no tienes un esquema definido al cuál asignarle áreas, debes crear uno primero para continuar.
        </Typography>
      </Box>
      <Box paddingTop="26px">
        <Button color="primary" variant="contained" endIcon={<ArrowForward />} onClick={goto}>
          <span className="button-label">Ir a esquemas</span>
        </Button>
      </Box>
    </div>
  );
};

export default AreasNonIdealState;
