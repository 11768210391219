import { API_PATH } from '../../../config/config';

const ProjectSchedulesServicesDefinition = {
  getProjectSchedules: (projectId) => {
    return `${API_PATH}schedules/project-schedules/${projectId}`;
  },

  putConstructionStartDate: (towerId) => {
    return `${API_PATH}schedules/construction-start-date/${towerId}`;
  },

  putSalesStartDate: (towerId) => {
    return `${API_PATH}schedules/sales-start-dates/${towerId}`;
  },

  putEndOfSalesDate: (towerId) => {
    return `${API_PATH}schedules/end-of-sales-date/${towerId}`;
  },

  putMaximumCollectionDate: (towerId) => {
    return `${API_PATH}schedules/maximum-collection-date/${towerId}`;
  },

  putAverageDeliveryDate: (towerId) => {
    return `${API_PATH}schedules/average-delivery-date/${towerId}`;
  },

  putBalancePointDate: (towerId) => {
    return `${API_PATH}schedules/balance-point-date/${towerId}`;
  },
};

export default ProjectSchedulesServicesDefinition;
