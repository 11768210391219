import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CollapseContainer from './CollapseContainer';
import SelectionSquare from './SelectorSquare';
import SelectsSquare from './SelectsSquare';
import SliderSelector from './SliderSelector';
import OrientationSchema from '../../Core/OrientationSchema';
import useFilter from '../../Core/CustomHooks/useFilter';
import useSelects from '../../Core/CustomHooks/useSelects';
import useSlider from '../../Core/CustomHooks/useSlider';
import SchemasCalcs from '../../Core/SchemasCalcs';
import InputBudget from './InputBudget';
import { disableClear } from '../../Core/actions';
import styles from './Filters.module.scss';
import useReducePropertyTypes from '../../../PropertyCharacteristic/Core/customHooks/useReducePropertyTypes';

export default function Filters({
  properties,
  makeArrayOfProperties,
  userType,
  propertyTypes,
}) {
  useFilter(makeArrayOfProperties, properties);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(disableClear());
  }, []);

  const { BedRooms, BathRooms } = SchemasCalcs(properties);
  const { availability, groups, typeIds } = useSelects(properties);
  const { maxRangeInM2, minRangeInM2, maxRangeInBudget } = useSlider(
    properties,
  );
  const { propertyTypesOptions } = useReducePropertyTypes({
    propertyTypes,
    typeIds,
  });


  return (
    <div className={styles.FilterContainer}>
      <CollapseContainer title="M2" name="m2">
        <SliderSelector
          name="m2"
          unitSymbol="m2"
          maxValue={maxRangeInM2}
          minValue={minRangeInM2}
          isSuffix
        />
      </CollapseContainer>
      <CollapseContainer title="Presupuesto" name="budget">
        <InputBudget defaultValue="" />
      </CollapseContainer>
      <CollapseContainer title="Habitaciones" name="bedrooms">
        <SelectionSquare name="bedrooms" schema={BedRooms} />
      </CollapseContainer>
      <CollapseContainer title="Baños" name="bathrooms">
        <SelectionSquare name="bathrooms" schema={BathRooms} />
      </CollapseContainer>
      <CollapseContainer title="Orientación" name="orientation">
        <SelectionSquare name="orientation" schema={OrientationSchema} />
      </CollapseContainer>
      <CollapseContainer title="Disponibilidad" name="availability">
        <SelectsSquare name="availability" options={availability} />
      </CollapseContainer>
      <CollapseContainer title="Tipos" name="propertyTypes">
        <SelectsSquare name="propertyTypes" options={propertyTypesOptions} />
      </CollapseContainer>
      {userType === 'super' && (
        <CollapseContainer title="Grupos" name="group">
          <SelectsSquare name="group" options={groups} />
        </CollapseContainer>
      )}
    </div>
  );
}

Filters.propTypes = {
  properties: PropTypes.object,
  makeArrayOfProperties: PropTypes.func,
  userType: PropTypes.string,
  propertyTypes: PropTypes.array,
};
