import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { activeFilter } from '../../Core/actions';
import styles from './Collapse.module.scss';

export default function CollapseContainer({ title, name, children }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleActiveFilter = () => {
    dispatch(activeFilter(`${name}Active`));
    setOpen(!open);
  };

  return (
    <List classes={{ root: styles.container }}>
      <ListItem
        button
        onClick={handleActiveFilter}
        classes={{ root: open ? styles.button : styles.outlineButton }}
      >
        <ListItemText primary={title} classes={{ primary: styles.titleText }} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </List>
  );
}

CollapseContainer.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.any,
};
