import React, { FC, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { InsertInvitationOutlined } from '@material-ui/icons';
import QuotationConfigFormItem from '../QuotationConfigFormItem';
import Typography from 'shared/components/Typography/Typography';
import TextFieldDisabled from '../../components/TextFieldDisabled/TextFieldDisabled';
import PlusInput from 'shared/components/PlusInput/PlusInput';

import styles from '../QuotationConfigForm.module.scss';
import { QuotationConfigFormatStringDate } from 'App/QuotationConfig/core/QuotationConfigUtils';
import QuotationQuestionTooltip from '../../components/QuotationQuestionTooltip/QuotationQuestionTooltip';
import useQuotationConfigFinalFeeLimitDate from 'App/QuotationConfig/core/customHooks/useQuotationConfigFinalFeeLimitDate';
import QuotationConfigAlert from '../../components/QuotationConfigAlert/QuotationConfigAlert';

interface Props {
  averageDeliveryDate: string;
  maximumCollectionDate: string;
}

const QuotationConfigFinalFeeLimitDate: FC<Props> = (props) => {
  const { averageDeliveryDate, maximumCollectionDate } = props;
  const { diffValue, isLoading, isValidValue, handleChange, isAllowed } = useQuotationConfigFinalFeeLimitDate(props);
  return (
    <Box display="flex" justifyContent="center" padding="40px">
      <Grid container item xs={10}>
        <QuotationConfigFormItem item xs={6} className={styles.ItemWithIcon}>
          <InsertInvitationOutlined className={styles.ItemIcon} />
          <Typography fontWeight="500">Cuota Inicial - Fecha Límite</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography fontWeight="500">{QuotationConfigFormatStringDate(maximumCollectionDate)}</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1">Desplazar</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <PlusInput
            onChange={handleChange}
            isDisabled={isLoading}
            value={diffValue}
            numberFormatProps={{
              isAllowed,
              decimalScale: 0,
            }}
          />
          <QuotationQuestionTooltip title="Desplazamiento en meses respecto a la fecha Cuota inicial - Fecha límite" />
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1">Fecha Cuota Final</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <TextFieldDisabled disabled value={QuotationConfigFormatStringDate(averageDeliveryDate)} />
        </QuotationConfigFormItem>
        {!isValidValue && (
          <QuotationConfigFormItem item xs={12}>
            <QuotationConfigAlert
              state="warning"
              text="La fecha de Cuota Final no puede ser menor a la fecha Cuota Inicial - Fecha Límite."
            />
          </QuotationConfigFormItem>
        )}
      </Grid>
    </Box>
  );
};

export default QuotationConfigFinalFeeLimitDate;
