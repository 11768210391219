import { Dispatch, SetStateAction, useState, MouseEvent } from 'react';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Services from '../../../../services/ContractsEvalpro/ContractsEvalproServices';
import { EvalproFlowDto } from '../DTO';
import constants from './constants';

const services = new Services();

const useContractsEvalProFlowData = (towerId: string) => {
  return useQuery<EvalproFlowDto[]>(
    constants.CONTRACTS_EVALPRO_FLOW,
    async () => {
      const { data } = await services.getEvalproFlow(towerId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
};

type HookType = () => {
  openContractsFlow: boolean;
  contractsFlowIsLoading: boolean;
  downloadFC: () => void;
  handleChange: (event: MouseEvent<HTMLElement>, newEvalProView: string) => void;
  evalProView: string;
  totalDistributed: number;
  setTotalDistributed: Dispatch<SetStateAction<number>>;
};

const useContractsEvalPro: HookType = () => {
  const { towerId }: { towerId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading: contractsFlowIsLoading, refetch: getContractsFlowData } = useContractsEvalProFlowData(towerId);
  const [openContractsFlow, setOpenContractsFlow] = useState<boolean>(false);

  const downloadFC = async () => {
    try {
      const name = 'Flujo_de_caja_contratos.xlsx';
      const response = await services.downloadEvalproFlow(towerId);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueSnackbar(error.message);
      } else {
        enqueueSnackbar('Error interno servidor');
      }
    }
  };

  const [evalProView, setEvalProView] = useState('detailed');
  const [totalDistributed, setTotalDistributed] = useState<number>(0);

  const handleChange = (event: MouseEvent<HTMLElement>, newEvalProView: string) => {
    if (newEvalProView !== null) {
      setEvalProView(newEvalProView);
      if (newEvalProView === 'detailed') {
        setOpenContractsFlow(false);
      } else {
        getContractsFlowData();
        setOpenContractsFlow(true);
      }
    }
  };

  return {
    contractsFlowIsLoading,
    handleChange,
    openContractsFlow,
    downloadFC,
    evalProView,
    totalDistributed,
    setTotalDistributed,
  };
};

export default useContractsEvalPro;
