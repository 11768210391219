import { MouseEvent, useState } from 'react';

const useContractPaymentListGridMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    open,
    anchorEl,
    handleClick,
    handleClose,
  };
};

export default useContractPaymentListGridMenu;
