import { ChangeEvent, MutableRefObject, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import useUploadSoldExternalResources from './useUploadSoldExternalResources';
import useAlert from '../../../../shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from '../../../../shared/components/Alert/Core/interfaces/ModalProps';
import { GET_QUERY_ER_REPORT } from '../ReactQuery/SoldReportForERReactQuerty';
import { useSnackbar } from 'notistack';

function useSoldReportForExternalResourceUpload() {
  const accept = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File>();
  const { mutate, isError, isSuccess } = useUploadSoldExternalResources();
  const { enqueueSnackbar } = useSnackbar();

  const { showAlert: showAlertError } = useAlert({
    isOpen: false,
    title: 'Error de archivo',
    variant: AlertVariants.AcceptOnly,
  });

  const handleRequestSuccess = async () => {
    await queryClient.invalidateQueries(GET_QUERY_ER_REPORT);
    if (isError) {
      enqueueSnackbar(`Error`, { variant: 'error' });
    } else {
      enqueueSnackbar('Listo!', { variant: 'success' });
    }
  };

  const acceptAction = () => {
    if (file?.name) {
      const formData = new FormData();
      formData.append('file', file);
      mutate(formData, {
        onError: handleRequestError,
        onSuccess: handleRequestSuccess,
      });
    }
  };
  const handleClick = () => inputRef.current.click();
  const cancelAction = () => {
    setFile(undefined);
  };

  const { showAlert } = useAlert({
    isOpen: false,
    title: 'Sobreescribir Áreas',
    acceptAction,
    cancelAction,
  });

  const handleRequestError = (err: unknown) => {
    const error = err as Record<string, string | undefined>;
    const message = `${error.message ?? 'Error al cargar el archivo no se pudo procesar'}`;
    showAlertError(message);
    cancelAction();
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files?.length) return;
    const file = e.currentTarget.files.item(0);
    if (!file) return;
    if (!accept.includes(file.type)) return;

    e.currentTarget.files = new DataTransfer().files;
    setFile(file);
    showAlert(
      'Al subir la plantilla se sobreescribirán las áreas existentes por las subidas en la plantilla. ¿Desea continuar?',
    );
  };

  return {
    inputRef,
    accept: accept.join(', '),
    handleClick,
    handleChange,
  };
}

export default useSoldReportForExternalResourceUpload;
