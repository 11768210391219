import React, { ChangeEventHandler, FC, useRef } from 'react';
import { InputAdornment, InputBase, InputBaseProps, Paper, SvgIcon } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import styles from './inputSearch.module.scss';

const InputSearch: FC<{
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}> = ({ onChange }) => {
  const ref = useRef<InputBaseProps>();

  return (
    <Paper component="form" className={styles.SearchFieldRoot}>
      <InputAdornment position="start">
        <SvgIcon color="action" fontSize="small">
          <SearchIcon />
        </SvgIcon>
      </InputAdornment>
      <InputBase
        className={styles.SearchFieldRoot_input}
        placeholder="Buscar"
        inputProps={{ 'aria-label': 'Buscar...' }}
        onChange={onChange}
        ref={ref}
        fullWidth
        autoFocus
      />
    </Paper>
  );
};

export default InputSearch;
