import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LinearProgressWithLabel from './util/LinearProgressWithLabel';
import ClientCell from '../ClientCell';
import styles from '../styles.module.scss';

export const Rows = ({ property, maxCols, clients }) => {
  const colorStatus = (status) => {
    switch (status) {
      case 'OPTIONAL':
        return styles.YellowHelper;
      case 'SOLD':
        return styles.BlueHelper;
      default:
        return styles.YellowHelper;
    }
  };

  const clientProperties = (currentProperties, currentProperty) => {
    if (currentProperties.length >= 1) {
      return currentProperties.find(
        (currentProp) => currentProp.propertyId === currentProperty.id,
      );
    }
    return [];
  };

  const percentageOfProperty = (currentProperty, clientsArray) => {
    const clientsSearchClean = clientsArray.flatMap((currentClient) => {
      const findInClientProperties = clientProperties(
        currentClient.properties,
        currentProperty,
      );
      return findInClientProperties || [];
    });
    if (clientsSearchClean[0] && clientsSearchClean.length >= 1) {
      const summaryOfPercentage = clientsSearchClean.reduce((prev, current) => {
        const prevPercentage =
          typeof prev === 'object' ? Number(prev.percentage) : prev;
        const currentPercentage = current ? Number(current.percentage) : 0;
        return prevPercentage + currentPercentage;
      });
      return typeof summaryOfPercentage === 'object'
        ? summaryOfPercentage.percentage
        : summaryOfPercentage;
    }
    return 0;
  };

  const clientsAtProperty = (currentProperty, clientsArray) => {
    const clientsSearch = clientsArray.flatMap((currentClient) => {
      const findInClientProperties = clientProperties(
        currentClient.properties,
        currentProperty,
      );
      if (findInClientProperties) {
        return currentClient;
      }
      return [];
    });
    return clientsSearch;
  };

  return (
    <TableRow>
      <TableCell>
        <div className={styles.container}>
          <div className={colorStatus(property.status)} />
        </div>
      </TableCell>
      <TableCell>{property.name}</TableCell>
      <TableCell>
        <LinearProgressWithLabel
          value={percentageOfProperty(property, clients)}
        />
      </TableCell>
      {Array(maxCols)
        .fill(null)
        .map((_, index) => {
          const currentClient = clientsAtProperty(property, clients);
          return (
            <ClientCell
              key={`${property.id}-${index}`}
              currentClient={currentClient[index]}
              status={property.status}
              property={property}
            />
          );
        })}
    </TableRow>
  );
};

Rows.propTypes = {
  property: PropTypes.object,
  maxCols: PropTypes.number,
  clients: PropTypes.array,
};

const mapStateToProps = (state) => ({
  maxCols: state.clientProperty.root.maxClientLength,
  clients: state.clientProperty.root.clients,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Rows);
