import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classes from '../../../styles/ResultsStateCustomCell.module.scss';

const CutsPercentage = ({ cellData, row, name }) => {
  const { saveBudgetState } = useSelector((state) => state.cashFlow.root) || {};
  const utilityBeforeTaxTitle = 'Utilidad antes de impuestos';
  const totalSalesId = 'TOTAL_SALES';
  const construction = 'Construcción';
  const financialPerformance = 'financialPerformance';
  const controlPercentage = 'controlPercentage';

  const virtualTableCell = [
    classes.virtualTableCell,
    name === controlPercentage ? classes.rightLine : '',
    saveBudgetState === false ? classes.errorRightLine : '',
  ];

  const cellClasses = [
    row.name === utilityBeforeTaxTitle ? classes.utilityFloor : '',
    row.name === utilityBeforeTaxTitle &&
    name === controlPercentage &&
    saveBudgetState === false
      ? classes.errorUtilityLine
      : '',
    row.name === utilityBeforeTaxTitle && name === controlPercentage
      ? classes.utilityLine
      : '',
    row.id === totalSalesId ? classes.groupsFloor : '',
    row.contracts ? classes.itemsFloor : '',
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance
      ? classes.contractsFloor
      : '',
  ];

  const typographyClasses = [
    row.name === utilityBeforeTaxTitle ? classes.utilityTypography : '',
    row.id === totalSalesId ? classes.groupTypography : '',
    row.contracts ? classes.itemsTypography : '',
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance
      ? classes.contractsTypography
      : '',
    classes.typographySize,
  ];

  return (
    <VirtualTable.Cell className={virtualTableCell}>
      <Box className={cellClasses}>
        <Typography variant="body2" className={typographyClasses}>
          {cellData === null ? '-' : cellData}
        </Typography>
      </Box>
    </VirtualTable.Cell>
  );
};

CutsPercentage.propTypes = {
  cellData: PropTypes.string,
};

export default CutsPercentage;
