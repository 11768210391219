import React, { createContext, FC, useContext } from "react";
import useContractor, {
  UseContractorResult,
} from "../../../Core/customHooks/useContractor";

type ContractorContextType = UseContractorResult;

const ContractorContext = createContext<ContractorContextType | null>(null);

export const useContractorContext = (): ContractorContextType =>
  useContext(ContractorContext) as ContractorContextType;

export const ContractorProvider: FC = ({ children }) => {
  const value = useContractor();

  return (
    <ContractorContext.Provider value={{ ...value }}>
      {children}
    </ContractorContext.Provider>
  );
};
