import { useState } from 'react';
import { useRecoilState } from 'recoil';
import useBoolean from 'shared/customHooks/useBoolean';
import { IAreaCustomColumn } from '../interfaces/IAreaCustomColumn';
import { AreaEditionHaveChangesAtom } from '../recoil/AreaEditionHaveChangesAtom';
import { AreaTypeParsedFunc } from '../types/AreaTypeParsedFunc';

interface Props {
  onSave: AreaTypeParsedFunc;
  onOpen: () => Promise<void>;
}

const useModalAreaTypeEditor = (props: Props) => {
  const { onOpen, onSave } = props;
  const { value: isOpenModalAreaTypeEditor, setTrue, setFalse } = useBoolean();
  const [areaTypeParsed, setAreaTypeParsed] = useState<IAreaCustomColumn>();
  const [_, setAreaEditionHaveChangesState] = useRecoilState(AreaEditionHaveChangesAtom);

  const openModalAreaTypeEditor: AreaTypeParsedFunc = async (areaType) => {
    setAreaTypeParsed(areaType);
    await onOpen();
    setTrue();
  };

  const closeModalAreaTypeEditor: AreaTypeParsedFunc = async (areaType) => {
    setFalse();
  };

  const handleClickSuccess: AreaTypeParsedFunc = async (areaType) => {
    await onSave(areaType);
    setAreaEditionHaveChangesState(true);
    setFalse();
  };

  return {
    areaTypeParsed,
    isOpenModalAreaTypeEditor,
    openModalAreaTypeEditor,
    handleClickSuccess,
    closeModalAreaTypeEditor,
  };
};

export default useModalAreaTypeEditor;
