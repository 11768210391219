import { useCallback } from 'react';
import ClientListCreatedAtFormatterContainer from '../../UI/Formatters/ClientListCreatedAtFormatter/ClientListCreatedAtFormatter';
import { ClientActionsProps } from '../interfaces/IActions';

const useClientListFormatter = (props: ClientActionsProps) => {
  const clientListFormatter = useCallback(ClientListCreatedAtFormatterContainer(props), []);
  return clientListFormatter;
};

export default useClientListFormatter;
