import React, { FC } from "react";
import MomentUtils from '@date-io/moment';
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { DateRangeOutlined } from "@material-ui/icons";

interface Props {
  datePickerProps: DatePickerProps
  textFieldProps: TextFieldProps
}

const ClientDateField: FC<Props> = ({ datePickerProps, textFieldProps }) => <MuiPickersUtilsProvider utils={MomentUtils}>
  <DatePicker
    TextFieldComponent={(props) => (
      <TextField
        {...props}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <DateRangeOutlined />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        {...textFieldProps}
      />
    )}
    {...datePickerProps}
  />
</MuiPickersUtilsProvider>

export default ClientDateField