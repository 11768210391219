import Services from 'services/services';
import { MoveCords, MoveTowerPoint, TowerValues } from '../core/types/Stages.types';
import {
  AddTowerResponse,
  CreateResponse,
  DeleteResponse,
  DeleteTowerResponse,
  FindProjectStagesResponse,
  UpdateTowerResponse,
} from '../core/types/StagesServices.types';
import StagesServicesDefinitions from './StagesServicesDefinitions';

class StagesServices extends Services {
  create(projectId: string): Promise<CreateResponse> {
    return this.post(StagesServicesDefinitions.rootUrl, { projectId });
  }

  getProjectStages(projectId: string): Promise<FindProjectStagesResponse> {
    return this.get(StagesServicesDefinitions.getStageMemberUrl(projectId));
  }

  deleteStage(stageId: number): Promise<DeleteResponse> {
    return this.delete(StagesServicesDefinitions.getStageMemberUrl(stageId));
  }

  addTower(stageId: number, tower: TowerValues): Promise<AddTowerResponse> {
    return this.post(StagesServicesDefinitions.getStageTowersUrl(stageId), tower);
  }

  updateTower(towerId: string, tower: TowerValues): Promise<UpdateTowerResponse> {
    return this.patch(StagesServicesDefinitions.getStageTowersUrl(towerId), tower);
  }

  deleteTower(towerId: string): Promise<DeleteTowerResponse> {
    return this.delete(StagesServicesDefinitions.getStageTowersUrl(towerId));
  }

  moveTower(towerId: string, movePoints: MoveCords<MoveTowerPoint>): Promise<DeleteTowerResponse> {
    return this.post(StagesServicesDefinitions.getMoveTowersUrl(towerId), movePoints);
  }
}

export default StagesServices;
