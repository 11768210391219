import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableHeaderRow,
  TableFixedColumns,
  VirtualTable,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import useColumnsAndRowsForTableCashFlow from './CustomHooks/useColumnsAndRowsForTableCashFlow';

const TableCashFlow = ({ data }) => {
  const {
    columns,
    tableColumnExtensions,
    leftColumns,
    rows,
    defaultExpandedRowIds,
    getChildrenRows,
  } = useColumnsAndRowsForTableCashFlow(data);

  return (
    <Grid rows={rows} columns={columns}>
      <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
      <CustomTreeData getChildRows={getChildrenRows} />
      <VirtualTable columnExtensions={tableColumnExtensions} height="800px" />
      <TableHeaderRow />
      <TableTreeColumn for="type" />
      <TableFixedColumns leftColumns={leftColumns} />
    </Grid>
  );
};

TableCashFlow.propTypes = {
  data: PropTypes.array,
};

export default TableCashFlow;
