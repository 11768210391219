import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import useCheckboxConfirm from '../../../../Core/customHooks/useCheckboxConfirm';
import CustomTypography from '../../../../../../components/Typography/CustomTypography';

const CheckboxConfirmPayments = () => {
  const { isConfirm, onChange, label } = useCheckboxConfirm();

  return (
    <>
      <CustomTypography variant="subtitle">
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={isConfirm}
                onChange={onChange}
                name="checkedB"
                color="primary"
                disabled={isConfirm}
              />
            }
            label={label}
          />
        </FormGroup>
      </CustomTypography>
    </>
  );
};

export default CheckboxConfirmPayments;
