import { API_PATH } from '../../config/config';

const ClientsServiceDefinitions = {
  fromClientProperty: (towerId) => `${API_PATH}clientProperty/${towerId}`,
  fromClientPropertyTower: (towerId, propertyId) =>
    `${API_PATH}clientProperty/property/${towerId}/${propertyId}`,
  fromProperty: (towerId) => `${API_PATH}clientProperty/properties/${towerId}`,
};

export default ClientsServiceDefinitions;
