import React, { FC } from 'react';
import GetAppOutlined from '@material-ui/icons/GetAppOutlined';
import AreaButton from './AreaButton';
import { AreaDownloadTemplateProps } from '../../Core/customHooks/AreaActions/AreaDownloadTemplateProps';
import useAreaDownloadTemplate from '../../Core/customHooks/AreaActions/useAreaDownloadTemplate';

const AreaDownloadTemplate: FC<AreaDownloadTemplateProps> = (props) => {
  const { onClick } = useAreaDownloadTemplate(props);
  return <AreaButton startIcon={<GetAppOutlined />} value="Descargar plantilla" onClick={onClick} />;
};
export default AreaDownloadTemplate;
