import MomentUtils from '@date-io/moment';
import { Box, TextareaAutosize, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import useBuyerCostumerLoader from '../../BuyerCostumers/Core/customHooks/useBuyerCustomerLoader';
import AbsoluteCircularProgress from '../../ResultsState/UI/styles/AbsoluteCircularProgress';
import useMapBuyersForRealPayment from '../Core/customHooks/useMapBuyersForRealPayment';
import useUpdatesForRealPayment from '../Core/customHooks/useUpdatesForRealPayment';
import Typography from '../../../shared/components/Typography/Typography';
import Style from './RealPaymentCreationMainView.module.scss';
import Button from '../../../components/UI/Button/Button';

const BoldTypography = styled(Typography)`
  width: 250px;
  padding: 2px 25px;
`;

const TitleTypography = styled(Typography)`
  font-weight: bold;
  padding: 25px 25px;
`;

const TextArea = styled(TextareaAutosize)`
  width: 100%;
`;

const RealPaymentCreationMainView = () => {
  const { isLoading } = useBuyerCostumerLoader();
  const { propertyRows } = useMapBuyersForRealPayment();
  const {
    states,
    updateAutoComplete,
    updateDate,
    updatePrice,
    updateNotes,
    onSave,
  } = useUpdatesForRealPayment();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" pt={10}>
      {isLoading && <AbsoluteCircularProgress />}
      <Box display="flex" flexDirection="column" style={{ width: '70%' }}>
        <Typography variant="subtitle1">Ingreso de pagos</Typography>
        <Box display="flex" flexDirection="column" py={5} px={5}>
          <Box display="flex" pb={5}>
            <Autocomplete
              id="autocomplete-box-property"
              options={propertyRows}
              getOptionLabel={(option) => option.title}
              className={Style.wholeWidth}
              onChange={updateAutoComplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={Style.wholeWidth}
                  label="Seleccionar cliente"
                  variant="outlined"
                  InputLabelProps={{
                    className: Style.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Box>
          <Box display="flex" pb={2}>
            <Box display="flex" pr={2} className={Style.sameWidthItem}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  openTo="year"
                  format="DD/MM/YYYY"
                  inputVariant="outlined"
                  className={Style.wholeWidth}
                  label="Fecha"
                  views={['year', 'month', 'date']}
                  value={states.date}
                  onChange={updateDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{
                        className: Style.floatingLabelFocusStyle,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box display="flex" pl={2} className={Style.sameWidthItem}>
              <NumberFormat
                customInput={TextField}
                className={Style.wholeWidth}
                variant="outlined"
                prefix="$"
                label="Valor Pagado"
                thousandSeparator
                allowNegative={false}
                defaultValue={0}
                value={states.price}
                onValueChange={updatePrice}
                InputLabelProps={{
                  className: Style.floatingLabelFocusStyle,
                }}
              />
            </Box>
          </Box>
          <Box display="flex" py={2} flexDirection="column">
            <Box pt={1}>
              <Typography variant="body1" color="gray500">
                Comentarios
              </Typography>
            </Box>
            <TextArea
              rowsMin={5}
              placeholder="Escribe aquí..."
              value={states.comment}
              onChange={updateNotes}
            />
          </Box>
          <Box display="flex" flexDirection="row-reverse" pt={20}>
            <Button className={Style.SaveButton} onClick={onSave}>
              <Typography variant="headline6" color="primary100">
                Guardar
              </Typography>
            </Button>
          </Box>
        </Box>
        {/* <Box>
          <UploadButton
            buttonTitle="Cargar pagos - Alianza fiduciaria"
            fileName={file ? file.name : null}
            onChangeHandler={onChangeFileHandler}
            onCancel={onCancel}
            onAccept={uploadExcel}
            isUploadingFile={isUploadingFile}
            key={file ? file.name : ''}
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default RealPaymentCreationMainView;
