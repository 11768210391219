import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { TableHeaderRow } from '@devexpress/dx-react-grid';
import { Plugin, Template } from '@devexpress/dx-react-core';
import TableHeaderRowCell from '../../../../../../shared/tables/TableHeaderRowCell';
import { isNotHeadingTableCell } from '../../../../../../shared/tables/helpers/TableTemplateHelpers';
import useAreaEditableHeaderConnector from '../../../../Core/customHooks/AreaEditionTable/useAreaTableHeaderCell';
import {
  AreaEditableHeaderConnectorProps,
  AreaEditableHeaderProps,
} from '../../../../Core/customHooks/AreaEditionTable/AreaHeaderConnectorProps';
import AreaEditionTablePopover from '../../AreaHeaderTablePopover/AreaEditionTablePopover';
import AreaHeaderCell from '../../Cells/AreaHeaderCell/AreaHeaderCell';

const AreaEditableHeaderConnector: FC<AreaEditableHeaderConnectorProps> = (props) => {
  const { title } = props.tableColumn.column ?? {};
  const { total, areaType } = useAreaEditableHeaderConnector(props);

  return (
    <TableHeaderRowCell>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <AreaHeaderCell title={title ?? ''} total={total} />
        <AreaEditionTablePopover
          areaType={areaType}
          removeColumn={props.removeColumn}
          updateColumn={props.updateColumn}
        />
      </Box>
    </TableHeaderRowCell>
  );
};

const AreaEditableHeaderProvider: FC<AreaEditableHeaderProps> = (props) => {
  return (
    <Plugin name="AreaEditableHeaderProvider">
      <Template name="tableCell" predicate={isNotHeadingTableCell(props.columnNames)}>
        {(cellProps: TableHeaderRow.CellProps) => <AreaEditableHeaderConnector {...cellProps} {...props} />}
      </Template>
    </Plugin>
  );
};

export default AreaEditableHeaderProvider;
