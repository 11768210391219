import React, { useState, useCallback, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteImageIcon from '@material-ui/icons/DeleteForeverOutlined';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { SegmentedControl } from 'segmented-control';
import Styles from '../styles/GraphicMaterialDialog.module.scss';
import Gallery from '../customViews/ImageGallery';
import DeleteDialog from './DeleteDialog';
import UploadGraphicMaterialButton from '../customViews/UploadGraphicMaterialButton';
import AssignImageToPropertyDialog from './AssignImageToPropertyDialog';
import Services from '../../../services/GraphicMaterialServices';
import useCanAssignImages from '../../../Core/customHooks/useCanAssignImages';

const services = new Services();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GraphicMaterialDialog = ({
  openGrapicMaterial,
  propertyInfo,
  closeFunction,
  showAddAndDelete,
}) => {
  const property = propertyInfo;
  const canAssignImages = useCanAssignImages();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [graphicMaterialFiles, setgraphicMaterialFiles] = useState([]);
  const [imgType, setImgType] = useState(null);
  const [newGraphicMaterialFile, setNewGraphicMaterialFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [
    isUploadingGraphicMaterialFile,
    setIsLoadingGraphicMaterial,
  ] = useState(false);
  const [
    openAssignImageToPropertyDialog,
    setOpenAssignImageToPropertyDialog,
  ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getGraphicMaterial = async (propertyId, plantOrRender) => {
    const response = await services.getGraphicMaterial(
      propertyId,
      plantOrRender,
    );
    setImgType(plantOrRender);
    const graphicMaterial = response;
    setgraphicMaterialFiles(graphicMaterial.data);
  };

  const onChangeFileHandler = (event) => {
    if (event.target.files[0]) {
      setNewGraphicMaterialFile(event.target.files[0]);
    }
  };

  const uploadGraphicMaterial = async () => {
    try {
      setIsLoadingGraphicMaterial(true);
      const formData = new FormData();
      formData.append('file', newGraphicMaterialFile);
      const res = await services.putGraphicMaterial(
        property.id,
        imgType,
        formData,
      );
      setNewGraphicMaterialFile(null);
      setIsLoadingGraphicMaterial(false);
      await getGraphicMaterial(property.id, imgType);
      enqueueSnackbar('Se adjuntó el archivo correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error interno, no es posible adjuntar archivos', {
        variant: 'error',
      });
    }
  };

  const onCancel = () => {
    setNewGraphicMaterialFile(null);
  };

  const deleteGraphicMaterial = async (imgId, propertyId, typeOfImage) => {
    try {
      await services.deleteGraphicMaterial(imgId, propertyId);
      await getGraphicMaterial(propertyId, typeOfImage);
      enqueueSnackbar('Archivo eliminado satisfactoriamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error interno, no se pudo eliminar el archivo', {
        variant: 'error',
      });
    }
  };

  const assignImageToProperty = async (propertyId, graphicMaterialId) => {
    try {
      await services.putAssignGraphicMaterial(
        propertyId,
        graphicMaterialId,
        imgType,
      );
      enqueueSnackbar('La imagen se asignó correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error al asignar la imagen', { variant: 'error' });
    }
  };

  const plant = 'PLANT';
  const render = 'RENDER';

  const currentImg = useRef();

  const handleClickOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseAssingImage = () => {
    setOpenAssignImageToPropertyDialog(false);
  };

  useEffect(() => {
    if (imgType) {
      getGraphicMaterial(property.id, imgType);
    }
  }, [propertyInfo]);

  const currentImgId = useCallback((imgId) => {
    setSelectedImage(imgId);
  }, []);

  return (
    <>
      <Dialog
        open={openGrapicMaterial}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeFunction}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={Styles.galeryHeader}>
          <DialogTitle id="alert-dialog-slide-title">{`Apartamento ${property.name}`}</DialogTitle>
          {graphicMaterialFiles.length > 0
            ? showAddAndDelete && (
              <div className={Styles.actionButtons}>
                <Button color="secondary" component="span" disableElevation>
                  <DeleteImageIcon
                    color="secondary"
                    className={Styles.deleteIcon}
                    onClick={async () => {
                      handleClickOpen();
                    }}
                  />
                </Button>
                <UploadGraphicMaterialButton
                  fileName={
                    newGraphicMaterialFile
                      ? newGraphicMaterialFile.name
                      : null
                  }
                  onChangeHandler={onChangeFileHandler}
                  onCancel={onCancel}
                  onAccept={uploadGraphicMaterial}
                  isUploadingFile={isUploadingGraphicMaterialFile}
                />
              </div>
            )
            : showAddAndDelete && (
              <UploadGraphicMaterialButton
                fileName={
                  newGraphicMaterialFile ? newGraphicMaterialFile.name : null
                }
                onChangeHandler={onChangeFileHandler}
                onCancel={onCancel}
                onAccept={uploadGraphicMaterial}
                isUploadingFile={isUploadingGraphicMaterialFile}
              />
            )}
        </div>
        <DialogContent>
          <div className={Styles.imageTypeSelector}>
            <SegmentedControl
              className={Styles.switch}
              name="imageType"
              options={[
                { label: 'Plantas', value: plant, default: true },
                { label: 'Renders', value: render },
              ]}
              setValue={async (newValue) => {
                setImgType(newValue);
                await getGraphicMaterial(property.id, newValue);
              }}
              variant="base"
            />
          </div>
          {graphicMaterialFiles.length > 0 ? (
            <Gallery
              customRef={currentImg}
              graphicMaterialFiles={graphicMaterialFiles}
            />
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Aún no se ha adjuntado ningun archivo
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={Styles.actions}>
          {canAssignImages && graphicMaterialFiles.length > 0 && (
            <Button
              color="primary"
              onClick={async () => {
                await currentImgId(
                  graphicMaterialFiles[currentImg.current.getCurrentIndex()].id,
                );
                setOpenAssignImageToPropertyDialog(true);
              }}
            >
              Asignar imagen
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              closeFunction();
            }}
          >
            CERRAR
          </Button>
        </DialogActions>
        <DeleteDialog
          propertyId={property.id}
          imgType={imgType}
          openDialog={openDeleteDialog}
          closeDeleteDialog={handleCloseDialog}
          deleteImg={deleteGraphicMaterial}
          onAccept={async () => {
            const imgToDelete =
              graphicMaterialFiles[currentImg.current.getCurrentIndex()].id;
            await deleteGraphicMaterial(imgToDelete, property.id, imgType);
            handleCloseDialog();
          }}
        />
      </Dialog>
      <AssignImageToPropertyDialog
        openDialog={openAssignImageToPropertyDialog}
        closeDialog={handleCloseAssingImage}
        propertyId={property.id}
        imgType={imgType}
        selectedImage={selectedImage}
        onAccept={async (propertyId, imgToAssign) => {
          assignImageToProperty(propertyId, imgToAssign, imgType);
        }}
        onDelete={async (propertyId, imgToDelete) => {
          await deleteGraphicMaterial(imgToDelete, propertyId, imgType);
        }}
        getImages={getGraphicMaterial}
      />
    </>
  );
};

GraphicMaterialDialog.propTypes = {
  openGrapicMaterial: PropTypes.bool,
  propertyInfo: PropTypes.object,
  closeFunction: PropTypes.bool,
  showAddAndDelete: PropTypes.bool,
};

export default GraphicMaterialDialog;
