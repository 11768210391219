import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import useDataGenerator from '../Core/CustomHooks/useDataGenerator';
import RowsCustomCell from './cells/SalesRoomTableRowCustomCell';
import HeaderCustomCell from './cells/SalesRoomTableHeaderCustomCell';
import useColumnExtension from '../Core/CustomHooks/useColumnExtensions'
import Style from './styles/SalesRoomTableMainView.module.scss';

const SalesRoomTableMainView = ({
  headers,
  columns,
  arrayOfProperties,
  typeSelected,
  onClickSelector,
  lowestFloor,
  propertyTypes,
}) => {
  const numberOFRows = columns;

  const { createHeaders, createRows } = useDataGenerator();
  const { CreateTableColumnExtensions } = useColumnExtension();

  const tableColumnExtensions = CreateTableColumnExtensions(headers);

  const generatedHeaders = createHeaders(headers);
  const generatedRows = createRows(
    arrayOfProperties,
    numberOFRows,
    typeSelected,
    onClickSelector,
    lowestFloor,
    propertyTypes,
  );

  return (
    <Paper className={Style.table}>
      <Grid rows={generatedRows} columns={generatedHeaders}>
        <VirtualTable
          cellComponent={RowsCustomCell}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow cellComponent={HeaderCustomCell} />
      </Grid>
    </Paper>
  );
};

SalesRoomTableMainView.propTypes = {
  headers: PropTypes.array,
  columns: PropTypes.array,
  arrayOfProperties: PropTypes.array,
  propertyTypes: PropTypes.array,
  typeSelected: PropTypes.string,
  onClickSelector: PropTypes.func,
  lowestFloor: PropTypes.number,
};

export default SalesRoomTableMainView;
