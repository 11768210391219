import { useState } from 'react';
import moment from 'moment';
import { getMonthlyDatesFromMoment } from '../../../../helpers/dates';

const makeDynamicColumns = (currentColumns, dates, hasInnerDate) => {
  const newColumns = dates.map((date) => {
    if (hasInnerDate) return { name: date.date, title: date.date };

    return { name: date, title: date, columnName: date };
  });

  return [...currentColumns, ...newColumns];
};

const initialMomentDates = (columnDates, initialDate, lastDate) => {
  const arrayOfDates = columnDates.map((dateColumn) =>
    moment(dateColumn.date, 'MMM YYYY'),
  );
  const oldestDate = moment.min([...arrayOfDates, moment(initialDate)]);
  const newerDate = moment.max([...arrayOfDates, moment(lastDate)]);
  return { oldestDate, newerDate };
};

const isParent = (row) => {
  return row && !row.parentId && !row.subParentId;
};

const subParentId = (row) => {
  return row && row.parentId && !row.subParentId;
};

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((r) => {
    if (isParent(row)) {
      return subParentId(r) && r.parentId === row.id;
    }

    if (subParentId(row)) {
      return r.subParentId === row.id;
    }

    if (r.parentId === undefined) r.parentId = null;
    if (r.subParentId === undefined) r.subParentId = null;
    return r.parentId === row && r.subParentId === row;
  });
  return childRows.length ? childRows : null;
};

function useColumnsForDetails(salesFlow, initialDate, lastDate) {
  const saleFound = salesFlow.find((sales) => sales.dates);
  const columnDates = saleFound ? saleFound.dates : [];

  const initialColumns = [{ name: 'name', title: 'Nombre' }];

  let columns = makeDynamicColumns(initialColumns, columnDates, true);
  let dates = [];
  if (initialDate && lastDate) {
    const { oldestDate, newerDate } = initialMomentDates(
      columnDates,
      initialDate,
      lastDate,
    );
    const objectDates = getMonthlyDatesFromMoment(oldestDate, newerDate);
    dates = Object.keys(objectDates);
    columns = makeDynamicColumns(initialColumns, dates, false);
  } else {
    dates = columnDates.map((column) => column.date);
  }

  const currentMonth = moment();
  const accumulatedColumns = columns.filter((column) => {
    const validation = moment(column.name, 'MMM YYYY').isBefore(
      currentMonth,
      'M',
    );

    return validation && column;
  });

  const projectedColumns = columns.filter((column) => {
    const validation = moment(column.name, 'MMM YYYY').isBefore(
      currentMonth,
      'M',
    );

    return !validation && column;
  });

  const [columnBands] = useState([
    {
      title: 'Meses facturados',
      children: accumulatedColumns,
    },
    {
      title: 'Meses proyectados',
      children: projectedColumns,
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'name', width: 350 },
  ]);

  const [defaultExpandedRowIds] = useState([]);
  const [leftColumns] = useState(['name']);

  return {
    columns,
    columnBands,
    tableColumnExtensions,
    defaultExpandedRowIds,
    leftColumns,
    dates,
  };
}

export default useColumnsForDetails;
