import { useState } from 'react';

import SalesSpeedHelperTables from '../enums/SalesSpeedHelperTables';
import { TabClickHandler } from '../types/SalesSpeedHelper.types';

const useSalesSpeedHelperTabs = () => {
  const [currentTab, setCurrentTab] = useState<SalesSpeedHelperTables>(SalesSpeedHelperTables.UnitSales);

  const handleTabClick: TabClickHandler = (event) => {
    setCurrentTab(event.currentTarget.dataset.name);
  };

  return { currentTab, handleTabClick };
};

export default useSalesSpeedHelperTabs;
