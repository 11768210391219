import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import NumberFormat from 'react-number-format';
import useUpdateEvalproDeedExpensesMonth from 'App/DeedExpenses/core/customHooks/useUpdateEvalproDeedExpensesMonth';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';
import VirtualTableContainer from 'shared/tables/VirtualTableContainer';
import { TableCellSmallCenter } from 'shared/tables/TableCell';
import EvalproDeedExpensesDeedMonthsInfoBanner from '../EvalproDeedExpensesDeedMonthsInfoBanner/EvalproDeedExpensesDeedMonthsInfoBanner';
import useEvalproDeedExpensesDeedMonthsTable from 'App/DeedExpenses/core/customHooks/useEvalproDeedExpensesDeedMonthsTable';
import { DateTypeProvider } from 'shared/tables/TableColumnFormat';
import Typography from 'shared/components/Typography/Typography';
import EvalproDeedExpensesCellNumberProvider from '../../tables/EvalproDeedExpensesCellNumber/EvalproDeedExpensesCellNumberProvider';
import styles from './EvalproDeedExpensesDeedMonthsTable.module.scss';
import EvalproDeedExpensesDeedValueProvider from '../../tables/EvalproDeedExpensesCellNumber/EvalproDeedExpensesDeedValueProvider';
import SnackAlert from '../../../../../shared/components/SnackAlert/SnackAlert';
import { Numbers } from 'helpers';

const EvalproDeedExpensesDeedMonthsTable: FC<{ towerId: string }> = ({ towerId }) => {
  const { data, columns, deedValue, totalDeedValue, unitsError, onRemoveSnack } =
    useEvalproDeedExpensesDeedMonthsTable(towerId);
  const { fetch: fetchStock } = useUpdateEvalproDeedExpensesMonth('stock');
  return (
    <div className={styles.Container}>
      <EvalproDeedExpensesDeedMonthsInfoBanner units={data?.evalproGroup.units ?? 0} deedValue={deedValue ?? 0} />
      {unitsError && (
        <SnackAlert
          message="El valor total de la unidades a escriturar debería ser igual al del inventario"
          onClick={onRemoveSnack}
        />
      )}
      <Grid rows={data?.months ?? []} columns={columns}>
        <Table containerComponent={VirtualTableContainer} cellComponent={TableCellSmallCenter} />
        <EvalproDeedExpensesCellNumberProvider for={['stock']} handleChangeValue={fetchStock} />
        <EvalproDeedExpensesDeedValueProvider
          for={['deedValue']}
          deedValue={deedValue ?? 0}
          units={data?.evalproGroup.units ?? 0}
        />
        <DateTypeProvider for={['date']} format="MMM-YYYY" />
        <TableHeaderRow cellComponent={TableHeaderRowCell} />
      </Grid>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Box marginRight="20px">
          <Typography variant="body1" fontWeight="bold">
            Total
          </Typography>
        </Box>
        <Box
          maxWidth="435px"
          minWidth="435px"
          minHeight="40px"
          className={styles.TotalBox}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="50%" className={styles.center}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={(data?.stock ?? 0) === data?.evalproGroup.units ? 'stateSuccess700' : 'stateError600'}
            >
              {data?.stock ?? 0}
            </Typography>
          </Box>
          <Box width="50%" className={styles.center}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={
                Numbers.toFixed(totalDeedValue, 0) === Numbers.toFixed(deedValue, 0)
                  ? 'stateSuccess700'
                  : 'stateError600'
              }
            >
              <NumberFormat displayType="text" thousandSeparator prefix="$" value={totalDeedValue} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default EvalproDeedExpensesDeedMonthsTable;
