import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useFilterSubscribe from '../../Core/CustomHooks/useFilterSubscribe';
import styles from './SelectionSquare.module.scss';

export default function SelectionSquare({ name, field }) {
  const [isActive, setActive] = useState(false);

  const { subscribeFilter, unsubscribeFilter } = useFilterSubscribe();

  const handleClickActive = () => {
    if (isActive) {
      unsubscribeFilter(name, field.value);
      setActive(false);
    } else {
      subscribeFilter(name, field.value);
      setActive(true);
    }
  };

  return (
    <Button
      key={field.label}
      classes={{
        root: isActive ? styles.buttonSelected : styles.buttonNormal,
      }}
      onClick={handleClickActive}
    >
      {field.label}
    </Button>
  );
}

SelectionSquare.propTypes = {
  name: PropTypes.string,
  field: PropTypes.object,
};
