import { useEffect, useState } from "react"
import QuotationsServices from '../../../../../../../../services/Quotations/Quotations.services'

const service = new QuotationsServices()

const useGetQuotationId = ({ id, towerId }) => {

    const [value, setValue] = useState(15)

    const fetch = async () => {
        const { data } = await service.getQuotationSetupByTowerId(towerId)
        setValue(data?.maxDueDaysForProperty ?? 15)
    }

    useEffect(() => {
        fetch()
    }, [])

    return {
        maxDueDaysForProperty: value
    }
}

export default useGetQuotationId