export const DEFAULT_FEATURE_FIELD_WIDTH = 120;

export enum ValidFeatures {
  GeneralWallet = 'generalWallet',
}

export enum FeatureFieldTypes {
  SingleSelect = 'singleSelect',
  MultiSelect = 'multiSelect',
  Text = 'text',
  Number = 'number',
}

export enum UpdateFeatureFieldTypes {
  Update = 'Update',
  Delete = 'Delete',
}
