import { useEffect, useRef } from 'react';

const setPillColor = (pill: HTMLDivElement | null, color: string) =>
  pill?.style.setProperty('--backgroun-color', color);

function usePillColor(color: string) {
  const pillElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPillColor(pillElement.current, color);
  });

  return { pillElement };
}

export default usePillColor;
