import Services from '../../../services/services';
import PaymentQuotationCashflowServicesDefinitions from './PaymentQuotationCashflowServicesDefinitions';

class PaymentQuotationCashflowServices extends Services {
  getCashflowServices(towerId) {
    const route = PaymentQuotationCashflowServicesDefinitions.quotationCashflow(
      towerId,
    );
    return this.get(route);
  }

  downloadCashflowServices(towerId) {
    const route = PaymentQuotationCashflowServicesDefinitions.donwloadQuotationCashflow(
      towerId,
    );
    return this.get(route, {
      responseType: 'blob',
    });
  }
}

export default PaymentQuotationCashflowServices;
