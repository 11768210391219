import { useEffect, useState } from 'react';

const makeDynamicColumns = (currentColumns, dates) => {
  const newColumns = Object.keys(dates).reduce((array, column) => {
    if (
      column === 'id' ||
      column === 'name' ||
      column === 'parentId' ||
      column === 'accumulated' ||
      column === 'projected' ||
      column === 'total'
    ) {
      return array;
    }
    return [...array, { name: column, title: column }];
  }, currentColumns);
  return newColumns;
};

function useColumnsForPaymentFlow(properties) {
  const [columns, setColumns] = useState([]);

  const [tableColumnExtensions] = useState([
    { columnName: 'name', width: 300 },
    { columnName: 'accumulated', width: 150 },
    { columnName: 'projected', width: 150 },
    { columnName: 'total', width: 150 },
  ]);

  const [leftColumns] = useState(['name', 'accumulated', 'projected', 'total']);

  useEffect(() => {
    const columnDates = properties[0] ? properties[0] : [];
    const newColumns = makeDynamicColumns(
      [
        { name: 'name', title: ' ' },
        { name: 'accumulated', title: 'Acumulado' },
        { name: 'projected', title: 'Proyectado' },
        { name: 'total', title: 'Total' },
      ],
      columnDates,
    );
    if (columns.length !== newColumns.length) {
      setColumns(newColumns);
    }
  }, [properties]);

  return {
    columns,
    tableColumnExtensions,
    leftColumns,
  };
}

export default useColumnsForPaymentFlow;
