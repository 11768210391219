type SymbolType = 'data' | 'heading';

export const isSymbolType = (type: Symbol, symbolType: SymbolType = 'data') => type.description === symbolType;
export const columnsIncludesName = (columnNames: string[], name?: string) => !!name && columnNames.includes(name);

export const isDataTableCell =
  (columnNames: string[]) =>
  ({ tableRow, tableColumn }: any) =>
    isSymbolType(tableRow.type) &&
    isSymbolType(tableColumn.type) &&
    columnsIncludesName(columnNames, tableColumn.column?.name);

export const isHeadingTableCell =
  (columnNames: string[]) =>
  ({ tableRow, tableColumn }: any) =>
    isSymbolType(tableRow.type, 'heading') &&
    isSymbolType(tableColumn.type) &&
    columnNames.includes(tableColumn.column?.name);

export const isNotHeadingTableCell =
  (columnNames: string[]) =>
  ({ tableRow, tableColumn }: any) =>
    isSymbolType(tableRow.type, 'heading') &&
    isSymbolType(tableColumn.type) &&
    !columnNames.includes(tableColumn.column?.name);
