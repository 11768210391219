import React, { FC } from 'react';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import { AreaUploadFileActionProps } from '../../Core/customHooks/AreaActions/AreaUploadFileActionProps';
import AreaButton from './AreaButton';
import useAreaUploadFileAction from '../../Core/customHooks/AreaActions/useAreaUploadFileAction';

const AreaUploadFileAction: FC<AreaUploadFileActionProps> = (props) => {
  const { inputRef, accept, handleChange, handleClick } = useAreaUploadFileAction(props);
  return (
    <>
      <input type="file" ref={inputRef} onChange={handleChange} hidden accept={accept} />
      <AreaButton startIcon={<CloudUploadOutlined />} {...props} value="Subir plantilla" onClick={handleClick} />
    </>
  );
};
export default AreaUploadFileAction;
