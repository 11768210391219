import { Contract } from '../../../../models/Contracts';

export interface UseContractPaymentScheduleGridPopoverActionsProps {
  handleActionRestartProjectedClicked: (contract: Contract) => void;
  handleActionAutofitProjectedClicked: (contract: Contract) => void;
}

interface UseContractPaymentScheduleGridPopoverActionsParams {
  contract?: Contract;
  extraProps: UseContractPaymentScheduleGridPopoverActionsProps;
  cb: () => void;
}

type HookType = (params: UseContractPaymentScheduleGridPopoverActionsParams) => {
  restartProjected: () => void;
  autofitProjected: () => void;
};

const useContractPaymentScheduleGridPopoverActions: HookType = ({ contract, extraProps, cb }) => {
  const restartProjected = () => {
    if (contract) extraProps.handleActionRestartProjectedClicked(contract);
    cb();
  };

  const autofitProjected = () => {
    if (contract) extraProps.handleActionAutofitProjectedClicked(contract);
    cb();
  };

  return {
    restartProjected,
    autofitProjected,
  };
};

export default useContractPaymentScheduleGridPopoverActions;
