import React from 'react';
import { Box, Paper } from '@material-ui/core';
import {
  Area,
  Bar,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { Numbers } from '../../../helpers';
import CustomizedChartLabel from './CustomizedChartLabel';
import useDataFormatterForChart from './customHooks/useDataFormatterForChart';

function MonthlySalesChart() {
  const { dataFormatted, totalUnits } = useDataFormatterForChart();

  return (
    <Paper>
      <Box width="100%" height="300px">
        <ResponsiveContainer>
          <ComposedChart width={1000} height={700} data={dataFormatted.all}>
            <XAxis dataKey="date" />
            <YAxis
              yAxisId="left"
              domain={[0, totalUnits]}
              tickFormatter={(y) => {
                return Numbers.toFixed((y * 100) / totalUnits, 0, true);
              }}
            />
            <Area
              type="monotone"
              name=""
              dataKey="backgroundToCurrentMonth"
              yAxisId="left"
              fill="#dae2ed"
              stroke="#dae2ed"
            />
            <Tooltip />
            <Bar
              name="Ventas reales - Proyectadas"
              dataKey="realSales"
              yAxisId="left"
              barSize={20}
              fill="#5781b8"
              label={
                <CustomizedChartLabel
                  data={dataFormatted.all}
                  totalUnits={totalUnits}
                />
              }
            >
              {dataFormatted.all.map((line, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={line.isBalancedPoint ? '#5e548e' : '#5781b8'}
                />
              ))}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}

export default MonthlySalesChart;
