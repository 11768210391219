const TotalCashFlowEnum = {
  ACCUMULATED_OPERATING_FLOW: {
    code: 'ACCUMULATED_OPERATING_FLOW',
    value: 'ACCUMULATED-OPERATING-FLOW',
  },

  SPREAD_IPC: { code: 'SPREAD_IPC', value: 'spreadIPC' },
  SALDO_ACUMULADO_INVERSIONISTAS: {
    code: 'SALDO_ACUMULADO_INVERSIONISTAS',
    value: 'saldo-acumulado-inversionistas',
  },

  MONTH_BALANCED_ID: {
    code: 'MONTH_BALANCED_ID',
    value: 'MONTH_BALANCED_ID',
  },

  ACCUMULATED_ID: {
    code: 'ACCUMULATED_ID',
    value: 'ACCUMULATED_ID',
  },

  CONSTRUCTION: {
    code: '-100',
    value: '-100',
  },

  EXPENSES: {
    code: 'EXPENSES',
    value: 'EXPENSES',
  },

  TOTAL_SALES: {
    code: 'TOTAL_SALES',
    value: 'TOTAL_SALES',
  },

  TOTAL_ID_FUTURE: {
    code: 'TOTAL_ID_FUTURE',
    value: 'TOTAL_ID_FUTURE',
  },

  TOTAL_ID_PAST: {
    code: 'TOTAL_ID_PAST',
    value: 'TOTAL_ID_PAST',
  },

  FINANCING_CREDIT_BALANCE: {
    code: 'financing-credit-balance',
    value: 'financing-credit-balance',
  },

  INVESTOR_FLOW: {
    code: 'investorFlow',
    value: 'investorFlow',
  },

  CXP: {
    code: 'cxp',
    value: 'cxp',
  },

  OPERATION_FLOW : {
    code: 'OPERATING-FLOW',
    value: 'OPERATING-FLOW'
  },

  FINANCING: {
    code: 'financing',
    value: 'financing'
  },

  FINANCIAL_PERFORMANCE: {
    code: 'financialPerformance',
    value: 'financialPerformance'
  }
};

export default TotalCashFlowEnum;
