import { Numbers } from '../../../../helpers';
import { EvalproSalesGroup } from '../../../../shared/interfaces/evalpro/EvalproSalesGroup';

export const TotalSalesByEvalproSalesGroup = (group: EvalproSalesGroup) => {
  return Numbers.toFixed(
    group.averageM2 * group.valueM2 * group.units +
      group.increment +
      group.salableAdditionals.reduce((current, additional) => current + additional.quantity * additional.price, 0),
    0,
  );
};
