import Numbers from '../../../helpers/numbers';
import { clientStates } from './enums/table.enum';
import { ClientDTO, GeneralWalletRow } from './interfaces/generalWallet.types';

class GeneralWalletClientFormatter {
  private static getClientState = (client: ClientDTO) =>
    client.isArrears ? clientStates.arrears.value : clientStates.upToDate.value;

  private static getClientArrears = (client: ClientDTO) => client.arrears * -1;

  private static getAccumulatedPercent = (client: ClientDTO) =>
    Numbers.toFixed(
      ((client.accumulatedPayments + client.financialAccumulated) / client.commercialPrice) * 100,
      2,
      true,
      false,
    );

  private static formatClient = (client: ClientDTO): GeneralWalletRow => ({
    ...client,
    financialAccumulated: client.financialAccumulated * -1,
    state: this.getClientState(client),
    arrears: this.getClientArrears(client),
    accumulatedPercentage: Numbers.toFixed(client.accumulatedPercentage, 2, true, false),
  });

  static format(clients: Array<ClientDTO>): Array<GeneralWalletRow> {
    return clients.map(this.formatClient);
  }
}

export default GeneralWalletClientFormatter;
