import moment from 'moment';
import { quotationMonthDate } from '../../../Quotation/Core/customHooks/useQuotationMonthDate';

const useCurrentMonthForAssignedPayment = (
  paymentTypes,
  index,
  startDate,
  initialDate,
  lastRealPaymentDate,
) => {
  const paymentDate = quotationMonthDate(index - 1, startDate, initialDate);

  const typeId =
    paymentTypes && paymentTypes.length > index ? paymentTypes[index] : '0';

  const isReal = paymentDate.isSameOrBefore(lastRealPaymentDate, 'M');
  const shownAsReal =
    (paymentDate.isSameOrBefore(lastRealPaymentDate, 'M') &&
      !lastRealPaymentDate.isSame(moment(), 'M')) ||
    paymentDate.isBefore(moment(), 'M');

  return { date: paymentDate, typeId, isReal, shownAsReal };
};

export default useCurrentMonthForAssignedPayment;
