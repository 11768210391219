import React, { FC } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { colors, Button, ButtonProps } from '@material-ui/core';

import MUIColors from '../../../assets/styles/settings/colors.module.scss';
import Typography from '../Typography/Typography';

export interface IStateChip {
  color: string;
  label: string;
  variant?: 'text' | 'outlined' | 'contained';
}

interface ChipButtonProps {
  color: string;
}

const resolveColor = (props: ChipButtonProps) =>
  colors[props.color]
    ? colors[props.color][200]
    : MUIColors[props.color]
    ? MUIColors[props.color]
    : CSS.supports('color', props.color)
    ? props.color
    : MUIColors['gray600'];

const resolveRedColorValue = (props: ChipButtonProps) => Color(resolveColor(props)).red();
const resolveGreenColorValue = (props: ChipButtonProps) => Color(resolveColor(props)).green();
const resolveBlueColorValue = (props: ChipButtonProps) => Color(resolveColor(props)).blue();
const resolveHueColorValue = (props: ChipButtonProps) => Color(resolveColor(props)).hue();

const resolveBgColor = (props: ButtonProps) =>
  props.variant === 'outlined' ? 'inherit' : 'hsl(var(--hue), 100%, 95%)';

const ChipButton = styled(({ color, ...otherProps }) => <Button {...otherProps} />)`
  --red: ${resolveRedColorValue};
  --green: ${resolveGreenColorValue};
  --blue: ${resolveBlueColorValue};
  --hue: ${resolveHueColorValue};
  --threshold: 0.5;
  --light: 55;
  --perceived-lightness: calc(var(--hue) / 255);
  --border-threshold: 0.8;
  border-radius: 4px;
  padding: 0;
  margin: -9px -9px -8px -14px;

  &.Mui-disabled {
    --border-alpha: calc((var(--perceived-lightness) - var(--border-threshold)) * 100);
    border-width: 0.2em;
    border-style: solid;
    border-color: rgb(var(--red), var(--green), var(--blue));
    background: ${resolveBgColor};
    --switch: calc((var(--light) - var(--threshold)) * 1%);
    color: hsl(var(--hue), 60%, var(--switch));
  }
  > .MuiButton-label {
    text-transform: capitalize;
  }
`;

const StateChip: FC<IStateChip> = ({ label, color, variant = 'contained' }) => {
  return (
    <ChipButton variant={variant} fullWidth size={'small'} color={color} disabled>
      {label}
    </ChipButton>
  );
};

export default StateChip;
