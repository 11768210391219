import { API_PATH } from '../../config/config';

const IncrementsServiceDefinition = {
  getDates: (towerId) => {
    return `${API_PATH}schedules/${towerId}`;
  },

  getLabeledDates: (towerId) => {
    return `${API_PATH}schedules/labeled-dates/${towerId}`;
  },

  getDatesOfStage: (towerId) => {
    return `${API_PATH}schedules/dates-of-stages/${towerId}`;
  },

  putStageDates: (towerId) => {
    return `${API_PATH}schedules/start-end-stage-date/${towerId}`;
  },

  putSalesStartDate: (towerId) => {
    return `${API_PATH}schedules/sales-start-dates/${towerId}`;
  },

  putBalancePointDate: (towerId) => {
    return `${API_PATH}schedules/balance-point-date/${towerId}`;
  },

  putConstructionStartDate: (towerId) => {
    return `${API_PATH}schedules/construction-start-date/${towerId}`;
  },

  putAverageDeliveryDate: (towerId) => {
    return `${API_PATH}schedules/average-delivery-date/${towerId}`;
  },

  putAverageDeliveryDisplacement: (towerId) => {
    return `${API_PATH}schedules/average-delivery-displacement/${towerId}`;
  },

  putEndOfSalesDate: (towerId) => {
    return `${API_PATH}schedules/end-of-sales-date/${towerId}`;
  },

  putMaximumCollectionDate: (towerId) => {
    return `${API_PATH}schedules/maximum-collection-date/${towerId}`;
  },

  putFirstSaleHandler: (towerId) => {
    return `${API_PATH}schedules/first-sales/${towerId}`;
  },

  putStrategySetup: (towerId) => {
    return `${API_PATH}schedules/update/strategy-setup/${towerId}`;
  },

  putBalancePointType: (towerId) => {
    return `${API_PATH}schedules/update/balance-point-type/${towerId}`;
  },

  getSalesChecker: (towerId) => {
    return `${API_PATH}schedules/sale-checker/${towerId}`;
  },
};

export default IncrementsServiceDefinition;
