import React, { FC } from 'react';
import { Dialog, Container, AppBar, Toolbar, Slide, CircularProgress } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Styles from './MonthlyInterestReportMainView.module.scss';
import { MuiIconButton } from 'shared/components/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '../../../shared/components/Typography/Typography';
import MonthlyInterestContent, {
  MonthlyInterestContentProp,
} from './components/MonthlyInterestContent/MonthlyInterestContent';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MonthlyInterestReportMainView: FC<MonthlyInterestContentProp> = (props) => {
  return (
    <Dialog fullScreen open={props.isOpen} onClose={props.handleClose} TransitionComponent={Transition}>
      <Container maxWidth="xl" className={Styles.root}>
        <AppBar position="fixed" className={Styles.appBar}>
          <Toolbar className={Styles.toolbar}>
            <MuiIconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </MuiIconButton>
            <Typography variant="h4" fontSize="1.5rem" fontWeight="600"></Typography>
          </Toolbar>
        </AppBar>

        <MonthlyInterestContent {...props} />
      </Container>
    </Dialog>
  );
};

export default MonthlyInterestReportMainView;
