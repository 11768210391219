import { useEffect, useState } from 'react';

export const useAreaPrices = () => {
  const [areaTypeId, setAreaTypeId] = useState<number>(0);
  const selectAreaType = (areaTypeId?: number | string) => setAreaTypeId(areaTypeId ? +areaTypeId : 0);

  return {
    areaTypeId,
    selectAreaType,
  };
};

export default useAreaPrices;
