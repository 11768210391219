import styled from 'styled-components';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import colors from '../../../../../../assets/styles/settings/colors.module.scss';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';

const TableHeaderRowCellButtonNewArea = styled(TableHeaderRowCell)<TableHeaderRow.CellProps>`
  padding: 0;
  &:hover {
    background: ${colors.primary100};
  }
`;

export default TableHeaderRowCellButtonNewArea;
