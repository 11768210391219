import { combineReducers } from 'redux';
import { reducer as StrategyReducer } from './containers/StrategyV2';
import { reducer as GroupsReducer } from './containers/Groups';
import { reducer as UploadDialogReducer } from './components/Area/Imports/Upload';
import { reducer as FinancialReducer } from './components/Financial';
import { reducer as BuildingDialogReducer } from './components/Building/Imports/Upload';
import { reducer as ClientPropertyReducer } from './containers/ClientProperty';
import { reducer as BudgetReducer } from './containers/Budget';
import { reducer as QuotationListReducer } from './components/Client2/List/Row/QuotationsList';
import { reducer as SaleDateSelectorReducer } from './containers/SaleDateSelector';
import { reducer as TowerBuilderReducer } from './containers/TowerBuilder';
import ArrearsReportReducer from './App/ArrearsReport/Core/reducer';
import { reducer as SalesEvalpro } from './App/SalesEvalpro/Core/index';
import CashFlowReducer from './App/TotalCashFlow/Core/reducer';
import SalesRoomReducer from './App/PropertyEdition/Core/reducer';
import BuyerCostumersReducer from './App/BuyerCostumers/Core/reducer';
import ProjectionTypesReducer from './App/ProjectionTypes/Core/reducer';
import ProjectStagesReducer from './App/ProjectStages/Core/reducer';
import SalesRoomFilterReducer from './App/SalesRoom/Core/reducer';
import BuildingReducer from './App/Building/Core/reducer';
import BusinessPartnerCreatorReducer from './App/BusinessPartners/Core/reducer';
import CashFlowEvalproReducer from './App/CashFlowEvalpro/Core/reducer';
import RealPaymentReducer from './App/RealPaymentsForProperty/Core/reducer';
import ReportWeeklyReducer from './App/SalesWeeklyReport/Core/reducer';
import ProjectResultsStateReducer from './App/ProjectResultsState/Core/reducer'

const appReducers = combineReducers({
  strategy: StrategyReducer,
  groups: GroupsReducer,
  areas: UploadDialogReducer,
  building: BuildingDialogReducer,
  financial: FinancialReducer,
  clientProperty: ClientPropertyReducer,
  budget: BudgetReducer,
  quotations: QuotationListReducer,
  saleDateSelector: SaleDateSelectorReducer,
  towerBuilder: TowerBuilderReducer,
  salesEvalpro: SalesEvalpro,
  cashFlow: CashFlowReducer,
  projectResultsState: ProjectResultsStateReducer,
  salesRoom: SalesRoomReducer,
  buyerCostumers: BuyerCostumersReducer,
  projectionTypes: ProjectionTypesReducer,
  projectStages: ProjectStagesReducer,
  salesRoomFilter: SalesRoomFilterReducer,
  buildingHeader: BuildingReducer,
  businessPartnerCreator: BusinessPartnerCreatorReducer,
  arrearsReport: ArrearsReportReducer,
  cashFlowEvalpro: CashFlowEvalproReducer,
  realPayments: RealPaymentReducer,
  reportWeekly: ReportWeeklyReducer,
});

export default (state, { type, payload }) => {
  return appReducers(state, { type, payload });
};
