import { ClientDetail } from '../interfaces/generalWallet.types';

export interface UseGeneralWalletPopoverActionsProps {
  handleActionDetailsClicked: (client: ClientDetail) => void;
  handleActionMonthlyInterestReportClicked: (client: ClientDetail) => void;
}

interface Props {
  client?: ClientDetail;
  extraProps: UseGeneralWalletPopoverActionsProps;
  cb: () => void;
}

const useGeneralWalletPopoverActions = (props: Props) => {
  const { client, extraProps, cb } = props;

  const goDetails = () => {
    if (client) extraProps.handleActionDetailsClicked(client);
    cb();
  };

  const goToMonthlyInterestReport = () => {
    if (client) extraProps.handleActionMonthlyInterestReportClicked(client);
    cb();
  };

  return {
    goDetails,
    goToMonthlyInterestReport,
  };
};

export default useGeneralWalletPopoverActions;
