import { MouseEvent } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ContractPaymentScheduleFlow } from '../../../../models/ContractPayments';

import Services from '../../../../services/ContractPayments/ContractPaymentsServices';
import InvoiceServices from '../../../../services/ContractPaymentsInvoice/ContractPaymentsInvoiceServices';

const services = new Services();
const invoiceServices = new InvoiceServices();

const useContractPaymentScheduleData = (contractId: number) => {
  return useQuery<ContractPaymentScheduleFlow[]>(
    ['contract-payments-schedule', contractId],
    async () => {
      const { data: schedulePayments } = await services.getSchedulePaymentsByContract(contractId);
      return schedulePayments;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useAutofitProjected = (contractId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => invoiceServices.autofitProjected(contractId), {
    onSettled: () => {
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries(['contract', contractId]);
      queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
    },
  });
};

const useRestartProjected = (contractId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => invoiceServices.restartProjected(contractId), {
    onSettled: () => {
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries(['contract', contractId]);
      queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
    },
  });
};

type HookType = (currentContractId: number) => {
  contractPayments: ContractPaymentScheduleFlow[] | undefined;
  isLoading: boolean;
  handleRestartProjected: () => void;
  handleAutofitProjected: () => void;
};

const useContractPaymentsSchedule: HookType = (currentContractId) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: contractPayments, isLoading } = useContractPaymentScheduleData(currentContractId);

  const { mutateAsync: autofitProjected } = useAutofitProjected(currentContractId);
  const { mutateAsync: restartProjected } = useRestartProjected(currentContractId);

  const handleRestartProjected = async () => {
    try {
      await restartProjected();
      enqueueSnackbar('Se actualizaron los valores con éxito', { variant: 'success' });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };

  const handleAutofitProjected = async () => {
    try {
      await autofitProjected();
      enqueueSnackbar('Se actualizaron los valores con éxito', { variant: 'success' });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };
  return {
    contractPayments,
    isLoading,
    handleRestartProjected,
    handleAutofitProjected,
  };
};

export default useContractPaymentsSchedule;
