import { API_PATH } from '../../../config/config';

class GeneralWalletServicesDefinitions {
  static updateWalletSetup = () => `${API_PATH}wallet-transaction/update/setup`;

  static generalWithTowerId = (towerId) =>
    `${API_PATH}wallet-transaction/general/${towerId}`;

  static downloadGeneralWithTowerId = (towerId) =>
    `${API_PATH}wallet-transaction/general/download/${towerId}`;

  static postNewPayment = () =>
    `${API_PATH}wallet-transaction/new-real-payment`;

  static uploadRealPayments = (towerId) =>
    `${API_PATH}wallet-transaction/upload-template/${towerId}`;

  static updateMonthsRecovery = (towerId) =>
    `${API_PATH}wallet-transaction/months-recovery/${towerId}`;

  static getMonthsRecovery = (towerId) =>
    `${API_PATH}wallet-transaction/months-recovery/${towerId}`;

  static getQuotationSummaryWithRealPayments = (towerId, propertyId) =>
    `${API_PATH}wallet-transaction/quotation-summary-with-real-payments/tower/${towerId}/property/${propertyId}`;

  static getQuotationExcelSummaryWithRealPayments = (towerId, propertyId) =>
    `${API_PATH}wallet-transaction/quotation-summary-with-real-payments/excel/tower/${towerId}/property/${propertyId}`;
}

export default GeneralWalletServicesDefinitions;
