import React from 'react';
import useOverwriteCut from '../../../../../../Core/customHooks/useOverwriteCut';
import ReactTooltip from 'react-tooltip';
import IconButton from '../../../../../../../../shared/components/IconButton/IconButton';
import { Typography } from '@material-ui/core';
import useCutsControlBandCell from '../../../../../../Core/customHooks/useCutsControlBandCell'
import styles from '../BandCell.module.scss';

const CutsControlBandCell = ({ children, column }) => {
  const emptyCut = 'Corte Vacío';

  const {
    onSubmitHandler,
    towerWithoutCuts,
    cutDate,
  } = useOverwriteCut();

  const {cutsHandleClick} = useCutsControlBandCell();
  
  return (
    <div className={styles.bandCellContainer}>
      <strong className={styles.CutTypography}>{children}</strong>
      <IconButton
        data-tip
        data-for={`emptyCutControl`}
        className={`${
          column && column.title === emptyCut
            ? styles.iconWithoutCut
            : styles.iconWithCut
        }`}
        onClick={() => {
          cutsHandleClick({cutDate, onSubmitHandler, towerWithoutCuts})
        }}
        iconName="content_copy"
        variant="fill"
        size="xsmall"
      />
      {!towerWithoutCuts && (
        <ReactTooltip
          type="dark"
          id={`emptyCutControl`}
          effect="solid"
          opensOn="true"
        >
          <Typography className={styles.tooltipTypography}>Copiar corte actual</Typography>
        </ReactTooltip>
      )}
    </div>
  );
};

export default CutsControlBandCell;
