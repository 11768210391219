import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Box, Button, Popover, Typography } from '@material-ui/core';
import classes from '../styles/SalesRoomTableRowCustomCell.module.scss';
import Numbers from '../../../../helpers/numbers';

const CustomCell = (props) => {
  const { tableRow, tableColumn } = props;

  let className = {};

  const { row } = tableRow;
  const dataKey = tableColumn.column.name;
  const cellData = row[tableColumn.column.name];
  const { selectedData } = cellData || '';
  const priceOfTooltip =
    cellData && cellData.status === 'SOLD'
      ? cellData.soldProperty
      : cellData && cellData.availableProperty;

  if (!cellData) {
    return <VirtualTable.Cell className={classes.emptyCell} {...props} />;
  }

  const onClickFunction = cellData.onClick;

  className = {
    ...className,
    [classes.available]: cellData.status === 'AVAILABLE',
    [classes.sold]: cellData.status === 'SOLD',
    [classes.optional]: cellData.status === 'OPTIONAL',
    [classes.clientBadge]: cellData.clientBadge === true,
  };

  if (dataKey === 'properties') {
    return (
      <VirtualTable.Cell className={classes.floorCell}>
        <Typography variant="body2" className={classes.floor}>
          {cellData.typeSelected}
        </Typography>
      </VirtualTable.Cell>
    );
  }

  const typography = {
    [classes.availableColor]: cellData.status === 'AVAILABLE',
    [classes.soldColor]: cellData.status === 'SOLD',
    [classes.optionalColor]: cellData.status === 'OPTIONAL',
    [classes.clientBadgeColor]: cellData.clientBadge === true,
    [classes.groupSelected]: cellData.groupSelected,
  };

  const numberFormat = {
    [classes.numberFormatAvailableColor]: cellData.status === 'AVAILABLE',
    [classes.numberFormatSoldColor]: cellData.status === 'SOLD',
    [classes.numberFormatOptionalColor]: cellData.status === 'OPTIONAL',
    [classes.numberFormatClientBadgeColor]: cellData.clientBadge === true,
  };

  const badgeDesist = {
    [classes.badgeDesist]: cellData.requestStatus === 'D',
  };

  if (cellData.isNumber) {
    return (
      <VirtualTable.Cell className={classes.virtualCell}>
        <Button
          data-tip
          data-for={`typographyToolTip ${cellData.id}`}
          className={classes.buttonCell}
          onClick={() => {
            onClickFunction(cellData);
          }}
        >
          <Box className={clsx(className)} />
          {cellData.requestStatus === 'D' && (
            <Box className={clsx(badgeDesist)}> D </Box>
          )}
          <Typography variant="body2" className={clsx(typography)}>
            {cellData.typeSelected}
          </Typography>
        </Button>
        <ReactTooltip type="info" id={`typographyToolTip ${cellData.id}`}>
          {selectedData === 'name'
            ? Numbers.currencyFormat(priceOfTooltip)
            : cellData.name}
        </ReactTooltip>
      </VirtualTable.Cell>
    );
  }

  return (
    <VirtualTable.Cell className={classes.virtualCell}>
      <Button
        data-tip
        data-for={`numberFormatToolTip ${cellData.id}`}
        className={classes.buttonCell}
        onClick={() => {
          onClickFunction(cellData);
        }}
      >
        <Box className={clsx(className)} />
        {cellData.requestStatus === 'D' && (
          <Box className={clsx(badgeDesist)}> D </Box>
        )}
        <NumberFormat
          displayType="text"
          value={cellData.typeSelected}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          prefix="$"
          className={clsx(numberFormat)}
        />
      </Button>
      <ReactTooltip type="info" id={`numberFormatToolTip ${cellData.id}`}>
        {selectedData === 'name'
          ? Numbers.currencyFormat(priceOfTooltip)
          : cellData.name}
      </ReactTooltip>
    </VirtualTable.Cell>
  );
};

CustomCell.propTypes = {
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
};

export default CustomCell;
