import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Tooltip,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from '@material-ui/core';
import DayPickerInput from 'react-date-picker';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Card, { CardHeader, CardBody } from '../../UI/Card/Card';
import Styles from './SalesDateRange.module.scss';
import Input from '../../UI/Input/Input';
import BalancePointType from './BalancePointType';
import useBalancePointDateChange from './useBalancePointDateChange';
import useStages from './customHooks/useStages';
import debouncedInput from '../../UI/TextFields/debouncedInput';

const DebouncedNumberFormat = debouncedInput(NumberFormat, { timeout: 600 });

const displacementValidation = () => [
  {
    fn: (value) => value >= 0,
    message: 'Debe ser mayor 0',
  },
  ...displacementValidationMaxMin(),
];

const displacementValidationMaxMin = () => [
  {
    fn: (value) => value <= 100,
    message: 'El desplazamiento es demasiado grande',
  },
  {
    fn: (value) => value >= -100,
    message: 'El desplazamiento es demasiado pequeño',
  },
];

const displacementValidationForNegative = () => [
  {
    fn: (value) => value <= 1,
    message: 'No debe ser mayor a cero',
  },
  ...displacementValidationMaxMin(),
];

const ContainerItem = ({ title, children, ...rest }) => {
  return (
    <Grid item container spacing={1}>
      <Grid item xs={3}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={5} {...rest}>
        {children}
      </Grid>
    </Grid>
  );
};

function SalesDateRange({
  endOfSalesDate,
  salesStartDate,
  averageDeliveryDate,
  balancePointDate,
  constructionStartDate,
  maximumCollectionDate,
  salesStartDateHandler,
  endOfSalesDateHandler,
  maximumCollectionDateHandler,
  constructionStartDateHandler,
  averageDeliveryDateHandler,
  balancePointDateHandler,
  updateScheduleData,
  balancePointType,
  balancePercentage,
  startStageDate,
  endStageDate,
  isAuthorizedForEdit,
}) {
  const { type, percentage, balanceDate, onChangeType, onChangePercentage } =
    useBalancePointDateChange(
      balancePointType,
      balancePercentage,
      balancePointDate,
      updateScheduleData,
    );

  const {
    startStage,
    endStage,
    startStageDisplacement,
    endStageDisplacement,
    onSetStartStage,
  } = useStages(startStageDate, endStageDate);

  const showText = (text) => <span>{text}</span>;

  return (
    <Card className={Styles.schedulesContainer}>
      <CardHeader>
        <span>Fechas de ventas</span>
      </CardHeader>
      <CardBody>
        <div className={Styles.wrapper}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={6} direction="column">
              <ContainerItem
                className={Styles.containerItem}
                title="Fecha de Corte:"
              >
                {isAuthorizedForEdit ? (
                  <DayPickerInput
                    className={Styles.Picker}
                    clearIcon={null}
                    format="MM/yyyy"
                    locale={'es'}
                    value={moment().toDate()}
                    disabled
                    disableCalendar
                  />
                ) : (
                  showText(moment().format('MM/YYYY').toString())
                )}
              </ContainerItem>
              <ContainerItem
                className={Styles.containerItem}
                title="Inicio de etapa:"
              >
                {isAuthorizedForEdit ? (
                  <DatePicker
                    openTo="year"
                    format="MM/YYYY"
                    label=""
                    disabled
                    className={Styles.Picker}
                    lang="es"
                    views={['year', 'month']}
                    value={moment(Number(startStage)).toDate()}
                    onChange={(date) => {
                      onSetStartStage(salesStartDate.valueOf());
                    }}
                  />
                ) : (
                  showText(
                    moment(Number(startStage)).format('MM/YYYY').toString(),
                  )
                )}
                {isAuthorizedForEdit && (
                  <Grid item>
                    <Input
                      removeTextAlign
                      style={{ textAlign: 'left', width: '150px' }}
                      validations={displacementValidationForNegative()}
                      value={moment(Number(startStage)).diff(
                        moment(Number(salesStartDate)),
                        'month',
                      )}
                      onChange={(e) =>
                        startStageDisplacement(e, salesStartDate.valueOf())
                      }
                    />
                    <div className={Styles.label}>
                      Desplazamiento
                      <Tooltip title="Desplazamiento en meses debe ser negativo para restar meses">
                        <span className={Styles.Badge}>?</span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}
              </ContainerItem>
              <ContainerItem
                className={Styles.containerItem}
                title="Inicio de Pre-Ventas:"
              >
                {isAuthorizedForEdit ? (
                  <DatePicker
                    openTo="year"
                    format="MM/YYYY"
                    label=""
                    views={['year', 'month']}
                    value={moment(Number(salesStartDate)).toDate()}
                    onChange={(date) => {
                      salesStartDateHandler(date.valueOf());
                      onSetStartStage(date.valueOf());
                    }}
                  />
                ) : (
                  showText(
                    moment(Number(salesStartDate)).format('MM/YYYY').toString(),
                  )
                )}
              </ContainerItem>
              <ContainerItem
                className={Styles.containerItem}
                title="Punto de equilibrio:"
              >
                <Grid item className={Styles.SelectContainer}>
                  <Grid container item spacing={1} direction="row">
                    <Grid item>
                      <FormControl className={Styles.Picker}>
                        {isAuthorizedForEdit && <InputLabel>Tipo</InputLabel>}
                        {isAuthorizedForEdit ? (
                          <Select value={type} onChange={onChangeType}>
                            <MenuItem value={BalancePointType.MANUAL.code}>
                              Manual
                            </MenuItem>
                            <MenuItem value={BalancePointType.PERCENTAGE.code}>
                              Porcentaje
                            </MenuItem>
                          </Select>
                        ) : (
                          showText(
                            `Tipo ${
                              type === 'M'
                                ? BalancePointType.MANUAL.value
                                : BalancePointType.PERCENTAGE.value
                            }`,
                          )
                        )}
                      </FormControl>
                    </Grid>
                    {type === BalancePointType.PERCENTAGE.code &&
                      isAuthorizedForEdit && (
                        <Grid item>
                          <DebouncedNumberFormat
                            label="Porcentaje"
                            variant="outlined"
                            value={percentage}
                            customInput={TextField}
                            suffix={'%'}
                            onValueChange={onChangePercentage}
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item>
                  {isAuthorizedForEdit ? (
                    <DatePicker
                      openTo="year"
                      format="MM/YYYY"
                      label=""
                      lang="es"
                      views={['year', 'month']}
                      disabled={type === BalancePointType.PERCENTAGE.code}
                      value={moment(Number(balanceDate)).toDate()}
                      onChange={(date) => {
                        balancePointDateHandler(date.valueOf());
                      }}
                    />
                  ) : (
                    showText(
                      moment(Number(balanceDate)).format('MM/YYYY').toString(),
                    )
                  )}
                </Grid>
              </ContainerItem>
              <ContainerItem
                className={Styles.containerItem}
                title="Inicio de Construcción:"
                container
                direction="column"
              >
                <Grid item>
                  {isAuthorizedForEdit ? (
                    <DayPickerInput
                      clearIcon={null}
                      format="MM/yyyy"
                      locale={'es'}
                      className={Styles.Picker}
                      value={moment(Number(constructionStartDate)).toDate()}
                      onChange={(date) => {
                        salesStartDateHandler(date.getTime());
                      }}
                      disabled
                      disableCalendar
                    />
                  ) : (
                    showText(
                      moment(Number(constructionStartDate))
                        .format('MM/YYYY')
                        .toString(),
                    )
                  )}
                </Grid>
                {isAuthorizedForEdit && (
                  <Grid item>
                    <Input
                      removeTextAlign
                      style={{ textAlign: 'left', width: '150px' }}
                      validations={displacementValidation()}
                      value={moment(Number(constructionStartDate)).diff(
                        moment(Number(balanceDate)),
                        'month',
                      )}
                      onChange={(target) => {
                        constructionStartDateHandler(target.value);
                      }}
                    />
                    <div className={Styles.label}>
                      Desplazamiento
                      <Tooltip title="Desplazamiento en meses respecto a la fecha de punto de equilibrio">
                        <span className={Styles.Badge}>?</span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}
              </ContainerItem>
              <ContainerItem
                className={Styles.containerItem}
                title="Fin de la Construcción:"
                container
                direction="column"
              >
                {isAuthorizedForEdit ? (
                  <Grid item>
                    <DayPickerInput
                      className={Styles.Picker}
                      clearIcon={null}
                      format="MM/yyyy"
                      locale={'es'}
                      value={moment(Number(endOfSalesDate)).toDate()}
                      disabled
                      disableCalendar
                    />
                  </Grid>
                ) : (
                  showText(
                    moment(Number(endOfSalesDate)).format('MM/YYYY').toString(),
                  )
                )}
                {isAuthorizedForEdit && (
                  <Grid item>
                    <Input
                      removeTextAlign
                      style={{ textAlign: 'left', width: '150px' }}
                      validations={displacementValidation()}
                      value={
                        moment(Number(endOfSalesDate)).diff(
                          moment(Number(constructionStartDate)),
                          'month',
                        ) + 1
                      }
                      onChange={(target) => {
                        endOfSalesDateHandler(target.value);
                      }}
                    />
                    <div className={Styles.label}>
                      Desplazamiento
                      <Tooltip title="Desplazamiento en meses respecto a la fecha de inicio de la construcción">
                        <span className={Styles.Badge}>?</span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}
              </ContainerItem>
              <ContainerItem
                className={Styles.containerItem}
                title="Fin de etapa:"
              >
                {isAuthorizedForEdit ? (
                  <DatePicker
                    openTo="year"
                    format="MM/YYYY"
                    label=""
                    disabled
                    disableCalendar
                    lang="es"
                    views={['year', 'month']}
                    value={moment(Number(endStage)).toDate()}
                  />
                ) : (
                  showText(
                    moment(Number(endStage)).format('MM/YYYY').toString(),
                  )
                )}
                {isAuthorizedForEdit && (
                  <Grid item>
                    <Input
                      removeTextAlign
                      style={{ textAlign: 'left', width: '150px' }}
                      validations={displacementValidation()}
                      value={
                        moment(Number(averageDeliveryDate)).diff(
                          moment(Number(endStage)),
                          'month',
                        ) < 0
                          ? moment(Number(averageDeliveryDate)).diff(
                              moment(Number(endStage)),
                              'month',
                            ) * -1
                          : moment(Number(averageDeliveryDate)).diff(
                              moment(Number(endStage)),
                              'month',
                            )
                      }
                      onChange={(e) =>
                        endStageDisplacement(e, averageDeliveryDate)
                      }
                    />
                    <div className={Styles.label}>
                      Desplazamiento
                      <Tooltip title="Desplazamiento en meses respecto a la fecha de promedio de entrega">
                        <span className={Styles.Badge}>?</span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}
              </ContainerItem>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      </CardBody>
    </Card>
  );
}

SalesDateRange.propTypes = {
  salesStartDate: PropTypes.number,
  endOfSalesDate: PropTypes.number,
  averageDeliveryDate: PropTypes.number,
  balancePointDate: PropTypes.number,
  constructionStartDate: PropTypes.number,
  maximumCollectionDate: PropTypes.number,
  salesStartDateHandler: PropTypes.func,
  maximumCollectionDateHandler: PropTypes.func,
  endOfSalesDateHandler: PropTypes.func,
  constructionStartDateHandler: PropTypes.func,
  averageDeliveryDateHandler: PropTypes.func,
  balancePointDateHandler: PropTypes.func,
  updateScheduleData: PropTypes.func,
  isAnySold: PropTypes.bool,
  balancePointType: PropTypes.any,
  balancePercentage: PropTypes.number,
};

SalesDateRange.defaultProps = {
  salesStartDate: new Date().getTime(),
  endOfSalesDate: new Date().getTime(),
  averageDeliveryDate: new Date().getTime(),
  balancePointDate: new Date().getTime(),
  constructionStartDate: new Date().getTime(),
  balancePointType: BalancePointType.MANUAL.code,
  balancePercentage: 0,
  salesStartDateHandler: () => null,
  endOfSalesDateHandler: () => null,
  constructionStartDateHandler: () => null,
  averageDeliveryDateHandler: () => null,
  balancePointDateHandler: () => null,
};

export default SalesDateRange;
