import React, { FC } from 'react';
import { Table } from '@devexpress/dx-react-grid';
import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import MonthlyInterestTableCell from '../cells/MonthlyInterestTableCell';
import { ConvertColumnsToColumnBandsFunction, ParentColumnForBand } from '../../../../shared/tables/Core/TableHelpers';

interface TableMonthlyInterestConnectorProps extends TableFixedColumns.CellProps {
  findParentFromColumnName: ConvertColumnsToColumnBandsFunction;
  name: string;
}

const TableMonthlyInterestConnector: FC<TableMonthlyInterestConnectorProps> = ({
  findParentFromColumnName,
  name,
  ...params
}) => {
  return (
    <TemplateConnector>
      {({ getCellValue }) => {
        const columnName = params.tableColumn.column!.name;
        const value = params.tableRow.row ? getCellValue(params.tableRow.row, columnName) : '';

        return (
          <TemplatePlaceholder
            name={name}
            params={{
              value,
              row: params.tableRow.row,
              column: params.tableColumn.column,
            }}
          >
            {(content) => (
              <MonthlyInterestTableCell
                params={params}
                value={value}
                findParentFromColumnName={findParentFromColumnName}
              >
                {content}
              </MonthlyInterestTableCell>
            )}
          </TemplatePlaceholder>
        );
      }}
    </TemplateConnector>
  );
};

export default TableMonthlyInterestConnector;
