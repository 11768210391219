import React, { FC, useState } from 'react';
import NumberFormat from 'react-number-format';

import {
  Box,
  Toolbar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Card,
  Avatar,
  Chip,
  Collapse,
  List,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Styles from '../../styles/ContractPaymentListGrid.module.scss';
import { ContractPayment, ContractPaymentSchedule } from '../../../../../models/ContractPayments';

import { Button } from '../../../../../shared/components/Button/Button';
import Typography from '../../../../../shared/components/Typography/Typography';

import ContractPaymentDescription from './ContractPaymentDescription';
import ScheduleByPaymentGrid from './ScheduleByPaymentGrid';
import useScheduleByPayment from '../../../Core/customHooks/useScheduleByPayment';
import useMachineForContractPaymentsSchedule from '../../../Core/customHooks/useMachineForContractPaymentsSchedule';
import ContractPaymentsScheduleFormMainView from '../../../../ContractPaymentScheduleFormModal/UI/ContractPaymentScheduleFormMainView';
import ContractPaymentListGridMenu from './ContractPaymentListGridMenu';

interface IContractPaymentListGrid {
  handleEdit: () => void;
  handleDelete: () => void;
  contractPayment: ContractPayment;
}

export const ContractPaymentListGrid: FC<IContractPaymentListGrid> = ({
  contractPayment,
  handleEdit,
  handleDelete,
}) => {
  const { disabledCancel, disabledSave, send } = useMachineForContractPaymentsSchedule();
  const {
    currentContractPaymentSchedules,
    openContractPaymentScheduleForm,
    setOpenContractPaymentScheduleForm,
    currentContractPaymentSchedule,
    setCurrentContractPaymentSchedule,
    handlePaymentUpdate,
    handleSaveContractPaymentsGrid,
    handleResetContractPaymentsGrid,
    expanded,
    setExpanded,
    expandable,
  } = useScheduleByPayment({ contractPayment, sendMachineState: send });

  return (
    <Box mb={2}>
      <Card>
        <List className={Styles.noPadding}>
          <ListItem>
            <ListItemAvatar>
              <Avatar aria-label="recipe" className={Styles.bgColorSecondary}>
                {contractPayment.type}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={contractPayment.typeDescription}
              secondary={
                <React.Fragment>
                  <ContractPaymentDescription contractPayment={contractPayment} /> <br />
                  <br />
                  <Typography variant="body2" color="gray500">
                    {contractPayment.name}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <ContractPaymentListGridMenu handleEdit={handleEdit} handleDelete={handleDelete} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button={expandable as any}
            classes={{
              root: expandable ? Styles.menuItem : '',
              selected: expandable ? Styles.menuItemSelected : '',
            }}
            onClick={() => (expandable ? setExpanded(!expanded) : null)}
          >
            {expandable ? (
              <>
                <ListItemIcon>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ListItemIcon>
                <ListItemText primary={expanded ? 'Ocultar' : 'Ver detalle'} />
              </>
            ) : (
              <ListItemText primary="" />
            )}
            <Box p={1}>
              {contractPayment.type !== 'PX' && contractPayment.type !== 'PR' && (
                <Box component="span" mr={1}>
                  <Chip
                    size="small"
                    className={Styles.bgColorSecondary}
                    clickable={false}
                    label={
                      <NumberFormat
                        thousandSeparator
                        suffix={` cuotas`}
                        displayType="text"
                        value={contractPayment.contractPaymentSchedules.length || 0}
                      />
                    }
                  />
                </Box>
              )}
              <Box component="span">
                <Chip
                  size="small"
                  clickable={false}
                  label={
                    <NumberFormat
                      className={Styles.amount}
                      value={contractPayment.total || 0}
                      displayType="text"
                      thousandSeparator
                      prefix={`Total $`}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  }
                />
              </Box>
            </Box>
          </ListItem>
        </List>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box>
            <ScheduleByPaymentGrid
              contractPaymentSchedules={currentContractPaymentSchedules}
              contractPayment={contractPayment}
              onSeleccionChange={(contractPaymentSchedule: ContractPaymentSchedule) => {
                setCurrentContractPaymentSchedule(contractPaymentSchedule);
                setOpenContractPaymentScheduleForm(true);
              }}
            />
            <Toolbar variant="dense" className={Styles.toolbar}>
              {contractPayment.type !== 'PX' && (
                <Box m={2}>
                  <Box component="span" mr={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={disabledCancel}
                      onClick={() => {
                        handleResetContractPaymentsGrid();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Box component="span">
                    <Button
                      variant="contained"
                      size="small"
                      disabled={disabledSave}
                      onClick={() => {
                        handleSaveContractPaymentsGrid();
                      }}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              )}
            </Toolbar>
          </Box>
        </Collapse>
      </Card>
      <ContractPaymentsScheduleFormMainView
        open={openContractPaymentScheduleForm}
        currentContractPaymentId={contractPayment.id}
        currentContractId={contractPayment.contractId || 0}
        contractPaymentType={contractPayment.type}
        currentContractPaymentSchedule={currentContractPaymentSchedule}
        handlePaymentUpdate={handlePaymentUpdate}
        handleClose={() => {
          setCurrentContractPaymentSchedule(undefined);
          setOpenContractPaymentScheduleForm(false);
        }}
        handleCreate={() => {}}
      />
    </Box>
  );
};
