import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import ContractPaymentScheduleGridNumberFormatter from './ContractPaymentScheduleGridNumberFormatter';

const TableCellNonEditing = TableCellNonEditingComponent(ContractPaymentScheduleGridNumberFormatter);

const ContractPaymentScheduleGridProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractPaymentScheduleGridNumberFormatter}
    editorComponent={TableCellNonEditing}
    {...props}
  />
);

export default ContractPaymentScheduleGridProvider;
