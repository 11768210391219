import { DataTypeProvider } from '@devexpress/dx-react-grid';
import React from 'react';
import NumberFormat from 'react-number-format';

import GeneralWalletCellUtils from '../../core/GeneralWalletCellUtils';
import stylesComponents from '../styles/components.module.scss';

const utils = new GeneralWalletCellUtils(stylesComponents);

const GeneralWalletNumberFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value, column }) => (
  <NumberFormat
    className={utils.getClassName(value, column?.name)}
    displayType="text"
    value={value}
    thousandSeparator
    decimalScale={0}
    fixedDecimalScale
    prefix="$"
  />
);

export default GeneralWalletNumberFormatter;
