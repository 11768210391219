export const API_LOADING_COMPONENT = 'API_LOADING_COMPONENT';
export const SET_FETCH_CLIENTS = 'SET_FETCH_CLIENTS';
export const SET_FETCH_PROPERTIES = 'SET_FETCH_PROPERTIES';
export const SET_MAX_LENGTH_AT_CLIENTS = 'SET_MAX_LENGTH_AT_CLIENTS';
export const SET_TOWER_ID = 'SET_TOWER_ID';
export const SET_ALL_CLIENTS = 'SET_ALL_CLIENTS';

export const setApiLoading = (payload) => ({
  type: API_LOADING_COMPONENT,
  payload,
});

export const setClients = (payload) => ({
  type: SET_FETCH_CLIENTS,
  payload,
});

export const setProperties = (payload) => ({
  type: SET_FETCH_PROPERTIES,
  payload,
});

export const setMaxClientLength = (payload) => ({
  type: SET_MAX_LENGTH_AT_CLIENTS,
  payload,
});

export const setTowerId = (payload) => ({
  type: SET_TOWER_ID,
  payload,
});

export const setAllClients = (payload) => ({
  type: SET_ALL_CLIENTS,
  payload,
});
