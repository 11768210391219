import { ModalProps } from '@material-ui/core';
import { MouseEventHandler, useState } from 'react';

function useSalesSpeedHelperPhasesDetail() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDetailClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDetailClose: ModalProps['onClose'] = () => {
    setAnchorEl(null);
  };

  return { anchorEl, handleDetailClick, handleDetailClose };
}

export default useSalesSpeedHelperPhasesDetail;
