import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import useModalAreaTypeForm from 'App/Area/Core/customHooks/useModalAreaTypeForm';
import { AreasModalFormProps } from 'App/Area/Core/interfaces/AreasModalFormProps';
import Message from 'shared/components/Message/Message';
import { Button } from 'shared/components/Button/Button';
import Modal from 'shared/components/Modal/Modal';
import ModalActions from 'shared/components/ModalActions/ModalActions';
import ModalTitle from 'shared/components/ModalTitle/ModalTitle';
import TextField from 'shared/components/TextField/TextField';
import styles from './ModalAreaType.module.scss';
import { sanitizeString } from 'helpers/HelperString';

const getTitle = (isEditing: boolean) => (isEditing ? 'Editar área' : 'Nueva área');

const getSaveButtonText = (isEditing: boolean) => (isEditing ? 'Guardar' : 'Crear');

const ModalAreaType: FC<AreasModalFormProps> = (props) => {
  const { form, existsAreaType, close } = useModalAreaTypeForm(props);
  const { data, errors, handleChange } = form;
  return (
    <Modal open={props.isOpen} aria-labelledby="modal-areas-editor" onClose={close}>
      <form onSubmit={form.handleSubmit} noValidate>
        <ModalTitle id="modal-areas-editor" disabled={false} onClickClose={close}>
          {getTitle(!!props.formData?.id)}
        </ModalTitle>
        <div className={styles.content}>
          <TextField
            className={styles.nameField}
            size="medium"
            value={data.name}
            placeholder="Nombre de área"
            required
            autoFocus
            onChange={handleChange('name', sanitizeString)}
            error={!!errors.name}
            helperText={errors.name}
          />
          {existsAreaType && (
            <Box marginTop={2}>
              <Message text="Ya exíste un área con este nombre" closable state="warning" size="small" />
            </Box>
          )}
        </div>
        <ModalActions>
          <Button variant="outlined" size="small" disabled={false} onClick={close}>
            Cancelar
          </Button>
          <Button className={styles.saveButton} variant="contained" size="small" type="submit" disabled={false}>
            <>{getSaveButtonText(!!props.formData?.id)}</>
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default ModalAreaType;
