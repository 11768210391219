import { MouseEventHandler, ReactNode } from 'react';

export enum AlertVariants {
  Accept = 'accept',
  AcceptOnly = 'acceptOnly',
  Destructive = 'destructive',
  Continue = 'continue',
}

export default interface ModalProps {
  title: string;
  isOpen: boolean;
  variant?: AlertVariants;
  children?: ReactNode;
  acceptAction?: MouseEventHandler<HTMLButtonElement>;
  cancelAction?: MouseEventHandler<HTMLButtonElement>;
}
