import React from 'react';

import { PillProps } from '../../interfaces/pill.types';
import Typography from '../Typography/Typography';
import usePillColor from './usePillColor';
import styles from './Pill.module.scss';

const Pill: React.FC<PillProps> = ({ label, color, children }) => {
  const { pillElement } = usePillColor(color);

  return (
    <div className={styles.root} ref={pillElement}>
      {label ? (
        <Typography className={styles.label} variant="caption" color="gray000" fontWeight="500">
          {label}
        </Typography>
      ) : (
        children
      )}
    </div>
  );
};

export default Pill;
