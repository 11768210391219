import Services from '../../../services/services';
import MacroeconomicServicesDefinition from './MacroeconomicServicesDefinition';

class MacroeconomicServices extends Services { 
  getMacroeconomic(projectId){
    return this.get(MacroeconomicServicesDefinition.macroeconomicId(projectId));
  }

  putMacroeconomic(projectId, data){
    return this.put(MacroeconomicServicesDefinition.macroeconomicUpdate(projectId), data);
  }
}

export default MacroeconomicServices;