import React, { FC } from 'react';
import moment from 'moment';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { GeneralWalletThreadInteractionDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import Typography from 'shared/components/Typography/Typography';
import { useGeneralWalletThreadContext } from 'App/GeneralWalletDetails/core/contexts/GeneralWalletThreadContext';

import styles from './GeneralWalletDetailsThread.module.scss';

const GeneralWallletDetailsThreadInteractionFile: FC<GeneralWalletThreadInteractionDTO> = (interaction) => {
  const { user, createdAt, urlAttachedFileURL, urlAttachedFileName, isEditable } = interaction;
  const { onClickConfirmRemoveInteraction } = useGeneralWalletThreadContext();
  return (
    <div className={styles.ThreadInteractionFile}>
      <div className={styles.ThreadInteractionHeader}>
        <div>
          <Typography variant="subtitle1">{user.name} </Typography>
          <Typography variant="caption" fontSize="1em">
            - agregó un adjunto
          </Typography>
        </div>
        <div>
          <Typography variant="caption">{moment(createdAt).format('DD [de]  [de] YYYY')}</Typography>
        </div>
      </div>
      <div className={styles.ThreadInteractionFileAttachedContainer}>
        <a className={styles.ThreadInteractionFileAttached} download href={urlAttachedFileURL} target="_blank">
          <InsertDriveFileOutlined className={styles.ThreadInteractionFileAttachedIcon} />
          <Typography>{urlAttachedFileName}</Typography>
        </a>
        {isEditable && (
          <IconButton onClick={() => onClickConfirmRemoveInteraction(interaction)}>
            <Close />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default GeneralWallletDetailsThreadInteractionFile;
