import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import TableCellDateFormat from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellDateFormat';

const TableCellNonEditing = TableCellNonEditingComponent(TableCellDateFormat);

const ContractsEvalproGridDateProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={TableCellDateFormat} editorComponent={TableCellNonEditing} {...props} />
);

export default ContractsEvalproGridDateProvider;
