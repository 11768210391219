import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SalesSpeedHelperServices from '../../services/SalesSpeedHelperServices';
import SalesSpeedHelperDTO from '../DTO/SalesSpeedHelperDTO';
import SalesSpeedDataStatus from '../enums/SalesSpeedDataStatus';
import SalesSpeedHelperStates from '../enums/SalesSpeedHelperStates';
import { OnSalesSpeedDataChange } from '../types/SalesSpeedHelper.types';

const salesSpeedHelperServices = new SalesSpeedHelperServices();

const defaultData = {
  status: SalesSpeedDataStatus.Empty,
};

type RouteParams = {
  towerId: string;
};

function useSalesSpeedHelperLoader() {
  const { towerId } = useParams<RouteParams>();
  const [viewState, setViewState] = useState<SalesSpeedHelperStates>(SalesSpeedHelperStates.Loading);
  const [salesSpeedData, setSalesSpeedData] = useState<SalesSpeedHelperDTO>(defaultData);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await salesSpeedHelperServices.getViewData(towerId);
        setSalesSpeedData(data);
        setViewState(SalesSpeedHelperStates[data.status]);
      } catch (error) {
        const message = (error as Error).message;
        enqueueSnackbar(message, { variant: 'error' });
        setViewState(SalesSpeedHelperStates.Error);
      }
    };

    fetchData();
  }, []);

  const handleSpeedDataChange: OnSalesSpeedDataChange = (newData) => {
    setSalesSpeedData(newData);
    setViewState(SalesSpeedHelperStates[newData.status]);
  };

  return { viewState, salesSpeedData, handleSpeedDataChange };
}

export default useSalesSpeedHelperLoader;
