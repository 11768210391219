import React, { FC } from 'react';
import { Table } from '@devexpress/dx-react-grid';
import useMapperForDataTypeProvider from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/customHooks/useMapperForDataTypeProvider';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import { columnsForMonthlyInterestTable } from '../../core/customHooks/useMonthlyInterestTableRows';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { MonthlyPaymentRow } from '../../core/DTO/WalletMonthlyReportDTO';
import Styles from './MonthlyInterestCellContentUpdater.module.scss';
import { Checkbox } from '@material-ui/core';
import { MonthlyInterestOnValueChange } from 'App/MonthlyInterestReport/core/customHooks/useMonthlyInterestValueChanged';
import useMonthlyInterestCellUpdater from '../../core/customHooks/useMonthlyInterestCellUpdater';
import { Numbers } from 'helpers';

export interface MonthlyInterestCellContentUpdaterProps {
  column: VirtualTableDataTypeProviderColumn;
  row: MonthlyPaymentRow;
  value: number;
  onValueChanged: MonthlyInterestOnValueChange;
}

const MonthlyInterestCellContentUpdater: FC<MonthlyInterestCellContentUpdaterProps> = ({
  column,
  row,
  value,
  onValueChanged,
}) => {
  const { providerComponent } = useMapperForDataTypeProvider(column ? [column] : []);
  const FormattedComponent = providerComponent(column.type ?? ProviderColumnType.plain);
  const { isChecked, toggleCheck, customValue } = useMonthlyInterestCellUpdater(row);

  return (
    <div className={Styles.container}>
      <FormattedComponent column={column} row={row} value={customValue}>
        {Numbers.currencyFormat(customValue)}
      </FormattedComponent>
      <Checkbox
        color="primary"
        checked={isChecked}
        className={Styles.check}
        onChange={() => {
          onValueChanged(row);
          toggleCheck();
        }}
      />
    </div>
  );
};

export default MonthlyInterestCellContentUpdater;
