import clsx from 'clsx';
import React from 'react';

import { SalesSpeedHelperPayload } from '../../../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperStates from '../../../core/enums/SalesSpeedHelperStates';
import SalesSpeedHelperSidenavFaseSummary from '../SalesSpeedHelperSidenavFaseSummary/SalesSpeedHelperSidenavFaseSummary';
import SalesSpeedHelperSidenavGlobalValue from '../SalesSpeedHelperSidenavGlobalValue/SalesSpeedHelperSidenavGlobalValue';

import styles from './SalesSpeedHelperSidenav.module.scss';

const getPercent = (value: number): string => `${value * 100}%`;

const getPercentWithSymbol = (value?: number): string | undefined => (value ? getPercent(value) : undefined);

type Props = {
  className?: string;
  viewState: SalesSpeedHelperStates;
  salesSpeedPayload?: SalesSpeedHelperPayload;
};

const SalesSpeedHelperSidenav: React.FC<Props> = ({ className, viewState, salesSpeedPayload }) => (
  <div className={clsx(styles.root, className)}>
    <div className={styles.content}>
      <SalesSpeedHelperSidenavGlobalValue
        className={styles.globalValue}
        viewState={viewState}
        title="Total unidades"
        value={salesSpeedPayload?.totalUnits}
      />
      <SalesSpeedHelperSidenavGlobalValue
        className={styles.globalValue}
        viewState={viewState}
        title="Punto de equilibrio"
        value={getPercentWithSymbol(salesSpeedPayload?.balancePointPercent)}
      />
      <SalesSpeedHelperSidenavGlobalValue
        className={styles.globalValue}
        viewState={viewState}
        title="Punto equilibrio unidades"
        value={salesSpeedPayload?.balancePointUnits}
      />

      <SalesSpeedHelperSidenavFaseSummary
        className={styles.phaseSummary}
        viewState={viewState}
        title="Preventas"
        phaseSummary={salesSpeedPayload?.summary.preSales}
      />
      <SalesSpeedHelperSidenavFaseSummary
        className={styles.phaseSummary}
        viewState={viewState}
        title="Ventas"
        phaseSummary={salesSpeedPayload?.summary.sales}
      />
      <SalesSpeedHelperSidenavFaseSummary
        className={styles.phaseSummary}
        viewState={viewState}
        title="Total"
        phaseSummary={salesSpeedPayload?.summary.total}
      />
    </div>
  </div>
);

export default SalesSpeedHelperSidenav;
