import React from 'react';

import Typography from 'shared/components/Typography/Typography';

import styles from './SalesSpeedHelperSidenavFaseSummaryValue.module.scss';

type Props = {
  title: string;
  value?: number;
};

const SalesSpeedHelperSidenavFaseSummaryValue: React.FC<Props> = ({ title, value }) => (
  <div>
    <Typography variant="body1" as="div" color="gray600">
      {title}
    </Typography>
    <Typography className={styles.summaryValue} variant="subtitle1" as="div">
      {value}
    </Typography>
  </div>
);

export default SalesSpeedHelperSidenavFaseSummaryValue;
