import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Styles from './ProjectResultsStateHeader.module.scss';
import TypeSelector from '../../../../ResultsState/UI/views/TypeSelector';

const ProjectResultsStateHeader = () => {
  return (
    <Box className={Styles.headerContainer}>
      <Box className={Styles.titleAndSelectorContainer}>
        <Box className={Styles.titleAndHeaderContainer}>
          <Typography className={Styles.title}>Estado de resultados</Typography>
        </Box>
        <Box className={Styles.typeSelectorContainer}>
          <TypeSelector />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectResultsStateHeader;
