import { useQuery } from 'react-query';
import { EvalproDeedExpensesService } from 'App/DeedExpenses/services/EvalproDeedExpensesService';
import { EvalproDeedExpensesConfigResponse } from '../interfaces/EvalproDeedExpensesConfigResponse';
import { EVALPRO_DEED_EXPENSES_CONFIG } from '../reactQuery/RequestConstants';

const service = new EvalproDeedExpensesService();

const useFindEvalproDeedExpensesConfig = (towerId: string) => {
  const { data, isLoading } = useQuery<EvalproDeedExpensesConfigResponse>(
    EVALPRO_DEED_EXPENSES_CONFIG,
    async () => {
      const { data: result } = await service.getConfig(towerId);
      return result ?? {};
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return { config: data?.config, schedule: data?.schedule, isLoading };
};

export default useFindEvalproDeedExpensesConfig;
