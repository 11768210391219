import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import QuotationServices from '../../../../../../../../services/Quotations';
import Numbers from '../../../../../../../../helpers/numbers';
import { propertyHasCompletePriceForPlan } from '../../../../../../../../App/BuyerCostumers/Core/customHooks/useBuyerPropertyDetailWithQuotationsStates';
import { updateBuyerCostumerList } from '../../../../../../../../App/BuyerCostumers/Core/actions';

const services = new QuotationServices();

export const validateMissingPrice = (quotation, throwError = true) => {
  const propertyHasPaymentPlan = propertyHasCompletePriceForPlan(
    quotation,
    {
      priceWithAdditionalAreas: quotation.priceWithAdditionalAreas,
      discount: quotation.discount,
      tradeDiscount: Number(quotation.discountPrice),
    },
    quotation.finalPayment,
  );

  if (!throwError) {
    return propertyHasPaymentPlan;
  }

  if (!propertyHasPaymentPlan && throwError) {
    throw new Error(
      'Edita los Pagos! Recuerda asegurarte de que las cuotas concuerden con el Valor Cierre de Negocio',
    );
  }
};

export const validateRealPriceAndCurrentOne = (quotation) => {
  const realPriceAreas =
    quotation.propertyWithAreas.additionalAreasPrice +
    quotation.propertyWithAreas.basePriceValidated;

  const realCommercialDiscount = quotation.propertyWithAreas.discount;

  const additionalAreasPrice = quotation.priceWithAdditionalAreas;
  const commercialDiscount = quotation.discount;

  const realPriceSubtraction =
    Numbers.cleanNumber(realPriceAreas) -
    Numbers.cleanNumber(additionalAreasPrice);

  if (Math.abs(realPriceSubtraction) > 2) {
    return {
      message:
        ' Esta cotización no se puede asignar como forma de pago. \n 🤓 Verifica que la propiedad esté vendida con el mismo PRECIO ',
      isError: true,
    };
  }
  const discountSubtraction =
    Numbers.cleanNumber(realCommercialDiscount) -
    Numbers.cleanNumber(commercialDiscount);

  if (Math.abs(discountSubtraction) > 2) {
    return {
      message:
        ' Esta cotización no se puede asignar como forma de pago. \n 🤓 Verifica que la propiedad esté vendida con el mismo DESCUENTO ',
      isError: true,
    };
  }

  if (
    quotation.propertyWithAreas.originalAdditionalAreas &&
    quotation.additionalAreas
  ) {
    const isSameLength =
      quotation.propertyWithAreas.additionalAreasPrice ===
        quotation.propertyWithAreas.originalPriceForAdditionalAreas ||
      quotation.temp;

    if (!isSameLength) {
      return {
        message:
          ' Esta cotización no se puede asignar como forma de pago. \n 🤓 Verifica que la propiedad esté vendida con el mismo PRECIO de areas',
        isError: true,
      };
    }
  }

  if (quotation.isAssigned && !validateMissingPrice(quotation, false)) {
    return {
      message:
        'Cotización inválida, revise que las cuotas cumplan con el precio del apartamento',
      isError: true,
    };
  }

  return { isError: false, message: '' };
};

const useAssignmentQuotation = (fetchUserList, closeHandler) => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isConfirmDialogOpen, setConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const { clients } = useSelector((state) => state.buyerCostumers.root) || {};

  const onAssignmentQuotation = () => {
    setConfirmDialog(true);
  };

  const onCancelAssignment = () => {
    setConfirmDialog(false);
  };

  const updateClientsWithQuotation = (quotation) => {
    if (clients) {
      const clientsUpdated = clients.map((client) => ({
        ...client,
        properties: client.properties.map((property) => {
          const quotationAssignedIndex = property.quotations.findIndex(
            (quotationProperty) => quotationProperty.id === quotation.id,
          );
          const propertyCopy = { ...property };
          if (quotationAssignedIndex > -1) {
            propertyCopy.quotations[quotationAssignedIndex] = quotation;
          } else if (
            quotation.propertyId === property.id &&
            quotation.clientId === client.id
          ) {
            propertyCopy.quotations[propertyCopy.quotations.length] = quotation;
          }

          return propertyCopy;
        }),
      }));
      dispatch(updateBuyerCostumerList(clientsUpdated));
    }
  };

  const onConfirmAssignment = async (quotation, setIsOpen) => {
    setConfirmDialog(false);
    try {
      validateMissingPrice(quotation);
      validateRealPriceAndCurrentOne(
        quotation,
        '¡Error! El precio de esta cotización está desactualizado, verifica el precio y descuento de la propiedad',
      );
      const quotationAssigned = await services.putAssignment(quotation.id, {
        finalFee: Numbers.cleanNumber(quotation.finalPayment),
        reservationDeposit: Numbers.cleanNumber(quotation.reservePercentage),
        distribution: quotation.distribution.map(Numbers.cleanNumber),
        initialDate: quotation.initialDate,
        towerId,
      });
      setIsOpen(false);
      closeHandler(true);
      await fetchUserList();
      updateClientsWithQuotation(quotationAssigned.data);
      enqueueSnackbar('Cotización asignada correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return {
    isConfirmDialogOpen,
    onAssignmentQuotation,
    onConfirmAssignment,
    onCancelAssignment,
    updateClientsWithQuotation,
  };
};

export default useAssignmentQuotation;
