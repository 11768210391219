import { combineReducers } from 'redux';
import {
  API_FETCH,
  SET_PROPERTIES,
  SET_SALES_START_DATE,
  SET_TOWERID,
} from './actions';

const initialState = {
  properties: [],
  isLoading: true,
  towerId: null,
  salesStartDate: null,
};

const root = (state = initialState, { type, payload }) => {
  switch (type) {
    case API_FETCH:
      return { ...state, isLoading: payload };
    case SET_PROPERTIES:
      return { ...state, properties: payload };
    case SET_TOWERID:
      return { ...state, towerId: payload };

    case SET_SALES_START_DATE:
      return { ...state, salesStartDate: payload };

    default:
      return state;
  }
};

export default combineReducers({
  root,
});
