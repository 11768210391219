type UseCloseFeatureFieldEditor = {
  isSavingField: boolean;
  onClose: () => void;
};

function useCloseFeatureFieldEditor({ isSavingField, onClose }: UseCloseFeatureFieldEditor) {
  const handleCloseModal = () => {
    !isSavingField && onClose();
  };

  return handleCloseModal;
}

export default useCloseFeatureFieldEditor;
