import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import WorkIcon from '@material-ui/icons/Work';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { Divider, InputAdornment } from '@material-ui/core';

export const SelectCompanyModal = ({ open, handleClose, handleListItemClick, companies }) => {
    const [filteredCompanies, setFilteredCompanies] = useState(companies)

    const onChange = (e) => {
        e.preventDefault()
        if (Array.isArray(companies))
            setFilteredCompanies(companies.filter(company => company.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Seleccione una compañia</DialogTitle>
            <TextField label="Filtrar por nombre" onChange={onChange} style={{ margin: "0 10px 0 10px" }} InputProps={{
                startAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }} />
            <Divider variant="middle"/>
            <List>
                {
                    Array.isArray(filteredCompanies) && filteredCompanies.map((company) => (
                        <ListItem button onClick={() => handleListItemClick(company)} key={company.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <WorkIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={company.name} />
                        </ListItem>
                    ))
                }
            </List>
        </Dialog>
    )
}