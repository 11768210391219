import EnumBase from '../../../../helpers/EnumBase';

export class CashFlowRowChangeValueType {
  static number = new EnumBase(1, 'number');

  static timestamp = new EnumBase(2, 'timestamp');
}

class CashFlowRowChangeValue {
  /**
   *
   * @param Any value
   * @param CashFlowRowChangeValueType type
   * @param CashFlowRowChangeValue additional
   */
  constructor(
    value,
    type = CashFlowRowChangeValueType.number,
    additional = null,
  ) {
    this.value = value;
    this.type = type;
    this.additional = additional;
  }
}

export default CashFlowRowChangeValue;
