import clsx from 'clsx';
import React from 'react';

import Typography from 'shared/components/Typography/Typography';
import { PhaseDetailDatesRange } from '../../../core/DTO/SalesSpeedHelperDTO';
import HighlightValues from '../../../core/enums/HighlightValues';
import SalesSpeedHelperPhasesDetailDateCard from '../SalesSpeedHelperPhasesDetailDateCard/SalesSpeedHelperPhasesDetailDateCard';

import styles from './SalesSpeedHelperPhasesDetailDates.module.scss';

const getIsBoldClass = (isBold = false) => ({
  [styles.isBold]: isBold,
});

enum TitleVariants {
  Subtitle1 = 'subtitle1',
  Headline6 = 'headline6',
}

const getTitleVariant = (isBold = false): TitleVariants => (isBold ? TitleVariants.Headline6 : TitleVariants.Subtitle1);

enum MonthVariants {
  Caption = 'caption',
  Body2 = 'body2',
}

const getMonthVariant = (isBold = false): MonthVariants => (isBold ? MonthVariants.Body2 : MonthVariants.Caption);

const isHighligthed =
  (type: 'End' | 'Start') =>
  (highlight: HighlightValues | undefined): boolean =>
    highlight === HighlightValues[type];

const isHighligthedEnd = isHighligthed('End');

const isHighligthedStart = isHighligthed('Start');

type Props = {
  title: string;
  datesRange: PhaseDetailDatesRange;
  isBold?: boolean;
  isDisabled?: boolean;
  highlight?: HighlightValues;
};

const SalesSpeedHelperPhasesDetailDates: React.FC<Props> = ({ title, datesRange, isBold, isDisabled, highlight }) => (
  <div className={styles.root}>
    <div className={clsx(styles.titleContainer, getIsBoldClass(isBold))}>
      <Typography variant={getTitleVariant(isBold)} as="h2">
        {title}
      </Typography>
      <Typography variant={getMonthVariant(isBold)}>({datesRange.months} meses)</Typography>
    </div>
    <div className={styles.datesContainer}>
      <SalesSpeedHelperPhasesDetailDateCard
        title="Desde"
        date={datesRange.fromTime}
        isBold={isBold}
        isDisabled={isDisabled}
        isHighlighted={isHighligthedStart(highlight)}
      />
      <SalesSpeedHelperPhasesDetailDateCard
        title="Hasta"
        date={datesRange.toTime}
        isBold={isBold}
        isDisabled={isDisabled}
        isHighlighted={isHighligthedEnd(highlight)}
      />
    </div>
  </div>
);

export default SalesSpeedHelperPhasesDetailDates;
