import { useContext, useEffect, useState } from 'react';
import ContainerContext from '../../../../containers/Client/context';
import ClientAddressType from '../models/ClientAddressType';
import AddressForClientFunctions from './AddressForClientFunctions';

function useClientAddressUpdater(type) {
  const { currentAddress, selectedClient } = useContext(ContainerContext);
  const [address, setAddress] = useState({});
  const [customDescription, setCustomDescription] = useState(
    selectedClient?.clientAdditionalInfo?.residenceAddress,
  );

  useEffect(() => {
    setCustomDescription(
      selectedClient?.clientAdditionalInfo?.residenceAddress,
    );
  }, [selectedClient]);

  useEffect(() => {
    setAddress(currentAddress);
  }, [currentAddress]);

  const updateWhenIsOpen = (event) => {
    setCustomDescription(event.target.value);
  };

  const updateWhenIsHelper = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
  };

  const update =
    type === ClientAddressType.OPEN.code
      ? updateWhenIsOpen
      : updateWhenIsHelper;

  const descriptionFromModel =
    AddressForClientFunctions.getDescriptionForAddress(address);

  const addressDescription =
    type === ClientAddressType.OPEN.code
      ? customDescription
      : descriptionFromModel;

  return {
    clientAddress: address,
    addressDescription,
    selectedClient,
    update,
  };
}

export default useClientAddressUpdater;
