import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UpdateFeatureFieldTypes } from '../enums/featureField.enum';

import FeatureFieldUtils from '../FeatureFieldUtils';
import {
  FeatureFieldDTO,
  FeatureField,
  FeatureFields,
  FeatureFieldsRepository,
} from '../interfaces/featureField.types';
import { UpdateFieldPayload } from '../interfaces/FeatureFieldAdmin.types';

const transformFeatureField = (previousFields: FeatureFields, featureFieldDTO: FeatureFieldDTO): FeatureFields => ({
  ...previousFields,
  [featureFieldDTO.id]: FeatureFieldUtils.getFeatureField(featureFieldDTO),
});

const getFeatureFields = (featureFieldsDTO: FeatureFieldDTO[]): FeatureFields =>
  featureFieldsDTO.reduce(transformFeatureField, {});

const getFieldId = ({ id }: FeatureField): number => id;

const sortFeatureFields = (featureFields: FeatureFields) => _.sortBy(featureFields, ['index']).map(getFieldId);

const insertFeatureField = (featureFields: FeatureFields, field: FeatureField): FeatureFields => ({
  ...featureFields,
  [field.id]: field,
});

const deleteFatureField = (featureFields: FeatureFields, field: FeatureField): FeatureFields => {
  const newFeatureFields = { ...featureFields };

  delete newFeatureFields[field.id];

  return newFeatureFields;
};

const featureFieldUpdaters = {
  [UpdateFeatureFieldTypes.Update]: insertFeatureField,
  [UpdateFeatureFieldTypes.Delete]: deleteFatureField,
};

const getFeatureFieldsRepo = (featureFields: FeatureFields): FeatureFieldsRepository => ({
  data: featureFields,
  order: sortFeatureFields(featureFields),
});

function useFeatureFieldRepo(featureFieldsDTO: FeatureFieldDTO[]) {
  const [featureFieldsRepo, setFeatureFieldsRepo] = useState<FeatureFieldsRepository>(getFeatureFieldsRepo({}));

  useEffect(() => {
    setFeatureFieldsRepo(getFeatureFieldsRepo(getFeatureFields(featureFieldsDTO)));
  }, [featureFieldsDTO]);

  const updateFeatureField = ({ type, field }: UpdateFieldPayload) => {
    const newFeatureFields = featureFieldUpdaters[type](featureFieldsRepo.data, field);

    setFeatureFieldsRepo(getFeatureFieldsRepo(newFeatureFields));
  };

  const updateFeatureFields = (featureFields: FeatureFields) => {
    setFeatureFieldsRepo(getFeatureFieldsRepo(featureFields));
  };

  return { featureFieldsRepo, updateFeatureField, updateFeatureFields };
}

export default useFeatureFieldRepo;
