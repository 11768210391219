import React, { FC } from 'react';
import useMonthlyInterestGetter from '../../../core/customHooks/useMonthlyInterestGetter';
import MonthlyInterestArrearsTableContainer from '../MonthlyInterestArrearsTableContainer/MonthlyInterestArrearsTableContainer';
import MonthlyInterestSummaryDrawer from '../MonthlyInterestSummaryDrawer/MonthlyInterestSummaryDrawer';
import MonthlyInterestArrearsTable from '../../tables/MonthlyInterestArrearsTable';

export interface MonthlyInterestContentProp {
  isOpen: boolean;
  propertyId: number;
  handleClose: () => void;
  hasToolbar: boolean;
}

const MonthlyInterestContent: FC<MonthlyInterestContentProp> = ({ propertyId, isOpen, handleClose, hasToolbar }) => {
  const { data, reload } = useMonthlyInterestGetter(propertyId, isOpen);

  return (
    <>
      <MonthlyInterestSummaryDrawer walletMonthlyReportDTO={data} hasToolbar={hasToolbar} />
      <MonthlyInterestArrearsTableContainer
        {...data}
        onClose={handleClose}
        propertyId={propertyId}
        hasToolbar={hasToolbar}
      >
        <MonthlyInterestArrearsTable
          monthlyReports={data?.distribution ?? []}
          propertyPlan={data?.propertyPlan ?? []}
          propertyId={propertyId}
          reload={reload}
          isAllInPropertyChecked={data?.isAllInPropertyChecked ?? false}
        />
      </MonthlyInterestArrearsTableContainer>
    </>
  );
};

export default MonthlyInterestContent;
