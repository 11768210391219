import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useUpdateAccordion from 'App/Clients/Core/customHooks/useUpdateAccordion';
import styles from './UpdateAccordion.module.scss';


interface Props {
  id: string;
  title: string;
  formIds?: string[]
}

const UpdateAccordion: FC<Props> = ({ id, title, formIds, children }) => {
  const { buttonRef, handleClick } = useUpdateAccordion()
  return (
    <Accordion>
      <AccordionSummary id={id} expandIcon={<ExpandMore />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.body}>
        <Box display="flex" flexDirection="column">
          <Box width="100%" paddingY={2}>
            {children}
          </Box>
          <Box width="100%">
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%" paddingY={2} paddingX={2}>
            <Button className={styles.button} onClick={handleClick} ref={buttonRef}>Guardar
              {formIds && formIds.map(formId => {
                return <Button type='submit' className={styles.hidden} form={formId} />
              })}
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default UpdateAccordion;
