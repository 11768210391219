import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';

const TableCellTooltipTypography: FC<TableCellTypographyProps> = ({ children, value }) => {
  return (
    <Tooltip title={<span>{value}</span>}>
      <span>{value}</span>
    </Tooltip>
  );
};

export default TableCellTooltipTypography;
