import { DragEventHandler } from 'react';

const useDragAndDrop = <T>(onDrop: (files: FileList, payload?: T) => void) => {
  const handleDragEnter: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop =
    (payload?: T): DragEventHandler<HTMLElement> =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      let files = e.dataTransfer.files;

      if (files && files.length > 0) {
        onDrop(files, payload);
        e.dataTransfer.clearData();
      }
    };

  return {
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  };
};

export default useDragAndDrop;
