import { useQuery } from 'react-query';
import { EvalproDeedExpensesService } from 'App/DeedExpenses/services/EvalproDeedExpensesService';
import { EVALPRO_DEED_EXPENSES_MONTHS } from '../reactQuery/RequestConstants';
import { EvalproDeedExpensesMonthsAPIResponse } from '../interfaces/EvalproDeedExpensesMonthsAPIResponse';

const service = new EvalproDeedExpensesService();

const useEvalproDeedExpensesDeedMonthsAPI = (towerId: string) => {
  const query = useQuery<EvalproDeedExpensesMonthsAPIResponse>(
    EVALPRO_DEED_EXPENSES_MONTHS,
    async () => {
      const { data: result } = await service.getMonths(towerId);
      return result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return query;
};

export default useEvalproDeedExpensesDeedMonthsAPI;
