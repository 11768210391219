import { useEffect, useState } from 'react';
import useBoolean from 'shared/customHooks/useBoolean';
import { EvalproDeedExpensesCellNumberProps } from '../interfaces/EvalproDeedExpensesCellNumberProps';

const useEvalproDeedExpensesCellNumber = (
  defaultStockUnit: number,
  monthId: number,
  handleChangeValue: EvalproDeedExpensesCellNumberProps['handleChangeValue'],
) => {
  const [newValue, setNewValue] = useState(defaultStockUnit);
  const { value: isOnBlur, setFalse, setTrue } = useBoolean();

  const handleChange = (newDisplacement: number) => {
    setNewValue(newDisplacement);
  };

  useEffect(() => {
    if (typeof defaultStockUnit === 'number') setNewValue(defaultStockUnit);
  }, [defaultStockUnit]);

  const onSubmit = () => setTrue();

  useEffect(() => {
    if (newValue < 0) handleChange(defaultStockUnit);

    if (isOnBlur && defaultStockUnit !== newValue) {
      if (newValue === undefined) {
        handleChange(0);
        return;
      }
      if (newValue >= 0) {
        handleChangeValue({ value: newValue, monthId });
        setFalse();
      }
    }
  }, [isOnBlur, newValue]);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') onSubmit();
  };

  return {
    newValue,
    isOnBlur,
    onSubmit,
    onKeyDown,
    handleChange,
  };
};

export default useEvalproDeedExpensesCellNumber;
