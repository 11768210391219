import Joi from 'joi';
import { useForm } from 'shared/customHooks/useForm';
import { ClientProps } from '../interfaces/ClientProps';
import { ClientModel } from '../models/ClientModel';

const validateEmail = Joi.string()
  .email({ tlds: { allow: false } })
  .required();
const validateOnlyNumbers = Joi.string().regex(/^\S+$/).required();

const usePersonalInformation = (props: ClientProps) => {
  const { model: client } = props;

  const form = useForm<ClientModel>(
    {
      validations: {
        name: {
          required: {
            value: true,
            message: 'El nombre es requerido',
          },
        },
        surname: {
          required: {
            value: true,
            message: 'El apellido es requerido',
          },
        },
        identityDocument: {
          custom: {
            isValid: (value: string) => !validateOnlyNumbers.validate(value).error,
            message: 'El documento de identidad contiene espacios',
          },
        },
        identityDocumentType: {
          required: {
            value: true,
            message: 'El tipo de documento es requerido',
          },
        },
        email: {
          custom: {
            isValid: (email) => !validateEmail.validate(email).error,
            message: 'El correo electrónico es inválido',
          },
        },
        phoneNumber: {
          required: {
            value: true,
            message: 'El número de teléfono es requerido',
          },
        },
      },
      initialValues: client,
      onSubmit: async (values) => {
        props.onChangeModel(values);
      },
    },
    [client],
  );
  return form;
};

export default usePersonalInformation;
