import { Dispatch, SetStateAction, useState, MouseEvent, useRef, ElementRef, RefObject, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ContractPayment, ForeignData, ContractPaymentScheduleFlow } from '../../../../models/ContractPayments';
import AsyncConfirmModal from '../../../../shared/components/AsyncModal/AsyncConfirmModal';
import Services from '../../../../services/ContractPayments/ContractPaymentsServices';
import { useSnackbar } from 'notistack';

const services = new Services();

const useForeignData = () => {
  return useQuery<ForeignData>(
    'contract-payments-foreign-list',
    async () => {
      const { data: foreignData } = await services.getAllContractPaymentsForeignList();
      return foreignData;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  );
};

const useContractPaymentScheduleData = (contractId: number) => {
  return useQuery<ContractPaymentScheduleFlow[]>(
    ['resume-schedule-by-contract', contractId],
    async () => {
      const { data: schedulePayments } = await services.getResumeContractPaymentsSchedule(contractId);
      return schedulePayments;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
};

const useContractPaymentsData = (contractId: number) => {
  return useQuery<ContractPayment[]>(
    ['contract-payments', contractId],
    async () => {
      const { data: contractPayments } = await services.getAllContractPayments(contractId);
      return contractPayments;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  );
};

const useDeleteContractPayment = (currentContractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractPaymentId: number) => services.deleteContractPayment(contractPaymentId, currentContractId),
    {
      onSettled: () => {
        queryClient.invalidateQueries('contracts');
        queryClient.invalidateQueries(['contract', currentContractId]);
        queryClient.invalidateQueries(['contract-payments', currentContractId]);
      },
    },
  );
};

type AsyncConfirmModalRefType = ElementRef<typeof AsyncConfirmModal>;

type HookType = (currentContractId: number) => {
  contractPayments: ContractPayment[] | undefined;
  isLoading: boolean;
  currentContractPayment: ContractPayment | undefined;
  setCurrentContractPayment: Dispatch<SetStateAction<ContractPayment | undefined>>;
  contractPaymentType: string;
  setContractPaymentType: Dispatch<SetStateAction<string>>;
  openContractPaymentForm: boolean;
  setOpenContractPaymentForm: Dispatch<SetStateAction<boolean>>;
  openContractPaymentScheduleModal: boolean;
  setOpenContractPaymentScheduleModal: Dispatch<SetStateAction<boolean>>;
  anchorNewContractPaymentButton: HTMLElement | null;
  handleNewContractPaymentClick: Dispatch<MouseEvent<HTMLButtonElement>>;
  handleCloseNewContractPaymentMenu: () => void;
  getContractPaymentSchedule: () => void;
  scheduleFlowIsLoading: boolean;
  handleDelete: (contractPayment: ContractPayment) => Promise<void>;
  asyncConfirmDeletePaymentModalRef: RefObject<AsyncConfirmModalRefType>;
};

const useContractPayments: HookType = (currentContractId) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: contractPayments, isLoading } = useContractPaymentsData(currentContractId);
  const { data: contractsForeignData } = useForeignData();

  const { mutateAsync: deleteContractPayment } = useDeleteContractPayment(currentContractId);

  const { isLoading: scheduleFlowIsLoading, refetch: getContractPaymentScheduleData } =
    useContractPaymentScheduleData(currentContractId);

  const asyncConfirmDeletePaymentModalRef = useRef<AsyncConfirmModalRefType>(null);

  const [currentContractPayment, setCurrentContractPayment] = useState<ContractPayment | undefined>();

  const [contractPaymentType, setContractPaymentType] = useState<string>('');

  const [openContractPaymentForm, setOpenContractPaymentForm] = useState<boolean>(false);

  const [openContractPaymentScheduleModal, setOpenContractPaymentScheduleModal] = useState<boolean>(false);

  const [anchorNewContractPaymentButton, setAnchorNewContractPaymentButton] = useState<HTMLElement | null>(null);

  const handleNewContractPaymentClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorNewContractPaymentButton(event.currentTarget);
  };

  const handleCloseNewContractPaymentMenu = () => {
    setAnchorNewContractPaymentButton(null);
  };

  const getContractPaymentSchedule = () => {
    getContractPaymentScheduleData();
  };

  const handleDelete = useCallback(async (contractPayment: ContractPayment) => {
    await asyncConfirmDeletePaymentModalRef.current?.show(async () => {
      try {
        await deleteContractPayment(contractPayment.id);
        setCurrentContractPayment(undefined);
        enqueueSnackbar('Pago con eliminado con éxito', {
          variant: 'success',
        });
      } catch ({ message }) {
        enqueueSnackbar(`${message}`, { variant: 'error' });
      }
    });
  }, []);

  return {
    contractPayments,
    isLoading,
    currentContractPayment,
    setCurrentContractPayment,
    contractPaymentType,
    setContractPaymentType,
    openContractPaymentForm,
    setOpenContractPaymentForm,
    openContractPaymentScheduleModal,
    setOpenContractPaymentScheduleModal,
    anchorNewContractPaymentButton,
    handleNewContractPaymentClick,
    handleCloseNewContractPaymentMenu,
    getContractPaymentSchedule,
    scheduleFlowIsLoading,
    handleDelete,
    asyncConfirmDeletePaymentModalRef,
  };
};

export default useContractPayments;
