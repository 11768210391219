import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import classes from '../../styles/LeftColumnCustomCell.module.scss';
import { Typography } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

const LeftColumnCustomCell = (props) => {
  const { tableRow, ...restProps } = props;

  const { row } = tableRow;
  const construction = 'Construcción';
  const financialPerformance = 'financialPerformance';
  const perThousand = 'perThousand'

  if (row.id === 'UTILITY-BEFORE-TAX') {
    return (
      <>
        <VirtualTable.Cell
          data-tip
          data-for={`typographyToolTip ${row.name}`}
          {...restProps}
          className={classes.utilityRow}
        ></VirtualTable.Cell>
        <ReactTooltip
          type="dark"
          id={`typographyToolTip ${row.name}`}
          effect="solid"
          className={classes.tooltip}
        >
          <Typography className={classes.text}>{row.name}</Typography>
        </ReactTooltip>
      </>
    );
  }

  if (row.id === 'TOTAL_SALES') {
    return (
      <>
        <VirtualTable.Cell
          data-tip
          data-for={`typographyToolTip ${row.name}`}
          {...restProps}
          className={classes.groupRow}
        ></VirtualTable.Cell>
        <ReactTooltip
          type="dark"
          id={`typographyToolTip ${row.name}`}
          effect="solid"
          className={classes.tooltip}
        >
          <Typography className={classes.text}>{row.name}</Typography>
        </ReactTooltip>
      </>
    );
  }

  if (row.contracts) {
    return (
      <>
        <VirtualTable.Cell
          data-tip
          data-for={`typographyToolTip ${row.name}`}
          {...restProps}
          className={classes.itemRow}
        ></VirtualTable.Cell>
        <ReactTooltip
          type="dark"
          id={`typographyToolTip ${row.name}`}
          effect="solid"
          className={classes.tooltip}
        >
          <Typography className={classes.text}>{row.name}</Typography>
        </ReactTooltip>
      </>
    );
  }

  if (
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance && 
    row.id !== perThousand
  ) {
    return (
      <>
        <VirtualTable.Cell
          data-tip
          data-for={`typographyToolTip ${row.name}`}
          {...restProps}
          className={classes.contractRow}
        ></VirtualTable.Cell>
        <ReactTooltip
          type="dark"
          id={`typographyToolTip ${row.name}`}
          effect="solid"
          className={classes.tooltip}
        >
          <Typography className={classes.text}>{row.name}</Typography>
        </ReactTooltip>
      </>
    );
  }

  return (
    <>
      <VirtualTable.Cell
        data-tip
        data-for={`typographyToolTip ${row.name}`}
        {...restProps}
        className={classes.groupRow}
      ></VirtualTable.Cell>
      <ReactTooltip
        type="dark"
        id={`typographyToolTip ${row.name}`}
        effect="solid"
        className={classes.tooltip}
      >
        <Typography className={classes.text}>{row.name}</Typography>
      </ReactTooltip>
    </>
  );
};

LeftColumnCustomCell.propTypes = {
  tableRow: PropTypes.object,
};

export default LeftColumnCustomCell;
