import React, { FC } from 'react';
import { QuotationConfigDto } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import QuotationConfigCollapse from '../components/QuotationConfigCollapse/QuotationConfigCollapse';
import { Box, Grid, TextField } from '@material-ui/core';
import QuotationConfigFormItem from './QuotationConfigFormItem';
import Typography from 'shared/components/Typography/Typography';
import useUpdateQuotationSetupQuery from 'App/QuotationConfig/core/customHooks/useUpdateQuotationSetup';

const DisclaimerQuoteForm: FC<QuotationConfigDto> = ({ quotationSetup }) => {
  const { updateQuotationSetup } = useUpdateQuotationSetupQuery('finalNote');
  return (
    <QuotationConfigCollapse name="Disclamer Cotización" state={!!quotationSetup.finalNote ? 'success' : 'warning'}>
      <Box display="flex" justifyContent="center" padding="40px" paddingY="32px" paddingBottom="55px">
        <Grid container item xs={10}>
          <QuotationConfigFormItem item xs={12}>
            <Box paddingBottom="8px">
              <Typography fontWeight="500">Nota final PDF</Typography>
            </Box>
            <TextField
              multiline
              variant="outlined"
              fullWidth
              rows={4}
              defaultValue={quotationSetup.finalNote}
              onBlur={(e) => updateQuotationSetup(e.target.value)}
            />
          </QuotationConfigFormItem>
        </Grid>
      </Box>
    </QuotationConfigCollapse>
  );
};

export default DisclaimerQuoteForm;
