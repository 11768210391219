import React from 'react';
import { IconButton, TableCell, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/LockRounded';
import LockOpenIcon from '@material-ui/icons/LockOpenRounded';
import { CustomPaymentHeaderRow } from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/Payments/ReservationDeposit/CustomPaymentTableRow';
import QuotationDiscountType from '../../../Quotation/Core/enums/QuotationDiscountType';
import useRealPaymentEditionType from '../../Core/customHooks/useRealPaymentEditionType';

const AssignedPaymentHeaderRow = ({
  showEditableButtons,
  enableDiscountCalculation,
}) => {
  const PADLOCK_ICON = 'PADLOCK_ICON';

  const {
    isLockedType,
    toggleEditionType,
    isEditingMode,
    discountType,
  } = useRealPaymentEditionType();

  return (
    <CustomPaymentHeaderRow isEditing={isEditingMode}>
      {[
        'Cuota',
        showEditableButtons ? 'Tipo de cuota' : [],
        'Fecha',
        'Valor',
        isEditingMode && showEditableButtons && enableDiscountCalculation
          ? PADLOCK_ICON
          : [],
      ]
        .flatMap((e) => e)
        .map((header, index) => (
          <TableCell key={`header-${index}`}>
            {PADLOCK_ICON === header && (
              <Tooltip title="Reiniciar" placement="left">
                <IconButton
                  color="secondary"
                  aria-label="Tipo de edición"
                  onClick={toggleEditionType}
                  disabled={discountType === QuotationDiscountType.MANUAL.code}
                >
                  {isLockedType ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
              </Tooltip>
            )}
            {PADLOCK_ICON !== header && <Typography>{header}</Typography>}
          </TableCell>
        ))}
    </CustomPaymentHeaderRow>
  );
};

AssignedPaymentHeaderRow.propTypes = {
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
};

export default AssignedPaymentHeaderRow;
