import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArrearsReportWalletServices from '../../Services/ArrearsReportWalletServices';
import { updateArrearsForWalletReport, updateGeneralWallet } from '../actions';

function useArrearsReportLoading() {
  const { towerId } = useParams();
  const [summaryReport, setSummaryReport] = useState();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const fetch = async () => {
    const services = new ArrearsReportWalletServices();
    setLoading(true);
    try {
      const clients = await services.getClientsWithGeneralWalletAndArrears(
        towerId,
      );
      dispatch(updateGeneralWallet(clients.data.generalWallets));
      dispatch(updateArrearsForWalletReport(clients.data.totalCosts));
      setSummaryReport(clients.data.totalCosts);
      setLoading(false);
    } catch (error) {
      setSummaryReport({});
      setLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (!summaryReport && !isLoading) {
    fetch();
  }
  return { isLoading, setLoading };
}

export default useArrearsReportLoading;
