import React from 'react';
import { Link } from 'react-router-dom';
import { ProjectRoutes, DashboardRoutes } from '../../routes/local/routes';
import style from './Sidebar.module.scss';
import Icon from '../../assets/icons/Icon';
import MainLogo from '../../assets/images/Likemetric-main-logo.png';
import INTEGRATIONS from '../../config/integrations';

const sidebar = () => {
  const ENV = process.env.NODE_ENV;

  const itemForSlidebar = (styles, route, iconName) => {
    return (
      <div className={styles}>
        <Link to={route}>
          <Icon name={iconName} fixWidth={true} />
        </Link>
      </div>
    );
  };
  return (
    <div className={style.Sidebar}>
      {INTEGRATIONS[ENV].uniqueSideBar && (
        <div className={style.logo}>
          <img alt="Like Metric Logo" src={MainLogo} className={style.logo} />
        </div>
      )}
      {itemForSlidebar(
        `${style.Start} ${style.MenuItem}`,
        DashboardRoutes.base + ProjectRoutes.base,
        'fa-home',
      )}
      {itemForSlidebar(
        `${style.MenuItem}`,
        DashboardRoutes.base + DashboardRoutes.user,
        'fas fa-tools',
      )}
    </div>
  );
};

export default sidebar;
