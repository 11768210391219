import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SemiTypography from '@material-ui/core/Typography';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import Numbers from '../../../../../helpers/numbers';

const BoldTypography = styled(SemiTypography)`
  font-weight: 600;
  color: ${(props) => {
    return props.color === 'title' ? '#29339b' : 'inherit';
  }};
`;

const Typography = styled(SemiTypography)`
  font-weight: 300;
`;

const PaddingDiv = styled.div`
  padding: 0 10px;
`;

const CustomQuotationProperty = ({
  name,
  propertyPrice,
  priceWithDiscount,
  reservePercentage,
  discountPrice,
  commercialDiscount,
  commercialPrice,
  initialFeePercentage,
  periods,
  monthlyFeesPercentage,
  monthlyFeesPrice,
  reservePrice,
  initialFeePrice,
  finalFee,
  finalPrice,
  basePrice,
  additionalAreas,
  totalArea,
  privateArea,
}) => {
  return (
    <Paper>
      <Box display="flex" alignItems="center" justifyContent="center">
        <BoldTypography color="title">{name}</BoldTypography>
      </Box>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <SemiTypography>Área total:</SemiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <SemiTypography>
                  <NumberFormat
                    thousandSeparator
                    suffix="mt"
                    displayType="text"
                    value={Numbers.toFixed(totalArea)}
                  />
                </SemiTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <SemiTypography>Área privada</SemiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <SemiTypography>
                  <NumberFormat
                    thousandSeparator
                    suffix="mt"
                    displayType="text"
                    value={Numbers.toFixed(privateArea)}
                  />
                </SemiTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <SemiTypography>Valor inmueble:</SemiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <SemiTypography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(basePrice)}
                  />
                </SemiTypography>
              </Grid>
            </Grid>
          </Grid>

          {additionalAreas.map((area) => {
            return (
              <>
                <Grid item xs={12} lg={6} key={`title_area_${area.id}`}>
                  <PaddingDiv>
                    <SemiTypography>
                      {area.nomenclature.split(
                        area.type ? area.type.name : area.areaType.name,
                      ).length > 1
                        ? area.nomenclature
                        : `${area.type.name} - ${area.nomenclature}`}
                    </SemiTypography>
                  </PaddingDiv>
                </Grid>
                <Grid item xs={12} lg={6} key={`price_area_${area.id}`}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <PaddingDiv>
                        <SemiTypography>
                          <NumberFormat
                            thousandSeparator
                            prefix="$"
                            displayType="text"
                            value={Math.round(
                              area.areaType.unit === 'MT2'
                                ? area.price
                                : area.price * area.measure,
                            )}
                          />
                        </SemiTypography>
                      </PaddingDiv>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}

          <Grid item xs={12} lg={6}>
            <SemiTypography>Valor inmueble + Áreas adicionales:</SemiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <SemiTypography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(propertyPrice)}
                  />
                </SemiTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography>Descuento Comercial:</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(commercialDiscount)}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Valor Cierre Comercial:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <BoldTypography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(commercialPrice)}
                  />
                </BoldTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography>{`${
              discountPrice >= 0 ? 'Descuento' : 'Incremento'
            } Financiero $ COP`}</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(Math.abs(discountPrice))}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography>
              {`${
                discountPrice >= 0 ? 'Descuento' : 'Incremento'
              } Financiero %`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography>
                  {Numbers.toFixed(
                    Math.abs((discountPrice / commercialPrice) * 100),
                    2,
                    true,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Valor Cierre de Negocio:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <BoldTypography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(priceWithDiscount)}
                  />
                </BoldTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <SemiTypography>Cuota inicial:</SemiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SemiTypography>
                  {(initialFeePercentage * 100).toFixed(2)}%
                </SemiTypography>
              </Grid>
              <Grid item xs={6}>
                <SemiTypography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(initialFeePrice)}
                  />
                </SemiTypography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <PaddingDiv>
              <SemiTypography>Separación:</SemiTypography>
            </PaddingDiv>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <PaddingDiv>
                  <SemiTypography>
                    {Numbers.cleanNumber(reservePercentage).toFixed(2)}%
                  </SemiTypography>
                </PaddingDiv>
              </Grid>
              <Grid item xs={6}>
                <PaddingDiv>
                  <SemiTypography>
                    <NumberFormat
                      thousandSeparator
                      prefix="$"
                      displayType="text"
                      value={reservePrice}
                    />
                  </SemiTypography>
                </PaddingDiv>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <PaddingDiv>
              <SemiTypography>Cuotas mensuales ({periods}):</SemiTypography>
            </PaddingDiv>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <PaddingDiv>
                  <SemiTypography>
                    {Numbers.cleanNumber(monthlyFeesPercentage).toFixed(2)}%
                  </SemiTypography>
                </PaddingDiv>
              </Grid>
              <Grid item xs={6}>
                <PaddingDiv>
                  <SemiTypography>
                    <NumberFormat
                      thousandSeparator
                      prefix="$"
                      displayType="text"
                      value={Math.round(monthlyFeesPrice)}
                    />
                  </SemiTypography>
                </PaddingDiv>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <SemiTypography>Pago final:</SemiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SemiTypography>{finalFee.toFixed(2)}%</SemiTypography>
              </Grid>
              <Grid item xs={6}>
                <SemiTypography>
                  <NumberFormat
                    thousandSeparator
                    prefix="$"
                    displayType="text"
                    value={Math.round(finalPrice)}
                  />
                </SemiTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

CustomQuotationProperty.propTypes = {
  property: PropTypes.shape({ name: PropTypes.string }).isRequired,
  priceWithDiscount: PropTypes.number,
  commercialDiscount: PropTypes.number,
  commercialPrice: PropTypes.number,
  initialFeePercentage: PropTypes.number,
  reservePercentage: PropTypes.number,
  periods: PropTypes.number,
  monthlyFeesPercentage: PropTypes.number,
  monthlyFeesPrice: PropTypes.number,
  discountPrice: PropTypes.number,
  reservePrice: PropTypes.number,
  initialFeePrice: PropTypes.number,
  finalFee: PropTypes.number,
  finalPrice: PropTypes.number,
  name: PropTypes.string,
  propertyPrice: PropTypes.number,
  additionalAreas: PropTypes.array,
  basePrice: PropTypes.number,
  privateArea: PropTypes.number,
  totalArea: PropTypes.number,
};

export default CustomQuotationProperty;
