import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Drawer } from '@material-ui/core';
import { GeneralWalletDetailsProps } from '../core/customHooks/useGeneralWalletDetailsDrawer';
import useGeneralWalletDetailsDrawer from '../core/customHooks/useGeneralWalletDetailsDrawer';
import GeneralWalletDetailsContainer from './GeneralWalletDetailsContainer';

import styles from './styles/GeneralWalletDetails.module.scss';

const queryClient = new QueryClient();

const GeneralWalletDetails: FC<GeneralWalletDetailsProps> = ({ client, onClose }) => {
  const { isOpen, closeGeneralWalletDetails } = useGeneralWalletDetailsDrawer({ client, onClose });
  return (
    <QueryClientProvider client={queryClient}>
      <Drawer anchor="right" open={isOpen} className={styles.drawer}>
        {client?.clientId && <GeneralWalletDetailsContainer client={client} onClose={closeGeneralWalletDetails} />}
      </Drawer>
    </QueryClientProvider>
  );
};

export default GeneralWalletDetails;
