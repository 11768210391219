import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import useAreaTableEditor from '../../../../Core/customHooks/AreaEditionTable/useAreaTableEditor';
import styles from './AreaTableEditor.module.scss';

const AreaTableEditorCell: FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  const { value, loading, handleChange, onBlur } = useAreaTableEditor(props);

  return (
    <NumberFormat
      value={value}
      onValueChange={(values) => handleChange(values.floatValue)}
      onBlur={onBlur}
      // @ts-ignore
      fullWidth
      className={`${styles.Input} ${loading ? styles.loading : ''}`}
      thousandSeparator
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue > -1 || floatValue === undefined;
      }}
    />
  );
};

export default AreaTableEditorCell;
