import Services from 'services/services';
import { EvalproDeedExpensesConfig } from '../core/interfaces/EvalproDeedExpensesConfig';
import { EvalproDeedExpensesConfigResponse } from '../core/interfaces/EvalproDeedExpensesConfigResponse';
import { EvalproDeedExpensesMonth } from '../core/interfaces/EvalproDeedExpensesMonth';
import { EvalproDeedExpensesMonthsAPIResponse } from '../core/interfaces/EvalproDeedExpensesMonthsAPIResponse';
import EvalproDeedExpensesServiceDefinitions from './EvalproDeedExpensesServiceDefinitions';

const def = EvalproDeedExpensesServiceDefinitions;

export class EvalproDeedExpensesService extends Services {
  getConfig = (towerId: string) => this.get<EvalproDeedExpensesConfigResponse>(def.evalproConfig(towerId));

  updateConfig = (
    towerId: string,
    payload: {
      key: keyof EvalproDeedExpensesConfig;
      value: EvalproDeedExpensesConfig[keyof EvalproDeedExpensesConfig];
    },
  ) => this.put<{ isSuccess: boolean }>(def.evalproConfig(towerId), payload);

  updateMonth = (id: number, payload: Partial<EvalproDeedExpensesMonth>) =>
    this.put<{ isSuccess: boolean }>(def.updateEvalproMonths(id), payload);

  getMonths = (towerId: string) => this.get<EvalproDeedExpensesMonthsAPIResponse>(def.evalproMonths(towerId));
}
