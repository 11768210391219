import { MouseEventHandler, useState } from 'react';

import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';
import { StageTowerDTO } from '../DTO/StageTowersDTO';
import { DeleteTowerHandler, EditTowerHandler, TowerValues } from '../types/Stages.types';

const getEditableTower = ({ name, description }: StageTowerDTO): TowerValues => ({
  name,
  description,
});

const getAlertDescription = (name: string) => `¿Desea eliminar ${name} permanentemente?`;

type UseStageTowerMenu = {
  stageId: number;
  tower: StageTowerDTO;
  onEditTower: EditTowerHandler;
  onDeleteTower: DeleteTowerHandler;
};

function useStageTowerMenu<T>({ stageId, tower, onEditTower, onDeleteTower }: UseStageTowerMenu) {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);

  const openMenu: MouseEventHandler<T> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    closeMenu();
    onEditTower({
      isEditing: true,
      stageId,
      towerId: tower.id,
      towerValues: getEditableTower(tower),
    });
  };

  const acceptDelete = () => {
    onDeleteTower(tower);
  };

  const { showAlert } = useAlert({
    isOpen: false,
    title: 'Eliminar Torre',
    variant: AlertVariants.Destructive,
    acceptAction: acceptDelete,
  });

  const handleDeleteClick = () => {
    closeMenu();
    showAlert(getAlertDescription(tower.name));
  };

  return { anchorEl, openMenu, closeMenu, handleEditClick, handleDeleteClick };
}

export default useStageTowerMenu;
