import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import BuyerCustomerServices from '../../Services/BuyerCustomerServices';
import { setCurrentFilterForBuyers, updateBuyerCostumerList } from '../actions';

const services = new BuyerCustomerServices();

function useBuyerCustomerLoader() {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [currentCostumers, setCostumers] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetch = async () => {
    setLoading(true);
    dispatch(setCurrentFilterForBuyers(''));
    try {
      const customers = await services.getCostumers(towerId);
      setCostumers(customers.data);
      dispatch(updateBuyerCostumerList(customers.data));
    } catch (error) {
      setCostumers([]);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!currentCostumers && !isLoading) {
      fetch();
    }
  }, []);

  const updateFilter = (event) => {
    const filteredWord = event.target.value;
    if (!filteredWord || filteredWord === '') {
      dispatch(setCurrentFilterForBuyers(null));
    } else {
      dispatch(setCurrentFilterForBuyers(filteredWord));
    }
  };

  return { isLoading, updateFilter };
}

export default useBuyerCustomerLoader;
