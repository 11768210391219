const { API_PATH } = require('../../../config/config');

const RealPaymentsServicesDefinitions = {
  getRealPayments: (propertyId, towerId) =>
    `${API_PATH}real-payment/general/${towerId}/${propertyId}`,
  getMonthlyPayments: (propertyId, towerId) =>
    `${API_PATH}wallet-transaction/client/monthly-summary/${towerId}/${propertyId}`,
  getClientHubForProperty: (propertyId, towerId) =>
    `${API_PATH}wallet-transaction/client/hub/${towerId}/${propertyId}`,
  isPurchaseConfirmedPath: (clientId, propertyId) =>
    `${API_PATH}wallet-transaction/client/isPurchaseConfirmed/${clientId}/${propertyId}`,
  update: (towerId) => `${API_PATH}real-payment/update/${towerId}`,
  delete: (towerId) => `${API_PATH}real-payment/delete/${towerId}`,
  download: (towerId, propertyId) =>
    `${API_PATH}real-payment/download/${towerId}/${propertyId}`,
  downloadMonthly: (towerId, propertyId) =>
    `${API_PATH}real-payment/monthly-summary/tower/${towerId}/property/${propertyId}`,
  upload: (towerId, propertyId, clientId) =>
    `${API_PATH}real-payment/template/${towerId}/${propertyId}/${clientId}`,
  updateFinancialPayment: (towerId) =>
    `${API_PATH}wallet-transaction/create/financial-payment/${towerId}`,
  updateChargeNumber: `${API_PATH}v2/properties/update/chargeNumber`,
};

export default RealPaymentsServicesDefinitions;
