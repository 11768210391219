import Numbers from '../../../../helpers/numbers';

class BuilderCreditModel {
  constructor(dbModel = {}) {
    const {
      bankableCosts,
      consolidatedApproval,
      consolidatedOperative,
      consolidatedBuilder,
      approvedOperativeCredit,
      approvedBuilderCredit,
      feeType,
      annualFee,
      frequency,
      firstDisbursementDate,
    } = dbModel;
    this.bankableCosts = bankableCosts;
    this.consolidatedApproval = consolidatedApproval;
    this.consolidatedOperative = consolidatedOperative;
    this.consolidatedBuilder = consolidatedBuilder;
    this.approvedOperativeCredit = approvedOperativeCredit;
    this.approvedBuilderCredit = approvedBuilderCredit;
    this.feeType = feeType;
    this.annualFee = annualFee;
    this.frequency = frequency;
    this.firstDisbursementDate = firstDisbursementDate;
  }

  get suggestedConsolidatedCredit() {
    return (
      Numbers.cleanNumber(this.bankableCosts) *
      this.percentage(this.consolidatedApproval)
    );
  }

  get suggestedOperativeCredit() {
    return (
      Numbers.cleanNumber(this.suggestedConsolidatedCredit) *
      this.percentage(this.consolidatedOperative)
    );
  }

  get suggestedBuilderCredit() {
    return (
      Number(+this.suggestedConsolidatedCredit) -
      Number(+this.suggestedOperativeCredit)
    );
  }

  get approvedConsolidatedCredit() {
    return (
      Number(+this.approvedOperativeCredit) +
      Number(+this.approvedBuilderCredit)
    );
  }

  percentage(value) {
    return Numbers.cleanNumber(value / 100);
  }
}

export default BuilderCreditModel;
