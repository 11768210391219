import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Paper, Button, Typography, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import styles from './AddDocumentTemplate.module.scss';
import useAddDocumentTemplateBehavior from '../../Core/customHooks/useAddDocumentTemplateBehavior';
import DocumentTemplateVariableNamesCell from '../cells/DocumentTemplateVariableNamesCell';

const AddDocumentTemplate = ({ open, onClose, uploadTemplate }) => {
  const {
    name,
    nameValid,
    onNameChange,
    onFileChange,
    upload,
    uploading,
  } = useAddDocumentTemplateBehavior(onClose, uploadTemplate);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modalContainer}>
        <Paper elevation={3} className={styles.addNewTemplateWrapper}>
          <div className={styles.header}>
            <h1>Agregar Nueva Plantilla</h1>
          </div>
          <div className={styles.content}>
            <section className={styles.big}>
              <Alert severity="info">
                Instrucciones de uso de las plantillas
              </Alert>
              <br />
              <Typography variant="body1">
                Las plantillas son archivos de Microsoft Word (.docx) que
                incluyen variables para luego ser remplazadas en el momento de
                descargar archivos para un cliente.
              </Typography>
              <br />
              <Typography variant="body1">
                Una variable es simplemente un nombre acompañado de llaves{' '}
                {'"{variable}"'} y debe estar incluída dentro de las variables
                soportadas para la torre. A continuación encontrará una lista de
                variables soportadas. Simplemente insértelas en el documento.
                Posteriormente Likemetric se encargará de remplazarlas a la hora
                de descargar un archivo para el cliente.
              </Typography>
              <br />
              <ul className={styles.variableList}>
                INFO DE UNIDADES INMOBILIARIAS Y PROYECTO
                <DocumentTemplateVariableNamesCell
                  name="fechaActual"
                  description="FECHA DE HOY"
                />
                <DocumentTemplateVariableNamesCell
                  name="proyecto"
                  description="NOMBRE DEL PROYECTO"
                />
                <DocumentTemplateVariableNamesCell
                  name="etapa"
                  description="NOMBRE DE LA TORRE"
                />
                <DocumentTemplateVariableNamesCell
                  name="apartamento"
                  description="NOMBRE DEL APARTAMENTO"
                />
                <DocumentTemplateVariableNamesCell
                  name="areaTotal"
                  description="ÁREA TOTAL CONSTRUIDA"
                />
                <DocumentTemplateVariableNamesCell
                  name="areaPrivada"
                  description="ÁREA PRIVADA"
                />
                <DocumentTemplateVariableNamesCell
                  name="fechaDeSeparacion"
                  description="FECHA DE CIERRE DEL NEGOCIO"
                />
                <DocumentTemplateVariableNamesCell
                  name="numeroDeEncargo"
                  description="# DE ENCARGO"
                />
                <DocumentTemplateVariableNamesCell
                  name="nomenclaturaDeAreasAdicionales"
                  description="NOMENCLATURA DE LOS ADICIONALES"
                />
                <DocumentTemplateVariableNamesCell
                  name="precioArea_1"
                  description="PRECIO DEL AREA NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="precioArea_1"
                  description="NOMENCLATURA DEL ÁREA NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="precioTotalCuotaInicial"
                  description="PRECIO TOTAL DE LA CUOTA INICIAL"
                />
                <DocumentTemplateVariableNamesCell
                  name="diasDeMora"
                  description="DÍAS DE MORA"
                />
                <DocumentTemplateVariableNamesCell
                  name="fechaUltimoPago"
                  description="FECHA DEL ÚLTIMO PAGO"
                />
                <DocumentTemplateVariableNamesCell
                  name="valorDeMora"
                  description="VALOR DE MORA"
                />
                <DocumentTemplateVariableNamesCell
                  name="valorPagadoALaFecha"
                  description="VALOR PAGADO A LA FECHA"
                />
                <DocumentTemplateVariableNamesCell
                  name="nombreDelUsuarioLikemetric"
                  description="NOMBRE DEL USUARIO DE LIKE METRIC"
                />
                <DocumentTemplateVariableNamesCell
                  name="asesorAsignado"
                  description="NOMBRE DEL ASESOR ASIGNADO AL CLIENTE"
                />
                INFO DE PRECIOS
                <DocumentTemplateVariableNamesCell
                  name="precioPorM2"
                  description="VALOR DE METRO CUADRADO"
                />
                <DocumentTemplateVariableNamesCell
                  name="descuentoComercial"
                  description="VALOR DE DESCUENTO COMERCIAL"
                />
                <DocumentTemplateVariableNamesCell
                  name="descuentoFinanciero"
                  description="VALOR DE DESCUENTO FINANCIERO"
                />
                <DocumentTemplateVariableNamesCell
                  name="incrementoFinanciero"
                  description="VALOR INCREMENTO FINANCIERO"
                />
                <DocumentTemplateVariableNamesCell
                  name="precioCotizado"
                  description="PRECIO MENOS DESCUENTO COMERCIAL"
                />
                <DocumentTemplateVariableNamesCell
                  name="precioConAdicionales"
                  description="PRECIO APARTAMENTO + ADICIONALES"
                />
                <DocumentTemplateVariableNamesCell
                  name="incrementoComercial"
                  description="VALOR INCREMENTO COMERCIAL"
                />
                <DocumentTemplateVariableNamesCell
                  name="cierreDeNegocio"
                  description="PRECIO TOTAL A PAGAR DEL NEGOCIO "
                />
                <DocumentTemplateVariableNamesCell
                  name="areasAdicionales"
                  description="PRECIO DE PARQUEADEROS Y ÚTILES"
                />
                <DocumentTemplateVariableNamesCell
                  name="precioDeSeparacion"
                  description="VALOR CUOTA DE SEPARACIÓN"
                />
                <DocumentTemplateVariableNamesCell
                  name="pagoFinal"
                  description="PAGO FINAL A CRÉDITO"
                />
                INFO DE CADA COMPRADOR (Si desea la información del segundo
                cliente puede usar el posfijo _2)
                <DocumentTemplateVariableNamesCell
                  name="nombreCliente_1"
                  description="NOMBRE DE COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="docuCliente_1"
                  description="NUMERO DE DOCUMENTO COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="lugarExp_1"
                  description="LUGAR EXPEDICIÓN DE CC. NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="estadoCivil_1"
                  description="ESTADO CIVIL COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="correoCliente_1"
                  description="CORREO COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="telCliente_1"
                  description="TELÉFONO COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="dirCliente_1"
                  description="DIRECCIÓN COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="ciudadCliente_1"
                  description="CIUDAD RESIDENCIA COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="segundoTelefono_1"
                  description="SEGÚNDO TElÉFONO COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="dirOficina_1"
                  description="DIRECCIÓN OFICINA COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="telOficina_1"
                  description="TELÉFONO OFICINA COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="ciudadOficina_1"
                  description="CIUDAD OFICINA COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="ciudadCliente_1"
                  description="CIUDAD CLIENTE COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="medio_1"
                  description="MEDIO COMPRADOR NÚMERO UNO"
                />
                <DocumentTemplateVariableNamesCell
                  name="porcentajeCli_1"
                  description="PORCENTAJE DE PARTICIPACIÓN COMPRADOR NÚMERO UNO"
                />
              </ul>
            </section>
            <section className={styles.small}>
              <div className={styles.form}>
                <label>Nombre de la Plantilla</label>
                <TextField
                  placeholder="Nombre"
                  variant="outlined"
                  value={name}
                  onChange={onNameChange}
                  error={!nameValid}
                />
                <label>Seleccione un archivo</label>
                <input
                  type="file"
                  onChange={onFileChange}
                  accept=".docx, .DOCX"
                />
              </div>
            </section>
          </div>
          <div className={styles.footer}>
            <Button
              color="secondary"
              variant="outlined"
              className={styles.button}
              onClick={() => !uploading && onClose()}
              disabled={uploading}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={styles.button}
              onClick={upload}
              disabled={uploading}
            >
              Agregar
            </Button>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

AddDocumentTemplate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  uploadTemplate: PropTypes.func.isRequired,
};

export default AddDocumentTemplate;
