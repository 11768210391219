import React, { FC, RefObject } from 'react';
import CustomPopover, { CustomPopoverContentProps } from '../../../../../shared/components/CustomPopover/CustomPopover';

import { Contract } from '../../../../../models/Contracts';
import useContractDetailPopoverActions, {
  UseContractDetailPopoverActionsProps,
} from '../../../Core/customHooks/useContractDetailPopoverActions';

import styles from './ContractDetailPopover.module.scss';
import { Divider, MenuItem } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';

type ContractsPopoverContentProps = CustomPopoverContentProps<UseContractDetailPopoverActionsProps, Contract>;

const ContractDetailPopoverContent: FC<ContractsPopoverContentProps> = (props) => {
  const { payload: contract, extraProps, onClose: cb } = props;
  const { goEdit, goDelete } = useContractDetailPopoverActions({
    contract,
    cb,
    extraProps,
  });
  return (
    <>
      <span className={styles.menuTopLeft}></span>
      <MenuItem
        onClick={goEdit}
        dense
        classes={{
          root: styles.menuItem,
        }}
      >
        Editar
      </MenuItem>
      <Divider className={styles.divider} />
      <MenuItem
        onClick={goDelete}
        dense
        classes={{
          root: styles.menuItem,
        }}
      >
        <Typography color="stateError600">Eliminar</Typography>
      </MenuItem>
    </>
  );
};

interface Props extends UseContractDetailPopoverActionsProps {
  elementRef: RefObject<HTMLDivElement>;
}

const ContractDetailPopover: FC<Props> = ({ elementRef, ...props }) => {
  return (
    <CustomPopover<UseContractDetailPopoverActionsProps, Contract>
      elementRef={elementRef}
      contentComponent={ContractDetailPopoverContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      extraProps={props}
      classes={{
        paper: styles.popoverRoot,
      }}
    />
  );
};

export default ContractDetailPopover;
