import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MaterialTable, { MTableCell } from 'material-table';
import NumberFormat from 'react-number-format';
import MaterialIcons from '../../../UI2/MaterialTableIcons';

const MacroEconomicTable = ({ columns, data }) => {
  const [dataLoad, setDataLoad] = useState(data);
  const currencyFormatRender = (rowData, name) => (
    <NumberFormat
      value={Number(rowData[name]).toFixed(0)}
      thousandSeparator
      prefix={'$'}
      displayType={'text'}
      renderText={(value) => <div>{value}</div>}
    />
  );

  const formatColumns = (arrayOfData) => {
    return arrayOfData.flatMap((row, index) => ({
      ...row,
      width: 150,
      render: (rowData) => currencyFormatRender(rowData, row.field),
    }));
  };

  useEffect(() => {
    setDataLoad(data);
  }, [data]);

  return (
    <Grid container direction="row">
      <MaterialTable
        icons={MaterialIcons}
        columns={formatColumns(columns)}
        data={dataLoad}
        options={{
          showTitle: false,
          rowStyle: {
            backgroundColor: '#FFF',
          },
          search: false,
          paging: false,
          pageSize: data.length,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Parece que aún no has puesto datos 🧐',
            addTooltip: 'Agregar',
            deleteTooltip: 'Borrar registro',
            editTooltip: 'Editar registro',
            filterRow: {
              filterTooltip: 'Flitrar',
            },
            editRow: {
              deleteText: '¿Estás seguro de borrar esta fila?',
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Guardar',
            },
          },
          header: {
            actions: '',
          },
          toolbar: {
            searchTooltip: 'Buscar',
            addRemoveColumns: 'Agregar o quitar columnas',
            searchPlaceholder: 'Buscar',
          },
          pagination: {
            labelRowsSelect: 'filas',
            labelDisplayedRows: '{count} de {from}-{to}',
            firstTooltip: 'Primera pág.',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
        style={{ width: '67vw' }}
      />
    </Grid>
  );
};

MacroEconomicTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { constructionFlow } = state.towerBuilder.root.currentTower;
  return {
    columns: constructionFlow
      ? constructionFlow.columns
      : [
          { field: 'accumulated', title: 'Acumulado' },
          { field: 'projected', title: 'Proyectado' },
          { field: 'total', title: 'Total' },
        ],
    data: constructionFlow ? constructionFlow.constructionWithCurve : [],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MacroEconomicTable);
