import React, { FC } from 'react';
import moment from 'moment';
import { Box, Card, Grid } from '@material-ui/core';
import { InsertInvitationOutlined } from '@material-ui/icons';
import PlusInput from 'shared/components/PlusInput/PlusInput';
import TextField from 'shared/components/TextField/TextField';
import Typography from 'shared/components/Typography/Typography';
import GridContent from '../../components/DeedExpensesGridContent/DeedExpensesGridContent';
import useDeedExpensesAVGDeliveryDisplacement from 'App/DeedExpenses/core/customHooks/useDeedExpensesAVGDeliveryDisplacement';

const DeedExpensesFutureSales: FC = () => {
  const { data, onChange, isLoading } = useDeedExpensesAVGDeliveryDisplacement();
  return (
    <Box display="flex" alignItems="center" justifyContent="center" marginTop="80px">
      {data?.averageDeliveryDate && (
        <>
          <Box>
            <Card>
              <Box paddingX="50px" paddingY="20px" maxWidth="520px">
                <Grid container>
                  <GridContent gridProps={{ xs: 8 }}>
                    <InsertInvitationOutlined />
                    <Typography variant="body1" fontWeight="bold" style={{ marginLeft: '6px' }}>
                      Fecha cuota final cotizaciones
                    </Typography>
                  </GridContent>
                  <GridContent gridProps={{ xs: 4 }}>
                    <Typography variant="body1" fontStyle="italic">
                      {moment(Number(data.averageDeliveryDate)).format('MMM-YYYY')}
                    </Typography>
                  </GridContent>
                  <GridContent gridProps={{ xs: 8 }} paddingTop="40px">
                    <Box>
                      <Typography variant="body1" fontWeight="bold">
                        Desplazamiento
                      </Typography>
                      <br />
                      <Typography variant="body2">Fecha promedio de entrega</Typography>
                    </Box>
                  </GridContent>
                  <GridContent gridProps={{ xs: 4 }} paddingTop="40px">
                    <PlusInput
                      value={data?.averageDeliveryDisplacement ?? 0}
                      onChange={onChange}
                      isDisabled={isLoading}
                    />
                  </GridContent>
                  <GridContent gridProps={{ xs: 8 }} maxWidth="124px">
                    <Typography variant="body1" fontWeight="bold">
                      Fecha promedio de escrituración
                    </Typography>
                  </GridContent>
                  <GridContent gridProps={{ xs: 4 }}>
                    <TextField
                      disabled
                      value={moment(Number(data.averageDeliveryDate))
                        .add(data?.averageDeliveryDisplacement ?? 0, 'M')
                        .format('MMM-YYYY')}
                    />
                  </GridContent>
                </Grid>
              </Box>
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DeedExpensesFutureSales;
