import { InputAdornment } from '@material-ui/core';
import React, { FC } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import Typography from 'shared/components/Typography/Typography';
import QuotationConfigOutlinedInput from '../../components/QuotationConfigOutlinedInput/QuotationConfigOutlinedInput';
import QuotationConfigFormItem from '../QuotationConfigFormItem';

interface Props extends NumberFormatProps {
  name: string;
  endLabel?: string;
}

const BankInformationFormItem: FC<Props> = ({ name, endLabel, ...props }) => {
  return (
    <>
      <QuotationConfigFormItem item xs={7}>
        <Typography variant="body1" color="gray800">
          {name}
        </Typography>
      </QuotationConfigFormItem>
      <QuotationConfigFormItem item xs={5}>
        <NumberFormat
          customInput={QuotationConfigOutlinedInput}
          {...props}
          // @ts-ignore
          fullWidth
          endAdornment={<InputAdornment position="end">{endLabel}</InputAdornment>}
        />
      </QuotationConfigFormItem>
    </>
  );
};

export default BankInformationFormItem;
