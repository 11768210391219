import { API_PATH } from '../../../config/config';

const BusinessPartnerServiceDefinition = {
  getBusinessPartners: (companyId) =>
    `${API_PATH}contract/business-partner-in-company/${companyId}`,
  createBusinessPartner: () => `${API_PATH}contract/business-partner-view`,
  editBusinessPartner: (id) =>
    `${API_PATH}contract/business-partner/edit/view/${id}`,
  deleteBusinessPartner: (id) =>
    `${API_PATH}contract/business-partner/delete/view/${id}`,
  download: () => `${API_PATH}contract/business-partner/download`,
  postBusinessPartnerTemplate: (companyId) =>
    `${API_PATH}contract/business-partner/template/${companyId}`,
};

export default BusinessPartnerServiceDefinition;
