import { MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import useBoolean from './useBoolean';

const POPOVER_EVENT_NAME = 'popover:open';

interface Props<T> {
  elementRef?: RefObject<HTMLElement>;
}

export const useCustomPopoverImplementation = <T>(props: Props<T>) => {
  const { elementRef } = props;
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();
  const [element, setElement] = useState<HTMLElement>();
  const [payload, setPayload] = useState<T>();

  const handlePopoverEvent = (event: Event) => {
    if (event instanceof CustomEvent && event.type === POPOVER_EVENT_NAME && event.detail.element) {
      setElement(event.detail.element);
      open();
      setPayload(event.detail.payload);
    }
  };

  const initialize = () => {
    if (elementRef?.current) {
      const { current } = elementRef;
      current.addEventListener(POPOVER_EVENT_NAME, handlePopoverEvent);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return {
    isOpen,
    element,
    payload,
    open,
    close,
  };
};

export type HandlePopoverOpenType<T> = (e: MouseEvent, payload?: T) => void;

const useCustomPopover = <T extends {}>() => {
  const elementRef = useRef<HTMLDivElement>(null);

  const handlePopoverOpen: HandlePopoverOpenType<T> = (e, payload) => {
    const event = new CustomEvent(POPOVER_EVENT_NAME, {
      detail: {
        element: e.currentTarget,
        payload: payload || {},
      },
    });
    if (elementRef.current) elementRef.current.dispatchEvent(event);
  };

  return {
    elementRef,
    handlePopoverOpen,
  };
};

export default useCustomPopover;
