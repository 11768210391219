import React from 'react';
import { ContractLot } from '../../../../models/Contracts';
import ContractLotFormMainView from '../../../ContractLotFormModal/UI/ContractLotFormMainView';
import useContractsLots from '../../core/customHooks/useContractsLots';
import ContractLotsToolbar from './components/ContractLotsToolbar';
import ContractsLotsDeleteConfirmModal from './components/ContractsLotsDeleteConfirmModal';
import ContractDetailMainView from '../../../ContractDetail/UI/ContractDetailMainView';
import ContractsLotsGrid from './tables/ContractsLotsGrid';
import ContractsListPopover from './tables/ContractsListPopover';
import useCustomPopover from 'shared/customHooks/useCustomPopover';

const ContractsLotsMain = () => {
  const {
    contracts,
    handleChangeSearch,
    currentContract,
    setCurrentContract,
    openContractForm,
    setOpenContractForm,
    openContractDetail,
    setOpenContractDetail,
    handleDelete,
    asyncConfirmDeleteContractModalRef,
    isNewContract,
    setIsNewContract,
  } = useContractsLots();

  const { elementRef, handlePopoverOpen } = useCustomPopover<ContractLot>();

  return (
    <>
      <ContractLotsToolbar
        handleChangeSearch={handleChangeSearch}
        handleNewLot={() => {
          setIsNewContract(true);
          setOpenContractForm(true);
        }}
      />
      <ContractsLotsGrid onHandlePopoverOpen={handlePopoverOpen} contracts={contracts || []} />
      <ContractsListPopover
        elementRef={elementRef}
        handleActionDeleteClicked={(contract) => {
          handleDelete(contract);
        }}
        handleActionEditClicked={(contract) => {
          setIsNewContract(true);
          setCurrentContract(contract);
          setOpenContractForm(true);
        }}
        handleActionDetailsClicked={(contract) => {
          setCurrentContract(contract);
          setOpenContractDetail(true);
        }}
      />
      {!!currentContract && (
        <ContractDetailMainView
          key={'key-Detail-Contract-Dialog'}
          open={openContractDetail}
          currentContractId={currentContract.id}
          handleClose={() => {
            setCurrentContract(undefined);
            setOpenContractDetail(false);
          }}
          handleEditContract={() => {
            setIsNewContract(false);
            setOpenContractForm(true);
          }}
          handleDeleteContract={(): void => {
            handleDelete(currentContract);
          }}
        />
      )}
      <ContractLotFormMainView
        key={'key-Contract-Lot-Form-Dialog'}
        currentContract={currentContract}
        open={openContractForm}
        handleClose={() => {
          if (isNewContract === true) {
            setCurrentContract(undefined);
          }
          setOpenContractForm(false);
        }}
        handleCreate={(contract: ContractLot) => {
          if (!!contract) {
            setCurrentContract(contract);
            setOpenContractDetail(true);
          }
        }}
      />
      <ContractsLotsDeleteConfirmModal asyncConfirmDeleteContractModalRef={asyncConfirmDeleteContractModalRef} />
    </>
  );
};

export default ContractsLotsMain;
