import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import EditContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/Payments/contexts';
import QuotationsServices from '../../../../services/Quotations/Quotations.services';
import QuotationFunctions from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/helpers/quotationFunctions';
import QuotationDiscountType from 'App/Quotation/Core/enums/QuotationDiscountType';
import { propertyHasCompletePriceForPlan } from '../../../BuyerCostumers/Core/customHooks/useBuyerPropertyDetailWithQuotationsStates';
import { useEffect } from 'react';
import Numbers from '../../../../helpers/numbers';

const useAssignedPlanDiscountGoalSeek = (
  quotation,
  enableDiscountCalculation,
  updateQuotation,
  setLoadedDiscount,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const isEditing = useContext(EditContext);
  const { towerId } = useParams();
  const [enableDiscount, setEnable] = useState(false);

  const isEnabledForAutomatic = (distributionWithInitialPrice) => {
    if (
      quotation.targetPercentages &&
      distributionWithInitialPrice.length === quotation.targetPercentages.length
    ) {
      const distributionWithPercentages = distributionWithInitialPrice.reduce(
        (accumulated, price, index) =>
          accumulated + price / (1 + quotation.targetPercentages[index]),
        quotation.reservePercentage,
      );

      const missingPrice = Numbers.cleanNumber(
        Math.ceil(quotation.targetPrice - distributionWithPercentages),
      );

      return Math.abs(missingPrice) <= 100;
    }
    return false;
  };

  useEffect(() => {
    const distributionCopy = [...quotation.distribution];
    distributionCopy.shift();

    const distributionWithInitialPrice = [
      ...distributionCopy,
      quotation.finalPayment,
    ];
    let isEnabled = false;

    if (quotation.discountType === QuotationDiscountType.ASSIGNED.code) {
      isEnabled = isEnabledForAutomatic(distributionWithInitialPrice);
    } else {
      isEnabled = propertyHasCompletePriceForPlan(
        { ...quotation, distribution: distributionCopy },
        {
          priceWithAdditionalAreas: quotation.priceWithAdditionalAreas,
          discount: quotation.discount,
          tradeDiscount: Number(quotation.discountPrice),
        },
        quotation.finalPayment,
      );
    }

    setEnable(isEnabled);
  }, [
    quotation.distribution,
    quotation.reservePercentage,
    quotation.finalPayment,
    quotation.targetPercentages,
    quotation.targetPrice,
    quotation.discountType,
    quotation.discountPrice,
  ]);

  const onExecuteDiscount = async (updatedQuotation = null) => {
    const services = new QuotationsServices();
    try {
      if (!enableDiscountCalculation) return;
      const currentQuotation = updatedQuotation || quotation;
      const response = await services.getQuotationDistributionForAssigned(
        towerId,
        currentQuotation.id,
        [
          ...currentQuotation.distribution,
          Number(currentQuotation.finalPayment),
        ],
        currentQuotation.priceWithAdditionalAreas - currentQuotation.discount,
        currentQuotation.isLockedType,
        [
          ...currentQuotation.indexSelected,
          0,
          currentQuotation.distribution.length,
        ],
      );

      const {
        distribution: newDistribution,
        discountPrice,
        priceWithDiscount,
        discountPercentage,
        targetPercentages,
        targetPrice,
        isSuccess,
      } = response.data;

      if (!isSuccess && !currentQuotation.isLockedType) {
        throw new Error(
          'Lo sentimos, no se puede generar un descueto con los valores actuales. Trata de ajustar las cuotas 🤓 O seleccionar una más alta ',
        );
      }

      const maxDistributionLength = Math.max(newDistribution.length - 1, 1);
      const ceilDistribution = newDistribution.map(Math.ceil);
      const newDistributionWithDiscount = ceilDistribution.splice(
        0,
        maxDistributionLength,
      );
      const [finalPrice] = ceilDistribution;

      const distributionPrice = QuotationFunctions.calculatePropertyPrice(
        newDistributionWithDiscount,
        0,
        0,
      );

      const quotationWithDiscount = {
        ...currentQuotation,
        distribution: newDistributionWithDiscount,
        discountPrice,
        discountPercentage,
        priceWithDiscount,
        targetPercentages,
        targetPrice,
        distributionPrice,
      };

      if (Number(finalPrice) !== Number(currentQuotation.finalPayment)) {
        quotationWithDiscount.finalFee = finalPrice;
        quotationWithDiscount.finalPayment = finalPrice;
      }
      setLoadedDiscount(true);
      updateQuotation(quotationWithDiscount);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    isEditing,
    enableDiscount,
    onClickDiscount: onExecuteDiscount,
  };
};

export default useAssignedPlanDiscountGoalSeek;
