import React, { FC } from 'react';

import { Dialog, DialogContent, DialogActions, Box, Grid, DialogTitle } from '@material-ui/core';

import Styles from './ContractPaymentScheduleFormMainView.module.scss';

import useContractPaymentScheduleForm from '../Core/customHooks/useContractPaymentScheduleForm';
import { ContractPaymentSchedule } from '../../../models/ContractPayments';

import Typography from '../../../shared/components/Typography/Typography';
import { Button, MuiIconButton } from '../../../shared/components/Button/Button';

import PaymentDateType from './Views/customViews/PaymentDateType';
import { IPaymentDate } from '../Core/interfaces/IPaymentDate';
import PaymentValue from './Views/customViews/PaymentValue';
import IconButton from 'shared/components/IconButton/IconButton';

interface IContractPaymentScheduleFormMainView {
  open: boolean;
  currentContractPaymentId: number;
  currentContractId: number;
  currentContractPaymentSchedule: ContractPaymentSchedule | undefined;
  contractPaymentType: string;
  handleClose: () => void;
  handleCreate: (contractPayment: ContractPaymentSchedule) => void;
  handlePaymentUpdate: (contractPaymentSchedule: ContractPaymentSchedule) => void;
}

const ContractPaymentScheduleFormMainView: FC<IContractPaymentScheduleFormMainView> = ({
  open,
  currentContractPaymentId,
  currentContractId,
  currentContractPaymentSchedule,
  contractPaymentType,
  handleClose,
  handleCreate,
  handlePaymentUpdate,
}) => {
  const { data, errors, handleSubmit, handleChangeValue, handleChangeData } = useContractPaymentScheduleForm({
    currentContractId,
    currentContractPaymentId,
    contractPaymentType,
    currentContractPaymentSchedule,
    handleClose,
    handleCreate,
    handlePaymentUpdate,
  });

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography color="gray800" fontWeight="700">
            Editar cuota
          </Typography>

          <IconButton
            className={Styles.FormMainView_closeIcon}
            iconName="Clear"
            variant="ghost"
            size="small"
            aria-controls="close-schedule-form"
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent className={Styles.FormMainView_content}>
          <form onSubmit={handleSubmit} id="ContractPaymentScheduleForm" />
          <Box className={Styles.BoxTitle} py={2} px={6}>
            <Typography fontWeight="600">Detalles</Typography>
          </Box>
          <Box mx={2} my={2} px={5}>
            <PaymentDateType
              currentContractPaymentSchedule={currentContractPaymentSchedule}
              data={data}
              errors={errors}
              handleChangeData={handleChangeData}
            />
            <PaymentValue
              data={data}
              errors={errors}
              handleChangeValue={handleChangeValue}
              currentContractPaymentSchedule={currentContractPaymentSchedule}
              contractPaymentType={contractPaymentType}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
          <Box m={2}>
            <Button size="small" variant="contained" type="submit" form="ContractPaymentScheduleForm">
              Guardar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractPaymentScheduleFormMainView;
