import { useState } from 'react';

function useColumnsForProjectResultsState(projectResultsStateRows) {
  const columns = projectResultsStateRows && projectResultsStateRows.columns;
  const columnBands = projectResultsStateRows && projectResultsStateRows.columnBands;

  const [tableColumnExtensions] = useState([{ columnName: 'name', width: 350 }]);

  const [leftColumns] = useState(['name']);

  return {
    columns,
    columnBands,
    leftColumns,
    tableColumnExtensions,
  };
}

export default useColumnsForProjectResultsState;
