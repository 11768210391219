import { MenuItem as MuiMenuItem, MenuItemProps } from '@material-ui/core';
import styled from 'styled-components';

import colors from '../../../assets/styles/settings/colors.module.scss';
import fonts from '../../../assets/styles/settings/fonts.module.scss';

const MenuItem = styled(MuiMenuItem)<MenuItemProps>`
  /* Box-model */
  padding: 7px 16px;
  min-width: 160px;

  /* Typograpphy */
  color: ${colors.gray900};
  font-family: ${fonts.body1FontFamily};
  font-size: ${fonts.body1FontSize};
  font-style: ${fonts.body1FontStyle};
  font-weight: ${fonts.body1FontWeight};
  letter-spacing: ${fonts.body1LetterSpacing};
  line-height: ${fonts.body1LineHeight};

  &:focus {
    /* Visual */
    outline: none;
  }

  &:hover {
    /* Visual */
    background-color: ${colors.primary200};
  }

  &.Mui-selected {
    /* Typography */
    color: ${colors.gray000};

    /* Visual */
    background-color: ${colors.primary800};
  }

  &.Mui-selected:hover {
    /* Visual */
    background-color: ${colors.primary800};
  }
`;

export default MenuItem;
