import {
  ChangeEventHandler,
  Dispatch,
  ElementRef,
  RefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Category, Contract, ForeignData, ContractsFlow } from '../../../../models/Contracts';
import { Event } from '../../../../models/ContractPayments';
import Services from '../../../../services/Contracts/ContractsServices';
import EventServices from '../../../../services/ContractEvents/ContractEventsServices';
import AsyncConfirmModal from '../../../../shared/components/AsyncModal/AsyncConfirmModal';

const services = new Services();
const eventServices = new EventServices();

const useForeignData = (towerId: string) => {
  return useQuery<ForeignData>(
    'contracts-foreign-list',
    async () => {
      const { data: foreignData } = await services.getAllContractForeignList(towerId);
      return foreignData;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
    },
  );
};

const useContractsFlowData = (queryTxt: string, towerId: string) => {
  return useQuery<ContractsFlow[]>(
    'contracts-flow',
    async () => {
      const { data: contractsFlow } = await services.getContractsFlow(towerId);
      return contractsFlow;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      select: (contracts: ContractsFlow[]) => {
        if (queryTxt && contracts) {
          const filtered = contracts.filter((contract: ContractsFlow) =>
            contract.name.toLowerCase().includes(queryTxt),
          );

          return filtered;
        }
        return contracts;
      },
    },
  );
};

const useContractsData = (queryTxt: string, towerId: string) => {
  return useQuery<Category[]>(
    'contracts',
    async () => {
      const { data: grupedcontracts } = await services.getAllContracts(towerId);
      return grupedcontracts;
    },
    {
      refetchOnWindowFocus: false,
      select: (categories) => {
        if (queryTxt && categories) {
          return filterContracts(categories, queryTxt);
        }
        return categories;
      },
    },
  );
};

const useContractEventsData = (towerId: string) => {
  return useQuery<Event[]>(
    ['contract-events', towerId],
    async () => {
      const { data: contractEvents } = await eventServices.getContractEventsByTower(towerId);
      return contractEvents;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
    },
  );
};

const useDeleteContract = () => {
  const queryClient = useQueryClient();
  return useMutation((contractId: number) => services.deleteContract(contractId), {
    onSettled: () => {
      queryClient.invalidateQueries('contracts');
    },
  });
};

const filterContracts = (categories: Category[], query: string) => {
  const filteredCategories: Category[] = [];

  categories.map((category) => {
    const { contracts: contractItems } = category;

    const inTitle = category.categoryName.toLowerCase().includes(query);

    const filtered = contractItems.filter((contract: Contract) => {
      return (
        contract.id.toString().includes(query) ||
        contract.title.toLowerCase().includes(query) ||
        contract.item.name.toLowerCase().includes(query) ||
        contract.contractorName.toLowerCase().includes(query) ||
        contract?.state?.name.toLowerCase().includes(query)
      );
    });

    if (inTitle) {
      filteredCategories.push(category);
    } else if (filtered.length > 0) {
      const addCategory: Category = {
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        contracts: filtered,
        numberOfContracts: filtered.length,
      };
      filteredCategories.push(addCategory);
    }
  });

  return filteredCategories;
};

type AsyncConfirmModalRefType = ElementRef<typeof AsyncConfirmModal>;

type HookType = () => {
  contracts: Category[] | undefined;
  isLoading: boolean;
  handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  currentContract: Contract | undefined;
  setCurrentContract: Dispatch<SetStateAction<Contract | undefined>>;
  openContractsFlow: boolean;
  setOpenContractsFlow: Dispatch<SetStateAction<boolean>>;
  openContractForm: boolean;
  setOpenContractForm: Dispatch<SetStateAction<boolean>>;
  openContractDetail: boolean;
  setOpenContractDetail: Dispatch<SetStateAction<boolean>>;
  isNewContract: boolean;
  setIsNewContract: Dispatch<SetStateAction<boolean>>;
  getContractsFlow: () => void;
  contractsFlowIsLoading: boolean;
  downloadFC: () => void;
  handleDelete: (contract: Contract) => Promise<void>;
  asyncConfirmDeleteContractModalRef: RefObject<AsyncConfirmModalRefType>;
};

const useContracts: HookType = () => {
  const { towerId }: { towerId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [queryTxt, setQueryTxt] = useState('');
  const asyncConfirmDeleteContractModalRef = useRef<AsyncConfirmModalRefType>(null);

  const { data: contracts, isLoading } = useContractsData(queryTxt, towerId);
  const { data: contractsForeignData } = useForeignData(towerId);
  const { data: contractsEventsData } = useContractEventsData(towerId);
  const { mutateAsync: deleteContract } = useDeleteContract();
  const { isLoading: contractsFlowIsLoading, refetch: getContractsFlowData } = useContractsFlowData(queryTxt, towerId);

  const [currentContract, setCurrentContract] = useState<Contract | undefined>();

  const [openContractsFlow, setOpenContractsFlow] = useState<boolean>(false);

  const [openContractForm, setOpenContractForm] = useState<boolean>(false);
  const [openContractDetail, setOpenContractDetail] = useState<boolean>(false);
  const [isNewContract, setIsNewContract] = useState<boolean>(false);

  const handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    e.preventDefault();
    const query: string = e.target.value.toLowerCase();

    setQueryTxt(query);
  };

  const getContractsFlow = () => {
    getContractsFlowData();
  };

  const downloadFC = async () => {
    try {
      const name = 'Flujo_de_caja_contratos.xlsx';
      const response = await services.downloadContractsFlow(towerId);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        enqueueSnackbar(error.message);
      } else {
        enqueueSnackbar('Error interno servidor');
      }
    }
  };

  const handleDelete = useCallback(async (contract: Contract) => {
    await asyncConfirmDeleteContractModalRef.current?.show(async () => {
      try {
        await deleteContract(contract.id);
        setCurrentContract(undefined);
        enqueueSnackbar('Contrato con eliminado con éxito', {
          variant: 'success',
        });
      } catch ({ message }) {
        enqueueSnackbar(`${message}`, { variant: 'error' });
      }
    });
  }, []);

  return {
    contracts,
    isLoading,
    handleChangeSearch,
    currentContract,
    setCurrentContract,
    openContractsFlow,
    setOpenContractsFlow,
    openContractForm,
    setOpenContractForm,
    openContractDetail,
    setOpenContractDetail,
    getContractsFlow,
    contractsFlowIsLoading,
    downloadFC,
    handleDelete,
    asyncConfirmDeleteContractModalRef,
    isNewContract,
    setIsNewContract,
  };
};

export default useContracts;
