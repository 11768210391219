import React, { Fragment, useState } from 'react';
import NumberFormat from 'react-number-format';
import Input from '../../UI/Input/Input';
import styles from '../FutureSalesSpeed.module.scss';
import Numbers from '../../../helpers/numbers';

const inputSeparationValidation = (averagePrice) => [
  {
    fn: (value) => {
      const finalFee = value <= averagePrice;
      return finalFee;
    },
    message:
      'La Cuota Final es menor a 0% por favor escoja una Separación menor',
  },
];

const inputInitialFeeValidation = (averagePrice) => [
  {
    fn: (value) => {
      return value <= 100;
    },
    message: 'El valor debe ser menor a 100%',
  },
  {
    fn: (value) => {
      return value >= 0;
    },
    message: 'El valor debe ser mayor a 0%',
  },
];

const DinamicCells = ({
  group,
  i,
  salesSpeeds,
  futureSalesSpeedHandler,
  separationHandler,
  initialFeeHandler,
  setTotal,
  arraySalesSpeeds,
  setPropsArraySalesSpeeds,
  ...rest
}) => {
  const [separate, setSeparate] = useState(group.separate);
  const [finalFee, setFinalFee] = useState(group.separate + group.initialFee);
  const [firstFee, setFirstFee] = useState(group.initialFee);

  const hasZeroUnits = () => group.units === 0;
  const initialFee = group.averagePrice * firstFee;
  return (
    <Fragment key={`fragment ${group.id}`}>
      <div className={styles.gridItem}>{group.name}</div>
      <div className={styles.gridItem}> {group.units}</div>
      <div className={styles.gridItem}>
        <NumberFormat
          value={Math.round(group.averagePrice)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </div>
      <div className={styles.gridItem}>{group.averageArea.toFixed(2)}</div>
      <div className={styles.gridItem}>
        {group.futureSalesSpeed ? group.futureSalesSpeed.toFixed(2) : 0}
      </div>
      <div className={styles.gridItem}>
        <Input
          value={separate}
          mask="currency"
          style={{ width: '120px' }}
          validations={inputSeparationValidation(group.averagePrice)}
          onChange={(target) => {
            const reserveRate = Number(target.value);
            const finalFeeValidation =
              reserveRate <= group.averagePrice && reserveRate >= 0;
            if (finalFeeValidation) {
              separationHandler(group.id, reserveRate, (isRollback) => {
                if (isRollback) {
                  separate(separate);
                } else {
                  setSeparate(reserveRate);
                }
              });
            } else {
              setSeparate(separate);
            }
          }}
        />
      </div>
      <div className={styles.gridItem}>
        <Input
          validations={inputInitialFeeValidation()}
          value={(firstFee * 100).toFixed(2)}
          mask="percentage"
          style={{ width: '120px' }}
          disable={hasZeroUnits()}
          onChange={(target) => {
            initialFeeHandler(
              group.id,
              Numbers.toFixed(target.value / 100),
              Number(separate),
              (isRollback) => {
                if (isRollback) {
                  setFirstFee(0);
                  setFirstFee(firstFee);
                } else {
                  setFirstFee(target.value / 100);
                }
              },
            );
          }}
        />
      </div>
      <div className={styles.gridItem}>
        <label>
          {
            <NumberFormat
              value={Numbers.toFixed(
                100 -
                  Math.max(
                    firstFee * 100,
                    (separate / group.averagePrice) * 100,
                  ),
              )}
              displayType={'text'}
              thousandSeparator={true}
              suffix={'%'}
            />
          }
        </label>
      </div>
    </Fragment>
  );
};

export default DinamicCells;
