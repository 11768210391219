import { atom } from 'recoil';
import { ValueChange } from '../interfaces/IAreasEditor';

export const AreaEditionValueAtom = atom<ValueChange>({
  key: 'AreaEditionValueAtom',
  default: {
    rowId: 0,
    columnId: '',
    defaultValue: 0,
    value: 0,
    record: false,
  },
});
