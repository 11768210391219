import React, { FC } from 'react';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import usePropertyEditionRowsMapper from '../../../Core/customHooks/usePropertyEditionRowsMapper';
import PropertyEditionRow from '../rows/PropertyEditionRow';
import AbsoluteCircularProgress from '../../../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';

const useStyles = makeStyles({
  Container: {
    maxHeight: '90vh',
  },
});

interface PropertyEditionTableProps {
  isSold: boolean;
}

const PropertyEditionTable: FC<PropertyEditionTableProps> = ({ isSold }) => {
  const { rows, columns, isLoading, updateLoading } = usePropertyEditionRowsMapper(isSold);
  const styles = useStyles();

  return (
    <TableContainer component={Paper} className={styles.Container}>
      {isLoading && <AbsoluteCircularProgress />}
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((columnHeader, index) => (
              <TableCell key={`columnHeader-${index}`} align={columnHeader.align}>
                {columnHeader.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <PropertyEditionRow key={row.id} property={row} isLoading={isLoading} updateLoading={updateLoading} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyEditionTable;
