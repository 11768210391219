import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import Icon from '../../../../assets/icons/OnboardingComents.svg';

import styles from './GeneralWalletDetailsEmptyState.module.scss';

const GeneralWalletDetailsEmptyState: FC = () => (
  <div className={styles.NonIdealState}>
    <div className={styles.content}>
      <img src={Icon} height="267" width="267" />
      <Box mt="24px">
        <Typography variant="body1">
          Escribe un comentario o agrega un archivo para mantenerte conectado con tus clientes
        </Typography>
      </Box>
    </div>
  </div>
);

export default GeneralWalletDetailsEmptyState;
