import { useState, ChangeEvent } from 'react';

const useFeatureFieldEditorTabs = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return { value, handleTabChange };
};

export default useFeatureFieldEditorTabs;
