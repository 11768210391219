const resolvePropertiesChanged = <T extends Object>(originalModel: T, newModel: T) => {
    const updatedData: Partial<T> = {};
    if (newModel && originalModel) {
        Object.keys(newModel).forEach((keyString) => {
            const key = keyString as keyof Partial<T>;
            if (newModel[key] !== originalModel[key])
                updatedData[key] = newModel[key];
        });
    }
    return { haveChanges: !!Object.keys(updatedData).length, updatedData }
}

export default resolvePropertiesChanged