import { useSelector } from 'react-redux';
import QuotationFunctions from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/helpers/quotationFunctions';
import { getFinalPayment } from '../../../../helpers/quotations';

export const propertyHasCompletePriceForPlan = (
  assignedPlan,
  currentPropertyInfo,
  finalFee = null,
) => {
  const totalPrice =
    currentPropertyInfo.priceWithAdditionalAreas -
    currentPropertyInfo.discount -
    assignedPlan.discountPrice;

  if (!totalPrice) {
    return false;
  }

  const missingValue = Math.abs(
    QuotationFunctions.missingPrice(
      assignedPlan.distribution,
      assignedPlan.reservePercentage,
      Number(
        finalFee ??
          getFinalPayment(
            assignedPlan.propertyPrice,
            assignedPlan.initialFeePercentage,
          ),
      ),
      totalPrice,
    ),
  );

  return missingValue < 100;
};

function useBuyerPropertyDetailWithQuotationsStates() {
  const { currentClient, currentPropertyInfo, currentPropertyFiles } =
    useSelector((state) => state.buyerCostumers.root);

  let propertyHasPaymentPlan = null;
  if (currentClient) {
    const assignedPlan = currentClient.quotations.find(
      (quotation) => quotation.isAssigned,
    );

    if (assignedPlan && currentPropertyInfo) {
      propertyHasPaymentPlan = propertyHasCompletePriceForPlan(
        assignedPlan,
        currentPropertyInfo,
        assignedPlan.finalPayment,
      );
    }
  }

  return {
    currentClient,
    currentPropertyInfo,
    propertyHasPaymentPlan,
    currentPropertyFiles,
  };
}

export default useBuyerPropertyDetailWithQuotationsStates;
