import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SalesRoomService from '../../../../services/salesRoom/salesRoomService';
import { updatePropertiesWithPrices } from '../actions';

const useGraphicMaterialRequest = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [existingProperties, setExistingProperties] = useState(null);

  const services = new SalesRoomService();

  const fetch = async () => {
    setLoading(true);
    try {
      const properties = await services.getProperties(towerId, null);
      dispatch(updatePropertiesWithPrices(properties.data));
      setLoading(false);
      setExistingProperties(properties.data.incrementList.properties);
    } catch (error) {
      setExistingProperties([]);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  if (!existingProperties && !isLoading) {
    fetch();
  }

  return { isLoading };
};
export default useGraphicMaterialRequest;
