import React, { FC, useContext } from 'react';
import { CardHeader, Drawer, Toolbar } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import Styles from '../../ContractDetailMainView.module.scss';
import { MuiIconButton } from '../../../../../shared/components/Button/Button';
import useCustomPopover from 'shared/customHooks/useCustomPopover';
import { Contract } from 'models/Contracts';

import ContractDetailDescription from './ContractDetailDescription';
import ContractDetailDrawerMenu from './ContractDetailDrawerMenu';
import ContractDetailStatistics from './ContractDetailStatistics';
import { ContractDetailContext, ContractDetailContextState } from '../../ContractDetailMainView';
import ContractDetailPopover from './ContractDetailPopover';

interface IContractDetailDrawer {
  handleEditContract: () => void;
  handleDeleteContract: () => void;
}

const ContractDetailDrawer: FC<IContractDetailDrawer> = ({ handleEditContract, handleDeleteContract }) => {
  const { currentContract } = useContext(ContractDetailContext) as ContractDetailContextState;

  const { elementRef, handlePopoverOpen } = useCustomPopover<Contract>();
  return (
    <Drawer
      className={Styles.drawer}
      variant="permanent"
      classes={{
        paper: Styles.drawerPaper,
      }}
    >
      <Toolbar />
      <CardHeader
        className={Styles.titleBold}
        title={currentContract!.title}
        action={
          <MuiIconButton aria-label="menu popover contract" onClick={handlePopoverOpen}>
            <MoreVert />
          </MuiIconButton>
        }
      />

      <ContractDetailDescription />
      <ContractDetailDrawerMenu />
      <ContractDetailStatistics />
      <ContractDetailPopover
        elementRef={elementRef}
        handleActionEditClicked={handleEditContract}
        handleActionDeleteClicked={handleDeleteContract}
      />
    </Drawer>
  );
};

export default ContractDetailDrawer;
