import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

const BoxWithBackground = styled(Box)`
  background-color: rgb(79, 79, 87);
`;

const TypographyWithBackground = styled(Typography)`
  color: white;
`;

const ClientHubTitleWithBackgroundRow = ({ title }) => {
  return (
    <BoxWithBackground
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={0.3}
    >
      <TypographyWithBackground variant="body2">
        {title}
      </TypographyWithBackground>
    </BoxWithBackground>
  );
};

ClientHubTitleWithBackgroundRow.propTypes = {
  title: PropTypes.string,
};

export default ClientHubTitleWithBackgroundRow;
