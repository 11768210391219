import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import { Typography } from '@material-ui/core';
import EditableCell from '../EditableCell';
import {
  CustomPaymentTableRow,
  CustomPaymentTableCell,
} from './CustomPaymentTableRow';
import { DEFAULT_DATE_FORMAT } from '../../../../../../helpers/dates';
import QuotationPaymentTypeRow from '../../../../../Quotation/UI/views/rows/QuotationPaymentTypeRow';
import useRealPaymentEditionType from '../../../../Core/customHooks/useRealPaymentEditionType';

const ReservationDeposit = ({
  quotation,
  showEditableButtons,
  dispatchFetchQuotationSuccess,
}) => {
  const {
    paymentStartDate,
    proposalPaymentStartDate,
    reservePercentage,
    paymentTypes,
  } = quotation;

  const startDate = paymentStartDate;

  const typeId =
    paymentTypes && paymentTypes.length > 0 ? paymentTypes[0] : '0';

  const { isEditingMode } = useRealPaymentEditionType();

  return (
    <CustomPaymentTableRow isEditing={isEditingMode}>
      <TableCell>
        <Typography>Separación</Typography>
      </TableCell>

      <CustomPaymentTableCell isEditing={false}>
        <Typography>
          <QuotationPaymentTypeRow
            key={`QuotationPaymentTypeRow_${0}_${typeId}`}
            index={0}
            typeId={typeId}
            quotation={quotation}
            dispatchFetchQuotationSuccess={dispatchFetchQuotationSuccess}
          />
        </Typography>
      </CustomPaymentTableCell>

      <TableCell>
        {moment(Number(startDate))
          .format(DEFAULT_DATE_FORMAT)
          .toString()}
      </TableCell>
      <TableCell>
        <EditableCell
          name="reservePercentage"
          value={reservePercentage}
          showEditableButtons={showEditableButtons}
          onBlur={() => {}}
          onEnter={() => {}}
        />
      </TableCell>
    </CustomPaymentTableRow>
  );
};

ReservationDeposit.propTypes = {
  quotation: PropTypes.shape({
    paymentStartDate: PropTypes.any,
    reservePercentage: PropTypes.any,
    currentIndexForDiscount: PropTypes.any,
    paymentTypes: PropTypes.array,
    proposalPaymentStartDate: PropTypes.any,
  }).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  dispatchFetchQuotationSuccess: PropTypes.func.isRequired,
};

export default ReservationDeposit;
