import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { useParams } from 'react-router-dom';
import FinalFeeRow from './FinalFee';
import IsEditableContext from './contexts';
import useAssignmentQuotation, {
  validateMissingPrice,
} from '../helpers/customHooks/useAssignmentQuotation';
import QuotationsServices from '../../../../../services/Quotations/Quotations.services';
import Numbers from '../../../../../helpers/numbers';
import QuotationDiscountType from '../../../../Quotation/Core/enums/QuotationDiscountType';
import MonthlyEditablePayment from '../../customViews/MonthlyEditablePayment';
import AssignedPaymentHeaderRow from '../../rows/AssignedPaymentHeaderRow';
import EditDashboard from './EditDashboard/EditDashboard';

const services = new QuotationsServices();

const validationScheme = yup.object().shape({
  finalFee: yup
    .number()
    .min(0)
    .required(),
  reservationDeposit: yup.number().required(),
  distribution: yup
    .array()
    .of(yup.number())
    .required(),
});

const AssignedPayments = ({
  quotation,
  dispatchFetchQuotationSuccess,
  isEditable,
  setIsEditable,
  showEditableButtons,
  enableDiscountCalculation,
  enableDiscount,
}) => {
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();

  const { updateClientsWithQuotation } = useAssignmentQuotation();

  const submitHandler = async (values) => {
    try {
      const {
        finalFee,
        reservationDeposit,
        periods,
        paymentStartDate,
        discountPrice,
        discountType,
      } = values;
      const response = await services.patchQuotation(quotation.id, {
        finalFee: Number(finalFee),
        reservationDeposit: Number(reservationDeposit),
        // distribution: distribution.map(Number),
        periods: Number(periods),
        paymentStartDate,
        discountPrice: Math.round(discountPrice),
        discountType,
        priceWithDiscount: Math.round(
          Numbers.cleanNumber(quotation.priceWithAdditionalAreas) -
            Numbers.cleanNumber(quotation.discount) -
            Numbers.cleanNumber(quotation.discountPrice),
        ),
        paymentTypes: quotation.paymentTypes.map(Number),
        towerId,
        initialDate: quotation.initialDate,
      });

      dispatchFetchQuotationSuccess(response.data);
      updateClientsWithQuotation(response.data);
      enqueueSnackbar('Cuotas editadas correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const editableToggleModeHandler = () => {
    if (isEditable) {
      try {
        validateMissingPrice(quotation);
        if (
          enableDiscount ||
          (quotation.discountType === QuotationDiscountType.MANUAL.code &&
            quotation.discountPrice)
        ) {
          submitHandler({
            finalFee: quotation.finalPayment,
            reservationDeposit: quotation.reservePercentage,
            distribution: quotation.distribution,
            periods: quotation.periods,
            paymentStartDate: quotation.paymentStartDate,
            discountPrice: quotation.discountPrice,
            priceWithDiscount:
              quotation.priceWithAdditionalAreas -
              quotation.discount -
              quotation.discountPrice,
            discountType: quotation.discountType,
            initialDate: quotation.initialDate,
            finalPaymentType: quotation.finalPaymentType,
            paymentTypes: quotation.paymentTypes,
          });
          setIsEditable((prevIsEditable) => !prevIsEditable);
        } else {
          enqueueSnackbar(
            'Ejecute el validador de descuento antes de guardar',
            {
              variant: 'error',
            },
          );
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    } else {
      setIsEditable((prevIsEditable) => !prevIsEditable);
    }
  };

  const onChangeForm = (event, handleChange) => {
    return handleChange(event);
  };

  return (
    <IsEditableContext.Provider value={isEditable}>
      <Formik
        initialValues={{
          finalFee: quotation.finalPayment,
          reservePercentage: quotation.reservePercentage,
          distribution: quotation.distribution,
          paymentStartDate: quotation.paymentStartDate,
        }}
        validationSchema={validationScheme}
        onSubmit={submitHandler}
        innerRef={formRef}
      >
        {({ handleChange }) => {
          const onChange = (e) => onChangeForm(e, handleChange);
          return (
            <Form>
              {showEditableButtons && !quotation.isPaymentEdition && (
                <EditDashboard
                  editableToggleModeHandler={editableToggleModeHandler}
                  distribution={quotation.distribution}
                  propertyPrice={Number(
                    quotation.priceWithAdditionalAreas -
                      quotation.discount -
                      quotation.discountPrice,
                  )}
                  reservationDeposit={Number(quotation.reservePercentage)}
                  finalFee={Number(quotation.finalPayment)}
                  discountPrice={quotation.discountPrice}
                  discountPercentage={quotation.discountPercentage}
                  priceWithDiscount={
                    quotation.priceWithAdditionalAreas -
                    quotation.discount -
                    quotation.discountPrice
                  }
                  enableDiscount={enableDiscount}
                />
              )}
              <TableContainer component={Paper}>
                <MuiTable>
                  <TableHead>
                    <AssignedPaymentHeaderRow
                      showEditableButtons={true}
                      enableDiscountCalculation={true}
                    />
                  </TableHead>

                  <TableBody>
                    {quotation.distribution.map((payment, index) => (
                      <MonthlyEditablePayment
                        quotation={quotation}
                        key={`MonthlyPayment-${index}`}
                        isEditable={isEditable}
                        onChange={onChange}
                        showEditableButtons={showEditableButtons}
                        enableDiscountCalculation={enableDiscountCalculation}
                        dispatchFetchQuotationSuccess={
                          dispatchFetchQuotationSuccess
                        }
                        payment={payment}
                        index={index}
                      />
                    ))}
                    <FinalFeeRow
                      onChange={onChange}
                      quotation={quotation}
                      showEditableButtons={showEditableButtons}
                      enableDiscountCalculation={enableDiscountCalculation}
                      dispatchFetchQuotationSuccess={
                        dispatchFetchQuotationSuccess
                      }
                    />
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Form>
          );
        }}
      </Formik>
    </IsEditableContext.Provider>
  );
};

AssignedPayments.propTypes = {
  quotation: PropTypes.shape({
    id: PropTypes.string,
    paymentStartDate: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    propertyPrice: PropTypes.number,
    initialFeePercentage: PropTypes.number,
    isPaymentEdition: PropTypes.bool,
    reservePercentage: PropTypes.number,
    finalFee: PropTypes.number,
    finalPayment: PropTypes.number,
    periods: PropTypes.number,
    deliveryDate: PropTypes.string,
    distribution: PropTypes.arrayOf(PropTypes.number),
    discountPrice: PropTypes.number,
    discountPercentage: PropTypes.number,
    discount: PropTypes.number,
    priceWithDiscount: PropTypes.number,
    finalPaymentType: PropTypes.number,
    priceWithAdditionalAreas: PropTypes.number,
    discountType: PropTypes.any,
    initialDate: PropTypes.any,
    paymentTypes: PropTypes.array,
    realPayments: PropTypes.array,
    property: PropTypes.shape({
      tower: PropTypes.shape({
        schedule: PropTypes.shape({
          averageDeliveryDate: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  enableDiscount: PropTypes.bool,
  realPayments: PropTypes.array,
  distribution: PropTypes.array,
  dispatchFetchQuotationSuccess: PropTypes.func,
};

export default AssignedPayments;
