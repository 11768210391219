import { useState, useEffect } from 'react';
import { Table, Column } from '@devexpress/dx-react-grid';

import { Property } from '../../../../../../models/Properties';

import { rightAlign } from '../../../../../../shared/tables/TableColumnFormat';

interface IPricesData {
  price: number;
  title: string;
  priceM2: number | string;
}

const calculateM2 = (price: number, totalM2: number): number => {
  let itemM2 = 0;
  if (totalM2 > 0) {
    itemM2 = Math.ceil(price / totalM2);
  }
  return itemM2;
};

const getArrayData = (property: Property): IPricesData[] => {
  const { basePriceValidated, additionalAreasPrice, discount, tradeDiscount, totalArea, additionalAreas } = property;
  const priceWithAdd = basePriceValidated + additionalAreasPrice;
  const soldPrice = Math.ceil(
    basePriceValidated + property.additionalAreasPrice - property.discount - property.tradeDiscount,
  );

  return [
    {
      price: basePriceValidated,
      title: 'Inmueble',
      priceM2: calculateM2(basePriceValidated, totalArea),
    },
    {
      price: Math.ceil(additionalAreasPrice),
      title: 'Adicionales',
      priceM2: calculateM2(additionalAreasPrice, totalArea),
    },
    {
      price: Math.ceil(priceWithAdd),
      title: 'Precio con adicionales',
      priceM2: calculateM2(priceWithAdd, totalArea),
    },
    {
      price: Math.ceil(discount),
      title: 'Descuento comercial',
      priceM2: calculateM2(discount, totalArea),
    },
    {
      price: Math.abs(Math.ceil(tradeDiscount)),
      title: `${tradeDiscount > 0 ? 'Descuento' : 'Incremento'} financiero`,
      priceM2: calculateM2(tradeDiscount, totalArea),
    },
    {
      price: soldPrice,
      title: 'Valor Cierre de Negocio',
      priceM2: calculateM2(soldPrice, totalArea),
    },
  ];
};

const getColumns = (): Column[] => {
  return [
    {
      title: 'Tipo',
      name: 'title',
    },
    {
      title: 'Total',
      name: 'price',
    },
    {
      title: 'Valor m2',
      name: 'priceM2',
    },
  ];
};

interface UseColumnsAndRowsForPricesParams {
  property: Property;
}

type HookType = (param: UseColumnsAndRowsForPricesParams) => {
  columns: Column[];
  data: IPricesData[];
  titleColumns: string[];
  currencyColumns: string[];
  tableColumnExtensions: Table.ColumnExtension[];
};

const useColumnsAndRowsForPrices: HookType = ({ property }) => {
  const tableColumnFormat: Table.ColumnExtension[] = [
    { columnName: 'priceM2', align: rightAlign },
    { columnName: 'price', align: rightAlign },
  ];

  const [columns] = useState(getColumns());
  const [data, setData] = useState<IPricesData[]>(getArrayData(property));
  const [titleColumns] = useState(['title']);
  const [currencyColumns] = useState(['price', 'priceM2']);
  const [tableColumnExtensions] = useState(tableColumnFormat);

  useEffect(() => {
    setData(getArrayData(property));
  }, [property]);

  return {
    columns,
    data,
    titleColumns,
    currencyColumns,
    tableColumnExtensions,
  };
};

export default useColumnsAndRowsForPrices;
