import { MouseEventHandler } from 'react';

import { StageTowerDTO } from '../DTO/StageTowersDTO';
import { OnGoToTower } from '../types/Stages.types';

function useTowerClick(tower: StageTowerDTO, onGoToTower: OnGoToTower) {
  const handleTowerClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const openNewTab = event.ctrlKey || event.metaKey;

    onGoToTower({ tower, openNewTab });
  };

  return handleTowerClick;
}

export default useTowerClick;
