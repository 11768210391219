import { useEffect } from 'react';
import { useQuery } from 'react-query';
import AreaServices from '../../../../services/area/AreaServices';
import { GET_QUERY_AREAS_CHECK_DISABLED } from '../ReactQuery/AreaReactQueryConstants';

const service = new AreaServices();

const useFetchAreaDisabled = (towerId: string) => {
  const query = useQuery(
    GET_QUERY_AREAS_CHECK_DISABLED,
    async () => {
      const { data: serviceData } = await service.isDisable(towerId);
      return serviceData;
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    return () => query.remove();
  }, []);

  return {
    response: query.data,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
  };
};
export default useFetchAreaDisabled;
