import { useState } from 'react';
import { Column, GridColumnExtension, SortingState } from '@devexpress/dx-react-grid';
import { ClientModel } from 'App/Clients/Core/models/ClientModel';
import { ClientListColumnNames, ColumnWidths } from '../ClientListColumnDefinitions';

export interface UseClientListProps {
  clients: ClientModel[];
}

const useClientList = (props: UseClientListProps) => {
  const { clients } = props;
  const [columns] = useState<Column[]>(ClientListColumnNames);
  const [columnWidths] = useState<GridColumnExtension[]>(ColumnWidths);
  const [sortingStateColumnExtensions] = useState<SortingState.ColumnExtension[]>([
    { columnName: 'createdAt', sortingEnabled: true },
    { columnName: 'name', sortingEnabled: true },
    { columnName: 'associated', sortingEnabled: false },
    { columnName: 'identityDocument', sortingEnabled: false },
    { columnName: 'email', sortingEnabled: false },
  ]);

  return {
    rows: clients,
    columns,
    columnWidths,
    sortingStateColumnExtensions,
  };
};

export default useClientList;
