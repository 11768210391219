import { Plugin, Template } from '@devexpress/dx-react-core';
import { Box } from '@material-ui/core';
import React from 'react';
import Typography from '../../../../shared/components/Typography/Typography';
import Styles from './ContractsEvalproGridDetailToolbar.module.scss';

const pluginDependencies = [{ name: 'Toolbar' }];

interface ContractsEvalproGridDetailToolbarProps {
  eventTitle: string;
}
const ContractsEvalproGridDetailToolbar: React.FC<ContractsEvalproGridDetailToolbarProps> = ({ eventTitle }) => {
  return (
    <Plugin name="ContractsEvalproGridDetailToolbar" dependencies={pluginDependencies}>
      <Template name="toolbarContent">
        <Box p={1} className={Styles.gridHeader}>
          <Typography variant="body2" color="gray000">
            {eventTitle}
          </Typography>
        </Box>
      </Template>
    </Plugin>
  );
};

export default ContractsEvalproGridDetailToolbar;
