import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Services from '../../../../services/ContractEvents/ContractEventsServices';

import { IUseFormResult, useForm } from '../../../../shared/customHooks/useForm';
import { Event } from '../../../../models/ContractPayments';

const services = new Services();

const useAddContractEvent = (handleCreate: (contractEvent: Event) => void, towerId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractEvent: Event) =>
      services.createContractEvent(towerId, {
        ...contractEvent,
        towerId,
      }),
    {
      onSuccess: (data) => {
        handleCreate(data.data);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['contract-events', towerId]);
      },
    },
  );
};

const useEditContractEvent = (contractEventId: number, towerId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractEvent: Partial<Event>) =>
      services.editContractEvent(contractEventId, {
        ...contractEvent,
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['contract-events', towerId]);
      },
    },
  );
};

export interface UseContractEventFormParams {
  currentContractEvent?: Event;
  handleCreate: (contractEvent: Event) => void;
  handleClose: () => void;
}

export interface UseContractEventFormResult extends IUseFormResult<Event> {
  currentContractEvent?: Event;
  handleSubtractDisplacement: (preValue: number) => void;
  handleAddDisplacement: (preValue: number) => void;
}

type HookType = (param: UseContractEventFormParams) => UseContractEventFormResult;

const useContractEventForm: HookType = ({ currentContractEvent, handleCreate, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId }: { towerId: string } = useParams();

  const { mutateAsync: addContractEvent } = useAddContractEvent(handleCreate, towerId);
  const { mutateAsync: editContractEvent } = useEditContractEvent(currentContractEvent?.id || 0, towerId);

  const handleSubtractDisplacement = (prevValue: number) => {
    if (prevValue > 0) {
      const value = Number(prevValue) - 1;
      form.handleChangeValue('displacement', `${value}`);
    }
  };

  const handleAddDisplacement = (prevValue: number) => {
    const value = Number(prevValue) + 1;
    form.handleChangeValue('displacement', `${value}`);
  };

  const createContractEvent = async (contractPayment: Event): Promise<void> => {
    try {
      await addContractEvent(contractPayment);
      enqueueSnackbar('Hito creado con éxito', {
        variant: 'success',
      });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
    handleClose();
  };

  const updateContractEvent = async (contractEvent: Event): Promise<void> => {
    if (currentContractEvent) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contractEvent).forEach((keyString) => {
        const key = keyString as keyof Partial<Event>;
        if (contractEvent[key] !== currentContractEvent[key]) updatedData[key] = contractEvent[key];
      });

      if (Object.keys(updatedData).length) {
        const data = {
          id: contractEvent.id,
          ...updatedData,
        };
        try {
          await editContractEvent(data);
          enqueueSnackbar('Hito actualizado con éxito', {
            variant: 'success',
          });
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
    handleClose();
  };

  const form = useForm<Event>(
    {
      validations: {
        description: {
          required: {
            value: true,
            message: 'Nombre es requerido',
          },
        },
        customDate: {
          required: {
            value: true,
            message: 'Fecha es requerida',
          },
        },
      },
      initialValues: {
        id: currentContractEvent?.id || undefined,
        description: currentContractEvent?.description || undefined,
        customDate: currentContractEvent?.customDate || undefined,
        displacement: 0,
      },
      async onSubmit(newData) {
        try {
          if (currentContractEvent?.id) await updateContractEvent(newData);
          else await createContractEvent(newData);
        } catch (error) {
          enqueueSnackbar('Error interno servidor');
        }
      },
    },
    [currentContractEvent],
  );

  return {
    ...form,
    handleSubtractDisplacement,
    handleAddDisplacement,
  };
};

export default useContractEventForm;
