export const HANDLE_CHANGE_REPORTS = 'HANDLE_CHANGE_REPORTS';
export const HANDLE_CHANGE_PREV_REPORTS = 'HANDLE_CHANGE_PREV_REPORTS';

export const setReports = (payload) => ({
  type: HANDLE_CHANGE_REPORTS,
  payload,
});

export const setPrevReports = (payload) => ({
  type: HANDLE_CHANGE_PREV_REPORTS,
  payload,
});
