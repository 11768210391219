import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';

import Typography from '../../../shared/components/Typography/Typography';
import useCustomPopover from '../../../shared/customHooks/useCustomPopover';
import useContractPaymentsSchedule from '../Core/customHooks/useContractPaymentsSchedule';
import ContractPaymentScheduleGrid from './Views/customViews/ContractPaymentScheduleGrid';
import ContractPaymentScheduleGridPopover from './Views/customViews/ContractPaymentScheduleGridPopover';
import ContractPaymentScheduleGridInterestPopover from './Views/customViews/ContractPaymentScheduleGridInterestPopover';

interface IContractLotPaymentsScheduleMainView {
  currentContractId: number;
}

const ContractLotPaymentsScheduleMainView: FC<IContractLotPaymentsScheduleMainView> = ({ currentContractId }) => {
  const {
    contractPayments,
    isLoading,
    handleRestartProjected,
    handleAutofitProjected,
    handleInterestRestartProjected,
  } = useContractPaymentsSchedule(currentContractId);

  const { elementRef: popoverRef, handlePopoverOpen } = useCustomPopover<{}>();
  const { elementRef: popoverInterestRef, handlePopoverOpen: handleInterestPopoverOpen } = useCustomPopover<{}>();

  return (
    <>
      <Box paddingY={3}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" fontSize="1.5rem" fontWeight="600">
              Ejecución del Contrato
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <ContractPaymentScheduleGrid
        rows={contractPayments}
        isLoading={isLoading}
        currentContractId={currentContractId}
        handlePopoverOpen={handlePopoverOpen}
        handleInterestPopoverOpen={handleInterestPopoverOpen}
      />
      <ContractPaymentScheduleGridPopover
        elementRef={popoverRef}
        handleActionRestartProjectedClicked={handleRestartProjected}
        handleActionAutofitProjectedClicked={handleAutofitProjected}
      />
      <ContractPaymentScheduleGridInterestPopover
        elementRef={popoverInterestRef}
        handleActionRestartProjectedClicked={handleInterestRestartProjected}
      />
    </>
  );
};

export default ContractLotPaymentsScheduleMainView;
