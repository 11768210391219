import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import IsEditableContext from '../../UI/Dialog/Payments/contexts';
import DialogContext from '../../UI/Dialog/context';

export default function useRealPaymentEditionType(
  index,
  typeId,
  currentQuotation,
  updateQuotation,
) {
  const { enqueueSnackbar } = useSnackbar();

  const context = useContext(DialogContext);

  const quotation = currentQuotation ||
    context.quotation || { quotationSetup: {} };
  const isEditingModeFromContext = useContext(IsEditableContext);
  const isEditingMode =
    isEditingModeFromContext || quotation.temp || quotation.isAssigned;

  const [paymentSelected, setCurrentPayment] = useState(typeId);

  const onChange = (event) => {
    const paymentTypesCopy = [...quotation.paymentTypes];
    paymentTypesCopy[index] = Number(event.target.value);
    setCurrentPayment(event.target.value);
    updateQuotation({ ...quotation, paymentTypes: paymentTypesCopy });
  };

  useEffect(() => {
    if (typeId && paymentSelected !== typeId) {
      setCurrentPayment(typeId);
    }
  }, [typeId]);

  const toggleEditionType = () => {
    enqueueSnackbar(
      `Reiniciado! ${
        quotation.isLockedType ? 'Cuota manual' : 'Cuota automática'
      }`,
      {
        variant: 'success',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      },
    );
    context.handleEditionTypeChange(!quotation.isLockedType);
  };

  return {
    options: quotation.quotationSetup.quotationPaymentTypes || [],
    paymentSelected,
    isEditing: isEditingMode,
    isEditingMode,
    onChange,
    toggleEditionType,
    ...quotation,
    ...context,
  };
}
