import React from 'react';
import { Typography } from '@material-ui/core';
import useWeeklyReport from '../Core/useWeeklyReport';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import Report from './Views/Report';
import DateFilter from './Views/DateFilter';

export default function SalesWeeklyReport() {
  const {
    isLoading,
    reports,
    columns,
    selectedDate,
    handleDateChange,
    clearFilter,
    limitDates,
  } = useWeeklyReport();
  return (
    <>
      <Typography variant="h4" component="h4" gutterBottom>
        Informes semana a semana
      </Typography>
      {isLoading && <AbsoluteCircularProgress topPercentage={0} />}
      <>
        <DateFilter
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          clearFilter={clearFilter}
          limitDates={limitDates}
        />
        {reports.map((report, index) => (
          <Report
            report={report}
            key={`table-in-position-${index}`}
            columns={columns}
          />
        ))}
      </>
    </>
  );
}
