import { DataTypeProvider } from '@devexpress/dx-react-grid';
import React from 'react';

import GeneralWalletCellUtils from '../../core/GeneralWalletCellUtils';
import stylesComponents from '../styles/components.module.scss';

const utils = new GeneralWalletCellUtils(stylesComponents);

const GeneralWalletTextFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value, column }) => (
  <span className={utils.getClassName(value, column?.name)}>{value}</span>
);

export default GeneralWalletTextFormatter;
