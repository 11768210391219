import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectorOfDisplayedFilter } from '../../App/SalesRoom/Core/actions';
import Button from '../UI/Button/Button';
import CustomButton from '../Buttons/CustomButton';
import Styles from './Selectors.module.scss';
import agent from '../../config/config';
import { Role } from '../../helpers';

const PRICE_WITH_INCREMENTS = 'priceWithIncrements';
const PRICE = 'price';
const M2 = 'mts2';
const GROUPS = 'groups';
const NAME = 'name';
const TYPE = 'type';

const Selectors = (props) => {
  const [selectedButton, setSelectedButton] = useState(
    `${props.agent === 'super' ? PRICE_WITH_INCREMENTS : NAME}`,
  );

  const dispatch = useDispatch();

  return (
    <>
      <div className={Styles.container}>
        <CustomButton
        type={selectedButton !== PRICE_WITH_INCREMENTS ? "secondary" : "selectedSecondary"}
        onClick={() => {
          setSelectedButton(PRICE_WITH_INCREMENTS);
          dispatch(setSelectorOfDisplayedFilter(PRICE_WITH_INCREMENTS));
          props.makeArrayOfProperties(props.response, PRICE_WITH_INCREMENTS);
        }}
        >
        Precio
        </CustomButton>
        <CustomButton
        type={selectedButton !== M2 ? "secondary" : "selectedSecondary"}
        name="Mt2"
        onClick={() => {
          setSelectedButton(M2);
          dispatch(setSelectorOfDisplayedFilter('mts2'));
          props.makeArrayOfProperties(props.response, 'mts2');
        }}
        >
        Mt2
        </CustomButton>
        {agent.isAuthorized([Role.Admin, Role.Super]) && (
          <CustomButton
          type={selectedButton !== GROUPS ? "secondary" : "selectedSecondary"}
            onClick={() => {
              setSelectedButton(GROUPS);
              dispatch(setSelectorOfDisplayedFilter('groups'));
              props.makeArrayOfProperties(props.response, 'groups');
            }}
            >
            Grupos
          </CustomButton>
        )}
        <CustomButton
        type={selectedButton !== NAME ? "secondary" : "selectedSecondary"}
        onClick={() => {
          setSelectedButton(NAME);
          dispatch(setSelectorOfDisplayedFilter('name'));
          props.makeArrayOfProperties(props.response, 'name');
        }}
        >
          Nomenclatura
        </CustomButton>
        <CustomButton
        type={selectedButton !== TYPE ? "secondary" : "selectedSecondary"}
        onClick={() => {
          setSelectedButton(TYPE);
          dispatch(setSelectorOfDisplayedFilter('type'));
          props.makeArrayOfProperties(props.response, 'type');
        }}
        >
          Tipo
        </CustomButton>
      </div>
    </>
  );
};

export default Selectors;
