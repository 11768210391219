import React, { FC, RefObject } from 'react';
import { ClientDetail } from 'App/GeneralWallet/core/interfaces/generalWallet.types';
import PopoverAction from 'shared/components/PopoverAction/PopoverAction';
import CustomPopover, { CustomPopoverContentProps } from 'shared/components/CustomPopover/CustomPopover';
import useGeneralWalletPopoverActions, {
  UseGeneralWalletPopoverActionsProps,
} from 'App/GeneralWallet/core/customHooks/useGeneralWalletPopoverActions';

type GeneralWalletPopoverContentProps = CustomPopoverContentProps<UseGeneralWalletPopoverActionsProps, ClientDetail>;

const GeneralWalletPopoverContent: FC<GeneralWalletPopoverContentProps> = (props) => {
  const { payload: client, extraProps, onClose: cb } = props;
  const { goDetails, goToMonthlyInterestReport } = useGeneralWalletPopoverActions({ client, cb, extraProps });
  return (
    <>
      <PopoverAction onClick={goDetails}>Ver mensajes</PopoverAction>
      <PopoverAction onClick={goToMonthlyInterestReport}>Ver informe</PopoverAction>
    </>
  );
};

interface Props extends UseGeneralWalletPopoverActionsProps {
  elementRef: RefObject<HTMLDivElement>;
}

const GeneralWalletPopover: FC<Props> = ({ elementRef, ...props }) => {
  return (
    <CustomPopover<UseGeneralWalletPopoverActionsProps, ClientDetail>
      elementRef={elementRef}
      contentComponent={GeneralWalletPopoverContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      extraProps={props}
    />
  );
};

export default GeneralWalletPopover;
