import { API_PATH } from '../../config/config';

const ContractsServicesDefinition = {
  getAllEvalproGroups: (towerId: string) => `${API_PATH}contracts-evalpro/${towerId}`,
  getAllEvalproDetailsGroups: (towerId: string) => `${API_PATH}contracts-evalpro/details/${towerId}`,
  getEvalproTowerStatistics: (towerId: string) => `${API_PATH}contracts-evalpro/statistics/${towerId}`,
  getEvalproFlow: (towerId: string) => `${API_PATH}contracts-evalpro/flow/${towerId}`,
  downloadEvalproFlow: (towerId: string) => `${API_PATH}contracts-evalpro/flow/download/${towerId}`,
  createContractEvalproGroupValue: (towerId: string) => `${API_PATH}contracts-evalpro/${towerId}`,
  createContractEvalproDetailValue: (towerId: string) => `${API_PATH}contracts-evalpro/details/${towerId}`,
};

export default ContractsServicesDefinition;
