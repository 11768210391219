import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';
import { cellEditorState, updateBudgetRows } from '../../../TotalCashFlow/Core/actions';
import ResultsStateServices from '../../Services/ResultsStateServices';
import { useSnackbar } from 'notistack';

const service = new ResultsStateServices();

function useBudgetTableHeader() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { editableBudgetRows, budgetRows } = useSelector((state) => state.cashFlow.root || {});
  const { towerId } = useParams<{ towerId: string }>();

  const [openAlert, setOpenAlert] = useState(true);
  const [useAlertProps, setUseAlertProps] = useState({
    isOpen: false,
    title: '',
    variant: AlertVariants.Accept,
    acceptAction: () => {},
  });

  const { showAlert } = useAlert(useAlertProps);

  const cutProps = () => {
    return {
      isOpen: false,
      title: 'Datos sin guardar',
      variant: AlertVariants.Accept,
      acceptAction: () => {
        dispatch(cellEditorState(false));
      },
    };
  };

  const getAlertDescription = () => 'Los datos no guardados se perderán';

  const cancelSaveHandleClick = () => {
    setUseAlertProps(cutProps());
    showAlert(getAlertDescription());
  };

  const rowsFilteredFunction = (newRows) => { 
    const rowsFiltered = budgetRows && budgetRows.map(row => newRows.find(newRow => newRow.budgetId === row.budgetId && newRow.budgetIndex === row.budgetIndex) || row)

    return rowsFiltered;
  }

  const handleUpdateBudget = async () => {
    const rows = editableBudgetRows;

    const response = await service.overwriteEvent({ towerId, rows });

    const newRowsMapper = rowsFilteredFunction(rows)
    response.data && dispatch(updateBudgetRows(newRowsMapper));

    response.data
      ? enqueueSnackbar('El evento se edito correctamente', { variant: 'success' })
      : enqueueSnackbar('No se pudo editar el evento', { variant: 'error' });
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  return {
    cancelSaveHandleClick,
    openAlert,
    handleClose,
    handleUpdateBudget,
  };
}

export default useBudgetTableHeader;
