import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { GeneralWalletThreadDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import useGeneralWalletDetailsThreadResponse from 'App/GeneralWalletDetails/core/customHooks/useGeneralWalletDetailsThreadResponse';
import GeneralWalletInput from './GeneralWalletInput';

import styles from './GeneralWalletDetailsThread.module.scss';

const GeneralWalletDetailsThreadResponse: FC<GeneralWalletThreadDTO> = (thread) => {
  const { isOpen, open, handleSubmit } = useGeneralWalletDetailsThreadResponse(thread);
  return (
    <div className={styles.ThreadNewInteractionContainer}>
      {isOpen ? (
        <GeneralWalletInput onSubmit={handleSubmit} isSuccess={false} />
      ) : (
        <Button className={styles.ThreadNewInteractionButton} children={<span>Responder</span>} onClick={open} />
      )}
    </div>
  );
};

export default GeneralWalletDetailsThreadResponse;
