import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({openDialog, closeDeleteDialog, onAccept}) {

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Señor usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Esta seguro de eliminar este archivo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            closeDeleteDialog()
          }} color="primary">
            Cancelar
          </Button>
          <Button 
          color="primary"
          onClick={onAccept} 
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
