import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import QuotationsServices from 'services/Quotations/Quotations.services';
import { QuotationPayload } from '../interfaces/DeedExpensesDisplacementCellProps';
import { DeedExpensesPropertiesSoldResponse } from '../interfaces/DeedExpensesPropertiesSoldResponse';
import { PROPERTY_SOLD } from '../reactQuery/RequestConstants';

const service = new QuotationsServices();

const useDeedExpensesSalesMadeTableActions = () => {
  const queryClient = useQueryClient();

  const change = () => {
    const data = queryClient.getQueryData<DeedExpensesPropertiesSoldResponse>(PROPERTY_SOLD);
    if (data) {
      const newData: DeedExpensesPropertiesSoldResponse = {
        ...data,
        properties: data.properties.map((property) => ({ ...property, loading: true })),
      };
      queryClient.setQueryData(PROPERTY_SOLD, newData);
    }
  };

  const { mutate } = useMutation(
    ({ quotationId, displacement }: QuotationPayload) =>
      service.putQuotation(quotationId, { deedDisplacement: displacement }).then(change),
    {
      onMutate: change,
      onSuccess() {
        queryClient.invalidateQueries(PROPERTY_SOLD);
      },
    },
  );

  const handleChangeDisplacement = useCallback(mutate, []);

  return {
    handleChangeDisplacement,
  };
};

export default useDeedExpensesSalesMadeTableActions;
