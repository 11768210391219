import { useState } from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { AreasTableCustomColumn } from '../../AreasTableCustomColumn';
import { AreasColumnExtentions, DefualtRightColumns } from '../../constants/AreasConstants';
import { IAreaCustomColumn } from '../../interfaces/IAreaCustomColumn';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';

const areasTableCustomColumn = new AreasTableCustomColumn();

const useAreaStaticTable = (areaTypes: IAreaCustomColumn[]) => {
  const [columns] = useState<Column[]>(areasTableCustomColumn.getDefaultLeftColumns(areaTypes, DefualtRightColumns));
  const [columnsExtentions] = useState<VirtualTable.ColumnExtension[]>(
    areasTableCustomColumn.getColumnExtentions(areaTypes, AreasColumnExtentions),
  );

  return {
    columns,
    columnsExtentions,
  };
};
export default useAreaStaticTable;
