import React, { FC, ReactNode } from 'react';
import _ from 'lodash';

import VirtualTableDataTypeProviderColumn from '../interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from '../types/ProviderColumnType';
import TableCellCurrencyTypography from '../../UI/customViews/TableCellCurrencyTypography';
import TableCellTypographyProps from '../interfaces/TableCellTypographyProps';
import TableCellGreenPositiveTypography from '../../UI/customViews/TableCellGreenPositiveTypography';
import TableCellGreenNegativeTypography from '../../UI/customViews/TableCellGreenNegativeTypography';
import TableCellPlainTypography from '../../UI/customViews/TableCellPlainTypography';
import TableCellPercentTypography from '../../UI/customViews/TableCellPercentTypography';
import TableCellSemaphoreTypography from '../../UI/customViews/TableCellSemaphoreTypography';
import TableCellTooltipTypography from '../../UI/customViews/TableCellTooltipTypography';
import TableCellGrayFuture from '../../UI/customViews/TableCellGrayFuture';
import TableCellDateFormat from '../../UI/customViews/TableCellDateFormat';
import TableCellNumericTypography from '../../UI/customViews/TableCellNumericTypography';

function useMapperForDataTypeProvider(columns: VirtualTableDataTypeProviderColumn[]) {
  const columnsForType = (types: ProviderColumnType | ProviderColumnType[]): string[] => {
    return columns.flatMap((column) => {
      const currentType = column.type || ProviderColumnType.plain;
      if (Array.isArray(types) && Array.isArray(column.type)) {
        const typesSet = new Set(types);
        const columnTypesSet = new Set(column.type);

        if (_.isEqual(columnTypesSet, typesSet)) return column.name;

        return [];
      }

      if (types === currentType) return column.name;
      return [];
    });
  };

  const providerFactory = (type: ProviderColumnType): FC<TableCellTypographyProps> => {
    switch (type) {
      case ProviderColumnType.numeric:
        return TableCellNumericTypography;
      case ProviderColumnType.currency:
        return TableCellCurrencyTypography;
      case ProviderColumnType.greenPositive:
        return TableCellGreenPositiveTypography;
      case ProviderColumnType.greenNegative:
        return TableCellGreenNegativeTypography;
      case ProviderColumnType.percent:
        return TableCellPercentTypography;
      case ProviderColumnType.semaphore:
        return TableCellSemaphoreTypography;
      case ProviderColumnType.tooltip:
        return TableCellTooltipTypography;
      case ProviderColumnType.grayFuture:
        return TableCellGrayFuture;
      case ProviderColumnType.dateFormat:
        return TableCellDateFormat;
      default:
        return TableCellPlainTypography;
    }
  };

  const providerComponent = (type: ProviderColumnType[] | ProviderColumnType): FC<TableCellTypographyProps> => {
    if (Array.isArray(type)) {
      const DefaultComponent: FC<TableCellTypographyProps> = providerFactory(ProviderColumnType.plain);

      return type.reduce((LastComponent, currentType) => {
        const NewComponent = providerFactory(currentType);

        const CustomComponent: FC<TableCellTypographyProps> = ({ value, children, row, column }) => {
          return (
            <NewComponent value={value} row={row} column={column}>
              <LastComponent value={value} row={row} column={column}>
                {children}
              </LastComponent>
            </NewComponent>
          );
        };
        return CustomComponent;
      }, DefaultComponent);
    }

    return providerFactory(type);
  };

  return { columnsForType, providerComponent };
}

export default useMapperForDataTypeProvider;
