import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import SalesSpeedHelperServices from '../../services/SalesSpeedHelperServices';
import { OnMaximumRotationMonthsChange, OnSalesSpeedDataChange } from '../types/SalesSpeedHelper.types';

const salesSpeedHelperServices = new SalesSpeedHelperServices();

type RouteParams = {
  towerId: string;
};

function useUpdateMaximumRotationMonths(onSalesSpeedDataChange: OnSalesSpeedDataChange) {
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const { towerId } = useParams<RouteParams>();
  const { enqueueSnackbar } = useSnackbar();

  const showProgress = () => {
    setIsProgressVisible(true);
  };

  const hideProgress = () => {
    setIsProgressVisible(false);
  };

  const requestUpdate = async (maximumRotationMonths: number) => {
    try {
      showProgress();
      const { data } = await salesSpeedHelperServices.updateMaximumRotationMonths(towerId, maximumRotationMonths);
      onSalesSpeedDataChange(data);
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      hideProgress();
    }
  };

  const handleMaximumRotationMonthsChange: OnMaximumRotationMonthsChange = (maximumRotationMonths) => {
    requestUpdate(maximumRotationMonths);
  };

  return { isProgressVisible, handleMaximumRotationMonthsChange };
}

export default useUpdateMaximumRotationMonths;
