import useBoolean from 'shared/customHooks/useBoolean';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClientsServices from 'services/client/ClientsServices';
import { ClientModel } from '../models/ClientModel';

const service = new ClientsServices();

const useClient = (id?: string) => {
  const { towerId } = useParams<{ towerId: string }>();
  const { value: isFetch, setTrue: fetch, setFalse: closeFetch } = useBoolean();
  const [client, setClient] = useState<ClientModel>();

  const fetchClient = useCallback(async () => {
    if (towerId && id) {
      fetch();
      const { data } = await service.getClientWithId<ClientModel>(towerId, id);
      setClient(data);
      closeFetch();
    }
  }, [id, towerId]);

  useEffect(() => {
    fetchClient();
    return () => {};
  }, [id]);

  return {
    isFetch,
    client,
    fetchClient,
    setClient,
  };
};

export default useClient;
