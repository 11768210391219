import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { QueryClient, useMutation, useQueryClient } from 'react-query';

import Services from '../../../../services/ContractsLots/ContractsLotsServices';

import { IUseFormResult, useForm } from '../../../../shared/customHooks/useForm';
import { Contractor } from '../../../../models/Contractors';
import { ContractLot, State, ForeignData } from '../../../../models/Contracts';

const services = new Services();

const useAddContractData = (handleCreate: (contract: ContractLot) => void) => {
  const { towerId }: { towerId: string } = useParams();
  const queryClient = useQueryClient();
  return useMutation(
    async (contract: ContractLot) => {
      const { data: newContract } = await services.createLot(towerId, {
        ...contract,
        towerId,
      });
      return newContract;
    },
    {
      onSuccess: (newContract) => {
        handleCreate(newContract);
      },
      onSettled: () => {
        queryClient.invalidateQueries('contracts-lots');
      },
    },
  );
};

const useEditContractData = (contractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (contract: Partial<ContractLot>) => {
      const { data: editedContract } = await services.editContractLot(contractId, contract);
      return editedContract;
    },
    {
      onSuccess: (editedContract) => {
        queryClient.setQueryData(['contract', contractId], editedContract);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['contracts-lots']);
        queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
      },
    },
  );
};

export interface UseContractLotFormParams {
  currentContract?: ContractLot;
  handleCreate: (contract: ContractLot) => void;
  handleClose: () => void;
}

export interface UseContractLotFormResult extends IUseFormResult<ContractLot> {
  currentContract?: ContractLot;
  contractorsList?: Contractor[];
  contractStatesList?: State[];
  autocompleteContractorValue: Contractor | null;
  setAutocompleteContractorValue: Dispatch<SetStateAction<Contractor | null>>;
  autocompleteStateValue: State | null;
  setAutocompleteStateValue: Dispatch<SetStateAction<State | null>>;
  inputContractorValue: string;
  setInputContractorValue: Dispatch<SetStateAction<string>>;
  inputStateValue: string;
  setInputStateValue: Dispatch<SetStateAction<string>>;
  minValidDate: number;
  maxValidDate: number;
}

type HookType = (param: UseContractLotFormParams) => UseContractLotFormResult;

const useContractLotForm: HookType = ({ currentContract, handleCreate, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addContract } = useAddContractData(handleCreate);
  const { mutateAsync: editContract } = useEditContractData(currentContract?.id || 0);

  const queryClient: QueryClient = useQueryClient();

  const [contractStatesList, setContractStates] = useState<State[]>();
  const [contractorsList, setContractors] = useState<Contractor[]>();

  const [autocompleteContractorValue, setAutocompleteContractorValue] = useState<Contractor | null>(null);
  const [autocompleteStateValue, setAutocompleteStateValue] = useState<State | null>(null);

  const [inputContractorValue, setInputContractorValue] = useState('');
  const [inputStateValue, setInputStateValue] = useState('');
  const [minValidDate, setMinValidDate] = useState<number>(0);
  const [maxValidDate, setMaxValidDate] = useState<number>(0);

  const data = queryClient.getQueryData<ForeignData>('contracts-foreign-list');

  useEffect(() => {
    if (!!data) {
      const { contractStates, contractors, statistics } = data as ForeignData;

      setMinValidDate(statistics.startStageDate);
      setMaxValidDate(statistics.endStageDate);

      setContractStates(contractStates);
      setContractors(contractors);
    }
    if (currentContract) {
      const contractorSelected =
        contractorsList?.find((v) => Number(v.id) == Number(currentContract?.contractorId)) || null;
      setAutocompleteContractorValue(contractorSelected);

      const stateSelected = contractStatesList?.find((v) => Number(v.id) == Number(currentContract?.stateId)) || null;
      setAutocompleteStateValue(stateSelected);
    } else {
      setAutocompleteContractorValue(null);
      setAutocompleteStateValue(null);
    }
  }, [currentContract, data]);

  const createContract = async (contract: ContractLot): Promise<void> => {
    try {
      await addContract(contract);
      enqueueSnackbar('Lote creado con éxito', {
        variant: 'success',
      });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
    handleClose();
  };

  const updateContract = async (contract: ContractLot): Promise<void> => {
    if (currentContract) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contract).forEach((keyString) => {
        const key = keyString as keyof Partial<ContractLot>;
        if (contract[key] !== currentContract[key]) updatedData[key] = contract[key];
      });

      if (Object.keys(updatedData).length) {
        const data = {
          id: contract.id,
          ...updatedData,
        };
        try {
          await editContract(data);
          enqueueSnackbar('Lote actualizado con éxito', {
            variant: 'success',
          });
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
    handleClose();
  };

  const form = useForm<ContractLot>(
    {
      validations: {
        title: {
          required: {
            value: true,
            message: 'Título del lote es requerido',
          },
        },
        contractorId: {
          required: {
            value: true,
            message: 'El proveedor es requerido',
          },
        },
        stateId: {
          required: {
            value: true,
            message: 'Estado de negociación es requerido',
          },
        },
        lotPurchaseDate: {
          required: {
            value: true,
            message: 'Fecha de compra es requerido',
          },
        },
        lotFirstPaymentDate: {
          required: {
            value: true,
            message: 'Fecha primer pago es requerido',
          },
        },
        lotLastPaymentDate: {
          required: {
            value: true,
            message: 'Fecha último pago es requerido',
          },
        },
      },
      initialValues: {
        id: currentContract?.id || undefined,
        title: currentContract?.title || undefined,
        contractorId: currentContract?.contractorId || undefined,
        description: currentContract?.description || undefined,
        lotPurchaseDate: currentContract?.lotPurchaseDate || undefined,
        lotFirstPaymentDate: currentContract?.lotFirstPaymentDate || undefined,
        lotLastPaymentDate: currentContract?.lotLastPaymentDate || undefined,
        itemId: currentContract?.itemId || undefined,
        stateId: currentContract?.stateId || undefined,
      },
      async onSubmit(newData, reset) {
        try {
          if (currentContract?.id) {
            await updateContract(newData);
          } else {
            await createContract(newData);
            reset();
          }
        } catch (error) {
          enqueueSnackbar('Error interno servidor');
        }
      },
    },
    [currentContract],
  );

  return {
    ...form,
    contractStatesList,
    contractorsList,
    autocompleteContractorValue,
    setAutocompleteContractorValue,
    autocompleteStateValue,
    setAutocompleteStateValue,
    inputContractorValue,
    setInputContractorValue,
    inputStateValue,
    setInputStateValue,
    minValidDate,
    maxValidDate,
  };
};

export default useContractLotForm;
