import React, { FC } from 'react';
import moment from 'moment';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import { DEFAULT_DATE_FORMAT } from '../../helpers/dates';
import Typography from '../components/Typography/Typography';
import NumberFormat from 'react-number-format';

type Align = 'center' | 'right' | 'left';
const rightAlignString = 'right';
const rightAlign: Align = rightAlignString;
const centerAlignString = 'center';
const centerAlign: Align = centerAlignString;

type ValueFormatterProps = DataTypeProvider.ValueFormatterProps;

const PercentFormatter = ({ value }: ValueFormatterProps) => (
  <Typography variant="body2">
    <NumberFormat displayType="text" value={value} thousandSeparator decimalScale={2} fixedDecimalScale suffix="%" />
  </Typography>
);

const PercentTypeProvider: FC<DataTypeProviderProps> = (props: DataTypeProviderProps) => {
  return <DataTypeProvider {...props} formatterComponent={PercentFormatter} />;
};

interface DateFormatterProps {
  format?: string;
}

const DateFormatter =
  ({ format }: DateFormatterProps) =>
  ({ value }: ValueFormatterProps) =>
    (
      <Typography variant="body2">
        {moment(Number(value))
          .format(format || DEFAULT_DATE_FORMAT)
          .toString()}
      </Typography>
    );

const DateTypeProvider: FC<DataTypeProviderProps & { format?: string }> = (
  props: DataTypeProviderProps & { format?: string },
) => {
  return <DataTypeProvider {...props} formatterComponent={DateFormatter(props)} />;
};

const CurrencyFormatter = ({ value }: ValueFormatterProps) => (
  <Typography variant="body2">
    {!isNaN(value) ? (
      <NumberFormat displayType="text" value={value} thousandSeparator decimalScale={0} fixedDecimalScale prefix="$" />
    ) : (
      value
    )}
  </Typography>
);

const CurrencyTypeProvider: FC<DataTypeProviderProps> = (props: DataTypeProviderProps) => {
  return <DataTypeProvider {...props} formatterComponent={CurrencyFormatter} />;
};

const TitleFormatter = ({ value }: ValueFormatterProps) => (
  <Typography color="gray800" fontWeight="600">
    {value}
  </Typography>
);

const TitleTypeProvider: FC<DataTypeProviderProps> = (props: DataTypeProviderProps) => {
  return <DataTypeProvider {...props} formatterComponent={TitleFormatter} />;
};

export { TitleTypeProvider, CurrencyTypeProvider, DateTypeProvider, PercentTypeProvider, rightAlign, centerAlign };
