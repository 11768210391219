import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import PaymentQuotationCashflowServices from '../../Services/PaymentQuotationCashflowServices';

function usePaymentQuotationMainStates(onFetchData) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(true);
  const { towerId } = useParams();

  const service = new PaymentQuotationCashflowServices();
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await service.getCashflowServices(towerId);
        onFetchData(res.data);
        setLoading(false);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    fetchData();
  }, []);

  const downloadTemplate = async () => {
    try {
      const name = 'Excel flujo de caja.xlsx';
      const response = await service.downloadCashflowServices(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    downloadTemplate,
    isLoading,
  };
}

export default usePaymentQuotationMainStates;
