import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import GeneralWalletServices from '../../services/GeneralWalletServices';

function useDownloadGeneralWallet(updateLoading) {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();

  const download = async () => {
    const services = new GeneralWalletServices();
    try {
      updateLoading(true);
      const name = `Cartera.xlsx`;
      const response = await services.downloadGeneralWallet(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      updateLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      updateLoading(false);
    }
  };

  return download;
}

export default useDownloadGeneralWallet;
