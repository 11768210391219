import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import useGroupGoals from '../../../Core/useGroupGoals';
import styles from './Goals.module.scss';

export default function GroupGoals({
  id,
  name,
  rowName,
  totalGoals,
  handleChangeReport,
  propertiesTotal,
}) {
  const {
    editable,
    handleChangeToEditable,
    cancelOperation,
    handleChangeReportValue,
    sendGoal,
    goalField,
  } = useGroupGoals(
    id,
    name,
    rowName,
    totalGoals,
    handleChangeReport,
    propertiesTotal,
  );
  return (
    <>
      {editable ? (
        <div className={styles.editableField}>
          <NumberFormat
            placeHolder="meta acumulada"
            variant="outlined"
            defaultValue={goalField}
            customInput={TextField}
            allowNegative={false}
            onValueChange={handleChangeReportValue}
          />
          <div className={styles.actions}>
            <Button variant="contained" color="primary" onClick={sendGoal}>
              <DoneIcon />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={cancelOperation}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      ) : (
        <Typography
          variant="body1"
          onClick={handleChangeToEditable}
          className={styles.textDecorations}
          component="p"
        >
          {rowName === 'Total aptos' ? totalGoals : goalField}
        </Typography>
      )}
    </>
  );
}

GroupGoals.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.number.isRequired,
  rowName: PropTypes.string.isRequired,
  totalGoals: PropTypes.number.isRequired,
  handleChangeReport: PropTypes.func.isRequired,
  propertiesTotal: PropTypes.number.isRequired,
};
