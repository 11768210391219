import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { omit } from 'lodash';

import Services from '../../../../services/ContractPaymentsSchedule/ContractPaymentsScheduleServices';

import { IUseFormResult, useForm } from '../../../../shared/customHooks/useForm';
import { ContractPaymentSchedule } from '../../../../models/ContractPayments';

const services = new Services();

const useAddContractPayment = (
  handleCreate: (contractPaymentSchedule: ContractPaymentSchedule) => void,
  currentContractPaymentId: number,
  currentContractId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractPaymentSchedule: ContractPaymentSchedule) =>
      services.createContractPaymentSchedule(currentContractPaymentId, {
        ...contractPaymentSchedule,
        contractPaymentId: currentContractPaymentId,
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['contract-payments', currentContractId]);
      },
    },
  );
};

const useEditContractPaymentSchedule = (contractPaymentScheduleId: number, currentContractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractPaymentSchedule: Partial<ContractPaymentSchedule>) => {
      return services.editContractPaymentSchedule(contractPaymentScheduleId, contractPaymentSchedule);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['contract-payments', currentContractId]);
      },
    },
  );
};

interface UseContractPaymentScheduleFormParams {
  currentContractId: number;
  currentContractPaymentId: number;
  currentContractPaymentSchedule?: ContractPaymentSchedule;
  contractPaymentType: string;
  handleCreate: (contractPayment: ContractPaymentSchedule) => void;
  handlePaymentUpdate: (contractPaymentSchedule: ContractPaymentSchedule) => void;
  handleClose: () => void;
}

interface UseContractPaymentScheduleFormResult extends IUseFormResult<ContractPaymentSchedule> {}

type HookType = (param: UseContractPaymentScheduleFormParams) => UseContractPaymentScheduleFormResult;

const useContractPaymentScheduleForm: HookType = ({
  currentContractId,
  currentContractPaymentId,
  currentContractPaymentSchedule,
  contractPaymentType,
  handlePaymentUpdate,
  handleCreate,
  handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addContractPaymentSchedule } = useAddContractPayment(
    handleCreate,
    currentContractPaymentId,
    currentContractId,
  );

  const { mutateAsync: editContractPaymentSchedule } = useEditContractPaymentSchedule(
    currentContractPaymentSchedule?.id || 0,
    currentContractId,
  );

  const createContractPayment = async (contractPaymentSchedule: ContractPaymentSchedule): Promise<void> => {
    handleClose();
  };

  const updateContractPaymentSchedule = async (contractPaymentSchedule: ContractPaymentSchedule): Promise<void> => {
    if (currentContractPaymentSchedule) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contractPaymentSchedule).forEach((keyString) => {
        const key = keyString as keyof Partial<ContractPaymentSchedule>;
        if (contractPaymentSchedule[key] !== currentContractPaymentSchedule[key] || key === 'paymentDateType')
          updatedData[key] = contractPaymentSchedule[key];
      });

      if (Object.keys(updatedData).length) {
        const data = {
          id: contractPaymentSchedule.id,
          ...updatedData,
        };

        try {
          await editContractPaymentSchedule(
            omit(data, 'scheduleLabelDescription', 'event', 'paymentDateType', 'finalDate'),
          );
          enqueueSnackbar('Cuota actualizada con éxito', {
            variant: 'success',
          });
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
    handleClose();
  };

  const form = useForm<ContractPaymentSchedule>(
    {
      validations: {},
      initialValues: {
        id: currentContractPaymentSchedule?.id || undefined,
        amount: currentContractPaymentSchedule?.amount || 0,
        percentage: currentContractPaymentSchedule?.percentage || 0,
        displacement: currentContractPaymentSchedule?.displacement || 0,
        paymentDate: currentContractPaymentSchedule?.paymentDate || 0,
        finalDate: currentContractPaymentSchedule?.finalDate || 0,
        eventId: currentContractPaymentSchedule?.eventId || undefined,
        scheduleId: currentContractPaymentSchedule?.scheduleId || undefined,
        scheduleLabel: currentContractPaymentSchedule?.scheduleLabel || undefined,
        paymentDateType: currentContractPaymentSchedule?.paymentDateType || undefined,
        contractPaymentId: currentContractPaymentSchedule?.contractPaymentId || undefined,
        scheduleLabelDescription: currentContractPaymentSchedule?.scheduleLabelDescription || undefined,
        event: currentContractPaymentSchedule?.event || undefined,
      },
      async onSubmit(newData) {
        try {
          if (currentContractPaymentSchedule?.id) {
            switch (contractPaymentType) {
              case 'PX':
                await updateContractPaymentSchedule(newData);
                break;
              default:
                handlePaymentUpdate(newData);
                handleClose();
                break;
            }
          } else await createContractPayment(newData);
        } catch (error) {
          enqueueSnackbar('Error interno servidor');
        }
      },
    },
    [currentContractPaymentSchedule],
  );

  return form;
};

export default useContractPaymentScheduleForm;
