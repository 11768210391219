import { useSnackbar } from 'notistack';
import { GeneralWalletDetailsService } from '../../../GeneralWalletDetails/services/GeneralWalletDetailsService';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import WalletMonthlyReportDTO from '../DTO/WalletMonthlyReportDTO';

function useMonthlyInterestGetter(
  propertyId: number | null,
  isOpen: boolean,
): { data: WalletMonthlyReportDTO | null; reload: () => Promise<void> } {
  const { towerId } = useParams<{ towerId: string }>();
  const services = new GeneralWalletDetailsService();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<WalletMonthlyReportDTO | null>(null);

  const fetchMonthlyInterest = async (id: number) => {
    try {
      const response = await services.viewReport(towerId, id);
      const report = response.data;

      setData({
        ...report,
        financialAccumulated: report.financialAccumulated * -1,
      });
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const reload = async () => {
    if (propertyId) await fetchMonthlyInterest(propertyId);
  };

  useEffect(() => {
    if (propertyId && isOpen) {
      fetchMonthlyInterest(propertyId);
    }
  }, [propertyId, isOpen]);

  return { data, reload };
}

export default useMonthlyInterestGetter;
