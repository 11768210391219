import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RealPaymentsServices from '../../Services/RealPaymentsServices';
import { updateRealPaymentsForProperty } from '../actions';

const useRealPaymentsForPropertyRequest = (propertyId) => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [id, setPropertyId] = useState(null);

  const services = new RealPaymentsServices();

  const fetch = async () => {
    setLoading(true);
    try {
      const payments = await services.getRealPayments(propertyId, towerId);
      dispatch(updateRealPaymentsForProperty(payments.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!isLoading && propertyId !== id) {
      setPropertyId(propertyId);
      if (propertyId) {
        fetch();
      }
    }
    if (!propertyId) {
      dispatch(updateRealPaymentsForProperty([]));
    }
  }, [propertyId]);

  return { isLoading };
};

export default useRealPaymentsForPropertyRequest;
