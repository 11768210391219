import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Styles from './Companies.module.scss';
import UserServices from '../../services/user/UserServices';
import { Role } from '../../helpers';
import styles from './Companies.module.scss';

const CreateCompany = ({
  action,
  actionOpen,
  createCompanyService,
  companies,
}) => {
  const services = new UserServices();
  const { enqueueSnackbar } = useSnackbar();
  const [companyParams, setCompanyParams] = useState({
    name: undefined,
    user: null,
    description: undefined,
  });
  const [emptyField, setEmptyField] = useState(false);
  const [emptySelectField, setEmptySelectField] = useState(false);
  const [users, setUsers] = useState([]);

  const changeParam = (name) => (element) => {
    const currentNewCompany = {
      ...companyParams,
      [name]: element.target.value,
    };
    setCompanyParams(currentNewCompany);
  };

  const closeAction = () => {
    setCompanyParams({
      name: undefined,
      user: null,
      description: undefined,
    });
    action();
  };

  const sendCompanyParams = () => {
    setEmptySelectField(!companyParams.user)
    setEmptyField(!companyParams.name);
    if (companyParams.name && companyParams.user) {
      const checkRepeated = companies.some(
        (company) =>
          company.name.toLowerCase() === companyParams.name.toLowerCase(),
      );
      if (checkRepeated) {
        enqueueSnackbar('Ya existe una compañía con ese nombre.', {
          variant: 'error',
        });
      } else {
        createCompanyService(companyParams);
        closeAction();
      }
    }
  };

  useEffect(() => {
    async function call() {
      try {
        const res = await services.all({ userType: Role.Admin });
        const users = res.data.users;
        setUsers(users);
      } catch (error) {
        console.log(error);
      }
    }
    call();
  }, []);

  return (
    <Dialog open={actionOpen} fullWidth="sm" maxWidth="sm">
      <DialogTitle>Nueva Compañía</DialogTitle>
      <DialogContent classes={{ root: Styles.dialogContent }}>
        <FormControl className={Styles.dialogContent}>
          <TextField
            className={Styles.texfieldModal}
            variant="outlined"
            required
            defaultValue={companyParams.name}
            error={emptyField}
            onChange={changeParam('name')}
            label="Ingrese el nombre de la compañía"
          />
          <FormControl
            variant="outlined"
            classes={{ root: styles.texfieldModal }}
            error={emptySelectField}
          >
            <InputLabel className={styles.labelSelect}>
              Seleccione un usuario administrador
            </InputLabel>
            <Select
              displayEmpty
              error={emptySelectField}
              value={companyParams.user}
              onChange={changeParam('user')}
            >
              {users.map((user, index) => (
                <MenuItem value={user.id} key={index}>
                  {user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={Styles.texfieldModal}
            multiline
            defaultValue={companyParams.description}
            rows={3}
            onChange={changeParam('description')}
            variant="outlined"
            label="Ingrese una descripción para la compañia"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAction} color="secondary">
          CANCELAR
        </Button>
        <Button onClick={sendCompanyParams} classes={{ root: Styles.btnModal }}>
          CREAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateCompany.propTypes = {
  action: PropTypes.func,
  actionOpen: PropTypes.bool,
  createCompanyService: PropTypes.func,
  companies: PropTypes.array,
};

export default CreateCompany;
