import Services from '../services';
import BuilderCreditServicesDefinitions from './BuilderCreditServicesDefinitions';

class BuilderCreditServices extends Services {
  getBuilderCreditSetup(towerId) {
    return this.get(BuilderCreditServicesDefinitions.setup(towerId));
  }

  getBuilderFlow(towerId) {
    return this.get(BuilderCreditServicesDefinitions.getFlow(towerId));
  }

  updateBuilderCreditSetup(towerId, data) {
    return this.put(BuilderCreditServicesDefinitions.updateSetup, {
      ...data,
      towerId,
    });
  }

  updateFlow(towerId, dateValue, value, arePayments, dates) {
    return this.put(BuilderCreditServicesDefinitions.updateFlow, {
      towerId,
      dateValue,
      value,
      arePayments,
      dates,
    });
  }
}

export default BuilderCreditServices;
