import { Toolbar } from '@material-ui/core';
import DownloadButton from 'components/UI/DownloadButton/DownloadButton';
import React, { FC } from 'react';
import { MuiIconButton } from 'shared/components/Button/Button';
import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import Styles from './MonthlyInterestArrearsTableContainer.module.scss';
import WalletMonthlyReportDTO from '../../../core/DTO/WalletMonthlyReportDTO';
import useDownloadMonthlyInterestReport from '../../../core/customHooks/useDownloadMonthlyInterestReport';
import property from '../../../../../components/Detail/Property/Property';

interface MonthlyInterestArrearsTableContainer extends Partial<WalletMonthlyReportDTO> {
  onClose: () => void;
  propertyId: number | null;
  hasToolbar: boolean;
}

const MonthlyInterestArrearsTableContainer: FC<MonthlyInterestArrearsTableContainer> = ({
  children,
  clientName,
  clientIdentity,
  projectName,
  chargeNumber,
  towerName,
  propertyName,
  propertyId,
  onClose,
  hasToolbar,
}) => {
  const { downloadExcel } = useDownloadMonthlyInterestReport(propertyId);

  return (
    <div className={Styles.Root}>
      {hasToolbar && <Toolbar />}
      <div className={Styles.HeaderContainer}>
        <div>
          <MuiIconButton onClick={onClose}>
            <Icon variant="icon-keyboard_arrow_left" height="20px" width="20px" />
          </MuiIconButton>
        </div>

        <div className={Styles.LeftContainer}>
          <div className={Styles.TitleContainer}>
            <Typography variant="headline6" color="gray900" className={Styles.LeftTypography}>
              {clientName}
            </Typography>
            <Typography variant="subtitle1" color="gray900" className={Styles.RightTypography}>
              {`- ${projectName}`}
            </Typography>
          </div>
          <div className={Styles.ChargerNumberTitle}>
            <Typography variant="body1" color="gray700" className={Styles.LeftTypography}>
              {`N° de encargo: ${chargeNumber}`}
            </Typography>
            <Typography variant="body1" color="gray700" className={Styles.RightTypography}>
              {`Torre: ${towerName}`}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="gray700" className={Styles.LeftTypography}>
              {`Documento: ${clientIdentity}`}
            </Typography>
            <Typography variant="body1" color="gray700" className={Styles.RightTypography}>
              {`Inmueble: ${propertyName}`}
            </Typography>
          </div>
        </div>

        <div className={Styles.RightContainer}>
          {/* <DownloadButton className={Styles.DownloadPDF} onClick={() => {}} height="2.5rem" borderRadius="0.375rem">
            Descargar PDF
          </DownloadButton> */}
          <DownloadButton onClick={downloadExcel} height="2.5rem" borderRadius="0.375rem">
            Descargar Excel
          </DownloadButton>
        </div>
      </div>

      <div>{children}</div>
    </div>
  );
};

export default MonthlyInterestArrearsTableContainer;
