import React, { FC } from 'react';
import { Box, Divider } from '@material-ui/core';
import { IClientListAction } from 'components/Client2/List/Core/interfaces/IClientListAction';
import styles from './ClientListAction.module.scss';
import { ClientModel } from 'App/Clients/Core/models/ClientModel';

interface Props extends IClientListAction {
  client: ClientModel;
}
const ClientListAction: FC<Props> = ({
  isDivider,
  intent,
  client,
  label,
  onClick,
}) => {
  return (
    <>
      {isDivider && <Divider />}
      <Box
        onClick={() => onClick(client)}
        className={`${styles.container} ${
          intent ? styles[intent] : styles.primary
        }`}
      >
        {label}
      </Box>
    </>
  );
};

export default ClientListAction;
