import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from '../../../../models/ContractPayments';

type HookType = () => {
  currentContractEvent: Event | undefined;
  setCurrentContractEvent: Dispatch<SetStateAction<Event | undefined>>;
  openContractEventForm: boolean;
  setOpenContractEventForm: Dispatch<SetStateAction<boolean>>;
};

const useContractEvents: HookType = () => {
  const [currentContractEvent, setCurrentContractEvent] = useState<Event | undefined>();
  const [openContractEventForm, setOpenContractEventForm] = useState<boolean>(false);

  return {
    openContractEventForm,
    setOpenContractEventForm,
    currentContractEvent,
    setCurrentContractEvent,
  };
};

export default useContractEvents;
