import React, { FC } from 'react';
import RealPaymentCreationMainView from '../../../RealPayment/UI/RealPaymentCreationMainView';

const IncomePaymentSingleSection: FC = () => {
  return (
    <>
      <RealPaymentCreationMainView />
    </>
  );
};

export default IncomePaymentSingleSection;
