import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Grid,
  TableHeaderRow,
  TableTreeColumn,
  TableFixedColumns,
  VirtualTable,
  TableBandHeader,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import {
  TreeDataState,
  CustomTreeData,
  EditingState,
} from '@devexpress/dx-react-grid';
import useColumnsForDetails, {
  getChildRows,
} from '../../../Core/customHooks/useColumnsForDetails';
import BandCell from '../cells/CellTypes/BandCell';
import useRowsForTotalCashFlow from '../../../Core/customHooks/useRowsForTotalCashFlow';
import { Numbers } from '../../../../../helpers';
import classes from './TotalCashFlowTable.module.scss';
import MainCashFlowCell from '../cells/MainCashFlowCell';
import HeaderCustomCell from '../cells/HeaderCustomCellForTotalCashFlow';
import usePopoverForCashflowTable from '../../../../BuilderCreditSetupModal/Core/customHooks/usePopoverForCashflowTable';
import usePopoverForDetails from '../../../Core/customHooks/usePopoverForDetails';
import useMapperForNewRows from '../../../Core/customHooks/useMapperForNewRowsForDetails';
import CashFlowRowChangeValue from '../../../Core/models/CashFlowRowChangeValue';
import LeftColumnCustomCell from '../cells/LeftColumnCustomCell';

const TotalCashFlowTable = ({
  salesRows: initialSalesRows,
  initialDate,
  lastDate,
  onRowChange,
  rows: initialRows,
  divider,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const salesRows = initialRows || initialSalesRows;
  const {
    columns,
    columnBands,
    tableColumnExtensions,
    defaultExpandedRowIds,
    leftColumns,
    dates,
  } = useColumnsForDetails(salesRows, initialDate, lastDate);

  const { onSelect, onClose } = usePopoverForCashflowTable(onRowChange);

  const { onSelectForDetails, onCloseForDetails } =
    usePopoverForDetails(onRowChange);

  const rows = useRowsForTotalCashFlow(salesRows, dates);

  const newRows = useMapperForNewRows({ rows });

  const rowComponent = ({ tableRow, ...restProps }) => {
    const { row } = tableRow;

    if (row.parentId === null && row.subParentId === null) {
      return <VirtualTable.Row {...restProps} className={classes.groupRow} />;
    }
    if (row.parentId && row.subParentId === null) {
      return (
        <VirtualTable.Row {...restProps} className={classes.subGroupRow} />
      );
    }
    if (row.parentId && row.subParentId) {
      return <VirtualTable.Row {...restProps} className={classes.itemRow} />;
    }
    return <VirtualTable.Row {...restProps} className={classes.height} />;
  };

  const Cell = useCallback(
    (props) => {
      const { row } = props;

      return (
        <>
          <MainCashFlowCell
            leftColumns={leftColumns}
            onSelect={onSelect}
            onSelectForDetails={onSelectForDetails}
            onClose={onCloseForDetails}
            divider={divider}
            {...props}
          />
        </>
      );
    },
    [onSelect, onClose, divider],
  );

  const commitChanges = ({ changed }) => {
    if (changed) {
      const keys = Object.keys(changed).filter((key) => changed[key]);

      if (keys.length) {
        const key = keys[0];
        const columnObject = changed[key];
        const columnObjectKey = Object.keys(columnObject)[0];
        const columnObjectValue = Numbers.parseLocaleNumber(
          columnObject[columnObjectKey],
        );

        if (columnObjectValue === '$NaN') return;
        const row = newRows[key];

        if (row.id !== 'returns' && row.id !== 'contributions') {
          onRowChange(
            row.id,
            columnObjectKey,
            new CashFlowRowChangeValue(columnObjectValue),
          );
        } else {
          if (row.id === 'returns') {
            columnObjectValue <= 0
              ? onRowChange(
                  row.id,
                  columnObjectKey,
                  new CashFlowRowChangeValue(columnObjectValue),
                )
              : enqueueSnackbar(
                  'No se permite ingresar valores positivos en esta fila.',
                  { variant: 'error' },
                );
          }

          if (row.id === 'contributions') {
            columnObjectValue >= 0
              ? onRowChange(
                  row.id,
                  columnObjectKey,
                  new CashFlowRowChangeValue(columnObjectValue),
                )
              : enqueueSnackbar(
                  'No se permite ingresar valores negativos en esta fila.',
                  { variant: 'error' },
                );
          }
        }
      }
    }
  };

  return (
    <>
      <Grid rows={newRows} columns={columns} className={classes.gridTable}>
        <EditingState onCommitChanges={commitChanges} />
        <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
        <CustomTreeData getChildRows={getChildRows} />
        <VirtualTable
          columnExtensions={tableColumnExtensions}
          cellComponent={Cell}
          rowComponent={rowComponent}
        />
        <TableHeaderRow cellComponent={HeaderCustomCell} />
        <TableBandHeader columnBands={columnBands} cellComponent={BandCell} />
        <TableTreeColumn for="name" cellComponent={LeftColumnCustomCell} />
        <TableFixedColumns leftColumns={leftColumns} />
        <TableInlineCellEditing
          selectTextOnEditStart
          startEditAction="doubleClick"
        />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { salesRows, initialDate, lastDate } = state.cashFlow.root;
  return {
    salesRows,
    initialDate,
    lastDate,
  };
};

const mapDispatchToProps = {};

TotalCashFlowTable.propTypes = {
  salesRows: PropTypes.array,
  initialDate: PropTypes.number,
  lastDate: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCashFlowTable);
