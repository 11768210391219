import { useEffect, useState } from 'react';
import FinancialNeighborhoodAlgorithms from '../../../../helpers/FinancialNeighborhoodAlgorithms';
import Numbers from '../../../../helpers/numbers';
import {
  calculateMonthlyRate,
  calculateMonthsForYear,
} from '../../../GeneralSetup/Core/customHooks/useBankTermLimitForQuotationSetup';

export const calculateMonthlyFinancialPayment = (
  annualRate,
  termLimit,
  finalPrice,
) => {
  const monthlyRate = Numbers.cleanNumber(
    calculateMonthlyRate(annualRate) / 100,
  );
  const bankCreditPrice = FinancialNeighborhoodAlgorithms.bankTotalPaymentCredit(
    finalPrice,
    monthlyRate,
    Number(termLimit),
  );
  const months = calculateMonthsForYear(termLimit);
  const monthlyPrice = FinancialNeighborhoodAlgorithms.bankMonthlyPaymentCredit(
    monthlyRate,
    months,
    bankCreditPrice * -1,
  );
  return monthlyPrice;
};

const useBankFinancialStatesForQuotation = (quotation, finalPrice) => {
  const [isBankDialogOpened, setOpen] = useState(false);
  const [bankCredit, setBankCredit] = useState(0);
  const [currentAnnualRate, setCurrentAnnualRate] = useState(
    quotation.quotationSetup.annualRate,
  );
  const [currentTermLimit, setCurrentTermLimit] = useState(
    quotation.quotationSetup.termLimit,
  );

  useEffect(() => {
    if (quotation.termLimit) {
      setCurrentTermLimit(quotation.termLimit);
    }

    if (quotation.annualRate) {
      setCurrentAnnualRate(quotation.annualRate);
    }
  }, [quotation.termLimit, quotation.annualRate]);

  useEffect(() => {
    const monthlyPrice = calculateMonthlyFinancialPayment(
      currentAnnualRate,
      currentTermLimit,
      finalPrice,
    );

    setBankCredit(monthlyPrice);
  }, [
    currentAnnualRate,
    currentTermLimit,
    quotation.priceWithAdditionalAreas,
    quotation.discount,
    quotation.discountPrice,
  ]);

  const monthlyRate = calculateMonthlyRate(currentAnnualRate);
  const months = calculateMonthsForYear(currentTermLimit);

  return {
    setOpen,
    bankCredit,
    isBankDialogOpened,
    currentAnnualRate,
    monthlyRate,
    currentTermLimit,
    months,
  };
};

export default useBankFinancialStatesForQuotation;
