import React, { useState } from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import Styles from '../styles/TypeSelector.module.scss';
import useStateForTypeSelector from 'App/TotalCashFlow/Core/customHooks/useStateForTypeSelector';

export default function TypeSelector() {

  const {typeOfView, onChange} = useStateForTypeSelector()

  return (
    <Box sx={{ minWidth: 300 }}>
      <FormControl variant="outlined" className={Styles.typeSelector}>
        <InputLabel id="demo-simple-select-label">Vista en</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeOfView}
          label="typeOfView"
          onChange={onChange}
        >
          <MenuItem value={'pesos'}>pesos</MenuItem>
          <MenuItem value={'thousands'}>miles</MenuItem>
          <MenuItem value={'millions'}>millones</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
