import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import DeedExpensesDisplacementDate from '../../components/DeedExpensesDisplacementDate/DeedExpensesDisplacementDate';
import useEvalproDeedExpensesDeedDate from 'App/DeedExpenses/core/customHooks/useEvalproDeedExpensesDeedDate';

interface Props {
  towerId: string;
  endOfSalesDate: number;
  displacementStartDateDeed: number;
}

const EvalproDeedExpensesDeedDate: FC<Props> = ({ towerId, endOfSalesDate, displacementStartDateDeed }) => {
  const { isLoading, onChange } = useEvalproDeedExpensesDeedDate(towerId, endOfSalesDate);
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" marginBottom="50px">
      <Box alignSelf="flex-start">
        <Typography variant="headline6" color="primary600">
          Fecha de escrituración
        </Typography>
      </Box>
      <Box maxWidth="560px" alignSelf="center" marginTop="20px">
        <DeedExpensesDisplacementDate
          title="Fecha fin de construcción"
          displacementLabel="Desplazamiento primera escrituración"
          displacementDateLabel="Fecha promedio de escrituración"
          firstDate={endOfSalesDate}
          displacement={displacementStartDateDeed}
          onChange={onChange}
          isDisabled={isLoading}
        />
      </Box>
    </Box>
  );
};

export default EvalproDeedExpensesDeedDate;
