import moment from 'moment';

export class DeedExpensesHelper {
  static validateDisplacementIsSameOrBefore(startDate: number, displacement: number) {
    const startMoment = moment(startDate);
    const displacementMoment = startMoment.clone();
    const newDisplacement = displacementMoment.add(displacement, 'M');
    return startMoment.isSameOrBefore(newDisplacement);
  }
}
