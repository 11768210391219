import React, { FC, RefObject } from 'react';
import { MenuItem } from '@material-ui/core';
import CustomPopover, { CustomPopoverContentProps } from '../../../../../shared/components/CustomPopover/CustomPopover';
import Typography from '../../../../../shared/components/Typography/Typography';

import { Contract } from '../../../../../models/Contracts';
import useContractPaymentScheduleGridPopoverActions, {
  UseContractPaymentScheduleGridPopoverActionsProps,
} from '../../../Core/customHooks/useContractPaymentScheduleGridPopoverActions';

import styles from './ContractPaymentScheduleGridPopover.module.scss';

type ContractsPopoverContentProps = CustomPopoverContentProps<
  UseContractPaymentScheduleGridPopoverActionsProps,
  Contract
>;

const ContractPaymentScheduleGridPopoverContent: FC<ContractsPopoverContentProps> = (props) => {
  const { payload: contract, extraProps, onClose: cb } = props;
  const { restartProjected, autofitProjected } = useContractPaymentScheduleGridPopoverActions({
    contract,
    cb,
    extraProps,
  });
  return (
    <>
      <span className={styles.menuTopLeft}></span>
      <MenuItem
        onClick={autofitProjected}
        dense
        classes={{
          root: styles.menuItem,
        }}
      >
        <Typography variant="caption">Auto-ajustar proyectado</Typography>
      </MenuItem>
      <MenuItem
        onClick={restartProjected}
        dense
        classes={{
          root: styles.menuItem,
        }}
      >
        <Typography variant="caption">Restaurar proyectado</Typography>
      </MenuItem>
    </>
  );
};

interface Props extends UseContractPaymentScheduleGridPopoverActionsProps {
  elementRef: RefObject<HTMLDivElement>;
}

const ContractPaymentScheduleGridPopover: FC<Props> = ({ elementRef, ...props }) => {
  return (
    <CustomPopover<UseContractPaymentScheduleGridPopoverActionsProps, Contract>
      elementRef={elementRef}
      contentComponent={ContractPaymentScheduleGridPopoverContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      extraProps={props}
      classes={{
        paper: styles.popoverRoot,
      }}
    />
  );
};

export default ContractPaymentScheduleGridPopover;
