import { FieldCell } from './interfaces/featureField.types';

class FeatureFieldCellUtils {
  private static isObjectCell = (value: Object) => typeof value === 'object';

  private static isSingleSelect = ({ type }) => type === 'singleSelect';

  static isTagCell(value: FieldCell) {
    return !!value && this.isObjectCell(value) && this.isSingleSelect(value);
  }
}

export default FeatureFieldCellUtils;
