import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Radio } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EditableCell from '../EditableCell';
import {
  CustomPaymentTableRow,
  CustomPaymentTableCell,
} from '../ReservationDeposit/CustomPaymentTableRow';
import QuotationPaymentTypeRow from '../../../../../Quotation/UI/views/rows/QuotationPaymentTypeRow';
import { DEFAULT_DATE_FORMAT } from '../../../../../../helpers/dates';
import useQuotationMonthDate from '../../../../../Quotation/Core/customHooks/useQuotationMonthDate';
import useRealPaymentEditionType from '../../../../Core/customHooks/useRealPaymentEditionType';

const MonthlyPayment = ({
  onChange,
  index,
  quotation,
  showEditableButtons,
  enableDiscountCalculation,
  dispatchFetchQuotationSuccess,
}) => {
  const {
    paymentStartDate,
    distribution,
    paymentTypes,
    initialDate,
    proposalPaymentStartDate,
  } = quotation;

  const startDate = showEditableButtons
    ? paymentStartDate
    : proposalPaymentStartDate;

  const { currentMonth } = useQuotationMonthDate(
    index,
    startDate,
    initialDate,
    showEditableButtons,
  );

  const value =
    paymentTypes && distribution.length > index ? distribution[index] : '0';
  const typeId =
    paymentTypes && paymentTypes.length > index ? paymentTypes[index + 1] : '0';

  const { isEditingMode } = useRealPaymentEditionType();

  return (
    <CustomPaymentTableRow isEditing={isEditingMode}>
      <TableCell>
        <Typography>{index + 1}</Typography>
      </TableCell>

      {showEditableButtons && (
        <CustomPaymentTableCell isEditing={false}>
          <Typography>
            <QuotationPaymentTypeRow
              key={`QuotationPaymentTypeRow_${index + 1}_${typeId}`}
              index={index + 1}
              typeId={typeId}
              quotation={quotation}
              dispatchFetchQuotationSuccess={dispatchFetchQuotationSuccess}
            />
          </Typography>
        </CustomPaymentTableCell>
      )}

      <TableCell>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {currentMonth.format(DEFAULT_DATE_FORMAT).toString()}
        </MuiPickersUtilsProvider>
      </TableCell>

      <TableCell>
        <EditableCell
          name={`distribution[${index}]`}
          value={value}
          showEditableButtons={false}
        />
      </TableCell>
    </CustomPaymentTableRow>
  );
};

MonthlyPayment.propTypes = {
  quotation: PropTypes.shape({
    paymentStartDate: PropTypes.string,
    proposalPaymentStartDate: PropTypes.string,
    distribution: PropTypes.array,
    paymentTypes: PropTypes.array,
    currentIndexForDiscount: PropTypes.any,
    discountPrice: PropTypes.any,
    initialDate: PropTypes.any,
  }).isRequired,
  index: PropTypes.number.isRequired,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dispatchFetchQuotationSuccess: PropTypes.func.isRequired,
};

export default MonthlyPayment;
