import { API_PATH } from '../../config/config';

const SalesRoomDefinitions = {
  salesRoom: (towerId, clientId) =>
    `${API_PATH}salesRoom/${towerId}/${clientId}`,
  getAdditionalAreas: (towerId) =>
    `${API_PATH}pairings/additional-areas/tower/${towerId}`,
  getAdditionalArea: (id, towerId) =>
    `${API_PATH}pairings/additional-areas/${id}/tower/${towerId}`,
  addAdditionalArea: (propertyId, areaId) =>
    `${API_PATH}pairings/sales-rooms/property/${propertyId}/area/${areaId}`,
  putState: (towerId, clientId) =>
    `${API_PATH}salesRoom/putState/${towerId}/${clientId}`,
  putPropertyPrice: (towerId) => `${API_PATH}salesRoom/update-price/${towerId}`,
  getHistory: ({ state, propertyId, clientId }) =>
    `${API_PATH}salesRoom/state/${state}/${propertyId}/${clientId}`,
  getWaitingList: ({ propertyId }) =>
    `${API_PATH}salesRoom//waiting-list/${propertyId}`,
  deleteClienToWaitingList: ({ propertyId, clientId }) =>
    `${API_PATH}salesRoom//waiting-list/${propertyId}/${clientId}`,
  deleteWaitingList: ({ propertyId }) =>
    `${API_PATH}salesRoom//waiting-list/${propertyId}`,
  currentPropertyList: (towerId) =>
    `${API_PATH}imports/schemas/current-property-list/${towerId}`,
  allLists: (towerId) =>
    `${API_PATH}imports/group/all-property-list/${towerId}`,
  soldApartments: (towerId) =>
    `${API_PATH}imports/all-sold-properties/${towerId}`,
  availableApartments: (towerId) =>
    `${API_PATH}imports/all-available-properties/${towerId}`,
};

export default SalesRoomDefinitions;
