import React from 'react';
import {
  Box,
  InputAdornment,
  Paper,
  TextField,
  Container,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import BuyerCustomerListTable from './views/tables/BuyerCustomerListTable';
import useBuyerCostumerLoader from '../Core/customHooks/useBuyerCustomerLoader';
import BuyerCustomerClientDetailView from './views/customViews/BuyerCustomerClientDetailView';
import Styles from './BuyerCustomersMainView.module.scss';

const BuyerCustomersMainView = () => {
  const { isLoading, updateFilter } = useBuyerCostumerLoader();

  return (
    <>
      {isLoading && <AbsoluteCircularProgress />}
      <Container maxWidth="xl">
        <Box display="flex" flexGrow={1}>
          <Box pl={0} width="26%" display="flex" flexDirection="column">
            <Box p={1}>
              <Paper elevation={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={updateFilter}
                  placeholder="Filtrar comprador..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </Box>
            <Box className={Styles.scrollBar}>
              <Paper elevation={1}>
                <BuyerCustomerListTable />
              </Paper>
            </Box>
          </Box>
          <Box pl={3} width="74%">
            <BuyerCustomerClientDetailView />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default BuyerCustomersMainView;
