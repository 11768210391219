import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { GeneralWalletDetailsService } from '../../../GeneralWalletDetails/services/GeneralWalletDetailsService';
import LikemetricError from '../../../../shared/types/LikemetricError';

function useDownloadMonthlyInterestReport(propertyId: number | null) {
  const services = new GeneralWalletDetailsService();
  const { towerId } = useParams<{ towerId: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const downloadExcel = async () => {
    try {
      if (!propertyId) throw new Error('Error: propertyId is required');
      const response = await services.excelSummary(towerId, propertyId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Resumen.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      const error = e as LikemetricError;
      enqueueSnackbar(error.message);
    }
  };

  return { downloadExcel };
}

export default useDownloadMonthlyInterestReport;
