import moment from 'moment';
import { QuotationConfigDto } from '../DTO/QuotationConfigDto';

const useQuotationConfigLimitDateForm = (originalQuotationConfig: QuotationConfigDto) => {
  const momentMaximumCollectionDate = moment(Number(originalQuotationConfig?.maximumCollectionDate));
  const momentEndOfSalesDate = moment(Number(originalQuotationConfig?.endOfSalesDate));
  const maximumCollectionDateIsAfterOfEndOfSalesDate = momentMaximumCollectionDate.isAfter(momentEndOfSalesDate);

  return {
    ...originalQuotationConfig,
    maximumCollectionDateIsAfterOfEndOfSalesDate,
  };
};

export default useQuotationConfigLimitDateForm;
