import React, { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Contractor } from '../../../../../models/Contractors';
import ContractorItem, { IContractorItem } from './ContractorItem';

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    display: 'flex',
    flex: '1 1 100px',
    flexDirection: 'column',
    minHeight: 0,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.2)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.2)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.4)',
      borderRadius: 8,
    },
  },
});

const ContractorList: FC<{ contractors: Contractor[] } & Omit<
  IContractorItem,
  'contractor'
>> = ({ contractors, ...extraProps }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} p={1}>
      {contractors.map((contractor) => (
        <ContractorItem
          contractor={contractor}
          key={contractor.id}
          {...extraProps}
        />
      ))}
    </Box>
  );
};

export default ContractorList;
