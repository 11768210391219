import { DataTypeProvider } from '@devexpress/dx-react-grid';
import React, { FC } from 'react';
import useMapperForDataTypeProvider from '../Core/customHooks/useMapperForDataTypeProvider';
import VirtualTableDataTypeProviderProps from '../Core/interfaces/VirtualTableDataTypeProviderProps';

const VirtualTableDataTypeProvider: FC<VirtualTableDataTypeProviderProps> = ({
  columns,
  type,
}) => {
  const { columnsForType, providerComponent } =
    useMapperForDataTypeProvider(columns);

  const FormattedComponent = providerComponent(type);

  return (
    <DataTypeProvider
      formatterComponent={FormattedComponent}
      for={columnsForType(type)}
    />
  );
};

export default VirtualTableDataTypeProvider;
