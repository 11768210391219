import { useSelector } from 'react-redux';
import Numbers from '../../../../helpers/numbers';

export default function useProjectResultsStateCustomCellData(cellData) {
  const { typeOfView } = useSelector((state) => state.cashFlow.root || {});

  const pesos = 'pesos';
  const thousands = 'thousands';
  const millions = 'millions';

  let cellDataFormatted;

  if (typeOfView === thousands) {
    cellDataFormatted = Numbers.currencyFormat(cellData / 1000, true, 2);
  }

  if (typeOfView === millions) {
    cellDataFormatted = Numbers.currencyFormat(cellData / 1000000, true, 2);
  }

  if (typeOfView === pesos) {
    cellDataFormatted = Numbers.currencyFormat(cellData / 1, true, 0);
  }

  return {
    cellDataFormatted,
  };
}
