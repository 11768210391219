import { CellStyles } from './interfaces/generalWallet.types';

class GeneralWalletCellUtils {
  private styles: CellStyles = {
    isGreen: '',
    isRed: '',
  };

  private isNegative = (value) => value < 0;

  private getNegativeRedClass = (value: number) => (this.isNegative(value) ? this.styles.isRed : this.styles.isGreen);

  private isPositive = (value) => value > 0;

  private getPositiveRedClass = (value: number) => (this.isPositive(value) ? this.styles.isRed : this.styles.isGreen);

  private isUpToDay = (value: string) => value === 'AL DÍA';

  private getStateClass = (value: string) => (this.isUpToDay(value) ? this.styles.isGreen : '');

  arrears = this.getNegativeRedClass;

  financialAccumulated = this.getNegativeRedClass;

  capitalBalance = this.getPositiveRedClass;

  state = this.getStateClass;

  constructor(styles: CellStyles) {
    this.styles = styles;
  }

  getClassName(value: number | string, columnName = '') {
    return this[columnName] ? this[columnName](value) : '';
  }
}

export default GeneralWalletCellUtils;
