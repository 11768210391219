import React from 'react';
import styled from 'styled-components';
import { Box, TextField } from '@material-ui/core';
import CustomTypography from '../../../../../../components/Typography/CustomTypography';
import debouncedInput from '../../../../../../components/UI/TextFields/debouncedInput';
import useChargeNumberUpdate from '../../../../Core/customHooks/useChargeNumberUpdate';

const DebouncedTextField = debouncedInput(TextField, { timeout: 400 });

const ChargeNumberClientHub = () => {
  const Typography = styled(CustomTypography)`
    width: 17vw;
  `;

  const { chargeNumber, updateCharge } = useChargeNumberUpdate();

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle">Número de encargo</Typography>
      <DebouncedTextField
        id="chargeNumber"
        label="Número"
        value={chargeNumber}
        onChange={updateCharge}
      />
    </Box>
  );
};

export default ChargeNumberClientHub;
