import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import Typography from '../../../../../shared/components/Typography/Typography';
import useContractPaymentScheduleNumberCell from '../../../Core/customHooks/useContractPaymentScheduleNumberCell';
import styles from './ContractPaymentScheduleGridHeaderCell.module.scss';

const ContractPaymentScheduleGridNumberFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  column,
  value,
}) => {
  const { color } = useContractPaymentScheduleNumberCell(column.name);
  return (
    <div className={styles.ContractPaymentScheduleGridHeaderCellContent}>
      <Typography color={color}>
        <NumberFormat
          displayType="text"
          value={value}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          prefix="$"
        />
      </Typography>
    </div>
  );
};

export default ContractPaymentScheduleGridNumberFormatter;
