import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import Agent from 'config/config';
import Icon from 'shared/components/Icon/Icon';
import Tooltip from 'shared/components/Tooltip/Tooltip';
import { StageDTO } from '../../../core/DTO/StagesDTO';
import {
  AddTowerHandler,
  DeleteTowerHandler,
  EditTowerHandler,
  OnGoToTower,
  StagesDragAndDropEvents,
} from '../../../core/types/Stages.types';
import StagesUserRole from '../../../core/utils/StagesUserRole';
import StagesTowerCard from '../StagesTowerCard/StagesTowerCard';

import styles from './StagesBoardTowersList.module.scss';

const getAddTowerButton = (stage: StageDTO, onAddTower: AddTowerHandler) => (
  <Tooltip title="Añadir nueva torre" enterDelay={3000} arrow>
    <Button className={styles.addStageButton} data-stage-id={stage.id} onClick={onAddTower}>
      <Icon variant="Add" width="1.5rem" height="1.5rem" />
    </Button>
  </Tooltip>
);

type GetTowerArgs = StagesDragAndDropEvents & {
  stage: StageDTO;
  movedCardId: string;
  onGoToTower: OnGoToTower;
  onEditTower: EditTowerHandler;
  onDeleteTower: DeleteTowerHandler;
};

const getTower =
  ({ stage, movedCardId, onGoToTower, onEditTower, onDeleteTower, ...dndHandlers }: GetTowerArgs) =>
  (towerId: string, index: number) =>
    (
      <StagesTowerCard
        key={towerId}
        className={styles.towerCard}
        stageId={stage.id}
        index={index}
        tower={stage.towers.records[towerId]}
        movedCardId={movedCardId}
        onGoToTower={onGoToTower}
        onEditTower={onEditTower}
        onDeleteTower={onDeleteTower}
        {...dndHandlers}
      />
    );

type Props = StagesDragAndDropEvents & {
  className?: string;
  stage: StageDTO;
  movedCardId: string;
  onAddTower: AddTowerHandler;
  onGoToTower: OnGoToTower;
  onEditTower: EditTowerHandler;
  onDeleteTower: DeleteTowerHandler;
};

const StagesBoardTowersList: React.FC<Props> = ({
  className,
  stage,
  movedCardId,
  onAddTower,
  onGoToTower,
  onEditTower,
  onDeleteTower,
  ...dndHandlers
}) => {
  const getTowerArgs: GetTowerArgs = {
    stage,
    movedCardId,
    onGoToTower,
    onEditTower,
    onDeleteTower,
    ...dndHandlers,
  };
  const isSuperOrAdminUser = StagesUserRole.isSuperOrAdminUser(Agent.currentUser.userType);

  return (
    <div className={clsx(styles.root, className)}>
      {isSuperOrAdminUser && getAddTowerButton(stage, onAddTower)}
      {stage.towers.order.map(getTower(getTowerArgs))}
      <div
        className={styles.lastEmptyCard}
        data-index={stage.towers.order.length}
        data-stage-id={stage.id}
        data-type="lastCard"
        onDragEnter={dndHandlers.onDragEnter}
        onDragOver={dndHandlers.onDragOver}
        onDrop={dndHandlers.onDrop}
      ></div>
    </div>
  );
};

export default StagesBoardTowersList;
