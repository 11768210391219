import { API_PATH } from '../../config/config';

export const API_FETCH = 'API_FETCH';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_TOWERID = 'SET_TOWERID';
export const SET_SALES_START_DATE = 'SET_SALES_START_DATE';

export const setApiFetch = (payload) => ({
  payload,
  type: API_FETCH,
});

export const setProperties = (payload) => ({
  payload,
  type: SET_PROPERTIES,
});

export const setTowerId = (payload) => ({
  payload,
  type: SET_TOWERID,
});

export const setSalesStartDate = (payload) => ({
  payload,
  type: SET_SALES_START_DATE,
});
