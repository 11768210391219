import { useMutation, useQueryClient } from 'react-query';
import { EvalproDeedExpensesService } from 'App/DeedExpenses/services/EvalproDeedExpensesService';
import { EvalproDeedExpensesConfig } from '../interfaces/EvalproDeedExpensesConfig';
import { EVALPRO_DEED_EXPENSES_CONFIG } from '../reactQuery/RequestConstants';

const service = new EvalproDeedExpensesService();

const useUpdateEvalproDeedExpensesConfig = <T extends keyof EvalproDeedExpensesConfig>(
  towerId: string,
  key: T,
  invalidate = true,
) => {
  const queryClient = useQueryClient();

  const fetch = async (value: EvalproDeedExpensesConfig[T]) => {
    const { data } = await service.updateConfig(towerId, { key, value });
    return data.isSuccess;
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(fetch, {
    onSuccess(isSuccess) {
      if (isSuccess && invalidate) queryClient.invalidateQueries(EVALPRO_DEED_EXPENSES_CONFIG);
    },
  });

  return {
    fetch: mutate,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useUpdateEvalproDeedExpensesConfig;
