import FeatureFieldCellUtils from '../FeatureFieldCellUtils';
import { FeatureFieldsRepository } from '../interfaces/featureField.types';

function useGetCellValue<T>(featureFieldsRepo: FeatureFieldsRepository) {
  const checkFeatureField = (value: any, columnName: string) =>
    featureFieldsRepo.data[columnName] ? value : undefined;

  const getCellValue = (row: T, columnName: string) => {
    const value = row[columnName];

    return FeatureFieldCellUtils.isTagCell(value) ? checkFeatureField(value, columnName) : value;
  };

  return getCellValue;
}

export default useGetCellValue;
