import { Column, ColumnBands, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableBandHeader, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import React from 'react';

import TableCell from 'shared/tables/TableCell';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';
import { SalesSpeedTableRow } from '../../../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperTables from '../../../core/enums/SalesSpeedHelperTables';
import SalesSpeedHelperBandCell from '../SalesSpeedHelperBandCell/SalesSpeedHelperBandCell';
import SalesSpeedHelperNumberFormatter from '../SalesSpeedHelperNumberFormatter';
import SalesSpeedHelperPercentFormatter from '../SalesSpeedHelperPercentFormatter';

import styles from './SalesSpeedHelperPartialTable.module.scss';

const isPercentTab = (currentTab: SalesSpeedHelperTables): boolean =>
  currentTab === SalesSpeedHelperTables.UnitSalesPercent;

type FormatterComponent = React.FC<DataTypeProvider.ValueFormatterProps>;

const getFormatterComponent = (currentTab: SalesSpeedHelperTables): FormatterComponent =>
  isPercentTab(currentTab) ? SalesSpeedHelperPercentFormatter : SalesSpeedHelperNumberFormatter;

type Props = {
  className?: string;
  currentTab: SalesSpeedHelperTables;
  rows: SalesSpeedTableRow[];
  columns: Column[];
  formattedColumns: string[];
  columnExtensions: Table.ColumnExtension[];
  columnBands: ColumnBands[];
};

const SalesSpeedHelperPartialTable: React.FC<Props> = ({
  className,
  currentTab,
  rows,
  columns,
  formattedColumns,
  columnExtensions,
  columnBands,
}) => (
  <div className={clsx(styles.root, className)}>
    <Grid rows={rows} columns={columns}>
      <DataTypeProvider for={formattedColumns} formatterComponent={getFormatterComponent(currentTab)} />
      <Table columnExtensions={columnExtensions} cellComponent={TableCell} />
      <TableHeaderRow cellComponent={TableHeaderRowCell} />
      <TableBandHeader columnBands={columnBands} cellComponent={SalesSpeedHelperBandCell} />
    </Grid>
  </div>
);

export default SalesSpeedHelperPartialTable;
