import { MouseEvent, useState } from 'react';

function useTableTagMenu<T>() {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);

  const openMenu = (event: MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return { anchorEl, openMenu, closeMenu };
}

export default useTableTagMenu;
