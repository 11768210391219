import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RealPaymentsServices from '../../../RealPaymentsForProperty/Services/RealPaymentsServices';
import { updateRealPaymentsForProperty } from '../../../RealPaymentsForProperty/Core/actions';

function useUpdatePayment(payment, togglePopover) {
  const { towerId } = useParams();
  const services = new RealPaymentsServices();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setEditionState] = useState(false);
  const [isRemoving, setRemove] = useState(false);
  const [customPayment, setPayment] = useState(payment || {});

  useEffect(() => {
    setPayment(payment || {});
  }, [payment]);

  const editionToggle = (event) => {
    if (isEditing) togglePopover(event);
    setEditionState(!isEditing);
  };

  const removingToggle = (event) => {
    if (isRemoving) togglePopover(event);
    setRemove(!isRemoving);
  };

  const onSave = async (event) => {
    try {
      const payments = await services.updatePayment(towerId, {
        number: customPayment.number,
        date: customPayment.date,
        price: customPayment.price,
        paymentId: customPayment.id,
        propertyId: customPayment.propertyId,
      });
      dispatch(updateRealPaymentsForProperty(payments.data));
    } catch (error) {
      setPayment(payment);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    editionToggle(event);
  };

  const onDelete = async (event) => {
    try {
      const payments = await services.deletePayment(towerId, {
        paymentId: customPayment.id,
        propertyId: customPayment.propertyId,
      });
      dispatch(updateRealPaymentsForProperty(payments.data));
    } catch (error) {
      setPayment(payment);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    removingToggle(event);
  };

  const onCancel = (event) => {
    setPayment(payment);
    editionToggle(event);
  };

  const onCancelRemoving = (event) => {
    removingToggle(event);
  };

  const onChange = (propertyToUpdate) => (value) => {
    setPayment({
      ...customPayment,
      [propertyToUpdate]:
        propertyToUpdate === 'date' ? value.getTime() : value.floatValue,
    });
  };

  return {
    isEditing,
    isRemoving,
    customPayment,
    onCancel,
    onSave,
    editionToggle,
    removingToggle,
    onCancelRemoving,
    onDelete,
    onChange,
  };
}

export default useUpdatePayment;
