import React, { FC } from 'react';
import useGeneralWalletDetailsThreadCreate from 'App/GeneralWalletDetails/core/customHooks/useGeneralWalletDetailsThreadCreate';
import { BaseClientDTO } from 'App/GeneralWallet/core/interfaces/generalWallet.types';
import GeneralWalletInput from '../components/GeneralWalletInput';

import styles from './GeneralWalletDetailsFooter.module.scss';

interface Props {
  client: BaseClientDTO;
}

const GeneralWalletDetailsFooter: FC<Props> = ({ client }) => {
  const { onSubmit, isLoading, isSuccess } = useGeneralWalletDetailsThreadCreate(client.clientId, client.propertyId);
  return (
    <div className={styles.Footer}>
      <div className={styles.Container}>
        {isLoading && <div className={styles.Spinner}></div>}
        <GeneralWalletInput onSubmit={onSubmit} isSuccess={isSuccess} />
      </div>
    </div>
  );
};

export default GeneralWalletDetailsFooter;
