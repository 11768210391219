import { DeedExpensesHelper } from '../helpers/DeedExpensesHelper';
import useUpdateEvalproDeedExpensesConfig from './useUpdateEvalproDeedExpensesConfig';

const useEvalproDeedExpensesConstructionEnd = (towerId: string, endOfSalesDate: number) => {
  const { fetch, isLoading } = useUpdateEvalproDeedExpensesConfig(towerId, 'averageDeliveryDisplacement');

  const onChange = (averageDeliveryDisplacement: number) => {
    if (DeedExpensesHelper.validateDisplacementIsSameOrBefore(Number(endOfSalesDate), averageDeliveryDisplacement))
      fetch(averageDeliveryDisplacement);
  };

  return {
    onChange,
    isLoading,
  };
};

export default useEvalproDeedExpensesConstructionEnd;
