import { useState } from 'react';

export const CLIENT_HUB_SEGMENT = 'client_hub_segment';
export const PAYMENT_SEGMENT = 'payment_segment';
export const MONTHLY_SEGMENT = 'monthly_segment';

const usePaymentSegmentedControlState = () => {
  const [currentSegment, updateSegment] = useState(PAYMENT_SEGMENT);

  return { updateSegment, currentSegment };
};

export default usePaymentSegmentedControlState;
