import React, { FC, useState, MouseEvent } from 'react';
import { Box, Grid } from '@material-ui/core';
import ContractsEvalproGroupsGrid from '../tables/ContractsEvalproGroupsGrid';
import ContractsEvalproDetail from './ContractsEvalproDetail';
import useContractsEvalpro from '../../core/customHooks/useContractsEvalPro';
import Styles from './ContractsEvalproMain.module.scss';
import ContractsEvalproToolbar from './ContractsEvalproToolbar';
import ContractsEvalproFlowGrid from '../tables/ContractsEvalproFlowGrid';

const ContractsEvalproMain: FC = () => {
  const {
    contractsFlowIsLoading,
    openContractsFlow,
    downloadFC,
    evalProView,
    handleChange,
    totalDistributed,
    setTotalDistributed,
  } = useContractsEvalpro();

  return (
    <>
      <ContractsEvalproToolbar
        evalProView={evalProView}
        download={downloadFC}
        handleChangeView={handleChange}
        totalDistributed={totalDistributed}
      />
      {!openContractsFlow ? (
        <Box height="75vh">
          <Grid container direction="row" className={Styles.container}>
            <Grid item md={5}>
              <ContractsEvalproGroupsGrid />
            </Grid>
            <Grid item md={7}>
              <Box className={Styles.scrollBar}>
                <ContractsEvalproDetail setTotalDistributed={setTotalDistributed} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <ContractsEvalproFlowGrid isLoading={contractsFlowIsLoading} />
      )}
    </>
  );
};

export default ContractsEvalproMain;
