export default [
  { field: 'patnerName', title: 'Nombre', visible: true, type: 'text' },
  {
    field: 'patnerVatNumber',
    title: 'NIT o cédula',
    visible: true,
    type: 'number',
  },
  {
    field: 'patnerEmail',
    title: 'Correo electrónico',
    visible: true,
    type: 'text',
  },
  { field: 'patnerPhone', title: 'Célular', visible: true, type: 'tel' },
  { field: 'patnerCity', title: 'Ciudad', visible: true, type: 'text' },
  { field: 'patnerAdress', title: 'Dirección', visible: true, type: 'text' },
  {
    field: 'patnerContactPerson',
    title: 'Persona de contacto',
    visible: true,
    type: 'text',
  },
  { field: 'patnerCountry', title: 'País', visible: true, type: 'text' },
  {
    field: 'patnerFiscalInformation',
    title: 'Información fiscal',
    visible: true,
    type: 'text',
  },
  {
    field: 'patnerPostalCode',
    title: 'Código postal',
    visible: true,
    type: 'text',
  },
  { field: 'patnerWebsite', title: 'Sitio web', visible: true, type: 'text' },
];
