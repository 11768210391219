import React, { FC } from 'react';
import AreaActions from '../../sections/AreaActions/AreaActions';
import AreaEditionActions from '../../sections/AreaEditionActions/AreaEditionActions';
import { AreaDashboardContainerProps } from '../../../Core/customHooks/AreaDashboardContainerProps';
import useAreaDashboardContainer from '../../../Core/customHooks/useAreaDashboardContainer';
import AreasHeader from '../../sections/AreasHeader/AreasHeader';
import AreaEditionTable from '../../tables/AreaEditionTable/AreaEditionTable';
import AreaStaticTable from '../../tables/AreaStaticTable/AreaStaticTable';
import { AreaDashboardState } from '../../../Core/types/AreaDashboardState';
import AreaEditAction from '../../actions/AreaEditAction';
import AreaUploadFileAction from '../../actions/AreaUploadFileAction';
import AreaDownloadTemplate from '../../actions/AreaDownloadTemplate';

const AreaDashboardContainer: FC<AreaDashboardContainerProps> = (props) => {
  const { propertiesArray, dashboardState, startEditionState, stopEditionState } = useAreaDashboardContainer(props);
  return (
    <div>
      <AreasHeader hasProperties={!!propertiesArray.length}>
        {dashboardState === AreaDashboardState.EDITION ? (
          <AreaEditionActions
            towerId={props.towerId}
            onClickCancelEdit={stopEditionState}
            onEditorSaveChanges={stopEditionState}
          />
        ) : (
          <AreaActions>
            <AreaDownloadTemplate towerId={props.towerId} />
            <AreaUploadFileAction towerId={props.towerId} />
            <AreaEditAction onClick={startEditionState} />
          </AreaActions>
        )}
      </AreasHeader>
      {dashboardState === AreaDashboardState.EDITION ? (
        <AreaEditionTable
          towerId={props.towerId}
          properties={propertiesArray}
          areaTypes={props.areasResponse.customColumns}
          customColumnTotals={props.areasResponse.totals}
        />
      ) : (
        <AreaStaticTable
          towerId={props.towerId}
          rows={propertiesArray}
          areaTypes={props.areasResponse.customColumns}
          customColumnTotals={props.areasResponse.totals}
        />
      )}
    </div>
  );
};

export default AreaDashboardContainer;
