import { API_PATH } from 'config/config';

class FeatureFieldServicesDefinitions {
  static getCreateUrl = () => `${API_PATH}feature-field`;

  static getUpsertCellUrl = () => `${API_PATH}feature-field/cell`;

  static getUpdateUrl = (featureId: number) => `${API_PATH}feature-field/${featureId}`;

  static getSortUrl = () => `${API_PATH}feature-field/sort`;
}

export default FeatureFieldServicesDefinitions;
