import { API_PATH } from '../../../config/config';

const GraphicMaterialServicesDefinition = {
  getGraphicMaterial: (propertyId, imgType) => 
    `${API_PATH}v2/properties/getPlantsAndRenders/${propertyId}/${imgType}`,

  getGraphicMaterialDetails: (imgId) =>
    `${API_PATH}v2/properties/graphicMaterial/${imgId}`,

  postGraphicMaterial: (propertyId, imgType) => 
    `${API_PATH}v2/properties/uploadPlantOrRender/${propertyId}/${imgType}`,

    postAssignGraphicMaterial: (propertyId, graphicMaterialId, imgType) => `${API_PATH}v2/properties/assignGraphicMaterialToProperty/${propertyId}/${graphicMaterialId}/${imgType}`,

  deleteGraphicMaterial: (imgId, propertyId) => 
    `${API_PATH}v2/properties/deleteImg/${imgId}/${propertyId}`,
}

export default GraphicMaterialServicesDefinition;
