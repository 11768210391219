import { useContext, useEffect, useState } from 'react';
import DialogContext from '../../UI/Dialog/context';
import IsEditableContext from '../../UI/Dialog/Payments/contexts';

function useRealPaymentEditionFinalType() {
  const context = useContext(DialogContext);
  const isEditingModeFromContext = useContext(IsEditableContext);
  const { updateQuotation, quotation } = context;

  const currentQuotation = quotation || { quotationSetup: {} };
  const [paymentTypeSelected, setCurrentPaymentType] = useState(
    currentQuotation.finalPaymentType,
  );
  const isEditingMode =
    isEditingModeFromContext ||
    currentQuotation.temp ||
    currentQuotation.isAssigned;

  useEffect(() => {
    if (paymentTypeSelected !== currentQuotation.finalPaymentType)
      setCurrentPaymentType();
  }, [currentQuotation.finalPaymentType]);

  const onChange = (event) => {
    setCurrentPaymentType(event.target.value);
    updateQuotation({
      ...currentQuotation,
      finalPaymentType: event.target.value,
    });
  };

  return {
    paymentTypeSelected,
    isEditingMode,
    onChange,
    options: currentQuotation.quotationSetup.quotationFinalPaymentTypes || [],
    ...context,
    ...quotation,
  };
}

export default useRealPaymentEditionFinalType;
