import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import EvalproDeedExpensesCellNumber from './EvalproDeedExpensesCellNumber';
import { EvalproDeedExpensesCellNumberProps } from 'App/DeedExpenses/core/interfaces/EvalproDeedExpensesCellNumberProps';

const EvalproDeedExpensesStockFormatter =
  (StockProps: EvalproDeedExpensesCellNumberProps): FC<DataTypeProvider.ValueFormatterProps> =>
  (props) =>
    <EvalproDeedExpensesCellNumber {...props} {...StockProps} />;

const EvalproDeedExpensesCellNumberProvider: FC<DataTypeProviderProps & EvalproDeedExpensesCellNumberProps> = (
  props,
) => {
  return <DataTypeProvider {...props} formatterComponent={EvalproDeedExpensesStockFormatter(props)} />;
};

export default EvalproDeedExpensesCellNumberProvider;
