import React from 'react';
import { Box, CircularProgress, Grid, TextField } from '@material-ui/core';
import { RoleName } from '../../../../helpers';
import useProfileInfo from '../../Core/customHooks/useProfileInfo';

const ProfileInfo = () => {
  const { user, onChange, onBlur, loadingUserField, errors } = useProfileInfo();

  return (
    <>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <h1>Información personal</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Tipo de usuario"
            value={RoleName[user.userType]}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nombre"
            required
            name="name"
            value={user.name}
            error={errors.name}
            helperText={errors.name}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loadingUserField.name}
          />
          {loadingUserField.name && <CircularProgress size={24} />}
        </Grid>
        <Grid item xs={12}>
          <TextField label="Email" value={user.email} disabled />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Número de teléfono"
            name="phoneNumber"
            value={user.phoneNumber}
            error={errors.phoneNumber}
            helperText={errors.phoneNumber}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loadingUserField.phoneNumber}
          />
          {loadingUserField.phoneNumber && <CircularProgress size={24} />}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Correo de contacto"
            name="contactEmail"
            value={user.contactEmail}
            error={errors.contactEmail}
            helperText={errors.contactEmail}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loadingUserField.contactEmail}
          />
          {loadingUserField.contactEmail && <CircularProgress size={24} />}
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileInfo;
