import React from 'react';
import { Box } from '@material-ui/core';
import ArrearsReportTotalTable from '../tables/ArrearsReportTotalTable';
import ArrearsDailyReportTable from '../tables/ArrearsDailyReportTable';

const ArrearsTotalReportContainer = () => {
  return (
    <Box style={{ width: '30%' }}>
      <ArrearsReportTotalTable />
      <ArrearsDailyReportTable />
    </Box>
  );
};

export default ArrearsTotalReportContainer;
