import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { AreaReactQueryClient } from 'App/Area/Core/ReactQuery/AreaReactQueryClient';
import AreaMainContainer from '../containers/AreaMainContainer/AreaMainContainer';

const AreaMainView: FC = () => {
  const { towerId } = useParams<{ towerId: string }>();
  return (
    <QueryClientProvider client={AreaReactQueryClient}>
      <AreaMainContainer towerId={towerId} />
    </QueryClientProvider>
  );
};

export default AreaMainView;
