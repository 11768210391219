import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import useCRMList from '../../customHooks/useCRMList';

const useStyles = makeStyles(() => ({
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

const CRMReasonPromiseModal = forwardRef((_p, ref) => {
  const { closingReasons } = useCRMList();
  const [closingReason, setClosingReason] = useState();
  const [open, setOpen] = useState(false);
  const [promise, setPromise] = useState({});
  const classes = useStyles();

  const handleOk = () => {
    setOpen(false);
    promise.resolve(closingReason);
    setClosingReason();
  };

  const handleChange = (event) => setClosingReason(event.target.value);

  useImperativeHandle(ref, () => ({
    show: () =>
      new Promise((resolve, reject) => {
        setPromise({
          resolve,
          reject,
        });
        setOpen(true);
      }),
  }));

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Seleccione un motivo de cierre</DialogTitle>
      <DialogContent dividers>
        <RadioGroup value={closingReason} onChange={handleChange}>
          {closingReasons.map((option, index) => (
            <FormControlLabel
              value={option.id}
              key={index}
              // eslint-disable-next-line eqeqeq
              checked={option.id == closingReason}
              control={<Radio />}
              label={option.value}
            />
          ))}
        </RadioGroup>
        {!closingReasons.length && <span>No hay motivos que seleccionar</span>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleOk}
          color="primary"
          disabled={!(closingReason || !closingReasons.length)}
        >
          Finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default CRMReasonPromiseModal;
