type UseCloseStageTowerEditor = {
  isSavingTower: boolean;
  onClose: () => void;
};

function useCloseStageTowerEditor({ isSavingTower, onClose }: UseCloseStageTowerEditor) {
  const handleCloseModal = () => {
    !isSavingTower && onClose();
  };

  return handleCloseModal;
}

export default useCloseStageTowerEditor;
