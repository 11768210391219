import React, { FC } from 'react';
import { Grid, TableFixedColumns, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import TableCell from '../../../../../shared/tables/TableCell';
import TableContainer from '../../../../../shared/tables/TableContainer';
import TableHeaderRowCell from '../../../../../shared/tables/TableHeaderRowCell';
import { AreasFixedColumns } from '../../../Core/constants/AreasConstants';
import { AreaEditionTableProps } from '../../../Core/customHooks/AreaEditionTable/AreaEditionTableProps';
import AreaEditionTotalTableProvider from '../providers/AreaEditionTotalTableProvider';
import AreaEditableHeaderProvider from '../providers/AreaHeaderProvider/AreaEditableHeaderProvider';
import AreaTableEditProvider from '../providers/AreaTableEditProvider';
import AreaTableNewAreaProvider from '../providers/AreaEditionNewAreaTableProvider';
import useModalAreaTypeEditor from '../../../Core/customHooks/useModalAreaTypeEditor';
import ModalAreaType from '../../modals/ModalAreasEditor/ModalAreaType';
import useAreaEditionTable from '../../../Core/customHooks/AreaEditionTable/useAreaEditionTable';
import { AreaTypeStates } from '../../../Core/customHooks/useAreasSaveChanges';
import AreaTableNameProvider from '../providers/AreaTableNameProvider';
import styles from './AreaEditionTable.module.scss';
import AreaGroupProvider from '../providers/AreaGroupProvider';

const AreaEditionTable: FC<AreaEditionTableProps> = (props) => {
  const {
    properties,
    areaTypes,
    columnsExtentions,
    columns,
    enabledColumnsForEdit,
    loading,
    totals,
    orchestateAreaType,
    removeAreaType,
  } = useAreaEditionTable(props);

  const {
    areaTypeParsed,
    isOpenModalAreaTypeEditor,
    openModalAreaTypeEditor,
    handleClickSuccess,
    closeModalAreaTypeEditor,
  } = useModalAreaTypeEditor({
    onOpen: async () => {},
    onSave: orchestateAreaType,
  });

  return (
    <div className={styles.Container}>
      <div className={loading ? styles.Skeleton : ''} />
      <Grid rows={properties} columns={columns}>
        <VirtualTable
          height="75vh"
          containerComponent={TableContainer}
          cellComponent={TableCell}
          columnExtensions={columnsExtentions}
        />
        <TableHeaderRow cellComponent={TableHeaderRowCell} />
        <TableFixedColumns leftColumns={AreasFixedColumns} />
        <AreaTableEditProvider for={enabledColumnsForEdit} />
        <AreaEditableHeaderProvider
          columnNames={AreasFixedColumns}
          areaTypes={areaTypes}
          totals={totals}
          removeColumn={removeAreaType}
          updateColumn={openModalAreaTypeEditor}
        />
        <AreaGroupProvider for={['group']} />
        <AreaEditionTotalTableProvider
          areaTypes={areaTypes.filter((areaType) => areaType?.state !== AreaTypeStates.REMOVED)}
        />
        <AreaTableNewAreaProvider onClick={openModalAreaTypeEditor} />
        <AreaTableNameProvider />
      </Grid>
      <ModalAreaType
        formData={areaTypeParsed}
        isOpen={isOpenModalAreaTypeEditor}
        customColumns={areaTypes}
        onClickSuccess={handleClickSuccess}
        onClose={closeModalAreaTypeEditor}
      />
    </div>
  );
};

export default AreaEditionTable;
