export const FETCH_QUOTATION__START = 'FQSS';
export const FETCH_QUOTATION__SUCCESS = 'FQSSS';
export const FETCH_QUOTATION__FAILURE = 'FQFS';
export const CHANGE_FINAL_FEE_PERCENTAGE = 'CHANGE_FINAL_FEE_PERCENTAGE';
export const CHANGE_RESERVE_PERCENTAGE = 'CRPS';
export const CHANGE_DISTRIBUTION = 'CHANGE_DISTRIBUTION';
export const CHANGE_INDEX_DISTRIBUTION = 'CHANGE_INDEX_DISTRIBUTION';
export const CHANGE_EDITION_TYPE = 'CHANGE_EDITION_TYPE';
export const CHANGE_INITIAL_DATE = 'CHANGE_INITIAL_DATE';
export const CHANGE_DISCOUNT_TYPE = 'CHANGE_DISCOUNT_TYPE';

export const fetchQuotationStart = () => ({
  type: FETCH_QUOTATION__START,
});

export const fetchQuotationSuccess = (quotation) => ({
  type: FETCH_QUOTATION__SUCCESS,
  payload: quotation,
});

export const fetchQuotationFailure = () => ({
  type: FETCH_QUOTATION__FAILURE,
});

export const changeFinalFeePercentage = (value, isSavingIndex) => ({
  type: CHANGE_FINAL_FEE_PERCENTAGE,
  payload: { value, isSavingIndex },
});

export const changeReservePercentage = (name, value, isSavingIndex) => ({
  type: CHANGE_RESERVE_PERCENTAGE,
  payload: { name, value, isSavingIndex },
});

export const changeDistribution = (index, value) => ({
  type: CHANGE_DISTRIBUTION,
  payload: { index, value },
});

export const changeDistributionIndex = (index) => ({
  type: CHANGE_INDEX_DISTRIBUTION,
  payload: index,
});

export const changeEditionType = (type) => ({
  type: CHANGE_EDITION_TYPE,
  payload: type,
});

export const changeInitialDate = (date) => ({
  type: CHANGE_INITIAL_DATE,
  payload: date,
});

export const changeDiscountType = (date) => ({
  type: CHANGE_DISCOUNT_TYPE,
  payload: date,
});
