import React, { ChangeEventHandler, FC } from 'react';
import { FormControl } from '@material-ui/core';
import InputSearch from '../../../../../shared/components/InputSearch/InputSearch';

interface IContractorSearch {
  handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}
const ContractorSearch: FC<IContractorSearch> = ({ handleChangeSearch }) => {
  return (
    <FormControl fullWidth>
      <InputSearch onChange={handleChangeSearch} />
    </FormControl>
  );
};

export default ContractorSearch;
