import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableCashFlow from '../../../../../components/CashFlow/TableCashFlow';
import useCashFlowEvalpro from '../../../Core/customHooks/useCashFlowEvalpro';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';

const CashFLowEvalproTable = ({ cashFlowEvalpro }) => {
  const { isLoading } = useCashFlowEvalpro();

  return (
    <>
      {cashFlowEvalpro.map((row) => {
        if (row.initialIsBeforeCurrent && row.name === 'Total') {
          return (
            <Box m={1}>
              <Alert severity="warning">
                <AlertTitle>Cuidado!</AlertTitle>
                La fecha 'Inicio de Pre-Ventas' es menor a la fecha actual en el
                grupo: "{`${row.type}`}"
              </Alert>
            </Box>
          );
        }
        return null;
      })}
      <TableCashFlow data={cashFlowEvalpro} />
    </>
  );
};
const mapStateToProps = (state) => {
  const cashFlowEvalpro = state.cashFlowEvalpro.root.groups;
  return { cashFlowEvalpro };
};

const mapDispatchToProps = {};

CashFLowEvalproTable.propTypes = {
  cashFlowEvalpro: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CashFLowEvalproTable);
