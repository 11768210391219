import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { TotalColumnName, TotalsID } from '../../constants/AreasConstants';
import { AreasDB } from '../../indexedDB/AreaIndexedDB';
import { AreaEditionValueAtom } from '../../recoil/AreaEditionValueAtom';
import { AreaEditableHeaderConnectorProps } from './AreaHeaderConnectorProps';

const useAreaEditableHeaderConnector = (props: AreaEditableHeaderConnectorProps) => {
  const { areaTypes, totals } = props;
  const name = props.tableColumn.column?.name ?? '';
  const areaType = areaTypes.find((area) => area.id == name);
  const [areaEditionValueState, changeArea] = useRecoilState(AreaEditionValueAtom);
  const [total, setTotal] = useState<number>(0);

  const initialize = async () => {
    setTotal(totals[name] ?? 0);
  };

  const refreshTotal = async (updated: typeof areaEditionValueState) => {
    const totals = await AreasDB.totals.get(TotalsID);
    if (totals) {
      if (name === TotalColumnName && areaTypes.length) {
        const totalColumnValue = areaTypes.reduce((prev, customColumn) => {
          const value = totals[customColumn.id] ?? 0;
          return prev + value;
        }, 0);
        setTotal(totalColumnValue);
      } else {
        const columnTotal = totals[name];
        const newTotal = columnTotal - updated.defaultValue + updated.value;
        await AreasDB.totals.update(TotalsID, { [name]: newTotal });
        setTotal(newTotal);
        changeArea({
          rowId: 0,
          columnId: TotalColumnName,
          defaultValue: 0,
          value: 0,
        });
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (areaEditionValueState.columnId === name) refreshTotal(areaEditionValueState);
  }, [areaEditionValueState]);

  return {
    total,
    areaType,
  };
};

export default useAreaEditableHeaderConnector;
