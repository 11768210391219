import { combineReducers } from 'redux';
import { LOAD_DATA } from './actions';

export const initialState = {
  groups: [
    {
      id: 0,
      name: '1',
      units: 0,
      averageM2: 0,
      valueM2: 0,
      salableAdditionals: [
        {
          name: '',
          additionalId: 0,
          quantity: 0,
          price: 0,
          type: 'UNT',
        },
      ],

      nonSalableAdditionals: [
        {
          additionalId: 0,
          quantity: 0,
          price: 0,
          type: 'M2',
        },
      ],

      salesSpeed: 0,
      firstSaleDeadline: 0,
      frequency: 1,
      increment: 0,
      EA: 0,
      separation: 0,
      initialFee: 0,
    },
  ],

  salableAdditionals: {
    1: { id: 0, name: '', type: '' },
  },
  nonSalableAdditionals: {
    1: { id: 0, name: '', type: '' },
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_DATA:
      return {
        ...state,
        groups: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  root: reducer,
});
