import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import generateDataset from '../../../../../../containers/StrategyV2/helpers/dataset';
import { Numbers } from '../../../../../../helpers';
import IncrementsV2Services from '../../../../../../services/incrementsV2/incrementsService';
import IncrementsServices from '../../../../../../services/increments/IncrementsServices';
import ManualSalesSpeedService from '../../../../../../services/manualSalesSpeed/manualSalesSpeedService';

const services = new ManualSalesSpeedService();
const services2 = new IncrementsV2Services();
const incrementService = new IncrementsServices();

const getMinSaleSpeed = (rotationMonths, speedUnits) => {
  let numberToValidation = Numbers.cleanNumber(speedUnits / rotationMonths);

  if (rotationMonths > 98) {
    numberToValidation = speedUnits / 98;
  }

  return numberToValidation;
};

const validationSchema = (rotationMonths, units) => {
  const numberToValidation = getMinSaleSpeed(rotationMonths, units);

  return yup
    .number()
    .min(
      numberToValidation,
      `La velocidad debe ser mayor o igual a ${Numbers.toFixed(
        numberToValidation,
      )}`,
    )
    .max(
      units,
      `La velocidad debe ser menor o igual a ${Numbers.toFixed(
        numberToValidation,
      )}`,
    );
};

export default function useSalesSpeedServices(
  type,
  units,
  groupId,
  onFetchedData,
  closeHandler,
  automaticSalesSpeed,
  rotationMonths,
  manualRotation,
) {
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [currentType, setType] = useState(type);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [length, setLength] = useState(manualRotation || 0);
  const [distributionTemp, setDistributionTemp] = useState([]);
  const [currentAutomaticSalesSpeed, setSales] = useState(automaticSalesSpeed);

  const onChangeSales = (event) => {
    validationSchema(rotationMonths, units)
      .validate(Numbers.cleanNumber(event.target.value))
      .then(() => {
        setError(null);
      })
      .catch((error) => {
        setError(error.errors[0]);
      });
    setSales(event.target.value);
  };

  const onChangeType = (event) => {
    setType(event.target.value);
  };

  const validateDistributionError = (distribution) => {
    const currentUnits = distribution.reduce((acc, val) => acc + val);
    if (currentUnits === units) {
      return false;
    }
    const errorMessage =
      currentUnits < units
        ? `Se deben asignar todas las unidades, faltan ${units - currentUnits}`
        : `Las unidades no pueden ser mayor a ${units}`;
    enqueueSnackbar(errorMessage, {
      variant: 'error',
    });

    return true;
  };

  const putDistribution = async (values) => {
    const distribution = values.distribution.map(Number);
    if (validateDistributionError(distribution)) {
      return false;
    }

    try {
      await services.putDistribution(groupId, {
        saleSpeed: units / distribution.length,
        distribution,
        towerId,
      });
      const response = await services2.getIncrementsAndStrategy(towerId);
      onFetchedData({
        strategyLines: generateDataset(response.data.increments),
        groups: response.data.summary.increments,
      });
      enqueueSnackbar('Velocidad de ventas guardada', { variant: 'success' });
      closeHandler();
      return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      return false;
    }
  };

  const updateSalesSpeed = async () => {
    setLoading(true);
    try {
      await incrementService.putSalesSpeeds(groupId, {
        salesSpeed: Number(currentAutomaticSalesSpeed),
        units,
        towerId,
      });
      const response = await services2.getIncrementsAndStrategy(towerId);
      onFetchedData({
        strategyLines: generateDataset(response.data.increments),
        groups: response.data.summary.increments,
      });
      closeHandler();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (type !== currentType) {
      setType(type);
    }

    if (automaticSalesSpeed !== currentAutomaticSalesSpeed) {
      setSales(automaticSalesSpeed);
    }
  }, [type, automaticSalesSpeed]);

  useEffect(() => {
    if (length !== manualRotation) {
      setLength(manualRotation);
    }
  }, [manualRotation]);

  return {
    currentType,
    currentAutomaticSalesSpeed,
    isLoading,
    inputError: hasError,
    putDistribution,
    onChangeType,
    getMinSaleSpeed,
    onChangeSales,
    updateSalesSpeed,
    length,
    setLength,
    distributionTemp,
    setDistributionTemp,
  };
}
