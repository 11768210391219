import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { SegmentedControl } from 'segmented-control';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import moment from 'moment';
import CustomTypography from '../../../components/Typography/CustomTypography';
import ContainerContext from '../../../containers/Client/context';
import ClientActions from '../../../components/Client2/FormDialog/ClientActions/ClientActions';
import CustomButton from '../../../components/Buttons/CustomButton';
import Context from '../Core/context';
import { DashboardRoutes } from '../../../routes/local/routes';
import LoadingButton from './Views/loadingButton/LoadingButton';
import Styles from './styles/AddClientModalMainView.module.scss';
import civilStatus from './Views/rows/civilStatus.enum';
import useCRMList from '../../CRM/customHooks/useCRMList';
import useActions from '../Core/customHooks/useActions';
import InputWithTitleForClient from './Views/input/InputWithTitleForClient';
import SelectWithTitleForClient from './Views/input/SelectWithTitleForClient';
import ClientAddressModalMainView from '../../ClientAddressModal/UI/ClientAddressModalMainView';
import useHandlerForClientAddressDialog from '../../ClientAddressModal/Core/customHooks/useHandlerForClientAddressDialog';
import ClientIDType from '../../ClientAddressModal/Core/enums/ClientIDType.enum';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';

const AddClientModalMainView = ({ client, open, onCloseHandler }) => {
  const history = useHistory();
  const { towerId } = useContext(ContainerContext);

  const {
    setClient,
    setOpen,
    handleSubmit,
    state,
    innerClient,
    handleAddToTower,
    infoType,
    setInfoType,
    clientInfo,
    addressDescription,
    addressDescriptionForOffice,
    isLoading,
    validateMissing,
    setClientInfo,
    setInnerClient,
    handleQuestionOne,
    handleChange,
    handleClientInforDateChange,
    getDefaultValue,
  } = useActions({
    onCloseHandler,
    currentClient: client,
  });

  const { isOpen: isAddressOpen, onClose: onCloseAddress } =
    useHandlerForClientAddressDialog(innerClient);

  const { isOpen: isAddressForOfficeOpen, onClose: onCloseAddressForOffice } =
    useHandlerForClientAddressDialog(innerClient);

  const { questionsOne } = useCRMList();

  const statesHandler = async () => {
    if (!open) {
      setClient(client);
      await setOpen(open);
      if (client) {
        setClientInfo(client);
        setInnerClient(client);
      }
    }
  };

  useEffect(() => {
    statesHandler();
  }, [client]);

  function gotoSalesroom() {
    history.push(
      `${DashboardRoutes.base}${DashboardRoutes.salesRoom.value}${towerId}/${state.createdClient}`,
    );
  }

  // MARK: There are two context Provider please search for the main one
  // at containers/Client/Context
  return (
    <Context.Provider value={{ state, handleAddToTower }}>
      <Dialog
        className={Styles.NewClientDialogContainer}
        open={open}
        onClose={onCloseHandler}
        fullWidth
      >
        <DialogTitle>
          <CustomTypography variant="title" color="black">
            Cliente
          </CustomTypography>
        </DialogTitle>
        <Box className={Styles.infoTypeSelector}>
          <SegmentedControl
            className={Styles.switch}
            name="infoType"
            options={[
              {
                label: 'Información basica',
                value: 'basicClientInfo',
                default: true,
              },
              { label: 'Información laboral', value: 'laborData' },
            ]}
            setValue={async (newValue) => {
              setInfoType(newValue);
            }}
            variant="base"
          />
        </Box>
        <DialogContent>
          {isLoading && <AbsoluteCircularProgress />}
          {!isLoading && (
            <Box mb={2}>
              <form action="">
                {infoType === 'basicClientInfo' && (
                  <>
                    <SelectWithTitleForClient
                      title="(*)Tipo de documento"
                      classObjectForOptions={ClientIDType}
                      value={getDefaultValue(
                        clientInfo?.identityDocumentType,
                        1,
                      )}
                      onChange={handleChange('identityDocumentType')}
                    />
                    <InputWithTitleForClient
                      title="(*)Documento de identidad"
                      id="identityDocument"
                      label="Escriba el documento"
                      hasError={validateMissing('identityDocument')}
                      helperText="Falta ingresar el documento de identidad"
                      value={getDefaultValue(
                        clientInfo?.identityDocument,
                        clientInfo?.identityDocument,
                      )}
                      onChange={handleChange('identityDocument')}
                    />
                    <Box className={Styles.clientInformationContainer}>
                      <CustomTypography
                        className={Styles.indicator}
                        variant="description"
                        color="#000000"
                      >
                        Fecha de expedición
                      </CustomTypography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="dateOfIssuanceOfID"
                          label="Fecha expedición de documento"
                          format="dd/MM/yyyy"
                          className={Styles.input}
                          value={getDefaultValue(
                            Number(clientInfo?.dateOfIssuanceOfID),
                            Number(
                              clientInfo?.clientAdditionalInfo
                                ?.dateOfIssuanceOfID || new Date().valueOf(),
                            ),
                          )}
                          onChange={handleClientInforDateChange(
                            'dateOfIssuanceOfID',
                          )}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <InputWithTitleForClient
                      title="(*)Primer nombre"
                      id="name"
                      label="Ingresa un nombre"
                      helperText="Falta ingresar el primer nombre"
                      hasError={validateMissing('name')}
                      value={getDefaultValue(
                        clientInfo?.name,
                        clientInfo?.name,
                      )}
                      onChange={handleChange('name')}
                    />
                    <InputWithTitleForClient
                      title="Segundo Nombre"
                      id="middleName"
                      label="Ingresa un nombre"
                      value={getDefaultValue(
                        clientInfo?.middleName,
                        clientInfo?.middleName,
                      )}
                      onChange={handleChange('middleName')}
                    />
                    <InputWithTitleForClient
                      title="(*)Primer Apellido"
                      id="surname"
                      label="Ingresa un apellido"
                      helperText="Falta ingresar el primer apellido"
                      hasError={validateMissing('surname')}
                      value={getDefaultValue(
                        clientInfo?.surname,
                        clientInfo?.surname,
                      )}
                      onChange={handleChange('surname')}
                    />
                    <InputWithTitleForClient
                      title="Segundo Apellido"
                      id="secondSurname"
                      label="Ingresa un apellido"
                      value={getDefaultValue(
                        clientInfo?.secondSurname,
                        clientInfo?.secondSurname,
                      )}
                      onChange={handleChange('secondSurname')}
                    />
                    <InputWithTitleForClient
                      title="(*)Correo electrónico"
                      id="email"
                      helperText="Falta ingresar el correo"
                      hasError={validateMissing('email')}
                      value={getDefaultValue(
                        clientInfo?.email,
                        clientInfo?.email,
                      )}
                      label="contact@email.com"
                      className={Styles.input}
                      onChange={handleChange('email')}
                    />
                    <InputWithTitleForClient
                      title="(*)Teléfono de contacto"
                      id="phoneNumber"
                      label="Número"
                      helperText="Falta ingresar el teléfono"
                      hasError={validateMissing('phoneNumber')}
                      value={getDefaultValue(
                        clientInfo?.phoneNumber,
                        clientInfo?.phoneNumber,
                      )}
                      onChange={handleChange('phoneNumber')}
                    />
                    <InputWithTitleForClient
                      title="Lugar de expedición de la cédula"
                      id="placeOfIssuanceId"
                      label="Lugar"
                      value={getDefaultValue(
                        clientInfo?.placeOfIssuanceId,
                        clientInfo?.clientAdditionalInfo?.placeOfIssuanceId,
                      )}
                      onChange={handleChange('placeOfIssuanceId')}
                    />
                    <SelectWithTitleForClient
                      title="Estado civil"
                      classObjectForOptions={civilStatus}
                      value={getDefaultValue(
                        clientInfo?.civilStatus,
                        clientInfo?.clientAdditionalInfo?.civilStatus,
                      )}
                      onChange={handleChange('civilStatus')}
                    />
                    <Box className={Styles.clientInformationContainer}>
                      <CustomTypography
                        className={Styles.indicator}
                        variant="description"
                        color="#000000"
                      >
                        Fecha de nacimiento
                      </CustomTypography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={Styles.input}
                          margin="normal"
                          id="dateOfBirth"
                          label="Fecha de nacimiento"
                          format="dd/MM/yyyy"
                          value={getDefaultValue(
                            Number(clientInfo?.dateOfBirth),
                            Number(
                              clientInfo?.clientAdditionalInfo?.dateOfBirth ||
                                new Date().valueOf(),
                            ),
                          )}
                          onChange={handleClientInforDateChange('dateOfBirth')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <InputWithTitleForClient
                      title="Dirección de residencia"
                      id="residenceAddress"
                      value={addressDescription}
                      className={Styles.input}
                      onClick={onCloseAddress}
                    />
                    <InputWithTitleForClient
                      title="Departamento residencia"
                      id="departmentOfResidence"
                      value={getDefaultValue(
                        clientInfo?.departmentOfResidence,
                        clientInfo?.clientAdditionalInfo?.departmentOfResidence,
                      )}
                      className={Styles.input}
                      onChange={handleChange('departmentOfResidence')}
                    />
                    <InputWithTitleForClient
                      title="Segundo teléfono"
                      id="mobileNumber"
                      value={getDefaultValue(
                        clientInfo?.mobileNumber,
                        clientInfo?.clientAdditionalInfo?.mobileNumber,
                      )}
                      className={Styles.input}
                      onChange={handleChange('mobileNumber')}
                    />
                    <InputWithTitleForClient
                      title="País de residencia"
                      id="countryOfResidence"
                      label="País"
                      value={getDefaultValue(
                        clientInfo?.countryOfResidence,
                        clientInfo?.clientAdditionalInfo?.countryOfResidence,
                      )}
                      className={Styles.input}
                      onChange={handleChange('countryOfResidence')}
                    />
                    <InputWithTitleForClient
                      title="Ciudad de residencia"
                      id="cityOfResidence"
                      label="Ciudad"
                      value={getDefaultValue(
                        clientInfo?.cityOfResidence,
                        clientInfo?.clientAdditionalInfo?.cityOfResidence,
                      )}
                      className={Styles.input}
                      onChange={handleChange('cityOfResidence')}
                    />
                    {Array.isArray(questionsOne) && questionsOne.length ? (
                      <Box className={Styles.clientInformationContainer}>
                        <CustomTypography
                          className={Styles.indicator}
                          variant="description"
                          color="#000000"
                        >
                          ¿Cómo se enteró del proyecto?
                        </CustomTypography>
                        <FormControl className={Styles.civilStatusSelector}>
                          <InputLabel fullWidth className={Styles.inputLabel}>
                            ¿Cómo se enteró del proyecto?
                          </InputLabel>
                          <Select
                            fullWidth
                            variant="outlined"
                            onChange={handleQuestionOne}
                            value={getDefaultValue(
                              clientInfo?.responseQuestionOne,
                              clientInfo?.clientAdditionalInfo
                                ?.responseQuestionOne,
                            )}
                          >
                            {questionsOne.map((questionOne) => {
                              return (
                                <MenuItem
                                  key={`questionOne_${questionOne.id}`}
                                  value={questionOne.id}
                                >
                                  {questionOne.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null}
                  </>
                )}

                {infoType === 'laborData' && (
                  <>
                    <InputWithTitleForClient
                      title="Empresa donde labora"
                      id="companyName"
                      label="Empresa"
                      value={getDefaultValue(
                        clientInfo?.companyName,
                        clientInfo?.clientAdditionalInfo?.companyName,
                      )}
                      onChange={handleChange('companyName')}
                    />
                    <InputWithTitleForClient
                      title="Profesión"
                      id="profession"
                      label="Profesión"
                      value={getDefaultValue(
                        clientInfo?.profession,
                        clientInfo?.clientAdditionalInfo?.profession,
                      )}
                      onChange={handleChange('profession')}
                    />
                    <InputWithTitleForClient
                      title="Ocupación"
                      id="position"
                      label="Ingrese su cargo"
                      value={getDefaultValue(
                        clientInfo?.position,
                        clientInfo?.clientAdditionalInfo?.position,
                      )}
                      onChange={handleChange('position')}
                    />
                    <InputWithTitleForClient
                      title="Ciudad de oficina"
                      id="officeCity"
                      label="Ingrese la ciudad"
                      value={getDefaultValue(
                        clientInfo?.officeCity,
                        clientInfo?.clientAdditionalInfo?.officeCity,
                      )}
                      onChange={handleChange('officeCity')}
                    />

                    <InputWithTitleForClient
                      title="Dirección de la empresa"
                      id="companyAddress"
                      value={addressDescriptionForOffice}
                      className={Styles.input}
                      onClick={onCloseAddressForOffice}
                    />

                    <InputWithTitleForClient
                      title="Teléfono de la empresa"
                      id="companyPhone"
                      label="3503678415"
                      value={getDefaultValue(
                        clientInfo?.companyPhone,
                        clientInfo?.clientAdditionalInfo?.companyPhone,
                      )}
                      onChange={handleChange('companyPhone')}
                    />

                    <InputWithTitleForClient
                      title="Ingresos mensuales"
                      id="monthlyIncome"
                      label="Ingrese un número"
                      isNumber={true}
                      value={getDefaultValue(
                        clientInfo?.monthlyIncome,
                        clientInfo?.clientAdditionalInfo?.monthlyIncome,
                      )}
                      onChange={handleChange('monthlyIncome')}
                    />
                  </>
                )}

                <Grid container spacing={1} direction="row-reverse">
                  <Grid item>
                    {!innerClient.id && (
                      <Button
                        disabled={!state.createdClient}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={gotoSalesroom}
                      >
                        Ir a sala de ventas
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Box>
          )}
          <ClientActions client={innerClient} />
        </DialogContent>
        <DialogActions className={Styles.dialogActions}>
          <CustomButton type="secondary" onClick={onCloseHandler}>
            Cancelar
          </CustomButton>
          <Grid item>
            <LoadingButton
              loading={state.clientRequestLoading}
              type="submit"
              variant="contained"
              color="primary"
              disabled={innerClient.id === null && state.createdClient}
              disableElevation
              onClick={() => {
                handleSubmit(clientInfo);
              }}
            >
              {innerClient.id ? 'Actualizar' : 'Crear'}
            </LoadingButton>
          </Grid>
        </DialogActions>
      </Dialog>
      <ClientAddressModalMainView
        isOpen={isAddressOpen}
        onClose={onCloseAddress}
        isMain={true}
      />
      <ClientAddressModalMainView
        isOpen={isAddressForOfficeOpen}
        onClose={onCloseAddressForOffice}
      />
    </Context.Provider>
  );
};

AddClientModalMainView.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    identityDocument: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    clientAdditionalInfo: PropTypes.object,
  }),
  open: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

export default AddClientModalMainView;
