import { Column } from '@devexpress/dx-react-grid';
import clsx from 'clsx';
import React from 'react';

import { Button } from 'shared/components/Button/Button';
import Icon from 'shared/components/Icon/Icon';
import Pill from 'shared/components/Pill/Pill';
import useTableTagMenu from '../../../core/customHooks/useTableTagMenu';
import useTableTagSelect from '../../../core/customHooks/useTableTagSelect';
import { FeatureFieldTypes } from '../../../core/enums/featureField.enum';
import { FeatureFieldOptions, FieldCell } from '../../../core/interfaces/featureField.types';
import { GetCellValue, TableTagProps } from '../../../core/interfaces/TableTag';

import styles from './TableTag.module.scss';
import TableTagMenu from './TableTagMenu';

const getDefaultCellValue = (column: Column, row: any): FieldCell => ({
  id: -1,
  selectedOptions: [],
  propertyId: row.propertyId,
  featureFieldId: +column.name,
  type: FeatureFieldTypes.SingleSelect,
});

const getCellValue = ({ column, row, value }: GetCellValue): FieldCell => value || getDefaultCellValue(column, row);

const getIsOpenClass = (isOpen: boolean) => ({
  [styles.isOpen]: isOpen,
});

const getPill = (options: FeatureFieldOptions) => (optionId: number) =>
  options[optionId] && <Pill key={optionId} label={options[optionId].value} color={options[optionId].color} />;

const getDropDowVariant = (isOpen: boolean) => (isOpen ? 'ArrowDropUp' : 'ArrowDropDown');

const TableTag: React.FC<TableTagProps> = ({ column, row, value, featureFields, onValueChange, onBlur, onFocus }) => {
  const cellValue = getCellValue({ column, row, value });
  const { anchorEl, openMenu, closeMenu } = useTableTagMenu<HTMLButtonElement>();
  const handleSelectOption = useTableTagSelect({ row, cellValue, onValueChange, closeMenu, onBlur });
  const featureField = featureFields[cellValue.featureFieldId];

  return (
    <div className={styles.root} onBlur={onBlur} onFocus={onFocus}>
      <Button className={clsx(styles.button, getIsOpenClass(Boolean(anchorEl)))} variant="text" onClick={openMenu}>
        <div className={styles.tagContainer}>{cellValue.selectedOptions.map(getPill(featureField.options))}</div>
        <Icon
          className={styles.icon}
          variant={getDropDowVariant(Boolean(anchorEl))}
          color="gray600"
          width="1.5rem"
          height="1.5rem"
        />
      </Button>
      <TableTagMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        options={featureField.options}
        optionsOrder={featureField.optionsOrder}
        selectedOptions={cellValue.selectedOptions}
        onSelectOption={handleSelectOption}
        onClose={closeMenu}
      />
    </div>
  );
};

export default TableTag;
