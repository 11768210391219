import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SalesRoomService from '../../../../services/salesRoom/salesRoomService';

function useSalesRoomExcelDownloader() {
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const services = new SalesRoomService();

  const downloadPropertyList = async () => {
    try {
      setLoading(true);
      const name = 'Lista_actual.xlsx';
      const response = await services.downloadApartmentList(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const downloadAllLists = async () => {
    try {
      setLoading(true);
      const name = 'todas_las_listas.xlsx';
      const response = await services.downloadAllLists(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const downloadSoldApartments = async () => {
    try {
      setLoading(true);
      const name = 'apartamentos vendidos.xlsx';
      const response = await services.downloadSoldLists(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const downloadAvailableApartments = async () => {
    try {
      setLoading(true);
      const name = 'apartamentos disponibles.xlsx';
      const response = await services.downloadAvailableLists(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return {
    isLoading,
    downloadPropertyList,
    downloadAllLists,
    downloadSoldApartments,
    downloadAvailableApartments,
  };
}

export default useSalesRoomExcelDownloader;
