import { SnackbarOrigin } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import SnackbarCloseButton from 'shared/components/SnackbarCloseButton/SnackbarCloseButton';
import { SalesStatusProperty } from '../DTO/SalesStatusViewDTO';
import {
  ChangeSalesStatusData,
  ChangeSalesStatusHandler,
  UpdatePropertiesHandler,
  UpdateSalesStatusErrorHandler,
} from '../types/SalesStatus.types';
import UpdateSalesStatusQueue from '../utils/UpdateSalesStatusQueue';

const requestQueue = new UpdateSalesStatusQueue();

const getSoldStatus = (isSold: boolean): string => (isSold ? 'vendido' : 'disponible');

const getErrorMessage = (isSold: boolean, name: string, error: Error): string =>
  `El inmueble ${name} no se pudo cambiar el estado ${getSoldStatus(isSold)} ${error.message}`;

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

type UseUpdateSalesStatusProps = {
  towerId: string;
  properties: SalesStatusProperty[];
  updateProperties: UpdatePropertiesHandler;
};

function useUpdateSalesStatus({ towerId, properties, updateProperties }: UseUpdateSalesStatusProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const errorActions = (snackbarId: string) => <SnackbarCloseButton onClick={() => closeSnackbar(snackbarId)} />;

  const notifyError = ({ isSold, property }: ChangeSalesStatusData, error: Error) =>
    enqueueSnackbar(getErrorMessage(isSold, property.name, error), {
      variant: 'error',
      anchorOrigin: snackbarAnchorOrigin,
      persist: true,
      action: errorActions,
    });

  const handleRequestError: UpdateSalesStatusErrorHandler = ({ data, error }) => {
    notifyError(data.changeData, error);
    updateProperties(data.properties);
  };

  useEffect(() => {
    requestQueue.onError(handleRequestError);
  }, []);

  const handleSalesStatusChange: ChangeSalesStatusHandler = (data) => {
    requestQueue.updateSalesStatus({
      towerId,
      properties,
      changeData: data,
    });
  };

  return handleSalesStatusChange;
}

export default useUpdateSalesStatus;
