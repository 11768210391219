import React from 'react';
import Table from '../../SalesSpeed/UI/views/Table/SalesSpeedTable';

const CashFlowEvalproMainView = () => {
  return (
    <>
      <Table isEvalpro={true} />
    </>
  );
};

export default CashFlowEvalproMainView;
