import { combineReducers } from 'redux';
import {
  SET_CURRENT_BUYER_CUSTOMER,
  SET_CURRENT_BUYER_CUSTOMER_PROPERTY_INFO,
  SET_CURRENT_BUYER_IS_LOADING,
  UPDATE_BUYER_CUSTOMER_LIST,
  SET_CURRENT_BUYER_CUSTOMER_PROPERTY_FILES,
  SET_CURRENT_FILTER_FOR_BUYERS,
} from './actions';

const initialState = {
  isLoading: false,
  properties: [],
  currentClient: null,
  currentPropertyInfo: null,
  currentClientIsLoading: false,
  currentFilter: null,
};

const mapCostumeList = (state, clients) => {
  return {
    ...state,
    clients,
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_BUYER_CUSTOMER_LIST:
      return mapCostumeList(state, payload);
    case SET_CURRENT_BUYER_CUSTOMER:
      return {
        ...state,
        currentClient: payload,
      };
    case SET_CURRENT_BUYER_IS_LOADING:
      return {
        ...state,
        currentClientIsLoading: payload,
      };
    case SET_CURRENT_BUYER_CUSTOMER_PROPERTY_INFO:
      return {
        ...state,
        currentPropertyInfo: payload,
      };
    case SET_CURRENT_BUYER_CUSTOMER_PROPERTY_FILES:
      return {
        ...state,
        currentPropertyFiles: payload,
      };
    case SET_CURRENT_FILTER_FOR_BUYERS:
      return {
        ...state,
        currentFilter: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({ root: reducer });
