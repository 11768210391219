import { useEffect, useState, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import reducer, { initialState } from '../../reducer';
import QuotationServices from '../../../../../../../../services/Quotations';
import {
  changeDistributionIndex,
  changeDistribution,
  fetchQuotationSuccess,
  changeFinalFeePercentage,
  changeReservePercentage,
  changeEditionType,
  changeInitialDate,
  changeDiscountType,
} from '../../actions';
import { validateMissingPrice } from './useAssignmentQuotation';

const services = new QuotationServices();

const useOpenQuotationDialogDidMount = (id, closeHandler, tempQuotation) => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [quotation, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [openAssignedEdition, setOpenEdition] = useState(false);

  const dispatchFetchQuotationSuccess = (quotationUpdated) => {
    dispatch(fetchQuotationSuccess(quotationUpdated));
  };

  const handleReservePercentageChange = (name, value, saveIndex = true) => {
    dispatch(changeReservePercentage(name, value, saveIndex));
  };

  const handleFinalFeeChange = (value, saveIndex = true) => {
    dispatch(changeFinalFeePercentage(Number(value), saveIndex));
  };

  const handleDistributionChange = (index, value) => {
    dispatch(changeDistribution(index, Number(value)));
  };

  const handleIndexSelection = (index) => {
    dispatch(changeDistributionIndex(index));
  };

  const handleEditionTypeChange = (isLockedType) => {
    dispatch(changeEditionType(isLockedType));
  };

  const handleEditionInitialDate = (date) => {
    dispatch(changeInitialDate(date));
  };

  const handleEditionDiscountType = (discountType) => {
    dispatch(changeDiscountType(discountType));
  };

  const fetch = async () => {
    try {
      setLoading(true);
      if (tempQuotation) {
        dispatchFetchQuotationSuccess(tempQuotation);
      } else {
        const response = await services.getQuotation(id, towerId);
        dispatchFetchQuotationSuccess(response.data);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      closeHandler();
    } finally {
      setLoading(false);
    }
  };

  const getQuotationPDF = async () => {
    try {
      validateMissingPrice(quotation);
      const name = `Cot_${quotation.property.tower.project.name} ${quotation.property.tower.name} ${quotation.client.name} ${quotation.property.name}.pdf`;
      const response = await services.downloadQuotationPDF(quotation, towerId);
      const url = window.URL.createObjectURL(
        new Blob([Buffer.from(response.data, 'binary')], {
          type: 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      link.click();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetch();
    }
  }, [isOpen]);

  return {
    loading,
    quotation,
    openAssignedEdition,
    fetch,
    setIsOpen,
    setOpenEdition,
    handleReservePercentageChange,
    handleFinalFeeChange,
    handleDistributionChange,
    dispatchFetchQuotationSuccess,
    handleIndexSelection,
    handleEditionTypeChange,
    handleEditionInitialDate,
    handleEditionDiscountType,
    getQuotationPDF,
  };
};

export default useOpenQuotationDialogDidMount;
