import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Grid,
  Typography,
  FormGroup,
  Box,
} from '@material-ui/core';
import useSwitchInProjection from '../../Core/customHooks/useSwitchInProjection';
import useProjectionsArray from '../../Core/customHooks/useProjectionsArray';
import styles from '../ProjectionStyles.module.scss';
import SegmentedControl from 'shared/components/SegmentedControl/SegmentedControl';

function SwitchCard({ name, title, switchState }) {
  const { onChange, projectionState } = useSwitchInProjection(
    name,
    switchState,
  );

  const { individualSelection } = useProjectionsArray({ name, switchState });

  const options = individualSelection();

  return (
    <Box elevation={2} className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Typography className={styles.typography}>{title}</Typography>
          </Grid>
          <Grid item>
            <FormGroup className={styles.formGroup}>
              <SegmentedControl
                options={options}
                switchState={projectionState}
                onClick={onChange}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

SwitchCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  switchState: PropTypes.bool.isRequired,
};

export default SwitchCard;
