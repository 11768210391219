import clsx from 'clsx';
import React from 'react';

import Icon from 'shared/components/Icon/Icon';

import styles from './SalesSpeedHelperErrorIcon.module.scss';

const getIsErrorClass = (isError: boolean) => ({
  [styles.isError]: isError,
});

type Props = {
  isError: boolean;
};

const SalesSpeedHelperErrorIcon: React.FC<Props> = ({ isError }) => (
  <Icon
    className={clsx(styles.root, getIsErrorClass(isError))}
    variant="ErrorOutline"
    width="1.25rem"
    height="1.25rem"
    color="stateError600"
  ></Icon>
);

export default SalesSpeedHelperErrorIcon;
