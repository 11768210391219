import _ from 'lodash';

export default function useDataGenerator() {
  const createHeaders = (headers) => {
    const intersect = [
      {
        name: 'properties',
        title: ' ',
      },
    ];

    const headersMapper = headers.map((header, index) => {
      const location = index + 1;
      return {
        name: location,
        title: header,
      };
    });

    const headersObject = intersect.concat(headersMapper);

    return [...headersObject, intersect];
  };

  const createRows = (
    properties,
    rows,
    typeSelected,
    onClickSelector,
    lowestFloor,
    propertyTypes
  ) => {
    const arrayOfProperties =
      properties && properties.flatMap((group) => group);

    const groupedProperties = _.chain(arrayOfProperties)
      .groupBy('floor')
      .map((value, key) => ({ floor: key, properties: value }))
      .value();

    const arrayForRows =
      groupedProperties &&
      groupedProperties.map(({ floor, properties }) => {
        const propertiesFloor = {
          typeSelected: rows[Number(floor) - lowestFloor],
        };

        const floorReduce = properties.reduce((acc, property) => {
          let selectedData = '';
          let number = true;
          let groupSelected = false;

          const type = propertyTypes.find((p) => p.id === property.type);

          let editedProperty = {
            ...property,
            mts2ToFixed: property.mts2.toFixed(2),
            type: type?.name ?? '------',
          };

          switch (typeSelected) {
            case 'mts2':
              selectedData = 'mts2ToFixed';
              break;
            case 'name':
              selectedData = 'name';
              break;
            case 'type':
              selectedData = 'type';
              break;
            case 'groups':
              selectedData = 'groupName';
              groupSelected = true;
              break;
            default:
              selectedData =
                property.status === 'SOLD'
                  ? 'priceWithIncrementSold'
                  : 'priceWithIncrement';
              number = false;
          }

          editedProperty = {
            ...editedProperty,
            typeSelected: editedProperty[selectedData],
            groupSelected,
            isNumber: number,
            onClick: onClickSelector,
            selectedData,
            availableProperty: editedProperty.priceWithIncrement,
            soldProperty: editedProperty.priceWithIncrementSold,
          };

          return {
            ...acc,
            [property.location]: editedProperty,
          };
        }, {});

        return { ...floorReduce, properties: propertiesFloor };
      });

    return arrayForRows;
  };

  return {
    createHeaders,
    createRows,
  };
}
