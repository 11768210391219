import React, { useState, useEffect } from 'react';
import { Resizable } from 're-resizable';
import { Link } from 'react-router-dom';
import { DashboardRoutes, UserRoutes } from '../../routes/local/routes';
import ProjectRoutes from '../../routes/local/projectRoutes';
import sideMenuStyle from './SideMenu.module.scss';
import menuStyle from './menu.module.scss';
import Icon from '../../assets/icons/Icon';
import agent from '../../config/config';
import { Role } from '../../helpers';
import Header from './Header/Header';
import Section from './Section';
import INTEGRATIONS from '../../config/integrations';

const ProjectSideMenu = (props) => {
  const ENV = process.env.NODE_ENV;
  const style = INTEGRATIONS[ENV].uniqueSideBar ? menuStyle : sideMenuStyle;
  const [active, setActive] = useState(window.location.pathname);
  const { projectId } = props;

  const onChangeResize = (change) => {
    props.onChange(props.resizableWidth * 0.3 <= -change ? 17 : 200);
    props.onHideArrow(true);
  };

  const handleEnterEvent = () => {
    props.onHideArrow(true);
  };

  const handleLeaveEvent = () => {
    props.onHideArrow(!(props.resizableWidth > 0));
  };

  const itemForSidebar = (styles, route, iconName, description) => {
    return (
      <div
        key={description}
        onClick={() => {
          setActive(route);
        }}
      >
        {active === route ? (
          <div className={style.Active}>
            <Link to={route}>
              <Icon name={iconName} fixWidth={true} />
              <span className={style.Description}> {description} </span>
            </Link>
          </div>
        ) : (
          <div className={styles}>
            <Link to={route}>
              <Icon name={iconName} fixWidth={true} />
              <span className={style.Description}> {description}</span>
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <Resizable
        enable={{
          right: true,
        }}
        className={
          `${style.SideMenu} ` +
          `${props.tower !== null ? style.OriginalWidth : style.ZeroWidth}`
        }
        size={{ width: `${props.resizableWidth}px`, height: '100vh' }}
        onResizeStop={(e, direction, ref, d) => onChangeResize(d.width)}
        onMouseEnter={handleEnterEvent}
        onMouseLeave={handleLeaveEvent}
      >
        <div className={style.fixedWidth + style.NoVisible}>
          <div className={style.title}></div>
          <div className={style.IconsContainer}>
            <Header title="Proyecto" />
            <div className={style.linkContainer}>
              {itemForSidebar(
                style.MenuItem,
                DashboardRoutes.base +
                  ProjectRoutes.base +
                  ProjectRoutes.towers.value +
                  props.projectId,
                null,
                'Torres',
              )}
              <Section
                title={'Cronograma'}
                display={
                  !!agent.isAuthorized([
                    Role.Admin,
                    Role.Super,
                    Role.Sales,
                    Role.SalesDirector,
                    Role.Wallet,
                    Role.User,
                  ])
                }
                icon="fas fa-list-ul"
                items={[
                  itemForSidebar(
                    style.MenuItem,
                    DashboardRoutes.base +
                      ProjectRoutes.base +
                      ProjectRoutes.projectSchedulesMainView.value +
                      props.projectId,
                    null,
                    'Cronograma',
                  ),
                ]}
              />
              <Section
                title={'Macroeconómicos'}
                display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                icon="fas fa-percentage"
                items={[
                  agent.isAuthorized([Role.Admin, Role.Super]) &&
                    itemForSidebar(
                      style.MenuItem,
                      DashboardRoutes.base +
                        ProjectRoutes.base +
                        ProjectRoutes.macroeconomicMainView.value +
                        props.projectId,
                      null,
                      'Macroeconómicos',
                    ),
                ]}
              />
              <Section
                title={'Estados financieros'}
                display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                icon="fas fa-money-check-alt"
                items={[
                  agent.isAuthorized([Role.Admin, Role.Super]) &&
                    itemForSidebar(style.MenuItem, '#', null, 'Flujo de caja'),
                  agent.isAuthorized([Role.Admin, Role.Super]) &&
                    itemForSidebar(
                      style.MenuItem,
                      DashboardRoutes.base +
                        ProjectRoutes.base +
                        ProjectRoutes.projectResultsStateMainView.value +
                        props.projectId,
                      null,
                      'Estado de resultados',
                    ),
                ]}
              />
              <Section
                title={'Informes'}
                display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                icon="fas fa-file-alt"
                items={[
                  agent.isAuthorized([Role.Admin, Role.Super]) &&
                    itemForSidebar(
                      style.MenuItem,
                      '#',
                      null,
                      'Informes de proyecto 1',
                    ),
                  agent.isAuthorized([Role.Admin, Role.Super]) &&
                    itemForSidebar(
                      style.MenuItem,
                      '#',
                      null,
                      'Informes de proyecto 2',
                    ),
                ]}
              />
            </div>
          </div>
        </div>
      </Resizable>
    </div>
  );
};

export default ProjectSideMenu;
