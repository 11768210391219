import {
  SET_NEW_PARTNER,
  SET_PARTNERS,
  SET_SELECTED_PARTNER,
  SET_HANDLE_CHANGE_NEW_PARTNER,
  SET_COMPANIES_TO_SELECT,
  SET_SELECTED_COMPANY,
} from './actions';

const initialState = {
  availableCompanies: [],
  isCompanySelected: false,
  companyId: null,
  selectedPartner: { isEmpty: true },
  partners: [],
  newPartner: {
    patnerName: 'Nuevo Proveedor',
    patnerVatNumber: '',
    patnerEmail: '',
    patnerPhone: '',
    patnerCity: '',
    patnerAdress: '',
    patnerContactPerson: '',
    patnerCountry: '',
    patnerFiscalInformation: '',
    patnerPostalCode: '',
    patnerWebsite: '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NEW_PARTNER:
      return { ...state, [payload.name]: payload.value };

    case SET_SELECTED_PARTNER:
      return { ...state, selectedPartner: payload };

    case SET_PARTNERS:
      return { ...state, partners: payload };

    case SET_HANDLE_CHANGE_NEW_PARTNER:
      return {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          [payload.title]: payload.value,
        },
      };

    case SET_SELECTED_COMPANY:
      return { ...state, companyId: payload, isCompanySelected: true };

    case SET_COMPANIES_TO_SELECT:
      return {
        ...state,
        availableCompanies: payload,
      };

    default:
      return state;
  }
};
