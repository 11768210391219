import React from 'react';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Card, { CardHeader, CardBody } from '../UI/Card/Card';
import useCreateUserFrom from './Core/customHooks/useCreateUserFrom';
import agent from '../../config/config';
import { Role } from '../../helpers';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

const CreateUserForm = ({ companies, projects, createUser }) => {
  const {
    onCompanyChange,
    change,
    role,
    company,
    name,
    email,
    password,
    confirmPassword,
    companyProjects,
    showPassword,
    toggleShowPassword,
    errors,
    isInvalidForm,
    formatCompanies,
    handleSubmit,
  } = useCreateUserFrom({ companies, projects, createUser });

  return (
    <>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Card>
            <CardHeader>
              <h1>
                <center>Crear Usuario</center>
              </h1>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs>
                    <FormControl fullWidth>
                      <Select
                        defaultValue={role}
                        value={role}
                        onChange={change('role')}
                      >
                        {agent.isAuthorized([Role.Super]) && (
                          <MenuItem value={Role.Super}>Super User</MenuItem>
                        )}
                        <MenuItem value={Role.Admin}>Administrador</MenuItem>
                        <MenuItem value={Role.SalesDirector}>
                          Director de ventas
                        </MenuItem>
                        <MenuItem value={Role.Wallet}>
                          Cartera
                        </MenuItem>
                        <MenuItem value={Role.Sales}>
                          Auxiliar de ventas
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {agent.isAuthorized([Role.Super]) ? (
                    formatCompanies.length ? (
                      <Grid item xs>
                        <TextField
                          fullWidth
                          id="standard-select-currency"
                          select
                          label={company?.name}
                          value={company?.id}
                          onChange={onCompanyChange}
                          helperText="Seleccione una compañia"
                        >
                          {formatCompanies?.map((option) => (
                            <MenuItem key={option.id} value={option}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    ) : (
                      <h3>Sin proyecto para asignar</h3>
                    )
                  ) : null}
                  {agent.isAuthorized([Role.Super, Role.Admin]) ? (
                    companyProjects.length ? (
                      <Grid
                        item
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <h3 style={{ color: '#777' }}>
                          Se le asignaran los proyectos
                        </h3>
                        {companyProjects.map((project) => (
                          <div>
                            <b style={{ color: '#999' }}>{project.name}</b>
                          </div>
                        ))}
                      </Grid>
                    ) : (
                      <h3 style={{ color: '#777' }}>
                        <center>No se asignara proyecto</center>
                      </h3>
                    )
                  ) : null}
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors['name']}
                      helperText={errors['name']}
                      label="Nombre"
                      variant="filled"
                      required
                      value={name}
                      onChange={change('name')}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors['email']}
                      helperText={errors['email']}
                      label="Correo"
                      variant="filled"
                      type="email"
                      required
                      value={email}
                      onChange={change('email')}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      autocomplete="none"
                      label="Contraseña"
                      variant="filled"
                      error={!!errors['password']}
                      helperText={errors['password']}
                      type="password"
                      required
                      value={password}
                      type={showPassword ? 'text' : 'password'}
                      onChange={change('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors['password']}
                      helperText={errors['password']}
                      label="Confirmar Contraseña"
                      variant="filled"
                      type={showPassword ? 'text' : 'password'}
                      required
                      value={confirmPassword}
                      onChange={change('confirmPassword')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isInvalidForm}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Crear
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

CreateUserForm.propTypes = {
  companies: PropTypes.array,
  projects: PropTypes.array,
  createUser: PropTypes.func,
};

export default CreateUserForm;
