import * as xlsx from 'xlsx';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import useSelectedRowsForResultsStateExcel from './useSelectedRowsForResultsStateExcel';
import { expensesNames, variationColumnsNames } from './constants/ResultsStateConstants.ts'

const useDownloadForResultsState = ({
  resultsStateRows,
  cutsControl,
  budgetControl,
  selectedTab
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { excelData } = useSelectedRowsForResultsStateExcel(resultsStateRows, cutsControl, budgetControl, selectedTab)

  const { columns, headersForFilterRows, name, newRows } = excelData()

  const downloadExcel = () => {

    const workbook = xlsx.utils.book_new();

    columns.forEach((arrayColumn, index) => {

      const filteredRows = newRows.map(row => {
        const newRow = {};
        headersForFilterRows[index].forEach(key => {
          if (row.hasOwnProperty(key)) {
            newRow[key] = row[key];
          }
        });
        return newRow;
      });

      const headers = arrayColumn.map((col) => col.header);

      const worksheet = xlsx.utils.json_to_sheet(filteredRows, {
        header: headers,
      });

      headers.forEach((header) => {
        const columnKey = arrayColumn.find((column) => column.header === header)?.header;

        if (columnKey) {
          filteredRows.forEach((row, rowIndex) => {
            const cellAddress = xlsx.utils.encode_cell({
              r: rowIndex + 1,
              c: arrayColumn.findIndex((column) => column.header === columnKey),
            });

            if (worksheet[cellAddress]) {
              const cellValue = row[columnKey];
              worksheet[cellAddress].v = cellValue;

              if (typeof cellValue === 'number') {

                if (variationColumnsNames.includes(columnKey) && cellValue !== 0) {
                  if (expensesNames.includes(row.Nombre)) {
                    worksheet[cellAddress].t = 'n';
                    worksheet[cellAddress].z = '[Color 30]"$"#,##0;[Color 10]-"$"#,##0';
                  }

                  if (!expensesNames.includes(row.Nombre)) {
                    worksheet[cellAddress].t = 'n';
                    worksheet[cellAddress].z = '[Color 10]"$"#,##0;[Color 30]-"$"#,##0';
                  }
                }

                if (!variationColumnsNames.includes(columnKey) || cellValue === 0) {
                  worksheet[cellAddress].t = 'n';
                  worksheet[cellAddress].z = '"$"#,##0;-"$"#,##0';
                }
              }
            }
          });
        }
      });

      if (selectedTab.id === 'BUDGET') {
        const budgetName = budgetControl.find(budget => budget.budgetIndex === index + 1)
        xlsx.utils.book_append_sheet(workbook, worksheet, budgetName?.budgetName || `Evento ${index + 1} Vacio`);
      } else {
        xlsx.utils.book_append_sheet(workbook, worksheet, name);
      }
    })

    try {
      xlsx.writeFile(workbook, `${name} - Estado de resultados.xlsx`);

      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error al descargar el Excel', {
        variant: 'error',
      });
    }
  };
  return {
    downloadExcel,
  };
};

export default useDownloadForResultsState;
