import React, { FC } from 'react';
import moment from 'moment';
import PlusInput from 'shared/components/PlusInput/PlusInput';
import Typography from 'shared/components/Typography/Typography';
import { Box, BoxProps, Grid, GridProps } from '@material-ui/core';
import { InsertInvitationOutlined } from '@material-ui/icons';
import TextField from 'shared/components/TextField/TextField';
import GridContent from 'App/DeedExpenses/UI/components/DeedExpensesGridContent/DeedExpensesGridContent';

interface Props {
  finalPaymentDate: string;
  value: number;
  isDisabled: boolean;
  onChange: (value: number) => void;
}

const DeedExpensesPropertiesSoldConfigDisplacementForm: FC<Props> = ({
  finalPaymentDate,
  value,
  isDisabled,
  onChange,
}) => {
  return (
    <Grid container>
      <GridContent gridProps={{ xs: 8 }}>
        <InsertInvitationOutlined />
        <Typography variant="body1" fontWeight="bold" style={{ marginLeft: '6px' }}>
          Cuota final
        </Typography>
      </GridContent>
      <GridContent gridProps={{ xs: 4 }}>
        <Typography variant="body1" fontStyle="italic">
          {moment(Number(finalPaymentDate)).format('MMM-YYYY')}
        </Typography>
      </GridContent>
      <GridContent gridProps={{ xs: 8 }}>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Desplazamiento
          </Typography>
          <br />
          <Typography variant="body2">Fecha promedio de entrega</Typography>
        </Box>
      </GridContent>
      <GridContent gridProps={{ xs: 4 }}>
        <PlusInput value={value || 0} onChange={onChange} isDisabled={isDisabled} />
      </GridContent>
      <GridContent gridProps={{ xs: 8 }}>
        <Typography variant="body1">Escrituración</Typography>
      </GridContent>
      <GridContent gridProps={{ xs: 4 }}>
        <TextField disabled value={moment(Number(finalPaymentDate)).add(value, 'M').format('MMM-YYYY')} />
      </GridContent>
    </Grid>
  );
};

export default DeedExpensesPropertiesSoldConfigDisplacementForm;
