import { createMachine } from 'xstate';
import { useMachine } from '@xstate/react';

interface ToggleContext {
  count: number;
}

type HookType = () => {
  disabledCancel: boolean;
  disabledSave: boolean;
  send: (event: string) => void;
};

const toggleMachine = createMachine<ToggleContext>({
  id: 'toggle',
  initial: 'inactive',
  states: {
    inactive: {
      on: {
        ACTIVE: 'active',
        MODIFY: 'modify',
      },
    },
    modify: {
      on: {
        ACTIVE: 'active',
        INACTIVE: 'inactive',
      },
    },
    active: {
      on: {
        INACTIVE: 'inactive',
        MODIFY: 'modify',
      },
    },
  },
});

const useMachineForContractPaymentsSchedule: HookType = () => {
  const [current, send] = useMachine(toggleMachine);
  const disabledCancel = current.matches('inactive');
  const disabledSave = !current.matches('active');

  return {
    disabledCancel,
    disabledSave,
    send,
  };
};

export default useMachineForContractPaymentsSchedule;
