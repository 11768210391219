import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Typography from '../../../../../../shared/components/Typography/Typography';

const AreaHeaderCell: FC<{ title: string; total: number }> = ({ title, total }) => {
  return (
    <Box>
      <Typography color="gray900">{title}</Typography>
      <Box>
        <Typography color="gray700" variant="body1">
          Total: <NumberFormat value={Number(total).toFixed(1)} displayType="text" thousandSeparator />
        </Typography>
      </Box>
    </Box>
  );
};

export default AreaHeaderCell;
