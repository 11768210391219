import clsx from 'clsx';
import React from 'react';

import Typography from 'shared/components/Typography/Typography';

import styles from './SalesSpeedHelperPhasesDetailDateCard.module.scss';

const getStatusClasses = (isDisabled = false, isHighlighted = false) => ({
  [styles.isDisabled]: isDisabled,
  [styles.isHighlighted]: isHighlighted,
});

const getIsHighlightedClass = (isBold = false) => ({
  [styles.isBold]: isBold,
});

const dateOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  year: 'numeric',
};

const formatDate = (date: number) =>
  Intl.DateTimeFormat(navigator.language, dateOptions).format(new Date(date)).replace(' ', ', ');

type Props = {
  className?: string;
  title: string;
  date: number;
  isBold?: boolean;
  isDisabled?: boolean;
  isHighlighted?: boolean;
};

const SalesSpeedHelperPhasesDetailDateCard: React.FC<Props> = ({
  className,
  title,
  date,
  isBold,
  isDisabled,
  isHighlighted,
}) => (
  <div className={clsx(styles.root, getStatusClasses(isDisabled, isHighlighted), className)}>
    <Typography className={styles.title} variant="caption" as="h3">
      {title}
    </Typography>
    <Typography className={clsx(styles.date, getIsHighlightedClass(isBold))} variant="body1" as="div">
      {formatDate(date)}
    </Typography>
  </div>
);

export default SalesSpeedHelperPhasesDetailDateCard;
