import React from 'react';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import usePropertyEditionRequest from '../Core/customHooks/usePropertyEditionRequest';
import PropertyEditionTable from './views/tables/PropertyEditionTable.tsx';
import Typography from 'shared/components/Typography/Typography';
import Styles from './PropertyEditionMainView.module.scss';
import clsx from 'clsx';

const PropertyEditionMainView = () => {
  const {
    isLoading,
    isSoldChecked,
    soldQuantity,
    availableQuantity,
    checkFilter,
  } = usePropertyEditionRequest();

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Typography variant="headline4">Edición de precios</Typography>
      </div>
      <div className={Styles.tableContainer}>
        <div className={Styles.tableHeader}>
          <div className={Styles.titleButtonContainer}>
            <button onClick={checkFilter(true)} className={Styles.buttonFilter}>
              <Typography
                className={Styles.titleButton}
                variant="body1"
                color="primary600"
              >
                {`Vendidos (${soldQuantity})`}
              </Typography>
              <div
                className={clsx({
                  [Styles.bottomLine]: true,
                  [Styles.bottomLineWithColor]: isSoldChecked,
                })}
              />
            </button>
          </div>
          <div className={Styles.titleButtonContainer}>
            <button
              onClick={checkFilter(false)}
              className={Styles.buttonFilter}
            >
              <Typography
                className={Styles.titleButton}
                variant="body1"
                color="gray700"
              >
                {`Disponibles (${availableQuantity})`}
              </Typography>
              <div
                className={clsx({
                  [Styles.bottomLine]: true,
                  [Styles.bottomLineWithColor]: !isSoldChecked,
                })}
              />
            </button>
          </div>
        </div>

        <PropertyEditionTable isSold={isSoldChecked} />
      </div>
      {isLoading && <AbsoluteCircularProgress topPercentage={0} />}
    </div>
  );
};

export default PropertyEditionMainView;
