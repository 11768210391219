import ExternalResourceForSalesType, { SalesRow } from '../interfaces/ExternalResourceForSalesType';
import { useEffect, useState } from 'react';
import { GridProps, Column, VirtualTable } from '@devexpress/dx-react-grid';
import { getMonthlyDatesFromMoment } from '../../../../helpers/dates';
import moment from 'moment';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import { Numbers } from '../../../../helpers';

function useRowsForSoldReportForExternalResources(currentResourcesRows: ExternalResourceForSalesType | undefined) {
  const leftColumns = ['name', 'accumulated', 'projected', 'total'];
  const [rowList, setRows] = useState<SalesRow[]>([]);
  const [totalPrice, setTotalPrice] = useState<Number>(0);

  const rowWithName = (
    salesRow: SalesRow | undefined,
    rowName: 'total' | 'initialAvailable' | 'initialTrustQuotes' | 'final',
  ): SalesRow => {
    if (!salesRow) return {};

    let name = `  ${salesRow.name}`;

    if (rowName === 'total') {
      name = 'Total';
    }
    if (rowName === 'initialAvailable') {
      name = '  Cuota inicial disponible';
    }

    return { ...salesRow, name };
  };

  const columnTitle = (columnName: string) => {
    if (columnName === 'accumulated') return 'Acumulado';
    if (columnName === 'total') return 'Total';
    if (columnName === 'projected') return 'Proyectado';
    return columnName;
  };

  const momentDates = getMonthlyDatesFromMoment(
    moment(Number(currentResourcesRows?.firstDate)),
    moment(Number(currentResourcesRows?.lastDate)),
  );

  const columnsBase: (Column & VirtualTable.ColumnExtension & VirtualTableDataTypeProviderColumn)[] = [
    { name: 'name', title: 'Nombre', columnName: 'name', width: 200 },
    {
      name: 'accumulated',
      title: 'Acumulado',
      columnName: 'accumulated',
      width: 200,
      type: ProviderColumnType.currency,
    },
    { name: 'projected', title: 'Proyectados', columnName: 'projected', width: 200, type: ProviderColumnType.currency },
    { name: 'total', title: 'Total', columnName: 'total', width: 200, type: ProviderColumnType.currency },
  ];

  const columns = Object.keys(momentDates ?? {}).reduce((prev, columnName) => {
    return [
      ...prev,
      {
        name: columnName,
        title: columnTitle(columnName),
        columnName: columnName,
        type: ProviderColumnType.currency,
        width: undefined,
      },
    ];
  }, columnsBase);

  useEffect(() => {
    const totalRow = rowWithName(currentResourcesRows?.totalSalesRows, 'total');
    const availableRow = rowWithName(currentResourcesRows?.initialAvailableRows, 'initialAvailable');
    const trustRow = rowWithName(currentResourcesRows?.initialTrustQuotesRows, 'initialTrustQuotes');
    const finalRow = rowWithName(currentResourcesRows?.finalRow, 'final');
    const rows = [totalRow, availableRow, trustRow, finalRow];
    setTotalPrice(Numbers.cleanNumber(currentResourcesRows?.totalSalesRows.total));

    setRows(rows);
  }, [currentResourcesRows]);

  return { rows: rowList, columns, leftColumns, totalPrice };
}

export default useRowsForSoldReportForExternalResources;
