import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Radio } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EditableCell from '../EditableCell';
import { DEFAULT_DATE_FORMAT } from '../../../../../../../../helpers/dates';
import QuotationPaymentTypeRow from '../../../../../../../../App/Quotation/UI/views/rows/QuotationPaymentTypeRow';
import {
  CustomPaymentTableRow,
  CustomPaymentTableCell,
} from '../ReservationDeposit/CustomPaymentTableRow';
import useQuotationEditionType from '../../../../../../../../App/Quotation/Core/customHooks/useQuotationEditionType';
import useQuotationMonthDate from '../../../../../../../../App/Quotation/Core/customHooks/useQuotationMonthDate';
import QuotationDiscountType from '../../../../../../../../App/Quotation/Core/enums/QuotationDiscountType';

const MonthlyPayment = ({
  onChange,
  index,
  quotation,
  showEditableButtons,
  enableDiscountCalculation,
  dispatchFetchQuotationSuccess,
}) => {
  const {
    isEditingMode: editMode,
    isLockedType,
    distributionHandler,
    updateDistributionIndex,
    onClickDiscount,
    handleEditionInitialDate,
    discountType,
  } = useQuotationEditionType();

  const {
    paymentStartDate,
    distribution,
    paymentTypes,
    initialDate,
    proposalPaymentStartDate,
  } = quotation;

  const startDate = showEditableButtons
    ? paymentStartDate
    : proposalPaymentStartDate;

  const { currentMonth } = useQuotationMonthDate(
    index,
    startDate,
    initialDate,
    showEditableButtons,
  );

  const value =
    paymentTypes && distribution.length > index ? distribution[index] : '0';
  const typeId =
    paymentTypes && paymentTypes.length > index ? paymentTypes[index + 1] : '0';

  const onChangeValue = (event) => {
    if (Number(event.target.value) !== Number(value)) {
      onChange(event);
      distributionHandler(index, event.target.value);
    }
  };

  return (
    <CustomPaymentTableRow isEditing={editMode}>
      <TableCell>
        <Typography>{index + 1}</Typography>
      </TableCell>

      {showEditableButtons && (
        <CustomPaymentTableCell isEditing={editMode}>
          <Typography>
            <QuotationPaymentTypeRow
              key={`QuotationPaymentTypeRow_${index + 1}_${typeId}`}
              index={index + 1}
              typeId={typeId}
              quotation={quotation}
              dispatchFetchQuotationSuccess={dispatchFetchQuotationSuccess}
            />
          </Typography>
        </CustomPaymentTableCell>
      )}

      <TableCell>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {(index !== 0 || !showEditableButtons || !editMode) &&
            currentMonth.format(DEFAULT_DATE_FORMAT).toString()}
          {index === 0 && showEditableButtons && editMode && (
            <KeyboardDatePicker
              allowKeyboardControl={false}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline-initial-deposit"
              label="Fecha inicial"
              minDate={currentMonth.clone().startOf('M')}
              maxDate={currentMonth.clone().endOf('M')}
              value={currentMonth}
              onChange={handleEditionInitialDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          )}
        </MuiPickersUtilsProvider>
      </TableCell>

      <TableCell>
        <EditableCell
          name={`distribution[${index}]`}
          value={value}
          showEditableButtons={showEditableButtons}
          onChange={onChangeValue}
          onBlur={() => {
            if (discountType === QuotationDiscountType.ASSIGNED.code)
              onClickDiscount();
          }}
          onEnter={() => {
            if (discountType === QuotationDiscountType.ASSIGNED.code)
              onClickDiscount();
          }}
        />
      </TableCell>
      {editMode && enableDiscountCalculation && (
        <TableCell>
          <Radio
            disabled={isLockedType}
            checked={quotation.currentIndexForDiscount === index + 1}
            onChange={() => {
              updateDistributionIndex(index + 1);
            }}
            value="a"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        </TableCell>
      )}
    </CustomPaymentTableRow>
  );
};

MonthlyPayment.propTypes = {
  quotation: PropTypes.shape({
    paymentStartDate: PropTypes.string,
    proposalPaymentStartDate: PropTypes.string,
    distribution: PropTypes.array,
    paymentTypes: PropTypes.array,
    currentIndexForDiscount: PropTypes.any,
    discountPrice: PropTypes.any,
    initialDate: PropTypes.any,
  }).isRequired,
  index: PropTypes.number.isRequired,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dispatchFetchQuotationSuccess: PropTypes.func.isRequired,
};

export default MonthlyPayment;
