import React, { FC, useContext } from 'react';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Styles from '../../ContractDetailMainView.module.scss';
import { MuiIconButton } from 'shared/components/Button/Button';
import { ContractDetailContext, ContractDetailContextState } from '../../ContractDetailMainView';
import useContractDetailDrawerMenu from '../../../Core/customHooks/useContractDetailDrawerMenu';

const ContractDetailDrawerMenu: FC = () => {
  const { handleListItemChange, selectedIndex, currentContractIsLot } = useContext(
    ContractDetailContext,
  ) as ContractDetailContextState;

  const { options } = useContractDetailDrawerMenu();

  return (
    <List>
      {options.map(
        (option) =>
          (option.isOnlyForLots === currentContractIsLot || option.isOnlyForLots === false) && (
            <ListItem
              button
              classes={{
                root: Styles.menuItem,
                selected: Styles.menuItemSelected,
              }}
              selected={selectedIndex === option.index}
              key={option.key}
              onClick={() => handleListItemChange(option.index)}
            >
              <ListItemText primary={option.title} />
              <ListItemSecondaryAction>
                <MuiIconButton edge="end" aria-label={option.key} disabled>
                  <ArrowForwardIosIcon />
                </MuiIconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ),
      )}
    </List>
  );
};

export default ContractDetailDrawerMenu;
