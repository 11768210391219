import React from 'react';
import { PropTypes } from 'prop-types';
import InputWithTitleForClient from '../../../AddClientModal/UI/Views/input/InputWithTitleForClient';
import { Grid } from '@material-ui/core';

const OpenAddressForClientType = ({ update, addressDescription }) => {
  return (
    <Grid item md={12}>
      <InputWithTitleForClient
        title="Dirección"
        id="residenceAddress"
        name="residenceAddress"
        defaultValue={addressDescription}
        label="Ingrese la dirección"
        onChange={update}
        required
      />
    </Grid>
  );
};

OpenAddressForClientType.propTypes = {
  update: PropTypes.func.isRequired,
  addressDescription: PropTypes.string,
};

export default OpenAddressForClientType;
