import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import ProjectServices from '../../services/Projects/ProjectServices';
import CompanyServices from '../../services/companies';
import ProjectItems from '../../components/Projects/Projects';
import Modal from '../../components/UI/Modal/Modal';
import Input from '../../components/UI/Input/Input';
import { DashboardRoutes, GroupsRoutes } from '../../routes/local/routes';
import LoadableContainer from '../../components/UI/Loader';
import CompanyModal from '../../components/Projects/company/CreateCompanyModal';
import Agent from '../../config/config';
import { Role } from '../../helpers';
import styles from './Projects.module.scss';
import Context from '../../Context';
import { SelectCompanyModal } from '../../components/Projects/company/SelectCompanyModal';

export default class Projects extends Component {

  static contextType = Context.Shared

  constructor(props) {
    super(props);
    this.services = new ProjectServices();
    this.companyServices = new CompanyServices();
  }

  state = {
    projectIsMissingCompany: null,
    projects: [],
    modalIsHidden: true,
    showModalSelectCompany: false,
    newTitleProject: '',
    newDescriptionProject: '',
    assignCompany: '',
    alertMessage: '',
    alertIsHidden: true,
    alertAccept: () => { },
    isLoading: false,
    companies: undefined,
    company: "",
    currentEditingProject: null,
  };

  componentDidMount() {
    if (this.props.changeTower) {
      this.props.changeTower(null);
    }
    this.loadCurrentProjects();
  }

  openProjectHandler = (id) => {
    this.props.history.push({
      pathname: this.props.match.url + DashboardRoutes.towers.value + id,
    });
  };

  openCtrlProjectHandler = (id) => {
    const urlToGo = this.props.match.url + DashboardRoutes.towers.value + id;
    window.open(urlToGo, '_blank');
  };

  createProjectHandler = () => {
    this.setState({
      modalIsHidden: false,
    });
  };

  removeProjectHandler = (id) => {
    const onAccept = () => {
      const { companies } = this.state;
      this.setState({
        alertIsHidden: true,
      });
      this.services
        .removeProject({ projectId: id })
        .then((response) => {
          const { projects } = response.data;
          if (projects) {
            this.setState({
              projects,
              modalIsHidden: projects.length > 0,
              companies: this.state.companies,
            });
          } else {
            this.setState({
              companies,
            });
          }
        })
        .catch((error) => {
          console.log('ERROR::: ', error);
        });
    };

    this.setState({
      alertAccept: onAccept,
      alertMessage:
        'Está seguro de que quiere eliminar todo el proyecto? Al hacer esto eliminará toda la info interna',
      alertIsHidden: false,
    });
  };

  setCompanyId = (id) => {
    const { loadSessionCompany } = this.context
    loadSessionCompany(id)
    this.setState({ showModalSelectCompany: false, company: id })
  }

  loadCurrentCompany = () => {
    const { companies } = this.state
    if (Array.isArray(companies) && companies.length === 1 && companies[0].id)
      return this.setCompanyId(companies[0].id)
    else if (Array.isArray(companies) && companies.length > 1) {
      const { loadSessionCompany } = this.context
      const id = loadSessionCompany()
      if (id) {
        const company = companies.find(company => company.id === id)
        if (company) return this.setCompanyId(company.id)
      }
      this.setState({ showModalSelectCompany: true })
    }
  }

  loadCurrentProjects = () => {
    this.setState({ isLoading: true });
    this.services
      .getProjects()
      .then((response) => {
        const nullCompany = response.data.projects.find(
          (project) => !project.companyId,
        );
        this.setState({
          projectIsMissingCompany: nullCompany,
          projects: response.data.projects ? response.data.projects : [],
          modalIsHidden: response.data.projects.length > 0 || nullCompany,
          isLoading: false,
          companies: response.data.companies,
        }, () => this.loadCurrentCompany());
      })
      .catch((error) => {
        this.setState({
          projects: [],
          modalIsHidden: true,
          companies: [],
        });
        this.setState({ isLoading: false });
      });
  };

  onCreateAndAssociateCompany = (name) => {
    if (this.state.projectIsMissingCompany) {
      this.createNewCompanyWithProject(
        name,
        this.state.projectIsMissingCompany.id,
      );
    } else {
      this.createNewCompany(name);
    }
  };

  createNewCompany = (name) => {
    this.companyServices.create(name).then((response) => {
      const newCompany = response.data;
      if (this.state.companies.length < 1) {
        this.setState({ companies: [newCompany] });
      }
    });
  };

  createNewCompanyWithProject = (name, projectId) => {
    this.companyServices.createWithProject(name, projectId).then((response) => {
      if (response.data) {
        this.loadCurrentProjects();
      }
    });
  };

  associateCompanyWithProject = (companyId, projectId) => {
    this.companyServices
      .associateWithProject(companyId, projectId)
      .then((response) => {
        if (response.data) {
          this.loadCurrentProjects();
        }
      });
  };

  onEditProject = () => {
    const {
      id,
      name,
      description,
      companyId,
    } = this.state.currentEditingProject;
    this.services
      .updateProject(id, { name, description, companyId })
      .then((response) => {
        const project = { ...response.data[1] };
        project.id = id;
        this.setState((prevState) => {
          const { projects } = prevState;
          const index = projects.findIndex((o) => o.id === id);
          projects[index] = project;
          return {
            projects,
            currentEditingProject: null,
            modalIsHidden: true,
          };
        });
      })
      .catch((error) => {
        this.setState({ currentEditingProject: null, modalIsHidden: true });
        console.error(error);
      });
  };

  onCreate = () => {
    if (this.state.newTitleProject === '') {
      alert('Dale un lindo nombre a tu proyecto');
      return;
    }
    this.services
      .createProject({
        name: this.state.newTitleProject,
        description: this.state.newDescriptionProject,
        companyId: this.state.assignCompany,
      })
      .then((response) => {
        this.setState({
          projects: response.data.projects ? response.data.projects : [],
          modalIsHidden: response.data.projects.length > 0,
        });
      })
      .catch((error) => {
        console.log('ERROR::: ', error);
      });

    this.setState({
      modalIsHidden: true,
      newTitleProject: '',
      newDescriptionProject: '',
    });
  };

  cancel = () => {
    this.setState({
      alertIsHidden: true,
      currentEditingProject: null,
    });

    if (this.state.projects.length > 0) {
      this.setState({
        modalIsHidden: true,
      });
    } else {
      alert(
        'Para acceder a todos los beneficios de esta plataform primero debes tener por lo menos un proyecto',
      );
    }
  };

  onChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  onChangeProject = (name) => (target) => {
    const currentEditingProject = {
      ...this.state.currentEditingProject,
      [name]: name === 'companyId' ? target.target.value : target.value,
    };
    this.setState({
      currentEditingProject,
    });
  };

  createModal = () => {
    return (
      <Modal
        title={
          this.state.currentEditingProject
            ? `Editar proyecto`
            : 'Crear proyecto'
        }
        className={styles.zzz}
        hidden={this.state.modalIsHidden}
        onConfirm={
          this.state.currentEditingProject ? this.onEditProject : this.onCreate
        }
        onCancel={this.cancel}
      >
        <div className={styles.projectModalDiv}>
          <label className={styles.projectModalName}>Nombre</label>
          <Input
            className={styles.s}
            name="newTitleProject"
            onChange={
              this.state.currentEditingProject
                ? this.onChangeProject('name')
                : this.onChange
            }
            validations={[]}
            style={{ width: '180px' }}
            value={
              this.state.currentEditingProject
                ? this.state.currentEditingProject.name
                : this.state.newTitleProject
            }
          />
        </div>
        <div className={styles.projectModalDiv}>
          <label className={styles.projectModalName}>Descripción</label>
          <Input
            name="newDescriptionProject"
            onChange={
              this.state.currentEditingProject
                ? this.onChangeProject('description')
                : this.onChange
            }
            validations={[]}
            style={{ width: '180px' }}
            value={
              this.state.currentEditingProject
                ? this.state.currentEditingProject.description
                : this.state.newDescriptionProject
            }
          />
        </div>

        <FormControl
          classes={{ root: styles.rootSelect }}
          className={styles.assignCompanyDiv}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Asignar una compañía
          </InputLabel>
          <Select
            classes={{ root: styles.rootSelect }}
            name="assignCompany"
            value={
              this.state.currentEditingProject
                ? this.state.currentEditingProject.companyId
                : this.state.assignCompany
            }
            onChange={
              this.state.currentEditingProject
                ? this.onChangeProject('companyId')
                : (event) =>
                  this.setState({ [event.target.name]: event.target.value })
            }
          >
            {this.state.companies.map((element) => (
              <MenuItem value={element.id}>{element.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Modal>
    );
  };

  createAlert() {
    return (
      <Modal
        title={'Alerta!'}
        hidden={this.state.alertIsHidden}
        onConfirm={this.state.alertAccept}
        onCancel={this.cancel}
      >
        <p>{this.state.alertMessage}</p>
      </Modal>
    );
  }

  editHandler = (projectId) => {
    const { projects } = this.state;
    const currentEditingProject = projects.find(
      (project) => project.id === projectId,
    );
    this.setState({
      currentEditingProject,
      modalIsHidden: !currentEditingProject,
    });
  };

  redirectToGroups = () => {
    this.props.history.push({
      pathname: GroupsRoutes.base,
    });
  };

  handleCloseSelectCompanyModal = () => {
    if (this.state.company) this.setState({ showModalSelectCompany: false })
  }

  render() {
    const { projectIsMissingCompany, projects, companies, company, showModalSelectCompany } = this.state;
    const showCompanyModal =
      projects &&
      companies &&
      (projectIsMissingCompany ||
        (projects.length < 1 && companies.length < 1));

    return (
      <LoadableContainer isLoading={this.state.isLoading}>
        <SelectCompanyModal open={showModalSelectCompany}
          handleClose={this.handleCloseSelectCompanyModal}
          handleListItemClick={company => this.setCompanyId(company.id)}
          companies={companies} />
        {Agent.isAuthorized([Role.Super]) && companies && (
          <CompanyModal
            isOpen={showCompanyModal}
            onCreate={this.onCreateAndAssociateCompany}
            onAssociate={this.associateCompanyWithProject}
            project={projectIsMissingCompany}
            companies={companies}
          />
        )}
        { company && projects.length > 0 ? (
          <ProjectItems
            projects={projects}
            openProject={this.openProjectHandler}
            createProject={this.createProjectHandler}
            removeProject={this.removeProjectHandler}
            editHandler={this.editHandler}
            company={company}
            showModalSelectCompany={() => this.setState({showModalSelectCompany: true})}
            DashboardRoutesValue={DashboardRoutes.towers.value}
            openCtrlProject={this.openCtrlProjectHandler}
            url={this.props.match.url}
            redirectToGroups={this.redirectToGroups}
          />
        ) :
          !(projects && projects.length) && <Typography variant="h6" align="center">
            Actualmente no tiene proyectos asociados, comuniquese con el
            administrador
          </Typography>
        }
        {(!this.state.modalIsHidden || this.state.currentEditingProject) &&
          !showCompanyModal &&
          this.createModal()}
        {!this.state.alertIsHidden && this.createAlert()}
      </LoadableContainer>
    );
  }
}
