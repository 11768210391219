import { useState, useEffect } from 'react';
import {
  ChangeSet,
  Column,
  EditingColumnExtension,
  EditingState,
  IntegratedSummary,
  SummaryItem,
  Table,
} from '@devexpress/dx-react-grid';
import { useSnackbar } from 'notistack';

import {
  ContractPaymentScheduleFlow,
  ContractPaymentInvoice,
  ContractInterest,
} from '../../../../models/ContractPayments';
import { IStateChip } from '../../../../shared/components/StateChip/StateChip';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import useAddContractPaymentInvoice from './useAddContractPaymentInvoice';
import useAddContractInterest from './useAddContractInterest';

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

const getColumns = (): ColumnType[] => [
  {
    title: 'Fecha de pago',
    name: 'finalDate',
    columnName: 'finalDate',
    align: 'center',
  },
  {
    title: 'Forma de pago',
    name: 'amountBudget',
    columnName: 'amountBudget',
    align: 'right',
  },
  {
    title: 'Real / Proyectado',
    name: 'amount',
    columnName: 'amount',
    align: 'right',
  },
  {
    title: 'Interés calculado',
    name: 'calculatedInterest',
    columnName: 'calculatedInterest',
    align: 'right',
  },
  {
    title: 'Interés pagado',
    name: 'payedInterest',
    columnName: 'payedInterest',
    align: 'right',
  },
  {
    title: 'Saldo',
    name: 'balance',
    columnName: 'balance',
    align: 'right',
  },
  {
    title: 'Estado',
    name: 'state',
    columnName: 'state',
    align: 'center',
    width: 150,
    getCellValue: (row: ContractPaymentScheduleFlow): IStateChip => {
      const paymentState: IStateChip = {
        color: 'gray400',
        label: 'Proyectado',
      };

      if (row.state === 'INVOICED') {
        paymentState.color = 'primary200';
        paymentState.label = 'Facturado';
      }
      return paymentState;
    },
  },
];

interface UseColumnsAndRowsForContractPaymentsScheduleParams {
  currentContractId: number;
  originalRows: ContractPaymentScheduleFlow[] | undefined;
}

type HookType = (param: UseColumnsAndRowsForContractPaymentsScheduleParams) => {
  rows: ContractPaymentScheduleFlow[] | undefined;
  columns: ColumnType[];
  dateColumns: string[];
  currencyColumns: string[];
  currencyEditColumns: string[];
  chipColumns: string[];
  totalSummarySchedules: SummaryItem[];
  editingStateColumnExtensions: EditingState.ColumnExtension[];
  commitChanges: (changes: ChangeSet) => void;
  summaryCalculator: (
    type: string,
    rows: ContractPaymentScheduleFlow[],
    getValue: (row: ContractPaymentScheduleFlow) => ContractPaymentScheduleFlow,
  ) => number | string | undefined;
};

const useColumnsAndRowsForContractPaymentsSchedule: HookType = ({ originalRows, currentContractId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { addContractPaymentInvoice } = useAddContractPaymentInvoice(currentContractId);
  const { addContractInterest } = useAddContractInterest(currentContractId);

  const [columns] = useState(getColumns());
  const [dateColumns] = useState(['finalDate']);
  const [currencyColumns] = useState(['amountBudget', 'calculatedInterest', 'balance']);
  const [currencyEditColumns] = useState(['amount', 'payedInterest']);
  const [chipColumns] = useState(['state']);
  const [totalSummarySchedules] = useState([
    { columnName: 'finalDate', type: 'title' },
    { columnName: 'amount', type: 'sum' },
    { columnName: 'amountBudget', type: 'sum' },
    { columnName: 'calculatedInterest', type: 'sum' },
    { columnName: 'payedInterest', type: 'sum' },
  ]);

  const [editingStateColumnExtensions] = useState<EditingColumnExtension[]>([
    { columnName: 'finalDate', editingEnabled: false },
    { columnName: 'state', editingEnabled: false },
    { columnName: 'amount', editingEnabled: true },
    { columnName: 'amountBudget', editingEnabled: false },
    { columnName: 'difference', editingEnabled: false },
    { columnName: 'calculatedInterest', editingEnabled: false },
    { columnName: 'payedInterest', editingEnabled: true },
    { columnName: 'balance', editingEnabled: false },
  ]);

  const [rows, setRows] = useState<ContractPaymentScheduleFlow[] | undefined>(originalRows);

  useEffect(() => {
    return setRows(originalRows);
  }, [originalRows]);

  const commitChanges = async ({ changed }: ChangeSet): Promise<void> => {
    let changedRows: ContractPaymentScheduleFlow[] = [];
    if (changed) {
      const rowIndex = Object.keys(changed)[0];
      const rowUpdated: ContractPaymentScheduleFlow = changed[rowIndex];

      if (rowUpdated)
        if (!!rowUpdated.amount || rowUpdated.amount === 0) {
          const invoice: ContractPaymentInvoice = {
            invoiceDate: Number(rowIndex),
            amount: rowUpdated.amount,
            tax: 0,
            contractId: currentContractId,
            invoiceNumber: '0',
          };

          try {
            await addContractPaymentInvoice(invoice);
            if (originalRows) {
              changedRows = originalRows.map((row) =>
                changed[row.finalDate] ? { ...row, ...changed[row.finalDate] } : row,
              );
            }
          } catch ({ message }) {
            enqueueSnackbar(`${message}`, { variant: 'error' });
          }
        }
      if (!!rowUpdated?.payedInterest || rowUpdated?.payedInterest === 0) {
        const interest: ContractInterest = {
          paymentDate: Number(rowIndex),
          amount: rowUpdated.payedInterest,
          contractId: currentContractId,
        };
        try {
          await addContractInterest(interest);
          if (originalRows) {
            changedRows = originalRows.map((row) =>
              changed[row.finalDate] ? { ...row, ...changed[row.finalDate] } : row,
            );
          }
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }

    return setRows(changedRows || originalRows);
  };

  const summaryCalculator = (
    type: string,
    rows: ContractPaymentScheduleFlow[],
    getValue: (row: ContractPaymentScheduleFlow) => ContractPaymentScheduleFlow,
  ) => {
    if (type === 'title') {
      if (!rows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', rows, getValue);
  };

  return {
    rows,
    columns,
    currencyColumns,
    currencyEditColumns,
    chipColumns,
    dateColumns,
    totalSummarySchedules,
    editingStateColumnExtensions,
    commitChanges,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractPaymentsSchedule;
