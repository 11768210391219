import SharedServices from '../../../../services/Shared/SharedServices';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

function useResetCacheService(callBack: () => void) {
  const { towerId } = useParams<{ towerId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const onResetCache = async () => {
    const services = new SharedServices();
    try {
      await services.postResetCache(towerId);
      enqueueSnackbar('Se actualizaron los datos de la tabla', { variant: 'success' });
      callBack();
    } catch (error) {
      const { message } = error as Error;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  return {
    onClick: onResetCache,
  };
}

export default useResetCacheService;
