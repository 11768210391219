import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import classes from '../../../styles/ResultsStateCustomCell.module.scss';

const ContractsPercentage = ({ cellData }) => {
  return (
    <VirtualTable.Cell className={classes.virtualTableCell}>
      <Box className={classes.itemsFloor}>
        <Typography variant="body2" className={classes.itemsTypography}>
          {cellData}
        </Typography>
      </Box>
    </VirtualTable.Cell>
  );
};

ContractsPercentage.propTypes = {
  cellData: PropTypes.number,
};

export default ContractsPercentage;
