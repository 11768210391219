import { DEFAULT_DATE_FORMAT } from 'helpers/dates';
import { WalletPaymentDistribution } from '../DTO/WalletMonthlyReportDTO';
import MonthlyInterestSummaryPaymentRow from '../interfaces/MonthlyInterestSummaryPaymentRow';
import Numbers from '../../../../helpers/numbers';
import moment from 'moment';

function useMonthlyCommitmentPaymentsMapper(payments: WalletPaymentDistribution[]) {
  const rows: MonthlyInterestSummaryPaymentRow[] = payments.map((payment, index) => {
    let number = index + 1 + '';
    return {
      number,
      date: moment(new Date(payment.date)).format(DEFAULT_DATE_FORMAT) ?? '',
      value: Numbers.currencyFormat(payment.price),
    };
  });

  return { rows };
}

export default useMonthlyCommitmentPaymentsMapper;
