import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import debouncedInput from '../../../../UI/TextFields/debouncedInput';

const DebouncedTextField = debouncedInput(TextField, { timeout: 300 });

const Input = ({ params, onChangeHandler, defaultValue }) => {
  return (
    <DebouncedTextField
      {...params}
      label="Cliente"
      variant="outlined"
      onChange={onChangeHandler}
      fullWidth
    />
  );
};

Input.propTypes = {
  params: PropTypes.object,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Input;
