import React from 'react';

import { Button } from 'shared/components/Button/Button';
import useMaximumRotationChangeHandler from '../../../core/customHooks/useMaximumRotationChangeHandler';
import { MaximumRotation } from '../../../core/DTO/SalesSpeedHelperDTO';
import { OnMaximumRotationMonthsChange } from '../../../core/types/SalesSpeedHelper.types';
import TextField from 'shared/components/TextField/TextField';
import Typography from 'shared/components/Typography/Typography';

import styles from './MaximumRotationInput.module.scss';

type Props = {
  isDisabled: boolean;
  maximumRotation?: MaximumRotation;
  onChange: OnMaximumRotationMonthsChange;
};

const MaximumRotationInput: React.FC<Props> = ({ isDisabled, maximumRotation, onChange }) => {
  const { maximumRotationMonths, hasChanges, errorState, handleOnChange, handleKeyUp, handleApplyClick } =
    useMaximumRotationChangeHandler({
      maximumRotation: maximumRotation,
      onChange,
    });

  return (
    <div className={styles.root}>
      <Typography className={styles.label} variant="body1" as="label">
        Máximo de rotación
      </Typography>
      <TextField
        className={styles.input}
        size="small"
        placeholder="-"
        disabled={isDisabled}
        value={maximumRotationMonths}
        error={errorState.hasError}
        helperText={errorState.message}
        onChange={handleOnChange}
        onKeyUp={handleKeyUp}
      />
      {hasChanges && (
        <Button className={styles.button} variant="contained" size="small" onClick={handleApplyClick}>
          Aplicar
        </Button>
      )}
    </div>
  );
};

export default MaximumRotationInput;
