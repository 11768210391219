import { useState, useContext } from 'react';
import ContainerContext from '../../../../containers/Client/context';
import { updateSelectedClient } from '../../../../containers/Client/actions';

function useHandlerForClientAddressDialog(innerClient) {
  const { dispatch } = useContext(ContainerContext);
  const [isOpen, setOpen] = useState(false);

  const onClose = () => {
    if (!isOpen) {
      dispatch(updateSelectedClient(innerClient));
    }
    setOpen(!isOpen);
  };

  return { isOpen, onClose };
}

export default useHandlerForClientAddressDialog;
