import moment from 'moment';
import { ClientInformationModel } from '../models/ClientInformationModel';

const GetInitialClientInformation = (): ClientInformationModel => ({
  placeOfIssuanceId: '',
  dateOfIssuanceOfID: moment().valueOf(),
  departmentOfResidence: '',
  neighborhood: '',
  officeCity: '',
  profession: '',
  position: '',
  residenceAddress: '',
  countryOfResidence: '',
  cityOfResidence: '',
  companyName: '',
  companyAddress: '',
  companyPhone: '',
  monthlyIncome: 0,
  chargeNumber: '',
  responseQuestionOne: undefined,
});

export default GetInitialClientInformation;
