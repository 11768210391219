import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  TableHeaderRow,
  TableTreeColumn,
  TableFixedColumns,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import Box from '@material-ui/core/Box';
import {
  TreeDataState,
  CustomTreeData,
  EditingState,
} from '@devexpress/dx-react-grid';
import useColumnsForGeneral, {
  getChildRows,
} from '../../../Core/customHooks/useColumnsForGeneral';
import useRowsForTotalCashFlow from '../../../Core/customHooks/useRowsForTotalCashFlow';
import MainCashFlowCell from '../cells/MainCashFlowCell';
import HeaderCustomCell from '../cells/HeaderCustomCellForTotalCashFlow';
import useMapperForNewRows from '../../../Core/customHooks/useMapperForNewRowsForGeneral';
import usePopoverForCashflowTable from 'App/BuilderCreditSetupModal/Core/customHooks/usePopoverForCashflowTable';
import LeftColumnCustomCell from '../cells/LeftColumnCustomCell';
import useCommitForGeneral from 'App/TotalCashFlow/Core/customHooks/useCommitForGeneral';
import classes from './TotalCashFlowTable.module.scss';

const TotalCashFlowTable = ({
  salesRows: initialSalesRows,
  initialDate,
  lastDate,
  onRowChange,
  rows: initialRows,
  divider,
}) => {
  const salesRows = initialRows || initialSalesRows;
  const {
    columns,
    tableColumnExtensions,
    defaultExpandedRowIds,
    leftColumns,
    dates,
  } = useColumnsForGeneral(salesRows, initialDate, lastDate);

  const { onSelect, onClose } = usePopoverForCashflowTable(onRowChange);

  const rows = useRowsForTotalCashFlow(salesRows, dates);

  const newRows = useMapperForNewRows({ rows });

  const commitChanges = useCommitForGeneral({ salesRows, onRowChange });

  const rowComponent = ({ tableRow, ...restProps }) => {
    return <VirtualTable.Row {...restProps} />;
  };

  const Cell = useCallback(
    (props) => {
      const { row } = props;

      return (
        <>
          <MainCashFlowCell
            leftColumns={leftColumns}
            divider={divider}
            onSelect={onSelect}
            {...props}
          />
        </>
      );
    },
    [onSelect, onClose, divider],
  );

  return (
    <Box className={classes.generalTable}>
      <Grid rows={newRows} columns={columns} className={classes.gridTable}>
        <EditingState onCommitChanges={commitChanges} />
        <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
        <CustomTreeData getChildRows={getChildRows} />
        <VirtualTable
          columnExtensions={tableColumnExtensions}
          cellComponent={Cell}
          rowComponent={rowComponent}
        />
        <TableHeaderRow cellComponent={HeaderCustomCell} />
        <TableTreeColumn for="name" cellComponent={LeftColumnCustomCell} />
        <TableFixedColumns leftColumns={leftColumns} />
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { salesRows, initialDate, lastDate } = state.cashFlow.root;
  return {
    salesRows,
    initialDate,
    lastDate,
  };
};

const mapDispatchToProps = {};

TotalCashFlowTable.propTypes = {
  salesRows: PropTypes.array,
  initialDate: PropTypes.number,
  lastDate: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCashFlowTable);
