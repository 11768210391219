import { FETCH_PROJECT_STAGES, UPDATE_FIELD } from './actions';

const initialState = {
  stages: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_FIELD:
      return { ...state, [payload.field]: payload.value };

    case FETCH_PROJECT_STAGES:
      return { ...state, stages: payload };

    default:
      return state;
  }
};
