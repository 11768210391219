import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Context from "../../../Context";
import { DashboardRoutes, ProjectRoutes } from "../../../routes/local/routes";

const useResolveCompany = () => {
    const { loadSessionCompany, sessionCompany } = useContext(Context.Shared);
    const history = useHistory();
    const [company, setCompany] = useState();
  
    useEffect(() => {
      const companyId = sessionCompany || loadSessionCompany();
      if (!companyId)
        return history.push(DashboardRoutes.base + ProjectRoutes.base);
      setCompany(companyId);
    }, []);

    return {
        company
    }
}

export default useResolveCompany