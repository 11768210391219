import { StepProps } from '@material-ui/core';
import useBoolean from 'shared/customHooks/useBoolean';
import { useEffect, useState } from 'react';

const useAddClientModalContentStep = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<StepProps[]>(
    new Array<StepProps>(5).fill({
      active: false,
      completed: false,
    }),
  );
  const {
    value: isFinishSteps,
    setTrue: finishSteps,
    setFalse: imcompletedSteps,
  } = useBoolean(false);

  const activateStep = (index: number) => {
    setActiveStep(index);
    setSteps(
      steps.map((step, i) => ({
        ...step,
        active: i === index,
      })),
    );
  };

  const completedStep = (completed: boolean) => {
    if (completed) {
      if (activeStep < steps.length - 1) {
        setSteps(
          steps.map((step, i) => ({
            ...step,
            completed: i <= activeStep,
            active: i === activeStep + 1,
          })),
        );
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < steps.length + 1) {
    }
  };

  const handleBack = () => {
    const newStep = activeStep > 4 ? 3 : activeStep - 1;
    if (newStep < 0) return;
    setActiveStep(newStep);
    setSteps(
      steps.map((step, i) => ({
        ...step,
        completed: i < newStep,
        active: i === newStep,
      })),
    );
  };

  useEffect(() => {
    activateStep(0);
  }, []);

  return {
    activeStep,
    steps,
    isFinishSteps,
    completedStep,
    handleNext,
    handleBack,
  };
};

export default useAddClientModalContentStep;
