import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FinancialNeighborhoodAlgorithms from '../../../../helpers/FinancialNeighborhoodAlgorithms';
import Numbers from '../../../../helpers/numbers';
import QuotationsServices from '../../../../services/Quotations';

export const calculateMonthsForYear = (years) => {
  const MONTHS_AT_YEAR = 12;
  return Numbers.cleanNumber(years * MONTHS_AT_YEAR);
};
export const calculateMonthlyRate = (annualRate) => {
  return Numbers.cleanNumber(
    FinancialNeighborhoodAlgorithms.totalIncrementRateToListIncrementRate(
      annualRate,
      12,
    ) * 100,
  );
};

function useBankTermLimitForQuotationSetup(
  initialYears,
  initialAnnualRate,
  { initialMaxDueDaysForProperty },
) {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();

  const [yearsTermLimit, setTermLimit] = useState(
    Numbers.cleanNumber(initialYears),
  );
  const [previousLimit, setPreviousLimit] = useState(
    Numbers.cleanNumber(initialYears),
  );

  const [annualRate, setAnnualRate] = useState(
    Numbers.cleanNumber(initialAnnualRate * 100),
  );
  const [previousRate, setPreviousRate] = useState(
    Numbers.cleanNumber(initialAnnualRate * 100),
  );

  const [maxDueDaysForProperty, setMaxDueDaysForProperty] = useState(
    Numbers.cleanNumber(initialMaxDueDaysForProperty),
  );

  useEffect(() => {
    setMaxDueDaysForProperty(initialMaxDueDaysForProperty);
  }, [initialMaxDueDaysForProperty]);

  const putQuotationSetup = async (field, newValue) => {
    const services = new QuotationsServices();
    try {
      await services.putQuotationSetup(towerId, field, newValue);
      setMaxDueDaysForProperty(newValue);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setTermLimit(previousLimit);
    }
  };

  const updateTermLimitSetup = async (newTerm) => {
    const services = new QuotationsServices();
    try {
      await services.putQuotationSetup(towerId, 'termLimit', newTerm);
      setPreviousLimit(newTerm);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setTermLimit(previousLimit);
    }
  };

  const updateAnnualRateSetup = async (newFee) => {
    const services = new QuotationsServices();
    try {
      await services.putQuotationSetup(towerId, 'annualRate', newFee / 100);
      setPreviousRate(newFee);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setAnnualRate(previousRate);
    }
  };

  useEffect(() => {
    if (yearsTermLimit !== initialYears) {
      setTermLimit(initialYears);
    }

    if (annualRate !== initialAnnualRate * 100) {
      setAnnualRate(Numbers.cleanNumber(initialAnnualRate * 100));
    }
  }, [initialYears, initialAnnualRate]);

  const updateTermLimit = async (values) => {
    if (yearsTermLimit !== values.floatValue) {
      setTermLimit(values.floatValue);
      await updateTermLimitSetup(values.floatValue);
    }
  };

  const updateAnnualEffectiveRate = async (values) => {
    if (annualRate !== values.floatValue) {
      setAnnualRate(values.floatValue);
      await updateAnnualRateSetup(values.floatValue);
    }
  };

  const onChangeMaxDueDaysForProperty = async (values) => {
    if (annualRate !== values.floatValue) {
      setMaxDueDaysForProperty(values.floatValue);
      await putQuotationSetup('maxDueDaysForProperty', values.floatValue);
    }
  };

  const monthsForCredit = calculateMonthsForYear(yearsTermLimit);
  const monthlyRate = calculateMonthlyRate(annualRate / 100);
  return {
    onChangeYears: updateTermLimit,
    onChangeEffectiveRange: updateAnnualEffectiveRate,
    yearsTermLimit,
    annualRate,
    monthsForCredit,
    monthlyRate,
    onChangeMaxDueDaysForProperty,
    maxDueDaysForProperty,
  };
}

export default useBankTermLimitForQuotationSetup;
