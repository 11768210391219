import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import useArrearsDailyReportRowsMapper from '../../../Core/customHooks/useArrearsDailyReportRowsMapper';
import ArrearsDailyReportRow from '../cell/ArrearsDailyReportRow';

const ArrearsDailyReportTable = () => {
  const rows = useArrearsDailyReportRowsMapper();
  return (<Box pt={5} pl={1} pr={1}>
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item container spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <Typography align="center">
            Cantidad
          </Typography>
        </Grid>
        <Grid item xs={4}>
        <Typography align="center">
          Valor
        </Typography>
        </Grid>
        <Grid item xs={2}>
        <Typography align="center">
          Part %
        </Typography>
        </Grid>
      </Grid>
      {rows.map((row) => (
        <ArrearsDailyReportRow
          key={`ArrearsDailyReportRow-${row.title}-${row.value}`}
          title={row.title}
          amount={row.amount}
          value={row.value}
          percentage={row.percentage}
        />
      ))}
    </Grid>
    </Box>
  );
};

export default ArrearsDailyReportTable;
