import React, { FC } from 'react';
import { Button, ButtonProps } from '../../../../shared/components/Button/Button';
import styles from './AreaButton.module.scss';

const AreaButton: FC<ButtonProps & { value: string }> = (props) => {
  return (
    <Button size="small" {...props} className={styles.margin}>
      <span className="button-label">{props.value}</span>
    </Button>
  );
};

export default AreaButton;
