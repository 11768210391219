import { ElementRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ClientModel } from 'App/Clients/Core/models/ClientModel';
import ClientsServices from 'services/client/ClientsServices';
import {
  fetchClientsFailure,
  fetchClientsStart,
  fetchClientsSuccess,
  removeClient,
  updateSelectedClient,
} from 'containers/Client/actions';
import { Sorting } from '@devexpress/dx-react-grid';
import { filterClean } from 'App/SalesRoom/Core/actions';
import { useHistory } from 'react-router-dom';
import { DashboardRoutes } from 'routes/local/routes';
import AsyncConfirmModal from 'shared/components/AsyncModal/AsyncConfirmModal';

const services = new ClientsServices();

interface Props {
  towerId: string;
  dispatch: (any) => void;
  openClientModal: () => void;
}

type AsyncConfirmModalRefType = ElementRef<typeof AsyncConfirmModal>;

const useClientActions = (props: Props) => {
  const { towerId, dispatch, openClientModal } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const limit = 25;
  const [sorting, changeSorting] = useState<Sorting[]>([{ columnName: 'createdAt', direction: 'desc' }]);
  const [isOpen, setIsOpen] = useState({ form: false, detail: false });
  const asyncConfirmDeleteClientModalRef = useRef<AsyncConfirmModalRefType>(null);
  const [selectedClient, setSelectedClient] = useState<ClientModel>();

  const fetchData = useCallback(async () => {
    try {
      const sortingQuery = sorting.map((sort) => ({
        field: sort.columnName,
        order: sort.direction,
      }));
      dispatch(fetchClientsStart());
      const { data } = await services.getClientsPaginate<{ clients: ClientModel; total: number }>(
        towerId,
        offset,
        limit,
        sortingQuery,
      );
      dispatch(fetchClientsSuccess(data.clients));
      setTotal(data.total);
    } catch (error) {
      dispatch(fetchClientsFailure());
    }
    dispatch(filterClean());
  }, [offset, limit, sorting]);

  useEffect(() => {
    fetchData();
    return () => {};
  }, [offset, limit, sorting]);

  const deleteClient = useCallback(async (client: ClientModel) => {
    try {
      const { id } = client;
      await services.deleteClient(id, towerId);
      dispatch(removeClient(id));
      enqueueSnackbar(`Cliente ${client.allowDelete ? 'eliminado' : 'desasociado'} correctamente`, {
        variant: 'success',
      });
      fetchData();
    } catch (error) {
      if (error instanceof Error) enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, []);

  const handleClickDelete = useCallback(async (client: ClientModel) => {
    setSelectedClient(client);
    await asyncConfirmDeleteClientModalRef.current?.show(() => deleteClient(client));
    setSelectedClient(undefined);
  }, []);

  const goToSalesRoom = (client: ClientModel) => {
    history.push(`${DashboardRoutes.base}${DashboardRoutes.salesRoom.value}${towerId}/${client.id}`);
  };

  const handleClickEditClient = (client: ClientModel) => {
    dispatch(updateSelectedClient(client));
    openClientModal();
  };

  const quotes = (client: ClientModel) => {
    dispatch(updateSelectedClient(client));
    setIsOpen({ ...isOpen, detail: true });
  };

  return {
    offset,
    limit,
    total,
    sorting,
    isOpen,
    asyncConfirmDeleteClientModalRef,
    selectedClient,
    setIsOpen,
    handleClickDelete,
    changeSorting,
    changeOffset: setOffset,
    goToSalesRoom,
    handleClickEditClient,
    quotes,
  };
};

export default useClientActions;
