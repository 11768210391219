import { Plugin, Getter, Action } from '@devexpress/dx-react-core';
import React, { useState } from 'react';

import getFilterExpressionComputed from '../../core/getFilterExpressionComputed';

type GeneralWalletToolbarStateProps = {
  defaultFilterValue: string;
  columnName: string;
};

const GeneralWalletToolbarState: React.FC<GeneralWalletToolbarStateProps> = ({
  defaultFilterValue,
  columnName
}) => {
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  return (
    <Plugin name="ToolbarFilterState" >
      <Getter name="toolbarFilterValue"
        value={filterValue} />
      <Getter name="filterExpression"
        computed={getFilterExpressionComputed(columnName)} />
      <Action name="changeToolbarFilterValue"
        action={setFilterValue} />
    </Plugin>
  )
};

export default GeneralWalletToolbarState;