import useBoolean from "./useBoolean";

interface UseModalProps {
    handleClose?: () => void;
    handleOpen?: () => void;
}

const useModal = (props?: UseModalProps) => {
    const { value: isOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)

    const close = () => {
        closeModal();
        if (props?.handleClose)
            props.handleClose();
    }

    const open = () => {
        openModal();
        if (props?.handleOpen)
            props.handleOpen();
    }

    return {
        isOpen,
        open,
        close,
    }
}

export default useModal