import React, { FC } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid';
import { MoreVertOutlined } from '@material-ui/icons';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';
import styles from './ContractPaymentScheduleGridHeaderCell.module.scss';
import { HandlePopoverOpenType } from 'shared/customHooks/useCustomPopover';

interface Props {
  onClickInvoice: HandlePopoverOpenType<{}>;
  onClickInterest: HandlePopoverOpenType<{}>;
}

const ContractPaymentScheduleGridHeaderCell =
  ({ onClickInvoice, onClickInterest }: Props): FC<TableHeaderRow.CellProps> =>
  (props) => {
    const { column } = props;

    if (column.name === 'amount') {
      return (
        <TableHeaderRowCell {...props}>
          <div className={styles.ContractPaymentScheduleGridHeaderCellContent}>
            {column.title}
            <MoreVertOutlined className={styles.More} onClick={onClickInvoice} />
          </div>
        </TableHeaderRowCell>
      );
    }
    if (column.name === 'payedInterest') {
      return (
        <TableHeaderRowCell {...props}>
          <div className={styles.ContractPaymentScheduleGridHeaderCellContent}>
            {column.title}
            <MoreVertOutlined className={styles.More} onClick={onClickInterest} />
          </div>
        </TableHeaderRowCell>
      );
    }
    return <TableHeaderRowCell {...props} />;
  };

export default ContractPaymentScheduleGridHeaderCell;
