import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { CustomPaymentTableRow } from '../Dialog/Payments/ReservationDeposit/CustomPaymentTableRow';
import { DEFAULT_DATE_FORMAT } from '../../../../helpers/dates';
import Numbers from '../../../../helpers/numbers';

const MonthlyRealPayment = ({
  index,
  date,
  price,
  editMode,
  enableDiscountCalculation,
}) => {
  return (
    <CustomPaymentTableRow isEditing={editMode}>
      <TableCell>
        <Typography>
          {index === 0 ? 'Separación' : `Pago No.${index}`}
        </Typography>
      </TableCell>
      <TableCell />

      <TableCell>
        {moment(Number(date))
          .format(DEFAULT_DATE_FORMAT)
          .toString()}
      </TableCell>

      <TableCell>{Numbers.currencyFormat(price)}</TableCell>
      {editMode && enableDiscountCalculation && <TableCell></TableCell>}
    </CustomPaymentTableRow>
  );
};

MonthlyRealPayment.propTypes = {
  date: PropTypes.string,
  price: PropTypes.string,
  index: PropTypes.number.isRequired,
  editMode: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
};

export default MonthlyRealPayment;
