import { useSnackbar } from 'notistack';
import ExternalResourcesServices from '../../../../services/externalResources/ExternalResourcesServices';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ExternalResourceForSalesType from '../interfaces/ExternalResourceForSalesType';
import { useQuery } from 'react-query';
import { GET_QUERY_ER_REPORT } from '../ReactQuery/SoldReportForERReactQuerty';

function useFetchServiceForSoldReportForExternalResource() {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams<{ towerId: string }>();
  // const [currentResourcesRows, setResources] = useState<ExternalResourceForSalesType | undefined>();

  const services = new ExternalResourcesServices();

  const query = useQuery(
    GET_QUERY_ER_REPORT,
    async () => {
      const { data } = await services.getSellsSummary(towerId);
      return data;
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  // const fetch = async () => {
  //   try {
  //     const { data } = await services.getSellsSummary(towerId);
  //     console.log({ data });
  //     setResources(data);
  //   } catch (error) {
  //     const message = (error as Error).message;
  //     enqueueSnackbar(message, { variant: 'error' });
  //   }
  // };

  useEffect(() => {
    return () => query.remove();
  }, []);
  return {
    currentResourcesRows: query.data,
  };
}

export default useFetchServiceForSoldReportForExternalResource;
