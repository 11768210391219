import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { setPrevReports, setReports } from './actions';
import Services from '../../SalesReportOne/Services/SalesReportOneServices';

const services = new Services();

export default function useWeeklyReport() {
  const { towerId } = useParams();
  const dispatch = useDispatch();
  const { reports, prevReports } = useSelector((state) => state.reportWeekly);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [limitDates, setLimitDates] = useState({
    minimumDate: moment(),
    maximumDate: moment(),
  });
  const [isLoading, setLoading] = useState(false);
  const [columns] = useState([
    { title: 'Fecha' },
    { title: 'N° de visitas + contactos' },
    { title: 'N° de cotizaciones' },
    { title: 'Opciones' },
    { title: 'Consignados' },
    { title: 'Ventas' },
    { title: 'Desistidos' },
    { title: 'Acumulado Ventas' },
    { title: 'Inventario disponible por semana' },
  ]);

  async function fetch() {
    try {
      setLoading(true);
      const response = await services.getWeeklyReport(towerId);
      dispatch(setReports(response.data));
      dispatch(setPrevReports(response.data));
      const onlyDates = response.data.flatMap((date) =>
        moment(date.title, 'MMMM YYYY'),
      );
      const minimumDate = moment.min(onlyDates);
      const maximumDate = moment.max(onlyDates);
      setLimitDates({ minimumDate, maximumDate });
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(`Ocurrió un error:${error.message}`, {
        variant: 'error',
      });
    }
  }

  const applyFilterByDate = (date) => {
    const formattedDate = moment(date, 'MM DD YYYY').format('MMMM YYYY');
    const datesFound = prevReports.filter(
      (report) => report.title === formattedDate,
    );
    dispatch(setReports(datesFound));
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    applyFilterByDate(e);
  };

  const clearFilter = () => {
    dispatch(setReports(prevReports));
    setSelectedDate(moment());
  };

  useEffect(() => {
    let start = true;
    if (start) {
      fetch();
    }
    return () => {
      start = false;
    };
  }, [towerId]);

  return {
    isLoading,
    reports,
    columns,
    selectedDate,
    handleDateChange,
    clearFilter,
    limitDates
  };
}
