import { API_PATH } from '../../../config/config';

class ArrearsReportWalletServicesDefinitions {
  static arrearsReportWithTowerId = (towerId) =>
    `${API_PATH}wallet-transaction/general/arrears/${towerId}`;

  static downloadArrearsWithTowerId = (towerId) =>
    `${API_PATH}wallet-transaction/general/arrears/export/${towerId}`;
}

export default ArrearsReportWalletServicesDefinitions;
