import React, { FC } from 'react';
import IncomePaymentsMainView from '../App/IncomePayments/UI/IncomePaymentsMainView';

const IncomePaymentsPage: FC = () => {
  return (
    <>
      <IncomePaymentsMainView />
    </>
  );
};

export default IncomePaymentsPage;
