import { useState, useEffect } from 'react';
import Services from '../../Services/ForgotPasswordServices';

const services = new Services();

const validateEmail = (email) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return true;
  }
  return false;
}

const useForgotPasswordBehavior = () => {
  const [email, setEmail] = useState('');
  const invalidEmailMessage = (!email || validateEmail(email)) ? '' : 'Correo inválido';
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dismissError = () => setErrorMessage('');
  const dismissSuccess = () => setSuccess(false);
  const submit = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      await services.forgotPassword(email);
      setSuccess(true);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setLoading(false);
  }
  useEffect(() => {
    const checkEnter = event => {
      if (event.key === 'Enter') {
        submit();
      }
    }
		document.addEventListener('keypress', checkEnter);
		return () => document.removeEventListener('keypress', checkEnter);
	}, [email, loading]);

  return {
    email,
    setEmail,
    invalidEmailMessage,
    errorMessage,
    success,
    loading,
    dismissError,
    dismissSuccess,
    submit
  }
};

export default useForgotPasswordBehavior;