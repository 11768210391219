import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import ManualPrice from './ManualPrice';
import AreasDetails from '../../shared/Areas';
import ClientServices from '../../../services/client/ClientsServices';
import SaleRequestsServices from '../../../services/SaleRequests';

import styles from './styles.module.scss';
import { Numbers } from '../../../helpers';

const services = new ClientServices();
const saleRequestServices = new SaleRequestsServices();

const DesistDialog = ({
  open,
  desistRequestId,
  closeHandler,
  updatePriceProperty,
  propertyId,
  reloadSaleRequestByPropertyId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef(null);

  const [isDisabled, setDisabled] = useState(false);
  const [property, setProperty] = useState(null);
  const [suggestedPrice, setSuggestedPrice] = useState(0);
  const [confirm, setConfirm] = useState({
    isConfirmed: false,
    open: false,
  });
  const [suggestedPriceBeforeAssign, setSuggestedPriceBeforeAssign] =
    useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await services.getPropertyInfo(propertyId);
        setProperty(response.data);
        const res = await saleRequestServices.getPropertySuggestedPrice(
          propertyId,
        );

        setSuggestedPriceBeforeAssign(res.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    if (open) {
      fetchData();
    }
  }, [propertyId]);

  const submit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const confirmUpdatePrice = async (price) => {
    setDisabled(true);
    const result = await updatePriceProperty(propertyId, {
      desistRequestId,
      price,
    });

    setSuggestedPrice(0);
    setDisabled(result);
  };

  const onSubmitHandler = async (values) => {
    const { price } = values;
    if (Math.round(price) < Math.round(suggestedPriceBeforeAssign)) {
      setConfirm({ open: true, isConfirmed: false });
      setSuggestedPrice(price);
      return;
    }
    await confirmUpdatePrice(price);
  };

  const handleReject = async () => {
    const result = await saleRequestServices.rejectDesistRequest(
      desistRequestId,
    );
    reloadSaleRequestByPropertyId(propertyId, result.data);
    setSuggestedPrice(0);
    closeHandler();
  };

  const setPrice = () => {
    setSuggestedPrice(suggestedPriceBeforeAssign);
  };

  const handleCloseFunc = () => {
    closeHandler();
    setSuggestedPrice(0);
  };

  const continueWithPrice = async () => {
    await confirmUpdatePrice(suggestedPrice);
    setConfirm({ isConfirmed: false, open: false });
  };

  const cancelConfrim = () => {
    setConfirm({ isConfirmed: false, open: false });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>Cambiar precio</DialogTitle>
      {!confirm.open && (
        <>
          <DialogContent>
            <DialogContentText>
              Agregue el precio manualmente del apartamento:{' '}
              {property && <span>{property.name}</span>}
            </DialogContentText>

            {property && <AreasDetails property={property} />}

            <Box my={2}>
              <Typography variant="caption">
                * Esta propiedad pertenece al Tipo ${property && property.group}
              </Typography>
            </Box>
            <Box my={2}>
              <div className={styles.wrapper}>
                <div className={styles.col1}>
                  <Typography variant="caption">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={setPrice}
                    >
                      Aplicar precio sugerido
                    </Button>
                  </Typography>
                </div>
                <div className={styles.col2}>
                  <Typography variant="subtitle1">
                    Valor sugerido:
                    {
                      <NumberFormat
                        value={Numbers.toFixed(suggestedPriceBeforeAssign, 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    }
                  </Typography>
                </div>
              </div>
            </Box>
            <ManualPrice
              ref={formRef}
              onSubmit={onSubmitHandler}
              suggestedPrice={suggestedPrice}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleReject}
              color="secondary"
              disabled={isDisabled}
            >
              Rechazar
            </Button>
            <Button onClick={submit} color="primary" disabled={isDisabled}>
              Actualizar
            </Button>
            <Button onClick={handleCloseFunc} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </>
      )}
      {confirm.open && (
        <>
          <DialogContent>
            <Typography variant="h5">
              El precio ingresado es {Numbers.currencyFormat(suggestedPrice)}{' '}
              pero el valor sugerido es mayor (
              {Numbers.currencyFormat(suggestedPriceBeforeAssign)})
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={continueWithPrice} color="primary">
              Seguir igualmente
            </Button>
            <Button onClick={cancelConfrim} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

DesistDialog.propTypes = {
  open: PropTypes.bool,
  desistRequestId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  closeHandler: PropTypes.func.isRequired,
  updatePriceProperty: PropTypes.func.isRequired,
  reloadSaleRequestByPropertyId: PropTypes.func.isRequired,
};

DesistDialog.defaultProps = {
  open: false,
};

export default DesistDialog;
