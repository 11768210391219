import StagesServices from '../../services/StagesServices';
import StagesDTO from '../DTO/StagesDTO';
import { MoveTowerError, MoveTowerErrorHandler, QueueData } from '../types/Stages.types';
import { MoveTowerResponse } from '../types/StagesServices.types';

const services = new StagesServices();

class MoveTowerQueue {
  private isRequesting = false;
  private queue: Array<QueueData> = [];

  private errorHandler: MoveTowerErrorHandler = () => {};

  private readonly addData = (data: QueueData) => this.queue.splice(0, 0, data);

  private readonly hasData = (): boolean => this.queue.length > 0;

  private readonly popQueue = (): QueueData | undefined => this.queue.pop();

  private readonly clear = () => {
    this.queue = [];
  };

  private readonly handleRequestError = (stages: StagesDTO) => (error: unknown) =>
    Promise.reject({
      data: error as Error,
      stages,
    });

  private readonly requestWithErrorHandling = (data: QueueData): Promise<MoveTowerResponse> =>
    services.moveTower(data.towerId, data.cords).catch(this.handleRequestError(data.stages));

  private readonly requestWithValidData = (data: QueueData | undefined): Promise<MoveTowerResponse> =>
    data ? this.requestWithErrorHandling(data) : Promise.resolve({ data: {} });

  private readonly requestMoveTower = async () => {
    this.isRequesting = true;

    while (this.hasData()) {
      const data = this.popQueue();
      await this.requestWithValidData(data);
    }

    this.isRequesting = false;
  };

  private readonly tryRequestMoveTower = async () => {
    try {
      await this.requestMoveTower();
    } catch (error) {
      this.clear();
      this.errorHandler(error as MoveTowerError);
    }
  };

  onError(errorHandler: MoveTowerErrorHandler) {
    this.errorHandler = errorHandler;
  }

  moveTower(data: QueueData): Promise<void> {
    this.addData(data);

    return this.isRequesting ? Promise.resolve() : this.tryRequestMoveTower();
  }
}

export default MoveTowerQueue;
