import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import DeedExpensesDisplacementCell from '../DeedExpensesDisplacementCell/DeedExpensesDisplacementCell';
import { DeedExpensesDisplacementCellProps } from 'App/DeedExpenses/core/interfaces/DeedExpensesDisplacementCellProps';

const PlusInputFormatter =
  (displacementProps: DeedExpensesDisplacementCellProps): FC<DataTypeProvider.ValueFormatterProps> =>
  (props) =>
    <DeedExpensesDisplacementCell {...props} {...displacementProps} />;

const DeadExpensesPlusInputProvider: FC<DataTypeProviderProps & DeedExpensesDisplacementCellProps> = (props) => {
  return <DataTypeProvider {...props} formatterComponent={PlusInputFormatter(props)} />;
};

export default DeadExpensesPlusInputProvider;
