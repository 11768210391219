import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import { Typography, Box, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Receipt from '@material-ui/icons/ReceiptRounded';
import Payment from '@material-ui/icons/PaymentRounded';
import { useParams } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import QuotationViewer from '../QuotationViewer';
import QuotationDialog from '../../../../Quotations/Dialog';
import Styles from './QuotationsList.module.scss';
import QuotationsServices from '../../../../../services/Quotations';
import { setQuotationsListData } from './redux/actions';
import { colorForState } from '../../../../../App/BuyerCostumers/UI/views/customViews/StyledBuyerCustomer';

const SpacingTypography = styled(Typography)`
  padding: 10px 10px;
  font-weight: 400;
`;

const LittleTypography = styled(Typography)`
  line-height: 2;
`;

const ReceiptWithColor = styled(Receipt)`
  fill: ${(props) => colorForState(props.state)};
`;

const services = new QuotationsServices();

const QuotationsList = ({
  property,
  clientId,
  propertyId,
  currentQuotation,
  fetchUserList,
  customOpenState,
  customCloseHandler,
  fullWidth,
  buttonProps,
}) => {
  const dispatch = useDispatch();
  const { towerId } = useParams();
  const quotations = useSelector((state) => state.quotations.root.list).sort(
    (a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    },
  );

  const [open, setOpen] = useState(false);
  const [newQuotationOpen, setOpenNewOne] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const openDialogHandler = () => {
    setOpen(true);
  };

  const closeDialogHandler = () => {
    if (customCloseHandler) {
      customCloseHandler();
      return;
    }
    setOpen(false);
  };

  async function fetch() {
    try {
      const response = await services.getClientQuotations(
        clientId,
        propertyId,
        towerId,
      );
      dispatch(setQuotationsListData(response.data));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (open) {
      fetch();
    }
  }, [open]);

  useEffect(() => {
    if (
      customOpenState !== undefined &&
      customOpenState !== null &&
      customOpenState !== open
    ) {
      setOpen(customOpenState);
    }
  }, [customOpenState]);

  const currentQuotationFromService = () => {
    return quotations.find((quotation) => quotation.id === currentQuotation);
  };

  const spawnMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const hasAssignedQuotation = quotations.find(
    (quotation) => quotation.isAssigned,
  );

  return (
    <>
      {property && (
        <QuotationDialog
          open={newQuotationOpen}
          closeHandler={() => setOpenNewOne(false)}
          quotationData={{
            property,
            propertyId,
            clientId,
            propertyPrice: property.priceSold,
            isFromList: true,
            additionalData: {
              discount: property.discount,
              additionalAreas: property.additionalAreas,
              addedAdditionalAreas: property.addedAdditionalAreas,
            },
          }}
          spawnMessage={spawnMessage}
          towerId={towerId}
          fetchUserList={fetchUserList || fetch}
        />
      )}
      {customOpenState === undefined && (
        <Button
          onClick={openDialogHandler}
          className={Styles.quotations}
          fullWidth={fullWidth}
          {...buttonProps}
        >
          {currentQuotation ? 'Forma de pago' : 'Cotizaciones'}
        </Button>
      )}
      {currentQuotation && currentQuotationFromService() && open && (
        <QuotationViewer
          quotation={currentQuotationFromService()}
          isAssignment={propertyId !== undefined}
          currentQuotation={currentQuotation}
          closeDialog={closeDialogHandler}
          fetchUserList={fetchUserList || fetch}
        />
      )}
      {!currentQuotation && (
        <Dialog
          aria-labelledby="quotations-dialog"
          scroll="paper"
          open={open}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="quotations-dialog">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6">
                Lista de cotizaciones - Formas de pago
              </Typography>
              <Box display="flex" pt={2}>
                <Box display="flex" pr={3}>
                  <ReceiptWithColor />
                  <LittleTypography variant="caption">
                    Cotización inválida
                  </LittleTypography>
                </Box>
                <Box display="flex" pr={3}>
                  <ReceiptWithColor state="missing" />
                  <LittleTypography variant="caption">
                    Cotización válida
                  </LittleTypography>
                </Box>
                <Box display="flex" pr={3}>
                  <ReceiptWithColor state="completed" />
                  <LittleTypography variant="caption">
                    Forma de pago
                  </LittleTypography>
                </Box>
                <Box display="flex">
                  <ReceiptWithColor state="editing" />
                  <LittleTypography variant="caption">
                    Forma de pago editada
                  </LittleTypography>
                </Box>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers classes={{ root: Styles.dialogContentRoot }}>
            {quotations.length > 0 ? (
              <List>
                <Box ml={10} mr={10}>
                  <Grid
                    container
                    item
                    autoFocus
                    button
                    onClick={openDialogHandler}
                    xs={12}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={1}>
                      <ListItemAvatar />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Número
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Inmueble
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Fecha
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Valor Apto + Adic
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Dcto Comercial
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Dcto Financiero
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography classes={{ root: Styles.fontListItemText }}>
                        Valor Cierre
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                {quotations.map((quotation, index) => (
                  <QuotationViewer
                    quotation={quotation}
                    key={`quotation-${index}`}
                    isAssignment={propertyId != undefined}
                    fetchUserList={fetchUserList || fetch}
                    closeDialog={closeDialogHandler}
                  />
                ))}
              </List>
            ) : (
              <SpacingTypography align="center" variant="subtitle1">
                ¡Oye! Aún no tienes cotizaciones, asigna una para este cliente
                😉
              </SpacingTypography>
            )}

            {property && !currentQuotation && !hasAssignedQuotation && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button color="primary" onClick={() => setOpenNewOne(true)}>
                  Crear forma de pago para esta propiedad
                </Button>
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                closeDialogHandler();
                if (customCloseHandler) {
                  customCloseHandler();
                }
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

QuotationsList.propTypes = {
  property: PropTypes.object,
  clientId: PropTypes.string.isRequired,
  propertyId: PropTypes.string,
  currentQuotation: PropTypes.string,
  customOpenState: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fetchUserList: PropTypes.func,
  customCloseHandler: PropTypes.func,
  customOpenDialog: PropTypes.func,
  customCloseDialog: PropTypes.func,
  buttonProps: PropTypes.object,
};

export default QuotationsList;
