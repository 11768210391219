import React from 'react';
import { Typography } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { CustomBoxForState } from '../StyledBuyerCustomer';
import BuyerCustomerScheduleStateDialog from '../../dialogs/BuyerCustomerScheduleStateDialog';
import { Button } from '../../../../../../shared/components/Button/Button';
import useBuyerCustomerPaymentPlanState from '../../../../Core/customHooks/useBuyerCustomerPaymentPlanState';

const BuyerCustomerScheduleState = () => {
  const { isOpen, onClick } = useBuyerCustomerPaymentPlanState();

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        size="small"
        startIcon={<AssignmentOutlinedIcon />}
        onClick={onClick(true)}
      >
        TRAMITES
      </Button>

      <CustomBoxForState
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={0.5}
        mt={1.5}
        currentState="missing"
      >
        <Typography variant="body">Pendiente</Typography>
      </CustomBoxForState>
      <BuyerCustomerScheduleStateDialog
        openDialog={isOpen}
        close={onClick(false)}
      />
    </>
  );
};

export default BuyerCustomerScheduleState;
