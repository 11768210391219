import { show } from '@blueprintjs/core/lib/esm/components/context-menu/contextMenu';
import { ChangeEventHandler, useEffect, useState } from 'react';

import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';
import { SalesStatusProperty } from '../DTO/SalesStatusViewDTO';
import { ChangeSalesStatusHandler } from '../types/SalesStatus.types';

const getTitle = (name: string) => `Inmueble ${name}`;

type HookOptions = {
  cellValue: boolean;
  row: SalesStatusProperty;
  onChange: ChangeSalesStatusHandler;
};

function useHandleSalesStatusSwitch({ cellValue, row, onChange }: HookOptions) {
  const [isSold, setIsSold] = useState(cellValue);
  const [isSoldNew, setIsSoldNew] = useState(cellValue);

  useEffect(() => {
    setIsSold(cellValue);
  }, [cellValue]);

  const changeSalesStatus = (isChecked: boolean) => {
    setIsSold(isChecked);
    onChange({
      isSold: isChecked,
      property: row,
    });
  };

  const acceptChange = () => {
    changeSalesStatus(isSoldNew);
  };

  const { showAlert } = useAlert({
    isOpen: false,
    title: '',
    variant: AlertVariants.Continue,
    acceptAction: acceptChange,
  });

  const confirmAvailableStatus = (isChecked: boolean) => {
    const description = '¿Esta seguro que desea cambiar el estado del inmueble a disponible?';
    const title = getTitle(row.name);

    setIsSoldNew(isChecked);
    showAlert(description, title);
  };

  const handleSwitchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const isChecked = event.currentTarget.checked;

    isChecked ? changeSalesStatus(isChecked) : confirmAvailableStatus(isChecked);
  };

  return { isSold, handleSwitchChange };
}

export default useHandleSalesStatusSwitch;
