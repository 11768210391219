import moment from 'moment';

const useReduceForHighlightedCell = ({ row, name }) => {

  const accumulatedForCell = () => {
    const momentValues = Object.keys(row).map((key) => {
      const keyValidation = moment(key, 'MMM YYYY')
      const isValidMoment = keyValidation.isValid();

      if (isValidMoment) {
        return moment(keyValidation).format('MMM YYYY');
      }

      return []
    })

    const filteredDates = momentValues.filter((date) => {

      if (moment(date, 'MMM YYYY').isBefore(moment(name, 'MMM YYYY'), 'M') || moment(date, 'MMM YYYY').isSame(moment(name, 'MMM YYYY'), 'M')) {

        return date
      }
    })

    const accumulatedValue = filteredDates.reduce((acc, date) => {
      return Number(acc) + Number(row[date])
    }, 0)

    return accumulatedValue
  }

  return { accumulatedForCell }
}

export default useReduceForHighlightedCell