import React, { FC } from 'react';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Typography from 'shared/components/Typography/Typography';
import QuotationConfigFormItem from '../QuotationConfigFormItem';
import useQuotationConfigLimitDateFormInitialFee from 'App/QuotationConfig/core/customHooks/useQuotationConfigLimitDateFormInitialFee';
import QuotationConfigOutlinedInput from '../../components/QuotationConfigOutlinedInput/QuotationConfigOutlinedInput';

interface Props {
  maximumCollectionDate: string;
  firstSale: number;
}

const QuotationConfigLimitDateFormInitialFee: FC<Props> = (props) => {
  const { value, maximumCollectionDateFormated, isAuthorizedForEdit, isAllowed, handleChange, handleBlur } =
    useQuotationConfigLimitDateFormInitialFee(props);
  return (
    <Box display="flex" justifyContent="center" padding="40px">
      <Grid container item xs={10}>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1">Plazo primer comprador</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          {isAuthorizedForEdit ? (
            <NumberFormat
              defaultValue={value}
              value={value}
              onBlur={handleBlur}
              onChange={handleChange}
              customInput={QuotationConfigOutlinedInput}
              decimalScale={0}
              isAllowed={isAllowed}
              // @ts-ignore
              endAdornment={<InputAdornment position="end">meses</InputAdornment>}
              variant="outlined"
            />
          ) : (
            <Typography variant="body1" fontStyle="italic">
              36 meses
            </Typography>
          )}
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1">Plazo hoy</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1" fontStyle="italic">
            {maximumCollectionDateFormated} meses
          </Typography>
        </QuotationConfigFormItem>
      </Grid>
    </Box>
  );
};

export default QuotationConfigLimitDateFormInitialFee;
