import React from 'react';
import { IntegratedFiltering, SortingState, IntegratedSorting, DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFixedColumns,
  Toolbar,
  VirtualTable,
  TableColumnResizing,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';

import useGeneralWalletDetailsClient from 'App/GeneralWalletDetails/core/customHooks/useGeneralWalletDetailsClient';
import useGetCellValue from 'App/FeatureField/core/customHooks/useGetCellValue';
import useCustomPopover from 'shared/customHooks/useCustomPopover';
import TableCell from '../../../../shared/tables/TableCell';
import TableHeaderRowCell from '../../../../shared/tables/TableHeaderRowCell';
import TableContainer from '../../../../shared/tables/TableContainer';
import useFeatureFieldAdminStatus from '../../../FeatureField/core/customHooks/useFeatureFieldAdminStatus';
import useFeatureFieldRepo from '../../../FeatureField/core/customHooks/useFeatureFieldRepo';
import { ValidFeatures } from '../../../FeatureField/core/enums/featureField.enum';
import TableTagProvider from '../../../FeatureField/UI/plugins/TableTagProvider/TableTagProvider';
import FeatureFieldAdmin from '../../../FeatureField/UI/components/FeatureFieldAdmin/FeatureFieldAdmin';
import GeneralWalletDetails from '../../../GeneralWalletDetails/UI/GeneralWalletDetails';
import useDownloadGeneralWallet from '../../core/customHooks/useDownloadGeneralWallet';
import useGeneralWalletTagChange from '../../core/customHooks/useGeneralWalletTagChange';
import useGeneralWalletTableColumns from '../../core/customHooks/useGeneralWalletTableColumns';
import useGeneralWalletTableRows from '../../core/customHooks/useGeneralWalletTableRows';
import { numberColumns, textColumns } from '../../core/enums/table.enum';
import {
  ClientDetail,
  GeneralWalletRow,
  GeneralWalletVirtualTableProps,
} from '../../core/interfaces/generalWallet.types';
import GeneralWalletToolbar from '../plugins/GeneralWalletToolbar';
import GeneralWalletToolbarState from '../plugins/GeneralWalletToolbarState';
import GeneralWalletClientCell from './GeneralWalletClientCell';
import GeneralWalletDetailsProvider from './GeneralWalletDetailsProvider';
import GeneralWalletNumberFormatter from './GeneralWalletNumberFormatter';
import GeneralWalletTextFormatter from './GeneralWalletTextFormatter';
import GeneralWalletToolbarRoot from './GeneralWalletToolbarRoot';
import GeneralWalletPopover from './GeneralWalletPopover';
import useMonthlyInterestReportOpener from '../../../MonthlyInterestReport/core/customHooks/useMonthlyInterestReportOpener';
import MonthlyInterestReportMainView from '../../../MonthlyInterestReport/UI/MonthlyInterestReportMainView';

const GeneralWalletVirtualTable: React.FC<GeneralWalletVirtualTableProps> = ({ tableData, setLoading }) => {
  const { rows, setRows, onTextFilterChange } = useGeneralWalletTableRows(tableData.clients);
  const commitTagChange = useGeneralWalletTagChange({ rows, setRows });
  const { featureFieldsRepo, updateFeatureField, updateFeatureFields } = useFeatureFieldRepo(tableData.featureFields);
  const { columnsData, handleColumnWidthsChange } = useGeneralWalletTableColumns(featureFieldsRepo);
  const downloadExcel = useDownloadGeneralWallet(setLoading);
  const { isAdminOpen, handleOpenAdmin, handleCloseAdmin } = useFeatureFieldAdminStatus();
  const { elementRef, handlePopoverOpen } = useCustomPopover<ClientDetail>();
  const { clientDetails, handleGeneralWalletDetailsClicked, removeClient } = useGeneralWalletDetailsClient();
  const { isOpenMonthlyInterest, currentPropertyId, handleMonthlyInterest, handleActionMonthlyInterestReportClicked } =
    useMonthlyInterestReportOpener();

  const getCellValue = useGetCellValue<GeneralWalletRow>(featureFieldsRepo);

  return (
    <>
      <Grid rows={rows} columns={columnsData.columns} getCellValue={getCellValue}>
        <GeneralWalletToolbarState columnName="state" defaultFilterValue="total" />
        <IntegratedFiltering />
        <SortingState />
        <IntegratedSorting />
        <DataTypeProvider for={textColumns} formatterComponent={GeneralWalletTextFormatter} />
        <DataTypeProvider for={numberColumns} formatterComponent={GeneralWalletNumberFormatter} />
        <GeneralWalletDetailsProvider for={['clientName']} onClick={handlePopoverOpen} />
        <VirtualTable height="75vh" containerComponent={TableContainer} cellComponent={TableCell} />
        <TableColumnResizing columnWidths={columnsData.columnWidths} onColumnWidthsChange={handleColumnWidthsChange} />
        <TableHeaderRow cellComponent={TableHeaderRowCell} showSortingControls />
        <TableColumnVisibility hiddenColumnNames={columnsData.hiddenColumnNames} />
        <Toolbar rootComponent={GeneralWalletToolbarRoot} />
        <GeneralWalletToolbar
          onDownloadClick={downloadExcel}
          onCustomizeClick={handleOpenAdmin}
          onTextFilterChange={onTextFilterChange}
        />
        <TableFixedColumns leftColumns={columnsData.leftColumns} cellComponent={GeneralWalletClientCell} />
        <TableTagProvider
          featureFields={featureFieldsRepo.data}
          for={columnsData.featureFieldColumns}
          onValueChange={commitTagChange}
        />
      </Grid>
      <FeatureFieldAdmin
        isOpen={isAdminOpen}
        feature={ValidFeatures.GeneralWallet}
        featureFieldsRepo={featureFieldsRepo}
        onUpdateField={updateFeatureField}
        onUpdateFields={updateFeatureFields}
        onClickExit={handleCloseAdmin}
      />
      <GeneralWalletPopover
        elementRef={elementRef}
        handleActionDetailsClicked={handleGeneralWalletDetailsClicked}
        handleActionMonthlyInterestReportClicked={handleActionMonthlyInterestReportClicked}
      />
      {clientDetails && <GeneralWalletDetails client={clientDetails} onClose={removeClient} />}
      <MonthlyInterestReportMainView
        isOpen={isOpenMonthlyInterest}
        propertyId={currentPropertyId ?? 0}
        handleClose={handleMonthlyInterest}
        hasToolbar={true}
      />
    </>
  );
};

export default GeneralWalletVirtualTable;
