import { useRef, useEffect } from 'react';

function useAutoFocus<T extends HTMLElement>() {
  const focusableElement = useRef<T>(null);

  useEffect(() => {
    const gainFocus = () => focusableElement.current?.focus();

    gainFocus();
  }, []);

  return { focusableElement };
}

export default useAutoFocus;
