import { Box, Button } from '@material-ui/core';
import React, { FC } from 'react';
import Icon from '../../../../shared/components/Icon/Icon';
import Typography from '../../../../shared/components/Typography/Typography';
import Styles from './IncomePaymentSpecialImportSection.module.scss';
import UploadButton from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/ImportFromExcel/UploadButton';
import useRealPaymentsUploader from '../../Core/customHooks/useRealPaymentsUploader';
import RealPaymentErrorHandlingModal from '../../../RealPayment/UI/Modal/RealPaymentErrorHandlingModal';
import useRealPaymentsErrorHandling from '../../../RealPayment/Core/customHooks/useRealPaymentsErrorHandling';

const IncomePaymentSpecialImportSection: FC = () => {
  const {
    file,
    isUploadingFile,
    lastPaymentsResponse,
    onChangeFileHandler,
    uploadExcel,
    onCancel,
  } = useRealPaymentsUploader();

  const {
    isDialogOpen,
    chargeNumberList,
    closeHandler,
  } = useRealPaymentsErrorHandling(lastPaymentsResponse);
  return (
    <>
      <RealPaymentErrorHandlingModal
        isOpen={isDialogOpen}
        chargeNumbers={chargeNumberList}
        closeHandler={closeHandler}
      />
      <div className={Styles.container}>
        <Icon variant="Warning" color="stateWarning500" />
        <Typography
          variant="body1"
          color="gray800"
          className={Styles.typography}
        >
          Los datos que compartan número de encargo y fechas con los últimos
          archivos cargados serán remplazados.
        </Typography>
        <UploadButton
          buttonTitle="Cargar pagos - Alianza fiduciaria"
          fileName={typeof file === 'string' ? null : file.name}
          onChangeHandler={onChangeFileHandler}
          onCancel={onCancel}
          onAccept={uploadExcel}
          isUploadingFile={isUploadingFile}
          className={Styles.uploadButton}
          key={typeof file === 'string' ? '' : file.name}
        />
      </div>
    </>
  );
};

export default IncomePaymentSpecialImportSection;
