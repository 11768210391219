import React, { useState } from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import GraphicMaterialDialog from '../dialogs/GraphicMaterialDialog';

function GraphicMaterialRow({ property }) {
  const [openGrapicMaterialDialog, setOpenGrapicMaterialDialog] = useState(
    false,
  );

  const handleClickOpen = () => {
    setOpenGrapicMaterialDialog(true);
  };

  const handleClose = () => {
    setOpenGrapicMaterialDialog(false);
  };

  return (
    <TableRow>
      <TableCell align="center">{property.name}</TableCell>
      <TableCell>{property.groupName}</TableCell>
      <TableCell>{`Piso #${property.floor}`}</TableCell>
      <TableCell>
        <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          handleClickOpen();
        }}
        >
          Material Gráfico
        </Button>
      </TableCell>
      {openGrapicMaterialDialog && (
        <GraphicMaterialDialog
          openGrapicMaterial={openGrapicMaterialDialog}
          propertyInfo={property}
          closeFunction={handleClose}
          showAddAndDelete={true}
        />
      )}
    </TableRow>
  );
}

GraphicMaterialRow.propTypes = {
  property: PropTypes.object,
};

export default GraphicMaterialRow;
