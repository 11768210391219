import React from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useGraphicMaterialRowsMapper from '../../../Core/customHooks/useGraphicMaterialRowsMapper';
import GraphicMaterialRow from '../rows/GraphicMaterialRow';

const useStyles = makeStyles({
  Container: {
    maxHeight: '90vh',
  },
});

const GraphicMaterialTable = () => {
  const { rows, columns } = useGraphicMaterialRowsMapper();
  const styles = useStyles();

  return (
    <TableContainer component={Paper} className={styles.Container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((columnHeader, index) => (
              <TableCell
                key={`columnHeader-${index}`}
                align={columnHeader.align}
                width={columnHeader.width}
              >
                {columnHeader.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <GraphicMaterialRow key={row.id} property={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GraphicMaterialTable;
