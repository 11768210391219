import { ChangeEvent, FormEvent, FormEventHandler, MouseEventHandler, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import QuotationsServices from 'services/Quotations/Quotations.services';
import { QuotationPaymentType } from '../DTO/QuotationConfigDto';
import { updateQuotationConfigQuotationSetupQueryData } from '../QuotationConfigQueryClient';

const service = new QuotationsServices();

const useQuotationConfigPaymentTypes = (feeTypes: QuotationPaymentType[], type: 'final' | 'initialFee') => {
  const [value, setValue] = useState<string>();
  const { towerId } = useParams<{ towerId: string }>();

  const { mutate: updateFeeTypes } = useMutation(
    (values: QuotationPaymentType[]) =>
      service
        .putPaymentTypes({
          towerId,
          paymentTypes: values,
        })
        .then(({ data }) => data),
    {
      onSuccess: (data: QuotationPaymentType[]) => {
        updateQuotationConfigQuotationSetupQueryData({ quotationPaymentTypes: data });
        setValue('');
      },
    },
  );

  const { mutate: updateFinalPaymentTypes } = useMutation(
    (values: QuotationPaymentType[]) =>
      service
        .putFinalPaymentTypes({
          towerId,
          paymentTypes: values,
        })
        .then(({ data }) => data),
    {
      onSuccess: (data: QuotationPaymentType[]) => {
        updateQuotationConfigQuotationSetupQueryData({ quotationFinalPaymentTypes: data });
        setValue('');
      },
    },
  );

  const { mutate: removeFeeType } = useMutation(
    (paymentTypeId: number | string) =>
      service
        .deletePaymentType({
          towerId,
          paymentTypeId,
        })
        .then(({ data }) => data),
    {
      onSuccess: (data: QuotationPaymentType[]) => {
        updateQuotationConfigQuotationSetupQueryData({ quotationPaymentTypes: data });
        setValue('');
      },
    },
  );

  const { mutate: removeFinalPaymentType } = useMutation(
    (paymentTypeId: number | string) =>
      service
        .deleteFinalPaymentType({
          towerId,
          paymentTypeId,
        })
        .then(({ data }) => data),
    {
      onSuccess: (data: QuotationPaymentType[]) => {
        updateQuotationConfigQuotationSetupQueryData({ quotationFinalPaymentTypes: data });
        setValue('');
      },
    },
  );

  const { mutate: updateDefaultPaymentType } = useMutation(
    (paymentTypeId: number | string) =>
      service.putQuotationSetup(towerId, 'defaultPaymentType', paymentTypeId).then(({ data }) => data),
    {
      onMutate: (paymentTypeId: number | string) =>
        updateQuotationConfigQuotationSetupQueryData({ defaultPaymentType: paymentTypeId }),
    },
  );

  const { mutate: updateDefaultFinalPaymentType } = useMutation(
    (paymentTypeId: number | string) =>
      service.putQuotationSetup(towerId, 'defaulFinalPaymentType', paymentTypeId).then(({ data }) => data),
    {
      onMutate: (paymentTypeId: number | string) =>
        updateQuotationConfigQuotationSetupQueryData({ defaulFinalPaymentType: paymentTypeId }),
    },
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    if (value && type === 'initialFee') updateFeeTypes(feeTypes.concat({ id: value, text: value }));
    if (value && type === 'final') updateFinalPaymentTypes(feeTypes.concat({ id: value, text: value }));
  };

  const handleSubmitNewFeeType: FormEventHandler = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleClickNewFeeType: MouseEventHandler<HTMLButtonElement> = (e) => handleSubmit();

  const handleChangeDefaultType = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    if (type === 'initialFee') updateDefaultPaymentType(e.target.value as number);
    if (type === 'final') updateDefaultFinalPaymentType(e.target.value as number);
  };

  return {
    value,
    handleChange,
    handleClickNewFeeType,
    handleSubmitNewFeeType,
    removeFeeType,
    handleChangeDefaultType,
    updateFinalPaymentTypes,
    removeFinalPaymentType,
  };
};
export default useQuotationConfigPaymentTypes;
