import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Numbers from '../../../../helpers/numbers';
import ScheduleServices from '../../../../services/schedule/ScheduleServices';

const services = new ScheduleServices();

export default function(startStageDate, endStageDate, towerId) {
  const { enqueueSnackbar } = useSnackbar();
  // @ts-ignore

  const [startStage, setStartStageDate] = useState(
    moment(new Date()).valueOf(),
  );
  const [endStage, setEndStageDate] = useState(moment(new Date()).valueOf());

  const [startDisplacement, setStartDisplacement] = useState(0);
  const [endDisplacement, setEndDisplacement] = useState(0);

  const fetch = async () => {
    try {
      setStartStageDate(Number(startStageDate));
      setEndStageDate(Number(endStageDate));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (
      !startStageDate ||
      startStageDate !== startStage ||
      !endStageDate ||
      endStageDate !== endStage
    ) {
      fetch();
    }
  }, [startStageDate, endStageDate]);

  const setDates = async (startStageDate, endStageDate, setDates) => {
    try {
      await services.putStageDates(towerId, {
        startStageDate,
        endStageDate,
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onSetStartStage = async (date) => {
    try {
      const startStageDate = moment(Number(date))
        .add(startDisplacement, 'months')
        .valueOf();
      setStartStageDate(Number(startStageDate));
      await setDates(Number(startStageDate), endStage);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onSetEndStage = async (date) => {
    try {
      setEndStageDate(Number(date));
      await setDates(startStage, Number(date));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const startStageDisplacement = async (event, date) => {
    const displacement = Numbers.cleanNumber(event.value);
    if (displacement <= 0) {
      try {
        const startStageDate = moment(Number(date))
          .add(displacement, 'months')
          .valueOf();
        setStartDisplacement(displacement);
        setStartStageDate(startStageDate);
        await setDates(Number(startStageDate), endStage);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else {
      enqueueSnackbar('El desplazamiento no puede ser mayor a cero.', {
        variant: 'error',
      });
    }
  };

  const endStageDisplacement = async (event, date) => {
    const displacement = Numbers.cleanNumber(event.value);
    if (displacement >= 0) {
      try {
        const endStageDate = moment(Number(date))
          .add(displacement, 'months')
          .valueOf();
        setEndDisplacement(displacement);
        setEndStageDate(endStageDate);
        await setDates(startStage, Number(endStageDate));
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else {
      enqueueSnackbar('El desplazamiento debe ser mayor a cero.', {
        variant: 'error',
      });
    }
  };

  return {
    startStage,
    endStage,
    startStageDisplacement,
    endStageDisplacement,
    onSetStartStage,
    onSetEndStage,
  };
}
