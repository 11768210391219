import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import Services from '../../services/user/UserServices';
import LoginComponent from '../../components/User/Login';
import agent from '../../config/config';
import { DashboardRoutes, ProjectRoutes } from '../../routes/local/routes';
import styles from './Login.module.scss';
import errorHandling from '../../services/commons/errorHelper';

class Login extends Component {
  constructor(props) {
    super(props);
    this.services = new Services();
  }

  state = {
    email: '',
    password: '',
    currentErrorMessage: '',
    isLoading: false,
    showPassword: false,
  };

  componentDidMount() {
    this.loadCurrentUserInfo();
  }

  onChange = (target) => {
    const { name, value } = target;
    if (name) {
      let newValue = value;
      if (name === 'email') newValue = value.toLowerCase();
      this.setState({
        [name]: newValue,
      });
    }
  };

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  loadCurrentUserInfo = () => {
    if (agent.currentToken) {
      this.setState({
        isLoading: true,
      });

      this.services
        .currentUser()
        .then((response) => {
          if (response.data.user) {
            this.props.history.push(DashboardRoutes.base + ProjectRoutes.base);
          } else {
            agent.logout();
          }
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          agent.logout();
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  loginActionHandler = () => {
    this.setState({ isLoading: true });

    this.services
      .login(this.state.email, this.state.password)
      .then((user) => {
        if (user) {
          this.props.activateAuth(user);
          this.props.pushTo(DashboardRoutes.base + ProjectRoutes.base);
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        const errorHelper = errorHandling(error);
        this.setState({
          currentErrorMessage: errorHelper.message,
          isLoading: false,
        });
      });
  };

  render() {
    return this.state.isLoading ? (
      <div className="Container">
        <Loader type="Puff" color={styles.mainColor} height="100" width="100" />
      </div>
    ) : (
      <div>
        <LoginComponent
          showPassword={this.state.showPassword}
          onChange={this.onChange}
          email={this.state.email}
          password={this.state.password}
          loginAction={this.loginActionHandler}
          currentErrorMessage={this.state.currentErrorMessage}
          toggleShowPassword={this.toggleShowPassword}
        />
      </div>
    );
  }
}

export default Login;
