import React, { Dispatch, FC, SetStateAction } from 'react';
import { Grid, TableHeaderRow, Table, Toolbar } from '@devexpress/dx-react-grid-material-ui';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import TableHeaderRowCell from '../../../../shared/tables/TableHeaderRowCell';
import TableCell from '../../../../shared/tables/TableCell';

import useColumnsAndRowsForContractsEvalproDetailPercentage from '../../core/customHooks/useColumnsAndRowsForContractsEvalproDetailPercentage';
import { TotalPercentage } from '../../core/interfaces/TotalPercentage';
import ContractsEvalproGridDetailToolbar from './ContractsEvalproGridDetailToolbar';
import ContractsEvalproGridTotalToolbarRoot from './ContractsEvalproGridTotalToolbarRoot';
import ContractsEvalproDetailGridContainer from './ContractsEvalproDetailGridContainer';
import ContractsEvalproDetailGridProgressProvider from './ContractsEvalproDetailGridProgressProvider';

interface IContractsEvalproDetailPercentageGrid {
  isLoading: boolean;
  setTotalDistributed: Dispatch<SetStateAction<number>>;
}

const ContractsEvalproDetailPercentageGrid: FC<IContractsEvalproDetailPercentageGrid> = ({
  isLoading,
  setTotalDistributed,
}) => {
  const { columns, rows } = useColumnsAndRowsForContractsEvalproDetailPercentage({ setTotalDistributed });
  const getRowId = (row: TotalPercentage) => row.categoryId;

  return (
    <>
      {isLoading ? (
        <AbsoluteCircularProgress />
      ) : (
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <ContractsEvalproDetailGridProgressProvider for={['totalPercentage']} />
          <Table
            columnExtensions={columns}
            containerComponent={ContractsEvalproDetailGridContainer}
            cellComponent={TableCell}
          />
          <TableHeaderRow cellComponent={TableHeaderRowCell} />
          <Toolbar rootComponent={ContractsEvalproGridTotalToolbarRoot} />
          <ContractsEvalproGridDetailToolbar eventTitle={'Total'} />
        </Grid>
      )}
    </>
  );
};

export default ContractsEvalproDetailPercentageGrid;
