import React, { FC } from 'react';
import { Box, Paper } from '@material-ui/core';
import Typography from '../../../../shared/components/Typography/Typography';
import IncomePaymentsSegmentedControl from './IncomePaymentsSegmentedControl';
import Styles from './IncomePaymentSectionsContainer.module.scss';
import useSegmentedControlComparison from '../../Core/customHooks/useSegmentedControlComparison';
import IncomePaymentOptionEnum from '../../Core/models/IncomePaymentOption.enum';
import IncomePaymentSingleSection from '../sections/IncomePaymentSingleSection';
import IncomePaymentSeveralSection from '../sections/IncomePaymentSeveralSection';
import IncomePaymentSpecialImportSection from '../sections/IncomePaymentSpecialImportSection';

interface IncomePaymentSectionsContainerProps {
  segment: string;
}

const IncomePaymentSectionsContainer: FC<IncomePaymentSectionsContainerProps> = ({
  segment,
}) => {
  const { compare } = useSegmentedControlComparison(segment, null);

  return (
    <>
      {compare(IncomePaymentOptionEnum.Single) && (
        <Paper elevation={3} className={Styles.Container}>
          <IncomePaymentSingleSection />
        </Paper>
      )}
      {compare(IncomePaymentOptionEnum.Several) && (
        <IncomePaymentSeveralSection />
      )}
      {compare(IncomePaymentOptionEnum.Special) && (
        <div className={Styles.specialContainer}>
          <Paper elevation={3} className={Styles.specialPaper}>
            <IncomePaymentSpecialImportSection />
          </Paper>
        </div>
      )}
    </>
  );
};

export default IncomePaymentSectionsContainer;
