import { FeatureFieldDTO, FeatureField, FieldOption } from './interfaces/featureField.types';
import { NormalizedOptions } from './interfaces/FeatureFieldUtils.types';

const defaultNormalizedOptions: NormalizedOptions = {
  options: {},
  optionsOrder: [],
};

class FeatureFieldUtils {
  private static readonly getCellOption = (
    previousValue: NormalizedOptions,
    option: FieldOption,
  ): NormalizedOptions => ({
    options: {
      ...previousValue.options,
      [option.id]: option,
    },
    optionsOrder: previousValue.optionsOrder.concat([option.id]),
  });

  private static readonly getCellOptions = ({ options }: FeatureFieldDTO): NormalizedOptions | undefined =>
    options?.reduce(this.getCellOption, defaultNormalizedOptions);

  static getFeatureField(featureFieldDTO: FeatureFieldDTO): FeatureField {
    return {
      ...featureFieldDTO,
      ...(this.getCellOptions(featureFieldDTO) || defaultNormalizedOptions),
    };
  }
}

export default FeatureFieldUtils;
