import ContractPaymentsServiceDefinition from './ContractPaymentsServicesDefinition';
import Services from '../services';
import { ContractPayment, ForeignData, ContractPaymentScheduleFlow } from '../../models/ContractPayments';

export default class ContractPaymentsService extends Services {
  getSchedulePaymentsByContract(contractId: number) {
    return this.get<ContractPaymentScheduleFlow[]>(
      ContractPaymentsServiceDefinition.getSchedulePaymentsByContract(contractId),
    );
  }
  getResumeContractPaymentsSchedule(contractId: number) {
    return this.get<ContractPaymentScheduleFlow[]>(
      ContractPaymentsServiceDefinition.getResumeContractPaymentsSchedule(contractId),
    );
  }
  getAllContractPayments(contractId: number) {
    return this.get<ContractPayment[]>(ContractPaymentsServiceDefinition.getAllContractPayments(contractId));
  }
  getAllContractPaymentsForeignList() {
    return this.get<ForeignData>(ContractPaymentsServiceDefinition.getAllContractPaymentsForeignList());
  }

  createContractPayment(contractId: number, data) {
    return this.post<ContractPayment>(ContractPaymentsServiceDefinition.createContractPayment(contractId), data);
  }

  editContractPayment(contractPaymentId: number, data) {
    return this.put<ContractPayment>(ContractPaymentsServiceDefinition.editContractPayment(contractPaymentId), data);
  }

  deleteContractPayment(id: number, contractId: number) {
    return this.delete(ContractPaymentsServiceDefinition.deleteContractPayment(id, contractId), {
      id,
    });
  }
}
