import { useEffect, useState } from 'react';
export interface ParentColumnForBand {
  parentColumn?: string;
  columnName: string;
}

export interface ColumnForBand {
  title: string;
  children: {
    columnName: string;
  }[];
}

export type ConvertColumnsToColumnBandsFunction = (name: string) => ParentColumnForBand | undefined;

function useTableHelpers(columns: ParentColumnForBand[]) {
  const [columnsState, setColumns] = useState<ParentColumnForBand[]>(columns);

  const findParentFromColumnName: ConvertColumnsToColumnBandsFunction = (columnName: string) => {
    return columnsState.find((column) => column.columnName === columnName);
  };

  useEffect(() => {
    const columnsEqualityValidation = columnsState === columns;
    if (!columnsEqualityValidation) {
      setColumns(columns);
    }
  }, [columns]);

  const convertColumnsToColumnBands: () => ColumnForBand[] = () => {
    const initialReduce: string[] = [];
    const parents = columnsState
      .reduce((acc, column) => {
        if (column.parentColumn) {
          const found = acc.indexOf(column.parentColumn);
          if (found === -1) return [...acc, column.parentColumn];
        }
        return acc;
      }, initialReduce)
      .map((title) => {
        return {
          title,
          children: columns.flatMap((column) => {
            if (column.parentColumn === title) {
              return { columnName: column.columnName };
            }
            return [];
          }),
        };
      });
    return parents;
  };

  return { convertColumnsToColumnBands, findParentFromColumnName };
}

export default useTableHelpers;
