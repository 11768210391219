import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Input from '../../../../../components/StrategyV2/Shared/Input/index';

const AddGroupModal = ({
  open,
  handleSubmit,
  validationSchema,
  closeHandler,
  projectId = null,
  towers = { data: [{ name: '' }] },
}) => {
  const [tower, setTower] = useState({});

  return (
    <Dialog open={open}>
      <DialogTitle>Crear grupo</DialogTitle>
      <DialogContent>
        <DialogContentText>Seleccione la torre</DialogContentText>

        <FormControl>
          <Select
            name={'name'}
            onChange={(e) => setTower(e.target.value)}
            style={{ minWidth: 120 }}
          >
            {towers.data &&
              towers.data.map((towerP) => (
                <MenuItem value={towerP}>{towerP.name}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!tower.name}
          onClick={() => {
            setTower({});
            handleSubmit(tower);
          }}
        >
          Seleccionar
        </Button>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeHandler}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupModal;
