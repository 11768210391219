import React from 'react';

import Menu from 'shared/components/Menu/Menu';
import MenuItem from 'shared/components/MenuItem/MenuItem';
import Pill from 'shared/components/Pill/Pill';
import { GetListItem, TableTagMenuProps } from '../../../core/interfaces/TableTagMenu.types';
import TableTagEmptyOption from './TableTagEmptyOption';

import styles from './TableTagMenu.module.scss';
import { FeatureFieldOptions } from 'App/FeatureField/core/interfaces/featureField.types';

const emptyOption = {
  id: -1,
};

const getOptionById = (options: FeatureFieldOptions, optionId: number) => options[optionId] || emptyOption;

const isPillOption = (id: number) => id !== -1;

const isSameOption = (optionId: number) => (id: number) => optionId === id;

const isSelectedOption = (selectedOptions: number[], optionId: number) => selectedOptions.some(isSameOption(optionId));

const isEmptySelected = (selectedOptions: number[]) => selectedOptions.length === 0;

const isSelectedItem = (selectedOptions: number[], optionId: number): boolean =>
  isPillOption(optionId) ? isSelectedOption(selectedOptions, optionId) : isEmptySelected(selectedOptions);

const getListItem =
  ({ options, selectedOptions, onSelectOption }: GetListItem) =>
  (optionId) => {
    const option = getOptionById(options, optionId);
    const { id, value = '', color = '' } = option;
    const isSelected = isSelectedItem(selectedOptions, option.id);

    return (
      <MenuItem key={optionId} className={styles.menuItem} selected={isSelected} onClick={() => onSelectOption(option)}>
        {isPillOption(optionId) ? (
          <Pill label={value} color={color} />
        ) : (
          <TableTagEmptyOption isSelected={isSelected} />
        )}
      </MenuItem>
    );
  };

const TableTagMenu: React.FC<TableTagMenuProps> = ({
  anchorEl,
  isOpen,
  options,
  optionsOrder,
  selectedOptions,
  onSelectOption,
  onClose,
}) => (
  <Menu
    className={styles.root}
    open={isOpen}
    anchorEl={anchorEl}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: -4,
      horizontal: 'right',
    }}
    onClose={onClose}
  >
    {[-1, ...optionsOrder].map(getListItem({ options, selectedOptions, onSelectOption }))}
  </Menu>
);

export default TableTagMenu;
