import { API_PATH } from 'config/config';

class StagesServicesDefinitions {
  static rootUrl = `${API_PATH}stages`;

  static getStageMemberUrl = (id: string | number) => `${this.rootUrl}/${id}`;

  static getStageTowersUrl = (id: number | string) => `${this.rootUrl}/towers/${id}`;

  static getMoveTowersUrl = (towerId: string) => `${this.getStageTowersUrl(towerId)}/move`;
}

export default StagesServicesDefinitions;
