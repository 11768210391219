import { createContext, DispatchWithoutAction } from 'react';

interface IClientListContextProps {
  towerId: string;
  dispatch?: (param: any) => void;
}

const context: IClientListContextProps = {
  towerId: '',
};
export default createContext(context);
