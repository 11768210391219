import React, { FC } from 'react';
import DeedExpensesTabNavigator from '../../components/DeedExpensesTabNavigator/DeedExpensesTabNavigator';
import DeedExpensesHeader from '../../sections/DeedExpensesHeader/DeedExpensesHeader';
import useDeedExpensesMainContainer from 'App/DeedExpenses/core/customHooks/useDeedExpensesMainContainer';
import DeedExpensesFutureSales from '../../sections/DeedExpensesFutureSales/DeedExpensesFutureSales';
import DeedExpensesSalesMade from '../../sections/DeedExpensesSalesMade/DeedExpensesSalesMade';

const DeedExpensesMainContainer: FC = () => {
  const { tabs, isFutureSales, isSalesMade, changeTab } = useDeedExpensesMainContainer();
  return (
    <>
      <DeedExpensesHeader>
        <DeedExpensesTabNavigator onClick={changeTab} options={tabs} />
      </DeedExpensesHeader>
      {isSalesMade && <DeedExpensesSalesMade />}
      {isFutureSales && <DeedExpensesFutureSales />}
    </>
  );
};

export default DeedExpensesMainContainer;
