import { useDispatch, useSelector } from 'react-redux';
import { filterUp, filterDown, filterClean } from '../actions';

export default function useFilter() {
  const dispatch = useDispatch();
  const salesRoomFilter = useSelector((state) => state.salesRoomFilter);

  const subscribeFilter = (name, option) => {
    const filterOption = salesRoomFilter[name];
    if (Array.isArray(filterOption) && name !== 'm2') {
      filterOption.push(option);
      dispatch(filterUp({ [name]: filterOption }));
    } else {
      dispatch(filterUp({ [name]: option }));
    }
  };

  const unsubscribeFilter = (name, option, all = false) => {
    if (all) {
      dispatch(filterClean());
    } else {
      const unselectedOption = salesRoomFilter[name].filter(
        (arrOption) => arrOption !== option,
      );
      dispatch(filterDown({ [name]: unselectedOption }));
    }
  };

  return { subscribeFilter, unsubscribeFilter };
}
