import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import ContractPaymentScheduleGridNumberEditFormatter from './ContractPaymentScheduleGridNumberEditFormatter';
import ContractPaymentScheduleGridNumberFormatter from './ContractPaymentScheduleGridNumberFormatter';

const ContractPaymentScheduleGridEditProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractPaymentScheduleGridNumberFormatter}
    editorComponent={ContractPaymentScheduleGridNumberEditFormatter}
    {...props}
  />
);

export default ContractPaymentScheduleGridEditProvider;
