import { ChangeEventHandler, useEffect, useState } from 'react';
import _ from 'lodash';

import { ClientDTO } from '../interfaces/generalWallet.types';
import { GeneralWalletRow } from '../interfaces/generalWallet.types';
import GeneralWalletClientFormatter from '../GeneralWalletClientFormatter';

type ClientsProps = {
  formatedClients: GeneralWalletRow[];
  queryTxt: string;
};

const filterClients = ({ formatedClients, queryTxt }: ClientsProps) => {
  return formatedClients.filter((client: GeneralWalletRow) => {
    return (
      client.clientName?.toString().toLowerCase().includes(queryTxt) ||
      client.propertyName?.toString().toLowerCase().includes(queryTxt)
    );
  });
};
const getRows = (clients: ClientDTO[], queryTxt: string) => {
  let formatedClients = GeneralWalletClientFormatter.format(clients);
  if (queryTxt && formatedClients) {
    formatedClients = filterClients({ formatedClients, queryTxt });
  }
  return _.sortBy(formatedClients, ['floor', 'location'], ['asc', 'asc']);
};

function useGeneralWalletTableRows(clients: Array<ClientDTO>) {
  const [rows, setRows] = useState<GeneralWalletRow[]>([]);
  const [queryTxt, setQueryTxt] = useState('');

  useEffect(() => {
    setRows(getRows(clients, queryTxt));
  }, [clients, queryTxt]);

  const onTextFilterChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    e.preventDefault();
    const query: string = e.target.value.toLowerCase();

    setQueryTxt(query);
  };

  return {
    rows,
    setRows,
    onTextFilterChange,
  };
}

export default useGeneralWalletTableRows;
