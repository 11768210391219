import Status from 'helpers/status';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SalesRoomService from '../../../../services/salesRoom/salesRoomService';
import { updatePropertiesWithPrices } from '../actions';

const usePropertyEditionRequest = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [existingProperties, setExistingProperties] = useState(null);
  const [isSoldChecked, setIsSoldChecked] = useState(false);
  const [soldQuantity, setSoldQuantity] = useState(0);
  const [availableQuantity, setAvailableQuantity] = useState(0);

  const services = new SalesRoomService();

  const updateQuantity = (properties) => {
    const all = properties.flatMap((property) => property);
    const soldProperties = all.filter(
      (property) => Status.Sold === property.status,
    ).length;
    const availableProperties = all.length - soldProperties;
    setSoldQuantity(soldProperties);
    setAvailableQuantity(availableProperties);
  };

  const fetch = async () => {
    setLoading(true);
    try {
      const properties = await services.getProperties(towerId, null);
      setExistingProperties(properties.data.incrementList.properties);
      updateQuantity(properties.data.incrementList.properties);
      dispatch(updatePropertiesWithPrices(properties.data));
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setExistingProperties([]);
    }
  };

  if (!existingProperties && !isLoading) {
    fetch();
  }

  const checkFilter = (isSold) => () => {
    setIsSoldChecked(isSold);
  };

  return {
    isLoading,
    isSoldChecked,
    soldQuantity,
    availableQuantity,
    checkFilter,
  };
};
export default usePropertyEditionRequest;
