import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useTableHelpers from 'shared/tables/Core/TableHelpers';
import {
  BASE,
  CUT,
  BUDGET,
} from '../customHooks/constants/ResultsStateConstants';
import {
  BASE_CONTROL,
  CUT_CONTROL_WITHOUT_CUT_DATE,
  CUT_CONTROL_WITH_CUT_DATE,
  BUDGET_CONTROL_WITH_EVENT,
} from '../constants/ResultsStateInitialColumnsConstants';

const makeDynamicColumns = (currentColumns) => {
  return [...currentColumns];
};

function useColumnsForResultsState({
  contractsFlow,
  selectedTab,
  cutDate,
  cutDateState,
  selectedBudget,
  budgetControl,
  budgetRows,
}) {
  const [initialState, setInitialColumns] = useState([]);

  const eventFind = (budgetRowsState) => {
    let eventObject = budgetControl?.find(
      (budget) => budget.budgetIndex === selectedBudget,
    );

    if (budgetRowsState) {
      eventObject = budgetRowsState?.find(
        (budget) => budget.budgetIndex === selectedBudget,
      );
    }

    return eventObject?.budgetName || 'Evento vacio';
  };

  let initialColumns = [];

  const initialColumnsDefinition = (selectedTabId) => {
    if (selectedTabId === BASE) {
      initialColumns = BASE_CONTROL;
    }

    if (selectedTabId === CUT && cutDate === null) {
      initialColumns = CUT_CONTROL_WITHOUT_CUT_DATE;
    }

    const cutDateValidation = cutDateState || cutDate;

    const cutDateString = `Corte del ${moment(cutDateValidation).format(
      'DD/MM/YYYY',
    )}`;

    if (selectedTabId === CUT && cutDateValidation) {
      initialColumns = CUT_CONTROL_WITH_CUT_DATE(cutDateString);
    }

    if (selectedTabId === BUDGET) {
      const budgetName = eventFind(budgetRows);
      initialColumns = BUDGET_CONTROL_WITH_EVENT(budgetName);
    }

    return initialColumns;
  };

  useEffect(() => {
    const columnsSelected = initialColumnsDefinition(selectedTab.id);
    setInitialColumns(columnsSelected);
  }, [selectedTab, selectedBudget, budgetRows, cutDateState]);

  const columns = makeDynamicColumns(initialState);

  const { convertColumnsToColumnBands } = useTableHelpers(initialState);

  const columnBands = convertColumnsToColumnBands();

  const [tableColumnExtensions] = useState([
    { columnName: 'name', width: 400 },
    { columnName: 'total', width: 200 },
    { columnName: 'sales Percentage', width: 200 },
    { columnName: 'accumulated', width: 200 },
    { columnName: 'projected', width: 200 },
    { columnName: 'variation', width: 200 },
  ]);

  const [leftColumns] = useState([
    'name',
    'total',
    'sales Percentage',
    'accumulated',
    'projected',
  ]);

  return {
    columns,
    tableColumnExtensions,
    leftColumns,
    columnBands,
  };
}

export default useColumnsForResultsState;
