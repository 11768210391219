import React, { FC } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import useHowDidFindAboutProject from 'App/Clients/Core/customHooks/useHowDidFindAboutProject';
import { ClientCompanyProps } from 'App/Clients/Core/interfaces/ClientCompanyProps';
import HubspotChip from './HubspotChip';

const HowDidFindAboutProject: FC<ClientCompanyProps> = (props) => {
  const { data, errors, questionsOne, handleSubmit, handleChangeSelect } =
    useHowDidFindAboutProject(props);
  return (
    <>
      {Array.isArray(questionsOne) && questionsOne.length ? (
        <>
          <form onSubmit={handleSubmit} id={props.formId} />
          <FormControl fullWidth error={!!errors?.howDidFindAboutProjectId}>
            <InputLabel id="howDidFindAbout">
              ¿Cómo se enteró del proyecto?
            </InputLabel>
            <Select
              variant="outlined"
              labelId="howDidFindAbout"
              id="howDidFindAbout"
              value={data?.howDidFindAboutProjectId}
              label="Seleccionar"
              fullWidth
              onChange={handleChangeSelect('howDidFindAboutProjectId')}
            >
              {questionsOne &&
                questionsOne.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <span>{item.value}</span>
                      {item.isHubspot && <HubspotChip />}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors?.howDidFindAboutProjectId}</FormHelperText>
          </FormControl>
        </>
      ) : (
        <Box display="flex" justifyContent="center" width="100%">
          <Typography>
            El administrador debe configurar las opciones para este selector
          </Typography>
        </Box>
      )}
    </>
  );
};

export default HowDidFindAboutProject;
