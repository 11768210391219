import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FC } from 'react';
import styled from 'styled-components';

import { tableContainerMixin } from './TableContainer';

const VirtualTableContainer: FC = styled(VirtualTable.Container)`
  ${tableContainerMixin}
`;

export default VirtualTableContainer;