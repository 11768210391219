import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Search from './Search';
import styles from './styles.module.scss';
import useClientFormUpdaterForClientProperty from './useClientFormUpdaterForClientProperty';

export const ClientForm = ({
  isEditingHandler,
  sendClientProperty,
  currentClient,
  clientEditing,
  currentClientProperty,
  sendEditClientProperty,
  property,
}) => {
  const [selectedClient, setSelectedClient] = useState(
    clientEditing && currentClient ? currentClient.name : '',
  );

  const {
    clientProperty,
    setClientProperty,
    onChangeChecker,
  } = useClientFormUpdaterForClientProperty(
    clientEditing,
    currentClient,
    currentClientProperty,
  );

  const onSelectHandler = (value) => {
    setSelectedClient(value ? value.name : '');
    setClientProperty({ ...clientProperty, clientId: value ? value.id : '' });
  };

  const onTextHandler = (e) => {
    setClientProperty({
      ...clientProperty,
      percentage: Number(e.target.value) ? Number(e.target.value) : 0,
    });
  };
  const saveSelection = () => {
    if (clientEditing) {
      sendEditClientProperty(clientProperty);
      setSelectedClient('');
      isEditingHandler(false);
    } else {
      sendClientProperty(clientProperty);
      setSelectedClient('');
      isEditingHandler(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.searcher}>
        <Search
          onSelectHandler={onSelectHandler}
          selectedClient={selectedClient}
          clientEditing={clientEditing}
          property={property}
        />
      </div>
      <TextField
        label="Porcentaje"
        placeholder={0}
        defaultValue={
          clientEditing && currentClientProperty
            ? currentClientProperty.percentage
            : ''
        }
        variant="outlined"
        fullWidth
        className={styles.percentage}
        onChange={onTextHandler}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={clientProperty.isContact}
            onChange={onChangeChecker}
          />
        }
        label="Es el comprador de contacto"
      />
      <Button
        onClick={saveSelection}
        variant="contained"
        color="primary"
        disabled={clientEditing ? false : !clientProperty.clientId}
        className={styles.buttons}
      >
        <i className="fas fa-check"></i>
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={styles.buttons}
        onClick={() => {
          isEditingHandler(false);
          setSelectedClient('');
        }}
      >
        <i className="fas fa-times"></i>
      </Button>
    </div>
  );
};

ClientForm.propTypes = {
  isEditingHandler: PropTypes.func,
  sendClientProperty: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
