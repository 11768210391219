import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RealPaymentsServices from '../../Services/RealPaymentsServices';
import { updateChargeNumber } from '../actions';

const useChargeNumberUpdate = () => {
  const { clientHub } = useSelector((state) => state.realPayments.root);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [chargeNumber, setCharge] = useState();

  useEffect(() => {
    if (chargeNumber !== clientHub.chargeNumber) {
      setCharge(clientHub.chargeNumber);
    }
  }, [clientHub.chargeNumber]);

  const updateCharge = async (event) => {
    const services = new RealPaymentsServices();
    setCharge(event.target.value);
    try {
      await services.updateChargeNumber({
        propertyId: clientHub.propertyId,
        chargeNumber: event.target.value,
      });
      dispatch(updateChargeNumber(event.target.value));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setCharge(clientHub.chargeNumber);
    }
  };

  return {
    chargeNumber,
    updateCharge,
  };
};

export default useChargeNumberUpdate;
