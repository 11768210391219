import TowerBuildingDefinition from './TowerBuildingDefinition';
import Services from '../services';

export default class TowerServices extends Services {
  getTowers(towerId) {
    return this.get(TowerBuildingDefinition.towerForProject(towerId));
  }

  getConstructionCal(projectId, datesBuildId) {
    return this.get(
      TowerBuildingDefinition.towerForConstruction(projectId, datesBuildId),
    );
  }

  getConstructionCalContract(towerId) {
    return this.get(
      TowerBuildingDefinition.towerForConstructionContract(towerId),
    );
  }

  createBuilder(projectId, towerId, data) {
    return this.post(
      TowerBuildingDefinition.towerForProjectAndTower(projectId, towerId),
      data,
    );
  }

  deleteBuilder(projectId, towerId, data) {
    return this.delete(
      TowerBuildingDefinition.towerForProjectAndTower(projectId, towerId),
      data,
    );
  }
}
