const SalesSpeedType = {
  MANUAL: {
    code: 'MAN',
    value: 'Manual',
  },
  AUTOMATIC: {
    code: 'AUT',
    value: 'Automatico',
  },
};

export default SalesSpeedType;
