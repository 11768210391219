import React, { FC } from 'react';
import { Button, Card } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import styles from './QuotationConfigCollapseFooter.module.scss';

interface Props {
  toggleIsOpenCollapse: () => void;
}

const QuotationConfigCollapseFooter: FC<Props> = ({ toggleIsOpenCollapse }) => {
  return (
    <Card className={styles.Footer}>
      <Button startIcon={<Close />} className={styles.Button} onClick={toggleIsOpenCollapse}>
        <span>Cerrar</span>
      </Button>
    </Card>
  );
};

export default QuotationConfigCollapseFooter;
