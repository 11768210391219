import React from 'react';
import { Numbers } from '../../../../helpers';
import { PER_THOUSAND } from '../../../TotalCashFlow/UI/views/cells/HighlightedCell';

const financingFormatted = (financing, total, hundred) => {
  const interest =
    financing && financing.find((item) => item.id === 'INTEREST');

  const interestFormatted = interest && {
    ...interest,
    parentId: 'financing',
    group: null,
    item: null,
    contracts: [interest],
    subParentId: null,
    total: interest.total * -1,
    projected: interest.projected * -1,
    accumulated: interest.accumulated * -1,
    percentage: Numbers.toFixed(
      Number(Number((interest.total * -1 * hundred) / total)),
      2,
      true,
    ),
  };

  const monetaryCorrection =
    financing && financing.find((item) => item.id === 'MONETARY_CORRECTION');

  const monetaryCorrectionFormatted = monetaryCorrection && {
    ...monetaryCorrection,
    parentId: 'financing',
    group: null,
    item: null,
    contracts: [monetaryCorrection],
    subParentId: null,
    total: monetaryCorrection.total * -1,
    projected: monetaryCorrection.projected * -1,
    accumulated: monetaryCorrection.accumulated * -1,
    percentage: Numbers.toFixed(
      Number(Number((monetaryCorrection.total * -1 * hundred) / total)),
      2,
      true,
    ),
  };

  const financingObject =
    financing && financing.find((item) => item.id === 'financing');

  const financingObjectFormatted = financingObject && {
    ...financingObject,
    name: 'Intereses financieros',
    parentId: null,
    group: null,
    item: null,
    items: [interestFormatted, monetaryCorrectionFormatted],
    total: (interest.total + monetaryCorrection.total) * -1,
    projected: (interest.projected + monetaryCorrection.projected) * -1,
    accumulated: (interest.accumulated + monetaryCorrection.accumulated) * -1,
    percentage: Numbers.toFixed(
      Number(
        Number(
          ((interest.total + monetaryCorrection.total) * -1 * hundred) / total,
        ),
      ),
      2,
      true,
    ),
  };

  let financingRows = [];

  if (financingObjectFormatted) {
    financingRows.push(financingObjectFormatted);
  }

  if (interestFormatted) {
    financingRows.push(interestFormatted);
  }

  if (monetaryCorrectionFormatted) {
    financingRows.push(monetaryCorrectionFormatted);
  }

  return financingRows;
};

function useRowsForResultsState({
  contracts,
  salesRows,
  contractRows,
  financialPerformance,
  financing,
  selectedTab,
  totalsReport,
}) {
  const constructionName = 'Construcción';
  const totalSalesName = 'Total Ventas';
  const totals = totalsReport || {};
  const total = totals && totals.total;
  const hundred = 100;

  const salesRowsFilter = salesRows.filter(
    (contract) =>
      contract.name === 'Total ventas realizadas ' ||
      contract.name === 'Total ventas futuras' ||
      contract.name === totalSalesName,
  );

  const totalPastObject = salesRowsFilter.find(
    (row) => row.id === 'TOTAL_ID_PAST',
  );

  const totalSalesRows = salesRowsFilter.map((salesRow) => {
    let totalData;
    let accumulatedData;
    let projectedData;
    let percentageData;

    let totalPrice = totals && totals.total;
    if (totalsReport.salesProjectionType == 'EVALPRO') {
      const findTotalSales = salesRowsFilter.find(
        (contract) => contract.name === totalSalesName,
      );
      totalPrice = findTotalSales && findTotalSales.total;
    }

    if (salesRow.id === 'TOTAL_SALES') {
      totalData = totalPrice;
      accumulatedData = salesRow.accumulated;
      projectedData = totals && totals?.inventory + totalPastObject?.projected;
      percentageData = Numbers.toFixed(0, 2, true);
      const findTotalSales = salesRowsFilter.find(
        (contract) => contract.name === totalSalesName,
      );

      if (totalsReport.salesProjectionType == 'EVALPRO') {
        projectedData = findTotalSales && findTotalSales.total;
      }
    } else if (salesRow.id === 'TOTAL_ID_FUTURE') {
      totalData = salesRow.total;
      accumulatedData = salesRow.accumulated;
      projectedData = salesRow.projected;
      percentageData = Numbers.toFixed(
        Number((salesRow.total * hundred) / totalPrice),
        2,
        true,
      );
    } else {
      totalData = salesRow.total;
      accumulatedData = salesRow.accumulated;
      projectedData = salesRow.projected;
      percentageData = Numbers.toFixed(
        Number((salesRow.total * hundred) / totalPrice),
        2,
        true,
      );
    }

    return {
      ...salesRow,
      ClientWithoutQuotation: totals && totals.ClientWithoutQuotation,
      group: null,
      item: null,
      total: totalData,
      accumulated: accumulatedData,
      percentage: percentageData,
      projected: projectedData,
    };
  });

  const contractsFilter = contractRows.filter(
    (contract) =>
      contract.name !== 'Total de Contratos' &&
      contract.name !== constructionName && 
      contract.id !== PER_THOUSAND
  );

  const GroupsFormatted = contractsFilter.map((group) => {
    const valueForPercentage = group.total;
    return {
      ...group,
      parentId: null,
      group: null,
      item: null,
      total: Number(group.total),
      accumulated: group.accumulated,
      projected: group.projected,
      percentage: Numbers.toFixed(
        Number((valueForPercentage * hundred) / total),
        2,
        true,
      ),
    };
  });

  const construction = salesRows.find(
    (contract) => contract.name === constructionName,
  );

  const constructionRow = {
    ...construction,
    parentId: null,
    group: null,
    item: null,
    cellType: 'Groups',
    total: Number(construction && construction.total),
    accumulated: construction && construction.accumulated,
    projected: construction && construction.projected,
    percentage: Numbers.toFixed(
      Number((construction && construction.total * hundred) / total),
      2,
      true,
    ),
  };

  const financialPerformanceObject =
    financialPerformance && financialPerformance[0];

  const financialPerformanceFormatted = financialPerformanceObject && {
    ...financialPerformanceObject,
    parentId: null,
    group: null,
    item: null,
    percentage: Numbers.toFixed(
      Number(Number((financialPerformanceObject.total * hundred) / total)),
      2,
      true,
    ),
  };

  const fourPerThousand = salesRows.find(row => row.id === PER_THOUSAND)

  const fourPerThousandRow = {
    ...fourPerThousand,
    parentId: null,
    group: null,
    item: null,
    cellType: 'Groups',
    percentage: Numbers.toFixed(
      Number((fourPerThousand?.total * hundred) / total),
      2,
      true,
    ),
  }

  const itemsFormatted = contractsFilter.flatMap((contract) => {
    const itemsData =
      contract.items &&
      contract.items.map((item) => {
        return {
          ...item,
          group: contract.id,
          parentId: null,
          item: null,
          total: Number(item.total),
          accumulated: item.accumulated,
          projected: item.projected,
          percentage: Numbers.toFixed(
            Number((item.total * hundred) / total),
            2,
            true,
          ),
        };
      });

    return itemsData || [];
  });

  const totalSales = totalSalesRows.find(
    (contract) => contract.name === totalSalesName,
  );

  const financingExpenses = financingFormatted(financing, total, hundred);

  const financingExpensesTotals =
    financingExpenses &&
    financingExpenses.find((row) => row.id === 'financing');

  const totalContractsAndConstructionAccumulated = contractsFilter.reduce(
    (sum, value) =>
      typeof value.accumulated === 'number' ? sum + value.accumulated : sum,
    construction && construction.accumulated,
  );

  const totalContractsAndConstructionProjected = contractsFilter.reduce(
    (sum, value) =>
      typeof value.projected === 'number' ? sum + value.projected : sum,
    construction && construction.projected,
  );

  const totalContractsAndConstruction = contractsFilter.reduce(
    (sum, value) => (typeof value.total === 'number' ? sum + value.total : sum),
    construction && construction.total,
  );

  const valuesToSubstractAccumulated =
    Numbers.cleanNumber(financingExpensesTotals?.accumulated) +
    totalContractsAndConstructionAccumulated;

  const valuesToSubstractProjected =
    Numbers.cleanNumber(financingExpensesTotals?.projected) +
    totalContractsAndConstructionProjected;

  const valuesToSubstractTotal =
    Numbers.cleanNumber(financingExpensesTotals?.total) +
    totalContractsAndConstruction;

  const utilityTotal = Number(
    totalSales &&
      totalSales.total -
        valuesToSubstractTotal +
        (financialPerformanceFormatted?.total || 0),
  );

  const utilityTotalAccumulated = Number(
    totalSales &&
      totalSales.accumulated -
        valuesToSubstractAccumulated +
        (financialPerformanceFormatted?.accumulated || 0),
  );

  const utilityTotalProjected = Number(
    totalSales &&
      totalSales.projected -
        valuesToSubstractProjected +
        (financialPerformanceFormatted?.projected || 0),
  );

  const utilityBeforeTax = {
    id: 'UTILITY-BEFORE-TAX',
    name: 'Utilidad antes de impuestos',
    parentId: null,
    total: utilityTotal,
    projected: utilityTotalProjected,
    accumulated: utilityTotalAccumulated,
    percentage: Numbers.toFixed(
      Number((utilityTotal * hundred) / total),
      2,
      true,
    ),
    group: null,
    item: null,
    dates: [],
  };

  const contractsFormatted = contractsFilter.flatMap((group) => {
    const groupId = group.id;

    const itemsData = group.items.flatMap((item) => {
      const contractsData = item.contracts.map((contract) => {
        return {
          ...contract,
          group: groupId,
          parentId: null,
          item: item.id,
          total: contract.total,
          accumulated: contract.accumulated,
          projected: contract.projected,
          percentage: Numbers.toFixed(
            Number((contract.total * hundred) / total),
            2,
            true,
          ),
        };
      });

      return contractsData;
    });

    return itemsData;
  });

  let rows = [
    ...GroupsFormatted,
    ...itemsFormatted,
    ...contractsFormatted,
  ];

  if (fourPerThousandRow) {
    rows.push(fourPerThousandRow)
  }

  if (constructionRow) {
    rows.unshift(constructionRow);
  }

  if (totalSalesRows) {
    rows.unshift(...totalSalesRows);
  }

  if (financingExpenses) {
    rows.push(...financingExpenses);
  }

  if (financialPerformanceFormatted) {
    rows.push(financialPerformanceFormatted);
  }

  if (utilityBeforeTax) {
    rows.push(utilityBeforeTax);
  }

  return { rows, selection: rows.length - 1 };
}

export default useRowsForResultsState;
