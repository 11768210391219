import { useSnackbar } from 'notistack';

const useReserveForAssignedPayment = (
  onChange,
  distributionHandler,
  payment,
  index,
  onClickDiscount,
  initialDistribution,
  isReal,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const onChangeValue = (event) => {
    if (Number(event.target.value) !== Number(payment)) {
      onChange(event);
      distributionHandler(index, event.target.value, 0);
    }
  };

  const paymentName = index === 0 ? 'Separación' : `Pago No.${index}`;

  const onBlur = () => {
    if (isReal && Number(payment) < initialDistribution[index]) {
      distributionHandler(index, initialDistribution[index], 0);
      enqueueSnackbar(
        'Debes ingresar como minimo el valor ya pagado en este mes',
        { variant: 'error' },
      );
    } else {
      onClickDiscount();
    }
  };

  return {
    onChangeValue,
    onBlur,
    paymentName,
  };
};

export default useReserveForAssignedPayment;
