import Services from '../../../services/services';
import ProjectionTypesDefinitions from './ProjectionTypesDefinitions';

export default class ProjectionTypesServices extends Services {
  getProjections(towerId) {
    const route = ProjectionTypesDefinitions.projectionTypes(towerId);
    return this.get(route);
  }

  setProjections(object, towerId) {
    const route = ProjectionTypesDefinitions.projectionTypes(towerId);
    return this.put(route, object);
  }
}
