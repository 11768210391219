import dotenv from 'dotenv';

if (process.env.NODE_ENV !== 'production') {
  dotenv.config();
}

export default {
  staging: {
    uniqueSideBar: true,
  },
  test: {
    uniqueSideBar: true,
  },
  debug: {
    uniqueSideBar: true,
  },
  debugProd: {
    uniqueSideBar: true,
  },
  development: {
    uniqueSideBar: true,
  },
  production: {
    uniqueSideBar: true,
  },
};
