const SalesRoomEnum = {
  status: {
    AVAILABLE: 'AVAILABLE',
    OPTIONAL: 'OPTIONAL',
    SOLD: 'SOLD',
    QUOTE: 'QUOTE',
  },
};

export default SalesRoomEnum;
