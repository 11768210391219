import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import DialogContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/context';
import IsEditableContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/Payments/contexts';
import QuotationDiscountType from '../enums/QuotationDiscountType';

function useQuotationEditionType() {
  const context = useContext(DialogContext);
  const { enqueueSnackbar } = useSnackbar();
  const { handleEditionTypeChange, quotation: currentQuotation } = context;

  const quotation = currentQuotation || { isLockedType: true };

  const isEditingModeFromContext = useContext(IsEditableContext);
  const isEditingMode =
    isEditingModeFromContext ||
    (currentQuotation.temp && !currentQuotation.isAssigned);

  const toggleEditionType = () => {
    enqueueSnackbar(
      `Reiniciado! ${
        quotation.isLockedType ? 'Cuota manual' : 'Cuota automática'
      }`,
      {
        variant: 'success',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      },
    );
    handleEditionTypeChange(!quotation.isLockedType);
  };

  const onClickDiscount = () => {
    if (currentQuotation.discountType === QuotationDiscountType.ASSIGNED.code)
      context.onClickDiscount();
  };

  return {
    toggleEditionType,
    isEditingMode,
    ...context,
    ...currentQuotation,
    onClickDiscount,
  };
}

export default useQuotationEditionType;
