import { useSelector } from 'react-redux';
import _ from 'lodash';

export function useMapBuyersForRealPaymentByClients() {
  const mapBuyers = (clients) => {
    const propertyRows = (clients || []).reduce(
      (accumulatedProperties, client) => {
        const properties = client.properties.map((property) => {
          return {
            clientId: client.id,
            propertyId: property.id,
            isContact: property.clientProperty.isContact,
            title: `Propiedad ${property.name || ''} - ${client.name || ''}`,
          };
        });

        return [...accumulatedProperties, ...properties];
      },
      [],
    );

    const filterDuplicatedCustomers = _.filter(
      propertyRows,
      (property, i, iteratee) => {
        const findDuplicated =
          !_.find(
            iteratee,
            (currentProperty) =>
              currentProperty.propertyId === property.propertyId &&
              currentProperty.clientId !== property.clientId,
          ) || property.isContact;

        return findDuplicated;
      },
    );
    return filterDuplicatedCustomers;
  };
  return { mapBuyers };
}

function useMapBuyersForRealPayment() {
  const { clients } = useSelector((state) => state.buyerCostumers.root) || {};
  const { mapBuyers } = useMapBuyersForRealPaymentByClients();
  const filterDuplicatedCustomers = mapBuyers(clients);

  return { propertyRows: filterDuplicatedCustomers };
}

export default useMapBuyersForRealPayment;
