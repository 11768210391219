import React, { FC } from 'react';
import Typography from '../../../../shared/components/Typography/Typography';
import useSoldReportForExternalResourceDownload from '../../Core/customHooks/useSoldReportForExternalResourceDownload';
import useSoldReportForExternalResourceUpload from '../../Core/customHooks/useSoldReportForExternalResourceUpload';
import Styles from '../SoldReportForExternalResourceMain.module.scss';
import AreaButton from '../../../Area/UI/actions/AreaButton';
import GetAppOutlined from '@material-ui/icons/GetAppOutlined';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import SoldReportForExternalResourceTable from '../tables/SoldReportForExternalResourceTable';

const SoldReportForERContainer: FC = () => {
  const { download } = useSoldReportForExternalResourceDownload();
  const { inputRef, handleChange, accept, handleClick } = useSoldReportForExternalResourceUpload();

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Typography variant="headline4">Recaudo ventas realizadas</Typography>
        <div className={Styles.buttons}>
          <AreaButton startIcon={<GetAppOutlined />} value="Descargar plantilla" onClick={download} />
          <>
            <input type="file" ref={inputRef} onChange={handleChange} hidden accept={accept} />
            <AreaButton startIcon={<CloudUploadOutlined />} value="Subir plantilla" onClick={handleClick} />
          </>
        </div>
      </div>
      <SoldReportForExternalResourceTable />
    </div>
  );
};

export default SoldReportForERContainer;
