import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const BoldTypography = styled(Typography)`
  font-weight: 700;
`;

const Client = ({ client, projectName, propertyName, date }) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <BoldTypography>Proyecto:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography>{projectName}</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Apartamento:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography>{propertyName}</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Fecha:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography>
              {moment(new Date(date)).format('DD/MM/YYYY')}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Nombre:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography>{`${client.name || ''} ${client.middleName ||
              ''} ${client.surname || ''} ${client.secondSurname ||
              ''}`}</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Documento de identidad:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography>{client.identityDocument}</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <BoldTypography>Correo:</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography>{client.email}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

Client.propTypes = {
  client: PropTypes.object.isRequired,
  projectName: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  date: PropTypes.any,
};

export default Client;
