import React, { FC } from 'react';
import { Grid, TableHeaderRow, TableFixedColumns, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import useRowsForSoldReportForExternalResources from '../../Core/customHooks/useRowsForSoldReportForExternalResources';
import useFetchServiceForSoldReportForExternalResource from '../../Core/customHooks/useFetchServiceForSoldReportForExternalResource';
import VirtualTableDataTypeProvider from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import Typography from '../../../../shared/components/Typography/Typography';
import { Numbers } from '../../../../helpers';

const SoldReportForExternalResourceTable: FC = () => {
  const { currentResourcesRows } = useFetchServiceForSoldReportForExternalResource();

  const { rows, columns, leftColumns, totalPrice } = useRowsForSoldReportForExternalResources(currentResourcesRows);

  return (
    <>
      <Typography variant="headline4">{Numbers.currencyFormat(totalPrice)}</Typography>
      <Grid rows={rows} columns={columns}>
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
        <VirtualTable columnExtensions={columns} height="90vh" />
        <TableHeaderRow />
        <TableFixedColumns leftColumns={leftColumns} />
      </Grid>
    </>
  );
};

export default SoldReportForExternalResourceTable;
