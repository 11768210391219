import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import CustomTypography from '../../../../components/Typography/CustomTypography';
import Styles from './AddClientModalMainView.module.scss';
import NumberFormat from '../../../../components/StrategyV2/Shared/Input/NumberFormat';

const InputWithTitleForCredit = ({
  title,
  id,
  label,
  defaultValue,
  disabled,
  value,
  multiline,
  onChange,
  pb,
  isPercentage,
}) => {
  return (
    <Box display="flex" pb={pb}>
      <CustomTypography
        className={Styles.indicator}
        variant="description"
        color="#000000"
      >
        {title}
      </CustomTypography>
      <NumberFormat
        customInput={TextField}
        className={Styles.input}
        id={id}
        name={id}
        label={label}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
        multiline={multiline}
        onChange={onChange}
        variant="outlined"
        prefix={isPercentage ? '' : '$'}
        suffix={isPercentage ? '%' : ''}
        thousandSeparator={!isPercentage}
        onValueChange={(values) => {
          onChange({
            target: {
              name: id,
              value: values.floatValue,
            },
          });
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

InputWithTitleForCredit.defaultProps = {
  pb: 2,
  isPercentage: false,
};

InputWithTitleForCredit.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  className: PropTypes.string,
  pb: PropTypes.number,
  onChange: PropTypes.func,
  isPercentage: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
};

export default InputWithTitleForCredit;
