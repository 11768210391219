import Services from '../../../services/services';
import ArrearsReportWalletServicesDefinitions from './ArrearsReportWalletServicesDefinitions';

class ArrearsReportWalletServices extends Services {
  getClientsWithGeneralWalletAndArrears(towerId) {
    return this.get(
      ArrearsReportWalletServicesDefinitions.arrearsReportWithTowerId(towerId),
    );
  }

  downloadArrearsWallet(towerId) {
    return this.get(
      ArrearsReportWalletServicesDefinitions.downloadArrearsWithTowerId(
        towerId,
      ),
      {
        responseType: 'blob',
      },
    );
  }
}

export default ArrearsReportWalletServices;
