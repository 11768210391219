import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import QuotationsServices from 'services/Quotations/Quotations.services';
import { QuotationConfigDto } from '../DTO/QuotationConfigDto';
import { updateQuotationConfigQuotationSetupQueryData } from '../QuotationConfigQueryClient';

const service = new QuotationsServices();

type QuotationSetupType = QuotationConfigDto['quotationSetup'];

const useUpdateQuotationSetupQuery = <K extends keyof QuotationSetupType>(key: K) => {
  const { towerId } = useParams<{ towerId: string }>();

  const updateValue = (newValue: QuotationSetupType[K]) =>
    updateQuotationConfigQuotationSetupQueryData({ [key]: newValue });

  const { mutate: updateQuotationSetup } = useMutation(
    (newValue: QuotationSetupType[K]) => service.putQuotationSetup(towerId, key, newValue).then(({ data }) => data),
    {
      onMutate: (newValue: QuotationSetupType[K]) => updateValue(newValue),
    },
  );

  return {
    updateQuotationSetup,
    updateValue,
  };
};

export default useUpdateQuotationSetupQuery;
