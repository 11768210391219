import React from 'react'
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@material-ui/core';
import classes from './ProjectResultsStateHeaderCustomCell.module.scss'

const ProjectResultsStateHeaderCustomCell = (props) => {
  const { ...restProps } = props;
  const { tableColumn } = props;
  const { column } = tableColumn;
  const { title } = column;

  return (
    <VirtualTable.Cell {...restProps} className={classes.header}>
      <Typography variant="body2" className={classes.headerTypography}>
        {title}
      </Typography>
    </VirtualTable.Cell>
  );
}

export default ProjectResultsStateHeaderCustomCell