import React, { FC } from 'react';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import useEvalproDeedExpensesDeedMonths from 'App/DeedExpenses/core/customHooks/useEvalproDeedExpensesDeedMonths';
import Typography from 'shared/components/Typography/Typography';
import PlusInput from 'shared/components/PlusInput/PlusInput';
import TextField from 'shared/components/TextField/TextField';
import GridContent from '../../components/DeedExpensesGridContent/DeedExpensesGridContent';
import EvalproDeedExpensesDeedMonthsTable from '../../sections/EvalproDeedExpensesDeedMonthsTable/EvalproDeedExpensesDeedMonthsTable';

interface Props {
  towerId: string;
  endOfSalesDate: number;
  displacementStartDateDeed: number;
  displacementEndDateDeed: number;
}

const EvalproDeedExpensesDeedMonths: FC<Props> = ({
  towerId,
  endOfSalesDate,
  displacementStartDateDeed,
  displacementEndDateDeed,
}) => {
  const { isLoading, onChange, getDisplacement } = useEvalproDeedExpensesDeedMonths(
    towerId,
    endOfSalesDate,
    displacementStartDateDeed,
    displacementEndDateDeed,
  );
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" marginBottom="50px">
      <Box alignSelf="flex-start">
        <Typography variant="headline6" color="primary600">
          Meses de escrituración
        </Typography>
      </Box>
      <Box maxWidth="560px" alignSelf="center" marginTop="20px" marginBottom="30px">
        <Grid container>
          <GridContent gridProps={{ xs: 8 }}>
            <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="body1" fontWeight="bold">
                Meses de escrituración
              </Typography>
            </Box>
          </GridContent>
          <GridContent gridProps={{ xs: 4 }}>
            <PlusInput value={displacementEndDateDeed} onChange={onChange} isDisabled={isLoading} />
          </GridContent>
          <GridContent gridProps={{ xs: 8 }} paddingTop="16px">
            <Typography variant="body1" fontWeight="bold">
              Fecha final de escrituración
            </Typography>
          </GridContent>
          <GridContent gridProps={{ xs: 4 }} paddingTop="16px">
            <TextField disabled value={moment(Number(endOfSalesDate)).add(getDisplacement(), 'M').format('MMM-YYYY')} />
          </GridContent>
        </Grid>
      </Box>
      {displacementEndDateDeed <= 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center" minHeight="100px">
          <Box marginRight="18px" maxHeight="24px">
            <Typography variant="body1" color="informative500">
              <InfoOutlined />
            </Typography>
          </Box>
          <Typography variant="body1">Define los meses de escrituración para gestionar el inventario</Typography>
        </Box>
      ) : (
        <EvalproDeedExpensesDeedMonthsTable towerId={towerId} />
      )}
    </Box>
  );
};

export default EvalproDeedExpensesDeedMonths;
