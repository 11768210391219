import React from 'react';

import { PhaseDetailDates } from '../../../core/DTO/SalesSpeedHelperDTO';
import HighlightValues from '../../../core/enums/HighlightValues';
import SalesSpeedHelperPhasesDetailDates from '../SalesSpeedHelperPhasesDetailDates/SalesSpeedHelperPhasesDetailDates';

import styles from './SalesSpeedHelperPhasesDetailCard.module.scss';

const isHighlighted = (highlightType: HighlightValues, isFaseCard = false) => (isFaseCard ? highlightType : undefined);

const isDisabledCard = (currentTime: number, startDate: number): boolean => currentTime < startDate;

const isInDateRange = (currentTime: number, startDate: number, endDate: number): boolean =>
  startDate <= currentTime && currentTime <= endDate;

type Props = {
  faseName: string;
  currentTime: number;
  phaseDates: PhaseDetailDates;
  isFaseCard?: boolean;
};

const SalesSpeedHelperPhasesDetailCard: React.FC<Props> = ({ faseName, currentTime, phaseDates, isFaseCard }) => {
  const isDisabled = isDisabledCard(currentTime, phaseDates.total.fromTime);
  const isCurrentFase = isInDateRange(currentTime, phaseDates.total.fromTime, phaseDates.total.toTime);

  return (
    <div className={styles.root}>
      <SalesSpeedHelperPhasesDetailDates
        title={faseName}
        datesRange={phaseDates.total}
        isBold
        isDisabled={isDisabled}
      />
      {isCurrentFase && (
        <>
          <div className={styles.divider}></div>
          <SalesSpeedHelperPhasesDetailDates
            title="Vendido"
            datesRange={phaseDates.sold}
            highlight={isHighlighted(HighlightValues.End, isFaseCard)}
          />
          <div className={styles.divider}></div>
          <SalesSpeedHelperPhasesDetailDates
            title="Por vender"
            datesRange={phaseDates.toSell}
            highlight={isHighlighted(HighlightValues.Start, isFaseCard)}
          />
        </>
      )}
    </div>
  );
};

export default SalesSpeedHelperPhasesDetailCard;
