import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import MuiPaper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Slide from '@material-ui/core/Slide';
import QuotationFunctions from '../../../helpers/quotationFunctions';

const Paper = styled(MuiPaper)``;

const AssignedValue = ({
  distribution,
  reservationDeposit,
  finalFee,
  propertyPrice,
  discountPrice,
  enableDiscount,
}) => {
  const discountTitle = Number(discountPrice) > 0 ? 'Descuento' : 'Incremento';
  return (
    <Slide in direction="right">
      <Paper elevation={24}>
        <Box p={2}>
          <Typography
            variant="h5"
            component="p"
            color={enableDiscount ? 'inherit' : 'secondary'}
          >
            {enableDiscount ? discountTitle : 'Valor faltante'}
          </Typography>
          <Typography
            variant="subtitle1"
            color={enableDiscount ? 'textSecondary' : 'secondary'}
          >
            <NumberFormat
              thousandSeparator
              fixedDecimalScale
              decimalScale={0}
              prefix="$"
              displayType="text"
              value={
                enableDiscount
                  ? Math.abs(Number(discountPrice))
                  : QuotationFunctions.missingPrice(
                      distribution,
                      reservationDeposit,
                      finalFee,
                      propertyPrice,
                    )
              }
            />
          </Typography>
        </Box>
      </Paper>
    </Slide>
  );
};

AssignedValue.propTypes = {
  distribution: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  reservationDeposit: PropTypes.number.isRequired,
  finalFee: PropTypes.number.isRequired,
  propertyPrice: PropTypes.number.isRequired,
  enableDiscount: PropTypes.bool,
  discountPrice: PropTypes.number,
};

export default AssignedValue;
