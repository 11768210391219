import useFeatureFieldMenuOption from 'App/FeatureField/core/customHooks/useFeatureFieldMenuOption';
import React from 'react';

import Icon from 'shared/components/Icon/Icon';
import IconButton from 'shared/components/IconButton/IconButton';
import Menu from 'shared/components/Menu/Menu';
import MenuItem from 'shared/components/MenuItem/MenuItem';
import Switch from 'shared/components/Switch/Switch';
import Typography from 'shared/components/Typography/Typography';
import useTableTagMenu from '../../../core/customHooks/useTableTagMenu';
import useUpdateFeatureFieldVisibility from '../../../core/customHooks/useUpdateFeatureFieldVisibility';
import { FeatureFieldAdminListItemProps } from '../../../core/interfaces/FeatureFieldAdminListItem.types';

import styles from './FeatureFieldAdminListItem.module.scss';

const FeatureFieldAdminListItem: React.FC<FeatureFieldAdminListItemProps> = ({
  field,
  index,
  onStartEditField,
  onDeleteField,
  onUpdateVisibility,
  onDragStart,
  onDragEnter,
  onDragOver,
  onDrop,
  onDragEnd,
}) => {
  const handleOnChange = useUpdateFeatureFieldVisibility({ field, onUpdateVisibility });
  const { anchorEl, openMenu, closeMenu } = useTableTagMenu<HTMLButtonElement>();
  const { handleEditClick, handleDeleteClick } = useFeatureFieldMenuOption({
    field,
    onStartEditField,
    onDeleteField,
    closeMenu,
  });

  return (
    <li
      className={styles.root}
      data-index={index}
      draggable
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
    >
      <div className={styles.actions}>
        <Icon className={styles.dragIcon} variant="Drag" />
        <Typography className={styles.label} variant="body1" color="gray900">
          {field.title}
        </Typography>
        <div>
          <IconButton
            className={styles.more}
            iconName="MoreVert"
            variant="ghost"
            size="small"
            aria-controls="edit-feature-field-menu"
            onClick={openMenu}
          />
          <Menu
            id="edit-feature-field-menu"
            className={styles.menu}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={closeMenu}
          >
            <MenuItem onClick={handleEditClick}>Editar</MenuItem>
            <MenuItem className={styles.deleteItem} onClick={handleDeleteClick}>
              Eliminar
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Switch className={styles.visibility} checked={field.isEnabled} color="primary" onChange={handleOnChange} />
    </li>
  );
};

export default FeatureFieldAdminListItem;
