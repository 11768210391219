import {
  ChangeEventHandler,
  EventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { MaximumRotation } from '../DTO/SalesSpeedHelperDTO';
import { OnMaximumRotationMonthsChange } from '../types/SalesSpeedHelper.types';

type ErrorState = {
  hasError: boolean;
  message: string;
};

const defaultErrorState: ErrorState = {
  hasError: false,
  message: '',
};

const defaultMaximumRotation: MaximumRotation = {
  currentMonths: 0,
  minMonths: 0,
  maxMonths: 0,
};

const getErrorMessage = ({ minMonths, maxMonths } = defaultMaximumRotation): string =>
  `El valor debe ser entre ${minMonths} y ${maxMonths}`;

const hasChanged = (newValue: number, currentMonths?: number): boolean => newValue !== currentMonths;

const isEmpty = (value: string) => value === '';

const isNumberPattern = /^[0-9\b]+$/;

const isNumber = (value: string): boolean => isNumberPattern.test(value);

const isEnterKey = (event: KeyboardEvent<HTMLInputElement>): boolean => event.key === 'Enter';

const isInRange = (maximumRotation = defaultMaximumRotation, maximumRotationMonths = -1): boolean =>
  maximumRotation.minMonths <= maximumRotationMonths && maximumRotationMonths <= maximumRotation.maxMonths;

type HookOptions = {
  maximumRotation?: MaximumRotation;
  onChange: OnMaximumRotationMonthsChange;
};

function useMaximumRotationChangeHandler({ maximumRotation, onChange }: HookOptions) {
  const [maximumRotationMonths, setMaximumRotationMonths] = useState<number | undefined>();
  const [hasChanges, setHasChanges] = useState(false);
  const [errorState, setErrorState] = useState<ErrorState>(defaultErrorState);

  useEffect(() => {
    setMaximumRotationMonths(maximumRotation?.currentMonths);
    setHasChanges(false);
  }, [maximumRotation]);

  const resetError = () => {
    setErrorState(defaultErrorState);
  };

  const setError = () => {
    setErrorState({
      hasError: true,
      message: getErrorMessage(maximumRotation),
    });
  };

  const changeMaximumRotationMonths = (newValue: number) => {
    setMaximumRotationMonths(newValue);
    setHasChanges(hasChanged(newValue, maximumRotation?.currentMonths));
    resetError();
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;

    if (isEmpty(inputValue) || isNumber(inputValue)) {
      changeMaximumRotationMonths(Number(inputValue));
    }
  };

  const updateMaximumRotationMonths = () => {
    maximumRotationMonths && onChange(maximumRotationMonths);
  };

  const tryUpdateMaximumRotationMonths = () => {
    isInRange(maximumRotation, maximumRotationMonths) ? updateMaximumRotationMonths() : setError();
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (isEnterKey(event)) {
      tryUpdateMaximumRotationMonths();
    }
  };

  const handleApplyClick: MouseEventHandler<HTMLButtonElement> = () => {
    tryUpdateMaximumRotationMonths();
  };

  return { maximumRotationMonths, hasChanges, errorState, handleOnChange, handleKeyUp, handleApplyClick };
}

export default useMaximumRotationChangeHandler;
