import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { GeneralWalletThreadDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import Typography from 'shared/components/Typography/Typography';
import AsyncConfirmModal from 'shared/components/AsyncModal/AsyncConfirmModal';
import { useGeneralWalletThreadContext } from 'App/GeneralWalletDetails/core/contexts/GeneralWalletThreadContext';
import GeneralWallletDetailsThreadInteraction from './GeneralWallletDetailsThreadInteraction';
import GeneralWallletDetailsThreadInteractionActions from './GeneralWallletDetailsThreadInteractionActions';
import GeneralWalletDetailsThreadResponse from './GeneralWalletDetailsThreadResponse';

import styles from './GeneralWalletDetailsThread.module.scss';

const GeneralWallletDetailsThreadContainer: FC<GeneralWalletThreadDTO> = (thread) => {
  const { interactions } = thread;
  const { elementRef, isEditing, alertRef, handleClickedEditInteraction, onClickConfirmRemoveInteraction } =
    useGeneralWalletThreadContext();
  return (
    <div className={styles.ThreadContainer}>
      {interactions.map(GeneralWallletDetailsThreadInteraction)}
      {!isEditing && <GeneralWalletDetailsThreadResponse {...thread} />}
      <GeneralWallletDetailsThreadInteractionActions
        elementRef={elementRef}
        onClicketEdit={handleClickedEditInteraction}
        onClicketDelete={onClickConfirmRemoveInteraction}
      />
      <AsyncConfirmModal ref={alertRef} open={true} acceptText="Eliminar">
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box marginTop={2}>
            <Typography variant="headline5">¿Eliminar comentario?</Typography>
          </Box>
          <Box marginY={2}>
            <Box>
              <Typography variant="body1">¿Seguro que deseas eliminar este comentario?</Typography>
            </Box>
          </Box>
        </Box>
      </AsyncConfirmModal>
    </div>
  );
};

export default GeneralWallletDetailsThreadContainer;
