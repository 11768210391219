import React, { createContext, FC } from 'react';

import { Dialog, Container, AppBar, Toolbar, Slide, CircularProgress } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';

import { MuiIconButton } from '../../../shared/components/Button/Button';
import Typography from '../../../shared/components/Typography/Typography';

import Styles from './ContractDetailMainView.module.scss';
import ContractPaymentsMainView from '../../ContractPayments/UI/ContractPaymentsMainView';
import useContractDetail from '../Core/customHooks/useContractDetail';
import ContractDetailDrawer from './views/customViews/ContractDetailDrawer';
import ContractDetailTabPanel from './views/customViews/ContractDetailTabPanel';
import ContractPaymentsScheduleMainView from '../../ContractPaymentsSchedule/UI/ContractPaymentsScheduleMainView';
import ContractEventsMainView from '../../ContractEvents/UI/ContractEventsMainView';
import ContractsLotsInterestRates from '../../ContractsLots/UI/views/ContractsLotsInterestRates';
import ContractLotPaymentsScheduleMainView from '../../ContractLotPaymentsSchedule/UI/ContractPaymentsScheduleMainView';

interface IContractDetailMainView {
  open: boolean;
  handleClose: () => void;
  currentContractId: number;
  handleEditContract: () => void;
  handleDeleteContract: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ContractDetailContextState = ReturnType<typeof useContractDetail>;

export const ContractDetailContext = createContext<ContractDetailContextState | null>(null);

const { Provider } = ContractDetailContext;

const ContractDetailMainView: FC<IContractDetailMainView> = ({
  open,
  handleClose,
  currentContractId,
  handleEditContract,
  handleDeleteContract,
}) => {
  const { contractTitle, selectedIndex, handleListItemChange, currentContract, isLoading, currentContractIsLot } =
    useContractDetail(currentContractId);

  const value = {
    contractTitle,
    selectedIndex,
    handleListItemChange,
    currentContract,
    isLoading,
    currentContractIsLot,
  };

  return (
    <Provider value={value}>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Container maxWidth="xl" className={Styles.root}>
          {isLoading || !currentContract ? (
            <CircularProgress />
          ) : (
            <>
              <AppBar position="fixed" className={Styles.appBar}>
                <Toolbar className={Styles.toolbarGradient}>
                  <MuiIconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </MuiIconButton>
                  <Typography variant="h4" fontSize="1.5rem" fontWeight="600">
                    {contractTitle}
                  </Typography>
                </Toolbar>
              </AppBar>

              <ContractDetailDrawer
                handleEditContract={handleEditContract}
                handleDeleteContract={handleDeleteContract}
              />

              <main className={Styles.content}>
                <Toolbar />
                <ContractDetailTabPanel value={selectedIndex} index={0}>
                  {!!!currentContractIsLot ? (
                    <ContractPaymentsScheduleMainView currentContractId={currentContractId} />
                  ) : (
                    <ContractLotPaymentsScheduleMainView currentContractId={currentContractId} />
                  )}
                </ContractDetailTabPanel>
                <ContractDetailTabPanel value={selectedIndex} index={1}>
                  <ContractPaymentsMainView
                    currentContractId={currentContractId}
                    currentContractIsLot={currentContractIsLot}
                  />
                </ContractDetailTabPanel>
                <ContractDetailTabPanel value={selectedIndex} index={2}>
                  <ContractEventsMainView />
                </ContractDetailTabPanel>
                <ContractDetailTabPanel value={selectedIndex} index={3}>
                  <ContractsLotsInterestRates currentContractId={currentContractId} />
                </ContractDetailTabPanel>
              </main>
            </>
          )}
        </Container>
      </Dialog>
    </Provider>
  );
};

export default ContractDetailMainView;
