import React from "react";
import styles from "./SummaryCell.module.scss";
import NumberFormat from "react-number-format";


const summaryCell = ({ children, k, ...rest }) => (
  <div className={styles.container} {...rest}>
    <div className={styles.content}>
      {children ? (
        <NumberFormat
          isNumberString
          value={Math.ceil(children[k])}
          displayType={"text"}
          thousandSeparator={true}
        />
      ) : (
        "-"
      )}
    </div>
  </div>
);

export default summaryCell;
