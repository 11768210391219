import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import QuotationsServices from 'services/Quotations/Quotations.services';

import { FIND_TOWER_SCHEDULE } from '../reactQuery/RequestConstants';

const quotationsService = new QuotationsServices();

const useDeedExpensesPropertiesSoldConfig = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (displacement: number) => quotationsService.putQuotationSetup(towerId, 'defualtDeedDisplacement', displacement),
    {
      onSuccess() {
        queryClient.invalidateQueries(FIND_TOWER_SCHEDULE);
      },
    },
  );

  return {
    isLoading,
    onChange: mutate,
  };
};

export default useDeedExpensesPropertiesSoldConfig;
