import { DEFAULT_FEATURE_FIELD_WIDTH } from './enums/featureField.enum';
import { FeatureField, FeatureFields, FeatureFieldsRepository } from './interfaces/featureField.types';

class FeatureFieldTable {
  private static getFieldColumn = (featureFields: FeatureFields) => (fieldId: number) => ({
    name: `${fieldId}`,
    title: featureFields[fieldId].title,
  });

  static getColumns(featureFieldsRepo: FeatureFieldsRepository) {
    return featureFieldsRepo.order.map(this.getFieldColumn(featureFieldsRepo.data));
  }

  private static isHiddenField = ({ isEnabled }: FeatureField) => !isEnabled;

  private static getFieldName = ({ id }: FeatureField) => `${id}`;

  static getHiddenColumns(featureFields: FeatureFields): string[] {
    return Object.values(featureFields).filter(this.isHiddenField).map(this.getFieldName);
  }

  private static getColumnWidth = (fieldId: number) => ({
    columnName: `${fieldId}`,
    width: DEFAULT_FEATURE_FIELD_WIDTH,
  });

  static getColumnWidths(fieldsOrder: number[]) {
    return fieldsOrder.map(this.getColumnWidth);
  }
}

export default FeatureFieldTable;
