import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useParams } from 'react-router';
import Services from '../../../services/services'
import { API_PATH } from '../../../config/config';

const IntegrationComponent = () => {
  const { companyId } = useParams();
  const [runSync, setRunSync] = useState(false);

  const sync = async () => {
    setRunSync(true)
    const service = new Services()
    await service.get(`${API_PATH}integrations/sync/pipedrive/${companyId}`)
  };

  return (
    <Box>
      <Box>SYNC {companyId} {runSync ? 'sincronizando' : ''}</Box>
      <Box>
        <Button onClick={sync} variant="contained" color="primary">
          Sincronizar
        </Button>
      </Box>
    </Box>
  );
};

export default IntegrationComponent;
