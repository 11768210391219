import React, { Component } from 'react';
import {
  DashboardRoutes,
  ContractRoutes,
  ContractLotsRoutes,
  UserRoutes,
  Group,
  CRMRoutes,
} from '../../routes/local/routes';

import ProjectRoutes2 from '../../routes/local/projectRoutes';
import DashboardLayout from '../../HOC/Layouts/Dashboard/Dashboard';
import Building from '../Building/Building';
import Projects from '../Project/Projects';
import { CreateUser, UserSettings, AssignTowerToUsers } from '../User';
import Areas from '../Area/Area';
import AreasAdditional from '../AreasAdditional/AreasAdditional';
import Prime from '../Prime/Prime';
import DetailAdmin from '../DetailAdmin/DetailAdmin';
import Detail from '../Detail/Detail';
import RackAreas from '../RackAreas/RackAreas';
import TowerServices from '../../services/Towers/TowerServices';
import Summary from '../Summary/Summary';
import Clustering from '../Clustering/Clustering';
import PrivateRoute from '../../config/PrivateRoute';
import { Role } from '../../helpers';
import SalesRoomClient from '../SalesRoom/SalesRoomClient';
import Client from '../Client';
import Client2 from '../Client/Client23';
import FutureSalesSpeed from '../FutureSalesSpeed/FutureSalesSpeed';
import Schedule from '../Schedule/Schedule';
import Report from '../Report';
import Contracts from '../../App/Contracts/UI/ContractsMainView';
import ContractsLots from '../../App/ContractsLots/UI/ContractsLotsMainView';
import CashFlow from '../CashFlow';
import CashFlowEvalpro from '../../App/CashFlowEvalpro/UI/CashFlowEvalproMainView';
import SaleRequests from '../SaleRequests';
import Pairing from '../Pairing';
import Quotations from '../Quotations';
import Strategy from '../StrategyV2';
import Companies from '../Companies/Companies';
import Groups from '../Groups';
import ClientProperty from '../ClientProperty';
import Budget from '../Budget';
import SaleDateSelector from '../SaleDateSelector';
import TowerBuilder from '../TowerBuilder';
import PaymentQuotationCashflow from '../../App/PaymentQuotationCashflow/UI/PaymentQuotationCashflow';
import SalesEvalpro from '../../App/SalesEvalpro/UI/SalesEvalproMainView';
import TotalCashFlowMainView from '../../App/TotalCashFlow/UI/TotalCashFlowMainView';
import MacroeconomicMainView from '../../App/Macroeconomics/UI/macroeconomicMainView';
import ProjectSchedulesMainView from '../../App/ProjectSchedules/UI/ProjectSchedulesMainView';
import PropertyEditionMainView from '../../App/PropertyEdition/UI/PropertyEditionMainView';
import GraphicMaterialMainView from '../../App/GraphicMaterial/UI/GraphicMaterialMainView';
import DocumentTemplatesMainView from '../../App/DocumentTemplates/UI/DocumentTemplatesMainView';
import BuyerCostumersMainView from '../../App/BuyerCostumers/UI/BuyerCustomersMainView';
import ProjectionTypesMainView from '../../App/ProjectionTypes/UI/ProjectionTypesMainView';
import ContractsEvalproMainView from '../../App/ContractsEvalpro/UI/ContractsEvalproMainView';
import PropertyCharacteristic from '../../App/PropertyCharacteristic/UI/PropertyCharacteristicMainView';
import ResultsState from '../../App/ResultsState/UI/ResultsStateMainView';
import SalesReportOneMainView from '../../App/SalesReportOne/UI/SalesReportOneMainView';
import GeneralWalletMainView from '../../App/GeneralWallet/UI/GeneralWalletMainView';
import SalesWeeklyReport from '../../App/SalesWeeklyReport/UI/SalesWeeklyReport';
import BusinessPartners from '../../App/BusinessPartners/UI/ContractorPage';
import ArrearsReportMainView from '../../App/ArrearsReport/UI/ArrearsReportMainView';
import CompanySchedules from '../../App/CompanySchedules/UI/CompanySchedulesMainView';
import SalesSpeed from '../../App/SalesSpeed/UI/SalesSpeedMainView';
import SalesSpeedEvalpro from '../../App/SalesSpeedEvalpro/UI/SalesSpeedEvalproMainView';
import CRM from '../../App/CRM/UI/CRM';
import IntegrationComponent from '../../components/integrations/UI/IntegrationComponent';
import IncomePaymentsPage from '../../pages/IncomePaymentsPage';
import QuotationConfigMainView from 'App/QuotationConfig/UI/views/QuotationConfigMainView';
import DeedExpensesMainView from 'App/DeedExpenses/UI/views/DeedExpensesMainView';
import EvalproDeedExpensesMainView from 'App/DeedExpenses/UI/views/EvalproDeedExpensesMainView';
import StagesMainView from 'App/Stages/UI/StagesMainView';
import AreaMainView from 'App/Area/UI/views/AreaMainView';
import SalesSpeedHelperMainView from 'App/SalesSpeedHelper/UI/SalesSpeedHelperMainView';
import SalesStatusMainView from 'App/SalesStatus/UI/SalesStatusMainView';
import SoldReportForExternalResourceMain from '../../App/SoldReportForExternalResource/UI/SoldReportForExternalResourceMain';
import ProjectResultsStateMainView from '../../App/ProjectResultsState/UI/ProjectResultsStateMainView';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.services = new TowerServices();
  }

  state = {
    tower: null,
  };

  componentDidMount() {
    const towerId = this.props.location.pathname.split('/')[3];
    if (
      towerId &&
      this.state.tower === null &&
      !this.props.location.pathname.includes('projects')
    ) {
      this.services
        .getTower(towerId)
        .then((response) => {
          this.setState({
            tower: response.data,
          });
          this.props.activateBadgeIncrement(response.data.isBadgeIncrement);
        })
        .catch((error) => {
          console.log('ERROR >', error);
        });
    }
  }

  onChangeTower = (tower) => {
    if (
      tower === this.state.tower ||
      (this.state.tower === null && tower === null)
    ) {
      return;
    }
    this.setState({
      tower,
    });
  };

  render() {
    const { match, location } = this.props;
    const { tower } = this.state;
    return (
      <DashboardLayout tower={tower} location={location} {...this.props}>
        <PrivateRoute
          path={match.url + ProjectRoutes2.base}
          exact
          component={Projects}
          changeTower={this.onChangeTower}
        />
        <PrivateRoute
          path={`${match.url}/pipedrivesync/:companyId`}
          exact
          roles={[Role.Super]}
          component={IntegrationComponent}
        />
        <PrivateRoute
          path={
            match.url +
            ProjectRoutes2.base +
            DashboardRoutes.towers.withIndicator
          }
          exact
          component={StagesMainView}
          changeTower={this.onChangeTower}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.towerBuilder.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={TowerBuilder}
          changeTower={this.onChangeTower}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.building.withIndicator}
          exact
          component={Building}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.areasv2.withIndicator}
          exact
          component={AreaMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.areasAdditional.withIndicator}
          exact
          component={AreasAdditional}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.user}
          exact
          component={UserSettings}
          changeTower={this.onChangeTower}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.companySchedules}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={CompanySchedules}
          changeTower={this.onChangeTower}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.prime.withIndicator}
          exact
          component={Prime}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.summary.withIndicator}
          exact
          component={Summary}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.detailAdmin.withIndicator}
          exact
          component={DetailAdmin}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.detail.withIndicator}
          exact
          component={Detail}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.rackAreas.withIndicator}
          exact
          component={RackAreas}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.clustering.withIndicator}
          exact
          component={Clustering}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.user + UserRoutes.create}
          roles={[Role.Admin, Role.Super]}
          exact
          component={CreateUser}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.user + UserRoutes.assignProjects}
          roles={[Role.Admin, Role.Super]}
          exact
          component={AssignTowerToUsers}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.user + UserRoutes.assignCompanies}
          roles={[Role.Super]}
          exact
          component={Companies}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesSpeedHelper.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={SalesSpeedHelperMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.strategy.withIndicator}
          exact
          component={Strategy}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesRoomClient.withIndicator}
          exact
          component={SalesRoomClient}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.clients.withIndicator}
          exact
          component={Client}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesStatus.withIndicator}
          exact
          component={SalesStatusMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.clients2.withIndicator}
          exact
          component={Client2}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.futureSalesSpeed.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={FutureSalesSpeed}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.schedule.withIndicator}
          roles={[
            Role.Admin,
            Role.Super,
            Role.SalesDirector,
            Role,
            Role.Wallet,
          ]}
          exact
          component={Schedule}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.deed.withIndicator}
          roles={[
            Role.Admin,
            Role.Super,
            Role.SalesDirector,
            Role,
            Role.Wallet,
          ]}
          exact
          component={DeedExpensesMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.deedEvalpro.withIndicator}
          roles={[
            Role.Admin,
            Role.Super,
            Role.SalesDirector,
            Role,
            Role.Wallet,
          ]}
          exact
          component={EvalproDeedExpensesMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.report.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={Report}
        />
        <PrivateRoute
          path={match.url + ContractRoutes.base.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={Contracts}
        />

        <PrivateRoute
          path={match.url + ContractLotsRoutes.base.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={ContractsLots}
        />

        <PrivateRoute
          path={match.url + DashboardRoutes.cashFlow.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={CashFlow}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.cashFlowEvalpro.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={CashFlowEvalpro}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.saleRequests.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={SaleRequests}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.saleRequestsWithId.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={SaleRequests}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.pairing.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Sales]}
          exact
          component={Pairing}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.quotations.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={Quotations}
        />
        <PrivateRoute
          path={match.url + Group.base}
          roles={[Role.Admin, Role.Super]}
          exact
          component={Groups}
        />

        <PrivateRoute
          path={match.url + DashboardRoutes.clientProperty.withIndicator}
          roles={[
            Role.Admin,
            Role.Super,
            Role.SalesDirector,
            Role.Sales,
            Role.Wallet,
          ]}
          exact
          component={ClientProperty}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.budget.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={Budget}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.saleDateSelector.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={SaleDateSelector}
        />

        <PrivateRoute
          path={match.url + DashboardRoutes.paymentCashFlow.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={PaymentQuotationCashflow}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.totalCashFlow.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={TotalCashFlowMainView}
        />
        <PrivateRoute
          path={
            match.url +
            ProjectRoutes2.base +
            ProjectRoutes2.macroeconomicMainView.withIndicator
          }
          roles={[Role.Admin, Role.Super]}
          exact
          component={MacroeconomicMainView}
        />
        <PrivateRoute
          path={
            match.url +
            ProjectRoutes2.base +
            ProjectRoutes2.projectSchedulesMainView.withIndicator
          }
          exact
          component={ProjectSchedulesMainView}
        />
        <PrivateRoute
          path={
            match.url + ProjectRoutes2.base + ProjectRoutes2.sales.withIndicator
          }
          roles={[Role.Admin, Role.Super]}
          exact
          component={SalesEvalpro}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesEvalpro.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={SalesEvalpro}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.propertyEdition.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={PropertyEditionMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.graphicMaterial.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={GraphicMaterialMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.documentTemplates.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={DocumentTemplatesMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.quotationConfig.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={QuotationConfigMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.buyerCostumers.withIndicator}
          roles={[
            Role.Admin,
            Role.Super,
            Role.SalesDirector,
            Role.Sales,
            Role.Wallet,
          ]}
          exact
          component={BuyerCostumersMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.projectionTypes.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={ProjectionTypesMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.projectStages.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={ContractsEvalproMainView}
        />
        <PrivateRoute
          path={
            match.url + DashboardRoutes.propertyCharacteristic.withIndicator
          }
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={PropertyCharacteristic}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.resultsState.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={ResultsState}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesReportOne.withIndicator}
          roles={[Role.Sales, Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={SalesReportOneMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.generalWallet.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={GeneralWalletMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.arrearsSummaryReport.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={ArrearsReportMainView}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.createRealPayment.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={IncomePaymentsPage}
        />

        <PrivateRoute
          path={match.url + DashboardRoutes.weeklySalesDetail.withIndicator}
          roles={[Role.Sales, Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={SalesWeeklyReport}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.businessPartners.value}
          roles={[Role.Admin, Role.Super]}
          exact
          component={BusinessPartners}
        />
        <PrivateRoute
          path={match.url + CRMRoutes.base}
          roles={[Role.Admin, Role.Super]}
          exact
          component={CRM}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesSpeed.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector]}
          exact
          component={SalesSpeed}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.salesSpeedEvalpro.withIndicator}
          roles={[Role.Admin, Role.Super]}
          exact
          component={SalesSpeedEvalpro}
        />
        <PrivateRoute
          path={match.url + DashboardRoutes.soldCollection.withIndicator}
          roles={[Role.Admin, Role.Super, Role.SalesDirector, Role.Wallet]}
          exact
          component={SoldReportForExternalResourceMain}
        />
        <PrivateRoute
          path={
            match.url +
            ProjectRoutes2.base +
            ProjectRoutes2.projectResultsStateMainView.withIndicator
          }
          roles={[Role.Admin, Role.Super]}
          exact
          component={ProjectResultsStateMainView}
        />
      </DashboardLayout>
    );
  }
}

export default Dashboard;
