export enum TemplateCell {
  valueFormatter = 'valueFormatter',
  valueEditor = 'valueEditor',
}

function usePredicateForMonthlyInterestTable() {
  const predicate = (valueType: TemplateCell) => (params: any) => {

    if (params.tableColumn && params.tableColumn.column) {
      if (valueType === TemplateCell.valueFormatter)
        return (
          params.tableRow.type.description === 'data' &&
          params.tableColumn.type.description === 'data' &&
          params.tableColumn.column.name !== 'paymentName' &&
          params.tableColumn.column.name !== 'commitmentDate' &&
          params.tableColumn.column.name !== 'financialAppliedValue'
        );
      if (valueType === TemplateCell.valueEditor)
        return (
          params.tableRow.type.description === 'data' &&
          params.tableColumn.type.description === 'data' &&
          params.tableColumn.column.name === 'financialAppliedValue'
        );
    }
    return false;
  };
  return { predicate };
}

export default usePredicateForMonthlyInterestTable;
