import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';

import Typography from '../../../shared/components/Typography/Typography';
import { Button } from '../../../shared/components/Button/Button';
import useContractPaymentsSchedule from '../Core/customHooks/useContractPaymentsSchedule';
import ContractPaymentScheduleGrid from './Views/customViews/ContractPaymentScheduleGrid';

interface IContractPaymentsScheduleMainView {
  currentContractId: number;
}

const ContractPaymentsScheduleMainView: FC<IContractPaymentsScheduleMainView> = ({ currentContractId }) => {
  const { contractPayments, isLoading, handleRestartProjected, handleAutofitProjected } =
    useContractPaymentsSchedule(currentContractId);

  return (
    <>
      <Box paddingY={3}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" fontSize="1.5rem" fontWeight="600">
              Cronograma de pagos
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              style={{
                margin: '0 10px',
              }}
              onClick={handleAutofitProjected}
            >
              Auto-ajustar proyectado
            </Button>
            <Button onClick={handleRestartProjected} variant="contained">
              Restaurar proyectado
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ContractPaymentScheduleGrid
        rows={contractPayments}
        isLoading={isLoading}
        currentContractId={currentContractId}
      />
    </>
  );
};

export default ContractPaymentsScheduleMainView;
