import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import useResultsStateToFetchRows from '../Core/customHooks/useResultsStateToFetchRows';
import ResultStateHeader from './views/ResultStateHeader';
import ResultsStateTable from './views/table/ResultsStateTable';
import useResultsStateViewController from '../Core/customHooks/useResultsStateViewController';
import AbsoluteCircularProgress from './styles/AbsoluteCircularProgress';
import Style from './styles/ResultStateMainView.module.scss';
import { updateTotalReport, updateContractCashflow, updateSalesCashflow } from '../../TotalCashFlow/Core/actions';

const ResultsStateMainView = ({
  onChangeContracts,
  onChangeSalesCashflow,
  onChangeTotalReport,
}) => {
  const {
    isLoading,
    towerWithoutQuotation,
    downloadTemplate,
    speedSalesIsMissing,
    forceUpdate,
  } = useResultsStateToFetchRows(
    onChangeContracts,
    onChangeSalesCashflow,
    onChangeTotalReport,
  );

  const { tabs, selectedTab, changeTab } = useResultsStateViewController();

  return (
    <Paper>
      <Box className={Style.resultStateContainer}>
        {isLoading && <AbsoluteCircularProgress />}
        <Box p={2} className={Style.resultStateTableContainer}>
          <ResultStateHeader
            forceUpdate={forceUpdate}
            towerWithoutQuotation={towerWithoutQuotation}
            speedSalesIsMissing={speedSalesIsMissing}
            tabs={tabs}
            selectedTab={selectedTab}
            changeTab={changeTab}
            isLoading={isLoading}
          />
          <ResultsStateTable
            towerWithoutQuotation={towerWithoutQuotation}
            selectedTab={selectedTab}
            speedSalesIsMissing={speedSalesIsMissing}
          />
        </Box>
      </Box>
    </Paper>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  onChangeContracts: updateContractCashflow,
  onChangeSalesCashflow: updateSalesCashflow,
  onChangeTotalReport: updateTotalReport,
};

ResultsStateMainView.propTypes = {
  onChangeContracts: PropTypes.func.isRequired,
  onChangeSalesCashflow: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResultsStateMainView);
