import { Button, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import useDeleteFeatureField from '../../../core/customHooks/useDeleteFeatureField';
import useFeatureFieldDragAndDrop from '../../../core/customHooks/useFeatureFieldDragAndDrop';
import useFeatureFieldEditorStatus from '../../../core/customHooks/useFeatureFieldEditorStatus';
import useHandleFieldSaved from '../../../core/customHooks/useHandleFieldSaved';
import usePatchFeatureField from '../../../core/customHooks/usePatchFeatureField';
import useSortFeatureFields from '../../../core/customHooks/useSortFeatureFields';
import { FeatureFieldAdminProps, GetFeatureFieldItems } from '../../../core/interfaces/FeatureFieldAdmin.types';
import FeatureFieldEditor from '../FeatureFieldEditor/FeatureFieldEditor';
import FeatureFieldAdminListItem from './FeatureFieldAdminListItem';

import itemStyles from './FeatureFieldAdminListItem.module.scss';
import styles from './FeatureFieldAdmin.module.scss';

const isAdminOpen = (isOpen: boolean) => ({
  [styles.isOpen]: isOpen,
});

const getFeatureFieldItem =
  ({
    featureFields,
    handleStartEditField,
    handleDeleteField,
    updateFeatureField,
    handleDragStart,
    handleDragEnter,
    handleDragOver,
    handleDrop,
    handleDragEnd,
  }: GetFeatureFieldItems) =>
  (id: number, index: number) =>
    featureFields[id] && (
      <FeatureFieldAdminListItem
        key={id}
        field={featureFields[id]}
        index={index}
        onStartEditField={handleStartEditField}
        onDeleteField={handleDeleteField}
        onUpdateVisibility={updateFeatureField}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
      />
    );

const FeatureFieldAdmin: React.FC<FeatureFieldAdminProps> = ({
  isOpen,
  feature,
  featureFieldsRepo,
  onUpdateField,
  onUpdateFields,
  onClickExit,
}) => {
  const {
    isEditorOpen,
    isEditing,
    editableField,
    handleAddNewField,
    handleStartEditField,
    handleCloseEditor,
    handleChangeField,
  } = useFeatureFieldEditorStatus(featureFieldsRepo);
  const handleFieldSaved = useHandleFieldSaved({
    onCloseEditor: handleCloseEditor,
    onUpdateField,
  });
  const updateFeatureField = usePatchFeatureField(onUpdateField);
  const sortFeatureFields = useSortFeatureFields({ featureFieldsRepo, onUpdateFields });
  const { draggableItems, handleDragStart, handleDragEnter, handleDragOver, handleDrop, handleDragEnd } =
    useFeatureFieldDragAndDrop<number>({
      itemsOrder: featureFieldsRepo.order,
      grabbingClassName: itemStyles.isGrabbing,
      onSortedItems: sortFeatureFields,
    });
  const handleDeleteField = useDeleteFeatureField(onUpdateField);
  const featureFieldItemProps = {
    featureFields: featureFieldsRepo.data,
    handleStartEditField,
    handleDeleteField,
    updateFeatureField,
    handleDragStart,
    handleDragEnter,
    handleDragOver,
    handleDrop,
    handleDragEnd,
  };

  return (
    <>
      <div className={clsx(styles.root, isAdminOpen(isOpen))}>
        <Typography as="h1" variant="headline6">
          Personalizar
        </Typography>
        <IconButton className={clsx(styles.button, styles.buttonExit)} arial-label="exit" onClick={onClickExit}>
          <Icon variant="Exit" width="24px" height="24px" />
        </IconButton>
        <Typography as="h2" variant="subtitle1">
          Columnas
        </Typography>
        <Button className={clsx(styles.button, styles.buttonAdd)} onClick={handleAddNewField}>
          <Icon className={styles.iconAdd} variant="Add" width="24px" height="24px" />
          <span>Agregar Nueva Columna</span>
        </Button>
        <ul className={styles.fieldsList}>{draggableItems.map(getFeatureFieldItem(featureFieldItemProps))}</ul>
      </div>
      <FeatureFieldEditor
        isOpen={isEditorOpen}
        isEditing={isEditing}
        feature={feature}
        editableField={editableField}
        onClose={handleCloseEditor}
        onChangeField={handleChangeField}
        onFieldSaved={handleFieldSaved}
      />
    </>
  );
};

export default FeatureFieldAdmin;
