import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Services from '../../../services/saleDateSelector/saleDateSelectorServices';
import styles from '../styles.module.scss';

const services = new Services();

const Row = ({ historical, towerId, salesStartDate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [history, setHistory] = useState(null);

  const historicalLastDate = (historicalDates) => {
    const indexPosition = historicalDates.salesHistories.length - 1;
    return moment(
      historicalDates.salesHistories[indexPosition].createdAt,
    ).valueOf();
  };
  const [currentDate, setCurrentDate] = useState(
    historicalLastDate(historical),
  );

  const colorStatus = (status) => {
    switch (status) {
      case 'OPTIONAL':
        return styles.YellowHelper;
      case 'SOLD':
        return styles.BlueHelper;
      default:
        return styles.YellowHelper;
    }
  };

  const updateHistorical = (historicalBase) => (date) => {
    const indexPosition = historicalBase.salesHistories.length - 1;
    const prevHistorical = historicalBase.salesHistories[indexPosition];
    const newHistorical = {
      ...prevHistorical,
      createdAt: moment(date).toISOString(),
    };
    setCurrentDate(newHistorical.createdAt);
    setHistory(newHistorical);
  };

  const updateHistorySale = async () => {
    try {
      const response = await services.updateSaleHistoryOf(towerId, history);
      enqueueSnackbar('Actualizado con éxito', { variant: 'success' });
      setHistory(null);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <TableRow>
      <TableCell>
        <div className={styles.container}>
          <div className={colorStatus(historical.status)} />
        </div>
      </TableCell>
      <TableCell>{historical.name}</TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            autoOk
            error={false}
            helperText=""
            className={styles.picker}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={currentDate}
            onChange={updateHistorical(historical)}
            minDate={moment(Number(salesStartDate))
              .startOf('M')
              .toDate()}
            maxDate={moment().toDate()}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          disabled={!history}
          onClick={updateHistorySale}
        >
          <i className="fas fa-check"></i>
        </Button>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  historical: PropTypes.object,
  towerId: PropTypes.string,
  salesStartDate: PropTypes.string,
};

const mapStateToProps = (state) => ({
  towerId: state.saleDateSelector.root.towerId,
  salesStartDate: state.saleDateSelector.root.salesStartDate,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Row);
