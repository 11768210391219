import React from 'react';
import {
  Grid,
  TableHeaderRow,
  TableFixedColumns,
  VirtualTable,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import useSalesSpeedTable from '../../../Core/customHooks/useSalesSpeedTable';
import { Numbers } from '../../../../../helpers';

const SalesSpeedTable = ({ isEvalpro }) => {
  const {
    columns,
    rows,
    tableColumnExtensions,
    leftColumns,
    defaultExpandedRowIds,
    getChildrenRows,
  } = useSalesSpeedTable(isEvalpro);

  const Cell = (props) => {
    const { tableColumn, tableRow } = props;
    const { row } = tableRow;
    const { name } = tableColumn.column;

    const value = row[name];

    return (
      <VirtualTable.Cell {...props}>
        {(Number.isInteger(value) ? value : Numbers.toFixed(value)) || '0'}
      </VirtualTable.Cell>
    );
  };

  return (
    <Grid rows={rows} columns={columns}>
      <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
      <CustomTreeData getChildRows={getChildrenRows} />
      <VirtualTable
        columnExtensions={tableColumnExtensions}
        height="80vh"
        cellComponent={Cell}
      />
      <TableHeaderRow />
      <TableTreeColumn for="type" />
      <TableFixedColumns leftColumns={leftColumns} />
    </Grid>
  );
};

export default SalesSpeedTable;
