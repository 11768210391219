import { combineReducers } from 'redux';
import { GET_CASH_FLOW_EVALPRO } from './actions';

export const initialState = {
  groups: [{}],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CASH_FLOW_EVALPRO:
      return {
        ...state,
        groups: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  root: reducer,
});
