import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { ButtonProps } from '../Button/Button';
import styles from './ButtonGroup.module.scss';

const isCurrentTab = (buttonIndex: string, currentIndex: string): boolean => buttonIndex === currentIndex;

enum TabVariant {
  Contained = 'contained',
  Outlined = 'outlined',
}

const getButtonVariant = (buttonIndex: string, currentIndex: string): TabVariant =>
  isCurrentTab(buttonIndex, currentIndex) ? TabVariant.Contained : TabVariant.Outlined;

type ButtonChild = ReactElement<ButtonProps>;

type ButtonSizes = 'small' | 'medium' | 'large';

const addButtonChildProperties = (currentIndex?: number | string, size?: ButtonSizes) => (child: ButtonChild) => {
  const buttonIndex = child.props['data-index'];
  const formatedCurrentIndex = `${currentIndex}`;

  return React.cloneElement(child, {
    className: clsx(styles.button, child.props.className),
    variant: getButtonVariant(buttonIndex, formatedCurrentIndex),
    size,
  });
};

type Props = {
  className?: string;
  size?: ButtonSizes;
  currentIndex?: number | string;
  children: ReactElement<ButtonProps> | ReactElement<ButtonProps>[];
};

const ButtonGroup: React.FC<Props> = ({ className, currentIndex, children, size = 'small' }) => (
  <div className={clsx(styles.root, className)}>
    {React.Children.map(children, addButtonChildProperties(currentIndex, size))}
  </div>
);

export default ButtonGroup;
