import { useState } from 'react';
import { sumBy } from 'lodash';
import { Column, Sorting, SummaryItem, Table, IntegratedSorting, IntegratedSummary } from '@devexpress/dx-react-grid';

import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';

import { ContractLot, State } from '../../../../models/Contracts';

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

type HookType = () => {
  columns: ColumnType[];
  totalSummaryItems: SummaryItem[];
  tableDefaultSort: Sorting[];
  integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[];
  summaryCalculator: (
    type: string,
    rows: ContractLot[],
    getValue: (row: ContractLot) => ContractLot,
  ) => number | string | undefined;
};

const getColumns = (): ColumnType[] => {
  return [
    {
      title: ' ',
      name: 'options',
      columnName: 'options',
      width: 40,
    },
    {
      title: 'Lote',
      name: 'title',
      columnName: 'title',
      type: ProviderColumnType.tooltip,
    },
    {
      title: 'Descripción',
      name: 'description',
      columnName: 'description',
      type: ProviderColumnType.tooltip,
    },
    {
      title: 'Proveedor',
      name: 'contractorName',
      columnName: 'contractorName',
      type: ProviderColumnType.tooltip,
    },
    {
      width: 170,
      title: 'Facturado',
      name: 'totalInvoiced',
      columnName: 'totalInvoiced',
      type: ProviderColumnType.currency,
    },
    {
      width: 130,
      title: '% Facturado',
      name: 'percentInvoiced',
      columnName: 'percentInvoiced',
      type: [ProviderColumnType.percent, ProviderColumnType.semaphore],
    },
    {
      width: 170,
      title: 'Presupuesto',
      name: 'total',
      columnName: 'total',
      type: ProviderColumnType.currency,
    },
    {
      width: 170,
      title: 'Proyectado',
      name: 'totalProjected',
      columnName: 'totalProjected',
      type: ProviderColumnType.currency,
    },
    {
      width: 130,
      title: 'Ejecucion',
      name: 'percentExecuted',
      columnName: 'percentExecuted',
      type: [ProviderColumnType.percent, ProviderColumnType.semaphore],
    },
    {
      width: 190,
      title: 'Estado',
      name: 'state',
      columnName: 'state',
      align: 'center',
    },
  ];
};

const useColumnsAndRowsForContractsLots: HookType = () => {
  const tableSummaryItems: SummaryItem[] = [
    { columnName: 'title', type: 'title' },
    { columnName: 'total', type: 'sum' },
    { columnName: 'totalProjected', type: 'sum' },
    { columnName: 'percentExecuted', type: 'percentExecuted' },
    { columnName: 'percentInvoiced', type: 'percentInvoiced' },
    { columnName: 'totalInvoiced', type: 'sum' },
  ];

  const tableDefaultSort: Sorting[] = [{ columnName: 'title', direction: 'asc' }];

  const [columns] = useState(getColumns());
  const [totalSummaryItems] = useState(tableSummaryItems);

  const [integratedSortingColumnExtensions] = useState([
    {
      columnName: 'state',
      compare: (a: State, b: State) => {
        if (a === null) {
          return 1;
        }

        if (b === null) {
          return -1;
        }

        if (a === b) {
          return 0;
        }

        return a < b ? 1 : -1;
      },
    },
  ]);

  const summaryCalculator = (type: string, rows: ContractLot[], getValue: (row: ContractLot) => ContractLot) => {
    const total = sumBy(rows, (r: ContractLot) => r.total);
    const totalInvoiced = sumBy(rows, (r: ContractLot) => r.totalInvoiced);
    const totalProjected = sumBy(rows, (r: ContractLot) => r.totalProjected);
    switch (type) {
      case 'percentExecuted':
        return total > 0 ? (totalProjected / total) * 100 : 0;
      case 'percentInvoiced':
        return totalProjected > 0 ? (totalInvoiced / totalProjected) * 100 : 0;
      case 'title':
        if (!rows.length) {
          return null;
        }
        return 'Total';
      default:
        return IntegratedSummary.defaultCalculator('sum', rows, getValue);
    }
  };

  return {
    columns,
    totalSummaryItems,
    tableDefaultSort,
    integratedSortingColumnExtensions,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractsLots;
