import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextareaAutosize, Typography } from '@material-ui/core';
import Card, { CardBody, CardHeader } from '../../UI/Card/Card';
import TitleWithAlignedFieldSetupView from '../../../App/GeneralSetup/Core/UI/views/customViews/TitleWithAlignedFieldSetupView';
import useBankTermLimitForQuotationSetup from '../../../App/GeneralSetup/Core/customHooks/useBankTermLimitForQuotationSetup';
import debouncedInput from '../../UI/TextFields/debouncedInput';
import useEditionQuotationNotesForSetup from '../../../App/GeneralSetup/Core/customHooks/useEditionQuotationNotesForSetup';

const DebouncedTextArea = debouncedInput(TextareaAutosize, { timeout: 800 });

function GeneralQuotationSetup({ quotationSetup, isAuthorizedForEdit }) {
  const { onChangeMaxDueDaysForProperty, maxDueDaysForProperty } =
    useBankTermLimitForQuotationSetup(
      quotationSetup.termLimit,
      quotationSetup.annualRate,
      { initialMaxDueDaysForProperty: quotationSetup.maxDueDaysForProperty },
    );

  return (
    <Card>
      <CardHeader>
        <span>{isAuthorizedForEdit && 'Configuración de '} Cotizaciones</span>
      </CardHeader>
      <CardBody>
        <Box display="flex" flexDirection="column" pb={5}>
          <TitleWithAlignedFieldSetupView
            title="Máximo de días por opción de apartamento"
            inputValue={maxDueDaysForProperty}
            isEnabled={isAuthorizedForEdit}
            onChange={onChangeMaxDueDaysForProperty}
          />
        </Box>
      </CardBody>
    </Card>
  );
}

GeneralQuotationSetup.defaultProps = {
  quotationSetup: { deedExpense: {} },
};

GeneralQuotationSetup.propTypes = {
  quotationSetup: PropTypes.object,
};

export default GeneralQuotationSetup;
