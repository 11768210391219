import React, { FC, MouseEvent } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import MoreVert from '@material-ui/icons/MoreVert';
import styles from '../../styles/ContractsListDetailsProvider.module.scss';
import { MuiIconButton } from 'shared/components/Button/Button';

type onClick = (e: MouseEvent, payload) => void;

const ContractsListClientFormatterComponent: FC<DataTypeProvider.ValueFormatterProps & { onClick: onClick }> = ({
  value,
  row,
  onClick,
}) => {
  return (
    <div className={styles.fakeComponent} onClick={(e) => e.stopPropagation()}>
      <MuiIconButton id={`details-button-${row.itemId}-${row.id}`} onClick={(e) => onClick(e, row)} size="small">
        <MoreVert />
      </MuiIconButton>
      {value}
    </div>
  );
};

const ContractsListClientFormatter =
  (onClick: onClick): FC<DataTypeProvider.ValueFormatterProps> =>
  (props) =>
    <ContractsListClientFormatterComponent {...props} onClick={onClick} />;

interface ContractsListDetailsProviderProps extends DataTypeProviderProps {
  onClick: onClick;
}

const ContractsListDetailsProvider: FC<ContractsListDetailsProviderProps> = (props) => {
  return <DataTypeProvider {...props} formatterComponent={ContractsListClientFormatter(props.onClick)} />;
};

export default ContractsListDetailsProvider;
