import { UpdateFeatureFieldTypes } from '../enums/featureField.enum';
import { FeatureField } from '../interfaces/featureField.types';
import { HandleFieldSavedHook } from '../interfaces/useHandleFieldSaved.types';

function useHandleFieldSaved({ onUpdateField, onCloseEditor }: HandleFieldSavedHook) {
  const handleFieldSaved = (savedField: FeatureField) => {
    onUpdateField({
      type: UpdateFeatureFieldTypes.Update,
      field: savedField,
    });
    onCloseEditor();
  };

  return handleFieldSaved;
}

export default useHandleFieldSaved;
