import React, { FC } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import styles from './GeneralWalletToolbarFilter.module.scss';
import PlusInput from 'shared/components/PlusInput/PlusInput';
import useMonthsRecovery from '../../core/customHooks/useMonthsRecovery';

const GeneralWalletToolbarMonthsRecovery: FC = () => {
  const { monthsRecovery, handleChangeMonthsRecovery } = useMonthsRecovery();

  return (
    <Grid container spacing={1} direction="row" alignItems="center">
      <Grid item>
        <Typography variant="body1" color="gray700">
          Recuperación cartera en mora
        </Typography>
      </Grid>
      <Grid item>
        <FormControl fullWidth className={styles.filterControl}>
          <PlusInput
            fullWidth
            value={monthsRecovery ?? 0}
            onChange={handleChangeMonthsRecovery}
            numberFormatProps={{
              decimalScale: 0,
              name: 'displacement',
              suffix: ' meses',
              readOnly: true,
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default GeneralWalletToolbarMonthsRecovery;
