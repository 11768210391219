import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import { WalletPaymentDistribution } from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';
import useMonthlyCommitmentPaymentsMapper from '../../core/customHooks/useMonthlyCommitmentPaymentsMapper';

interface MonthlyInterestSummaryPaymentTableProps {
  commitmentPayments: WalletPaymentDistribution[];
}

const MonthlyInterestSummaryPaymentTable: FC<MonthlyInterestSummaryPaymentTableProps> = ({ commitmentPayments }) => {
  const { rows } = useMonthlyCommitmentPaymentsMapper(commitmentPayments);
  return (
    <Grid container spacing={3} xs={4} sm={8} md={12}>
      <Grid item container spacing={2} xs={4} sm={8} md={12}>
        <Grid item xs={2} sm={4} md={3} key={`title-number`}>
          <Typography variant="subtitle1" color="gray800">
            Pago N°
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={4} key={`title-date`}>
          <Typography variant="subtitle1" color="gray800">
            Fecha
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} md={5} key={`title-value`}>
          <Typography variant="subtitle1" color="gray800">
            Valor
          </Typography>
        </Grid>
      </Grid>
      {rows.map((row, index) => {
        return (
          <Grid item container spacing={1} xs={4} sm={8} md={12}>
            <Grid item xs={2} sm={4} md={3} key={`${index}-number}`}>
              <Typography variant="body1" color="gray700">
                {row.number}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={`${index}-date`}>
              <Typography variant="body1" color="gray700">
                {row.date}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={5} key={`${index}-value`}>
              <Typography variant="body1" color="gray700">
                {row.value}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MonthlyInterestSummaryPaymentTable;
