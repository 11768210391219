import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import ContractsEvalproGridPercentEditFormatter from './ContractsEvalproGridPercentEditFormatter';
import ContractsEvalproGridPercentFormatter from './ContractsEvalproGridPercentFormatter';

const ContractsEvalproGridPercentEditProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractsEvalproGridPercentFormatter}
    editorComponent={ContractsEvalproGridPercentEditFormatter}
    {...props}
  />
);

export default ContractsEvalproGridPercentEditProvider;
