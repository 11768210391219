import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import styles from './DeedExpensesHeader.module.scss';

const DeedExpensesHeader: FC = ({ children }) => {
  return (
    <div className={styles.Content}>
      <Box marginBottom="16px">
        <Typography variant="headline2">Gastos de Escrituración</Typography>
      </Box>
      {children && <Box>{children}</Box>}
    </div>
  );
};

export default DeedExpensesHeader;
