import React, { FC } from 'react';
import { Box, TextField } from '@material-ui/core';
import useUpdateQuotationSetupQuery from 'App/QuotationConfig/core/customHooks/useUpdateQuotationSetup';
import Typography from 'shared/components/Typography/Typography';

interface Props {
  deedNote: string;
}

const DeedExpensesNotes: FC<Props> = (props) => {
  const { updateQuotationSetup } = useUpdateQuotationSetupQuery('deedNote');
  return (
    <>
      <Box width="100%">
        <Box paddingBottom="8px" paddingTop="48px">
          <Typography variant="body1">Nota de Gastos de Escrituración</Typography>
        </Box>
        <TextField
          multiline
          variant="outlined"
          fullWidth
          rows={4}
          defaultValue={props.deedNote}
          onBlur={(e) => updateQuotationSetup(e.target.value)}
        />
      </Box>
    </>
  );
};

export default DeedExpensesNotes;
