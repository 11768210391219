const ProjectRoutes = {
  base: '/projects',
  construction: {
    value: '/construction/',
    withIndicator: '/construction/:projectId',
  },
  sales: {
    value: '/sales/',
    withIndicator: '/sales/:projectId',
  },
  macroeconomicMainView: {
    value: '/macroeconomic/',
    withIndicator: '/macroeconomic/:projectId',
  },
  projectSchedulesMainView: {
    value: '/projectSchedules/',
    withIndicator: '/projectSchedules/:projectId',
  },
  towers: {
    value: '/towers/',
    withIndicator: '/towers/:projectId',
  },
  projectResultsStateMainView: {
    value: '/projectResultsState/',
    withIndicator: '/projectResultsState/:projectId',
  },
};

export default ProjectRoutes;
