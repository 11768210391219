import { Column } from '@devexpress/dx-react-grid';
import useTableHelpers, { ParentColumnForBand } from '../../../../shared/tables/Core/TableHelpers';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { MonthlyPaymentForReport, WalletPaymentDistribution } from '../DTO/WalletMonthlyReportDTO';
import useMonthlyInterestRowsMapper from './useMonthlyInterestRowsMapper';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { GeneralWalletDetailsService } from '../../../GeneralWalletDetails/services/GeneralWalletDetailsService';

export const MONTHLY_PAYMENT_STATE = 'Estado Cuenta';
export const MONTHLY_PAYMENT_FINANCIAL = 'Cálculo intereses';

export const columnsForMonthlyInterestTable = [
  {
    columnName: 'paymentName',
    name: 'paymentName',
    title: ' ',
    // parentColumn: ' ',
  },
  {
    columnName: 'commitmentDate',
    name: 'commitmentDate',
    type: ProviderColumnType.dateFormat,
    // parentColumn: ' ',
    title: ' ',
    width: 160,
  },
  {
    columnName: 'commitment',
    name: 'commitment',
    type: ProviderColumnType.currency,
    parentColumn: MONTHLY_PAYMENT_STATE,
    title: 'Compromisos',
    width: 150,
  },
  {
    columnName: 'assignedPayment',
    name: 'assignedPayment',
    type: ProviderColumnType.currency,
    parentColumn: MONTHLY_PAYMENT_STATE,
    title: 'Pago asignado',
    width: 150,
  },
  {
    columnName: 'aggregatedCommitment',
    name: 'aggregatedCommitment',
    type: ProviderColumnType.currency,
    parentColumn: MONTHLY_PAYMENT_STATE,
    title: 'Abono cuenta',
    width: 150,
  },
  {
    columnName: 'financialApplied',
    name: 'financialApplied',
    type: [ProviderColumnType.greenPositive, ProviderColumnType.currency],
    parentColumn: MONTHLY_PAYMENT_STATE,
    title: 'Intereses',
    width: 150,
  },
  {
    columnName: 'balancePayment',
    name: 'balancePayment',
    type: ProviderColumnType.currency,
    parentColumn: MONTHLY_PAYMENT_STATE,
    title: 'Saldo cuota',
    width: 150,
  },
  {
    columnName: 'placeholderColumn',
    name: 'placeholderColumn',
    type: ProviderColumnType.plain,
    title: ' ',
    width: 20,
  },
  {
    columnName: 'balanceFinancial',
    name: 'balanceFinancial',
    type: ProviderColumnType.currency,
    parentColumn: MONTHLY_PAYMENT_FINANCIAL,
    title: 'Saldo calc int.',
    width: 150,
  },
  {
    columnName: 'days',
    name: 'days',
    type: ProviderColumnType.plain,
    parentColumn: MONTHLY_PAYMENT_FINANCIAL,
    title: 'Días',
    width: 70,
  },
  {
    columnName: 'financialFeeForDate',
    name: 'financialFeeForDate',
    type: ProviderColumnType.percent,
    parentColumn: MONTHLY_PAYMENT_FINANCIAL,
    title: 'Interés e.a. (fecha)',
    width: 150,
  },
  {
    columnName: 'financialPeriod',
    name: 'financialPeriod',
    type: ProviderColumnType.percent,
    parentColumn: MONTHLY_PAYMENT_FINANCIAL,
    title: 'Interés periodo',
    width: 150,
  },
  {
    columnName: 'financial',
    name: 'financial',
    type: [ProviderColumnType.greenPositive, ProviderColumnType.currency],
    parentColumn: MONTHLY_PAYMENT_FINANCIAL,
    title: 'Intereses generados',
    width: 180,
  },
  {
    columnName: 'financialAppliedValue',
    name: 'financialAppliedValue',
    type: ProviderColumnType.greenPositive,
    parentColumn: MONTHLY_PAYMENT_FINANCIAL,
    title: 'Intereses aplicados',
    width: 180,
  },
];

function useMonthlyInterestTableRows(
  monthlyReports: MonthlyPaymentForReport[],
  propertyPlan: WalletPaymentDistribution[],
  propertyId: number,
  isAllInPropertyChecked: boolean,
  reload: () => Promise<void>,
) {
  const { convertColumnsToColumnBands, findParentFromColumnName } = useTableHelpers(columnsForMonthlyInterestTable);
  const { rows, onValueChanged } = useMonthlyInterestRowsMapper(monthlyReports, propertyPlan, propertyId, reload);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(isAllInPropertyChecked);
  const { enqueueSnackbar } = useSnackbar();

  const columnBands = convertColumnsToColumnBands();

  const leftColumns = ['paymentName', 'commitmentDate'];

  const updateCheckAll = async (isChecked: boolean) => {
    setIsAllChecked(isChecked);
    try {
      const services = new GeneralWalletDetailsService();
      await services.checkAllReport(isChecked, propertyId);
      reload();
    } catch ({ message }) {
      setIsAllChecked(!isChecked);
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isAllChecked !== isAllInPropertyChecked) {
      updateCheckAll(isAllInPropertyChecked);
    }
  }, [isAllInPropertyChecked]);

  return {
    rows,
    columns: columnsForMonthlyInterestTable,
    columnBands,
    leftColumns,
    isAllChecked,
    updateCheckAll,
    findParentFromColumnName,
    onValueChanged,
  };
}

export default useMonthlyInterestTableRows;
