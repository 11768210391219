import React, { FC } from 'react';
import { QuotationConfigDto } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import useConfigureDocumentTemplateModal from 'App/QuotationConfig/core/customHooks/useDisclaimerQuoteModal';
import Typography from 'shared/components/Typography/Typography';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import QuotationConfigActionButton from '../../components/QuotationConfigActionButton/QuotationConfigActionButton';
import QuotationConfigAlert from '../../components/QuotationConfigAlert/QuotationConfigAlert';

import styles from './ConfigureDocumentTemplateModal.module.scss';
import { DeleteOutline } from '@material-ui/icons';
import useDragAndDrop from 'shared/customHooks/useDragAndDrop';

const ConfigureDocumentTemplateModal: FC<QuotationConfigDto> = (props) => {
  const { companyIconPath, projectIconPath } = props.quotationSetup;
  const { isOpen, isLoading, removeCompanyIconPath, removeProjectIconPath, open, close, onDropFile, handleChangeFile } =
    useConfigureDocumentTemplateModal(props);
  const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } = useDragAndDrop<string>(onDropFile);
  return (
    <>
      <QuotationConfigActionButton isTransparent onClick={open}>
        Configurar
      </QuotationConfigActionButton>
      <Dialog maxWidth="md" open={isOpen} onClose={close}>
        <DialogTitle>Configurar plantilla de documentos</DialogTitle>
        <DialogContent>
          <Box>
            <QuotationConfigAlert state="info">
              Agrega las imágenes de los logos aquí para incluirlo en tus propuestas y cotizaciones. <br />
              Formatos compatibles: PNG, JPG y PDF
            </QuotationConfigAlert>
          </Box>
          <Box padding="40px" className={styles.Container}>
            {isLoading && (
              <div className={styles.Loading}>
                <CircularProgress />
              </div>
            )}
            <Box>
              <Typography variant="subtitle1" color="gray900">
                Logo de la compañia
              </Typography>
              {companyIconPath ? (
                <Box className={styles.LogoContainer}>
                  <img src={companyIconPath} className={styles.Logo} />
                  <IconButton onClick={() => removeCompanyIconPath('')}>
                    <DeleteOutline color="error" />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  className={styles.UpLogo}
                  draggable
                  onDrop={handleDrop('companyIconPath')}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <Box>
                    <Typography variant="subtitle1" color="gray900">
                      Arrastra hasta aquí el archivo ó{' '}
                    </Typography>
                  </Box>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={handleChangeFile('companyIconPath')}
                  />
                  <label htmlFor="contained-button-file">
                    <QuotationConfigActionButton component="span">Agregar desde el PC</QuotationConfigActionButton>
                  </label>
                </Box>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle1" color="gray900">
                Logo del proyecto
              </Typography>
              {projectIconPath ? (
                <Box className={styles.LogoContainer}>
                  <img src={projectIconPath} className={styles.Logo} />
                  <IconButton onClick={() => removeProjectIconPath('')}>
                    <DeleteOutline color="error" />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  className={styles.UpLogo}
                  draggable
                  onDrop={handleDrop('projectIconPath')}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <Box>
                    <Typography variant="subtitle1" color="gray900">
                      Arrastra hasta aquí el archivo ó{' '}
                    </Typography>
                  </Box>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={handleChangeFile('projectIconPath')}
                  />
                  <label htmlFor="contained-button-file">
                    <QuotationConfigActionButton component="span">Agregar desde el PC</QuotationConfigActionButton>
                  </label>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfigureDocumentTemplateModal;
