import { useState } from 'react';

function usePopoverUpdaterForExcelInSalesRoom() {
  const [isOpen, setOpen] = useState(false);
  const [anchorElement, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return {
    handleClick,
    isOpen,
    anchorElement,
  };
}

export default usePopoverUpdaterForExcelInSalesRoom;
