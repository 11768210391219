import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import ContractPaymentScheduleGridTitleFormatter from './ContractPaymentScheduleGridTitleFormatter';

const TableCellNonEditing = TableCellNonEditingComponent(ContractPaymentScheduleGridTitleFormatter);

const ContractPaymentScheduleGridTitleProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractPaymentScheduleGridTitleFormatter}
    editorComponent={TableCellNonEditing}
    {...props}
  />
);

export default ContractPaymentScheduleGridTitleProvider;
