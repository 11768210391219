import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Services from '../Services/SalesReportOneServices';

const services = new Services();

export default function useSalesReport() {
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [totalGoals, setTotalGoals] = useState(0);

  const [columns] = useState([
    { name: 'Grupos' },
    { name: 'Total Aptos' },
    { name: 'Total vendido' },
    { name: 'Metas acumuladas' },
    { name: 'Indicador de distribución' },
    { name: 'Indicadores de rotación' },
  ]);

  async function fetch() {
    try {
      setLoading(true);
      const response = await services.getReport(towerId);
      setReports(response.data);
      setTotalGoals(response.data[0].goals);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  const handleChangeReport = (value) => {
    setTotalGoals(value);
    fetch();
  };

  return { isLoading, columns, reports, totalGoals, handleChangeReport };
}
