import { useQueryClient } from 'react-query';
import { DeedExpensesHelper } from '../helpers/DeedExpensesHelper';
import { EVALPRO_DEED_EXPENSES_MONTHS } from '../reactQuery/RequestConstants';
import useUpdateEvalproDeedExpensesConfig from './useUpdateEvalproDeedExpensesConfig';

const useEvalproDeedExpensesDeedDate = (towerId: string, endOfSalesDate: number) => {
  const queryClient = useQueryClient();
  const { fetch, isLoading } = useUpdateEvalproDeedExpensesConfig(towerId, 'displacementStartDateDeed');

  const onFetchSuccess = () => queryClient.invalidateQueries(EVALPRO_DEED_EXPENSES_MONTHS);

  const onChange = (displacement: number) => {
    if (DeedExpensesHelper.validateDisplacementIsSameOrBefore(Number(endOfSalesDate), displacement))
      fetch(displacement, { onSuccess: onFetchSuccess });
  };

  return {
    isLoading,
    onChange,
  };
};

export default useEvalproDeedExpensesDeedDate;
