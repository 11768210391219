import { NumberFormatValues } from 'react-number-format';

const useMeasurementUnit = ({ handleChangeValue }) => {
  const handleChangeInstallments = (value: number) => {
    if (+value >= 0) {
      handleChangeValue('installments', value);
    }
  };

  const isAllowed = ({ floatValue }: NumberFormatValues) => {
    if (Number(floatValue) >= 0) return true;
    if (typeof floatValue === 'undefined') return true;
    return false;
  };

  return {
    handleChangeInstallments,
    isAllowed,
  };
};

export default useMeasurementUnit;
