import React, { FC } from 'react';
import { DeedExpensesQueryClient } from 'App/DeedExpenses/core/reactQuery/DeedExpensesQueryClient';
import { QueryClientProvider } from 'react-query';
import DeedExpensesMainContainer from '../containers/DeedExpensesMainContainer/DeedExpensesMainContainer';

const DeedExpensesMainView: FC = () => {
  return (
    <QueryClientProvider client={DeedExpensesQueryClient}>
      <DeedExpensesMainContainer />
    </QueryClientProvider>
  );
};

export default DeedExpensesMainView;
