import React, { FC, ReactChild } from 'react';
import { Box } from '@material-ui/core';
import Typography from '../../../../shared/components/Typography/Typography';
import Styles from './IncomePaymentsHeader.module.scss';
import IncomePaymentsHeaderProps from '../../Core/models/IncomePaymentsHeaderProps';

const IncomePaymentsHeader: FC<IncomePaymentsHeaderProps> = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      className={Styles.containerWithBorder}
    >
      <Typography variant="headline3">Pagos</Typography>
      <Box className={Styles.segmentsHeaderContainer}>{children}</Box>
    </Box>
  );
};

export default IncomePaymentsHeader;
