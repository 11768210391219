import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { useParams } from 'react-router-dom';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';
import { updateBudgetSelector, cellEditorState, updateBudgetRows } from '../../../TotalCashFlow/Core/actions';
import ResultsStateServices from '../../Services/ResultsStateServices';

interface RouteParams {
  towerId: string;
}

function useEventsControlBandCell() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { resultsStateRows, budgetRows } = useSelector((state) => state.cashFlow.root || {});

  const resultsStateRowsMapper = resultsStateRows.map((row) => {
    return {
      previousBudget: row.total,
      budgetId: row.budgetId,
      budgetPercentage: row.percentage,
      budgetName: row.budgetName,
      budgetIndex: row.budgetIndex,
    };
  });

  const overwriteRows = (newRows, eventName) => {
    const oldRows = budgetRows.filter(row => row.budgetName !== eventName)

    const rowsFiltered = oldRows.concat(newRows)

    return rowsFiltered
  }

  const deleteEvent = (eventName) => {
    const rowsFiltered = budgetRows.filter((row) => row.budgetName !== eventName)

    return rowsFiltered
  }

  const resultsStateServices = new ResultsStateServices();
  const { towerId } = useParams<RouteParams>();

  const [alertProps, setAlertProps] = useState({
    isOpen: false,
    title: '',
    variant: AlertVariants.Destructive,
    acceptAction: () => {},
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const { showAlert } = useAlert(alertProps);

  const deleteEventProps = (eventName) => {
    return {
      isOpen: false,
      title: 'Eliminar evento',
      variant: AlertVariants.Destructive,
      acceptAction: async () => {
        const response = await resultsStateServices.deleteEventControl({ towerId, eventName });

        const rowsFiltered = deleteEvent(eventName)

        response.data && dispatch(updateBudgetRows(rowsFiltered));

        response.data
          ? enqueueSnackbar('El evento se elimino correctamente', { variant: 'success' })
          : enqueueSnackbar('No se pudo eliminar el evento', { variant: 'error' });
      },
    };
  };

  const getDeleteAlertDescription = (eventName: string) =>
    `¿Esta seguro que desea eliminar el evento "${eventName}" permanentemente?`;

  const deleteEventsHandleClick = (eventName: string) => {
    setAlertProps(deleteEventProps(eventName));
    showAlert(getDeleteAlertDescription(eventName));
  };

  const overwriteEventProps = (eventName) => {
    return {
      isOpen: false,
      title: 'Sobreescribir evento',
      variant: AlertVariants.Accept,
      acceptAction: async () => {
        const response = await resultsStateServices.overwriteEvent({ towerId, rows: resultsStateRowsMapper });
        const {data} = response
        
        const rowsFiltered = overwriteRows(resultsStateRowsMapper, eventName)

        data && dispatch(updateBudgetRows(rowsFiltered))
        data
          ? enqueueSnackbar(`El evento "${eventName}" se sobreescribio correctamente`, { variant: 'success' })
          : enqueueSnackbar(`No se pudo sobreescribir el evento "${eventName}"`, { variant: 'error' });
      },
    };
  };

  const getOverwriteAlertDescription = (eventName: string) =>
    `El evento "${eventName}" se sobreescribira con los datos del corte actual`;

  const overwriteEventHandleClick = (eventName) => {
    setAlertProps(overwriteEventProps(eventName));
    showAlert(getOverwriteAlertDescription(eventName));
  };

  const changeBudget = (newSelectedBudget) => {
    dispatch(updateBudgetSelector(newSelectedBudget));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditableCells = () => {
    dispatch(cellEditorState(true));
  };

  return {
    deleteEventsHandleClick,
    overwriteEventHandleClick,
    changeBudget,
    handleClick,
    handleCloseMenu,
    handleEditableCells,
    anchorEl,
    openMenu,
  };
}

export default useEventsControlBandCell;
