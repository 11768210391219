import React from 'react';
import { useSelector } from 'react-redux';
import TotalAndUtilityPercentage from './CellTypes/TotalAndUtilityPercentage';
import EmptyCell from './CellTypes/EmptyCell';
import TotalForClientsWithoutQuotation from './CellTypes/TotalForClientsWithoutQuotation';
import UtilityBeforeTax from './CellTypes/UtilityBeforeTax';
import UtilityBeforeTaxPercentage from './CellTypes/UtilityBeforeTaxPercentage';
import TotalAndUtility from './CellTypes/TotalAndUtility';
import ContractsPercentage from './CellTypes/ContractsPercentage';
import Contracts from './CellTypes/Contracts';
import ItemsPercentage from './CellTypes/ItemsPercentage';
import Items from './CellTypes/Items';
import GroupsPercentage from './CellTypes/GroupsPercentage';
import CutsPercentage from './CellTypes/CutsPercentage';
import PreviousCut from './CellTypes/PreviousControl';
import Groups from './CellTypes/Groups';
import CellType from '../../../Core/enums/CellType';

const CellsSelector = (cellType, cellData, row, name) => {

  const typeOfViewState = useSelector(
    (state) => state.cashFlow.root.typeOfView,
  );

  const thousands = 1000;
  const millions = 1000000;
  const pesos = 1;

  let divider;
  let decimals;

  switch (typeOfViewState) {
    case 'thousands':
      divider = thousands;
      decimals = 0;
      break;
    case 'pesos':
      divider = pesos;
      decimals = 0;
      break;
    default:
      divider = millions;
      decimals = 1;
      break;
  }

  switch (cellType) {
    case CellType.EMPTY_CELL.value:
      return <EmptyCell />;
    case CellType.CONTROL_PERCENTAGE.value:
      return <CutsPercentage cellData={cellData} row={row} name={name} />;
    case CellType.PREVIOUS_CONTROL.value:
      return (
        <PreviousCut
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
    case CellType.TOTAL_AND_UTILITY_PERCENTAGE.value:
      return <TotalAndUtilityPercentage cellData={cellData} />;
    case CellType.TOTAL_FOR_CLIENTS_WITHOUT_QUOTATION.value:
      return (
        <TotalForClientsWithoutQuotation
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
    case CellType.UTILITY_BEFORE_TAX.value:
      return (
        <UtilityBeforeTax
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
    case CellType.UTILITY_BEFORE_TAX_PERCENTAGE.value:
      return <UtilityBeforeTaxPercentage cellData={cellData} />;
    case CellType.TOTAL_AND_UTILITY.value:
      return (
        <TotalAndUtility
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
    case CellType.CONTRACTS_PERCENTAGE.value:
      return <ContractsPercentage cellData={cellData} />;
    case CellType.CONTRACTS.value:
      return (
        <Contracts
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
    case CellType.ITEMS_PERCENTAGE.value:
      return <ItemsPercentage cellData={cellData} />;
    case CellType.ITEMS.value:
      return (
        <Items
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
    case CellType.GROUPS_PERCENTAGE.value:
      return <GroupsPercentage cellData={cellData} />;
    default:
      return (
        <Groups
          cellData={cellData}
          divider={divider}
          decimals={decimals}
          row={row}
          name={name}
        />
      );
  }
};

export default CellsSelector;
