import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { Button } from 'shared/components/Button/Button';
import Modal from 'shared/components/Modal/Modal';
import ModalActions from 'shared/components/ModalActions/ModalActions';
import ModalTitle from 'shared/components/ModalTitle/ModalTitle';
import TextField from 'shared/components/TextField/TextField';
import useCloseStageTowerEditor from '../../../core/customHooks/useCloseStageTowerEditor';
import useSaveStageTower from '../../../core/customHooks/useSaveStageTower';
import useStageTowerEditorForm from '../../../core/customHooks/useStageTowerEditorForm';
import { OnUpdateStages, TowerEditorOptions } from '../../../core/types/Stages.types';

import styles from './StagesTowerEditor.module.scss';

const getTitle = (isEditing: boolean) => (isEditing ? 'Editar Torre' : 'Nueva Torre');

const getSaveButtonText = (isEditing: boolean) => (isEditing ? 'Guardar' : 'Crear');

const LoadingProgress = (
  <div className={styles.loadingProgress}>
    <CircularProgress className={styles.circularProgress} size="30px" />
  </div>
);

type Props = {
  isOpen: boolean;
  options: TowerEditorOptions;
  onUpdateStages: OnUpdateStages;
  onClose: () => void;
};

const StagesTowerEditor: React.FC<Props> = ({ isOpen, options, onUpdateStages, onClose }) => {
  const { isSavingTower, saveTower } = useSaveStageTower({ isOpen, options, onUpdateStages, onClose });
  const {
    name,
    hasNameError,
    nameHelperText,
    description,
    handleChangeName,
    handleChangeDescription,
    handleSubmit,
    handleOnIvalid,
    resetNameError,
  } = useStageTowerEditorForm({ isOpen, options, saveTower });
  const handleCloseModal = useCloseStageTowerEditor({ isSavingTower, onClose });

  return (
    <Modal open={isOpen} aria-labelledby="stages-tower-editor-title" onClose={handleCloseModal}>
      <form onSubmit={handleSubmit} noValidate>
        <ModalTitle id="stages-tower-editor-title" disabled={isSavingTower} onClickClose={onClose}>
          {getTitle(options.isEditing)}
        </ModalTitle>
        <div className={styles.content}>
          <TextField
            className={styles.nameField}
            size="medium"
            value={name}
            placeholder="Nombre"
            required
            disabled={isSavingTower}
            onChange={handleChangeName}
            error={hasNameError}
            helperText={nameHelperText}
            inputProps={{ onInvalid: handleOnIvalid }}
            onInput={resetNameError}
          />
          <TextField
            className={styles.descriptionField}
            size="medium"
            label="Descripción"
            value={description}
            placeholder="Escribe una descripción"
            multiline
            minRows={4}
            maxRows={4}
            disabled={isSavingTower}
            onChange={handleChangeDescription}
          />
        </div>
        <ModalActions>
          <Button variant="outlined" size="small" disabled={isSavingTower} onClick={onClose}>
            Cancelar
          </Button>
          <Button className={styles.saveButton} variant="contained" size="small" type="submit" disabled={isSavingTower}>
            <>
              {getSaveButtonText(options.isEditing)}
              {isSavingTower && LoadingProgress}
            </>
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default StagesTowerEditor;
