import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Styles from '../styles/CompanySchedulesTable.module.scss';
import useRowsAndColumnsForCompanySchedules from '../../../Core/customHooks/useRowsAndColumnsForCompanySchedules';

const CompanySchedulesTable = ({ projects }) => {
  const { rows, columns } = useRowsAndColumnsForCompanySchedules(projects);

  const CustomCell = styled(TableCell)`
    background-color: ${(props) => {
      switch (props.currentState) {
        case 'yellow':
          return '#a80072';
        case 'blue':
          return 'rgb(52, 52, 56)';
        case 'green':
          return 'rgb(58, 116, 117)';
        case 'red':
          return '#E04A65';
        default:
          return 'transparent';
      }
    }};

    border: ${(props) => {
      switch (props.currentState) {
        case 'yellow':
          return 'solid 0.01px black';
        case 'blue':
          return 'solid 0.01px black';
        case 'green':
          return 'solid 0.01px black';
        case 'red':
          return 'solid 0.01px black';
        default:
          return 'transparent';
      }
    }};
  `;

  return (
    <>
      {projects ? (
        <>
          <Box className={Styles.indicatorContainer}>
            <Typography className={Styles.indicator}>
              Estructuración
              <Box className={Styles.structuringBox}></Box>
            </Typography>
            <Typography className={Styles.indicator}>
              Preventas
              <Box className={Styles.presalesBox}></Box>
            </Typography>
            <Typography className={Styles.indicator}>
              Construcción
              <Box className={Styles.constructionBox}></Box>
            </Typography>
            <Typography className={Styles.indicator}>
              Escrituración
              <Box className={Styles.deedBox}></Box>
            </Typography>
          </Box>
          <Paper className={Styles.root}>
            <TableContainer className={Styles.container}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={clsx(Styles.columnDates, {
                          [Styles.stickyCell]: index === 0,
                        })}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return (
                              <CustomCell
                                key={column.id}
                                currentState={value}
                                className={clsx({
                                  [Styles.stickyCell]: index === 0,
                                })}
                              >
                                {column.id === 'name' && (
                                  <Typography variant="body2">
                                    {value}
                                  </Typography>
                                )}
                              </CustomCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

CompanySchedulesTable.propTypes = {
  selectedCompany: PropTypes.string,
  projects: PropTypes.array,
};

export default CompanySchedulesTable;
