import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';
import useQuotationPaymentTypeEdition from '../../../Core/customHooks/useQuotationPaymentTypeEdition';

const QuotationPaymentTypeRow = ({
  index,
  typeId,
  quotation,
  dispatchFetchQuotationSuccess,
}) => {
  const {
    options,
    paymentSelected,
    isEditing,
    onChange,
  } = useQuotationPaymentTypeEdition(
    index,
    typeId,
    quotation,
    dispatchFetchQuotationSuccess,
  );
  return (
    <Box>
      <FormControl>
        <InputLabel></InputLabel>
        <Select
          native
          value={paymentSelected}
          onChange={onChange}
          disabled={!isEditing}
          inputProps={{
            name: 'payment',
            id: `payment-type_${index}_${typeId}`,
          }}
        >
          {options.map((option) => (
            <option key={`payment_type_${option.id}`} value={option.id}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

QuotationPaymentTypeRow.propTypes = {
  dispatchFetchQuotationSuccess: PropTypes.func,
  typeId: PropTypes.number,
  index: PropTypes.number,
  quotation: PropTypes.object,
};

export default QuotationPaymentTypeRow;
