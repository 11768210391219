import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import styles from './QuotationConfigActionButton.module.scss';

const QuotationConfigActionButton: FC<ButtonProps & { isTransparent?: boolean; component?: string }> = (props) => (
  <Button
    {...props}
    className={`${styles.Button} ${props.isTransparent ? styles.Transparent : ''} ${props.className || ''}`}
    children={<span>{props.children}</span>}
  />
);

export default QuotationConfigActionButton;
