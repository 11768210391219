import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import styles from './PopoverAction.module.scss';

const PopoverAction: FC<ButtonProps> = (props) => (
  <Button {...props} className={`${styles.action} ${props.className || ''}`}>
    {props.children}
  </Button>
);
export default PopoverAction;
