import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { Grid, Table, TableHeaderRow, TableSummaryRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SelectionState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { CondensedTableHeaderRowCell } from '../../../../../shared/tables/CondensedTable';
import TableSummaryRowCell from '../../../../../shared/tables/TableSummaryRowCell';
import TableCell from '../../../../../shared/tables/TableCell';
import VirtualTableDataTypeProvider from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import StateChip from '../../../../../shared/components/StateChip/StateChip';
import useCustomPopover from '../../../../../shared/customHooks/useCustomPopover';
import { Contract } from '../../../../../models/Contracts';

import useColumnsAndRowsForContracts from '../../../Core/customHooks/useColumnsAndRowsForContracts';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import ContractsGridSummaryItemComponent from './ContractsGridSummaryItemComponent';
import ContractsListPopover from './ContractsListPopover';
import ContractsListDetailsProvider from './ContractsGridDetailsProvider';

const ContractStateFormatter = ({ value }) => (!!value ? <StateChip label={value.name} color={value.color} /> : null);

const ContractStateProvider = (props) => <DataTypeProvider formatterComponent={ContractStateFormatter} {...props} />;

const getRowId = (row: Contract) => row.id;

interface IContractsGrid {
  onSeleccionChange: (contract: Contract) => void;
  onEditContract: (contract: Contract) => void;
  onDeleteContract: (contract: Contract) => void;
  contracts: Contract[];
}

const ContractsGrid: FC<IContractsGrid> = ({ onSeleccionChange, onEditContract, onDeleteContract, contracts }) => {
  const { columns, totalSummaryItems, tableDefaultSort, integratedSortingColumnExtensions, summaryCalculator } =
    useColumnsAndRowsForContracts();

  const rows: Contract[] = contracts;

  const { elementRef, handlePopoverOpen } = useCustomPopover<Contract>();

  const [selection] = useState([]);

  return (
    <Box>
      {!!rows ? (
        <Box>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState defaultSorting={tableDefaultSort} />
            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
            <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.tooltip} />
            <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
            <VirtualTableDataTypeProvider
              columns={columns}
              type={[ProviderColumnType.percent, ProviderColumnType.semaphore]}
            />
            <ContractStateProvider for={['state']} />
            <ContractsListDetailsProvider for={['options']} onClick={handlePopoverOpen} />
            <SummaryState totalItems={totalSummaryItems} />
            <IntegratedSummary calculator={summaryCalculator} />
            <SelectionState
              selection={selection}
              onSelectionChange={(sel) => {
                const [selectedId] = sel;
                const contract = rows.find((row) => row.id === selectedId) || null;
                if (!!contract) {
                  onSeleccionChange(contract);
                }
              }}
            />
            <Table columnExtensions={columns} cellComponent={TableCell} />
            <TableHeaderRow cellComponent={CondensedTableHeaderRowCell} showSortingControls />
            <TableSummaryRow
              totalCellComponent={TableSummaryRowCell}
              itemComponent={ContractsGridSummaryItemComponent}
            />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
          </Grid>
          <ContractsListPopover
            elementRef={elementRef}
            handleActionDeleteClicked={(contract) => {
              if (!!contract) {
                onDeleteContract(contract);
              }
            }}
            handleActionEditClicked={(contract) => {
              if (!!contract) {
                onEditContract(contract);
              }
            }}
            handleActionDetailsClicked={(contract) => {
              if (!!contract) {
                onSeleccionChange(contract);
              }
            }}
          />
        </Box>
      ) : (
        <AbsoluteCircularProgress />
      )}
    </Box>
  );
};

export default ContractsGrid;
