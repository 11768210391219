import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import ExternalResourcesServices from '../../../../services/externalResources/ExternalResourcesServices';

function useSoldReportForExternalResourceDownload() {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId }: { towerId: string } = useParams();

  const download = async () => {
    const services = new ExternalResourcesServices();
    try {
      const name = `Flujo_de_Ventas.xlsx`;
      const response = await services.downloadSoldSummary(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Plantilla descargado correctamente', {
        variant: 'success',
      });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };

  return {
    download,
  };
}

export default useSoldReportForExternalResourceDownload;
