import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Numbers from '../../../../helpers/numbers';
import QuotationsServices from '../../../../services/Quotations/Quotations.services';

function useUpdateQuotationDeedExpense(
  soldName,
  buyerName,
  deedExpense,
  handleChangeValue = (propertyId, value) => {},
) {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [currentDeedExpense, setCurrentDeedExpense] = useState(deedExpense);

  useEffect(() => {
    if (currentDeedExpense[soldName] === undefined) {
      setCurrentDeedExpense(deedExpense);
    }
  }, [deedExpense]);

  const sold = Numbers.cleanNumber(
    currentDeedExpense ? currentDeedExpense[soldName] : 0,
  );
  const buyer = Numbers.cleanNumber(
    currentDeedExpense ? currentDeedExpense[buyerName] : 0,
  );

  const finalPercentage = Numbers.cleanNumber((sold * buyer) / 100);

  const updateGenericPropertyInQuotationDeedExpense = async (
    propertyId,
    value,
    previousValue,
  ) => {
    if (previousValue === value) {
      return;
    }
    const services = new QuotationsServices();
    try {
      await services.putQuotationDeedExpense(
        towerId,
        deedExpense.quotationSetupId,
        propertyId,
        value,
      );
      if (handleChangeValue) {
        handleChangeValue(propertyId, value, previousValue);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setCurrentDeedExpense({
        ...currentDeedExpense,
        [propertyId]: previousValue,
      });
    }
  };

  const onChangeSold = (values) => {
    const previousValue = currentDeedExpense[soldName];
    setCurrentDeedExpense({
      ...currentDeedExpense,
      [soldName]: values.floatValue,
    });
    updateGenericPropertyInQuotationDeedExpense(
      soldName,
      values.floatValue,
      previousValue,
    );
  };

  const onChangeBuyer = (values) => {
    const previousValue = currentDeedExpense[buyerName];
    setCurrentDeedExpense({
      ...currentDeedExpense,
      [buyerName]: values.floatValue,
    });
    updateGenericPropertyInQuotationDeedExpense(
      buyerName,
      values.floatValue,
      previousValue,
    );
  };

  const isAllowed = ({ floatValue }) => {
    if (floatValue === undefined) return true;
    return floatValue >= 0 && floatValue <= 100;
  };

  return {
    sold,
    buyer,
    finalPercentage,
    onChangeSold,
    onChangeBuyer,
    isAllowed,
  };
}

export default useUpdateQuotationDeedExpense;
