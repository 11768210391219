import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import GeneralWalletServices from '../../../GeneralWallet/services/GeneralWalletServices';
import Axios from 'axios';
import LikemetricError from '../../../../shared/types/LikemetricError';

function useRealPaymentsUploader() {
  const [file, setFile] = useState<File | string>('');
  const { towerId } = useParams<{ towerId?: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isUploadingFile, setIsLoading] = useState(false);
  const [lastPaymentsResponse, setLastResponse] = useState(null);

  const services = new GeneralWalletServices();
  const onChangeFileHandler = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const uploadExcel = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await services.uploadRealPayments(towerId, formData);

      setFile('');
      enqueueSnackbar('Plantilla cargada correctamente', {
        variant: 'success',
      });

      if (response.data.warningMessage)
        enqueueSnackbar(response.data.warningMessage, {
          variant: 'warning',
        });
      setLastResponse(response.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setFile('');
      const error = e as LikemetricError;
      enqueueSnackbar(error.message);
    }
  };

  const onCancel = () => {
    setFile('');
  };

  return {
    file,
    isUploadingFile,
    lastPaymentsResponse,
    onChangeFileHandler,
    uploadExcel,
    onCancel,
  };
}

export default useRealPaymentsUploader;
