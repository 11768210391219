import React, { FC } from 'react';

import {
  Dialog,
  FormControl,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Grid,
  InputAdornment,
  DialogTitle,
} from '@material-ui/core';
import moment from 'moment';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { DateRangeOutlined } from '@material-ui/icons';

import Typography from '../../../shared/components/Typography/Typography';

import Styles from './ContractFormMainView.module.scss';

import useContractForm from '../Core/customHooks/useContractForm';
import { ContractItem, State, Contract } from '../../../models/Contracts';
import { Contractor } from '../../../models/Contractors';
import { Button } from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';

interface IContractFormMainView {
  open: boolean;
  handleUpdate: () => void;
  handleClose: () => void;
  handleCreate: (contract: Contract) => void;
  currentContract: Contract | undefined;
}

const ContractFormMainView: FC<IContractFormMainView> = ({
  open,
  handleUpdate,
  handleClose,
  handleCreate,
  currentContract,
}) => {
  const {
    contractorsList,
    contractItemsList,
    contractStatesList,
    data,
    errors,
    handleSubmit,
    handleChange,
    handleChangeDate,
    handleChangeValue,
    resetData,
    autocompleteItemValue,
    setAutocompleteItemValue,
    autocompleteContractorValue,
    setAutocompleteContractorValue,
    autocompleteStateValue,
    setAutocompleteStateValue,
    inputItemValue,
    setInputItemValue,
    inputContractorValue,
    setInputContractorValue,
    inputStateValue,
    setInputStateValue,
    minValidDate,
    maxValidDate,
  } = useContractForm({
    currentContract,
    handleCreate,
    handleUpdate,
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            <Typography variant="headline6" color="gray800">
              {currentContract?.id ? `Contrato ${currentContract?.id}` : 'Nuevo Contrato'}
            </Typography>

            <IconButton
              className={Styles.CloseIcon}
              iconName="Clear"
              variant="ghost"
              size="small"
              aria-controls="close-lot-form"
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent className={Styles.DialogContent}>
            <Box className={Styles.BoxTitle} py={2} px={6}>
              <Typography variant="subtitle1" color="primary800">
                Detalles
              </Typography>
            </Box>
            <Box my={2} px={6}>
              <FormControl fullWidth>
                <TextField
                  id="title"
                  label="Título de Contrato"
                  variant="outlined"
                  value={data?.title || ''}
                  size="small"
                  name="title"
                  error={!!errors?.title}
                  helperText={errors?.title}
                  onChange={handleChange('title')}
                />
              </FormControl>
            </Box>
            <Box my={2} px={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="item"
                  options={contractItemsList as ContractItem[]}
                  autoHighlight
                  getOptionLabel={(option: ContractItem) => `${option.contractCategory.categoryName} - ${option.name}`}
                  value={autocompleteItemValue}
                  onChange={(event, newValue) => {
                    const val = !!newValue ? `${newValue?.id}` : '';
                    handleChangeValue('itemId', val);
                    setAutocompleteItemValue(newValue);
                  }}
                  inputValue={inputItemValue}
                  onInputChange={(_, newInputValue) => {
                    setInputItemValue(newInputValue);
                  }}
                  renderOption={(option: ContractItem) => (
                    <React.Fragment>
                      <b>{option.contractCategory.categoryName}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Definir grupo contable"
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="itemId"
                      error={!!errors?.itemId}
                      helperText={errors?.itemId}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box my={2} px={6}>
              <FormControl fullWidth>
                <TextField
                  id="description"
                  name="description"
                  label="Detalles"
                  variant="outlined"
                  multiline
                  minRows={3}
                  value={data?.description || ''}
                  size="small"
                  error={!!errors?.description}
                  helperText={errors?.description}
                  onChange={handleChange('description')}
                />
              </FormControl>
            </Box>
            {currentContract?.id && (
              <>
                <Box className={Styles.BoxTitle} py={2} px={6}>
                  <Typography variant="subtitle1" color="primary800">
                    Duración
                  </Typography>
                </Box>
                <Box my={2} px={6}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <Box mb={2}>
                        <Typography variant="body1" color="gray800">
                          Inicio
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={2}>
                        <FormControl fullWidth>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              value={data?.fromDate ? moment(Number(data?.fromDate)) : null}
                              format="DD/MM/YYYY"
                              minDate={moment(Number(minValidDate))}
                              maxDate={moment(Number(maxValidDate))}
                              maxDateMessage="La fecha debe ser menor a "
                              minDateMessage="La fecha debe ser mayor a "
                              onChange={(date) => {
                                handleChangeDate('fromDate')(date?.valueOf() || null);
                              }}
                              TextFieldComponent={(props) => (
                                <TextField
                                  {...props}
                                  label=""
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  helperText={errors?.fromDate}
                                  error={!!errors?.fromDate}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <DateRangeOutlined />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={2}>
                        <Typography variant="body1" color="gray800">
                          Fin
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={2}>
                        <FormControl fullWidth>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              value={data?.toDate ? moment(Number(data?.toDate)) : null}
                              format="DD/MM/YYYY"
                              minDate={moment(Number(minValidDate))}
                              maxDate={moment(Number(maxValidDate))}
                              maxDateMessage="La fecha debe ser menor a "
                              minDateMessage="La fecha debe ser mayor a "
                              onChange={(date) => {
                                handleChangeDate('toDate')(date?.valueOf() || null);
                              }}
                              TextFieldComponent={(props) => (
                                <TextField
                                  {...props}
                                  label=""
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  helperText={errors?.toDate}
                                  error={!!errors?.toDate}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <DateRangeOutlined />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            <Box className={Styles.BoxTitle} py={2} px={6}>
              <Typography variant="subtitle1" color="primary800">
                Proveedor
              </Typography>
            </Box>
            <Box my={2} px={6}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Selecciona un proveedor
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="contractor"
                        options={contractorsList as Contractor[]}
                        autoHighlight
                        value={autocompleteContractorValue}
                        onChange={(event, newValue) => {
                          const val = !!newValue ? `${newValue?.id}` : '';
                          handleChangeValue('contractorId', val);
                          setAutocompleteContractorValue(newValue);
                        }}
                        inputValue={inputContractorValue}
                        onInputChange={(_, newInputValue) => {
                          setInputContractorValue(newInputValue);
                        }}
                        getOptionLabel={(option: Contractor) => option.patnerName}
                        renderOption={(option: Contractor) => (
                          <React.Fragment>
                            <span>{option.patnerName}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="contractorId"
                            error={!!errors?.contractorId}
                            helperText={errors?.contractorId}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Estado de negociación
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="contract-state"
                        options={contractStatesList as State[]}
                        autoHighlight
                        value={autocompleteStateValue}
                        onChange={(event, newValue) => {
                          const val = !!newValue ? `${newValue?.id}` : '';
                          handleChangeValue('stateId', val);
                          setAutocompleteStateValue(newValue);
                        }}
                        inputValue={inputStateValue}
                        onInputChange={(_, newInputValue) => {
                          setInputStateValue(newInputValue);
                        }}
                        getOptionLabel={(option: State) => option.name}
                        renderOption={(option: State) => (
                          <React.Fragment>
                            <span>{option.name}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="stateId"
                            error={!!errors?.stateId}
                            helperText={errors?.stateId}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  resetData();
                  if (!currentContract) {
                    setAutocompleteItemValue(null);
                    setAutocompleteContractorValue(null);
                    setAutocompleteStateValue(null);
                  }
                  handleClose();
                }}
              >
                Cancelar
              </Button>
            </Box>
            <Box m={2}>
              <Button variant="contained" size="small" type="submit">
                Guardar
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ContractFormMainView;
