import { useSnackbar } from 'notistack';

import FeatureFieldServices from '../../services/FeatureFieldServices';
import { UpdateFeatureFieldTypes } from '../enums/featureField.enum';
import { FeatureField } from '../interfaces/featureField.types';
import { OnUpdateField } from '../interfaces/FeatureFieldAdmin.types';
const repository = new FeatureFieldServices();

function useDeleteFeatureField(onUpdateField: OnUpdateField) {
  const { enqueueSnackbar } = useSnackbar();

  const deleteFeatureField = async (fieldId: number) => {
    try {
      await repository.deleteField(fieldId);
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleDeleteField = (field: FeatureField) => {
    deleteFeatureField(field.id);
    onUpdateField({
      type: UpdateFeatureFieldTypes.Delete,
      field,
    });
  };

  return handleDeleteField;
}

export default useDeleteFeatureField;
