import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, CircularProgress } from '@material-ui/core';
import ConfirmIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import AddImageIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import Styles from '../styles/GraphicMaterialDialog.module.scss';

export default function UploadGraphicMaterialButton({
    fileName,
    isUploadingFile,
    onChangeHandler,
    onCancel,
    onAccept,
}) {
    return (
        <>
        <input
            name="graphicMaterialfile"
            accept="image/*"
            hidden
            id="raised-button-file"
            type="file"
            onChange={onChangeHandler}
        />
        <label htmlFor="raised-button-file">
            <Button
            color="primary"
            component="span"
            disableElevation
            disabled={isUploadingFile}
            >
                {fileName || <AddImageIcon className={Styles.addIcon}/>}
            </Button>
            {fileName && (
            <>
                <IconButton
                color="primary"
                onClick={onAccept}
                disabled={isUploadingFile}
                >
                <ConfirmIcon />
                </IconButton>
                <IconButton
                color="default"
                onClick={onCancel}
                disabled={isUploadingFile}
                >
                <CancelIcon />
                </IconButton>
                {isUploadingFile && <CircularProgress />}
            </>
            )}
        </label>
        </>
    );
}

UploadGraphicMaterialButton.propTypes = {
    fileName: PropTypes.string,
    isUploadingFile: PropTypes.bool.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};