import React, { Component } from 'react';
import debounce from 'lodash/debounce';

/**
 * Use debouncedInput as an option for delaying the onChange
 *
 */
export default function debouncedInput(
  WrappedComponent,
  config = { timeout: 500 },
) {
  return class DebouncedTextField extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: this.props.value,
        values: this.props.values,
        enableUpdate: true,
      };
      this.sendTextChange = debounce(this.sendTextChange, config.timeout);
      this.onValueChange = debounce(this.onValueChange, config.timeout);
    }

    componentDidUpdate() {
      if (this.state.value !== this.props.value && this.state.enableUpdate) {
        this.setState({ value: this.props.value });
      }
    }

    handleTextChange = (e) => {
      this.setState({ value: e.target.value, enableUpdate: false });
      this.sendTextChange({ target: { value: e.target.value } });
    };

    handleValuesChange = (values) => {
      this.setState({ values });
      this.onValueChange(values);
    };

    sendTextChange = (e) => {
      if (this.props.onChange) {
        this.setState({ enableUpdate: true }, () => {
          this.props.onChange(e);
        });
      }
    };

    onValueChange = (e) => {
      if (this.props.onValueChange) this.props.onValueChange(e);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          value={this.state.value}
          onChange={this.handleTextChange.bind(this)}
          onValueChange={this.handleValuesChange}
        />
      );
    }
  };
}
