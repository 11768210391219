import React, { FC } from "react";
import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Button, ButtonGroup, Divider } from "@blueprintjs/core";
import useContractorList from "../Core/customHooks/useContractorList";
import useXLSXContractors from "../Core/customHooks/useXLSXContractors";
import {
  ContractorProvider,
  useContractorContext,
} from './Views/Context/ContractorContext'

import CompanySelector from './Views/Components/CompanySelector';
import ContractorSearch from "./Views/Components/ContractorSearch";
import ContractorList from "./Views/Components/ContractorList";
import ContractorInformation from "./Views/Components/ContractorInformation";

import './Views/Components/styles.module.scss';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 36,
    // height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: "flex",
    flexDirection: "column",
  },
}));

const Contractor: FC = () => {
  const classes = useStyles();
  const {
    currentContractor,
    selectCurrentContractor,
    contractors,
    fetchContractors,
  } = useContractorContext();

  const { contractors: contractorList, handleChangeSearch } =
    useContractorList(contractors);

  const { makeDemoXLSX, uploadContractors, loading } = useXLSXContractors({
    onBulkCreate: fetchContractors,
  });

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (): void => hiddenFileInput.current?.click();

  return (
    <Container maxWidth="lg">
      <Box marginY={2}>
        <Typography variant="h4">Contratistas</Typography>
      </Box>
      <Divider />
      <Box display="flex" mt={2} flexGrow={1}>
        <Box pl={0} width="40%" display="flex" flexDirection="column">
          <Box paddingX={1}>
            <CompanySelector />
            <ButtonGroup fill>
              <Button
                icon="cloud-upload"
                onClick={handleClick}
                disabled={loading}
                loading={loading}
              >
                Cargar proveedores
                <input
                  type="file"
                  hidden
                  ref={hiddenFileInput}
                  onChange={(e) => uploadContractors(e)}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Button>
              <Button icon="download" onClick={makeDemoXLSX}>
                Plantilla proveedores
              </Button>
            </ButtonGroup>
          </Box>
          <Box p={1}>
            <ContractorSearch handleChangeSearch={handleChangeSearch} />
          </Box>

          <ContractorList
            contractors={contractorList}
            onClick={selectCurrentContractor}
            isSelect={(contractor) => contractor.id === currentContractor?.id}
          />
        </Box>
        <Box paddingX={1} width="60%">
          <ContractorInformation
            onClose={() => selectCurrentContractor(undefined)}
            onDelete={() => selectCurrentContractor(undefined)}
          />
        </Box>
      </Box>
    </Container>
  );
};

const ContractorPage: FC = () => {
  return (
    <ContractorProvider>
      <Contractor />
    </ContractorProvider>
  );
};

export default ContractorPage;
