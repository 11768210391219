import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import GroupGoals from './GroupGoals/GroupGoals';
import useSalesReport from '../../Core/useSalesReport';

export default function ReportTable() {
  const { columns, reports, totalGoals, handleChangeReport } = useSalesReport();
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Reporte de ventas 1
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  align="center"
                  key={`header-${index}-column`}
                  size={column.name === 'Grupos' && 'medium'}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report, index) => (
              <TableRow key={`row-${index}`}>
                <TableCell align="center" variant="head">
                  {report.name}
                </TableCell>
                <TableCell align="center">{report.total}</TableCell>
                <TableCell align="center">{report.solds}</TableCell>
                <TableCell align="center">
                  <GroupGoals
                    name={report.goals}
                    id={report.id}
                    rowName={report.name}
                    totalGoals={totalGoals}
                    handleChangeReport={handleChangeReport}
                    propertiesTotal={report.total}
                  />
                </TableCell>
                <TableCell align="center">
                  <NumberFormat
                    value={report.distributionIndicator}
                    decimalScale={2}
                    suffix="%"
                    displayType="text"
                  />
                </TableCell>
                <TableCell align="center">
                  <NumberFormat
                    value={report.rotationIndicator}
                    suffix="%"
                    decimalScale={2}
                    displayType="text"
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="center" variant="head">
                Disponibles
              </TableCell>
              <TableCell align="center">
                {reports[0] ? reports[0].availables : 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" variant="head">
                Total ventas
              </TableCell>
              <TableCell align="center">
                <NumberFormat
                  value={
                    reports[0] ? (reports[0].solds / reports[0].total) * 100 : 0
                  }
                  suffix="%"
                  decimalScale={2}
                  displayType="text"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
