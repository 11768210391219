import React from 'react';
import { Typography } from '@material-ui/core';
import BankIcon from '@material-ui/icons/AccountBalanceRounded';
import { CustomBoxForState } from '../StyledBuyerCustomer';
import useBuyerPropertyDetailWithQuotationsStates from '../../../../Core/customHooks/useBuyerPropertyDetailWithQuotationsStates';
import QuotationsList from '../../../../../../components/Client2/List/Row/QuotationsList/QuotationsList';
import useBuyerCustomerPaymentPlanState from '../../../../Core/customHooks/useBuyerCustomerPaymentPlanState';

import { Button } from '../../../../../../shared/components/Button/Button';
import { useBuyerCustomerPropertyLoader } from '../../../../Core/customHooks/useBuyerCustomerPropertySelector';

const BuyerCustomerPaymentPlanState = () => {
  const {
    propertyHasPaymentPlan,
    currentClient,
    currentPropertyInfo,
    currentFilter,
  } = useBuyerPropertyDetailWithQuotationsStates();

  const { isOpen, onClick } = useBuyerCustomerPaymentPlanState(true);

  const { reloadProperty } = useBuyerCustomerPropertyLoader(
    currentClient?.clientId,
    currentClient?.propertyId,
    currentFilter,
  );

  return (
    currentClient && (
      <>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          startIcon={<BankIcon />}
          onClick={onClick(true)}
        >
          PLAN DE PAGOS
        </Button>

        <CustomBoxForState
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={0.5}
          mt={1.5}
          currentState={propertyHasPaymentPlan ? 'completed' : 'pending'}
        >
          <Typography variant="body">
            {propertyHasPaymentPlan ? 'Completo' : 'Pendiente'}
          </Typography>
        </CustomBoxForState>
        <QuotationsList
          property={currentPropertyInfo}
          clientId={currentClient.clientId}
          propertyId={currentClient.propertyId}
          currentQuotation={
            propertyHasPaymentPlan ? propertyHasPaymentPlan.id : undefined
          }
          customOpenState={isOpen}
          customCloseHandler={() => {
            reloadProperty()
            onClick(false)()
          }}
        />
      </>
    )
  );
};

export default BuyerCustomerPaymentPlanState;
