import { Tabs as MuiTabs } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { TabsProps } from '../../interfaces/Tabs.types';
import styles from './Tabs.module.scss';

const Tabs: React.FC<TabsProps> = ({ className, ...restProps }) => (
  <MuiTabs className={clsx(styles.root, className)} {...restProps} />
);

export default Tabs;
