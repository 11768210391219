import { API_PATH } from '../../../config/config';

const SalesEvalproServicesDefinitions = {
  putGroup: (towerId) => {
    return `${API_PATH}salesEvalpro/${towerId}`;
  },

  putGroupsOrder: (towerId) => {
    return `${API_PATH}salesEvalpro/groupsOrder/${towerId}`;
  },

  destroyGroup: (groupId) => {
    return `${API_PATH}salesEvalpro/${groupId}`;
  },

  putAdditional: (towerId) => {
    return `${API_PATH}salesEvalpro/additional/${towerId}`;
  },

  getSales: (towerId) => {
    return `${API_PATH}salesEvalpro/${towerId}`;
  },

  getTowers: (towerId) => {
    return `${API_PATH}salesEvalpro/towers/${towerId}`;
  },

  getIncrementSuggestion: (groupId, ear, frequency) => {
    return `${API_PATH}salesEvalpro/groups/${groupId}/ear/${ear}/frequencies/${frequency}`;
  },
};

export default SalesEvalproServicesDefinitions;
