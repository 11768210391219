import React from 'react';
import { Box } from '@material-ui/core';
import ArrearsReportTotalCell from '../cell/ArrearsTotalSummaryReport';
import useArrearsTotalSummaryForWallet from '../../../Core/customHooks/useArrearsTotalSummaryForWallet';

const ArrearsReportTotalTable = () => {
  const {
    commitments,
    payments,
    wallet,
    arrearsPercent,
  } = useArrearsTotalSummaryForWallet();
  return (
    <Box p={1} pt={5}>
      <ArrearsReportTotalCell
        title="Recaudos Programados"
        value={commitments}
        isPercentage={false}
      />
      <ArrearsReportTotalCell
        title="Pagos Realizados"
        value={payments}
        isPercentage={false}
      />
      <ArrearsReportTotalCell
        title="Cartera"
        value={wallet}
        isPercentage={false}
      />
      <ArrearsReportTotalCell
        title="% Cartera Mora"
        value={arrearsPercent}
        isPercentage={true}
      />
    </Box>
  );
};

export default ArrearsReportTotalTable;
