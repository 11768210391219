import React from 'react';

import Icon from 'shared/components/Icon/Icon';
import IconButton from 'shared/components/IconButton/IconButton';
import useUpdateFeatureFieldOption from '../../../core/customHooks/useUpdateFeatureFieldOption';
import { FeatureFieldOptionInputProps } from '../../../core/interfaces/FeatureFieldOptionInput.types';
import FeatureFieldColorInput from './FeatureFieldColorInput';

import styles from './FeatureFieldOptionInput.module.scss';

const FeatureFieldOptionInput: React.FC<FeatureFieldOptionInputProps> = ({
  option,
  index,
  disabled,
  onChange,
  onDelete,
  onDragStart,
  onDragEnter,
  onDragOver,
  onDrop,
  onDragEnd,
}) => {
  const { handleChangeColor, handleChangeValue } = useUpdateFeatureFieldOption({ option, onChange });

  return (
    <div
      className={styles.root}
      data-index={index}
      draggable
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
    >
      <Icon className={styles.dragIcon} variant="Drag" />
      <FeatureFieldColorInput value={option.color} disabled={disabled} onChange={handleChangeColor} />
      <input
        className={styles.textInput}
        value={option.value}
        disabled={disabled}
        placeholder="Escribe una opción"
        onChange={handleChangeValue}
      />
      <IconButton
        className={styles.closeButton}
        iconName="Clear"
        variant="ghost"
        size="xsmall"
        disabled={disabled}
        onClick={onDelete}
      />
    </div>
  );
};

export default FeatureFieldOptionInput;
