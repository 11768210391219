import Services from '../../../services/services';
import ForgotPasswordServicesDefinition from './ForgotPasswordServicesDefinition';

class ForgotPassword extends Services { 
  forgotPassword(email) {
    return this.post(
      ForgotPasswordServicesDefinition.forgotPassword(),
      { email }
    );
  }

  changePassword(password, confirmPassword, recoveryToken) {
    return this.put(
      ForgotPasswordServicesDefinition.changePassword(),
      {
        password,
        confirmPassword,
        recoveryToken
      }
    );
  }
}

export default ForgotPassword;