import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import Typography from 'shared/components/Typography/Typography';
import styles from './EvalproDeedExpensesDeedMonthsInfoBanner.module.scss';
import { Numbers } from 'helpers';

const EvalproDeedExpensesDeedMonthsInfoBanner: FC<{ units: number; deedValue: number }> = ({ units, deedValue }) => {
  return (
    <Box
      className={styles.InfoBanner}
      width="100%"
      height="92px"
      padding="20px"
      display="flex"
      justifyContent="center"
      marginY="20px"
    >
      <Box minWidth="200px">
        <Box>
          <Typography variant="headline6">{units}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">En inventario</Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography variant="headline6">
            <NumberFormat displayType="text" value={Numbers.toFixed(deedValue, 0)} thousandSeparator prefix="$" />
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">Valor de escrituraciones</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EvalproDeedExpensesDeedMonthsInfoBanner;
