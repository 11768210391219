import { AreaService } from 'App/Area/Services/AreaService';
import { useMutation } from 'react-query';
import { AreaSaveBulkPayload } from '../interfaces/AreaSaveBulkPayload';
import { IAreaCustomColumn } from '../interfaces/IAreaCustomColumn';
import IPropertyArea from '../interfaces/IPropertyArea';

export enum AreaTypeStates {
  NEW = 'NEW',
  REMOVED = 'REMOVED',
  CHANGED = 'CHANGED',
  NONE = 'NONE',
}

const service = new AreaService();

const useAreasSaveChanges = (
  towerId: string,
  onSuccess: () => Promise<void>,
  onError: (error: unknown) => Promise<void>,
) => {
  const { mutate } = useMutation((payload: AreaSaveBulkPayload) => service.saveBulk(towerId, payload), {
    onSuccess,
    onError,
  });

  const saveChanges = (propertiesUpdated: IPropertyArea[], customColumns: IAreaCustomColumn[]) => {
    const propertyAreas = propertiesUpdated
      .map((property) => ({
        propertyId: property.id,
        areas:
          property.hasChanges?.map((key) => ({
            key,
            value: property[key],
          })) ?? [],
      }))
      .filter((property) => property.areas.length);
    const areaTypeStates = customColumns.map((column) => ({
      id: column.id,
      name: column.name,
      state: column.state,
    }));
    if (propertyAreas.length || areaTypeStates.length) mutate({ propertyAreas, areaTypeStates });
  };

  return {
    saveChanges,
  };
};

export default useAreasSaveChanges;
