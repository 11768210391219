export const SET_ARREARS_FOR_WALLET_REPORT = 'SET_ARREARS_FOR_WALLET_REPORT';

export const updateArrearsForWalletReport = (totalSummary) => ({
  type: SET_ARREARS_FOR_WALLET_REPORT,
  payload: totalSummary,
});

export const SET_ARREARS_REPORT_CLIENTS = 'SET_ARREARS_REPORT_CLIENTS';

export const updateGeneralWallet = (clients) => ({
  type: SET_ARREARS_REPORT_CLIENTS,
  payload: clients,
});
