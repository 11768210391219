import React, { FC } from 'react';
import { Button } from '../../../shared/components/Button/Button';
import useResetCacheService from './core/useResetCacheService';

interface RestCacheButtonProps {
  children: React.ReactNode;
  callback: () => void;
}

const RestCacheButton: FC<RestCacheButtonProps> = ({ children, callback }) => {
  const { onClick } = useResetCacheService(callback);

  return (
    <Button
      variant="outlined"
      style={{
        margin: '0 10px',
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default RestCacheButton;
