import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'shared/components/Button/Button';
import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import SalesStatusStates from '../../../core/enums/SalesStatusStates';

import styles from './SalesStatusNoDataView.module.scss';

const messages = {
  [SalesStatusStates.NoScheme]: 'Debes crear el esquema y las áreas de la obra, para continuar.',
  [SalesStatusStates.NoAreas]: 'Debes completar las áreas de la obra, para continuar.',
};

const getConfigPath = (section: string) => (towerId: string) => `/dashboard/${section}/${towerId}`;

const configPathGetters = {
  [SalesStatusStates.NoScheme]: getConfigPath('building'),
  [SalesStatusStates.NoAreas]: getConfigPath('areas'),
};

const ArrowForwardIcon = <Icon variant="ArrowForward" width="1.25rem" height="1.25rem"></Icon>;

const buttonTexts = {
  [SalesStatusStates.NoScheme]: 'Ir a esquemas',
  [SalesStatusStates.NoAreas]: 'Ir a áreas',
};

type Props = {
  viewState: SalesStatusStates;
  towerId: string;
};

const SalesStatusNoDataView: React.FC<Props> = ({ viewState, towerId }) => (
  <div className={styles.root}>
    <Typography className={styles.message} variant="subtitle1" as="p">
      {messages[viewState]}
    </Typography>
    <Link to={configPathGetters[viewState](towerId)}>
      <Button className={styles.button} variant="contained" size="small" endIcon={ArrowForwardIcon}>
        {buttonTexts[viewState]}
      </Button>
    </Link>
  </div>
);

export default SalesStatusNoDataView;
