import React, { memo, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from '../../UI2/Loader';
import {
  fetchQuotationStart,
  fetchQuotationSuccess,
  fetchQuotationFailure,
} from './actions';
import reducer, { initialState } from './reducer';
import QuotationsServices from '../../../services/Quotations';
import QuotationViewerDialog from '../../Client2/List/Row/QuotationViewer/Dialog';
import {
  validateMissingPrice,
  validateRealPriceAndCurrentOne,
} from '../../Client2/List/Row/QuotationViewer/Dialog/helpers/customHooks/useAssignmentQuotation';

const services = new QuotationsServices();

const Dialog = ({
  open,
  closeHandler,
  quotationData,
  spawnMessage,
  towerId,
  fetchUserList,
  openQuotationListHandler,
}) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const putQuotation = async (quotation) => {
    const { isError, message } = validateRealPriceAndCurrentOne(quotation);
    try {
      validateMissingPrice(quotation);
      if (isError) {
        throw new Error(message);
      }
      dispatch(fetchQuotationStart());
      await services.putQuotationToPermanent(quotation.id, {
        ...quotation,
        finalFee: quotation.finalPayment,
        reservationDeposit: quotation.reservePercentage,
        distribution: quotation.distribution.map(Number),
        initialDate: quotation.initialDate,
        finalPaymentType: Number(quotation.finalPaymentType),
        paymentTypes: quotation.paymentTypes.map(Number),
      });
      spawnMessage('Se agregó correctamente la cotización', 'success');
      closeHandler();
      fetchUserList();
    } catch (error) {
      spawnMessage(error.message, 'error');
    }
  };

  const deleteQuotation = async () => {
    try {
      dispatch(fetchQuotationStart());
      if (!store.error) {
        await services.deleteQuotation(store.quotation.id);
        spawnMessage('Se canceló correctamente la cotización', 'info');
      }
      closeHandler();
    } catch (error) {
      dispatch(fetchQuotationFailure());
      spawnMessage(
        `Hubo un problema cancelando la cotización ${error.message}`,
        'error',
      );
    }
  };

  useEffect(() => {
    let active = true;
    async function fetchQuotation() {
      try {
        dispatch(fetchQuotationStart());
        const response = await services.postTempQuotation(
          quotationData,
          towerId,
        );

        if (active) {
          const newQuotation = response.data;
          dispatch(
            fetchQuotationSuccess({
              ...newQuotation,
              temporalPriceWithAdditionalAreas:
                newQuotation.priceWithAdditionalAreas,
              temporalAdditionalAreas:
                newQuotation.propertyWithAreas.additionalAreas,
              temporalAdditionalAreasPrice:
                newQuotation.propertyWithAreas.additionalAreasPrice,
              temporalBasePrice:
                newQuotation.propertyWithAreas.basePriceValidated,
              temporalDiscount: newQuotation.discount,
              isLockedType: true,
            }),
          );
        }
      } catch (error) {
        spawnMessage(error.message, 'error');
        dispatch(fetchQuotationFailure());
      }
    }
    if (open) {
      fetchQuotation();
    }
    return () => {
      active = false;
    };
  }, [open]);

  return (
    <MuiDialog open={open} fullScreen>
      <DialogTitle>Cotización</DialogTitle>
      <DialogContent>
        <Loader isLoading={store.loading}>
          {store.error ? (
            <DialogContentText>
              Ha ocurrido un error obteniendo la cotización, cierre el modal e
              inténtelo de nuevo. 👁 Asegurese de que tiene tipos de pago
              configurados.
            </DialogContentText>
          ) : (
            <>
              <QuotationViewerDialog
                id={store.quotation.id}
                tempQuotation={store.quotation}
                open={open}
                closeHandler={() => {
                  closeHandler();
                  deleteQuotation();
                }}
                onSave={putQuotation}
                fetchUserList={fetchUserList}
                openQuotationListHandler={openQuotationListHandler}
                clientId={quotationData.clientId}
                propertyId={quotationData.propertyId}
              />
            </>
          )}
        </Loader>
      </DialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  quotationData: PropTypes.shape({
    propertyId: PropTypes.number,
    identityDocument: PropTypes.string,
    propertyPrice: PropTypes.number,
  }),
  spawnMessage: PropTypes.number,
  towerId: PropTypes.string.isRequired,
  additionalData: PropTypes.object,
  fetchUserList: PropTypes.func,
  openQuotationListHandler: PropTypes.func,
};

export default memo(Dialog);
