import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import React, { FC, ReactChild } from 'react';
import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import Styles from './MonthlyInterestSummaryPayment.module.scss';

interface MonthlyInterestSummaryPayment {
  children: ReactChild;
  title: string;
}

const MonthlyInterestSummaryPayment: FC<MonthlyInterestSummaryPayment> = ({ title, children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<Icon variant="icon-keyboard_arrow_down" />} className={Styles.PaymentSummary}>
        <Typography variant="subtitle1" color="gray900">
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default MonthlyInterestSummaryPayment;
