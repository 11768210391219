import { Container, Grid } from '@material-ui/core';
import React, { FC } from 'react';
import Typography from 'shared/components/Typography/Typography';
import styles from './QuotationConfigHeader.module.scss';

const QuotationConfigHeader: FC = () => (
  <header className={styles.Header}>
    <Container maxWidth="md">
      <Grid container>
        <Grid item md={12}>
          <Typography variant="headline4">Configuración Cotizaciones</Typography>
          <br />
          <Typography variant="subtitle1" color="gray600">
            Configura las fechas para cada etapa administrativa.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </header>
);

export default QuotationConfigHeader;
