import React, { FC } from 'react';
import styled from 'styled-components';
import { TableHeaderRow, VirtualTable, Table } from '@devexpress/dx-react-grid-material-ui';

const CustomRow: React.FC = styled(VirtualTable.Row)<Table.RowProps>`
  border: 0px;
  height: 2.188rem;
  padding: 0px;
`;

const MonthlyInterestHeaderRow: FC<Table.RowProps> = (props) => {
  return <CustomRow {...props} />;
};

export default MonthlyInterestHeaderRow;
