import React, { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import QuotationConfigQueryClient from '../../core/QuotationConfigQueryClient';
import QuotationConfigHeader from '../sections/header/QuotationConfigHeader';
import QuotationConfigMain from '../sections/main/QuotationConfigMain';

const QuotationConfigMainView: FC = () => {
  return (
    <QueryClientProvider client={QuotationConfigQueryClient}>
      <QuotationConfigHeader />
      <QuotationConfigMain />
    </QueryClientProvider>
  );
};

export default QuotationConfigMainView;
