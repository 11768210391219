import { Box, Grid } from '@material-ui/core';
import { InsertInvitationOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { FC } from 'react';
import PlusInput from 'shared/components/PlusInput/PlusInput';
import TextField from 'shared/components/TextField/TextField';
import Typography from 'shared/components/Typography/Typography';
import GridContent from '../DeedExpensesGridContent/DeedExpensesGridContent';

interface Props {
  title: string;
  displacementLabel: string;
  displacementLabelInfo?: string;
  displacementDateLabel: string;
  firstDate: number;
  displacement: number;
  isDisabled?: boolean | undefined;
  onChange: (value: number) => void;
}

const DeedExpensesDisplacementDate: FC<Props> = ({
  title,
  displacementLabel,
  displacementLabelInfo,
  displacementDateLabel,
  firstDate,
  displacement,
  isDisabled,
  onChange,
}) => {
  return (
    <Grid container>
      <GridContent gridProps={{ xs: 8 }}>
        <InsertInvitationOutlined />
        <Typography variant="body1" fontWeight="bold" style={{ marginLeft: '6px' }}>
          {title}
        </Typography>
      </GridContent>
      <GridContent gridProps={{ xs: 4 }}>
        <Typography variant="body1" fontStyle="italic" style={{ textTransform: 'capitalize' }}>
          {moment(Number(firstDate)).format('MMM-YYYY').replace('.-', ', ')}
        </Typography>
      </GridContent>
      <GridContent gridProps={{ xs: 8 }} paddingTop="20px">
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="body1" fontWeight="bold">
            {displacementLabel}
          </Typography>
          {displacementLabelInfo && (
            <>
              <Typography variant="body2">{displacementLabelInfo}</Typography>
            </>
          )}
        </Box>
      </GridContent>
      <GridContent gridProps={{ xs: 4 }} paddingTop="20px">
        <PlusInput value={displacement ?? 0} onChange={onChange} isDisabled={isDisabled} />
      </GridContent>
      <GridContent gridProps={{ xs: 8 }} paddingTop="16px">
        <Typography variant="body1" fontWeight="bold">
          {displacementDateLabel}
        </Typography>
      </GridContent>
      <GridContent gridProps={{ xs: 4 }} paddingTop="16px">
        <TextField
          disabled
          value={moment(Number(firstDate))
            .add(displacement ?? 0, 'M')
            .format('MMM-YYYY')}
        />
      </GridContent>
    </Grid>
  );
};

export default DeedExpensesDisplacementDate;
