import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack';

const useSnackbarForTotalCashFlow = ({ clientWithoutQuotation, speedSalesIsMissing }) => {
  const { enqueueSnackbar } = useSnackbar();

  const snackbarAlert = () => {
    if (clientWithoutQuotation) {
      enqueueSnackbar('Existen compradores que no poseen forma de pago', { variant: 'error' })
    } 
    if (speedSalesIsMissing) {
      enqueueSnackbar('Existen compradores que no poseen forma de pago', { variant: 'error' })
    }
  }

  useEffect(() => {
    snackbarAlert()
  }, [clientWithoutQuotation, speedSalesIsMissing])

}
export default useSnackbarForTotalCashFlow