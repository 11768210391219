import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Card, { CardHeader, CardBody } from '../../UI/Card/Card';
import Input from '../../UI/Input/Input';
import Styles from './InitialFees.module.scss';

function InitialFees({ firstSale, maximumCollectionDate, firstSaleHandler, isAuthorizedForEdit }) {
  return (
    <Card>
      <CardHeader>
        <span>Plazos cuota inicial</span>
      </CardHeader>
      <CardBody>
        <div className={Styles.Container}>
          <span className={Styles.Item}>Plazo primer comprador:</span>
          { isAuthorizedForEdit ? <Input
            className={Styles.Item}
            validations={[]}
            value={firstSale}
            onChange={(target) => {
              firstSaleHandler(target.value);
            }}
          /> : <span className={`${Styles.Item} ${Styles.Deadline}`}>{firstSale}</span> }
          <span className={Styles.Item}>Plazo hoy:</span>

          <span className={`${Styles.Item} ${Styles.Deadline}`}>
            {moment(Number(maximumCollectionDate))
              .startOf('month')
              .diff(moment().startOf('month'), 'months') + 1}
          </span>
        </div>
      </CardBody>
    </Card>
  );
}

InitialFees.propTypes = {
  firstSale: PropTypes.number,
  salesStartDate: PropTypes.number,
  firstSaleHandler: PropTypes.func,
  maximumCollectionDate: PropTypes.number,
};

InitialFees.defaultProps = {
  firstSale: 0,
  salesStartDate: 0,
  firstSaleHandler: () => null,
};

export default InitialFees;