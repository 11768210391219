import { API_PATH } from '../../../config/config';

const DocumentTemplatesServicesDefinition = {
  getTemplates: (towerId) => {
    return `${API_PATH}documents/${towerId}/documentTemplates`
  },
  deleteTemplate: (id) => {
    return `${API_PATH}documents/documentTemplate/${id}`
  },
  uploadTemplate: (towerId, name) => {
    return `${API_PATH}documents/${towerId}/documentTemplate/${escape(name)}`
  }
}

export default DocumentTemplatesServicesDefinition;