import React from 'react';
import {
  Box,
  CircularProgress,
  fade,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import useCRMList from '../customHooks/useCRMList';
import CRMFieldAccordion from './CRMFieldAccordion';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  wrapperSpinner: {
    background: fade(theme.palette.primary.main, 0.1),
    borderRadius: '4px',
    display: 'flex',
    minHeight: '200px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  messageOpacity: {
    opacity: '0.7',
  },
}));

const CRMList = () => {
  const {
    questionsOne,
    closingReasons,
    updateField,
    removeField,
    createField,
    hubspotIsEnabled,
    loading,
    crmFieldTypes,
  } = useCRMList(true);

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.wrapper}>
      {!loading ? (
        <Box>
          <CRMFieldAccordion
            title="Motivo de cierre del negocio"
            createField={createField}
            hubspotIsEnabled={hubspotIsEnabled}
            removeField={removeField}
            updateField={updateField}
            values={closingReasons}
            type={crmFieldTypes.BUSINESS_CLOSING_REASON.code}
          />
          <CRMFieldAccordion
            title="¿Cómo se enteró del proyecto?"
            createField={createField}
            hubspotIsEnabled={hubspotIsEnabled}
            removeField={removeField}
            updateField={updateField}
            values={questionsOne}
            type={crmFieldTypes.QUESTION_1.code}
          />
        </Box>
      ) : null}
      {loading ? (
        <Box className={classes.wrapperSpinner}>
          <CircularProgress />
          {hubspotIsEnabled ? (
            <Box className={classes.messageOpacity} mt={2}>
              <Typography>Cargando datos desde Hubspot</Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Grid>
  );
};

export default CRMList;
