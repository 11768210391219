import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export default function useGraphicMaterialRowsMapper() {
  const { properties } = useSelector((state) => state.salesRoom.root);
  const rowsFiltered = properties
    .flatMap((property) => property);

  const rows = _.sortBy(rowsFiltered, ['floor', 'location'], ['asc', 'asc']);

  const columns = [
    { text: 'Número de apartamento', align: 'center' },
    { text: 'Grupo', align: 'inherit' },
    { text: 'Piso', align: 'inherit' },
    { text: '', align: 'inherit' },
    { text: '', align: 'inherit' },
  ];
  return { rows, columns };
}
