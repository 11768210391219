import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Input from '../../components/UI/Input/Input';
import Locations from '../../components/Primes/Locations';
import Altitudes from '../../components/Primes/Altitudes';
import PrimeServices from '../../services/prime/PrimeServices';
import SchemeServices from '../../services/schema/SchemaServices';
import FloatingButton from '../../components/UI/FloatingButton/FloatingButton';
import LoadableContainer from '../../components/UI/Loader';
import withDefaultLayout from '../../HOC/Layouts/Default/withDefaultLayout';

class Prime extends Component {
  constructor(props) {
    super(props);
    this.services = new PrimeServices(this);
    this.schemaServices = new SchemeServices();
  }

  state = {
    altitude: {
      unit: {},
      prices: [[]],
    },
    location: {
      unit: {},
      prices: [[]],
    },
    floorsNames: [],
    showFloatingButton: false,
    lowestFloor: 0,
    disabledProp: false,
    headers: [],
    verticalHeaders: [],
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const { towerId } = this.props.match.params;
    if (!towerId) {
      return;
    }
    this.schemaServices
      .getSchema(this.props.match.params.towerId)
      .then((response) => {
        if (response.data.length !== 0) {
          const { schemaHeader, verticalSchemaHeader } = response.data;
          this.setState({
            headers: schemaHeader,
            verticalHeaders: verticalSchemaHeader,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.services
      .isDisable(this.props.match.params.towerId)
      .then((response) => {
        this.setState({ disabledProp: response.data });
      })
      .catch((err) => {
        console.log(err);
      });

    this.services.getAltitudePrimes(towerId).then((response) => {
      if (response.data.primes.length !== 0) {
        this.setState({
          lowestFloor: response.data.primes[0].tower.lowestFloor,
        });
      }

      const floorsNames = [];

      const altitude = { ...this.state.altitude };
      altitude.prices = response.data.primes;
      altitude.unit = response.data.unit;

      response.data.primes.forEach((element) => {
        floorsNames.push(element.reference);
      });
      const showFloating = response.data.primes.find((prime) => {
        return prime !== null && prime.price !== 0;
      });
      if (showFloating !== undefined) {
        this.setState({ showFloatingButton: true, isLoading: false });
      }
      this.setState({
        floorsNames,
        altitude,
        isLoading: false,
      });
    });
    this.getLocationPrimes();
  }

  getLocationPrimes = () => {
    this.setState({ isLoading: true });
    const { towerId } = this.props.match.params;
    this.services.getLocationPrimes(towerId).then((response) => {
      const location = { ...this.state.location };
      location.prices = response.data.primes;
      location.unit = response.data.unit;
      this.setState({ location });
      const showFloating = response.data.primes.find((arrayPrimes) => {
        const anyPrime = arrayPrimes.find((prime) => {
          return prime !== null && prime.price !== 0;
        });
        return anyPrime !== undefined;
      });
      if (showFloating !== undefined) {
        this.setState({ showFloatingButton: true, isLoading: false });
      }
    });
  };

  getInputs(type) {
    if (type === 'ALT') {
      const inputs = this.state.altitude.prices.map((prime) => [
        <Input
          key={`prime-${prime && prime.id}`}
          mask="currency"
          style={{ width: '75px' }}
          validations={[]}
          zeroDefault={true}
          onChange={(target) => {
            this.priceHandler('ALT', prime.id, parseInt(target.value, 10));
          }}
          value={prime.price}
          disable={this.state.disabledProp}
        />,
      ]);
      return inputs;
    }
    if (type === 'LCT') {
      const inputs = this.state.location.prices.map((primes) =>
        primes.map((prime) => {
          if (prime) {
            return (
              <Input
                mask="currency"
                style={{ width: '75px' }}
                value={prime.price}
                validations={[]}
                onChange={(target) => {
                  this.priceHandler(
                    'LCT',
                    prime.id,
                    parseInt(target.value, 10),
                  );
                }}
                placeholder={prime.name}
                tooltip={prime.name}
                disable={this.state.disabledProp}
              />
            );
          }
          return (
            <Input
              style={{ width: '75px' }}
              placeholder="-"
              disable
              key={`prime-LCT-${prime && prime.id}`}
            />
          );
        }),
      );
      return inputs;
    }
  }

  priceHandler(type, id, price) {
    if (type === 'ALT') {
      this.services
        .putAltitudePrimesById(id, {
          price: parseInt(price, 10),
        })
        .then((data) => {
          console.log(data);
          this.setState({ showFloatingButton: true });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === 'LCT') {
      this.services
        .putLocationPrimesById(id, {
          price: parseInt(price, 10),
        })
        .then((data) => {
          console.log(data);
          this.setState({ showFloatingButton: true });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  unitHandler = (type, value) => {
    if (type === 'ALT') {
      this.services
        .putAltitudePrimeUnit({
          towerId: this.props.match.params.towerId,
          unit: value,
        })
        .then((response) => {
          const altitude = { ...this.state.altitude };
          altitude.unit = response.data;
          this.setState({ altitude });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === 'LCT') {
      this.services
        .putLocationPrimeUnit({
          towerId: this.props.match.params.towerId,
          unit: value,
        })
        .then((response) => {
          const location = { ...this.state.location };
          location.unit = response.data;
          this.setState({ location });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <LoadableContainer isLoading={this.state.isLoading}>
        <Altitudes
          unitHandler={this.unitHandler}
          unit={this.state.altitude.unit}
          floorsNames={this.state.floorsNames}
          prices={this.getInputs('ALT')}
        />
        <Locations
          unitHandler={this.unitHandler}
          headers={
            this.state.headers ||
            [
              ...Array(
                _.defaultTo(this.state.location.prices[0], []).length,
              ).keys(),
            ].map((o) => o + 1)
          }
          verticalHeaders={this.state.verticalHeaders}
          floorsNames={this.state.floorsNames}
          prices={this.getInputs('LCT')}
          unit={this.state.location.unit}
          towerId={this.props.match.params.towerId}
          reloadPrimes={this.getLocationPrimes}
          alertHandler={this.props.spawnMessage}
          lowestFloor={this.state.lowestFloor}
          disabledProp={this.state.disabledProp}
        />
        {this.state.showFloatingButton ? (
          <FloatingButton
            route="summary"
            projectId={this.props.match.params.projectId}
            towerId={this.props.match.params.towerId}
          >
            Resumen
          </FloatingButton>
        ) : null}
      </LoadableContainer>
    );
  }
}

export default withDefaultLayout(Prime);
