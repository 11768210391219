import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import clsx from 'clsx';
import Styles from './CustomButton.module.scss';

type ReactButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type ButtonProps = Omit<ReactButtonProps, 'type'>;
type ButtonType = Pick<ReactButtonProps, 'type'>;

interface CustomButtonProps extends ButtonProps {
  type: 'primary' | 'secondary' | 'selectedSecondary';
  buttonType?: ButtonType;
}

const CustomButton: FC<CustomButtonProps> = (props) => {
  const { type, ...buttonWithButtonType } = props;
  const { buttonType, ...buttonWithOutType } = buttonWithButtonType;
  const buttonProps: ReactButtonProps = {
    ...buttonWithOutType,
    type: buttonType?.type,
  }
  
  return (
    <div>
      <button
        className={clsx({
          [Styles.primary]: type === 'primary',
          [Styles.secondary]: type === 'secondary',
          [Styles.selectedSecondary]: type === 'selectedSecondary',
        })}
        {...buttonProps}
        type={buttonType?.type}
      >
        {props.children}
      </button>
    </div>
  );
};

export default CustomButton;
