import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Numbers } from '../../../../../helpers';

const ArrearsDailyReportRow = ({ title, amount, value, percentage }) => {
  return (
    <Grid item container spacing={1}>
      <Grid item xs={4}>
        <Typography>
        {title}
        </Typography>
      </Grid>
      <Grid item xs={2}>
      <Typography align="center">
        {amount}
      </Typography>
      </Grid>
      <Grid item xs={4}>
      <Typography>

        {Numbers.currencyFormat(value)}
      </Typography>
      </Grid>
      <Grid item xs={2}>
      <Typography align="right">

        {Numbers.toFixed(percentage, 1, true)}
      </Typography>
      </Grid>
    </Grid>
  );
};

ArrearsDailyReportRow.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number,
  value: PropTypes.number,
  percentage: PropTypes.number,
};

export default ArrearsDailyReportRow;
