import { ITabOption } from '../interfaces/ITabOption';

export const GENERAL = 'GENERAL';
export const DETAIL = 'DETAIL';

export const TOTAL_CASH_FLOW_TABS: ITabOption[] = [
  {
    id: GENERAL,
    label: 'General',
    selected: true,
  },
  {
    id: DETAIL,
    label: 'Detalle',
  },
];
