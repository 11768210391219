import { Column } from '@devexpress/dx-react-grid';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import VirtualTableDataTypeProviderColumn from 'shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from 'shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';

type ColumnType = VirtualTable.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

const quotationSummaryColumns: ColumnType[] = [
  {
    width: 145,
    title: 'Nº Cuota',
    columnName: 'eventName',
    name: 'eventName',
  },
  {
    width: 120,
    title: 'Fecha',
    columnName: 'date',
    name: 'date',
    type: ProviderColumnType.dateFormat,
  },
  {
    width: 170,
    title: 'Compromiso',
    columnName: 'commitment',
    name: 'commitment',
    type: ProviderColumnType.currency,
  },
  {
    width: 170,
    title: 'Intereses',
    columnName: 'financial',
    name: 'financial',
    type: [ProviderColumnType.currency, ProviderColumnType.greenPositive],
  },
  {
    width: 170,
    title: 'Valor Pagado',
    columnName: 'valueToPay',
    name: 'valueToPay',
    type: ProviderColumnType.currency,
  },
  {
    width: 170,
    title: 'Pago',
    columnName: 'alreadyPayed',
    name: 'alreadyPayed',
    type: ProviderColumnType.currency,
  },
  {
    width: 170,
    title: 'Saldo',
    columnName: 'Saldo',
    name: 'balance',
    type: [ProviderColumnType.currency, ProviderColumnType.greenNegative, ProviderColumnType.grayFuture],
  },
  {
    width: 130,
    title: 'Días Mora',
    columnName: 'arrearsDays',
    name: 'arrearsDays',
    type: ProviderColumnType.greenNegative,
  },
];

class QuotationSummaryTableDefinitions {
  static columns = quotationSummaryColumns;
}

export default QuotationSummaryTableDefinitions;
