import React from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import usePropertyEditionRowsMapper from '../../../RealPaymentsForProperty/Core/customHooks/useRealPaymentsRowsMapper';
import Styles from './RealPaymentsForPropertyTable.module.scss';
import RealPaymentsForPropertyRow from '../rows/RealPaymentsForPropertyRow';

const useStyles = makeStyles({
  Container: {
    maxHeight: '90vh',
  },
});

const RealPaymentsForPropertyTable = () => {
  const { rows, columns } = usePropertyEditionRowsMapper();
  const styles = useStyles();

  return (
    <TableContainer component={Paper} className={styles.Container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((columnHeader, index) => (
              <TableCell
                key={`columnHeader-${index}`}
                align={columnHeader.align}
                width={columnHeader.width}
                className={Styles.tableRow}
              >
                {columnHeader.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <RealPaymentsForPropertyRow
              key={`RealPaymentsForPropertyRow_${row.id}`}
              payment={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RealPaymentsForPropertyTable;
