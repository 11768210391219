import ClientsServices from '../../../../../services/imports/Imports.services';
import { AreaDownloadTemplateProps } from './AreaDownloadTemplateProps';

const services = new ClientsServices();
const useAreaDownloadTemplate = (props: AreaDownloadTemplateProps) => {
  const onClick = async () => {
    let name = 'Plantilla_Esquema.xlsx';
    const response = await services.getSchemaTemplate(props.towerId);
    const towerRequest = await services.getTowerInfo(props.towerId);
    if (towerRequest.data.name && towerRequest.data.project) {
      const { data } = towerRequest;
      name = `Plantilla de areas torre ${data.name} del proyecto ${data.project}.xlsx`;
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };
  return { onClick };
};

export default useAreaDownloadTemplate;
