import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import { Input } from '@material-ui/core';

const ContractPaymentScheduleGridNumberEditFormatter: React.FC<DataTypeProvider.ValueEditorProps> = ({
  onValueChange,
  value,
  // @ts-ignore
  autoFocus,
  // @ts-ignore
  onBlur,
  // @ts-ignore
  onKeyDown,
}) => {
  return (
    <NumberFormat
      customInput={Input}
      autoFocus={autoFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      value={value}
      thousandSeparator
      fixedDecimalScale
      onValueChange={(values) => {
        onValueChange(values.floatValue);
      }}
      decimalScale={0}
      prefix="$"
      // @ts-ignore
      size="small"
      disableUnderline
    />
  );
};

export default ContractPaymentScheduleGridNumberEditFormatter;
