import moment from 'moment';
import {
  SET_CONSTRUCTION_TYPE,
  SET_BUILDER,
  SET_DATE_COST,
  SET_IPC,
  SET_DATES_BUILD,
  SET_MANUAL_DATES,
  SET_MANUAL_DATES_VALUES,
  SET_ROW_DATA,
  RESET,
} from './actions';
import { towerBuilder } from './autoConstruction/Type/type.enum';

const initialState = {
  constructionType: towerBuilder.MANUAL.code,
  datesBuild: {
    ipc: 0,
    dateCost: null,
    type: towerBuilder.MANUAL.code,
  },
  builderArray: [],
  builder: [],
  dateCost: null,
  ipc: 0,
  manualDates: [],
  manualDatesValues: [],
  rowData: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONSTRUCTION_TYPE:
      return {
        ...state,
        constructionType: payload,
        datesBuild: { ...state.datesBuild, type: payload },
      };

    case SET_DATE_COST:
      return {
        ...state,
        dateCost: payload,
        datesBuild: { ...state.datesBuild, dateCost: payload },
      };

    case SET_IPC:
      return {
        ...state,
        ipc: payload,
        datesBuild: { ...state.datesBuild, ipc: payload },
      };

    case SET_BUILDER:
      return { ...state, builder: payload };

    case SET_DATES_BUILD:
      return { ...state, datesBuild: { ...state.datesBuild, ...payload } };

    case SET_MANUAL_DATES:
      return { ...state, manualDates: payload };

    case SET_MANUAL_DATES_VALUES:
      return { ...state, manualDatesValues: payload };
    case SET_ROW_DATA:
      return { ...state, rowData: payload };
    case RESET:
      return (state = initialState);
    default:
      return state;
  }
};
