enum ProviderColumnType {
  plain = 'plain',
  tooltip = 'tooltip',
  currency = 'currency',
  numeric = 'numeric',
  percent = 'percent',
  semaphore = 'semaphore',
  greenPositive = 'greenPositive',
  greenNegative = 'greenNegative',
  grayFuture = 'grayFuture',
  dateFormat = 'dateFormat',
}

export default ProviderColumnType;
