import React, { useState } from 'react';

const makeDynamicColumns = (currentColumns, dates, hasInnerDate) => {
  const newColumns = dates.map((date) => {
    if (hasInnerDate) return { name: date.date, title: date.date };

    return { name: date, title: date };
  });

  return [...currentColumns, ...newColumns];
};

const isParent = (row) => {
  return row && !row.parentId && !row.subParentId;
};

const subParentId = (row) => {
  return row && row.parentId && !row.subParentId;
};

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((r) => {
    if (isParent(row)) {
      return subParentId(r) && r.parentId === row.id;
    }

    if (subParentId(row)) {
      return r.subParentId === row.id;
    }

    if (r.parentId === undefined) r.parentId = null;
    if (r.subParentId === undefined) r.subParentId = null;
    return r.parentId === row && r.subParentId === row;
  });
  return childRows.length ? childRows : null;
};

function useColumnsForGeneral(salesFlow, initialDate, lastDate) {
  const columnDates = [];

  const initialColumns = [
    { name: 'name', title: 'Categoría' },
    { name: 'accumulated', title: 'Real' },
    { name: 'projected', title: 'Proyectado' },
    { name: 'total', title: 'Total' },
  ];

  let columns = makeDynamicColumns(initialColumns, columnDates, true);
  let dates = [];

  const [tableColumnExtensions] = useState([
    { columnName: 'name', width: 700 },
    { columnName: 'accumulated', width: 150 },
    { columnName: 'projected', width: 150 },
    { columnName: 'total', width: 150 },
  ]);

  const [defaultExpandedRowIds] = useState([]);
  const [leftColumns] = useState(['name']);

  return {
    columns,
    tableColumnExtensions,
    defaultExpandedRowIds,
    leftColumns,
    dates,
  };
}

export default useColumnsForGeneral;
