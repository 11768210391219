import React, { ChangeEvent } from 'react';
import { Getters, Actions } from '@devexpress/dx-react-core';
import FormControl from '@material-ui/core/FormControl';
import Typography from 'shared/components/Typography/Typography';

import { clientStates } from '../../core/enums/table.enum';
import FilterMenuItem from './FilterMenuItem';
import FilterMenuTypography from './FilterMenuTypography';
import FilterSelect from './FilterSelect';
import styles from './GeneralWalletToolbarFilter.module.scss';

type OnChangeEvent = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const GeneralWalletToolbarStateFilter = ({ toolbarFilterValue }: Getters, { changeToolbarFilterValue }: Actions) => {
  const handleOnChange = (event: OnChangeEvent) => {
    changeToolbarFilterValue(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={styles.filterControl}>
      <Typography variant="body1" color="gray700">
        Filtrar por estado
      </Typography>
      <FilterSelect value={toolbarFilterValue} onChange={handleOnChange}>
        <FilterMenuItem value={clientStates.total.id}>
          <FilterMenuTypography>Total</FilterMenuTypography>
        </FilterMenuItem>
        <FilterMenuItem value={clientStates.upToDate.id}>
          <FilterMenuTypography>Al día</FilterMenuTypography>
        </FilterMenuItem>
        <FilterMenuItem value={clientStates.arrears.id}>
          <FilterMenuTypography>Mora</FilterMenuTypography>
        </FilterMenuItem>
      </FilterSelect>
    </FormControl>
  );
};

export default GeneralWalletToolbarStateFilter;
