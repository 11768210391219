import React, { FC } from 'react';
import { Box, MenuItem, MenuList } from '@material-ui/core';
import { TableHeaderRow } from '@devexpress/dx-react-grid';
import { Plugin, Template } from '@devexpress/dx-react-core';
import TableHeaderRowCell from '../../../../../../shared/tables/TableHeaderRowCell';
import AreaHeaderCell from '../../Cells/AreaHeaderCell/AreaHeaderCell';
import {
  AreaHeaderConnectorProps,
  AreaHeaderProps,
  AreaStaticHeaderProps,
} from '../../../../Core/customHooks/AreaEditionTable/AreaHeaderConnectorProps';
import { isNotHeadingTableCell } from '../../../../../../shared/tables/helpers/TableTemplateHelpers';
import AreaTablePopover from '../../AreaHeaderTablePopover/AreaTablePopover';

const AreaHeaderStaticConnector: FC<AreaHeaderConnectorProps> = (props) => {
  const { title, name } = props.tableColumn.column ?? {};
  const total = props.totals && name ? props.totals[name] : 0;
  const areaType = props.areaTypes.find((areaType) => areaType.id == name);
  return (
    <TableHeaderRowCell>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <AreaHeaderCell title={title ?? ''} total={total} />
      </Box>
    </TableHeaderRowCell>
  );
};

const AreaHeaderStaticTableProvider: FC<AreaStaticHeaderProps> = (props) => {
  return (
    <Plugin name="AreaHeaderStaticTableProvider">
      <Template name="tableCell" predicate={isNotHeadingTableCell(props.columnNames)}>
        {(cellProps: TableHeaderRow.CellProps) => <AreaHeaderStaticConnector {...cellProps} {...props} />}
      </Template>
    </Plugin>
  );
};

export default AreaHeaderStaticTableProvider;
