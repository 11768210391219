import { useState } from 'react';
import useFilterSubscribe from './useFilterSubscribe';

function useSliderSelectorUpdater(min, max, name) {
  const [sliderState, setSliderState] = useState([min, max]);
  const { subscribeFilter } = useFilterSubscribe();

  const onChangeSliderState = (_e, values) => {
    setSliderState(values);
    subscribeFilter(name, values);
  };

  return {
    sliderState,
    onChangeSliderState,
  };
}

export default useSliderSelectorUpdater;
