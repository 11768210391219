import React, { FC } from 'react';
import { Box, Grid, FormControl, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import Typography from '../../../../../shared/components/Typography/Typography';
import { ContractPaymentSchedule } from '../../../../../models/ContractPayments';
import { IUseFormResult } from '../../../../../shared/customHooks/useForm';
import TextField from '../../../../../shared/components/TextField/TextField';
import Styles from '../../ContractPaymentScheduleFormMainView.module.scss';

interface IPaymentValue extends Partial<IUseFormResult<ContractPaymentSchedule>> {
  handleChangeValue: <G>(key: keyof ContractPaymentSchedule, value: G) => void;
  currentContractPaymentSchedule: ContractPaymentSchedule | undefined;
  contractPaymentType: string;
}

const PaymentValue: FC<IPaymentValue> = ({ data, errors, contractPaymentType, handleChangeValue }) => {
  return (
    <>
      {(() => {
        switch (contractPaymentType) {
          case 'PX':
            return <Box m={1}></Box>;
          case 'PP':
            return (
              <Box mx={5} my={2}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">Porcentaje</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberFormat
                        customInput={TextField}
                        defaultValue={data?.percentage}
                        value={data?.percentage}
                        onValueChange={(values) => handleChangeValue('percentage', Number(values.value))}
                        label="Porcentaje"
                        variant="outlined"
                        thousandSeparator
                        fixedDecimalScale={true}
                        decimalScale={2}
                        // @ts-ignore
                        size="small"
                        inputProps={{
                          className: Styles.textRight,
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        helperText={errors?.percentage}
                        error={!!errors?.percentage}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            );
          case 'PC':
            return (
              <Box mx={5} my={2}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">Valor</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumberFormat
                        customInput={TextField}
                        defaultValue={data?.amount}
                        value={data?.amount}
                        onValueChange={(values) => handleChangeValue('amount', Number(values.value))}
                        label="Valor"
                        variant="outlined"
                        thousandSeparator
                        fixedDecimalScale={true}
                        decimalScale={0}
                        // @ts-ignore
                        size="small"
                        inputProps={{
                          className: Styles.textRight,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        helperText={errors?.amount}
                        error={!!errors?.amount}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            );
        }
      })()}
    </>
  );
};

export default PaymentValue;
