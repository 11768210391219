export const SET_NEW_PARTNER = 'SET_NEW_PARTNER';
export const SET_PARTNERS = 'SET_PARTNERS';
export const SET_SELECTED_PARTNER = 'SET_SELECTED_PARTNER';
export const SET_HANDLE_CHANGE_NEW_PARTNER = 'SET_HANDLE_CHANGE_NEW_PARTNER';
export const SET_COMPANIES_TO_SELECT = 'SET_COMPANIES_TO_SELECT';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';

export const onSetNewPartnerField = (payload) => ({
  type: SET_NEW_PARTNER,
  payload,
});

export const onSetAllPartners = (payload) => ({
  type: SET_PARTNERS,
  payload,
});

export const onSetPartnerSelected = (payload) => ({
  type: SET_SELECTED_PARTNER,
  payload,
});

export const onSetChangeOfPartner = (payload) => ({
  type: SET_HANDLE_CHANGE_NEW_PARTNER,
  payload,
});

export const onSetCompaniesToSelect = (payload) => ({
  type: SET_COMPANIES_TO_SELECT,
  payload,
});

export const onSetCompanySelected = (payload) => ({
  type: SET_SELECTED_COMPANY,
  payload,
});
