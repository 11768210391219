import Services from '../../../services/services';
import BuildingServicesDefinition from './BuildingServicesDefinition';

class BuildingServices extends Services {
  setHeaders(towerId, data) {
    return this.put(BuildingServicesDefinition.setHeaders(towerId), data);
  }
}

export default BuildingServices;
