import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import Settings from '../../components/StrategyV2/Settings';
import Widgets from '../../components/StrategyV2/Widgets';
import Overviews from '../../components/StrategyV2/Overviews';
import APILoader from '../../components/StrategyV2/Loader';
import Loader from '../../components/UI2/Loader';
import Message from '../../components/StrategyV2/Message/Message';

import {
  fetchDataInit,
  fetchDataStart,
  fetchDataEmpty,
  changeGroup,
} from './actions';
import IncrementServices from '../../services/incrementsV2/incrementsService';
import generateDataset from './helpers/dataset';
import InventorySalesSepeedModal from '../../components/StrategyV2/Overviews/InventoryOverview/SalesSpeedModal/InventorySalesSepeedModal';
import ManualSalesSpeedModal from '../../components/StrategyV2/Overviews/InventoryOverview/manualSalesSpeedModal/ManualSalesSpeedModal';
import validateSelectedGroup from '../../components/StrategyV2/Shared/Validator';
import useUpdateStrategyGroups from './customHooks/useUpdateStrategyGroups';

const services = new IncrementServices();
const Strategy = ({
  onFetchedData,
  onFetchedDataStart,
  onFetchedDataEmpty,
  loading,
  validGroup,
  onUpdateGroup,
  isEmpty,
  view,
  isReset,
}) => {
  const { towerId } = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    activateBadgeIncrementWithGroups,
    isBadge,
  } = useUpdateStrategyGroups();

  useEffect(() => {
    async function fetch() {
      try {
        onFetchedDataStart();
        const response = await services.getIncrementsAndStrategy(towerId);
        if (response.data.length === 0) {
          onFetchedDataEmpty();
        } else {
          onFetchedData({
            strategyLines: generateDataset(response.data.increments),
            groups: response.data.summary.increments,
          });
          const incrementValidated =
            response.data.summary.increments[validGroup];
          const firstGroup = Object.keys(
            response.data.summary.increments || {},
          )[0];
          if (!incrementValidated && firstGroup) {
            onUpdateGroup(firstGroup);
          }
          activateBadgeIncrementWithGroups(response.data.summary.increments);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    fetch();
  }, [towerId, location]);

  return (
    <Loader isLoading={loading}>
      {isEmpty ? (
        <Message />
      ) : (
        <div>
          <Box mb={2}>
            <APILoader />
          </Box>
          <Box pb={2}>
            <Settings />
            {isBadge && (
              <Typography variant="h5" color="secondary">
                👀 ¡Oye ten cuidado! Hay grupos sin estrategia seleccionada
              </Typography>
            )}
          </Box>
          <Box mb={3}>{view === 'main' || !isReset ? <Widgets /> : null}</Box>
          <Box>
            <Overviews />
            <InventorySalesSepeedModal />
            <ManualSalesSpeedModal />
          </Box>
        </div>
      )}
    </Loader>
  );
};

Strategy.propTypes = {
  onFetchedDataStart: PropTypes.func.isRequired,
  onFetchedData: PropTypes.func.isRequired,
  onFetchedDataEmpty: PropTypes.func.isRequired,
  onUpdateGroup: PropTypes.func.isRequired,
  validGroup: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  let currentGroup =
    state.strategy.root.groups[state.strategy.root.selectedGroup];
  if (validateSelectedGroup(state.strategy.root)) {
    currentGroup = state.strategy.root.groups
      ? state.strategy.root.groups[0]
      : null;
    if (!currentGroup) return {};
  }
  return {
    strategy: currentGroup.strategy,
    isReset: currentGroup.isReset,
    view: state.strategy.overviews.view,
    validGroup: state.strategy.root.selectedGroup,
    loading: state.strategy.root.loading,
    isEmpty: state.strategy.root.isEmpty,
  };
};

const mapDispatchToProps = {
  onFetchedDataStart: fetchDataStart,
  onFetchedData: fetchDataInit,
  onFetchedDataEmpty: fetchDataEmpty,
  onUpdateGroup: changeGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Strategy);
