import clsx from 'clsx';
import React, { Ref } from 'react';

import useStageTowerDragAndDrop from '../../../core/customHooks/useStageTowerDragAndDrop';
import StagesDTO, { StagesRecord } from '../../../core/DTO/StagesDTO';
import {
  AddTowerHandler,
  DeleteStageHandler,
  DeleteTowerHandler,
  EditTowerHandler,
  MoveCardHandler,
  OnGoToTower,
  StagesDragAndDropEvents,
} from '../../../core/types/Stages.types';
import StagesBoardHeaders from '../StagesBoardHeaders/StagesBoardHeaders';
import StagesBoardTowersList from '../StagesBoardTowersList/StagesBoardTowersList';

import itemStyles from '../StagesTowerCard/StagesTowerCard.module.scss';
import styles from './StagesBoard.module.scss';

const getStageNumber = (index: number): number => index + 1;

const isLastItem = (index: number, order: number[]): boolean => index === order.length - 1;

const getLastStageRef = (lastStageList: Ref<HTMLDivElement>, index: number, stages: number[]) =>
  isLastItem(index, stages) ? lastStageList : null;

type GetBoardHeadersArgs = {
  stagesRecords: StagesRecord;
  lastStageList: Ref<HTMLDivElement>;
  onDeleteStage: DeleteStageHandler;
};

const getBoardHeaders =
  ({ stagesRecords, lastStageList, onDeleteStage }: GetBoardHeadersArgs) =>
  (stageId: number, index: number, order: number[]) =>
    (
      <StagesBoardHeaders
        className={styles.column}
        key={stageId}
        stage={stagesRecords[stageId]}
        stageNumber={getStageNumber(index)}
        lastStageList={getLastStageRef(lastStageList, index, order)}
        onDeleteStage={onDeleteStage}
      />
    );

type GetTowersListArgs = StagesDragAndDropEvents & {
  stagesRecords: StagesRecord;
  movedCardId: string;
  onAddTower: AddTowerHandler;
  onGoToTower: OnGoToTower;
  onEditTower: EditTowerHandler;
  onDeleteTower: DeleteTowerHandler;
};

const getTowersList =
  ({
    stagesRecords,
    movedCardId,
    onAddTower,
    onGoToTower,
    onEditTower,
    onDeleteTower,
    ...dndHandlers
  }: GetTowersListArgs) =>
  (stageId: number) =>
    (
      <StagesBoardTowersList
        className={styles.column}
        key={stageId}
        stage={stagesRecords[stageId]}
        movedCardId={movedCardId}
        onAddTower={onAddTower}
        onGoToTower={onGoToTower}
        onEditTower={onEditTower}
        onDeleteTower={onDeleteTower}
        {...dndHandlers}
      />
    );

type Props = {
  stages: StagesDTO;
  lastStageList: Ref<HTMLDivElement>;
  onDeleteStage: DeleteStageHandler;
  onAddTower: AddTowerHandler;
  onGoToTower: OnGoToTower;
  onEditTower: EditTowerHandler;
  onDeleteTower: DeleteTowerHandler;
  onMoveCard: MoveCardHandler;
};

const StagesBoard: React.FC<Props> = ({
  stages,
  lastStageList,
  onDeleteStage,
  onAddTower,
  onGoToTower,
  onEditTower,
  onDeleteTower,
  onMoveCard,
}) => {
  const { movedCardId, handleDragStart, handleDragEnter, handleDragOver, handleDrop } = useStageTowerDragAndDrop({
    isGrabbingCSSClass: itemStyles.isGrabbing,
    onMoveCard: onMoveCard,
  });

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        {stages.order.map(
          getBoardHeaders({
            stagesRecords: stages.records,
            lastStageList,
            onDeleteStage,
          }),
        )}
      </div>
      <div className={styles.row}>
        {stages.order.map(
          getTowersList({
            stagesRecords: stages.records,
            movedCardId,
            onAddTower,
            onGoToTower,
            onEditTower,
            onDeleteTower,
            onDragStart: handleDragStart,
            onDragEnter: handleDragEnter,
            onDragOver: handleDragOver,
            onDrop: handleDrop,
          }),
        )}
        <div className={styles.lastColumnGutter}></div>
      </div>
    </div>
  );
};

export default StagesBoard;
