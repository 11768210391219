const baseColumns = [
  {
    title: 'Comprador',
    name: 'clientName',
  },
  {
    title: 'Nomenclatura',
    name: 'propertyName',
  },
  {
    title: 'Valor de Compra',
    name: 'commercialPrice',
  },
  {
    title: 'Pagado',
    name: 'accumulatedPayments',
  },
  {
    title: '% Recaudo',
    name: 'accumulatedPercentage',
  },
  {
    title: 'Financieros',
    name: 'financialAccumulated',
  },
  {
    title: 'Saldo Total',
    name: 'balanceCalculated',
  },
  {
    title: 'Compromisos',
    name: 'commitment',
  },
  {
    title: 'Saldo Compromisos',
    name: 'capitalBalance',
  },
  {
    title: 'Estado',
    name: 'state',
  },
  {
    title: 'Cartera en Mora',
    name: 'arrears',
  },
  {
    title: 'Dias Mora',
    name: 'arrearsDays',
  },
];

const tableColumnWidths = [
  {
    columnName: 'clientName',
    width: 400,
  },
  {
    columnName: 'propertyName',
    width: 146,
  },
  {
    columnName: 'commercialPrice',
    width: 200,
  },
  {
    columnName: 'accumulatedPayments',
    width: 200,
  },
  {
    columnName: 'accumulatedPercentage',
    width: 136,
  },
  {
    columnName: 'financialAccumulated',
    width: 200,
  },
  {
    columnName: 'balanceCalculated',
    width: 200,
  },
  {
    columnName: 'commitment',
    width: 200,
  },
  {
    columnName: 'capitalBalance',
    width: 200,
  },
  {
    columnName: 'state',
    width: 100,
  },
  {
    columnName: 'arrears',
    width: 200,
  },
  {
    columnName: 'arrearsDays',
    width: 126,
  },
];

const tableLeftColumns = ['clientName'];

const numberColumns = [
  'todayComparison',
  'price',
  'today',
  'flow',
  'balancePayments',
  'financial',
  'financialAccumulated',
  'wallet',
  'balance',
  'commitment',
  'accumulatedPayments',
  'commercialPrice',
  'propertyId',
  'floor',
  'location',
  'balanceCalculated',
  'capitalBalance',
  'arrears',
];

const textColumns = [
  'date',
  'isRealPayment',
  'realPaymentId',
  'isNotAPayment',
  'clientName',
  'clientEmail',
  'clientPhoneNumber',
  'finalPayment',
  'clientIdentity',
  'projectName',
  'towerName',
  'clientId',
  'propertyName',
  'isArrears',
  'arrearsDays',
  'state',
  'accumulatedPercentage',
];

const clientStates = {
  total: {
    id: 'total',
    value: '',
  },
  upToDate: {
    id: 'upToDate',
    value: 'AL DÍA',
  },
  arrears: {
    id: 'arrears',
    value: 'MORA',
  },
};

export { baseColumns, tableColumnWidths, tableLeftColumns, numberColumns, textColumns, clientStates };
