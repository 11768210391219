import React, { FC } from 'react';
import Typography from 'shared/components/Typography/Typography';
import Styles from './MonthlyInterestBuyingDetail.module.scss';
import Numbers from '../../../../../helpers/numbers';
import WalletMonthlyReportDTO from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';

const MonthlyInterestBuyingDetail: FC<Partial<WalletMonthlyReportDTO>> = ({
  accumulatedPayments,
  financialAccumulated,
  commitmentForToday,
  commercialPrice,
}) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.totalValueContainer}>
        <Typography variant="body1" color="gray900">
          {Numbers.currencyFormat((commitmentForToday ?? 0) * -1)}
        </Typography>
        <Typography variant="body2" color="gray700" className={Styles.TypographyTitle}>
          Saldo total
        </Typography>
      </div>
      <div className={Styles.TypographyContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.TypographyTitle}>
          Valor de compra
        </Typography>
        <Typography variant="body1" color="gray700">
          {Numbers.currencyFormat(commercialPrice)}
        </Typography>
      </div>
      <div className={Styles.TypographyContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.TypographyTitle}>
          Pagado
        </Typography>
        <Typography variant="body1" color="gray700">
          {Numbers.currencyFormat(accumulatedPayments)}
        </Typography>
      </div>
      <div className={Styles.TypographyContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.TypographyTitle}>
          Intereses
        </Typography>
        <Typography variant="body1" color={(financialAccumulated ?? 0) >= 0 ? 'success600' : 'stateError600'}>
          {Numbers.currencyFormat(financialAccumulated ?? 0)}
        </Typography>
      </div>
    </div>
  );
};

export default MonthlyInterestBuyingDetail;
