import {
  FETCH_OPTIONS_START,
  FETCH_OPTIONS_SUCCESS,
  FETCH_OPTIONS_FAILURE,
} from './actions';

export const initialState = {
  list: [],
  isLoading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_OPTIONS_START:
      return { ...state, list: [], isLoading: true };
    case FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        list: [...payload.options],
        isLoading: false,
      };
    case FETCH_OPTIONS_FAILURE:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};

export default reducer;
