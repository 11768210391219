import { useState } from 'react';
import { useForm } from 'shared/customHooks/useForm';
import { AreasModalFormProps } from '../interfaces/AreasModalFormProps';
import { IAreaCustomColumn } from '../interfaces/IAreaCustomColumn';

const useModalAreaTypeForm = (props: AreasModalFormProps) => {
  const { formData, customColumns, onClickSuccess, onClose } = props;
  const [existsAreaType, setExistsAreaType] = useState<boolean>(false);

  const checkIfExistsAreaTypeName = (areaTypeName: string) =>
    customColumns.some(
      (custonColumn) => custonColumn.name.toLocaleLowerCase().trim() === areaTypeName.toLocaleLowerCase(),
    );

  const form = useForm<IAreaCustomColumn>(
    {
      initialValues: {
        name: formData?.name ?? '',
      },
      validations: {
        name: {
          required: {
            message: 'El campo nombre de área es requerido',
            value: true,
          },
        },
      },
      async onSubmit(data, resetForm) {
        const { name } = data;
        const nameTrim = name.trim();
        setExistsAreaType(() => false);
        const existsAreaTypeName = checkIfExistsAreaTypeName(nameTrim);
        if (existsAreaTypeName) {
          setExistsAreaType(() => true);
          return;
        }
        onClickSuccess({ ...(formData ?? {}), ...data, name: nameTrim });
        onClose();
        resetForm();
      },
    },
    [formData],
  );

  const close = () => {
    form.resetData();
    onClose();
  };

  return {
    form,
    existsAreaType,
    close,
  };
};

export default useModalAreaTypeForm;
