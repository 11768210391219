import clsx from 'clsx';
import React, { Ref } from 'react';

import Agent from 'config/config';
import IconButton from 'shared/components/IconButton/IconButton';
import Tooltip from 'shared/components/Tooltip/Tooltip';
import Typography from 'shared/components/Typography/Typography';
import { StageDTO } from '../../../core/DTO/StagesDTO';
import { DeleteStageHandler } from '../../../core/types/Stages.types';
import StagesUserRole from '../../../core/utils/StagesUserRole';

import styles from './StagesBoardHeaders.module.scss';

const getStageName = (stageNumber: number) => `Etapa ${stageNumber}`;

const getDeleteButton = (stage: StageDTO, stageNumber: number, onDeleteStage: DeleteStageHandler) => (
  <Tooltip title="Eliminar etapa" enterDelay={3000} placement="right" arrow>
    <IconButton
      className={styles.deleteButton}
      iconName="Delete"
      variant="ghost"
      size="xsmall"
      data-stage-id={stage.id}
      data-stage-name={getStageName(stageNumber)}
      data-total-towers={stage.towers.order.length}
      onClick={onDeleteStage}
    />
  </Tooltip>
);

type Props = {
  className?: string;
  stage: StageDTO;
  stageNumber: number;
  lastStageList: Ref<HTMLDivElement>;
  onDeleteStage: DeleteStageHandler;
};

const StagesBoardHeaders: React.FC<Props> = ({ className, stage, stageNumber, lastStageList, onDeleteStage }) => {
  const isSuperOrAdminUser = StagesUserRole.isSuperOrAdminUser(Agent.currentUser.userType);

  return (
    <div className={clsx(styles.root, className)} ref={lastStageList}>
      <div className={styles.divider}></div>
      <div className={styles.header}>
        <Typography className={styles.titleHeading} as="h2" variant="subtitle1" color="gray900">
          {getStageName(stageNumber)}
        </Typography>
        {isSuperOrAdminUser && getDeleteButton(stage, stageNumber, onDeleteStage)}
      </div>
      <div className={styles.divider}></div>
    </div>
  );
};

export default StagesBoardHeaders;
