import clsx from 'clsx';
import React from 'react';

import { ModalActionsProps } from '../../interfaces/ModalActions.types';

import styles from './ModalActions.module.scss';

const ModalActions: React.FC<ModalActionsProps> = ({ className, children, ...restProps }) => (
  <div className={clsx(styles.root, className)} {...restProps}>
    {children}
  </div>
);

export default ModalActions;
