import React, { FC } from 'react';
import { Box, BoxProps, Grid, GridProps } from '@material-ui/core';

const GridContent: FC<BoxProps & { gridProps?: GridProps }> = ({ children, gridProps, ...props }) => (
  <Grid item xs={6} {...gridProps}>
    <Box display="flex" alignItems="center" {...props} height="100%" minHeight="54px">
      {children}
    </Box>
  </Grid>
);
export default GridContent;
