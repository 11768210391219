import React, { FC } from 'react';
import { BaseClientDTO } from 'App/GeneralWallet/core/interfaces/generalWallet.types';
import GeneralWalletDetailsHeader from './sections/GeneralWalletDetailsHeader';
import GeneralWalletDetailsContent from './sections/GeneralWalletDetailsContent';
import GeneralWalletDetailsFooter from './sections/GeneralWalletDetailsFooter';

import styles from './styles/GeneralWalletDetails.module.scss';

interface Props {
  client: BaseClientDTO;
  onClose: () => void;
}

const GeneralWalletDetailsContainer: FC<Props> = ({ client, onClose }) => {
  return (
    <div className={styles.container}>
      <GeneralWalletDetailsHeader client={client} onClose={onClose} />
      <GeneralWalletDetailsContent client={client} />
      <GeneralWalletDetailsFooter client={client} />
    </div>
  );
};

export default GeneralWalletDetailsContainer;
