import clsx from 'clsx';
import React from 'react';

import Icon from 'shared/components/Icon/Icon';
import { FeatureFieldColorInputProps } from '../../../core/interfaces/FeatureFieldColorInput.types';

import styles from './FeatureFieldColorInput.module.scss';

const disabledClass = (isDisabled = false) => ({
  [styles.isDisabled]: isDisabled,
});

const getLableStyles = (color: any) => ({
  backgroundColor: color || 'none',
});

const FeatureFieldColorInput: React.FC<FeatureFieldColorInputProps> = ({
  value,
  disabled,
  onChange,
  type = 'color',
  ...restProps
}) => (
  <label className={clsx(disabledClass(disabled), styles.root)} style={getLableStyles(value)}>
    <input className={styles.input} type={type} value={value} disabled={disabled} onChange={onChange} {...restProps} />
    <Icon className={styles.icon} variant="Add" width="1rem" height="1rem" />
  </label>
);

export default FeatureFieldColorInput;
