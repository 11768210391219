/* eslint-disable react/prop-types */
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Resizable } from 're-resizable';
import { animateScroll as scroll } from 'react-scroll';
import {
  DashboardRoutes,
  ContractRoutes,
  ContractLotsRoutes,
  Construction,
  SalesEvalpro,
  AreasAndPrices,
  Increments,
  SalesRoom,
  Reports,
  Contracts,
  CashFlows,
  Wallet,
  ContractsLots,
} from '../../routes/local/routes';
import ProjectRoutes from '../../routes/local/projectRoutes';
import sideMenuStyle from './SideMenu.module.scss';
import Icon from '../../assets/icons/Icon';
import agent from '../../config/config';
import { Role } from '../../helpers';
import Section from './Section';
import Header from './Header/Header';
import menuStyle from './menu.module.scss';
import INTEGRATIONS from '../../config/integrations';
import likemetricLogo from '../../assets/images/Likemetric-main-logo.png';
import useNotificationsLoader from './customHook/useNotificationsLoader';
import SideMenuControl from './SideMenuControl/SideMenuControl';
import useSideMenuControl from './customHook/useSideMenuControl';
import SubMenuTypes from './enums/SubMenuTypes';
import { SalesSetup } from '../../routes/local/routes';

const isSameSubMenuType = (type) => (subMenuType) => type === subMenuType;

const showExecutionSubMenu = isSameSubMenuType(SubMenuTypes.Execution);

const showEvalproSubMenu = isSameSubMenuType(SubMenuTypes.Evalpro);

const showExternalSourceSubMenu = isSameSubMenuType(
  SubMenuTypes.ExternalSource,
);

const SideMenu = ({
  resizableWidth,
  onChange,
  onHideArrow,
  tower,
  isBadgeIncrement,
  showContent,
  onChangeShowContent,
  projectId,
}) => {
  const ENV = process.env.NODE_ENV;
  const style = INTEGRATIONS[ENV].uniqueSideBar ? menuStyle : sideMenuStyle;

  const [active, setActive] = useState(window.location.pathname);
  const { subMenuType, handleControlClick } = useSideMenuControl();

  const { isApprovalsNotificationEnabled } = useNotificationsLoader(
    tower,
    active,
  );

  const onChangeResizeStop = (change) => {
    onChange(resizableWidth * 0.3 <= -change ? 17 : 200);
    onHideArrow(true);
    onChangeShowContent(!(resizableWidth * 0.3 <= -change));
  };

  const onChangeResize = (change) => {
    onChangeShowContent(change > -170);
  };

  const handleEnterEvent = () => {
    onHideArrow(true);
  };
  const handleLeaveEvent = () => {
    onHideArrow(resizableWidth <= 17);
  };

  if (window.location.pathname !== active) {
    setActive(window.location.pathname);
  }

  const itemForSlidebar = (
    styles,
    route,
    iconName,
    description,
    isBadge,
    isProjectId = false,
  ) => {
    if (tower !== null && !isProjectId) {
      const towerId = tower.id;
      route += towerId;
    }
    let badgeStyle = '';
    if (isBadge) badgeStyle = style.Badge;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          setActive(route);
        }}
      >
        {active === route ? (
          <div className={style.Active}>
            <Link to={route}>
              <Icon name={iconName} fixWidth={true} />
              <span className={style.DescriptionActive}> {description} </span>
            </Link>
          </div>
        ) : (
          <div className={styles}>
            <Link to={route}>
              <Icon name={iconName} fixWidth={true} />
              <span className={style.Description}> {description}</span>
              {isBadge && <span className={badgeStyle} />}
            </Link>
          </div>
        )}
      </div>
    );
  };

  const scrollToBottom = () => {
    scroll.scrollTo(500);
  };

  const isSalesGroup = (...roles) =>
    agent.isAuthorized([Role.Admin, Role.Super, Role.SalesDirector, ...roles]);
  const deny = (...roles) => agent.denyAccess(...roles);

  const checkForSalesGroup = (cb) => {
    if (isSalesGroup()) return cb;
  };

  return (
    <div className={style.container}>
      <Resizable
        enable={{
          right: true,
        }}
        className={
          `${style.SideMenu}  ` +
          `${tower !== null ? style.ZeroWidth : style.OriginalWidth}`
        }
        onMouseEnter={handleEnterEvent}
        onMouseLeave={handleLeaveEvent}
        size={{ width: `${resizableWidth}`, height: '100vh' }}
        onResize={(e, direction, ref, d) => onChangeResize(d.width)}
        onResizeStop={(e, direction, ref, d) => onChangeResizeStop(d.width)}
      >
        {showContent && (
          <Fragment>
            <div className={style.fixedWidth + style.NoVisible}>
              <div className={style.titleWithImageContainer}>
                {INTEGRATIONS[ENV].uniqueSideBar && (
                  <img
                    alt="Like Metric logo"
                    src={likemetricLogo}
                    className={style.imgLogo}
                  />
                )}
                <div className={style.title}>
                  <p>{tower ? tower.projectName : ''}</p>
                  <p>{tower ? tower.name : ''}</p>
                </div>
              </div>
              {INTEGRATIONS[ENV].uniqueSideBar && (
                <div className={style.iconsRoutes}>
                  <Link
                    to={DashboardRoutes.base + ProjectRoutes.base}
                    className={style.iconRoute}
                  >
                    <Icon name="fa-home" fixWidth={true} />
                  </Link>
                  <Link
                    to={DashboardRoutes.base + DashboardRoutes.user}
                    className={style.iconRoute}
                  >
                    <Icon name="fas fa-tools" fixWidth={true} />
                  </Link>
                </div>
              )}
              <div className={style.IconsContainer}>
                {agent.isAuthorized([Role.Admin, Role.Super]) &&
                  itemForSlidebar(
                    style.MenuItem,
                    DashboardRoutes.base +
                      ProjectRoutes.base +
                      ProjectRoutes.towers.value +
                      projectId,
                    'fas fa-arrow-left',
                    'Torres',
                    null,
                    true,
                  )}

                <hr className={style.lineSpace} />
                <Header
                  title="Proyección"
                  validation={!!agent.isAuthorized([Role.Admin, Role.Super])}
                />
                {agent.isAuthorized([Role.Admin, Role.Super]) &&
                  itemForSlidebar(
                    style.MenuItem,
                    DashboardRoutes.base +
                      DashboardRoutes.projectionTypes.value,
                    null,
                    'Tipo de proyección',
                  )}

                <hr className={style.lineSpace} />
                <Header
                  title="Estados Financieros"
                  validation={!!agent.isAuthorized([Role.Admin, Role.Super])}
                />
                <Section
                  title={'Estados Financieros'}
                  validation={CashFlows.array.some((url) =>
                    window.location.pathname.includes(url),
                  )}
                  display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                  icon="fas fa-file-alt"
                  items={[
                    agent.isAuthorized([Role.Admin, Role.Super]) &&
                      itemForSlidebar(
                        style.MenuItem,
                        DashboardRoutes.base +
                          DashboardRoutes.totalCashFlow.value,
                        null,
                        'Flujo de caja Total',
                      ),
                    agent.isAuthorized([Role.Admin, Role.Super]) &&
                      itemForSlidebar(
                        style.MenuItem,
                        DashboardRoutes.base +
                          DashboardRoutes.resultsState.value,
                        null,
                        'Estado de resultados',
                      ),
                  ]}
                />

                <hr className={style.lineSpace} />
                <Header
                  title="Informes"
                  validation={!!agent.isAuthorized([Role.Admin, Role.Super])}
                />
                <Section
                  title={'Informes'}
                  validation={CashFlows.array.some((url) =>
                    window.location.pathname.includes(url),
                  )}
                  display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                  icon="fas fa-file-alt"
                  items={[
                    agent.isAuthorized([Role.Admin, Role.Super]) &&
                      itemForSlidebar(style.MenuItem, '#', null, 'Pendiente'),
                  ]}
                />

                <hr className={style.lineSpace} />
                {deny(Role.Sales, Role.Wallet) && (
                  <SideMenuControl
                    subMenuType={subMenuType}
                    onControlClick={handleControlClick}
                  />
                )}

                {showExecutionSubMenu(subMenuType) && (
                  <div>
                    <Header
                      title="Ventas"
                      validation={isSalesGroup(Role.Sales)}
                    />
                    {isSalesGroup() &&
                      itemForSlidebar(
                        style.MenuItem,
                        DashboardRoutes.base + DashboardRoutes.schedule.value,
                        'fas fa-cogs',
                        'Configuración inicial',
                      )}
                    <Section
                      title={'Configuración de proyecto'}
                      validation={AreasAndPrices.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      icon="fas fa-building"
                      display={!!isSalesGroup(Role.Sales)}
                      items={[
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.building.value,
                            null,
                            'Esquema',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.areasv2.value,
                            null,
                            'Areas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.areasAdditional.value,
                            null,
                            'Areas Adicionales',
                          ),
                        isSalesGroup(Role.Sales) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.pairing.value,
                            null,
                            'Apareamiento',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.propertyCharacteristic.value,
                            null,
                            'Características',
                          ),
                        agent.isAuthorized([Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.prime.value,
                            null,
                            'Primas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.clustering.value,
                            null,
                            'Agrupamiento',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.propertyEdition.value,
                            null,
                            'Edición de la propiedad',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.graphicMaterial.value,
                            null,
                            'Material Gráfico',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.documentTemplates.value,
                            null,
                            'Plantillas de Documentos',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.quotationConfig.value,
                            null,
                            'Cotizaciones',
                          ),
                      ]}
                    />

                    <Section
                      title={'Proyecciones'}
                      validation={Increments.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      icon="fas fa-chart-line"
                      display={isSalesGroup()}
                      isBadge={isBadgeIncrement}
                      items={[
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.salesSpeedHelper.value,
                            null,
                            'Velocidad de ventas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.strategy.value,
                            null,
                            'Estrategia de precios',
                            isBadgeIncrement,
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.futureSalesSpeed.value,
                            null,
                            'Forma de pago',
                          ),
                      ]}
                    />
                    <Section
                      title={'Sala de ventas'}
                      validation={SalesRoom.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Sales, Role.Wallet)}
                      icon="fas fa-users"
                      isBadge={isApprovalsNotificationEnabled}
                      items={[
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.saleRequests.value,
                            null,
                            'Aprobaciones',
                            isApprovalsNotificationEnabled,
                          ),
                        isSalesGroup(Role.Sales, Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.clientProperty.value,
                            null,
                            'Apareamiento Clientes',
                          ),
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.saleDateSelector.value,
                            null,
                            'Edición de fechas',
                          ),
                        isSalesGroup(Role.Sales, Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.clients.value,
                            null,
                            'Sala de ventas',
                          ),
                        isSalesGroup(Role.Sales, Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.buyerCostumers.value,
                            null,
                            'Compradores',
                          ),
                      ]}
                    />
                    <Section
                      title={'Cartera'}
                      validation={Wallet.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Wallet)}
                      items={[
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.generalWallet.value,
                            null,
                            'General',
                          ),
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.createRealPayment.value,
                            null,
                            'Ingreso de pagos',
                          ),
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.arrearsSummaryReport.value,
                            null,
                            'Mora',
                          ),
                      ]}
                      icon="fas fa-money-check-alt"
                    />
                    {itemForSlidebar(
                      style.MenuItem,
                      DashboardRoutes.base + DashboardRoutes.deed.value,
                      'fas fa-exchange-alt',
                      'Escrituración',
                    )}
                    <Section
                      title={'Tramites'}
                      validation={Wallet.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Wallet)}
                      items={[
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            '#',
                            null,
                            'Pendiente',
                          ),
                      ]}
                      icon="fas fa-file-signature"
                    />

                    <Section
                      title={'Informes Comerciales'}
                      validation={Reports.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Sales, Role.SalesDirector)}
                      icon="fas fa-file-alt"
                      items={[
                        isSalesGroup(Role.Sales, Role.SalesDirector) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.salesReportOne.value,
                            null,
                            'Informe de Ventas 1',
                          ),
                        isSalesGroup(Role.Sales, Role.SalesDirector) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.weeklySalesDetail.value,
                            null,
                            'Detalle Semanal de Ventas',
                          ),
                      ]}
                    />

                    <Section
                      title={'Informes De Gerencia'}
                      validation={Reports.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Wallet)}
                      icon="fas fa-file-alt"
                      items={[
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.summary.value,
                            null,
                            'Resumen de Estructuración',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.rackAreas.value,
                            null,
                            'Resumen Areas',
                          ),
                        agent.isAuthorized([Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.detail.value,
                            null,
                            'Detalle',
                          ),

                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.report.value,
                            null,
                            'Reporte',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.budget.value,
                            null,
                            'Ejecución presupuestal',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.salesSpeed.value,
                            null,
                            'Informe velocidades de Venta',
                          ),
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.paymentCashFlow.value,
                            null,
                            'FC Ventas realizadas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.cashFlow.value,
                            null,
                            'FC Ventas futuras',
                          ),
                      ]}
                    />

                    <hr className={style.lineSpace} />
                    <Header
                      title="Lote"
                      validation={
                        !!agent.isAuthorized([Role.Admin, Role.Super])
                      }
                    />
                    <Section
                      title={'Lote'}
                      validation={ContractsLots.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      scrollToBottom={scrollToBottom}
                      icon="fab fa-react"
                      display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                      items={[
                        agent.isAuthorized([Role.Admin, Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              ContractLotsRoutes.base.value,
                            null,
                            'Lote',
                          ),
                      ]}
                    />

                    <hr className={style.lineSpace} />
                    <Header
                      title="Costos construcción"
                      validation={
                        !!agent.isAuthorized([Role.Admin, Role.Super])
                      }
                    />
                    <Section
                      title={'Construcción'}
                      validation={Contracts.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      scrollToBottom={scrollToBottom}
                      icon="fab fa-react"
                      display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                      items={[
                        agent.isAuthorized([Role.Admin, Role.Super]) &&
                          tower &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + Construction.base.value,
                            null,
                            'Construcción',
                          ),
                      ]}
                    />

                    <hr className={style.lineSpace} />
                    <Header
                      title="Costos indirectos"
                      validation={
                        !!agent.isAuthorized([Role.Admin, Role.Super])
                      }
                    />
                    <Section
                      title={'Contratos Ejecución'}
                      validation={Contracts.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      scrollToBottom={scrollToBottom}
                      icon="fas fa-file-signature"
                      display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                      items={[
                        agent.isAuthorized([Role.Admin, Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + ContractRoutes.base.value,
                            null,
                            'Contratos',
                          ),
                      ]}
                    />

                    <hr className={style.lineSpace} />
                    <Header
                      title="Financiación"
                      validation={
                        !!agent.isAuthorized([Role.Admin, Role.Super])
                      }
                    />
                    <Section
                      title={'Pendiente'}
                      validation={Contracts.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      scrollToBottom={scrollToBottom}
                      icon="fab fa-react"
                      display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                      items={[
                        agent.isAuthorized([Role.Admin, Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + ContractRoutes.base.value,
                            null,
                            'Pendiente',
                          ),
                      ]}
                    />
                  </div>
                )}
                {showEvalproSubMenu(subMenuType) && (
                  <div>
                    <div className={style.linkContainer}>
                      <Header title="Ventas" validation={isSalesGroup()} />
                      <Section
                        title={'Proyección'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={isSalesGroup()}
                        items={[
                          isSalesGroup() &&
                            itemForSlidebar(
                              style.MenuItem,
                              DashboardRoutes.base + SalesEvalpro.base.value,
                              null,
                              'Ventas',
                            ),
                        ]}
                        icon="fas fa-chart-line"
                      />
                      {itemForSlidebar(
                        style.MenuItem,
                        DashboardRoutes.base +
                          DashboardRoutes.deedEvalpro.value,
                        'fas fa-exchange-alt',
                        'Escrituración',
                      )}
                      <Section
                        title={'Informes de gerencia'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={isSalesGroup()}
                        items={[
                          checkForSalesGroup(
                            itemForSlidebar(
                              style.MenuItem,
                              DashboardRoutes.base +
                                DashboardRoutes.salesSpeedEvalpro.value,
                              null,
                              'Velocidades de Venta',
                            ),
                          ),
                          checkForSalesGroup(
                            itemForSlidebar(
                              style.MenuItem,
                              DashboardRoutes.base +
                                DashboardRoutes.cashFlowEvalpro.value,
                              null,
                              'FC ventas futuras Evalpro',
                            ),
                          ),
                        ]}
                        icon="fas fa-file-alt"
                      />

                      <hr className={style.lineSpace} />
                      <Header
                        title="Lote"
                        validation={
                          !!agent.isAuthorized([Role.Admin, Role.Super])
                        }
                      />
                      <Section
                        title={'Lote'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                        items={[
                          agent.isAuthorized([Role.Admin, Role.Super]) &&
                            itemForSlidebar(style.MenuItem, '#', null, 'Lote'),
                        ]}
                        icon="fas fa-border-all"
                      />

                      <hr className={style.lineSpace} />
                      <Header
                        title="Costos Construcción"
                        validation={
                          !!agent.isAuthorized([Role.Admin, Role.Super])
                        }
                      />
                      <Section
                        title={'Costos Construcción'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                        items={[
                          agent.isAuthorized([Role.Admin, Role.Super]) &&
                            itemForSlidebar(
                              style.MenuItem,
                              DashboardRoutes.base + Construction.base.value,
                              null,
                              'Construcción',
                            ),
                        ]}
                        icon="fas fa-dollar-sign"
                      />

                      <hr className={style.lineSpace} />
                      <Header
                        title="Costos indirectos"
                        validation={
                          !!agent.isAuthorized([Role.Admin, Role.Super])
                        }
                      />
                      <Section
                        title={'Contratos Evalpro'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                        items={[
                          agent.isAuthorized([Role.Admin, Role.Super]) &&
                            itemForSlidebar(
                              style.MenuItem,
                              DashboardRoutes.base +
                                DashboardRoutes.projectStages.value,
                              null,
                              'Contratos',
                            ),
                        ]}
                        icon="fas fa-file-signature"
                      />
                      <Section
                        title={'Informes Contratos Evalpro'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                        items={[
                          agent.isAuthorized([Role.Admin, Role.Super]) &&
                            itemForSlidebar(
                              style.MenuItem,
                              '#',
                              null,
                              'Pendiente',
                            ),
                        ]}
                        icon="fas fa-file-signature"
                      />

                      <hr className={style.lineSpace} />
                      <Header
                        title="Financiación"
                        validation={
                          !!agent.isAuthorized([Role.Admin, Role.Super])
                        }
                      />
                      <Section
                        title={'Financiación'}
                        validation={Wallet.array.some((url) =>
                          window.location.pathname.includes(url),
                        )}
                        display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                        items={[
                          agent.isAuthorized([Role.Admin, Role.Super]) &&
                            itemForSlidebar(
                              style.MenuItem,
                              '#',
                              null,
                              'Financiación',
                            ),
                        ]}
                        icon="fa fa-coins"
                      />
                    </div>
                  </div>
                )}
                {showExternalSourceSubMenu(subMenuType) && (
                  <div>
                    <Header
                      title="Ventas"
                      validation={isSalesGroup(Role.Sales)}
                    />
                    {isSalesGroup() &&
                      itemForSlidebar(
                        style.MenuItem,
                        DashboardRoutes.base + DashboardRoutes.schedule.value,
                        'fas fa-cogs',
                        'Configuración inicial',
                      )}
                    <Section
                      title={'Configuración de proyecto'}
                      validation={AreasAndPrices.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      icon="fas fa-building"
                      display={!!isSalesGroup(Role.Sales)}
                      items={[
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.building.value,
                            null,
                            'Esquema',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.areasv2.value,
                            null,
                            'Areas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.areasAdditional.value,
                            null,
                            'Areas Adicionales',
                          ),
                        isSalesGroup(Role.Sales) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.pairing.value,
                            null,
                            'Apareamiento',
                          ),
                        agent.isAuthorized([Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.prime.value,
                            null,
                            'Primas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.clustering.value,
                            null,
                            'Agrupamiento',
                          ),
                      ]}
                    />

                    <Section
                      title={'Proyecciones'}
                      validation={Increments.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      icon="fas fa-chart-line"
                      display={isSalesGroup()}
                      isBadge={isApprovalsNotificationEnabled}
                      items={[
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.salesSpeedHelper.value,
                            null,
                            'Velocidad de ventas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.strategy.value,
                            null,
                            'Estrategia de precios',
                            isBadgeIncrement,
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.futureSalesSpeed.value,
                            null,
                            'Forma de pago',
                          ),
                      ]}
                    />
                    <Section
                      title={'Sala de ventas'}
                      validation={SalesRoom.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Sales, Role.Wallet)}
                      icon="fas fa-users"
                      isBadge={isApprovalsNotificationEnabled}
                      items={[
                        isSalesGroup(Role.Sales, Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.salesStatus.value,
                            null,
                            'Estado de Ventas',
                          ),
                        isSalesGroup(Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.saleDateSelector.value,
                            null,
                            'Edición de fechas',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.propertyEdition.value,
                            null,
                            'Edición de la propiedad',
                          ),
                      ]}
                    />
                    <Section
                      title={'Configuración Ventas'}
                      validation={SalesSetup.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Sales, Role.Wallet)}
                      icon="fas fa-users"
                      isBadge={isApprovalsNotificationEnabled}
                      items={[
                        isSalesGroup(Role.Sales, Role.Wallet) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.soldCollection.value,
                            null,
                            'Recaudo Ventas realizadas',
                          ),
                      ]}
                    />

                    <Section
                      title={'Informes De Gerencia'}
                      validation={Reports.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      display={isSalesGroup(Role.Wallet)}
                      icon="fas fa-file-alt"
                      items={[
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.budget.value,
                            null,
                            'Ejecución presupuestal',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.salesSpeed.value,
                            null,
                            'Informe velocidades de Venta',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base +
                              DashboardRoutes.cashFlow.value,
                            null,
                            'FC Ventas futuras',
                          ),
                        isSalesGroup() &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + DashboardRoutes.report.value,
                            null,
                            'Reporte',
                          ),
                      ]}
                    />

                    <hr className={style.lineSpace} />
                    <Header
                      title="Costos construcción"
                      validation={
                        !!agent.isAuthorized([Role.Admin, Role.Super])
                      }
                    />
                    <Section
                      title={'Construcción'}
                      validation={Contracts.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      scrollToBottom={scrollToBottom}
                      icon="fab fa-react"
                      display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                      items={[
                        agent.isAuthorized([Role.Admin, Role.Super]) &&
                          tower &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + Construction.base.value,
                            null,
                            'Construcción',
                          ),
                      ]}
                    />

                    <hr className={style.lineSpace} />
                    <Header
                      title="Costos indirectos"
                      validation={
                        !!agent.isAuthorized([Role.Admin, Role.Super])
                      }
                    />
                    <Section
                      title={'Contratos Ejecución'}
                      validation={Contracts.array.some((url) =>
                        window.location.pathname.includes(url),
                      )}
                      scrollToBottom={scrollToBottom}
                      icon="fas fa-file-signature"
                      display={!!agent.isAuthorized([Role.Admin, Role.Super])}
                      items={[
                        agent.isAuthorized([Role.Admin, Role.Super]) &&
                          itemForSlidebar(
                            style.MenuItem,
                            DashboardRoutes.base + ContractRoutes.base.value,
                            null,
                            'Contratos',
                          ),
                      ]}
                    />
                  </div>
                )}

                <hr className={style.lineSpace} />
              </div>
            </div>
          </Fragment>
        )}
      </Resizable>
    </div>
  );
};

export default SideMenu;
