import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { MonthlyEventType, MonthlyPaymentRow } from '../../core/DTO/WalletMonthlyReportDTO';
import VirtualTableDataTypeProviderProps from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderProps';
import useMapperForDataTypeProvider from 'shared/tables/VirtualTableDataTypeProvider/Core/customHooks/useMapperForDataTypeProvider';

interface MonthlyInterestEmptyProviderProps extends VirtualTableDataTypeProviderProps {
  watchedColumns: string[];
}

const EmptyComponent: React.FC<DataTypeProvider.ValueFormatterProps & VirtualTableDataTypeProviderProps> = (props) => {
  const { columnsForType, providerComponent } = useMapperForDataTypeProvider(props.columns);

  const FormattedComponent = providerComponent(props.type);
  const paymentRow: MonthlyPaymentRow = props.row;
  if (paymentRow && paymentRow.type === MonthlyEventType.placeholder) {
    return <></>;
  }

  return <FormattedComponent {...props} />;
};

const MonthlyInterestEmptyProvider: React.FC<MonthlyInterestEmptyProviderProps> = ({
  columns,
  watchedColumns,
  type,
}) => {
  return (
    <DataTypeProvider
      formatterComponent={(props) => <EmptyComponent {...props} columns={columns} type={type} />}
      for={watchedColumns}
    />
  );
};

export default MonthlyInterestEmptyProvider;
