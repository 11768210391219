import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import CustomTypography from '../../../../components/Typography/CustomTypography';
import Styles from './AddClientModalMainView.module.scss';

const SelectWithTitleForCredit = ({
  id,
  title,
  classObjectForOptions,
  defaultValue,
  value,
  onChange,
}) => {
  return (
    <Box className={Styles.clientInformationContainer}>
      <CustomTypography
        className={Styles.indicator}
        variant="description"
        color="#000000"
      >
        {title}
      </CustomTypography>
      <FormControl className={Styles.civilStatusSelector}>
        <InputLabel fullWidth className={Styles.inputLabel}>
          {title}
        </InputLabel>
        <Select
          id={id}
          name={id}
          fullWidth
          defaultValue={defaultValue}
          value={value}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChange}
        >
          {Object.keys(classObjectForOptions).map((key) => {
            return (
              <MenuItem
                key={`${title}${classObjectForOptions[key].code}`}
                value={classObjectForOptions[key].code}
              >
                {classObjectForOptions[key].value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

SelectWithTitleForCredit.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  classObjectForOptions: PropTypes.object,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default SelectWithTitleForCredit;
