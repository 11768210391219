import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  Box,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { DashboardRoutes } from '../../../../../routes/local/routes';

const DocumentTemplatesMenu = ({
  open,
  toggleMenu,
  anchorRef,
  documents,
  downloadDocument,
}) => {
  const { towerId } = useParams();
  const configLocation = `${DashboardRoutes.base}${DashboardRoutes.documentTemplates.value}${towerId}`;

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            {documents && documents.length > 0 && (
              <ClickAwayListener onClickAway={toggleMenu}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {documents.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      onClick={() => downloadDocument(id, name)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            )}
            {(!documents || !documents.length) && (
              <ClickAwayListener onClickAway={toggleMenu}>
                <Box padding="10px">
                  <Typography variant="body1">
                    No hay documentos disponibles para la torre, por favor
                    diríjase a <Link to={configLocation}>configuración</Link>.
                  </Typography>
                </Box>
              </ClickAwayListener>
            )}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

DocumentTemplatesMenu.propTypes = {
  open: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
  anchorRef: PropTypes.elementType.isRequired,
  documents: PropTypes.array,
  downloadDocument: PropTypes.func.isRequired,
};

export default DocumentTemplatesMenu;
