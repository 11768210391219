import React, { FC } from 'react';
import { Box, IconButton, Popover } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { ClientModel } from 'App/Clients/Core/models/ClientModel';
import Portal from 'shared/components/Portal/Portal';
import { ClientActionsProps } from 'components/Client2/List/Core/interfaces/IActions';
import useClientListPopover from 'components/Client2/List/Core/customHooks/useClientListPopover';
import ClientListAction from '../../Menu/ClientListAction/ClientListAction';

const ClientListPopover: FC<{
  actions: ClientActionsProps;
  client: ClientModel;
}> = ({ actions, client }) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useClientListPopover();

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Portal>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          onClose={handleClose}
        >
          <Box paddingY={1} width={180} onClick={handleClose}>
            <ClientListAction
              onClick={actions.onClickEditClient}
              label="Más opciones"
              client={client}
            />
            <ClientListAction
              onClick={actions.goToSalesRoom}
              label="Ir a sala de ventas"
              client={client}
            />
            {!!!client?.properties?.length && (
              <ClientListAction
                isDivider
                onClick={actions.deleteClient}
                intent="danger"
                label="Eliminar"
                client={client}
              />
            )}
          </Box>
        </Popover>
      </Portal>
    </>
  );
};

export default ClientListPopover;
