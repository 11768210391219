import React from 'react';
import { Box, Button, Paper, Slide } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useQuotationEditionType from '../../../Core/customHooks/useQuotationEditionType';

const Container = styled(Box)`
  position: fixed;
  top: 100px;
  right: 8px;
`;

const CalculateDiscountThroughGoalSeek = ({
  onClickDiscount,
  enableDiscount,
}) => {
  const { isLockedType, isEditingMode: isEditing } = useQuotationEditionType();
  return (
    <Container>
      {isEditing && !isLockedType && (
        <Slide in direction="left">
          <Paper elevation={24}>
            <Box p={2}>
              <Button
                color={enableDiscount ? 'primary' : 'secondary'}
                enabled={!enableDiscount}
                onClick={onClickDiscount}
              >
                {enableDiscount ? 'Ok' : 'Ejecutar Descuento'}
              </Button>
            </Box>
          </Paper>
        </Slide>
      )}
    </Container>
  );
};

CalculateDiscountThroughGoalSeek.propTypes = {
  enableDiscount: PropTypes.bool,
  onClickDiscount: PropTypes.func,
};

export default CalculateDiscountThroughGoalSeek;
