import { useMutation, useQueryClient } from 'react-query';
import { ContractPaymentInvoice } from '../../../../models/ContractPayments';
import Services from '../../../../services/ContractPaymentsInvoice/ContractPaymentsInvoiceServices';

const services = new Services();

const useAddContractPaymentInvoice = (contractId: number) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addContractPaymentInvoice } = useMutation(
    (contractPayment: ContractPaymentInvoice) =>
      services.createContractPaymentInvoice(contractId, {
        ...contractPayment,
        contractId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        queryClient.invalidateQueries(['contract', contractId]);
        queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
      },
    },
  );

  return {
    addContractPaymentInvoice,
  };
};

export default useAddContractPaymentInvoice;
