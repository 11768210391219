import React, { ComponentType, FC, ReactNode } from 'react';
import { SvgIconProps } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import styles from './DeedExpensesInformationLabel.module.scss';

interface Props {
  label: string;
  value: string | ReactNode;
  InfoIcon?: ComponentType<SvgIconProps>;
}

const DeedExpensesInformationLabel: FC<Props> = ({ label, value, InfoIcon }) => {
  return (
    <div className={styles.DeedExpensesInformationLabel}>
      {!!InfoIcon && <InfoIcon fontSize="small" className={styles.Icon} />}
      <Typography color="gray800" className={styles.Label} fontWeight="500">
        {label}
      </Typography>
      <Typography color="gray600" fontStyle="italic">
        {value}
      </Typography>
    </div>
  );
};

export default DeedExpensesInformationLabel;
