import { useState, useEffect } from 'react';
import { MeasurementUnit } from '../../../../models/ContractPayments';

const useMeasurementUnit = ({ currentContractPayment, measurementUnits }) => {
  const [inputMeasurementUnitValue, setInputMeasurementUnitValue] = useState('');

  const [autocompleteMeasurementUnitValue, setAutocompleteMeasurementUnitValue] = useState<MeasurementUnit | null>(
    null,
  );

  useEffect(() => {
    if (currentContractPayment) {
      const measurementUnitSelected =
        measurementUnits?.find((v) => v.id == currentContractPayment?.measurementUnitId) || null;

      setAutocompleteMeasurementUnitValue(measurementUnitSelected);
    }
  }, [currentContractPayment]);

  return {
    inputMeasurementUnitValue,
    setInputMeasurementUnitValue,
    autocompleteMeasurementUnitValue,
    setAutocompleteMeasurementUnitValue,
  };
};

export default useMeasurementUnit;
