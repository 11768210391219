import React, { useState } from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Styles from '../styles/TypeSelector.module.scss';
import { updateTypeOfViewState } from '../../../TotalCashFlow/Core/actions';

export default function TypeSelector() {
  const [typeOfView, setTypeOfView] = useState('millions');

  const dispatch = useDispatch();

  return (
    <Box sx={{ minWidth: 300 }}>
      <FormControl variant="outlined" className={Styles.typeSelector}>
        <InputLabel id="demo-simple-select-label">Ver en</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={typeOfView}
          label="typeOfView"
          onChange={(event) => {
            setTypeOfView(event.target.value);
            dispatch(updateTypeOfViewState(event.target.value));
          }}
        >
          <MenuItem value={'pesos'}>Ver en pesos</MenuItem>
          <MenuItem value={'thousands'}>Ver en miles</MenuItem>
          <MenuItem value={'millions'}>Ver en millones</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
