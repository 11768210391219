import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Typography from '../../../../../shared/components/Typography/Typography';

const ContractPaymentScheduleGridTitleFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography color="gray800" fontWeight="600">
        {value}
      </Typography>
    </div>
  );
};

export default ContractPaymentScheduleGridTitleFormatter;
