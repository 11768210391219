import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import styled from 'styled-components';

import table from '../../../../assets/styles/components/table.module.scss';
import colors from '../../../../assets/styles/settings/colors.module.scss';
import fonts from '../../../../assets/styles/settings/fonts.module.scss';

const GeneralWalletClientCell: React.FC = styled(TableFixedColumns.Cell)<TableFixedColumns.CellProps>`
  /* Visual */
  filter: drop-shadow(4px 0px 4px rgba(191, 191, 191, 0.25));

  &:first-child {
    /* Box-model */
    padding-left: ${table.cellWidhtPadding};
  }

  &:not(.MuiTableCell-head) {
    /* Typography */
    font-family: ${fonts.headline};
    color: ${colors.gray900};
    font-weight: 400;
  }
`;

export default GeneralWalletClientCell;
