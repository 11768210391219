import React, { FC, useContext } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';

import Styles from '../../ContractDetailMainView.module.scss';
import { FORMAT_YEAR_MONTH2 } from '../../../../../helpers/dates';
import StateChip from '../../../../../shared/components/StateChip/StateChip';
import { ContractDetailContext, ContractDetailContextState } from '../../ContractDetailMainView';
import Typography from 'shared/components/Typography/Typography';

const ContractDetailDescription: FC = () => {
  const { currentContract, currentContractIsLot } = useContext(ContractDetailContext) as ContractDetailContextState;

  const categoryName = currentContract?.item
    ? `${currentContract.item.contractCategory.categoryName} - ${currentContract.item.name}`
    : '';

  return (
    <Box p={2}>
      {currentContractIsLot === true ? (
        <Typography>{currentContract?.description}</Typography>
      ) : (
        <dl className={Styles.dlHorizontal}>
          <dt>Proveedor</dt>
          <dd>{currentContract?.contractorName}</dd>
          <dt>Grupo</dt>
          <dd>{categoryName}</dd>
          <dt>Periodo</dt>
          <dd>
            {moment(Number(currentContract?.fromDate)).format(FORMAT_YEAR_MONTH2).toString()}
            {' - '}
            {moment(Number(currentContract?.toDate)).format(FORMAT_YEAR_MONTH2).toString()}
          </dd>
        </dl>
      )}
      <Box width="100%" mt={1} className={Styles.texCenter}>
        {!!currentContract?.state && (
          <StateChip label={currentContract.state.name} color={currentContract.state.color} variant="outlined" />
        )}
      </Box>
    </Box>
  );
};

export default ContractDetailDescription;
