import React, { FC } from 'react';
import moment from 'moment';

import { FORMAT_YEAR_MONTH2 } from '../../../../../helpers/dates';
import { ContractPayment } from '../../../../../models/ContractPayments';
import Typography from '../../../../../shared/components/Typography/Typography';

moment.locale('es');

interface IContractPaymentDescription {
  contractPayment: ContractPayment;
}

const ContractPaymentDescription: FC<IContractPaymentDescription> = ({ contractPayment }) => {
  const { contractPaymentSchedules } = contractPayment;

  return (
    <>
      {(() => {
        switch (contractPayment.type) {
          case 'PX':
            const [schedule] = contractPaymentSchedules;
            return (
              <Typography variant="body2" color="gray600">
                {'Pagar en '}
                {moment(Number(schedule.finalDate)).format(FORMAT_YEAR_MONTH2).toString()}
              </Typography>
            );
          case 'PC':
          case 'PP':
          case 'PR':
            const [dateFrom, dateTo] = contractPayment.dateRange;
            return (
              <Typography variant="body2" color="gray600">
                {'A facturar entre '}
                {moment(Number(dateFrom)).format(FORMAT_YEAR_MONTH2).toString()}
                {' - '}
                {moment(Number(dateTo)).format(FORMAT_YEAR_MONTH2).toString()}
              </Typography>
            );
        }
      })()}
    </>
  );
};

export default ContractPaymentDescription;
