import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import SelectRows from './SelectRows';
import Styles from '../styles/AssignImageToPropertyDialog.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignImageToPropertyDialog({
  openDialog,
  closeDialog,
  propertyId,
  imgType,
  selectedImage,
  onAccept,
  onDelete,
  getImages,
}) {
  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={Styles.dialogContainer}
      >
        <DialogTitle id="alert-dialog-slide-title" className={Styles.title}>
          Señor usuario
        </DialogTitle>
        <DialogContent className={Styles.DialogContent}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={Styles.margin}
          >
            Aquí podrá asignar la imagen seleccionada a diferentes apartamentos
          </DialogContentText>
          <SelectRows
            selectedImage={selectedImage}
            onAccept={onAccept}
            onDelete={onDelete}
            getImages={getImages}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await getImages(propertyId, imgType);
              closeDialog();
            }}
            color="primary"
          >
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AssignImageToPropertyDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.bool,
  propertyId: PropTypes.number,
  imgType: PropTypes.string,
  selectedImage: PropTypes.number,
  onAccept: PropTypes.func,
  onDelete: PropTypes.func,
  getImages: PropTypes.func,
};
