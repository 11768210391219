import React, { useState, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import {
  fetchOptionsStart,
  fetchOptionsSuccess,
  fetchOptionsFailure,
} from './actions';
import reducer, { initialState } from './reducer';
import Input from './Input';
import Option from './Option/Option';
import Services from '../../../services/client/ClientsServices';
import ContainerContext from '../../../containers/Client/context';
import { DashboardRoutes } from '../../../routes/local/routes';
import debouncedInput from '../../UI/TextFields/debouncedInput';
import styles from './Search.module.scss';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Typography from 'shared/components/Typography/Typography';

const services = new Services();

const DebouncedInputWrapped = debouncedInput(Input, { timeout: 400 });

const Search = ({ onSelectHandler, onClickNewClient }) => {
  const { towerId } = useContext(ContainerContext);
  const [inputValue, setInputValue] = useState('');
  const [options, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    let active = true;
    async function fetchClients() {
      dispatch(fetchOptionsStart());
      try {
        if (inputValue !== '') {
          const res = await services.searchClients(towerId, inputValue);
          if (active) {
            dispatch(fetchOptionsSuccess(res.data, inputValue));
          }
        }
      } catch (error) {
        dispatch(fetchOptionsFailure());
      }
    }
    fetchClients();
    return () => {
      active = false;
    };
  }, [inputValue]);

  const gotoSalesroom = () => {
    history.push(
      `${DashboardRoutes.base}${DashboardRoutes.salesRoom.value}${towerId}/-`,
    );
  };

  return (
    <Box className={styles.Content}>
      <Box display="flex" alignItems="center">
        <Typography variant="headline2">Clientes</Typography>
        <Box marginLeft="50px" width={280}>
          <Autocomplete
            noOptionsText="No hay clientes"
            loading={options.isLoading}
            loadingText="Obteniendo clientes..."
            filterOptions={(x) => x}
            options={options.list}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <DebouncedInputWrapped
                params={params}
                onChange={(event) => {
                  setInputValue(event.target.value);
                }}
              />
            )}
            renderOption={(option, { innerInputValue }) => (
              <Option value={option} inputValue={innerInputValue} />
            )}
            onChange={(_, value) => {
              onSelectHandler(value);
            }}
          />
        </Box>
      </Box>
      <Box>
        <Button className={styles.SecondaryButton} onClick={gotoSalesroom}>
          <span>Ir a sala de ventas</span>
        </Button>
        <Button
          className={styles.CreateButton}
          startIcon={<Add />}
          onClick={onClickNewClient}
        >
          <span>Nuevo cliente</span>
        </Button>
      </Box>
    </Box>
  );
};

Search.propTypes = {
  onSelectHandler: PropTypes.func.isRequired,
  onClickNewClient: PropTypes.func.isRequired,
};

export default Search;
