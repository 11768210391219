import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

const DateFilter = ({
  selectedDate,
  handleDateChange,
  clearFilter,
  limitDates,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Box display="flex" flexDirection="column" p={1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              autoOk
              inputVariant="outlined"
              variant="inline"
              openTo="year"
              minDate={limitDates.minimumDate}
              maxDate={limitDates.maximumDate}
              views={['year', 'month']}
              helperText=""
              error={false}
              label="Seleccione un mes y año para filtrar"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>
      <Grid
        container
        xs={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <IconButton onClick={clearFilter}>
          <ClearIcon color="secondary" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

DateFilter.propTypes = {
  selectedDate: PropTypes.object,
  handleDateChange: PropTypes.func,
  clearFilter: PropTypes.func,
  limitDates: PropTypes.object,
};

export default DateFilter;
