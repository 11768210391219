import React from 'react';
import { Box } from '@material-ui/core';
import useStateForViewController from 'App/TotalCashFlow/Core/customHooks/useStateForViewController';
import useTotalCashFlowViewController from '../../../Core/customHooks/useTotalCashFlowViewController';
import TotalCashFlowTabNavigator from '../../components/TotalCashFlowTabNavigator/TotalCashFlowTabNavigator';

const ViewController = ({ onRowChange, rows }) => {
  const { tableSelector } = useStateForViewController({ onRowChange, rows });
  const { tabs, selectedTab, changeTab } = useTotalCashFlowViewController();

  return (
    <Box>
      <TotalCashFlowTabNavigator onClick={changeTab} options={tabs} />
      {tableSelector(selectedTab)}
    </Box>
  );
};

export default ViewController;
