import { combineReducers } from 'redux';
import towerBuilderReducer from '../../components/TowerBuilder/reducer';
import {
  OPEN_BUILDER_DIALOG,
  CHANGE_TABLE_WATCHER,
  CHANGE_PROJECT_DATA,
  CHANGE_TOWERS_ARRAY,
  SET_CURRENT_TOWER,
  FETCH_LOADER,
  RESET_SELECTOR,
} from './action';

const initialState = {
  open: false,
  apiLoading: false,
  isTableVisible: true,
  project: {},
  towers: [],
  currentTower: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_BUILDER_DIALOG:
      return { ...state, open: payload };
    case FETCH_LOADER:
      return { ...state, apiLoading: payload };
    case CHANGE_TABLE_WATCHER:
      return { ...state, isTableVisible: payload };
    case CHANGE_PROJECT_DATA:
      return { ...state, project: payload };
    case CHANGE_TOWERS_ARRAY:
      return { ...state, towers: payload };
    case SET_CURRENT_TOWER:
      return { ...state, currentTower: payload };
    case RESET_SELECTOR:
      return (state = initialState);
    default:
      return state;
  }
};

export default combineReducers({
  root: reducer,
  builder: towerBuilderReducer,
});
