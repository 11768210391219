export const ProjectionTypeEnum = {
  EXECUTION: 'EXECUTION',
  EVALPRO: 'EVALPRO',
  EXTERNAL_SOURCE: 'EXTERNAL_SOURCE',
};

export const ProjectionTypes = {
  EXECUTION: {
    label: 'Ejecución',
    value: ProjectionTypeEnum.EXECUTION,
  },
  EVALPRO: {
    label: 'Evalpro',
    value: ProjectionTypeEnum.EVALPRO,
  },
  EXTERNAL_SOURCE: {
    label: 'Fuente Externa',
    value: ProjectionTypeEnum.EXTERNAL_SOURCE,
  },
};
