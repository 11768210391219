import React, { FC } from 'react';
import { Table } from '@devexpress/dx-react-grid';
import { Plugin, Template } from '@devexpress/dx-react-core';
import { isDataTableCell } from '../../../../../shared/tables/helpers/TableTemplateHelpers';
import { TotalColumnName } from '../../../Core/constants/AreasConstants';
import AreaEditionTotalTableCell from '../Cells/AreaEditionTableTotalCell';
import { AreaEditionTotalTotalProviderProps } from '../../../Core/customHooks/AreaEditionTable/AreaEditionTotalTotalProviderProps';

const AreaEditionTotalTableProvider: FC<AreaEditionTotalTotalProviderProps> = (props) => {
  return (
    <Plugin name="AreaEditionTotalTableProvider">
      <Template name="tableCell" predicate={isDataTableCell([TotalColumnName])}>
        {(params: Table.CellProps) => <AreaEditionTotalTableCell {...params} {...props} />}
      </Template>
    </Plugin>
  );
};

export default AreaEditionTotalTableProvider;
