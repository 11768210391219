import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { QueryClient, useMutation, useQueryClient } from 'react-query';

import Services from '../../../../services/Contracts/ContractsServices';

import { IUseFormResult, useForm } from '../../../../shared/customHooks/useForm';
import { Contractor } from '../../../../models/Contractors';
import { Contract, ContractItem, State, ForeignData } from '../../../../models/Contracts';

const services = new Services();

const useAddContractData = (handleCreate: (contract: Contract) => void) => {
  const { towerId }: { towerId: string } = useParams();
  const queryClient = useQueryClient();
  return useMutation(
    async (contract: Contract) => {
      const { data: newContract } = await services.createContract(towerId, {
        ...contract,
        towerId,
      });
      return newContract;
    },
    {
      onSuccess: (newContract) => {
        queryClient.invalidateQueries('contracts').then(() => {
          handleCreate(newContract);
        });
      },
    },
  );
};

const useEditContractData = (contractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (contract: Partial<Contract>) => {
      const { data: editedContract } = await services.editContract(contractId, contract);
      return editedContract;
    },
    {
      onSuccess: (editedContract) => {
        queryClient.setQueryData(['contract', contractId], editedContract);
        queryClient.invalidateQueries(['contracts']);
        queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
      },
    },
  );
};

export interface UseContractFormParams {
  currentContract?: Contract;
  handleCreate: (contract: Contract) => void;
  handleUpdate: () => void;
}

export interface UseContractFormResult extends IUseFormResult<Contract> {
  currentContract?: Contract;
  contractorsList?: Contractor[];
  contractStatesList?: State[];
  contractItemsList?: ContractItem[];
  autocompleteItemValue: ContractItem | null;
  setAutocompleteItemValue: Dispatch<SetStateAction<ContractItem | null>>;
  autocompleteContractorValue: Contractor | null;
  setAutocompleteContractorValue: Dispatch<SetStateAction<Contractor | null>>;
  autocompleteStateValue: State | null;
  setAutocompleteStateValue: Dispatch<SetStateAction<State | null>>;
  inputItemValue: string;
  setInputItemValue: Dispatch<SetStateAction<string>>;
  inputContractorValue: string;
  setInputContractorValue: Dispatch<SetStateAction<string>>;
  inputStateValue: string;
  setInputStateValue: Dispatch<SetStateAction<string>>;
  minValidDate: number;
  maxValidDate: number;
}

type HookType = (param: UseContractFormParams) => UseContractFormResult;

const useContractForm: HookType = ({ currentContract, handleCreate, handleUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addContract } = useAddContractData(handleCreate);
  const { mutateAsync: editContract } = useEditContractData(currentContract?.id || 0);

  const queryClient: QueryClient = useQueryClient();

  const [contractItemsList, setContractItems] = useState<ContractItem[]>();
  const [contractStatesList, setContractStates] = useState<State[]>();
  const [contractorsList, setContractors] = useState<Contractor[]>();
  const [autocompleteItemValue, setAutocompleteItemValue] = useState<ContractItem | null>(null);

  const [autocompleteContractorValue, setAutocompleteContractorValue] = useState<Contractor | null>(null);
  const [autocompleteStateValue, setAutocompleteStateValue] = useState<State | null>(null);

  const [inputItemValue, setInputItemValue] = useState('');
  const [inputContractorValue, setInputContractorValue] = useState('');
  const [inputStateValue, setInputStateValue] = useState('');
  const [minValidDate, setMinValidDate] = useState<number>(0);
  const [maxValidDate, setMaxValidDate] = useState<number>(0);

  const foreignData = queryClient.getQueryData<ForeignData>('contracts-foreign-list');

  useEffect(() => {
    if (!!foreignData) {
      const { contractCategories, contractStates, contractors, statistics } = foreignData as ForeignData;

      setMinValidDate(statistics.startStageDate);
      setMaxValidDate(statistics.endStageDate);

      setContractItems(contractCategories);
      setContractStates(contractStates);
      setContractors(contractors);
    }
    if (currentContract) {
      const itemSelected = contractItemsList?.find((v) => Number(v.id) == Number(currentContract?.itemId)) || null;
      setAutocompleteItemValue(itemSelected);

      const contractorSelected =
        contractorsList?.find((v) => Number(v.id) == Number(currentContract?.contractorId)) || null;
      setAutocompleteContractorValue(contractorSelected);

      const stateSelected = contractStatesList?.find((v) => Number(v.id) == Number(currentContract?.stateId)) || null;
      setAutocompleteStateValue(stateSelected);
    } else {
      setAutocompleteItemValue(null);
      setAutocompleteContractorValue(null);
      setAutocompleteStateValue(null);
    }
  }, [currentContract, foreignData]);

  const createContract = async (contract: Contract): Promise<void> => {
    try {
      await addContract(contract);
      enqueueSnackbar('Contrato creado con éxito', {
        variant: 'success',
      });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };

  const updateContract = async (contract: Contract): Promise<void> => {
    if (currentContract) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contract).forEach((keyString) => {
        const key = keyString as keyof Partial<Contract>;
        if (contract[key] !== currentContract[key]) updatedData[key] = contract[key];
      });

      if (Object.keys(updatedData).length) {
        const data = {
          id: contract.id,
          ...updatedData,
        };
        try {
          await editContract(data);
          enqueueSnackbar('Contrato actualizado con éxito', {
            variant: 'success',
          });
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
    handleUpdate();
  };

  const form = useForm<Contract>(
    {
      validations: {
        title: {
          required: {
            value: true,
            message: 'Título del contrato es requerido',
          },
        },
        contractorId: {
          required: {
            value: true,
            message: 'El proveedor es requerido',
          },
        },
        itemId: {
          required: {
            value: true,
            message: 'Grupo contable es requerido',
          },
        },
        stateId: {
          required: {
            value: true,
            message: 'Estado de negociación es requerido',
          },
        },
        fromDate: {
          custom: {
            isValid: (value: number, data?: Contract | undefined) => (data?.id ? value > 0 : true),
            message: 'Duración inicio es requerido',
          },
        },
        toDate: {
          custom: {
            isValid: (value: number, data?: Contract | undefined) => (data?.id ? value > 0 : true),
            message: 'Duración fin es requerido',
          },
        },
      },
      initialValues: {
        id: currentContract?.id || undefined,
        title: currentContract?.title || undefined,
        contractorId: currentContract?.contractorId || undefined,
        description: currentContract?.description || undefined,
        fromDate: currentContract?.fromDate || undefined,
        toDate: currentContract?.toDate || undefined,
        itemId: currentContract?.itemId || undefined,
        stateId: currentContract?.stateId || undefined,
      },
      async onSubmit(newData, reset) {
        try {
          if (currentContract?.id) {
            await updateContract(newData);
          } else {
            await createContract(newData);
            reset();
          }
        } catch (error) {
          enqueueSnackbar('Error interno servidor');
        }
      },
    },
    [currentContract],
  );

  return {
    ...form,
    contractItemsList,
    contractStatesList,
    contractorsList,
    autocompleteItemValue,
    setAutocompleteItemValue,
    autocompleteContractorValue,
    setAutocompleteContractorValue,
    autocompleteStateValue,
    setAutocompleteStateValue,
    inputItemValue,
    setInputItemValue,
    inputContractorValue,
    setInputContractorValue,
    inputStateValue,
    setInputStateValue,
    minValidDate,
    maxValidDate,
  };
};

export default useContractForm;
