import { useState } from 'react';

type MenuOption = {
  key: string;
  title: string;
  index: number;
  isOnlyForLots: boolean;
};

const getOptions = (): MenuOption[] => [
  {
    key: 'contract-performance-menu-item',
    title: 'Ejecución del contrato',
    index: 0,
    isOnlyForLots: false,
  },
  {
    key: 'contract-budget-menu-item',
    title: 'Presupuesto de Pagos',
    index: 1,
    isOnlyForLots: false,
  },
  {
    key: 'contract-events-menu-item',
    title: 'Hitos',
    index: 2,
    isOnlyForLots: false,
  },
  {
    key: 'contract-interest-rate-menu-item',
    title: 'Tasa de interés',
    index: 3,
    isOnlyForLots: true,
  },
];

const useContractDetailDrawerMenu = () => {
  const [options] = useState(getOptions());
  return { options };
};

export default useContractDetailDrawerMenu;
