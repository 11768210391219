import React, { FC } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useClientModal, {
  ClientModalProps,
} from 'App/Clients/Core/customHooks/useClientModal';
import AddClientModalContent from '../../AddClientModalContent/AddClientModalContent';
import UpdateClientModalContent from '../../UpdateClientModalContent/UpdateClientModalContent';
import CloseAlertModal from '../CloseAlertModal/CloseAlertModal';

import styles from '../ClientModal.module.scss';

const ClientModal: FC<ClientModalProps> = (props) => {
  const { client, isOpen, onChangeClient, openDetails } =
    props;
  const {
    agents,
    isCloseAlertOpen,
    handleConfirmAlert,
    closeCloseAlert,
    handleClickClose,
    haveChangesTrue,
    handleChangeClient,
  } = useClientModal(props);

  return (
    <>
      <Dialog open={isOpen} className={styles.clientModal} maxWidth="md" fullWidth>
        <CloseAlertModal
          isOpen={isCloseAlertOpen}
          handleClose={closeCloseAlert}
          handleConfirm={handleConfirmAlert}
        />
        <Box padding={2}>
          <DialogTitle className={styles.title}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">
                {!client?.id ? 'Nuevo Cliente' : 'Editar Cliente'}
              </Typography>
              <IconButton onClick={handleClickClose}>
                <CloseIcon className={styles.titleIcon}/>
              </IconButton>
            </Box>
          </DialogTitle>
          {!client?.id && (
            <AddClientModalContent
              agents={agents}
              onAddedClient={handleChangeClient}
              handleChangeState={haveChangesTrue}
              onCancel={handleClickClose}
            />
          )}
          {client?.id && (
            <UpdateClientModalContent
              onChangeClient={onChangeClient}
              client={client}
              agents={agents}
              openDetails={openDetails}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ClientModal;
