import { useSnackbar } from 'notistack';
import { ChangeEventHandler } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import CompanyServices from 'services/companies';
import useModal from 'shared/customHooks/useModal';
import { QuotationConfigDto } from '../DTO/QuotationConfigDto';
import { updateQuotationConfigQuotationSetupQueryData } from '../QuotationConfigQueryClient';
import useUpdateQuotationSetupQuery from './useUpdateQuotationSetup';

const services = new CompanyServices();
const useConfigureDocumentTemplateModal = (props: QuotationConfigDto) => {
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams<{ towerId: string }>();
  const { updateQuotationSetup: removeCompanyIconPath } = useUpdateQuotationSetupQuery('companyIconPath');
  const { updateQuotationSetup: removeProjectIconPath } = useUpdateQuotationSetupQuery('projectIconPath');
  const { mutate: uploadFile, isLoading } = useMutation(
    ({ form, isCompany }: { form: FormData; isCompany: boolean }) =>
      services
        .uploadQuotationIcons(towerId, isCompany ? 1 : 0, form)
        .then(({ data }: { data: QuotationConfigDto['quotationSetup'] }) => data),
    {
      onSuccess: (data) => {
        updateQuotationConfigQuotationSetupQueryData(data);
      },
    },
  );

  const onDropFile = (files: FileList, key?: string) => {
    const file = files[0];
    const form = new FormData();
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width !== 400 && img.height !== 400) {
        enqueueSnackbar('Las dimensiones permitidas para la imagen son de 400 x 400 pixeles', { variant: 'warning' });
        return;
      }
      form.append('file', file);
      uploadFile({ form, isCompany: key === 'companyIconPath' });
    };
  };

  const handleChangeFile =
    (key: 'companyIconPath' | 'projectIconPath'): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      e.preventDefault();
      if (e.target.files && e.target.files.length > 0) {
        onDropFile(e.target.files, key);
      }
    };

  return {
    ...modal,
    isLoading,
    onDropFile,
    handleChangeFile,
    removeCompanyIconPath,
    removeProjectIconPath,
  };
};
export default useConfigureDocumentTemplateModal;
