import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import GraphicMaterialServices from '../../services/GraphicMaterialServices';

const useGraphicMaterialDetails = imgId => {
  const [loading, setLoading] = useState(false);
  const [graphicMaterial, setGraphicMaterial] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const services = new GraphicMaterialServices();

  useEffect(() => {
    if (!imgId) {
      return;
    }
    setLoading(true);
    services.getGraphicMaterialDetails(imgId)
      .then(({ data: { graphicMaterial } }) => {
        setGraphicMaterial(graphicMaterial);
      })
      .catch(err => {
        const readableMessage = 'No se pudo obtener información del material gráfico';
        console.error(readableMessage, err.message);
        enqueueSnackbar(readableMessage, { variant: 'error' });
      })
      .then(() => setLoading(false));
  }, [imgId]);

  return {
    loading,
    graphicMaterial
  }
}

export default useGraphicMaterialDetails;