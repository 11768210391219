import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import ContractPaymentScheduleGridDifferenceFormatter from './ContractPaymentScheduleGridDifferenceFormatter';

const TableCellNonEditing = TableCellNonEditingComponent(ContractPaymentScheduleGridDifferenceFormatter);

const ContractPaymentScheduleGridDifferenceProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractPaymentScheduleGridDifferenceFormatter}
    editorComponent={TableCellNonEditing}
    {...props}
  />
);

export default ContractPaymentScheduleGridDifferenceProvider;
