import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Input from '../../../../../components/StrategyV2/Shared/Input/index';

const AddAdditional = ({
  open,
  handleSubmit,
  validationSchema,
  disabled,
  closeHandler,
  groupId = null,
}) => {
  return (
    <Dialog open={open === groupId}>
      <DialogTitle>Crear adicional</DialogTitle>
      <DialogContent>
        <DialogContentText>Ingrese el nombre del adicional</DialogContentText>
        <Formik
          initialValues={{
            groupId,
            name: null,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <Box mb={2}>
                <Field name="name" label="Nombre" component={Input} fullWidth />
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!values.name || disabled}
              >
                Guardar
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeHandler}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAdditional;
