import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { Box, InputBase } from '@material-ui/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import useEvalproDeedExpensesCellNumber from 'App/DeedExpenses/core/customHooks/useEvalproDeedExpensesCellNumber';
import { EvalproDeedExpensesCellNumberProps } from 'App/DeedExpenses/core/interfaces/EvalproDeedExpensesCellNumberProps';
import styles from './EvalproDeedExpensesCellNumber.module.scss';

const EvalproDeedExpensesCellNumber: FC<EvalproDeedExpensesCellNumberProps & DataTypeProvider.ValueFormatterProps> = ({
  value,
  row,
  handleChangeValue,
  isMoney,
}) => {
  const { newValue, handleChange, onSubmit, onKeyDown } = useEvalproDeedExpensesCellNumber(
    value,
    row.id,
    handleChangeValue,
  );

  return (
    <Box display="flex" justifyContent="center">
      <NumberFormat
        defaultValue={newValue}
        value={newValue}
        onValueChange={(values) => handleChange(values.floatValue)}
        onBlur={onSubmit}
        customInput={InputBase}
        onKeyDown={onKeyDown}
        // @ts-ignore
        fullWidth
        className={`${styles.EvalproDeedExpensesCellNumber} ${!isMoney ? styles.isStock : ''}`}
        thousandSeparator={isMoney}
        prefix={isMoney ? '$' : ''}
      />
    </Box>
  );
};

export default EvalproDeedExpensesCellNumber;
