import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import useGeneralWalletLoader from '../core/customHooks/useGeneralWalletLoader';
import GeneralWalletVirtualTable from './tables/GeneralWalletVirtualTable';
const queryClient = new QueryClient();

const GeneralWalletMainView: React.FC = () => {
  const { isLoading, setLoading, tableData } = useGeneralWalletLoader();

  return (
    <QueryClientProvider client={queryClient}>
      <GeneralWalletVirtualTable tableData={tableData} setLoading={setLoading} />
      {isLoading && <AbsoluteCircularProgress />}
    </QueryClientProvider>
  );
};

export default GeneralWalletMainView;
