import React, { FC, RefObject } from 'react';
import CustomPopover from 'shared/components/CustomPopover/CustomPopover';
import PopoverAction from 'shared/components/PopoverAction/PopoverAction';

interface Props {
  refDivElement: RefObject<HTMLDivElement>;
  onClickConfiguration: () => void;
}

const DeedExpensesSalesMadePopover: FC<Props> = ({ refDivElement, onClickConfiguration }) => {
  return (
    <CustomPopover<{}, {}>
      elementRef={refDivElement}
      contentComponent={({ onClose }) => (
        <PopoverAction
          onClick={() => {
            onClickConfiguration();
            onClose();
          }}
        >
          Configurar
        </PopoverAction>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      extraProps={{}}
    />
  );
};

export default DeedExpensesSalesMadePopover;
