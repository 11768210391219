import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Typography, Box, Paper } from '@material-ui/core';
import { updatePaymentCashflow } from '../Core/actions';
import TablePaymentFlow from './views/table/TablePaymentFlow';
import usePaymentQuotationMainStates from '../Core/customHooks/usePaymentQuotationMainStates';
import DownloadButton from '../../../components/UI/DownloadButton/DownloadButton';

const PaymentQuotationCashflow = ({ onFetchData, shouldLoad }) => {
  const { isLoading, downloadTemplate } = usePaymentQuotationMainStates(
    onFetchData,
  );

  return isLoading && shouldLoad ? (
    <Box display="flex" justifyContent="center" alignContent="center">
      <CircularProgress />
    </Box>
  ) : (
    <Paper>
      <Box p={2}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4">
              Flujo de caja de formas de pago
            </Typography>
          </Box>
          <DownloadButton onClick={downloadTemplate}>
            DESCARGAR EN EXCEL
          </DownloadButton>
        </Box>
        <TablePaymentFlow />
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  const tableRows = state.quotations.cashFlow.root.table;
  return {
    shouldLoad: tableRows.length < 1,
  };
};

const mapDispatchToProps = {
  onFetchData: updatePaymentCashflow,
};

PaymentQuotationCashflow.propTypes = {
  onFetchData: PropTypes.func.isRequired,
  shouldLoad: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentQuotationCashflow);
