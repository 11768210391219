import { Grid, Divider, Box, Toolbar } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { FC } from 'react';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Styles from './ContractsEvalproToolbar.module.scss';
import Typography from '../../../../shared/components/Typography/Typography';
import { Button } from '../../../../shared/components/Button/Button';
import Icon from '../../../../shared/components/Icon/Icon';
import BorderLinearProgress from '../linearProgress/BorderLinearProgress';

interface IContractsEvalproToolbar {
  handleChangeView: (event: React.MouseEvent<HTMLElement>, newContractsView: string) => void;
  evalProView: string;
  totalDistributed: number;
  download: () => void;
}

const ContractsEvalproToolbar: FC<IContractsEvalproToolbar> = ({
  totalDistributed,
  handleChangeView,
  evalProView,
  download,
}) => {
  return (
    <header>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="headline3">Flujo de Caja</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
              <Box display={evalProView === 'FC' ? 'inherit' : 'none'}>
                <Button
                  variant="outlined"
                  disableElevation
                  startIcon={<Icon variant="Download" color="primary600" />}
                  onClick={download}
                >
                  <Typography variant="subtitle1" color="primary600">
                    Descargar
                  </Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Toolbar disableGutters variant="dense">
                <ToggleButtonGroup size="medium" value={evalProView} exclusive onChange={handleChangeView}>
                  <ToggleButton
                    value="detailed"
                    focusRipple
                    classes={{
                      root: Styles.toggleButton,
                      selected: Styles.toggleButtonSelected,
                    }}
                  >
                    <ViewListOutlinedIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton
                    value="FC"
                    focusRipple
                    classes={{
                      root: Styles.toggleButton,
                      selected: Styles.toggleButtonSelected,
                    }}
                  >
                    <AccountBalanceOutlinedIcon fontSize="small" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Typography variant="body1" color="gray700">
            Progreso total
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Box display="flex" alignItems="center">
            <Box width="100%">
              <BorderLinearProgress variant="determinate" value={totalDistributed} />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption" color="gray600">{`${Math.round(totalDistributed)}%`}</Typography>
          </Box>
        </Grid>
      </Grid>
    </header>
  );
};

export default ContractsEvalproToolbar;
