import React, { FC } from 'react';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableBandHeader,
  TableTreeColumn,
  TableFixedColumns,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import VirtualTableDataTypeProvider from 'shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import useMonthlyInterestTableRows from '../../core/customHooks/useMonthlyInterestTableRows';
import TableCell from '../../../../shared/tables/TableCell';
import { MonthlyPaymentForReport } from '../../core/DTO/WalletMonthlyReportDTO';
import TableMonthlyInterestCellsProvider from '../plugins/TableMonthlyInterestCellsProvider';
import MonthlyInterestFixedCell from '../cells/MonthlyInterestFixedCell';
import MonthlyInterestEmptyProvider from '../plugins/MonthlyInterestEmptyProvider';
import MonthlyInterestHeaderCell from '../cells/MonthlyInterestHeaderCell';
import MonthlyInterestBandHeaderCell from '../cells/MonthlyInterestBandHeaderCell';
import MonthlyInterestHeaderRow from '../cells/MonthlyInterestHeaderRow';
import { WalletPaymentDistribution } from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';

interface MonthlyInterestArrearsTableProps {
  monthlyReports: MonthlyPaymentForReport[];
  propertyPlan: WalletPaymentDistribution[];
  propertyId: number;
  isAllInPropertyChecked: boolean;
  reload: () => Promise<void>;
}

const MonthlyInterestArrearsTable: FC<MonthlyInterestArrearsTableProps> = ({
  monthlyReports,
  propertyPlan,
  propertyId,
  isAllInPropertyChecked,
  reload,
}) => {
  const {
    rows,
    columns,
    columnBands,
    leftColumns,
    isAllChecked,
    findParentFromColumnName,
    onValueChanged,
    updateCheckAll,
  } = useMonthlyInterestTableRows(monthlyReports, propertyPlan, propertyId, isAllInPropertyChecked, reload);

  return (
    <div>
      <Grid rows={rows} columns={columns}>
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.dateFormat} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.percent} />
        <VirtualTableDataTypeProvider
          columns={columns}
          type={[ProviderColumnType.currency, ProviderColumnType.greenPositive]}
        />
        <MonthlyInterestEmptyProvider
          watchedColumns={['commitmentDate']}
          columns={columns}
          type={ProviderColumnType.dateFormat}
        />
        <MonthlyInterestEmptyProvider
          watchedColumns={['paymentName']}
          columns={columns}
          type={ProviderColumnType.plain}
        />
        <VirtualTable columnExtensions={columns} cellComponent={TableCell} height="75vh" />
        <TableHeaderRow
          cellComponent={MonthlyInterestHeaderCell(updateCheckAll, isAllChecked)}
          rowComponent={MonthlyInterestHeaderRow}
        />
        {/* <TableBandHeader columnBands={columnBands} /> */}
        <TableFixedColumns leftColumns={leftColumns} cellComponent={MonthlyInterestFixedCell} />
        <TableMonthlyInterestCellsProvider
          findParentFromColumnName={findParentFromColumnName}
          onValueChanged={onValueChanged}
        />
      </Grid>
    </div>
  );
};

export default MonthlyInterestArrearsTable;
