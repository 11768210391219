import { Box, Grid, Paper } from '@material-ui/core';
import React, { Component } from 'react';
import colors from '../../../../../assets/styles/settings/colors.module.scss';
import styled from 'styled-components';

export const CustomBuyerGrid = styled(Grid)`
  cursor: pointer;
  flex-basis: 0;
`;

export const CustomBuyerPointer = styled(Grid)`
  cursor: pointer;
`;

export const CustomBuyerPaper = styled(Paper)`
  :hover {
    background-color: ${(props) =>
      props.isSelected ? 'rgb(52, 52, 56, 0.9)' : 'rgba(0, 0, 0, 0.03)'};
  }

  background-color: ${(props) =>
    props.isSelected ? 'rgba(52, 52, 56,1)' : 'inherit'};
  color: ${(props) => (props.isSelected ? 'white' : 'inherit')};
`;

export const CustomBoxForState = styled(Box)`
  color: ${(props) => {
    switch (props.currentState) {
      case 'completed':
        return colors.stateSuccess600;
      case 'isArreas':
        return colors.stateError600;
      default:
        return colors.stateWarning600;
    }
  }};

  &::before {
    content: '';
    margin: 0 10px;
    background-color: ${(props) => {
      switch (props.currentState) {
        case 'completed':
          return colors.stateSuccess600;
        case 'isArreas':
          return colors.stateError600;
        default:
          return colors.stateWarning600;
      }
    }};
    border-radius: 50%;
    width: 5px;
    height: 5px;
  }
`;

export const CustomBadgeForState = styled(Box)`
  background-color: ${(props) =>
    props.currentState === 'completed'
      ? colors.stateSuccess600
      : colors.stateWarning600};
  border-radius: 50%;
  width: 15px;
  height: 15px;
`;

export const colorForState = (state) => {
  switch (state) {
    case 'missing':
      return 'rgba(52, 52, 56,1)';
    case 'editing':
      return 'rgba(122,49,140,1)';
    case 'completed':
      return 'rgba(22,219,114,1)';
    default:
      return 'rgba(224,74,101,1)';
  }
};
