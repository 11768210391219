import { Role } from '../../../../helpers';

class StagesUserRole {
  private static readonly isSuperUser = (userType: string) => userType === Role.Super;

  private static readonly isAdminUser = (userType: string) => userType === Role.Admin;

  static isUser(userType) {
    return userType === Role.User;
  }

  static isSales(userType) {
    return userType === Role.Sales;
  }

  static isSuperOrAdminUser(userType: string) {
    return this.isSuperUser(userType) || this.isAdminUser(userType);
  }
}

export default StagesUserRole;
