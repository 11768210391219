import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import useRealPaymentEditionFinalType from '../../Core/customHooks/useRealPaymentEditionFinalType';

const RealPaymentsFinalPaymentTypeRow = ({ showEditableButtons }) => {
  const {
    paymentTypeSelected,
    isEditingMode,
    options,
    onChange,
  } = useRealPaymentEditionFinalType();

  return (
    <Box>
      <FormControl>
        <InputLabel></InputLabel>
        <Select
          native
          value={paymentTypeSelected}
          onChange={onChange}
          disabled={!isEditingMode || !showEditableButtons}
          inputProps={{
            name: 'payment',
            id: `payment-type_final`,
          }}
        >
          {options.map((option) => (
            <option key={`payment_type_${option.id}`} value={option.id}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

RealPaymentsFinalPaymentTypeRow.propTypes = {
  showEditableButtons: PropTypes.bool,
};

export default RealPaymentsFinalPaymentTypeRow;
