import { useState } from 'react';
import SegmentedControlOption from '../models/SegmentedControlOption';

function useSegmentedControlHandlingChange(
  options: SegmentedControlOption[],
  onSegmentChange: (id: string) => void,
) {
  const [currentValue, setValue] = useState<string>(options[0]?.value || '');

  const onClick = (id: string) => {
    setValue(id);
    onSegmentChange(id);
  };

  return {
    currentValue,
    onClick,
  };
}

export default useSegmentedControlHandlingChange;
