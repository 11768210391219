import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { CheckOutlined, Close, InfoOutlined, ReportProblemOutlined } from '@material-ui/icons';
import Typography from 'shared/components/Typography/Typography';
import useBoolean from 'shared/customHooks/useBoolean';

import styles from './QuotationConfigAlert.module.scss';

interface QuotationConfigLimitDateFormProps {
  state: 'success' | 'warning' | 'info';
  text?: string;
  closable?: boolean;
}
const QuotationConfigAlert: FC<QuotationConfigLimitDateFormProps> = ({ state, text, closable, children }) => {
  const { value, setFalse } = useBoolean(true);
  return (
    <>
      {value && (
        <div className={`${styles.QuotationConfigAlert} ${styles[state]}`}>
          {state === 'warning' && <ReportProblemOutlined className={`${styles.Icon} ${styles.IconWaring}`} />}
          {state === 'info' && <InfoOutlined className={`${styles.Icon} ${styles.IconInfo}`} />}
          {state === 'success' && <CheckOutlined className={`${styles.Icon} ${styles.IconSuccess}`} />}
          <div>
            <Typography variant="body1">{children || text}</Typography>
          </div>
          <div>
            {closable && (
              <IconButton onClick={setFalse}>
                <Close />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default QuotationConfigAlert;
