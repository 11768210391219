import { CircularProgress } from '@material-ui/core';
import React from 'react';

import Typography from 'shared/components/Typography/Typography';
import useSalesStatusLoader from '../core/customHooks/useSalesStatusLoader';
import { SalesStatusProperty } from '../core/DTO/SalesStatusViewDTO';
import SalesStatusStates from '../core/enums/SalesStatusStates';
import SalesStatusNoDataView from './components/SalesStatusNoDataView/SalesStatusNoDataView';
import SalesStatusContentView from './components/SalesStatusContentView/SalesStatusContentView';

import styles from './SalesStatusMainView.module.scss';
import useUpdateSalesStatus from '../core/customHooks/useUpdateSalesStatus';
import { ChangeSalesStatusHandler } from '../core/types/SalesStatus.types';

const loadingView = (
  <div className={styles.loader}>
    <CircularProgress />
  </div>
);

type MainViewsProps = {
  viewState: SalesStatusStates;
  towerId: string;
  properties: SalesStatusProperty[];
  onChangeSalesStatus: ChangeSalesStatusHandler;
};

const getNoDataStateView = ({ viewState, towerId }: MainViewsProps) => (
  <SalesStatusNoDataView viewState={viewState} towerId={towerId} />
);

const getContentView = ({ properties, onChangeSalesStatus }: MainViewsProps) => (
  <SalesStatusContentView properties={properties} onChangeSalesStatus={onChangeSalesStatus} />
);

type MainViews = Record<SalesStatusStates, React.FC<MainViewsProps>>;

const mainViews: MainViews = {
  [SalesStatusStates.Loading]: () => loadingView,
  [SalesStatusStates.Error]: () => <></>,
  [SalesStatusStates.NoAreas]: getNoDataStateView,
  [SalesStatusStates.NoScheme]: getNoDataStateView,
  [SalesStatusStates.Complete]: getContentView,
};

const SalesStatusMainView: React.FC = () => {
  const { viewState, towerId, properties, updateProperties } = useSalesStatusLoader();
  const handleSalesStatusChange = useUpdateSalesStatus({ towerId, properties, updateProperties });
  const mainViewsProps: MainViewsProps = {
    viewState,
    towerId,
    properties,
    onChangeSalesStatus: handleSalesStatusChange,
  };

  return (
    <div className={styles.root}>
      <div className={styles.toolbar}>
        <Typography className={styles.toolbarTitle} variant="headline4" as="h1">
          Estado de ventas
        </Typography>
      </div>
      <div className={styles.content}>{mainViews[viewState](mainViewsProps)}</div>
    </div>
  );
};

export default SalesStatusMainView;
