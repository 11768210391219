import React, { FC } from 'react';
import { Table } from '@devexpress/dx-react-grid';
import TableCell from 'shared/tables/TableCell';
import styled from 'styled-components';
import { MonthlyEventType, MonthlyPaymentRow } from '../../core/DTO/WalletMonthlyReportDTO';
import { ConvertColumnsToColumnBandsFunction, ParentColumnForBand } from '../../../../shared/tables/Core/TableHelpers';
import { MONTHLY_PAYMENT_FINANCIAL } from 'App/MonthlyInterestReport/core/customHooks/useMonthlyInterestTableRows';

interface MonthlyInterestTableCellProps {
  children: React.ReactNode;
  params: Table.CellProps;
  value: any;
  findParentFromColumnName: ConvertColumnsToColumnBandsFunction;
}

const ParentTableCell: React.FC<Table.DataCellProps> = styled(TableCell)<Table.DataCellProps>`
  background-color: #00a3a9;
  color: #fff;
`;
const PlaceholderTableCell: React.FC<Table.DataCellProps> = styled(TableCell)<Table.DataCellProps>`
  border-right: 0px;
  border-left: 0px;
`;
const PlaceholderColumnCell: React.FC<Table.DataCellProps> = styled(TableCell)<Table.DataCellProps>`
  border-right: 1px solid #a80072;
  border-left: 1px solid #a80072;
  background-color: ${(props) => {
    const paymentRow: MonthlyPaymentRow = props.tableRow.row;
    return paymentRow.type === MonthlyEventType.fee ? '#00a3a9' : 'inherit';
  }};
`;

const CustomTableCell: React.FC<Table.DataCellProps> = styled(TableCell)<Table.DataCellProps>``;

const MonthlyInterestTableCell: FC<MonthlyInterestTableCellProps> = ({
  children,
  params,
  value,
  findParentFromColumnName,
}) => {
  const paymentRow: MonthlyPaymentRow = params.tableRow.row;
  const parentColumn = findParentFromColumnName(params.tableColumn.column?.name ?? '');

  if (parentColumn?.parentColumn === MONTHLY_PAYMENT_FINANCIAL && paymentRow?.type === MonthlyEventType.fee) {
    return <ParentTableCell {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={null} />;
  }

  if (params.tableColumn.column?.name === 'placeholderColumn') {
    return (
      <PlaceholderColumnCell {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={null} />
    );
  }

  if (paymentRow?.type === MonthlyEventType.fee) {
    return (
      <ParentTableCell {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={value}>
        {children}
      </ParentTableCell>
    );
  }

  if (paymentRow?.type === MonthlyEventType.placeholder) {
    return (
      <PlaceholderTableCell {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={null} />
    );
  }

  if (paymentRow?.type === MonthlyEventType.balance) {
    if (params.tableColumn.column?.name === 'financialAppliedValue') {
      const currentValue = value as { value: 0; isChecked: false };
      return (
        <CustomTableCell
          {...params}
          row={params.tableRow.row}
          column={params.tableColumn.column!}
          value={currentValue.value}
        >
          {children}
        </CustomTableCell>
      );
    }
    if (params.tableColumn.column?.name === 'commitment') {
      return (
        <CustomTableCell {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={value}>
          {children}
        </CustomTableCell>
      );
    }
  }

  return (
    <CustomTableCell {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={value}>
      {children}
    </CustomTableCell>
  );
};

export default MonthlyInterestTableCell;
