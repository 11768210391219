import EnumBase from '../../../../helpers/EnumBase';

class ClientIdentityType {
  static DEFAULT = new EnumBase(-1, '');

  static ADMIN = new EnumBase(0, 'Administración');

  static AIRPORT = new EnumBase(1, 'Aeropuerto');

  static AGENCY = new EnumBase(2, 'Agencia');

  static GROUPING = new EnumBase(3, 'Agrupación');

  static LOFT = new EnumBase(4, 'Altillo');

  static WAREHOUSE = new EnumBase(5, 'Alacen');

  static APARTMENT = new EnumBase(6, 'Apartamento');

  static HEAD_LAND = new EnumBase(7, 'Apartado');

  static STORE = new EnumBase(8, 'Bodega');

  static BLOCK = new EnumBase(9, 'Bloque');

  static BOULEVARD = new EnumBase(10, 'Boulevard');

  static NEIGHBORHOOD = new EnumBase(11, 'Barrio');

  static CORREGIMIENTO = new EnumBase(12, 'Corregimiento');

  static HOUSE = new EnumBase(13, 'Casa');

  static HAMLET = new EnumBase(14, 'Caserio');

  static MALL = new EnumBase(15, 'Centro comercial');

  static CITADEL = new EnumBase(16, 'Ciudadela');

  static RESIDENTIAL = new EnumBase(17, 'Conjunto residencial');

  static SET = new EnumBase(18, 'Conjunto');

  static CONSULTING_ROOM = new EnumBase(19, 'Consultorio');

  static RESERVOIR = new EnumBase(20, 'Depósito');

  static DEPARTMENT = new EnumBase(21, 'Departamento');

  static RESERVOIR_BASEMENT = new EnumBase(22, 'Depósito sótano');

  static BUILDING = new EnumBase(23, 'Edificio');

  static STAIRS = new EnumBase(24, 'Escalera');

  static CORNER = new EnumBase(25, 'Esquina');

  static STAGE = new EnumBase(26, 'Etapa');

  static FARM = new EnumBase(27, 'Finca');

  static GARAGE = new EnumBase(28, 'Garage');

  static GARAGE_BASEMENT = new EnumBase(29, 'Garage sótano');

  static ROUNDABOUT = new EnumBase(30, 'Glorieta');

  static FARM_STATE = new EnumBase(31, 'Hacienda');

  static HANGAR = new EnumBase(32, 'Hangar');

  static INTERIOR = new EnumBase(33, 'Interior');

  static POLICE = new EnumBase(34, 'Policia');

  static INSPECCION_DEPARTAMENTAL = new EnumBase(
    35,
    'Inspección departamental',
  );

  static INSPECCION_MUNICIPAL = new EnumBase(36, 'Inspección municipal');

  static KILOMETRO = new EnumBase(37, 'Kilometro');

  static LOCAL = new EnumBase(38, 'Local');

  static LOCAL_MEZZANINE = new EnumBase(39, 'Local mezzanine');

  static LOTE = new EnumBase(40, 'Lote');

  static MODULE = new EnumBase(41, 'Modulo');

  static MOJON = new EnumBase(42, 'Mojón');

  static PIER = new EnumBase(43, 'Muelle');

  static MEZZANINE = new EnumBase(43, 'Mezzanine');

  static MANZANA = new EnumBase(44, 'Manzana');

  static OFFICE = new EnumBase(45, 'Oficina');

  static FLOOR = new EnumBase(46, 'Piso');

  static PARCELA = new EnumBase(47, 'Parcela');

  static PARQUE = new EnumBase(48, 'Parque');

  static PREDIO = new EnumBase(49, 'Predio');

  static PENTHOUSE = new EnumBase(50, 'Penthouse');

  static PLANTA = new EnumBase(51, 'Planta');

  static PUENTE = new EnumBase(52, 'Puente');

  static PORTERIA = new EnumBase(53, 'Porteria');

  static POSTE = new EnumBase(54, 'Poste');

  static PARKING = new EnumBase(55, 'Parking');

  static PARAJE = new EnumBase(56, 'Paraje');

  static PUESTO = new EnumBase(57, 'Puesto');

  static ROUND_POINT = new EnumBase(58, 'Round Point');

  static SALON = new EnumBase(59, 'Salon');

  static SALON_COMUNAL = new EnumBase(60, 'Salón Comunal');

  static SALIDA = new EnumBase(61, 'Salida');

  static SECTOR = new EnumBase(62, 'Sector');

  static SOLAR = new EnumBase(63, 'Solar');

  static SUPER_MANZANA = new EnumBase(64, 'Super Manzana');

  static SEMI_BASEMENT = new EnumBase(65, 'Semi Basement');

  static BASEMENT = new EnumBase(66, 'Basement');

  static SUITE = new EnumBase(67, 'Suite');

  static TERMINAL = new EnumBase(68, 'Terminal');

  static TERRAPIEN = new EnumBase(69, 'Terrapién');

  static TOWER = new EnumBase(70, 'Torre');

  static TERRACE = new EnumBase(71, 'Terraza');

  static UNIDAD = new EnumBase(72, 'Unidad');

  static UNIDAD_RESIDENCIAL = new EnumBase(73, 'Unidad residencial');

  static URBANIZACION = new EnumBase(74, 'Urbanización');

  static VEREDA = new EnumBase(75, 'Vereda');

  static ZONA_FRANCA = new EnumBase(76, 'Zona Franca');

  static ZONE = new EnumBase(77, 'Zona');

  static findOneByValue(value) {
    const keyForValue = Object.keys(ClientIdentityType).find(
      (key) => ClientIdentityType[key].value === value,
    );
    return ClientIdentityType[keyForValue];
  }

  static findOneByCode(code) {
    const keyForValue = Object.keys(ClientIdentityType).find(
      (key) => ClientIdentityType[key].code === code,
    );
    return ClientIdentityType[keyForValue];
  }
}

export default ClientIdentityType;
