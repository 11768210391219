import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Item from '../../../../components/Financial/Dialog/Info/BankCredit/Dialog/Item';
import LabelEditor from '../../../../components/Financial/Dialog/Info/BankCredit/Dialog/LabelEditor';
import useBankFinancialEditionsDialog from '../../Core/customHooks/useBankFinancialEditionsDialog';
import Numbers from '../../../../helpers/numbers';

const QuotationBankCreditDialog = ({
  open,
  totalPaymentCredit,
  totalYears,
  months,
  annualEffectiveRate,
  monthlyRate,
  closeHandler,
  quotation,
  updateQuotation,
}) => {
  const {
    editAnnualEffectiveRate,
    editTotalYears,
    setAnnualEffectiveRateEdition,
    setTotalYearsEdition,
    updateAnnualRate,
    updateTermLimit,
  } = useBankFinancialEditionsDialog(quotation, updateQuotation);
  return (
    <>
      <MuiDialog open={open}>
        <DialogTitle>Crédito Banco</DialogTitle>
        <DialogContent>
          <Paper variant="outlined">
            <Grid container>
              <Grid item xs={12}>
                <Item
                  left="Crédito"
                  right={
                    <NumberFormat
                      value={Math.round(totalPaymentCredit)}
                      displayType="text"
                      prefix="$ "
                      thousandSeparator
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Item
                  left="Años"
                  right={
                    <LabelEditor
                      editMode={editTotalYears}
                      units={totalYears}
                      submitHandler={(values) => {
                        updateTermLimit(values.units);
                      }}
                      toggleEditModeHandler={() => {
                        setTotalYearsEdition(!editTotalYears);
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Item left="Meses" right={months} />
              </Grid>

              <Grid item xs={12}>
                <Item
                  left="Tasa ea"
                  right={
                    <LabelEditor
                      editMode={editAnnualEffectiveRate}
                      units={Numbers.toFixed(annualEffectiveRate * 100)}
                      suffix="%"
                      submitHandler={(values) => {
                        updateAnnualRate(values.units);
                      }}
                      toggleEditModeHandler={() => {
                        setAnnualEffectiveRateEdition(!editAnnualEffectiveRate);
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Item
                  left="Tasa em"
                  right={
                    <NumberFormat
                      value={Numbers.toFixed(monthlyRate)}
                      displayType="text"
                      suffix="%"
                      thousandSeparator
                    />
                  }
                  noDivider
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeHandler}>
            Cerrar
          </Button>
        </DialogActions>
      </MuiDialog>
    </>
  );
};

QuotationBankCreditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  totalPaymentCredit: PropTypes.number.isRequired,
  totalYears: PropTypes.number.isRequired,
  months: PropTypes.number.isRequired,
  annualEffectiveRate: PropTypes.number.isRequired,
  monthlyRate: PropTypes.number.isRequired,
  closeHandler: PropTypes.func.isRequired,
  updateTotalYears: PropTypes.func.isRequired,
  updateAnnualEffectiveRate: PropTypes.func.isRequired,
  quotation: PropTypes.object,
  updateQuotation: PropTypes.func,
};

export default QuotationBankCreditDialog;
