import { API_PATH } from '../../config/config';

const ContractPaymentsServicesDefinition = {
  createContractPaymentSchedule: (contractPaymentId: number) =>
    `${API_PATH}contracts/payments-schedule/${contractPaymentId}`,
  editContractPaymentSchedule: (contractPaymentScheduleId: number) =>
    `${API_PATH}contracts/payments-schedule/${contractPaymentScheduleId}`,
  bulkEditContractPaymentSchedules: (contractPaymentId: number) =>
    `${API_PATH}contracts/payments-schedule/bulk/${contractPaymentId}`,
};

export default ContractPaymentsServicesDefinition;
