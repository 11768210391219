import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { ITabOption } from '../../../Core/interfaces/ITabOptionForResultsState';
import Typography from 'shared/components/Typography/Typography';
import styles from './ResultsStateTabOption.module.scss';

const ResultsStateTabOption =
  (onClick: (option: ITabOption) => void): FC<ITabOption> =>
  (option: ITabOption) =>
    (
      <Box
        key={option.id}
        className={`${styles.resultsStateTabOption} ${option.selected ? styles.Selected : ''}`}
        onClick={() => onClick(option)}
      >
        <Box>
          <Typography>{option.label}</Typography>
        </Box>
      </Box>
    );

export default ResultsStateTabOption;
