import React, { FC } from 'react';
import { QuotationConfigDto } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import QuotationConfigCollapse from '../components/QuotationConfigCollapse/QuotationConfigCollapse';
import { Box, Grid } from '@material-ui/core';
import QuotationConfigFormItem from './QuotationConfigFormItem';
import Typography from 'shared/components/Typography/Typography';
import QuotationConfigFeeItem from '../components/QuotationConfigFeeItem/QuotationConfigFeeItem';
import useUpdateQuotationSetupQuery from 'App/QuotationConfig/core/customHooks/useUpdateQuotationSetup';

const CustomerFinancingRateForm: FC<QuotationConfigDto> = ({ quotationSetup }) => {
  const { clientDiscountSalesFee, clientDiscountConstructionFee } = quotationSetup;
  const { updateValue: updateClientDiscountSalesFee } = useUpdateQuotationSetupQuery('clientDiscountSalesFee');
  const { updateValue: updateClientDiscountConstructionFee } = useUpdateQuotationSetupQuery(
    'clientDiscountConstructionFee',
  );
  return (
    <QuotationConfigCollapse
      name="Tasa Financiación de Clientes"
      state={!!clientDiscountSalesFee && !!clientDiscountConstructionFee ? 'success' : 'warning'}
    >
      <Box display="flex" justifyContent="center" padding="40px" paddingY="32px" paddingBottom="55px">
        <Grid container item xs={10}>
          <QuotationConfigFormItem item xs={6} container>
            <QuotationConfigFormItem item xs={12}></QuotationConfigFormItem>
            <QuotationConfigFormItem item xs={12}>
              <Typography fontWeight="500">Efectivo Anual</Typography>
            </QuotationConfigFormItem>
            <QuotationConfigFormItem item xs={12}>
              <Typography fontWeight="500">Efectivo mensual</Typography>
            </QuotationConfigFormItem>
          </QuotationConfigFormItem>
          <QuotationConfigFormItem item xs={6} container>
            <QuotationConfigFormItem item xs={6} container>
              <QuotationConfigFormItem item xs={12}>
                <Typography fontWeight="500" color="gray600">
                  Preventa
                </Typography>
              </QuotationConfigFormItem>
              <QuotationConfigFeeItem
                id="clientDiscountSalesFee"
                handleChange={updateClientDiscountSalesFee}
                initialValue={clientDiscountSalesFee}
              />
            </QuotationConfigFormItem>
            <QuotationConfigFormItem item xs={6} container>
              <QuotationConfigFormItem item xs={12}>
                <Typography fontWeight="500" color="gray600">
                  Construcción
                </Typography>
              </QuotationConfigFormItem>
              <QuotationConfigFeeItem
                id="clientDiscountConstructionFee"
                handleChange={updateClientDiscountConstructionFee}
                initialValue={clientDiscountConstructionFee}
              />
            </QuotationConfigFormItem>
          </QuotationConfigFormItem>
        </Grid>
      </Box>
    </QuotationConfigCollapse>
  );
};

export default CustomerFinancingRateForm;
