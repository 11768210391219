import { useCallback } from 'react';
import useAgents from 'App/Agents/Core/customHooks/useAgents';
import useBoolean from 'shared/customHooks/useBoolean';
import useModal from 'shared/customHooks/useModal';
import { ClientModel } from '../models/ClientModel';

export interface ClientModalProps {
  client?: ClientModel;
  isOpen: boolean;
  onChangeClient: (client: ClientModel) => void;
  onCloseHandler: () => void;
  openDetails: (client: ClientModel) => void
}

const useClientModal = (props: ClientModalProps) => {
  const { onChangeClient, onCloseHandler } = props
  const [agents] = useAgents();
  const {
    isOpen: isCloseAlertOpen,
    open: openCloseAlert,
    close: closeCloseAlert,
  } = useModal();

  const {
    value: haveChanges,
    setTrue: haveChangesTrue,
    setFalse: haveChangesFalse
  } = useBoolean();

  const handleClickClose = useCallback(() => {
    if (haveChanges)
      openCloseAlert()
    else
      onCloseHandler();
  }, [haveChanges]);

  const handleConfirmAlert = () => {
    closeCloseAlert();
    onCloseHandler();
  }

  const handleChangeClient = (client: ClientModel) => {
    onChangeClient(client)
    haveChangesFalse()
  }

  return {
    agents,
    isCloseAlertOpen,
    haveChanges,
    closeCloseAlert,
    haveChangesTrue,
    handleClickClose,
    handleConfirmAlert,
    handleChangeClient
  };
};

export default useClientModal;
