import { API_PATH } from '../../../config/config';

const TotalCashFlowServicesDefinitions = {
  total: (towerId) => `${API_PATH}cash-flow/total-sales/${towerId}`,
  updateTotalCashFlow: (towerId) => `${API_PATH}cash-flow/total-sales/${towerId}/flow`,
  downloadQuotationCashflow: (towerId) =>
    `${API_PATH}cash-flow/total-sales/download/${towerId}`,
};

export default TotalCashFlowServicesDefinitions;
