const useFilterOptionsForAdditionalArea = () => {
  const filterOptions = (options, state) => {
    if (state.inputValue === '') return options;
    const formatted = (string) => {
      return string.toLowerCase().replace(/\s/g, '');
    };
    const formattedText = formatted(state.inputValue);
    const filtered = options.filter((option) => {
      return (
        formatted(option.nomenclature).includes(formattedText) ||
        formatted(option.areaType.name).includes(formattedText) ||
        formatted(option.areaType.name + option.nomenclature).includes(
          formattedText,
        ) ||
        formatted(`${option.areaType.name}-${option.nomenclature}`).includes(
          formattedText,
        ) ||
        formatted(option.nomenclature + option.areaType.name).includes(
          formattedText,
        )
      );
    });
    return filtered;
  };
  return { filterOptions };
};

export default useFilterOptionsForAdditionalArea;
