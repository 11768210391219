import React from 'react';
import { useSelector } from 'react-redux';
import useAddEventForResultsState from '../../../../../../Core/customHooks/useAddEventForResultsState';
import useRenameEventForResultsState from '../../../../../../Core/customHooks/useRenameEventForResultsState';
import IconButton from '../../../../../../../../shared/components/IconButton/IconButton';
import { Menu, MenuItem } from '@material-ui/core';
import SaveEventForResultsState from '../../../../../components/Dialogs/BudgetDialogs/SaveEventForResultsState';
import styles from '../BandCell.module.scss';
import ReactTooltip from 'react-tooltip';
import { Typography, Box } from '@material-ui/core';
import useEventsControlBandCell from '../../../../../../Core/customHooks/useEventsControlBandCell';
import { events } from '../../../../../../Core/constants/BudgetsConstants';
import RenameEventForResultsState from '../../../../../components/Dialogs/BudgetDialogs/RenameEventForResultsState/RenameEventForResultsState';

const EventsControlBandCell = ({ children, column }) => {
  const { selectedBudget } = useSelector((state) => state.cashFlow.root || {});

  const emptyControl = 'Evento vacio';

  const { open, handleClose, setOpen } = useAddEventForResultsState();

  const { openRename, handleCloseRename, setOpenRename } = useRenameEventForResultsState();

  const budgetWithEvent = column?.title !== emptyControl;

  const {
    deleteEventsHandleClick,
    overwriteEventHandleClick,
    changeBudget,
    handleClick,
    handleCloseMenu,
    handleEditableCells,
    anchorEl,
    openMenu,
  } = useEventsControlBandCell();

  return (
    <>
      <Box className={styles.budgetSelector}>
        <IconButton
          iconName="icon-keyboard_arrow_left"
          className={styles.arrowColor}
          onClick={() => {
            let newSelectedBudget;
            if (selectedBudget === 1) {
              newSelectedBudget = 3;
            } else {
              newSelectedBudget = selectedBudget - 1;
            }
            changeBudget(newSelectedBudget);
          }}
        />
        <Typography className={styles.budgetSelectorTypography}>{events[selectedBudget - 1].value}</Typography>
        <IconButton
          iconName="icon-keyboard_arrow_right"
          className={styles.arrowColor}
          onClick={() => {
            let newSelectedBudget;
            if (selectedBudget === 3) {
              newSelectedBudget = 1;
            } else {
              newSelectedBudget = selectedBudget + 1;
            }
            changeBudget(newSelectedBudget);
          }}
        />
      </Box>
      <Box className={styles.eventNameContainer}>
        <strong data-tip data-for={`budgetNameForBandCell`} className={styles.CutTypography}>
          {children}
        </strong>
        {!budgetWithEvent ? (
          <IconButton
            data-tip
            data-for={`emptyBudgetControl`}
            onClick={() => {
              setOpen(true);
            }}
            iconName="content_copy"
            variant="fill"
            size="xsmall"
          />
        ) : (
          <>
            <IconButton onClick={handleClick} iconName="MoreVert" variant="fill" size="xsmall" />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  overwriteEventHandleClick(children);
                }}
              >
                Copiar corte actual
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setOpenRename(true);
                }}
              >
                Renombrar evento
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  handleEditableCells();
                }}
              >
                Editar valores
              </MenuItem>
              <MenuItem
                className={styles.deleteButton}
                onClick={() => {
                  handleCloseMenu();
                  deleteEventsHandleClick(children);
                }}
              >
                Eliminar evento
              </MenuItem>
            </Menu>
          </>
        )}
        <SaveEventForResultsState open={open} handleClose={handleClose} />

        <RenameEventForResultsState open={openRename} handleClose={handleCloseRename} />

        <ReactTooltip type="dark" id={`emptyBudgetControl`} effect="solid" opensOn="true">
          <Typography>Copiar evento actual</Typography>
        </ReactTooltip>

        <ReactTooltip type="dark" id={`budgetNameForBandCell`} effect="solid" opensOn="true">
          <Typography>{children}</Typography>
        </ReactTooltip>
      </Box>
    </>
  );
};

export default EventsControlBandCell;
