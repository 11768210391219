import React, { FC } from 'react';
import useQuotationConfigLimitDateForm from 'App/QuotationConfig/core/customHooks/useQuotationConfigLimitDateForm';
import { QuotationConfigFormatStringDate } from 'App/QuotationConfig/core/QuotationConfigUtils';
import { QuotationConfigDto } from '../../../core/DTO/QuotationConfigDto';
import QuotationConfigCollapse from '../../components/QuotationConfigCollapse/QuotationConfigCollapse';
import QuotationConfigCollapseContentDivider from '../../components/QuotationConfigCollapseContentDivider/QuotationConfigCollapseContentDivider';
import QuotationConfigLimitDateFormEndConstruction from './QuotationConfigLimitDateFormEndConstruction';
import QuotationConfigLimitDateFormInitialFee from './QuotationConfigLimitDateFormInitialFee';
import QuotationConfigLimitDateFormInitialFeeTypes from './QuotationConfigLimitDateFormInitialFeeTypes';

const QuotationConfigLimitDateForm: FC<QuotationConfigDto> = (quotationConfig) => {
  const {
    maximumCollectionDate,
    endOfSalesDate,
    firstSale,
    quotationSetup,
    maximumCollectionDateIsAfterOfEndOfSalesDate,
  } = useQuotationConfigLimitDateForm(quotationConfig);
  return (
    <QuotationConfigCollapse
      name="Cuota Inicial - Fecha Límite"
      state={maximumCollectionDateIsAfterOfEndOfSalesDate ? 'warning' : 'success'}
      label={QuotationConfigFormatStringDate(maximumCollectionDate)}
    >
      <QuotationConfigLimitDateFormEndConstruction
        maximumCollectionDate={maximumCollectionDate}
        endOfSalesDate={endOfSalesDate}
        isAfterOfEndOfSalesDate={maximumCollectionDateIsAfterOfEndOfSalesDate}
      />
      <QuotationConfigCollapseContentDivider content="Plazos para Cuota Inicial" />
      <QuotationConfigLimitDateFormInitialFee firstSale={firstSale} maximumCollectionDate={maximumCollectionDate} />
      <QuotationConfigCollapseContentDivider content="Tipos de Cuota Inicial" />
      <QuotationConfigLimitDateFormInitialFeeTypes
        quotationPaymentTypes={quotationSetup?.quotationPaymentTypes}
        defaultPaymentType={quotationSetup?.defaultPaymentType}
      />
    </QuotationConfigCollapse>
  );
};

export default QuotationConfigLimitDateForm;
