import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import TableCellPlainTypography from 'shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellPlainTypography';

import ContractsEvalproGridDisplacementFormatter from './ContractsEvalproGridDisplacementFormatter';

const ContractsEvalproGridDisplacementEditProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    // @ts-ignore
    formatterComponent={TableCellPlainTypography}
    editorComponent={ContractsEvalproGridDisplacementFormatter}
    {...props}
  />
);

export default ContractsEvalproGridDisplacementEditProvider;
