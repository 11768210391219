import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import SalesEvalproServices from '../../Services/SalesEvalproServices';
import { Numbers } from '../../../../helpers';

function useSalesMainStates(onFetchData) {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isModalOpen, setModalOpen] = useState(0);
  const [addGroupModalOpen, setAddGroupModal] = useState(false);
  const [addAdditionalModalOpen, setAddAdditionalModal] = useState(0);
  const [suggestedIncrement, setSuggestedIncrement] = useState(0);
  const [calculated, setCalculated] = useState(false);
  const [frequency, setFrequency] = useState(1);
  const [isDeleteButtonAvailable, setDeleteButton] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isSalable, setSalable] = useState(false);
  const [groupsOrder, setGroupsOrder] = useState(false);
  const [towers, setTowers] = useState([]);

  const services = new SalesEvalproServices();
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await services.getSales(towerId);
        onFetchData(res.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    fetchData();
  }, []);

  const closehandler = () => {
    setModalOpen(0);
  };

  const closeAddGroupHandler = () => {
    setAddGroupModal(false);
  };
  const getTowers = async () => {
    const resp = await services.getTowers(towerId);
    setTowers(resp);
  };

  const openAddGroupHandler = () => {
    setAddGroupModal(true);
  };

  const closeAddAdditionalHandler = () => {
    setAddAdditionalModal(0);
  };

  const openAddAdditionalHandler = (id, salable) => {
    setAddAdditionalModal(id);
    setSalable(salable);
  };

  const findSalable = (additionals, activeSalable) => {
    const additionalFounded = additionals.find(
      (additional) => activeSalable == additional.additionalId,
    );
    if (!additionalFounded) {
      return {
        name: '',
        additionalId: 0,
        quantity: 0,
        price: 0,
        type: 'UNT',
      };
    }
    return additionalFounded;
  };
  const findNonSalable = (additionals, activeNonSalable) => {
    const additionalFounded = additionals.find(
      (additional) => activeNonSalable == additional.additionalId,
    );
    if (!additionalFounded) {
      return {
        name: '',
        additionalId: 0,
        quantity: 0,
        price: 0,
        type: 'UNT',
      };
    }
    return additionalFounded;
  };

  const handleSubmit = async (values) => {
    const { groupId, ear, frequency: frequencyP } = values;
    setFrequency(frequencyP);
    try {
      const response = await services.getIncrementSuggestion(
        groupId,
        ear / 100,
        frequencyP,
      );
      setSuggestedIncrement(response.data);
      setCalculated(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  /**
   * @param {String|Number} valueIfInputIsEmpty
   * @param {any} lastValue
   */
  const updateGroup = async (
    e,
    key,
    group,
    valueIfInputIsEmpty = '',
    lastValue = '',
    towerId = null,
  ) => {
    const value = e.target.value || valueIfInputIsEmpty;
    if (lastValue != value) {
      setLoading(true);
      const data = { group: {}, additionals: {}, additionalsByGroup: {} };
      data.group[key] = value;
      data.groupId = group.id;
      if (towerId) {
        data.group.towerId = towerId;
      }
      await services.putGroup(towerId, data);
      const res = await services.getSales(towerId);
      setLoading(false);
      onFetchData(res.data);
    }
  };

  const updateIndex = async (groupsOrder) => {
    await services.putGroupsOrder(towerId, groupsOrder);
    const res = await services.getSales(towerId);
    setLoading(false);
    onFetchData(res.data);
  };

  const destroyGroup = async (group) => {
    setLoading(true);
    await services.destroyGroup(group.id);
    const res = await services.getSales(towerId);
    setLoading(false);
    onFetchData(res.data);
  };

  const updateAdditional = async (
    e,
    key,
    group,
    isSalable,
    activeAdditional,
  ) => {
    setLoading(true);
    const data = { group: {}, additionals: {}, additionalsByGroup: {} };
    data.group[key] = e.target.value;
    data.groupId = group.id;
    data.additionalId = isSalable
      ? findSalable(group.salableAdditionals, activeAdditional).additionalId
      : findNonSalable(group.nonSalableAdditionals, activeAdditional)
          .additionalId;
    data.isSalable = isSalable;
    await services.putAdditional(towerId, data);
    const res = await services.getSales(towerId);
    setLoading(false);
    onFetchData(res.data);
  };

  const currencyFormat = ({
    inputRef,
    name,
    onChange,
    onBlur,
    defaultValue,
    ...other
  }) => {
    let value = defaultValue;

    return (
      <NumberFormat
        {...other}
        defaultValue={defaultValue}
        name={name}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.floatValue,
            },
          });
          value = values.floatValue;
        }}
        onBlur={() =>
          onBlur({
            target: {
              name,
              value,
            },
          })
        }
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  const percentageFormat = ({
    inputRef,
    name,
    onChange,
    onBlur,
    defaultValue,
    ...other
  }) => {
    let value = defaultValue;

    return (
      <NumberFormat
        {...other}
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue >= 0 && floatValue <= 100;
        }}
        defaultValue={defaultValue}
        name={name}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.floatValue,
            },
          });
          value = values.floatValue;
        }}
        onBlur={() =>
          onBlur({
            target: {
              name,
              value,
            },
          })
        }
        thousandSeparator
        isNumericString
        suffix="%"
      />
    );
  };
  const onlyIntegerFormat = ({
    inputRef,
    name,
    onChange,
    onBlur,
    defaultValue,
    ...other
  }) => {
    let value = defaultValue;

    return (
      <NumberFormat
        {...other}
        defaultValue={defaultValue}
        name={name}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.floatValue,
            },
          });
          value = values.floatValue;
        }}
        onBlur={() =>
          onBlur({
            target: {
              name,
              value,
            },
          })
        }
        thousandSeparator
        isNumericString
        decimalSeparator={false}
      />
    );
  };

  const numberFormat = ({
    inputRef,
    name,
    onChange,
    onBlur,
    defaultValue,
    ...other
  }) => {
    let value = defaultValue;

    return (
      <NumberFormat
        {...other}
        defaultValue={defaultValue}
        name={name}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.floatValue,
            },
          });
          value = values.floatValue;
        }}
        onBlur={() =>
          onBlur({
            target: {
              name,
              value,
            },
          })
        }
        thousandSeparator
        isNumericString
      />
    );
  };

  return {
    enqueueSnackbar,
    towerId,
    isModalOpen,
    setModalOpen,
    suggestedIncrement,
    setSuggestedIncrement,
    calculated,
    setCalculated,
    closehandler,
    handleSubmit,
    updateGroup,
    addGroupModalOpen,
    openAddGroupHandler,
    closeAddGroupHandler,
    currencyFormat,
    updateAdditional,
    frequency,
    setFrequency,
    isDeleteButtonAvailable,
    setDeleteButton,
    destroyGroup,
    isLoading,
    setLoading,
    percentageFormat,
    onlyIntegerFormat,
    addAdditionalModalOpen,
    closeAddAdditionalHandler,
    openAddAdditionalHandler,
    isSalable,
    findSalable,
    numberFormat,
    findNonSalable,
    towers,
    getTowers,
    updateIndex,
    groupsOrder,
    setGroupsOrder,
  };
}

export default useSalesMainStates;
