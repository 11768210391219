import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const useAddDocumentTemplateBehavior = (onClose, uploadTemplate) => {
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState(true);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const onNameChange = (event) => {
    const newName = event.target.value;
    if (!newName) {
      setNameValid(false);
    } else {
      setNameValid(true);
    }
    setName(newName);
  };
  const onFileChange = (event) => setFile(event.target.files[0]);
  const upload = async () => {
    if (uploading || !name || !file) {
      enqueueSnackbar('Primero debe ingresar un nombre y un archivo', {
        variant: 'warning',
      });
      return;
    }
    setUploading(true);
    const result = await uploadTemplate(towerId, name, file);
    setUploading(false);
    setFile(null);
    if (result) {
      onClose();
    }
  };

  return {
    name,
    nameValid,
    onNameChange,
    onFileChange,
    upload,
    uploading,
  };
};

export default useAddDocumentTemplateBehavior;
