import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import Slide from '@material-ui/core/Slide';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Styles from './DeleteDialog.module.scss';
import AreasAdditionalServices from '../../../services/areasAdditional/AreasAdditionalServices';

const services = new AreasAdditionalServices();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const deleteAdditionalAreaRow = async (id, areaTypeId) => {
  await services.deleteAdditionalAreaRow(id, areaTypeId);
};

export default function DeleteDialog({
  id,
  areaTypeId,
  refreshAreaTypeHandler,
  disabled,
}) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const messageToUser = () =>{
    enqueueSnackbar("El area se eliminó correctamente", { variant: 'success' });
  }

  return (
      <div>
        {disabled ? (
          <div>
            <DeleteForeverOutlinedIcon
              className={Styles.delete}
              onClick={handleClickOpen}
            />
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                Señor usuario
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  No es posible eliminar un area adicional vendida
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  ACEPTAR
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div>
            <DeleteForeverOutlinedIcon
              className={Styles.delete}
              onClick={handleClickOpen}
            />
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                Señor usuario
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  ¿Está seguro de eliminar esta area adicional?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  CANCELAR
                </Button>
                <Button
                  onClick={ async () => {
                    await deleteAdditionalAreaRow(id, areaTypeId);
                    handleClose();
                    messageToUser();
                    refreshAreaTypeHandler();
                  }}
                  color="primary"
                >
                  ACEPTAR
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
  );
}
