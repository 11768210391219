import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Status from '../../../../helpers/status';

enum Align {
  center = 'center',
  inherit = 'inherit',
}

export default function usePropertyEditionRowsMapper(isSoldSelected: boolean) {
  const [rows, setRows] = useState<{ id: string }[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { properties } = useSelector((state) => state.salesRoom.root);
  useEffect(() => {
    const rowsFiltered = properties
      .flatMap((property) => property)
      .filter(
        (property) =>
          (isSoldSelected && Status.Sold === property.status) || (!isSoldSelected && Status.Sold !== property.status),
      );

    setRows(_.sortBy(rowsFiltered, ['floor', 'location'], ['asc', 'asc']));
  }, [properties, isSoldSelected]);

  const columns = [
    { text: 'Estado', align: Align.center },
    { text: 'Número de apartamento', align: Align.center },
    { text: 'Grupo', align: Align.inherit },
    { text: 'Piso', align: Align.inherit },
    { text: 'Precio', align: Align.inherit },
    { text: '', align: Align.inherit },
  ];

  const updateLoading = (load) => {
    setLoading(load);
  };

  return { rows, columns, isLoading, updateLoading };
}
