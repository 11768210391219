import { CircularProgress } from '@material-ui/core';
import React, { Ref } from 'react';

import CustomProgress from 'shared/components/CustomProgress/CustomProgress';
import useAddStage from '../core/customHooks/useAddStage';
import useDeleteStage from '../core/customHooks/useDeleteStage';
import useDeleteTower from '../core/customHooks/useDeleteTower';
import useMoveStageTower from '../core/customHooks/useMoveStageTower';
import useStagesLoader from '../core/customHooks/useStagesLoader';
import useStagesNavigation from '../core/customHooks/useStagesNavigation';
import useStagesState from '../core/customHooks/useStagesState';
import StagesDTO from '../core/DTO/StagesDTO';
import StagesViewStates from '../core/enums/StagesViewStates';
import {
  AddStageHandler,
  ChangeTowerHanlder,
  DeleteStageHandler,
  DeleteTowerHandler,
  MoveCardHandler,
  OnGoToTower,
  OnUpdateStages,
} from '../core/types/Stages.types';
import StagesContent from './components/StagesContent/StagesContent';
import StagesHeader from './components/StagesHeader/StagesHeader';

import styles from './StageMainView.module.scss';

type ViewProps = {
  stages: StagesDTO;
  lastStageList: Ref<HTMLDivElement>;
  onAddStage: AddStageHandler;
  onDeleteStage: DeleteStageHandler;
  onUpdateStages: OnUpdateStages;
  onGoToTower: OnGoToTower;
  onDeleteTower: DeleteTowerHandler;
  onMoveCard: MoveCardHandler;
};

const stagesViews = {
  [StagesViewStates.Loading]: (_: ViewProps) => <CircularProgress className={styles.loader} />,
  [StagesViewStates.Ready]: (props: ViewProps) => <StagesContent {...props} />,
  [StagesViewStates.Error]: (_: ViewProps) => {},
};

type Props = {
  changeTower: ChangeTowerHanlder;
};

const StagesMainView: React.FC<Props> = ({ changeTower }) => {
  const { viewState, stageProject } = useStagesLoader();
  const {
    isProgressVisible,
    projectName,
    stages,
    lastStageList,
    showProgress,
    hideProgress,
    addStage,
    updateStages,
    moveTower,
  } = useStagesState(stageProject);
  const handleAddStage = useAddStage({ stageProject, showProgress, hideProgress, addStage });
  const handleDeleteStage = useDeleteStage({ showProgress, hideProgress, updateStages });
  const { goBack, goToTower } = useStagesNavigation(changeTower);
  const handleDeleteTower = useDeleteTower({ showProgress, hideProgress, updateStages });
  const handleMoveCardEnd = useMoveStageTower({ stages, moveTower, updateStages });
  const viewProps: ViewProps = {
    stages,
    lastStageList,
    onAddStage: handleAddStage,
    onDeleteStage: handleDeleteStage,
    onUpdateStages: updateStages,
    onGoToTower: goToTower,
    onDeleteTower: handleDeleteTower,
    onMoveCard: handleMoveCardEnd,
  };

  return (
    <div className={styles.root}>
      <StagesHeader
        className={styles.header}
        projectName={projectName}
        stages={stages}
        onGoBack={goBack}
        onAddStage={handleAddStage}
      />
      {stagesViews[viewState](viewProps)}
      {isProgressVisible && <CustomProgress />}
    </div>
  );
};

export default StagesMainView;
