import moment from 'moment';
import agent from '../../../../config/config';
import { Role } from 'helpers';
import { NumberFormatValues } from 'react-number-format';
import { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import ScheduleServices from 'services/schedule/ScheduleServices';
import { useParams } from 'react-router-dom';
import { updateQuotationConfigQueryData } from '../QuotationConfigQueryClient';

interface Props {
  maximumCollectionDate: string;
  firstSale: number;
}

const service = new ScheduleServices();

const useQuotationConfigLimitDateFormInitialFee = ({ firstSale, maximumCollectionDate }: Props) => {
  const [value, setValue] = useState<number | undefined>(firstSale);
  const { towerId } = useParams<{ towerId: string }>();
  const { mutate: updateFirstSale } = useMutation(
    (value: number) => service.putFirstSaleHandler(towerId, { firstSale: value }),
    {
      onMutate: (value: number) => updateQuotationConfigQueryData({ firstSale: value }),
    },
  );

  const maximumCollectionDateFormated =
    moment(Number(maximumCollectionDate)).startOf('month').diff(moment().startOf('month'), 'months') + 1;

  const isAuthorizedForEdit = agent.isAuthorized([Role.Super, Role.Admin]);

  const isAllowed = ({ floatValue }: NumberFormatValues) => {
    if (Number(floatValue) >= 0 && Number(floatValue) <= 100) return true;
    if (typeof floatValue === 'undefined') return true;
    return false;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(+e.target.value);
  };

  const handleBlur = () => {
    if (typeof value !== 'undefined' && value !== firstSale) updateFirstSale(value);
  };

  return {
    value,
    isAuthorizedForEdit,
    maximumCollectionDateFormated,
    handleBlur,
    handleChange,
    isAllowed,
  };
};

export default useQuotationConfigLimitDateFormInitialFee;
