import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import GeneralWalletServices from '../../services/GeneralWalletServices';

const services = new GeneralWalletServices();
const useEditMonthsRecoveryValue = (towerId: string) => {
  const queryClient = useQueryClient();
  return useMutation((monthsRecovery: number) => services.updateMonthsRecovery(towerId, monthsRecovery), {
    onSettled: () => {
      queryClient.invalidateQueries('months-recovery');
    },
  });
};

const useMonthsRecoveryData = (towerId: string) => {
  return useQuery<number>(
    'months-recovery',
    async () => {
      const { data: monthsRecoveryData } = await services.getMonthsRecovery(towerId);
      return monthsRecoveryData;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useMonthsRecovery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId }: { towerId: string } = useParams();
  const { mutateAsync: editMonthsRecoveryValue } = useEditMonthsRecoveryValue(towerId);
  const { data: monthsRecovery, isLoading } = useMonthsRecoveryData(towerId);

  const handleChangeMonthsRecovery = async (value: number) => {
    try {
      if (+value >= 0) {
        await editMonthsRecoveryValue(value);
        enqueueSnackbar('Recuperación cartera en mora actualizado con éxito', {
          variant: 'success',
        });
      }
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };

  return {
    monthsRecovery,
    handleChangeMonthsRecovery,
  };
};

export default useMonthsRecovery;
