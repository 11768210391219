import { useEffect, useState } from 'react';
import useBoolean from 'shared/customHooks/useBoolean';
import { DeedExpensesDisplacementCellProps } from '../interfaces/DeedExpensesDisplacementCellProps';

const useDeedExpensesDisplacementCell = (
  defaultDisplacementValue: number,
  quotationId: string,
  handleChangeDisplacement: DeedExpensesDisplacementCellProps['handleChangeDisplacement'],
) => {
  const [displacement, setDisplacement] = useState(defaultDisplacementValue);
  const { value: isOnBlur, setFalse, setTrue } = useBoolean();

  const handleChange = (newDisplacement: number) => {
    setDisplacement(Math.sign(newDisplacement) === -1 ? 0 : newDisplacement);
  };

  useEffect(() => {
    if (typeof defaultDisplacementValue === 'number') setDisplacement(defaultDisplacementValue);
  }, [defaultDisplacementValue]);

  const onSubmit = () => setTrue();

  useEffect(() => {
    if (isOnBlur) {
      handleChangeDisplacement({ displacement, quotationId });
      setFalse();
    }
  }, [isOnBlur, displacement]);

  return {
    displacement,
    onSubmit,
    handleChange,
  };
};

export default useDeedExpensesDisplacementCell;
