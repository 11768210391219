import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Contractor } from '../../../../models/Contractors';
import Services from '../../Services/BusinessPartnersServices';
import useContractor from './useContractor';
import LikemetricError from '../../../../shared/types/LikemetricError';

const services = new Services();

const useContractorInformation = () => {
  const [tab, setTab] = useState('Home');

  const { enqueueSnackbar } = useSnackbar();

  const { handleDeleteContractor } = useContractor();

  const deleteContractor = async (contractor: Contractor): Promise<void> => {
    if (contractor) {
      try {
        await services.deleteBusinessPartner(contractor.id);
        handleDeleteContractor(contractor);
      } catch (e) {
        const error = e as LikemetricError;
        enqueueSnackbar(error.message);
      }
    }
  };

  return {
    tab,
    setTab,
    deleteContractor,
  };
};

export default useContractorInformation;
