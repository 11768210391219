import { Menu as MuiMenu, MenuProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import styles from './Menu.module.scss';

const getArrowClass = (showArrow = false) => ({
  [styles.arrow]: showArrow,
});

const handleOnEntered = (element: HTMLElement) => {
  element.classList.add(styles.onEntered);
};

const handleOnExit = (element: HTMLElement) => {
  element.classList.remove(styles.onEntered);
  element.classList.add(styles.onExit);
};

type Props = MenuProps & {
  hasArrow?: boolean;
};

const Menu: React.FC<Props> = ({ className, hasArrow, TransitionProps = {}, ...restProps }) => (
  <MuiMenu
    className={clsx(styles.root, getArrowClass(hasArrow), className)}
    TransitionProps={{
      ...TransitionProps,
      onEntered: handleOnEntered,
      onExit: handleOnExit,
    }}
    {...restProps}
  />
);

export default Menu;
