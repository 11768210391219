import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useGraphicMaterialRowsMapper() {
  const { properties } = useSelector((state) => state.salesRoom.root);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const rowsFiltered = properties.flatMap((property) => property);
    setRows(_.sortBy(rowsFiltered, ['floor', 'location'], ['asc', 'asc']));
  }, [properties]);

  const columns = [
    { text: 'Número de apartamento', align: 'center' },
    { text: 'Habitaciones', align: 'inherit' },
    { text: 'Baños', align: 'inherit' },
    { text: 'Orientación', align: 'inherit' },
    { text: 'Tipo', align: 'inherit' },
  ];

  const updateRows = (property) => {
    setRows(rows.map(row => {
      if (row.id === property.id) return property
      return row
    }))
  }

  return { rows, columns, updateRows };
}
