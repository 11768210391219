import { Column } from '@devexpress/dx-react-grid';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { AreaColumnType } from '../interfaces/AreaColumnType';

export const TotalColumnName = 'total';
export const PrivateAreasColumnName = 'privateArea';
export const NewAreaColumnName = '_NewArea';

export const AreasColumnExtentions: VirtualTable.ColumnExtension[] = [
  {
    columnName: 'name',
    width: '150px',
  },
  {
    columnName: 'group',
    width: '120px',
  },
  {
    columnName: NewAreaColumnName,
    width: '60px',
  },
  {
    columnName: TotalColumnName,
    width: '160px',
  },
  {
    columnName: PrivateAreasColumnName,
    width: '240px',
  },
];

export const AreasFixedColumns = ['name', 'group'];

export const DefualtRightColumns: AreaColumnType[] = [
  { name: TotalColumnName, title: 'Total Áreas mt2', type: ProviderColumnType.numeric },
  { name: PrivateAreasColumnName, title: 'Área Privada mt2', type: ProviderColumnType.numeric },
];

export const DefaulColumnsForEdit = [PrivateAreasColumnName];

export const DisableEditInColumns = ['name', 'group', TotalColumnName];
export const TotalsID = 1;
