import colors from '../../../assets/styles/settings/colors.module.scss';
import fonts from '../../../assets/styles/settings/fonts.module.scss';

const ButtonDesignSystem = {
  variant: {
    default: {
      color: colors.primary600,
      backgroundColor: colors.primary200,
      borderColor: colors.primary200,
      hover: {
        color: colors.primary600,
        backgroundColor: colors.primary300,
      },
      focus: {
        color: colors.primary600,
        backgroundColor: colors.primary300,
        outline: `${colors.primary300} solid 0.25rem`,
      },
      active: {
        color: colors.primary800,
        backgroundColor: colors.primary200,
      },
    },
    contained: {
      color: colors.gray000,
      backgroundColor: colors.primary600,
      hover: {
        color: colors.gray000,
        backgroundColor: colors.primary500,
      },
      focus: {
        color: colors.gray000,
        backgroundColor: colors.primary600,
        outline: `${colors.primary300} solid 0.25rem`,
      },
      active: {
        color: colors.gray000,
        backgroundColor: colors.primary800,
      },
      disabled: {
        color: colors.gray500,
        backgroundColor: colors.gray300,
      },
    },
    outlined: {
      color: colors.primary600,
      backgroundColor: 'transparent',
      borderColor: colors.primary600,
      hover: {
        color: colors.primary500,
        backgroundColor: `${colors.primary500}1A`,
        borderColor: colors.primary500,
      },
      focus: {
        color: colors.primary600,
        backgroundColor: 'transparent',
        borderColor: colors.primary600,
        outline: `${colors.primary300} solid 0.25rem`,
      },
      active: {
        color: colors.primary800,
        backgroundColor: 'transparent',
        borderColor: colors.primary800,
      },
      disabled: {
        color: colors.gray500,
        backgroundColor: 'transparent',
        borderColor: colors.gray500,
      },
    },
    tertiary: {
      color: colors.primary600,
      backgroundColor: `${colors.primary600}1A`,
      hover: {
        color: colors.primary600,
        backgroundColor: `${colors.primary600}40`,
      },
      focus: {
        color: colors.primary600,
        backgroundColor: `${colors.primary600}1A`,
        outline: `${colors.primary300} solid 0.25rem`,
      },
      active: {
        color: colors.primary800,
        backgroundColor: `${colors.primary600}80`,
      },
      disabled: {
        color: colors.gray500,
        backgroundColor: colors.gray100,
      },
    },
    text: {
      color: colors.primary600,
      backgroundColor: 'transparent',
      hover: {
        color: colors.primary600,
        backgroundColor: `${colors.primary500}1A`,
      },
      focus: {
        color: colors.primary600,
        backgroundColor: 'transparent',
        outline: `${colors.primary300} solid 0.125rem`,
      },
      active: {
        color: colors.primary800,
        backgroundColor: 'transparent',
      },
      disabled: {
        color: colors.gray500,
        backgroundColor: 'transparent',
      },
    },
  },
  size: {
    large: {
      padding: '1rem 2.5rem',
      outlinedPadding: '0.875rem 2.375rem',
      minHeight: '3.5rem',
      fontFamily: fonts['button-medium-font-family'],
      fontSize: fonts['button-medium-font-size'],
      fontWeight: fonts['button-medium-font-weight'],
      letterSpacing: fonts['button-medium-letter-spacing'],
      lineHeight: fonts['button-medium-line-height'],
      borderRadius: '0.5rem',
      borderWidth: '0.125rem',
    },
    medium: {
      padding: '0.75rem 1.5rem',
      outlinedPadding: '0.6875rem 1.4375rem',
      minHeight: '3rem',
      fontFamily: fonts['button-medium-font-family'],
      fontSize: fonts['button-medium-font-size'],
      fontWeight: fonts['button-medium-font-weight'],
      letterSpacing: fonts['button-medium-letter-spacing'],
      lineHeight: fonts['button-medium-line-height'],
      borderRadius: '0.5rem',
      borderWidth: '0.0625rem',
    },
    small: {
      padding: '0.625rem 1.5rem',
      outlinedPadding: '0.5625rem 1.4375rem',
      minHeight: '2.5rem',
      fontFamily: fonts['button-small-font-family'],
      fontSize: fonts['button-small-font-size'],
      fontWeight: fonts['button-small-font-weight'],
      letterSpacing: fonts['button-small-letter-spacing'],
      lineHeight: fonts['button-small-line-height'],
      borderRadius: '0.4rem',
      borderWidth: '0.0625rem',
    },
  },
};

export default ButtonDesignSystem;
