import React, { FC, ChangeEventHandler } from 'react';

import { Box, Grid, Paper, InputBase, InputAdornment, SvgIcon } from '@material-ui/core';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import SearchIcon from '@material-ui/icons/Search';

import Typography from '../../../../../shared/components/Typography/Typography';
import Styles from '../../styles/ContractLotsToolbar.module.scss';
import { Button } from '../../../../../shared/components/Button/Button';

interface IContractLotsToolbar {
  handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleNewLot: () => void;
}

const ContractLotsToolbar: FC<IContractLotsToolbar> = ({ handleChangeSearch, handleNewLot }) => {
  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item>
              <Typography as="h1" variant="headline3">
                Lotes
              </Typography>
            </Grid>
            <Grid item>
              <Paper component="form" className={Styles.SearchFieldToolbarRoot}>
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
                <InputBase
                  className={Styles.SearchFieldToolbarRoot_input}
                  placeholder="Buscar"
                  inputProps={{ 'aria-label': 'Buscar lotes' }}
                  onChange={handleChangeSearch}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            startIcon={<AddTwoToneIcon fontSize="small" />}
            onClick={handleNewLot}
            variant="contained"
            size={'medium'}
          >
            Nuevo Lote
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractLotsToolbar;
