import { ChangeEvent, FormEvent, InvalidEvent, useEffect, useState } from 'react';

import { SaveTowerHandler, TowerEditorOptions } from '../types/Stages.types';

const getSaveDescription = (description: string | undefined): string | undefined => description || undefined;

const getOptionsDescription = (options: TowerEditorOptions): string => options.towerValues.description || '';

type UseStageTowerEditorForm = {
  isOpen: boolean;
  options: TowerEditorOptions;
  saveTower: SaveTowerHandler;
};

function useStageTowerEditorForm({ isOpen, options, saveTower }: UseStageTowerEditorForm) {
  const [name, setName] = useState('');
  const [hasNameError, setHasNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState('');
  const [description, setDescription] = useState('');

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.checkValidity()) {
      const towerValues = {
        name,
        description: getSaveDescription(description),
      };

      saveTower(towerValues);
    }
  };

  const handleOnIvalid = (event: InvalidEvent<HTMLInputElement>) => {
    if (event.currentTarget.validity.valueMissing) {
      setHasNameError(true);
      setNameHelperText('Campo obligatorio');
    }
  };

  const resetNameError = () => {
    setHasNameError(false);
    setNameHelperText('');
  };

  useEffect(() => {
    setName(options.towerValues.name);
    setDescription(getOptionsDescription(options));
    resetNameError();
  }, [isOpen]);

  return {
    name,
    hasNameError,
    nameHelperText,
    description,
    handleChangeName,
    handleChangeDescription,
    handleSubmit,
    handleOnIvalid,
    resetNameError,
  };
}

export default useStageTowerEditorForm;
