import React from 'react';

import Agent from 'config/config';
import Typography from 'shared/components/Typography/Typography';
import StagesDTO from '../../../core/DTO/StagesDTO';
import StagesOnboardingStates from '../../../core/enums/StagesOnboardingStates';
import { AddStageHandler } from '../../../core/types/Stages.types';
import StagesOnboardingState from '../../../core/utils/StagesOnboardingState';
import StagesUserRole from '../../../core/utils/StagesUserRole';
import StagesOnboardingHeader from '../StagesOnboardingHeader/StagesOnboardingHeader';
import StagesOnboardingStep1 from '../StagesOnboardingStep1/StagesOnboardingStep1';

import styles from './StagesOnboarding.module.scss';

const OnboardingDivider = <div className={styles.divider}></div>;

type AdminOnboardingProps = {
  onAddStage: AddStageHandler;
};

const AdminOnboardingSteps = {
  [StagesOnboardingStates.Step1]: (props: AdminOnboardingProps) => <StagesOnboardingStep1 {...props} />,
  [StagesOnboardingStates.Step2]: (_: AdminOnboardingProps) => <StagesOnboardingHeader step={2} />,
  [StagesOnboardingStates.Done]: (_: AdminOnboardingProps) => OnboardingDivider,
};

const UserOnboarding = (
  <Typography as="p" variant="body1">
    Este proyecto está vacío o en proceso de configuración, comunícate con un administrador para visualizar su
    contenido.
  </Typography>
);

const UserOnboardingSteps = {
  [StagesOnboardingStates.Step1]: () => UserOnboarding,
  [StagesOnboardingStates.Step2]: () => UserOnboarding,
  [StagesOnboardingStates.Done]: () => OnboardingDivider,
};

type Props = {
  className?: string;
  stages: StagesDTO;
  onAddStage: AddStageHandler;
};

const StagesOnboarding: React.FC<Props> = ({ className, stages, onAddStage }) => {
  const isSuperOrAdminUser = StagesUserRole.isSuperOrAdminUser(Agent.currentUser.userType);
  const state = StagesOnboardingState.get(stages);
  const adminProps = { onAddStage };

  return (
    <div className={className}>
      {isSuperOrAdminUser ? AdminOnboardingSteps[state](adminProps) : UserOnboardingSteps[state]()}
    </div>
  );
};

export default StagesOnboarding;
