import { Box, CircularProgress, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import BackupIcon from '@material-ui/icons/Backup';
import ConfirmIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';

const CustomImage = styled.img`
  height: 200px;
  width: 600px;
  padding-bottom: 20px;
  cursor: pointer;
`;

const BorderedBox = styled(Box)`
  height: 200px;
  width: 600px;
  cursor: pointer;
  padding-bottom: 20px;
  border: 0.5px solid gray;
  border-radius: 3px;
`;

const ClickableImage = ({
  keyName,
  src,
  onClick,
  isUploadingFile,
  onChangeHandler,
  onCancel,
  onAccept,
  fileName,
}) => {
  return (
    <label htmlFor={`raised-button-file-for-icon-${keyName}`}>
      <input
        name="file"
        accept="image/*"
        hidden
        id={`raised-button-file-for-icon-${keyName}`}
        type="file"
        onChange={onChangeHandler}
        key={keyName}
      />
      {src ? (
        <CustomImage src={src} onClick={onClick} />
      ) : (
        <BorderedBox display="flex" alignItems="center" justifyContent="center">
          <IconButton
            aria-label="Done"
            component="span"
            disabled={isUploadingFile}
          >
            <BackupIcon fontSize="large" />
          </IconButton>
        </BorderedBox>
      )}
      {fileName && (
        <>
          <IconButton
            color="primary"
            onClick={onAccept}
            disabled={isUploadingFile}
          >
            <ConfirmIcon />
          </IconButton>
          <IconButton
            color="default"
            onClick={onCancel}
            disabled={isUploadingFile}
          >
            <CancelIcon />
          </IconButton>
          {isUploadingFile && <CircularProgress />}
        </>
      )}
    </label>
  );
};

export default ClickableImage;
