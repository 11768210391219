import Services from '../../../services/services';
import { HubspotDefinitions } from './IntegrationServiceDefinitions';

class HubspotService extends Services {
  isEnabled(companyId) {
    return this.get(HubspotDefinitions.isEnabled(companyId));
  }

  refreshProperties(companyId) {
    return this.get(HubspotDefinitions.refreshProperties(companyId));
  }
}

export default class IntegrationService {
  static Hubspot() {
    return new HubspotService();
  }
}
