import React, { FC } from 'react';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Typography from 'shared/components/Typography/Typography';
import useQuotationConfigPaymentTypes from 'App/QuotationConfig/core/customHooks/useQuotationConfigPaymentTypes';
import QuotationConfigActionButton from '../../components/QuotationConfigActionButton/QuotationConfigActionButton';
import QuotationConfigActionChip from '../../components/QuotationConfigActionChip/QuotationConfigActionChip';
import QuotationConfigTextField from '../../components/QuotationConfigTextField/QuotationConfigTextField';
import QuotationConfigFormItem from '../QuotationConfigFormItem';
import { QuotationPaymentType } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import QuotationConfigSelect from '../../components/QuotationConfigSelect/QuotationConfigSelect';

interface Props {
  quotationFinalPaymentTypes: QuotationPaymentType[];
  defaulFinalPaymentType: number | string;
}

const QuotationConfigFinalFeeFormInitialFeeTypes: FC<Props> = ({
  quotationFinalPaymentTypes,
  defaulFinalPaymentType,
}) => {
  const {
    value,
    handleChange,
    handleClickNewFeeType,
    handleSubmitNewFeeType,
    removeFinalPaymentType,
    handleChangeDefaultType,
  } = useQuotationConfigPaymentTypes(quotationFinalPaymentTypes, 'final');
  return (
    <Box display="flex" justifyContent="center" padding="40px">
      <Grid container item xs={10}>
        <QuotationConfigFormItem item xs={6}>
          <Box paddingRight="8px" width="100%">
            <form onSubmit={handleSubmitNewFeeType}>
              <QuotationConfigTextField
                fullWidth
                variant="outlined"
                label="Ingresa un nuevo tipo"
                onChange={handleChange}
                value={value}
              />
            </form>
          </Box>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <QuotationConfigActionButton startIcon={<Add />} onClick={handleClickNewFeeType}>
            Añadir
          </QuotationConfigActionButton>
        </QuotationConfigFormItem>
        {Array.isArray(quotationFinalPaymentTypes) && quotationFinalPaymentTypes.length > 0 && (
          <>
            <QuotationConfigFormItem item xs={10}>
              {quotationFinalPaymentTypes.map((element) => (
                <QuotationConfigActionChip key={element.id} {...element} onClick={removeFinalPaymentType} />
              ))}
            </QuotationConfigFormItem>
            <QuotationConfigFormItem item xs={6}>
              <Box marginBottom="8px" marginTop="40px">
                <Typography variant="body1">Tipo de cuota por defecto</Typography>
              </Box>
              <QuotationConfigSelect
                selectProps={{
                  labelId: 'quotationFinalPaymentTypeselect',
                  id: 'quotationFinalPaymentTypesSelected',
                  onChange: handleChangeDefaultType,
                  value: defaulFinalPaymentType,
                }}
              >
                {quotationFinalPaymentTypes.map(({ id, text }) => (
                  <MenuItem value={id} key={id}>
                    {text}
                  </MenuItem>
                ))}
              </QuotationConfigSelect>
            </QuotationConfigFormItem>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default QuotationConfigFinalFeeFormInitialFeeTypes;
