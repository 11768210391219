import moment from 'moment';
import { NumberFormatValues } from 'react-number-format';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ScheduleServices from 'services/schedule/ScheduleServices';
import { updateQuotationConfigQueryData } from '../QuotationConfigQueryClient';

interface UseQuotationConfigLimitDateFormEndConstructionProps {
  endOfSalesDate: string;
  maximumCollectionDate: string;
}

const service = new ScheduleServices();

const useQuotationConfigLimitDateFormEndConstruction = (props: UseQuotationConfigLimitDateFormEndConstructionProps) => {
  const { maximumCollectionDate, endOfSalesDate } = props;
  const { towerId } = useParams<{ towerId: string }>();

  const { mutate: updateMaximumCollectionDate, isLoading } = useMutation(
    (value: number) =>
      service.putMaximumCollectionDate(towerId, {
        maximumCollectionDate: value,
      }),
    {
      onMutate: (value: number) => updateQuotationConfigQueryData({ maximumCollectionDate: `${value}` }),
    },
  );

  const momentMaximumCollectionDate = moment(Number(maximumCollectionDate)).startOf('month');
  const momentEndOfSalesDate = moment(Number(endOfSalesDate)).startOf('month');
  const diffValue = momentMaximumCollectionDate.diff(momentEndOfSalesDate, 'month');

  const handleChange = (value: number) => {
    if (typeof value === 'number' && value >= -100 && value <= 100) {
      const newMaximumCollectionDate = moment(Number(endOfSalesDate)).add(value, 'M').valueOf();
      if (Number(newMaximumCollectionDate) !== Number(maximumCollectionDate))
        updateMaximumCollectionDate(newMaximumCollectionDate);
    }
  };

  const isAllowed = ({ floatValue }: NumberFormatValues) => {
    if (Number(floatValue) >= -100 && Number(floatValue) <= 100) return true;
    if (typeof floatValue === 'undefined') return true;
    return false;
  };

  return {
    isLoading,
    diffValue,
    isAllowed,
    handleChange,
  };
};

export default useQuotationConfigLimitDateFormEndConstruction;
