import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from '@material-ui/core';
import BuilderCreditSetupForm from './views/BuilderCreditSetupForm';
import useBuilderCreditLoader from '../Core/customHooks/useBuilderCreditLoader';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BuilderCreditSetupModalView = ({ onClose, isOpen }) => {
  const {
    states,
    isLoading,
    update,
    updateDate,
    onSend,
  } = useBuilderCreditLoader(isOpen, onClose);

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Configuración de Crédito constructor
        </DialogTitle>
        <DialogContent>
          {isLoading && <AbsoluteCircularProgress />}
          <BuilderCreditSetupForm
            states={states}
            update={update}
            updateDate={updateDate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSend} color="primary">
            Guardar
          </Button>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

BuilderCreditSetupModalView.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default BuilderCreditSetupModalView;
