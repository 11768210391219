import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../App/SalesRoomTable/UI/SalesRoomTableMainView';
import styles from './PropertiesTable.module.scss';

const TableInstructions = React.memo(function Instructions() {
  return (
    <div className={styles.Container}>
      <div className={styles.SoftGreenHelper} />
      <div className={styles.Label}>Disponible</div>
      <div className={styles.GreenHelper} />
      <div className={styles.Label}>Opcionado</div>
      <div className={styles.ownerBadge} />
      <div className={styles.Label}>Propietario</div>
      <div className={styles.GrayHelper} />
      <div className={styles.Label}>Vendido</div>
    </div>
  );
});

const PropertiesTables = (props) => {
  const getHeaders = React.useCallback(() => {
    return {
      headers:
        props.headers.length > 0
          ? props.headers
          : [...Array(props.properties).keys()].map((o) => o + 1),
      columns:
        props.verticalHeaders.length > 0
          ? props.verticalHeaders
          : [...Array(props.floors).keys()].map((o) => o + props.lowestFloor),
    };
  }, [props.properties, props.floors]);

  const { headers, columns } = getHeaders();

  return (
    <div>
      <TableInstructions />
      <div>
        <Table
          className={styles.tableContainer}
          headers={headers}
          columns={columns}
          arrayOfProperties={props.arrayOfProperties}
          propertyTypes={props.propertyTypes}
          typeSelected={props.typeSelected}
          onClickSelector={props.onClickSelector}
          lowestFloor={props.lowestFloor}
        />
      </div>
    </div>
  );
};

PropertiesTables.propTypes = {
  arrayOfProperties: PropTypes.array,
  propertyTypes: PropTypes.array,
  typeSelected: PropTypes.string,
  onClickSelector: PropTypes.func,
  headers: PropTypes.array,
  verticalHeaders: PropTypes.array,
  lowestFloor: PropTypes.number,
};

export default PropertiesTables;
