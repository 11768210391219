import { useState } from 'react';

function useEditionDiscountType() {
  const [isEdition, setEdition] = useState(false);

  const editionToggle = () => {
    setEdition(!isEdition);
  };

  return { isEdition, editionToggle };
}

export default useEditionDiscountType;
