import React, { ChangeEvent, DependencyList, FormEvent, useEffect, useState } from 'react';

interface Validation<T, K> {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string;
    message: string;
  };
  custom?: {
    isValid(value: T, data?: K): boolean;
    message: string;
  };
}

type IValidations<T extends Record<string, unknown>> = {
  [key in keyof T]?: Validation<T[key], T>;
};

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type DateType = Date | null;
export type FormSanitizeFn = (value: string) => string;

export interface IUseFormResult<T> {
  data: Partial<T>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleChange: (
    key: keyof Partial<T>,
    sanitizeFn?: FormSanitizeFn,
  ) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void;
  handleInputChange: (
    key: keyof Partial<T>,
    sanitizeFn?: FormSanitizeFn,
  ) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleChangeRadio: (
    key: keyof Partial<T>,
    sanitizeFn?: FormSanitizeFn,
  ) => (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  handleChangeSelect: (
    key: keyof Partial<T>,
    sanitizeFn?: FormSanitizeFn,
  ) => (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  handleChangeDate: (
    key: keyof Partial<T>,
    sanitizeFn?: FormSanitizeFn,
  ) => (date: DateType | number | null, value?: string | null) => void;
  handleChangeValue: <G>(key: keyof Partial<T>, value: G) => void;
  handleChangeData: (newData: Partial<T>) => void;
  handleChangeValueType: <X>(key: keyof Partial<T>, value: X) => void;
  errors: ErrorRecord<Partial<T>>;
  resetData: () => void;
  formLoading?: boolean;
}

export const useForm = <T extends Partial<Record<keyof T, unknown>>>(
  options?: {
    validations?: IValidations<T>;
    initialValues?: Partial<T>;
    onSubmit?: (data: T, resetForm: () => void) => Promise<void>;
  },
  dependencies: DependencyList = [],
): IUseFormResult<T> => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});
  const [loading, setLoading] = useState(false);

  const handleChange =
    (key: keyof Partial<Record<keyof T, unknown>>, sanitizeFn?: FormSanitizeFn) =>
    (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const handleInputChange =
    (key: keyof Partial<Record<keyof T, unknown>>, sanitizeFn?: FormSanitizeFn) =>
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const handleChangeValue = <G>(key: keyof Partial<T>, value: G): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChangeData = (newData: Partial<T>): void => {
    setData({
      ...data,
      ...newData,
    });
  };

  const handleChangeValueType = <X>(key: keyof Partial<T>, value: X): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChangeSelect =
    (key: keyof Partial<Record<keyof T, unknown>>, sanitizeFn?: FormSanitizeFn) =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value as string) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const handleChangeDate = (key: keyof Partial<T>) => (date: DateType | number | null) => {
    setData({
      ...data,
      [key]: date,
    });
  };

  const handleChangeRadio =
    (key: keyof Partial<Record<keyof T, unknown>>, sanitizeFn?: FormSanitizeFn) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = sanitizeFn ? sanitizeFn(event.target.value as string) : event.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const resetData = (): void => {
    setData((options?.initialValues || {}) as T);
    setErrors({});
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    const validations = options?.validations;
    if (validations) {
      let valid = true;
      const newErrors: ErrorRecord<T> = {};

      Object.keys(validations).forEach((objectKey) => {
        const key = objectKey as keyof T;
        const value = data[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value as string)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value, data)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      });

      if (!valid) {
        console.log(data, newErrors);

        setLoading(false);
        setErrors(newErrors);
        return;
      }
    }

    setErrors({});
    if (options?.onSubmit) {
      await options.onSubmit(data, resetData);
    }
    setLoading(false);
  };

  useEffect(() => {
    resetData();
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {
    data,
    errors,
    formLoading: loading,
    handleChange,
    handleSubmit,
    handleChangeSelect,
    handleChangeRadio,
    resetData,
    handleChangeDate,
    handleInputChange,
    handleChangeValue,
    handleChangeData,
    handleChangeValueType,
  };
};
