import { useSnackbar } from 'notistack';

import FeatureFieldServices from '../../services/FeatureFieldServices';
import { UpdateFeatureFieldTypes } from '../enums/featureField.enum';
import { FeatureField } from '../interfaces/featureField.types';
import { OnUpdateField } from '../interfaces/FeatureFieldAdmin.types';
import { UpdateData } from '../interfaces/FeatureFieldServices.types';

const repository = new FeatureFieldServices();

const getUpdateData = ({ isEnabled, title, options }: FeatureField): UpdateData => ({
  isEnabled,
  title,
  options: Object.values(options),
});

function usePatchFeatureField(onUpdateField: OnUpdateField) {
  const { enqueueSnackbar } = useSnackbar();

  const patchFeatureField = async (field: FeatureField) => {
    try {
      await repository.update(field.id, getUpdateData(field));
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const updateFeatureField = (field: FeatureField) => {
    patchFeatureField(field);
    onUpdateField({
      type: UpdateFeatureFieldTypes.Update,
      field,
    });
  };

  return updateFeatureField;
}

export default usePatchFeatureField;
