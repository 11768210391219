import { useState } from 'react';

import {
  propertiesColumnExtensions,
  propertiesColumns,
  statusColumnsNames,
} from '../constants/salesStatusTableColumns';

function useSalesStatusTableColumns() {
  const [columns] = useState(propertiesColumns);
  const [columnExtensions] = useState(propertiesColumnExtensions);
  const [statusColumns] = useState(statusColumnsNames);

  return { columns, columnExtensions, statusColumns };
}

export default useSalesStatusTableColumns;
