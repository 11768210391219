import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  TableHeaderRow,
  TableTreeColumn,
  TableFixedColumns,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import useColumnsForPaymentFlow from '../../../Core/customHooks/useColumnsForPaymentFlow';
import useRowsForPaymentFlow from '../../../Core/customHooks/useRowsForPaymentFlow';

const TablePaymentFlow = ({ tableRows }) => {
  const {
    columns,
    tableColumnExtensions,
    leftColumns,
  } = useColumnsForPaymentFlow(tableRows);

  const { rows, defaultExpandedRowIds, getChildRows } = useRowsForPaymentFlow(
    tableRows,
  );

  return (
    <Grid rows={rows} columns={columns}>
      <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
      <CustomTreeData getChildRows={getChildRows} />
      <VirtualTable columnExtensions={tableColumnExtensions} height="90vh" />
      <TableHeaderRow />
      <TableTreeColumn for="name" />
      <TableFixedColumns leftColumns={leftColumns} />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const tableRows = state.quotations.cashFlow.root.table;
  return {
    tableRows,
  };
};

const mapDispatchToProps = {};

TablePaymentFlow.propTypes = {
  tableRows: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePaymentFlow);
