import EnumBase from '../../../../helpers/EnumBase';

class CardinalityType {
  static DEFAULT = new EnumBase(-1, '');

  static AHEAD = new EnumBase(0, 'Adelante');

  static NEXT_TO = new EnumBase(1, 'Al lado');

  static BEHIND = new EnumBase(2, 'Atrás');

  static CENTER = new EnumBase(3, 'Centro');

  static ENTRY = new EnumBase(4, 'Entrada');

  static EAST = new EnumBase(5, 'Este');

  static OUTSIDE = new EnumBase(6, 'Exterior');

  static NORTH = new EnumBase(7, 'Norte');

  static EAST_2 = new EnumBase(8, 'Oriente');

  static WEST = new EnumBase(9, 'Occidente');

  static WEST_2 = new EnumBase(10, 'Oeste');

  static PASSAGE = new EnumBase(11, 'Pasaje');

  static SOUTH = new EnumBase(12, 'Sur');

  static findOneByValue(value) {
    const keyForValue = Object.keys(CardinalityType).find(
      (key) => CardinalityType[key].value === value,
    );
    return CardinalityType[keyForValue];
  }

  static findOneByCode(code) {
    const keyForValue = Object.keys(CardinalityType).find(
      (key) => CardinalityType[key].code === code,
    );
    return CardinalityType[keyForValue];
  }
}

export default CardinalityType;
