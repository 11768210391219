import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import ContractsEvalproGridProgressFormatter from './ContractsEvalproGridProgressFormatter';

const ContractsEvalproDetailGridProgressProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={ContractsEvalproGridProgressFormatter} {...props} />
);

export default ContractsEvalproDetailGridProgressProvider;
