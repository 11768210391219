import React, { createContext, useState } from 'react';
import Agent from './config/config';

const Context = createContext(null);

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(
    () => Agent.currentToken !== null && Agent.currentUser !== null,
    );
  const [sessionCompany, setSessionCompany] = useState("")
  const [isBadgeIncrement, setBadgeIncrement] = useState(false);

  const loadSessionCompany = company => {
    const key = "instabuild_sessioncompany"
    if (company) {
      window.sessionStorage.setItem(key, company)
      setSessionCompany(company)
    }

    if (typeof company === "string" && !company) window.sessionStorage.removeItem(key)
    if (typeof company === "undefined") {
      const _company = window.sessionStorage.getItem(key)
      if (_company) loadSessionCompany(_company)
      return _company
    }
  }

  const value = {
    isAuth,
    sessionCompany,
    loadSessionCompany,
    activateAuth: (user) => {
      Agent.saveUser(user);
      setIsAuth(true);
    },
    isBadgeIncrement,
    activateBadgeIncrement: (showBadge) => setBadgeIncrement(showBadge),
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Shared: Context,
  Provider,
  Consumer: Context.Consumer,
};
