import {
  ChangeEventHandler,
  Dispatch,
  ElementRef,
  RefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ContractLot, ForeignData } from '../../../../models/Contracts';
import { Event } from '../../../../models/ContractPayments';
import Services from '../../../../services/ContractsLots/ContractsLotsServices';
import ContractServices from '../../../../services/Contracts/ContractsServices';
import EventServices from '../../../../services/ContractEvents/ContractEventsServices';
import AsyncConfirmModal from '../../../../shared/components/AsyncModal/AsyncConfirmModal';
import constants from './constants';

const services = new Services();
const contractServices = new ContractServices();
const eventServices = new EventServices();

const useForeignData = (towerId: string) => {
  return useQuery<ForeignData>(
    constants.CONTRACTS_FOREIGN_LIST,
    async () => {
      const { data: foreignData } = await contractServices.getAllContractForeignList(towerId);
      return foreignData;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
    },
  );
};

const useContractsData = (queryTxt: string, towerId: string) => {
  return useQuery<ContractLot[]>(
    constants.CONTRACTS_LOTS,
    async () => {
      const { data } = await services.getAllContractsLots(towerId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      select: (contracts) => {
        if (queryTxt && contracts) {
          return filterContracts(contracts, queryTxt);
        }
        return contracts;
      },
    },
  );
};

const useContractEventsData = (towerId: string) => {
  return useQuery<Event[]>(
    [constants.CONTRACTS_EVENTS, towerId],
    async () => {
      const { data: contractEvents } = await eventServices.getContractEventsByTower(towerId);
      return contractEvents;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
    },
  );
};

const useDeleteContract = () => {
  const queryClient = useQueryClient();
  return useMutation((contractId: number) => contractServices.deleteContract(contractId), {
    onSettled: () => {
      queryClient.invalidateQueries(constants.CONTRACTS_LOTS);
    },
  });
};

const filterContracts = (contracts: ContractLot[], query: string) => {
  const filtered: ContractLot[] = contracts.filter((contract: ContractLot) => {
    return (
      contract.id.toString().includes(query) ||
      contract.title.toLowerCase().includes(query) ||
      contract.description.toLowerCase().includes(query) ||
      contract.item.name.toLowerCase().includes(query) ||
      contract.contractorName.toLowerCase().includes(query) ||
      contract?.state?.name.toLowerCase().includes(query)
    );
  });

  return filtered;
};

type AsyncConfirmModalRefType = ElementRef<typeof AsyncConfirmModal>;

type HookType = () => {
  contracts: ContractLot[] | undefined;
  isLoading: boolean;
  handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  currentContract: ContractLot | undefined;
  setCurrentContract: Dispatch<SetStateAction<ContractLot | undefined>>;
  openContractsFlow: boolean;
  setOpenContractsFlow: Dispatch<SetStateAction<boolean>>;
  openContractForm: boolean;
  setOpenContractForm: Dispatch<SetStateAction<boolean>>;
  isNewContract: boolean;
  setIsNewContract: Dispatch<SetStateAction<boolean>>;
  openContractDetail: boolean;
  setOpenContractDetail: Dispatch<SetStateAction<boolean>>;
  handleDelete: (contract: ContractLot) => Promise<void>;
  asyncConfirmDeleteContractModalRef: RefObject<AsyncConfirmModalRefType>;
};

const useContractsLots: HookType = () => {
  const { towerId }: { towerId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [queryTxt, setQueryTxt] = useState('');
  const asyncConfirmDeleteContractModalRef = useRef<AsyncConfirmModalRefType>(null);

  const { data: contracts, isLoading } = useContractsData(queryTxt, towerId);
  const { data: contractsForeignData } = useForeignData(towerId);
  const { data: contractsEventsData } = useContractEventsData(towerId);
  const { mutateAsync: deleteContract } = useDeleteContract();

  const [currentContract, setCurrentContract] = useState<ContractLot | undefined>();

  const [openContractsFlow, setOpenContractsFlow] = useState<boolean>(false);

  const [openContractForm, setOpenContractForm] = useState<boolean>(false);
  const [openContractDetail, setOpenContractDetail] = useState<boolean>(false);
  const [isNewContract, setIsNewContract] = useState<boolean>(false);

  const handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    e.preventDefault();
    const query: string = e.target.value.toLowerCase();
    setQueryTxt(query);
  };

  const handleDelete = useCallback(async (contract: ContractLot) => {
    await asyncConfirmDeleteContractModalRef.current?.show(async () => {
      try {
        await deleteContract(contract.id);
        setCurrentContract(undefined);
        enqueueSnackbar('Contrato con eliminado con éxito', {
          variant: 'success',
        });
      } catch ({ message }) {
        enqueueSnackbar(`${message}`, { variant: 'error' });
      }
    });
  }, []);

  return {
    contracts,
    isLoading,
    handleChangeSearch,
    currentContract,
    setCurrentContract,
    openContractsFlow,
    setOpenContractsFlow,
    openContractForm,
    setOpenContractForm,
    openContractDetail,
    setOpenContractDetail,
    handleDelete,
    asyncConfirmDeleteContractModalRef,
    isNewContract,
    setIsNewContract,
  };
};

export default useContractsLots;
