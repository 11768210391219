import definitions from './Quotations.definitions';
import Services from '../services';

class QuotationsServices extends Services {
  postTempQuotation(quotation, towerId) {
    return this.post(definitions.postTempQuotation(towerId), quotation);
  }

  putQuotationToPermanent(quotationId, data) {
    return this.put(definitions.putQuotationToPermanent(quotationId), data);
  }

  getClientQuotations(clientId, propertyId, towerId) {
    return this.get(
      definitions.getClientQuotations(clientId, propertyId, towerId),
    );
  }

  getQuotation(quotationId, towerId) {
    return this.get(definitions.getQuotation(quotationId, towerId));
  }

  getQuotationWithRealPayments(quotationId, towerId) {
    return this.get(
      definitions.getQuotationWithRealPayments(quotationId, towerId),
    );
  }

  getQuotationSetupByTowerId(towerId) {
    return this.get(definitions.getQuotationSetupByTowerId(towerId));
  }

  deleteQuotation(quotationId) {
    return this.delete(definitions.deleteQuotation(quotationId));
  }

  putDistribution(quotationId, distribution) {
    return this.put(definitions.putDistribution(quotationId), distribution);
  }

  putPaymentTypes(data) {
    return this.put(definitions.putQuotationPaymentTypes(), data);
  }

  deletePaymentType(data) {
    return this.delete(definitions.deleteQuotationPaymentTypes(), data);
  }

  deleteFinalPaymentType(data) {
    return this.delete(definitions.deleteFinalPaymentType(), data);
  }

  putFinalPaymentTypes(data) {
    return this.put(definitions.putQuotationFinalPaymentTypes(), data);
  }

  putAssignment(quotationId, data) {
    return this.put(definitions.putQuotationAssignment(quotationId), data);
  }

  putPlanAssignment(data) {
    return this.put(definitions.putPaymentPlanAssignment(), data);
  }

  putQuotationSetup(towerId, id, value) {
    return this.put(definitions.putQuotationSetup(), {
      towerId,
      id,
      value,
    });
  }

  putQuotation(quotationId, data) {
    return this.put(definitions.putQuotation(quotationId), data);
  }

  putQuotationDeedExpense(towerId, quotationSetupId, id, value) {
    return this.put(definitions.putQuotationDeedExpense(), {
      towerId,
      quotationSetupId,
      id,
      value,
    });
  }

  patchQuotation(quotationId, data) {
    return this.patch(definitions.patchQuotation(quotationId), data);
  }

  getTemplate(towerId, quotationId, startDate, financialDiscount) {
    return this.get(
      definitions.getSchemaTemplate(
        towerId,
        quotationId,
        startDate,
        financialDiscount,
      ),
      {
        responseType: 'blob',
      },
    );
  }

  postTemplate(towerId, quotationId, financialDiscount, form) {
    return this.post(
      definitions.postSchemaTemplate(towerId, quotationId, financialDiscount),
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }

  getQuotationDistribution(
    towerId,
    quotationId,
    customDistribution,
    realPrice,
    isLockedType,
    indexSelected,
  ) {
    return this.post(definitions.quotationDistribution(), {
      towerId,
      quotationId,
      customDistribution,
      realPrice,
      indexSelected,
      isLockedType,
    });
  }

  getQuotationDistributionForAssigned(
    towerId,
    quotationId,
    customDistribution,
    realPrice,
    isLockedType,
    indexSelected,
    realPayments,
    lastIndexToResetDistribution,
  ) {
    return this.post(definitions.assignedDiscountDistribution(), {
      towerId,
      quotationId,
      customDistribution,
      realPrice,
      indexSelected,
      isLockedType,
      realPayments,
      lastIndexToResetDistribution,
    });
  }

  downloadQuotationPDF(quotation, towerId) {
    return this.post(definitions.downloadPDF(), { quotation, towerId });
  }

  downloadPaymentPlanPdf(quotation, towerId) {
    return this.post(definitions.downloadAssignedPaymentPDF(), {
      quotation,
      towerId,
    });
  }

  saveAssignedPayment(towerId, quotation) {
    return this.post(definitions.createPaymentPlanEdited(), {
      ...quotation,
      towerId,
    });
  }
}

export default QuotationsServices;
