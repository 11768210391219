import React, { FC } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { GeneralWalletThreadInteractionDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import Typography from 'shared/components/Typography/Typography';
import { HandlePopoverOpenType } from 'shared/customHooks/useCustomPopover';

import styles from './GeneralWalletDetailsThread.module.scss';

interface Props {
  onClick: HandlePopoverOpenType<GeneralWalletThreadInteractionDTO>;
}

const GeneralWallletDetailsThreadInteractionCommentContent: FC<GeneralWalletThreadInteractionDTO & Props> = (
  interaction,
) => {
  const { user, initial, createdAt, comment, isEditable, onClick } = interaction;
  return (
    <div className={styles.ThreadInteractionComment}>
      <div className={styles.ThreadInteractionHeader}>
        <div>
          <Typography variant="subtitle1">{user.name}</Typography>
          <br />
          <Typography variant="caption" fontStyle="italic">{`${
            initial ? 'Agregó' : 'Respondió'
          } un comentario - `}</Typography>
          <Typography variant="caption">{moment(createdAt).format('DD [de] MMMM [de] YYYY')}</Typography>
        </div>
        {isEditable && (
          <IconButton onClick={(e) => onClick(e, interaction)}>
            <MoreVert />
          </IconButton>
        )}
      </div>
      <div>
        <Typography>{comment}</Typography>
      </div>
    </div>
  );
};

export default GeneralWallletDetailsThreadInteractionCommentContent;
