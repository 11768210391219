import { ChangeEventHandler, useEffect, useState } from 'react';
import { Contractor } from '../../../../models/Contractors';

const useContractorList = (originalContractors: Contractor[]) => {
  const [contractors, setContractors] = useState<Contractor[]>([]);

  useEffect(() => {
    setContractors(originalContractors);
  }, [originalContractors]);

  const handleChangeSearch: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    e.preventDefault();
    setContractors(
      originalContractors.filter((contractor) => {
        if (contractor.patnerName) {
          return contractor.patnerName
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
      }),
    );
  };

  const reset = () => {
    setContractors(originalContractors);
  };

  return {
    contractors,
    handleChangeSearch,
    reset,
  };
};

export default useContractorList;
