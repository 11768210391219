import _ from 'lodash';
import { useEffect, useState } from 'react';

export default function SchemasCalcs(salesRoom) {
  const [BedRooms, setBedRooms] = useState([]);
  const [BathRooms, setBathRooms] = useState([]);
  const [salesRoomState] = useState(salesRoom);

  const getCharacteristics = (properties) =>
    properties
      .flatMap((property) => property.propertyCharacteristic)
      .filter((el) => el);

  const findAll = (propertyName, mainArr) =>
    mainArr.flatMap((characteristics) => characteristics[propertyName]);

  const cleanArr = (arrToClean) =>
    [...new Set(arrToClean)].map((el) => ({ label: `${el}`, value: el }));

  const main = () => {
    const props = salesRoomState.properties
      ? salesRoomState.properties.flatMap((p) => p)
      : [];
    const characteristics = getCharacteristics(props);
    const fullBathrooms = findAll('bathrooms', characteristics);
    const fullBedrooms = findAll('bedrooms', characteristics);
    const bathroomsArr = cleanArr(fullBathrooms);
    const bedroomsArr = cleanArr(fullBedrooms);
    setBathRooms(_.sortBy(bathroomsArr, (el) => el.value));
    setBedRooms(_.sortBy(bedroomsArr, (el) => el.value));
  };

  useEffect(() => {
    main();
  }, [salesRoomState]);

  return { BedRooms, BathRooms };
}
