import { Modal as MuiModal } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { ModalProps } from '../../interfaces/Modal.types';
import styles from './Modal.module.scss';

const Modal: React.FC<ModalProps> = ({ className, children, ...restProps }) => (
  <MuiModal {...restProps}>
    <div className={clsx(styles.root, className)}>{children}</div>
  </MuiModal>
);

export default Modal;
