import { API_PATH } from '../../config/config';

const ContractsServicesDefinition = {
  getAllContractors: (towerId: string) => `${API_PATH}contract/business-partner/${towerId}`,
  getAllContractStates: (towerId: string) => `${API_PATH}contracts/states/${towerId}`,
  getAllContractCategories: (towerId: string) => `${API_PATH}contracts/categories/${towerId}`,
  getAllContracts: (towerId: string) => `${API_PATH}contracts/${towerId}`,
  getContractsFlow: (towerId: string) => `${API_PATH}contracts/flow/${towerId}`,
  downloadContractsFlow: (towerId: string) => `${API_PATH}contracts/download-flow/${towerId}`,
  getAllContractForeignList: (towerId: string) => `${API_PATH}contracts/foreign-lists/${towerId}`,
  getContract: (contracId: number) => `${API_PATH}contracts/id/${contracId}`,
  createContract: (towerId: string) => `${API_PATH}contracts/${towerId}`,
  editContract: (contracId: number) => `${API_PATH}contracts/id/${contracId}`,
  deleteContract: (contracId: number) => `${API_PATH}contracts/id/${contracId}`,
};

export default ContractsServicesDefinition;
