import { DragEventHandler, useEffect, useState } from 'react';
import MoveCardTypes from '../enums/MoveCardTypes';

import { MoveCardHandler, MoveCardPoint } from '../types/Stages.types';
import StagesDragAndDropStore from '../utils/StagesDragAndDropStore';

type CardElement = HTMLElement & {
  dataset: {
    cardId: string;
    index: string;
    stageId: string;
    type: string;
  };
};

const getDataIndex = (element: CardElement): number => +element.dataset.index;

const getDataStageId = (element: CardElement): number => +element.dataset.stageId;

const isLastElementCard = (element: CardElement): boolean => element.dataset.type === 'lastCard';

type MovePoints = {
  fromRow: number;
  toRow: number;
  fromColumn: number;
  toColumn: number;
};

const isMovedBetweenColumns = (fromColumn: number, toColumn: number): boolean => fromColumn !== toColumn;

const areDiferentRows = (fromRow: number, toRow: number): boolean => fromRow !== toRow;

const isMovedBetweenRows = ({ fromRow, toRow }: MovePoints, isLastCard: boolean): boolean =>
  !isLastCard && areDiferentRows(fromRow, toRow);

const isValidMove = (points: MovePoints, isLastCard: boolean): boolean =>
  isMovedBetweenColumns(points.fromColumn, points.toColumn) || isMovedBetweenRows(points, isLastCard);

const getMoveCardPoint = (row: number, column: number): MoveCardPoint => ({
  row,
  column,
});

type UseStageTowerDragAndDrop = {
  isGrabbingCSSClass: string;
  onMoveCard: MoveCardHandler;
};

const store = new StagesDragAndDropStore();

function useStageTowerDragAndDrop({ isGrabbingCSSClass, onMoveCard }: UseStageTowerDragAndDrop) {
  const [movedCardId, setMovedCardId] = useState('');

  const handleDragStart: DragEventHandler<CardElement> = (event) => {
    event.dataTransfer.effectAllowed = 'move';
    event.currentTarget.classList.add(isGrabbingCSSClass);
    store.setCardId(event.currentTarget.dataset.cardId);
    store.setFromRow(getDataIndex(event.currentTarget));
    store.setFromColumn(getDataStageId(event.currentTarget));
    event.currentTarget.addEventListener('dragend', handleDragEnd);
  };

  const handleDragEnter: DragEventHandler<CardElement> = (event) => {
    event.preventDefault();
    const cardId = store.cardId;
    const fromRow = store.fromRow;
    const fromColumn = store.fromColumn;
    const toRow = getDataIndex(event.currentTarget);
    const toColumn = getDataStageId(event.currentTarget);
    const isLastCard = isLastElementCard(event.currentTarget);

    if (isValidMove({ fromRow, toRow, fromColumn, toColumn }, isLastCard)) {
      const cords = {
        from: getMoveCardPoint(fromRow, fromColumn),
        to: getMoveCardPoint(toRow, toColumn),
      };
      onMoveCard({
        type: MoveCardTypes.Moved,
        cardId,
        cords,
      });
      store.setFromRow(toRow);
      store.setFromColumn(toColumn);
      store.setCords(cords);
    }

    setMovedCardId(cardId);
  };

  const handleDragOver: DragEventHandler<CardElement> = (event) => {
    event.preventDefault();
  };

  const handleDrop: DragEventHandler<CardElement> = (event) => {
    event.preventDefault();
  };

  const handleDragEnd = (event) => {
    event.currentTarget.classList.add(isGrabbingCSSClass);
    event.currentTarget.removeEventListener('dragend', handleDragEnd);
    onMoveCard({
      type: MoveCardTypes.End,
      cardId: store.cardId,
      cords: store.cords,
    });
    store.reset();
    setMovedCardId('');
  };

  useEffect(() => {
    const cleanup = () => {
      store.reset();
    };

    return cleanup;
  }, []);

  return { movedCardId, handleDragStart, handleDragEnter, handleDragOver, handleDrop };
}

export default useStageTowerDragAndDrop;
