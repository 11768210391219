import React, { FC } from 'react';
import Close from '@material-ui/icons/Close';
import { Box, IconButton } from '@material-ui/core';
import { ClientDetailsType } from 'App/GeneralWalletDetails/core/interfaces/ClientDetails.type';
import Typography from 'shared/components/Typography/Typography';

import styles from './GeneralWalletDetailsHeader.module.scss';

interface Props {
  client: ClientDetailsType;
  onClose: () => void;
}

const GeneralWalletDetailsHeader: FC<Props> = ({ client, onClose }) => (
  <Box className={styles.Header}>
    <Typography variant="headline6">{client.clientName}</Typography>
    <IconButton className={styles.CloseButton} onClick={onClose}>
      <Close />
    </IconButton>
  </Box>
);

export default GeneralWalletDetailsHeader;
