import React from 'react';
import { PropTypes } from 'prop-types';
import ClientAddressModalForMainInfo from './modal/ClientAddressModalForMainInfo';
import ClientAddressModalForOffice from './modal/ClientAddressModalForOffice';

const ClientAddressModalMainView = ({ isOpen, onClose, isMain }) => {
  return (
    <>
      {isMain && (
        <ClientAddressModalForMainInfo isOpen={isOpen} onClose={onClose} />
      )}
      {!isMain && (
        <ClientAddressModalForOffice isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

ClientAddressModalMainView.propTypes = {
  isOpen: PropTypes.bool,
  isMain: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ClientAddressModalMainView;
