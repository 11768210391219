import { ClientModel } from '../models/ClientModel';

const GetInitialClient = (): ClientModel => ({
  allowDelete: true,
  associated: false,
  email: '',
  identityDocument: '',
  identityDocumentType: 0,
  middleName: '',
  name: '',
  phoneNumber: '',
  secondSurname: '',
  surname: '',
  vid: '',
  createdAt: '',
  updatedAt: '',
});

export default GetInitialClient;
