import { useSnackbar } from 'notistack';
import { useEffect, useState, FormEvent } from 'react';

import FeatureFieldServices from '../../services/FeatureFieldServices';
import { ValidFeatures } from '../enums/featureField.enum';
import FeatureFieldUtils from '../FeatureFieldUtils';
import { EditableFeatureField, FeatureFieldDTO } from '../interfaces/featureField.types';
import { CreateData, UpdateData } from '../interfaces/FeatureFieldServices.types';
import { SaveFeatureFieldHook } from '../interfaces/useSaveFeatureField.types';

const repository = new FeatureFieldServices();

const getUpdateData = ({ isEnabled, title, options }: EditableFeatureField): UpdateData => ({
  isEnabled,
  title,
  options,
});

const updateField = async (id: number, editableField: EditableFeatureField): Promise<FeatureFieldDTO> => {
  const { data } = await repository.update(id, getUpdateData(editableField));

  return data;
};

const getNewFieldData = (
  feature: ValidFeatures,
  { title, index, type, options }: EditableFeatureField,
): CreateData => ({
  title,
  index,
  type,
  feature,
  options,
});

const createField = async (feature: ValidFeatures, editableField: EditableFeatureField): Promise<FeatureFieldDTO> => {
  const { data } = await repository.create(getNewFieldData(feature, editableField));

  return data;
};

const saveFeatureField = (feature: ValidFeatures, editableField: EditableFeatureField): Promise<FeatureFieldDTO> =>
  editableField.id !== undefined ? updateField(editableField.id, editableField) : createField(feature, editableField);

function useSaveFeatureField({ isOpen, feature, editableField, onFieldSaved }: SaveFeatureFieldHook) {
  const [isSavingField, setIsSavingField] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    closeSnackbar();
  }, [isOpen]);

  const saveField = async () => {
    try {
      setIsSavingField(true);
      const data = await saveFeatureField(feature, editableField);
      onFieldSaved(FeatureFieldUtils.getFeatureField(data));
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error', persist: true });
    } finally {
      setIsSavingField(false);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.checkValidity()) {
      saveField();
    }
  };

  return { isSavingField, handleSubmit };
}

export default useSaveFeatureField;
