import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Counter from './Counter';
import BudgetGrid from './Grid';
import useSalesStartDateForDistribution from './Core/CustomHooks/useSalesStartDateForDistribution';

const Distribution = ({
  units,
  saleSpeed,
  salesStartDate,
  submitHandler,
  manualSalesSpeed = null,
  manualRotation = null,
  length,
  setLength,
  distributionTemp,
  setDistributionTemp,
}) => {
  const { startDate } = useSalesStartDateForDistribution(salesStartDate);

  return (
    <Paper>
      <Box p={3}>
        <Counter units={units} length={length} setLength={setLength} />
        <BudgetGrid
          units={units}
          length={length}
          startDate={startDate}
          submitHandler={submitHandler}
          manualSalesSpeed={manualSalesSpeed}
          distributionTemp={distributionTemp}
          setDistributionTemp={setDistributionTemp}
        />
      </Box>
    </Paper>
  );
};

Distribution.propTypes = {
  units: PropTypes.number.isRequired,
  saleSpeed: PropTypes.number,
  salesStartDate: PropTypes.any,
  submitHandler: PropTypes.func,
  distribution: PropTypes.array.isRequired,
  manualSalesSpeed: PropTypes.array,
  manualRotation: PropTypes.number,
  length: PropTypes.number,
  setLength: PropTypes.func,
  distributionTemp: PropTypes.array,
  setDistributionTemp: PropTypes.func,
};

Distribution.defaultProps = {
  saleSpeed: 0,
  salesStartDate: new Date().valueOf(),
  submitHandler: () => null,
};

export default Distribution;
