import saleDateSelectorServicesDefinitions from './saleDateSelectorServicesDefinitions';
import Services from '../services';

export default class ClientsServices extends Services {
  findHistoryIn(towerId) {
    return this.get(saleDateSelectorServicesDefinitions.root(towerId));
  }

  updateSaleHistoryOf(towerId, data) {
    return this.put(saleDateSelectorServicesDefinitions.root(towerId), data);
  }
}
