import ContractEventsServicesDefinition from './ContractEventsServicesDefinition';
import Services from '../services';
import { Event } from '../../models/ContractPayments';

export default class ContractEventsService extends Services {
  getContractEventsByTower(towerId: string) {
    return this.get<Event[]>(ContractEventsServicesDefinition.getContractEventsByTower(towerId));
  }

  createContractEvent(towerId: string, data) {
    return this.post<Event>(ContractEventsServicesDefinition.createContractEvent(towerId), data);
  }

  editContractEvent(contractEventId: number, data) {
    return this.put<Event>(ContractEventsServicesDefinition.editContractEvent(contractEventId), data);
  }
}
