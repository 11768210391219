import Services from '../../../services/services';
import TotalCashFlowServicesDefinitions from './TotalCashFlowServicesDefinitions';

export default class TotalCashFlowServices extends Services {
  getTotalCashFlow(towerId) {
    return this.get(TotalCashFlowServicesDefinitions.total(towerId));
  }

  updateTotalCashFlow(towerId, row, key, value) {
    return this.put(TotalCashFlowServicesDefinitions.updateTotalCashFlow(towerId), { row, key, value })
  }

  downloadCashflowServices(towerId) {
    const route = TotalCashFlowServicesDefinitions.downloadQuotationCashflow(
      towerId,
    );
    return this.get(route, {
      responseType: 'blob',
    });
  }
}
