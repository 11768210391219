import { DeedExpenseType } from '../DTO/QuotationConfigDto';
import { updateQuotationConfigDeedExpenseType } from '../QuotationConfigQueryClient';

const useDeedExpenses = (quotationSetup: DeedExpenseType) => {
  const updateDeedExpenseValue = (key: keyof DeedExpenseType, newValue: number) =>
    updateQuotationConfigDeedExpenseType({ [key]: newValue });

  const isValidDeedExpenseType = Object.values(quotationSetup).every((value) => !!value);

  return { updateDeedExpenseValue, isValidDeedExpenseType };
};

export default useDeedExpenses;
