import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const ContractsLotsInterestRatesFormItem = styled(Grid)`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 8px;
  min-height: 48px;
  flex-wrap: wrap;
`;

export default ContractsLotsInterestRatesFormItem;
