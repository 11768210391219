import React, { Component, FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { StylesProvider } from '@material-ui/core/styles';
import { DashboardRoutes, UserRoutes } from './routes/local/routes';
import Dashboard from './containers/Dashboard/Dashboard';
import { Login } from './containers/User';
import ForgotPassword from './App/ForgotPassword/UI/ForgotPasswordMainView';
import ChangePassword from './App/ForgotPassword/UI/ChangePassword';
import './App.scss';
import CustomRoute from './config/PrivateRoute';
import store from './store';

const App: FC = () => {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <CustomRoute
              exact
              path={`(/|${UserRoutes.login})`}
              component={Login}
              isPrivate={false}
            />
            <CustomRoute
              exact
              path={UserRoutes.forgotPassword}
              component={ForgotPassword}
              isPrivate={false}
            />
            <CustomRoute
              exact
              path={UserRoutes.changePassword}
              component={ChangePassword}
              isPrivate={false}
            />
            <CustomRoute path={DashboardRoutes.base} component={Dashboard} />
          </BrowserRouter>
        </SnackbarProvider>
      </StylesProvider>
    </Provider>
  );
};

export default App;
