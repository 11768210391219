import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

function useClientHubMapper() {
  const { clientHub } = useSelector((state) => state.realPayments.root);

  const [currentHub, setCurrentHub] = useState({});

  const arrearsValue =
    clientHub.commitment -
    clientHub.accumulatedPayments -
    clientHub.financialAccumulated;
  const initialRealPayment =
    clientHub.balanceCalculated - clientHub.finalPayment;

  const totalPayment =
    clientHub.commercialPrice -
    clientHub.accumulatedPayments +
    clientHub.financialAccumulated;
  const minPaymentToday =
    clientHub.commitment -
    clientHub.accumulatedPayments +
    clientHub.financialAccumulated;

  useEffect(() => {
    const hub = {
      ...clientHub,
      arrearsValue,
      initialRealPayment,
      totalPayment,
      minPaymentToday,
    };
    setCurrentHub(hub);
  }, [clientHub]);

  return currentHub;
}

export default useClientHubMapper;
