import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Services from '../../../../services/ContractsEvalpro/ContractsEvalproServices';
import { TowerStatistics } from '../../../../models/Tower/TowerStatistics';
import constants from './constants';

const services = new Services();

const useContractsEvalProTowerStatisticsData = (towerId: string) => {
  return useQuery<TowerStatistics>(
    constants.CONTRACTS_EVALPRO_TOWER_STATISTICS,
    async () => {
      const { data } = await services.getEvalproTowerStatistics(towerId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

type HookType = () => {
  totalSales: number;
  isLoading: boolean;
};

const useContractsEvalProTowerStatistics: HookType = () => {
  const { towerId }: { towerId: string } = useParams();
  const { data, isLoading } = useContractsEvalProTowerStatisticsData(towerId);
  const totalSales = data?.totalSales || 0;
  return { totalSales, isLoading };
};

export default useContractsEvalProTowerStatistics;
