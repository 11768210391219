import { useEffect } from "react"
import SalesRoomEnum from "../../../../containers/SalesRoom/SalesRoom.enum"
import SalesRoomService from "../../../../services/salesRoom/salesRoomService"

const service = new SalesRoomService()

const useResolveHistory = ({
    currentState,
    propertyId,
    clientId,
    onChangeDeadlineDate
}) => {
    const fetch = async () => {
        const { data } = await service.getHistory({
            state: SalesRoomEnum.status.OPTIONAL,
            propertyId,
            clientId
        })
        if (data && data.deadlineDate) {
            try {
                const time = Number(data.deadlineDate)
                onChangeDeadlineDate(new Date(time))
            } catch (error) {
                console.error("Invalid date")
            }
        }
    }
    useEffect(()=>{
        if (currentState === SalesRoomEnum.status.OPTIONAL) {
            fetch()
        }
    }, [currentState])

    return {
        ins: 1
    }
}
export default useResolveHistory