import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const mainColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;
  return {
    root: ({ isSelected }) => ({
      flexGrow: 1,
      flexBasis: 0,
      padding: 10,
      margin: '0 4px 0 4px',
      border: `1px solid ${isSelected ? secondaryColor : mainColor}`,
      borderRadius: 2,
      color: isSelected ? secondaryColor : mainColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      '&.Mui-disabled': {
        opacity: '0.5',
      },
      '& > .Mui-disabled': {
        color: isSelected ? secondaryColor : mainColor,
      },
    }),
  };
});

const RadioButtonItem = ({ value, disabled, label, currentState }) => {
  const classes = useStyles({ isSelected: value === currentState });

  return (
    <FormControlLabel
      className={classes.root}
      value={value}
      control={<Radio />}
      disabled={disabled}
      labelPlacement="start"
      label={label}
    />
  );
};

RadioButtonItem.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  currentState: PropTypes.string,
};

export default RadioButtonItem;
