import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { AreasDB } from '../../indexedDB/AreaIndexedDB';
import { IAreaCustomColumn } from '../../interfaces/IAreaCustomColumn';
import IPropertyArea from '../../interfaces/IPropertyArea';
import { AreaEditionValueAtom } from '../../recoil/AreaEditionValueAtom';
import { AreaEditionTotalTableCellProps } from './AreaEditionTotalTotalProviderProps';

const useAreaEditionTotalTableCell = (props: AreaEditionTotalTableCellProps) => {
  const columnName = props.tableColumn.column!.name;
  const row: IPropertyArea = props.tableRow.row;
  const propValue = row[columnName] ?? 0;
  const [areaEditionValueState] = useRecoilState(AreaEditionValueAtom);
  const [value, setValue] = useState<number>(propValue);
  const [loading, setLoad] = useState(false);

  const getTotalReduce = (areaTypes: IAreaCustomColumn[], propertyArea: IPropertyArea) => {
    if (!areaTypes.length) return 0;
    return areaTypes.reduce((prev: number, key) => {
      const value: number = propertyArea[key.id] ?? 0;
      return prev + value;
    }, 0);
  };

  const initialize = useCallback(async (areaTypes: IAreaCustomColumn[]) => {
    setLoad(true);
    if (!areaTypes.length || !row?.id) setValue(0);
    const total = getTotalReduce(areaTypes, row);
    setValue(total);
    setLoad(false);
  }, []);

  useEffect(() => {
    initialize(props.areaTypes);
  }, [props.areaTypes]);

  const refreshTotalValueByRowChanged = async () => {
    if (!props.areaTypes.length) return 0;
    const propertyArea = await AreasDB.properties.get(row.id);
    if (!propertyArea?.id) return 0;
    const total = getTotalReduce(props.areaTypes, propertyArea);
    setValue(total);
  };

  useEffect(() => {
    if (areaEditionValueState.rowId === row.id) refreshTotalValueByRowChanged();
  }, [areaEditionValueState]);

  return { value, loading };
};
export default useAreaEditionTotalTableCell;
