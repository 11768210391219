import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import CustomDrawer from 'shared/components/CustomDrawer/CustomDrawer';
import CustomDrawerHeader from 'shared/components/CustomDrawer/CustomDrawerHeader';
import Message from 'shared/components/Message/Message';
import DeedExpensesPropertiesSoldConfigDisplacementForm from './DeedExpensesPropertiesSoldConfigDisplacementForm/DeedExpensesPropertiesSoldConfigDisplacementForm';
import useDeedExpensesPropertiesSoldConfig from 'App/DeedExpenses/core/customHooks/useDeedExpensesPropertiesSoldConfig';
import {
  QuotationSetup,
  ScheduleServicesGetDatesResponse,
} from 'App/DeedExpenses/core/interfaces/ScheduleServicesGetDatesResponse';

interface Props {
  isOpen: boolean;
  quotationSetup?: QuotationSetup;
  averageDeliveryDate?: string;
  onClose: (displacement?: number) => void;
}

const DeedExpensesPropertiesSoldConfig: FC<Props> = ({ isOpen, quotationSetup, averageDeliveryDate, onClose }) => {
  const { isLoading, onChange } = useDeedExpensesPropertiesSoldConfig();
  return (
    <CustomDrawer anchor="right" open={isOpen}>
      <CustomDrawerHeader
        title="Configurar desplazamiento"
        onClose={() => onClose(quotationSetup?.defualtDeedDisplacement)}
      />
      <Box padding="24px" paddingTop="11px">
        <Box marginBottom="40px">
          <Message
            state="info"
            text="Asigna un desplazamiento preterminado para las fechas de escrituración de ventas nuevas."
          />
        </Box>
        <Box paddingX="16px">
          {quotationSetup && averageDeliveryDate && (
            <DeedExpensesPropertiesSoldConfigDisplacementForm
              isDisabled={isLoading}
              value={quotationSetup.defualtDeedDisplacement}
              onChange={onChange}
              finalPaymentDate={averageDeliveryDate}
            />
          )}
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default DeedExpensesPropertiesSoldConfig;
