import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Icon from '../../../shared/components/Icon/Icon';
import Styles from './DownloadButton.module.scss';
import Typography from '../../../shared/components/Typography/Typography';
import styled from 'styled-components';
import clsx from 'clsx';

const CustomButton = styled(Button)`
  height: ${(props) => props.height || 'inherit'};
  width: ${(props) => props.width || 'inherit'};
  border-radius: ${(props) => props.borderRadius || 'inherit'};
`;

const DownloadButton = ({
  children,
  onClick,
  isEnabled,
  height,
  width,
  borderRadius,
  className,
}) => {
  return (
    <CustomButton
      className={clsx(Styles.root, className)}
      variant="outlined"
      height={height}
      width={width}
      borderRadius={borderRadius}
      startIcon={
        <Icon
          variant="Download"
          color="primary600"
          height="20px"
          width="20px"
        />
      }
      disabled={!isEnabled}
      onClick={onClick}
    >
      <Typography variant="button-small" color="primary600">
        {children}
      </Typography>
    </CustomButton>
  );
};

DownloadButton.propTypes = {
  children: PropTypes.node.isRequired,
  isEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  className: PropTypes.string,
};

DownloadButton.defaultProps = {
  isEnabled: true,
};

export default DownloadButton;
