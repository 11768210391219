import React from 'react';
import Card, { CardBody, CardHeader } from '../../UI/Card/Card';
import PropTypes from 'prop-types';
import { Grid, FormGroup, FormControlLabel, Switch } from '@material-ui/core';

function GeneralStrategySetup({ strategySetup, update }) {
  return (
    <Card>
      <CardHeader>
        <span>Configuración de Estrategia</span>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <FormControlLabel
            name="automaticPrices"
            control={
              <Switch
                color="primary"
                checked={strategySetup.automaticPrices}
                onChange={update}
              />
            }
            checked={strategySetup.automaticStrategy || false}
            onChange={update}
            label="Precios sugeridos automáticos"
          />
          <FormControlLabel
            name="automaticStrategy"
            control={<Switch color="primary" />}
            checked={strategySetup.automaticStrategy || false}
            onChange={update}
            label="Estrategia automática al desistir"
          />
          <FormControlLabel
            name="approvalTradeDiscounts"
            control={<Switch color="primary" />}
            checked={strategySetup.approvalTradeDiscounts || false}
            onChange={update}
            label="Aprobación descuentos comerciales"
          />
          <FormControlLabel
            name="approvalFinancialDiscounts"
            control={<Switch color="primary" />}
            checked={strategySetup.approvalFinancialDiscounts || false}
            onChange={update}
            label="Aprobación descuentos financieros"
          />
        </FormGroup>
      </CardBody>
    </Card>
  );
}

GeneralStrategySetup.defaultProps = {
  strategySetup: {},
};

GeneralStrategySetup.propTypes = {
  strategySetup: PropTypes.object,
  update: PropTypes.func,
};

export default GeneralStrategySetup;
