import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { useParams } from 'react-router-dom';
import ReservationDepositRow from './ReservationDeposit';
import FinalFeeRow from './FinalFee';
import MonthlyPaymentRow from './MonthlyPayment';
import IsEditableContext from './contexts';
import useAssignmentQuotation from '../helpers/customHooks/useAssignmentQuotation';
import QuotationsServices from '../../../../../services/Quotations/Quotations.services';
import Numbers from '../../../../../helpers/numbers';
import AssignedPaymentHeaderRow from '../../rows/AssignedPaymentHeaderRow';

const services = new QuotationsServices();

const validationScheme = yup.object().shape({
  finalFee: yup
    .number()
    .min(0)
    .required(),
  reservationDeposit: yup.number().required(),
  distribution: yup
    .array()
    .of(yup.number())
    .required(),
});

const Payments = ({
  quotation,
  dispatchFetchQuotationSuccess,
  isEditable,
  showEditableButtons,
  enableDiscountCalculation,
}) => {
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();

  const { updateClientsWithQuotation } = useAssignmentQuotation();

  const submitHandler = async (values) => {
    try {
      const {
        finalFee,
        reservationDeposit,
        distribution,
        periods,
        paymentStartDate,
        discountPrice,
        discountType,
      } = values;
      const response = await services.patchQuotation(quotation.id, {
        finalFee: Number(finalFee),
        reservationDeposit: Number(reservationDeposit),
        distribution: distribution.map(Number),
        periods: Number(periods),
        paymentStartDate,
        discountPrice: Math.round(discountPrice),
        discountType,
        priceWithDiscount: Math.round(
          Numbers.cleanNumber(quotation.priceWithAdditionalAreas) -
            Numbers.cleanNumber(quotation.discount) -
            Numbers.cleanNumber(quotation.discountPrice),
        ),
        paymentTypes: quotation.paymentTypes.map(Number),
        towerId,
        initialDate: quotation.initialDate,
      });

      dispatchFetchQuotationSuccess(response.data);
      updateClientsWithQuotation(response.data);
      enqueueSnackbar('Cuotas editadas correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const onChangeForm = (event, handleChange) => {
    return handleChange(event);
  };

  return (
    <IsEditableContext.Provider value={isEditable}>
      <Formik
        initialValues={{
          finalFee: quotation.finalPayment,
          reservePercentage: quotation.reservePercentage,
          distribution: quotation.distribution,
          paymentStartDate: quotation.paymentStartDate,
        }}
        validationSchema={validationScheme}
        onSubmit={submitHandler}
        innerRef={formRef}
      >
        {({ handleChange }) => {
          const onChange = (e) => onChangeForm(e, handleChange);
          return (
            <Form>
              <TableContainer component={Paper}>
                <MuiTable>
                  <TableHead>
                    <AssignedPaymentHeaderRow
                      showEditableButtons={true}
                      enableDiscountCalculation={false}
                    />
                  </TableHead>

                  <TableBody>
                    <ReservationDepositRow
                      onChange={onChange}
                      quotation={quotation}
                      showEditableButtons={false}
                      enableDiscountCalculation={enableDiscountCalculation}
                      dispatchFetchQuotationSuccess={
                        dispatchFetchQuotationSuccess
                      }
                    />
                    {Array.from(Array(Math.max(0, quotation.periods))).map(
                      (_, index) => (
                        <MonthlyPaymentRow
                          quotation={quotation}
                          key={`MonthlyPayment-${index}`}
                          index={index}
                          isEditable={false}
                          onChange={onChange}
                          showEditableButtons={showEditableButtons}
                          enableDiscountCalculation={enableDiscountCalculation}
                          dispatchFetchQuotationSuccess={
                            dispatchFetchQuotationSuccess
                          }
                        />
                      ),
                    )}
                    <FinalFeeRow
                      onChange={onChange}
                      quotation={quotation}
                      showEditableButtons={false}
                      enableDiscountCalculation={false}
                      dispatchFetchQuotationSuccess={
                        dispatchFetchQuotationSuccess
                      }
                    />
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Form>
          );
        }}
      </Formik>
    </IsEditableContext.Provider>
  );
};

Payments.propTypes = {
  quotation: PropTypes.shape({
    id: PropTypes.string,
    paymentStartDate: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    propertyPrice: PropTypes.number,
    initialFeePercentage: PropTypes.number,
    reservePercentage: PropTypes.number,
    finalFee: PropTypes.number,
    finalPayment: PropTypes.number,
    periods: PropTypes.number,
    deliveryDate: PropTypes.string,
    distribution: PropTypes.arrayOf(PropTypes.number),
    discountPrice: PropTypes.number,
    discountPercentage: PropTypes.number,
    discount: PropTypes.number,
    priceWithDiscount: PropTypes.number,
    finalPaymentType: PropTypes.number,
    priceWithAdditionalAreas: PropTypes.number,
    discountType: PropTypes.any,
    initialDate: PropTypes.any,
    paymentTypes: PropTypes.array,
    realPayments: PropTypes.array,
    property: PropTypes.shape({
      tower: PropTypes.shape({
        schedule: PropTypes.shape({
          averageDeliveryDate: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  enableDiscount: PropTypes.bool,
  onClickDiscount: PropTypes.func,
  dispatchFetchQuotationSuccess: PropTypes.func,
};

export default Payments;
