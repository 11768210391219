/* eslint-disable no-restricted-properties */
import FinancialAlgorithms from './FinancialAlgorithms';
import Numbers from './numbers';

class FinancialNeighborhoodAlgorithms {
  initialFee = (separationValue, monthlyRate, termLimit, initialCIPrice) => {
    const monthlyPayment = FinancialAlgorithms.PMT(
      monthlyRate,
      termLimit,
      -initialCIPrice,
    );
    const initialFeeValue = separationValue + monthlyPayment * termLimit;

    return [monthlyPayment, Numbers.cleanNumber(initialFeeValue)];
  };

  additionalFee = (totalBonus, totalLayoffs, additionalTotal) => {
    return Numbers.cleanNumber(totalBonus + totalLayoffs + additionalTotal);
  };

  static bankMonthlyPaymentCredit = (bankMonthlyRate, months, totalCredit) => {
    const monthlyPayment = FinancialAlgorithms.PMT(
      bankMonthlyRate,
      months,
      totalCredit,
    );
    return monthlyPayment;
  };

  static bankTotalPaymentCredit = (finalPrice, monthlyRate, termLimit) => {
    const totalBankCredit =
      finalPrice * Math.pow(1 + monthlyRate, termLimit + 1);
    return Numbers.cleanNumber(totalBankCredit);
  };

  static totalIncrementRateToListIncrementRate = (
    totalIncrementRate,
    numberOfLists,
  ) => {
    return Math.pow(1 + totalIncrementRate, 1 / numberOfLists) - 1;
  };

  bankMonthsLimitAndAnualEffectiveRate = (yearsLimit, annualEffectiveRate) => {
    const monthsForYear = 12;
    const monthsLimit = yearsLimit * monthsForYear;
    const annualEffective = this.totalIncrementRateToListIncrementRate(
      annualEffectiveRate,
      monthsForYear,
    );

    return [monthsLimit, annualEffective];
  };
}

export default FinancialNeighborhoodAlgorithms;
