import React from 'react';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import useSalesReport from '../Core/useSalesReport';
import ReportTable from './Views/ReportTable';

export default function SalesReportOneMainView() {
  const { isLoading } = useSalesReport();
  return (
    <>
      {isLoading && <AbsoluteCircularProgress topPercentage={0} />}
      <ReportTable />
    </>
  );
}
