import ServiceDefinitions, { Route } from '../ServicesDefinitions';

class SharedServiceDefinitions {
  static getBase() {
    return new ServiceDefinitions('shared/');
  }

  static resetCache(towerId) {
    return this.getBase().createRoute(new Route('resetCache/', towerId));
  }
}

export default SharedServiceDefinitions;
