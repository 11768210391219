import ContractPaymentsInvoiceServicesDefinition from './ContractPaymentsInvoiceServicesDefinition';
import Services from '../services';
import { ContractPaymentInvoice, ContractInterest } from '../../models/ContractPayments';

export default class ContractPaymentsInvoiceService extends Services {
  createContractInterest(contractId: number, data: ContractInterest) {
    return this.post<ContractInterest>(
      ContractPaymentsInvoiceServicesDefinition.createContractInterest(contractId),
      data,
    );
  }
  createContractPaymentInvoice(contractId: number, data) {
    return this.post<ContractPaymentInvoice>(
      ContractPaymentsInvoiceServicesDefinition.createContractPaymentInvoice(contractId),
      data,
    );
  }
  restartProjected(contractId: number) {
    return this.post<ContractPaymentInvoice>(ContractPaymentsInvoiceServicesDefinition.restartProjected(contractId));
  }
  autofitProjected(contractId: number) {
    return this.post<ContractPaymentInvoice>(ContractPaymentsInvoiceServicesDefinition.autofitProjected(contractId));
  }
  interestRestartProjected(contractId: number) {
    return this.post<ContractPaymentInvoice>(
      ContractPaymentsInvoiceServicesDefinition.interestRestartProjected(contractId),
    );
  }
}
