import moment from 'moment';
import { NumberFormatValues } from 'react-number-format';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ScheduleServices from 'services/schedule/ScheduleServices';
import useBoolean from 'shared/customHooks/useBoolean';
import { updateQuotationConfigQueryData } from '../QuotationConfigQueryClient';

interface UseQuotationConfigFinalFeeLimitDateProps {
  averageDeliveryDate: string;
  maximumCollectionDate: string;
}

const service = new ScheduleServices();

const useQuotationConfigFinalFeeLimitDate = (props: UseQuotationConfigFinalFeeLimitDateProps) => {
  const { maximumCollectionDate, averageDeliveryDate } = props;
  const { towerId } = useParams<{ towerId: string }>();
  const { value: isValidValue, setValue: validateValue } = useBoolean(true);

  const { mutate: updateAverageDeliveryDate, isLoading } = useMutation(
    (value: number) =>
      service.putAverageDeliveryDate(towerId, {
        averageDeliveryDate: value,
      }),
    {
      onMutate: (value: number) => updateQuotationConfigQueryData({ averageDeliveryDate: `${value}` }),
    },
  );

  const momentMaximumCollectionDate = moment(Number(maximumCollectionDate)).startOf('month');
  const momentAverageDeliveryDate = moment(Number(averageDeliveryDate)).startOf('month');
  const diffValue = momentAverageDeliveryDate.diff(momentMaximumCollectionDate, 'month');

  const handleChange = (value: number) => {
    if (typeof value === 'number' && value >= -100 && value <= 100) {
      const maximumCollectionDateMoment = moment(Number(maximumCollectionDate));
      const newAverageDeliveryDate = maximumCollectionDateMoment.clone().add(value, 'M');
      const newAverageDeliveryTime = newAverageDeliveryDate.valueOf();
      const isValidDate = newAverageDeliveryDate.isSameOrAfter(maximumCollectionDateMoment);
      const isNewValue = Number(newAverageDeliveryTime) !== Number(averageDeliveryDate);
      const isValid = isNewValue && isValidDate;
      if (isValid) updateAverageDeliveryDate(newAverageDeliveryTime);
      validateValue(isValid);
    }
  };

  const isAllowed = ({ floatValue }: NumberFormatValues) => {
    if (Number(floatValue) >= -100 && Number(floatValue) <= 100) return true;
    if (typeof floatValue === 'undefined') return true;
    return false;
  };

  return {
    isLoading,
    diffValue,
    isValidValue,
    isAllowed,
    handleChange,
  };
};

export default useQuotationConfigFinalFeeLimitDate;
