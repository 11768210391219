import React, { FC } from 'react';
import Typography from 'shared/components/Typography/Typography';
import Styles from './MonthlyInterestCommitmentDetail.module.scss';
import Numbers from '../../../../../helpers/numbers';
import WalletMonthlyReportDTO from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';

const MonthlyInterestCommitmentDetail: FC<Partial<WalletMonthlyReportDTO>> = ({
  minPayment,
  commitment,
  accumulatedPayments,
  financialAccumulated,
}) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.totalValueContainer}>
        <Typography variant="body1" color={(minPayment ?? 0) > 0 ? 'stateError600' : 'success600'}>
          {Numbers.currencyFormat(minPayment)}
        </Typography>
        <Typography variant="body2" color="gray700" className={Styles.TypographyTitle}>
          Pago mínimo hoy
        </Typography>
      </div>
      <div className={Styles.TypographyContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.TypographyTitle}>
          Compormisos
        </Typography>
        <Typography variant="body1" color="gray700">
          {Numbers.currencyFormat(commitment)}
        </Typography>
      </div>
      <div className={Styles.TypographyContainer}>
        <Typography variant="subtitle1" color="gray800" className={Styles.TypographyTitle}>
          Pagado
        </Typography>
        <Typography variant="body1" color="gray700">
          {Numbers.currencyFormat(accumulatedPayments)}
        </Typography>
      </div>
      <div className={Styles.TypographyContainer}>
        <Typography variant="subtitle1" color="suc" className={Styles.TypographyTitle}>
          Intereses
        </Typography>
        <Typography variant="body1" color={(financialAccumulated ?? 0) >= 0 ? 'success600' : 'stateError600'}>
          {Numbers.currencyFormat(financialAccumulated ?? 0)}
        </Typography>
      </div>
    </div>
  );
};

export default MonthlyInterestCommitmentDetail;
