import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ResultsStateServices from '../../Services/ResultsStateServices';
import { updateSaveBudgetState, updateBudgetRows } from '../../../TotalCashFlow/Core/actions';

interface RouteParams {
  towerId: string;
}

function useRenameEventForResultsState() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { resultsStateRows } = useSelector((state) => state.cashFlow.root || {});

  const resultsStateServices = new ResultsStateServices();
  const { towerId } = useParams<RouteParams>();

  const [openRename, setOpenRename] = useState(false);
  const [newEventName, setNewEventName] = useState('');

  const resultsStateRowsMapper = resultsStateRows.map((row) => {
    return {
      previousBudget: row.previousControl,
      budgetId: row.budgetId,
      budgetPercentage: row.controlPercentage,
      budgetName: newEventName,
      budgetIndex: row.budgetIndex,
    };
  });

  const handleCloseRename = () => {
    setOpenRename(false);
  };

  const onSubmitHandler = async (newEventName: string, selectedBudget) => {
    setOpenRename(false);
    const updateBudgetNameControl = await resultsStateServices.renameEvent({ towerId, newEventName, selectedBudget });
    const { data } = updateBudgetNameControl;
    dispatch(updateSaveBudgetState(data))
    data && 
      enqueueSnackbar(`el evento ha sido renombrado exitosamente`, {
        variant: 'success',
      });
    data && dispatch(updateBudgetRows(resultsStateRowsMapper))
    data === false && enqueueSnackbar(`Error al renombrar del evento`, { variant: 'error' });
  };

  const handleChange = (e) => {
    setNewEventName(e.target.value);
  };

  return {
    openRename,
    handleCloseRename,
    setOpenRename,
    onSubmitHandler,
    handleChange,
    newEventName
  };
}

export default useRenameEventForResultsState;
