import React from 'react';
import useForgotPasswordBehavior from '../Core/customHooks/useForgotPasswordBehavior';
import styles from './styles.module.scss';
import {
  Typography,
  Card,
  TextField,
  Box,
  Button,
  Link,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import Alert from './Alert';
import { Link as RouterLink } from 'react-router-dom';
import { UserRoutes } from '../../../routes/local/routes';

const ForgotPassword = () => {
  const {
    email,
    setEmail,
    invalidEmailMessage,
    errorMessage,
    success,
    loading,
    dismissError,
    dismissSuccess,
    submit
  } = useForgotPasswordBehavior();

  return (
    <main className={styles.container}>
      <Card className={styles.card}>
        <div className={styles.title}>
          <Typography variant="h5">Recuperar Contraseña</Typography>
        </div>
        <Box>
          <TextField
            label="Correo"
            name="email"
            className={styles.input}
            value={email}
            onChange={event => setEmail(event.target.value)}
            variant="outlined"
            color="primary"
            error={!!invalidEmailMessage}
            helperText={invalidEmailMessage}
          />
        </Box>
        <Button className={styles.submit} onClick={submit}>Enviar</Button>
        <RouterLink to={UserRoutes.login} className={styles.link}>
          <Link color='textSecondary'>Regresar</Link>
        </RouterLink>
      </Card>
      <Snackbar open={loading}>
        <CircularProgress />
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={dismissError}>
        <Alert onClose={dismissError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={6000} onClose={dismissSuccess}>
        <Alert onClose={dismissSuccess} severity="success">
          Se ha enviado un correo a {email} con instrucciones para recuperar la contraseña.
        </Alert>
      </Snackbar>
    </main>
  );
};

export default ForgotPassword;