import useBoolean from '../../../../shared/customHooks/useBoolean';
import { ClientDetail } from 'App/GeneralWallet/core/interfaces/generalWallet.types';
import { useState } from 'react';

function useMonthlyInterestReportOpener() {
  const { value: isOpenMonthlyInterest, toggle } = useBoolean();
  const [currentPropertyId, setCurrentProperty] = useState<number | null>(null);

  const handleActionMonthlyInterestReportClicked = (client: ClientDetail) => {
    toggle();
    setCurrentProperty(client.propertyId);
  };

  return {
    isOpenMonthlyInterest,
    currentPropertyId,
    handleMonthlyInterest: toggle,
    handleActionMonthlyInterestReportClicked,
  };
}

export default useMonthlyInterestReportOpener;
