import React, { FC } from 'react';
import { ListItemText, Divider, List, ListItem } from '@material-ui/core';

import Styles from '../../styles/ContractListToolbar.module.scss';
import Typography from '../../../../../shared/components/Typography/Typography';

const ContractMenuList: FC<{
  handleClose: () => void;
  handleNewContract: () => void;
}> = ({ handleClose, handleNewContract }) => {
  return (
    <List
      classes={{
        root: Styles.contractMenu,
      }}
    >
      <ListItem button={false} key="new-contract-menu-title" onClick={() => {}}>
        <ListItemText
          primary={
            <Typography variant="h6" color="gray800" fontWeight="600">
              Aún no tienes contratos creados, para empezar selecciona el tipo de contrato que deseas crear.
            </Typography>
          }
        />
      </ListItem>
      <Divider />
      <ListItem
        button
        classes={{
          root: Styles.menuItem,
          selected: Styles.menuItemSelected,
        }}
        key="new-manual-contract-menu-item"
        onClick={() => {
          handleClose();
          handleNewContract();
        }}
        divider
      >
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="button" fontWeight="600">
                Manual
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="caption">
                Distribuye el monto total del contrato manualmente en cada fecha.
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <ListItem
        key="new-recurrent-contract-menu-item"
        classes={{
          root: Styles.menuItem,
          selected: Styles.menuItemSelected,
        }}
        disabled={true}
        divider
      >
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="button" fontWeight="600">
                Recurrente
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="caption">
                Ingresa el monto por cuota y la cantidad de transacciones requeridas para el contrato.
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
};

export default ContractMenuList;
