export const SET_CONSTRUCTION_TYPE = 'SET_CONSTRUCTION_TYPE';
export const SET_BUILDER = 'SET_BUILDER';
export const SET_DATE_COST = 'SET_DATE_COST';
export const SET_IPC = 'SET_IPC';
export const SET_DATES_BUILD = 'SET_DATES_BUILD';
export const SET_MANUAL_DATES = 'SET_MANUAL_DATES';
export const SET_MANUAL_DATES_VALUES = 'SET_MANUAL_DATES_VALUES';
export const SET_ROW_DATA = 'SET_ROW_DATA';
export const RESET = 'RESET';

export const setContructionType = (payload) => ({
  payload,
  type: SET_CONSTRUCTION_TYPE,
});

export const setBuilderRows = (payload) => ({
  payload,
  type: SET_BUILDER,
});

export const setDateCost = (payload) => ({
  payload,
  type: SET_DATE_COST,
});

export const setIpc = (payload) => ({
  payload,
  type: SET_IPC,
});

export const setDatesBuild = (payload) => ({
  payload,
  type: SET_DATES_BUILD,
});

export const setManualDates = (payload) => ({
  payload,
  type: SET_MANUAL_DATES,
});

export const setManualDatesValues = (payload) => ({
  payload,
  type: SET_MANUAL_DATES_VALUES,
});

export const setRowData = (payload) => ({
  payload,
  type: SET_ROW_DATA,
});

export const setReset = () => ({
  type: RESET,
  payload: 'reset',
});
