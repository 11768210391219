import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import TotalCashFlowServices from '../../Services/TotalCashFlowServices';
import {
  BUILDER_CREDIT_PAYMENTS,
  BUILDER_CREDIT_DISBURSEMENTS,
} from '../../UI/views/cells/HighlightedCell';
import BuilderCreditServices from '../../../../services/BuilderCredit/BuilderCreditServices';
import { FORMAT_YEAR_MONTH2 } from '../../../../helpers/dates';
import Numbers from '../../../../helpers/numbers';
import { CashFlowRowChangeValueType } from '../models/CashFlowRowChangeValue';

function useTotalCashFlowToFetchRows(setGroups, setSalesCashFlow) {
  const salesCashflow = new TotalCashFlowServices();
  const { enqueueSnackbar } = useSnackbar();

  const { towerId } = useParams();
  const [salesFlow, setSalesFlow] = useState(null);
  const [isLoading, setLoad] = useState(false);

  const getSalesCashflow = async () => {
    try {
      setLoad(true);
      const salesCashFlow = await salesCashflow.getTotalCashFlow(towerId);
      setSalesFlow(salesCashFlow.data);
      setSalesCashFlow(salesCashFlow.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!salesFlow && !isLoading) {
      if (!salesFlow) getSalesCashflow();
      setLoad(true);
    }
  });

  useEffect(() => {
    if (salesFlow) {
      setLoad(false);
    }
  }, [salesFlow]);

  const downloadTemplate = async () => {
    try {
      const name = 'Excel flujo total.xlsx';
      const response = await salesCashflow.downloadCashflowServices(towerId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const updateSalesFlowByKey = (key, data) => {
    const newSalesFlow = {
      ...salesFlow,
      [key]: data,
    };
    setSalesFlow(newSalesFlow);
    setSalesCashFlow(newSalesFlow);
  };

  const update = async (row, key, value) => {
    try {
      setLoad(true);
      const { data } = await salesCashflow.updateTotalCashFlow(
        towerId,
        row,
        key,
        value,
      );
      if (data && data.updated) await getSalesCashflow();
      setLoad(false);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const updateMonthForBuilderPayment = async (column, row, arePayments) => {
    try {
      const builderServices = new BuilderCreditServices();

      const defaultDateForPayments = moment(column, FORMAT_YEAR_MONTH2)
        .endOf('month')
        .valueOf();
      let timestampDate = defaultDateForPayments;
      let { value } = row;
      if (row.type === CashFlowRowChangeValueType.timestamp.code) {
        timestampDate = row.value;
        // eslint-disable-next-line prefer-destructuring
        value = row.additional.value;
      }

      setLoad(true);
      const payments = await builderServices.updateFlow(
        towerId,
        timestampDate,
        Numbers.cleanNumber(value),
        arePayments,
        salesFlow.dates,
      );

      updateSalesFlowByKey('financing', payments.data);
      setLoad(false);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      setLoad(false);
    }
  };

  const reloadFinancialRows = async () => {
    await getSalesCashflow();
  };

  /**
   *
   * @param String rowId
   * @param String column
   * @param CashFlowRowChangeValue row
   */
  const updateTotalCashFlow = async (rowId, column, row) => {
    switch (rowId) {
      case BUILDER_CREDIT_PAYMENTS:
        setLoad(true);
        await updateMonthForBuilderPayment(column, row, true);
        await reloadFinancialRows();
        break;
      case BUILDER_CREDIT_DISBURSEMENTS:
        setLoad(true);
        await updateMonthForBuilderPayment(column, row, false);
        await reloadFinancialRows();
        setLoad(false);
        break;
      default:
        update(rowId, column, row.value);
    }
  };

  return {
    isLoading,
    downloadTemplate,
    updateTotalCashFlow,
    reloadFinancialRows,
  };
}

export default useTotalCashFlowToFetchRows;
