import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import { Input } from '@material-ui/core';

const ContractsEvalproGridPercentEditFormatter: React.FC<DataTypeProvider.ValueEditorProps> = ({
  onValueChange,
  value,
  // @ts-ignore
  autoFocus,
  // @ts-ignore
  onBlur,
  // @ts-ignore
  onKeyDown,
}) => {
  const isValidPercentage = (inputObj) => {
    const { value } = inputObj;
    if (value >= 0 && value <= 100) return true;
    return false;
  };

  return (
    <div>
      <NumberFormat
        customInput={Input}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={value}
        thousandSeparator
        fixedDecimalScale
        isAllowed={isValidPercentage}
        onValueChange={(values) => {
          onValueChange(values.floatValue);
        }}
        decimalScale={2}
        // @ts-ignore
        size="small"
        disableUnderline
      />
    </div>
  );
};

export default ContractsEvalproGridPercentEditFormatter;
