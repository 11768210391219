import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './AsyncConfirmModal.module.scss';
import DialogButton from 'components/Buttons/DialogButtons/DialogButtons';

interface IAsyncConfirmModal extends DialogProps {
  title?: string;
  acceptText?: string;
}

type AsyncConfirmModalFunc = <T>(param?: T) => void;

interface IAsyncConfirmModalActions {
  ok?: AsyncConfirmModalFunc;
  cancel?: AsyncConfirmModalFunc;
}

interface IAsyncConfirmModalRefActions {
  show: (successCallback: AsyncConfirmModalFunc, cancelCallback?: AsyncConfirmModalFunc) => Promise<unknown>;
}

const AsyncConfirmModal = forwardRef<IAsyncConfirmModalRefActions, IAsyncConfirmModal>(
  ({ title, acceptText, children, ...props }, ref) => {
    const [open, setOpen] = useState(false);
    const [promise, setPromise] = useState<IAsyncConfirmModalActions>({});

    const handleOk = () => {
      setOpen(false);
      if (promise.ok) promise.ok();
    };

    const handleCancel = () => {
      setOpen(false);
      if (promise.cancel) promise.cancel();
    };

    useImperativeHandle(ref, () => ({
      show: (okFunc, cancelFunc) =>
        new Promise(() => {
          setPromise({
            ok: okFunc,
            cancel: cancelFunc,
          });
          setOpen(true);
        }),
    }));

    return (
      <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="md" className={styles.dialog} {...props} open={open}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <DialogButton onClick={handleCancel} color="secondary">
            Cancelar
          </DialogButton>
          <DialogButton onClick={handleOk}>{acceptText || 'Aceptar'}</DialogButton>
        </DialogActions>
      </Dialog>
    );
  },
);

export default AsyncConfirmModal;
