import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Style from './CutsTableHeader.module.scss';
import Icon from '../../../../../shared/components/Icon/Icon';
import IconButton from '../../../../../shared/components/IconButton/IconButton';

const CutsTableHeader = () => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible && (
        <Box className={Style.cutAlert}>
          <Icon
            className={Style.exclamationPointIcon}
            variant={'exclamationPoint'}
            height={'20px'}
            width={'20px'}
            color={'#2D52D6'}
          />
          <Typography className={Style.cutAlertTypography}>
            Debes copiar el corte actual para ver la variación
          </Typography>
          <IconButton
            className={Style.closeIcon}
            iconName="Clear"
            variant="ghost"
            size="small"
            aria-controls="close-lot-form"
            onClick={() => setVisible(false)}
          />
        </Box>
      )}
    </>
  );
};

export default CutsTableHeader;
