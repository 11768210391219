import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Numbers } from '../../../helpers';

export default class CustomizedChartLabel extends PureComponent {
  render() {
    const { x, y, stroke, value, data, index, totalUnits } = this.props;
    const { isBalancedPoint, isCurrentMonth, isNextMonth } = data[index] || {};
    return isBalancedPoint || isCurrentMonth || isNextMonth ? (
      <text
        x={x + 4}
        y={y - 3}
        dy={1}
        fill={stroke}
        fontSize={15}
        textAnchor="middle"
      >
        {Numbers.toFixed((Math.floor(value) * 100) / totalUnits, 0, true)}
      </text>
    ) : (
      <></>
    );
  }
}

CustomizedChartLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  totalUnits: PropTypes.number,
  index: PropTypes.number,
  stroke: PropTypes.any,
  value: PropTypes.any,
  data: PropTypes.array,
};
