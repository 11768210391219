import { useState } from 'react';
import { AreaDashboardState } from '../types/AreaDashboardState';
import { AreasPropertiesObject } from '../types/AreasPropertiesObject';
import { AreaDashboardContainerProps } from './AreaDashboardContainerProps';

const useAreaDashboardContainer = (props: AreaDashboardContainerProps) => {
  const [properties, setProperties] = useState(props.areasResponse.properties);
  const propertiesArray = Object.values(props.areasResponse.properties);
  const [dashboardState, setDashboardState] = useState<AreaDashboardState>(AreaDashboardState.BOARD);

  const resetPropertiesState = () => {
    setProperties(props.areasResponse.properties);
  };

  const startEditionState = async () => {
    setDashboardState(AreaDashboardState.EDITION);
  };

  const stopEditionState = async () => {
    resetPropertiesState();
    setDashboardState(AreaDashboardState.BOARD);
  };

  const changePropertiesState = (newProperties: AreasPropertiesObject) => {
    setProperties(newProperties);
  };

  return {
    properties,
    dashboardState,
    propertiesArray,
    startEditionState,
    stopEditionState,
    changePropertiesState,
  };
};

export default useAreaDashboardContainer;
