import { MonthlyPaymentRow } from '../DTO/WalletMonthlyReportDTO';
import { useEffect, useState } from 'react';

function useMonthlyInterestCellUpdater(monthlyPayment: MonthlyPaymentRow) {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheck = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (monthlyPayment.financialAppliedValue.isChecked !== isChecked) {
      setIsChecked(monthlyPayment.financialAppliedValue.isChecked);
    }
  }, [monthlyPayment]);

  return { toggleCheck, isChecked, customValue: isChecked ? monthlyPayment.financialAppliedValue.value : 0 };
}

export default useMonthlyInterestCellUpdater;
