import React, { FC, useEffect } from 'react';

import { Dialog, FormControl, DialogContent, DialogActions, Box, DialogTitle, Grid } from '@material-ui/core';

import Styles from './ContractPaymentFormMainView.module.scss';

import useContractPaymentForm from '../Core/customHooks/useContractPaymentForm';
import { ContractPayment } from '../../../models/ContractPayments';

import Typography from '../../../shared/components/Typography/Typography';
import { Button } from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';
import TextField from '../../../shared/components/TextField/TextField';

import PaymentCycleField from './Views/customViews/PaymentCycleField';
import InstallmentsField from './Views/customViews/InstallmentsField';
import PaymentValueFields from './Views/customViews/PaymentValueFields';
import PaymentDateType from 'App/ContractPaymentScheduleFormModal/UI/Views/customViews/PaymentDateType';

interface IContractPaymentFormMainView {
  open: boolean;
  currentContractId: number;
  currentContractPayment: ContractPayment | undefined;
  contractPaymentType: string;
  handleClose: () => void;
  handleCreate: (contractPayment: ContractPayment) => void;
}

const ContractPaymentFormMainView: FC<IContractPaymentFormMainView> = ({
  open,
  currentContractPayment,
  currentContractId,
  contractPaymentType,
  handleClose,
  handleCreate,
}) => {
  const {
    paymentCyclesList,
    measurementUnits,
    paymentProjectionsList,
    data,
    errors,
    handleSubmit,
    handleChange,
    handleChangeData,
    handleChangeValue,
    handleChangePaymentValues,
    firstContractPaymentSchedule,
    subtotal,
    total,
    contractPaymentTypeName,
    resetData,
  } = useContractPaymentForm({
    currentContractPayment,
    handleCreate,
    handleClose,
    currentContractId,
    contractPaymentType,
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography color="gray800" fontWeight="700">
            {contractPaymentTypeName}
          </Typography>
          <IconButton
            className={Styles.CloseIcon}
            iconName="Clear"
            variant="ghost"
            size="small"
            aria-controls="close-lot-form"
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent className={Styles.DialogContent}>
          <form noValidate onSubmit={handleSubmit} id="ContractPaymentForm">
            <input name="type" value={data?.type || ''} id="contractPaymentType" type="hidden" />
            <input name="projectionType" value={data?.projectionType || 'M'} id="projectionType" type="hidden" />
            <Box className={Styles.BoxTitle} py={2} px={6}>
              <Typography fontWeight="600">Detalles</Typography>
            </Box>
            <Box my={2} px={6}>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  name="name"
                  label="Nombre del pago"
                  variant="outlined"
                  minRows={2}
                  multiline
                  value={data?.name || ''}
                  size="small"
                  error={!!errors?.name}
                  helperText={errors?.name}
                  onChange={handleChange('name')}
                />
              </FormControl>
            </Box>
            <Box className={Styles.BoxTitle} py={2} px={6}>
              <Typography fontWeight="600">Valor</Typography>
            </Box>

            <Box pb={1}>
              <PaymentValueFields
                contractPaymentType={contractPaymentType}
                paymentProjectionsList={paymentProjectionsList}
                measurementUnits={measurementUnits}
                handleChangePaymentValues={handleChangePaymentValues}
                handleChangeValue={handleChangeValue}
                currentContractPayment={currentContractPayment}
                total={total}
                subtotal={subtotal}
                data={data}
                errors={errors}
              />
            </Box>

            {contractPaymentType !== 'PX' && contractPaymentType !== 'PR' && (
              <>
                <Box className={Styles.BoxTitle} py={2} px={6}>
                  <Typography fontWeight="600">Cuotas</Typography>
                </Box>
                <Box mx={2} my={2} px={5}>
                  <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                    <Grid item xs={5}>
                      <InstallmentsField
                        data={data}
                        errors={errors}
                        handleChangeValue={handleChangeValue}
                        handleChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <PaymentCycleField
                        handleChangeValue={handleChangeValue}
                        paymentCyclesList={paymentCyclesList}
                        currentContractPayment={currentContractPayment}
                        data={data}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

            {contractPaymentType !== 'PR' && (
              <>
                <Box className={Styles.BoxTitle} py={2} px={6}>
                  <Typography fontWeight="600">Fecha primer pago</Typography>
                </Box>
                <Box mx={2} my={2} px={5}>
                  <PaymentDateType
                    data={data}
                    errors={errors}
                    currentContractPaymentSchedule={firstContractPaymentSchedule}
                    handleChangeData={handleChangeData}
                  />
                </Box>
              </>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                resetData();
                handleClose();
              }}
            >
              Cancelar
            </Button>
          </Box>
          <Box m={2}>
            <Button size="small" variant="contained" type="submit" form="ContractPaymentForm">
              Guardar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContractPaymentFormMainView;
