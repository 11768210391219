import { Switch as MuiSwitch, SwitchProps } from '@material-ui/core';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import colors from '../../../assets/styles/settings/colors.module.scss';

type SwitchSizes = 'small' | 'medium' | 'large';

type Props = Omit<SwitchProps, 'size'> & {
  size?: SwitchSizes;
};

type SizeMixins = {
  large: FlattenSimpleInterpolation;
  medium: FlattenSimpleInterpolation;
  small: FlattenSimpleInterpolation;
};

const rootMixinLarge = css`
  /* Box-model */
  padding: 0.5625rem 0.4375rem;
  width: 3.375rem;
  height: 2.375rem;
`;

const rootMixinMedium = css`
  /* Box-model */
  padding: 0.5rem 0.375rem;
  width: 2.875rem;
  height: 2rem;
`;

const rootMixinSmall = css`
  /* Box-model */
  padding: 0.375rem 0.25rem;
  width: 2.125rem;
  height: 1.5rem;
`;

const rootMixins: SizeMixins = {
  large: rootMixinLarge,
  medium: rootMixinMedium,
  small: rootMixinSmall,
};

const baseMixinLarge = css`
  /* Box-model */
  padding: 0.4375rem;
`;

const baseMixinMedium = css`
  /* Box-model */
  padding: 0.375rem;
`;

const baseMixinSmall = css`
  /* Box-model */
  padding: 0.25rem;
`;

const baseMixins: SizeMixins = {
  large: baseMixinLarge,
  medium: baseMixinMedium,
  small: baseMixinSmall,
};

const baseCheckedMixinLarge = css`
  /* Visual */
  transform: translateX(1rem);
`;

const baseCheckedMixinMedium = css`
  /* Visual */
  transform: translateX(0.875rem);
`;

const baseCheckedMixinSmall = css`
  /* Visual */
  transform: translateX(0.625rem);
`;

const baseCheckedMixins: SizeMixins = {
  large: baseCheckedMixinLarge,
  medium: baseCheckedMixinMedium,
  small: baseCheckedMixinSmall,
};

const thumbMixinLarge = css`
  /* Box-model */
  width: 1.5rem;
  height: 1.5rem;
`;

const thumbMixinMedium = css`
  /* Box-model */
  width: 1.25rem;
  height: 1.25rem;
`;

const thumbMixinSmall = css`
  /* Box-model */
  width: 1rem;
  height: 1rem;
`;

const thumbMixins: SizeMixins = {
  large: thumbMixinLarge,
  medium: thumbMixinMedium,
  small: thumbMixinSmall,
};

const trackMixinLarge = css`
  /* Visual */
  border-radius: 0.625rem;
`;

const trackMixinMedium = css`
  /* Visual */
  border-radius: 0.5625rem;
`;

const trackMixinSmall = css`
  /* Visual */
  border-radius: 0.5625rem;
`;

const trackMixins: SizeMixins = {
  large: trackMixinLarge,
  medium: trackMixinMedium,
  small: trackMixinSmall,
};

const getSizeMixin =
  (mixin: SizeMixins) =>
  ({ size = 'large' }: Props): FlattenSimpleInterpolation =>
    mixin[size];

const Switch = styled(MuiSwitch)<Props>`
  /* Box-model */
  ${getSizeMixin(rootMixins)}

  .MuiButtonBase-root {
    ${getSizeMixin(baseMixins)}
  }

  .MuiIconButton-root:hover {
    /* Visual */
    background-color: ${colors.primary600}33;
  }

  .MuiSwitch-switchBase.Mui-checked {
    ${getSizeMixin(baseCheckedMixins)}
  }

  .MuiSwitch-thumb {
    ${getSizeMixin(thumbMixins)}

    /* Visual */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
  }

  .MuiSwitch-track {
    ${getSizeMixin(trackMixins)}

    /* Visual */
    opacity: 1;
  }

  .MuiSwitch-colorPrimary,
  .MuiSwitch-colorPrimary.Mui-checked {
    /* Visual */
    color: ${colors.gray100};
  }

  .MuiSwitch-colorPrimary.Mui-disabled,
  .MuiSwitch-colorPrimary.Mui-disabled.Mui-checked {
    /* Visual */
    color: ${colors.gray400};
  }

  .MuiSwitch-colorPrimary + .MuiSwitch-track {
    /* Visual */
    background-color: ${colors.gray600};
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    /* Visual */
    background-color: ${colors.primary600};
    opacity: 1;
  }

  .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track,
  .MuiSwitch-colorPrimary.Mui-disabled.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.gray200};
  }
`;

export default Switch;
