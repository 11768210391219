import React, { FC } from 'react';
import { Box, CircularProgress, Divider, Drawer, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Table, TableHeaderRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';

import { SummaryState, IntegratedSummary, DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import { CurrencyTypeProvider, TitleTypeProvider } from '../../../../../shared/tables/TableColumnFormat';
import VirtualTableContainer from '../../../../../shared/tables/VirtualTableContainer';
import TableHeaderRowCell from '../../../../../shared/tables/TableHeaderRowCell';
import { MuiIconButton } from '../../../../../shared/components/Button/Button';
import Typography from '../../../../../shared/components/Typography/Typography';

import useColumnsAndRowsForContractPaymentsSchedule from '../../../Core/customHooks/useColumnsAndRowsForContractPaymentsSchedule';

import Styles from '../../styles/ContractPaymentScheduleModal.module.scss';
import StateChip from '../../../../../shared/components/StateChip/StateChip';
import { IStateChip } from '../../../../../shared/components/StateChip/StateChip';
import TableCell from '../../../../../shared/tables/TableCell';
import TableSummaryRowCell from '../../../../../shared/tables/TableSummaryRowCell';
import ContractPaymentScheduleSummary from './ContractPaymentScheduleSummary';

interface IContractPaymentScheduleModal {
  contractId: number;
  isLoading: boolean;
  open: boolean;
  handleClose: () => void;
}

const PaymentStateFormatter = ({ value }: { value: IStateChip }) =>
  !!value ? <StateChip label={value.label} color={value.color} /> : null;

const PaymentStateProvider: FC<DataTypeProviderProps> = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PaymentStateFormatter} {...props} />
);

const ContractPaymentScheduleModal: FC<IContractPaymentScheduleModal> = ({
  contractId,
  isLoading,
  open,
  handleClose,
}) => {
  const {
    rows,
    columns,
    currencyColumns,
    chipColumns,
    dateColumns,
    totalSummarySchedules,
    tableColumnExtensions,
    summaryCalculator,
  } = useColumnsAndRowsForContractPaymentsSchedule(contractId);

  return (
    <Drawer
      anchor={'left'}
      open={open}
      onClose={handleClose}
      className={Styles.drawer}
      classes={{
        paper: Styles.drawerPaper,
      }}
    >
      <Toolbar>
        <Typography className={Styles.title} variant="h4" fontSize="1.5rem" fontWeight="600">
          Resumen de Presupuesto
        </Typography>
        <MuiIconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </MuiIconButton>
      </Toolbar>
      <Divider />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box mt={2} p={2}>
          {!!rows && (
            <Grid rows={rows} columns={columns}>
              <TitleTypeProvider for={dateColumns} />
              <CurrencyTypeProvider for={currencyColumns} />
              <PaymentStateProvider for={chipColumns} />
              <SummaryState totalItems={totalSummarySchedules} />
              <IntegratedSummary calculator={summaryCalculator} />
              <Table
                columnExtensions={tableColumnExtensions}
                containerComponent={VirtualTableContainer}
                cellComponent={TableCell}
              />
              <TableHeaderRow cellComponent={TableHeaderRowCell} />
              <TableSummaryRow
                totalCellComponent={TableSummaryRowCell}
                itemComponent={ContractPaymentScheduleSummary}
              />
            </Grid>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default ContractPaymentScheduleModal;
