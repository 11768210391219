import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Styles from '../../styles/BuyerCustomerScheduleStateDialog.module.scss';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScheduleState({openDialog, close}) {

  const [linkStatus, setLinkStatus] = useState('');

  const handleChange = (event) => {
    setLinkStatus(event.target.value);
  };
  
  return (
    <div className={Styles.DialogContainer}>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={Styles.DialogContainer}
      >
        <DialogTitle 
        id="alert-dialog-slide-title"
        className={Styles.DialogTitle}
        >
          <strong className={Styles.strong}>Estado de vinculación</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <FormControl className={Styles.formControl}>
            <Select
              value={linkStatus}
              onChange={handleChange}
              displayEmpty
              className={Styles.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
              Seleccionar estado
              </MenuItem>
              <MenuItem 
              value={1}
              >
                Documentos de fiducia
              </MenuItem>
              <MenuItem 
              value={2}
              >
                En firma del cliente
              </MenuItem>
              <MenuItem 
              value={3}
              >
                En firma del vendedor
              </MenuItem>
              <MenuItem 
              value={4}
              >
                En firma de la fiduciaria
              </MenuItem>
              <MenuItem 
              value={5}
              >
                Cliente vinculado
              </MenuItem>
            </Select>
          </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            close()
          }} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ScheduleState.propTypes = {
  openDialog: PropTypes.bool, 
  close: PropTypes.func,
};