import React from 'react';
import { List } from '@material-ui/core';
import useCustomerListMapper from '../../../Core/customHooks/useCustomerListMapper';
import BuyerCustomerListCell from '../rows/BuyerCustomerListCell';

const BuyerCustomerListTable = () => {
  const { propertyRows } = useCustomerListMapper();

  return (
    <List>
      {propertyRows.map((row) => (
        <BuyerCustomerListCell key={row.id} row={row} />
      ))}
    </List>
  );
};

export default BuyerCustomerListTable;
