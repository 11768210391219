import { Column, VirtualTable } from '@devexpress/dx-react-grid';

export const DEED_EXPENSES_COLUMNS: Column[] = [
  {
    name: 'name',
    title: 'Nomenclatura',
  },
  {
    name: 'floor',
    title: 'Piso',
  },
  {
    name: 'totalPrice',
    title: 'Precio cierre',
  },
  {
    name: 'firstPayment',
    title: '$ Cuota inicial',
  },
  {
    name: 'finalPayment',
    title: '$ Cuota final',
  },
  {
    name: 'finalPaymentDate',
    title: 'Cuota final',
  },
  {
    name: 'deedDisplacement',
    title: 'Desplazamiento',
  },
  {
    name: 'deedDate',
    title: 'Escrituración',
  },
];

export const DEED_EXPENSES_COLUMNS_EXTENSIONS: VirtualTable.ColumnExtension[] = [
  {
    columnName: 'name',
  },
  {
    columnName: 'floor',
    width: '100px',
  },
  {
    columnName: 'totalPrice',
  },
  {
    columnName: 'firstPayment',
  },
  {
    columnName: 'finalPayment',
  },
  {
    columnName: 'finalPaymentDate',
    width: '150px',
  },
  {
    columnName: 'deedDisplacement',
    width: '200px',
  },
  {
    columnName: 'deedDate',
    width: '150px',
  },
];
