import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import EditableList from '../../../components/EditableList/EditableList';
import useGroupTypesLoader from '../Core/customHooks/useGroupTypesLoader';

const GroupTypesMainView = ({ isOpen, close, loadAllGroups }) => {
  const {
    onSubmit,
    setName,
    updateType,
    removeType,
    currentName,
    allTypes,
  } = useGroupTypesLoader(loadAllGroups);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>Edición de tipos</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" pt={2}>
          <form onSubmit={onSubmit} style={{ padding: '1em' }}>
            <TextField
              required
              autoFocus
              fullWidth
              value={currentName}
              placeholder="Nuevo tipo"
              onChange={(e) => setName(e.target.value)}
            />
          </form>
          <Divider variant="middle" />
          <EditableList
            style={{ marginTop: '1em' }}
            onEdit={updateType}
            onDelete={removeType}
            values={allTypes}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GroupTypesMainView.propTypes = {
  isOpen: PropTypes.bool,
  loadAllGroups: PropTypes.func,
  close: PropTypes.func,
};

export default GroupTypesMainView;
