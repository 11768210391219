import { API_PATH } from 'config/config';

class SalesSpeedHelperServicesDefinitions {
  static rootUrl = `${API_PATH}sales-speed-helper`;

  static getTowerIdUrl = (towerId: string) => `${this.rootUrl}/${towerId}`;

  static getStrategiesUrl = (towerId: string) => `${this.rootUrl}/strategies/${towerId}`;
}

export default SalesSpeedHelperServicesDefinitions;
