import { ChangeEvent, useEffect, useState } from 'react';

const useGeneralWalletInput = (
  onSubmit: (value: File | string) => void,
  reset: boolean = true,
  initialValue?: string,
) => {
  const [value, setValue] = useState(initialValue || '');
  const [file, setFile] = useState<File>();
  const [input, setInput] = useState<HTMLInputElement>();
  const [isError, setIsError] = useState(false);

  const handleInputFileChange = (event) => {
    const { files } = event.target;
    const file = files && files[0];
    if (file) setFile(file);
  };

  const createInputFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.style.display = 'none';
    input.onchange = handleInputFileChange;
    setInput(input);

    return () => {};
  };

  useEffect(createInputFile, []);

  useEffect(() => {
    if (typeof reset === 'boolean' && reset) {
      setValue('');
      setFile(undefined);
      setIsError(false);
    }
  }, [reset]);

  const onClickUpload = () => {
    if (input) input.click();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const removeFile = () => {
    setFile(undefined);
  };

  const handleClick = () => {
    if (!file && !value) {
      setIsError(true);
      return;
    }
    setIsError(false);
    if (file) onSubmit(file);
    else onSubmit(value);
  };

  return {
    file,
    value,
    isError,
    handleClick,
    removeFile,
    onChange,
    onClickUpload,
  };
};

export default useGeneralWalletInput;
