import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExecutionWidget from '../Widget';
import { getExecution } from '../helpers';

const LastMonth = () => {
  const data = useSelector((state) => state.budget.chart.data);

  const realSalesDate = data.find((row) =>
    moment(row.date, 'MMM-YY').isSame(moment().add(-1, 'M'), 'month'),
  ) || {
    realAccumulated: 0,
    estimationAccumulated: 0,
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Ejecución presupuestal a
          {moment()
            .subtract(1, 'month')
            .format(' MMMM [de] YYYY')}
        </Typography>
      </Grid>
      <Grid item lg={4}>
        <ExecutionWidget title="Real acumulado">
          <Typography component="span">
            {realSalesDate.realAccumulated}
          </Typography>
        </ExecutionWidget>
      </Grid>
      <Grid item lg={4}>
        <ExecutionWidget title="Presupuesto acumulado">
          <Typography component="span">
            {realSalesDate.estimationAccumulated}
          </Typography>
        </ExecutionWidget>
      </Grid>
      <Grid item lg={4}>
        <ExecutionWidget title="Ejecución">
          <Typography component="span">
            {getExecution(
              realSalesDate.realAccumulated,
              realSalesDate.estimationAccumulated,
            )}
          </Typography>
        </ExecutionWidget>
      </Grid>
    </Grid>
  );
};

export default LastMonth;
