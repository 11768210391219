import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, ReversedRadioButton } from 'react-radio-buttons';
import {
  Typography,
  RadioGroup as RadioGroupMUI,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Input from '../../UI/Input/Input';
import Styles from './styles.module.scss';
import StyleVariables from '../../../assets/styles/variables.scss';
import SalesRoomEnum from '../../../containers/SalesRoom/SalesRoom.enum';
import AdditionalAreas from './AdditionalAreas';
import QuotationDialog from '../../Quotations/Dialog';
import FinancialDialog from '../../Financial/Dialog';
import RequestStatus from '../../../helpers/requestStatus';
import CoOwners from './CoOwners';
import Numbers from '../../../helpers/numbers';
import useCurrentAssignedQuotation from './customHooks/useCurrentAssignedQuotation';
import useGetQuotationId from '../../Client2/List/Row/QuotationViewer/Dialog/helpers/customHooks/useGetQuotationId';
import useResolveHistory from './customHooks/useResolveHIstory';
import RadioButtonItem from './RadioButtonItem';
import WaitingList from './waitingList/UI/WaitingList';

// Internal constants definitions
const DISCOUNT = 'DISCOUNT';
const INCREMENT = 'INCREMENT';

const SalesRoomModal = ({
  isLast,
  property,
  onChange,
  clientId,
  deadlineDate,
  onChangeDeadlineDate,
  additionalAreas,
  addAdditionalAreaHandler,
  deleteAdditionalAreaHandler,
  towerId,
  spawnMessage,
  optionalDescript,
  optionalDescription,
  isSavingErrorActive,
  updateInitialStatus,
  strategyIsAvailable,
  originalPropertyStatus,
  changeClientInQueue,
  additionalAreaStateHandler,
  openQuotationListHandler,
  updateAdditionalDesistedArea,
  isOpen,
}) => {
  const {
    status,
    priceWithIncrement = 0,
    priceSold,
    discount,
    tradeDiscount,
    price,
    addedAdditionalAreas,
    adminAdditionalAreas,
    optionalDescriptions,
  } = property;

  const [initialStatus, setInitialStatus] = useState(null);
  useEffect(() => {
    setInitialStatus(status);
    updateInitialStatus();
  }, []);

  const {
    currentQuotation,
    quotationDiscount,
    updateCurrentQuotation,
  } = useCurrentAssignedQuotation();

  const { maxDueDaysForProperty } = useGetQuotationId({ towerId });

  const [quotationOpen, setQuotationOpen] = useState(false);
  const [fixedPrice, setFixed] = useState(Number(priceWithIncrement));

  const reduceAdditionalAreas = (array = []) => {
    const priceWithMeasure = ({ price, measure }) => {
      return price * measure;
    };
    return array.reduce((c, n) => {
      return c + priceWithMeasure(n);
    }, 0);
  };

  const [additionalPrices, setAdditionalPrices] = useState(
    reduceAdditionalAreas(addedAdditionalAreas) +
      reduceAdditionalAreas(adminAdditionalAreas),
  );
  const [currentState, setCurrentState] = useState(status);
  const [currentDiscount, setCurrentDiscount] = useState(discount || 0);
  const [currentTradeDiscount, setCurrentTradeDiscount] = useState(
    tradeDiscount === null ? 0 : tradeDiscount,
  );
  const [clientInQueue, setClientInQueue] = useState(false);
  const [waitingListSize, setWaitingListSize] = useState(0);
  const [discountState, setDiscountState] = useState(
    discount === null || discount >= 0 ? DISCOUNT : INCREMENT,
  );

  const getTradePrice = () => {
    return (fixedPrice - currentDiscount + additionalPrices).toFixed(2);
  };

  const { ins } = useResolveHistory({
    currentState,
    propertyId: property.id,
    clientId,
    onChangeDeadlineDate,
  });

  const getFinalTradePrice = () => {
    return (
      Number(fixedPrice) -
      currentDiscount -
      quotationDiscount +
      additionalPrices
    ).toFixed(2);
  };
  const setAdditionalPricesHandler = () => {
    setAdditionalPrices(
      reduceAdditionalAreas(addedAdditionalAreas) +
        reduceAdditionalAreas(adminAdditionalAreas),
    );
  };

  useEffect(() => {
    setFixed(priceWithIncrement.toFixed(2));
  }, [priceWithIncrement]);

  useEffect(() => {
    setAdditionalPrices(
      reduceAdditionalAreas(addedAdditionalAreas) +
        reduceAdditionalAreas(adminAdditionalAreas),
    );
  });

  useEffect(() => {
    if (clientInQueue) changeClientInQueue(clientInQueue);
  }, [clientInQueue]);

  useEffect(() => {
    setCurrentState(status);
    setCurrentDiscount(discount);
    updateCurrentQuotation(null);
    setDiscountState(discount === null || discount >= 0 ? DISCOUNT : INCREMENT);
    setAdditionalPrices(
      reduceAdditionalAreas(addedAdditionalAreas) +
        reduceAdditionalAreas(adminAdditionalAreas),
    );
    setFixed(Number(priceWithIncrement));
  }, [isOpen]);

  const requestStatusValidation = (statusValidation) => {
    switch (statusValidation) {
      case SalesRoomEnum.status.AVAILABLE:
        return false;
      case SalesRoomEnum.status.OPTIONAL:
        return (
          originalPropertyStatus === RequestStatus.DISMISSED ||
          (isLast && currentState !== SalesRoomEnum.status.AVAILABLE) ||
          originalPropertyStatus === SalesRoomEnum.status.SOLD
        );
      case SalesRoomEnum.status.SOLD:
        return (
          originalPropertyStatus === RequestStatus.DISMISSED ||
          (!strategyIsAvailable &&
            originalPropertyStatus !== SalesRoomEnum.status.SOLD)
        );

      case SalesRoomEnum.status.QUOTE:
        return (
          SalesRoomEnum.status.SOLD === originalPropertyStatus &&
          !!currentQuotation
        );
      default:
        return true;
    }
  };

  return (
    <>
      <div>
        <div className={Styles.status}>
          {isSavingErrorActive && (
            <Typography variant="subtitle1" color="error" align="center">
              {
                '* No es posible realizar esta acción, el grupo actual no tiene estrategias'
              }
            </Typography>
          )}
          {property.clientId === clientId || property.clientId === null
            ? [Math.random()].map((e) => (
                <RadioGroupMUI
                  key={e}
                  defaultValue={currentState}
                  onChange={(currentEvent) => {
                    onChange('status', currentEvent.target.value);
                    setCurrentState(currentEvent.target.value);
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <RadioButtonItem
                      value={SalesRoomEnum.status.AVAILABLE}
                      currentState={currentState}
                      disabled={
                        requestStatusValidation(
                          SalesRoomEnum.status.AVAILABLE,
                        ) || waitingListSize > 0
                      }
                      label="Disponible"
                    />
                    <RadioButtonItem
                      value={SalesRoomEnum.status.OPTIONAL}
                      label="Opcionado"
                      currentState={currentState}
                      disabled={requestStatusValidation(
                        SalesRoomEnum.status.OPTIONAL,
                      )}
                    />
                    <RadioButtonItem
                      value={SalesRoomEnum.status.SOLD}
                      currentState={currentState}
                      disabled={
                        requestStatusValidation(SalesRoomEnum.status.SOLD) ||
                        waitingListSize > 1 ||
                        (waitingListSize > 0 && !clientInQueue)
                      }
                      label="Vendido"
                    />
                    <RadioButtonItem
                      value={SalesRoomEnum.status.QUOTE}
                      currentState={currentState}
                      disabled={requestStatusValidation(
                        SalesRoomEnum.status.QUOTE,
                      )}
                      label="Cotizar"
                    />
                  </Box>
                </RadioGroupMUI>
              ))
            : null}
        </div>
        <CoOwners
          towerId={towerId}
          property={property.id}
          currentState={currentState}
          updateCurrentQuotation={updateCurrentQuotation}
          propertyStatus={status}
        />
        {currentState === SalesRoomEnum.status.OPTIONAL && (
          <div>
            <WaitingList
              property={property}
              clientId={clientId}
              setClientInQueue={setClientInQueue}
              setWaitingListSize={setWaitingListSize}
              clientInQueue={clientInQueue}
              isOpen={isOpen}
            />
            {!clientInQueue && (
              <>
                <div className={Styles.inputContainer}>
                  Seleccione la fecha de vencimiento:
                </div>
                <div className={Styles.DateTimePicker}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={moment.locale('es')}
                  >
                    <DateTimePicker
                      value={deadlineDate}
                      fullWidth
                      onChange={onChangeDeadlineDate}
                      locale="es"
                      minDate={moment().add(1, 'd').toDate()}
                      maxDate={moment()
                        .add(maxDueDaysForProperty, 'd')
                        .toDate()}
                      cancelLabel="Cancelar"
                      okLabel="Aceptar"
                      minDateMessage="Se requiere asignar una fecha de vencimiento"
                    ></DateTimePicker>
                  </MuiPickersUtilsProvider>
                </div>
                <div className={Styles.DateTimePicker}>
                  <TextField
                    multiline
                    fullWidth
                    placeholder="¿Por qué será opcionado?"
                    rows={3}
                    variant="outlined"
                    defaultValue={optionalDescriptions}
                    onChange={optionalDescription}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {(currentState === SalesRoomEnum.status.SOLD ||
          currentState === SalesRoomEnum.status.QUOTE) && (
          <div>
            <div className={Styles.inputContainer}>
              <span className={Styles.title}>Valor Apartamento</span>
              <div>
                <NumberFormat
                  value={Math.ceil(priceWithIncrement)}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </div>
            </div>
            <div>
              <AdditionalAreas
                property={property}
                additionalAreas={additionalAreas}
                addAdditionalAreaHandler={addAdditionalAreaHandler}
                deleteAdditionalAreaHandler={deleteAdditionalAreaHandler}
                setAdditionalPrices={setAdditionalPricesHandler}
                status={initialStatus}
                additionalAreaStateHandler={additionalAreaStateHandler}
                updateAdditionalDesistedArea={updateAdditionalDesistedArea}
              />
            </div>
            <div className={Styles.inputContainer}>
              <span className={Styles.title}>
                Valor Apartamento + Areas Adicionales
              </span>
              <div>
                <NumberFormat
                  value={Numbers.toFixed(
                    priceWithIncrement + additionalPrices,
                    0,
                    false,
                    true,
                  )}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </div>
            </div>
            <div className={Styles.dividedInputContainer}>
              <div className={Styles.row}>
                <div className={Styles.label}>
                  <span>Valor Comercial</span>
                </div>
                <div>
                  <RadioGroup
                    value={discountState}
                    onChange={(value) => {
                      const changedCurrentDiscount = currentDiscount * -1;
                      onChange('discount', changedCurrentDiscount);
                      setCurrentDiscount(changedCurrentDiscount);
                      setDiscountState(value);
                    }}
                    horizontal
                  >
                    <ReversedRadioButton
                      value={DISCOUNT}
                      pointColor={StyleVariables.greenColor}
                    >
                      Descuento
                    </ReversedRadioButton>
                    <ReversedRadioButton
                      value={INCREMENT}
                      pointColor={StyleVariables.redColor}
                    >
                      Incremento
                    </ReversedRadioButton>
                  </RadioGroup>
                </div>
              </div>
              <Input
                forceUpdate
                mask="currency"
                className={Styles.input}
                validations={[
                  {
                    fn: (value) => value >= 0,
                    message: 'No se permiten valores negativos',
                  },
                  {
                    fn: (value) => {
                      const pattern = new RegExp('^[0-9]*$');
                      return pattern.test(value);
                    },
                    message: 'Debe ser un número entero',
                  },
                ]}
                onChange={(target) => {
                  const calculatedDiscount =
                    discountState === DISCOUNT
                      ? target.value
                      : target.value * -1;
                  const pattern = new RegExp('^[0-9]*$');

                  if (pattern.test(target.value)) {
                    onChange('discount', calculatedDiscount);
                    setCurrentDiscount(calculatedDiscount);
                  }
                }}
                value={Math.abs(currentDiscount)}
              />
            </div>
            <div className={Styles.inputContainer}>
              <span className={Styles.label}>Valor de Cierre Comercial</span>
              <NumberFormat
                value={Math.ceil(getTradePrice())}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </div>
            <div className={Styles.dividedInputContainer}>
              <div className={Styles.row}>
                <div className={Styles.label}>
                  <span>Valor Financiero</span>
                </div>
                <div>
                  <RadioGroup
                    value={quotationDiscount >= 0 ? DISCOUNT : INCREMENT}
                    horizontal
                    className={Styles.disabled}
                    onChange={() => {}}
                  >
                    <ReversedRadioButton
                      value={DISCOUNT}
                      className={Styles.disabled}
                      pointColor={StyleVariables.greenColor}
                    >
                      Descuento
                    </ReversedRadioButton>
                    <ReversedRadioButton
                      value={INCREMENT}
                      className={Styles.disabled}
                      pointColor={StyleVariables.redColor}
                    >
                      Incremento
                    </ReversedRadioButton>
                  </RadioGroup>
                </div>
              </div>
              <NumberFormat
                customInput={TextField}
                className={Styles.input}
                prefix="$"
                isNumberString
                thousandSeparator
                value={Math.abs(quotationDiscount)}
                disabled
              />
            </div>
            <div className={Styles.inputContainer}>
              <span className={Styles.label}>Valor de Cierre Negocio</span>
              <NumberFormat
                value={Math.ceil(getFinalTradePrice())}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </div>
          </div>
        )}
      </div>
      {currentState === SalesRoomEnum.status.QUOTE && (
        <>
          <Typography variant="h6" align="center">
            <Button
              fullWidth
              color="primary"
              onClick={() => {
                setQuotationOpen(true);
              }}
            >
              Generar Cotización
            </Button>
          </Typography>
          <QuotationDialog
            open={quotationOpen}
            closeHandler={() => setQuotationOpen(false)}
            quotationData={{
              propertyId: property.id,
              clientId,
              propertyPrice: getTradePrice(),
              additionalData: property,
              isFromList: false,
            }}
            towerId={towerId}
            spawnMessage={spawnMessage}
            fetchUserList={() => {
              setCurrentState(currentState);
              openQuotationListHandler(clientId, property.id, true);
            }}
            openQuotationListHandler={openQuotationListHandler}
          />
        </>
      )}
    </>
  );
};

SalesRoomModal.propTypes = {
  property: PropTypes.shape({
    status: PropTypes.string,
    priceWithIncrement: PropTypes.number,
    priceSold: PropTypes.number,
    discount: PropTypes.number,
    tradeDiscount: PropTypes.number,
    requestStatus: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  clientId: PropTypes.string,
  additionalAreas: PropTypes.array.isRequired,
  addAdditionalAreaHandler: PropTypes.func.isRequired,
  deleteAdditionalAreaHandler: PropTypes.func.isRequired,
  updateInitialStatus: PropTypes.func.isRequired,
  changeClientInQueue: PropTypes.func,
  updateAdditionalDesistedArea: PropTypes.func,
  isOpen: PropTypes.bool,
};

SalesRoomModal.defaultProps = {
  property: {
    status: SalesRoomEnum.status.OPTIONAL,
    priceWithIncrement: 0,
    priceSold: 0,
    discount: 0,
    tradeDiscount: 0,
  },
};

export default SalesRoomModal;
