import React, { ChangeEvent, ChangeEventHandler } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputSearch from 'shared/components/InputSearch/InputSearch';
import styles from './GeneralWalletToolbarFilter.module.scss';

type Props = {
  onTextFilterChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

const GeneralWalletToolbarTextFilter = ({ onTextFilterChange }: Props) => {
  return (
    <FormControl fullWidth className={styles.textFilterControl}>
      <InputSearch onChange={onTextFilterChange} />
    </FormControl>
  );
};

export default GeneralWalletToolbarTextFilter;
