import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import styles from './DialogButtons.module.scss';

const resolveColorClassName = (color: ButtonProps['color']) => {
  switch (color) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    default:
      return styles.primary;
  }
};

const DialogButton: FC<ButtonProps> = (props) => (
  <Button
    {...props}
    className={`${styles.Button} ${resolveColorClassName(props.color)} ${props?.className || ''}`}
    children={<span>{props.children}</span>}
  />
);

export default DialogButton;
