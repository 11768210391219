import SalesSpeedServicesDefinition from './SalesSpeedServicesDefinitions';
import Services from '../../../services/services';

export default class SalesSpeedServices extends Services {
  getSalesSpeed(towerId, isEvalpro = false) {
    return this.get(
      SalesSpeedServicesDefinition.getSalesSpeed(towerId, isEvalpro),
    );
  }
}
