export const FETCH_CLIENTS__START = 'FCST';
export const FETCH_CLIENTS__SUCCESS = 'FCSS';
export const FETCH_CLIENTS__FAILURE = 'FCF';
export const CREATE_CLIENT__SUCCESS = 'CDS';
export const UPDATE_CLIENT__SUCCESS = 'UDS';
export const ADD_CLIENT__SUCCESS = 'ADS';
export const REMOVE_CLIENT = 'RMV';
export const UPDATE_ADDRESS_FOR_CLIENT = 'UPDATE_ADDRESS_FOR_CLIENT';
export const UPDATE_ADDRESS_FOR_OFFICE = 'UPDATE_ADDRESS_FOR_OFFICE';
export const UPDATE_SELECTED_CLIENT = 'UPDATE_SELECTED_CLIENT';
export const UPDATE_ADDRESS_DESCRIPTION = 'UPDATE_ADDRESS_DESCRIPTION';

export const removeClient = (clientId) => ({
  type: REMOVE_CLIENT,
  payload: clientId,
});

export const fetchClientsStart = () => ({
  type: FETCH_CLIENTS__START,
});

export const fetchClientsSuccess = (clients) => ({
  type: FETCH_CLIENTS__SUCCESS,
  payload: clients,
});

export const fetchClientsFailure = () => ({
  type: FETCH_CLIENTS__FAILURE,
});

export const createClient = (client) => ({
  type: CREATE_CLIENT__SUCCESS,
  payload: client,
});

export const updateClient = (client) => ({
  type: UPDATE_CLIENT__SUCCESS,
  payload: client,
});

export const addClient = (client) => ({
  type: ADD_CLIENT__SUCCESS,
  payload: client,
});

export const updateAddressForClient = (address) => ({
  type: UPDATE_ADDRESS_FOR_CLIENT,
  payload: address,
});

export const updateAddressForOffice = (address) => ({
  type: UPDATE_ADDRESS_FOR_OFFICE,
  payload: address,
});

export const updateSelectedClient = (client) => ({
  type: UPDATE_SELECTED_CLIENT,
  payload: client,
});

export const updateCustomAddressDescription = (addressDescription) => ({
  type: UPDATE_ADDRESS_DESCRIPTION,
  payload: addressDescription,
});
