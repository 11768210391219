import BusinessPartnerServiceDefinition from './BusinessPartnersServiceDefinition';
import Services from '../../../services/services';

export default class BusinessPartnerService extends Services {
  getBusinessPartnersInCompany(companyId) {
    return this.get(
      BusinessPartnerServiceDefinition.getBusinessPartners(companyId),
    );
  }

  createBusinessPartner(data) {
    return this.post(
      BusinessPartnerServiceDefinition.createBusinessPartner(),
      data,
    );
  }

  editBusinessPartner(id, data) {
    return this.put(
      BusinessPartnerServiceDefinition.editBusinessPartner(id),
      data,
    );
  }

  deleteBusinessPartner(data) {
    return this.delete(
      BusinessPartnerServiceDefinition.deleteBusinessPartner(data),
    );
  }

  downloadBusinessPartnerTemplate() {
    const route = BusinessPartnerServiceDefinition.download();
    return this.get(route, {
      responseType: 'blob',
    });
  }

  postBusinessPartnerTemplate(companyId, form) {
    const route = BusinessPartnerServiceDefinition.postBusinessPartnerTemplate(
      companyId,
    );
    return this.post(route, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}
