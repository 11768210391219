import {
  SET_ARREARS_FOR_WALLET_REPORT,
  SET_ARREARS_REPORT_CLIENTS,
} from './actions';

const initialState = {
  summaryReport: { total: {}, daysSummary: {} },
  arrearsReportClients: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ARREARS_FOR_WALLET_REPORT:
      return {
        ...state,
        summaryReport: payload,
      };
    case SET_ARREARS_REPORT_CLIENTS:
      return {
        ...state,
        arrearsReportClients: payload,
      };
    default:
      return state;
  }
};

export default reducer;
