import { useSnackbar } from 'notistack';

import FeatureFieldServices from '../../services/FeatureFieldServices';
import { FeatureField, FeatureFields } from '../interfaces/featureField.types';
import { SortFieldData, SortFieldsData } from '../interfaces/FeatureFieldServices.types';
import { SortFeatureFieldsHook } from '../interfaces/useSortFeatureFields.types';

const repository = new FeatureFieldServices();

const getSortFieldData = ({ id, index }: FeatureField): SortFieldData => ({
  id,
  index,
});

const getSortFieldsData = (featureFields: FeatureFields): SortFieldsData => ({
  fields: Object.values(featureFields).map(getSortFieldData),
});

const updateFieldIndex = (featureField: FeatureField, index: number) => ({
  ...featureField,
  index,
});

const addNewIndexFieldToFields = (
  prevFeatureFields: FeatureFields,
  featureId: number,
  index: number,
): FeatureFields => ({
  ...prevFeatureFields,
  [featureId]: updateFieldIndex(prevFeatureFields[featureId], index),
});

const updateFieldsIndex = (sortedFields: number[], featureFields: FeatureFields): FeatureFields =>
  sortedFields.reduce(addNewIndexFieldToFields, { ...featureFields });

function useSortFeatureFields({ featureFieldsRepo, onUpdateFields }: SortFeatureFieldsHook) {
  const { enqueueSnackbar } = useSnackbar();

  const requestSortFields = async (sortedFeatureFields: FeatureFields) => {
    try {
      await repository.sort(getSortFieldsData(sortedFeatureFields));
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const sortFeatureFields = (sortedFields: number[]) => {
    const sortedFeatureFields = updateFieldsIndex(sortedFields, featureFieldsRepo.data);

    requestSortFields(sortedFeatureFields);
    onUpdateFields(sortedFeatureFields);
  };

  return sortFeatureFields;
}

export default useSortFeatureFields;
