import React from 'react';
import { PropTypes } from 'prop-types';
import ClientAddressType from '../../Core/models/ClientAddressType';
import HelperAddressForClientType from './HelperAddressForClientType';
import OpenAddressForClientType from './OpenAddressForClientType';

const AddressContainerForClientWithType = ({
  currentType,
  update,
  clientAddress,
  addressDescription,
}) => {
  return (
    <>
      {currentType === ClientAddressType.URBAN.code && (
        <HelperAddressForClientType
          update={update}
          clientAddress={clientAddress}
        />
      )}
      {currentType === ClientAddressType.OPEN.code && (
        <OpenAddressForClientType
          update={update}
          addressDescription={addressDescription}
        />
      )}
    </>
  );
};

AddressContainerForClientWithType.propTypes = {
  currentType: PropTypes.object,
  clientAddress: PropTypes.object,
  update: PropTypes.func,
  addressDescription: PropTypes.string,
};

export default AddressContainerForClientWithType;
