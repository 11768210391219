import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Styles from '../../styles/AddClientModalMainView.module.scss';

const SelectWithTitleForClient = ({
  id,
  title,
  classObjectForOptions,
  defaultValue,
  value,
  onChange,
  required,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel fullWidth className={Styles.inputLabel}>
        {title}
      </InputLabel>
      <Select
        id={id}
        name={id}
        fullWidth
        required={required}
        defaultValue={defaultValue}
        value={value}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
      >
        {Object.keys(classObjectForOptions).map((key) => {
          return (
            <MenuItem
              key={`${title}${classObjectForOptions[key].code}`}
              value={classObjectForOptions[key].code}
            >
              {classObjectForOptions[key].value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SelectWithTitleForClient.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  classObjectForOptions: PropTypes.object,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default SelectWithTitleForClient;
