import { useState, useEffect, useContext } from 'react';
import ContainerContext from '../../../../containers/Client/context';
import ClientAddressType from '../models/ClientAddressType';

function useClientAddressTypeSelector(isOffice) {
  const { currentAddress, currentAddressForOffice } = useContext(
    ContainerContext,
  );
  const [type, setType] = useState(ClientAddressType.URBAN.code);

  useEffect(() => {
    if (!isOffice)
      setType(currentAddress?.addressType || ClientAddressType.URBAN.code);
  }, [currentAddress?.addressType]);

  useEffect(() => {
    if (isOffice)
      setType(
        currentAddressForOffice?.addressType || ClientAddressType.URBAN.code,
      );
  }, [currentAddressForOffice?.addressType]);

  const onChangeType = (event) => {
    setType(event.target.value);
  };

  return { currentType: type, onChangeType };
}

export default useClientAddressTypeSelector;
