import useCustomCellForHeader from '../../../Core/customHooks/useCustomCellForHeader'


const ResultsStateHeaderCustomCell = (props) => {
  const { ...restProps } = props;
  const { tableColumn } = props;
  const { column } = tableColumn;
  const { title } = column;

  const customCell = useCustomCellForHeader({title, ...restProps});

  return customCell;
}

export default ResultsStateHeaderCustomCell
