import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ResultsStateServices from '../../Services/ResultsStateServices';
import { updateCutsRows } from '../../../TotalCashFlow/Core/actions';

interface RouteParams {
  towerId: string;
}

function useOverwriteCut() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const resultsStateData = useSelector((state) => state.cashFlow || {});
  const { resultsStateRows, towerWithoutCuts, cutsControl, cutsRows } = resultsStateData.root;

  const resultsStateServices = new ResultsStateServices();
  const { towerId } = useParams<RouteParams>();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (cutsControl !== cutsRows && !cutsRows) {
      dispatch(updateCutsRows(cutsControl));
    }
  }, [cutsControl]);

  const resultsStateRowsMapper = resultsStateRows.map((row) => {
    return {
      controlId: row.id,
      previousCut: row.total,
      cutPercentage: row.percentage,
    };
  });

  const cutsStateRows = (cutsRows) => {
    const rowsWithDate = cutsRows.map((cutRow) => {
      return {
        ...cutRow,
        cutDate: moment().format('DD/MM/YYYY')
      }
    })

    return rowsWithDate
  }

  const cutDate = cutsControl && moment(cutsControl[0]?.createdAt).format('DD/MM/YYYY')

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandler = async () => {
    setOpen(false);
    const updateCutsControl = await resultsStateServices.putCutsControl({ towerId, rows: resultsStateRowsMapper });
    const { data } = updateCutsControl;

    const cutsState = cutsStateRows(resultsStateRowsMapper)

    data &&  dispatch(updateCutsRows(cutsState));

    data &&
      enqueueSnackbar(`El corte del ${moment().format('DD/MM/YYYY')} ha sido copiado exitosamente`, {
        variant: 'success',
      });
    data === false && enqueueSnackbar('No se puedieron copiar los datos del corte actual', { variant: 'error' });
  };

  return {
    open,
    handleClose,
    setOpen,
    onSubmitHandler,
    towerWithoutCuts,
    cutDate,
  };
}

export default useOverwriteCut;
