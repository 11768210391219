import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#686f75',
      main: '#434C53',
      dark: '#2e353a',
      contrastText: '#F3F3F5',
    },
    secondary: {
      light: '#b9338e',
      main: '#A80072',
      dark: '#75004f',
      contrastText: '#F3F3F5',
    },
    ternary: {
      main: '#E04A65',
    },
  },
});

export default theme;
