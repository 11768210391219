import React, { FC } from 'react';
import { ListItemText, Menu, MenuItem } from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { MuiIconButton } from '../../../../../shared/components/Button/Button';
import Typography from '../../../../../shared/components/Typography/Typography';
import useContractPaymentListGridMenu from '../../../Core/customHooks/useContractPaymentListGridMenu';
import Styles from '../../styles/Menu.module.scss';

interface IContractPaymentListGridMenu {
  handleEdit: () => void;
  handleDelete: () => void;
}
const ContractPaymentListGridMenu: FC<IContractPaymentListGridMenu> = ({ handleEdit, handleDelete }) => {
  const { open, anchorEl, handleClick, handleClose } = useContractPaymentListGridMenu();
  return (
    <>
      <MuiIconButton
        id="long-button"
        aria-haspopup="true"
        edge="end"
        aria-label="edit contract payment"
        size="small"
        onClick={handleClick}
        aria-controls="contract-payment-grid-menu"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVert />
      </MuiIconButton>
      <Menu
        id="contract-payment-grid-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={2}
        getContentAnchorEl={null}
        MenuListProps={{
          'aria-labelledby': 'contract-payment-grid-menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        classes={{
          paper: Styles.menuRoot,
          list: Styles.menuList,
        }}
      >
        <span className={Styles.menuCenterRight}></span>
        <MenuItem
          key="edit-contract-payment-menu-item"
          classes={{
            root: Styles.menuItem,
            selected: Styles.menuItemSelected,
          }}
          dense
          onClick={() => {
            handleClose();
            handleEdit();
          }}
        >
          <EditOutlinedIcon />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography variant="body2">Editar</Typography>
              </React.Fragment>
            }
          />
        </MenuItem>
        <MenuItem
          key="delete-contract-payment-menu-item"
          classes={{
            root: Styles.menuItem,
            selected: Styles.menuItemSelected,
          }}
          dense
          onClick={() => {
            handleClose();
            handleDelete();
          }}
        >
          <DeleteOutlineOutlinedIcon />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography variant="body2">Eliminar</Typography>
              </React.Fragment>
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default ContractPaymentListGridMenu;
