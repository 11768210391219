import {
  columnsForBaseControl,
  columnsForCutControl,
  columnsForFirstBudgetControl,
  columnsForSecondBudgetControl,
  columnsForThirdBudgetControl,
  baseControlHeadersForFilterRows,
  cutHeadersForFilterRows,
  budgetHeadersForFirstFilterRows,
  budgetHeadersForSecondFilterRows,
  budgetHeadersForThirdFilterRows,
  BASE_CONTROL_NAME,
  CUT_CONTROL_NAME,
  BUDGET_CONTROL_NAME,
  NAME,
  TOTAL,
  PERCENTAGE,
  ACCUMULATED,
  PROJECTED,
  PREVIOUS_CONTROL,
  CONTROL_PERCENTAGE,
  VARIATION,
  CURRENT_CONTROL,
  FIRST_BUDGET,
  FIRST_PERCENTAGE,
  FIRST_VARIATION,
  SECOND_BUDGET,
  SECOND_PERCENTAGE,
  SECOND_VARIATION,
  THIRD_BUDGET,
  THIRD_PERCENTAGE,
  THIRD_VARIATION,
  EMPTYCELL,
  PERCENTAGE_EMPTY_CELL
} from './constants/ResultsStateConstants';

const useSelectedRowsForResultsStateExcel = (
  resultsStateRows,
  cutsControl,
  budgetControl,
  selectedTab
) => {

  const getBaseRows = (rows) => {
    const rowsWithColumnKeys = rows.map(row => {
      const { name, total, percentage, accumulated, projected } = row ?? {}

      return {
        [NAME]: name,
        [TOTAL]: total,
        [PERCENTAGE]: percentage,
        [ACCUMULATED]: accumulated,
        [PROJECTED]: projected,
      }
    })

    return rowsWithColumnKeys
  }

  const getCutRows = (baseRows, rowsToAdd = null) => {
    const rowsWithCutControl = baseRows.map(baseRow => {
      const cutData = rowsToAdd.find(row => row.controlId === baseRow.id)

      const row = {
        [NAME]: baseRow.name,
        [CURRENT_CONTROL]: baseRow.total,
        [PERCENTAGE]: baseRow.percentage,
        [PREVIOUS_CONTROL]: cutData?.previousCut || EMPTYCELL,
        [CONTROL_PERCENTAGE]: cutData?.cutPercentage || PERCENTAGE_EMPTY_CELL,
        [VARIATION]: baseRow.total - cutData?.previousCut || EMPTYCELL,
      }

      return row
    })

    return rowsWithCutControl
  }

  const getBudgetRows = (baseRows, rowsToAdd = null) => {
    const rowsWithCutControl = baseRows.map(baseRow => {
      const budgetsFiltered = rowsToAdd.filter(row => row.budgetId === baseRow.id)

      const firstBudget = budgetsFiltered.find(row => row.budgetIndex === 1)
      const secondBudget = budgetsFiltered.find(row => row.budgetIndex === 2)
      const thirdBudget = budgetsFiltered.find(row => row.budgetIndex === 3)

      const row = {
        [NAME]: baseRow.name,
        [CURRENT_CONTROL]: baseRow.total,
        [PERCENTAGE]: baseRow.percentage,
        [FIRST_BUDGET]: firstBudget?.previousBudget || EMPTYCELL,
        [FIRST_PERCENTAGE]: firstBudget?.budgetPercentage || PERCENTAGE_EMPTY_CELL,
        [FIRST_VARIATION]: baseRow.total - firstBudget?.previousBudget || EMPTYCELL,
        [SECOND_BUDGET]: secondBudget?.previousBudget || EMPTYCELL,
        [SECOND_PERCENTAGE]: secondBudget?.budgetPercentage || PERCENTAGE_EMPTY_CELL,
        [SECOND_VARIATION]: baseRow.total - secondBudget?.previousBudget || EMPTYCELL,
        [THIRD_BUDGET]: thirdBudget?.previousBudget || EMPTYCELL,
        [THIRD_PERCENTAGE]: thirdBudget?.budgetPercentage || PERCENTAGE_EMPTY_CELL,
        [THIRD_VARIATION]: baseRow.total - thirdBudget?.previousBudget || EMPTYCELL,
      }

      return row
    })

    return rowsWithCutControl
  }

  const excelData = () => {
    let columns;
    let headersForFilterRows;
    let name;
    let newRows;

    if (selectedTab.id === 'BASE') {
      columns = [columnsForBaseControl];
      headersForFilterRows = [baseControlHeadersForFilterRows];
      name = BASE_CONTROL_NAME;
      newRows = getBaseRows(resultsStateRows)
    }

    if (selectedTab.id === 'CUT') {
      columns = [columnsForCutControl];
      headersForFilterRows = [cutHeadersForFilterRows]
      name = CUT_CONTROL_NAME
      newRows = getCutRows(resultsStateRows, cutsControl)
    }

    if (selectedTab.id === 'BUDGET') {
      columns = [columnsForFirstBudgetControl, columnsForSecondBudgetControl, columnsForThirdBudgetControl];
      headersForFilterRows = [
        budgetHeadersForFirstFilterRows,
        budgetHeadersForSecondFilterRows,
        budgetHeadersForThirdFilterRows,
      ]
      name = BUDGET_CONTROL_NAME;
      newRows = getBudgetRows(resultsStateRows, budgetControl)
    }

    return {
      columns,
      headersForFilterRows,
      name,
      newRows
    }
  }

  return {
    excelData
  }
}

export default useSelectedRowsForResultsStateExcel