import React, { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { AgentModel } from 'App/Agents/Core/models/AgentModel';

import { ClientModel } from 'App/Clients/Core/models/ClientModel';
import useAddClientModalContentStep from 'App/Clients/Core/customHooks/useAddClientModalContentStep';
import useAddClientModalContent from 'App/Clients/Core/customHooks/useAddClientModalContent';
import ModalStepIconComponent from './ModalStepIconComponent/ModalStepIconComponent';

import HowDidFindAboutProject from '../Sections/HowDidFindAboutProject/HowDidFindAboutProject';
import AssignedAgent from '../Sections/AssignedAgent/AssignedAgent';
import PersonalInformation from '../Sections/PersonalInformation/PersonalInformation';
import AdditionalInformation from '../Sections/AdditionalInformation/AdditionalInformation';
import LaboralInformation from '../Sections/LaboralInformation/LaboralInformation';
import ClientSuccessCreated from '../Sections/ClientSuccessCreated/ClientSuccessCreated';

import clientModalStyles from '../Modals/ClientModal.module.scss';
import buttonStyles from '../ClientButtons/ClientButtons.module.scss';
import styles from './AddClientModalContent.module.scss';

interface Props {
  agents: AgentModel[];
  onAddedClient: (client: ClientModel) => void;
  handleChangeState: () => void;
  onCancel: () => void;
}

const AddClientModalContent: FC<Props> = (props) => {
  const { agents, handleChangeState, onAddedClient, onCancel } = props;
  const { steps, activeStep, completedStep, handleBack } =
    useAddClientModalContentStep();

  const {
    isLoading,
    isSuccessCreated,
    client,
    clientCompany,
    clientInformation,
    clientAddressSection,
    handleClientChange,
    handleClientCompanyChange,
    handleClientInformationChange,
    handleClientAddressSectionChange,
    handleEndEdit,
  } = useAddClientModalContent({
    onAddedClient,
    onChangeModels: completedStep,
    handleChangeState,
  });

  return (
    <>
      <DialogContent className={styles.main}>
        {activeStep < steps.length && !isSuccessCreated && (
          <Box display="flex" justifyContent="center">
            <Box maxWidth="400px" minWidth="400px" className={styles.stepper}>
              <Stepper>
                {steps.map((step, index) => (
                  <Step key={index} {...step}>
                    <StepLabel StepIconComponent={ModalStepIconComponent} />
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        )}
        <Box className={clientModalStyles.content} paddingY={4}>
          {isSuccessCreated ? (
            <ClientSuccessCreated client={client} />
          ) : (
            <Grid container>
              <Grid item xs={12}>
                {activeStep === 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginY={4}
                    flexDirection="column"
                  >
                    <Box padding={2}>
                      <Typography variant="h5">
                        ¿Cómo se enteró del proyecto?
                      </Typography>
                    </Box>
                    <Grid container md={8}>
                      <HowDidFindAboutProject
                        formId="clientModalForm"
                        model={clientCompany}
                        onChangeModel={handleClientCompanyChange}
                      />
                    </Grid>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginY={4}
                    flexDirection="column"
                  >
                    <Box padding={2}>
                      <Typography variant="h5">Asesor responsable</Typography>
                    </Box>
                    <Grid container md={8}>
                      <AssignedAgent
                        formId="clientModalForm"
                        model={clientCompany}
                        onChangeModel={handleClientCompanyChange}
                        agents={agents}
                      />
                    </Grid>
                  </Box>
                )}
                {activeStep === 2 && (
                  <PersonalInformation
                    title="Datos personales"
                    formId="clientModalForm"
                    model={client}
                    onChangeModel={handleClientChange}
                  />
                )}
                {activeStep === 3 && (
                  <AdditionalInformation
                    title="Información adicional"
                    formId="clientModalForm"
                    model={clientInformation}
                    clientAddressSection={clientAddressSection}
                    onChangeModel={handleClientInformationChange}
                    onChangeAddress={handleClientAddressSectionChange}
                  />
                )}
                {activeStep === 4 && (
                  <LaboralInformation
                    title="Información Laboral"
                    formId="clientModalForm"
                    model={clientInformation}
                    clientAddressSection={clientAddressSection}
                    onChangeModel={handleClientInformationChange}
                    onChangeAddress={handleClientAddressSectionChange}
                    onEndEdit={handleEndEdit}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {isLoading && (
            <Box className={clientModalStyles.loader}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </DialogContent>
      {!isSuccessCreated && (
        <DialogActions>
          <Button
            onClick={activeStep === 0 ? onCancel : handleBack}
            className={
              activeStep === 0
                ? buttonStyles.buttonInitial
                : buttonStyles.button
            }
          >
            {activeStep === 0 ? 'Cancelar' : 'Atrás'}
          </Button>
          <Button
            className={
              activeStep >= steps.length - 1
                ? buttonStyles.buttonEnd
                : buttonStyles.button
            }
            type="submit"
            form="clientModalForm"
          >
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default AddClientModalContent;
