export const UPDATE_PROPERTIES_WITH_PRICES = 'UPDATE_PROPERTIES_WITH_PRICES';
export const UPDATE_PROPERTIES_WITH_CHARACTERISTICS =
  'UPDATE_PROPERTIES_WITH_CHARACTERISTICS';

export const updatePropertiesWithPrices = (salesRoomData) => ({
  type: UPDATE_PROPERTIES_WITH_PRICES,
  payload: salesRoomData,
});

export const updatePropertiesWithCharacteristics = (properties) => ({
  type: UPDATE_PROPERTIES_WITH_CHARACTERISTICS,
  payload: properties,
});
