import React, { FC } from 'react';
import { Box, Button } from '@material-ui/core';
import Typography from '../../../../shared/components/Typography/Typography';
import useSegmentedControlComparison from '../../Core/customHooks/useSegmentedControlComparison';
import Styles from './IncomePaymentSegment.module.scss';
import SegmentedControlOption from '../../Core/models/SegmentedControlOption';

type IncomePaymentSegmentProps = SegmentedControlOption & {
  currentValue: string;
  onButtonClick: (id: string) => void;
};

const IncomePaymentSegment: FC<IncomePaymentSegmentProps> = ({
  label,
  value,
  currentValue,
  onButtonClick,
}) => {
  const { compare, onClick } = useSegmentedControlComparison(
    currentValue,
    onButtonClick,
  );
  return (
    <>
      <Button className={Styles.Button} onClick={onClick(value)}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography
            className={Styles.NameText}
            variant="body1"
            color="primary-600"
          >
            {label}
          </Typography>
          {compare(value) && <Box className={Styles.Underscore} />}
        </Box>
      </Button>
    </>
  );
};

export default IncomePaymentSegment;
