import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Loading from '../../components/UI/Loader';
import Card, { CardHeader, CardBody } from '../../components/UI/Card/Card';
import SaleDateSelectorTable from '../../components/SaleDateSelector';
import Services from '../../services/saleDateSelector/saleDateSelectorServices';
import GotoSalesRoom from '../../components/ClientProperty/GotoSalesRoom';
import {
  setApiFetch,
  setProperties,
  setSalesStartDate,
  setTowerId,
} from './actions';

const services = new Services();

const SaleDateSelector = ({
  match,
  onFetchApi,
  onSetProperties,
  onSetSalesStartDate,
  loading,
  onSetTowerId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openAndGotoSalesRoom, setOpenGotoSalesRoom] = useState(false);
  async function fetch() {
    try {
      onFetchApi(true);
      const response = await services.findHistoryIn(match.params.towerId);
      if (response.data.groups.length >= 1) {
        let unsortProperties = [];
        if (response.data.groups.length > 1) {
          unsortProperties = response.data.groups.reduce((prev, current) => {
            return [...prev, ...current.properties];
          }, []);
        } else {
          unsortProperties = response.data.groups.flatMap((current) => {
            return current.properties;
          });
        }
        const sortedProperties = _.sortBy(unsortProperties, (property) =>
          Number(property.name) ? Number(property.name) : property.name,
        );

        onSetSalesStartDate(response.data.salesStartDate);
        onSetProperties(sortedProperties);
        onSetTowerId(match.params.towerId);
        onFetchApi(false);
      } else {
        onFetchApi(false);
        setOpenGotoSalesRoom(true);
      }
    } catch (error) {
      onFetchApi(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Loading isLoading={loading}>
      <GotoSalesRoom
        open={openAndGotoSalesRoom}
        towerId={match.params.towerId}
      />
      <Card>
        <CardHeader>
          <span>Fechas de venta</span>
        </CardHeader>
        <CardBody>
          <SaleDateSelectorTable />
        </CardBody>
      </Card>
    </Loading>
  );
};

SaleDateSelector.propTypes = {
  match: PropTypes.object,
  onFetchApi: PropTypes.func,
  onSetProperties: PropTypes.func,
  onSetTowerId: PropTypes.func,
  onSetSalesStartDate: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.saleDateSelector.root.isLoading,
});

const mapDispatchToProps = {
  onFetchApi: setApiFetch,
  onSetProperties: setProperties,
  onSetTowerId: setTowerId,
  onSetSalesStartDate: setSalesStartDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleDateSelector);
