import React from 'react';

import useSalesSpeedHelperTable from '../../../core/customHooks/useSalesSpeedHelperTable';
import { SalesSpeedHelperPayload } from '../../../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperTables from '../../../core/enums/SalesSpeedHelperTables';
import SalesSpeedHelperPartialTable from '../SalesSpeedHelperPartialTable/SalesSpeedHelperPartialTable';

import styles from './SalesSpeedHelperTable.module.scss';

type Props = {
  salesSpeedPayload?: SalesSpeedHelperPayload;
  currentTab: SalesSpeedHelperTables;
};

const SalesSpeedHelperTable: React.FC<Props> = ({ salesSpeedPayload, currentTab }) => {
  const {
    primaryColumns,
    secondaryColumns,
    formattedColumns,
    columnsBands,
    columnExtensions,
    preSalesRows,
    salesRows,
    totalRows,
  } = useSalesSpeedHelperTable({ salesSpeedPayload, currentTab });

  return (
    <div className={styles.root}>
      <div className={styles.tableContainer}>
        {preSalesRows && (
          <SalesSpeedHelperPartialTable
            className={styles.partialTable}
            currentTab={currentTab}
            rows={preSalesRows}
            columns={primaryColumns}
            formattedColumns={formattedColumns}
            columnExtensions={columnExtensions}
            columnBands={columnsBands.preSales}
          />
        )}

        {salesRows && (
          <SalesSpeedHelperPartialTable
            className={styles.partialTable}
            currentTab={currentTab}
            rows={salesRows}
            columns={secondaryColumns}
            formattedColumns={formattedColumns}
            columnExtensions={columnExtensions}
            columnBands={columnsBands.sales}
          />
        )}

        {totalRows && (
          <SalesSpeedHelperPartialTable
            className={styles.partialTable}
            currentTab={currentTab}
            rows={totalRows}
            columns={secondaryColumns}
            formattedColumns={formattedColumns}
            columnExtensions={columnExtensions}
            columnBands={columnsBands.total}
          />
        )}
      </div>
    </div>
  );
};

export default SalesSpeedHelperTable;
