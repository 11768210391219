import styled from 'styled-components';
import { TableCell, TableRow } from '@material-ui/core';

export const CustomPaymentTableRow = styled(TableRow)`
  height: ${(props) => (props.isEditing ? '110px' : '60px')};
  padding: 0px;
`;

export const CustomPaymentHeaderRow = styled(TableRow)`
  height: ${(props) => (props.isEditing ? '90px' : '60px')};
  padding: 0px;
`;

export const CustomPaymentTableCell = styled(TableCell)`
  height: ${(props) => (props.isEditing ? '90px' : '0px')};
  padding: 0px;
`;
