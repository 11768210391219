import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import StagesServices from '../../services/StagesServices';
import { OnUpdateStages, SaveTowerHandler, TowerEditorOptions, TowerValues } from '../types/Stages.types';
import { AddTowerResponse, UpdateTowerResponse } from '../types/StagesServices.types';

const services = new StagesServices();

const upsertStageTower = (
  { isEditing, stageId, towerId }: TowerEditorOptions,
  values: TowerValues,
): Promise<UpdateTowerResponse | AddTowerResponse> =>
  isEditing ? services.updateTower(towerId, values) : services.addTower(stageId, values);

type UseSaveStageTower = {
  isOpen: boolean;
  options: TowerEditorOptions;
  onUpdateStages: OnUpdateStages;
  onClose: () => void;
};

function useSaveStageTower({ isOpen, options, onUpdateStages, onClose }: UseSaveStageTower) {
  const [isSavingTower, setIsSavingTower] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    closeSnackbar();
  }, [isOpen]);

  const requestSaveTower = async (values: TowerValues) => {
    try {
      setIsSavingTower(true);
      const { data } = await upsertStageTower(options, values);
      onUpdateStages(data);
      onClose();
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error', persist: true });
    } finally {
      setIsSavingTower(false);
    }
  };

  const saveTower: SaveTowerHandler = (values) => {
    requestSaveTower(values);
  };

  return { isSavingTower, saveTower };
}

export default useSaveStageTower;
