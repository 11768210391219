import moment from 'moment';
import { quotationMonthDate } from '../../../Quotation/Core/customHooks/useQuotationMonthDate';

const addZeroPayments = (currentMonth, startMomentDate, sortedPayments) => {
  const missingMonths = Math.ceil(
    currentMonth.diff(startMomentDate, 'M', true),
  );

  const zeroPayments = Array(Math.max(missingMonths, 0))
    .fill(0)
    .map((_, index) => {
      return {
        id: index + 300,
        date: startMomentDate.clone().add(index + 1, 'M'),
        price: 0,
      };
    });
  const sortedRealPayments = [...sortedPayments, ...zeroPayments];
  const lastDate = zeroPayments[zeroPayments.length - 1]
    ? zeroPayments[zeroPayments.length - 1].date
    : startMomentDate;
  return { sortedPayments: sortedRealPayments, lastDate };
};

export const splitPaymentsDistribution = (
  realPayments,
  periods,
  oldDistribution,
  startDate,
  initialDate,
  paymentTypes,
) => {
  const paymentsSummary = realPayments.reduce((summary, payment) => {
    return summary + Number(payment.price);
  }, 0);

  let sortedRealPayments = realPayments
    .sort((a, b) => a.date - b.date)
    .map((payment) => ({ ...payment, date: moment(Number(payment.date)) }));

  const lastPayment = sortedRealPayments[sortedRealPayments.length - 1];
  let lastDate = lastPayment ? moment(Number(lastPayment.date)) : null;
  const startMomentDate = moment(Number(startDate));
  const currentMonth = moment()
    .startOf('month')
    .add(-1, 'M');
  if (!lastDate || (lastDate && lastDate.isBefore(currentMonth, 'M'))) {
    if (!lastDate) {
      const { sortedPayments, lastDate: last } = addZeroPayments(
        currentMonth.clone(),
        startMomentDate.clone().add(-1, 'M'),
        sortedRealPayments,
      );
      lastDate = last;
      sortedRealPayments = sortedPayments;
    } else {
      const { sortedPayments, lastDate: last } = addZeroPayments(
        currentMonth,
        lastDate,
        sortedRealPayments,
      );
      lastDate = last;
      sortedRealPayments = sortedPayments;
    }
  }

  const distribution = Array.from(Array(Math.max(0, periods))).reduce(
    (accumulated, _, index) => {
      const currentMonthForIndex = quotationMonthDate(
        index - 1,
        startDate,
        startDate,
        true,
      );
      const customIndex = index;
      const paymentNumber = customIndex + 1;
      const value = (oldDistribution && oldDistribution[customIndex]) || 0;
      const date = currentMonthForIndex;
      const typeId =
        paymentTypes && paymentTypes.length > customIndex
          ? paymentTypes[customIndex]
          : '0';

      const item = {
        index: customIndex,
        paymentNumber,
        value,
        date,
        typeId,
      };

      return {
        summary: accumulated.summary + value,
        items: [...accumulated.items, item],
      };
    },
    { summary: 0, items: [] },
  );

  return {
    realPayments: sortedRealPayments,
    distribution: distribution.items,
    summary: distribution.summary,
    paymentsSummaryPrice: paymentsSummary + distribution.summary,
    lastIndexToResetDistribution: 0,
  };
};

const usePaymentsMerge = (
  realPayments,
  periods,
  oldDistribution,
  startDate,
  initialDate,
  paymentTypes,
) => {
  return splitPaymentsDistribution(
    realPayments,
    periods,
    oldDistribution,
    startDate,
    initialDate,
    paymentTypes,
  );
};

export default usePaymentsMerge;
