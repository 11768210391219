import { API_PATH } from '../../../config/config';

const MacroeconomicServicesDefinition = {
  macroeconomicId: (projectId) => {
    return `${API_PATH}macroeconomic/${projectId}`
  },

  macroeconomicUpdate: (projectId) => {
    return `${API_PATH}macroeconomic/update/${projectId}`
  }
}

export default MacroeconomicServicesDefinition