import React, { FC } from 'react';
import { GeneralWalletThreadInteractionDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import GeneralWallletDetailsThreadInteractionCommentContent from './GeneralWallletDetailsThreadInteractionCommentContent';
import GeneralWallletDetailsThreadInteractionCommentEditable from './GeneralWallletDetailsThreadInteractionCommentEditable';
import useGeneralWallletDetailsThreadInteractionComment from 'App/GeneralWalletDetails/core/customHooks/useGeneralWallletDetailsThreadInteractionComment';

const GeneralWallletDetailsThreadInteractionComment: FC<GeneralWalletThreadInteractionDTO> = (interaction) => {
  const { isInteractionInEdition, handleChange, handlePopoverOpen } =
    useGeneralWallletDetailsThreadInteractionComment(interaction);
  return (
    <>
      {isInteractionInEdition && (
        <GeneralWallletDetailsThreadInteractionCommentEditable {...interaction} onChange={handleChange} />
      )}
      {!isInteractionInEdition && (
        <GeneralWallletDetailsThreadInteractionCommentContent {...interaction} onClick={handlePopoverOpen} />
      )}
    </>
  );
};

export default GeneralWallletDetailsThreadInteractionComment;
