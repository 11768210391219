import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import { Radio, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EditableCell from '../EditableCell';
import { DEFAULT_DATE_FORMAT } from '../../../../../../../../helpers/dates';
import QuotationPaymentTypeRow from '../../../../../../../../App/Quotation/UI/views/rows/QuotationPaymentTypeRow';
import {
  CustomPaymentTableRow,
  CustomPaymentTableCell,
} from './CustomPaymentTableRow';
import useQuotationEditionType from '../../../../../../../../App/Quotation/Core/customHooks/useQuotationEditionType';
import QuotationDiscountType from '../../../../../../../../App/Quotation/Core/enums/QuotationDiscountType';

const ReservationDeposit = ({
  onChange,
  quotation,
  showEditableButtons,
  enableDiscountCalculation,
  dispatchFetchQuotationSuccess,
}) => {
  const {
    isEditingMode: editMode,
    isLockedType,
    discountType,
    reserveHandler,
    updateDistributionIndex,
    onClickDiscount,
  } = useQuotationEditionType();
  const {
    paymentStartDate,
    proposalPaymentStartDate,
    reservePercentage,
    paymentTypes,
  } = quotation;

  const startDate = showEditableButtons
    ? paymentStartDate
    : proposalPaymentStartDate;

  const onChangeValue = (event) => {
    if (Number(event.target.value) !== Number(reservePercentage)) {
      onChange(event);
      reserveHandler(event.target.name, event.target.value);
    }
  };

  const typeId =
    paymentTypes && paymentTypes.length > 0 ? paymentTypes[0] : '0';

  return (
    <CustomPaymentTableRow isEditing={editMode}>
      <TableCell>
        <Typography>Separación</Typography>
      </TableCell>

      {showEditableButtons && (
        <CustomPaymentTableCell isEditing={editMode}>
          <Typography>
            <QuotationPaymentTypeRow
              key={`QuotationPaymentTypeRow_${0}_${typeId}`}
              index={0}
              typeId={typeId}
              quotation={quotation}
              dispatchFetchQuotationSuccess={dispatchFetchQuotationSuccess}
            />
          </Typography>
        </CustomPaymentTableCell>
      )}

      <TableCell>
        {editMode && showEditableButtons && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              allowKeyboardControl={false}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline-reservation-deposit"
              label="Fecha reserv"
              minDate={moment(Number(startDate)).startOf('M')}
              maxDate={moment(Number(startDate)).endOf('M')}
              value={moment(Number(startDate))}
              onChange={(date) => reserveHandler('paymentStartDate', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        )}

        {(!editMode || !showEditableButtons) &&
          moment(Number(startDate))
            .format(DEFAULT_DATE_FORMAT)
            .toString()}
      </TableCell>
      <TableCell>
        <EditableCell
          name="reservePercentage"
          value={reservePercentage}
          showEditableButtons={showEditableButtons}
          onChange={onChangeValue}
          onBlur={() => {
            if (discountType === QuotationDiscountType.ASSIGNED.code)
              onClickDiscount();
          }}
          onEnter={() => {
            if (discountType === QuotationDiscountType.ASSIGNED.code)
              onClickDiscount();
          }}
        />
      </TableCell>
      {editMode && enableDiscountCalculation && (
        <TableCell>
          <Radio
            disabled={isLockedType}
            checked={quotation.currentIndexForDiscount === 0}
            onChange={() => {
              updateDistributionIndex(0);
            }}
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        </TableCell>
      )}
    </CustomPaymentTableRow>
  );
};

ReservationDeposit.propTypes = {
  quotation: PropTypes.shape({
    paymentStartDate: PropTypes.any,
    reservePercentage: PropTypes.any,
    currentIndexForDiscount: PropTypes.any,
    paymentTypes: PropTypes.array,
    proposalPaymentStartDate: PropTypes.any,
  }).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  dispatchFetchQuotationSuccess: PropTypes.func.isRequired,
};

export default ReservationDeposit;
