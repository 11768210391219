import AsyncConfirmModal from 'shared/components/AsyncModal/AsyncConfirmModal';
import { ElementRef, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useBoolean from 'shared/customHooks/useBoolean';
import useCustomPopover from 'shared/customHooks/useCustomPopover';
import { GeneralWalletDetailsService } from '../../services/GeneralWalletDetailsService';
import { GENERAL_WALLET_THREADS } from '../constants/GeneralWalletDetailsReactQuery';
import { GeneralWalletThreadDTO, GeneralWalletThreadInteractionDTO } from '../DTO/GeneralWalletComment.dto';

export interface UseGeneralWalletThreadContextProps {
  thread: GeneralWalletThreadDTO;
}

type AsyncConfirmModalRefType = ElementRef<typeof AsyncConfirmModal>;

const service = new GeneralWalletDetailsService();

const useGeneralWalletThread = (props: UseGeneralWalletThreadContextProps) => {
  const queryClient = useQueryClient();
  const removeInteractionMutation = useMutation(
    (interaction: GeneralWalletThreadInteractionDTO) => service.removeInteractionComment(interaction.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GENERAL_WALLET_THREADS]);
      },
    },
  );
  const [interaction, setInteraction] = useState<GeneralWalletThreadInteractionDTO>();
  const { elementRef, handlePopoverOpen } = useCustomPopover<GeneralWalletThreadInteractionDTO>();
  const alertRef = useRef<AsyncConfirmModalRefType>(null);
  const { value: isEditing, setValue: setEditing } = useBoolean();

  const handleClickedEditInteraction = (interaction: GeneralWalletThreadInteractionDTO) => {
    setInteraction(interaction);
    setEditing(true);
  };

  const closeEditInteraction = () => {
    setInteraction(undefined);
    setEditing(false);
  };

  const onClickConfirmRemoveInteraction = async (interaction: GeneralWalletThreadInteractionDTO) =>
    alertRef.current?.show(() => removeInteractionMutation.mutate(interaction));

  return {
    elementRef,
    interaction,
    isEditing,
    alertRef,
    handlePopoverOpen,
    onClickConfirmRemoveInteraction,
    handleClickedEditInteraction,
    closeEditInteraction,
  };
};

export default useGeneralWalletThread;
