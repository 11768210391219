import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Services from '../Services/SalesReportOneServices';

const services = new Services();

export default function useGroupGoals(
  id,
  name,
  rowName,
  totalGoals,
  handleChangeReport,
  properties,
) {
  const [editable, setEditable] = useState(false);
  const [goalField, setGoalField] = useState(name);
  const [tempGoal, setTempGoal] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeToEditable = () => {
    if (rowName === 'Total aptos') {
      setEditable(false);
    } else {
      setEditable(!editable);
    }
  };

  const cancelOperation = () => {
    setGoalField(0);
    handleChangeToEditable();
  };

  const handleChangeReportValue = (e) => {
    const value = e.floatValue;
    setTempGoal(value);
  };

  const validationCheck = (
    prevGoal,
    currentGoal,
    totalGoal,
    propertiesSize,
  ) => {
    const estimateGoal = totalGoal === 0 ? prevGoal : totalGoal - prevGoal;
    return {
      newTotal: totalGoal === 0 ? currentGoal : estimateGoal + currentGoal,
      validation: currentGoal <= propertiesSize,
    };
  };

  const sendGoal = async () => {
    try {
      const { newTotal, validation } = validationCheck(
        goalField,
        tempGoal,
        totalGoals,
        properties,
      );
      if (!validation) {
        enqueueSnackbar('Las metas superan el número de apartamentos', {
          variant: 'error',
        });
      } else {
        await services.setGroupGoal({ id, goal: tempGoal });
        setGoalField(tempGoal);
        setTempGoal(0);
        setEditable(false);
        handleChangeReport(newTotal);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    editable,
    handleChangeToEditable,
    goalField,
    cancelOperation,
    handleChangeReportValue,
    sendGoal,
  };
}
