import React from 'react';
import { Typography } from '@material-ui/core';
import MonetizationIcon from '@material-ui/icons/MonetizationOnRounded';
import { CustomBoxForState } from '../StyledBuyerCustomer';
import RealPaymentsForPropertyMainView from '../../../../../RealPaymentsForProperty/UI/RealPaymentsForPropertyMainView';
import useBuyerCustomerPaymentPlanState from '../../../../Core/customHooks/useBuyerCustomerPaymentPlanState';
import useBuyerPropertyDetailWithQuotationsStates from '../../../../Core/customHooks/useBuyerPropertyDetailWithQuotationsStates';
import useBuyerCustomerPaymentsState from '../../../../Core/customHooks/useBuyerCustomerPaymentsState';

import { Button } from '../../../../../../shared/components/Button/Button';

const BuyerCustomerPaymentsState = () => {
  const { currentClient } = useBuyerPropertyDetailWithQuotationsStates();
  const { label, isComplete, isInArrears } = useBuyerCustomerPaymentsState(
    currentClient || {},
  );
  const { isOpen, onClick } = useBuyerCustomerPaymentPlanState();

  return (
    currentClient && (
      <>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          startIcon={<MonetizationIcon />}
          onClick={onClick(true)}
        >
          PAGOS
        </Button>

        <CustomBoxForState
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={0.5}
          mt={1.5}
          currentState={
            isComplete ? 'completed' : isInArrears ? 'isArreas' : 'missing'
          }
        >
          <Typography variant="body">{label}</Typography>
        </CustomBoxForState>
        <RealPaymentsForPropertyMainView
          propertyId={currentClient.propertyId}
          clientId={currentClient.clientId}
          isOpen={isOpen}
          onCloseHandler={onClick(false)}
        />
      </>
    )
  );
};

export default BuyerCustomerPaymentsState;
