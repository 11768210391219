import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const Edit = ({ editableToggleModeHandler, isEditing }) => {
  const classes = useStyles();
  return (
    <Fab
      color="primary"
      className={classes.fab}
      variant="extended"
      onClick={editableToggleModeHandler}
    >
      {isEditing ? (
        <SaveIcon className={classes.extendedIcon} />
      ) : (
        <EditIcon className={classes.extendedIcon} />
      )}
      {isEditing ? 'Guardar' : 'Editar pagos'}
    </Fab>
  );
};

Edit.propTypes = {
  editableToggleModeHandler: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default Edit;
