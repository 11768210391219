import moment from 'moment';

export const quotationMonthDate = (
  index,
  paymentStartDate,
  initialDate,
  shouldStartFromInitialDate,
) => {
  const startDate = moment(Number(paymentStartDate));
  let currentMonth = startDate.clone().add(index + 1, 'M');

  if (initialDate && shouldStartFromInitialDate) {
    const lastMonthDay = currentMonth.clone().endOf('M').date();
    if (moment(Number(initialDate)).date() > lastMonthDay) {
      currentMonth.set('date', lastMonthDay);
    } else {
      currentMonth.set('date', moment(Number(initialDate)).date());
    }
  }

  const startDateIsLastDay = currentMonth
    .clone()
    .endOf('M')
    .isSame(currentMonth, 'day');
  if (startDateIsLastDay) {
    currentMonth = currentMonth.clone().endOf('M');
  }
  return currentMonth;
};

function useQuotationMonthDate(
  index,
  paymentStartDate,
  initialDate,
  showEditableButtons,
) {
  const currentMonth = quotationMonthDate(
    index,
    paymentStartDate,
    initialDate,
    showEditableButtons,
  );
  return { currentMonth };
}

export default useQuotationMonthDate;
