import useCRMList from 'App/CRM/customHooks/useCRMList';
import { useEffect, useState } from 'react';
import { useForm } from 'shared/customHooks/useForm';
import { ClientCompanyProps } from '../interfaces/ClientCompanyProps';
import { ClientCompanyModel } from '../models/ClientCompanyModel';

interface Question {
  id: number;
  value: string;
  isHubspot: boolean;
  type: 'QUESTION_1';
}

const useHowDidFindAboutProject = ({ model: clientCompany, onChangeModel }: ClientCompanyProps) => {
  const { questionsOne } = useCRMList();

  const form = useForm<ClientCompanyModel>(
    {
      validations: {
        howDidFindAboutProjectId: {
          required: {
            value: true,
            message: 'La respuesta es requerida',
          },
        },
      },
      initialValues: {
        howDidFindAboutProjectId: clientCompany?.howDidFindAboutProjectId || 0,
      },
      onSubmit: async (values) => {
        onChangeModel(values);
      },
    },
    [clientCompany],
  );

  return { ...form, questionsOne: questionsOne as Question[] };
};

export default useHowDidFindAboutProject;
