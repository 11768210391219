import React from 'react';

import Typography from 'shared/components/Typography/Typography';
import SalesSpeedHelperStates from '../../../core/enums/SalesSpeedHelperStates';
import SalesSpeedHelperErrorIcon from '../SalesSpeedHelperErrorIcon/SalesSpeedHelperErrorIcon';

import styles from './SalesSpeedHelperSidenavGlobalValue.module.scss';

type GlobalValue = number | string;

const isLoading = (viewStates: SalesSpeedHelperStates) => viewStates === SalesSpeedHelperStates.Loading;

const hasError = (viewStates: SalesSpeedHelperStates, value?: GlobalValue) => !isLoading(viewStates) && !value;

type Props = {
  className?: string;
  viewState: SalesSpeedHelperStates;
  title: string;
  value?: GlobalValue;
};

const SalesSpeedHelperSidenavGlobalValue: React.FC<Props> = ({ className, viewState, title, value }) => (
  <div className={className}>
    <div className={styles.title}>
      <Typography variant="body1" as="div">
        {title}
      </Typography>
      <SalesSpeedHelperErrorIcon isError={hasError(viewState, value)} />
    </div>
    <Typography className={styles.value} variant="headline6" as="div" color="gray700">
      {value}
    </Typography>
  </div>
);

export default SalesSpeedHelperSidenavGlobalValue;
