import React from 'react';
import { PropTypes } from 'prop-types';
import useClientAddressTypeSelector from '../../Core/customHooks/useClientAddressTypeSelector';
import ClientAddressModal from './ClientAddressModal';
import useClientAddressUpdaterForOffice from '../../Core/customHooks/useClientAddressUpdaterForOffice';
import useClientAddressLoaderForOffice from '../../Core/customHooks/useClientAddressLoaderForOffice';

const ClientAddressModalForOffice = ({ isOpen, onClose }) => {
  const { currentType, onChangeType } = useClientAddressTypeSelector(true);
  const {
    update,
    clientAddress,
    addressDescription,
    selectedClient,
  } = useClientAddressUpdaterForOffice(currentType);
  const { onAccept } = useClientAddressLoaderForOffice(
    onClose,
    clientAddress,
    selectedClient,
    addressDescription,
    currentType,
  );

  return (
    <>
      <ClientAddressModal
        isOpen={isOpen}
        onClose={onClose}
        addressDescription={addressDescription}
        onChangeType={onChangeType}
        currentType={currentType}
        update={update}
        clientAddress={clientAddress}
        onAccept={onAccept}
      />
    </>
  );
};

ClientAddressModalForOffice.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
};

export default ClientAddressModalForOffice;
