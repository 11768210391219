import { Column } from '@devexpress/dx-react-grid';
import { ChangeSalesStatusHandler } from 'App/SalesStatus/core/types/SalesStatus.types';
import clsx from 'clsx';
import React, { FocusEventHandler } from 'react';

import Switch from 'shared/components/Switch/Switch';
import Typography from 'shared/components/Typography/Typography';
import useHandleSalesStatusSwitch from '../../../core/customHooks/useHandleSalesStatusSwitch';
import { SalesStatusProperty } from '../../../core/DTO/SalesStatusViewDTO';

import styles from './SalesStatusCell.module.scss';

const getIsAvailableClass = (sold: boolean) => ({
  [styles.isAvailable]: !sold,
});

const AVAILABLE_LABEL = 'Disponible';
const SOLD_LABEL = 'Vendido';

const getLabel = (sold: boolean) => (sold ? SOLD_LABEL : AVAILABLE_LABEL);

export type SalesStatusCellProps = {
  column: Column;
  row: SalesStatusProperty;
  value: boolean;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: () => void;
  onChange: ChangeSalesStatusHandler;
};

const SalesStatusCell: React.FC<SalesStatusCellProps> = ({ row, value, onChange }) => {
  const { isSold, handleSwitchChange } = useHandleSalesStatusSwitch({
    cellValue: value,
    row,
    onChange,
  });

  return (
    <div className={clsx(styles.root, getIsAvailableClass(isSold))}>
      <Typography className={styles.label} variant="body1" color="gray600">
        {getLabel(isSold)}
      </Typography>
      <Switch className={styles.switch} size="small" color="primary" checked={isSold} onChange={handleSwitchChange} />
    </div>
  );
};

export default SalesStatusCell;
