import { Column } from '@devexpress/dx-react-grid';
import React, { useEffect, useState } from 'react';
import { Numbers } from '../../../../helpers';
import { TotalSalesByEvalproSalesGroup } from '../../../SalesEvalpro/Core/utils/TotalSalesByEvalproSalesGroup';
import useEvalproDeedExpensesDeedMonthsAPI from './useEvalproDeedExpensesDeedMonthsAPI';

const defaultColumns: Column[] = [
  {
    title: 'Meses de escrituración',
    name: 'month',
  },
  {
    title: 'Fechas de escrituración',
    name: 'date',
  },
  {
    title: 'Unidades a escriturar',
    name: 'stock',
  },
  {
    title: 'Valores de escrituración',
    name: 'deedValue',
  },
];

const useEvalproDeedExpensesDeedMonthsTable = (towerId: string) => {
  const { data, isFetching } = useEvalproDeedExpensesDeedMonthsAPI(towerId);
  const [columns] = useState<Column[]>(defaultColumns);
  const [deedValue, setDeedValue] = useState<number>(0);
  const [totalDeedValue, setTotalDeedValue] = useState<number>(0);
  const [unitsError, setErrorForUnits] = useState<boolean>(false);

  useEffect(() => {
    if (data?.evalproGroup) {
      const units = data.evalproGroup.units;
      const totalSales = +TotalSalesByEvalproSalesGroup(data.evalproGroup);
      const deedExpensesValue = (totalSales * 70) / 100;
      const valueByUnit = +Numbers.toFixed(deedExpensesValue / (units ?? 1));
      setDeedValue(deedExpensesValue);

      if (data?.months && data.months.length) {
        const totalDeedValueResult = data.months.reduce(
          (prev, month) => {
            const totalMonthStockValue = +Numbers.toFixed(month.stock * valueByUnit);
            return {
              totalMonthStockValue: prev.totalMonthStockValue + totalMonthStockValue,
              totalOfStock: prev.totalOfStock + month.stock,
            };
          },
          { totalMonthStockValue: 0, totalOfStock: 0 },
        );

        setTotalDeedValue(Math.floor(totalDeedValueResult.totalMonthStockValue));
        setErrorForUnits(totalDeedValueResult.totalOfStock < units);
      }
    }
  }, [data]);

  const onRemoveSnack: React.MouseEventHandler<HTMLButtonElement> = () => setErrorForUnits(false);

  return {
    columns,
    data,
    isLoading: isFetching,
    deedValue,
    totalDeedValue,
    unitsError,
    onRemoveSnack,
  };
};

export default useEvalproDeedExpensesDeedMonthsTable;
