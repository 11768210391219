import React from 'react';
import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import DoneRounded from '@material-ui/icons/DoneRounded';
import CloseRounded from '@material-ui/icons/CloseRounded';
import styles from './propertyEditionRow.module.scss';
import Status from '../../../../../helpers/status';
import useUpdatePropertyPrice from '../../../Core/customHooks/useUpdatePropertyPrice';

function PropertyEditionRow({ property, isLoading, updateLoading }) {
  const {
    isEditing,
    customProperty,
    onSave,
    editionToggle,
    onChangePrice,
    onCancel,
  } = useUpdatePropertyPrice(property, isLoading, updateLoading);

  const colorStatus = (status) => {
    switch (status) {
      case Status.Optional:
        return styles.YellowHelper;
      case Status.Available:
        return styles.GreenHelper;
      default:
        return styles.BlueHelper;
    }
  };

  return (
    <TableRow>
      <TableCell align="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className={colorStatus(property.status)} />
        </Box>
      </TableCell>
      <TableCell align="center">{property.name}</TableCell>
      <TableCell>{property.groupName}</TableCell>
      <TableCell>{`Piso #${property.floor}`}</TableCell>
      <TableCell>
        <NumberFormat
          customInput={TextField}
          prefix="$"
          isNumberString
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          value={customProperty.priceWithIncrement}
          disabled={!isEditing}
          onValueChange={onChangePrice}
        />
      </TableCell>
      <TableCell align="center">
        {isEditing && (
          <>
            <IconButton color="primary" aria-label="Done" onClick={onSave}>
              <DoneRounded />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="Cancelar"
              onClick={onCancel}
            >
              <CloseRounded />
            </IconButton>
          </>
        )}
        {!isEditing && (
          <Button onClick={editionToggle} color="primary">
            Editar precio
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}

PropertyEditionRow.propTypes = {
  property: PropTypes.object,
  isLoading: PropTypes.bool,
  updateLoading: PropTypes.func,
};

export default PropertyEditionRow;
