import { useParams } from 'react-router-dom';
import useFindEvalproDeedExpensesConfig from './useFindEvalproDeedExpensesConfig';
import useUpdateEvalproDeedExpensesConfig from './useUpdateEvalproDeedExpensesConfig';

const useEvalproDeedExpenses = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const { config, schedule, isLoading } = useFindEvalproDeedExpensesConfig(towerId);
  const { fetch: fetchIsDeedExpensesAVG } = useUpdateEvalproDeedExpensesConfig(towerId, 'isDeedExpensesAVG');

  const toggleAVGDeedDate = () => fetchIsDeedExpensesAVG(!config?.isDeedExpensesAVG);

  return {
    towerId,
    config,
    schedule,
    isLoading,
    toggleAVGDeedDate,
  };
};

export default useEvalproDeedExpenses;
