import { Column, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { useEffect, useState } from 'react';

import FeatureFieldTable from 'App/FeatureField/core/FeatureFieldTable';
import { FeatureFieldsRepository } from 'App/FeatureField/core/interfaces/featureField.types';
import { baseColumns, tableColumnWidths, tableLeftColumns } from '../enums/table.enum';
import { ColumnsData } from '../interfaces/useGeneralWalletTableColumns.types';

const defaultColumnsData = {
  columns: [],
  leftColumns: [],
  columnWidths: [],
  featureFieldColumns: [],
  hiddenColumnNames: [],
};

const getColumns = (featureFieldsRepo: FeatureFieldsRepository): Column[] => [
  ...baseColumns,
  ...FeatureFieldTable.getColumns(featureFieldsRepo),
];

const getColumnWidths = (fieldsOrder: number[]): TableColumnWidthInfo[] => [
  ...tableColumnWidths,
  ...FeatureFieldTable.getColumnWidths(fieldsOrder),
];

const transformId = (id: number) => `${id}`;

const getFeatureFieldsColumns = (fieldsOrder: number[]): string[] => fieldsOrder.map(transformId);

const getColumnsData = (featureFieldsRepo: FeatureFieldsRepository): ColumnsData => ({
  columns: getColumns(featureFieldsRepo),
  leftColumns: tableLeftColumns,
  columnWidths: getColumnWidths(featureFieldsRepo.order),
  featureFieldColumns: getFeatureFieldsColumns(featureFieldsRepo.order),
  hiddenColumnNames: FeatureFieldTable.getHiddenColumns(featureFieldsRepo.data),
});

const updateColumnsWidths = (columnsData: ColumnsData, columnWidths: TableColumnWidthInfo[]): ColumnsData => ({
  ...columnsData,
  columnWidths,
});

function useGeneralWalletTableColumns(featureFieldsData) {
  const [columnsData, setColumnsData] = useState<ColumnsData>(defaultColumnsData);

  useEffect(() => {
    setColumnsData(getColumnsData(featureFieldsData));
  }, [featureFieldsData]);

  const handleColumnWidthsChange = (columnWidths: TableColumnWidthInfo[]) => {
    setColumnsData(updateColumnsWidths(columnsData, columnWidths));
  };

  return { columnsData, handleColumnWidthsChange };
}

export default useGeneralWalletTableColumns;
