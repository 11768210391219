import { Table } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import styled, { css } from 'styled-components';

import table from 'assets/styles/components/table.module.scss';
import colors from 'assets/styles/settings/colors.module.scss';
import fonts from 'assets/styles/settings/fonts.module.scss';

const tableCellMixin = css`
  /* Box-model */
  padding: ${table.cellPadding};

  /* Typography */
  color: ${colors.gray700};
  font-family: ${fonts.body};
  font-size: ${fonts['body1-font-size']};
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
`;

const StyledTableCell = styled(Table.Cell)<Table.DataCellProps>`
  ${tableCellMixin}
`;

const StyledTableCellSmall = styled(Table.Cell)<Table.DataCellProps>`
  ${tableCellMixin}
  padding: ${table.cellSmallPadding};
`;

const StyledTableCellSmallCenter = styled(Table.Cell)<Table.DataCellProps>`
  ${tableCellMixin}
  padding: ${table.cellSmallPadding};
  text-align: center;
`;

const TableCell: React.FC<Table.DataCellProps> = (props) => <StyledTableCell {...props} />;
const TableCellSmall: React.FC<Table.DataCellProps> = (props) => <StyledTableCellSmall {...props} />;
const TableCellSmallCenter: React.FC<Table.DataCellProps> = (props) => <StyledTableCellSmallCenter {...props} />;

export default TableCell;

export { tableCellMixin, TableCellSmall, TableCellSmallCenter };
