import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import QuotationsServices from '../../../../services/Quotations/Quotations.services';
import { validateMissingPrice } from '../../UI/Dialog/helpers/customHooks/useAssignmentQuotation';

function useSaveAssignedCopy(closeHandler, quotation) {
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const onSaveButtonClick = async () => {
    const services = new QuotationsServices();

    try {
      validateMissingPrice(quotation);
      await services.saveAssignedPayment(towerId, quotation);
      enqueueSnackbar('Se guardó correctamente', { variant: 'success' });
      closeHandler();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    onSaveButtonClick,
  };
}

export default useSaveAssignedCopy;
