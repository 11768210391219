import React, { useState, useEffect } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DeleteIcon from '@material-ui/icons/HighlightOffOutlined';
import UploadButton from './PropertyFileActions/UploadButton';
import Styles from './PropertyFiles.module.scss';
import ClientServices from '../../../../../services/client/ClientsServices';
import DeleteDialog from './Dialogs/deleteDialog';

const services = new ClientServices();

const PropertyFiles = ({ clientId, propertyId, propertyFiles }) => {
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState(null);
  const [isUploadingFile, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [id, setId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getPropertyFiles = async (clientId, propertyId) => {
    try {
      const response = await services.getPropertyFilesAndInfo(
        clientId,
        propertyId,
      );
      setFiles(response.data.propertyFile);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onChangeFileHandler = (event) => {
    if (event.target.files[0]) {
      setNewFile(event.target.files[0]);
    }
  };

  const onFileUpload = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', newFile);
      const res = await services.postPropertyFile(
        clientId,
        propertyId,
        formData,
      );
      setNewFile(null);
      setIsLoading(false);
      await getPropertyFiles(clientId, propertyId);
      enqueueSnackbar('Se adjuntó el archivo correctamente', {
        variant: 'success',
      });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onCancel = () => {
    setNewFile(null);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deletePropertyFile = async (propertyFileId, clientId, propertyId) => {
    await services.deletePropertyFile(propertyFileId);
    await getPropertyFiles(clientId, propertyId);
  };

  const downloadPropertyFile = async (propertyFile) => {
    try {
      const response = await services.downloadPropertyFile(propertyFile.id);
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = propertyFile.name;
      element.click();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setFiles(propertyFiles);
  }, [propertyFiles]);

  return (
    <Card>
      <CardHeader
        title="Archivos del cliente"
        titleTypographyProps={{
          className: Styles.cardHeader,
        }}
        action={
          <UploadButton
            fileName={newFile ? newFile.name : null}
            onChangeHandler={onChangeFileHandler}
            onCancel={onCancel}
            onAccept={onFileUpload}
            isUploadingFile={isUploadingFile}
            key={newFile ? newFile.name : ''}
          />
        }
      />
      <div className={Styles.filesContainer}>
        {files &&
          files.map((file) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <Card className={Styles.card}>
                <div className={Styles.file}>
                  <strong className={Styles.dataFile}>{file.name}</strong>
                </div>
                <div className={Styles.fileActions}>
                  <DeleteIcon
                    className={Styles.deleteIcon}
                    onClick={() => {
                      setId(file.id);
                      setOpenDeleteDialog(true);
                    }}
                  />
                  <DownloadIcon
                    className={Styles.downloadIcon}
                    onClick={() => {
                      downloadPropertyFile(file);
                    }}
                  />
                  <DeleteDialog
                    fileId={file.id}
                    clientId={file.clientId}
                    propertyId={file.propertyId}
                    openDialog={openDeleteDialog}
                    closeDeleteDialog={handleCloseDialog}
                    deleteFile={deletePropertyFile}
                    onAccept={async () => {
                      deletePropertyFile(id, file.clientId, file.propertyId);
                      handleCloseDialog();
                    }}
                  />
                </div>
              </Card>
            );
          })}
      </div>
    </Card>
  );
};

PropertyFiles.propTypes = {
  clientId: PropTypes.string,
  propertyId: PropTypes.number,
  propertyFiles: PropTypes.array,
};

export default PropertyFiles;
