import { useRef } from 'react';

const useUpdateAccordion = () => {
    const ref = useRef<HTMLButtonElement>(null);

    const handleClick = () => {
        if (ref.current) {
            const nodes = ref.current.querySelectorAll('button');
            for (let i = 0; i < nodes.length; i++) {
                const button = nodes[i] as HTMLButtonElement;
                button.click()
            }
        }
    }

    return {
        buttonRef: ref,
        handleClick
    }
}

export default useUpdateAccordion;