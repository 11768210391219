import { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import QuotationServices from '../../../../../../../../services/Quotations';
import DialogContext from '../../context';
import QuotationDiscountType from '../../../../../../../../App/Quotation/Core/enums/QuotationDiscountType';

const services = new QuotationServices();

const useQuotationFileUploader = () => {
  const [date, setDate] = useState(new Date());
  const { towerId } = useParams();
  const [isUploadingFile, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { quotation, updateQuotation, onClickDiscount } = useContext(
    DialogContext,
  );

  const description = {
    clientName: quotation.client.name,
    propertyName: quotation.property.name,
  };
  const { id } = quotation;

  const onChangeFileHandler = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const onCancel = () => {
    setFile(null);
  };

  const onFileUpload = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const res = await services.postTemplate(
        towerId,
        quotation.id,
        quotation.discountPrice,
        formData,
      );
      const newQuotation = res.data;
      const quotationUpdated = {
        ...quotation,
        ...newQuotation,
        isLockedType: false,
      };
      updateQuotation(quotationUpdated);
      setFile(null);
      enqueueSnackbar('Plantilla cargada correctamente', {
        variant: 'success',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      });
      setIsLoading(false);
      const isSameOrAfterCurrentMonth = moment(
        Number(quotationUpdated.paymentStartDate),
      ).isSameOrAfter(moment(), 'month');
      if (
        quotation.discountType === QuotationDiscountType.ASSIGNED.code &&
        isSameOrAfterCurrentMonth &&
        !quotation.isAssigned
      ) {
        onClickDiscount(quotationUpdated);
      }
    } catch (error) {
      setIsLoading(false);
      setFile(null);
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      });
    }
  };

  const onAccept = async () => {
    await onFileUpload();
  };

  const downloadTemplate = async () => {
    try {
      let name = 'Cotizar propertiedad.xlsx';
      const response = await services.getTemplate(
        towerId,
        id,
        date.getTime(),
        quotation.discountPrice,
      );
      const { clientName, propertyName } = description;
      if (clientName && propertyName) {
        name = `Cotizar propertiedad ${propertyName} para el cliente ${clientName}.xlsx`;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      });
    }
  };

  return {
    date,
    setDate,
    isUploadingFile,
    downloadTemplate,
    onChangeFileHandler,
    onAccept,
    onCancel,
    file,
  };
};

export default useQuotationFileUploader;
