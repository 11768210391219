import { useContext, useState } from "react";
import Context from "../../../Context";


export default function useUpdateStrategyGroups() {
  const { activateBadgeIncrement, isBadgeIncrement } = useContext(
    Context.Shared,
    );

  const activateBadgeIncrementWithGroups = (groups) => {
    const isStrategyReset = Object.keys(groups).reduce((validation, groupKey) => {
      if (groupKey !== 'Total' && !validation) {
        const currentGroup = groups[groupKey]
        return currentGroup && currentGroup.isReset && currentGroup.inventory.units > 1
      }
      return validation
    }, false)
    activateBadgeIncrement(isStrategyReset)
  }

  return { activateBadgeIncrementWithGroups, isBadge: isBadgeIncrement }
}