import { combineReducers } from 'redux';
import { UPDATE_PROPERTIES_WITH_PRICES } from './actions';
import { UPDATE_PROPERTIES_WITH_CHARACTERISTICS } from '../../GraphicMaterial/Core/actions';

const initialState = {
  isLoading: false,
  properties: [],
};

const mapSalesRoomData = (state, salesRoomData) => {
  return {
    ...state,
    properties: salesRoomData.incrementList.properties,
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROPERTIES_WITH_PRICES:
      return mapSalesRoomData(state, payload);

    case UPDATE_PROPERTIES_WITH_CHARACTERISTICS:
      return { ...state, properties: payload };

    default:
      return state;
  }
};

export default combineReducers({ root: reducer });
