import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';

import Agent from 'config/config';
import { Button } from 'shared/components/Button/Button';
import Icon from 'shared/components/Icon/Icon';
import IconButton from 'shared/components/IconButton/IconButton';
import Typography from 'shared/components/Typography/Typography';
import StagesDTO from '../../../core/DTO/StagesDTO';
import StagesOnboardingState from '../../../core/utils/StagesOnboardingState';
import StagesUserRole from '../../../core/utils/StagesUserRole';
import { AddStageHandler } from '../../../core/types/Stages.types';

import styles from './StagesHeader.module.scss';

const showNewStageButton = (userType: string, stages: StagesDTO) =>
  StagesUserRole.isSuperOrAdminUser(userType) && StagesOnboardingState.isDone(stages);

const getNewStageButton = (onAddStage: AddStageHandler) => (
  <Button className={styles.addStageButton} variant="contained" size="small" onClick={onAddStage}>
    <>
      <Icon className={styles.addStageIcon} variant="Add" width="1.25rem" height="1.25rem" />
      <span>Nueva Etapa</span>
    </>
  </Button>
);

type Props = {
  className?: string;
  projectName: string;
  stages: StagesDTO;
  onGoBack: MouseEventHandler<HTMLButtonElement>;
  onAddStage: AddStageHandler;
};

const StagesHeader: React.FC<Props> = ({ className, projectName, stages, onAddStage, onGoBack }) => (
  <div className={clsx(styles.root, className)}>
    <IconButton className={styles.goBackButton} iconName="ArrowBack" variant="ghost" size="small" onClick={onGoBack} />
    <Typography className={styles.title} as="h1" variant="headline4">
      Obra {projectName}
    </Typography>
    <div className={styles.space}></div>
    {showNewStageButton(Agent.currentUser.userType, stages) && getNewStageButton(onAddStage)}
  </div>
);

export default StagesHeader;
