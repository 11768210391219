import { useState } from 'react';
import { useSnackbar } from 'notistack';

import useContractors from './useContractors';
import { Contractor } from '../../../../models/Contractors';

export interface UseContractorParams {
  a: string;
}

export interface UseContractorResult {
  contractors: Contractor[];
  currentContractor?: Contractor;
  selectCurrentContractor: (contractor?: Contractor) => void;
  handleCreateContractor: (contractor: Contractor) => void;
  handleDeleteContractor: (contractor: Contractor) => void;
  handleUpdateContractor: (
    contractorId: string,
    contractor: Record<string, unknown>,
  ) => void;
  fetchContractors: () => Promise<void>;
}

type HookType = (param?: UseContractorParams) => UseContractorResult;

const useContractor: HookType = () => {
  const [contractors, setContractors, fetchContractors] = useContractors();
  const [currentContractor, setCurrentContractor] = useState<Contractor>();

  const { enqueueSnackbar } = useSnackbar();

  const selectCurrentContractor = (contractor?: Contractor): void => {
    setCurrentContractor(contractor);
  };

  const handleDeleteContractor = (contractor: Contractor): void => {
    const data = contractors.filter((item) => {
      return item.id !== contractor.id;
    });
    setContractors(data);
    enqueueSnackbar('Contratista eliminado con éxito', {
      variant: 'success',
    });
  };

  const handleCreateContractor = (contractor: Contractor): void => {
    setContractors(contractors.concat(contractor));
    enqueueSnackbar('Contratista creado con éxito', {
      variant: 'success',
    });
  };

  const handleUpdateContractor = (
    contractorId: string,
    contractorUpdated: Record<string, unknown>,
  ): void => {
    setContractors(
      contractors.map((contractor) => {
        if (contractor.id === contractorId)
          return {
            ...contractor,
            ...contractorUpdated,
          };
        return contractor;
      }),
    );
    enqueueSnackbar('Contratista actualizado con éxito', {
      variant: 'success',
    });
  };

  return {
    contractors,
    selectCurrentContractor,
    currentContractor,
    handleDeleteContractor,
    handleCreateContractor,
    handleUpdateContractor,
    fetchContractors,
  };
};

export default useContractor;
