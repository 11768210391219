import { useState } from 'react';
import { Column, IntegratedSummary, SummaryItem, Table } from '@devexpress/dx-react-grid';
import { QueryClient, useQueryClient } from 'react-query';

import { rightAlign, centerAlign } from '../../../../shared/tables/TableColumnFormat';
import { ContractPaymentScheduleFlow } from '../../../../models/ContractPayments';
import { IStateChip } from '../../../../shared/components/StateChip/StateChip';

const getColumns = (): Column[] => [
  {
    title: 'Fecha de pago',
    name: 'finalDate',
  },
  {
    title: 'Cuota',
    name: 'quota',
  },
  {
    title: 'Pago Extra',
    name: 'extra',
  },
  {
    title: 'Total',
    name: 'amountBudget',
  },
  {
    title: 'Total Facturado',
    name: 'amount',
  },
  {
    title: 'Estado',
    name: 'state',
    getCellValue: (row: ContractPaymentScheduleFlow): IStateChip => {
      const paymentState: IStateChip = {
        color: 'gray400',
        label: 'Proyectado',
      };

      if (row.state === 'INVOICED') {
        paymentState.color = 'primary200';
        paymentState.label = 'Real';
      }
      return paymentState;
    },
  },
];

type HookType = (contractId: number) => {
  rows: ContractPaymentScheduleFlow[] | undefined;
  columns: Column[];
  dateColumns: string[];
  currencyColumns: string[];
  chipColumns: string[];
  totalSummarySchedules: SummaryItem[];
  tableColumnExtensions: Table.ColumnExtension[];
  summaryCalculator: (
    type: string,
    rows: ContractPaymentScheduleFlow[],
    getValue: (row: ContractPaymentScheduleFlow) => ContractPaymentScheduleFlow,
  ) => number | string | undefined;
};

const useColumnsAndRowsForContractPaymentsSchedule: HookType = (contractId: number) => {
  const [columns] = useState(getColumns());
  const [dateColumns] = useState(['finalDate']);
  const [chipColumns] = useState(['state']);
  const [currencyColumns] = useState(['quota', 'extra', 'amount', 'amountBudget']);
  const [totalSummarySchedules] = useState([
    { columnName: 'finalDate', type: 'title' },
    { columnName: 'quota', type: 'sum' },
    { columnName: 'extra', type: 'sum' },
    { columnName: 'amount', type: 'sum' },
    { columnName: 'amountBudget', type: 'sum' },
  ]);

  const tableColumnFormat: Table.ColumnExtension[] = [
    { columnName: 'finalDate', align: centerAlign },
    { columnName: 'state', align: centerAlign, width: 150 },
    { columnName: 'quota', align: rightAlign },
    { columnName: 'extra', align: rightAlign },
    { columnName: 'amountBudget', align: rightAlign },
    { columnName: 'amount', align: rightAlign },
  ];

  const [tableColumnExtensions] = useState(tableColumnFormat);

  const queryClient: QueryClient = useQueryClient();

  const rows = queryClient.getQueryData<ContractPaymentScheduleFlow[]>(['resume-schedule-by-contract', contractId]);

  const summaryCalculator = (
    type: string,
    rows: ContractPaymentScheduleFlow[],
    getValue: (row: ContractPaymentScheduleFlow) => ContractPaymentScheduleFlow,
  ) => {
    if (type === 'title') {
      if (!rows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', rows, getValue);
  };

  return {
    rows,
    columns,
    currencyColumns,
    chipColumns,
    dateColumns,
    totalSummarySchedules,
    tableColumnExtensions,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractPaymentsSchedule;
