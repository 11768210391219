import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import StagesServices from '../../services/StagesServices';
import StageProjectDTO from '../DTO/StageProjectDTO';
import StagesViewStates from '../enums/StagesViewStates';

const services = new StagesServices();

const defaultStageProject = {
  id: '',
  name: '',
  stages: {
    records: {},
    order: [],
  },
};

type StagesParams = {
  projectId: string;
};

function useStagesLoader() {
  const { projectId } = useParams<StagesParams>();
  const [viewState, setViewState] = useState<StagesViewStates>(StagesViewStates.Loading);
  const [stageProject, setStageProject] = useState<StageProjectDTO>(defaultStageProject);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await services.getProjectStages(projectId);
        setStageProject(data);
        setViewState(StagesViewStates.Ready);
      } catch (error) {
        const message = (error as Error).message;
        enqueueSnackbar(message, { variant: 'error' });
        setViewState(StagesViewStates.Error);
      }
    };

    fetchData();
  }, []);

  return { viewState, stageProject };
}

export default useStagesLoader;
