const civilStatus = {
  MARRIED: { code: 'M', value: 'Casado(a) con Sociedad Conyugal D. y L.' },
  MARRIED_BUT_SEPARATED: {
    code: 'MARRIED_BUT_SEPARATED',
    value: 'Casado(a) pero separado de cuerpos y con sociedad conyugal vigente',
  },
  MARRIED_BUT_SEPARATED_D_AND_L: {
    code: 'MARRIED_BUT_SEPARATED_D_AND_L',
    value: 'Casado(a) pero separado de cuerpos y con sociedad conyugal D. y L.',
  },
  MARRIED_ASSOCIATED: {
    code: 'MARRIED_WITH_SOCIETY',
    value: 'Casado(a) con Sociedad Conyugal Vigente',
  },
  DIVORCED: { code: 'D', value: 'Divorciado(a) con Sociedad Conyugal D. y L.' },
  SINGLE: { code: 'S', value: 'Soltero(a) sin Union Marital de Hecho' },
  SINGLE_WITH_UNION: {
    code: 'SINGLE_WITH_UNION',
    value: 'Soltero(a) con Union Marital de Hecho',
  },
  FREE_UNION: {
    code: 'F',
    value:
      'Unión marital de hecho estabe durante un lapso no inferior a 2 años y sin vinculo anterior',
  },
  WIDOWED: { code: 'W', value: 'Viudo(a)' },
  WIDOWED_ASSOCIATED_D_AND_L: {
    code: 'W',
    value: 'Viudo(a) con sociedad conyugal D. y L.',
  },
  WIDOWED_ASSOCIATED: {
    code: 'W',
    value: 'Viudo(a) con sociedad conyugal D. y sin liquidar',
  },
};

export default civilStatus;
