import React from 'react';
import classes from '../../styles/CellContentForVirtualTableCell.module.scss';
import ReactTooltip from 'react-tooltip';
import { Typography } from '@material-ui/core';
import Numbers from '../../../../../helpers/numbers';

const CellContentForVirtualTableCell = ({ row, name, value, divider, accumulatedObject }) => {

  const classNameForGroup = [
    (row.id === 'OPERATING-FLOW' || row.id === 'MONTH_BALANCED_ID') ? classes.boldRows : '',
  ]

  if (row.id === 'ACCUMULATED_ID') {
    return (
      <span>
        <Typography
          data-tip
          data-for={`typographyToolTip ${row.name} ${name}`}
          className={classNameForGroup}
        >
          {name === 'name' ? value / divider : Numbers.currencyFormat(value / divider, true, 1)}
        </Typography>

        <ReactTooltip
          type="ligth"
          id={`typographyToolTip ${row.name} ${name}`}
          effect="solid"
          className={classes.tooltip}
        >
          <Typography className={classes.text}>
            {`Valor en pesos:`}
          </Typography>

          <Typography className={classes.values}>
            {`${name === 'name' ? value : Numbers.currencyFormat(value)}`}
          </Typography>
        </ReactTooltip>
      </span>
    )
  }

  const generalColumnsvalidation = name === "accumulated" || name === "total" || name === "projected"

  if (row.id === 'investmentBalance') {
    return (
      <span>
        <Typography
          data-tip
          data-for={`typographyToolTip ${row.name} ${name}`}
          className={classNameForGroup}
        >
          {name === 'name' ? accumulatedObject / divider : Numbers.currencyFormat(accumulatedObject / divider, true, 1)}
        </Typography>

        <ReactTooltip
          type="ligth"
          id={`typographyToolTip ${row.name} ${name}`}
          effect="solid"
          className={classes.tooltip}
        >
          {generalColumnsvalidation &&
            <Typography className={classes.text}>
              {`Valor en pesos:`}
            </Typography>
          }

          <Typography className={classes.values}>
            {`${name === 'name' ? value : Numbers.currencyFormat(value)}`}
          </Typography>

          {!generalColumnsvalidation && (
            <>
              <Typography className={classes.text}>
                {`Acumulado:`}
              </Typography>

              <Typography className={classes.values}>
                {`${accumulatedObject && Numbers.currencyFormat(accumulatedObject)}`}
              </Typography>
            </>
          )}
        </ReactTooltip>
      </span>
    )
  }

  return (
    <span>
      <Typography
        data-tip
        data-for={`typographyToolTip ${row.name} ${name}`}
        className={classNameForGroup}
      >
        {name === 'name' ? value / divider : Numbers.currencyFormat(value / divider, true, 1)}
      </Typography>

      <ReactTooltip
        type="ligth"
        id={`typographyToolTip ${row.name} ${name}`}
        effect="solid"
        className={classes.tooltip}
      >
        {generalColumnsvalidation &&
          <Typography className={classes.text}>
            {`Valor en pesos:`}
          </Typography>
        }

        <Typography className={classes.values}>
          {`${name === 'name' ? value : Numbers.currencyFormat(value)}`}
        </Typography>

        {!generalColumnsvalidation && (
          <>
            <Typography className={classes.text}>
              {`Acumulado:`}
            </Typography>

            <Typography className={classes.values}>
              {`${accumulatedObject && Numbers.currencyFormat(accumulatedObject)}`}
            </Typography>
          </>
        )}
      </ReactTooltip>
    </span>
  )
}

export default CellContentForVirtualTableCell