import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddCircleIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './Projects.module.scss';
import agent from '../../config/config';
import { Role } from '../../helpers/role';
import { Link as RouterLink } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
import { DashboardRoutes } from '../../routes/local/routes';
import CustomButton from '../Buttons/CustomButton';
import CustomTypography from '../Typography/CustomTypography';
import CustomIcon from '../Icons/CustomIcons';

const ProjectItems = (props) => {
  const [projectDivWidth, setProjectDivWidth] = useState(0);

  const onRefChange = useCallback((node) => {
    if (node) {
      const width = Math.floor(node?.clientWidth / 320) * 320;
      setProjectDivWidth(width);
    }
  }, []);

  const itemFromProject = (project) => {
    return (
      <Card
        classes={{ root: styles.ItemContainer }}
        key={project.id}
        onClick={(event) => {
          event.stopPropagation();
          if (window.event.ctrlKey) {
            props.openCtrlProject(project.id);
          } else {
            props.openProject(project.id);
          }
        }}
      >
        <div className={styles.DescriptionItem}>
          <div className={styles.Buttons}>
            <div
              className={styles.Remove}
              onClick={(event) => {
                event.stopPropagation();
                props.editHandler(project.id);
              }}
            >
              {!agent.isAuthorized([Role.Sales, Role.Wallet]) && (
                <CustomIcon variant="edit" />
              )}
            </div>
            <div
              className={styles.Remove}
              onClick={(event) => {
                event.stopPropagation();
                props.removeProject(project.id);
              }}
            >
              {!agent.isAuthorized([Role.Sales, Role.Wallet]) && (
                <CustomIcon variant="delete" />
              )}
            </div>
          </div>
          <div>
            <CustomTypography variant="subtitle" color="black">
              <p className={styles.projectName}>{project.name}</p>
            </CustomTypography>
          </div>
        </div>
        <div className={styles.Item}>
          <div className={styles.Description}>
            <CustomTypography variant="description" color="gray">
              <p>{project.description}</p>
            </CustomTypography>
          </div>
        </div>
      </Card>
    );
  };

  const items = (projects, companyId) => {
    const filterByCompany = projects.filter(
      (element) => element.companyId === companyId,
    );
    return _.orderBy(
      filterByCompany,
      [(project) => project.name.toLowerCase()],
      ['asc'],
    ).map((project) => {
      return itemFromProject(project);
    });
  };

  return (
    <Card className={styles.projectsContainer}>
      <div className={styles.headerContainer}>
        <CustomTypography variant="title" color="white">
          Proyectos
        </CustomTypography>
        <div className={styles.rightMenuOptions}>
          {!agent.isAuthorized([Role.Sales]) && (
            <MaterialLink
              component={RouterLink}
              to={DashboardRoutes.base + DashboardRoutes.companySchedules}
            >
              <CustomButton type="secondary">
                Ver mapa de proyectos
              </CustomButton>
            </MaterialLink>
          )}
          <CustomButton
            type="secondary"
            onClick={() => props.showModalSelectCompany()}
          >
            Seleccionar compañia
          </CustomButton>
          {agent.isAuthorized([Role.Super, Role.Admin]) && (
            <div>
              <Tooltip title="Crear proyecto" aria-label="add" placement="left">
                <Fab
                  color="secondary"
                  aria-label="add"
                  onClick={props.createProject}
                  size="medium"
                >
                  <AddCircleIcon className={styles.addButton} />
                </Fab>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <CardContent className={styles.cardContent}>
        <div
          ref={onRefChange}
          className={styles.Projects}
          style={{
            width: projectDivWidth === 0 ? '100%' : `${projectDivWidth}px`,
          }}
        >
          {items(props.projects, props.company)}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectItems;
