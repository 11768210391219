import React, { FC } from 'react';
import {
  Grid,
  TableHeaderRow,
  TableSummaryRow,
  Table,
  TableInlineCellEditing,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { EditingCell, EditingState, IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import TableHeaderRowCell from '../../../../shared/tables/TableHeaderRowCell';
import TableCell from '../../../../shared/tables/TableCell';

import useColumnsAndRowsForContractsEvalproDetailRange from '../../core/customHooks/useColumnsAndRowsForContractsEvalproDetailRange';
import { EvalproGroupDto } from '../../core/DTO/ContractsEvalpro.dto';
import TableSummaryRowCell from '../../../../shared/tables/TableSummaryRowCell';
import ContractsEvalproGridPercentEditProvider from './ContractsEvalproGridPercentEditProvider';
import ContractsEvalproGridNumberProvider from './ContractsEvalproGridNumberProvider';
import ContractsEvalproGridSummaryItemComponent from './ContractsEvalproGridSummaryItemComponent';
import ContractsEvalproGridDetailToolbar from './ContractsEvalproGridDetailToolbar';
import ContractsEvalproGridDetailToolbarRoot from './ContractsEvalproGridDetailToolbarRoot';
import ContractsEvalproDetailGridContainer from './ContractsEvalproDetailGridContainer';
import { EvalproDetailDto } from 'App/ContractsEvalpro/core/DTO';

interface IContractsEvalproDetailRangeGrid {
  rows: EvalproDetailDto[];
  eventTitle: string;
  eventId: number;
  isLoading: boolean;
}

const ContractsEvalproDetailRangeGrid: FC<IContractsEvalproDetailRangeGrid> = ({
  rows,
  eventId,
  eventTitle,
  isLoading,
}) => {
  const { columns, totalSummaryItems, editingStateColumnExtensions, commitChanges, summaryCalculator } =
    useColumnsAndRowsForContractsEvalproDetailRange({ eventId });

  const [editingCells, setEditingCells] = React.useState<EditingCell[]>([]);
  const [rowChanges, setRowChanges] = React.useState({});

  const getRowId = (row: EvalproGroupDto) => row.contractCategory.id;

  return (
    <>
      {isLoading ? (
        <AbsoluteCircularProgress />
      ) : (
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <ContractsEvalproGridNumberProvider for={['total']} />
          <ContractsEvalproGridPercentEditProvider for={['percentage']} />
          <EditingState
            columnExtensions={editingStateColumnExtensions}
            editingCells={editingCells}
            onEditingCellsChange={setEditingCells}
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            onCommitChanges={commitChanges}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary calculator={summaryCalculator} />
          <Table
            columnExtensions={columns}
            containerComponent={ContractsEvalproDetailGridContainer}
            cellComponent={TableCell}
          />
          <TableHeaderRow cellComponent={TableHeaderRowCell} />
          <Toolbar rootComponent={ContractsEvalproGridDetailToolbarRoot} />
          <ContractsEvalproGridDetailToolbar eventTitle={eventTitle} />
          <TableInlineCellEditing startEditAction="click" />
          <TableSummaryRow
            itemComponent={ContractsEvalproGridSummaryItemComponent}
            totalCellComponent={TableSummaryRowCell}
          />
        </Grid>
      )}
    </>
  );
};

export default ContractsEvalproDetailRangeGrid;
