import React, { FC } from 'react';
import Typography from '../../../shared/components/Typography/Typography';
import Styles from './SoldReportForExternalResourceMain.module.scss';
import DownloadButton from '../../../components/UI/DownloadButton/DownloadButton';
import UploadButton from '../../BusinessPartners/UI/Views/Components/UploadButton';
import useSoldReportForExternalResourceDownload from '../Core/customHooks/useSoldReportForExternalResourceDownload';
import SoldReportForExternalResourceTable from './tables/SoldReportForExternalResourceTable';
import GetAppOutlined from '@material-ui/icons/GetAppOutlined';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import AreaButton from '../../Area/UI/actions/AreaButton';
import useSoldReportForExternalResourceUpload from '../Core/customHooks/useSoldReportForExternalResourceUpload';
import { QueryClientProvider } from 'react-query';
import { SoldReportForERQueryClient } from '../Core/ReactQuery/SoldReportForERQueryClient';
import SoldReportForERContainer from './views/SoldReportForERContainer';

const SoldReportForExternalResourceMain: FC = () => {
  return (
    <QueryClientProvider client={SoldReportForERQueryClient}>
      <SoldReportForERContainer />
    </QueryClientProvider>
  );
};

export default SoldReportForExternalResourceMain;
