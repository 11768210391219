import { useState, useEffect } from 'react';

import SubMenuTypes from '../enums/SubMenuTypes';
import { ControlClickHandler } from '../types/sidebar.types';
import { useLocalStorage } from '../../../shared/customHooks/localStorage/useLocalStorage';
import LocalStorageEnum from '../../../shared/customHooks/localStorage/LocalStorage.enum';

function useSideMenuControl() {
  const [subMenuType, setSubMenuType] = useState<string>(SubMenuTypes.Execution);

  const {storedValue, setValue} = useLocalStorage(LocalStorageEnum.externalSourceMenu, '')

  useEffect(() => {
    if (storedValue) {
      setSubMenuType(storedValue)
    } 
  }, [])

  const handleControlClick: ControlClickHandler = (event) => {    
    setSubMenuType(event.currentTarget.dataset.index);
    setValue(event.currentTarget.dataset.index)
  };

  return { subMenuType, handleControlClick };
}

export default useSideMenuControl;
