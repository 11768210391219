import moment from 'moment';
import { MonthlyPaymentForReport, MonthlyPaymentRow } from '../DTO/WalletMonthlyReportDTO';
import { DEFAULT_DATE_FORMAT } from '../../../../helpers/dates';
import GeneralWalletDetailsServiceDefinitions from 'App/GeneralWalletDetails/services/GeneralWalletDetailsServiceDefinitions';
import { GeneralWalletDetailsService } from '../../../GeneralWalletDetails/services/GeneralWalletDetailsService';

export type MonthlyInterestOnValueChange = (row: MonthlyPaymentRow) => void;

function useMonthlyInterestValueChanged(updateRows: (rows: MonthlyPaymentRow[]) => void, propertyId: number) {
  const updateThroughService = async (
    oldRows: MonthlyPaymentRow[],
    isChecked: boolean,
    propertyId: number,
    rowId: string,
  ) => {
    try {
      const services = new GeneralWalletDetailsService();
      await services.updateReport(isChecked, propertyId, rowId);
    } catch (error) {
      updateRows(oldRows);
    }
  };

  const updateCheckRowByIndex = (rows: MonthlyPaymentRow[], index: number, newValue: boolean) => {
    const updatedRows = [...rows];
    updatedRows[index].financialAppliedValue.isChecked = newValue;
    return updatedRows;
  };

  const commitChanges = (row: MonthlyPaymentRow, rows: MonthlyPaymentRow[]) => {
    const indexToUpdate = rows.findIndex((current) => current.id === row.id);

    const toggleChecked = !rows[indexToUpdate].financialAppliedValue.isChecked;
    let updatedRows = updateCheckRowByIndex(rows, indexToUpdate, toggleChecked);

    updateRows(updatedRows);

    updateThroughService(rows, toggleChecked, propertyId, row.id);
  };

  return { commitChanges };
}

export default useMonthlyInterestValueChanged;
