import React, { FC } from 'react';

import {
  Dialog,
  FormControl,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  InputAdornment,
  DialogTitle,
} from '@material-ui/core';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { DateRangeOutlined } from '@material-ui/icons';
import Typography from '../../../shared/components/Typography/Typography';
import { Button } from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';
import TextField from '../../../shared/components/TextField/TextField';

import Styles from './styles/ContractLotFormMainView.module.scss';

import useContractLotForm from '../core/customHooks/useContractLotForm';
import { State, ContractLot } from '../../../models/Contracts';
import { Contractor } from '../../../models/Contractors';

interface IContractLotFormMainView {
  open: boolean;
  handleClose: () => void;
  handleCreate: (contract: ContractLot) => void;
  currentContract: ContractLot | undefined;
}

const ContractLotFormMainView: FC<IContractLotFormMainView> = ({
  open,
  handleClose,
  handleCreate,
  currentContract,
}) => {
  const {
    contractorsList,
    contractStatesList,
    data,
    errors,
    handleSubmit,
    handleChange,
    handleChangeDate,
    handleChangeValue,
    resetData,
    autocompleteContractorValue,
    setAutocompleteContractorValue,
    autocompleteStateValue,
    setAutocompleteStateValue,
    inputContractorValue,
    setInputContractorValue,
    inputStateValue,
    setInputStateValue,
    minValidDate,
    maxValidDate,
  } = useContractLotForm({
    currentContract,
    handleCreate,
    handleClose,
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            <Typography variant="headline6" color="gray800">
              {currentContract?.id ? `Lote ${currentContract?.id}` : 'Nuevo lote'}
            </Typography>

            <IconButton
              className={Styles.ContractLotFormMainView_closeIcon}
              iconName="Clear"
              variant="ghost"
              size="small"
              aria-controls="close-lot-form"
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent className={Styles.ContractLotFormMainView_content}>
            <Box className={Styles.ContractLotFormMainView_boxTitle} py={2} px={6}>
              <Typography variant="subtitle1" color="primary800">
                Detalles
              </Typography>
            </Box>
            <Box my={2} px={6}>
              <FormControl fullWidth>
                <TextField
                  id="title"
                  label="Nombre del lote"
                  variant="outlined"
                  value={data?.title || ''}
                  size="small"
                  name="title"
                  error={!!errors?.title}
                  helperText={errors?.title}
                  onChange={handleChange('title')}
                />
              </FormControl>
            </Box>
            <Box my={2} px={6}>
              <FormControl fullWidth>
                <TextField
                  id="description"
                  label="Descripción"
                  variant="outlined"
                  value={data?.description || ''}
                  size="small"
                  minRows={3}
                  multiline
                  name="description"
                  error={!!errors?.description}
                  helperText={errors?.description}
                  onChange={handleChange('description')}
                />
              </FormControl>
            </Box>
            <Box className={Styles.ContractLotFormMainView_boxTitle} py={2} px={6}>
              <Typography variant="subtitle1" color="primary800">
                Fechas
              </Typography>
            </Box>
            <Box mt={2} px={6}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Fecha de Compra
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          value={data?.lotPurchaseDate ? moment(Number(data?.lotPurchaseDate)) : null}
                          format="DD/MM/YYYY"
                          minDate={moment(Number(minValidDate))}
                          maxDate={moment(Number(maxValidDate))}
                          maxDateMessage="La fecha debe ser menor a "
                          minDateMessage="La fecha debe ser mayor a "
                          onChange={(date) => {
                            handleChangeDate('lotPurchaseDate')(date?.valueOf() || null);
                          }}
                          TextFieldComponent={(props) => (
                            <TextField
                              {...props}
                              label=""
                              variant="outlined"
                              className={Styles.TextFieldDisabled}
                              fullWidth
                              size="small"
                              helperText={errors?.lotPurchaseDate}
                              error={!!errors?.lotPurchaseDate}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeOutlined />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Fecha primer pago
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          value={data?.lotFirstPaymentDate ? moment(Number(data?.lotFirstPaymentDate)) : null}
                          format="DD/MM/YYYY"
                          minDate={moment(Number(minValidDate))}
                          maxDate={moment(Number(maxValidDate))}
                          maxDateMessage="La fecha debe ser menor a "
                          minDateMessage="La fecha debe ser mayor a "
                          onChange={(date) => {
                            handleChangeDate('lotFirstPaymentDate')(date?.valueOf() || null);
                          }}
                          TextFieldComponent={(props) => (
                            <TextField
                              {...props}
                              label=""
                              variant="outlined"
                              className={Styles.TextFieldDisabled}
                              fullWidth
                              size="small"
                              helperText={errors?.lotFirstPaymentDate}
                              error={!!errors?.lotFirstPaymentDate}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeOutlined />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Fecha último pago
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          value={data?.lotLastPaymentDate ? moment(Number(data?.lotLastPaymentDate)) : null}
                          format="DD/MM/YYYY"
                          minDate={moment(Number(minValidDate))}
                          maxDate={moment(Number(maxValidDate))}
                          maxDateMessage="La fecha debe ser menor a "
                          minDateMessage="La fecha debe ser mayor a "
                          onChange={(date) => {
                            handleChangeDate('lotLastPaymentDate')(date?.valueOf() || null);
                          }}
                          TextFieldComponent={(props) => (
                            <TextField
                              {...props}
                              label=""
                              variant="outlined"
                              className={Styles.TextFieldDisabled}
                              fullWidth
                              size="small"
                              helperText={errors?.lotLastPaymentDate}
                              error={!!errors?.lotLastPaymentDate}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeOutlined />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={Styles.ContractLotFormMainView_boxTitle} py={2} px={6}>
              <Typography variant="subtitle1" color="primary800">
                Proveedor
              </Typography>
            </Box>
            <Box mt={2} px={6}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Selecciona un proveedor
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="contractor"
                        options={contractorsList as Contractor[]}
                        autoHighlight
                        value={autocompleteContractorValue}
                        onChange={(event, newValue) => {
                          const val = !!newValue ? `${newValue?.id}` : '';
                          handleChangeValue('contractorId', val);
                          setAutocompleteContractorValue(newValue);
                        }}
                        inputValue={inputContractorValue}
                        onInputChange={(_, newInputValue) => {
                          setInputContractorValue(newInputValue);
                        }}
                        getOptionLabel={(option: Contractor) => option.patnerName}
                        renderOption={(option: Contractor) => (
                          <React.Fragment>
                            <span>{option.patnerName}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="contractorId"
                            error={!!errors?.contractorId}
                            helperText={errors?.contractorId}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="body1" color="gray800">
                      Estado de negociación
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="contract-state"
                        options={contractStatesList as State[]}
                        autoHighlight
                        value={autocompleteStateValue}
                        onChange={(event, newValue) => {
                          const val = !!newValue ? `${newValue?.id}` : '';
                          handleChangeValue('stateId', val);
                          setAutocompleteStateValue(newValue);
                        }}
                        inputValue={inputStateValue}
                        onInputChange={(_, newInputValue) => {
                          setInputStateValue(newInputValue);
                        }}
                        getOptionLabel={(option: State) => option.name}
                        renderOption={(option: State) => (
                          <React.Fragment>
                            <span>{option.name}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="stateId"
                            error={!!errors?.stateId}
                            helperText={errors?.stateId}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  resetData();
                  if (!currentContract) {
                    setAutocompleteContractorValue(null);
                    setAutocompleteStateValue(null);
                  }
                  handleClose();
                }}
              >
                Cancelar
              </Button>
            </Box>
            <Box m={2}>
              <Button size="small" variant="contained" type="submit">
                Guardar
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ContractLotFormMainView;
