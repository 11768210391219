import Services from '../../../services/services';
import ResultsStateServicesDefinition from './ResultsStateServicesDefinition';

export default class ResultsStateServices extends Services {
  getTotalCashFlow(towerId) {
    return this.get(ResultsStateServicesDefinition.total(towerId));
  }

  downloadCashflowServices(towerId) {
    const route = ResultsStateServicesDefinition.downloadQuotationCashflow(
      towerId,
    );
    return this.get(route, {
      responseType: 'blob',
    });
  }
  
  putCutsControl({towerId, rows}) {
    const route = ResultsStateServicesDefinition.putCutsControl(towerId);
    return this.put(route, rows)
  }

  putEventsControl({towerId, rows}) {
    const route = ResultsStateServicesDefinition.putEventsControl(towerId);
    return this.put(route, rows)
  }

  overwriteEvent({towerId, rows}) {
    const route = ResultsStateServicesDefinition.overwriteEvent(towerId);
    return this.put(route, rows)
  }

  renameEvent({towerId, newEventName, selectedBudget}) {
    const route = ResultsStateServicesDefinition.renameEvent({towerId, newEventName, selectedBudget});
    return this.put(route)
  }

  deleteEventControl({towerId, eventName}) {
    const route = ResultsStateServicesDefinition.deleteEventControl(towerId, eventName);
    return this.delete(route)
  }
}
