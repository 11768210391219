import { combineReducers } from 'redux';
import { reducer as searchReducer } from '../../components/ClientProperty/ClientCell/ClientForm/Search';
import {
  API_LOADING_COMPONENT,
  SET_FETCH_CLIENTS,
  SET_FETCH_PROPERTIES,
  SET_MAX_LENGTH_AT_CLIENTS,
  SET_TOWER_ID,
  SET_ALL_CLIENTS,
} from './actions';

const initialState = {
  loadingContainer: true,
  clients: [],
  allClients: [],
  properties: [],
  maxClientLength: 0,
  towerId: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case API_LOADING_COMPONENT:
      return { ...state, loadingContainer: payload };

    case SET_FETCH_CLIENTS:
      return { ...state, clients: payload };

    case SET_FETCH_PROPERTIES:
      return { ...state, properties: payload };

    case SET_MAX_LENGTH_AT_CLIENTS:
      return { ...state, maxClientLength: payload };

    case SET_TOWER_ID:
      return { ...state, towerId: payload };

    case SET_ALL_CLIENTS:
      return { ...state, allClients: payload };

    default:
      return state;
  }
};

export default combineReducers({
  root: reducer,
  searcher: searchReducer,
});
