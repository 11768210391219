import { ChangeEvent, useState } from 'react';
import { useForm } from 'shared/customHooks/useForm';
import AddressForClientFunctions from 'App/ClientAddressModal/Core/customHooks/AddressForClientFunctions';
import ClientAddressType from 'App/ClientAddressModal/Core/models/ClientAddressType';
import GetClientAddress from '../helpers/GetClientAddress';
import { ClientInformationProps } from '../interfaces/ClientInformationProps';
import { ClientInformationModel } from '../models/ClientInformationModel';
import { ClientAddressSection } from '../interfaces/ClientAddressSection';

const useClientInformationForm = (
    props: ClientInformationProps,
    addressType: 'companyAddress' | 'residenceAddress',
) => {
    const {
        clientAddressSection,
        onChangeAddress,
        onChangeModel,
        model: initialValues,
    } = props;
    const [address, setClientAddress] = useState(
        GetClientAddress(
            addressType === 'residenceAddress'
                ? clientAddressSection?.address
                : clientAddressSection?.additionalAddress,
        ),
    );

    const form = useForm<ClientInformationModel>({
        initialValues,
        onSubmit: async (values) => {
            onChangeModel(values);
            const updateAddressData: ClientAddressSection = {};
            if (Object.keys(address).length > 1) {
                if (addressType === 'residenceAddress')
                    updateAddressData.address = address;
                else updateAddressData.additionalAddress = address;
                onChangeAddress(updateAddressData);
            }
            if (props?.onEndEdit) props.onEndEdit();
        },
    }, [initialValues]);

    const updateModelAddress = (value: string) =>
        form.handleChangeValue(addressType, value);

    const updateWhenIsOpen = (event: ChangeEvent<HTMLInputElement>) =>
        updateModelAddress(event.target.value);

    const updateWhenIsHelper = (event) => {
        setClientAddress({
            ...address,
            [event.target.name]: event.target.value,
        });
    };

    const updateAddress =
        address.addressType === ClientAddressType.OPEN.code
            ? updateWhenIsOpen
            : updateWhenIsHelper;

    const descriptionFromModel =
        AddressForClientFunctions.getDescriptionForAddress(address);

    const addressDescription =
        address.addressType === ClientAddressType.OPEN.code
            ? form.data[addressType]
            : descriptionFromModel;

    const onChangeType = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const addressType =
            value === ClientAddressType.OPEN.code
                ? ClientAddressType.OPEN.code
                : ClientAddressType.URBAN.code;
        setClientAddress({
            ...address,
            addressType,
        });
    };

    const onFinishAddressEdition = () =>
        updateModelAddress(addressDescription || '');

    return {
        addressDescription,
        address,
        updateAddress,
        onChangeType,
        onFinishAddressEdition,
        ...form,
    };
};

export default useClientInformationForm;
