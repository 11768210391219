import { FC } from 'react';
import styled from 'styled-components';

import fonts from '../../assets/styles/settings/fonts.module.scss';

import TableContainer from './TableContainer';
import TableCell from './TableCell';
import TableHeaderRowCell from './TableHeaderRowCell';

export const CondensedTableContainer: FC = styled(TableContainer)`
  border: none;
`;

export const CondensedTableCell: FC = styled(TableCell)`
  border: none;
  padding: 6px !important;
  font-size: ${fonts['body2-font-size']};
`;

export const CondensedTableHeaderRowCell: FC = styled(TableHeaderRowCell)`
  border: none;
  padding: 6px auto !important;
  font-weight: ${fonts['headline1-font-weight']};
  font-size: ${fonts['body2-font-size']};
`;

export default CondensedTableContainer;
