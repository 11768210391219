import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import styles from './ArrearsClientsReportNumberCell.module.scss';

const ArrearsClientsReportNumberCell = (props) => {
  const { tableRow, tableColumn } = props;

  if (tableColumn.column.name === 'clientName') {
    return <VirtualTable.Cell {...props} />;
  }

  let className = {};
  const { row } = tableRow;
  const cellData = row[tableColumn.column.name];
  const dataKey = tableColumn.column.name;

  if (dataKey === 'arrears' || dataKey === 'financialAccumulated') {
    className = {
      ...className,
      [styles.red]: cellData < 0,
      [styles.green]: cellData >= 0,
    };
  } else if (dataKey === 'capitalBalance') {
    className = {
      ...className,
      [styles.red]: cellData > 0,
      [styles.green]: cellData <= 0,
    };
  } else if (dataKey === 'state') {
    className = { ...className, [styles.green]: cellData === 'AL DÍA' };
  }

  if (
    // eslint-disable-next-line no-restricted-globals
    isNaN(cellData) ||
    dataKey === 'arrearsDays' ||
    dataKey === 'propertyName'
  ) {
    return (
      <VirtualTable.Cell>
        <Typography variant="body2" className={clsx(className)}>
          {cellData}
        </Typography>
      </VirtualTable.Cell>
    );
  }

  return (
    <VirtualTable.Cell>
      <NumberFormat
        displayType="text"
        value={cellData}
        thousandSeparator
        decimalScale={0}
        fixedDecimalScale
        prefix="$"
        className={clsx(className)}
      />
    </VirtualTable.Cell>
  );
};

ArrearsClientsReportNumberCell.propTypes = {
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
};

export default ArrearsClientsReportNumberCell;
