import React, { FC } from 'react';
import { Box, Paper } from '@material-ui/core';
import IncomePaymentsHeader from './customViews/IncomePaymentsHeader';
import useSegmentedControlHandlingChange from '../Core/customHooks/useSegmentedControlHandlingChange';
import IncomePaymentsSegmentedControl from './customViews/IncomePaymentsSegmentedControl';
import usePaymentOptionsForIncomeSegments from '../Core/customHooks/usePaymentOptionsForIncomeSegments';
import IncomePaymentSectionsContainer from './customViews/IncomePaymentSectionsContainer';

const IncomePaymentsMainView: FC = () => {
  const {
    onSegmentChange,
    options,
    segment,
  } = usePaymentOptionsForIncomeSegments();

  return (
    <>
      <Box style={{ height: '100%' }} display="flex" justifyContent="center">
        <Box
          style={{ width: '90%', height: '100%' }}
          display="flex"
          flexDirection="column"
        >
          <IncomePaymentsHeader>
            <IncomePaymentsSegmentedControl
              options={options}
              onSegmentChange={onSegmentChange}
            />
          </IncomePaymentsHeader>

          <IncomePaymentSectionsContainer segment={segment} />
        </Box>
      </Box>
    </>
  );
};

export default IncomePaymentsMainView;
