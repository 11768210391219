import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const AbsoluteCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: ${(props) => props.leftPercentage || 50}%;
  top: ${(props) => props.topPercentage || 50}%;
  z-index: 1000;
`;

export default AbsoluteCircularProgress;
