import React, { useState } from 'react';
import Icon from '../../../../../shared/components/Icon/Icon';
import IconButton from '../../../../../shared/components/IconButton/IconButton';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Style from './ResultsStateHeaderAlerts.module.scss';
import SnackAlert from '../../../../../shared/components/SnackAlert/SnackAlert';

const ResultsStateHeaderAlerts = ({ towerWithoutQuotation, speedSalesIsMissing }) => {
  const [visibleQuotationAlert, setVisibleQuotationAlert] = useState(true);
  const [visibleSpeedAlert, setVisibleSpeedAlert] = useState(true);

  return (
    <div>
      <>
        {visibleQuotationAlert && speedSalesIsMissing && (
          <SnackAlert
            message="Alguno de los grupos no tiene la velocidad de ventas de acuerdo a sus unidades"
            onClick={() => setVisibleQuotationAlert(false)}
          />
        )}
        {visibleSpeedAlert && !!towerWithoutQuotation && (
          <Box className={Style.alert}>
            <Icon
              className={Style.exclamationPointIcon}
              variant={'exclamationPoint'}
              height={'20px'}
              width={'20px'}
              color={'#2D52D6'}
            />
            <Typography className={Style.alertTypography}>
              {towerWithoutQuotation && 'Existen compradores que no poseen forma de pago'}
            </Typography>
            <IconButton
              className={Style.closeIcon}
              iconName="Clear"
              variant="ghost"
              size="small"
              aria-controls="close-lot-form"
              onClick={() => setVisibleSpeedAlert(false)}
            />
          </Box>
        )}
      </>
    </div>
  );
};

export default ResultsStateHeaderAlerts;
