import Services from 'services/services';
import { GeneralWalletDetailsResponseDTO } from '../core/DTO/GeneralWalletComment.dto';
import GeneralWalletDetailsServiceDefinitions from './GeneralWalletDetailsServiceDefinitions';
import WalletMonthlyReportDTO from '../../MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';

export class GeneralWalletDetailsService extends Services {
  getThreads = (clientId: string, propertyId: number) =>
    this.get<GeneralWalletDetailsResponseDTO>(GeneralWalletDetailsServiceDefinitions.threads(clientId, propertyId));

  viewReport = (towerId: string, propertyId: number) =>
    this.get<WalletMonthlyReportDTO>(GeneralWalletDetailsServiceDefinitions.viewReport(towerId, propertyId));
  updateReport = (isChecked: boolean, propertyId: number, rowId: string) =>
    this.put(GeneralWalletDetailsServiceDefinitions.updateReport(), {
      isChecked,
      propertyId,
      rowId,
    });
  checkAllReport = (isAllInPropertyChecked: boolean, propertyId: number) =>
    this.put(GeneralWalletDetailsServiceDefinitions.checkAllReport(), {
      isAllInPropertyChecked,
      propertyId,
    });

  createThreadsWithFile = (clientId: string, propertyId: number, form: FormData) =>
    this.post(GeneralWalletDetailsServiceDefinitions.threads(clientId, propertyId), form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  createThreadsWithComment = (clientId: string, propertyId: number, comment: string) =>
    this.post(GeneralWalletDetailsServiceDefinitions.threads(clientId, propertyId), {
      comment,
    });

  updateInteractionComment = (interactionId: number, comment: string) =>
    this.put(GeneralWalletDetailsServiceDefinitions.inteactionUpdate(interactionId), {
      comment,
    });

  removeInteractionComment = (interactionId: number) =>
    this.delete(GeneralWalletDetailsServiceDefinitions.inteactionUpdate(interactionId));

  createInteractionComment = (threadId: number, comment: string) =>
    this.post(GeneralWalletDetailsServiceDefinitions.threadInteraction(threadId), {
      comment,
    });

  createInteractionFile = (threadId: number, form: FormData) =>
    this.post(GeneralWalletDetailsServiceDefinitions.threadInteraction(threadId), form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  excelSummary = (towerId: string, propertyId: number) =>
    this.get<any>(GeneralWalletDetailsServiceDefinitions.excelSummary(towerId, propertyId), {
      responseType: 'blob',
    });
}
