const IdentityDocumentType = [
  { id: 1, name: 'Cédula de Ciudadanía' },
  { id: 2, name: 'NIT' },
  { id: 3, name: 'NIUP-Registro Único' },
  { id: 4, name: 'Tarjeta de Identidad' },
  { id: 5, name: 'Cédula de Extranjería' },
  { id: 6, name: 'Libreta Militar' },
  { id: 7, name: 'Pasaporte' },
  { id: 8, name: 'Visa' },
  { id: 9, name: 'Registro Civil' },
  { id: 10, name: 'Tarjeta Extranjería' },
  { id: 11, name: 'Permiso especial de permanencia' },
]
export default IdentityDocumentType;