import React, { FC } from 'react';
import { FormControl } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { IUseFormResult } from '../../../../../shared/customHooks/useForm';
import TextField from '../../../../../shared/components/TextField/TextField';
import { ContractPayment, PaymentCycle } from '../../../../../models/ContractPayments';
import usePaymentCycle from '../../../Core/customHooks/usePaymentCycle';

interface IPaymentCycleField extends Partial<IUseFormResult<ContractPayment>> {
  handleChangeValue: <G>(key: keyof ContractPayment, value: G) => void;
  paymentCyclesList: PaymentCycle[] | undefined;
  currentContractPayment: ContractPayment | undefined;
}

const PaymentCycleField: FC<IPaymentCycleField> = ({
  handleChangeValue,
  paymentCyclesList,
  currentContractPayment,
  errors,
}) => {
  const { autocompleteCycleValue, setAutocompleteCycleValue, inputCycleValue, setInputCycleValue } = usePaymentCycle({
    paymentCyclesList,
    currentContractPayment,
  });

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="paymentCycle"
        options={paymentCyclesList as PaymentCycle[]}
        blurOnSelect
        getOptionLabel={(option: PaymentCycle) => `${option.name}`}
        value={autocompleteCycleValue}
        onChange={(event, newValue) => {
          const val = !!newValue ? `${newValue?.id}` : '';
          handleChangeValue('paymentCycle', val);
          setAutocompleteCycleValue(newValue);
        }}
        inputValue={inputCycleValue}
        onInputChange={(_, newInputValue) => {
          setInputCycleValue(newInputValue);
        }}
        renderOption={(option: PaymentCycle) => (
          <React.Fragment>
            <span>{option.name}</span>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Periodicidad"
            variant="outlined"
            fullWidth
            size="small"
            name="paymentCycle"
            error={!!errors?.paymentCycle}
            helperText={errors?.paymentCycle}
          />
        )}
      />
    </FormControl>
  );
};

export default PaymentCycleField;
