import Services from '../../../services/services';
import RealPaymentsServicesDefinitions from './RealPaymentsServicesDefinitions';

export default class RealPaymentsServices extends Services {
  getRealPayments(propertyId, towerId) {
    return this.get(
      RealPaymentsServicesDefinitions.getRealPayments(propertyId, towerId),
    );
  }

  getMonthlyPayments(propertyId, towerId) {
    return this.get(
      RealPaymentsServicesDefinitions.getMonthlyPayments(propertyId, towerId),
    );
  }

  getClientHubForProperty(propertyId, towerId) {
    return this.get(
      RealPaymentsServicesDefinitions.getClientHubForProperty(
        propertyId,
        towerId,
      ),
    );
  }

  getClientIsPurchaseConfirmedProperty(clientId, propertyId) {
    return this.get(
      RealPaymentsServicesDefinitions.isPurchaseConfirmedPath(
        clientId,
        propertyId,
      ),
    );
  }

  setClientPurchaseConfirmedProperty(clientId, propertyId) {
    return this.put(
      RealPaymentsServicesDefinitions.isPurchaseConfirmedPath(
        clientId,
        propertyId,
      ),
    );
  }

  updatePayment(towerId, data) {
    return this.put(RealPaymentsServicesDefinitions.update(towerId), data);
  }

  deletePayment(towerId, data) {
    return this.delete(RealPaymentsServicesDefinitions.delete(towerId), data);
  }

  downloadRealPayments(towerId, propertyId) {
    const route = RealPaymentsServicesDefinitions.download(towerId, propertyId);
    return this.get(route, {
      responseType: 'blob',
    });
  }

  downloadMonthlyRealPayments(towerId, propertyId) {
    const route = RealPaymentsServicesDefinitions.downloadMonthly(
      towerId,
      propertyId,
    );
    return this.get(route, {
      responseType: 'blob',
    });
  }

  uploadRealPayments(towerId, propertyId, clientId, form) {
    return this.post(
      RealPaymentsServicesDefinitions.upload(towerId, propertyId, clientId),
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }

  updateFinancialPayment(towerId, data) {
    return this.put(
      RealPaymentsServicesDefinitions.updateFinancialPayment(towerId),
      data,
    );
  }

  updateChargeNumber(data) {
    return this.put(RealPaymentsServicesDefinitions.updateChargeNumber, data);
  }
}
