import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';

export const Total = ({ resumes, readjustment, buildDateDifference }) => {
  const [resume, setResume] = useState({});
  const [readjustments, setReadjustments] = useState(0);
  useEffect(() => {
    setReadjustments(readjustment);
    setResume(resumes);
  }, [readjustment]);
  const areaRender = (name) => (
    <NumberFormat
      value={Number(name)}
      thousandSeparator
      suffix={'mt2'}
      displayType={'text'}
      renderText={(value) => <div>{value}</div>}
    />
  );
  const currencyFormatRender = (name) => (
    <NumberFormat
      value={Number(name)}
      thousandSeparator
      prefix={'$'}
      displayType={'text'}
      renderText={(value) => <div>{value}</div>}
    />
  );
  return (
    <Paper classes={{ root: styles.container }}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        classes={{ root: styles.grid }}
      >
        <Grid container xs={3} direction="row" justify="flex-start">
          <div className={styles.total}>
            <Typography classes={{ root: styles.totalText }}>
              VALOR BASE
            </Typography>
          </div>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {areaRender(resume ? resume.area : 0)}
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {currencyFormatRender(resume ? resume.price : 0)}
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {currencyFormatRender(resume ? resume.totalCost : 0)}
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="flex-start">
          <div className={styles.readjustment}>
            <Typography classes={{ root: styles.totalText }}>
              REAJUSTE POR INFLACIÓN
            </Typography>
          </div>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            <br />
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            <br />
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {currencyFormatRender(
              readjustments -
                (buildDateDifference < 12 ? 0 : resume ? resume.totalCost : 0),
            )}
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="flex-start">
          <div className={styles.totalFn}>
            <Typography classes={{ root: styles.totalText }}>TOTAL</Typography>
          </div>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {areaRender(resume ? resume.area : 0)}
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {currencyFormatRender(
              (readjustments / Number(resume ? resume.area : 0)).toFixed(0),
            )}
          </Typography>
        </Grid>
        <Grid container xs={3} direction="row" justify="center">
          <Typography classes={{ root: styles.itemContainer }}>
            {currencyFormatRender(readjustments)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

Total.propTypes = {
  resumes: PropTypes.array.isRequired,
  readjustment: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const index = state.towerBuilder.builder.builder.length;
  const { constructionFlow } = state.towerBuilder.root.currentTower;
  return {
    readjustment: constructionFlow
      ? constructionFlow.constructionWithCurve[0].total
      : [],
    resumes: state.towerBuilder.builder.builder[index - 1],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Total);
