import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createTheme, Box } from '@material-ui/core';
import {
  Grid,
  TableHeaderRow,
  TableTreeColumn,
  VirtualTable,
  TableSelection,
  TableBandHeader,
} from '@devexpress/dx-react-grid-material-ui';
import {
  TreeDataState,
  CustomTreeData,
  SelectionState,
} from '@devexpress/dx-react-grid';
import useRowsForContracts, {
  getChildRows,
} from '../../../Core/customHooks/useRowsForContracts';
import useColumnsForResultsState from '../../../Core/customHooks/useColumnsForResultsState';
import RowsCustomCell from '../cells/ResultsStateCustomCell';
import headerCustomCell from '../cells/ResultsStateHeaderCustomCell';
import { useDispatch } from 'react-redux';
import { updateResultsStateRows } from '../../../../TotalCashFlow/Core/actions';
import CustomRow from '../rows/ResultsStateCustomRow';
import BudgetTableHeader from '../../components/Events/BudgetTableHeader';
import CutsTableHeader from '../../components/Cuts/CutsTableHeader';
import BandCell from '../cells/CellTypes/BandCell/BandCell';
import LeftColumnCustomCell from '../cells/LeftColumnCustomCell';
import Style from '../../styles/ResultStateMainView.module.scss';
import useControlsForResultsState from 'App/ResultsState/Core/customHooks/useControlsForResultsState';
import ResultsStateHeaderAlerts from '../../components/Alerts/ResultsStateHeaderAlerts';
import useSortingResultsStatesRows from 'App/ResultsState/Core/customHooks/useSortingResultsStatesRows';

const theme = createTheme({ palette: { action: { selected: '#000000' } } });

const ResultsStateTable = ({
  contractRows,
  salesRows,
  financing,
  financialPerformance,
  towerWithoutQuotation,
  speedSalesIsMissing,
  selectedTab,
  cutsControl,
  budgetControl,
  budgetRows,
  cutsRows,
  selectedBudget,
  totalsReport,
}) => {
  const dispatch = useDispatch();
  const { contracts } = useRowsForContracts(contractRows);

  const { rowsWithControls, selection } = useControlsForResultsState({
    contracts,
    salesRows,
    contractRows,
    financialPerformance,
    financing,
    selectedTab,
    cutsControl,
    budgetControl,
    budgetRows,
    cutsRows,
    selectedBudget,
    totalsReport,
  });

  const { orderedRows } = useSortingResultsStatesRows({
    resultsStateRows: rowsWithControls,
    contractRows,
    financing,
    financialPerformance,
  })

  const cutDateState = cutsRows && cutsRows[0]?.cutDate;

  const cutDate = (rowsWithControls && rowsWithControls[0].cutDate) || null;

  const { columns, tableColumnExtensions, defaultExpandedRowIds, columnBands } =
    useColumnsForResultsState({
      contractRows,
      selectedTab,
      cutDate,
      cutDateState,
      selectedBudget,
      budgetControl,
      budgetRows,
    });

  useEffect(() => {
    dispatch(updateResultsStateRows(orderedRows))
  }, [rowsWithControls])

  return (
    <Box>
      {(towerWithoutQuotation || speedSalesIsMissing) && (
        <ResultsStateHeaderAlerts
          towerWithoutQuotation={towerWithoutQuotation}
          speedSalesIsMissing={speedSalesIsMissing}
        />
      )}
      {rowsWithControls &&
        rowsWithControls[0].previousControl === '-' &&
        selectedTab.id === 'CUT' && <CutsTableHeader />}
      {selectedTab.id === 'BUDGET' && <BudgetTableHeader />}
      <Box className={Style.resultStateTable}>
        <MuiThemeProvider theme={theme}>
          <Grid rows={rowsWithControls} columns={columns}>
            <SelectionState selection={[selection]} />
            <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
            <CustomTreeData getChildRows={getChildRows} />
            <VirtualTable
              cellComponent={RowsCustomCell}
              columnExtensions={tableColumnExtensions}
              height="800px"
            />
            <TableHeaderRow cellComponent={headerCustomCell} />
            <TableSelection
              selectByRowClick={true}
              highlightRow
              showSelectionColumn={false}
            />
            <TableTreeColumn for="name" cellComponent={LeftColumnCustomCell} />
            <TableBandHeader
              columnBands={columnBands}
              cellComponent={BandCell}
            />
          </Grid>
        </MuiThemeProvider>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, props) => {
  const {
    contractRows,
    totalsReport,
    financialPerformance,
    salesRows,
    financing = [],
    initialDate,
    lastDate,
    cutsControl,
    budgetControl,
    budgetRows,
    cutsRows,
    selectedBudget,
  } = state.cashFlow.root;
  
  return {
    contractRows,
    financialPerformance,
    salesRows,
    financing,
    initialDate,
    lastDate,
    towerWithoutQuotation: props.towerWithoutQuotation,
    cutsControl,
    budgetControl,
    budgetRows,
    cutsRows,
    selectedBudget,
    totalsReport,
  };
};

const mapDispatchToProps = {};

ResultsStateTable.propTypes = {
  contractRows: PropTypes.array,
  financialPerformance: PropTypes.array,
  salesRows: PropTypes.array,
  financing: PropTypes.array,
  initialDate: PropTypes.number,
  lastDate: PropTypes.number,
  towerWithoutQuotation: PropTypes.bool,
  cutsControl: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsStateTable);
