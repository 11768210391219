import React, { useState, useEffect } from 'react';
import styles from './Login.module.scss';
import Card, { CardHeader, CardBody } from '../../components/UI/Card/Card';
import Error from '../../components/UI/Error/Error';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Link
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { UserRoutes } from '../../routes/local/routes';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
    '& label & fieldset': {
      borderColor: '#29339b',
      color: '#29339b',
    },
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const emailValidation = (value) => {
    let emailExp = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const pattern = new RegExp(emailExp);
    return pattern.test(value);
  };

  const keyHandler = (e) => {
    return e.key === 'Enter' ? props.loginAction() : e;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setError(
      props.email && !emailValidation(props.email)
        ? 'Debe ser un email válido'
        : null,
    );
  }, [props.email]);

  return (
    <div>
      {props.currentErrorMessage !== '' ? (
        <Error message={props.currentErrorMessage} />
      ) : null}
      <div className={styles.Container} onKeyDown={keyHandler} tabIndex="0">
        <Card>
          <CardHeader>
            <Typography variant="h4">Login</Typography>
          </CardHeader>
          <CardBody>
            <Box>
              <TextField
                className={`${classes.margin} ${classes.textField}`}
                label="Correo"
                name="email"
                value={props.email}
                onChange={(input) => {
                  props.onChange(input.target);
                }}
                variant="outlined"
                color="primary"
                error={error}
                helperText={error}
              />
            </Box>
            <Box>
              <FormControl
                className={`${classes.margin} ${classes.textField}`}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password"
                  type={props.showPassword ? 'text' : 'password'}
                  value={props.password}
                  onChange={(input) => {
                    props.onChange(input.target);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={props.toggleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {props.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={80}
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                className={styles.Button}
                onClick={props.loginAction}
                color="primary"
              >
                Iniciar sesión
              </Button>
            </Box>
            <RouterLink to={UserRoutes.forgotPassword} className={styles.Link}>
              <Link color='textSecondary'>
                Recuperar Contraseña
              </Link>
            </RouterLink>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Login;
