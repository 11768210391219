import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';
import useQuotationEditionFinalType from '../../../Core/customHooks/useQuotationEditionFinalType';

const QuotationFinalPaymentTypeRow = () => {
  const {
    paymentTypeSelected,
    isEditingMode,
    options,
    onChange,
  } = useQuotationEditionFinalType();

  return (
    <Box>
      <FormControl>
        <InputLabel></InputLabel>
        <Select
          native
          value={paymentTypeSelected}
          onChange={onChange}
          disabled={!isEditingMode}
          inputProps={{
            name: 'payment',
            id: `payment-type_final`,
          }}
        >
          {options.map((option) => (
            <option key={`payment_type_${option.id}`} value={option.id}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

QuotationFinalPaymentTypeRow.propTypes = {};

export default QuotationFinalPaymentTypeRow;
