/* eslint-disable no-param-reassign */
import { ChangeEvent, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Borders, Column, Fill } from 'exceljs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import XLSXUtils from '../../../../helpers/XLSX';
import Services from '../../Services/BusinessPartnersServices';
import Fields from '../fields';

const services = new Services();

export interface UseXLSXContractorsResult {
  makeDemoXLSX: () => Promise<void>;
  uploadContractors: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  loading: boolean;
}

type HookType = (params: {
  onBulkCreate: () => void;
}) => UseXLSXContractorsResult;

const useXLSXContractors: HookType = ({ onBulkCreate }) => {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { companyId } = useSelector(
    (state: RootState) => state.businessPartnerCreator,
  );

  const makeDemoXLSX = async (): Promise<void> => {
    const xlsx = new XLSXUtils();
    const book = xlsx.createBook();
    const SheetName = 'Proveedores';
    const worksheet = xlsx.createSheet(book, SheetName);

    const fill: Fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'EAEAEA' },
    };

    const border: Partial<Borders> = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const fields = Fields.map((field) => {
      return {
        header: field.title,
        key: field.field,
        width: 20,
        fill,
      };
    });

    const columns: Partial<Column>[] = fields;

    worksheet.columns = columns;

    worksheet.addRow([
      'Contratista 1',
      '000000',
      'contratista1@contractor.com',
      'MICRO',
      '',
      '',
      '',
      '',
      '1',
      '111112',
      '',
    ]);

    worksheet.getCell('A1').fill = fill;
    worksheet.getCell('A1').border = border;
    worksheet.getCell('B1').fill = fill;
    worksheet.getCell('B1').border = border;
    worksheet.getCell('C1').fill = fill;
    worksheet.getCell('C1').border = border;
    worksheet.getCell('D1').fill = fill;
    worksheet.getCell('D1').border = border;
    worksheet.getCell('E1').fill = fill;
    worksheet.getCell('E1').border = border;
    worksheet.getCell('F1').fill = fill;
    worksheet.getCell('F1').border = border;
    worksheet.getCell('G1').fill = fill;
    worksheet.getCell('G1').border = border;
    worksheet.getCell('H1').fill = fill;
    worksheet.getCell('H1').border = border;
    worksheet.getCell('I1').fill = fill;
    worksheet.getCell('I1').border = border;
    worksheet.getCell('J1').fill = fill;
    worksheet.getCell('J1').border = border;
    worksheet.getCell('K1').fill = fill;
    worksheet.getCell('K1').border = border;

    await xlsx.downloadXLSX(book, `Plantilla${SheetName}`);
  };

  const uploadContractors = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setLoading(true);
    if (e.target.files?.length) {
      try {
        const formData = new FormData();
        const file = e.target.files[0];
        const types = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
        ];
        if (types.includes(file.type)) {
          formData.append('file', file);
          const res = await services.postBusinessPartnerTemplate(
            companyId,
            formData,
          );
          const { data } = res;

          if (data.created) onBulkCreate();
          enqueueSnackbar('Plantilla cargada correctamente', {
            variant: 'success',
            autoHideDuration: 5000,
            resumeHideDuration: 0,
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            disableWindowBlurListener: true,
          });
        } else {
          enqueueSnackbar('El tipo de archivo no es valido');
        }
      } catch (error) {
        enqueueSnackbar('error');
      }
    }
    setLoading(false);
  };

  return {
    makeDemoXLSX,
    uploadContractors,
    loading,
  };
};

export default useXLSXContractors;
