import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import ContractPaymentMenu from './Views/customViews/ContractPaymentMenu';
import Typography from '../../../shared/components/Typography/Typography';
import { Button } from '../../../shared/components/Button/Button';
import { ContractPayment } from '../../../models/ContractPayments';
import useContractPayments from '../Core/customHooks/useContractPayments';
import { ContractPaymentListGrid } from './Views/customViews/ContractPaymentListGrid';
import ContractPaymentFormModal from '../../ContractPaymentFormModal/UI/ContractPaymentFormMainView';
import ContractPaymentScheduleModal from './Views/customViews/ContractPaymentScheduleModal';
import AsyncConfirmModal from '../../../shared/components/AsyncModal/AsyncConfirmModal';
import Styles from './styles/ContractPaymentsMainView.module.scss';
import ContractPaymentMenuList from './Views/customViews/ContractPaymentMenuList';

interface IContractPaymentsMainView {
  currentContractId: number;
  currentContractIsLot: boolean;
}

const ContractPaymentsMainView: FC<IContractPaymentsMainView> = ({ currentContractId, currentContractIsLot }) => {
  const {
    contractPayments,
    isLoading,
    currentContractPayment,
    setCurrentContractPayment,
    contractPaymentType,
    setContractPaymentType,
    openContractPaymentForm,
    setOpenContractPaymentForm,
    openContractPaymentScheduleModal,
    setOpenContractPaymentScheduleModal,
    anchorNewContractPaymentButton,
    handleNewContractPaymentClick,
    handleCloseNewContractPaymentMenu,
    getContractPaymentSchedule,
    scheduleFlowIsLoading,
    handleDelete,
    asyncConfirmDeletePaymentModalRef,
  } = useContractPayments(currentContractId);

  return (
    <>
      <Box paddingY={3}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" fontSize="1.5rem" fontWeight="600">
              Configuración de pagos
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              style={{
                margin: '0 10px',
              }}
              onClick={() => {
                getContractPaymentSchedule();
                setOpenContractPaymentScheduleModal(true);
              }}
            >
              Ver Presupuesto
            </Button>
            <Button
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={handleNewContractPaymentClick}
            >
              Nuevo pago
            </Button>

            <ContractPaymentMenu
              anchorEl={anchorNewContractPaymentButton}
              currentContractIsLot={currentContractIsLot}
              handleClose={handleCloseNewContractPaymentMenu}
              onMenuClick={(paymentType) => {
                handleCloseNewContractPaymentMenu();
                setCurrentContractPayment(undefined);
                setOpenContractPaymentForm(true);
                setContractPaymentType(paymentType);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <div>
        {isLoading ? (
          <AbsoluteCircularProgress />
        ) : (
          <Box paddingY={3} height="75vh">
            {!!!contractPayments || contractPayments?.length === 0 ? (
              <ContractPaymentMenuList
                currentContractIsLot={currentContractIsLot}
                setOpenContractPaymentForm={setOpenContractPaymentForm}
                setContractPaymentType={setContractPaymentType}
              />
            ) : (
              <Box overflow="false" className={Styles.scrollbar}>
                {contractPayments?.map((contractPayment: ContractPayment) => (
                  <Box marginY={2} key={`contractPaymentCard-${contractPayment.id}`}>
                    <ContractPaymentListGrid
                      contractPayment={contractPayment}
                      handleEdit={() => {
                        if (!!contractPayment) {
                          setContractPaymentType(contractPayment.type);
                          setOpenContractPaymentForm(true);
                          setCurrentContractPayment(contractPayment);
                        }
                      }}
                      handleDelete={() => {
                        setCurrentContractPayment(contractPayment);
                        handleDelete(contractPayment);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      </div>
      <ContractPaymentFormModal
        key={'key-Contract-Payments-Form-Dialog'}
        contractPaymentType={contractPaymentType}
        currentContractId={currentContractId}
        open={openContractPaymentForm}
        handleClose={() => {
          setCurrentContractPayment(undefined);
          setOpenContractPaymentForm(false);
        }}
        handleCreate={() => {}}
        currentContractPayment={currentContractPayment}
      />
      <ContractPaymentScheduleModal
        isLoading={scheduleFlowIsLoading}
        open={openContractPaymentScheduleModal}
        contractId={currentContractId}
        handleClose={() => {
          setOpenContractPaymentScheduleModal(false);
        }}
      />

      <AsyncConfirmModal ref={asyncConfirmDeletePaymentModalRef} open={true} acceptText="Eliminar">
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box marginTop={2}>
            <Typography variant="headline5">¿Eliminar pago?</Typography>
          </Box>
          <Box marginY={2}>
            <Box>
              <Typography variant="body1">¿Seguro que deseas eliminar este pago?</Typography>
            </Box>
          </Box>
        </Box>
      </AsyncConfirmModal>
    </>
  );
};

export default ContractPaymentsMainView;
