import { Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { FC } from 'react';
import styled from 'styled-components'

const GeneralWalletToolbarRoot: FC = styled(Toolbar.Root)<Toolbar.RootProps>`
  /* Box-model */
  padding: 0;

  /* Visual */
  border-bottom: none;
`;

export default GeneralWalletToolbarRoot;