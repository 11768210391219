import React from 'react';
import useChangePasswordBehavior from '../Core/customHooks/useChangePasswordBehavior';
import styles from './styles.module.scss';
import {
  Typography,
  Card,
  TextField,
  Box,
  Button,
  Link,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { UserRoutes } from '../../../routes/local/routes';
import Alert from './Alert';

const ChangePassword = () => {
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordDoesNotMatch,
    submit,
    loading,
    errorMessage,
    success,
    dismissError,
    dismissSuccess
  } = useChangePasswordBehavior();

  return (
    <main className={styles.container}>
      <Card className={styles.card}>
        <div className={styles.title}>
          <Typography variant="h5">Ingresar nueva contraseña</Typography>
        </div>
        <Box>
          <TextField
            label="Nueva Contraseña"
            name="password"
            className={styles.input}
            value={password}
            onChange={event => setPassword(event.target.value)}
            variant="outlined"
            color="primary"
            type="password"
          />
        </Box>
        <Box>
          <TextField
            label="Confirmar Contraseña"
            name="confirmPassword"
            className={styles.input}
            value={confirmPassword}
            onChange={event => setConfirmPassword(event.target.value)}
            variant="outlined"
            color="primary"
            type="password"
            error={confirmPasswordDoesNotMatch}
            helperText={confirmPasswordDoesNotMatch ? 'Las contraseñas no coinciden' : ''}
          />
        </Box>
        <Button className={styles.submit} onClick={submit}>Enviar</Button>
        <RouterLink to={UserRoutes.login} className={styles.link}>
          <Link color='textSecondary'>Regresar</Link>
        </RouterLink>
      </Card>
      <Snackbar open={loading}>
        <CircularProgress />
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={dismissError}>
        <Alert onClose={dismissError} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={6000} onClose={dismissSuccess}>
        <Alert onClose={dismissSuccess} severity="success">
          Contraseña cambiada correctamente.
        </Alert>
      </Snackbar>
    </main>
  );
};

export default ChangePassword;