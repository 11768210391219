import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import agent from '../../../../config/config';
import { Role } from '../../../../helpers';

export default function useRealPaymentsRowsMapper() {
  const [rows, setRows] = useState([]);
  const { payments } = useSelector((state) => state.realPayments.root);
  useEffect(() => {
    setRows(_.sortBy(payments, ['date'], ['asc']));
  }, [payments]);

  const columns = [
    { text: 'Identificador', align: 'inherit' },
    { text: 'Fecha', align: 'inherit' },
    { text: 'Pago', align: 'inherit' },
  ];

  const columnsWithSpaces = !agent.isAuthorized([Role.Sales])
    ? [{ text: '', align: 'inherit' }]
    : [];

  return { rows, columns: [...columns, ...columnsWithSpaces] };
}
