import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { DeedExpensesDisplacementCellProps } from 'App/DeedExpenses/core/interfaces/DeedExpensesDisplacementCellProps';
import PlusInput from 'shared/components/PlusInput/PlusInput';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import useDeedExpensesDisplacementCell from 'App/DeedExpenses/core/customHooks/useDeedExpensesDisplacementCell';

const DeedExpensesDisplacementCell: FC<DeedExpensesDisplacementCellProps & DataTypeProvider.ValueFormatterProps> = ({
  value,
  row,
  handleChangeDisplacement,
}) => {
  const { displacement, handleChange, onSubmit } = useDeedExpensesDisplacementCell(
    value,
    row.quotationId,
    handleChangeDisplacement,
  );
  return (
    <Box display="flex" justifyContent="center">
      <div onBlur={onSubmit}>
        <PlusInput value={displacement} onChange={handleChange} size="small" isDisabled={row.loading} />
      </div>
    </Box>
  );
};

export default DeedExpensesDisplacementCell;
