import React, { FC } from 'react';
import {
  Grid,
  TableHeaderRow,
  TableSummaryRow,
  VirtualTable,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import TableHeaderRowCell from '../../../../shared/tables/TableHeaderRowCell';
import TableCell from '../../../../shared/tables/TableCell';
import VirtualTableContainer from '../../../../shared/tables/VirtualTableContainer';
import TableSummaryRowCell from '../../../../shared/tables/TableSummaryRowCell';

import useColumnsAndRowsForContractsEvalproFlow from '../../core/customHooks/useColumnsAndRowsForContractsEvalproFlow';
import ContractsEvalproGridSummaryItemComponent from './ContractsEvalproGridSummaryItemComponent';
import VirtualTableDataTypeProvider from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';

interface IContractsEvalproFlowGrid {
  isLoading: boolean;
}

const ContractsEvalproFlowGrid: FC<IContractsEvalproFlowGrid> = ({ isLoading }) => {
  const { rows, columns, totalSummaryItems, summaryCalculator, fixedColumns } =
    useColumnsAndRowsForContractsEvalproFlow();

  return (
    <>
      {isLoading || !!!rows ? (
        <AbsoluteCircularProgress />
      ) : (
        <Grid rows={rows} columns={columns}>
          <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.tooltip} />
          <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary calculator={summaryCalculator} />
          <VirtualTable
            columnExtensions={columns}
            containerComponent={VirtualTableContainer}
            cellComponent={TableCell}
            height="80vh"
          />
          <TableHeaderRow cellComponent={TableHeaderRowCell} />
          <TableSummaryRow
            itemComponent={ContractsEvalproGridSummaryItemComponent}
            totalCellComponent={TableSummaryRowCell}
          />
          <TableFixedColumns leftColumns={fixedColumns} />
        </Grid>
      )}
    </>
  );
};

export default ContractsEvalproFlowGrid;
