import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import UserServices from '../../services/user/UserServices';
import errorHandling from '../../services/commons/errorHelper';
import Error from '../../components/UI/Error/Error';
import agent from '../../config/config';
import User from '../../components/User/User';
import { UserRoutes } from '../../routes/local/routes';
import styles from './UserSettings.module.scss';
import Modal from '../../components/UI/Modal/Modal';
import { PasswordEditor } from '../../components/User/ModalsContent';
import Context from '../../Context';

export default class UserSettings extends Component {
  static contextType = Context.Shared;

  constructor(props) {
    super(props);
    this.services = new UserServices(this);
  }

  state = {
    isLoading: false,
    user: {},
    currentErrorMessage: '',
    password: '',
    confirmPassword: '',
    isModalLoading: '',
    isModalLocked: false,
    isUpdatingPasswordMode: false,
  };

  componentDidMount() {
    if (this.props.changeTower) {
      this.props.changeTower(null);
    }
  }

  logoutAction = () => {
    const { loadSessionCompany } = this.context;
    loadSessionCompany('');
    this.props.history.push(UserRoutes.login);
    if (agent.currentUser) {
      this.services
        .logout({
          refreshToken: agent.currentUser.refreshToken,
        })
        .then()
        .catch(this.genericCatch);
    }
    agent.logout();
  };

  updatePasswordAction = () => {
    this.setState({
      isUpdatingPasswordMode: true,
    });
  };

  updatePassword = () => {
    if (
      this.state.password !== this.state.confirmPassword ||
      this.state.password.length === 0
    ) {
      return;
    }

    this.setState({
      isModalLoading: true,
    });
    this.services
      .updatePassword({ password: this.state.password })
      .then(() => {
        this.setState({
          isUpdatingPasswordMode: false,
          isModalLoading: false,
          currentErrorMessage:
            '¡Listo! Se ha actualizado la contraseña del usuario',
          password: '',
          confirmPassword: '',
        });
      })
      .catch(this.genericCatch);
  };

  genericCatch = (error) => {
    const errorHelper = errorHandling(error);
    this.setState({
      currentErrorMessage: errorHelper.message,
      isLoading: false,
    });
  };

  loaderView = () =>
    this.state.isModalLoading ? (
      <div className={styles.Loader}>
        <Loader
          type="ThreeDots"
          color={styles.mainColor}
          height="100"
          width="100"
        />
      </div>
    ) : null;

  onChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  onCancel = () => {
    this.setState({
      isUpdatingPasswordMode: false,
      password: '',
      confirmPassword: '',
    });
  };

  render() {
    return (
      <div>
        {this.state.currentErrorMessage !== '' ? (
          <Error message={this.state.currentErrorMessage} />
        ) : null}
        <User
          isLoading={this.state.isLoading}
          user={this.state.user}
          logoutAction={this.logoutAction}
          updatePassword={this.updatePasswordAction}
        />
        <Modal
          title={`Modificar contraseña del usuario ${
            this.state.currentUser ? this.state.currentUser.name : ''
          }`}
          hidden={!this.state.isUpdatingPasswordMode}
          onConfirm={this.updatePassword}
          onCancel={this.onCancel}
          blocked={this.state.isModalLocked}
        >
          {!this.state.isModalLoading && (
            <PasswordEditor
              password={this.state.password}
              confirmPassword={this.state.confirmPassword}
              onChange={this.onChange}
            />
          )}
          {this.loaderView()}
        </Modal>
      </div>
    );
  }
}
