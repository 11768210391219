import { combineReducers } from 'redux';
import { QUOTATIONS_LIST__SET_DATA } from './actions';
import PaymentCashFlowReducer from '../../../../../../App/PaymentQuotationCashflow/Core/reducer';

const initialState = {
  list: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case QUOTATIONS_LIST__SET_DATA:
      return { ...state, list: payload };
    default:
      return state;
  }
};

export default combineReducers({
  root: reducer,
  cashFlow: PaymentCashFlowReducer,
});
