import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import { Input, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

import Styles from './ContractsEvalproGridDisplacementFormatter.module.scss';

const ContractsEvalproGridDisplacementFormatter: React.FC<DataTypeProvider.ValueEditorProps> = ({
  onValueChange,
  value,
  // @ts-ignore
  autoFocus,
  // @ts-ignore
  onBlur,
  // @ts-ignore
  onKeyDown,
}) => {
  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        onBlur();
      }
    }, 0);
  };

  return (
    <div className={Styles.PlusInput} onBlur={handleBlur}>
      <IconButton
        size="small"
        className={Styles.Icon}
        onClick={() => {
          onValueChange(+value - 1);
        }}
      >
        <Remove />
      </IconButton>
      <NumberFormat
        className={Styles.Input}
        customInput={Input}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        value={value}
        thousandSeparator
        fixedDecimalScale
        onValueChange={(values) => {
          onValueChange(values.floatValue);
        }}
        decimalScale={0}
        // @ts-ignore
        size="small"
        disableUnderline
      />
      <IconButton
        className={Styles.Icon}
        size="small"
        onClick={() => {
          onValueChange(+value + 1);
        }}
      >
        <Add />
      </IconButton>
    </div>
  );
};

export default ContractsEvalproGridDisplacementFormatter;
