import { useCallback, useState } from 'react';

export function anchorPositionByAnchorEl(event) {
  const elementDetailedPosition = event.currentTarget.getBoundingClientRect();
  const anchorPosition = {
    left: elementDetailedPosition.left + elementDetailedPosition.width / 2,
    top: elementDetailedPosition.top + elementDetailedPosition.height,
  };
  return anchorPosition;
}

function usePopoverForCashflowTable(onRowChange) {
  const [anchorElementForDetails, setAnchorElement] = useState(null);
  const [isOpenForDetails, setIsOpen] = useState(false);
  const [date, updateDate] = useState(null);
  const [columnAndRow, updateColumnAndRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const onSelectForDetails = useCallback(
    (event, row = null, columnName = null) => {
      const popoverText = row[columnName];
      const anchorPosition = anchorPositionByAnchorEl(event);
      if (popoverText) {
        setAnchorElement(anchorPosition);
        setIsOpen(true);
        updateDate(row[columnName]);
        updateColumnAndRow({ column: columnName, row });
      }
    },
    []
  );

  const onCloseForDetails = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const updateDateWithEventForDetails = (momentDate) => {
    const newDate = momentDate.valueOf();
    updateDate(newDate);
  };

  const idForPopoverDetails = isOpenForDetails ? 'simple-popover-id-for-anchor' : undefined;

  const dateForDetails = date;

  return {
    anchorElementForDetails,
    isOpenForDetails,
    idForPopoverDetails,
    editingDate: date,
    onSelectForDetails,
    onCloseForDetails,
    updateDateWithEventForDetails,
    dateForDetails,
  };
}

export default usePopoverForCashflowTable;
