import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useGraphicMaterialRowsMapper from '../../../Core/customHooks/useGraphicMaterialRowsMapper';
import GraphicMaterialRow from '../rows/GraphicMaterialRow';
import usePropertyType from '../../../Core/customHooks/usePropertyType';

const useStyles = makeStyles({
  Container: {
    maxHeight: '90vh',
  },
});

const GraphicMaterialTable = () => {
  const { rows, columns, updateRows } = useGraphicMaterialRowsMapper();
  const { propertyTypes, setPropertyTypes, resolveType } = usePropertyType();

  const styles = useStyles();
  return (
    <TableContainer component={Paper} className={styles.Container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((columnHeader, index) => (
              <TableCell
                key={`columnHeader-${index}`}
                align={columnHeader.align}
                width={columnHeader.width}
              >
                {columnHeader.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <GraphicMaterialRow
              key={row.id}
              property={row}
              type={resolveType(row && row.type ? row.type: '')}
              propertyTypes={propertyTypes}
              updatePropertyTypes={setPropertyTypes}
              updateRows={updateRows}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GraphicMaterialTable;
