import ClientsServicesDefinition from './ClientsServicesDefinition';
import Services from '../services';

export default class ClientsServices extends Services {
  searchClients(towerId, query) {
    return this.get(ClientsServicesDefinition.searchClients(towerId, query));
  }

  searchClientsWithProperty(towerId, propertyId, query) {
    return this.get(ClientsServicesDefinition.searchClientsWithProperty(towerId, propertyId, query));
  }

  getEnums(towerId) {
    return this.get(ClientsServicesDefinition.getEnums(towerId));
  }

  postClient<T>(towerId, data) {
    return this.post<T>(ClientsServicesDefinition.postClient(towerId), data);
  }

  putClient<T>(identityDocument, towerId, data) {
    return this.put<T>(ClientsServicesDefinition.putClient(identityDocument, towerId), data);
  }

  putClientAddress(towerId, clientId, data) {
    return this.put(ClientsServicesDefinition.updateAddressForClient(towerId), {
      ...data,
      clientId,
    });
  }

  putAddressForAdditionalInfo(towerId, clientAdditionalInfoId, data) {
    return this.put(ClientsServicesDefinition.updateAddressForAdditionalInfo(towerId), {
      ...data,
      clientAdditionalInfoId,
    });
  }

  addClient(towerId, data) {
    return this.put(ClientsServicesDefinition.addClient(towerId), data);
  }

  getClients(towerId) {
    return this.get(ClientsServicesDefinition.getClients(towerId));
  }

  getClientsPaginate<T>(towerId, offset: number, limit: number, sort: { field: string; order: 'asc' | 'desc' }[]) {
    return this.get<T>(ClientsServicesDefinition.getClients(towerId), { params: { offset, limit, sort } });
  }

  getClientWithId<T>(towerId, clientId) {
    return this.get<T>(ClientsServicesDefinition.getClientWithId(towerId, clientId));
  }

  getClient(text, type, towerId) {
    return this.get(ClientsServicesDefinition.getClient(text, type, towerId));
  }

  getPropertyInfo(propertyId) {
    return this.get(ClientsServicesDefinition.getPropertyInfo(propertyId));
  }

  getPropertyFilesAndInfo(clientId, propertyId) {
    return this.get(ClientsServicesDefinition.getPropertyFilesAndInfo(clientId, propertyId));
  }

  addClientToTower(clientId, towerId) {
    return this.post(ClientsServicesDefinition.addClientToTower(clientId, towerId));
  }

  deleteClient(clientId, towerId) {
    return this.delete(ClientsServicesDefinition.deleteClient(clientId, towerId));
  }

  postPropertyFile(clientId, propertyId, form) {
    return this.post(ClientsServicesDefinition.postPropertyFile(clientId, propertyId), form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deletePropertyFile(propertyFileId) {
    return this.delete(ClientsServicesDefinition.deletePropertyFile(propertyFileId));
  }

  downloadPropertyFile(propertyFileId) {
    return this.get(ClientsServicesDefinition.downloadPropertyFile(propertyFileId), {
      responseType: 'arraybuffer',
    });
  }
}
