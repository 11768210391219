import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import Typography from '../../../shared/components/Typography/Typography';
import { Button } from '../../../shared/components/Button/Button';
import useContractEvents from '../Core/customHooks/useContractEvents';
import ContractEventsGrid from './Views/customViews/ContractEventsGrid';

import ContractEventFormModal from '../../ContractEventFormModal/UI/ContractEventFormMainView';
import { Event } from '../../../models/ContractPayments';

const ContractEventsMainView: FC = () => {
  const { currentContractEvent, setCurrentContractEvent, openContractEventForm, setOpenContractEventForm } =
    useContractEvents();

  return (
    <>
      <Box paddingY={3}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" fontSize="1.5rem" fontWeight="600">
              Hitos
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={() => {
                setOpenContractEventForm(true);
              }}
            >
              Nuevo Hito
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ContractEventsGrid
        onSeleccionChange={(contractEvent: Event) => {
          setCurrentContractEvent(contractEvent);
          setOpenContractEventForm(true);
        }}
      />
      <ContractEventFormModal
        key={'key-Contract-Events-Form-Dialog'}
        open={openContractEventForm}
        handleClose={() => {
          setCurrentContractEvent(undefined);
          setOpenContractEventForm(false);
        }}
        handleCreate={() => {}}
        currentContractEvent={currentContractEvent}
        currentContractId={0}
      />
    </>
  );
};

export default ContractEventsMainView;
