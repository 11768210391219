import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import { Radio, Typography } from '@material-ui/core';
import EditableCell from '../EditableCell';
import {
  CustomPaymentTableRow,
  CustomPaymentTableCell,
} from '../ReservationDeposit/CustomPaymentTableRow';
import { DEFAULT_DATE_FORMAT } from '../../../../../../helpers/dates';
import QuotationDiscountType from '../../../../../Quotation/Core/enums/QuotationDiscountType';
import useRealPaymentEditionType from '../../../../Core/customHooks/useRealPaymentEditionType';
import RealPaymentsFinalPaymentTypeRow from '../../../rows/QuotationFinalPaymentTypeRow';

const FinalFee = ({
  quotation,
  showEditableButtons,
  enableDiscountCalculation,
  onChange,
}) => {
  const {
    isEditingMode: editMode,
    isLockedType,
    updateDistributionIndex,
    finalFeeHandler,
    paymentTypes,
    onClickDiscount,
    discountType,
  } = useRealPaymentEditionType();

  const onChangeValue = (event) => {
    if (
      (quotation.finalPayment &&
        Number(event.target.value) !== Number(quotation.finalPayment)) ||
      Number(quotation.finalFee) !== Number(event.target.value)
    ) {
      onChange(event);
      finalFeeHandler(event.target.value);
    }
  };

  const currentIndex =
    paymentTypes && paymentTypes.length > 1 ? paymentTypes.length - 1 : 0;

  return (
    <CustomPaymentTableRow isEditing={editMode}>
      <TableCell>Cuota final</TableCell>

      <CustomPaymentTableCell isEditing={editMode && showEditableButtons}>
        <Typography>
          <RealPaymentsFinalPaymentTypeRow
            key={`QuotationPaymentTypeRow_final_${currentIndex}_assigned`}
            showEditableButtons={showEditableButtons}
          />
        </Typography>
      </CustomPaymentTableCell>

      <TableCell>
        {moment(Number(quotation.property.tower.schedule.averageDeliveryDate))
          .endOf('M')
          .format(DEFAULT_DATE_FORMAT)
          .toString()}
      </TableCell>
      <TableCell>
        <EditableCell
          name="finalFee"
          value={quotation.finalPayment}
          onChange={onChangeValue}
          showEditableButtons={editMode && showEditableButtons}
          onBlur={() => {
            if (discountType === QuotationDiscountType.ASSIGNED.code)
              onClickDiscount();
          }}
          onEnter={() => {
            if (discountType === QuotationDiscountType.ASSIGNED.code)
              onClickDiscount();
          }}
        />
      </TableCell>
      {editMode && enableDiscountCalculation && (
        <TableCell>
          <Radio
            checked={quotation.currentIndexForDiscount === quotation.periods}
            disabled={isLockedType}
            onChange={() => {
              updateDistributionIndex(quotation.periods);
            }}
            value="a"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        </TableCell>
      )}
    </CustomPaymentTableRow>
  );
};

FinalFee.propTypes = {
  value: PropTypes.number.isRequired,
  quotation: PropTypes.object,
  showEditableButtons: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dispatchFetchQuotationSuccess: PropTypes.func.isRequired,
  enableDiscountCalculation: PropTypes.bool,
};

export default FinalFee;
