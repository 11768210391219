import { useSelector } from 'react-redux';
import { Numbers } from '../../../../helpers';

function useArrearsDailyReportRowsMapper() {
  const { summaryReport } = useSelector(
    (state) => state.arrearsReport || {},
  );

  const { total, daysSummary } = summaryReport;
  const { commitments, payments } = total;
  const wallet = Numbers.cleanNumber(commitments - payments);

  const rowsMapped = Object.keys(daysSummary || {}).reduce(
    (accumulated, days) => {
      const newRow = {
        title: days !== 'max' ? `Cartera ${days} días` : 'Cartera >90 días',
        amount: daysSummary[days].amount,
        value: daysSummary[days].price,
        percentage: Numbers.cleanNumber(
          (daysSummary[days].price / wallet) * 100,
        ),
      };

      return {
        total: {
          title: 'Total',
          amount: newRow.amount + accumulated.total.amount,
          value: newRow.value + accumulated.total.value,
          percentage: newRow.percentage + accumulated.total.percentage,
        },
        rows: [...accumulated.rows, newRow],
      };
    },
    { total: { title: 'Total', amount: 0, value: 0, percentage: 0 }, rows: [] },
  );

  return [...rowsMapped.rows, rowsMapped.total];
}

export default useArrearsDailyReportRowsMapper;
