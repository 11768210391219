import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Numbers from '../../../../helpers/numbers';
import QuotationsServices from '../../../../services/Quotations';

const calculateYearDiscount = (fee) => {
  return Numbers.cleanNumber(
    Numbers.toFixed(
      ((1 + Numbers.cleanNumber(fee) / 100) ** (1 / 12) - 1) * 100,
    ),
  );
};

function useUpdateQuotationSetup(id, initialValue, handleChange) {
  const { enqueueSnackbar } = useSnackbar();
  const [fee, setQuotationFee] = useState(
    Numbers.cleanNumber(initialValue * 100),
  );
  const [previousFee, setPreviousFee] = useState(
    Numbers.cleanNumber(initialValue * 100),
  );

  const { towerId } = useParams();

  const updateQuotation = async (newFee) => {
    const services = new QuotationsServices();
    try {
      await services.putQuotationSetup(
        towerId,
        id,
        Numbers.cleanNumber(newFee / 100),
      );
      setPreviousFee(newFee);
      if (handleChange) {
        handleChange(newFee);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setQuotationFee(previousFee);
    }
  };

  useEffect(() => {
    if (fee !== initialValue) {
      setQuotationFee(Numbers.cleanNumber(initialValue) * 100);
    }
  }, [initialValue]);

  const updateFee = async (values) => {
    if (fee !== values.floatValue) {
      setQuotationFee(values.floatValue);
      await updateQuotation(values.floatValue);
    }
  };

  const calculatedDiscount = calculateYearDiscount(Numbers.cleanNumber(fee));

  const isAllowed = ({ floatValue }) => {
    if (floatValue === undefined) return true;
    return floatValue >= 0 && floatValue <= 100;
  };

  return { onChange: updateFee, isAllowed, fee, calculatedDiscount };
}

export default useUpdateQuotationSetup;
