import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Builder from './autoConstruction/Builder';
import DateCost from './autoConstruction/DateCost';
import {
  setOpen,
  setResetSelector,
} from '../../containers/TowerBuilder/action';
import styles from './TowerBuilder.module.scss';
import { setReset } from './actions';
import { towerBuilder } from './autoConstruction/Type/type.enum';
import { Alert, AlertTitle } from '@material-ui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TowerBuilderDetail = ({
  open,
  currentTower,
  onSetOpen,
  onSetReset,
  onSetResetSelector,
  constructionType,
}) => {
  const buildDateDifference = currentTower.schedule
    ? moment(Number(currentTower.schedule.endOfSalesDate))
        .add(1, 'M')
        .startOf('month')
        .diff(
          moment(Number(currentTower.schedule.constructionStartDate)).startOf(
            'month',
          ),
          'months',
        )
    : 0;
  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar classes={{ root: styles.appBar }}>
        <Toolbar classes={{ root: styles.toolBar }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onSetReset();
              onSetResetSelector();
              onSetOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Construcción</Typography>
        </Toolbar>
      </AppBar>
      <Divider />
      {buildDateDifference < 12 ? (
        <Alert severity="Warning">
          <AlertTitle>Cuidado</AlertTitle>
          El numero de meses debe ser minimo de 12 para poder reajustar, ve a
          configuracion Inicial, fecha Fin de la Construcción.
        </Alert>
      ) : null}
      <div className={styles.mainCont}>
        <Paper classes={{ root: styles.paperContainer }}>
          <Typography variant="h4" gutterBottom>
            {buildDateDifference}
            {` `}
            {buildDateDifference === 1 ? 'mes' : 'meses'}
          </Typography>
          <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            spacing={3}
          >
            {constructionType === towerBuilder.AUTOMATIC.code && <DateCost />}
            <Builder />
          </Grid>
        </Paper>
      </div>
    </Dialog>
  );
};

TowerBuilderDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  currentTower: PropTypes.object,
  onSetOpen: PropTypes.func.isRequired,
  onSetReset: PropTypes.func.isRequired,
  onSetResetSelector: PropTypes.func.isRequired,
  constructionType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  open: state.towerBuilder.root.open,
  currentTower: state.towerBuilder.root.currentTower,
  isTableVisible: state.towerBuilder.root.isTableVisible,
  constructionType: state.towerBuilder.builder.constructionType,
});

const mapDispatchToProps = {
  onSetOpen: setOpen,
  onSetReset: setReset,
  onSetResetSelector: setResetSelector,
};

export default connect(mapStateToProps, mapDispatchToProps)(TowerBuilderDetail);
