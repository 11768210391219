import moment from 'moment';
import { QuotationConfigDto } from '../DTO/QuotationConfigDto';

const useQuotationConfigFinalFeeForm = (originalQuotationConfig: QuotationConfigDto) => {
  const momentMaximumCollectionDate = moment(Number(originalQuotationConfig?.maximumCollectionDate));
  const momentAverageDeliveryDate = moment(Number(originalQuotationConfig?.averageDeliveryDate));
  const averageDeliveryDateIsBeforeOfMaximumCollectionDate =
    momentAverageDeliveryDate.isBefore(momentMaximumCollectionDate);

  return {
    ...originalQuotationConfig,
    averageDeliveryDateIsBeforeOfMaximumCollectionDate,
  };
};

export default useQuotationConfigFinalFeeForm;
