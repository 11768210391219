import React, { FC } from 'react';
import { DeedExpensesQueryClient } from 'App/DeedExpenses/core/reactQuery/DeedExpensesQueryClient';
import { QueryClientProvider } from 'react-query';
import DeedExpensesHeader from '../sections/DeedExpensesHeader/DeedExpensesHeader';
import EvalproDeedExpensesMainContainer from '../containers/EvalproDeedExpensesMainContainer/EvalproDeedExpensesMainContainer';

const EvalproDeedExpensesMainView: FC = () => {
  return (
    <QueryClientProvider client={DeedExpensesQueryClient}>
      <DeedExpensesHeader />
      <EvalproDeedExpensesMainContainer />
    </QueryClientProvider>
  );
};

export default EvalproDeedExpensesMainView;
