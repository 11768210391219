import React from 'react';
import { Box, Paper, Button } from '@material-ui/core';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import useArrearsReportLoading from '../Core/customHooks/useArrearsReportLoading';
import ArrearsTotalReportContainer from './Views/customViews/ArrearsTotalReportContainer';
import ArrearsTableReportContainer from './Views/customViews/ArrearsTableReportContainer';
import useDownloadArrearsReport from '../Core/customHooks/useDownloadArrearsReport';

const ArrearsReportMainView = () => {
  const { isLoading, setLoading } = useArrearsReportLoading();
  const downloadExcel = useDownloadArrearsReport(setLoading);
  return (
    <>
      {isLoading && <AbsoluteCircularProgress />}
      <Paper>
        <Box display="flex" flexDirection="column">
          <Button variant="outlined" color="primary" onClick={downloadExcel}>
            Descargar Excel
          </Button>
          <Box display="flex" height="90vh" overflow="false">
            <ArrearsTotalReportContainer />
            <ArrearsTableReportContainer />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ArrearsReportMainView;
