import React from 'react';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import CustomTypography from '../../../../components/Typography/CustomTypography';
import Styles from './AddClientModalMainView.module.scss';

const BuilderCreditFormHead = ({ title }) => {
  return (
    <>
      <Box pt={2} pb={2} display="flex">
        <Box style={{ width: '50%' }}></Box>
        <CustomTypography
          className={Styles.indicator}
          variant="form"
          color="bloack"
        >
          {title}
        </CustomTypography>
      </Box>
    </>
  );
};

BuilderCreditFormHead.propTypes = {
  title: PropTypes.string,
};

export default BuilderCreditFormHead;
