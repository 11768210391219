import { ChangeEvent } from 'react';

import { EditableFeatureField, EditableFieldOption } from '../interfaces/featureField.types';
import { UpdateSingleSelectFieldHook } from '../interfaces/useUpdateSingleSelectField.types';

const updateFieldTitle = (field: EditableFeatureField, title: string) => ({
  ...field,
  title,
});

const updateFieldOptions = (field: EditableFeatureField, options: EditableFieldOption[]) => ({
  ...field,
  options,
});

function useUpdateSingleSelectField({ editableField, onChange }: UpdateSingleSelectFieldHook) {
  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(updateFieldTitle(editableField, event.target.value));
  };

  const handleChangeOptions = (options: EditableFieldOption[]) => {
    onChange(updateFieldOptions(editableField, options));
  };

  return { handleChangeTitle, handleChangeOptions };
}

export default useUpdateSingleSelectField;
