import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Style from './SnackAlert.module.scss';
import Icon from '../../../shared/components/Icon/Icon';
import IconButton from '../../../shared/components/IconButton/IconButton';

interface SnackAlertProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  message: string;
}

// Use a state to show the snack and use onClick to hide it
const SnackAlert: FC<SnackAlertProps> = ({ onClick, message }) => {
  return (
    <Box className={Style.speedSalesAlert}>
      <Icon
        className={Style.exclamationPointIcon}
        variant={'exclamationPoint'}
        height={'20px'}
        width={'20px'}
        color={'#2D52D6'}
      />
      <Typography className={Style.alertTypography}>{message}</Typography>
      <IconButton
        className={Style.closeIcon}
        iconName="Clear"
        variant="ghost"
        size="small"
        aria-controls="close-lot-form"
        onClick={onClick}
      />
    </Box>
  );
};

export default SnackAlert;
