import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const Format = ({ inputRef, name, onChange, textAlign, ...other }) => {
  return (
    <NumberFormat
      {...other}
      style={{ textAlign }}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      prefix="$"
    />
  );
};

Format.propTypes = {
  inputRef: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  textAlign: PropTypes.string,
};

Format.defaultProps = {
  textAlign: 'left',
};

const PercentageInput = ({ field, width, ...rest }) => {
  return (
    <TextField
      style={{ width }}
      {...field}
      {...rest}
      color="primary"
      InputProps={{
        inputComponent: Format,
      }}
    />
  );
};

PercentageInput.defaultProps = {
  width: '100px',
};

PercentageInput.propTypes = {
  field: PropTypes.object,
  width: PropTypes.object,
};

export default PercentageInput;
