export const FETCH_PROJECT_STAGES = 'FETCH_PROJECT_STAGES';
export const UPDATE_FIELD = 'UPDATE_FIELD';

export const onUpdateField = (payload) => ({
  payload,
  type: UPDATE_FIELD,
});

export const setProjectStages = (payload) => ({
  payload,
  type: FETCH_PROJECT_STAGES,
});
