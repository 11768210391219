import { useState } from 'react';

function useGenericOpenModal() {
  const [isOpen, update] = useState();

  const onClose = () => update(false);

  const onOpen = () => update(true);

  return {
    isOpen,
    onClose,
    onOpen,
  };
}

export default useGenericOpenModal;
