import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Radio } from '@material-ui/core';
import EditableCell from '../Dialog/Payments/EditableCell';
import {
  CustomPaymentTableRow,
  CustomPaymentTableCell,
} from '../Dialog/Payments/ReservationDeposit/CustomPaymentTableRow';
import { DEFAULT_DATE_FORMAT } from '../../../../helpers/dates';
import useCurrentMonthForAssignedPayment from '../../Core/customHooks/useCurrentMonthForAssignedPayment';
import MonthlyRealPayment from '../rows/MonthlyRealPayment';
import RealPaymentTypeRow from '../rows/RealPaymentTypeRow';
import useRealPaymentEditionFinalType from '../../Core/customHooks/useRealPaymentEditionFinalType';
import useReserveForAssignedPayment from '../../Core/customHooks/useReserveForAssignedPayment';

const MonthlyEditablePayment = ({
  onChange,
  index,
  quotation,
  enableDiscountCalculation,
  dispatchFetchQuotationSuccess,
  payment,
}) => {
  const {
    isEditingMode: editMode,
    isLockedType,
    distributionHandler,
    updateDistributionIndex,
    onClickDiscount,
    initialDistribution,
  } = useRealPaymentEditionFinalType();

  const {
    date,
    typeId,
    isReal,
    shownAsReal,
  } = useCurrentMonthForAssignedPayment(
    quotation.paymentTypes,
    index,
    quotation.paymentStartDate,
    quotation.initialDate,
    quotation.lastRealPaymentDate,
  );

  const { onChangeValue, paymentName, onBlur } = useReserveForAssignedPayment(
    onChange,
    distributionHandler,
    payment,
    index,
    onClickDiscount,
    initialDistribution,
    isReal,
  );

  if (shownAsReal)
    return (
      <MonthlyRealPayment
        key={`MonthlyRealPayment-${index}`}
        editMode={editMode}
        enableDiscountCalculation={enableDiscountCalculation}
        date={date}
        price={payment}
        index={index}
      />
    );

  return (
    <CustomPaymentTableRow isEditing={editMode}>
      <TableCell>
        <Typography>{paymentName}</Typography>
      </TableCell>

      <CustomPaymentTableCell isEditing={editMode}>
        <Typography>
          <RealPaymentTypeRow
            key={`QuotationPaymentTypeRow_${index}_${typeId}`}
            index={index}
            typeId={typeId}
            quotation={quotation}
            dispatchFetchQuotationSuccess={dispatchFetchQuotationSuccess}
          />
        </Typography>
      </CustomPaymentTableCell>

      <TableCell>{date.format(DEFAULT_DATE_FORMAT).toString()}</TableCell>

      <TableCell>
        <EditableCell
          name={index + 1}
          value={payment}
          showEditableButtons={editMode}
          onChange={onChangeValue}
          onBlur={onBlur}
          onEnter={onBlur}
        />
      </TableCell>
      {editMode && enableDiscountCalculation && (
        <TableCell>
          <Radio
            disabled={isLockedType}
            checked={quotation.currentIndexForDiscount === index}
            onChange={() => {
              updateDistributionIndex(index);
            }}
            value="a"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        </TableCell>
      )}
    </CustomPaymentTableRow>
  );
};

MonthlyEditablePayment.propTypes = {
  quotation: PropTypes.shape({
    paymentStartDate: PropTypes.string,
    proposalPaymentStartDate: PropTypes.string,
    distribution: PropTypes.array,
    paymentTypes: PropTypes.array,
    currentIndexForDiscount: PropTypes.any,
    discountPrice: PropTypes.any,
    initialDate: PropTypes.any,
    lastRealPaymentDate: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  payment: PropTypes.number,
  showEditableButtons: PropTypes.bool,
  enableDiscountCalculation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dispatchFetchQuotationSuccess: PropTypes.func.isRequired,
};

export default MonthlyEditablePayment;
