import React, { FC, RefObject } from 'react';
import { MenuItem } from '@material-ui/core';
import CustomPopover, { CustomPopoverContentProps } from '../../../../../shared/components/CustomPopover/CustomPopover';
import Typography from '../../../../../shared/components/Typography/Typography';

import { Contract } from '../../../../../models/Contracts';
import useContractPaymentScheduleGridInterestPopoverActions, {
  UseContractPaymentScheduleGridInterestPopoverActionsProps,
} from '../../../Core/customHooks/useContractPaymentScheduleGridInterestPopoverActions';

import styles from './ContractPaymentScheduleGridPopover.module.scss';

type ContractsPopoverContentProps = CustomPopoverContentProps<
  UseContractPaymentScheduleGridInterestPopoverActionsProps,
  Contract
>;

const ContractPaymentScheduleGridPopoverContent: FC<ContractsPopoverContentProps> = (props) => {
  const { payload: contract, extraProps, onClose: cb } = props;
  const { restartProjected } = useContractPaymentScheduleGridInterestPopoverActions({
    contract,
    cb,
    extraProps,
  });
  return (
    <>
      <span className={styles.menuTopLeft}></span>
      <MenuItem
        onClick={restartProjected}
        dense
        classes={{
          root: styles.menuItem,
        }}
      >
        <Typography variant="caption">Restaurar proyectado</Typography>
      </MenuItem>
    </>
  );
};

interface Props extends UseContractPaymentScheduleGridInterestPopoverActionsProps {
  elementRef: RefObject<HTMLDivElement>;
}

const ContractPaymentScheduleGridInterestPopover: FC<Props> = ({ elementRef, ...props }) => {
  return (
    <CustomPopover<UseContractPaymentScheduleGridInterestPopoverActionsProps, Contract>
      elementRef={elementRef}
      contentComponent={ContractPaymentScheduleGridPopoverContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      extraProps={props}
      classes={{
        paper: styles.popoverRoot,
      }}
    />
  );
};

export default ContractPaymentScheduleGridInterestPopover;
