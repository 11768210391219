import { useSnackbar } from 'notistack';

import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';
import StagesServices from '../../services/StagesServices';
import StagesDTO from '../DTO/StagesDTO';
import { DeleteStageHandler } from '../types/Stages.types';

const services = new StagesServices();

const getSuccessMessage = (stageName: string) => `${stageName} eliminada correctamente`;

const hasTowers = (totalTowers: number) => totalTowers > 0;

const getStageTowersAlertDescription = (stageName: string): string =>
  `Si desea eliminar la ${stageName} deberá mover las torres que contiene o eliminarlas.`;

type UpdateStages = (stages: StagesDTO) => void;

type UseDeleteStageOptions = {
  showProgress: () => void;
  hideProgress: () => void;
  updateStages: UpdateStages;
};

function useDeleteStage({ showProgress, hideProgress, updateStages }: UseDeleteStageOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessMessage = (stageName: string) =>
    enqueueSnackbar(getSuccessMessage(stageName), {
      autoHideDuration: 6000,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });

  const requestDeleteStage = async (stageId: number, stageName: string) => {
    try {
      showProgress();
      const { data } = await services.deleteStage(stageId);
      updateStages(data);
      showSuccessMessage(stageName);
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      hideProgress();
    }
  };

  const { showAlert: showTowersAlert } = useAlert({
    isOpen: false,
    title: 'Eliminar Etapa',
    variant: AlertVariants.AcceptOnly,
  });

  const showStageTowersAlert = (stageName: string) => {
    showTowersAlert(getStageTowersAlertDescription(stageName));
  };

  const tryDeleteStage = (stageId: number, stageName: string) => {
    requestDeleteStage(stageId, stageName);
  };

  const handleDeleteStage: DeleteStageHandler = (event) => {
    const { stageId, stageName, totalTowers } = event.currentTarget.dataset;

    hasTowers(totalTowers) ? showStageTowersAlert(stageName) : tryDeleteStage(stageId, stageName);
  };

  return handleDeleteStage;
}

export default useDeleteStage;
