import { API_PATH } from '../../config/config';

const ContractPaymentsServicesDefinition = {
  createContractPaymentInvoice: (contractId: number) => `${API_PATH}contracts/invoices/${contractId}`,
  restartProjected: (contractId: number) => `${API_PATH}contracts/invoices/restart-projected/${contractId}`,
  autofitProjected: (contractId: number) => `${API_PATH}contracts/invoices/autofit-projected/${contractId}`,
  interestRestartProjected: (contractId: number) => `${API_PATH}contracts/interest/restart-projected/${contractId}`,
  createContractInterest: (contractId: number) => `${API_PATH}contracts/interest/${contractId}`,
};

export default ContractPaymentsServicesDefinition;
