import { useEffect, useState } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { ForeignData } from '../../../../models/Contracts';
import { PaymentProjection } from '../../../../models/ContractPayments';

interface IPaymentProjectionOption {
  label: string;
  value: string;
  default: boolean;
}

interface ITowerValues {
  M: {
    label: string;
    value: number;
  };
  D: {
    label: string;
    value: number;
  };
  S: {
    label: string;
    value: number;
  };
}

const usePaymentProjection = ({ currentContractPayment, paymentProjectionsList }) => {
  const queryClient: QueryClient = useQueryClient();

  const foreignListData = queryClient.getQueryData<ForeignData>('contracts-foreign-list');

  const [statistics, setStatistics] = useState<ITowerValues | undefined>();

  useEffect(() => {
    if (foreignListData?.statistics) {
      const towerValues = {
        M: {
          label: '',
          value: 0,
        },
        D: {
          label: 'Total Costos directos',
          value: foreignListData?.statistics.totalDirectCost || 0,
        },
        S: {
          label: 'Total Ventas',
          value: foreignListData?.statistics.totalSales || 0,
        },
      };
      setStatistics(towerValues);
    }
  }, [foreignListData]);

  const paymentProjectionOptions: IPaymentProjectionOption[] = paymentProjectionsList.map(
    (item: PaymentProjection, index: number) => {
      return {
        value: item.id,
        label: item.name,
        default: currentContractPayment?.projectionType === item.id || (!!!currentContractPayment && index === 0),
      };
    },
  );

  return {
    paymentProjectionOptions,
    statistics,
  };
};

export default usePaymentProjection;
