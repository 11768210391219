import { API_PATH } from '../../../config/config';

const PaymentQuotationCashflowServicesDefinitions = {
  quotationCashflow: (towerId) => {
    return `${API_PATH}v2/quotations/cash-flow/${towerId}`;
  },
  donwloadQuotationCashflow: (towerId) => {
    return `${API_PATH}v2/quotations/download/cash-flow/${towerId}`;
  },
};

export default PaymentQuotationCashflowServicesDefinitions;
