import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import NumberFormat from 'react-number-format';
import useMaskForPhoneNumber from '../../../Core/customHooks/useMaskForPhoneNumber';
import TextFieldOutlined from 'components/TextFieldOutlined/TextFieldOutlined';

const InputWithTitleForClient = ({
  title,
  id,
  label,
  defaultValue,
  value,
  multiline,
  onChange,
  onClick,
  isNumber,
  prefix,
  isPhone,
  hasError,
  helperText,
  required,
}) => {
  const { mask, onChangeValue } = useMaskForPhoneNumber(onChange, id);
  return (
    <>
      {onClick && !isNumber && !isPhone && (
        <Button onClick={onClick}>
          <TextField
            id={id}
            fullWidth
            required={required}
            variant="outlined"
            name={id}
            label={title}
            defaultValue={defaultValue}
            value={value}
            multiline={multiline}
            onChange={onChange}
          />
        </Button>
      )}
      {!onClick && !isNumber && !isPhone && (
        <TextField
          id={id}
          fullWidth
          name={id}
          variant="outlined"
          label={title}
          required={required}
          defaultValue={defaultValue}
          value={value}
          multiline={multiline}
          onChange={onChange}
          error={hasError}
          helperText={hasError && helperText}
        />
      )}
      {isNumber && (
        <NumberFormat
          customInput={TextFieldOutlined}
          id={id}
          name={id}
          label={title}
          defaultValue={defaultValue}
          value={value}
          required={required}
          multiline={multiline}
          prefix={prefix || '$'}
          thousandSeparator
          onValueChange={(values) => {
            onChange({
              target: {
                name: id,
                value: values.floatValue,
              },
            });
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {isPhone && (
        <NumberFormat
          customInput={TextFieldOutlined}
          id={id}
          name={id}
          label={title}
          defaultValue={defaultValue}
          value={value}
          multiline={multiline}
          prefix={prefix || '$'}
          required={required}
          thousandSeparator
          onValueChange={onChangeValue}
          format={mask}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </>
  );
};

InputWithTitleForClient.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  isNumber: PropTypes.bool,
  isPhone: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  prefix: PropTypes.string,
};

export default InputWithTitleForClient;
