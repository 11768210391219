import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import { LOCAL_MONTH_NAMES } from '../../../../../helpers/dates';
import classes from '../table/TotalCashFlowTable.module.scss';
import useReduceForHighlightedCell from '../../../Core/customHooks/useReduceForHighlightedCell';
import CellContentForVirtualTableCell from './CellContentForVirtualTableCell';

export const OPERATION_FLOW_ACUM = 'Flujo Operativo Acum';
export const OPERATION_FLOW = 'Flujo Operativo';
export const PER_THOUSAND = 'perThousand';
export const BUILDER_CREDIT_PAYMENTS = 'builderCreditPayments';
export const BUILDER_CREDIT_DISBURSEMENTS = 'builderCreditDisbursement';

const validateOperationFlows = (name) =>
  name === OPERATION_FLOW_ACUM || name === OPERATION_FLOW;

const editableMonth = moment().subtract(1, 'months');

const HighlightedCell = ({
  tableRow,
  tableColumn,
  onDoubleClick,
  isFixed,
  onSelect,
  onSelectForDetails,
  onClose,
  idForPopover,
  divider,
  ...props
}) => {
  const { row } = tableRow;
  const { name } = tableColumn.column;
  const value = row[name] || 0;

  const { accumulatedForCell } = useReduceForHighlightedCell({ row, name });

  const accumulatedObject = accumulatedForCell();

  const className = [
    props.className,
    classes.height,
    row.parentId === null && row.subParentId === null ? classes.groupRow : '',
    row.parentId && row.subParentId === null ? classes.subGroupRow : '',
    row.id === 'ACCUMULATED_ID' ? classes.cashBalance : '',
    row.id === 'investmentBalance' ? classes.builderCreditBalance : '',
    row.id === 'OPERATING-FLOW' || row.id === 'MONTH_BALANCED_ID'
      ? classes.boldRows
      : '',
    row.parentId && row.subParentId ? classes.itemRow : '',
    row.id === 'financing-credit-balance' ? classes.builderCreditBalance : '',
    row.subParentId === 'financing-credit-balance'
      ? classes.subBuilderCreditBalance
      : '',
  ];

  const extraProps = {
    onDoubleClick: null,
  };

  if (
    (row.id === PER_THOUSAND && row.name === '4 * mil') ||
    (row.id === 'financialPerformance' &&
      row.name === 'Rendimientos Financieros') ||
    (row.edit && tableColumn.column.name !== 'name' && !isFixed)
  ) {
    const monthName = name.slice(0, 3);
    const currentMonth = moment(
      name.replace(monthName, LOCAL_MONTH_NAMES[monthName]),
    );
    if (currentMonth.isBefore(editableMonth) || row.edit) {
      className.push(classes.editCell);
      extraProps.tabIndex = new Date().getUTCMilliseconds();
      extraProps.onContextMenu = onSelect(row, name);
      extraProps.onDoubleClick = onDoubleClick;
    }
  }

  return (
    <VirtualTable.Cell
      {...props}
      className={className}
      {...extraProps}
      value={
        <CellContentForVirtualTableCell
          row={row}
          name={name}
          value={value}
          divider={divider}
          accumulatedObject={accumulatedObject}
        />
      }
    />
  );
};

export default HighlightedCell;
