import React, { FC } from 'react';
import { CardHeader, Drawer, Toolbar, Box } from '@material-ui/core';
import Styles from './MonthlyInterestSummaryDrawer.module.scss';
import MonthlyInterestArrearsIndicator from '../MonthlyInterestArrearsIndicator/MonthlyInterestArrearsIndicator';
import MonthlyInterestFinancialSummary from '../MonthlyInterestFinancialSummary/MonthlyInterestFinancialSummary';
import MonthlyInterestRates from '../MonthlyInterestRates/MonthlyInterestRates';
import MonthlyInterestSummaryPayment from '../MonthlyInterestSummaryPayment/MonthlyInterestSummaryPayment';
import MonthlyInterestSummaryPaymentTable from '../../tables/MonthlyInterestSummaryPaymentTable';
import WalletMonthlyReportDTO from '../../../core/DTO/WalletMonthlyReportDTO';
import MonthlyInterestBuyingDetail from '../MonthlyInterestBuyingDetail/MonthlyInterestBuyingDetail';
import MonthlyInterestCommitmentDetail from '../MonthlyInterestCommitmentDetail/MonthlyInterestCommitmentDetail';

interface MonthlyInterestSummaryDrawerProps {
  walletMonthlyReportDTO: WalletMonthlyReportDTO | null;
  hasToolbar: boolean;
}

const MonthlyInterestSummaryDrawer: FC<MonthlyInterestSummaryDrawerProps> = ({
  walletMonthlyReportDTO,
  hasToolbar,
}) => {
  return (
    <div className={Styles.drawer}>
      {hasToolbar && <Toolbar />}
      <MonthlyInterestArrearsIndicator {...(walletMonthlyReportDTO ?? {})} />
      <MonthlyInterestFinancialSummary {...(walletMonthlyReportDTO ?? {})} />
      <MonthlyInterestSummaryPayment title="Tasas">
        <MonthlyInterestRates {...(walletMonthlyReportDTO?.walletFees ?? {})} />
      </MonthlyInterestSummaryPayment>
      <MonthlyInterestSummaryPayment title="Detalles de compras">
        <MonthlyInterestBuyingDetail {...(walletMonthlyReportDTO ?? {})} />
      </MonthlyInterestSummaryPayment>
      <MonthlyInterestSummaryPayment title="Compromisos a la fecha">
        <MonthlyInterestCommitmentDetail {...(walletMonthlyReportDTO ?? {})} />
      </MonthlyInterestSummaryPayment>
      <MonthlyInterestSummaryPayment title="Resumen de Pagos">
        <MonthlyInterestSummaryPaymentTable commitmentPayments={walletMonthlyReportDTO?.commitmentPayments ?? []} />
      </MonthlyInterestSummaryPayment>
    </div>
  );
};

export default MonthlyInterestSummaryDrawer;
