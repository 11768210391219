import ContractPaymentsScheduleServicesDefinition from './ContractPaymentsScheduleServicesDefinition';
import Services from '../services';
import { ContractPaymentSchedule } from '../../models/ContractPayments';

export default class ContractPaymentsScheduleService extends Services {
  createContractPaymentSchedule(contractPaymentId: number, data) {
    return this.post<ContractPaymentSchedule>(
      ContractPaymentsScheduleServicesDefinition.createContractPaymentSchedule(contractPaymentId),
      data,
    );
  }

  editContractPaymentSchedule(contractPaymentScheduleId: number, data) {
    return this.put<ContractPaymentSchedule>(
      ContractPaymentsScheduleServicesDefinition.editContractPaymentSchedule(contractPaymentScheduleId),
      data,
    );
  }
  bulkEditContractPaymentSchedule(contractPaymentId: number, data) {
    return this.put<ContractPaymentSchedule>(
      ContractPaymentsScheduleServicesDefinition.bulkEditContractPaymentSchedules(contractPaymentId),
      data,
    );
  }
}
