function useRowsForTotalCashFlow(salesRows, dates) {
  const salesRowsFormatted = salesRows.map((row) => {
    const customRow = { ...row };
    customRow.group = null;
    customRow.item = null;
    customRow.accumulated = customRow.accumulated || 0;
    customRow.projected = customRow.projected || 0;
    customRow.total = customRow.total || 0;
    return customRow;
  });
  const rows = salesRowsFormatted;
  return rows;
}

export default useRowsForTotalCashFlow;
