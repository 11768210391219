import React, { FC } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { AreaEditionTablePopoverProps } from '../../../Core/customHooks/AreaEditionTable/AreaEditionTablePopoverProps';
import useAreaEditionTablePopover from '../../../Core/customHooks/AreaEditionTable/useAreaEditionTablePopover';
import styles from './AreaHeaderTablePopover.module.scss';
import AreaTablePopover from './AreaTablePopover';

const AreaEditionTablePopover: FC<AreaEditionTablePopoverProps> = (props) => {
  const { handleClickRemoveColumn } = useAreaEditionTablePopover(props);
  const column = props.areaType;
  return (
    <AreaTablePopover isVisible={props.areaType && !props.areaType?.primary}>
      <MenuList>
        <MenuItem
          onClick={() => {
            if (column) props.updateColumn(column);
          }}
        >
          Cambiar nombre
        </MenuItem>
        <MenuItem
          classes={{
            root: styles.menuItemDanger,
          }}
          onClick={handleClickRemoveColumn}
        >
          Eliminar columna
        </MenuItem>
      </MenuList>
    </AreaTablePopover>
  );
};

export default AreaEditionTablePopover;
