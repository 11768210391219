import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Loading from '../../components/UI/Loader';
import Card, { CardHeader, CardBody } from '../../components/UI/Card/Card';
import {
  setApiLoading,
  setClients,
  setProperties,
  setMaxClientLength,
  setTowerId,
  setAllClients,
} from './actions';
import Services from '../../services/clientProperty/clientPropertyServices';
import TablePairing from '../../components/ClientProperty';
import GotoSalesRoom from '../../components/ClientProperty/GotoSalesRoom';
import AbsoluteCircularProgress from '../../App/TotalCashFlow/UI/styles/AbsoluteCircularProgress';

const services = new Services();

export const ClientProperty = ({
  loadingContainer,
  isLoading,
  onSetApiLoading,
  onSetClients,
  onSetProperties,
  onSetMaxClientLength,
  onSetTowerId,
  onSetAllClients,
  match,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openAndGotoSalesRoom, setOpenGotoSalesRoom] = useState(false);

  const cleanClients = (clients) => {
    const tempClean = clients.reduce((prev, current) => {
      if (current.properties.length >= 1) {
        return [...prev, current];
      }
      return [...prev, null];
    }, []);
    return tempClean.filter((n) => n);
  };

  const calculateMaxClientColumn = (clientsArray) => {
    const properties = clientsArray.flatMap((currentClient) =>
      currentClient.properties ? currentClient.properties : [],
    );
    const repeated = properties.reduce(
      (prev, current) => ({
        ...prev,
        [current.propertyId]: (prev[current.propertyId] || 0) + 1,
      }),
      {},
    );
    const countRepeated = Object.keys(repeated).flatMap((el) => repeated[el]);
    const maxRepeated = _.max(countRepeated);
    onSetMaxClientLength(maxRepeated > 0 ? maxRepeated : 1);
  };

  async function fetch() {
    try {
      onSetApiLoading(true);
      onSetTowerId(match.params.towerId);
      const clientsRecived = await services.getClientsFrom(
        match.params.towerId,
      );
      if (clientsRecived.data.length >= 0) {
        onSetAllClients(clientsRecived.data);
        const cleanedClients = cleanClients(clientsRecived.data);
        const sortedClients = _.orderBy(
          cleanedClients,
          [
            (currentClient) =>
              Number(currentClient.name)
                ? Number(currentClient.name)
                : currentClient.name,
          ],
          ['asc'],
        );
        onSetClients(sortedClients);
        calculateMaxClientColumn(sortedClients);
        const propertiesRecived = await services.getPropertiesFrom(
          match.params.towerId,
        );
        if (
          propertiesRecived.data &&
          propertiesRecived.data.properties.length >= 1
        ) {
          onSetProperties(
            _.orderBy(
              propertiesRecived.data.properties,
              ['floor', 'location'],
              ['asc', 'asc'],
            ),
          );
          onSetApiLoading(false);
        } else {
          onSetApiLoading(false);
          setOpenGotoSalesRoom(true);
        }
      } else {
        onSetApiLoading(false);
        setOpenGotoSalesRoom(true);
      }
    } catch (error) {
      onSetApiLoading(true);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }
  useEffect(() => {
    fetch();
  }, []);
  return (
    <Loading isLoading={loadingContainer}>
      <GotoSalesRoom
        open={openAndGotoSalesRoom}
        towerId={match.params.towerId}
      />
      <Card>
        <CardHeader>
          <span>Clientes</span>
        </CardHeader>
        <CardBody>
          {isLoading && (
            <AbsoluteCircularProgress leftPercentage={90} topPercentage={20} />
          )}
          <TablePairing />
        </CardBody>
      </Card>
    </Loading>
  );
};

ClientProperty.propTypes = {
  loadingContainer: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSetApiLoading: PropTypes.func.isRequired,
  onSetClients: PropTypes.func.isRequired,
  onSetProperties: PropTypes.func.isRequired,
  onSetMaxClientLength: PropTypes.func.isRequired,
  onSetTowerId: PropTypes.func.isRequired,
  onSetAllClients: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const newState = {
    loadingContainer: state.clientProperty.root.loadingContainer,
    isLoading: state.clientProperty.searcher.isLoading,
  };
  return newState;
};

const mapDispatchToProps = {
  onSetApiLoading: setApiLoading,
  onSetClients: setClients,
  onSetProperties: setProperties,
  onSetMaxClientLength: setMaxClientLength,
  onSetTowerId: setTowerId,
  onSetAllClients: setAllClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProperty);
