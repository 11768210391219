import React, { FC } from 'react';
import Typography from 'shared/components/Typography/Typography';
import Styles from './MonthlyInterestFinancialSummary.module.scss';
import Numbers from '../../../../../helpers/numbers';
import WalletMonthlyReportDTO from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';

const MonthlyInterestFinancialSummary: FC<Partial<WalletMonthlyReportDTO>> = ({
  generated,
  summaryAppliedIncrement,
  toPay,
  alreadyPayed,
  stillInDebt,
}) => {
  return (
    <div className={Styles.Container}>
      <Typography variant="subtitle1" color="gray900">
        Intereses
      </Typography>
      <div className={Styles.ContainerDetail}>
        <div className={Styles.TypographyContainer}>
          <Typography variant="subtitle1" color="gray800" className={Styles.LeftTypography}>
            Generados
          </Typography>
          <Typography variant="body1" color="gray700" className={Styles.RightTypography}>
            {Numbers.currencyFormat(generated)}
          </Typography>
        </div>
        <div className={Styles.TypographyContainer}>
          <Typography variant="subtitle1" color="gray800" className={Styles.LeftTypography}>
            Aplicados
          </Typography>
          <Typography variant="body1" color="gray700" className={Styles.RightTypography}>
            {Numbers.currencyFormat(summaryAppliedIncrement)}
          </Typography>
        </div>
        <div className={Styles.TypographyContainer}>
          <Typography variant="subtitle1" color="gray800" className={Styles.LeftTypography}>
            Pagados
          </Typography>
          <Typography variant="body1" color="gray700" className={Styles.RightTypography}>
            {Numbers.currencyFormat(alreadyPayed)}
          </Typography>
        </div>
        <div className={Styles.TypographyContainer}>
          <Typography variant="subtitle1" color="gray800" className={Styles.LeftTypography}>
            Adeudados
          </Typography>
          <Typography variant="body1" color="gray700" className={Styles.RightTypography}>
            {Numbers.currencyFormat(stillInDebt)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MonthlyInterestFinancialSummary;
