import { useState } from 'react';
import { Numbers } from '../../../helpers';

const makeDynamicColumns = (currentColumns, dates) => {
  const newColumns = Object.keys(dates).reduce((array, column) => {
    if (
      column === 'id' ||
      column === 'parentId' ||
      column === 'type' ||
      column === 'name' ||
      column === 'accumulated' ||
      column === 'total' ||
      column === 'projected'
    ) {
      return array;
    }
    return [...array, { name: column, title: column }];
  }, currentColumns);
  return newColumns;
};

function useColumnsAndRowsForTableCashFlow(data) {
  const rows = data.map((row) => {
    const currentRow = Object.keys(row).reduce((customRow, key) => {
      if (
        key === 'id' ||
        key === 'parentId' ||
        key === 'type' ||
        key === 'name'
      ) {
        return customRow;
      }

      return { ...customRow, [key]: Numbers.currencyFormat(row[key]) };
    }, row);
    return currentRow;
  });
  const columnRows = rows[0] ? rows[0] : [];
  const [lengthColumns, setLengthColumns] = useState(
    Object.keys(columnRows).length,
  );

  const [columns, setColumns] = useState(
    makeDynamicColumns(
      [
        { name: 'type', title: 'Grupo' },
        { name: 'name', title: ' ' },
        { name: 'accumulated', title: 'Acumulado' },
        { name: 'projected', title: 'Proyectado' },
        { name: 'total', title: 'Total' },
      ],
      columnRows,
    ),
  );

  if (lengthColumns !== Object.keys(columnRows).length) {
    setColumns(
      makeDynamicColumns(
        [
          { name: 'type', title: 'Grupo' },
          { name: 'name', title: ' ' },
          { name: 'accumulated', title: 'Acumulado' },
          { name: 'projected', title: 'Proyectado' },
          { name: 'total', title: 'Total' },
        ],
        columnRows,
      ),
    );
    setLengthColumns(Object.keys(columnRows).length);
  }

  const [tableColumnExtensions] = useState([
    { columnName: 'type', width: 200 },
    { columnName: 'name', width: 150 },
    { columnName: 'accumulated', width: 100 },
    { columnName: 'projected', width: 150 },
    { columnName: 'total', width: 150 },
  ]);

  const [leftColumns] = useState([
    'type',
    'name',
    'accumulated',
    'projected',
    'total',
  ]);

  const isParent = (row) => {
    return row && !row.parentId;
  };

  const getChildrenRows = (row, rootRows) => {
    const childRows = rootRows.filter((nextRow) => {
      if (isParent(row)) {
        return nextRow.parentId === row.id;
      }
      return nextRow.parentId === row;
    });
    return childRows.length ? childRows : null;
  };

  const [defaultExpandedRowIds] = useState([]);

  return {
    columns,
    rows,
    tableColumnExtensions,
    leftColumns,
    defaultExpandedRowIds,
    getChildrenRows,
  };
}

export default useColumnsAndRowsForTableCashFlow;
