import React from 'react';
import DocumentTemplatesTable from './views/DocumentTemplatesTable';
import AddDocumentTemplate from './views/AddDocumentTemplate';
import {
  Tooltip,
  Fab
} from '@material-ui/core';
import {
  Add as AddIcon
} from '@material-ui/icons';
import {
  makeStyles
} from '@material-ui/core/styles';
import useDocumentTemplatesBehavior from '../Core/customHooks/useDocumentTemplatesBehavior';

const useStyles = makeStyles((theme) => ({
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const DocumentTemplatesMainView = () => {
  const classes = useStyles();
  const {
    templates,
    addTemplateVisible,
    toggleAddTemplate,
    deleteTemplate,
    uploadTemplate
  } = useDocumentTemplatesBehavior();

  return (
    <>
      <DocumentTemplatesTable
        templates={templates}
        deleteTemplate={deleteTemplate}
      />
      <AddDocumentTemplate
        open={addTemplateVisible}
        onClose={toggleAddTemplate}
        uploadTemplate={uploadTemplate}
      />
      <Tooltip title="Agregar" aria-label="agregar">
        <Fab
          color="secondary"
          className={classes.absolute}
          onClick={toggleAddTemplate}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </>
  );
};

export default DocumentTemplatesMainView;
