import { useEffect, useState } from 'react';
import SalesRoomService from '../../../../services/salesRoom/salesRoomService';

const service = new SalesRoomService();

const useWaitingList = ({
  propertyId,
  clientId,
  setClientInQueue,
  setWaitingListSize,
  isOpen
}) => {
  const [waitingList, setWaitingList] = useState([]);
  const [maxDiffDays, setMaxDiffDates] = useState(0);
  const [clientDiffDays, setClientDiffDays] = useState({});

  const [forceModalOpen, setForceModalOpen] = useState(false);
  const [deleteClientModalOpen, setDeleteClientModalOpen] = useState(false);
  const [modalActionLoading, setModalActionLoading] = useState(false);
  const [clientForDelete, setClientForDelete] = useState();

  const fetch = async () => {
    if (propertyId) {
      const { data } = await service.getWaitingList({ propertyId });
      if (Array.isArray(data) && data.length)
        setWaitingList(
          data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
        );
      else setWaitingList([]);

      setWaitingListSize(data.length);
    }
  };

  const resolveExpiresDays = (id) => clientDiffDays[id];

  const resolveExpiresPercentageDays = (id) =>
    ((resolveExpiresDays(id) + 1) * 100) / (maxDiffDays + 2);

  const resolveDates = () => {
    if (Array.isArray(waitingList) && waitingList.length) {
      const currentDate = new Date();
      let maxDiffDaysResult = 0;
      let clientInQueue;
      const diffDays = waitingList.reduce((init, el, index) => {
        const current = init;
        current[el.clientId] = 0;
        if (clientId && clientId === el.clientId)
          clientInQueue = { ...el, index };
        if (el.deadlineDate) {
          const date = new Date(el.deadlineDate);
          const diffTime = date.getTime() - currentDate.getTime();
          const diffDaysTime = diffTime / (1000 * 3600 * 24);
          const diffDaysFloor = Math.floor(diffDaysTime);
          if (diffDaysFloor > maxDiffDays) maxDiffDaysResult = diffDaysFloor;
          current[el.clientId] = diffDaysFloor;
        }
        return init;
      }, {});
      setClientInQueue(clientInQueue);
      setClientDiffDays(diffDays);
      setMaxDiffDates(maxDiffDaysResult);
    } else setClientInQueue();
  };

  const openForceModal = () => setForceModalOpen(true);
  const closeForceModal = () => setForceModalOpen(false);

  const openDeleteClientModal = async (client) => {
    setClientForDelete(client);
    await fetch();
    setDeleteClientModalOpen(true);
  };

  const closeDeleteClientModal = () => {
    setClientForDelete();
    setDeleteClientModalOpen(false);
  };

  const onConfirmForceModal = async () => {
    setModalActionLoading(true);
    await service.deleteWaitingList({ propertyId, clientId });
    await fetch();
    setModalActionLoading(false);
    setForceModalOpen(false);
  };

  const onConfirmDeleteClient = async () => {
    setModalActionLoading(true);
    await service.deleteClienToWaitingList({
      propertyId,
      clientId: clientForDelete.clientId,
    });
    await fetch();
    setModalActionLoading(false);
    setDeleteClientModalOpen(false);
  };

  useEffect(() => {
    fetch();
  }, [isOpen]);
  
  useEffect(() => {
    resolveDates();
  }, [waitingList]);

  return {
    waitingList,
    resolveExpiresDays,
    resolveExpiresPercentageDays,
    maxDiffDays,
    forceModalOpen,
    openForceModal,
    closeForceModal,
    onConfirmForceModal,
    modalActionLoading,
    openDeleteClientModal,
    closeDeleteClientModal,
    deleteClientModalOpen,
    onConfirmDeleteClient,
    clientForDelete,
  };
};

export default useWaitingList;
