import { useMutation, useQueryClient } from 'react-query';
import { ContractInterest } from '../../../../models/ContractPayments';
import Services from '../../../../services/ContractPaymentsInvoice/ContractPaymentsInvoiceServices';

const services = new Services();

const useAddContractInterest = (contractId: number) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addContractInterest } = useMutation(
    (contractInterest: ContractInterest) => services.createContractInterest(contractId, contractInterest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
      },
    },
  );

  return {
    addContractInterest,
  };
};

export default useAddContractInterest;
