import { Column, Table } from '@devexpress/dx-react-grid';

const propertiesColumns: Column[] = [
  {
    name: 'name',
    title: 'Nomenclatura',
  },
  {
    name: 'groupName',
    title: 'Grupos',
  },
  {
    name: 'isSold',
    title: 'Estado',
  },
];

const propertiesColumnExtensions: Table.ColumnExtension[] = [
  {
    columnName: 'name',
    width: 130,
    align: 'center',
  },
  {
    columnName: 'isSold',
    width: 156,
  },
];

const statusColumnsNames: string[] = ['isSold'];

export { propertiesColumns, propertiesColumnExtensions, statusColumnsNames };
