import { API_PATH } from '../../config/config';

const QUOTATIONS = 'quotations';

const ClientsServiceDefinitions = {
  postTempQuotation: (towerId) => `${API_PATH}${QUOTATIONS}/towers/${towerId}`,
  putQuotationToPermanent: (quotationId) =>
    `${API_PATH}${QUOTATIONS}/${quotationId}`,
  putQuotationPaymentTypes: () =>
    `${API_PATH}${QUOTATIONS}/update/quotation-payment-types`,
  deleteQuotationPaymentTypes: () =>
    `${API_PATH}${QUOTATIONS}/delete/quotation-payment-type`,
  deleteFinalPaymentType: () =>
    `${API_PATH}${QUOTATIONS}/delete/final-payment-type`,
  putQuotationFinalPaymentTypes: () =>
    `${API_PATH}${QUOTATIONS}/update/quotation-final-payment-types`,
  putQuotationAssignment: (quotationId) =>
    `${API_PATH}${QUOTATIONS}/assignment/${quotationId}`,
  putPaymentPlanAssignment: () =>
    `${API_PATH}${QUOTATIONS}/update/assign-payment-plan-edited`,
  putQuotationSetup: () => `${API_PATH}${QUOTATIONS}/update/setup`,
  putQuotation: (quotationId) =>
    `${API_PATH}${QUOTATIONS}/update/${quotationId}/fields`,
  putQuotationDeedExpense: () =>
    `${API_PATH}${QUOTATIONS}/update/setup/deed-expense`,
  getClientQuotations: (clientId, propertyId, towerId) =>
    `${API_PATH}${QUOTATIONS}/clients/${clientId}/${propertyId}/${towerId}`,
  getQuotation: (quotationId, towerId) =>
    `${API_PATH}${QUOTATIONS}/${quotationId}/${towerId}`,
  getQuotationWithRealPayments: (quotationId, towerId) =>
    `${API_PATH}${QUOTATIONS}/payment-plan/${quotationId}/${towerId}`,
  getQuotationSetupByTowerId: (towerId) =>
    `${API_PATH}${QUOTATIONS}/${towerId}`,
  deleteQuotation: (quotationId) => `${API_PATH}${QUOTATIONS}/${quotationId}`,
  putDistribution: (quotationId) =>
    `${API_PATH}${QUOTATIONS}/${quotationId}/distribution`,
  getSchemaTemplate: (towerId, quotationId, startDate, financialDiscount) =>
    `${API_PATH}${QUOTATIONS}/template/${towerId}/${quotationId}/${startDate}/${financialDiscount}`,
  postSchemaTemplate: (towerId, quotationId, financialDiscount) =>
    `${API_PATH}${QUOTATIONS}/template/${towerId}/${quotationId}/${financialDiscount}`,
  patchQuotation: (quotationId) => `${API_PATH}${QUOTATIONS}/${quotationId}`,
  quotationDistribution: () =>
    `${API_PATH}${QUOTATIONS}/distribution-discount/`,
  assignedDiscountDistribution: () =>
    `${API_PATH}${QUOTATIONS}/distribution-discount-payments/`,
  createPaymentPlanEdited: () =>
    `${API_PATH}${QUOTATIONS}/create-payment-plan-edited`,
  downloadPDF: () => `${API_PATH}${QUOTATIONS}/pdf-download`,
  downloadAssignedPaymentPDF: () =>
    `${API_PATH}${QUOTATIONS}/download-payment-plan-pdf`,
};

export default ClientsServiceDefinitions;
