import { useState } from 'react';
import useAlert from '../../../../shared/components/Alert/Core/customHooks/useAlert';

function useClientFormUpdaterForClientProperty(
  clientEditing: boolean,
  currentClient: { id: string },
  currentClientProperty: { percentage: number; isContact: boolean },
) {
  const [clientProperty, setClientProperty] = useState({
    clientId: clientEditing && currentClient ? currentClient.id : '',
    percentage: clientEditing && currentClientProperty ? currentClientProperty.percentage || 0 : 0,
    isContact: clientEditing && currentClientProperty ? currentClientProperty.isContact || false : false,
  });

  const { showAlert } = useAlert({
    isOpen: false,
    title: '¿Está seguro de que quiere seleccionar este cliente como principal?',
    cancelAction: () => {
      setClientProperty({ ...clientProperty, isContact: false });
    }
  });

  const onChangeChecker = (event) => {
    setClientProperty({ ...clientProperty, isContact: event.target.checked });
    if (event.target.checked) {
      showModalHandler();
    }
  };

  const showModalHandler = () => {
    showAlert('Al hacer esto todos los pagos y la forma de pago se asociará a este cliente');
  };

  return { clientProperty, setClientProperty, onChangeChecker };
}

export default useClientFormUpdaterForClientProperty;
