import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FastField } from 'formik';
import NumberFormat from 'react-number-format';
import withFormikField from '../../../../../../../../HOC/withFormikField';
import PercentageInput from '../../../../../../../Quotations/Header/PropertyInfo/CurrencyInput';
import useQuotationEditionType from '../../../../../../../../App/Quotation/Core/customHooks/useQuotationEditionType';

const Field = withFormikField(PercentageInput);

const EditableCell = ({
  name,
  value,
  showEditableButtons,
  onChange,
  onEnter,
  onBlur,
}) => {
  const { isEditingMode: editMode } = useQuotationEditionType();

  const onKeyDown = (e) => {
    if (e.key === 'Enter') onEnter();
  };

  return editMode && showEditableButtons ? (
    <div>
      {onChange ? (
        <PercentageInput
          size="small"
          width="150px"
          name={name}
          variant="outlined"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      ) : (
        <FastField
          size="small"
          width="150px"
          component={Field}
          name={name}
          variant="outlined"
        />
      )}
    </div>
  ) : (
    <NumberFormat
      thousandSeparator
      prefix="$"
      displayType="text"
      value={Math.round(value)}
    />
  );
};

EditableCell.propTypes = {
  name: PropTypes.string.isRequired,
  showEditableButtons: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(EditableCell);
