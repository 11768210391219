import React, { useReducer, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ContainerContext from '../../../../containers/Client/context';
import reducer, { initialState } from '../reducer';
import {
  fetchAddToTowerStart,
  fetchAddToTowerSuccess,
  fetchAddToTowerFailure,
  restartState,
  clientRequestStart,
  clientRequestSuccess,
  clientRequestFailure,
  createdClient,
} from '../actions';
import Services from '../../../../services/client/ClientsServices';
import {
  addClient,
  updateClient,
  updateSelectedClient,
} from '../../../../containers/Client/actions';
import AddressForClientFunctions from '../../../ClientAddressModal/Core/customHooks/AddressForClientFunctions';
import ClientAddressType from '../../../ClientAddressModal/Core/models/ClientAddressType';

const services = new Services();

const defaultClient = {
  id: null,
  identityDocument: '',
  name: '',
  email: '',
  phoneNumber: '',
  properties: [],
  allowDelete: true,
};

const useActions = ({ onCloseHandler, currentClient }) => {
  const {
    towerId,
    dispatch: containerDispatcher,
    makeAlert,
    createClient,
    currentAddress,
    selectedClient,
    currentAddressForOffice,
  } = useContext(ContainerContext);

  const { enqueueSnackbar } = useSnackbar();

  const [infoType, setInfoType] = useState(0);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [innerClient, setInnerClient] = useState(defaultClient);
  const [client, setClient] = useState(null);
  const [open, setOpen] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [addressDescription, setAddressDescription] = useState('');
  const [addressDescriptionForOffice, setAddressForOffice] = useState('');
  const [missingProperty, setMissingProperties] = useState(null);

  const handleChange = (fieldName) => (e) => {
    setMissingProperties(null);
    setInnerClient({
      ...innerClient?.clientAdditionalInfo,
      ...innerClient,
      [fieldName]: e.target.value,
    });
  };

  const handleClientInforDateChange = (fieldName) => (value) => {
    const clientData = value.valueOf();
    setInnerClient({
      ...innerClient?.clientAdditionalInfo,
      ...innerClient,
      [fieldName]: clientData,
    });
  };

  const handleQuestionOne = (event) => {
    if (event.target.value) {
      setInnerClient({
        ...innerClient,
        responseQuestionOne: event.target.value,
      });
    }
  };

  const clientData = {
    client: {
      allowDelete: true,
      email: innerClient.email || '',
      identityDocument: innerClient.identityDocument || '',
      name: innerClient.name || '',
      middleName: innerClient.middleName || '',
      surname: innerClient.surname || '',
      secondSurname: innerClient.secondSurname || '',
      phoneNumber: innerClient.phoneNumber || '',
      mobileNumber: innerClient.phoneNumber || '',
      identityDocumentType: innerClient?.identityDocumentType || 1,
    },
    additionalInfo: {
      placeOfIssuanceId: innerClient.placeOfIssuanceId || '',
      civilStatus: innerClient.civilStatus || 'S',
      dateOfBirth: innerClient.dateOfBirth || moment().valueOf(),
      dateOfIssuanceOfID: innerClient.dateOfIssuanceOfID || moment().valueOf(),
      departmentOfResidence: innerClient.departmentOfResidence || '',
      neighborhood: innerClient.dateOfBirth || '',
      officeCity: innerClient.officeCity || '',
      profession: innerClient.profession || '',
      position: innerClient.position || '',
      residenceAddress: innerClient?.residenceAddress || '',
      countryOfResidence: innerClient.countryOfResidence || '',
      cityOfResidence: innerClient.cityOfResidence || '',
      companyName: innerClient.companyName || '',
      companyAddress: innerClient.companyAddress || '',
      companyPhone: innerClient.companyPhone || '',
      monthlyIncome: innerClient.monthlyIncome || 0,
      chargeNumber: innerClient.chargeNumber || '',
      responseQuestionOne: innerClient.responseQuestionOne || undefined,
    },
    address: currentAddress,
    additionalAddress: currentAddressForOffice,
  };

  const hasMissingInfo = () => {
    const propertiesToValidate = [
      'identityDocument',
      'name',
      'surname',
      'email',
      'phoneNumber',
    ];
    const propertyFound = propertiesToValidate.find(
      (property) => !innerClient[property],
    );
    setMissingProperties(propertyFound);
    return propertyFound;
  };

  const handleSubmit = async () => {
    try {
      const missing = hasMissingInfo();
      if (missing) {
        enqueueSnackbar('Falta uno de los campos obligatorios', 'warning');
        return;
      }

      dispatch(clientRequestStart());
      if (innerClient.id) {
        const res = await services.putClient(
          innerClient.id,
          towerId,
          clientData,
        );
        containerDispatcher(updateClient(res.data));
        dispatch(clientRequestSuccess());
      } else {
        const res = await services.postClient(towerId, clientData);
        containerDispatcher(createClient(res.data));
        dispatch(clientRequestSuccess());
        dispatch(createdClient(res.data.id));
      }
      makeAlert(
        `Se ${innerClient.id ? 'actualizó' : 'creó'} correctamente el usuaro`,
        'success',
      );
      onCloseHandler();
    } catch (error) {
      dispatch(clientRequestFailure());
      makeAlert(error.message, 'error');
    }
  };

  const loadClient = async (selectedClientId) => {
    try {
      setLoading(true);
      dispatch(clientRequestStart());
      const res = await services.getClientWithId(towerId, selectedClientId);
      containerDispatcher(updateClient(res.data));
      containerDispatcher(updateSelectedClient(res.data));
      setInnerClient({ ...res.data.clientAdditionalInfo, ...res.data });
      dispatch(clientRequestSuccess());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(clientRequestFailure());
    }
  };

  useEffect(() => {
    const descriptionFromModel =
      AddressForClientFunctions.getDescriptionForAddress(currentAddress);

    const description =
      currentAddress?.addressType === ClientAddressType.OPEN.code
        ? innerClient?.residenceAddress
        : descriptionFromModel;

    setAddressDescription(description);
  }, [currentAddress, innerClient?.residenceAddress]);

  useEffect(() => {
    const descriptionFromModel =
      AddressForClientFunctions.getDescriptionForAddress(
        currentAddressForOffice,
      );

    const description =
      currentAddressForOffice?.addressType === ClientAddressType.OPEN.code
        ? innerClient?.clientAdditionalInfo?.companyAddress
        : descriptionFromModel;

    setAddressForOffice(description);
  }, [
    currentAddressForOffice,
    innerClient?.clientAdditionalInfo?.companyAddress,
  ]);

  const handleAddToTower = async () => {
    dispatch(fetchAddToTowerStart());
    try {
      const res = await services.addClientToTower(innerClient.id, towerId);
      containerDispatcher(addClient(res.data));
      const tempClient = { ...res.data };
      tempClient.associated = true;
      setInnerClient(tempClient);
      dispatch(fetchAddToTowerSuccess());
    } catch (error) {
      dispatch(fetchAddToTowerFailure());
      makeAlert(error.message, 'warning');
    }
  };

  const getDefaultValue = (currentValue, nextValue, defaultValue = '') => {
    if (currentValue) {
      return currentValue;
    }
    if (nextValue) return nextValue;
    return defaultValue;
  };

  const validateMissing = (id) => {
    return missingProperty === id;
  };

  return {
    setClient,
    setOpen,
    defaultClient,
    handleSubmit,
    state,
    innerClient,
    handleAddToTower,
    infoType,
    setInfoType,
    clientInfo: innerClient,
    setClientInfo: setInnerClient,
    addressDescription,
    addressDescriptionForOffice,
    isLoading,
    validateMissing,
    setInnerClient,
    handleQuestionOne,
    handleChange,
    handleClientInforDateChange,
    getDefaultValue,
  };
};

export default useActions;
