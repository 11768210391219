import { API_PATH } from '../../config/config';

const ContractPaymentsServicesDefinition = {
  getResumeContractPaymentsSchedule: (contractId: number) =>
    `${API_PATH}contracts/payments/resume-schedule-by-contract/${contractId}`,
  getSchedulePaymentsByContract: (contractId: number) => `${API_PATH}contracts/payments/flow/${contractId}`,
  getAllContractPayments: (contractId: number) => `${API_PATH}contracts/payments/by-contract/${contractId}`,
  getAllContractPaymentsForeignList: () => `${API_PATH}contracts/payments/foreign-lists`,
  createContractPayment: (contractId: number) => `${API_PATH}contracts/payments/${contractId}`,
  editContractPayment: (contractPaymentId: number) => `${API_PATH}contracts/payments/${contractPaymentId}`,
  deleteContractPayment: (contractPaymentId: number, contractId: number) =>
    `${API_PATH}contracts/payments/${contractPaymentId}/contract/${contractId}`,
};

export default ContractPaymentsServicesDefinition;
