import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import BuyerCustomerServices from '../../Services/BuyerCustomerServices';
import { propertyHasCompletePriceForPlan } from './useBuyerPropertyDetailWithQuotationsStates';
import { useCustomerListMapperByClients } from './useCustomerListMapper';
import {
  setCurrentBuyerCustomer,
  setCurrentBuyerCustomerPropertyInfo,
  setCurrentBuyerIsLoading,
  setCurrentBuyerCustomerPropertyFiles,
} from '../actions';

export function useBuyerCustomerPropertyLoader(
  clientId,
  propertyId,
  currentFilter,
) {
  const { enqueueSnackbar } = useSnackbar();
  const { mapCustomers } = useCustomerListMapperByClients();
  const dispatch = useDispatch();

  const reloadProperty = async () => {
    try {
      const services = new BuyerCustomerServices();
      const currentClientLoaded = await services.getCostumer(
        clientId,
        propertyId,
      );
      const property = mapCustomers(
        [currentClientLoaded.data.propertyWithQuotations],
        currentFilter,
      );
      dispatch(setCurrentBuyerCustomer(property[0]));

      dispatch(
        setCurrentBuyerCustomerPropertyInfo({
          ...currentClientLoaded.data.property,
          id: propertyId,
        }),
      );
      dispatch(
        setCurrentBuyerCustomerPropertyFiles(
          currentClientLoaded.data.propertyFile,
        ),
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return { reloadProperty };
}

function useBuyerCustomerPropertySelector(propertyWithQuotation) {
  const dispatch = useDispatch();
  const [alreadyTryToSelect, setSelect] = useState(false);
  const [isCurrentSelected, setCurrentSelect] = useState(false);

  const {
    currentClientIsLoading,
    currentClient,
    currentPropertyInfo,
    currentFilter,
  } = useSelector((state) => state.buyerCostumers.root);

  const { reloadProperty } = useBuyerCustomerPropertyLoader(
    propertyWithQuotation.clientId,
    propertyWithQuotation.propertyId,
    currentFilter,
  );

  useEffect(() => {
    const isSelected =
      currentClient &&
      currentClient.propertyId === propertyWithQuotation.propertyId;
    if (
      !currentPropertyInfo &&
      !alreadyTryToSelect &&
      !currentClientIsLoading &&
      isSelected
    ) {
      setSelect(true);
      dispatch(setCurrentBuyerIsLoading(true));
      reloadProperty();
      dispatch(setCurrentBuyerIsLoading(false));
    }

    if (isSelected !== isCurrentSelected) {
      setCurrentSelect(isSelected);
    }
  });

  const onClick = async () => {
    dispatch(setCurrentBuyerIsLoading(true));
    reloadProperty();
    dispatch(setCurrentBuyerIsLoading(false));
  };

  const assignedPlan = propertyWithQuotation.quotations.find(
    (quotation) => quotation.isAssigned,
  );

  let propertyHasPaymentPlan = false;
  if (assignedPlan && propertyWithQuotation) {
    propertyHasPaymentPlan = propertyHasCompletePriceForPlan(
      assignedPlan,
      propertyWithQuotation,
      assignedPlan.finalPayment,
    );
  }

  return { onClick, isCurrentSelected, propertyHasPaymentPlan };
}

export default useBuyerCustomerPropertySelector;
