import { useHistory } from 'react-router-dom';
import { DashboardRoutes } from 'routes/local/routes';

export interface GoToSalesRoomProps {
    towerId: string;
    clientId: string;
}

const GoToSalesRoom = (props: GoToSalesRoomProps) => {
    const history = useHistory();
    const { clientId, towerId } = props;
    const goToSalesRoom = () => {
        history.push(
            `${DashboardRoutes.base}${DashboardRoutes.salesRoom.value}${towerId}/${clientId}`,
        );
    };

    return {
        goToSalesRoom
    }
};

export default GoToSalesRoom;
