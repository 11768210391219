import { CircularProgress } from '@material-ui/core';
import React from 'react';

import CustomProgress from 'shared/components/CustomProgress/CustomProgress';
import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import useSalesSpeedHelperLoader from '../core/customHooks/useSalesSpeedHelperLoader';
import useSalesSpeedHelperTabs from '../core/customHooks/useSalesSpeedHelperTabs';
import useUpdateMaximumRotationMonths from '../core/customHooks/useUpdateMaximumRotationMonths';
import { SalesSpeedHelperPayload } from '../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperStates from '../core/enums/SalesSpeedHelperStates';
import SalesSpeedHelperTables from '../core/enums/SalesSpeedHelperTables';
import SalesSpeedHelperSidenav from './components/SalesSpeedHelperSidenav/SalesSpeedHelperSidenav';
import SalesSpeedHelperToolbar from './components/SalesSpeedHelperToolbar/SalesSpeedHelperToolbar';
import SalesSpeedHelperTable from './tables/SalesSpeedHelperTable/SalesSpeedHelperTable';

import styles from './SalesSpeedHelperMainView.module.scss';

const loadingView = <CircularProgress className={styles.loader} />;

const emptypMessage = (
  <Typography className={styles.emptyMessage} variant="subtitle1" as="p">
    Define el máximo de rotación para comenzar
  </Typography>
);

const noDataMessage = (
  <div className={styles.noDataMessage}>
    <Icon className={styles.errorIcon} variant="ErrorOutline" width="2rem" height="2rem" color="stateError600"></Icon>
    <Typography variant="subtitle1" as="p">
      Datos insuficientes
    </Typography>
  </div>
);

type MainViewProps = {
  salesSpeedPayload?: SalesSpeedHelperPayload;
  currentTab: SalesSpeedHelperTables;
};

const mainViews = {
  [SalesSpeedHelperStates.Loading]: () => loadingView,
  [SalesSpeedHelperStates.Error]: () => {},
  [SalesSpeedHelperStates.NoData]: () => noDataMessage,
  [SalesSpeedHelperStates.Empty]: () => emptypMessage,
  [SalesSpeedHelperStates.Complete]: (props: MainViewProps) => <SalesSpeedHelperTable {...props} />,
};

const SalesSpeedHelperMainView: React.FC = () => {
  const { viewState, salesSpeedData, handleSpeedDataChange } = useSalesSpeedHelperLoader();
  const { currentTab, handleTabClick } = useSalesSpeedHelperTabs();
  const { isProgressVisible, handleMaximumRotationMonthsChange } =
    useUpdateMaximumRotationMonths(handleSpeedDataChange);
  const mainViewProps: MainViewProps = {
    salesSpeedPayload: salesSpeedData.payload,
    currentTab,
  };

  return (
    <>
      <div className={styles.root}>
        <SalesSpeedHelperSidenav
          className={styles.sidebar}
          viewState={viewState}
          salesSpeedPayload={salesSpeedData.payload}
        />
        <div className={styles.content}>
          <SalesSpeedHelperToolbar
            viewState={viewState}
            currentTab={currentTab}
            salesSpeedPayload={salesSpeedData.payload}
            onTabClick={handleTabClick}
            onMaximumRotationMonthsChange={handleMaximumRotationMonthsChange}
          />
          {mainViews[viewState](mainViewProps)}
        </div>
      </div>
      {isProgressVisible && <CustomProgress />}
    </>
  );
};

export default SalesSpeedHelperMainView;
