import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Typography from '../../../../shared/components/Typography/Typography';
import Icon from '../../../../shared/components/Icon/Icon';
import Styles from './RealPaymentErrorHandlingModal.module.scss';

const RealPaymentErrorHandlingModal = ({
  isOpen,
  closeHandler,
  chargeNumbers,
}) => {
  return (
    <Dialog open={isOpen} onClose={closeHandler}>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {chargeNumbers?.success.length > 0 && (
            <Box display="flex" flexDirection="column">
              <Box pb="10px">
                <Typography variant="caption" color="gray600">
                  Numeros de encargo guardados
                </Typography>
              </Box>
              {chargeNumbers?.success.map((chargeNumber) => (
                <Box
                  display="flex"
                  key={`Charge-${chargeNumber}`}
                  pt="10px"
                  alignItems="center"
                >
                  <Box pr="9px">
                    <Icon variant="Done" color="stateSuccess600" width="16px" />
                  </Box>
                  <Typography variant="body1" color="gray800">
                    {'Número de encargo '}
                    {chargeNumber || 'Vacío'}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {chargeNumbers?.error.length > 0 &&
            chargeNumbers?.success.length > 0 && (
              <div className={Styles.borderDivider} />
            )}

          {chargeNumbers?.error.length > 0 && (
            <Box
              display="flex"
              flexDirection="column"
              mt={chargeNumbers?.success.length > 0 ? '36px' : '0px'}
            >
              <Box pb="10px" display="flex" alignItems="center">
                <Typography variant="caption" color="gray600">
                  Numeros de encargo no guardados
                </Typography>
              </Box>
              {chargeNumbers?.error.map((chargeNumber) => (
                <Box
                  display="flex"
                  key={`no-charged--${chargeNumber}`}
                  pt="10px"
                  alignItems="center"
                >
                  <Box pr="9px">
                    <Icon variant="ErrorX" color="stateError600" width="16px" />
                  </Box>
                  <Typography variant="body1" color="gray800">
                    {'Número de encargo '}
                    {chargeNumber || 'Vacío'}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RealPaymentErrorHandlingModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  chargeNumbers: PropTypes.object,
};

export default RealPaymentErrorHandlingModal;
