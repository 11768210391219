import React, { FC, ChangeEvent } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';

import { FormSanitizeFn, IUseFormResult } from '../../../../../shared/customHooks/useForm';
import Typography from '../../../../../shared/components/Typography/Typography';
import { ContractPayment } from '../../../../../models/ContractPayments';
import useInstallment from '../../../Core/customHooks/useInstallment';
import PlusInput from 'shared/components/PlusInput/PlusInput';

interface IInstallmentsField extends Partial<IUseFormResult<ContractPayment>> {
  handleChangeValue: <G>(key: keyof ContractPayment, value: G) => void;
  handleChange: (
    key: keyof ContractPayment,
    sanitizeFn?: FormSanitizeFn,
  ) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void;
}

const InstallmentsField: FC<IInstallmentsField> = ({ handleChangeValue, handleChange, errors, data }) => {
  const { isAllowed, handleChangeInstallments } = useInstallment({
    handleChangeValue,
  });

  return (
    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
      <Grid item>
        <Typography variant="body1" color="gray900">
          Número de cuotas
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="gray900">
          <PlusInput
            value={data?.installments ?? 0}
            onChange={handleChangeInstallments}
            numberFormatProps={{
              isAllowed,
              decimalScale: 0,
              name: 'installments',
            }}
          />
        </Typography>
        <FormHelperText error>{errors?.installments}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default InstallmentsField;
