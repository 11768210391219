import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Button } from '../../../../../../shared/components/Button/Button';
import { TransitionProps } from '@material-ui/core/transitions';
import useAddEventForResultsState from '../../../../Core/customHooks/useAddEventForResultsState';
import styles from './SaveEventForResultsState.module.scss'

interface ISaveEvent {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SaveEventForResultsState: FC<ISaveEvent> = ({ open, handleClose }) => {
  const resultsStateData = useSelector((state) => state.cashFlow || {});
  const { selectedBudget } = resultsStateData.root;

  const { handleChange, onSubmitHandler, eventName } = useAddEventForResultsState();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{`Evento ${selectedBudget}`}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <DialogContentText 
        className={styles.dialogContentText}
        id="alert-dialog-slide-description">
          <TextField 
          className={styles.textField}
          required 
          id="outlined-required" 
          label="Nombre del evento" 
          variant="outlined" 
          size="small" 
          onChange={(event) => {
            handleChange(event, selectedBudget)
          }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSubmitHandler(eventName);
            handleClose()
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveEventForResultsState;
