import { API_PATH } from '../../../config/config';

const ResultsStateServicesDefinitions = {
  total: (towerId) => `${API_PATH}cash-flow/result-states/${towerId}`,

  downloadQuotationCashflow: (towerId) =>
    `${API_PATH}cash-flow/total-sales/download/${towerId}`,

  putCutsControl: (towerId) =>
    `${API_PATH}cash-flow/result-states/cutsControlUpdate/${towerId}`,

  putEventsControl: (towerId) =>
    `${API_PATH}cash-flow/result-states/eventsControlUpdate/${towerId}`,

  overwriteEvent: (towerId) =>
  `${API_PATH}cash-flow/result-states/overwriteEvent/${towerId}`,

  renameEvent: ({towerId, newEventName, selectedBudget}) =>
  `${API_PATH}cash-flow/result-states/renameEvent/${towerId}/${newEventName}/${selectedBudget}`,

  deleteEventControl: (towerId, eventName) =>
  `${API_PATH}cash-flow/result-states/eventsControlDelete/${towerId}/${eventName}`,
};

export default ResultsStateServicesDefinitions;
