import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import MuiPaper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Slide from '@material-ui/core/Slide';

const Paper = styled(MuiPaper)``;

const TotalValue = ({ propertyPrice }) => {
  return (
    <Slide in direction="right">
      <Paper elevation={24}>
        <Box p={2}>
          <Typography variant="h5" component="p">
            Valor Cierre de Negocio
          </Typography>

          <Typography variant="subtitle1" color="textSecondary">
            <NumberFormat
              thousandSeparator
              prefix="$"
              displayType="text"
              value={Math.round(propertyPrice)}
            />
          </Typography>
        </Box>
      </Paper>
    </Slide>
  );
};

TotalValue.propTypes = {
  propertyPrice: PropTypes.number.isRequired,
};

export default TotalValue;
