import React, { useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import Alert from '../../UI/Alert';
import ModalProps from '../interfaces/ModalProps';
import useModalHandlers from './useModalHandler';

type ValidDescription = string | ReactNode | ReactNode[];
function useAlert(props: ModalProps) {
  const { isOpen, setOpen, acceptAction, cancelAction } = useModalHandlers(props);
  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState<ValidDescription>('');

  useEffect(() => {
    const div = document.createElement('div');

    ReactDOM.render(
      <Alert
        title={title}
        isOpen={isOpen}
        variant={props.variant}
        acceptAction={acceptAction}
        cancelAction={cancelAction}
      >
        {description}
      </Alert>,
      div,
    );
  }, [isOpen]);

  const showAlert = (description: ValidDescription, title = props.title) => {
    setDescription(description);
    setTitle(title);
    setOpen(true);
  };

  return { showAlert };
}

export default useAlert;
