import { API_PATH } from '../config/config';

export class Route {
  constructor(route, params = undefined, query = undefined) {
    this.route = route;
    this.params = params;
    this.query = query;
  }
}

class ServicesDefinitions {
  constructor(base) {
    this.path = API_PATH;
    this.base = base;
  }

  /**
   * @param {Route} additional
   */
  createRoute(additional = {}) {
    return `${this.path}${this.base}${additional.route || ''}${
      additional.params || ''
    } ${additional.query || ''}`;
  }
}

export default ServicesDefinitions;
