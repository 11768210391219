import TotalCashFlowEnum from '../TotalCashFlow.enum';

const useMapperForNewRows = ({ rows }) => {
  const AccumulatedOperatingFlow = TotalCashFlowEnum.ACCUMULATED_OPERATING_FLOW.value;
  const spreadIPC = TotalCashFlowEnum.SPREAD_IPC.value;
  const saldoAcumuladoInversionistas = TotalCashFlowEnum.SALDO_ACUMULADO_INVERSIONISTAS.value;
  const monthBalacedId = TotalCashFlowEnum.MONTH_BALANCED_ID.value;
  const accumulatedId = TotalCashFlowEnum.ACCUMULATED_ID.value;
  const constructionId = TotalCashFlowEnum.CONSTRUCTION.value;
  const expensesId = TotalCashFlowEnum.EXPENSES.value;
  const totalSalesId = TotalCashFlowEnum.TOTAL_SALES.value;
  const totalIdFuture = TotalCashFlowEnum.TOTAL_ID_FUTURE.value;
  const totalIdPast = TotalCashFlowEnum.TOTAL_ID_PAST.value;
  const financingCreditBalance = TotalCashFlowEnum.FINANCING_CREDIT_BALANCE.value;
  const investorFlowId = TotalCashFlowEnum.INVESTOR_FLOW.value;
  const cxpId = TotalCashFlowEnum.CXP.value;
  const financingId = TotalCashFlowEnum.FINANCING.value
  const financialPerformanceId = TotalCashFlowEnum.FINANCIAL_PERFORMANCE.value
  const operationFlowId = TotalCashFlowEnum.OPERATION_FLOW.value

  const filteredRows = rows.filter((row) => {
    return (
      row.id !== AccumulatedOperatingFlow &&
      row.id !== spreadIPC &&
      row.id !== saldoAcumuladoInversionistas &&
      row.id !== accumulatedId &&
      row.id !== monthBalacedId &&
      row.id !== constructionId
    );
  });

  const findRow = (rowId) => {
    const row = rows.filter(row => row.id === rowId)

    return row
  }

  const filterAndOrderedByParent = (parentId, rowsToFilter,  rowsToConcat = []) => {
    const filteredRows = rowsToFilter.filter((row) => row.parentId === parentId);

    if (rowsToConcat) {
      return [...rowsToConcat, ...filteredRows];
    }

    return filteredRows;
  };

  const totalSalesRow = findRow(totalSalesId)
  const salesArray = filterAndOrderedByParent(totalSalesId, rows, totalSalesRow);

  const constructionRow = findRow(constructionId)
  const expensesRow = findRow(expensesId)

  const expensesAndConstructionRows = expensesRow.concat(constructionRow);

  const expensesArray = filterAndOrderedByParent(expensesId, filteredRows, expensesAndConstructionRows);

  const operatingFlowRow = findRow(operationFlowId)

  const cxpRow = findRow(cxpId)

  const financingRow = findRow(financingId)
  const financingArray = filterAndOrderedByParent(financingId, rows, financingRow)

  const investorFlowRow = findRow(investorFlowId)
  const investorFlowArray = filterAndOrderedByParent(investorFlowId, rows, investorFlowRow)

  const financialPerformanceRow = findRow(financialPerformanceId)

  const freeCashFlowRow = rows.filter(row => row.id === monthBalacedId)

  const cashBalanceRow = rows.filter(row => row.id === accumulatedId)

  const sortedRows = [
    ...salesArray,
    ...expensesArray,
    ...operatingFlowRow,
    ...cxpRow,
    ...financingArray,
    ...investorFlowArray,
    ...financialPerformanceRow,
    ...freeCashFlowRow,
    ...cashBalanceRow
  ]

  const newRows = sortedRows.map((row) => {
    const { id } = row;

    switch (id) {
      case totalSalesId:
        return {
          ...row,
          name: 'Ventas',
        };
      case totalIdFuture:
        return {
          ...row,
          name: 'Pendientes',
        };
      case totalIdPast:
        return {
          ...row,
          name: 'Realizadas',
        };
      case financingCreditBalance:
        return {
          ...row,
          name: 'Saldo Crédito Constructor',
        };
      case investorFlowId:
        return {
          ...row,
          name: 'Inversiones de Capital',
        };
      case cxpId:
        return {
          ...row,
          name: 'Cuentas por Pagar',
        };
      case monthBalacedId:
        return {
          ...row,
          name: 'Flujo de Caja Libre',
        };
      case accumulatedId:
        return {
          ...row,
          name: 'Saldo de Caja',
        };
      default:
        break;
    }
    return row;
  });
  return newRows;
};

export default useMapperForNewRows;
