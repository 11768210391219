import React from 'react';
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import styles from '../../../styles/BandCell.module.scss';

const BandCell = ({
  children,
  tableRow,
  tableColumn,
  column,
  classes,
  ...restProps
}) => {
  const accumulatedMonths = 'Meses proyectados';

  if (column && column.title === accumulatedMonths) {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        className={`${styles.projectedBandCell} ${styles.borderTop}`}
      >
        <strong className={styles.projectedTypography}>{children}</strong>
      </TableBandHeader.Cell>
    );
  }
  return (
    <TableBandHeader.Cell
      {...restProps}
      column={column}
      className={`${styles.height} ${styles.borderTop}`}
    >
      <strong className={styles.accumulatedTypography}>{children}</strong>
    </TableBandHeader.Cell>
  );
};

export default BandCell;
