import React, { FC } from 'react';
import styled from 'styled-components';
import Typography from '../../../../components/Typography/Typography';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';
import colors from '../../../../../assets/styles/settings/colors.module.scss';
import moment from 'moment';

const TableCellTypography = styled(Typography)<TableCellTypographyProps>`
  color: ${(props) => {
    const inherit = 'inherit';
    if (!props.row?.date) return inherit;

    const rowDate = moment(props.row.date);

    return rowDate.isSameOrAfter(moment()) ? colors.gray500 : inherit;
  }};
`;

const TableCellGrayFuture: FC<TableCellTypographyProps> = ({
  value,
  children,
  column,
  row,
}) => {
  return (
    <TableCellTypography value={value} column={column} row={row}>
      {children || value}
    </TableCellTypography>
  );
};

export default TableCellGrayFuture;
