import { useMutation, useQueryClient } from 'react-query';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { unionBy, sumBy, omit } from 'lodash';
import { useSnackbar } from 'notistack';

import Services from '../../../../services/ContractPaymentsSchedule/ContractPaymentsScheduleServices';
import { ContractPayment, ContractPaymentSchedule } from '../../../../models/ContractPayments';

const services = new Services();

const useEditContractPaymentSchedules = (contractPaymentId: number, currentContractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractPaymentSchedules: ContractPaymentSchedule[]) => {
      return services.bulkEditContractPaymentSchedule(contractPaymentId, contractPaymentSchedules);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['contract-payments', currentContractId]);
      },
    },
  );
};

interface SummaryContractPaymentSchedule {
  totalAmount: number;
  totalPercentage: number;
}
const summaryContractPaymentSchedule = (
  contractPaymentSchedules: ContractPaymentSchedule[],
): SummaryContractPaymentSchedule => {
  const totalAmount = Math.ceil(sumBy(contractPaymentSchedules, 'amount'));
  const totalPercentage = Math.ceil(sumBy(contractPaymentSchedules, 'percentage'));
  return {
    totalAmount,
    totalPercentage,
  };
};

interface UseScheduleByPaymentParams {
  contractPayment: ContractPayment;
  sendMachineState: (event: string) => void;
}

type HookType = (param: UseScheduleByPaymentParams) => {
  currentContractPaymentSchedules: ContractPaymentSchedule[];
  currentContractPaymentSchedule: ContractPaymentSchedule | undefined;
  setCurrentContractPaymentSchedule: Dispatch<SetStateAction<ContractPaymentSchedule | undefined>>;
  openContractPaymentScheduleForm: boolean;
  setOpenContractPaymentScheduleForm: Dispatch<SetStateAction<boolean>>;
  handlePaymentUpdate: (contractPaymentSchedule: ContractPaymentSchedule) => void;
  handleSaveContractPaymentsGrid: () => void;
  handleResetContractPaymentsGrid: () => void;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  expandable: boolean;
};

const useScheduleByPayment: HookType = ({ contractPayment, sendMachineState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState(false);
  const [expandable, setExpandable] = useState(false);

  const { mutateAsync: editContractPaymentSchedules } = useEditContractPaymentSchedules(
    contractPayment?.id || 0,
    contractPayment?.contractId || 0,
  );

  const [currentContractPaymentSchedule, setCurrentContractPaymentSchedule] = useState<
    ContractPaymentSchedule | undefined
  >();
  const [openContractPaymentScheduleForm, setOpenContractPaymentScheduleForm] = useState<boolean>(false);

  const [currentContractPaymentSchedules, setCurrentContractPaymentSchedules] = useState<ContractPaymentSchedule[]>([]);

  useEffect(() => {
    const { contractPaymentSchedules } = contractPayment;
    setExpandable(contractPayment.type !== 'PR');
    setCurrentContractPaymentSchedules([...contractPaymentSchedules]);
  }, [contractPayment]);

  const [editedContractPaymentSchedules, setEditedContractPaymentSchedules] = useState<ContractPaymentSchedule[]>([]);

  const handlePaymentUpdate = (paymentFormData: ContractPaymentSchedule) => {
    const { subtotal, type } = contractPayment;
    const elementIndex = currentContractPaymentSchedules.findIndex(
      (obj: ContractPaymentSchedule) => obj.id == paymentFormData.id,
    );
    currentContractPaymentSchedules[elementIndex] = paymentFormData;

    const mergeItems = unionBy(
      [omit(paymentFormData, 'scheduleLabelDescription', 'event', 'finalDate')],
      editedContractPaymentSchedules,
      'id',
    );

    setEditedContractPaymentSchedules(mergeItems);

    const sumarizeContractPaymentSchedule: SummaryContractPaymentSchedule = summaryContractPaymentSchedule(
      currentContractPaymentSchedules,
    );
    const isValidToSave: boolean =
      (type === 'PC' && sumarizeContractPaymentSchedule.totalAmount === subtotal) ||
      (type === 'PP' && sumarizeContractPaymentSchedule.totalPercentage === 100);

    if (isValidToSave) sendMachineState('ACTIVE');
    else sendMachineState('MODIFY');
    setCurrentContractPaymentSchedules([...currentContractPaymentSchedules]);
  };

  const handleResetContractPaymentsGrid = async () => {
    const { contractPaymentSchedules } = contractPayment;
    setCurrentContractPaymentSchedules([...contractPaymentSchedules]);
    setEditedContractPaymentSchedules([]);
    sendMachineState('INACTIVE');
  };

  const handleSaveContractPaymentsGrid = async () => {
    const { subtotal, type } = contractPayment;
    let hasError = false;

    if (editedContractPaymentSchedules.length > 0) {
      const newItems = unionBy(editedContractPaymentSchedules, currentContractPaymentSchedules, 'id');
      const sumarizeContractPaymentSchedule: SummaryContractPaymentSchedule = summaryContractPaymentSchedule(newItems);
      switch (type) {
        case 'PC':
          if (sumarizeContractPaymentSchedule.totalAmount !== subtotal) {
            enqueueSnackbar('El valor del pago no corresponde a la suma de las cuotas', { variant: 'error' });
            hasError = true;
          }
          break;
        case 'PP':
          if (sumarizeContractPaymentSchedule.totalPercentage !== 100) {
            enqueueSnackbar('El valor del pago no esta distribuido correctamente en las cuotas', { variant: 'error' });
            hasError = true;
          }
          break;
      }

      if (!!!hasError) {
        try {
          await editContractPaymentSchedules(editedContractPaymentSchedules);
          sendMachineState('INACTIVE');
          enqueueSnackbar('Cuotas editadas correctamente', {
            variant: 'success',
          });
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
  };

  return {
    currentContractPaymentSchedules,
    openContractPaymentScheduleForm,
    setOpenContractPaymentScheduleForm,
    currentContractPaymentSchedule,
    setCurrentContractPaymentSchedule,
    handlePaymentUpdate,
    handleSaveContractPaymentsGrid,
    handleResetContractPaymentsGrid,
    expanded,
    setExpanded,
    expandable,
  };
};

export default useScheduleByPayment;
