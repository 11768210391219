import {
  FILTER_SELECTION_UP,
  FILTER_SELECTION_DOWN,
  FILTER_SELECTION_TO_DEFAULT,
  ACTIVE_OR_DEACTIVATE_FILTER,
  DISABLE_CLEAR,
  SELECTOR_NAME_ON_DISPLAYED_FILTER,
} from './actions';

const defaultState = () => ({
  m2: [],
  budget: 0,
  bedrooms: [],
  bathrooms: [],
  orientation: [],
  availability: '',
  group: '',
  propertyTypes: 0,
  m2Active: false,
  budgetActive: false,
  bedroomsActive: false,
  bathroomsActive: false,
  orientationActive: false,
  availabilityActive: false,
  groupActive: false,
  propertyTypesActive: false,
  clear: true,
  filterTableName: 'priceWithIncrements',
});

const initialState = {
  ...defaultState(),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FILTER_SELECTION_UP:
      return { ...state, ...payload };

    case FILTER_SELECTION_DOWN:
      return { ...state, ...payload };

    case FILTER_SELECTION_TO_DEFAULT:
      return {
        m2: [],
        budget: 0,
        bedrooms: [],
        bathrooms: [],
        orientation: [],
        availability: '',
        group: '',
        propertyTypes: 0,
        m2Active: false,
        budgetActive: false,
        bedroomsActive: false,
        bathroomsActive: false,
        orientationActive: false,
        availabilityActive: false,
        groupActive: false,
        propertyTypesActive: false,
        clear: true,
        filterTableName: 'priceWithIncrements',
      };

    case DISABLE_CLEAR:
      return {
        m2: [],
        budget: 0,
        bedrooms: [],
        bathrooms: [],
        orientation: [],
        availability: '',
        group: '',
        propertyTypes: '',
        m2Active: false,
        budgetActive: false,
        bedroomsActive: false,
        bathroomsActive: false,
        orientationActive: false,
        availabilityActive: false,
        groupActive: false,
        propertyTypesActive: false,
        clear: false,
        filterTableName: 'priceWithIncrements',
      };

    case ACTIVE_OR_DEACTIVATE_FILTER:
      const newState =  { ...state, [payload]: !state[payload] }
      if (!!state[payload] && payload && payload.includes('Active')) {
        const name = payload.replace('Active', '')
        newState[name] = defaultState()[name]
      }
      return newState;

    case SELECTOR_NAME_ON_DISPLAYED_FILTER:
      return { ...state, filterTableName: payload };

    default:
      return defaultState();
  }
};
