import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AbsoluteCircularProgress from '../../../../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import useClientHubForPropertyRequest from '../../../../Core/customHooks/useClientHubForPropertyRequest';
import ClientHubForCurrentMonthView from '../ClientHubForCurrentMonthView';
import CheckboxConfirmPayments from './CheckboxConfirmPayments';
import ChargeNumberClientHub from './ChargeNumberClientHub';
import CustomTypography from '../../../../../../components/Typography/CustomTypography';

const ClientHubSegmentedContainer = ({ propertyId }) => {
  const { isLoading } = useClientHubForPropertyRequest(propertyId);

  return (
    <>
      <Box display="flex" p={3}>
        <Box flexGrow={1}>
          <CustomTypography variant="subtitle">
            Hub del cliente
          </CustomTypography>
        </Box>
      </Box>

      <Box pl={4} pr={4}>
        <CheckboxConfirmPayments />
        <ChargeNumberClientHub />
        <ClientHubForCurrentMonthView />
      </Box>
      {isLoading && <AbsoluteCircularProgress topPercentage={0} />}
    </>
  );
};

ClientHubSegmentedContainer.propTypes = {
  propertyId: PropTypes.number,
};

export default ClientHubSegmentedContainer;
