import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import BuilderCreditModel from '../models/BuilderCreditModel';
import BuilderCreditServices from '../../../../services/BuilderCredit/BuilderCreditServices';

function useBuilderCreditLoader(isOpen, onClose) {
  const services = new BuilderCreditServices();

  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [currentBuilderModel, setBuilderModel] = useState(
    new BuilderCreditModel(),
  );
  const [isLoading, setLoading] = useState(false);

  const loadBuilderSetup = async () => {
    if (isOpen) {
      try {
        setLoading(true);
        const response = await services.getBuilderCreditSetup(towerId);
        setBuilderModel(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    loadBuilderSetup();
  }, [isOpen]);

  const updateDate = (key) => (date) => {
    if (!date) {
      return;
    }
    const value = date.valueOf();
    const newBuilderModel = {
      ...currentBuilderModel,
      [key]: value,
    };
    const builderModel = new BuilderCreditModel(newBuilderModel);

    setBuilderModel({
      ...builderModel,
      ...{
        suggestedConsolidatedCredit: builderModel.suggestedConsolidatedCredit,
        suggestedOperativeCredit: builderModel.suggestedOperativeCredit,
        suggestedBuilderCredit: builderModel.suggestedBuilderCredit,
        approvedConsolidatedCredit: builderModel.approvedConsolidatedCredit,
      },
    });
  };

  const update = (event) => {
    const newBuilderModel = {
      ...currentBuilderModel,
      [event.target.name]: event.target.value,
    };
    const builderModel = new BuilderCreditModel(newBuilderModel);

    setBuilderModel({
      ...builderModel,
      ...{
        suggestedConsolidatedCredit: builderModel.suggestedConsolidatedCredit,
        suggestedOperativeCredit: builderModel.suggestedOperativeCredit,
        suggestedBuilderCredit: builderModel.suggestedBuilderCredit,
        approvedConsolidatedCredit: builderModel.approvedConsolidatedCredit,
      },
    });
  };

  const onSend = async () => {
    try {
      await services.updateBuilderCreditSetup(towerId, currentBuilderModel);
      onClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    states: currentBuilderModel,
    isLoading,
    update,
    updateDate,
    onSend,
  };
}

export default useBuilderCreditLoader;
