import cellsSelector from '../../UI/views/cells/cellsSelector';
import CellType from '../enums/CellType';

function useCellsForCustomCell(cellData, tableColumn, row, name) {
  const utilityBeforeTaxTitle = 'Utilidad antes de impuestos';
  const utilityBeforeTaxId = 'UTILITY-BEFORE-TAX';
  const totalSalesId = 'TOTAL_SALES';
  const percentage = 'percentage';
  const controlPercentage = 'controlPercentage';
  const previousControl = 'previousControl';
  const construction = 'Construcción';
  const financialPerformance = 'financialPerformance';
  const perThousand = 'perThousand'

  if (cellData !== 0 && !cellData) {
    return cellsSelector(CellType.EMPTY_CELL.value, cellData, row, name);
  }

  if (tableColumn.column.name === previousControl) {
    return cellsSelector(CellType.PREVIOUS_CONTROL.value, cellData, row, name);
  }

  if (tableColumn.column.name === controlPercentage) {
    return cellsSelector(CellType.CONTROL_PERCENTAGE.value, cellData, row, name);
  }

  if (tableColumn.column.name === percentage && row.id === utilityBeforeTaxId) {
    return cellsSelector(
      CellType.UTILITY_BEFORE_TAX_PERCENTAGE.value,
      cellData,
      row,
      name,
    );
  }

  if (tableColumn.column.name === percentage && row.id === totalSalesId) {
    return cellsSelector(
      CellType.TOTAL_AND_UTILITY_PERCENTAGE.value,
      cellData,
      row,
      name,
    );
  }

  if (row.id === totalSalesId && row.ClientWithoutQuotation === true) {
    return cellsSelector(
      CellType.TOTAL_FOR_CLIENTS_WITHOUT_QUOTATION.value,
      cellData,
      row,
      name,
    );
  }

  if (cellData === utilityBeforeTaxTitle) {
    return cellsSelector(
      CellType.UTILITY_BEFORE_TAX.value,
      cellData,
      row,
      name,
    );
  }

  if (row.id === utilityBeforeTaxId) {
    return cellsSelector(
      CellType.UTILITY_BEFORE_TAX.value,
      cellData,
      row,
      name,
    );
  }

  if (row.id === totalSalesId) {
    return cellsSelector(CellType.TOTAL_AND_UTILITY.value, cellData, row, name);
  }

  if (tableColumn.column.name === percentage && row.contracts) {
    return cellsSelector(
      CellType.CONTRACTS_PERCENTAGE.value,
      cellData,
      row,
      name,
    );
  }

  if (row.contracts) {
    return cellsSelector(CellType.CONTRACTS.value, cellData, row, name);
  }

  if (
    tableColumn.column.name === percentage &&
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance &&
    row.id !== perThousand
  ) {
    return cellsSelector(CellType.ITEMS_PERCENTAGE.value, cellData, row, name);
  }

  if (
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance &&
    row.id !== perThousand
  ) {
    return cellsSelector(CellType.ITEMS.value, cellData, row, name);
  }

  if (tableColumn.column.name === percentage) {
    return cellsSelector(CellType.GROUPS_PERCENTAGE.value, cellData, row, name);
  }

  return cellsSelector(CellType.GROUPS.value, cellData, row, name);
}

export default useCellsForCustomCell;
