import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GeneralWalletParams, GeneralWalletData, GeneralWalletGetResponse } from '../interfaces/generalWallet.types';
import GeneralWalletServices from '../../services/GeneralWalletServices';

const DEFAULT_TABLE_DATA = {
  clients: [],
  featureFields: [],
};
const services = new GeneralWalletServices();

function useGeneralWalletLoader() {
  const { towerId } = useParams<GeneralWalletParams>();
  const [tableData, setTableData] = useState<GeneralWalletData>(DEFAULT_TABLE_DATA);
  const [isLoading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data }: GeneralWalletGetResponse = await services.getClientsWithGeneralWallet(towerId);
        setTableData(data);
      } catch (error) {
        const message = (error as Error).message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { isLoading, setLoading, tableData };
}

export default useGeneralWalletLoader;
