import { API_PATH } from '../../../config/config';

const PATH = `${API_PATH}evalpro-deep-expenses`;

class EvalproDeedExpensesServiceDefinitions {
  static evalproConfig = (towerId: string) => `${PATH}/${towerId}/config`;
  static evalproMonths = (towerId: string) => `${PATH}/${towerId}/months`;
  static updateEvalproMonths = (id: number) => `${PATH}/months/${id}`;
}

export default EvalproDeedExpensesServiceDefinitions;
