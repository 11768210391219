import React, { FC } from 'react';
import { MuiIconButton } from 'shared/components/Button/Button';
import { MoreVert } from '@material-ui/icons';
import { Paper, Popover } from '@material-ui/core';
import useAreaPopover from '../../../Core/customHooks/useAreaPopover';

const AreaTablePopover: FC<{ isVisible?: boolean }> = ({ isVisible, children }) => {
  const { isOpen, anchorEl, id, close, handlePopoverClick } = useAreaPopover();
  return isVisible ? (
    <>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={close}
      >
        <Paper>{children}</Paper>
      </Popover>
      <MuiIconButton onClick={handlePopoverClick} size="small">
        <MoreVert />
      </MuiIconButton>
    </>
  ) : null;
};

export default AreaTablePopover;
