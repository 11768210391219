class Colors {
  static randomRgba(alpha = 1) {
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${
      alpha !== null ? alpha : r().toFixed(1)
    })`;
  }
}

export default Colors;
