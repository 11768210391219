import React, { FC } from "react";
import { Button } from "@blueprintjs/core";
import {
  Box,
  makeStyles,
  TextField,
} from "@material-ui/core";
import useContractorForm from "./useContractorForm";

const useStyles = makeStyles({
  root: {},
  title: {
    fontSize: "1.1em",
    fontWeight: "bold",
  },
  box: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    "& > *": {
      margin: 0,
      marginTop: 2,
      marginBottom: 1,
    },
  },
  boxSizing: {
    boxSizing: "border-box",
  },
});

const ContractorForm: FC = () => {
  const classes = useStyles();
  const { data, errors, handleSubmit, handleChange, handleChangeSelect } =
    useContractorForm();
  return (
    <Box className={classes.root} p={2}>
      <form noValidate onSubmit={handleSubmit}>
        <Box display="flex" flexWrap="wrap" alignItems="start">
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerName || ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="patnerName"
              label="Nombre Empresa"
              name="patnerName"
              autoComplete="patnerName"
              autoFocus
              error={!!errors?.patnerName}
              helperText={errors?.patnerName}
              onChange={handleChange("patnerName")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerEmail || ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="patnerEmail"
              label="Correo Electrónico"
              name="patnerEmail"
              autoComplete="patnerEmail"
              type="patnerEmail"
              error={!!errors?.patnerEmail}
              helperText={errors?.patnerEmail}
              onChange={handleChange("patnerEmail")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerVatNumber || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerVatNumber"
              label="Número Documento"
              name="patnerVatNumber"
              autoComplete="patnerVatNumber"
              error={!!errors?.patnerVatNumber}
              helperText={errors?.patnerVatNumber}
              onChange={handleChange("patnerVatNumber")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerPhone || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerPhone"
              label="Célular"
              name="patnerPhone"
              autoComplete="patnerPhone"
              error={!!errors?.patnerPhone}
              helperText={errors?.patnerPhone}
              onChange={handleChange("patnerPhone")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerAdress || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerAdress"
              label="Dirección"
              name="patnerAdress"
              autoComplete="patnerAdress"
              error={!!errors?.patnerAdress}
              helperText={errors?.patnerAdress}
              onChange={handleChange("patnerAdress")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerCity || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerCity"
              label="Ciudad"
              name="patnerCity"
              autoComplete="patnerCity"
              error={!!errors?.patnerCity}
              helperText={errors?.patnerCity}
              onChange={handleChange("patnerCity")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerCountry || ""}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerCountry"
              label="Pais"
              name="patnerCountry"
              autoComplete="patnerCountry"
              error={!!errors?.patnerCountry}
              helperText={errors?.patnerCountry}
              onChange={handleChange("patnerCountry")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerContactPerson || ''}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerContactPerson"
              label="Persona de contacto"
              name="patnerContactPerson"
              autoComplete="patnerContactPerson"
              error={!!errors?.patnerContactPerson}
              helperText={errors?.patnerContactPerson}
              onChange={handleChange("patnerContactPerson")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerFiscalInformation || ''}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerFiscalInformation"
              label="Información fiscal"
              name="patnerFiscalInformation"
              autoComplete="patnerFiscalInformation"
              error={!!errors?.patnerFiscalInformation}
              helperText={errors?.patnerFiscalInformation}
              onChange={handleChange("patnerFiscalInformation")}
            />
          </Box>
          <Box width="50%" p={1} className={classes.box}>
            <TextField
              value={data?.patnerPostalCode || ''}
              variant="outlined"
              margin="normal"
              fullWidth
              id="patnerPostalCode"
              label="Código postal"
              name="patnerPostalCode"
              autoComplete="patnerPostalCode"
              error={!!errors?.patnerPostalCode}
              helperText={errors?.patnerPostalCode}
              onChange={handleChange("patnerPostalCode")}
            />
          </Box>
          <Box width="100%" marginTop={2}>
            <Button
              icon={data?.id ? "refresh" : "add"}
              intent="primary"
              large
              type="submit"
              fill
            >
              {data?.id ? "Actualizar" : "Nuevo contratista"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ContractorForm;
