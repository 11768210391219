import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';
import { FeatureField } from '../interfaces/featureField.types';
import { FeatureFieldMenuOptionHook } from '../interfaces/useFeatureFieldMenuOption.types';

const getAlertDescription = ({ title }: FeatureField) => `¿Desea eliminar la columna ${title} permanentemente?`;

function useFeatureFieldMenuOption({ field, onStartEditField, onDeleteField, closeMenu }: FeatureFieldMenuOptionHook) {
  const handleEditClick = () => {
    closeMenu();
    onStartEditField(field);
  };

  const acceptDelete = () => {
    onDeleteField(field);
  };

  const { showAlert } = useAlert({
    isOpen: false,
    title: 'Eliminar columna',
    variant: AlertVariants.Destructive,
    acceptAction: acceptDelete,
  });

  const handleDeleteClick = () => {
    closeMenu();
    showAlert(getAlertDescription(field));
  };

  return { handleEditClick, handleDeleteClick };
}

export default useFeatureFieldMenuOption;
