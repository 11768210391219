import clientPropertyServicesDefinitions from './clientPropertyServicesDefinitions';
import Services from '../services';

export default class ClientsServices extends Services {
  getClientsFrom(towerId) {
    return this.get(
      clientPropertyServicesDefinitions.fromClientProperty(towerId),
    );
  }

  getClientsFromPropertyId(towerId, propertyId) {
    return this.get(
      clientPropertyServicesDefinitions.fromClientPropertyTower(
        towerId,
        propertyId,
      ),
    );
  }

  getPropertiesFrom(towerId) {
    return this.get(clientPropertyServicesDefinitions.fromProperty(towerId));
  }

  postClientsFrom(towerId, data) {
    return this.post(
      clientPropertyServicesDefinitions.fromClientProperty(towerId),
      data,
    );
  }

  updateClientsFrom(towerId, data) {
    return this.put(
      clientPropertyServicesDefinitions.fromClientProperty(towerId),
      data,
    );
  }

  deleteClientsFrom(towerId, data) {
    return this.delete(
      clientPropertyServicesDefinitions.fromClientProperty(towerId),
      data,
    );
  }
}
