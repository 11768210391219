import React, { FC } from 'react';
import styled from 'styled-components';
import Typography from '../../../../components/Typography/Typography';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';
import colors from '../../../../../assets/styles/settings/colors.module.scss';

const TableCellTypography = styled.span<TableCellTypographyProps>`
  color: ${(props) => {
    return Number(props.value) >= 0 ? colors.stateSuccess600 : colors.stateError600;
  }};
`;

const TableCellGreenPositiveTypography: FC<TableCellTypographyProps> = ({
  value,
  children,
  column,
}) => {
  return (
    <TableCellTypography value={value} column={column}>
      {children || value}
    </TableCellTypography>
  );
};

export default TableCellGreenPositiveTypography;
