import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import debouncedInput from '../../../../../../components/UI/TextFields/debouncedInput';
import useUpdateWalletSetup from '../../../customHooks/useUpdateWalletSetup';
import Numbers from '../../../../../../helpers/numbers';

const DebouncedTextField = debouncedInput(NumberFormat, { timeout: 400 });

const DebouncedQuotationSetupTextField = styled(DebouncedTextField)`
  width: 130px;
  padding-left: 30px;
  text-align: center;
  input {
    text-align: center;
  }
`;

const QuotationSetupTypography = styled(Typography)`
  width: 250px;
`;

const WalletSetupItem = ({ title, propertyName, walletSetup, isAuthorizedForEdit }) => {
  const { yearly, monthly, daily, onChange } = useUpdateWalletSetup(
    propertyName,
    walletSetup,
  );

  return (
    <Box display="flex">
      <QuotationSetupTypography variant="subtitle1">
        {title}
      </QuotationSetupTypography>
      <DebouncedQuotationSetupTextField
        customInput={TextField}
        suffix="%"
        thousandSeparator
        disabled={!isAuthorizedForEdit}
        value={Numbers.cleanNumber(yearly)}
        decimalScale={2}
        fixedDecimalScale
        onValueChange={onChange}
      />
      <DebouncedQuotationSetupTextField
        customInput={TextField}
        suffix="%"
        thousandSeparator
        disabled
        decimalScale={2}
        fixedDecimalScale
        value={Numbers.cleanNumber(monthly)}
      />
      <DebouncedQuotationSetupTextField
        customInput={TextField}
        thousandSeparator
        disabled
        suffix="%"
        decimalScale={3}
        fixedDecimalScale
        value={Numbers.cleanNumber(daily)}
      />
    </Box>
  );
};

WalletSetupItem.propTypes = {
  title: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  walletSetup: PropTypes.object.isRequired,
};

export default WalletSetupItem;
