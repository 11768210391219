import React, { useState, useEffect } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import useGraphicMaterialRowsMapper from '../../../Core/customHooks/useGraphicMaterialRowsMapper';
import useGraphicMaterialDetails from '../../../Core/customHooks/useGraphicMaterialDetails';
import Styles from '../styles/SelectRows.module.scss';

export default function CheckboxesGroup({ selectedImage, onAccept, onDelete }) {
  const { rows } = useGraphicMaterialRowsMapper();
  const { loading, graphicMaterial } = useGraphicMaterialDetails(selectedImage);
  const propertyName = rows.map((row) => row.name);
  const objectToState = {};

  propertyName.forEach(name => {
    objectToState[name] = false;
  });

  const [state, setState] = useState(objectToState);

  const handleChange = async (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (!loading && graphicMaterial) {
      // Set state from backend
      const assignedTo = {};
      graphicMaterial.properties.forEach(property => (
        assignedTo[property.name] = true
      ));
      setState({
        ...state,
        ...assignedTo
      });
    }
  }, [loading]);

  return (
    <div>
      <FormControl component="fieldset" className={Styles.container}>
        <FormLabel component="legend" className={Styles.center}>Seleccione los apartamentos</FormLabel>
        {!loading && (
          <FormGroup className={Styles.selectContainer}>
            {rows.map((row) => {
              return (
                <>
                  <FormControlLabel
                    key={row.name}
                    className={Styles.size}
                    color="primary"
                    control={
                      <Checkbox
                        color='primary'
                        checked={state[row.name]}
                        onChange={(event) => {
                          handleChange(event);
                          const addOrDeleteValidation = event.target.checked;
                          const img = selectedImage;
                          if (addOrDeleteValidation) {
                            onAccept(row.id, img);
                          } else {
                            onDelete(row.id, img);
                          }
                        }}
                        name={`${row.name}`}
                      />
                    }
                    label={`Apartamento ${row.name}`}
                  />
                </>
              );
            })}
          </FormGroup>
        )}
        {loading && (
          <div className={Styles.loadingContainer}>
            <CircularProgress color='secondary' />
          </div>
        )}
      </FormControl>
    </div>
  );
}

CheckboxesGroup.propTypes = {
  selectedImage: PropTypes.number,
  onAccept: PropTypes.func,
  onDelete: PropTypes.func,
};
