import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserServices from 'services/user/UserServices';
import { AgentModel } from '../models/AgentModel';

const service = new UserServices();

const useAgents = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const [agents, setAgents] = useState<AgentModel[]>([]);

  const fetchAgents = useCallback(async () => {
    if (towerId) {
      const { data } = await service.agents<{ users: AgentModel[] }>(towerId);
      setAgents(data.users);
    }
  }, [towerId]);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return [agents];
};

export default useAgents;
