import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RealPaymentsServices from '../../Services/RealPaymentsServices';
import { updateClientHubForWalletProperty } from '../actions';

function useClientHubForPropertyRequest(propertyId) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [id, setPropertyId] = useState(null);

  const services = new RealPaymentsServices();

  const fetch = async () => {
    setLoading(true);
    try {
      const hub = await services.getClientHubForProperty(propertyId, towerId);
      dispatch(updateClientHubForWalletProperty(hub.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(updateClientHubForWalletProperty({}));
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!isLoading && propertyId && propertyId !== id) {
      setPropertyId(propertyId);
      fetch();
    }
  }, [propertyId]);

  return { isLoading };
}

export default useClientHubForPropertyRequest;
