import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { DataTypeProvider, Column } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import colors from '../../../../../assets/styles/settings/colors.module.scss';

interface Props {
  value?: string | number;
}

const RowTypography = styled.div<Props>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: ${(props) => {
    return Number(props.value) < 0
      ? colors.stateSuccess600
      : Number(props.value) > 0
      ? colors.stateError600
      : colors.stateNeutral600;
  }};
`;

const ContractDetailDifferenceFormatter: React.FC<Props> = ({ value }) => {
  return (
    <>
      <RowTypography value={value}>
        <dt>Variación</dt>
        <dd>
          <NumberFormat
            displayType="text"
            value={value}
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            prefix="$"
          />
        </dd>
      </RowTypography>
    </>
  );
};

export default ContractDetailDifferenceFormatter;
