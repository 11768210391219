import { useState, useEffect } from 'react';
import { PaymentCycle } from '../../../../models/ContractPayments';

const usePaymentCycle = ({ currentContractPayment, paymentCyclesList }) => {
  const [inputCycleValue, setInputCycleValue] = useState('');

  const [autocompleteCycleValue, setAutocompleteCycleValue] = useState<PaymentCycle | null>(null);

  useEffect(() => {
    if (currentContractPayment) {
      const cycleSelected = paymentCyclesList?.find((v) => v.id == currentContractPayment?.paymentCycle) || null;

      setAutocompleteCycleValue(cycleSelected);
    }
  }, [currentContractPayment]);

  return {
    inputCycleValue,
    setInputCycleValue,
    autocompleteCycleValue,
    setAutocompleteCycleValue,
  };
};

export default usePaymentCycle;
