import { Tab as MuiTab } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { TabProps } from '../../interfaces/Tab.types';

import styles from './Tab.module.scss';

const sizeClasses = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

const getSizeClass = (size = 'small') => sizeClasses[size];

const Tab: React.FC<TabProps> = ({ size, className, ...restProps }) => (
  <MuiTab className={clsx(styles.root, getSizeClass(size), className)} {...restProps} />
);

export default Tab;
