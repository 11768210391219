import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import agent from '../config/config';
import UserServices from './user/UserServiceDefinitions';

class Services {
  private axios: AxiosInstance;
  constructor() {
    this.axios = axios;
    axios.defaults.timeout = 1000 * 180;
    Services.setupAuthentication();
  }

  post<T>(url, data: any = null, config?: AxiosRequestConfig) {
    console.log('url 🐒 post', url);
    const d = () => this.axios.post<T>(url, data, config);
    return this.axiosPromise<T>(() => this.axios.post(url, data, config));
  }

  put<T>(url, data: any = null, config?: AxiosRequestConfig) {
    console.log('url 🐒 put', url);
    return this.axiosPromise<T>(() => this.axios.put(url, data, config));
  }

  patch<T>(url, data: any = null, config?: AxiosRequestConfig) {
    return this.axiosPromise<T>(() => this.axios.patch(url, data, config));
  }

  get<T>(url, config?: AxiosRequestConfig) {
    console.log('url 🐒 get', url, this.axios.defaults.headers);
    return this.axiosPromise<T>(() => this.axios.get(url, config));
  }

  download<T>(url, data) {
    console.log('url 🐒 get', url);
    return this.axiosPromise<T>(() => this.axios.get(url, data));
  }

  delete<T>(url, data: any = null, config = {}) {
    console.log('url 🐒 delete', url);
    const newConfig = {
      ...config,
      data,
    };
    return this.axiosPromise<T>(() => this.axios.delete(url, newConfig));
  }

  renewToken(promise, resolve, reject) {
    agent.reloadCurrentUser();
    if (agent.currentUser) {
      const { refreshToken } = agent.currentUser;
      this.axios
        .post(UserServices.renewToken, { refreshToken }, { timeout: 1000 * 80 })
        .then((response) => {
          if (response.data.token) {
            agent.saveUser(response.data);
            this.axiosPromise(promise, 2, false).then(resolve).catch(reject);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            agent.logout();
            window.location.reload();
          }
          reject(error);
        });
    } else {
      agent.logout();
      window.location.reload();
    }
  }

  axiosPromise<T>(promise: () => Promise<AxiosResponse<T>>, retry = 2, isAuthorizationEnabled = true) {
    return new Promise((resolve: (value: AxiosResponse<T>) => void, reject) => {
      promise()
        .then((response: AxiosResponse<T>) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            if (agent.currentUser && agent.currentUser.refreshToken && isAuthorizationEnabled) {
              this.renewToken(promise, resolve, reject);
              return;
            }

            agent.logout();
            window.location.reload();
            reject(error);
          } else if (
            retry >= 1 &&
            retry < 5 &&
            ((error.response && error.response.status !== 404 && error.response.status !== 400) || !error.response)
          ) {
            const newRetry = retry - 1;
            this.axiosPromise(promise, newRetry).then(resolve).catch(reject);
          } else {
            const errorObject = error.response ? error.response.data : { message: 'Ha ocurrido un error' };
            reject(errorObject);
          }
        });
    });
  }

  static setupAuthentication() {
    const { currentToken } = agent;
    if (currentToken !== undefined && currentToken !== '') {
      axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`;
      axios.defaults.withCredentials = true;
    } else {
      axios.defaults.headers.common.Authorization = '';
    }
  }
}

export default Services;
