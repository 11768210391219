import React from 'react';
import { TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';

import TableCellTooltipTypography from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellTooltipTypography';
import TableCellCurrencyTypography from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellCurrencyTypography';
import TableCellPercentTypography from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellPercentTypography';

const ContractsGridSummaryItemComponent: React.ComponentType<TableSummaryRow.ItemProps> = ({ type, value }) => {
  const cellValue = value || 0;
  switch (type) {
    case 'percentExecuted':
    case 'percentInvoiced':
      //@ts-ignore
      return <TableCellPercentTypography value={Number(cellValue)} />;
    case 'title':
      //@ts-ignore
      return <TableCellTooltipTypography value={cellValue} />;

    default:
      //@ts-ignore
      return <TableCellCurrencyTypography value={Number(cellValue)} />;
  }
};

export default ContractsGridSummaryItemComponent;
