import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SalesStatusServices from '../../services/SalesStatusServices';
import { SalesStatusProperty } from '../DTO/SalesStatusViewDTO';
import SalesStatusStates from '../enums/SalesStatusStates';
import { UpdatePropertiesHandler } from '../types/SalesStatus.types';

const salesStatusServices = new SalesStatusServices();

type RouteParams = {
  towerId: string;
};

function useSalesStatusLoader() {
  const { towerId } = useParams<RouteParams>();
  const [viewState, setViewState] = useState<SalesStatusStates>(SalesStatusStates.Loading);
  const [properties, setProperties] = useState<SalesStatusProperty[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await salesStatusServices.getViewData(towerId);
        setProperties(data.properties);
        setViewState(SalesStatusStates[data.status]);
      } catch (error) {
        const message = (error as Error).message;
        enqueueSnackbar(message, { variant: 'error' });
        setViewState(SalesStatusStates.Error);
      }
    };

    fetchData();
  }, []);

  const updateProperties: UpdatePropertiesHandler = (properties) => {
    setProperties(properties);
  };

  return { viewState, towerId, properties, updateProperties };
}

export default useSalesStatusLoader;
