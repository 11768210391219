import moment from 'moment';
import { useQueryClient } from 'react-query';
import { DeedExpensesHelper } from '../helpers/DeedExpensesHelper';
import { EVALPRO_DEED_EXPENSES_MONTHS } from '../reactQuery/RequestConstants';
import useUpdateEvalproDeedExpensesConfig from './useUpdateEvalproDeedExpensesConfig';

const useEvalproDeedExpensesDeedMonths = (
  towerId: string,
  endOfSalesDate: number,
  displacementStartDateDeed: number,
  displacementEndDateDeed: number,
) => {
  const queryClient = useQueryClient();
  const { fetch, isLoading } = useUpdateEvalproDeedExpensesConfig(towerId, 'displacementEndDateDeed');

  const onFetchSuccess = () => queryClient.invalidateQueries(EVALPRO_DEED_EXPENSES_MONTHS);

  const onChange = (newDisplacement: number) => {
    const displacementStartMoment = moment(Number(endOfSalesDate)).add(displacementStartDateDeed, 'M').valueOf();
    if (DeedExpensesHelper.validateDisplacementIsSameOrBefore(displacementStartMoment, newDisplacement))
      fetch(newDisplacement, { onSuccess: onFetchSuccess });
  };

  const getDisplacement = () => (displacementStartDateDeed ?? 0) + (displacementEndDateDeed ?? 0);

  return {
    isLoading,
    onChange,
    getDisplacement,
  };
};

export default useEvalproDeedExpensesDeedMonths;
