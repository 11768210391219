import { Grid } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button/Button';
import ProfileInfo from './UI/components/ProfileInfo';

const user = (props) => {
  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12}>
        <div
        // className={styles.Actions}
        >
          <Button onClick={props.logoutAction}>Cerrar Sesión</Button>
          <Button onClick={props.updatePassword}>Cambiar contraseña</Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <ProfileInfo />
      </Grid>
    </Grid>
  );
};

export default user;
