import { useState } from 'react';
import { uniq, without } from 'lodash';

type HookType = () => {
  isExpanded: (categoryId: string) => boolean;
  setExpanded: (categoryId: string) => void;
};

const useCategoryContractList: HookType = () => {
  const [expandedList, setExpandedList] = useState<string[]>([]);

  const isExpanded = (categoryId: string) => {
    return expandedList.includes(categoryId);
  };

  const setExpanded = (categoryId: string) => {
    let list = expandedList;

    if (expandedList.includes(categoryId)) {
      list = without(list, categoryId);
    } else {
      list.push(categoryId);
    }

    setExpandedList(uniq(list));
  };

  return { setExpanded, isExpanded };
};

export default useCategoryContractList;
