import { FC } from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';

import { Button as MUIButton, ButtonProps as MuiButtonProps, IconButton, IconButtonProps } from '@material-ui/core';
import ButtonDesignSystem from './Button.ds';
import colors from '../../../assets/styles/settings/colors.module.scss';

export type ButtonProps = Omit<MuiButtonProps, 'variant'> & {
  variant?: 'text' | 'outlined' | 'contained' | 'tertiary';
};

const resolveVariant = (prop: string, props: ButtonProps) =>
  get(ButtonDesignSystem, `variant.${props.variant || 'default'}.${prop}`, 'inherit');

const resolveSize = (prop: string, props: ButtonProps) =>
  get(ButtonDesignSystem, `size.${props.size || 'medium'}.${prop}`, 'inherit');

const isOutlined = (props: ButtonProps) => props.variant === 'outlined';

const getPadding = (props: ButtonProps) =>
  isOutlined(props) ? resolveSize('outlinedPadding', props) : resolveSize('padding', props);

const getBorderWidth = (props: ButtonProps) => (isOutlined(props) ? resolveSize('borderWidth', props) : 'unset');

const buttonMixin = (props: ButtonProps) => css`
  /* Box-model */
  padding: ${getPadding(props)};
  min-height: ${resolveSize('minHeight', props)};

  /* Typography */
  color: ${resolveVariant('color', props)};
  font-family: ${resolveSize('fontFamily', props)};
  font-size: ${resolveSize('fontSize', props)};
  font-weight: ${resolveSize('fontWeight', props)};
  line-height: ${resolveSize('lineHeight', props)};
  letter-spacing: ${resolveSize('letterSpacing', props)};
  text-transform: capitalize;

  /* Visual */
  background-color: ${resolveVariant('backgroundColor', props)};
  border-radius: ${resolveSize('borderRadius', props)};
  border-color: ${resolveVariant('borderColor', props)};
  border-width: ${getBorderWidth(props)};

  &:hover {
    /* Typography */
    color: ${resolveVariant('hover.color', props)};

    /* Visual */
    background-color: ${resolveVariant('hover.backgroundColor', props)};
    border-color: ${resolveVariant('hover.borderColor', props)};
  }

  &:focus {
    /* Typography */
    color: ${resolveVariant('focus.color', props)};

    /* Visual */
    background-color: ${resolveVariant('focus.backgroundColor', props)};
    border-color: ${resolveVariant('focus.borderColor', props)};
    outline: ${resolveVariant('focus.outline', props)};
    outline-offset: 0;
  }

  &:active {
    /* Typography */
    color: ${resolveVariant('active.color', props)};

    /* Visual */
    background-color: ${resolveVariant('active.backgroundColor', props)};
    border-color: ${resolveVariant('active.borderColor', props)};
  }

  &.Mui-disabled {
    /* Typography */
    color: ${resolveVariant('disabled.color', props)};

    /* Visual */
    background-color: ${resolveVariant('disabled.backgroundColor', props)};
    border-color: ${resolveVariant('disabled.borderColor', props)};
  }

  &.MuiButton-contained {
    /* Visual */
    box-shadow: none;
  }

  &.MuiIconButton-root {
    /* Box-model */
    height: inherit;
  }
  & .button-label {
    text-transform: lowercase;
  }
  & .button-label::first-letter {
    text-transform: uppercase;
  }
`;

const Button: FC<ButtonProps> = styled(MUIButton)<ButtonProps>`
  ${buttonMixin}
`;

Button.defaultProps = {
  variant: undefined,
  size: 'medium',
};

const MuiIconButton: FC<IconButtonProps> = styled(IconButton)<IconButtonProps>`
  border: 0;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  border-radius: 50%;
  padding: 0.25em;
  border: none;
  &:hover {
    color: ${colors.primary600};
    background-color: ${colors.primary100};
  }
`;

export { Button, MuiIconButton };
