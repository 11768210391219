function useSegmentedControlComparison(
  currentValue: string,
  onButtonClick: ((id: string) => void) | null,
) {
  const compare = (value: string) => value === currentValue;

  const onClick = (id: string) => () => {
    if (onButtonClick) onButtonClick(id);
  };

  return { compare, onClick };
}

export default useSegmentedControlComparison;
