import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ScheduleServices from '../../../../services/schedule/ScheduleServices';

const services = new ScheduleServices();

export default function useBalancePointDateChange(
  balancePointType,
  balancePercentage,
  balancePointDate,
  updateScheduleData,
  towerId,
) {
  const [type, setType] = useState(balancePointType);
  const [percentage, setPercentage] = useState(balancePercentage);
  const [balanceDate, setBalanceDate] = useState(balancePointDate);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (balancePercentage && balancePercentage !== percentage) {
      setPercentage(balancePercentage * 100);
    }

    if (balancePointType && balancePointType !== type) {
      setType(balancePointType);
    }

    if (balancePointDate && balancePointDate !== balanceDate) {
      setBalanceDate(balancePointDate);
    }
  }, [balancePointType, balancePercentage, balancePointDate]);

  const onChangeType = async (event) => {
    const oldType = type;
    try {
      setType(event.target.value);
      const scheduleUpdated = await services.putBalancePointType(towerId, {
        balancePointType: event.target.value,
        balancePercentage: percentage / 100,
      });
      setBalanceDate(scheduleUpdated.data.balancePointDate);
      updateScheduleData(scheduleUpdated.data);
    } catch (error) {
      setType(oldType);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onChangePercentage = async (values) => {
    const oldPercentage = percentage;
    try {
      setPercentage(values.floatValue);
      const scheduleUpdated = await services.putBalancePointType(towerId, {
        balancePointType: type,
        balancePercentage: values.floatValue / 100,
      });
      setBalanceDate(scheduleUpdated.data.balancePointDate);
      updateScheduleData(scheduleUpdated.data);
    } catch (error) {
      setPercentage(oldPercentage);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return { type, onChangeType, percentage, onChangePercentage, balanceDate };
}
