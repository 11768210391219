import { Grid, TableFixedColumns, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import React, { FC } from 'react';
import Prices from '../../../../../components/Area/Prices2/Prices';
import TableCell from '../../../../../shared/tables/TableCell';
import TableContainer from '../../../../../shared/tables/TableContainer';
import TableHeaderRowCell from '../../../../../shared/tables/TableHeaderRowCell';
import useAreaStaticTable from '../../../Core/customHooks/AreaStaticTable/useAreaStaticTable';
import { AreaStaticTableProps } from '../../../Core/customHooks/AreaStaticTable/AreaStaticTableProps';
import { AreasFixedColumns } from '../../../Core/constants/AreasConstants';
import AreaHeaderStaticTableProvider from '../providers/AreaHeaderProvider/AreaHeaderStaticProvider';
import useAreaPrices from '../../../Core/customHooks/AreaStaticTable/useAreaPrices';
import VirtualTableDataTypeProvider from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import AreaTableNameProvider from '../providers/AreaTableNameProvider';
import AreaGroupProvider from '../providers/AreaGroupProvider';

const AreaStaticTable: FC<AreaStaticTableProps> = ({ areaTypes, rows, customColumnTotals, towerId }) => {
  const { columns, columnsExtentions } = useAreaStaticTable(areaTypes);
  const { areaTypeId, selectAreaType } = useAreaPrices();

  return (
    <>
      <Grid rows={rows} columns={columns}>
        <VirtualTable
          height="75vh"
          containerComponent={TableContainer}
          cellComponent={TableCell}
          columnExtensions={columnsExtentions}
        />
        <TableHeaderRow cellComponent={TableHeaderRowCell} />
        <TableFixedColumns leftColumns={AreasFixedColumns} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.numeric} />
        <AreaGroupProvider for={['group']} />
        <AreaHeaderStaticTableProvider
          areaTypes={areaTypes}
          columnNames={AreasFixedColumns}
          totals={customColumnTotals}
          onSelectAreaTypeId={selectAreaType}
        />
        <AreaTableNameProvider />
      </Grid>
      <Prices
        handleClose={() => selectAreaType(0)}
        inputMethod={'MANUAL'}
        towerId={towerId}
        updateInformation={() => {}}
        areaTypeId={areaTypeId}
        disableSold={false}
        open={!!areaTypeId}
      />
    </>
  );
};

export default AreaStaticTable;
