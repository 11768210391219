import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ConfirmDialogForUpload({ onConfirm, onCancel, isOpen }) {
  return (
    <MuiDialog open={isOpen}>
      <DialogTitle>Cargar plantilla</DialogTitle>
      <DialogContent>
        <DialogContentText color="secondary">
          ¿Seguro que deseas continuar? Al hacer esto se perderán todas las
          estrategias y los datos de venta que anteriormente hayas ingresado
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" color="primary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button size="small" color="secondary" onClick={onConfirm}>
          Aceptar
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

ConfirmDialogForUpload.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ConfirmDialogForUpload;
