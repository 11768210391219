import { ChangeEvent } from 'react';

import { IndexedFieldOption } from '../interfaces/featureField.types';
import { UpdateFeatureFieldOptionHook } from '../interfaces/useUpdateFeatureFieldOption.types';

const updateColor = (option: IndexedFieldOption, color: string): IndexedFieldOption => ({
  ...option,
  color,
});

const updateValue = (option: IndexedFieldOption, value: string): IndexedFieldOption => ({
  ...option,
  value,
});

function useUpdateFeatureFieldOption({ option, onChange }: UpdateFeatureFieldOptionHook) {
  const handleChangeColor = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(updateColor(option, event.target.value));
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(updateValue(option, event.target.value));
  };

  return { handleChangeColor, handleChangeValue };
}

export default useUpdateFeatureFieldOption;
