import React, { useState } from 'react';
import useAlert from 'shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from 'shared/components/Alert/Core/interfaces/ModalProps';

function useCutsControlBandCell() {
  const [useAlertProps, setUseAlertProps] = useState({
    isOpen: false,
    title: '',
    variant: AlertVariants.Accept,
    acceptAction: () => {},
  });

  const { showAlert } = useAlert(useAlertProps);

  const cutProps = ({onSubmitHandler, towerWithoutCuts}) => {
    return {
      isOpen: false,
      title: towerWithoutCuts ? 'Sobreescribir el corte' : 'Copiar corte',
      variant: AlertVariants.Accept,
      acceptAction: onSubmitHandler,
    };
  }

  const getAlertDescription = (cutDate: any, towerWithoutCuts) => {
    const cutContent = towerWithoutCuts ? `El corte del ${cutDate} se sobreescribira con los datos del corte actual.` : '¿Desea copiar el corte actual?'

    return cutContent;
  }

  const cutsHandleClick = ({cutDate, onSubmitHandler, towerWithoutCuts}) => {
    setUseAlertProps(cutProps({onSubmitHandler, towerWithoutCuts}));
    showAlert(getAlertDescription(cutDate, towerWithoutCuts));
  };


  return { cutsHandleClick };
}

export default useCutsControlBandCell;
