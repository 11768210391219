import Services from '../../../services/services';
import ProjectResultsStateServicesDefinition from './ProjectResultsStateServicesDefinition';

export default class ProjectResultsStateServices extends Services {
  getProjectResultsState(projectId) {
    return this.get(
      ProjectResultsStateServicesDefinition.getProjectResultsState(projectId),
    );
  }
}
