import { AgentModel } from 'App/Agents/Core/models/AgentModel';
import { ChangeEvent } from 'react';
import { useForm } from 'shared/customHooks/useForm';
import { ClientCompanyProps } from '../interfaces/ClientCompanyProps';
import { ClientCompanyModel } from '../models/ClientCompanyModel';

const useAssignedAgent = ({
  model: clientCompanyProp,
  onChangeModel,
  agents
}: ClientCompanyProps) => {

  const form = useForm<{ agent: AgentModel | null }>({
    validations: {
      agent: {
        custom: {
          isValid: (value) => value?.id !== undefined,
          message: 'Se requiere de un asesor responsable',
        }
      }
    },
    initialValues: {
      agent: agents?.find(agent => agent.id === clientCompanyProp?.assignedAgentId) || null,
    },
    onSubmit: async (value, reset) => {
      const model: ClientCompanyModel = {
        assignedAgentId: value.agent?.id
      }
      onChangeModel(model);
      reset();
    }
  }, [clientCompanyProp])

  return form;
};

export default useAssignedAgent;
