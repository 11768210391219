import { useContext, useEffect, useState } from 'react';
import DialogContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/context';
import IsEditableContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/Payments/contexts';

function useQuotationEditionFinalType() {
  const context = useContext(DialogContext);
  const isEditingModeFromContext = useContext(IsEditableContext);
  const { updateQuotation, quotation: currentQuotation } = context;
  const [paymentTypeSelected, setCurrentPaymentType] = useState(
    currentQuotation.finalPaymentType,
  );
  const isEditingMode =
    isEditingModeFromContext ||
    (currentQuotation.temp && !currentQuotation.isAssigned);

  useEffect(() => {
    if (paymentTypeSelected !== currentQuotation.finalPaymentType)
      setCurrentPaymentType();
  }, [currentQuotation.finalPaymentType]);

  const onChange = (event) => {
    setCurrentPaymentType(event.target.value);
    updateQuotation({
      ...currentQuotation,
      finalPaymentType: event.target.value,
    });
  };

  return {
    paymentTypeSelected,
    isEditingMode,
    onChange,
    options: currentQuotation.quotationSetup.quotationFinalPaymentTypes || [],
  };
}

export default useQuotationEditionFinalType;
