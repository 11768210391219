import { useState, InvalidEvent } from 'react';

function useSingleSelectValidation() {
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const handleOnIvalid = (event: InvalidEvent<HTMLInputElement>) => {
    if (event.currentTarget.validity.valueMissing) {
      setHasError(true);
      setHelperText('Campo obligatorio');
    }
  };

  const resetError = () => {
    setHasError(false);
    setHelperText('');
  };

  return { hasError, helperText, handleOnIvalid, resetError };
}

export default useSingleSelectValidation;
