import React, { FC } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@material-ui/core';

const AreaTableNameValueFormater: FC<DataTypeProvider.ValueFormatterProps> = (props) => (
  <Box textAlign="center">{props.value}</Box>
);

const AreaTableNameProvider: FC = () => {
  return <DataTypeProvider for={['name']} formatterComponent={AreaTableNameValueFormater} />;
};

export default AreaTableNameProvider;
