import _ from 'lodash';

const useRowsAndColumnsForCompanySchedules = (projects) => {
  if (!projects) {
    return { rows: [], columns: [] };
  }

  const columnsFromProjects = Object.keys(projects.arrayForColumns).map(
    (date) => {
      return {
        id: date,
        label: date,
        maxWidth: 10,
        maxHeight: 10,
      };
    },
  );

  const columns = [
    { id: 'name', label: 'Nombre', minWidth: 200 },
    ...columnsFromProjects,
  ];

  const dataForRows = projects.arrayForRows.map((project) => {
    return Object.values(project).map((project) => {
      return project.map((tower) => {
        const towerName = {
          name: `${tower.towerName} - ${tower.projectName}`,
          maxWidth: 10,
          color: 'blue',
        };

        const structuring = tower.structuring.reduce((date1, date2) => {
          return {
            ...date1,
            [date2]: 'yellow',
          };
        }, {});

        const presale = tower.presale.reduce((date1, date2) => {
          return {
            ...date1,
            [date2]: 'blue',
          };
        }, {});

        const construction = tower.construction.reduce((date1, date2) => {
          return {
            ...date1,
            [date2]: 'green',
          };
        }, {});

        const deed = tower.deed.reduce((date1, date2) => {
          return {
            ...date1,
            [date2]: 'red',
          };
        }, {});

        const arrayForRow = Object.assign(
          {},
          structuring,
          presale,
          construction,
          deed,
        );

        return {
          ...towerName,
          ...arrayForRow,
          projectName: tower.projectName,
          towerName: tower.towerName,
        };
      });
    });
  });

  const rows = dataForRows.flatMap((project) => {
    return project.flatMap((tower) => {
      return tower;
    });
  });

  return { rows: _.sortBy(rows, ['projectName', 'towerName']), columns };
};

export default useRowsAndColumnsForCompanySchedules;
