import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Table } from '@devexpress/dx-react-grid';
import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import React, { FC } from 'react';
import TableCell from 'shared/tables/TableCell';
import MonthlyInterestTableCell from '../cells/MonthlyInterestTableCell';
import TableMonthlyInterestConnector from './TableMonthlyInterestConnector';
import { ConvertColumnsToColumnBandsFunction, ParentColumnForBand } from '../../../../shared/tables/Core/TableHelpers';
import usePredicateForMonthlyInterestTable, {
  TemplateCell,
} from '../../core/customHooks/usePredicateForMonthlyInterestTable';
import MonthlyInterestCellContentUpdater, {
  MonthlyInterestCellContentUpdaterProps,
} from '../cells/MonthlyInterestCellContentUpdater';
import { MonthlyInterestOnValueChange } from 'App/MonthlyInterestReport/core/customHooks/useMonthlyInterestValueChanged';

interface TableMonthlyInterestCellsProviderProps {
  findParentFromColumnName: ConvertColumnsToColumnBandsFunction;
  onValueChanged: MonthlyInterestOnValueChange;
}

const TableMonthlyInterestCellsProvider: FC<TableMonthlyInterestCellsProviderProps> = ({
  findParentFromColumnName,
  onValueChanged,
}) => {
  const { predicate } = usePredicateForMonthlyInterestTable();
  return (
    <Plugin name="TableMonthlyInterestCellsProvider" key="TableMonthlyInterestCellsProvider">
      <Template name="tableCell" predicate={predicate(TemplateCell.valueFormatter)}>
        {(params: TableFixedColumns.CellProps) => (
          <TableMonthlyInterestConnector
            {...params}
            findParentFromColumnName={findParentFromColumnName}
            name={TemplateCell.valueFormatter}
          />
        )}
      </Template>
      <Template name="tableCell" predicate={predicate(TemplateCell.valueEditor)}>
        {(params: TableFixedColumns.CellProps) => (
          <TableMonthlyInterestConnector
            {...params}
            findParentFromColumnName={findParentFromColumnName}
            name={TemplateCell.valueEditor}
          />
        )}
      </Template>
      <Template name={TemplateCell.valueEditor} predicate={() => true}>
        {(params: MonthlyInterestCellContentUpdaterProps) => (
          <MonthlyInterestCellContentUpdater {...params} onValueChanged={onValueChanged} />
        )}
      </Template>
    </Plugin>
  );
};

export default TableMonthlyInterestCellsProvider;
