import React, { FC } from 'react';
import { BaseClientDTO } from 'App/GeneralWallet/core/interfaces/generalWallet.types';
import useGeneralWalletThreads from '../../core/customHooks/useGeneralWalletThreads';
import GeneralWallletDetailsThread from '../components/GeneralWallletDetailsThread';
import GeneralWalletDetailsEmptyState from './GeneralWalletDetailsEmptyState';

import styles from './GeneralWalletDetailsContent.module.scss';

interface Props {
  client: BaseClientDTO;
}

const GeneralWalletDetailsContent: FC<Props> = ({ client }) => {
  const { threads, isLoadingThreads } = useGeneralWalletThreads(client.clientId, client.propertyId);
  return (
    <div className={styles.content}>
      {isLoadingThreads && <div>Cargando</div>}
      {threads.length <= 0 ? <GeneralWalletDetailsEmptyState /> : threads.map(GeneralWallletDetailsThread)}
    </div>
  );
};

export default GeneralWalletDetailsContent;
