import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import classes from '../../../styles/ResultsStateCustomCell.module.scss'

const TotalAndUtilityBeforeTaxPercentageCell = ({cellData}) => {

  return (
    <VirtualTable.Cell className={classes.virtualTableCell}>
      <Box className={classes.gruopsFloor}>
        <Typography variant="body2" className={classes.groupTypography}>
          {cellData}
        </Typography>
      </Box>
    </VirtualTable.Cell>
  );
};

TotalAndUtilityBeforeTaxPercentageCell.propTypes = {
  cellData: PropTypes.number,
};

export default TotalAndUtilityBeforeTaxPercentageCell;
