import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';
import React, { FC } from 'react';
import table from '../../../../assets/styles/components/table.module.scss';
import colors from '../../../../assets/styles/settings/colors.module.scss';
import fonts from '../../../../assets/styles/settings/fonts.module.scss';
import { MonthlyEventType } from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';
import { MonthlyPaymentRow } from '../../core/DTO/WalletMonthlyReportDTO';

const CommonMonthlyInterestFixedCell: FC = styled(TableFixedColumns.Cell)<TableFixedColumns.CellProps>`
  /* Visual */
  border-right: ${(props) => {
    if (props.tableColumn.column && props.tableColumn.column.name === 'commitmentDate') {
      return '1px #a80072 solid';
    }
  }};

  border-left: ${(props) => {
    if (props.tableColumn.column && props.tableColumn.column.name === 'paymentName') {
      return '1px #DCE2EA solid';
    }
  }};

  &:first-child {
    /* Box-model */
    padding-left: ${table.cellWidhtPadding};
  }

  &:not(.MuiTableCell-head) {
    /* Typography */
    font-family: ${fonts.headline};
    /* color: ${colors.gray900}; */
    font-weight: 400;
  }
`;

const ParentTableCell: React.FC = styled(CommonMonthlyInterestFixedCell)<TableFixedColumns.CellProps>`
  background-color: #00a3a9;
  color: #fff;
`;

const PlaceholderTableCell: React.FC = styled(CommonMonthlyInterestFixedCell)<TableFixedColumns.CellProps>`
  border-left: ${(props) => {
    if (props.tableColumn.column && props.tableColumn.column.name === 'paymentName') {
      return '0px';
    }
  }};
`;

const MonthlyInterestFixedCell: FC<TableFixedColumns.CellProps> = (props) => {
  const paymentRow: MonthlyPaymentRow = props.tableRow.row;
  if (paymentRow && paymentRow.type === MonthlyEventType.fee) {
    return <ParentTableCell {...props} />;
  }

  if (paymentRow && paymentRow.type === MonthlyEventType.placeholder) {
    return <PlaceholderTableCell {...props} children={null} />;
  }

  return <CommonMonthlyInterestFixedCell {...props} />;
};

export default MonthlyInterestFixedCell;
