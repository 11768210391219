import React, { useEffect, forwardRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Services from '../Services/MacroeconomicServices';
import { useParams } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import styles from './views/styles/macroeconomicMainView.module.scss';
import { Numbers } from '../../../helpers';
import NumberFormat from 'react-number-format';

const services = new Services();

const Macroeconomic = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams();

  const [data, setData] = useState([]);

  const [IPCForMinimumSalary, setIPCForMinimumSalary] = useState(0);
  const [SpreadIPC, setSpreadIPC] = useState(0);

  const percentage = 100;
  const inputNumbers = 6;

  const updateDecimalNumbersForPercentagesAndSetData = (currentData) => {
    const rows = currentData.map((row) => {
      return {
        ...row,
        IPC: Numbers.toFixed(row.IPC * percentage),
        DTF: Numbers.toFixed(row.DTF * percentage),
      };
    });
    setData(rows);
  };

  const fetch = async () => {
    const consult = await services.getMacroeconomic(projectId);
    updateDecimalNumbersForPercentagesAndSetData(consult.data);
    const IPCForMinimumSalaryForFront = consult.data[0].IPCForMinimumSalary * percentage;
    const SpreadIPCForFront = consult.data[0].SpreadIPC * percentage;
    setIPCForMinimumSalary(
      consult.data[0] ?
        IPCForMinimumSalaryForFront.toFixed(2) : 0
    );
    setSpreadIPC(
      consult.data[0] ?
        SpreadIPCForFront.toFixed(2) : 0
    );
  };

  const updateData = async (newValue, index, name) => {
    const prevData = [...data];
    const currentRowToUpdate = prevData[index];
    const currentRowWithUpdatedField = {
      ...currentRowToUpdate,
      [name]: newValue,
    };
    prevData[index] = currentRowWithUpdatedField;
    setData(prevData);
  };

  const saveCurrentData = async () => {
    const currentData = [...data].splice(0, 6);
    const rowsToSave = {
      macroecomonicId: currentData[0].id,
      IPC: currentData.map((row) => row.IPC / percentage),
      DTF: currentData.map((row) => row.DTF / percentage),
      minimumSalary: currentData.map((row) => row.minimumSalary).splice(0, 6),
      IPCForMinimumSalary: IPCForMinimumSalary / percentage,
      SpreadIPC: SpreadIPC / percentage
    };

    try {
      await services.putMacroeconomic(projectId, rowsToSave);
      await fetch();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    let active = true;
    if (active) {
      fetch();
      active = false;
    }
  }, []);

  return (
    <div>
      <TableContainer>
        <h2 className={styles.title}>Macroeconómicos</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={styles.TableCell}>
                <b className={styles.principal}>Descripción</b>
              </TableCell>
              {data.map((cell) => (
                <TableCell className={styles.TableCell}>
                  <b className={styles.principal}>{cell.date}</b>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={styles.TableCell}>
                <b className={styles.principal}>IPC e.a</b>
              </TableCell>
              {data.map((cell, index) => {
                if (index < inputNumbers) {
                  return (
                    <TableCell className={styles.TableCell}>
                      <NumberFormat
                        className={styles.input}
                        isNumberString
                        suffix=" %"
                        value={cell.IPC}
                        onBlur={() => {
                          saveCurrentData();
                        }}
                        onValueChange={(values) => {
                          updateData(values.floatValue, index, 'IPC');
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell className={styles.TableCell}>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      suffix=" %"
                      value={cell.IPC}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell className={styles.TableCell}>
                <b className={styles.principal}>IPC e.m</b>
              </TableCell>
              {data.map((cell) => (
                <TableCell className={styles.TableCell}>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    suffix=" %"
                    value={(cell.IPCMonthlyRate * percentage).toFixed(
                      2,
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={styles.TableCell}>
                <b className={styles.principal}>DTF e.a</b>
              </TableCell>
              {data.map((cell, index) => {
                if (index < inputNumbers) {
                  return (
                    <TableCell className={styles.TableCell}>
                      <NumberFormat
                        className={styles.input}
                        isNumberString
                        suffix=" %"
                        value={cell.DTF}
                        onBlur={() => {
                          saveCurrentData();
                        }}
                        onValueChange={(values) => {
                          updateData(values.floatValue, index, 'DTF');
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell className={styles.TableCell}>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      suffix=" %"
                      value={cell.DTF}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell className={styles.TableCell}>
                <b className={styles.principal}>DTF e.m</b>
              </TableCell>
              {data.map((cell) => (
                <TableCell className={styles.TableCell}>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    suffix=" %"
                    value={(cell.DTFMonthlyRate * percentage).toFixed(
                      2,
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={styles.TableCell}>
                <b className={styles.principal}>SMMLV</b>
              </TableCell>
              {data.map((cell, index) => {
                if (index < inputNumbers) {
                  return (
                    <TableCell className={styles.TableCell}>
                      <NumberFormat
                        className={styles.input}
                        isNumberString
                        thousandSeparator
                        prefix="$ "
                        value={cell.minimumSalary}
                        onBlur={() => {
                          saveCurrentData();
                        }}
                        onValueChange={(values) => {
                          updateData(values.floatValue, index, 'minimumSalary');
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell className={styles.TableCell}>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={Math.ceil(cell.minimumSalary)}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.IPCForMinimumSalaryContainer}>
        <h3 className={styles.IPCForMinimumSalaryTitle}>
          Reajuste sobre IPC para SMMLV:
        </h3>
        <NumberFormat
          className={styles.input}
          isNumberString
          thousandSeparator
          suffix=" %"
          value={IPCForMinimumSalary}
          onBlur={() => {
            saveCurrentData();
          }}
          onValueChange={(values) => {
            setIPCForMinimumSalary(values.floatValue || 0);
          }}
          />
      </div>
      <div className={styles.IPCForMinimumSalaryContainer}>
        <h3 className={styles.IPCForMinimumSalaryTitle}>
          SpreadIPC rendimientos financieros:
        </h3>
        <NumberFormat
          className={styles.input}
          isNumberString
          thousandSeparator
          suffix=" %"
          value={SpreadIPC}
          onBlur={() => {
            saveCurrentData();
          }}
          onValueChange={(values) => {
            setSpreadIPC(values.floatValue || 0);
          }}
        />
      </div>
    </div>
  );
};

export default Macroeconomic;
