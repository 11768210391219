import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { towerBuilder } from '../Type/type.enum';
import Services from '../../../../services/towerBuilding/TowerBuildingServices';
import BuilderTableManual from '../BuilderTableManual';
import BuilderTable from '../BuilderTable';
import MacroEconomicTable from '../MacroEconomicTable';
import {
  setBuilderRows,
  setDateCost,
  setIpc,
  setContructionType,
  setDatesBuild,
  setManualDatesValues,
  setRowData,
} from '../../actions';
import Total from './Total';
import { setCurrentTower } from '../../../../containers/TowerBuilder/action';
import moment from 'moment';

const services = new Services();

const Builder = ({
  constructionType,
  manualDates,
  onSetManualDatesValues,
  builder,
  onSetBuilderRows,
  datesBuild,
  towerId,
  projectId,
  onSetDateBuilder,
  onSetRowData,
  rowData,
  onSetCurrentTower,
  currentTower,
}) => {
  const buildDateDifference = currentTower.schedule
    ? moment(Number(currentTower.schedule.endOfSalesDate))
        .add(1, 'M')
        .startOf('month')
        .diff(
          moment(Number(currentTower.schedule.constructionStartDate)).startOf(
            'month',
          ),
          'months',
        )
    : 0;
  const { enqueueSnackbar } = useSnackbar();
  const datesValues = (builders) => {
    return builders.reduce((prev, current) => {
      if (current.pricesArray && current.pricesArray.length >= 1) {
        const row = {
          id: current.id,
          name: current.name,
          projected: current.totals
            ? current.totals.projected
            : current.projected,
          accumulated: current.totals
            ? current.totals.accumulated
            : current.accumulated,
          total: current.totals ? current.totals.total : current.total,
          ...current.pricesArray,
        };
        return [...prev, row];
      }
      return [...prev];
    }, []);
  };

  const calculateTotal = (newBuilder) => {
    const builders = newBuilder.filter((row) => !row.total && !row.pricesArray);
    const subTotal = builders.reduce(
      (prev, current) => {
        const area = Number(prev.area) + Number(current.area);
        const totalCost =
          prev.totalCost + Number(current.area) * Number(current.price);
        const price = totalCost / area;
        return {
          total: 'total',
          area: Number(area.toFixed(0)),
          price: Number(price.toFixed(0)),
          totalCost: Number(totalCost.toFixed(0)),
        };
      },
      {
        total: 'total',
        area: 0,
        price: 0,
        totalCost: 0,
      },
    );
    return subTotal;
  };

  const onSetNewConstructionFlow = async () => {
    if (datesBuild.id) {
      const constructionValue = await services.getConstructionCal(
        projectId,
        datesBuild.id,
      );
      onSetCurrentTower({
        ...currentTower,
        constructionFlow: {
          ...currentTower.constructionFlow,
          ...constructionValue.data,
        },
      });
    }
  };

  const onSetNewConstructionFlowNew = async (datesBuildData) => {
    if (datesBuildData.id) {
      const constructionValue = await services.getConstructionCal(
        projectId,
        datesBuildData.id,
      );
      onSetCurrentTower({
        ...currentTower,
        constructionFlow: {
          ...currentTower.constructionFlow,
          ...constructionValue.data,
        },
      });
    }
  };

  const addRowIntoAutomatic = async (newRow) => {
    try {
      const builderRows = [...builder].filter((row) => !row.total);
      const addService = await services.createBuilder(projectId, towerId, {
        datesBuild: { ...datesBuild, towerId },
        financialRows: [{ ...newRow }],
      });
      onSetDateBuilder(addService.data.datesBuild);
      builderRows.push({ ...newRow, ...addService.data.financialRows });
      const total = calculateTotal(builderRows);
      builderRows.push({ ...total });
      onSetBuilderRows(builderRows);
      await onSetNewConstructionFlowNew(addService.data.datesBuild);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const updateRowIntoAutomatic = async (newRow, oldRow) => {
    try {
      const builderRows = [...builder].filter((row) => !row.total);
      const index = oldRow.tableData.id;
      const updateService = await services.createBuilder(projectId, towerId, {
        datesBuild: { ...datesBuild, towerId },
        financialRows: [{ ...newRow }],
      });
      builderRows[index] = { ...newRow, ...updateService.data.financialRows };
      const total = calculateTotal(builderRows);
      builderRows.push({ ...total });
      onSetBuilderRows(builderRows);
      await onSetNewConstructionFlow();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const deleteRowIntoAutomatic = async (oldRow) => {
    try {
      const builderRows = [...builder].filter((row) => !row.total);
      await services.deleteBuilder(projectId, towerId, {
        id: oldRow.id,
      });
      const newBuilderRow = builderRows.filter((row) => row.id !== oldRow.id);
      const total = calculateTotal(newBuilderRow);
      newBuilderRow.push(total);
      onSetBuilderRows(newBuilderRow);
      await onSetNewConstructionFlow();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const addRowIntoManual = async (newRow) => {
    try {
      const builderRows = [...builder];
      const nameKeys = Object.keys(newRow);
      const dates = nameKeys.filter(
        (key) =>
          key !== 'name' &&
          key !== 'accumulated' &&
          key !== 'projected' &&
          key !== 'total' &&
          key !== 'id',
      );
      const newDates = dates.flatMap((key) => Number(newRow[key]));
      const newRowValue = { ...newRow, pricesArray: newDates, manualDates };
      const addService = await services.createBuilder(projectId, towerId, {
        datesBuild: { ...datesBuild, towerId },
        financialRows: [newRowValue],
      });
      onSetDateBuilder(addService.data.datesBuild);
      builderRows.push({ ...newRow, ...addService.data.financialRows });
      const total = calculateTotal(builderRows);
      builderRows.push({ ...total });
      onSetBuilderRows(builderRows);
      const datesResponse = datesValues(builderRows);
      onSetManualDatesValues(newDates);
      onSetRowData(datesResponse);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const updateRowIntoManual = async (newRow, oldRow, columnDef) => {
    try {
      const builderRows = [...builder].filter((row) => !row.area || !row.price);
      const builderHelper = [...builder].filter(
        (row) => !row.total && !row.pricesArray,
      );
      const builderTotal = [...builder].filter((row) => row.total && !row.name);
      const index = oldRow.tableData.id;
      const nameKeys = Object.keys(newRow);
      const dates = nameKeys.filter(
        (key) =>
          key !== 'name' &&
          key !== 'accumulated' &&
          key !== 'projected' &&
          key !== 'total' &&
          key !== 'id',
      );
      const newDates = dates.flatMap((key) => Number(newRow[key]));
      const newRowValue = { ...newRow, pricesArray: newDates, manualDates };
      const updateService = await services.createBuilder(projectId, towerId, {
        datesBuild: { ...datesBuild, towerId },
        financialRows: [newRowValue],
      });
      builderRows[index] = {
        ...updateService.data.financialRows,
      };
      onSetBuilderRows([...builderHelper, ...builderRows, ...builderTotal]);
      const datesResponse = datesValues(builderRows);
      onSetRowData(datesResponse);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const deleteRowIntoManual = async (oldRow) => {
    try {
      const builderRows = [...builder].filter((row) => !row.total);
      const rowDataPrev = [...rowData];
      const newBuilderRow = builderRows.filter((row) => row.id !== oldRow.id);
      const total = calculateTotal(newBuilderRow);
      newBuilderRow.push(total);
      const newRowData = rowDataPrev.filter((row) => row.id !== oldRow.id);
      onSetRowData(newRowData);
      await services.deleteBuilder(projectId, towerId, {
        id: oldRow.id,
      });
      onSetBuilderRows(newBuilderRow);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const updateCell = async (newValue, oldValue, newRowData, columnDef) => {
    try {
      const builderRows = [...builder].filter((row) => !row.area || !row.price);
      const builderHelper = [...builder].filter(
        (row) => !row.total && !row.pricesArray,
      );
      const builderTotal = [...builder].filter((row) => row.total && !row.name);
      const index = newRowData.tableData.id;
      const nameKeys = Object.keys(newRowData);
      const dates = nameKeys.filter(
        (key) =>
          key !== 'name' &&
          key !== 'accumulated' &&
          key !== 'projected' &&
          key !== 'total' &&
          key !== 'id',
      );
      const newDates = dates.flatMap((key) => Number(newRowData[key] || 0));
      let newRowValue = {};
      if (columnDef.field !== 'name') {
        newDates[columnDef.field] = Number(newValue);
        newRowValue = {
          ...builderRows[index],
          pricesArray: newDates,
          manualDates,
        };
      } else {
        newRowValue = {
          ...builderRows[index],
          name: newValue,
          pricesArray: newDates,
          manualDates,
        };
      }
      const updateService = await services.createBuilder(projectId, towerId, {
        datesBuild: { ...datesBuild, towerId },
        financialRows: [
          {
            id: newRowData.id,
            name: newRowData.name,
            accumulated: newRowData.accumulated,
            projected: newRowData.projected,
            total: newRowData.totál,
            ...newRowValue,
          },
        ],
      });
      builderRows[index] = {
        ...updateService.data.financialRows,
      };
      onSetBuilderRows([...builderHelper, ...builderRows, ...builderTotal]);
      const datesResponse = datesValues(builderRows);
      onSetRowData(datesResponse);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <>
      {constructionType === towerBuilder.MANUAL.code ? (
        <BuilderTableManual
          addRow={addRowIntoManual}
          updateRow={updateRowIntoManual}
          deleteRow={deleteRowIntoManual}
          updateCell={updateCell}
        />
      ) : (
        <>
          <BuilderTable
            addRow={addRowIntoAutomatic}
            updateRow={updateRowIntoAutomatic}
            deleteRow={deleteRowIntoAutomatic}
          />
          <Total buildDateDifference={buildDateDifference} />
          <MacroEconomicTable />
        </>
      )}
    </>
  );
};

Builder.propTypes = {
  constructionType: PropTypes.string.isRequired,
  towerId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  builder: PropTypes.array.isRequired,
  manualDatesValues: PropTypes.array.isRequired,
  datesBuild: PropTypes.object.isRequired,
  onSetBuilderRows: PropTypes.func.isRequired,
  onSetDateCost: PropTypes.func.isRequired,
  onSetIpc: PropTypes.func.isRequired,
  onSetConstructionType: PropTypes.func.isRequired,
  onSetDateBuilder: PropTypes.func.isRequired,
  onSetManualDatesValues: PropTypes.func.isRequired,
  onSetCurrentTower: PropTypes.func.isRequired,
  currentTower: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  constructionType: state.towerBuilder.builder.constructionType,
  manualDatesValues: state.towerBuilder.builder.manualDatesValues,
  manualDates: state.towerBuilder.builder.manualDates,
  builder: state.towerBuilder.builder.builder,
  datesBuild: state.towerBuilder.builder.datesBuild,
  towerId: state.towerBuilder.root.currentTower.id,
  projectId: state.towerBuilder.root.project.id,
  rowData: state.towerBuilder.builder.rowData,
  currentTower: state.towerBuilder.root.currentTower,
});

const mapDispatchToProps = {
  onSetBuilderRows: setBuilderRows,
  onSetDateCost: setDateCost,
  onSetIpc: setIpc,
  onSetConstructionType: setContructionType,
  onSetDateBuilder: setDatesBuild,
  onSetManualDatesValues: setManualDatesValues,
  onSetRowData: setRowData,
  onSetCurrentTower: setCurrentTower,
};

export default connect(mapStateToProps, mapDispatchToProps)(Builder);
