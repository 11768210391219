import { Card, CardContent, CardHeader } from '@material-ui/core';
import React, { FC } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { Property } from '../../../../../models/Properties';

import {
  TitleTypeProvider,
  CurrencyTypeProvider,
} from '../../../../../shared/tables/TableColumnFormat';

import {
  CondensedTableContainer,
  CondensedTableCell,
  CondensedTableHeaderRowCell,
} from '../../../../../shared/tables/CondensedTable';

import Styles from '../PropertyDetails.module.scss';
import useColumnsAndRowsForAdditionalAreas from '../Core/customHooks/useColumnsAndRowsForAdditionalAreas';

interface IAdditionalAreasCard {
  property: Property;
}

const AdditionalAreasCard: FC<IAdditionalAreasCard> = ({ property }) => {
  const { additionalAreas } = property;
  const { columns, titleColumns, currencyColumns, tableColumnExtensions } =
    useColumnsAndRowsForAdditionalAreas();

  return (
    <Card>
      <CardHeader
        title="Adicionales"
        titleTypographyProps={{
          className: Styles.cardHeader,
        }}
      />
      <CardContent>
        <Grid rows={additionalAreas} columns={columns}>
          <TitleTypeProvider for={titleColumns} />
          <CurrencyTypeProvider for={currencyColumns} />
          <Table
            columnExtensions={tableColumnExtensions}
            containerComponent={CondensedTableContainer}
            cellComponent={CondensedTableCell}
          />
          <TableHeaderRow cellComponent={CondensedTableHeaderRowCell} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AdditionalAreasCard;
