import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { ChangeSalesStatusHandler } from 'App/SalesStatus/core/types/SalesStatus.types';
import React from 'react';

import TableCell from 'shared/tables/TableCell';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';
import useSalesStatusTableColumns from '../../../core/customHooks/useSalesStatusTableColumns';
import { SalesStatusProperty } from '../../../core/DTO/SalesStatusViewDTO';
import SalesStatusCellProvider from '../../plugins/SalesStatusCellProvider/SalesStatusCellProvider';

import styles from './SalesStatusContentView.module.scss';

type Props = {
  properties: SalesStatusProperty[];
  onChangeSalesStatus: ChangeSalesStatusHandler;
};

const SalesStatusContentView: React.FC<Props> = ({ properties, onChangeSalesStatus }) => {
  const { columns, columnExtensions, statusColumns } = useSalesStatusTableColumns();

  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <Grid rows={properties} columns={columns}>
          <Table columnExtensions={columnExtensions} cellComponent={TableCell} />
          <TableHeaderRow cellComponent={TableHeaderRowCell} />
          <SalesStatusCellProvider for={statusColumns} onChange={onChangeSalesStatus} />
        </Grid>
      </div>
    </div>
  );
};

export default SalesStatusContentView;
