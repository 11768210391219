import { AreaService } from 'App/Area/Services/AreaService';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { GET_QUERY_AREAS } from '../ReactQuery/AreaReactQueryConstants';

const service = new AreaService();

interface IUseFetchAreasProps {
  towerId: string;
}

const useFetchAreas = (props: IUseFetchAreasProps) => {
  const { towerId } = props;

  const query = useQuery(
    GET_QUERY_AREAS,
    async () => {
      const { data: serviceData } = await service.getAreas(towerId);
      return serviceData;
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    return () => query.remove();
  }, []);

  return {
    areasResponse: query.data,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
  };
};

export default useFetchAreas;
