import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, CircularProgress } from '@material-ui/core';
import { Button } from '../../../../../../shared/components/Button/Button';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ConfirmIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';

export default function UploadButton({
  fileName,
  isUploadingFile,
  onChangeHandler,
  onCancel,
  onAccept,
}) {
  return (
    <>
      <input
        name="file"
        hidden
        id="raised-button-file"
        type="file"
        onChange={onChangeHandler}
      />
      <label htmlFor="raised-button-file">
        <Button
          size="small"
          component="span"
          disableElevation
          startIcon={<CloudUploadOutlinedIcon />}
          disabled={isUploadingFile}
        >
          {fileName || 'Subir documento'}
        </Button>
        {fileName && (
          <>
            <IconButton
              color="primary"
              onClick={onAccept}
              disabled={isUploadingFile}
            >
              <ConfirmIcon />
            </IconButton>
            <IconButton
              color="default"
              onClick={onCancel}
              disabled={isUploadingFile}
            >
              <CancelIcon />
            </IconButton>
            {isUploadingFile && <CircularProgress />}
          </>
        )}
      </label>
    </>
  );
}

UploadButton.propTypes = {
  fileName: PropTypes.string,
  isUploadingFile: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};
