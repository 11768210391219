const useSortingResultsStatesRows = ({
  resultsStateRows,
  contractRows,
  financing,
  financialPerformance,
}) => {

  const salesRowsFiltered = () => {
    const salesRowsFiltered = resultsStateRows.filter(
      (row) => row.parentId === 'TOTAL_SALES');
    const totalRow = resultsStateRows.find(row => row.id === 'TOTAL_SALES')
    salesRowsFiltered.unshift(totalRow);

    return salesRowsFiltered
  }

  const contractsRowsFiltered = () => {
    const construcciónRow = contractRows.find((row) => row.id === '-100');

    const contractsFiltered = contractRows.filter(
      (contract) => contract.id !== '-200' && contract.id !== '-100',
    );

    const allExpensesRows = contractsFiltered.flatMap((group) => {
      const items = group.items.flatMap((item) => {
        const contracts = item.contracts.flatMap((contract) => contract);

        return [item, ...contracts];
      });

      return [group, ...items];
    });

    const expensesRows = [construcciónRow, ...allExpensesRows];

    return expensesRows;
  }

  const financingFiltered = () => {
    let financingRows = [];

    const financingTotalRow = financing.find(row => row.id === 'financing')
    const interestRow = financing.find(row => row.id === 'INTEREST')
    const monetaryCorrectionRow = financing.find(row => row.id === 'MONETARY_CORRECTION')

    const isAllRows = financingTotalRow && interestRow && monetaryCorrectionRow

    if (isAllRows) {
      financingRows.push(financingTotalRow, interestRow, monetaryCorrectionRow)
    }

    return financingRows
  }
  const orderedRowsForSort = () => {

    const totalSalesRows = salesRowsFiltered();

    const contractRows = contractsRowsFiltered();

    const financingRows = financingFiltered();

    const utilityBeforeTax = resultsStateRows.find(row => row.id === 'UTILITY-BEFORE-TAX')

    return [...totalSalesRows, ...contractRows, ...financingRows, ...financialPerformance, utilityBeforeTax]
  };

  const rowsForSort = orderedRowsForSort();

  const idsToSortRows = rowsForSort.map(row => row?.id)

  const orderedRowsById = () => {
    const idMap = resultsStateRows.reduce((acc, row, index) => {
      acc[row.id] = index;
      return acc
    }, {})

    return idsToSortRows.map(id => resultsStateRows[idMap[id]])
  }

  const orderedRows = orderedRowsById()

  return {
    orderedRows
  };
};

export default useSortingResultsStatesRows;
