import useBoolean from 'shared/customHooks/useBoolean';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import GetInitialClient from '../helpers/GetInitialClient';
import GetInitialClientCompany from '../helpers/GetInitialClientCompany';
import GetInitialClientInformation from '../helpers/GetInitialClientInformation';
import { ClientAddressSection } from '../interfaces/ClientAddressSection';
import { ClientCompanyModel } from '../models/ClientCompanyModel';
import { ClientInformationModel } from '../models/ClientInformationModel';
import { ClientModel } from '../models/ClientModel';
import useClientService from './useClientService';

interface AddClientModalContentProps {
  onChangeModels: (changed: boolean) => void;
  onAddedClient: (client: ClientModel) => void;
  handleChangeState: () => void;
}

const useAddClientModalContent = (props: AddClientModalContentProps) => {
  const { onAddedClient, onChangeModels, handleChangeState } = props;
  const { createClient } = useClientService();

  const [client, setClient] = useState<ClientModel>(GetInitialClient());
  const [clientCompany, setClientCompany] = useState<ClientCompanyModel>(GetInitialClientCompany());
  const [clientInformation, setClientInformation] = useState<ClientInformationModel>(GetInitialClientInformation());
  const [clientAddressSection, setClientAddressSection] = useState<ClientAddressSection>();

  const { value: isLoading, setTrue: loading, setFalse: stopLoading } = useBoolean();

  const { value: isSuccessCreated, setTrue: successCreated, setFalse: notSuccessCreated } = useBoolean();

  const { value: isEndEdit, setTrue: endEdit, setFalse: notEndEdit } = useBoolean();

  const handleEndSteps = useCallback(async () => {
    loading();
    const result = await createClient({
      client,
      clientCompany,
      clientInformation,
      clientAddressSection,
    });
    if (result) {
      onAddedClient(result);
      setClient(result);
      successCreated();
    } else {
      notSuccessCreated();
      notEndEdit();
    }
    stopLoading();
  }, [client, clientCompany, clientInformation, clientAddressSection]);

  useEffect(() => {
    isEndEdit && handleEndSteps();
    return () => {};
  }, [isEndEdit]);

  const changedModels = (changed: boolean) => {
    onChangeModels(changed);
    handleChangeState();
  };

  const handleClientChange = (newClient?: ClientModel) => {
    changedModels(!!newClient);
    if (newClient?.name) setClient({ ...client, ...newClient });
  };

  const handleClientCompanyChange = (newClientCompany?: ClientCompanyModel) => {
    changedModels(!!newClientCompany);
    if (newClientCompany) setClientCompany({ ...clientCompany, ...newClientCompany });
  };

  const handleClientInformationChange = (newClientInformation?: ClientInformationModel) => {
    changedModels(!!newClientInformation);
    if (newClientInformation) setClientInformation({ ...clientInformation, ...newClientInformation });
  };

  const handleClientAddressSectionChange = (newClientAddressSection?: ClientAddressSection) => {
    changedModels(!!newClientAddressSection);
    if (newClientAddressSection)
      setClientAddressSection({
        ...clientAddressSection,
        ...newClientAddressSection,
      });
  };

  return {
    isLoading,
    isSuccessCreated,
    client,
    clientCompany,
    clientInformation,
    clientAddressSection,
    handleClientChange,
    handleClientCompanyChange,
    handleClientInformationChange,
    handleClientAddressSectionChange,
    handleEndEdit: endEdit,
  };
};

export default useAddClientModalContent;
