import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Styles from './Chart.module.scss';
import Line from '../../../../UI/ChartLine/ChartLine';
import validateSelectedGroup from '../../../Shared/Validator';
import EmptyContentMessageView from '../../../../UI/EmptyContentMessageView';
import { MARKET_GRAPH_ID } from '../../../../../containers/StrategyV2/helpers/dataset';

const Chart = ({
  groupStrategies,
  initialMonth,
  groupName,
  areAllGroupValid,
}) => {
  const makeArrayLabels = () => {
    const strategyDates =
      groupStrategies[1] !== undefined ? groupStrategies[1].data.length : 0;
    const marketDates = groupStrategies[0] ? groupStrategies[0].data.length : 0;
    const length = strategyDates > 0 ? strategyDates : marketDates;

    const month = initialMonth || Date.now();
    return Array(length)
      .fill(null)
      .map((_, index) =>
        moment(Number(month))
          .add(index, 'months')
          .format('MM/YY'),
      );
  };

  return (
    <Paper classes={{ root: Styles.container }}>
      <Grid container classes={{ root: Styles.header }}>
        <Grid>
          <Typography variant="h5">Precios</Typography>
        </Grid>
        <Grid> </Grid>
      </Grid>
      <Box pb={4} px={4}>
        {groupName === 'Total' && !areAllGroupValid ? (
          <div className={Styles.EmptyMessage}>
            <EmptyContentMessageView
              title="No se puede mostrar la grafica total ⛔️!"
              message="Para poder crear la grafica primero todos los grupos deben tener la estrategia seleccionada ✔️."
            />
          </div>
        ) : (
          <Line
            currentGroup={[...groupStrategies]}
            labels={makeArrayLabels()}
          />
        )}
      </Box>
    </Paper>
  );
};

Chart.propTypes = {
  groupStrategies: PropTypes.array,
  initialMonth: PropTypes.string,
  groupName: PropTypes.string,
  allStrategies: PropTypes.array,
  areAllGroupValid: PropTypes.bool,
};

const validateAllGroupsForTotal = (groups) => {
  const invalidGroup = Object.values(groups).find((group) => {
    return (
      group.id !== 0 &&
      group.inventory.units > 1 &&
      (group.isReset === true || !group.strategy)
    );
  });
  return !invalidGroup;
};

const mapStateToProps = (state) => {
  let currentGroup =
    state.strategy.root.strategyLines[state.strategy.root.selectedGroup];
  if (validateSelectedGroup(state.strategy.root)) {
    currentGroup =
      state.strategy.root.strategyLines.length > 0
        ? state.strategy.root.strategyLines[0]
        : null;

    if (!currentGroup)
      return {
        groupStrategies: [],
        initialMonth: Date.now(),
      };
  }
  if (!currentGroup)
    return {
      groupStrategies: [],
      initialMonth: Date.now(),
    };
  const areAllGroupValid = validateAllGroupsForTotal(
    state.strategy.root.groups,
  );

  if (currentGroup) {
    const groupStrategies = [...currentGroup.strategies].map((strategy) => {
      const firstNotNull = strategy.data.find((pointOnChart) => pointOnChart);
      const data = strategy.data.map((point) =>
        strategy.id && strategy.id !== MARKET_GRAPH_ID && !point
          ? firstNotNull
          : point,
      );
      return { ...strategy, data };
    });

    return {
      groupStrategies: groupStrategies ? [...groupStrategies] : [],
      initialMonth: currentGroup.initialMonthForChart,
      groupName: currentGroup.type,
      areAllGroupValid,
    };
  }

  return {
    groupStrategies: [],
    initialMonth: Date.now(),
  };
};
export default connect(mapStateToProps)(Chart);
