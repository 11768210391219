import { useHistory } from 'react-router-dom';

import Agent from 'config/config';
import { DashboardRoutes } from 'routes/local/routes';
import { ChangeTowerHanlder, TowerClickEvent } from '../types/Stages.types';
import StagesUserRole from '../utils/StagesUserRole';
import { StageTowerDTO } from '../DTO/StageTowersDTO';

const isClientsPath = (userType: string): boolean =>
  StagesUserRole.isUser(userType) || StagesUserRole.isSales(userType);

const getMainComponentPath = (userType: string): string =>
  isClientsPath(userType) ? DashboardRoutes.clients.value : DashboardRoutes.schedule.value;

const getTowerUrl = (userType: string, tower: StageTowerDTO) => {
  const mainComponentPath = getMainComponentPath(userType);

  return `${DashboardRoutes.base}${mainComponentPath}${tower.id}`;
};

function useStagesNavigation(changeTower: ChangeTowerHanlder) {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const goToUrl = (openNewTab: boolean, url: string) => {
    openNewTab ? window.open(url, '_blank') : history.push(url);
  };

  const goToTower = ({ tower, openNewTab }: TowerClickEvent) => {
    const towerUrl = getTowerUrl(Agent.currentUser.userType, tower);

    changeTower(tower);
    goToUrl(openNewTab, towerUrl);
  };

  return { goBack, goToTower };
}

export default useStagesNavigation;
