const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export default {
  staging: {
    serverUrl: SERVER_URL || 'https://pefpiapis.herokuapp.com/api/',
  },
  development: {
    serverUrl: SERVER_URL || 'http://localhost:1337/api/',
  },
  production: {
    // serverUrl: SERVER_URL || 'https://dev.likemetric.com/server/api/',
    serverUrl: SERVER_URL || 'https://likemetric.com/server/api/',
  },
};
