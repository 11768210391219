import { useContext, useEffect, useState } from 'react';
import ContainerContext from '../../../../containers/Client/context';
import ClientAddressType from '../models/ClientAddressType';
import AddressForClientFunctions from './AddressForClientFunctions';

function useClientAddressUpdaterForOffice(type) {
  const { currentAddressForOffice, selectedClient } = useContext(
    ContainerContext,
  );
  const [address, setAddress] = useState({});
  const [customDescription, setCustomDescription] = useState(
    selectedClient?.clientAdditionalInfo?.companyAddress,
  );

  useEffect(() => {
    setCustomDescription(selectedClient?.clientAdditionalInfo?.companyAddress);
  }, [selectedClient, selectedClient?.clientAdditionalInfo?.companyAddress]);

  useEffect(() => {
    setAddress(currentAddressForOffice);
  }, [currentAddressForOffice]);

  const updateWhenIsOpen = (event) => {
    setCustomDescription(event.target.value);
  };

  const updateWhenIsHelper = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
  };

  const update =
    type === ClientAddressType.OPEN.code
      ? updateWhenIsOpen
      : updateWhenIsHelper;

  const descriptionFromModel = AddressForClientFunctions.getDescriptionForAddress(
    address,
  );

  const addressDescription =
    type === ClientAddressType.OPEN.code
      ? customDescription
      : descriptionFromModel;

  return {
    clientAddress: address,
    addressDescription,
    selectedClient,
    update,
  };
}

export default useClientAddressUpdaterForOffice;
