import React from 'react';

import { Button } from 'shared/components/Button/Button';
import ButtonGroup from 'shared/components/ButtonGroup/ButtonGroup';
import Icon from 'shared/components/Icon/Icon';
import useSalesSpeedHelperPhasesDetail from '../../../core/customHooks/useSalesSpeedHelperPhasesDetail';
import {
  SalesSpeedHelperPayload,
  PhasesDetail,
  PhaseDetailDates,
  PhaseDetailDatesRange,
} from '../../../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperStates from '../../../core/enums/SalesSpeedHelperStates';
import SalesSpeedHelperTables from '../../../core/enums/SalesSpeedHelperTables';
import { OnMaximumRotationMonthsChange, TabClickHandler } from '../../../core/types/SalesSpeedHelper.types';
import MaximumRotationInput from '../MaximumRotationInput/MaximumRotationInput';
import SalesSpeedHelperPhasesDetail from '../SalesSpeedHelperPhasesDetail/SalesSpeedHelperPhasesDetail';

import styles from './SalesSpeedHelperToolbar.module.scss';

const disabledStates = [SalesSpeedHelperStates.Loading, SalesSpeedHelperStates.Error, SalesSpeedHelperStates.NoData];

const isDisabledToolbar = (viewState: SalesSpeedHelperStates) => disabledStates.includes(viewState);

const defaultDatesRange: PhaseDetailDatesRange = {
  fromTime: 0,
  toTime: 0,
  months: 0,
};

const defaultPhaseDetailDates: PhaseDetailDates = {
  sold: defaultDatesRange,
  toSell: defaultDatesRange,
  total: defaultDatesRange,
};

const defaultPhasesDetail: PhasesDetail = {
  currentTime: 0,
  preSales: defaultPhaseDetailDates,
  sales: defaultPhaseDetailDates,
  total: defaultPhaseDetailDates,
};

const getPhasesDetail = (phasesDetail = defaultPhasesDetail) => phasesDetail;

const CalendarTodayIcon = <Icon variant="CalendarToday" width="1.25rem" height="1.25rem"></Icon>;

const tabIndexes = {
  [SalesSpeedHelperTables.UnitSales]: 0,
  [SalesSpeedHelperTables.UnitSalesPercent]: 1,
  [SalesSpeedHelperTables.SalesSpeed]: 2,
};

type Props = {
  viewState: SalesSpeedHelperStates;
  currentTab: SalesSpeedHelperTables;
  salesSpeedPayload?: SalesSpeedHelperPayload;
  onTabClick: TabClickHandler;
  onMaximumRotationMonthsChange: OnMaximumRotationMonthsChange;
};

const SalesSpeedHelperToolbar: React.FC<Props> = ({
  viewState,
  currentTab,
  salesSpeedPayload,
  onTabClick,
  onMaximumRotationMonthsChange,
}) => {
  const { anchorEl, handleDetailClick, handleDetailClose } = useSalesSpeedHelperPhasesDetail();
  const isDisabled = isDisabledToolbar(viewState);

  return (
    <div className={styles.root}>
      <ButtonGroup className={styles.tabButtons} size="small" currentIndex={tabIndexes[currentTab]}>
        <Button data-index="0" data-name={SalesSpeedHelperTables.UnitSales} disabled={isDisabled} onClick={onTabClick}>
          Venta de unidades
        </Button>
        <Button
          data-index="1"
          data-name={SalesSpeedHelperTables.UnitSalesPercent}
          disabled={isDisabled}
          onClick={onTabClick}
        >
          % Venta de unidades
        </Button>
        <Button data-index="2" data-name={SalesSpeedHelperTables.SalesSpeed} disabled={isDisabled} onClick={onTabClick}>
          Velocidad de ventas
        </Button>
      </ButtonGroup>
      <div className={styles.bottomContent}>
        <MaximumRotationInput
          isDisabled={isDisabled}
          maximumRotation={salesSpeedPayload?.maximumRotation}
          onChange={onMaximumRotationMonthsChange}
        />

        <Button
          className={styles.detailButton}
          variant="tertiary"
          size="small"
          disabled={isDisabled}
          endIcon={CalendarTodayIcon}
          onClick={handleDetailClick}
        >
          Detalle de las fases
        </Button>
        <SalesSpeedHelperPhasesDetail
          anchorEl={anchorEl}
          phasesDetail={getPhasesDetail(salesSpeedPayload?.phasesDetail)}
          onClose={handleDetailClose}
        />
      </div>
    </div>
  );
};

export default SalesSpeedHelperToolbar;
