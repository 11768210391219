import clsx from 'clsx';
import React, { FC, MouseEventHandler } from 'react';

import Modal from '../../Modal/Modal';
import ModalActions from '../../ModalActions/ModalActions';
import ModalTitle from '../../ModalTitle/ModalTitle';
import Portal from '../../Portal/Portal';
import Typography from '../../Typography/Typography';
import useModalHandlers from '../Core/customHooks/useModalHandler';
import ModalProps, { AlertVariants } from '../Core/interfaces/ModalProps';

import Styles from './Alert.module.scss';

const isAcceptOnlyVariant = (variant: AlertVariants): boolean => variant === AlertVariants.AcceptOnly;

const getCancelButton = (cancelAction: MouseEventHandler<HTMLButtonElement>) => (
  <button className={clsx(Styles.secondary, Styles.button)} onClick={cancelAction}>
    Cancelar
  </button>
);

const primaryTexts = {
  [AlertVariants.Accept]: 'Aceptar',
  [AlertVariants.AcceptOnly]: 'Aceptar',
  [AlertVariants.Destructive]: 'Eliminar',
  [AlertVariants.Continue]: 'Continuar',
};

const acceptButtonTypes = {
  [AlertVariants.Accept]: Styles.primary,
  [AlertVariants.AcceptOnly]: Styles.secondary,
  [AlertVariants.Destructive]: Styles.destructive,
  [AlertVariants.Continue]: Styles.primary,
};

const Alert: FC<ModalProps> = (props) => {
  const { isOpen, acceptAction, cancelAction } = useModalHandlers(props);
  const { variant = AlertVariants.Accept } = props;

  return (
    <Portal>
      <Modal disableEscapeKeyDown open={isOpen}>
        <ModalTitle className={Styles.title}>{props.title}</ModalTitle>
        <Typography className={Styles.content} variant="body1" as="div">
          {props.children}
        </Typography>
        <ModalActions>
          {!isAcceptOnlyVariant(variant) && getCancelButton(cancelAction)}
          <button className={clsx(acceptButtonTypes[variant], Styles.button)} onClick={acceptAction}>
            {primaryTexts[variant]}
          </button>
        </ModalActions>
      </Modal>
    </Portal>
  );
};

export default Alert;
