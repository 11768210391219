import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import useRealPaymentsExcel from '../Core/customHooks/useRealPaymentsExcel';
import DownloadButton from '../../../components/UI/DownloadButton/DownloadButton';
import UploadButton from '../../../components/Client2/List/Row/QuotationViewer/Dialog/ImportFromExcel/UploadButton';
import RealPaymentsForPropertyTable from './tables/RealPaymentsForPropertyTable';
import agent from '../../../config/config';
import { Role } from '../../../helpers';
import useRealPaymentsForPropertyRequest from '../../RealPaymentsForProperty/Core/customHooks/useRealPaymentsForPropertyRequest';
import RealPaymentAutocompleteField from '../../RealPayment/UI/customViews/RealPaymentAutocompleteField';
import useCurrentPropertyForRealPayments from '../Core/customHooks/useCurrentPropertyForRealPayments';
import Styles from './RealMonthlyPaymentsMainView.module.scss';

const RealMonthlyPaymentsMainView: FC = ({}) => {
  const {
    propertySelected,
    onUpdateAutoComplete,
  } = useCurrentPropertyForRealPayments();

  const { isLoading } = useRealPaymentsForPropertyRequest(
    propertySelected.propertyId,
  );

  const {
    file,
    isUploadingFile,
    onDownload,
    uploadTemplate,
    onChangeFileHandler,
    onCancel,
  } = useRealPaymentsExcel(
    propertySelected.propertyId,
    propertySelected.clientId,
  );

  return (
    <>
      <Box display="flex" p={3}>
        <Box className={Styles.autocompleteContainer}>
          <RealPaymentAutocompleteField
            updateAutoComplete={onUpdateAutoComplete}
          />
        </Box>
        <Box className={Styles.excelButtonsContainer}>
          <Box pr="16px">
            <DownloadButton onClick={onDownload}>
              Descargar Excel
            </DownloadButton>
          </Box>
          {!agent.isAuthorized([Role.Sales]) && (
            <UploadButton
              buttonTitle="Cargar pagos"
              fileName={typeof file === 'string' ? null : file.name}
              onChangeHandler={onChangeFileHandler}
              onCancel={onCancel}
              onAccept={uploadTemplate}
              isUploadingFile={isUploadingFile}
              key={typeof file === 'string' ? '' : file.name}
            />
          )}
        </Box>
      </Box>
      <RealPaymentsForPropertyTable />
      {isLoading && (
        <AbsoluteCircularProgress topPercentage={0} leftPercentage={0} />
      )}
    </>
  );
};

export default RealMonthlyPaymentsMainView;
