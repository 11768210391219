import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import useBudgetTableHeader from 'App/ResultsState/Core/customHooks/useBudgetTableHeader';
import { Button } from '../../../../../shared/components/Button/Button';
import Icon from '../../../../../shared/components/Icon/Icon';
import Style from './BudgetTableHeader.module.scss';
import IconButton from '../../../../../shared/components/IconButton/IconButton';
import { cellEditorState } from '../../../../TotalCashFlow/Core/actions';

const BudgetTableHeader = () => {
  const dispatch = useDispatch();
  const { editCellsState } = useSelector((state) => state.cashFlow.root || {});

  const { cancelSaveHandleClick, openAlert, handleClose, handleUpdateBudget } = useBudgetTableHeader();

  return (
    <Box className={Style.eventsControlHeaderContainer}>
      {openAlert && (
        <Box className={Style.eventAlert}>
          <Icon
            className={Style.exclamationPointIcon}
            variant={'exclamationPoint'}
            height={'20px'}
            width={'20px'}
            color={'#2D52D6'}
          />
          <Typography className={Style.eventAlertTypography}>
            Puedes guardar 3 cortes diferentes, copiar el corte actual para ver la variación
          </Typography>
          <IconButton
            className={Style.closeIcon}
            iconName="Clear"
            variant="ghost"
            size="small"
            aria-controls="close-lot-form"
            onClick={() => handleClose()}
          />
        </Box>
      )}

      {editCellsState && (
        <Box className={Style.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(cellEditorState(false));
              handleUpdateBudget()
            }}
          >
            Guardar cambios
          </Button>
          <Button
            className={Style.cancelButton}
            variant="tertiary"
            onClick={() => {
              cancelSaveHandleClick();
            }}
          >
            Cancelar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BudgetTableHeader;
