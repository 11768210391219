const BathRoomSchemas = [
  {
    label: 'N',
    value: 'N',
  },
  {
    label: 'S',
    value: 'S',
  },
  {
    label: 'E',
    value: 'E',
  },
  {
    label: 'O',
    value: 'W',
  },
];

export default BathRoomSchemas;
