import { API_PATH } from '../../config/config';

const UserServiceDefinitions = Object.freeze({
  login: `${API_PATH}user/login`,
  signup: `${API_PATH}user`,
  user: `${API_PATH}user`,
  logout: `${API_PATH}user/logout`,
  childrenInfo: `${API_PATH}user/allChildrenInfo`,
  all: `${API_PATH}user/all`,
  agents: `${API_PATH}user/agents`,
  updatePassword: `${API_PATH}user/updatePassword`,
  updatePasswordFromAdmin: `${API_PATH}user/updatePasswordFromAdmin`,
  renewToken: `${API_PATH}user/renewToken`,
  revokeToken: `${API_PATH}user/revokeToken`,
});

export default UserServiceDefinitions;
