import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Loader from '../../components/UI2/Loader/Loader';
import Services from '../../services/group/groupService';
import GroupsErrorMessage from '../../components/Groups/ErrorMessage';
import Tabs from '../../components/Groups/Tabs';
import { startApiFetch, successApiFetch, failApiFetch } from './actions';
import {
  setGroups,
  setItems,
  setCompanies,
  setCompanyId
} from '../../components/Groups/Tabs/action';
import Context from '../../Context';
import { useHistory } from 'react-router';
import { DashboardRoutes, ProjectRoutes } from '../../routes/local/routes';

const services = new Services();

const Groups = ({
  apiLoading,
  onStartApiFetch,
  onApiFetchSuccess,
  onSetCompanies,
  onSetCompanyId,
  onApiFetchFail,
  apiError,
  onSetGroups,
  onSetItems,
  groups,
}) => {
  const { loadSessionCompany, sessionCompany } = useContext(Context.Shared)
  const history = useHistory()

  useEffect(() => {
    const companyId = sessionCompany || loadSessionCompany()
    if (!companyId) return history.push(DashboardRoutes.base + ProjectRoutes.base)
    onSetCompanyId(companyId)
    fetchCompany(companyId)
  }, [])

  const { enqueueSnackbar } = useSnackbar();
  
  const fetchCompany = async (companyId) => {
    onStartApiFetch();
    try {
      const responseGroups = await services.getAllGroup(companyId);
      const responseItems = await services.getAllItems();
      onSetGroups(
        _.orderBy(
          responseGroups.data,
          [(group) => group.categoryName.toLowerCase()],
          ['asc'],
        ),
      );
      onSetItems(responseItems.data);
      onApiFetchSuccess();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  async function fetch() {
    try {
      const response = await services.getCompanies();
      const companies = response.data.filter((company) => company !== null);
      onSetCompanies(
        _.orderBy(
          companies,
          [(company) => company.name.toLowerCase()],
          ['asc'],
        ),
      );
      onApiFetchSuccess();
    } catch (error) {
      onApiFetchFail();
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }
  useEffect(() => {
    fetch();
  }, [groups]);
  
  return (
    <Loader isLoading={apiLoading}>
      {apiError ? <GroupsErrorMessage /> : <Tabs />}
    </Loader>
  );
};
Groups.propTypes = {
  apiLoading: PropTypes.bool.isRequired,
  apiError: PropTypes.bool.isRequired,
  onStartApiFetch: PropTypes.func.isRequired,
  onApiFetchSuccess: PropTypes.func.isRequired,
  onApiFetchFail: PropTypes.func.isRequired,
  onSetGroups: PropTypes.func.isRequired,
  onSetItems: PropTypes.func.isRequired,
  onSetCompanies: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  apiLoading: state.groups.root.loading,
  apiError: state.groups.root.error,
  groups: state.groups.groupTabs.groups,
});
const mapDispatchToProps = {
  onStartApiFetch: startApiFetch,
  onApiFetchSuccess: successApiFetch,
  onApiFetchFail: failApiFetch,
  onSetGroups: setGroups,
  onSetItems: setItems,
  onSetCompanies: setCompanies,
  onSetCompanyId: setCompanyId
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Groups);
