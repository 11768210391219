import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import GeneralWalletServices from '../../../GeneralWallet/services/GeneralWalletServices';

function useUpdatesForRealPayment() {
  const initialState = { date: new Date(), price: 0, comment: '' };
  const [states, setStates] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();

  const updatePrice = (values) => {
    setStates({ ...states, price: values.floatValue });
  };

  const updateNotes = (event) => {
    setStates({ ...states, comment: event.target.value });
  };

  const updateDate = (date) => {
    setStates({ ...states, date });
  };

  const updateAutoComplete = (event, newValue) => {
    setStates({ ...states, ...newValue });
  };

  const onSave = async () => {
    const services = new GeneralWalletServices();
    try {
      await services.postRealPayment({
        towerId,
        ...states,
        date: states.date.valueOf(),
        type: 'CASH',
        number: 1,
      });
      setStates({ ...states, ...initialState });
      enqueueSnackbar('Hecho! Puedes agregar otro pago', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    updateAutoComplete,
    updateDate,
    updatePrice,
    updateNotes,
    states,
    onSave,
  };
}

export default useUpdatesForRealPayment;
