import { Table } from '@devexpress/dx-react-grid';
import React from 'react';
import styled from 'styled-components';

import TableCell from '../../../../../shared/tables/TableCell';

const TableCellTag: React.FC<Table.DataCellProps> = styled(TableCell)<Table.DataCellProps>`
  /* Box-model */
  padding: 0;
`;

export default TableCellTag;
