import React, { FC } from 'react';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';

const TableCellPlainTypography: FC<TableCellTypographyProps> = ({
  children,
  value,
}) => {
  return <span>{children || value}</span>;
};

export default TableCellPlainTypography;
