import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Box, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import Button from '../../../../UI2/Button';
import Styles from './Select.module.scss';
import useFilterOptionsForAdditionalArea from '../Core/customHooks/useFilterOptionsForAdditionalArea';

const Select = ({
  property,
  additionalAreas,
  addAdditionalAreaHandler,
  setAdditionalPrices,
  additionalAreaStateHandler,
}) => {
  const [selectedArea, setSelectedArea] = useState('');
  const [addingArea, setAddingArea] = useState(false);

  const updateAutoComplete = (event, newValue) => {
    setSelectedArea(newValue.id);
  };

  const { filterOptions } = useFilterOptionsForAdditionalArea();

  return (
    <Box className={Styles.container}>
      {addingArea ? (
        <Box>
          <Autocomplete
            disableClearable
            id="autocomplete-box-aditional-areas"
            options={additionalAreas}
            getOptionLabel={(option) =>
              `${option.areaType.name}-${option.nomenclature ||
                '(sin nomenclatura)'}`
            }
            sx={{ width: 300 }}
            onChange={updateAutoComplete}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: '100%' }}
                label="Areas adicionales"
                variant="outlined"
              />
            )}
          />
          <Button
            onClick={async () => {
              setSelectedArea('');
              setAddingArea(false);
              await addAdditionalAreaHandler(selectedArea);
              setAdditionalPrices();
              additionalAreaStateHandler(false);
            }}
          >
            <i className="fas fa-check"></i>
          </Button>
          <Button
            onClick={() => {
              setSelectedArea('');
              setAddingArea(false);
              additionalAreaStateHandler(false);
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Box>
      ) : (
        <Button
          onClick={() => {
            setAddingArea(true);
            additionalAreaStateHandler(true);
          }}
        >
          Agregar un area adicional
        </Button>
      )}
    </Box>
  );
};

Select.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    additionalAreas: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        measure: PropTypes.number,
        price: PropTypes.number,
        nomenclature: PropTypes.string,
        areaType: PropTypes.shape({
          name: PropTypes.string,
          unit: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  additionalAreas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nomenclature: PropTypes.string,
      areaType: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  addAdditionalAreaHandler: PropTypes.func.isRequired,
  setAdditionalPrices: PropTypes.func.isRequired,
};

export default Select;
