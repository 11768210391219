import { useEffect, useState } from 'react';
import { Table, Column } from '@devexpress/dx-react-grid';

import { Area, Property } from '../../../../../../models/Properties';

import { rightAlign } from '../../../../../../shared/tables/TableColumnFormat';

interface UseColumnsAndRowsForAreasParams {
  property: Property;
}

type HookType = (param: UseColumnsAndRowsForAreasParams) => {
  columns: Column[];
  data: Area[];
  titleColumns: string[];
  tableColumnExtensions: Table.ColumnExtension[];
};

const getColumns = (): Column[] => {
  return [
    {
      title: '',
      name: 'name',
    },
    {
      title: '',
      name: 'measure',
      getCellValue: (row) => {
        const measure = row.measure ? row.measure.toFixed(2) : 0;
        return `${measure} ${row?.unit || ''}`;
      },
    },
  ];
};

const getArrayData = (property: Property): Area[] => {
  const { areas, totalArea, privateArea } = property;
  return areas.concat([
    {
      name: 'Área total',
      price: 0,
      measure: totalArea,
      unit: 'm²',
    },
    {
      name: 'Área privada ',
      price: 0,
      measure: privateArea,
      unit: 'm²',
    },
  ]);
};

const useColumnsAndRowsForAreas: HookType = ({ property }) => {
  const tableColumnFormat: Table.ColumnExtension[] = [{ columnName: 'measure', align: rightAlign }];

  const [columns] = useState(getColumns());
  const [titleColumns] = useState(['name']);
  const [tableColumnExtensions] = useState(tableColumnFormat);

  const [data, setData] = useState<Area[]>(getArrayData(property));

  useEffect(() => {
    setData(getArrayData(property));
  }, [property]);

  return { columns, data, titleColumns, tableColumnExtensions };
};

export default useColumnsAndRowsForAreas;
