import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ScheduleServices from 'services/schedule/ScheduleServices';
import { QuotationConfigDto } from '../DTO/QuotationConfigDto';
import { QUOTATION_CONFIG_SCHEDULE } from '../QuotationConfigConstants';

const service = new ScheduleServices();

const useQuotationConfigMain = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const { data: quotationConfig } = useQuery<QuotationConfigDto>(
    QUOTATION_CONFIG_SCHEDULE,
    () => service.getDates(towerId).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );
  return {
    towerId,
    quotationConfig,
  };
};

export default useQuotationConfigMain;
