import { useQuery } from 'react-query';
import ScheduleServices from 'services/schedule/ScheduleServices';
import { ScheduleServicesGetDatesResponse } from '../interfaces/ScheduleServicesGetDatesResponse';
import { FIND_TOWER_SCHEDULE } from '../reactQuery/RequestConstants';

const scheduleService = new ScheduleServices();

const useScheduleServicesGetDates = (towerId: string) => {
  const { data } = useQuery(
    FIND_TOWER_SCHEDULE,
    () => scheduleService.getDates(towerId).then((response) => response.data as ScheduleServicesGetDatesResponse),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    scheduleDatesResponse: data,
  };
};

export default useScheduleServicesGetDates;
