import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { AreasTableCustomColumn } from '../../../Core/AreasTableCustomColumn';
import {
  AreasColumnExtentions,
  DefaulColumnsForEdit,
  DefualtRightColumns,
  NewAreaColumnName,
} from '../../../Core/constants/AreasConstants';
import { AreasDB } from '../../../Core/indexedDB/AreaIndexedDB';
import { AreaEditionTableProps } from './AreaEditionTableProps';
import { IAreaCustomColumn } from '../../../Core/interfaces/IAreaCustomColumn';
import { AreaTypeStates } from '../../../Core/customHooks/useAreasSaveChanges';
import IPropertyArea from '../../../Core/interfaces/IPropertyArea';
import { ColumnTotals } from '../../../Core/types/ColumnTotals';
import { AreaTypeParsedFunc } from '../../../Core/types/AreaTypeParsedFunc';
import { useRecoilState } from 'recoil';
import { AreaEditionAreaTypes } from '../../../Core/recoil/AreaEditionAreaTypes';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';

const areasTableCustomColumn = new AreasTableCustomColumn();

function constructColumns(areaTypes: IAreaCustomColumn[]) {
  return areasTableCustomColumn.getDefaultLeftColumns(areaTypes, [
    { name: NewAreaColumnName, title: '' },
    ...DefualtRightColumns,
  ]);
}

function constructEnabledColumnsForEdit(areaTypes: IAreaCustomColumn[]) {
  return [...areasTableCustomColumn.getCustomColumnsIds(areaTypes), ...DefaulColumnsForEdit];
}

const useAreaEditionTable = (props: AreaEditionTableProps) => {
  const [areaTypes, setAreaTypes] = useRecoilState(AreaEditionAreaTypes);
  const [properties, setProperties] = useState(props.properties);
  const [totals, setTotals] = useState(props.customColumnTotals);
  const [columns, setColumns] = useState<Column[]>(constructColumns(props.areaTypes));
  const [enabledColumnsForEdit, setEnabledColumnsForEdit] = useState<string[]>(
    constructEnabledColumnsForEdit(props.areaTypes),
  );
  const [columnsExtentions, setColumnExtentions] = useState<VirtualTable.ColumnExtension[]>(
    areasTableCustomColumn.getColumnExtentions(areaTypes, AreasColumnExtentions),
  );
  const [loading, setLoad] = useState<boolean>(false);

  const initializeDB = useCallback(async (propertiesParam: IPropertyArea[], totals: ColumnTotals) => {
    setLoad(true);
    await AreasDB.initialize(propertiesParam, totals);
    setLoad(false);
  }, []);

  const start = useCallback(async () => {
    setAreaTypes(props.areaTypes);
    await initializeDB(props.properties, props.customColumnTotals);
  }, []);

  useEffect(() => {
    start();
    return () => {
      AreasDB.cleanDB();
    };
  }, []);

  useEffect(() => {
    setColumnExtentions(areasTableCustomColumn.getColumnExtentions(areaTypes, AreasColumnExtentions));
    return () => {};
  }, [areaTypes]);

  const changeAreaTypes = (newAreaTypes: IAreaCustomColumn[]) => {
    setColumns(constructColumns(newAreaTypes));
    setEnabledColumnsForEdit(constructEnabledColumnsForEdit(newAreaTypes));
    setAreaTypes(newAreaTypes);
  };

  const createAreaType = async (name: string) => {
    const dbProperties = await AreasDB.properties.toArray();
    const dbTotals = await AreasDB.totals.toArray();
    if (dbProperties.length && dbTotals.length) {
      const dbTotal = dbTotals[0];
      const areaType: IAreaCustomColumn = {
        id: -Date.now(),
        name,
        unit: 'MT2',
        state: AreaTypeStates.NEW,
      };

      const newProperties = dbProperties.map((dbProperty) => ({
        ...dbProperty,
        [areaType.id]: 0,
      }));
      const newTotals = { ...dbTotal, [areaType.id]: 0 };
      const newAreaTypes = areaTypes.concat(areaType);

      await initializeDB(newProperties, newTotals);

      setProperties(newProperties);
      setTotals(newTotals);
      changeAreaTypes(newAreaTypes);
    }
  };

  const updateAreaType = async (areaType: IAreaCustomColumn) => {
    const areaTypesUpdated = areaTypes.map((stateAreaType) => {
      if (stateAreaType.id === areaType.id)
        return {
          ...stateAreaType,
          ...areaType,
          state: stateAreaType.state === AreaTypeStates.NEW ? AreaTypeStates.NEW : AreaTypeStates.CHANGED,
        };

      return stateAreaType;
    });
    changeAreaTypes(areaTypesUpdated);
  };

  const orchestateAreaType: AreaTypeParsedFunc = async (areaType) => {
    if (areaType?.id) await updateAreaType(areaType);
    else if (areaType?.name) await createAreaType(areaType.name);
  };

  const removeAreaType = async (areaType?: IAreaCustomColumn) => {
    if (areaType?.id) {
      const dbProperties = await AreasDB.properties.toArray();
      const dbTotals = await AreasDB.totals.toArray();
      if (dbProperties.length && dbTotals.length) {
        const id = +areaType.id;
        const dbTotal = dbTotals[0];

        const newProperties = dbProperties.map((dbProperty) => {
          const { [id]: _, ...extra } = dbProperty;
          return extra;
        });
        const { [id]: _, ...newTotals } = dbTotal;

        await initializeDB(newProperties, newTotals);

        if (areaTypes.length) {
          const newAreaTypes = areaTypes.reduce((prev, stateAreaType) => {
            if (stateAreaType.id == id) {
              if (stateAreaType.state === AreaTypeStates.NEW) return prev;
              else return prev.concat({ ...stateAreaType, state: AreaTypeStates.REMOVED });
            }
            return prev.concat(stateAreaType);
          }, [] as IAreaCustomColumn[]);

          changeAreaTypes(newAreaTypes);
        }

        setProperties(newProperties);
        setTotals(newTotals);
      }
    }
  };

  return {
    properties,
    columns,
    enabledColumnsForEdit,
    loading,
    areaTypes,
    totals,
    columnsExtentions,
    removeAreaType,
    orchestateAreaType,
  };
};
export default useAreaEditionTable;
