import React from 'react';

import Typography from 'shared/components/Typography/Typography';

import styles from './StagesOnboardingHeader.module.scss';

const descriptions = {
  1: 'Cada proyecto está organizado en etapas, crea tu primera etapa para añadir torres nuevas.',
  2: 'A cada etapa se le pueden asignar múltiples torres, añade la primera torre en Etapa 1.',
};

type Props = {
  step: number;
};

const StagesOnboardingHeader: React.FC<Props> = ({ step }) => (
  <div className={styles.root}>
    <Typography className={styles.title} as="h2" variant="subtitle1" color="primary600">
      Paso {step} de 2
    </Typography>
    <Typography className={styles.description} as="p" variant="body1">
      {descriptions[step]}
    </Typography>
  </div>
);

export default StagesOnboardingHeader;
