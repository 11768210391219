import { DeedExpensesService } from 'App/DeedExpenses/services/DeedExpensesService';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { DeedExpensesPropertiesSoldResponse } from '../interfaces/DeedExpensesPropertiesSoldResponse';
import { PROPERTY_SOLD } from '../reactQuery/RequestConstants';

const service = new DeedExpensesService();

const useDeedExpensesSalesMadeData = (towerId: string) => {
  const { data, isLoading, isFetching } = useQuery<DeedExpensesPropertiesSoldResponse>(
    PROPERTY_SOLD,
    () => service.getAllPropertiesSold(towerId).then((response) => response.data),
    {
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading: isLoading || isFetching };
};

export default useDeedExpensesSalesMadeData;
