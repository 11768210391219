import { Role } from '../../../../helpers';
import agent from '../../../../config/config';

const useCanAssignImages = () => {
  const canAssignImages = agent.isAuthorized([
    Role.Super,
    Role.Admin,
    Role.SalesDirector
  ]);

  return canAssignImages;
}

export default useCanAssignImages;