import { useState } from 'react';
import Numbers from '../../../../helpers/numbers';

const useBankFinancialEditionsDialog = (quotation, updateQuotation) => {
  const [editAnnualEffectiveRate, setAnnualEffectiveRateEdition] = useState(
    false,
  );
  const [editTotalYears, setTotalYearsEdition] = useState(false);

  const updateTermLimit = (newTerm) => {
    updateQuotation({ ...quotation, termLimit: newTerm });
    setTotalYearsEdition(false);
  };

  const updateAnnualRate = (newAnnualRate) => {
    updateQuotation({
      ...quotation,
      annualRate: Numbers.cleanNumber(newAnnualRate / 100),
    });
    setAnnualEffectiveRateEdition(false);
  };

  return {
    editAnnualEffectiveRate,
    editTotalYears,
    setAnnualEffectiveRateEdition,
    setTotalYearsEdition,
    updateTermLimit,
    updateAnnualRate,
  };
};

export default useBankFinancialEditionsDialog;
