import Services from '../../../services/services';
import SalesReportOneServicesDefinition from './SalesReportOneServicesDefinition';

class SalesReportOneServices extends Services {
  getReport(towerId) {
    return this.get(SalesReportOneServicesDefinition.getReport(towerId));
  }

  getWeeklyReport(towerId) {
    return this.get(SalesReportOneServicesDefinition.getWeeklyReport(towerId));
  }

  setGroupGoal(DATA) {
    return this.put(SalesReportOneServicesDefinition.setGroupGoal(), DATA);
  }
}

export default SalesReportOneServices;
