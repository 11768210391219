import Numbers from '../../../../../helpers/numbers';

class QuotationFunctions {
  static monthlyFeesPercentage(
    initialFeePercentage,
    propertyPrice,
    reservePercentage,
  ) {
    return (
      ((initialFeePercentage * propertyPrice - reservePercentage) /
        propertyPrice) *
      100
    );
  }

  static monthlyFeesPrice(
    initialFeePercentage,
    propertyPrice,
    reservePercentage,
  ) {
    return initialFeePercentage * propertyPrice - reservePercentage;
  }

  static initialFeePrice(finalPayment, propertyPrice) {
    return propertyPrice - finalPayment;
  }

  static initialFeePercentage(finalPayment, propertyPrice) {
    return (propertyPrice - finalPayment) / propertyPrice;
  }

  static monthlyFeesPriceWithPrices(finalPayment, propertyPrice, reservePrice) {
    return propertyPrice - finalPayment - reservePrice;
  }

  static monthlyFeePercentageWithPrices(
    finalPayment,
    propertyPrice,
    reservePrice,
  ) {
    return (
      ((propertyPrice - finalPayment - reservePrice) * 100) / propertyPrice
    );
  }

  static finalFeePercentage(finalFee, propertyPrice) {
    return (finalFee * 100) / propertyPrice;
  }

  static reserveFeePercentage(reservePrice, propertyPrice) {
    return (reservePrice * 100) / propertyPrice;
  }

  static calculateInitialPrice(distribution) {
    return distribution.map(Number).reduce((acc, value) => acc + value, 0);
  }

  static initialPricePercentage(distribution, propertyPrice) {
    return (
      distribution.map(Number).reduce((acc, value) => acc + value, 0) /
      propertyPrice
    );
  }

  static calculatePropertyPrice(distribution, reservationDeposit, finalFee) {
    return (
      distribution.map(Number).reduce((acc, value) => acc + value, 0) +
      reservationDeposit +
      finalFee
    );
  }

  static missingPrice(
    distribution,
    reservationDeposit,
    finalFee,
    propertyPrice,
  ) {
    const missing = Numbers.cleanNumber(
      Math.ceil(
        propertyPrice -
          this.calculatePropertyPrice(
            distribution,
            reservationDeposit,
            finalFee,
          ),
      ),
    );

    return Math.abs(missing) > 3 ? missing : 0;
  }
}

export default QuotationFunctions;
