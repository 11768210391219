import moment from 'moment';
import {
  FETCH_QUOTATION__START,
  FETCH_QUOTATION__SUCCESS,
  FETCH_QUOTATION__FAILURE,
  CHANGE_FINAL_FEE_PERCENTAGE,
  CHANGE_RESERVE_PERCENTAGE,
  CHANGE_DISTRIBUTION,
  CHANGE_INDEX_DISTRIBUTION,
  CHANGE_EDITION_TYPE,
  CHANGE_INITIAL_DATE,
  CHANGE_DISCOUNT_TYPE,
} from './actions';

import {
  getFinalPayment,
  calculateInitialFeePercentageWithDistribution,
} from '../../../../../../helpers/quotations';
import QuotationDiscountType from '../../../../../../App/Quotation/Core/enums/QuotationDiscountType';

export const initialState = {
  id: '',
  propertyPrice: 0,
  initialFeePercentage: 0,
  reservePercentage: 0,
  periods: 0,
  paymentStartDate: 0,
  temp: 0,
  property: {
    name: '',
    tower: {
      schedule: {
        averageDeliveryDate: new Date().getTime(),
      },
      project: { name: '' },
    },
  },
  client: { name: '', identityDocument: '', email: '' },
  distribution: [],
  loading: false,
  error: false,
  currentIndexForDiscount: null,
  discountPrice: null,
  discountPercentage: null,
  priceWithDiscount: null,
  targetPercentages: null,
  targetPrice: null,
  finalPayment: null,
  discountType: QuotationDiscountType.ASSIGNED.code,
  propertyWithAreas: {},
  temporalPriceWithAdditionalAreas: null,
  temporalAdditionalAreas: null,
  temporalAdditionalAreasPrice: null,
  temporalBasePrice: null,
  temporalDiscount: null,
  isLockedType: true,
  initialDate: null,
  finalPaymentType: null,
  indexSelected: [],
};

const editQuotationFinalFeePrice = (quotation, value) => {
  const { value: finalFeePrice } = value;
  const tempQuotation = { ...quotation };
  tempQuotation.finalFee = finalFeePrice;
  tempQuotation.finalPayment = finalFeePrice;

  return tempQuotation;
};

const editQuotationReservePercentage = (quotation, reserve) => {
  const tempQuotation = { ...quotation };
  const { name, value } = reserve;

  if (name === 'paymentStartDate') {
    if (!value || isNaN(value.getTime())) {
      return tempQuotation
    }
    const periods = moment(
      Number(quotation.property.tower.schedule.maximumCollectionDate),
    )
      .startOf('month')
      .diff(moment(value).startOf('month'), 'months');
    const distributionLength = quotation.distribution.length;
    const diff = distributionLength - periods;
    if (diff > 0) {
      tempQuotation.distribution.splice(distributionLength - diff, diff);
    } else {
      tempQuotation.distribution.splice(
        distributionLength,
        0,
        ...Array(-diff).fill(0),
      );
    }

    return {
      ...tempQuotation,
      paymentStartDate: value.getTime(),
      periods,
    };
  }
  const initialFeePercentage = calculateInitialFeePercentageWithDistribution(
    tempQuotation.distribution,
    Number(value),
    quotation.priceWithAdditionalAreas -
      quotation.discount -
      quotation.discountPrice,
  );

  return {
    initialFeePercentage,
    reservePercentage: Number(value),
  };
};

const updateInitialDate = (quotation, newInitialDate) => {
  return {
    ...quotation,
    initialDate: newInitialDate.getTime(),
  };
};

const updateQuotation = (previous, quotation) => {
  const finalFee = Math.round(
    getFinalPayment(quotation.propertyPrice, quotation.initialFeePercentage),
  );

  const initialFeePercentage = calculateInitialFeePercentageWithDistribution(
    quotation.distribution,
    quotation.reservePercentage,
    quotation.priceWithAdditionalAreas -
      quotation.discount -
      quotation.discountPrice,
  );

  const { propertyWithAreas } = quotation;
  const priceWithAdditionalAreas =
    previous.temporalPriceWithAdditionalAreas ||
    quotation.priceWithAdditionalAreas;
  propertyWithAreas.additionalAreas =
    previous.temporalAdditionalAreas || propertyWithAreas.additionalAreas;
  propertyWithAreas.discount =
    previous.temporalDiscount || propertyWithAreas.discount;
  propertyWithAreas.additionalAreasPrice =
    previous.temporalAdditionalAreasPrice ||
    propertyWithAreas.additionalAreasPrice;
  propertyWithAreas.basePriceValidated =
    previous.temporalBasePrice || propertyWithAreas.basePriceValidated;

  return {
    ...quotation,
    finalFee,
    initialFeePercentage,
    priceWithAdditionalAreas,
    propertyWithAreas,
    loading: false,
    error: false,
  };
};

const updateDistribution = (quotation, distributionToUpdate) => {
  const { index, value } = distributionToUpdate;
  const distribution = [...quotation.distribution];
  distribution[index] = value;
  const initialFeePercentage = calculateInitialFeePercentageWithDistribution(
    distribution,
    quotation.reservePercentage,
    quotation.priceWithAdditionalAreas -
      quotation.discount -
      quotation.discountPrice,
  );

  let { indexSelected } = quotation;
  if (quotation.isLockedType) {
    indexSelected = [...quotation.indexSelected, index + 1];
  }

  return {
    distribution,
    initialFeePercentage,
    indexSelected,
  };
};

const updateCurrentIndex = (state, payload) => {
  let { indexSelected } = state;
  if (state.isLockedType) {
    indexSelected = [...indexSelected, payload];
  } else {
    indexSelected = [payload];
  }
  return { ...state, currentIndexForDiscount: payload, indexSelected };
};

const resetQuotationByProposal = (state) => {
  const finalPayment = getFinalPayment(
    state.proposalPropertyPrice - state.proposalDiscount,
    state.proposalInitialFeePercentage,
  );

  return {
    periods: state.proposalPeriods,
    distribution: state.proposalDistribution,
    initialFeePercentage: state.proposalInitialFeePercentage,
    reservePercentage: state.proposalReservePercentage,
    finalPayment,
  };
};

const updateEditionType = (state, isLockedType) => {
  const currentIndexForDiscount = isLockedType ? null : state.periods + 1;
  return {
    ...state,
    isLockedType,
    indexSelected: [],
    currentIndexForDiscount,
    discountPrice: 0,
    discountType: QuotationDiscountType.ASSIGNED.code,
    ...resetQuotationByProposal(state),
  };
};

const updateDiscountType = (quotation, discountType) => {
  const discountPrice =
    discountType === QuotationDiscountType.MANUAL.code
      ? quotation.discountPrice
      : 0;

  return {
    ...quotation,
    discountType,
    discountPrice,
    ...resetQuotationByProposal(quotation),
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_QUOTATION__START:
      return { ...state, loading: true, error: false };
    case FETCH_QUOTATION__SUCCESS:
      return { ...state, ...updateQuotation(state, payload) };
    case FETCH_QUOTATION__FAILURE:
      return { ...state, loading: false, error: true };
    case CHANGE_INDEX_DISTRIBUTION:
      return updateCurrentIndex(state, payload);
    case CHANGE_DISTRIBUTION:
      return {
        ...state,
        ...updateDistribution(state, payload),
      };
    case CHANGE_FINAL_FEE_PERCENTAGE:
      return {
        ...state,
        ...editQuotationFinalFeePrice(state, payload),
      };
    case CHANGE_RESERVE_PERCENTAGE:
      return {
        ...state,
        ...editQuotationReservePercentage(state, payload),
      };
    case CHANGE_EDITION_TYPE:
      return updateEditionType(state, payload);
    case CHANGE_INITIAL_DATE:
      return updateInitialDate(state, payload);
    case CHANGE_DISCOUNT_TYPE:
      return updateDiscountType(state, payload);
    default:
      return state;
  }
};

export default reducer;
