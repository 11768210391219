import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { ClientModel } from 'App/Clients/Core/models/ClientModel';
import GoToSalesRoom from '../../ClientButtons/GoToSalesRoom/GoToSalesRoom';
import styles from './ClientSuccessCreated.module.scss';

interface Props {
  client: ClientModel;
}

const ClientSuccessCreated: FC<Props> = (props) => {
  const { client } = props;
  const { towerId } = useParams<{ towerId: string }>();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={styles.success}>
        <CheckCircleOutline fontSize="large" />
      </Box>
      <Box marginTop={2}>
        <Typography>
          El cliente{' '}
          <b>
            {client.name} {client.surname}
          </b>{' '}
          con número de identificación <b>{client.identityDocument}</b>
        </Typography>
      </Box>
      <Box className={styles.success} marginTop={2}>
        <Typography>
          <b>Ha sido creado exitosamente</b>
        </Typography>
      </Box>
      <Box marginTop={2} marginBottom={4} display="flex" flexDirection="column" alignItems="center">
        <Box marginTop={1} width="100%">
          <GoToSalesRoom clientId={client.id || ''} towerId={towerId} />
        </Box>
      </Box>
    </Box>
  );
};
export default ClientSuccessCreated;
