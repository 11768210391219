import { Plugin, Template, TemplateConnector } from '@devexpress/dx-react-core';
import React from 'react';
import { Divider, Grid, Button } from '@material-ui/core';
import clsx from 'clsx';

import Typography from '../../../../shared/components/Typography/Typography';
import { GeneralWalletToolbarProps } from '../../core/interfaces/GeneralWalletToolbar.types';
import GeneralWalletToolbarStateFilter from './GeneralWalletToolbarStateFilter';
import GeneralWalletToolbarMonthsRecovery from './GeneralWalletToolbarMonthsRecovery';
import GeneralWalletToolbarTextFilter from './GeneralWalletToolbarTextFilter';
import styles from './GeneralWalletToolbar.module.scss';

const pluginDependencies = [{ name: 'Toolbar' }];

const GeneralWalletToolbar: React.FC<GeneralWalletToolbarProps> = ({
  onDownloadClick,
  onCustomizeClick,
  onTextFilterChange,
}) => (
  <Plugin name="GeneralWalletToolbar" dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <div className={styles.header}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography as="h1" variant="headline3">
              Gestión Cartera
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={3} direction="row" alignItems="flex-end">
              <Grid item>
                <GeneralWalletToolbarMonthsRecovery />
              </Grid>
              <Grid item>
                <TemplateConnector>{GeneralWalletToolbarStateFilter}</TemplateConnector>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={styles.divider} />
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <GeneralWalletToolbarTextFilter onTextFilterChange={onTextFilterChange} />
          </Grid>
          <Grid item>
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item>
                <Button className={styles.button} onClick={onDownloadClick}>
                  Descargar Excel
                </Button>
              </Grid>
              <Grid item>
                <Button className={clsx(styles.button, styles.buttonOutlined)} onClick={onCustomizeClick}>
                  Pesonalizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Template>
  </Plugin>
);

export default GeneralWalletToolbar;
