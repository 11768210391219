import Services from '../../../services/services';
import CRMServicesDefinitions from './CRMServicesDefinitions';

const CRMOptionTypes = {
  BUSINESS_CLOSING_REASON: {
    code: 'BUSINESS_CLOSING_REASON', // Business Closing reasong
    value: 'BUSINESS_CLOSING_REASON',
    description: 'Motivo cierre de negocio',
  },
  QUESTION_1: {
    code: 'QUESTION_1', // how did you hear about the project?
    value: 'QUESTION_1',
    description: '¿Como se entero del proyecto?',
  },
};

export default class CRMServices extends Services {
  getFields(companyId) {
    return this.get(CRMServicesDefinitions.commonPath(companyId));
  }

  createField(companyId, type, { value }) {
    return this.post(
      CRMServicesDefinitions.createField(companyId, type),
      { value },
    );
  }

  updateField({ id, value }) {
    return this.put(CRMServicesDefinitions.commonPath(id), {
      value,
    });
  }

  deleteField({ id }) {
    return this.delete(CRMServicesDefinitions.commonPath(id));
  }

  static CRMOptionTypes = CRMOptionTypes
}
