import ClientAddressType from "App/ClientAddressModal/Core/models/ClientAddressType";
import { ClientAddressModel } from "../models/ClientAddressModel";

const createEmptyClientAddress = (): ClientAddressModel => ({
    addressType: ClientAddressType.URBAN.code
})

const GetClientAddress = (clientAddress?: ClientAddressModel | string) => {
    if (!clientAddress) return createEmptyClientAddress();
    if (typeof clientAddress === "string") return createEmptyClientAddress()
    return clientAddress
}

export default GetClientAddress