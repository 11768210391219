import ScheduleServicesDefinition from './ScheduleServicesDefinition';
import Services from '../services';

export default class ScheduleServices extends Services {
  getDates(towerId) {
    return this.get(ScheduleServicesDefinition.getDates(towerId));
  }

  getLabeledDates(towerId) {
    return this.get(ScheduleServicesDefinition.getLabeledDates(towerId));
  }

  getDatesOfStage(towerId) {
    return this.get(ScheduleServicesDefinition.getDatesOfStage(towerId));
  }

  putStageDates(towerId, body) {
    return this.put(ScheduleServicesDefinition.putStageDates(towerId), body);
  }

  putSalesStartDate(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putSalesStartDate(towerId),
      body,
    );
  }

  putBalancePointDate(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putBalancePointDate(towerId),
      body,
    );
  }

  putConstructionStartDate(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putConstructionStartDate(towerId),
      body,
    );
  }

  putAverageDeliveryDate(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putAverageDeliveryDate(towerId),
      body,
    );
  }

  putAverageDeliveryDisplacement(towerId, averageDeliveryDisplacement) {
    return this.put(
      ScheduleServicesDefinition.putAverageDeliveryDisplacement(towerId),
      { averageDeliveryDisplacement },
    );
  }

  putEndOfSalesDate(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putEndOfSalesDate(towerId),
      body,
    );
  }

  putMaximumCollectionDate(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putMaximumCollectionDate(towerId),
      body,
    );
  }

  putFirstSaleHandler(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putFirstSaleHandler(towerId),
      body,
    );
  }

  putBalancePointType(towerId, body) {
    return this.put(
      ScheduleServicesDefinition.putBalancePointType(towerId),
      body,
    );
  }

  getSalesChecker(towerId) {
    return this.get(ScheduleServicesDefinition.getSalesChecker(towerId));
  }

  putStrategySetup(towerId, data) {
    return this.put(ScheduleServicesDefinition.putStrategySetup(towerId), data);
  }
}
