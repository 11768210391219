import { Getters } from '@devexpress/dx-react-core';

import { clientStates } from './enums/table.enum';

type FilterExpression = {
  filters: Array<any>;
  operator: string;
};

type Filter = {
  columnName: string;
  value: string;
};

const defaultFilterExpression = {
  filters: [],
  operator: 'and',
};

const getFilterExpressionComputed = (columnName: string) => {
  const getNewFilterExpresssion = (filterExpression: FilterExpression) => ({
    ...(filterExpression || defaultFilterExpression),
  });

  const getNewColumnFilters = (filters: Array<Filter>, toolbarFilterValue: string) => [
    ...filters,
    {
      columnName,
      value: clientStates[toolbarFilterValue].value,
    },
  ];

  const getColumnFilters = (filters: Array<Filter>, toolbarFilterValue: string) =>
    toolbarFilterValue ? getNewColumnFilters(filters, toolbarFilterValue) : filters;

  const getFinalFilterExpression = (filterExpression: FilterExpression, filters: Array<Filter>) => ({
    ...filterExpression,
    filters,
  });

  const filterExpressionComputed = ({ filterExpression, toolbarFilterValue }: Getters) => {
    const newFilterExpression = getNewFilterExpresssion(filterExpression);
    const filters = getColumnFilters(newFilterExpression.filters, toolbarFilterValue);

    return getFinalFilterExpression(newFilterExpression, filters);
  };

  return filterExpressionComputed;
};

export default getFilterExpressionComputed;
