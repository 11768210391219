import React from 'react';
import moment from 'moment';
import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Popover,
  ListItemText,
  ListItem,
  List,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import DoneRounded from '@material-ui/icons/DoneRounded';
import CloseRounded from '@material-ui/icons/CloseRounded';
import DeleteRounded from '@material-ui/icons/DeleteForeverRounded';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import agent from '../../../../config/config';
import { Role } from '../../../../helpers';
import Styles from './RealPaymentsForPropertyRow.module.scss';
import useUpdatePayment from '../../Core/customHooks/useUpdatePayment';
import usePopoverUpdaterForExcelInSalesRoom from '../../../../containers/SalesRoom/Core/customHooks/usePopoverUpdaterForExcelInSalesRoom';
import Icon from '../../../../shared/components/Icon/Icon';

function RealPaymentsForPropertyRow({ payment }) {
  const {
    handleClick,
    isOpen,
    anchorElement,
  } = usePopoverUpdaterForExcelInSalesRoom();

  const {
    isEditing,
    isRemoving,
    customPayment,
    onSave,
    editionToggle,
    removingToggle,
    onCancelRemoving,
    onChange,
    onDelete,
    onCancel,
  } = useUpdatePayment(payment, handleClick);

  return (
    <TableRow>
      <TableCell>
        <NumberFormat
          customInput={TextField}
          isNumberString
          decimalScale={0}
          fixedDecimalScale
          value={customPayment.number}
          disabled={!isEditing}
          onValueChange={onChange('number')}
        />
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            allowKeyboardControl
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id={`date-picker-payment-row-${payment.id}`}
            disabled={!isEditing}
            value={moment(Number(customPayment.date))}
            onChange={onChange('date')}
            KeyboardButtonProps={{
              'aria-label': 'Cambiar fecha',
            }}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <NumberFormat
          customInput={TextField}
          prefix="$"
          isNumberString
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          value={customPayment.price}
          disabled={!isEditing}
          onValueChange={onChange('price')}
        />
      </TableCell>
      {!agent.isAuthorized([Role.Sales]) && (
        <TableCell align="center">
          {(isEditing || isRemoving) && (
            <>
              <IconButton
                aria-label="Guardar"
                onClick={isEditing ? onSave : onDelete}
              >
                <DoneRounded />
              </IconButton>
              <IconButton
                color="secondary"
                aria-label="Cancelar"
                onClick={isEditing ? onCancel : onCancelRemoving}
              >
                <CloseRounded />
              </IconButton>
            </>
          )}
          {!isEditing && !isRemoving && (
            <>
              <IconButton aria-label="Opciones" onClick={handleClick}>
                <Icon variant="Options" color="gray600" />
              </IconButton>
              <Popover
                open={isOpen}
                anchorEl={anchorElement}
                onClose={handleClick}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <Button
                      className={Styles.buttonsText}
                      onClick={editionToggle}
                    >
                      <ListItemText primary="Editar" />
                    </Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button
                      className={Styles.buttonsText}
                      onClick={removingToggle}
                    >
                      <ListItemText primary="Eliminar" />
                    </Button>
                  </ListItem>
                </List>
              </Popover>
            </>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}

RealPaymentsForPropertyRow.propTypes = {
  payment: PropTypes.object,
};

export default RealPaymentsForPropertyRow;
