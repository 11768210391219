import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';

const EditableListFormItem = ({
  onSubmit,
  loading,
  onChange,
  defaultValue,
  onCancelEdit,
}) => {
  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box flexGrow={1} flexBasis={1}>
          <TextField
            required
            autoFocus
            fullWidth
            disabled={loading}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        </Box>
        <Box ml={2}>
          <IconButton variant="contained" ml="2" type="submit" color="primary">
            <CheckIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="edit"
            color="secondary"
            onClick={onCancelEdit}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </form>
  );
};

EditableListFormItem.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  onCancelEdit: PropTypes.func,
};

export default EditableListFormItem;
