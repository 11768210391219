import Services from 'services/services';
import {
  UpsertData,
  UpsertResponse,
  UpdateData,
  CreateResponse,
  CreateData,
  UpdateResponse,
  SortFieldsData,
  DeleteResponse,
} from '../core/interfaces/FeatureFieldServices.types';
import FeatureFieldServicesDefinitions from './FeatureFieldServicesDefinitions';

class FeatureFieldServices extends Services {
  create(data: CreateData): Promise<CreateResponse> {
    return this.post(FeatureFieldServicesDefinitions.getCreateUrl(), data);
  }

  upsertCell(data: UpsertData): Promise<UpsertResponse> {
    return this.put(FeatureFieldServicesDefinitions.getUpsertCellUrl(), data);
  }

  update(featureFieldId: number, data: UpdateData): Promise<UpdateResponse> {
    return this.patch(FeatureFieldServicesDefinitions.getUpdateUrl(featureFieldId), data);
  }

  deleteField(featureFieldId: number): Promise<DeleteResponse> {
    return this.delete(FeatureFieldServicesDefinitions.getUpdateUrl(featureFieldId));
  }

  sort(data: SortFieldsData) {
    return this.patch(FeatureFieldServicesDefinitions.getSortUrl(), data);
  }
}

export default FeatureFieldServices;
