import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import EditContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/Payments/contexts';
import QuotationsServices from '../../../../services/Quotations/Quotations.services';
import Numbers from '../../../../helpers/numbers';
import QuotationDiscountType from '../enums/QuotationDiscountType';
import { propertyHasCompletePriceForPlan } from '../../../BuyerCostumers/Core/customHooks/useBuyerPropertyDetailWithQuotationsStates';

const useDiscountGoalSeek = (
  quotation,
  enableDiscountCalculation,
  updateQuotation,
  reserveHandler,
  finalFeeHandler,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const isEditing = useContext(EditContext);
  const { towerId } = useParams();
  const [enableDiscount, setEnable] = useState(false);

  const isEnabledForAutomatic = (distributionWithInitialPrice) => {
    if (
      quotation.targetPercentages &&
      distributionWithInitialPrice.length === quotation.targetPercentages.length
    ) {
      const distributionWithPercentages = distributionWithInitialPrice.reduce(
        (accumulated, price, index) =>
          accumulated + price / (1 + quotation.targetPercentages[index]),
        quotation.reservePercentage,
      );

      const missingPrice = Numbers.cleanNumber(
        Math.ceil(quotation.targetPrice - distributionWithPercentages),
      );

      return Math.abs(missingPrice) <= 100;
    }
    return false;
  };

  useEffect(() => {
    const distributionWithInitialPrice = [
      ...quotation.distribution,
      quotation.finalPayment,
    ];
    let isEnabled = false;

    if (quotation.discountType === QuotationDiscountType.ASSIGNED.code) {
      isEnabled = isEnabledForAutomatic(distributionWithInitialPrice);
    } else {
      isEnabled = propertyHasCompletePriceForPlan(
        quotation,
        {
          priceWithAdditionalAreas: quotation.priceWithAdditionalAreas,
          discount: quotation.discount,
          tradeDiscount: Number(quotation.discountPrice),
        },
        quotation.finalPayment,
      );
    }

    setEnable(isEnabled);
  }, [
    quotation.distribution,
    quotation.reservePercentage,
    quotation.finalPayment,
    quotation.targetPercentages,
    quotation.targetPrice,
    quotation.discountType,
    quotation.discountPrice,
  ]);

  const onExecuteDiscount = async (updatedQuotation = null) => {
    const services = new QuotationsServices();
    try {
      if (!enableDiscountCalculation) return;
      const currentQuotation = updatedQuotation || quotation;
      const newDistribution = [
        currentQuotation.reservePercentage,
        ...currentQuotation.distribution,
        Number(currentQuotation.finalPayment),
      ];
      const response = await services.getQuotationDistribution(
        towerId,
        currentQuotation.id,
        newDistribution,
        currentQuotation.priceWithAdditionalAreas - currentQuotation.discount,
        currentQuotation.isLockedType,
        [...currentQuotation.indexSelected, newDistribution.length - 1, 0],
      );

      const {
        distribution,
        discountPrice,
        priceWithDiscount,
        discountPercentage,
        targetPercentages,
        targetPrice,
        isSuccess,
      } = response.data;

      if (!isSuccess && !currentQuotation.isLockedType) {
        throw new Error(
          'Lo sentimos, no se puede generar un descueto con los valores actuales. Trata de ajustar las cuotas 🤓 ',
        );
      }

      const maxDistributionLength = Math.max(distribution.length - 2, 1);
      const ceilDistribution = distribution.map(Math.ceil);
      const newDistributionWithDiscount = ceilDistribution.splice(
        1,
        maxDistributionLength,
      );
      const [reservePrice, finalPrice] = ceilDistribution;

      const quotationWithDiscount = {
        ...currentQuotation,
        distribution: newDistributionWithDiscount,
        discountPrice,
        discountPercentage,
        priceWithDiscount,
        targetPercentages,
        targetPrice,
      };
      updateQuotation(quotationWithDiscount);

      if (Number(reservePrice) !== Number(currentQuotation.reservePercentage)) {
        reserveHandler('', reservePrice, false);
      }

      if (Number(finalPrice) !== Number(currentQuotation.finalPayment)) {
        finalFeeHandler(finalPrice, false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    isEditing,
    onClickDiscount: onExecuteDiscount,
    enableDiscount,
  };
};

export default useDiscountGoalSeek;
