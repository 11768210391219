import React, { useReducer, useState } from 'react';
import {
  createClient,
  updateClient,
  addClient,
  updateSelectedClient,
} from './actions';
import reducer, { initialState } from './reducer';
import { Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from 'shared/components/Typography/Typography';
import AsyncConfirmModal from 'shared/components/AsyncModal/AsyncConfirmModal';
import ClientSearch from '../../components/Client2/Search';
import ClientList from '../../components/Client2/List';
import ClientFormDialog from '../../App/AddClientModal/UI/AddClientModalMainView';
import ClientDetailsDialog from '../../components/Client2/DetailsDialog';
import ContainerContext from './context';
import withDefaultLayout from '../../HOC/Layouts/Default/withDefaultLayout';
import ClientModal from 'App/Clients/UI/Modals/ClientModal/ClientModal';
import useModal from 'shared/customHooks/useModal';
import Styles from './Client.module.scss';
import useClientActions from './Core/customHooks/useClientActions';

const Client = (props) => {
  const [keySearchInput, setKeySearchInput] = useState(Math.random());
  const [clientState, dispatch] = useReducer(reducer, initialState);

  const {
    isOpen: isOpenClientModal,
    open: openClientModal,
    close: closeClientModal,
  } = useModal({
    handleClose: () => {
      setKeySearchInput(Math.random());
      dispatch(updateSelectedClient(null));
    },
  });

  const {
    limit,
    offset,
    total,
    sorting,
    isOpen,
    asyncConfirmDeleteClientModalRef,
    selectedClient,
    setIsOpen,
    changeOffset,
    handleClickDelete,
    changeSorting,
    goToSalesRoom,
    handleClickEditClient,
    quotes,
  } = useClientActions({
    towerId: props.match.params.towerId,
    dispatch,
    openClientModal,
  });

  return (
    <ContainerContext.Provider
      value={{
        towerId: props.match.params.towerId,
        history: props.history,
        dispatch,
        createClient,
        updateClient,
        addClient,
        makeAlert: props.spawnMessage,
        isOpen,
        setIsOpen,
        ...clientState,
      }}
    >
      <ClientSearch
        key={keySearchInput}
        onSelectHandler={(client) => {
          openClientModal();
          if (client) {
            dispatch(updateSelectedClient(client));
          }
        }}
        onClickNewClient={openClientModal}
      />
      <div className={Styles.container}>
        <div>
          <ClientModal
            client={clientState.selectedClient}
            onChangeClient={(client) => dispatch(addClient(client))}
            isOpen={isOpenClientModal}
            onCloseHandler={closeClientModal}
            openDetails={(client) => {
              setIsOpen({ ...isOpen, detail: true });
              dispatch(updateSelectedClient(client));
            }}
          />
        </div>
        <ClientList
          page={offset}
          limit={limit}
          total={total}
          sorting={sorting}
          onChangePaginationPage={changeOffset}
          onSortingChange={changeSorting}
          clients={clientState.list}
          isLoading={clientState.isLoading}
          deleteClient={handleClickDelete}
          goToQuotes={quotes}
          goToSalesRoom={goToSalesRoom}
          onClickEditClient={handleClickEditClient}
        />
      </div>
      <ClientFormDialog
        key={'key-Dialog'}
        open={isOpen.form}
        client={clientState.selectedClient}
        onCloseHandler={() => {
          setKeySearchInput(Math.random());
          setIsOpen({ ...isOpen, form: false });
          dispatch(updateSelectedClient(null));
        }}
      />
      <ClientDetailsDialog
        client={clientState.selectedClient}
        open={isOpen.detail}
        towerId={props.match.params.towerId}
        handleClose={() => {
          setIsOpen({ ...isOpen, detail: false });
        }}
      />
      <AsyncConfirmModal ref={asyncConfirmDeleteClientModalRef}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <ErrorOutlineIcon color="error" fontSize="large" />
          <Box marginTop={2}>
            <Typography variant="headline5">Eliminar usuario</Typography>
          </Box>
          <Box marginY={2}>
            <Box>
              <Typography variant="body1">
                El usuario {selectedClient?.name} {selectedClient?.middleName}{' '}
                {selectedClient?.surname} {selectedClient?.secondSurname} se
                elimirará de forma permamante.
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                ¿Esta seguro que desea continuar esta acción?
              </Typography>
            </Box>
          </Box>
        </Box>
      </AsyncConfirmModal>
    </ContainerContext.Provider>
  );
};

export default withDefaultLayout(Client);
