import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import numbers from '../../../../../helpers/numbers';

const GridBold = (text) => (
  <Grid item>
    <Box m={1} fontWeight="fontWeightBold">
      {text}
    </Box>
  </Grid>
);

const GridCurrency = (text) => (
  <Grid item>
    <Box m={1}>{numbers.currencyFormat(text)}</Box>
  </Grid>
);
export default function TodayPriceDialog({ open, closeFunction, property }) {
  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">{`Precio a hoy`}</DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <Grid item>
            <Grid container direction="column">
              {GridBold('Valor de compra:')}
              {GridBold('Valor hoy:')}
              {GridBold('Diferencia:')}
              {GridBold('% de Incremento:')}
              {GridBold('Tasa E.A:')}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              {GridCurrency(property.priceSold)}
              {GridCurrency(property.todayPrice)}
              {GridCurrency(property.difference)}
              <Grid item>
                <Box m={1}>
                  {numbers.toFixed(
                    property.percentageOfIncrement * 100,
                    2,
                    true,
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>{numbers.toFixed(property.ea * 100, 2, true)}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            closeFunction();
          }}
        >
          CERRAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
