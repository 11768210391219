import { useMutation } from 'react-query';
import ClientsServices from '../../../../../services/imports/Imports.services';

const service = new ClientsServices();
const useAreaUploadFile = (towerId: string) => {
  const { mutate, isSuccess, isLoading } = useMutation(async (formData: FormData) =>
    service.postSchema(towerId, formData),
  );
  return { isSuccess, isLoading, mutate };
};

export default useAreaUploadFile;
