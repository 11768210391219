import { useState, useEffect } from 'react';

import { FieldCell } from 'App/FeatureField/core/interfaces/featureField.types';
import { UseTableTagSelectParams } from '../interfaces/useTableTagSelect.types';
import { TagOption } from '../interfaces/TableTagMenu.types';

const isEmptyOption = (id: number) => id === -1;

const getNewSelectedOptions = (option: TagOption) => (isEmptyOption(option.id) ? [] : [option.id]);

const getNewValue = (prevValue: FieldCell, option: TagOption): FieldCell => ({
  ...prevValue,
  selectedOptions: getNewSelectedOptions(option),
});

const getChange = (row: any, cellValue: FieldCell, option: TagOption) => ({
  row,
  cell: getNewValue(cellValue, option),
});

function useTableTagSelect({ row, cellValue, onValueChange, closeMenu, onBlur }: UseTableTagSelectParams) {
  const [isUpdated, setIsUpdated] = useState(false);

  const handleSelectOption = (option: TagOption) => {
    onValueChange(getChange(row, cellValue, option));
    closeMenu();
    setIsUpdated(true);
  };

  useEffect(() => {
    const emitOnBlur = () => onBlur && onBlur();

    const changeEditState = () => {
      emitOnBlur();
      setIsUpdated(false);
    };

    if (isUpdated) {
      changeEditState();
    }
  });

  return handleSelectOption;
}

export default useTableTagSelect;
