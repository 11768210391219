import React from 'react';
import { Container } from '@material-ui/core';
import CRMList from './CRMList';

const CRM = () => {
  return (
    <Container>
      <CRMList />
    </Container>
  );
};

export default CRM;
