import Services from 'services/services';

import {
  GetViewDataResponse,
  UpdateSalesStatusResponse,
  UpdateSalesStatusValues,
} from '../core/types/SalesStatus.types';
import SalesStatusServicesDefinitions from './SalesStatusServicesDefinitions';

class SalesStatusServices extends Services {
  getViewData(towerId: string): Promise<GetViewDataResponse> {
    return this.get(SalesStatusServicesDefinitions.getTowerIdUrl(towerId));
  }

  updateSalesStatus(towerId: string, values: UpdateSalesStatusValues): Promise<UpdateSalesStatusResponse> {
    return this.put(SalesStatusServicesDefinitions.getTowerIdUrl(towerId), values);
  }
}

export default SalesStatusServices;
