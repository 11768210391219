import Services from '../../../services/services';
import DocumentTemplatesServicesDefinition from './DocumentTemplatesDefinition';

class DocumentTemplates extends Services { 
  getTemplates(towerId) {
    return this.get(
      DocumentTemplatesServicesDefinition.getTemplates(towerId)
    );
  }

  deleteTemplate(id) {
    return this.delete(
      DocumentTemplatesServicesDefinition.deleteTemplate(id)
    );
  }

  uploadTemplate(towerId, name, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(
      DocumentTemplatesServicesDefinition.uploadTemplate(towerId, name),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
  }
}

export default DocumentTemplates;