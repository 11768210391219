import { useEffect, useState } from 'react';
import { find, merge } from 'lodash';
import { Column, IntegratedSummary, SummaryItem, Table } from '@devexpress/dx-react-grid';
import { QueryClient, useQueryClient } from 'react-query';
import moment from 'moment';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { EvalproFlowDto } from '../DTO';
import constants from './constants';
import { FORMAT_YEAR_MONTH2 } from '../../../../helpers/dates';

const defaultColumns: ColumnType[] = [
  {
    title: ' ',
    name: 'name',
    columnName: 'name',
    width: 300,
    type: ProviderColumnType.tooltip,
  },
  {
    title: 'Total',
    name: 'amount',
    columnName: 'amount',
    width: 150,
    type: ProviderColumnType.currency,
  },
];

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

type HookType = () => {
  rows: EvalproFlowDto[] | undefined;
  columns: ColumnType[];
  totalSummaryItems: SummaryItem[];
  fixedColumns: string[];
  summaryCalculator: (
    type: string,
    rows: EvalproFlowDto[],
    getValue: (row: EvalproFlowDto) => EvalproFlowDto,
  ) => number | string | undefined;
};

const getDateColumns = <T extends object>(sampleRow: T): ColumnType[] => {
  const keys = Object.keys(sampleRow) as Array<Extract<keyof typeof sampleRow, string>>;

  return keys
    .sort((a, b) => {
      const aMoment = moment(a, FORMAT_YEAR_MONTH2);
      const bMoment = moment(b, FORMAT_YEAR_MONTH2);
      return aMoment.diff(bMoment);
    })
    .reduce((acc: ColumnType[], col: string) => {
      const defaultColumn = find(defaultColumns, (o: Column) => o.name === col);
      if (defaultColumn === undefined && !['id', 'parentId'].includes(col)) {
        const row: ColumnType = {
          name: col,
          title: col,
          columnName: col,
          type: ProviderColumnType.currency,
          width: 150,
        };
        return [...acc, row];
      }
      return acc;
    }, []);
};

const getColumns = <T extends object>(sampleRow: T): ColumnType[] => {
  if (!!sampleRow) {
    const dateColumns = getDateColumns(sampleRow);

    return defaultColumns.concat(dateColumns);
  }
  return defaultColumns;
};

const getSummaryColumns = (columns: ColumnType[]): SummaryItem[] => {
  return columns.reduce((acc: SummaryItem[], col: ColumnType) => {
    let type = 'title';
    if (col.type === ProviderColumnType.currency) {
      type = 'sum';
    }
    const row: SummaryItem = {
      columnName: col.columnName,
      type,
    };
    return [...acc, row];
  }, []);
};

const useColumnsAndRowsForContractsEvalproFlow: HookType = () => {
  const queryClient: QueryClient = useQueryClient();
  const rows = queryClient.getQueryData<EvalproFlowDto[]>(constants.CONTRACTS_EVALPRO_FLOW) ?? [];
  const [fixedColumns] = useState(['name', 'amount']);

  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [totalSummaryItems, setSummaryItems] = useState<SummaryItem[]>([]);

  useEffect(() => {
    const sampleRow = merge({}, ...rows);

    const newColumns = getColumns(sampleRow);
    const newSummaryItems = getSummaryColumns(newColumns);
    setSummaryItems(newSummaryItems);
    setColumns(newColumns);
  }, [rows]);

  const summaryCalculator = (
    type: string,
    rows: EvalproFlowDto[],
    getValue: (row: EvalproFlowDto) => EvalproFlowDto,
  ) => {
    if (type === 'title') {
      if (!rows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', rows, getValue);
  };

  return {
    rows,
    columns,
    totalSummaryItems,
    summaryCalculator,
    fixedColumns,
  };
};

export default useColumnsAndRowsForContractsEvalproFlow;
