import { Table } from '@devexpress/dx-react-grid-material-ui';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import colors from '../../assets/styles/settings/colors.module.scss';

const tableContainerMixin = css`
  /* Visual */
  background-color: white;
  border: 1px solid ${colors.gray300};
  border-radius: 4px;
`;

const TableContainer: FC = styled(Table.Container)`
  ${tableContainerMixin}
`;

export default TableContainer;

export { tableContainerMixin };