import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomTypography from '../../../../../components/Typography/CustomTypography';

const CustomBoldTypography = styled(CustomTypography)`
  width: 17vw;
`;

const ClientHubBasicDescriptionRow = ({ description, value, pt }) => {
  return (
    <Box display="flex" pt={pt}>
      <CustomBoldTypography variant="subtitle">
        {description}
      </CustomBoldTypography>
      <CustomTypography variant="form">{value}</CustomTypography>
    </Box>
  );
};

ClientHubBasicDescriptionRow.defaultProps = {
  pt: 0,
};

ClientHubBasicDescriptionRow.propTypes = {
  description: PropTypes.string,
  value: PropTypes.string,
  pt: PropTypes.number,
};

export default ClientHubBasicDescriptionRow;
