import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { Grid, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { SelectionState } from '@devexpress/dx-react-grid';

import { DateTypeProvider, TitleTypeProvider } from '../../../../../shared/tables/TableColumnFormat';
import TableCell from '../../../../../shared/tables/TableCell';
import VirtualTableContainer from '../../../../../shared/tables/VirtualTableContainer';
import TableHeaderRowCell from '../../../../../shared/tables/TableHeaderRowCell';

import useColumnsAndRowsForContractEvents from '../../../Core/customHooks/useColumnsAndRowsForContractEvents';

import { Event } from '../../../../../models/ContractPayments';

const getRowId = (row: Event) => row.id;

interface IContractEventsGrid {
  onSeleccionChange: (contractEvent: Event) => void;
}

const ContractEventsGrid: FC<IContractEventsGrid> = ({ onSeleccionChange }) => {
  const { rows, columns, titleColumns, dateColumns, tableColumnExtensions } = useColumnsAndRowsForContractEvents();

  const [selection] = useState([]);

  return (
    <>
      <Box mt={2}>
        {!!rows && (
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <TitleTypeProvider for={titleColumns} />
            <DateTypeProvider for={dateColumns} />
            <SelectionState
              selection={selection}
              onSelectionChange={(sel) => {
                const [selectedId] = sel;
                const contractEvent = rows.find((row) => row.id === selectedId) || null;

                if (!!contractEvent) {
                  onSeleccionChange(contractEvent);
                }
              }}
            />
            <Table
              columnExtensions={tableColumnExtensions}
              containerComponent={VirtualTableContainer}
              cellComponent={TableCell}
            />
            <TableHeaderRow cellComponent={TableHeaderRowCell} />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ContractEventsGrid;
