import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button, Grid } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import UploadButton from './UploadButton';
import useQuotationFileUploader from '../helpers/customHooks/useQuotationFileUploader';
import DownloadButton from '../../../../../../UI/DownloadButton/DownloadButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#018e42',
  },
}));

export default function ImportFromExcelView({ isExpanded, setExpansion }) {
  const classes = useStyles();

  const {
    date,
    file,
    isUploadingFile,
    setDate,
    onAccept,
    onCancel,
    downloadTemplate,
    onChangeFileHandler,
  } = useQuotationFileUploader();

  return (
    <Box pt={3} className={classes.root}>
      <Accordion onChange={setExpansion} expanded={isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Cotización a través de EXCEL
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item container spacing={1} lg={12}>
              <Grid item xs={6}>
                <Typography>
                  Seleccione la fecha de la primera cuota:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline-quotation-viewer"
                    label="Fecha inicial"
                    disableFuture={true}
                    value={date}
                    views={['year', 'date', 'month']}
                    defaultValue={new Date()}
                    onChange={setDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container spacing={1} lg={12}>
              <Grid item xs={6}>
                <Typography>Descargar plantilla de cuotas:</Typography>
              </Grid>
              <Grid item xs={6}>
                <DownloadButton onClick={downloadTemplate}>
                  Descargar
                </DownloadButton>
              </Grid>
            </Grid>
            <Grid item container spacing={1} lg={12}>
              <Grid item xs={6}>
                <Typography>Cargar plantilla de cuotas:</Typography>
              </Grid>
              <Grid item xs={6}>
                <UploadButton
                  fileName={file ? file.name : null}
                  onChangeHandler={onChangeFileHandler}
                  onCancel={onCancel}
                  onAccept={onAccept}
                  isUploadingFile={isUploadingFile}
                  key={file ? file.name : ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

ImportFromExcelView.propTypes = {
  isExpanded: PropTypes.bool,
  setExpansion: PropTypes.func,
};
