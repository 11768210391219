import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Numbers } from 'helpers';
import { QuotationConfigDto } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import QuotationConfigCollapse from '../../components/QuotationConfigCollapse/QuotationConfigCollapse';
import useBankInformationForm from 'App/QuotationConfig/core/customHooks/useBankInformationForm';
import BankInformationFormItem from './BankInformationFormItem';

const BankInformationForm: FC<QuotationConfigDto> = (props) => {
  const { termLimit, annualRate, requiredEntryRate } = props.quotationSetup;
  const {
    monthlyRate,
    isValidBankInformation,
    handleChangeTermLimit,
    handleChangeAnnualRate,
    handleChangeRequiredEntryRate,
    isAllowed,
  } = useBankInformationForm(props);

  return (
    <QuotationConfigCollapse name="Información Bancaria" state={isValidBankInformation ? 'success' : 'error'}>
      <Box display="flex" justifyContent="center" padding="40px" paddingY="32px" paddingBottom="55px">
        <Grid container item xs={8}>
          <BankInformationFormItem
            name="Años de financiación"
            endLabel="años"
            value={termLimit ?? 0}
            isAllowed={isAllowed(-1, 1000)}
            onValueChange={handleChangeTermLimit}
            decimalScale={0}
          />
          <BankInformationFormItem name="Equivalente en meses" endLabel="meses" value={termLimit * 12} disabled />
          <BankInformationFormItem
            name="Tasa efectiva anual"
            endLabel="%"
            defaultValue={annualRate * 100 ?? 0}
            isAllowed={isAllowed(-1, 101)}
            onValueChange={handleChangeAnnualRate}
            decimalScale={2}
            fixedDecimalScale
          />
          <BankInformationFormItem
            name="Tasa mensual"
            endLabel="%"
            value={Numbers.toFixed(monthlyRate)}
            disabled
            decimalScale={2}
            fixedDecimalScale
          />
          <BankInformationFormItem
            name="Porcentaje"
            endLabel="%"
            value={requiredEntryRate ?? 0}
            isAllowed={isAllowed(-1, 101)}
            onValueChange={handleChangeRequiredEntryRate}
            decimalScale={2}
            fixedDecimalScale
          />
        </Grid>
      </Box>
    </QuotationConfigCollapse>
  );
};

export default BankInformationForm;
