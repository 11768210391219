import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import useClientHubMapper from '../../../Core/customHooks/useClientHubMapper';
import ClientHubBasicDescriptionRow from '../rows/ClientHubBasicDescriptionRow';
import ClientHubSummaryDescriptionRow from '../rows/ClientHubSummaryDescriptionRow';
import ClientHubTitleWithBackgroundRow from '../rows/ClientHubTitleWithBackgroundRow';

const ClientHubForCurrentMonthView = () => {
  const {
    clientName,
    clientIdentity,
    towerName,
    projectName,
    propertyName,
    isArrears,
    arrearsDays,
    arrearsValue,
    initialRealPayment,
    balanceCalculated,
    commercialPrice,
    commitment,
    totalPayment,
    accumulatedPayments,
    financialAccumulated,
    minPaymentToday,
  } = useClientHubMapper();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ClientHubBasicDescriptionRow
            description="Nombre"
            value={clientName}
          />
          <ClientHubBasicDescriptionRow
            description="Documento"
            value={clientIdentity}
          />
          <ClientHubBasicDescriptionRow
            description="Proyecto"
            value={projectName}
          />
          <ClientHubBasicDescriptionRow description="Torre" value={towerName} />
          <ClientHubBasicDescriptionRow
            description="Apartamento"
            value={propertyName}
          />
          <ClientHubBasicDescriptionRow
            description="Datos actualizados a"
            value={moment().format('dddd, MMMM DD, YYYY')}
            pt={5}
          />
        </Grid>

        <Grid item xs={12}>
          <ClientHubTitleWithBackgroundRow title="ESTADO DE CARTERA Y PAGOS" />
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="ESTADO DE CARTERA"
              value={isArrears ? 'MORA' : 'AL DÍA'}
              background={isArrears ? 'red' : 'green'}
              isPrice={false}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGO MÍNIMO HOY"
              value={arrearsValue}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="DÍAS DE MORA"
              value={arrearsDays}
              isPrice={false}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGO CUOTA INICIAL"
              value={initialRealPayment}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="VALOR EN MORA"
              value={arrearsValue}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGO TOTAL"
              value={balanceCalculated}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ClientHubTitleWithBackgroundRow title="RESUMEN CARTERA" />
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="VALOR DE COMPRA"
              value={commercialPrice}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="COMPROMISOS A LA FECHA"
              value={commitment}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGADO"
              value={accumulatedPayments}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGADO"
              value={accumulatedPayments}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="INTERESES"
              value={financialAccumulated}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="INTERESES"
              value={financialAccumulated}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGO TOTAL"
              value={totalPayment}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientHubSummaryDescriptionRow
              description="PAGO MÍNIMO HOY"
              value={minPaymentToday}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientHubForCurrentMonthView;
