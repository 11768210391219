import { combineReducers } from 'redux';
import {
  UPDATE_CHARGE_NUMBER,
  UPDATE_CLIENT_HUB_FOR_WALLET_PROPERTY,
  UPDATE_CLIENT_IS_PURCHASE_CONFIRMED,
  UPDATE_MONTHLY_PAYMENTS_FOR_PROPERTY,
  UPDATE_PROPERTY_ID_FOR_PAYMENTS,
  UPDATE_REAL_PAYMENTS_FOR_PROPERTY,
} from './actions';

const initialState = {
  isLoading: false,
  payments: [],
  monthlyEvents: [],
  clientHub: {},
  isPurchaseConfirmed: false,
  propertyId: null,
};

const mapRealPayments = (state, payments) => {
  return {
    ...state,
    payments,
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_REAL_PAYMENTS_FOR_PROPERTY:
      return mapRealPayments(state, payload);
    case UPDATE_MONTHLY_PAYMENTS_FOR_PROPERTY:
      return {
        ...state,
        monthlyEvents: payload,
      };
    case UPDATE_CLIENT_HUB_FOR_WALLET_PROPERTY:
      return {
        ...state,
        clientHub: payload,
      };
    case UPDATE_CLIENT_IS_PURCHASE_CONFIRMED:
      return {
        ...state,
        isPurchaseConfirmed: payload,
      };
    case UPDATE_PROPERTY_ID_FOR_PAYMENTS:
      return {
        ...state,
        propertyId: payload,
      };
    case UPDATE_CHARGE_NUMBER:
      return {
        ...state,
        clientHub: { ...state.clientHub, chargeNumber: payload },
      };
    default:
      return state;
  }
};

export default combineReducers({ root: reducer });
