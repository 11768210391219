const BalancePointType = {
  MANUAL: {
    code: 'M',
    value: 'Manual',
  },
  PERCENTAGE: {
    code: 'P',
    value: 'Porcentaje',
  },
};

export default BalancePointType;
