import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import DetailsTable from '../../UI/views/table/DetailsTableForTotalCashFlow';
import GeneralTable from '../../UI/views/table/GeneralTotalCashFlowTable';

const useStateForViewController = ({ onRowChange, rows }) => {
  const typeOfViewState = useSelector(
    (state) => state.cashFlow.root.typeOfView,
  );
  const detail = 'DETAIL';

  const thousands = 1000;
  const millions = 1000000;
  const pesos = 1;

  let divider;
  let decimals;

  switch (typeOfViewState) {
    case 'thousands':
      divider = thousands;
      decimals = 0;
      break;
    case 'pesos':
      divider = pesos;
      decimals = 0;
      break;
    default:
      divider = millions;
      decimals = 1;
      break;
  }

  const tableSelector = (selectedTab) => {
    return (
      <Box height="60vh">
        {selectedTab.id === detail ? (
          <DetailsTable
            onRowChange={onRowChange}
            rows={rows}
            divider={divider}
          />
        ) : (
          <GeneralTable
            onRowChange={onRowChange}
            rows={rows}
            divider={divider}
          />
        )}
      </Box>
    );
  };

  return {
    tableSelector,
  };
};

export default useStateForViewController;
