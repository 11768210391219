import { useMutation, useQueryClient } from 'react-query';
import useBoolean from 'shared/customHooks/useBoolean';
import { GeneralWalletDetailsService } from '../../services/GeneralWalletDetailsService';
import { GENERAL_WALLET_THREADS } from '../constants/GeneralWalletDetailsReactQuery';
import { GeneralWalletThreadDTO } from '../DTO/GeneralWalletComment.dto';

const service = new GeneralWalletDetailsService();
const useGeneralWalletDetailsThreadResponse = (props: GeneralWalletThreadDTO) => {
  const queryClient = useQueryClient();
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();

  const mutation = useMutation(
    (value: string | File) => {
      if (typeof value === 'string') {
        return service.createInteractionComment(props.id, value);
      }
      const form = new FormData();
      form.append('file', value);
      return service.createInteractionFile(props.id, form);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GENERAL_WALLET_THREADS]);
        close();
      },
      onError: () => {
        close();
      },
    },
  );

  const handleSubmit = (value: string | File) => {
    mutation.mutate(value);
  };

  return {
    handleSubmit,
    isOpen,
    open,
    ...mutation,
  };
};

export default useGeneralWalletDetailsThreadResponse;
