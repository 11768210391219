import { useEffect, useState } from 'react';
import useBoolean from 'shared/customHooks/useBoolean';
import { QuotationSetup } from '../interfaces/ScheduleServicesGetDatesResponse';

interface UseDeedExpensesSalesMadeProps {
  quotationSetup?: QuotationSetup;
}

const useDeedExpensesSalesMade = ({ quotationSetup }: UseDeedExpensesSalesMadeProps) => {
  const [displacement, setDisplacement] = useState<number>(0);
  const { value: isOpenConfiguration, setTrue: openConfiguration, setFalse } = useBoolean();

  const onCloseConfig = (newDisplacement?: number) => {
    if (newDisplacement !== undefined || (typeof newDisplacement === 'number' && newDisplacement !== displacement))
      setDisplacement(newDisplacement || 0);
    setFalse();
  };

  useEffect(() => {
    if (quotationSetup?.defualtDeedDisplacement !== undefined && !isNaN(quotationSetup.defualtDeedDisplacement))
      setDisplacement(Number(quotationSetup.defualtDeedDisplacement));
  }, [quotationSetup?.defualtDeedDisplacement]);

  return {
    displacement,
    isOpenConfiguration,
    openConfiguration,
    onCloseConfig,
  };
};

export default useDeedExpensesSalesMade;
