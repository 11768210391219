import { API_PATH } from '../../../config/config';

class GroupTypesServicesDefinitions {
  static create = (towerId) => `${API_PATH}group-types/create-new/${towerId}`;

  static updateName = (towerId) =>
    `${API_PATH}group-types/update-name/${towerId}`;

  static delete = (towerId) => `${API_PATH}group-types/remove/${towerId}`;

  static all = (towerId) => `${API_PATH}group-types/all/${towerId}`;
}

export default GroupTypesServicesDefinitions;
