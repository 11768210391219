import useBoolean from "shared/customHooks/useBoolean";
import { useSnackbar } from "notistack";
import ClientsServices from "services/client/ClientsServices";
import { RestServiceError } from "services/errors/ResponseError";


const services = new ClientsServices();

const useAddCientToTower = (towerId: string, clientId?: string) => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        value: isLoading,
        setTrue: loading,
        setFalse: stopLoading,
    } = useBoolean()

    const addClientToTower = async () => {
        loading()
        try {
            if (clientId) {
                const { data } = await services.addClientToTower(clientId, towerId);
                stopLoading()
                return data;
            }
        } catch (error) {
            const errorMessage = error as RestServiceError;
            if (errorMessage?.error === "Conflict")
                enqueueSnackbar(errorMessage.message, { variant: 'error' });
            else
                enqueueSnackbar(errorMessage.message, { variant: 'error' });
            stopLoading()
            return false
        }
        stopLoading()
    }

    return {
        addClientToTower,
        isLoading
    }
}

export default useAddCientToTower