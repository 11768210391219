import { createFilterOptions } from "@material-ui/lab/useAutocomplete";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import PropertyTypeService from "../../services/PropertyTypeService";

const service = new PropertyTypeService()
const filter = createFilterOptions();

const useAutocompletePropertyType = ({
  property,
  type,
  types = [],
  updatePropertyTypes,
  updateRows,
}) => {
  const [value, setValue] = useState(type || { id: '', name: ''});
  const [loading, setLoading] = useState(false);
  const { towerId } = useParams();

  const updateProperty = async (typeId) => {
    const { data } = await service.setTypeToProperty({
      propertyId: property.id,
      typeId,
    });
    if (data.updated)
      updateRows(Object.assign({}, { ...property, type: typeId }));
  };

  useEffect(() => {
    if (type && type.id) {
      setValue(type)}
  },[type])

  const onChange = async (e, value) => {
    if (value && value.inputValue) {
      setLoading(true);
      const { data } = await service.create({ name: value.inputValue, towerId });
      if (data.created && data.id) {
        const newType = {
          id: data.id,
          name: value.inputValue,
        };
        setValue(newType);
        updateProperty(newType.id);
        updatePropertyTypes(types.concat(newType));
      }
      setLoading(false);
    } else {
      if (value) updateProperty(value.id);
      setValue(value);
    }
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `Agregar "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = (type) => {
    if (typeof type === 'string') return type;
    if (type.inputValue) return type.inputValue;
    return type.name;
  };

  const onBlur = () => {
    if (!value && type) setValue(type);
  };

  return {
    value,
    onChange,
    filterOptions,
    onBlur,
    types,
    loading,
    getOptionLabel,
  };
};

export default useAutocompletePropertyType;
