import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import CashFlowEvalproServices from '../../Services/SalesSpeedServices';
import _ from 'lodash';

const services = new CashFlowEvalproServices();
const makeDynamicColumns = (currentColumns, dates) => {
  const newColumns = Object.keys(dates).reduce((array, column) => {
    if (
      column === 'id' ||
      column === 'parentId' ||
      column === 'type' ||
      column === 'total'
    ) {
      return array;
    }
    return [...array, { name: column, title: column }];
  }, currentColumns);
  return newColumns;
};

function useColumnsAndRowsForTableSalesSpeed(isEvalpro = false) {
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const salesSpeed = await services.getSalesSpeed(towerId, isEvalpro);
        setTableData(salesSpeed.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setLoading(false);
    };

    if (tableData.length <= 0 && !isLoading) {
      fetch();
    }
  }, []);
  const rows = tableData.map((row) => {
    const currentRow = Object.keys(row).reduce((customRow, key) => {
      if (key === 'id' || key === 'parentId' || key === 'type') {
        return customRow;
      }

      return { ...customRow, [key]: row[key] };
    }, row);
    return currentRow;
  });
  const lengths = rows.map((row) => _.values(row).length);
  const columnRows = rows[0] ? rows[lengths.indexOf(Math.max(...lengths))] : [];
  const [lengthColumns, setLengthColumns] = useState(
    Object.keys(columnRows).length,
  );

  const [columns, setColumns] = useState(
    makeDynamicColumns(
      [
        { name: 'type', title: 'Grupo' },
        { name: 'total', title: 'Total' },
      ],
      columnRows,
    ),
  );

  if (lengthColumns !== Object.keys(columnRows).length) {
    setColumns(
      makeDynamicColumns(
        [
          { name: 'type', title: 'Grupo' },
          { name: 'total', title: 'Total' },
        ],
        columnRows,
      ),
    );
    setLengthColumns(Object.keys(columnRows).length);
  }

  const [tableColumnExtensions] = useState([
    { columnName: 'type', width: 200 },
    { columnName: 'total', width: 150 },
  ]);

  const [leftColumns] = useState(['type', 'total']);

  const getChildrenRows = (row, rootRows) => {
    const childRows = rootRows.filter((nextRow) => {
      return nextRow.parentId === row;
    });
    return childRows.length ? childRows : null;
  };

  const [defaultExpandedRowIds] = useState([]);

  return {
    columns,
    rows,
    tableColumnExtensions,
    leftColumns,
    defaultExpandedRowIds,
    getChildrenRows,
  };
}

export default useColumnsAndRowsForTableSalesSpeed;
