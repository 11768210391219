import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SalesRoomService from '../../../../services/salesRoom/salesRoomService';
import PropertyCharacteristicServices from '../../services/PropertyCharacteristicServices';
import { updatePropertiesWithCharacteristics } from '../../../GraphicMaterial/Core/actions';

const usePropertyCharacteristicRequest = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [existingProperties, setExistingProperties] = useState(null);

  const services = new SalesRoomService();
  const propertyServices = new PropertyCharacteristicServices();

  const fillPropertiesCharacteristics = async (tower, properties) => {
    const plainProperties = properties.flatMap((property) => property);
    await Promise.all(
      plainProperties.flatMap(async (property) => {
        if (!property.propertyCharacteristic) {
          await propertyServices.setCharacteristic(tower, {
            propertyId: property.id,
            bathrooms: 0,
          });
          return true;
        }
        return [];
      }),
    );
  };

  const fetch = async () => {
    setLoading(true);
    try {
      const properties = await services.getProperties(towerId, null);
      await fillPropertiesCharacteristics(
        towerId,
        properties.data.incrementList.properties,
      );
      dispatch(
        updatePropertiesWithCharacteristics(
          properties.data.incrementList.properties,
        ),
      );
      setLoading(false);
      setExistingProperties(properties.data.incrementList.properties);
    } catch (error) {
      setExistingProperties([]);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  if (!existingProperties && !isLoading) {
    fetch();
  }

  return { isLoading };
};
export default usePropertyCharacteristicRequest;
