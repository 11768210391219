import Services from '../../../services/services';
import BuyerCostumerServicesDefinitions from './BuyerCustomerServicesDefinitions';

export default class BuyerCustomerServices extends Services {
  getCostumers(towerId) {
    return this.get(BuyerCostumerServicesDefinitions.getClients(towerId));
  }
  
  getCostumer(clientId, propertyId) {
    return this.get(BuyerCostumerServicesDefinitions.getClientByProperty(clientId, propertyId));
  }

  getPropertyForWord(propertyId, towerId) {
    return this.get(
      BuyerCostumerServicesDefinitions.getPropertyForWord(propertyId, towerId),
      {
        responseType: 'blob',
      },
    );
  }

  getWord() {
    return this.get(BuyerCostumerServicesDefinitions.getWord(), {
      responseType: 'blob',
    });
  }

  getAvailableDocuments(towerId) {
    return this.get(
      BuyerCostumerServicesDefinitions.getAvailableDocuments(towerId),
    );
  }

  downloadDocument(documentId, propertyId, clientId) {
    return this.get(
      BuyerCostumerServicesDefinitions.downloadDocument(
        documentId,
        propertyId,
        clientId,
      ),
      {
        responseType: 'arraybuffer',
      },
    );
  }
}
