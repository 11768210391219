import * as xlsx from 'xlsx';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { getMonthlyDatesFromMomentColumns } from 'helpers/dates';

const NAME = 'name';
const ACCUMULATED = 'accumulated';
const PROJECTED = 'projected';
const TOTAL = 'total';

const useDownloadTotalCashFlow = (dataToDownload) => {
  const { enqueueSnackbar } = useSnackbar();

  const { initialDate, lastDate, orderedRows } = dataToDownload;

  const downloadExcel = () => {
    const { arrayOfDates } = getMonthlyDatesFromMomentColumns(
      initialDate,
      lastDate,
    );

    const getColumns = (arrayOfDates) => {
      return [
        { key: NAME, header: 'Nombre' },
        { key: ACCUMULATED, header: 'Acumulado' },
        { key: PROJECTED, header: 'Proyectado' },
        { key: TOTAL, header: 'Total' },
        ...arrayOfDates.map((date) => ({ key: date, header: date })),
      ];
    };

    const headersForFilterRows = [
      NAME,
      ACCUMULATED,
      PROJECTED,
      TOTAL,
      ...arrayOfDates,
    ]

    const filteredRows = orderedRows.map(row => {
      const newRow = {};
      headersForFilterRows.forEach(key => {
        if (row.hasOwnProperty(key)) {
          newRow[key] = row[key];
        }
      });
      return newRow;
    });

    const columns = getColumns(arrayOfDates);
    const columnValues = columns.map((col) => col.key);
    const headers = columns.map((col) => col.header);

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(filteredRows, {
      header: columnValues,
    });

    try {
      headers.forEach((header) => {
        const columnName = columns.find((column) => column.header === header)?.key;
  
        if (columnName) {
          orderedRows.forEach((row, rowIndex) => {
            const cellAddress = xlsx.utils.encode_cell({
              r: rowIndex + 1,
              c: columns.findIndex((column) => column.key === columnName),
            }); 
  
            if (worksheet[cellAddress]) {
              const cellValue = row[columnName];
              worksheet[cellAddress].v = cellValue;
  
              if (typeof cellValue === 'number') {
                worksheet[cellAddress].t = 'n';
                worksheet[cellAddress].z = '"$"#,##0;[Red]-"$"#,##0';
              }
            }
          });
        }
      });
  
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Flujo');
  
      xlsx.writeFile(workbook, 'Flujo De Caja Total.xlsx');

      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error al descargar el Excel', {
        variant: 'error',
      });
    }
  };

  return {
    downloadExcel,
  };
};

export default useDownloadTotalCashFlow;
