import { Plugin, Template } from '@devexpress/dx-react-core';
import { Box } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import Typography from '../../../../shared/components/Typography/Typography';

const pluginDependencies = [{ name: 'Toolbar' }];

interface ContractEvalproGridToolbarProps {
  totalSales: number;
}
const ContractsEvalproGridToolbar: React.FC<ContractEvalproGridToolbarProps> = ({ totalSales }) => {
  return (
    <Plugin name="ContractsEvalproGridToolbar" dependencies={pluginDependencies}>
      <Template name="toolbarContent">
        <Box paddingY={2}>
          <Box pb={1}>
            <Typography variant="subtitle1" color="gray900">
              Ventas
            </Typography>
          </Box>
          <Typography variant="headline6" color="gray700" fontWeight="700">
            <NumberFormat
              displayType="text"
              value={totalSales}
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              prefix="$"
            />
          </Typography>
        </Box>
      </Template>
    </Plugin>
  );
};

export default ContractsEvalproGridToolbar;
