import React, { ElementRef, FC, RefObject } from 'react';
import { Box } from '@material-ui/core';

import AsyncConfirmModal from '../../../../../shared/components/AsyncModal/AsyncConfirmModal';
import Typography from '../../../../../shared/components/Typography/Typography';

interface IContractsLotsConfirmModal {
  asyncConfirmDeleteContractModalRef: RefObject<ElementRef<typeof AsyncConfirmModal>>;
}

const ContractsLotsDeleteConfirmModal: FC<IContractsLotsConfirmModal> = ({ asyncConfirmDeleteContractModalRef }) => {
  return (
    <AsyncConfirmModal ref={asyncConfirmDeleteContractModalRef} open={true} acceptText="Eliminar">
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        <Box marginTop={2}>
          <Typography variant="headline5">¿Eliminar lote?</Typography>
        </Box>
        <Box marginY={2}>
          <Box>
            <Typography variant="body1">¿Seguro que deseas eliminar este lote?</Typography>
          </Box>
        </Box>
      </Box>
    </AsyncConfirmModal>
  );
};

export default ContractsLotsDeleteConfirmModal;
