import { useEffect, useState } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { Column, IntegratedSummary, SummaryItem, Table } from '@devexpress/dx-react-grid';
import { sample, find } from 'lodash';

import { rightAlign } from '../../../../shared/tables/TableColumnFormat';

import { ContractsFlow } from '../../../../models/Contracts';
import VirtualTableDataTypeProviderColumn from 'shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from 'shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

const defaultColumns: ColumnType[] = [
  {
    title: ' ',
    name: 'name',
    columnName: 'name',
    type: ProviderColumnType.tooltip,
    width: 300,
  },
  {
    title: 'Acumulado',
    name: 'amountInvoiced',
    columnName: 'amountInvoiced',
    type: ProviderColumnType.currency,
    width: 155,
    align: 'right',
  },
  {
    title: 'Proyectado',
    name: 'amountProjected',
    columnName: 'amountProjected',
    type: ProviderColumnType.currency,
    width: 155,
    align: 'right',
  },
  {
    title: 'Total',
    name: 'amount',
    columnName: 'amount',
    type: ProviderColumnType.currency,
    width: 155,
    align: 'right',
  },
];

const getDateColumns = <T extends object>(sampleRow: T): ColumnType[] => {
  const keys = Object.keys(sampleRow) as Array<Extract<keyof typeof sampleRow, string>>;

  return keys.reduce((acc: ColumnType[], col: string) => {
    const defaultColumn = find(defaultColumns, (o: ColumnType) => o.name === col);
    if (defaultColumn === undefined && !['id', 'parentId'].includes(col)) {
      acc.push({
        name: col,
        title: col,
        columnName: col,
        type: ProviderColumnType.currency,
        align: 'right',
        width: 150,
      });
    }

    return acc;
  }, []);
};

const getSummaryColumns = (currencyCols: string[]): SummaryItem[] => {
  const summaryColumns: SummaryItem[] = [{ columnName: 'name', type: 'title' }];

  let dateColumns: SummaryItem[] = [];

  if (!!currencyCols) {
    dateColumns = currencyCols.map((columnName) => ({ columnName, type: 'sum' }));
  }

  return summaryColumns.concat(dateColumns);
};

const getCurrencyColumns = <T extends object>(sampleRow: T): string[] => {
  const currencyColumns = ['amountProjected', 'amountInvoiced', 'amount'];
  let dateColumns: string[] = [];

  if (!!sampleRow) {
    const dates = getDateColumns(sampleRow);
    dateColumns = dates.map((el) => el.name);
  }

  return currencyColumns.concat(dateColumns);
};

const getColumns = <T extends object>(sampleRow: T): ColumnType[] => {
  if (!!sampleRow) {
    const dateColumns = getDateColumns(sampleRow);

    return defaultColumns.concat(dateColumns);
  }
  return defaultColumns;
};

type HookType = () => {
  rows: ContractsFlow[] | undefined;
  columns: ColumnType[];
  totalSummarySchedules: SummaryItem[];
  fixedColumns: string[];
  getChildRows: (row: any, rootRows: any) => Array<any> | null;
  summaryCalculator: (
    type: string,
    rows: ContractsFlow[],
    getValue: (row: ContractsFlow) => ContractsFlow,
  ) => number | string | undefined;
};

const useColumnsAndRowsForContractsFlow: HookType = () => {
  const [columns, setColumns] = useState<ColumnType[]>([]);

  const [totalSummarySchedules, setTotalSummarySchedules] = useState<SummaryItem[]>([]);

  const [fixedColumns] = useState(['name', 'amountProjected', 'amountInvoiced', 'amount']);

  const queryClient: QueryClient = useQueryClient();

  const rows = queryClient.getQueryData<ContractsFlow[]>('contracts-flow');

  useEffect(() => {
    const sampleRow = sample(rows);

    const newColumns = getColumns(sampleRow);
    const currencyCols = getCurrencyColumns(sampleRow);
    const summaryCols = getSummaryColumns(currencyCols);

    setTotalSummarySchedules(summaryCols);
    setColumns(newColumns);
  }, [rows]);

  const getChildRows = (row: any, rootRows: any): Array<any> | null => {
    const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.id : null));
    return childRows.length ? childRows : null;
  };

  const summaryCalculator = (type: string, rows: ContractsFlow[], getValue: (row: ContractsFlow) => ContractsFlow) => {
    const summaryRows = rows.filter((row: ContractsFlow) => row.parentId === null);

    if (type === 'title') {
      if (!summaryRows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', summaryRows, getValue);
  };

  return {
    rows,
    columns,
    totalSummarySchedules,
    fixedColumns,
    getChildRows,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractsFlow;
