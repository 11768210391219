import { MouseEvent, useState } from 'react';

const useAreaPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'AreaTablePopover' : undefined;

  const close = () => setAnchorEl(undefined);

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return {
    id,
    isOpen,
    anchorEl,
    close,
    handlePopoverClick,
  };
};

export default useAreaPopover;
