import React from 'react';
import { Formik, Form, Field } from 'formik';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import StrategySelect from './StrategySelect';
import Input, { PERCENTAGE } from '../../../StrategyV2/Shared/Input/index';
import Numbers from '../../../../helpers/numbers';

const SalesWizard = ({
  open,
  rotationMonths,
  suggestedIncrement,
  handleSubmit,
  validationSchema,
  units,
  closeHandler,
  handleApplyIncrement,
  calculated,
  groupId = null,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Asistente de incrementos</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ingrese la tasa efectiva anual y el tipo de estrategia a la que quiere
          apuntar, el asistente le dara una sugerencia del incremento.
        </DialogContentText>
        <Box mb={2}>
          <Grid container justify="space-between" mb={2}>
            <Grid item>
              <Typography>Rotación de inventario: {rotationMonths}</Typography>
              {rotationMonths <= 0 && (
                <Alert severity="warning">
                  La rotación es 0 por lo que no se puede calcular
                </Alert>
              )}
            </Grid>
            <Grid item>
              <Typography>
                Incremento sugerido:
                <NumberFormat
                  value={Numbers.toFixed(suggestedIncrement)}
                  displayType="text"
                  prefix="$"
                  thousandSeparator
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Formik
          initialValues={{
            groupId,
            ear: null,
            frequency: 1,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isValid }) => (
            <Form>
              <Box mb={2}>
                <Field
                  name="ear"
                  label="Tasa efectiva anual"
                  placeholder="5.3%"
                  mask={PERCENTAGE}
                  component={Input}
                  fullWidth
                />
              </Box>
              <Box mb={2}>
                <Field
                  name="frequency"
                  units={units}
                  component={StrategySelect}
                />
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid || rotationMonths <= 0}
              >
                Calcular
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeHandler}>
          Cerrar
        </Button>
        <Button
          color="primary"
          onClick={handleApplyIncrement}
          disabled={!calculated}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SalesWizard;
