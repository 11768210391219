import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import stateType from './stateType.enum';
import Styles from './HoverContainer.module.scss';
import RemoveDialog from './removeDialog';

export const options = {
  EDIT: 'e',
  DELETE: 'd',
};

const HoverContainer = ({
  children,
  updateHandler,
  updateAllHandler,
  removeClientHandler,
  option,
  status,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const removeArea = () => {
    removeClientHandler();
    handleClose();
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.noHover}>{children}</div>
      <div className={Styles.hover}>
        {status !== 'AVAILABLE' ? (
          <>
            {option === options.EDIT && (
              <Button
                className={Styles.button}
                onClick={updateHandler}
                color="primary"
                variant="contained"
                disabled={status === stateType.Available.code}
              >
                <i className="fas fa-edit"></i>
              </Button>
            )}
            {option === options.DELETE && (
              <>
                <Button
                  className={Styles.button}
                  onClick={updateAllHandler}
                  color="primary"
                  variant="contained"
                  disabled={status === stateType.Available.code}
                >
                  <i className="fas fa-edit"></i>
                </Button>
                <Button
                  className={Styles.button}
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  color="secondary"
                  disabled={status === stateType.Available.code}
                >
                  <i className="fas fa-trash-alt"></i>
                </Button>
              </>
            )}
          </>
        ) : (
          <span>No disponible</span>
        )}
      </div>
      <RemoveDialog
        isOpen={open}
        removeArea={removeArea}
        handleClose={handleClose}
      />
    </div>
  );
};

HoverContainer.propTypes = {
  children: PropTypes.node,
  updateHandler: PropTypes.func.isRequired,
  option: PropTypes.oneOf(Object.values(options)),
  removeClientHandler: PropTypes.func.isRequired,
  status: PropTypes.string,
  edition: PropTypes.bool.isRequired,
};

export default HoverContainer;
