import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Report({ report, columns }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2" component="span" gutterBottom>
          {report.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              {columns.map((column, index) => (
                <TableCell key={`table-head-with-index-${index}`}>
                  {column.title}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {report.content.map((content, index) => (
                <TableRow key={`table-row-with-index-${index}`}>
                  <TableCell>{content.title}</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>{content.quotations}</TableCell>
                  <TableCell>{content.optional}</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>{content.solds}</TableCell>
                  <TableCell>{content.desists}</TableCell>
                  <TableCell>{content.salesAccumulated}</TableCell>
                  <TableCell>{content.availableInventory}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

Report.propTypes = {
  report: PropTypes.object,
  columns: PropTypes.array,
};
