import { Contract } from '../../../../models/Contracts';

export interface UseContractPaymentScheduleGridInterestPopoverActionsProps {
  handleActionRestartProjectedClicked: (contract: Contract) => void;
}

interface UseContractPaymentScheduleGridPopoverActionsParams {
  contract?: Contract;
  extraProps: UseContractPaymentScheduleGridInterestPopoverActionsProps;
  cb: () => void;
}

type HookType = (params: UseContractPaymentScheduleGridPopoverActionsParams) => {
  restartProjected: () => void;
};

const useContractPaymentScheduleGridInterestPopoverActions: HookType = ({ contract, extraProps, cb }) => {
  const restartProjected = () => {
    if (contract) extraProps.handleActionRestartProjectedClicked(contract);
    cb();
  };

  return {
    restartProjected,
  };
};

export default useContractPaymentScheduleGridInterestPopoverActions;
