import React, { FC } from 'react';
import QuotationSummaryHeaderButtons from './customViews/QuotationSummaryHeaderButtons';
import QuotationSummaryHeaderDescription from './customViews/QuotationSummaryHeaderDescription';
import QuotationSummaryTable from './customViews/QuotationSummaryTable';
import Styles from './QuotationSummaryMainView.module.scss';
import useQuotationSummaryLoader from '../Core/customHooks/useQuotationSummaryLoader';

interface QuotationSummaryMainViewProps {
  propertyId: string;
}

const QuotationSummaryMainView: FC<QuotationSummaryMainViewProps> = ({
  propertyId,
}) => {
  const { hub, payments } = useQuotationSummaryLoader(propertyId);

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <QuotationSummaryHeaderButtons
          propertyId={propertyId}
          hub={hub}
          payments={payments}
        />
        <QuotationSummaryHeaderDescription hub={hub} />
        <QuotationSummaryTable payments={payments} />
      </div>
    </div>
  );
};

export default QuotationSummaryMainView;
