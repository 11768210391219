import React, { FC } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import { ClientInformationProps } from '../../../Core/interfaces/ClientInformationProps';
import ClientAddressModal from 'App/ClientAddressModal/UI/modal/ClientAddressModal';
import useModal from 'shared/customHooks/useModal';
import ClientAddressType from 'App/ClientAddressModal/Core/models/ClientAddressType';
import useClientInformationForm from 'App/Clients/Core/customHooks/useClientInformationForm';
import AddressField from '../../Fields/AddressField';
import ClientDateField from '../../Fields/ClientDateField';
import civilStatus from 'App/AddClientModal/UI/Views/rows/civilStatus.enum';
import NumberFormat from 'react-number-format';

const AdditionalInformation: FC<ClientInformationProps> = (props) => {
  const {
    data,
    errors,
    addressDescription,
    address,
    onChangeType,
    updateAddress,
    onFinishAddressEdition,
    handleChange,
    handleChangeSelect,
    handleChangeDate,
    handleSubmit,
    handleChangeValue,
  } = useClientInformationForm(props, 'residenceAddress');

  const { isOpen, open, close } = useModal({
    handleClose: onFinishAddressEdition,
  });

  return (
    <>
      {props.title && <Typography variant="h5">{props.title}</Typography>}
      <form onSubmit={handleSubmit} id={props.formId} />
      <Box display="flex" justifyContent="center" marginY={4}>
        <Grid container item md={8} spacing={2}>
          <Grid item md={6}>
            <FormControl fullWidth error={!!errors?.civilStatus}>
              <InputLabel id="civilStatus-label">Estado civil</InputLabel>
              <Select
                variant="outlined"
                labelId="civilStatus-label"
                id="civilStatusId"
                defaultValue={data.civilStatus || null}
                value={data?.civilStatus || null}
                label="Estado civil"
                onChange={handleChangeSelect('civilStatus')}
              >
                {Object.values(civilStatus).map((item) => (
                  <MenuItem value={item.code} key={item.code}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors?.civilStatus}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <ClientDateField
              datePickerProps={{
                value: data.dateOfBirth || null,
                format: 'DD/MM/YYYY',
                onChange: (date) => {
                  handleChangeDate('dateOfBirth')(date?.valueOf() || null);
                },
              }}
              textFieldProps={{
                className: 'AdditionalInformation__Address',
                label: 'Fecha de nacimiento',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Lugar de expedición de la cédula"
              value={data.placeOfIssuanceId || ''}
              onChange={handleChange('placeOfIssuanceId')}
              helperText={errors.placeOfIssuanceId}
              error={!!errors.placeOfIssuanceId}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="País de residencia"
              value={data.countryOfResidence || ''}
              onChange={handleChange('countryOfResidence')}
              helperText={errors.countryOfResidence}
              error={!!errors.countryOfResidence}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Departamento"
              value={data.departmentOfResidence || ''}
              onChange={handleChange('departmentOfResidence')}
              helperText={errors.departmentOfResidence}
              error={!!errors.departmentOfResidence}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Ciudad"
              value={data.cityOfResidence || ''}
              onChange={handleChange('cityOfResidence')}
              helperText={errors.cityOfResidence}
              error={!!errors.cityOfResidence}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              customInput={TextField}
              defaultValue={data.mobileNumber || ''}
              value={data.mobileNumber || ''}
              onValueChange={(values) => handleChangeValue('mobileNumber', values.value)}
              // @ts-ignore
              fullWidth
              label="Segundo teléfono"
              variant="outlined"
              helperText={errors.mobileNumber}
              error={!!errors.mobileNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressField
              fullWidth
              label="Dirrección"
              value={data.residenceAddress || ''}
              onClick={open}
              helperText={errors.residenceAddress}
              error={!!errors.residenceAddress}
            />
          </Grid>
        </Grid>

        <ClientAddressModal
          isOpen={isOpen}
          onClose={close}
          addressDescription={addressDescription || ''}
          currentType={address?.addressType || ClientAddressType.OPEN}
          onChangeType={onChangeType}
          update={updateAddress}
          clientAddress={address}
          onAccept={close}
        />
      </Box>
    </>
  );
};

export default AdditionalInformation;
