import React, { useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Draggable } from 'react-beautiful-dnd';
import Numbers from '../../../../../helpers/numbers';
import AddAdditional from './addAdditional';
import SalesWizard from '../../../../../components/Increments/IncrementTable/SalesWizard';
import CustomInput from './customInput';
import { TotalSalesByEvalproSalesGroup } from '../../../Core/utils/TotalSalesByEvalproSalesGroup';

const Group = ({
  group,
  setDeleteButton,
  isDeleteButtonAvailable,
  updateGroup,
  onlyIntegerFormat,
  destroyGroup,
  currencyFormat,
  openAddAdditionalHandler,
  findSalable,
  updateAdditional,
  findNonSalable,
  setModalOpen,
  isModalOpen,
  closehandler,
  numberFormat,
  handleSubmit,
  suggestedIncrement,
  calculated,
  addAdditionalModalOpen,
  closeAddAdditionalHandler,
  percentageFormat,
  isSalable,
  frequency,
  index,
}) => {
  const [activeSalable, setActiveSalable] = useState(
    group.salableAdditionals.length > 0
      ? group.salableAdditionals[0].additionalId
      : 0,
  );
  const [activeNonSalable, setActiveNonSalable] = useState(
    group.nonSalableAdditionals.length > 0
      ? group.nonSalableAdditionals[0].additionalId
      : 0,
  );
  const [errorText, setErrorText] = useState('');

  const [isDisabled, setDisabled] = useState(false);

  return (
    // <Draggable index={index} draggableId={group.id}>
    //   {(provided, snapshot) => (
    <Grid key={group.id} item direction="column">
      <Grid
        onMouseEnter={() => setDeleteButton(group.id)}
        onMouseLeave={() => setDeleteButton(0)}
      >
        {isDeleteButtonAvailable === group.id ? (
          <Chip
            label="Eliminar grupo"
            onClick={() => destroyGroup(group)}
            color="secondary"
            align="center"
          />
        ) : (
          <Typography variant={'h6'} component="div" align="center">
            {group.name}
          </Typography>
        )}
      </Grid>
      <Grid>
        <TextField
          id={`group-units-${group.id}`}
          defaultValue={group.units}
          onBlur={(e) =>
            updateGroup(e, 'units', group, 0, group.units, group.tower.id)
          }
          InputProps={{
            inputComponent: onlyIntegerFormat,
          }}
        ></TextField>
      </Grid>
      <Grid>
        <TextField
          id={`group-averageM2-${group.id}`}
          defaultValue={group.averageM2}
          onBlur={(e) =>
            updateGroup(
              e,
              'averageM2',
              group,
              0,
              group.averageM2,
              group.tower.id,
            )
          }
          type={'number'}
        ></TextField>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography
            id={`group-salableM2-${group.id}`}
            component="div"
            variant={'subtitle1'}
          >
            {Numbers.toFixed(group.units * group.averageM2, 0)} m²
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <TextField
          id={`group-valueM2-${group.id}`}
          defaultValue={group.valueM2}
          onBlur={(e) => {
            updateGroup(e, 'valueM2', group, 0, group.valueM2, group.tower.id);
          }}
          InputProps={{
            inputComponent: currencyFormat,
          }}
        ></TextField>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-averagePriceUnit-${group.id}`} component="div">
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={Numbers.toFixed(group.averageM2 * group.valueM2, 0)}
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-averagePriceUnit-${group.id}`} component="div">
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={Numbers.toFixed(
                  group.averageM2 * group.valueM2 * group.units,
                  0,
                )}
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-increment-${group.id}`} component="div">
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={Numbers.toFixed(group.increment, 0)}
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-totalUnit-${group.id}`} component="div">
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={Numbers.toFixed(
                  group.averageM2 * group.valueM2 * group.units +
                    group.increment,
                  0,
                )}
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-total-Additional-${group.id}`} component="div">
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={group.salableAdditionals.reduce(
                  (current, additional) =>
                    current + additional.quantity * additional.price,
                  0,
                )}
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-total-${group.id}`} component="div">
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={TotalSalesByEvalproSalesGroup(group)}
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={1}>
          <Chip
            label="Agregar vendible"
            onClick={() => openAddAdditionalHandler(group.id, true)}
            color="primary"
          />
        </Box>
      </Grid>
      <Grid>
        <FormControl>
          <Select
            id={`salableAdditional-type-${group.id}`}
            value={
              findSalable(group.salableAdditionals, activeSalable).additionalId
            }
            onChange={(e) => {
              setActiveSalable(e.target.value);
            }}
          >
            {group.salableAdditionals.map((additional) => (
              <MenuItem
                value={`${additional.additionalId}`}
                key={`${additional.name}`}
              >
                {additional.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <FormControl>
          <Select
            id={`salableAdditional-type-${group.id}`}
            value={findSalable(group.salableAdditionals, activeSalable).type}
            onChange={(e) => {
              updateAdditional(e, 'type', group, true, activeSalable);
            }}
          >
            <MenuItem value={'M2'}>M2</MenuItem>
            <MenuItem value={'UNT'}>Unidades</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <CustomInput
          id={`salableAdditional-quantity-${group.id}`}
          defaultValueP={
            findSalable(group.salableAdditionals, activeSalable).quantity
          }
          onBlur={(value) =>
            updateAdditional(
              { target: { value } },
              'quantity',
              group,
              true,
              activeSalable,
            )
          }
        />
      </Grid>
      <Grid>
        <CustomInput
          id={`salableAdditional-price-${group.id}`}
          defaultValueP={
            findSalable(group.salableAdditionals, activeSalable).price
          }
          onBlur={(value) =>
            updateAdditional(
              { target: { value } },
              'price',
              group,
              true,
              activeSalable,
            )
          }
          prefix={'$'}
        />
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`salableAdditional-total-${group.id}`}>
            {
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="$"
                value={
                  findSalable(group.salableAdditionals, activeSalable)
                    .quantity *
                  findSalable(group.salableAdditionals, activeSalable).price
                }
              />
            }
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={1}>
          <Chip
            label="Agregar no vendible"
            onClick={() => openAddAdditionalHandler(group.id, false)}
            color="primary"
          />
        </Box>
      </Grid>
      <Grid>
        <FormControl>
          <Select
            id={`salableAdditional-type-${group.id}`}
            value={
              findNonSalable(group.nonSalableAdditionals, activeNonSalable)
                .additionalId
            }
            onChange={(e) => {
              setActiveNonSalable(e.target.value);
            }}
          >
            {group.nonSalableAdditionals.map((additional) => (
              <MenuItem
                value={`${additional.additionalId}`}
                key={`${additional.name}`}
              >
                {additional.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <FormControl>
          <Select
            id={`nonSalableAdditional-type-${group.id}`}
            value={
              findNonSalable(group.nonSalableAdditionals, activeNonSalable).type
            }
            onChange={(e) => {
              updateAdditional(e, 'type', group, false, activeNonSalable);
            }}
          >
            <MenuItem value={'M2'}>M2</MenuItem>
            <MenuItem value={'UNT'}>Unidades</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <CustomInput
          id={`nonSalableAdditional-quantity-${group.id}`}
          defaultValueP={
            findNonSalable(group.nonSalableAdditionals, activeNonSalable)
              .quantity
          }
          onBlur={(value) =>
            updateAdditional(
              { target: { value } },
              'quantity',
              group,
              false,
              activeNonSalable,
            )
          }
        />
      </Grid>
      <Typography variant={'h6'} component="div" align="center">
        {' '}
      </Typography>
      <Grid>
        <TextField
          id={`rotation-salesSpeed-${group.id}`}
          defaultValue={group.salesSpeed}
          onBlur={(e) => {
            const { value } = e.target;
            if (group.units / value < 100) {
              setErrorText('');
              return updateGroup(
                e,
                'salesSpeed',
                group,
                0,
                group.salesSpeed,
                group.tower.id,
              );
            }
            setErrorText('Error');
          }}
          error={errorText !== ''}
          helperText={
            errorText !== ''
              ? `La velocidad de ventas debe ser mayor a ${Numbers.toFixed(
                  99 / group.units,
                  2,
                )}`
              : null
          }
          type={'number'}
        ></TextField>
      </Grid>
      <Grid>
        <TextField
          id={`rotation-firstSaleDeadline-${group.id}`}
          defaultValue={group.firstSaleDeadline}
          onBlur={(e) =>
            updateGroup(
              e,
              'firstSaleDeadline',
              group,
              0,
              group.firstSaleDeadline,
              group.tower.id,
            )
          }
          type={'number'}
        ></TextField>
      </Grid>
      <Typography variant={'h6'} component="div" align="center">
        {' '}
      </Typography>
      <Grid>
        <FormControl>
          <Select
            id={`priceList-stratum-${group.id}`}
            value={group.stratum}
            onChange={(e) =>
              updateGroup(e, 'stratum', group, 1, group.stratum, group.tower.id)
            }
          >
            <MenuItem value={'LOW'}>Vivienda de interes social</MenuItem>
            <MenuItem value={'MED'}>Medio</MenuItem>
            <MenuItem value={'MDH'}>Medio-Alto</MenuItem>
            <MenuItem value={'HGH'}>Alto</MenuItem>
            <MenuItem value={'LIN'}>Lineal</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <FormControl>
          <Select
            id={`priceList-frequency-${group.id}`}
            value={group.frequency}
            onChange={(e) =>
              updateGroup(
                e,
                'frequency',
                group,
                1,
                group.frequency,
                group.tower.id,
              )
            }
          >
            <MenuItem value={'1'}>(1) Continua</MenuItem>
            <MenuItem value={'3'}>(3) Semi-continua</MenuItem>
            <MenuItem value={'9'}>(9) Semi-escalonada </MenuItem>
            <MenuItem value={'18'}>(18) Escalonada</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <TextField
          id={`priceList-increment-${group.id}`}
          defaultValue={Numbers.toFixed(group.increment, 0)}
          onBlur={(e) =>
            updateGroup(
              e,
              'increment',
              group,
              0,
              group.increment,
              group.tower.id,
            )
          }
          InputProps={{
            inputComponent: currencyFormat,
          }}
        ></TextField>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`priceList-EA-${group.id}`}>
            {Numbers.toFixed(group.EA)}%
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Chip
          label="Abrir"
          onClick={() => setModalOpen(group.id)}
          color="primary"
        />
        <SalesWizard
          open={isModalOpen === group.id}
          closeHandler={closehandler}
          rotationMonths={group.rotationMonths}
          handleSubmit={handleSubmit}
          suggestedIncrement={suggestedIncrement}
          calculated={calculated}
          handleApplyIncrement={() => {
            updateGroup(
              { target: { value: suggestedIncrement } },
              'increment',
              group,
              0,
              group.increment,
              group.tower.id,
            );
            updateGroup(
              { target: { value: frequency } },
              'frequency',
              group,
              1,
              group.frequency,
              group.tower.id,
            );
            closehandler();
          }}
          groupId={group.id}
        />
      </Grid>
      <Typography variant={'h6'} component="div" align="center">
        {' '}
      </Typography>
      <Grid>
        <TextField
          id={`group-separation-${group.id}`}
          defaultValue={Numbers.toFixed(group.separation, 0)}
          onBlur={(e) =>
            updateGroup(
              e,
              'separation',
              group,
              0,
              group.separation,
              group.tower.id,
            )
          }
          InputProps={{
            inputComponent: currencyFormat,
          }}
        ></TextField>
      </Grid>
      <Grid>
        <TextField
          id={`group-initialFee-${group.id}`}
          defaultValue={group.initialFee}
          onBlur={(e) =>
            updateGroup(
              e,
              'initialFee',
              group,
              0,
              group.initialFee,
              group.tower.id,
            )
          }
          InputProps={{
            inputComponent: percentageFormat,
          }}
        ></TextField>
      </Grid>
      <Grid>
        <Box mt={0.7} mb={0.3}>
          <Typography id={`group-credit-${group.id}`}>
            {100 - group.initialFee}%
          </Typography>
        </Box>
      </Grid>
      <AddAdditional
        open={addAdditionalModalOpen}
        closeHandler={closeAddAdditionalHandler}
        disabled={isDisabled}
        handleSubmit={async (e) => {
          setDisabled(true);
          await updateAdditional(
            { target: { value: e.name } },
            'name',
            group,
            isSalable,
            isSalable ? activeSalable : activeNonSalable,
          );

          closeAddAdditionalHandler();
          setTimeout(() => setDisabled(false), 2000);
        }}
        groupId={group.id}
      ></AddAdditional>
    </Grid>
    // )}
    // </Draggable>
  );
};

export default Group;
