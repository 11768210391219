import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  Grid,
  TableFixedColumns,
  TableHeaderRow,
  TableSummaryRow,
  TableTreeColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import { TreeDataState, CustomTreeData, SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';

import VirtualTableContainer from '../../../../../shared/tables/VirtualTableContainer';
import { CondensedTableHeaderRowCell } from '../../../../../shared/tables/CondensedTable';
import TableSummaryRowCell from '../../../../../shared/tables/TableSummaryRowCell';
import VirtualTableDataTypeProvider from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import TableCell from '../../../../../shared/tables/TableCell';

import useColumnsAndRowsForContractsFlow from '../../../Core/customHooks/useColumnsAndRowsForContractsFlow';
import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import ContractsGridSummaryItemComponent from './ContractsGridSummaryItemComponent';

interface IContractsFlowGrid {
  isLoading: boolean;
}

const ContractsFlowGrid: FC<IContractsFlowGrid> = ({ isLoading }) => {
  const { rows, columns, fixedColumns, getChildRows, totalSummarySchedules, summaryCalculator } =
    useColumnsAndRowsForContractsFlow();

  return (
    <Box>
      {!!rows ? (
        <Box>
          <Grid rows={rows} columns={columns}>
            <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.tooltip} />
            <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
            <TreeDataState />
            <SummaryState totalItems={totalSummarySchedules} />
            <CustomTreeData getChildRows={getChildRows} />
            <IntegratedSummary calculator={summaryCalculator} />

            <VirtualTable
              columnExtensions={columns}
              containerComponent={VirtualTableContainer}
              cellComponent={TableCell}
              height="80vh"
            />
            <TableHeaderRow cellComponent={CondensedTableHeaderRowCell} />
            <TableTreeColumn for="name" />
            <TableSummaryRow
              totalCellComponent={TableSummaryRowCell}
              itemComponent={ContractsGridSummaryItemComponent}
            />
            <TableFixedColumns leftColumns={fixedColumns} />
          </Grid>
        </Box>
      ) : (
        <AbsoluteCircularProgress />
      )}
    </Box>
  );
};

export default ContractsFlowGrid;
