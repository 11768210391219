import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { AreaActionsProps } from './AreaActionsProps';

const AreaActions: FC<AreaActionsProps> = (props) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {props.children}
    </Box>
  );
};

export default AreaActions;
