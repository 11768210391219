import { useState, useEffect } from 'react';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { sortBy, pick, omit } from 'lodash';

import Services from '../../../../services/ContractPayments/ContractPaymentsServices';
import ContractPaymentType from 'App/ContractPayments/Core/types/contractPaymentType';

import { IUseFormResult, useForm } from '../../../../shared/customHooks/useForm';
import {
  PaymentCycle,
  PaymentProjection,
  ContractPayment,
  MeasurementUnit,
  ForeignData,
  ContractPaymentSchedule,
} from '../../../../models/ContractPayments';

import { ForeignData as ContractForeignData, TowerStatistics } from '../../../../models/Contracts';

const services = new Services();

const useAddContractPayment = (handleCreate: (contractPayment: ContractPayment) => void, currentContractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractPayment: ContractPayment) =>
      services.createContractPayment(currentContractId, {
        ...contractPayment,
        contractId: currentContractId,
      }),
    {
      onSuccess: (data) => {
        handleCreate(data.data);
        queryClient.invalidateQueries('contracts');
        queryClient.invalidateQueries(['contract', currentContractId]);
        queryClient.invalidateQueries(['contract-payments', currentContractId]);
      },
    },
  );
};

const useEditContractPayment = (contractPaymentId: number, currentContractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (contractPayment: Partial<ContractPayment>) => {
      return services.editContractPayment(contractPaymentId, {
        ...contractPayment,
        contractId: currentContractId,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['contractPayment', contractPaymentId], data);
        queryClient.invalidateQueries('contracts');
        queryClient.invalidateQueries(['contract', currentContractId]);
        queryClient.invalidateQueries(['contract-payments', currentContractId]);
      },
    },
  );
};

export interface UseContractPaymentFormParams {
  currentContractId: number;
  contractPaymentType: string;
  currentContractPayment?: ContractPayment;
  handleCreate: (contractPayment: ContractPayment) => void;
  handleClose: () => void;
}

export interface UseContractPaymentFormResult extends IUseFormResult<ContractPayment> {
  firstContractPaymentSchedule?: ContractPaymentSchedule;
  paymentProjectionsList?: PaymentProjection[];
  paymentCyclesList?: PaymentCycle[];
  measurementUnits?: MeasurementUnit[];
  handleChangePaymentValues: <G>(key: keyof ContractPayment, value: G) => void;
  subtotal: number;
  total: number;
  contractPaymentTypeName: string;
}

type HookType = (param: UseContractPaymentFormParams) => UseContractPaymentFormResult;

const useContractPaymentForm: HookType = ({
  currentContractId,
  currentContractPayment,
  contractPaymentType,
  handleCreate,
  handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addContractPayment } = useAddContractPayment(handleCreate, currentContractId);

  const { mutateAsync: editContractPayment } = useEditContractPayment(
    currentContractPayment?.id || 0,
    currentContractId,
  );
  const [contractPaymentTypeName, setContractPaymentTypeName] = useState<string>('');

  useEffect(() => {
    form.handleChangeValue('type', `${contractPaymentType}`);
    if (contractPaymentType === 'PR') {
      form.handleChangeValue('projectionType', 'S');
    }
    const contractPaymentTypeDesc = ContractPaymentType.find((item) => item.id === contractPaymentType);
    setContractPaymentTypeName(contractPaymentTypeDesc?.name || '');
  }, [contractPaymentType]);

  const firstPaymentDateKeys = [
    'paymentDateType',
    'paymentDate',
    'scheduleId',
    'scheduleLabel',
    'eventId',
    'finalDate',
    'displacement',
    'event',
    'schedule',
    'scheduleLabelDescription',
  ];

  const queryClient: QueryClient = useQueryClient();

  const foreignListData = queryClient.getQueryData<ForeignData>('contract-payments-foreign-list');
  const contractForeignListData = queryClient.getQueryData<ContractForeignData>('contracts-foreign-list');

  const [paymentCyclesList, setPaymentCycles] = useState<PaymentCycle[]>();
  const [paymentProjectionsList, setPaymentProjections] = useState<PaymentProjection[]>();
  const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>();
  const [firstContractPaymentSchedule, setFirstContractPaymentSchedule] = useState<ContractPaymentSchedule>();

  const [towerStatistics, setTowerStatistics] = useState<TowerStatistics>();

  const [total, setTotal] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);

  useEffect(() => {
    if (!!contractForeignListData) {
      const { statistics } = contractForeignListData;
      setTowerStatistics(statistics);
    }
  }, [contractForeignListData]);
  useEffect(() => {
    if (!!foreignListData) {
      const { paymentCycles, paymentProjections, measurementUnits } = foreignListData as ForeignData;

      setPaymentCycles(paymentCycles);
      setPaymentProjections(paymentProjections);
      setMeasurementUnits(measurementUnits);
    }
    if (currentContractPayment) {
      updateTotals(currentContractPayment);

      const sorted = sortBy(currentContractPayment.contractPaymentSchedules, ['finalDate'], ['asc']);

      setFirstContractPaymentSchedule(sorted[0]);

      form.handleChangeData({
        paymentDate: sorted[0].paymentDate,
        scheduleId: sorted[0].scheduleId,
        scheduleLabel: sorted[0].scheduleLabel,
        eventId: sorted[0].eventId,
        finalDate: sorted[0].finalDate,
        displacement: sorted[0].displacement,
      });
    } else {
      setSubtotal(0);
      setTotal(0);
      setFirstContractPaymentSchedule({
        paymentDateType: 'MANUAL',
        id: 0,
        amount: 0,
        finalDate: 0,
        contractPaymentId: 0,
        paymentDate: 0,
        displacement: 0,
        percentage: 0,
      });
      form.handleChangeData({
        paymentDate: undefined,
        scheduleId: undefined,
        scheduleLabel: undefined,
        eventId: undefined,
        finalDate: undefined,
        displacement: undefined,
      });
    }
  }, [currentContractPayment, foreignListData]);

  const createContractPayment = async (contractPayment: ContractPayment): Promise<void> => {
    try {
      const firstPaymentDate = pick(contractPayment, firstPaymentDateKeys);
      let contractPaymentData = omit(contractPayment, firstPaymentDateKeys);
      if (contractPaymentType !== 'PR') {
        contractPaymentData = {
          ...contractPaymentData,
          firstPaymentDate: omit(firstPaymentDate, ['finalDate', 'event', 'schedule', 'scheduleLabelDescription']),
        };
      }
      await addContractPayment(contractPaymentData);
      enqueueSnackbar('Pago creado con éxito', {
        variant: 'success',
      });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
    handleClose();
  };

  const updateContractEvent = async (contractPayment: ContractPayment): Promise<void> => {
    if (currentContractPayment) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contractPayment).forEach((keyString) => {
        const key = keyString as keyof Partial<ContractPayment>;
        if (contractPayment[key] !== currentContractPayment[key]) updatedData[key] = contractPayment[key];
      });

      if (Object.keys(updatedData).length) {
        const data = {
          id: contractPayment.id,
          ...updatedData,
        };
        try {
          const firstPaymentDate = pick(data, firstPaymentDateKeys);
          let contractPaymentData = omit(data, firstPaymentDateKeys);

          if (contractPaymentType !== 'PR') {
            contractPaymentData = {
              ...contractPaymentData,
              firstPaymentDate: omit(firstPaymentDate, ['finalDate', 'event', 'schedule', 'scheduleLabelDescription']),
            };
          }

          await editContractPayment(contractPaymentData);

          enqueueSnackbar('Pago actualizado con éxito', {
            variant: 'success',
          });
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
    handleClose();
  };

  const form = useForm<ContractPayment>(
    {
      validations: {
        name: {
          required: {
            value: true,
            message: 'Nombre es requerido',
          },
        },
        qty: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.projectionType === 'M' ? value > 0 : true,
            message: 'Cant. es requerida',
          },
        },
        unitPrice: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.projectionType === 'M' ? value > 0 : true,
            message: 'Valor requerido!',
          },
        },
        percentage: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.projectionType !== 'M' ? value > 0 : true,
            message: '% es requerido!',
          },
        },
        installments: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.type !== 'PX' && data?.type !== 'PR' ? value > 0 : true,
            message: 'Número de cuotas es requerido!',
          },
        },
        paymentCycle: {
          custom: {
            isValid: (value: string, data?: ContractPayment | undefined) =>
              data?.type !== 'PX' && data?.type !== 'PR' ? !!value : true,
            message: 'periodicidad es requerida!',
          },
        },
        measurementUnitId: {
          custom: {
            isValid: (value: string, data?: ContractPayment | undefined) =>
              data?.projectionType === 'M' ? !!value : true,
            message: 'Unidad es requerida!',
          },
        },
        paymentDate: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.paymentDateType === 'MANUAL' && data?.type !== 'PR' ? value > 0 : true,
            message: 'Fecha es requerida!',
          },
        },
        scheduleId: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.paymentDateType === 'SCHEDULE' && data?.type !== 'PR' ? value > 0 : true,
            message: 'Evento de torre es requerido!',
          },
        },
        eventId: {
          custom: {
            isValid: (value: number, data?: ContractPayment | undefined) =>
              data?.paymentDateType === 'EVENT' && data?.type !== 'PR' ? value > 0 : true,
            message: 'Hito es requerido!',
          },
        },
      },
      initialValues: {
        id: currentContractPayment?.id || undefined,
        name: currentContractPayment?.name || undefined,
        qty: currentContractPayment?.qty || 0,
        percentage: currentContractPayment?.percentage || 0,
        tax: currentContractPayment?.tax || 0,
        installments: currentContractPayment?.installments || 0,
        unitPrice: currentContractPayment?.unitPrice || 0,
        measurementUnitId: currentContractPayment?.measurementUnitId || 'u',
        type: currentContractPayment?.type || contractPaymentType,
        projectionType: currentContractPayment?.projectionType || (contractPaymentType !== 'PR' ? 'M' : 'S'),
        paymentCycle: currentContractPayment?.paymentCycle || undefined,
        paymentDateType: firstContractPaymentSchedule?.paymentDateType || undefined,
        paymentDate: firstContractPaymentSchedule?.paymentDate || undefined,
        scheduleId: firstContractPaymentSchedule?.scheduleId || undefined,
        scheduleLabel: firstContractPaymentSchedule?.scheduleLabel || undefined,
        eventId: firstContractPaymentSchedule?.eventId || undefined,
        finalDate: firstContractPaymentSchedule?.finalDate || undefined,
        displacement: firstContractPaymentSchedule?.displacement || 0,
      },
      async onSubmit(newData, reset) {
        try {
          if (currentContractPayment?.id) await updateContractEvent(newData);
          else await createContractPayment(newData);
          reset();
        } catch (error) {
          enqueueSnackbar('Error interno servidor');
        }
      },
    },
    [currentContractPayment, firstContractPaymentSchedule, contractPaymentType],
  );

  const updateTotals = (formData: Partial<ContractPayment>) => {
    const { qty, tax, unitPrice, percentage, projectionType, type } = formData;

    let subtotal = Number(unitPrice) * (1 + Number(tax) / 100);
    let total = subtotal * Number(qty);
    if (type !== 'PC') {
      switch (projectionType) {
        case 'S':
          const totalSales = towerStatistics?.totalSales || 0;
          subtotal = !!percentage ? totalSales * (percentage / 100) : 0;
          total = subtotal * (1 + Number(tax) / 100);
          break;
        case 'D':
          const totalDirectCost = towerStatistics?.totalDirectCost || 0;
          subtotal = !!percentage ? totalDirectCost * (percentage / 100) : 0;
          total = subtotal * (1 + Number(tax) / 100);
          break;
        case 'M':
          subtotal = Number(unitPrice) * (1 + Number(tax) / 100);
          total = subtotal * Number(qty);
          break;
      }
    }

    setSubtotal(subtotal);
    setTotal(total);
  };

  const handleChangePaymentValues = <G>(key: keyof ContractPayment, value: G) => {
    const { data, handleChangeValue } = form;

    const newData = {
      ...data,
      [key]: value,
    };

    handleChangeValue(key, value);
    updateTotals(newData);
  };

  return {
    ...form,
    firstContractPaymentSchedule,
    paymentCyclesList,
    paymentProjectionsList,
    measurementUnits,
    handleChangePaymentValues,
    total,
    subtotal,
    contractPaymentTypeName,
  };
};

export default useContractPaymentForm;
