import React from 'react'
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Typography, Box } from '@material-ui/core';
import classes from '../../UI/styles/HeaderCustomCellForTotalCashFlow.module.scss';
import moment from 'moment';
import Icon from '../../../../shared/components/Icon/Icon'

const useCustomCellForHeader = ({title, ...restProps}) => {
  const projected = 'Proyectado';
  const accumulated = 'Real';
  const total = 'Total';
  const category = 'Categoría';
  const name = 'Nombre'

  const currentMonth = moment()
  const validation = moment(title, 'MMM YYYY').isBefore(currentMonth, 'M');


  if (title === name) {
    return (
      <VirtualTable.Cell {...restProps} className={classes.leftHeader}>
        <Typography variant="body2" className={classes.leftHeaderTypography}>
          {title}
        </Typography>
      </VirtualTable.Cell>
    )
  }

  if (title === projected) {
    return (
      <VirtualTable.Cell {...restProps} className={classes.headerCell}>
        <Box className={classes.projectCell}>
          <Typography variant="body2" className={classes.headerTypography}>
            {title}
          </Typography>
          <Icon variant="offline_bolat" width="100%" color="accent600"/>
        </Box>
      </VirtualTable.Cell>
    )
  }

  if (!validation && 
    title !== projected && 
    title !== accumulated && 
    title !== total &&
    title !== category
    ) {
    return (
      <VirtualTable.Cell {...restProps} className={classes.projectedHeaderCell} >
        <Typography variant="body2" className={classes.projectedHeaderTypography}>
          {title}
        </Typography>
      </VirtualTable.Cell>
    )
  }

  return (
    <VirtualTable.Cell {...restProps} className={classes.headerCell} >
      <Typography variant="body2" className={classes.headerTypography}>
        {title}
      </Typography>
    </VirtualTable.Cell>
  )
}

export default useCustomCellForHeader