import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import styles from './SegmentedControl.module.scss';

interface SegmentedControlProps {
  options: { label: string; value: string; disabled: boolean }[];
  switchState: string;
  onClick: Function;
}

const SegmentedControl: FC<SegmentedControlProps> = ({ options, onClick, switchState }) => {
  return (
    <Box className={`${styles.switch}`}>
      {options.map((option) => (
        <button
          className={`${styles.projectionButton} ${option.value === switchState ? styles.selected : ''} ${
            option.disabled ? styles.disabled : ''
          }`}
          disabled={option.disabled}
          onClick={() => {
            if (option.value !== switchState) onClick(option.value);
          }}
        >
          {option.label}
        </button>
      ))}
    </Box>
  );
};

export default SegmentedControl;
