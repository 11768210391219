import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, MuiThemeProvider, createTheme } from '@material-ui/core';
import {
  Grid,
  TableFixedColumns,
  TableHeaderRow,
  VirtualTable,
  TableBandHeader,
} from '@devexpress/dx-react-grid-material-ui';
import RowsCustomCell from '../rows/ProjectResultsStateCustomRow';
import BandCell from '../cells/BandCell/ProjectResultsStateBandCell';
import headerCustomCell from '../cells/headerCustomCell/ProjectResultsStateHeaderCustomCell';
import useColumnsForProjectResultsState from '../../../Core/customHooks/useColumnsForProjectResultsState';
import classes from './ProjectResultsStateTable.module.scss';

const theme = createTheme({ palette: { action: { selected: '#000000' } } });

const ProjectResultsStateTable = ({ projectResultsStateRows }) => {
  const { rows } = projectResultsStateRows && projectResultsStateRows;

  const { columns, columnBands, leftColumns, tableColumnExtensions } =
    useColumnsForProjectResultsState(projectResultsStateRows);

  return (
    <Box className={classes.projectResultsStateTable}>
      {rows && (
        <MuiThemeProvider theme={theme}>
          <Grid rows={rows} columns={columns}>
            <VirtualTable
              cellComponent={RowsCustomCell}
              columnExtensions={tableColumnExtensions}
              height="800px"
            />
            <TableHeaderRow cellComponent={headerCustomCell} />
            <TableBandHeader
              columnBands={columnBands}
              cellComponent={BandCell}
            />
            <TableFixedColumns leftColumns={leftColumns} />
          </Grid>
        </MuiThemeProvider>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { projectResultsStateRows } = state.projectResultsState.root;

  return {
    projectResultsStateRows,
  };
};

const mapDispatchToProps = {};
ProjectResultsStateTable.prototypes = {
  projectResultsStateRows: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectResultsStateTable);
