import { useEffect, useState } from 'react';

const useReducePropertyTypes = ({ propertyTypes, typeIds }) => {
  const [propertyTypesOptions, setPropertyTypesOptions] = useState([]);

  useEffect(() => {
    if (
      Array.isArray(propertyTypes) &&
      propertyTypes.length &&
      typeIds.length
    ) {
      setPropertyTypesOptions(
        typeIds.reduce((init, id) => {
          const type = propertyTypes.find((t) => t.id === id);
          if (type) init = init.concat(type);
          return init;
        }, []),
      );
    }
  }, [propertyTypes, typeIds]);

  return { propertyTypesOptions };
};

export default useReducePropertyTypes;
