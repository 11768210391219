import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import Context from '../../../../../Context';
import { DashboardRoutes, ProjectRoutes } from '../../../../../routes/local/routes';
import useBusinessPartner from '../../../Core/useBusinessPartner';

export default function CompanySelector() {
  const { loadSessionCompany, sessionCompany } = useContext(Context.Shared)
  const history = useHistory()
  const { selectComapany } = useBusinessPartner();

  useEffect(() => {
    const companyId = sessionCompany || loadSessionCompany()
    if (!companyId) return history.push(DashboardRoutes.base + ProjectRoutes.base)
    selectComapany(companyId)
  }, [])

  return (
    <></>
  );
}
