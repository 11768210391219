import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import {
  setEditedHeaders,
  setEditedVerticalHeaders,
} from '../../../Core/actions';
import styles from './styles.module.scss';

export default function InputHeader({ header, position, vertical = false }) {
  const { headers, verticalHeaders } = useSelector(
    (state) => state.buildingHeader,
  );
  const dispatch = useDispatch();
  const [error, setError] = useState({ active: false, message: '' });

  const handleChangeHeader = (e) => {
    if (e.target.value && e.target.value !== '') {
      const newHeaders = vertical ? [...verticalHeaders] : [...headers];
      newHeaders[position] = e.target.value;
      setError({
        active: false,
        message: '',
      });
      if (vertical) {
        dispatch(setEditedVerticalHeaders(newHeaders));
      } else {
        dispatch(setEditedHeaders(newHeaders));
      }
    } else {
      setError({
        active: true,
        message: 'No puedes dejar este campo vacio',
      });
    }
  };

  return (
    <TextField
      defaultValue={header}
      classes={{ root: styles.headerInput }}
      onChange={handleChangeHeader}
      error={error.active}
      helperText={error.message}
    />
  );
}

InputHeader.propTypes = {
  header: PropTypes.string,
  position: PropTypes.number,
  vertical: PropTypes.bool,
};
