import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import classes from '../../../styles/ResultsStateCustomCell.module.scss'

const TotalAndUtilityBeforeTaxPercentageCell = (props) => {
  const {...restProps} = props

  return (
    <VirtualTable.Cell {...restProps} className={classes.groupRow} />
  );
};

export default TotalAndUtilityBeforeTaxPercentageCell;