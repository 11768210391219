import {
  CHANGE_STATE_INTO_HEADER_EDITABLE,
  ON_SET_INITIAL_HEADERS,
  ON_SET_NEW_HEADERS,
  ON_SET_NEW_VERTICAL_HEADERS,
  ON_SET_INITIAL_VERTICAL_HEADERS,
} from './actions';

const initialState = {
  isHeaderEditable: false,
  headers: [],
  verticalHeaders: [],
  verticalLabels: [],
  labels: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_STATE_INTO_HEADER_EDITABLE:
      return { ...state, isHeaderEditable: !state.isHeaderEditable };

    case ON_SET_INITIAL_HEADERS:
      return { ...state, headers: payload, labels: payload };

    case ON_SET_INITIAL_VERTICAL_HEADERS:
      return { ...state, verticalHeaders: payload, verticalLabels: payload };

    case ON_SET_NEW_HEADERS:
      return { ...state, headers: payload, labels: payload };

    case ON_SET_NEW_VERTICAL_HEADERS:
      return { ...state, verticalHeaders: payload, verticalLabels: payload };

    default:
      return state;
  }
};
