import React, { FC } from 'react';
import { Menu, MenuItem, ListItemText, Divider } from '@material-ui/core';

import Styles from '../../styles/ContractListToolbar.module.scss';
import Typography from '../../../../../shared/components/Typography/Typography';

const ContractMenu: FC<{
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleNewContract: () => void;
}> = ({ anchorEl, handleClose, handleNewContract }) => {
  return (
    <Menu
      id="contract-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={1}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: Styles.contractMenu,
      }}
    >
      <MenuItem key="new-contract-menu-title" disabled>
        <Typography variant="button" fontWeight="600">
          Selecciona un tipo de contrato
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem
        key="new-manual-contract-menu-item"
        classes={{
          root: Styles.menuItem,
          selected: Styles.menuItemSelected,
        }}
        selected
        onClick={() => {
          handleClose();
          handleNewContract();
        }}
      >
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="button" fontWeight="600">
                Manual
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="caption">
                Distribuye el monto total del contrato manualmente en cada fecha.
              </Typography>
            </React.Fragment>
          }
        />
      </MenuItem>
      <Divider />
      <MenuItem
        key="new-recurrent-contract-menu-item"
        classes={{
          root: Styles.menuItem,
          selected: Styles.menuItemSelected,
        }}
        disabled={true}
      >
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="button" fontWeight="600">
                Recurrente
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="caption">
                Ingresa el monto por cuota y la cantidad de transacciones requeridas para el contrato.
              </Typography>
            </React.Fragment>
          }
        />
      </MenuItem>
    </Menu>
  );
};

export default ContractMenu;
