import React from 'react';
import { Typography, Box, TextField } from '@material-ui/core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classes from '../../../styles/ResultsStateCustomCell.module.scss';
import Numbers from '../../../../../../helpers/numbers';
import ReactTooltip from 'react-tooltip';
import usePreviousControl from '../../../../Core/customHooks/usePreviousControl';
import NumberFormatInput from './NumberFormatCell';

const PreviousControl = ({ cellData, divider, decimals, row, name }) => {
  const { editCellsState, selectedTab, saveBudgetState } = useSelector(
    (state) => state.cashFlow.root,
  );

  const utilityBeforeTaxTitle = 'Utilidad antes de impuestos';
  const totalSalesId = 'TOTAL_SALES';
  const construction = 'Construcción';
  const financialPerformance = 'financialPerformance';
  const previousControl = 'previousControl';

  const { handleChange } = usePreviousControl(row);

  const virtualTableCell = [
    classes.virtualTableCell,
    name === previousControl && saveBudgetState === false
      ? classes.errorLeftLine
      : '',
    name === previousControl ? classes.leftLine : '',
  ];

  const cellClasses = [
    row.name === utilityBeforeTaxTitle ? classes.utilityFloor : '',
    row.name === utilityBeforeTaxTitle &&
    name === previousControl &&
    saveBudgetState === false
      ? classes.errorUtilityLine
      : '',
    row.name === utilityBeforeTaxTitle && name === previousControl
      ? classes.utilityLine
      : '',
    row.id === totalSalesId ? classes.groupsFloor : '',
    row.contracts ? classes.itemsFloor : '',
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance
      ? classes.contractsFloor
      : '',
  ];

  const typographyClasses = [
    row.name === utilityBeforeTaxTitle ? classes.utilityTypography : '',
    row.id === totalSalesId ? classes.groupTypography : '',
    row.contracts ? classes.itemsTypography : '',
    row &&
    !row.items &&
    row.name !== construction &&
    row.id !== financialPerformance
      ? classes.contractsTypography
      : '',
    classes.typographySize,
  ];

  const isContract = row.id.split('_')[0] == 'contractId';
  const isLastChild =
    row.id !== 'TOTAL_SALES' &&
    row.id !== 'UTILITY-BEFORE-TAX' &&
    (!row?.items || row.id === 'perThousand');

  return (
    <VirtualTable.Cell className={virtualTableCell}>
      <Box className={cellClasses}>
        {selectedTab.id === 'BUDGET' &&
        editCellsState &&
        (isContract || isLastChild) ? (
          <NumberFormatInput
            data-tip
            data-for={`typographyToolTip ${row.name} ${name}`}
            cellData={cellData}
            divider={divider}
            decimals={decimals}
            handleChange={handleChange}
            row={row}
          />
        ) : (
          <Typography
            data-tip
            data-for={`typographyToolTip ${row.name} ${name}`}
            variant="body2"
            className={typographyClasses}
          >
            {cellData === '-'
              ? '-'
              : Numbers.currencyFormat(cellData / divider, true, decimals)}
          </Typography>
        )}

        <ReactTooltip
          type="ligth"
          id={`typographyToolTip ${row.name} ${name}`}
          effect="solid"
          className={classes.tooltip}
        >
          <Typography className={classes.text}>{`Valor en pesos:`}</Typography>

          <Typography className={classes.values}>
            {`${name === 'name' ? cellData : Numbers.currencyFormat(cellData)}`}
          </Typography>
        </ReactTooltip>
      </Box>
    </VirtualTable.Cell>
  );
};

PreviousControl.propTypes = {
  cellData: PropTypes.string,
};

export default PreviousControl;
