import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  Delete as DeleteIcon
} from '@material-ui/icons';

const useStyles = makeStyles({
  Container: {
    maxHeight: '90vh',
  },
});

const DocumentTemplatesTable = ({ templates, deleteTemplate }) => {
  const styles = useStyles();

  return (
    <TableContainer component={Paper} className={styles.Container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              align='left'
            >
              Nombre de Plantilla
            </TableCell>
            <TableCell
              align='center'
              width={50}
            >
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates && templates.map(template => (
            <TableRow key={template.id}>
              <TableCell align='left'>{template.name}</TableCell>
              <TableCell align='center'>
                <Tooltip title="Eliminar" onClick={() => deleteTemplate(template.id, template.name)}>
                  <IconButton aria-label="eliminar">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DocumentTemplatesTable.propTypes = {
  templates: PropTypes.array.isRequired,
  deleteTemplate: PropTypes.func.isRequired
};

export default DocumentTemplatesTable;
