import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Numbers } from '../../../../../helpers';
import CustomTypography from '../../../../../components/Typography/CustomTypography';

const CustomBoldTypography = styled(CustomTypography)`
  width: 17vw;
`;

const TypographyWithBackground = styled(Box)`
  width: 17vw;
  background-color: ${(props) => {
    switch (props.color) {
      case 'green':
        return '#018e42';
      case 'red':
        return '#ee2e31';
      default:
        return '#f4f9ff';
    }
  }};
  color: inherit;
`;

const ClientHubSummaryDescriptionRow = ({
  description,
  value,
  pt,
  isPrice,
  background,
}) => {
  return (
    <Box display="flex" alignItems="center" pt={pt}>
      <CustomBoldTypography variant="subtitle">
        {description}
      </CustomBoldTypography>
      <TypographyWithBackground
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={0.3}
        color={background}
      >
        <CustomTypography variant="form">
          {isPrice ? Numbers.currencyFormat(value) : value}
        </CustomTypography>
      </TypographyWithBackground>
    </Box>
  );
};

ClientHubSummaryDescriptionRow.defaultProps = {
  pt: 0.3,
  isPrice: true,
};

ClientHubSummaryDescriptionRow.propTypes = {
  description: PropTypes.string,
  value: PropTypes.string,
  background: PropTypes.string,
  isPrice: PropTypes.bool,
  pt: PropTypes.number,
};

export default ClientHubSummaryDescriptionRow;
