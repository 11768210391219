import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import EditableList from '../../../components/EditableList/EditableList';

const CRMFieldAccordion = ({
  title,
  hubspotIsEnabled,
  createField,
  updateField,
  removeField,
  values,
  type,
}) => {
  const [value, setValue] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    createField(value, type);
    setValue('');
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box flexBasis="1" flexGrow="1">
          {!hubspotIsEnabled ? (
            <form onSubmit={handleSubmit} style={{ padding: '1em' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box flexGrow={1} flexBasis={1}>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    value={value}
                    placeholder="Nuevo motivo de cierre"
                    onChange={(e) => setValue(e.target.value)}
                  ></TextField>
                </Box>
                <Box ml={2}>
                  <IconButton
                    variant="contained"
                    ml="2"
                    type="submit"
                    color="primary"
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Box>
            </form>
          ) : null}
          <Divider variant="middle" />
          <EditableList
            style={{ marginTop: '1em' }}
            onEdit={!hubspotIsEnabled && updateField}
            onDelete={!hubspotIsEnabled && removeField}
            values={values}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

CRMFieldAccordion.propTypes = {
  title: PropTypes.string,
  hubspotIsEnabled: PropTypes.bool,
  createField: PropTypes.func,
  updateField: PropTypes.func,
  removeField: PropTypes.func,
  values: PropTypes.array,
  type: PropTypes.string,
};

export default CRMFieldAccordion;
