import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import TableCell from 'shared/tables/TableCell';
import { AreaEditionTotalTableCellProps } from '../../../Core/customHooks/AreaEditionTable/AreaEditionTotalTotalProviderProps';
import useAreaEditionTotalTableCell from '../../../Core/customHooks/AreaEditionTable/useAreaEditionTotalTableCell';

const AreaEditionTotalTableCell: FC<AreaEditionTotalTableCellProps> = (props) => {
  const { value, loading } = useAreaEditionTotalTableCell(props);
  return (
    <TableCell {...props} row={props.tableRow.row} column={props.tableColumn.column!} value={value}>
      {loading && <div>Caragando</div>}
      <NumberFormat value={Number(value).toFixed(2)} displayType="text" thousandSeparator />
    </TableCell>
  );
};

export default AreaEditionTotalTableCell;
