import { Contract } from '../../../../models/Contracts';

export interface UseContractDetailPopoverActionsProps {
  handleActionEditClicked: (contract: Contract) => void;
  handleActionDeleteClicked: (contract: Contract) => void;
}

interface UseContractDetailPopoverActionsParams {
  contract?: Contract;
  extraProps: UseContractDetailPopoverActionsProps;
  cb: () => void;
}

type HookType = (params: UseContractDetailPopoverActionsParams) => {
  goEdit: () => void;
  goDelete: () => void;
};

const useContractDetailPopoverActions: HookType = ({ contract, extraProps, cb }) => {
  const goEdit = () => {
    if (contract) extraProps.handleActionEditClicked(contract);
    cb();
  };

  const goDelete = () => {
    if (contract) extraProps.handleActionDeleteClicked(contract);
    cb();
  };

  return {
    goEdit,
    goDelete,
  };
};

export default useContractDetailPopoverActions;
