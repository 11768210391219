import React, { FC } from 'react';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { TableCellSmall } from 'shared/tables/TableCell';
import TableContainer from 'shared/tables/TableContainer';
import useCustomPopover from 'shared/customHooks/useCustomPopover';
import { DateTypeProvider, CurrencyTypeProvider } from 'shared/tables/TableColumnFormat';
import useDeedExpensesSalesMadeTable from 'App/DeedExpenses/core/customHooks/useDeedExpensesSalesMadeTable';
import { DEED_EXPENSES_COLUMNS_EXTENSIONS } from 'App/DeedExpenses/core/DeedExpensesTable';
import { ISaleMade } from 'App/DeedExpenses/core/interfaces/ISaleMade';
import useDeedExpensesSalesMadeTableActions from 'App/DeedExpenses/core/customHooks/useDeedExpensesSalesMadeTableActions';
import DeedExpensesSalesMadeEmptyState from '../../containers/DeedExpensesSalesMadeEmptyState/DeedExpensesSalesMadeEmptyState';
import DeedExpensesSalesMadePopover from '../../components/DeedExpensesSalesMadePopover/DeedExpensesSalesMadePopover';
import DeedExpensesSalesMadeTableHederCell from '../DeedExpensesSalesMadeTableHederCell/DeedExpensesSalesMadeTableHederCell';
import DeadExpensesPlusInputProvider from './DeadExpensesPlusInputProvider';
import DeedExpensesDeedDateFormatter from '../DeedExpensesDeedDateFormatter/DeedExpensesDeedDateFormatter';

import styles from './DeedExpensesSalesMadeTable.module.scss';

interface Props {
  displacement: number;
  propertiesSold?: ISaleMade[];
  openConfiguration: () => void;
}

const DeedExpensesSalesMadeTable: FC<Props> = ({ displacement, propertiesSold, openConfiguration }) => {
  const { elementRef: popoverRef, handlePopoverOpen: onClickDisplacement } = useCustomPopover<{}>();
  const { columns, rows } = useDeedExpensesSalesMadeTable(propertiesSold);
  const { handleChangeDisplacement } = useDeedExpensesSalesMadeTableActions();
  return (
    <>
      {propertiesSold && propertiesSold.length > 0 ? (
        <div className={styles.DeedExpensesSalesMadeTable}>
          <Grid rows={rows} columns={columns}>
            <VirtualTable
              height="70vh"
              containerComponent={TableContainer}
              cellComponent={TableCellSmall}
              columnExtensions={DEED_EXPENSES_COLUMNS_EXTENSIONS}
            />
            <DeadExpensesPlusInputProvider
              for={['deedDisplacement']}
              handleChangeDisplacement={handleChangeDisplacement}
            />
            <DateTypeProvider for={['finalPaymentDate']} format="MMM-YYYY" />
            <DataTypeProvider for={['deedDate']} formatterComponent={DeedExpensesDeedDateFormatter} />
            <CurrencyTypeProvider for={['totalPrice', 'finalPayment', 'firstPayment']} />
            <TableHeaderRow
              cellComponent={DeedExpensesSalesMadeTableHederCell({
                displacement,
                onClickDisplacement,
              })}
            />
          </Grid>
          <DeedExpensesSalesMadePopover refDivElement={popoverRef} onClickConfiguration={openConfiguration} />
        </div>
      ) : (
        <DeedExpensesSalesMadeEmptyState openConfiguration={openConfiguration} />
      )}
    </>
  );
};

export default DeedExpensesSalesMadeTable;
