import { useCallback, useEffect, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { AreasDB } from '../../indexedDB/AreaIndexedDB';
import { useRecoilState } from 'recoil';
import { AreaEditionValueAtom } from '../../recoil/AreaEditionValueAtom';
import IPropertyArea from '../../interfaces/IPropertyArea';
import { AreaEditionHaveChangesAtom } from '../../recoil/AreaEditionHaveChangesAtom';

const useAreaTableEditor = (props: DataTypeProvider.ValueFormatterProps) => {
  const { column, value: propValue } = props;
  const id = props.row?.id;
  const { name } = column;

  const [loading, setLoad] = useState(false);
  const [preValue, setPrevValue] = useState(0);
  const [value, setValue] = useState(0);
  const [_, setAreaEditionValueState] = useRecoilState(AreaEditionValueAtom);
  const [areaEditionHaveChanges, setAreaEditionHaveChangesState] = useRecoilState(AreaEditionHaveChangesAtom);

  const initialize = useCallback(async () => {
    setLoad(true);

    if (id) {
      const propertyArea = await AreasDB.properties.get(id);
      const propertyAreaValue = propertyArea ? propertyArea[name] : propValue;
      setValue(propertyAreaValue);
      setPrevValue(propertyAreaValue);
    }
    setLoad(false);
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  const refreshEditorDB = async (prevValue: number, value: number) => {
    setLoad(true);
    const propertyArea = await AreasDB.properties.get(id);
    if (propertyArea) {
      const payload: Partial<IPropertyArea> = {
        [name]: value,
      };

      if (!propertyArea.hasChanges?.includes(name)) {
        const hasChanges = propertyArea?.hasChanges?.length ? propertyArea.hasChanges.concat(name) : [name];
        payload.hasChanges = hasChanges;
      }

      await AreasDB.properties.update(id, payload);

      if (!areaEditionHaveChanges) setAreaEditionHaveChangesState(true);

      setAreaEditionValueState({
        rowId: id,
        columnId: name,
        defaultValue: prevValue,
        value,
        record: true,
      });

      setValue(value);
      setPrevValue(value);
    }
    setLoad(false);
  };

  const onBlur = async () => {
    if (id && typeof preValue === 'number' && +preValue !== value) await refreshEditorDB(preValue ?? 0, value ?? 0);
  };

  const handleChange = (newDisplacement: number) => {
    setValue(newDisplacement);
  };

  return {
    value,
    loading,
    onBlur,
    handleChange,
  };
};
export default useAreaTableEditor;
