import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import styles from './TextField.module.scss';

const TextField: React.FC<TextFieldProps> = ({ className, variant = 'outlined', ...restProps }) => (
  <MuiTextField className={clsx(styles.root, className)} variant={variant} {...restProps} />
);

export default TextField;
