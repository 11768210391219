
function useColumnExtension() {
  const CreateTableColumnExtensions = (headers) => {
    const intersect = [{ columnName: 'properties', width: 70 }];

    const createExtensions = headers.map((header) => {
      const name = header;
      return [{ columnName: name, width: 125 }];
    });

    const allColumns = intersect.concat(createExtensions);

    const arrayOfHeadersObjects = allColumns.flatMap((header) => header);

    return arrayOfHeadersObjects;
  };

  return { CreateTableColumnExtensions };
}

export default useColumnExtension;
