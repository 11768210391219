import { useState, useEffect } from 'react';
import { RESULTS_STATE_TABS } from '../customHooks/constants/ResultsStateConstants';
import { useDispatch } from 'react-redux';
import { ITabOption } from '../interfaces/ITabOptionForResultsState';
import { updateSelectedTab } from '../../../TotalCashFlow/Core/actions';

const selectTabs =
  (selectedTab: ITabOption) =>
  (tab: ITabOption): ITabOption => ({ ...tab, selected: tab.id === selectedTab.id });

const useResultsStateViewController = () => {
  const [selectedTab, setSelectedTab] = useState(RESULTS_STATE_TABS.find((tab) => tab.selected));
  const [tabs, setTabs] = useState(RESULTS_STATE_TABS);
  const [privateCurrentTab, setCurrentTab] = useState<ITabOption | undefined>(
    RESULTS_STATE_TABS.find((tab) => tab.selected),
  );
  const dispatch = useDispatch();

  const changeTab = (tab: ITabOption) => {
    setTabs((preTabs) => preTabs.map(selectTabs(tab)));
    setCurrentTab(tab);
  };

  useEffect(() => {
    setSelectedTab(() => privateCurrentTab);
    dispatch(updateSelectedTab(privateCurrentTab));
  }, [privateCurrentTab, tabs]);

  return {
    tabs,
    selectedTab,
    changeTab,
  };
};

export default useResultsStateViewController;
