import React from 'react';
import { PropTypes } from 'prop-types';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import HighlightedCell from './HighlightedCell';

const MainCashFlowCell = ({ tableColumn, onSelect, leftColumns, divider, ...rest }) => {

  if (tableColumn) {
    if (leftColumns.find((column) => column === tableColumn.column.name)) {
      return (
        <HighlightedCell {...rest} isFixed={true} tableColumn={tableColumn} divider={divider} />
      );
    }
    if (tableColumn.column) {
      return (
        <HighlightedCell
          {...rest}
          onSelect={onSelect}
          tableColumn={tableColumn}
          divider={divider}
        />
      );
    }
  }

  return (
    <VirtualTable.Cell
      {...rest}
      tableColumn={tableColumn}
      divider={divider}
    />
  );
};

MainCashFlowCell.propTypes = {
  tableColumn: PropTypes.string,
  idForPopover: PropTypes.string,
  leftColumns: PropTypes.array,
  onSelect: PropTypes.func,
};

export default MainCashFlowCell;
