import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import Tooltip from '../../../../../shared/components/Tooltip/Tooltip';

const AreaGroupFormatter: FC<DataTypeProvider.ValueFormatterProps> = (props) => {
  return (
    <Tooltip title={props.value}>
      <span>{props.value}</span>
    </Tooltip>
  );
};
const AreaGroupProvider: FC<DataTypeProviderProps> = (props) => {
  return <DataTypeProvider {...props} formatterComponent={AreaGroupFormatter} />;
};

export default AreaGroupProvider;
