import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CashFlowEvalproServices from '../../Services/CashFlowEvalproServices';
import { getCashflowEvalpro } from '../actions';

const services = new CashFlowEvalproServices();

function useCashFlowEvalpro() {
  const { towerId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [cashFlowEvalpro, setCashFlowEvalpro] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const cashFlow = await services.getCashFlowEvalpro(towerId);
        setCashFlowEvalpro(cashFlow.data);
        dispatch(getCashflowEvalpro(cashFlow.data));
      } catch (error) {
        setCashFlowEvalpro([]);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setLoading(false);
    };

    if (!cashFlowEvalpro && !isLoading) {
      fetch();
    }
  }, []);

  return { isLoading, cashFlowEvalpro };
}

export default useCashFlowEvalpro;
