import { Column } from '@devexpress/dx-react-grid';
import { useEffect, useState } from 'react';
import { DEED_EXPENSES_COLUMNS } from '../DeedExpensesTable';
import { ISaleMade } from '../interfaces/ISaleMade';

const useDeedExpensesSalesMadeTable = (salesMade?: ISaleMade[]) => {
  const [columns] = useState<Column[]>(DEED_EXPENSES_COLUMNS);
  const [rows, setRows] = useState<ISaleMade[]>([]);

  useEffect(() => {
    if (salesMade?.length) setRows(salesMade);
  }, [salesMade]);

  return {
    columns,
    rows,
  };
};

export default useDeedExpensesSalesMadeTable;
