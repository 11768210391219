import { Plugin, Template } from '@devexpress/dx-react-core';
import React from 'react';

import { ChangeSalesStatusHandler } from '../../../core/types/SalesStatus.types';
import SalesStatusCell, { SalesStatusCellProps } from './SalesStatusCell';

const shouldRenderTemplate =
  (columnNames: string[]) =>
  ({ column }: any) =>
    columnNames.includes(column.name);

const tableTagTemplate =
  (onChange: ChangeSalesStatusHandler) =>
  ({ ...restParams }: SalesStatusCellProps) =>
    <SalesStatusCell {...restParams} onChange={onChange} />;

type Props = {
  for: Array<string>;
  onChange: ChangeSalesStatusHandler;
};

const SalesStatusCellProvider: React.FC<Props> = ({ for: columnNames, onChange }) => (
  <Plugin name="TableTagProvider" key={columnNames.join('_')}>
    <Template name="valueFormatter" predicate={shouldRenderTemplate(columnNames)}>
      {tableTagTemplate(onChange)}
    </Template>
    <Template name="valueEditor" predicate={shouldRenderTemplate(columnNames)}>
      {tableTagTemplate(onChange)}
    </Template>
  </Plugin>
);

export default SalesStatusCellProvider;
