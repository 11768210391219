import clsx from 'clsx';
import React from 'react';

import { TableTagEmptyOptionProps } from '../../../core/interfaces/TableTagEmptyOption.types';

import styles from './TableTagEmptyOption.module.scss';

const getSelectedClass = (isSelected: boolean) => ({
  [styles.isSelected]: isSelected,
});

const TableTagEmptyOption: React.FC<TableTagEmptyOptionProps> = ({ isSelected }) => (
  <div className={clsx(getSelectedClass(isSelected), styles.root)}></div>
);

export default TableTagEmptyOption;
