import { useQuery } from 'react-query';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Services from '../../../../services/ContractsEvalpro/ContractsEvalproServices';
import { FORMAT_YEAR_MONTH3 } from '../../../../helpers/dates';
import constants from './constants';
import { EvalproEventDto } from '../DTO/ContractsEvalproEvent.dto';

const services = new Services();

const useContractsEvalProDetailsData = (towerId: string) => {
  return useQuery<EvalproEventDto[]>(
    constants.CONTRACTS_EVALPRO_GROUPS_DETAILS,
    async () => {
      const { data } = await services.getAllEvalproDetailsGroups(towerId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

type HookType = () => {
  data: EvalproEventDto[] | undefined;
  isLoading: boolean;
  getEventTitle: (event: EvalproEventDto) => string;
};

const useContractsEvalProDetails: HookType = () => {
  const { towerId }: { towerId: string } = useParams();
  const { data, isLoading } = useContractsEvalProDetailsData(towerId);

  const getEventTitle = (event: EvalproEventDto) => {
    const { name, type, dateRange } = event;
    const [startDate, endDate] = dateRange || [0, 0];
    const dateIni = moment(Number(startDate));

    if (type === 'RANGE') {
      const dateFin = moment(Number(endDate));
      const diff = dateFin.diff(dateIni, 'months');

      return `${name} ${dateIni.format(FORMAT_YEAR_MONTH3)} - ${dateFin.format(FORMAT_YEAR_MONTH3)} (${diff} meses)`;
    }

    return `${name} (${dateIni.format(FORMAT_YEAR_MONTH3)})`;
  };

  return { data, isLoading, getEventTitle };
};

export default useContractsEvalProDetails;
