import React from 'react';
import TotalCashFlowEnum from '../TotalCashFlow.enum';

const useMapperForNewRows = ({ rows }) => {
  const AccumulatedOperatingFlow = TotalCashFlowEnum.ACCUMULATED_OPERATING_FLOW.value;
  const spreadIPC = TotalCashFlowEnum.SPREAD_IPC.value;
  const saldoAcumuladoInversionistas = TotalCashFlowEnum.SALDO_ACUMULADO_INVERSIONISTAS.value;
  const monthBalacedId = TotalCashFlowEnum.MONTH_BALANCED_ID.value;
  const accumulatedId = TotalCashFlowEnum.ACCUMULATED_ID.value;

  const filteredRows = rows.filter((row) => {
    return (
      row.id !== AccumulatedOperatingFlow &&
      row.id !== spreadIPC &&
      row.id !== saldoAcumuladoInversionistas &&
      row.id !== accumulatedId &&
      row.id !== monthBalacedId &&
      row.name !== 'Construcción'
    );
  });

  const freeCashFlow = rows.filter((row) => row.id === monthBalacedId);

  const filteredRowsWithFreeCashFlow = filteredRows.concat(freeCashFlow);

  const filteredRowsOrdered = rows.filter((row) => row.name === 'Construcción').concat(filteredRowsWithFreeCashFlow);

  let newRows = filteredRowsOrdered.map((row) => {
    const { id } = row;

    switch (id) {
      case 'TOTAL_SALES':
        return {
          ...row,
          name: 'Ventas',
        };
      case 'TOTAL_ID_FUTURE':
        return {
          ...row,
          name: 'Pendientes',
        };
      case 'TOTAL_ID_PAST':
        return {
          ...row,
          name: 'Realizadas',
        };
      case 'financing-credit-balance':
        return {
          ...row,
          name: 'Saldo Crédito Constructor',
        };
      case 'investorFlow':
        return {
          ...row,
          name: 'Inversiones de Capital',
        };
      case 'cxp':
        return {
          ...row,
          name: 'Cuentas por Pagar',
        };
      case monthBalacedId:
        return {
          ...row,
          name: 'Flujo de Caja Libre',
        };
      case accumulatedId:
        return {
          ...row,
          name: 'Saldo de Caja',
        };
      default:
        break;
    }
    return row;
  });
  return newRows;
};

export default useMapperForNewRows;
