import useBoolean from 'shared/customHooks/useBoolean';
import removeEmptyValues from 'helpers/removeEmptyValues';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import ClientsServices from 'services/client/ClientsServices';
import { RestServiceError } from 'services/errors/ResponseError';
import { ClientAddressSection } from '../interfaces/ClientAddressSection';
import { ClientCompanyModel } from '../models/ClientCompanyModel';
import { ClientInformationModel } from '../models/ClientInformationModel';
import { ClientModel } from '../models/ClientModel';

interface ClientParams {
  client: Partial<ClientModel>;
  clientCompany: Partial<ClientCompanyModel>;
  clientInformation: Partial<ClientInformationModel>;
  clientAddressSection?: Partial<ClientAddressSection>;
}

interface Payload extends ClientAddressSection {
  client?: Partial<ClientModel>;
  clientCompany?: Partial<ClientCompanyModel>;
  additionalInfo?: Partial<ClientInformationModel>;
}

const services = new ClientsServices();

const useClientService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams<{ towerId: string }>();
  const { value: isLoading, setTrue: loading, setFalse: stopLoading } = useBoolean();

  const transformModelToRequestBody = (params: Partial<ClientParams>) => {
    const { client, clientCompany, clientInformation, clientAddressSection } = params;

    const data: Payload = { ...clientAddressSection };

    if (client) data.client = removeEmptyValues(client);
    if (clientInformation) data.additionalInfo = removeEmptyValues(clientInformation);
    if (clientInformation?.mobileNumber) {
      if (!data.client) data.client = {};
      data.client.mobileNumber = clientInformation?.mobileNumber;
    }
    if (clientCompany) data.clientCompany = removeEmptyValues(clientCompany);

    return removeEmptyValues(data);
  };

  const createClient = async (params: ClientParams) => {
    loading();
    try {
      const requestBody = transformModelToRequestBody(params);
      const { data } = await services.postClient<ClientModel>(towerId, requestBody);
      enqueueSnackbar('Cliente creado con éxito', { variant: 'success' });
      stopLoading();
      return data;
    } catch (error) {
      const errorMessage = error as RestServiceError;
      if (errorMessage?.error === 'Conflict') enqueueSnackbar(errorMessage.message, { variant: 'error' });
      else enqueueSnackbar(errorMessage, { variant: 'error' });
      stopLoading();
      return false;
    }
  };

  const updateClient = async (clientId: string, params: Partial<ClientParams>) => {
    loading();
    try {
      const requestBody = transformModelToRequestBody(params);
      const { data } = await services.putClient<ClientModel>(clientId, towerId, requestBody);
      enqueueSnackbar('Cliente actualizado con éxito', { variant: 'success' });
      stopLoading();
      return data;
    } catch (error) {
      const errorMessage = error as RestServiceError;
      if (errorMessage?.error === 'Conflict') enqueueSnackbar(errorMessage.message, { variant: 'error' });
      else enqueueSnackbar(errorMessage, { variant: 'error' });
      stopLoading();
      return false;
    }
  };

  return { createClient, updateClient, isFetch: isLoading };
};

export default useClientService;
