import React from 'react';

import TextField from 'shared/components/TextField/TextField';
import Typography from 'shared/components/Typography/Typography';
import useSingleSelectValidation from '../../../core/customHooks/useSingleSelectValidation';
import useUpdateSingleSelectField from '../../../core/customHooks/useUpdateSingleSelectField';
import { EditableFeatureField } from '../../../core/interfaces/featureField.types';
import { FeatureFieldSingleSelectEditorProps } from '../../../core/interfaces/FeatureFieldSingleSelectEditor.types';
import FeatureFieldOptionsControl from './FeatureFieldOptionsControl';

import styles from './FeatureFieldSingleSelectEditor.module.scss';

const getOptions = ({ options = [] }: EditableFeatureField) => options;

const FeatureFieldSingleSelectEditor: React.FC<FeatureFieldSingleSelectEditorProps> = ({
  editableField,
  disabled,
  onChange,
}) => {
  const { hasError, helperText, handleOnIvalid, resetError } = useSingleSelectValidation();
  const { handleChangeTitle, handleChangeOptions } = useUpdateSingleSelectField({ editableField, onChange });

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Typography id="single-select-editor" className={styles.titleLabel} variant="body2" as="label" color="gray600">
          Título
        </Typography>
        <TextField
          id="single-select-editor"
          size="small"
          value={editableField.title}
          placeholder="ej. estado, prioridad, etapa"
          required
          disabled={disabled}
          onChange={handleChangeTitle}
          error={hasError}
          helperText={helperText}
          inputProps={{ onInvalid: handleOnIvalid }}
          onInput={resetError}
        />
      </div>
      <FeatureFieldOptionsControl
        options={getOptions(editableField)}
        disabled={disabled}
        onChange={handleChangeOptions}
      />
    </div>
  );
};

export default FeatureFieldSingleSelectEditor;
