import Services from '../../../services/services';
import GraphicMaterialServicesDefinition from './GraphicMaterialServicesDefinition';

class GraphicMaterialServices extends Services {
  getGraphicMaterial(propertyId, imgType){
    return this.get(
      GraphicMaterialServicesDefinition.getGraphicMaterial(propertyId, imgType)
    );
  }

  getGraphicMaterialDetails(imgId) {
    return this.get(
      GraphicMaterialServicesDefinition.getGraphicMaterialDetails(imgId)
    );
  }

  putGraphicMaterial(propertyId, imgType, form){
    return this.post(
      GraphicMaterialServicesDefinition.postGraphicMaterial(propertyId, imgType), form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  }

  putAssignGraphicMaterial(propertyId, graphicMaterialId, imgType){
    return this.post(
      GraphicMaterialServicesDefinition.postAssignGraphicMaterial(propertyId, graphicMaterialId, imgType)
    )
  }

  deleteGraphicMaterial(imgId, propertyId){
    return this.delete(
      GraphicMaterialServicesDefinition.deleteGraphicMaterial(imgId, propertyId)
    )
  }
}

export default GraphicMaterialServices;