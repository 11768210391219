import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

import Typography from 'shared/components/Typography/Typography';

import styles from './SalesSpeedHelperBandCell.module.scss';

const getIsEmpty = (children: ReactNode) => ({
  [styles.isEmpty]: !children,
});

const SalesSpeedHelperBandCell: React.FC<TableBandHeader.CellProps> = ({ children, ...restProps }) => (
  <TableBandHeader.Cell className={clsx(styles.root, getIsEmpty(children))} {...restProps}>
    <Typography variant="body2" color="gray000">
      {children}
    </Typography>
  </TableBandHeader.Cell>
);

export default SalesSpeedHelperBandCell;
