import { Table } from '@devexpress/dx-react-grid';

const faseColumns = [
  {
    name: 'sold',
    title: 'Vendido',
  },
  {
    name: 'toSell',
    title: 'Por vender',
  },
  {
    name: 'total',
    title: 'Total',
  },
];

const preSalesUnitsColumns = [
  {
    name: 'group',
    title: 'Venta de unidades por fase',
  },
  ...faseColumns,
];

const preSalesUnitsPercentColumns = [
  {
    name: 'group',
    title: '% Venta de unidades por fase',
  },
  ...faseColumns,
];

const preSalesSpeedColumns = [
  {
    name: 'group',
    title: 'Velocidad de ventas por fase',
  },
  ...faseColumns,
];

const faseBandsChildren = [{ columnName: 'sold' }, { columnName: 'toSell' }, { columnName: 'total' }];

const phasesFormattedColumns = [faseColumns[0].name, faseColumns[1].name, faseColumns[2].name];

const phasesColumnsBands = {
  preSales: [
    {
      children: [{ columnName: 'group' }],
    },
    {
      title: 'Preventas',
      children: faseBandsChildren,
    },
  ],
  sales: [
    {
      title: 'Ventas',
      children: faseBandsChildren,
    },
  ],
  total: [
    {
      title: 'Total',
      children: faseBandsChildren,
    },
  ],
};

const salesSpeedColumnExtensions: Table.ColumnExtension[] = [
  {
    columnName: 'group',
    width: 258,
  },
  {
    columnName: 'sold',
    width: 96,
    align: 'center',
  },
  {
    columnName: 'toSell',
    align: 'center',
  },
  {
    columnName: 'total',
    width: 70,
    align: 'center',
  },
];

export {
  faseColumns,
  preSalesUnitsColumns,
  preSalesUnitsPercentColumns,
  preSalesSpeedColumns,
  phasesFormattedColumns,
  phasesColumnsBands,
  salesSpeedColumnExtensions,
};
