import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { GeneralWalletDetailsService } from '../../services/GeneralWalletDetailsService';
import { GENERAL_WALLET_THREADS } from '../constants/GeneralWalletDetailsReactQuery';
import { GeneralWalletDetailsResponseDTO } from '../DTO/GeneralWalletComment.dto';

const service = new GeneralWalletDetailsService();

const useGeneralWalletThreads = (clientId: string, propertyId: number) => {
  const query = useQuery<GeneralWalletDetailsResponseDTO, AxiosError>(
    [GENERAL_WALLET_THREADS, clientId, propertyId],
    async () => {
      const { data } = await service.getThreads(clientId, propertyId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      initialData: {
        threads: [],
      },
    },
  );

  const threads = query.isSuccess ? query.data.threads : [];
  const isLoadingThreads = query.isLoading;

  return { threads, isLoadingThreads };
};

export default useGeneralWalletThreads;
