import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { QuotationConfigDto } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import Typography from 'shared/components/Typography/Typography';
import useDeedExpenses from 'App/QuotationConfig/core/customHooks/useDeedExpenses';
import QuotationConfigCollapse from '../../components/QuotationConfigCollapse/QuotationConfigCollapse';
import QuotationConfigAlert from '../../components/QuotationConfigAlert/QuotationConfigAlert';
import QuotationConfigDeedExpensesItem from '../../components/QuotationConfigDeedExpensesItem/QuotationConfigDeedExpensesItem';
import QuotationConfigFormItem from '../QuotationConfigFormItem';
import DeedExpensesNotes from './DeedExpensesNotes';

const DeedExpenses: FC<QuotationConfigDto> = ({ quotationSetup }) => {
  const { deedExpense, deedNote } = quotationSetup;
  const { isValidDeedExpenseType, updateDeedExpenseValue } = useDeedExpenses(deedExpense);
  return (
    <QuotationConfigCollapse
      name="Gastos de Escrituración"
      state={!!isValidDeedExpenseType && !!deedNote ? 'success' : 'error'}
    >
      <Box display="flex" justifyContent="center" padding="40px" paddingY="32px" paddingBottom="55px">
        <Grid container item xs={10}>
          <QuotationConfigFormItem item xs={12}>
            <Box paddingBottom="30px">
              <QuotationConfigAlert
                state="info"
                text="Distribuye el 100% de los gastos de Escrituración en una de las etapas a continuación."
                closable
              />
            </Box>
          </QuotationConfigFormItem>
          <QuotationConfigFormItem item xs={3} />
          <QuotationConfigFormItem item xs={3}>
            <Typography fontWeight="500" color="gray600">
              % Sobre ventas
            </Typography>
          </QuotationConfigFormItem>
          <QuotationConfigFormItem item xs={3}>
            <Box paddingRight="12px">
              <Typography fontWeight="500" color="gray600">
                Asumido por comprador
              </Typography>
            </Box>
          </QuotationConfigFormItem>
          <QuotationConfigFormItem item xs={3}>
            <Box paddingRight="12px">
              <Typography fontWeight="500" color="gray600">
                Asumido por vendedor
              </Typography>
            </Box>
          </QuotationConfigFormItem>
          <QuotationConfigDeedExpensesItem
            title="Notariales"
            deedExpense={deedExpense}
            soldName="notarialSold"
            buyerName="notarialBuyerAssumed"
            handleChangeValue={updateDeedExpenseValue}
          />
          <QuotationConfigDeedExpensesItem
            title="Rentas"
            deedExpense={deedExpense}
            soldName="revenueSold"
            buyerName="revenueBuyerAssumed"
            handleChangeValue={updateDeedExpenseValue}
          />
          <QuotationConfigDeedExpensesItem
            title="Registro"
            deedExpense={deedExpense}
            soldName="recordSold"
            buyerName="recordBuyerAssumed"
            handleChangeValue={updateDeedExpenseValue}
          />
          <QuotationConfigFormItem item xs={12}>
            <DeedExpensesNotes deedNote={deedNote} />
          </QuotationConfigFormItem>
        </Grid>
      </Box>
    </QuotationConfigCollapse>
  );
};

export default DeedExpenses;
