import React from 'react';
import { Grid, PagingPanel, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import {
  CustomPaging,
  DataTypeProvider,
  IntegratedSorting,
  PagingState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { CircularProgress } from '@material-ui/core';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';
import VirtualTableContainer from 'shared/tables/VirtualTableContainer';
import { ClientModel } from 'App/Clients/Core/models/ClientModel';
import useClientList, { UseClientListProps } from './Core/customHooks/useClientList';
import PagingPanelContainer from './UI/Containers/PagingPanelContainer/PagingPanelContainer';
import ClientListAssociatedFormatter from './UI/Formatters/ClientListAssociatedFormatter/ClientListAssociatedFormatter';
import styles from './List.module.scss';
import useClientListFormatter from './Core/customHooks/useClientListFormatter';

interface Props extends UseClientListProps {
  clients: ClientModel[];
  isLoading: number;
  page: number;
  limit: number;
  total: number;
  sorting: Sorting[];
  onChangePaginationPage: (page: number) => void;
  onSortingChange: (sorting: Sorting[]) => void;
  deleteClient: (client: ClientModel) => void;
  goToSalesRoom: (client: ClientModel) => void;
  onClickEditClient: (client: ClientModel) => void;
  goToQuotes: (client: ClientModel) => void;
}

const List = (props: Props) => {
  const { isLoading, page, limit, total, sorting, onChangePaginationPage, onSortingChange } = props;
  const { rows, columns, columnWidths, sortingStateColumnExtensions } = useClientList(props);

  const clientListCreatedAtFormatter = useClientListFormatter(props);

  return (
    <div className={styles.container}>
      <Grid rows={rows} columns={columns}>
        <DataTypeProvider for={['associated']} formatterComponent={ClientListAssociatedFormatter} />
        <DataTypeProvider for={['createdAt']} formatterComponent={clientListCreatedAtFormatter} />
        {/* <PagingState currentPage={page} pageSize={limit} onCurrentPageChange={onChangePaginationPage} /> */}
        {/* <CustomPaging totalCount={total} /> */}
        {/* <SortingState
          defaultSorting={sorting}
          sorting={sorting}
          onSortingChange={onSortingChange}
          columnExtensions={sortingStateColumnExtensions}
        />
        <IntegratedSorting /> */}
        <VirtualTable height="65vh" columnExtensions={columnWidths} />
        {/* <PagingPanel
          containerComponent={PagingPanelContainer}
          messages={{
            info: ({ from, count, to }) => `${from}-${to} de ${count}`,
          }}
        /> */}
        <TableHeaderRow cellComponent={TableHeaderRowCell} />
      </Grid>
      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default List;
