import React, { Component, createRef } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from '@material-ui/core';
import _ from 'lodash';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../../../components/UI/Button/Button';
import { DashboardRoutes } from '../../../routes/local/routes';

import SalesRoomService from '../../../services/salesRoom/salesRoomService';
import Card, {
  CardHeader,
  CardBody,
  CardFooter,
} from '../../../components/UI/Card/Card';
import Modal from '../../../components/UI/Modal/Modal';
import agent from '../../../config/config';
import { Numbers, Role } from '../../../helpers';
import variables from '../../../assets/styles/variables.scss';
import Selectors from '../../../components/SalesRoom/Selectors';
import PropertiesTable from '../../../components/SalesRoom/PropertiesTable';
import Message from '../../../components/SalesRoom/Message';
import Status from '../../../helpers/status';
import LoadableContainer from '../../../components/UI/Loader';
import SalesRoomModal from '../../../components/SalesRoom/modal';
import Filters from '../../../App/SalesRoom/UI/Views/Filters';
import SalesRoomEnum from '../SalesRoom.enum';
import AlertRedirect from '../AlertRedirect';
import Styles from './salesRoomClient.module.scss';
import withDefaultLayout from '../../../HOC/Layouts/Default/withDefaultLayout';
import { changeGroup } from '../../StrategyV2/actions';
import validateSelectedGroup from '../../../components/StrategyV2/Shared/Validator';
import GraphicMaterialDialog from '../../../App/GraphicMaterial/UI/views/dialogs/GraphicMaterialDialog';
import PropertiesQuotationsDialogMainView from '../../../components/Client2/List/Row/QuotationsList';
import { filterClean } from '../../../App/SalesRoom/Core/actions';
import CRMSelectClosignReasonPromiseModal from '../../../App/CRM/UI/components/CRMReasonPromiseModal';
import PropertyTypeService from '../../../App/PropertyCharacteristic/services/PropertyTypeService';
import ExcelSalesRoomPopover from '../SalesRoomPopover/ExcelSalesRoomPopover';

const modelCRMReft = createRef();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class SalesRoom extends Component {
  constructor(props) {
    super(props);
    this.services = new SalesRoomService();
    this.propertyTypeService = new PropertyTypeService();
    this.makeArrayOfProperties = this.makeArrayOfProperties.bind(this);
    this.isModalShowingStatesContent = false;
  }

  state = {
    originalPropertiesResponse: {},
    response: {},
    properties: 1,
    floors: 1,
    lowestFloor: 1,
    data: [[]],
    isOpen: false,
    isLoading: false,
    rightButton: {},
    leftButton: {},
    id: 0,
    groupId: 0,
    priceSold: 0,
    discountApplied: 0,
    isEmpty: null,
    isLoadingModal: false,
    selectedProperty: { name: '' },
    clientName: null,
    deadlineDate: new Date(),
    isInvalidDeadlineDate: true,
    additionalAreas: [],
    lastSelector: 'priceWithIncrements',
    isLastProperty: false,
    optionalDescription: '',
    isSavingErrorActive: false,
    openGrouping: false,
    strategyIsAvailable: true,
    originalPropertyStatus: null,
    openDesistDialog: false,
    openGraphicMaterialDialog: false,
    headers: [],
    verticalHeaders: [],
    propertyTypes: [],
    clientInQueue: null,
    additionalAreaIsSelected: false,
    clientId: null,
    propertyId: null,
    customOpenDialog: false,
  };

  openQuotationListHandler = (clientId, propertyId, customOpenDialog) => {
    this.setState({
      clientId,
      propertyId,
      customOpenDialog,
    });
  };

  customCloseDialog = () => {
    this.setState({
      customOpenDialog: false,
    });
  };

  additionalAreaStateHandler = (state) => {
    this.setState({ additionalAreaIsSelected: state });
  };

  propertyHandler = (key, value) => {
    const temp = { ...this.state.selectedProperty };
    let additionalState = {};
    if (key === 'status') additionalState = { isSavingErrorActive: false };
    temp[key] = value;
    if (temp.isReset) {
      this.props.activateBadgeIncrement(true);
    }
    this.setState({ selectedProperty: temp, ...additionalState });
  };

  componentDidMount() {
    const { towerId, clientId } = this.props.match.params;
    this.setState({ isLoading: true, clientId });
    this.props.onFilterClean();
    this.services
      .getProperties(towerId, clientId)
      .then((properties) => {
        const { data } = properties;
        this.makeArrayOfProperties(data.incrementList);
        const { data: originalPropertiesResponse } =
          this.mapResponseToProperties(data.incrementList, false);
        this.isModalShowingStatesContent = false;
        this.setState({
          originalPropertiesResponse,
          isLoading: false,
          clientName: `${data.client.name || ''} ${
            data.client.middleName || ''
          } ${data.client.surname || ''} ${data.client.secondSurname || ''}`,
          headers: data.incrementList.headers,
          verticalHeaders: data.incrementList.verticalHeaders,
        });

        const checker = this.state.data.flatMap((singleData) =>
          singleData.filter((datas) => datas !== null),
        );
        if (checker.length < 1) {
          this.setState({ openGrouping: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
    this.propertyTypeService
      .getTypes({ towerId: this.props.match.params.towerId })
      .then(({ data }) => {
        if (data.types)
          this.setState({
            propertyTypes: data.types,
          });
      });
    this.updateAdditionalAreas();
  }

  buttonsStyles = (status, clientBadge) => {
    let backgroundColor;
    let rightButton;
    let leftButton;
    if (status === Status.Available) {
      backgroundColor = variables.blueLightColor;
      rightButton = { label: 'Vendido', color: variables.mainColor };
      leftButton = { label: 'Opcionado', color: variables.secondaryColor };
    } else if (status === Status.Optional) {
      backgroundColor = variables.secondaryColor;
      rightButton = {
        label: 'Vendido',
        color: variables.mainAquaGreenDarkColor,
      };
      leftButton = { label: 'Disponible', color: variables.mainAquaGreen };
    } else if (clientBadge) {
      backgroundColor = variables.mainGreenColor;
      rightButton = {
        color: variables.secondaryColor,
      };
      leftButton = { color: variables.mainGreenColor };
    } else {
      backgroundColor = variables.mainAquaGreenDarkColor;
      rightButton = {
        label: 'Opcionado',
        color: variables.secondaryColor,
      };
      leftButton = { label: 'Disponible', color: variables.mainGreenColor };
    }
    return {
      backgroundColor,
      rightButton,
      leftButton,
    };
  };

  updateAdditionalDesistedArea = (areaId) => {
    this.setState((prevState) => {
      const tempProperty = { ...prevState.selectedProperty };
      const additionalAreaUpdated = (area) => {
        if (areaId === area.id) {
          return {
            ...area,
            additionalAreaRequests: [
              ...area.additionalAreaRequests,
              {
                action: 'D',
                status: 'P',
              },
            ],
          };
        }
        return area;
      };
      const adminAdditionalAreas = tempProperty.adminAdditionalAreas.map(
        additionalAreaUpdated,
      );
      const addedAdditionalAreas = tempProperty.addedAdditionalAreas.map(
        additionalAreaUpdated,
      );
      const additionalAreas = prevState.additionalAreas.map(
        additionalAreaUpdated,
      );

      return {
        selectedProperty: {
          ...tempProperty,
          adminAdditionalAreas,
          addedAdditionalAreas,
        },
        additionalAreas: _.sortBy(additionalAreas, [
          { areaType: 'name' },
          'id',
        ]),
      };
    });
  };

  onClickSelector = (property, buttons) => {
    if (this.state.clientName) {
      try {
        const tempProperty = { ...property };
        tempProperty.addedAdditionalAreas = tempProperty.additionalAreas.filter(
          (additionalArea) => additionalArea.addedFromSalesRoom,
        );
        tempProperty.adminAdditionalAreas = tempProperty.additionalAreas.filter(
          (additionalArea) => !additionalArea.addedFromSalesRoom,
        );
        const group = this.state.originalPropertiesResponse.properties.find(
          (g) => {
            return g[0] ? g[0].groupId === tempProperty.groupId : false;
          },
        );
        const availableProperties = group.filter(
          (p) => p.status === Status.Available,
        );

        tempProperty.clientId = this.props.match.params.clientId;

        const lastProperty =
          availableProperties.length === 1 ? availableProperties[0] : null;

        this.setState({
          originalPropertyStatus: tempProperty.status,
          id: property.id,
          groupId: property.groupId,
          isOpen: true,
          deadlineDate: new Date(),
          isInvalidDeadlineDate: true,
          priceSold: property.priceWithIncrement,
          selectedProperty: tempProperty,
          discountApplied: property.discount,
          isLastProperty:
            (lastProperty != null && tempProperty.id === lastProperty.id) ||
            availableProperties.length < 1,
        });
      } catch (error) {
        this.props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    }
  };

  getType = (id) => {
    const type = this.state.propertyTypes.find((p) => p.id === id);
    return type ? type.name : '------';
  };

  makeCells = (
    buttons,
    property,
    active = `${
      agent.isAuthorized([Role.Admin, Role.Super])
        ? 'priceWithIncrements'
        : 'name'
    }`,
  ) => {
    const propertyPrice = Numbers.currencyFormat(
      parseFloat(
        property.status === SalesRoomEnum.status.SOLD
          ? property.priceWithIncrementSold
          : property.priceWithIncrement,
      ).toFixed(0),
    );
    return (
      <div
        style={{
          backgroundColor: buttons.backgroundColor,
          padding: '0.01em',
          textAlign: 'center',
          position: 'relative',
        }}
        onClick={() => this.onClickSelector(property, buttons)}
      >
        {active === 'name' ? (
          <p
            style={{ fontWeight: 'bold', color: 'White' }}
            data-tip={propertyPrice}
          >
            {active === 'mts2' && parseFloat(property.mts2).toFixed(2)}
            {active === 'priceWithIncrements' && (
              <NumberFormat
                value={parseFloat(
                  property.status === SalesRoomEnum.status.SOLD
                    ? property.priceWithIncrementSold
                    : property.priceWithIncrement,
                ).toFixed(0)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            )}
            {active === 'price' && (
              <NumberFormat
                value={parseFloat(property.price).toFixed(0)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            )}
            {active === 'groups' && property.groupName}
            {property.requestStatus === 'R' && (
              <span className={Styles.rejectBadge}>R</span>
            )}
            {property.requestStatus === 'D' && (
              <span className={Styles.rejectBadge}>D</span>
            )}
            {active === 'name' && property.name}
          </p>
        ) : (
          <p
            style={{ fontWeight: 'bold', color: 'White' }}
            data-tip={property.name}
          >
            {active === 'mts2' && parseFloat(property.mts2).toFixed(2)}
            {active === 'priceWithIncrements' && (
              <NumberFormat
                value={parseFloat(
                  property.status === SalesRoomEnum.status.SOLD
                    ? property.priceWithIncrementSold
                    : property.priceWithIncrement,
                ).toFixed(0)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            )}
            {active === 'type' ? this.getType(property.type) : ''}
            {active === 'price' && (
              <NumberFormat
                value={parseFloat(property.price).toFixed(0)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            )}
            {active === 'groups' && property.groupName}
            {property.requestStatus === 'R' && (
              <span className={Styles.rejectBadge}>R</span>
            )}
            {property.requestStatus === 'D' && (
              <span className={Styles.rejectBadge}>D</span>
            )}
          </p>
        )}
        <ReactTooltip data-html={true} html={true} />
      </div>
    );
  };

  mapResponseToProperties = (response, active) => {
    const data = response;
    let matrix = [];
    if (data && data.floors !== null) {
      matrix = this.createNullMatrix(data.floors, data.totalProperties);
      if (data.properties) {
        data.properties.forEach((row) => {
          row.forEach((property) => {
            const buttons = this.buttonsStyles(
              property.status,
              property.clientBadge,
            );
            matrix[property.floor - data.lowestFloor][property.location - 1] =
              this.makeCells(buttons, property, active);
          });
        });
      }
    }
    return { data, matrix };
  };

  makeArrayOfProperties(properties, active) {
    if (properties && properties.floors !== null) {
      const { data, matrix } = this.mapResponseToProperties(properties, active);

      this.setState({
        response: properties,
        properties: data.totalProperties,
        floors: data.floors,
        lowestFloor: data.lowestFloor,
        data: matrix,
        isEmpty: false,
        lastSelector: active,
      });
    } else {
      this.setState({ isEmpty: true });
    }
  }

  findGroup = (properties) => {
    try {
      const flatGroup = properties.flatMap((group) => group);
      return flatGroup.filter((group) => group.groupId === this.state.groupId);
    } catch (error) {
      this.props.enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  calculateCollectedIncrement(status) {
    const groups = this.state.originalPropertiesResponse.properties;
    let properties = groups[0];
    if (groups.length > 1) {
      properties = this.findGroup(groups);
    }
    return properties.reduce((current, next) => {
      let { price } = next;
      const { manualPrice } = next;
      // Assigning manual price
      price = price === 0 && manualPrice > 0 ? manualPrice : price;
      // Default increment
      let increment = next.priceSold - price;
      if (
        next.groupId === this.state.groupId &&
        next.status === Status.Sold &&
        next.id !== this.state.id
      ) {
        current += increment;
      } else if (next.id === this.state.id) {
        increment = this.state.priceSold - price;
        current += increment;
      }
      return current;
    }, 0);
  }

  save = (showModalSelectedProperty) => async () => {
    if (!showModalSelectedProperty) {
      const groupToGo =
        Number(this.state.selectedProperty.groupName) ||
        this.state.selectedProperty.groupName;
      this.props.onChangeGroup(groupToGo);
      this.props.history.push(
        `${DashboardRoutes.base}${DashboardRoutes.strategy.value}${this.props.match.params.towerId}`,
      );
      return;
    }

    if (this.props.match.params.clientId === '-') {
      this.props.enqueueSnackbar('Primero debe seleccionar un cliente');
      return;
    }
    const { showModalSelectedProperty: renderValidation } =
      this.modalRenderValidation();
    if (
      this.state.selectedProperty.status !== SalesRoomEnum.status.AVAILABLE &&
      this.state.selectedProperty.addedAdditionalAreas.length === 0 &&
      this.state.originalPropertyStatus !==
        this.state.selectedProperty.status &&
      !renderValidation
    ) {
      this.setState({ isSavingErrorActive: true });
      return;
    }

    const collectedIncrement = this.calculateCollectedIncrement(
      this.state.rightButton.label,
    );

    if (
      this.state.originalPropertyStatus === 'OPTIONAL' &&
      this.state.selectedProperty.status === 'AVAILABLE' &&
      !!this.state.clientInQueue
    ) {
      this.services.deleteClienToWaitingList({
        clientId: this.state.clientInQueue?.clientId,
        propertyId: this.state.selectedProperty.id,
      });
    }

    const closingReason =
      this.state.originalPropertyStatus !== 'SOLD' &&
      this.state.selectedProperty.status === 'SOLD'
        ? await modelCRMReft.current.show()
        : undefined;

    // Important: This sends the collected increment to update into database
    const price = this.state.selectedProperty.price;
    const manualPriceOrPrice =
      price === 0 && this.state.selectedProperty.manualPrice > 0
        ? this.state.selectedProperty.manualPrice
        : price;
    const propertyCollectedIncrement =
      this.state.selectedProperty.priceWithIncrement - manualPriceOrPrice;

    this.setState({ isLoadingModal: true });
    this.services
      .putState(
        {
          id: this.state.selectedProperty.id,
          status: this.state.selectedProperty.status,
          priceSold:
            this.state.selectedProperty.status !==
            SalesRoomEnum.status.AVAILABLE
              ? this.state.selectedProperty.priceWithIncrement
              : null,
          discount:
            this.state.selectedProperty.status !==
            SalesRoomEnum.status.AVAILABLE
              ? this.state.selectedProperty.discount
              : null,
          tradeDiscount:
            this.state.selectedProperty.status !==
            SalesRoomEnum.status.AVAILABLE
              ? this.state.selectedProperty.tradeDiscount
              : null,
          optionalDescription: this.state.optionalDescription,
          collectedIncrement,
          groupId: this.state.groupId,
          deadlineDate: Number(moment(this.state.deadlineDate).format('x')),
          addedAdditionalAreas:
            this.state.selectedProperty.addedAdditionalAreas,
          propertyCollectedIncrement,
          mts2: this.state.selectedProperty.mts2,
          closingReason,
        },
        this.props.match.params.towerId,
        this.props.match.params.clientId,
      )
      .then((response) => {
        const { incrementList } = response.data;
        if (incrementList) {
          this.makeArrayOfProperties(incrementList, this.state.lastSelector);
        }

        const { data: originalPropertiesResponse } =
          this.mapResponseToProperties(
            incrementList || {},
            this.state.lastSelector,
          );
        this.isModalShowingStatesContent = false;
        this.setState({
          originalPropertiesResponse,
          isOpen: false,
          isLoadingModal: false,
          optionalDescription: '',
          selectedProperty: { clientId: this.props.match.params.clientId },
        });
        this.updateAdditionalAreas();
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
        this.updateAdditionalAreas();
        this.setState({ isLoadingModal: false });
      });
  };

  cancel = () => {
    this.isModalShowingStatesContent = false;
    this.setState({
      isOpen: false,
      isSavingErrorActive: false,
      selectedProperty: {},
    });
    this.updateAdditionalAreas();
  };

  createNullMatrix = (m, n) => {
    return Array(m)
      .fill(null)
      .map(() => Array(n).fill(null));
  };

  isThereOneAvailableProperty = (selectedProperty, propertiesMatrix) => {
    const propertiesArray = propertiesMatrix.find(
      (propertiesByGroup) =>
        propertiesByGroup.length > 0 &&
        propertiesByGroup[0].groupId === selectedProperty.groupId,
    );

    if (propertiesArray) {
      return (
        propertiesArray.filter(
          (property) => property.status === Status.Available,
        ).length === 1
      );
    }
    return false;
  };

  deadlineDateHandler = (value) => {
    this.setState({
      deadlineDate: value,
      isInvalidDeadlineDate: value < moment().endOf('day'),
    });
  };

  optionalDescript = (element) => {
    const optionalDescription = element.target.value;
    this.setState({ optionalDescription });
  };

  changeClientInQueue = (clientInQueue) => {
    this.setState({ clientInQueue });
  };

  addAdditionalArea = (id) => {
    return this.services
      .getAdditionalArea(id, this.props.match.params.towerId)
      .then((response) => {
        const { measure, price } = response.data;
        this.setState((prevState) => {
          const tempProperty = { ...prevState.selectedProperty };
          response.data.unitPrice = measure * price;
          tempProperty.addedAdditionalAreas.push(response.data);
          const tempAdditionalAreas = prevState.additionalAreas.filter(
            (additionalArea) => additionalArea.id !== id,
          );
          return {
            selectedProperty: tempProperty,
            additionalAreas: _.sortBy(tempAdditionalAreas, [
              { areaType: 'name' },
              'id',
            ]),
          };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  deleteAdditionalArea = (area) => {
    this.setState((prevState) => {
      const tempProperty = { ...prevState.selectedProperty };
      const tempAdditionalAreas = [...prevState.additionalAreas, area];
      tempProperty.addedAdditionalAreas =
        tempProperty.addedAdditionalAreas.filter(
          (additionalArea) => additionalArea.id !== area.id,
        );
      return {
        selectedProperty: tempProperty,
        additionalAreas: _.sortBy(tempAdditionalAreas, [
          { areaType: 'name' },
          'id',
        ]),
      };
    });
  };

  updateAdditionalAreas = () => {
    this.services
      .getAdditionalAreas(this.props.match.params.towerId)
      .then((response) => {
        const additionalAreasSorted = response.data.sort((a, b) => {
          if (a.areaType.id === b.areaType.id) {
            return a.nomenclature.localeCompare(b.nomenclature);
          }
          return a.areaType.name.localeCompare(b.areaType.name);
        });
        this.setState({ additionalAreas: additionalAreasSorted });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCloseGroupingRedirect = () => {
    this.setState({ openGrouping: false });
  };

  handleCloseGraphicmaterial = () => {
    this.setState({ openGraphicMaterialDialog: false });
  };

  modalRenderValidation = () => {
    const isStrategyNull =
      this.state.selectedProperty.isReset ||
      !this.state.selectedProperty.strategy;

    let showModalSelectedProperty = !isStrategyNull;
    let strategyIsAvailable = true;

    if (
      isStrategyNull &&
      this.state.selectedProperty &&
      this.state.selectedProperty.increment < 0 &&
      this.state.selectedProperty.strategy == null
    ) {
      const isThereOneProperty = this.isThereOneAvailableProperty(
        this.state.selectedProperty,
        this.state.response.properties,
      );
      showModalSelectedProperty = isThereOneProperty;
    }

    if (isStrategyNull && this.state.isLastProperty) {
      showModalSelectedProperty = true;
    }

    strategyIsAvailable = showModalSelectedProperty;
    if (
      isStrategyNull &&
      (this.state.selectedProperty.status === Status.Optional ||
        this.state.selectedProperty.status === Status.Available)
    ) {
      strategyIsAvailable = showModalSelectedProperty;
      showModalSelectedProperty = true;
    }

    return { showModalSelectedProperty, strategyIsAvailable };
  };

  clientValidation = () => {
    return (
      this.state.selectedProperty.clientId ===
        this.props.match.params.clientId ||
      this.state.selectedProperty.clientId === null
    );
  };

  updateInitialStatus = () => {
    this.isModalShowingStatesContent = true;
  };

  closeDesistDialog = () => {
    this.setState({ openDesistDialog: false });
  };

  saveNewApartmentState = (showModalSelectedProperty) => () => {
    if (
      this.state.originalPropertyStatus === 'SOLD' &&
      this.state.selectedProperty.status === 'AVAILABLE'
    ) {
      this.setState({ openDesistDialog: true });
    } else {
      this.save(showModalSelectedProperty)();
    }
  };

  render() {
    let { showModalSelectedProperty, strategyIsAvailable } =
      this.modalRenderValidation();
    if (
      this.state.selectedProperty.status === SalesRoomEnum.status.SOLD ||
      this.isModalShowingStatesContent
    ) {
      showModalSelectedProperty = true;
    }

    if (this.state.originalPropertiesResponse.properties) {
      const collectedIncrement = this.calculateCollectedIncrement();
      console.log({ collectedIncrement });
    }

    return (
      <>
        <CRMSelectClosignReasonPromiseModal ref={modelCRMReft} />
        <LoadableContainer isLoading={this.state.isLoading}>
          {this.state.isEmpty && (
            <Message route={this.props.match.params.towerId} />
          )}
          {!this.state.isEmpty && (
            <Card>
              <CardHeader>
                <div className={Styles.TitleContainer}>
                  <p>Propiedades</p>
                  {this.state.clientName ? (
                    <p>Cliente: {this.state.clientName}</p>
                  ) : (
                    <p>No se ha seleccionado ningun cliente</p>
                  )}
                  <ExcelSalesRoomPopover />
                </div>
              </CardHeader>
              <CardBody>
                <Filters
                  properties={this.state.originalPropertiesResponse}
                  propertyTypes={this.state.propertyTypes}
                  makeArrayOfProperties={this.makeArrayOfProperties}
                  userType={
                    agent.isAuthorized([Role.Admin, Role.Super])
                      ? 'super'
                      : 'staff'
                  }
                />
                <Selectors
                  makeArrayOfProperties={this.makeArrayOfProperties}
                  response={this.state.response}
                  buttonsStyles={this.buttonsStyles}
                  makeCells={this.makeCells}
                  agent={
                    agent.isAuthorized([Role.Admin, Role.Super])
                      ? 'super'
                      : 'staff'
                  }
                />
                <PropertiesTable
                  properties={this.state.properties}
                  propertyTypes={this.state.propertyTypes}
                  floors={this.state.floors}
                  lowestFloor={this.state.lowestFloor}
                  data={this.state.data}
                  headers={this.state.headers}
                  verticalHeaders={this.state.verticalHeaders}
                  arrayOfProperties={this.state.response.properties}
                  typeSelected={this.state.lastSelector}
                  onClickSelector={this.onClickSelector}
                />
              </CardBody>
              <CardFooter />
              <Dialog
                open={this.state.isOpen}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="lg"
              >
                <DialogTitle>
                  <div className={Styles.dialogHeader}>
                    <span>
                      {`Nuevo Estado - ${this.state.selectedProperty.name}`}
                    </span>
                    {this.state.selectedProperty.requestStatus === 'R' && (
                      <span className={Styles.rejectedLabel}>RECHAZADO</span>
                    )}
                    <button
                      className={Styles.graphicMaterialButton}
                      onClick={() => {
                        this.setState({ customOpenDialog: true });
                      }}
                    >
                      Cotizaciones
                    </button>
                    {this.state.customOpenDialog && (
                      <PropertiesQuotationsDialogMainView
                        clientId={this.state.clientId}
                        propertyId={this.state.selectedProperty.id}
                        customOpenState={this.state.customOpenDialog}
                        customCloseHandler={this.customCloseDialog}
                      />
                    )}

                    <button
                      className={Styles.graphicMaterialButton}
                      onClick={() => {
                        this.setState({
                          openGraphicMaterialDialog: true,
                          isOpen: false,
                        });
                      }}
                    >
                      Material gráfico
                    </button>
                  </div>
                </DialogTitle>
                <DialogContent>
                  {!showModalSelectedProperty &&
                    'Debe escoger una estrategia para poder vender los apartamentos de este grupo 📈'}
                  {showModalSelectedProperty && this.state.isLoadingModal && (
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                      <Loader
                        type="ThreeDots"
                        color={variables.mainColor}
                        height="100"
                        width="100"
                      />
                    </div>
                  )}
                  {showModalSelectedProperty &&
                    !this.state.isLoadingModal &&
                    (this.clientValidation() ? (
                      <SalesRoomModal
                        isOpen={this.state.isOpen}
                        isLast={this.state.isLastProperty}
                        property={this.state.selectedProperty}
                        onChange={this.propertyHandler}
                        deadlineDate={this.state.deadlineDate}
                        onChangeDeadlineDate={this.deadlineDateHandler}
                        optionalDescription={this.optionalDescript}
                        optionalDescript={this.state.optionalDescription}
                        clientId={this.props.match.params.clientId}
                        additionalAreas={this.state.additionalAreas}
                        addAdditionalAreaHandler={this.addAdditionalArea}
                        deleteAdditionalAreaHandler={this.deleteAdditionalArea}
                        towerId={this.props.match.params.towerId}
                        spawnMessage={this.props.spawnMessage}
                        clientId={this.props.match.params.clientId}
                        isSavingErrorActive={this.state.isSavingErrorActive}
                        strategyIsAvailable={strategyIsAvailable}
                        updateInitialStatus={this.updateInitialStatus}
                        originalPropertyStatus={
                          this.state.originalPropertyStatus
                        }
                        changeClientInQueue={this.changeClientInQueue}
                        additionalAreaStateHandler={
                          this.additionalAreaStateHandler
                        }
                        openQuotationListHandler={this.openQuotationListHandler}
                        updateAdditionalDesistedArea={
                          this.updateAdditionalDesistedArea
                        }
                      />
                    ) : (
                      'El apartamento seleccionado no le pertenece a este cliente'
                    ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.cancel} className={Styles.CancelButton}>
                    {this.state.selectedProperty.status ===
                    SalesRoomEnum.status.QUOTE
                      ? 'Cerrar'
                      : 'Cancelar'}
                  </Button>
                  {(this.state.selectedProperty.clientId ===
                    this.props.match.params.clientId ||
                    this.state.selectedProperty.clientId === null) &&
                    this.state.selectedProperty.status !==
                      SalesRoomEnum.status.QUOTE && (
                      <Button
                        onClick={this.saveNewApartmentState(
                          showModalSelectedProperty,
                        )}
                        className={Styles.ConfirmButton}
                        isDisabled={
                          this.state.additionalAreaIsSelected ||
                          this.state.selectedProperty.requestStatus === 'D' ||
                          (this.state.selectedProperty.status ===
                            SalesRoomEnum.status.OPTIONAL &&
                            this.state.isInvalidDeadlineDate)
                        }
                      >
                        Aceptar
                      </Button>
                    )}
                </DialogActions>
                <Dialog
                  open={this.state.openDesistDialog}
                  onClose={this.closeDesistDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Señor usuario
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      ¿Está seguro que desea desistir este apartamento?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeDesistDialog}
                      className={Styles.CancelButton}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => {
                        this.save(showModalSelectedProperty)();
                        this.setState({ openDesistDialog: false });
                      }}
                      className={Styles.ConfirmButton}
                      autoFocus
                    >
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Dialog>
            </Card>
          )}
          <AlertRedirect
            open={this.state.openGrouping}
            message={'Debes agrupar las áreas antes de vender.'}
            route={`${DashboardRoutes.base}${DashboardRoutes.clustering.value}${this.props.match.params.towerId}`}
            handleClose={this.handleCloseGroupingRedirect}
          />
        </LoadableContainer>
        {this.state.openGraphicMaterialDialog && (
          <GraphicMaterialDialog
            openGrapicMaterial={this.state.openGraphicMaterialDialog}
            closeFunction={this.handleCloseGraphicmaterial}
            propertyInfo={this.state.selectedProperty}
            showAddAndDelete={false}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  if (validateSelectedGroup(state.strategy.root)) {
    return {};
  }
  return {
    validGroup: state.strategy.root.selectedGroup,
  };
};

const mapDispatchToProps = {
  onChangeGroup: changeGroup,
  onFilterClean: filterClean,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDefaultLayout(withSnackbar(SalesRoom)));
