import React, { FC, RefObject } from 'react';
import { Box, BoxProps, Popover, PopoverProps } from '@material-ui/core';
import { useCustomPopoverImplementation } from 'shared/customHooks/useCustomPopover';
import styles from './CustomPopover.module.scss';

export interface CustomPopoverContentProps<P extends {}, T extends {} = {}> {
  payload?: T;
  extraProps: P;
  onClose: () => void;
}

export interface Props<P extends {}, T extends {}> extends Partial<PopoverProps> {
  contentComponent: FC<CustomPopoverContentProps<P, T>>;
  elementRef?: RefObject<HTMLDivElement>;
  boxProps?: Partial<BoxProps>;
  extraProps: P;
}

const CustomPopover = <P extends {}, T extends {} = {}>(props: Props<P, T>) => {
  const { elementRef, contentComponent, boxProps, extraProps, ...popoverProps } = props;
  const { isOpen, payload, element, close } = useCustomPopoverImplementation<T>({ elementRef });

  return (
    <div className={styles.container} ref={elementRef}>
      <Popover
        {...popoverProps}
        anchorEl={element}
        open={isOpen}
        onClose={props.onClose ? props.onClose : close}
        className={styles.popper}
      >
        <Box paddingY="8px" width="180px" display="flex" flexDirection="column">
          {contentComponent({ payload, extraProps, onClose: close })}
        </Box>
      </Popover>
    </div>
  );
};

export default CustomPopover;
