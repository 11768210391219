import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import styled from 'styled-components';

import table from '../../assets/styles/components/table.module.scss';
import colors from '../../assets/styles/settings/colors.module.scss';
import fonts from '../../assets/styles/settings/fonts.module.scss';

const TableHeaderRowCell: React.FC = styled(TableHeaderRow.Cell)<TableHeaderRow.CellProps>`
  /* Box-model */
  padding: 11px 11px 11px ${table.cellWidhtPadding};

  /* Typography */
  color: ${colors.gray900};
  font-family: ${fonts.body};
  font-size: ${fonts['body1-font-size']};
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;

  /* Visual */
  background: ${colors.primary100};
  border-bottom: 2px solid rgba(105, 133, 161, 0.24);

  &:hover {
    /* Visual */
    background: ${colors.primary200};

    & .MuiTableSortLabel-root,
    & .MuiTableSortLabel-root:hover {
      /* Typograpy */
      color: ${colors.gray900};
    }
  }

  .MuiTableSortLabel-root {
    /* Box-model */
    display: flex;
    justify-content: space-between;
  }

  .MuiTableSortLabel-root:focus,
  .MuiTableSortLabel-root.MuiTableSortLabel-active,
  .MuiTableSortLabel-root.MuiTableSortLabel-active * {
    /* Visual */
    outline: none;
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    /* Typograpy */
    color: ${colors.gray900};
  }

  .MuiTableSortLabel-icon {
    /* Positioning */
    position: relative;
    bottom: 1px;

    /* Box-model */
    margin: 0;
    width: 20px;
    height: 20px;

    /* Visual */
    fill: currentColor;
  }

  .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
    /* Visual */
    opacity: 1;
  }
`;

export default TableHeaderRowCell;
