import { useEffect, useState } from "react"
import useIsPurchaseConfirmed from './useIsPurchaseConfirmed'

const useCheckboxConfirm = () => {
    const { isPurchaseConfirmed, setPurchaseConfirmed } = useIsPurchaseConfirmed()
    const [isConfirm, setIsConfirm] = useState(false)
    const [label, setLabel] = useState()
    
    const onChange = (e) => {
        if (e.target.checked) setPurchaseConfirmed()
        setIsConfirm(e.target.checked)
    }

    useEffect(() => {
        setIsConfirm(isPurchaseConfirmed)
    }, [isPurchaseConfirmed])

    useEffect(() => {
        setLabel(isConfirm ? 'Compra confirmada' : 'Confirmar compra')
    }, [isConfirm])

    return {
        isConfirm,
        onChange,
        label
    }
}

export default useCheckboxConfirm