import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DocumentTemplatesServices from '../../Services/DocumentTemplatesServices';

const services = new DocumentTemplatesServices();

const useDocumentTemplatesBehavior = () => {
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [templates, setTemplates] = useState([]);
  const [addTemplateVisible, setAddTemplateVisible] = useState(false);
  const toggleAddTemplate = () => setAddTemplateVisible(!addTemplateVisible);
  useEffect(() => {
    // TODO: handle rejection
    services.getTemplates(towerId)
      .then(({ data }) => setTemplates(data));
  }, []);
  const deleteTemplate = async (id, name) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Desea eliminar ${name}?`)) {
      try {
        await services.deleteTemplate(id);
        setTemplates(
          templates.filter(template => template.id !== id)
        );
        enqueueSnackbar('Plantilla eliminada correctamente', {
          variant: 'success'
        });
      } catch (err) {
        console.error(`Error al eliminar plantilla: ${name}`, err.message);
        enqueueSnackbar(`Error al eliminar plantilla: ${name}`, {
          variant: 'error'
        });
      }
    }
  }
  const uploadTemplate = async (towerId, name, file) => {
    try {
      const result = await services.uploadTemplate(towerId, name, file);
      const { data } = result;
      setTemplates([...templates, data]);
      enqueueSnackbar(`Plantilla agregada correctamente`, {
        variant: 'success'
      });
      return true;
    } catch (err) {
      console.error(`Error al enviar plantilla: ${name}`, err.message);
      enqueueSnackbar(`Error al enviar plantilla: ${name}`, {
        variant: 'error'
      });
      return false;
    }
  }

  return {
    templates,
    addTemplateVisible,
    toggleAddTemplate,
    deleteTemplate,
    uploadTemplate
  };
}

export default useDocumentTemplatesBehavior;