import { useState } from 'react';
import { useSelector } from 'react-redux';

function useArrearsReportRowsMapper() {
  const { arrearsReportClients } = useSelector(
    (state) => state.arrearsReport || {},
  );

  const [leftColumns] = useState(['clientName']);

  const columns = [
    {
      width: 200,
      title: 'Comprador',
      name: 'clientName',
    },
    {
      width: 200,
      title: 'Pagado',
      name: 'accumulatedPayments',
    },
    {
      width: 200,
      title: 'Financieros',
      name: 'financialAccumulated',
    },
    {
      width: 200,
      title: 'Compromisos',
      name: 'commitment',
    },
    {
      width: 200,
      title: 'Saldo Compromisos',
      name: 'capitalBalance',
    },
    {
      width: 200,
      title: 'Cartera en Mora',
      name: 'arrears',
    },
    {
      width: 200,
      title: 'Dias Mora',
      name: 'arrearsDays',
    },
  ];

  const [tableColumnExtensions] = useState([
    { columnName: 'clientName', width: 350 },
    { columnName: 'commercialPrice', width: 150 },
    { columnName: 'accumulatedPayments', width: 150 },
    { columnName: 'financialAccumulated', width: 150 },
    { columnName: 'balanceCalculated', width: 150 },
    { columnName: 'commitment', width: 150 },
    { columnName: 'capitalBalance', width: 150 },
    { columnName: 'arrears', width: 150 },
    { columnName: 'arrearsDays', width: 100 },
  ]);

  const defaultSorting = useState([
    { columnName: 'clientName', direction: 'asc' },
    { columnName: 'commercialPrice', direction: 'asc' },
    { columnName: 'accumulatedPayments', direction: 'asc' },
    { columnName: 'financialAccumulated', direction: 'asc' },
    { columnName: 'balanceCalculated', direction: 'asc' },
    { columnName: 'commitment', direction: 'asc' },
    { columnName: 'capitalBalance', direction: 'asc' },
    { columnName: 'arrears', direction: 'asc' },
    { columnName: 'arrearsDays', direction: 'asc' },
  ]);

  const rows = arrearsReportClients.flatMap((client) => {
    if (!client.isArrears) {
      return [];
    }

    const clientWithNewData = {
      ...client,
      arrears: client.arrears * -1,
    };

    return clientWithNewData;
  });

  return { rows, columns, leftColumns, tableColumnExtensions, defaultSorting };
}

export default useArrearsReportRowsMapper;
