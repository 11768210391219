import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import validateSelectedGroup from '../../../StrategyV2/Shared/Validator';

const StrategySelect = ({ field, units, customFrequency, ...rest }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Estrategia</InputLabel>
      <Select {...field} {...rest} label="Estrategia">
        <MenuItem value={1}>Continua</MenuItem>
        <MenuItem value={3} disabled={units <= 3}>
          Semi-continua
        </MenuItem>
        <MenuItem value={9} disabled={units <= 9}>
          Semi-escalonada
        </MenuItem>
        <MenuItem value={18} disabled={units <= 18}>
          Escalonada
        </MenuItem>
        {customFrequency !== 0 && (
          <MenuItem value={customFrequency}>
            Personalizada ({customFrequency})
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

StrategySelect.propTypes = {
  units: PropTypes.number.isRequired,
  customFrequency: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  let group =
    state.strategy.root.strategyLines[state.strategy.root.selectedGroup];
  if (validateSelectedGroup(state.strategy.root)) {
    group = state.strategy.root.strategyLines
      ? state.strategy.root.strategyLines[0]
      : null;
    if (!group) return {};
  }

  const customFrequency = group ? group.customFrequency : null;

  return {
    customFrequency,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StrategySelect);
