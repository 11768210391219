import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { CheckOutlined, Close, InfoOutlined, ReportProblemOutlined } from '@material-ui/icons';
import Typography from 'shared/components/Typography/Typography';
import useBoolean from 'shared/customHooks/useBoolean';

import styles from './Message.module.scss';

interface MessageProps {
  state: 'success' | 'warning' | 'info';
  size?: 'small' | 'medium' | 'large';
  text?: string;
  closable?: boolean;
}

const Message: FC<MessageProps> = ({ state, text, closable, children, size }) => {
  const { value, setFalse } = useBoolean(true);
  return (
    <>
      {value && (
        <div className={`${styles.Message} ${styles[state]} ${styles[size ?? 'medium']}`}>
          {state === 'warning' && <ReportProblemOutlined className={`${styles.Icon} ${styles.IconWaring}`} />}
          {state === 'info' && <InfoOutlined className={`${styles.Icon} ${styles.IconInfo}`} />}
          {state === 'success' && <CheckOutlined className={`${styles.Icon} ${styles.IconSuccess}`} />}
          <div>
            <Typography variant="body1">{children || text}</Typography>
          </div>
          <div className={styles.close}>
            {closable && (
              <IconButton onClick={setFalse}>
                <Close />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
