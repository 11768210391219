import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Typography from '../../../../shared/components/Typography/Typography';
import IncomePaymentSegment from './IncomePaymentSegment';
import useSegmentedControlHandlingChange from '../../Core/customHooks/useSegmentedControlHandlingChange';
import IncomePaymentsSegmentedControlProps from '../../Core/models/IncomePaymentsSegmentedControlProps';

const IncomePaymentsSegmentedControl: FC<IncomePaymentsSegmentedControlProps> = ({
  options,
  onSegmentChange,
}) => {
  const { onClick, currentValue } = useSegmentedControlHandlingChange(
    options,
    onSegmentChange,
  );

  return (
    <Box display="flex">
      {options.map((option) => (
        <IncomePaymentSegment
          label={option.label}
          value={option.value}
          currentValue={currentValue}
          onButtonClick={onClick}
        />
      ))}
    </Box>
  );
};

export default IncomePaymentsSegmentedControl;
