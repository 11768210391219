import { useContext, useState } from 'react';

import DialogContext from '../../UI/Dialog/context';

function useRealPaymentEditionDiscountType() {
  const [isEdition, setEdition] = useState(false);
  const context = useContext(DialogContext);
  const { quotation: currentQuotation } = context || {};

  const editionToggle = () => {
    setEdition(!isEdition);
  };

  const canEdit =
    currentQuotation.isEditingReal && !currentQuotation.isPaymentEdition;

  return { isEdition: canEdit && isEdition, canEdit, editionToggle };
}

export default useRealPaymentEditionDiscountType;
