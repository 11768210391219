import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Services from '../../../services/clientProperty/clientPropertyServices';
import styles from './styles.module.scss';
import SalesRoomEnum from '../../../containers/SalesRoom/SalesRoom.enum';
import QuotationsList from '../../Client2/List/Row/QuotationsList/QuotationsList';

const services = new Services();

const CoOwners = ({
  towerId,
  property,
  currentState,
  updateCurrentQuotation,
  propertyStatus,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]);
  const cleanClients = (clientArray) => {
    const tempClean = clientArray.reduce((prev, current) => {
      if (current.properties.length >= 1) {
        return [...prev, current];
      }
      return [...prev, null];
    }, []);
    return tempClean.filter((n) => n);
  };

  const clientsInProperty = (sortedClients, propertyId) => {
    return sortedClients.flatMap((client) => {
      const isClientAtProperty = client.properties.find(
        (item) => Number(item.propertyId) === propertyId,
      );
      if (isClientAtProperty) {
        return {
          name: client.name,
          property: isClientAtProperty,
          id: client.id,
          currentQuotation: client.currentQuotation,
        };
      }
      return [];
    });
  };

  async function fetch() {
    try {
      setClients([])
      const clientsReceived = await services.getClientsFromPropertyId(
        towerId,
        property,
      );
      if (clientsReceived.data.length >= 1) {
        const cleanedClients = cleanClients(clientsReceived.data);
        const clientsInPropertyArray = clientsInProperty(
          cleanedClients,
          property,
        );
        const sortedClients = _.orderBy(
          clientsInPropertyArray,
          [(currentClient) => Number(currentClient.property.percentage)],
          ['desc'],
        );
        updateCurrentQuotation(
          cleanedClients.find((client) => client.currentQuotation),
        );
        setClients(sortedClients);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (currentState && currentState !== SalesRoomEnum.status.AVAILABLE) {
      fetch();
    }
  }, [currentState]);

  return (
    <>
      {currentState !== SalesRoomEnum.status.AVAILABLE &&
        propertyStatus === SalesRoomEnum.status.SOLD && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Copropietarios de este apartamento
                </Typography>
              </Grid>
              {clients.flatMap((client) => (
                <Grid container>
                  <Box>
                    <Typography variant="body1" className={styles.coOwnerName}>
                      {`${client.name} `}
                    </Typography>
                  </Box>
                  <Box flexGrow={1}>
                    <Typography variant="body1" color="primary">
                      {client.property.percentage || 100}%
                    </Typography>
                  </Box>
                  <Box>
                    {currentState === SalesRoomEnum.status.SOLD && (
                      <QuotationsList
                        clientId={client.id}
                        propertyId={client.property.propertyId}
                        currentQuotation={client.currentQuotation}
                        fetchUserList={fetch}
                      />
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

CoOwners.propTypes = {
  towerId: PropTypes.string,
  property: PropTypes.number,
  currentState: PropTypes.any,
  propertyStatus: PropTypes.string,
  updateCurrentQuotation: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoOwners);
