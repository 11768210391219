import { HANDLE_CHANGE_PREV_REPORTS, HANDLE_CHANGE_REPORTS } from './actions';

const initialState = {
  reports: [],
  prevReports: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HANDLE_CHANGE_PREV_REPORTS:
      return { ...state, prevReports: payload };

    case HANDLE_CHANGE_REPORTS:
      return { ...state, reports: payload };

    default:
      return state;
  }
};
