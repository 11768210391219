import { useContext, useEffect, useState } from 'react';
import IsEditableContext from '../../../../components/Client2/List/Row/QuotationViewer/Dialog/Payments/contexts';

export default function useQuotationPaymentTypeEdition(
  index,
  typeId,
  quotation,
  updateQuotation,
) {
  const isEditingModeFromContext = useContext(IsEditableContext);
  const isEditingMode =
    isEditingModeFromContext || (quotation.temp && !quotation.isAssigned);

  const [paymentSelected, setCurrentPayment] = useState(typeId);

  const onChange = (event) => {
    const paymentTypesCopy = [...quotation.paymentTypes];
    paymentTypesCopy[index] = Number(event.target.value);
    setCurrentPayment(event.target.value);
    updateQuotation({ ...quotation, paymentTypes: paymentTypesCopy });
  };

  useEffect(() => {
    if (typeId && paymentSelected !== typeId) {
      setCurrentPayment(typeId);
    }
  }, [typeId]);

  return {
    options: quotation.quotationSetup.quotationPaymentTypes || [],
    paymentSelected,
    isEditing: isEditingMode,
    onChange,
  };
}
