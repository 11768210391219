import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styles from './QuotationConfigActionChip.module.scss';
import { QuotationPaymentType } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';

interface Props extends QuotationPaymentType {
  onClick: (id: number | string) => void;
}

const QuotationConfigActionChip: FC<Props> = ({ id, text, onClick }) => (
  <div className={styles.Chip}>
    <span>{text}</span>
    <IconButton className={styles.Button} onClick={() => onClick(id)}>
      <Close fontSize="small" />
    </IconButton>
  </div>
);

export default QuotationConfigActionChip;
