import React, { FC } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import { Numbers } from '../../../../../helpers';
import { EvalproDeedExpensesDeedValueProviderProps } from '../../../core/interfaces/EvalproDeedExpensesDeedValueProviderProps';

const DataTypeFormater =
  ({ units, deedValue }: EvalproDeedExpensesDeedValueProviderProps): FC<DataTypeProvider.ValueFormatterProps> =>
  (props) =>
    (
      <NumberFormat
        value={Math.ceil(+Numbers.toFixed((deedValue / (units ?? 1)) * props.row.stock ?? 1))}
        displayType="text"
        prefix="$"
        thousandSeparator
      />
    );

const EvalproDeedExpensesDeedValueProvider: FC<EvalproDeedExpensesDeedValueProviderProps> = (props) => {
  return <DataTypeProvider {...props} formatterComponent={DataTypeFormater(props)} />;
};

export default EvalproDeedExpensesDeedValueProvider;
