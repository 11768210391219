import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import useEvalproDeedExpenses from 'App/DeedExpenses/core/customHooks/useEvalproDeedExpenses';
import EvalproDeedExpensesSwitch from '../../sections/EvalproDeedExpensesSwitch/EvalproDeedExpensesSwitch';
import EvalproDeedExpensesConstructionEnd from '../EvalproDeedExpensesConstructionEnd/EvalproDeedExpensesConstructionEnd';
import DeedExpensesCard from '../../components/DeedExpensesCard/DeedExpensesCard';
import EvalproDeedExpensesDeedDate from '../EvalproDeedExpensesDeedDate/EvalproDeedExpensesDeedDate';
import EvalproDeedExpensesDeedMonths from '../EvalproDeedExpensesDeedMonths/EvalproDeedExpensesDeedMonths';
import styles from './EvalproDeedExpensesMainContainer.module.scss';

const EvalproDeedExpensesMainContainer: FC = () => {
  const { towerId, config, schedule, isLoading, toggleAVGDeedDate } = useEvalproDeedExpenses();
  return (
    <div className={styles.EvalproDeedExpensesMainContainer}>
      {isLoading ? (
        <div className={styles.Loading}>
          <CircularProgress size={20} />
        </div>
      ) : null}
      <EvalproDeedExpensesSwitch onChange={toggleAVGDeedDate} isAVGDeedDate={!!config?.isDeedExpensesAVG} />
      {!!config && !!schedule && (
        <>
          {!!config?.isDeedExpensesAVG ? (
            <>
              <EvalproDeedExpensesConstructionEnd
                towerId={towerId}
                endOfSalesDate={schedule.endOfSalesDate}
                averageDeliveryDisplacement={config.averageDeliveryDisplacement}
              />
            </>
          ) : (
            <DeedExpensesCard maxWidth="980px" minWidth="980px" marginTop="24px" marginBottom="50px">
              <EvalproDeedExpensesDeedDate
                towerId={towerId}
                endOfSalesDate={schedule.endOfSalesDate}
                displacementStartDateDeed={config.displacementStartDateDeed}
              />
              <EvalproDeedExpensesDeedMonths
                towerId={towerId}
                endOfSalesDate={schedule.endOfSalesDate}
                displacementStartDateDeed={config.displacementStartDateDeed}
                displacementEndDateDeed={config.displacementEndDateDeed}
              />
            </DeedExpensesCard>
          )}
        </>
      )}
    </div>
  );
};

export default EvalproDeedExpensesMainContainer;
