import _ from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import SalesRoomEnum from '../SalesRoom.enum';

export default function useSelects(salesRoom) {
  const [availability, setAvailability] = useState([]);
  const [groups, setGroups] = useState([]);
  const [typeIds, setTypeIds] = useState([]);

  const salesRoomStatus = useCallback(() =>
    Object.keys(SalesRoomEnum).flatMap(
      (key) => ({
        ...SalesRoomEnum[key],
      }),
      [SalesRoomEnum],
    ),
  );

  const getValuesFromGroupSalesRoom = (properties) => {
    const repeatedSelectors = properties.map((property) => ({
      value: property.groupName,
      code: property.groupId,
    }));
    return _.uniqBy(repeatedSelectors, (e) => e.code);
  };

  const getValuesFromSalesRoom = (properties, selectorName) => {
    const repeatedSelectors = properties.flatMap(
      (property) => property[selectorName],
    );
    return _.uniqBy(repeatedSelectors, (e) => e);
  };

  const main = () => {
    const properties = salesRoom.properties
      ? salesRoom.properties.flatMap((p) => p)
      : [];
    const status = salesRoomStatus();
    const types = getValuesFromSalesRoom(properties, 'type');
    setTypeIds(types.filter((t) => t !== null));
    const groupOptions = getValuesFromGroupSalesRoom(properties);
    setAvailability(status);
    setGroups(groupOptions.length < groups.length ? groups : groupOptions);
  };

  useEffect(() => {
    main();
  }, [salesRoom]);

  return { availability, groups, typeIds };
}
