import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import {
  onSetAllPartners,
  onSetPartnerSelected,
  onSetCompanySelected,
  onSetCompaniesToSelect,
} from './actions';
import Services from '../Services/BusinessPartnersServices';
import ProjectServices from '../../../services/Projects/ProjectServices';

const services = new Services();
const projectServices = new ProjectServices();

export default function useBusinessPartner() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    partners,
    newPartner,
    selectedPartner,
    isCompanySelected,
    availableCompanies,
    companyId,
  } = useSelector((state) => state.businessPartnerCreator);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isUploadingFile, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);

  const getCompanies = (arrOfProjects, companies) => {
    const filteredByCompanyId = arrOfProjects.flatMap(
      (project) => project.companyId || [],
    );
    const resultOfCompanies = [...new Set(filteredByCompanyId)];
    const companiesResult = resultOfCompanies.flatMap((current) => {
      return companies.find((element) => {
        return element.id === current;
      });
    });
    return companiesResult;
  };

  const fetchPartners = async (companyIdSelected) => {
    setIsLoading(true);
    const response = await services.getBusinessPartnersInCompany(
      companyIdSelected,
    );
    dispatch(
      onSetAllPartners(_.sortBy(response.data, (prop) => prop.patnerName)),
    );
    setIsLoading(false);
  };

  async function fetch() {
    try {
      setIsLoading(true);
      const projectsResponse = await projectServices.getProjects();
      const companies = getCompanies(
        projectsResponse.data.projects,
        projectsResponse.data.companies,
      );
      dispatch(onSetCompaniesToSelect(companies));
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  const handleSelectPartner = (partnerParams) => () => {
    dispatch(onSetPartnerSelected({ isEmpty: true }));
    setTimeout(() => {
      dispatch(onSetPartnerSelected(partnerParams));
    }, 10);
  };

  const newBusinessPartnerActive = () => {
    const addingNewPartner = partners;
    addingNewPartner.unshift({
      ...newPartner,
      isNewPartner: true,
    });
    dispatch(onSetAllPartners(addingNewPartner));
  };

  const handleChangeSelection = async (e) => {
    dispatch(onSetCompanySelected(e.target.value));
    await fetchPartners(e.target.value);
  };

  const selectComapany = async (companyId) => {
    dispatch(onSetCompanySelected(companyId));
    await fetchPartners(companyId);
  };

  const downloadTemplate = async () => {
    try {
      const name = 'Plantilla_Esquema.xlsx';
      const response = await services.downloadBusinessPartnerTemplate();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.error(error);
    }
  };

  const onChangeFileHandler = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const onFileUpload = async () => {
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      const res = await services.postBusinessPartnerTemplate(
        companyId,
        formData,
      );

      setFile(null);
      enqueueSnackbar('Plantilla cargada correctamente', {
        variant: 'success',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      });
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      setFile(null);
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 5000,
        resumeHideDuration: 0,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        disableWindowBlurListener: true,
      });
    }
  };

  const onCancelUpload = () => {
    setFile(null);
  };

  const onAcceptUpload = async () => {
    await onFileUpload();
  };

  return {
    isLoading,
    isUploadingFile,
    partners,
    selectedPartner,
    handleSelectPartner,
    newBusinessPartnerActive,
    fetch,
    isCompanySelected,
    availableCompanies,
    handleChangeSelection,
    companyId,
    fetchPartners,
    selectComapany,
    downloadTemplate,
    onChangeFileHandler,
    onCancelUpload,
    onAcceptUpload,
    file,
  };
}
