import React from 'react';

import Typography from 'shared/components/Typography/Typography';
import { PhaseSummary } from '../../../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperStates from '../../../core/enums/SalesSpeedHelperStates';
import SalesSpeedHelperErrorIcon from '../SalesSpeedHelperErrorIcon/SalesSpeedHelperErrorIcon';
import SalesSpeedHelperSidenavFaseSummaryValue from '../SalesSpeedHelperSidenavFaseSummaryValue/SalesSpeedHelperSidenavFaseSummaryValue';

import styles from './SalesSpeedHelperSidenavFaseSummary.module.scss';

const isLoading = (viewStates: SalesSpeedHelperStates) => viewStates === SalesSpeedHelperStates.Loading;

const hasError = (viewStates: SalesSpeedHelperStates, phaseSummary?: PhaseSummary) =>
  !isLoading(viewStates) && !phaseSummary;

type Props = {
  className?: string;
  viewState: SalesSpeedHelperStates;
  title: string;
  phaseSummary?: PhaseSummary;
};

const SalesSpeedHelperSidenavFaseSummary: React.FC<Props> = ({ className, viewState, title, phaseSummary }) => (
  <div className={className}>
    <div className={styles.title}>
      <Typography variant="subtitle1" as="div" color="gray700">
        {title}
      </Typography>
      <SalesSpeedHelperErrorIcon isError={hasError(viewState, phaseSummary)} />
    </div>
    <div className={styles.summary}>
      <SalesSpeedHelperSidenavFaseSummaryValue title="Meses" value={phaseSummary?.months} />
      <SalesSpeedHelperSidenavFaseSummaryValue title="Unidades" value={phaseSummary?.units} />
      <SalesSpeedHelperSidenavFaseSummaryValue title="V.ventas" value={phaseSummary?.speed} />
    </div>
  </div>
);

export default SalesSpeedHelperSidenavFaseSummary;
