import React, { FC, RefObject } from 'react';
import CustomPopover, { CustomPopoverContentProps } from '../../../../../shared/components/CustomPopover/CustomPopover';
import useContractsPopoverActions, {
  UseContractsPopoverActionsProps,
} from '../../../core/customHooks/useContractsPopoverActions';
import Styles from '../../styles/Menu.module.scss';
import { Divider, MenuItem } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import { ContractLot } from '../../../../../models/Contracts';

type ContractsPopoverContentProps = CustomPopoverContentProps<UseContractsPopoverActionsProps, ContractLot>;

const ContractsPopoverContent: FC<ContractsPopoverContentProps> = (props) => {
  const { payload: contract, extraProps, onClose: cb } = props;
  const { goDetails, goEdit, goDelete } = useContractsPopoverActions({
    contract,
    cb,
    extraProps,
  });
  return (
    <>
      <span className={Styles.menuTopLeft}></span>
      <MenuItem
        onClick={goDetails}
        dense
        classes={{
          root: Styles.menuItem,
        }}
      >
        Ver contrato
      </MenuItem>
      <MenuItem
        onClick={goEdit}
        dense
        classes={{
          root: Styles.menuItem,
        }}
      >
        Editar
      </MenuItem>
      <Divider className={Styles.divider} />
      <MenuItem
        onClick={goDelete}
        dense
        classes={{
          root: Styles.menuItem,
        }}
      >
        <Typography color="stateError600">Eliminar</Typography>
      </MenuItem>
    </>
  );
};

interface Props extends UseContractsPopoverActionsProps {
  elementRef: RefObject<HTMLDivElement>;
}

const ContractsListPopover: FC<Props> = ({ elementRef, ...props }) => {
  return (
    <CustomPopover<UseContractsPopoverActionsProps, ContractLot>
      elementRef={elementRef}
      contentComponent={ContractsPopoverContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      extraProps={props}
      classes={{
        paper: Styles.menuRoot,
      }}
    />
  );
};

export default ContractsListPopover;
