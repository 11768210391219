import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';
import QuotationConfigLimitDateForm from '../../forms/QuotationConfigLimitDateForm/QuotationConfigLimitDateForm';
import useQuotationConfigMain from 'App/QuotationConfig/core/customHooks/useQuotationConfigMain';
import QuotationConfigFinalFeeForm from '../../forms/QuotationConfigFinalFeeForm/QuotationConfigFinalFeeForm';
import CustomerFinancingRateForm from '../../forms/CustomerFinancingRateForm';
import FinancialDiscountRate from '../../forms/FinancialDiscountRate';
import DeedExpenses from '../../forms/DeedExpenses/DeedExpenses';
import BankInformationForm from '../../forms/BankInformationForm/BankInformationForm';
import DisclaimerQuoteForm from '../../forms/DisclaimerQuoteForm';
import ConfigureDocumentTemplateForm from '../../forms/ConfigureDocumentTemplateForm/ConfigureDocumentTemplateForm';

const QuotationConfigMain: FC = () => {
  const { quotationConfig } = useQuotationConfigMain();
  return (
    <Container maxWidth="md">
      {quotationConfig ? (
        <>
          <QuotationConfigLimitDateForm {...quotationConfig} />
          <QuotationConfigFinalFeeForm {...quotationConfig} />
          <CustomerFinancingRateForm {...quotationConfig} />
          <FinancialDiscountRate {...quotationConfig} />
          <DeedExpenses {...quotationConfig} />
          <BankInformationForm {...quotationConfig} />
          <DisclaimerQuoteForm {...quotationConfig} />
          <Box paddingTop="15px">
            <ConfigureDocumentTemplateForm {...quotationConfig} />
          </Box>
        </>
      ) : (
        <Box />
      )}
    </Container>
  );
};

export default QuotationConfigMain;
