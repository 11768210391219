import React, { FC } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '../../../../../shared/components/Typography/Typography';
import { Contract, Category } from '../../../../../models/Contracts';

import Styles from '../../styles/ContractListGrid.module.scss';
import ContractsGrid from '../tables/ContractsGrid';

export interface IContractList {
  category: Category;
  onSeleccionChange: (contract: Contract) => void;
  onEditContract: (contract: Contract) => void;
  onDeleteContract: (contract: Contract) => void;
  setExpanded: () => void;
}

const ContractList: FC<IContractList> = ({
  category,
  onSeleccionChange,
  onEditContract,
  onDeleteContract,
  setExpanded,
}) => {
  return (
    <Box mb={1}>
      <Accordion TransitionProps={{ unmountOnExit: true }} elevation={1}>
        <AccordionSummary
          className={Styles.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded()}
          classes={{
            root: Styles.menuItem,
            expanded: Styles.menuItemSelected,
            content: Styles.summaryContent,
          }}
        >
          <div style={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between">
              <Box p={1}>
                <Typography variant="caption" fontWeight="600">
                  {category.categoryName}
                </Typography>
              </Box>

              <Box p={1}>
                <Typography variant="caption">{category.numberOfContracts} contratos</Typography>
              </Box>
            </Box>
          </div>
        </AccordionSummary>
        <AccordionDetails className={Styles.detailsContainer}>
          <ContractsGrid
            onSeleccionChange={onSeleccionChange}
            onEditContract={onEditContract}
            onDeleteContract={onDeleteContract}
            contracts={category?.contracts || []}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ContractList;
