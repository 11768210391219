import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { RootState } from "../../../../store/store";
import { Contractor } from '../../../../models/Contractors';
import Services from '../../Services/BusinessPartnersServices';

const services = new Services();

type HookType = () => [
  contractors: Contractor[],
  updateContractors: (contractors: Contractor[]) => void,
  fetch: () => Promise<void>
];

const useContractors: HookType = () => {

  const { companyId } = useSelector(
    (state: RootState) => state.businessPartnerCreator
  );
  
  const [originalContractors, setOriginalContractors] = useState<Contractor[]>(
    []
  );

  const fetch = useCallback(async () => {
    const { data } = await services.getBusinessPartnersInCompany(companyId);
    setOriginalContractors(data || []);
  }, [companyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const updateContractors = (contractors: Contractor[]): void =>
    setOriginalContractors(contractors);

  return [originalContractors, updateContractors, fetch];
};

export default useContractors;
