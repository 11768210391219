import { useEffect, useState } from 'react';
import { Table, Column } from '@devexpress/dx-react-grid';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../../../../../helpers/dates';
import { Property } from '../../../../../../models/Properties';
import { rightAlign } from '../../../../../../shared/tables/TableColumnFormat';

interface ISaleData {
  title: string;
  value: string;
}

const getArrayData = (property: Property): ISaleData[] => {
  const { saleDate, seller, howDidFindAboutProject } = property;

  const date = isNaN(Number(saleDate)) ? saleDate : Number(saleDate);

  return [
    {
      title: 'Fecha de venta',
      value: moment(date).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: 'Asesor responsable',
      value: seller?.name || '',
    },
    {
      title: 'Por donde se enteró',
      value: howDidFindAboutProject,
    },
  ];
};

const getColumns = (): Column[] => {
  return [
    {
      title: '',
      name: 'title',
    },
    {
      title: '',
      name: 'value',
    },
  ];
};

interface UseColumnsAndRowsForSaleParams {
  property: Property;
}

type HookType = (param: UseColumnsAndRowsForSaleParams) => {
  columns: Column[];
  data: ISaleData[];
  titleColumns: string[];
  tableColumnExtensions: Table.ColumnExtension[];
};

const useColumnsAndRowsForSale: HookType = ({ property }) => {
  const tableColumnFormat: Table.ColumnExtension[] = [
    { columnName: 'title', wordWrapEnabled: true },
    { columnName: 'value', align: rightAlign, wordWrapEnabled: true },
  ];

  const [columns] = useState(getColumns());
  const [data, setData] = useState(getArrayData(property));
  const [titleColumns] = useState(['title']);
  const [tableColumnExtensions] = useState(tableColumnFormat);

  useEffect(() => {
    setData(getArrayData(property));
    return () => {};
  }, [property]);

  return {
    columns,
    data,
    titleColumns,
    tableColumnExtensions,
  };
};

export default useColumnsAndRowsForSale;
