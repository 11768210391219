import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';

const ContractsEvalproGridNumberFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  return (
    <NumberFormat displayType="text" value={value} thousandSeparator decimalScale={0} fixedDecimalScale prefix="$" />
  );
};

export default ContractsEvalproGridNumberFormatter;
