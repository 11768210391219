import StagesDTO, { StageDTO, StagesRecord } from '../DTO/StagesDTO';
import { StageTowerDTO, StageTowersRecord } from '../DTO/StageTowersDTO';
import { MoveTowerPoint, MoveCords } from '../types/Stages.types';

type Record = StageDTO | StageTowerDTO;

class StagesHandling {
  private static readonly upsertRecord = <T>(records: T, record: Record): T => ({
    ...records,
    [record.id]: record,
  });

  private static getMovedTower = (fromStage: StageDTO, from: MoveTowerPoint): StageTowerDTO => ({
    ...fromStage.towers.records[fromStage.towers.order[from.index]],
  });

  private static readonly removeRecord = <T>(records: T, recordId: string | number): T => {
    const newRecords = { ...records };

    delete newRecords[recordId];

    return newRecords;
  };

  private static readonly removeIndex = <T>(order: T[], index: number): T[] => {
    const newOrder = [...order];

    newOrder.splice(index, 1);

    return newOrder;
  };

  private static readonly removeTowerFromStage = (stage: StageDTO, index: number): StageDTO => ({
    ...stage,
    towers: {
      records: this.removeRecord<StageTowersRecord>(stage.towers.records, stage.towers.order[index]),
      order: this.removeIndex<string>(stage.towers.order, index),
    },
  });

  private static readonly getStageRecordsForRemovedTower = (
    stagesRecords: StagesRecord,
    from: MoveTowerPoint,
  ): StagesRecord => {
    const fromStage = stagesRecords[from.stageId];
    const updatedStage = this.removeTowerFromStage(fromStage, from.index);

    return this.upsertRecord<StagesRecord>(stagesRecords, updatedStage);
  };

  private static readonly addRecordId = <T>(order: T[], index: number, recordId: T): T[] => {
    const newOrder = [...order];

    newOrder.splice(index, 0, recordId);

    return newOrder;
  };

  private static readonly addTowerToStage = (stage: StageDTO, tower: StageTowerDTO, index: number): StageDTO => ({
    ...stage,
    towers: {
      records: this.upsertRecord<StageTowersRecord>(stage.towers.records, tower),
      order: this.addRecordId<string>(stage.towers.order, index, tower.id),
    },
  });

  private static readonly getStageRecordsForAddedTower = (
    stagesRecords: StagesRecord,
    movedTower: StageTowerDTO,
    to: MoveTowerPoint,
  ): StagesRecord => {
    const toStage = stagesRecords[to.stageId];
    const updatedStage = this.addTowerToStage(toStage, movedTower, to.index);

    return this.upsertRecord<StagesRecord>(stagesRecords, updatedStage);
  };

  static moveTower(stages: StagesDTO, { from, to }: MoveCords<MoveTowerPoint>): StagesDTO {
    const fromStage = stages.records[from.stageId];
    const movedTower = this.getMovedTower(fromStage, from);
    const updateRecords = this.getStageRecordsForRemovedTower(stages.records, from);
    const records = this.getStageRecordsForAddedTower(updateRecords, movedTower, to);

    return {
      ...stages,
      records,
    };
  }
}

export default StagesHandling;
