import Services from '../../../services/services';
import GeneralWalletServicesDefinitions from './GeneralWalletServicesDefinitions';

export default class GeneralWalletServices extends Services {
  updateWalletSetup(towerId, id, value) {
    return this.put(GeneralWalletServicesDefinitions.updateWalletSetup(), {
      towerId,
      id,
      value,
    });
  }

  updateMonthsRecovery(towerId, monthsRecovery) {
    return this.put(
      GeneralWalletServicesDefinitions.updateMonthsRecovery(towerId),
      {
        monthsRecovery,
      },
    );
  }

  getMonthsRecovery(towerId) {
    return this.get(
      GeneralWalletServicesDefinitions.getMonthsRecovery(towerId),
    );
  }

  getClientsWithGeneralWallet(towerId) {
    return this.get(
      GeneralWalletServicesDefinitions.generalWithTowerId(towerId),
    );
  }

  downloadGeneralWallet(towerId) {
    return this.get(
      GeneralWalletServicesDefinitions.downloadGeneralWithTowerId(towerId),
      {
        responseType: 'blob',
      },
    );
  }

  postRealPayment(data) {
    return this.post(GeneralWalletServicesDefinitions.postNewPayment(), data);
  }

  uploadRealPayments(towerId, form) {
    return this.post(
      GeneralWalletServicesDefinitions.uploadRealPayments(towerId),
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }

  getQuotationSummaryWithRealPayments(towerId, propertyId) {
    return this.get(
      GeneralWalletServicesDefinitions.getQuotationSummaryWithRealPayments(
        towerId,
        propertyId,
      ),
    );
  }

  getQuotationExcelSummaryWithRealPayments(towerId, propertyId) {
    return this.get(
      GeneralWalletServicesDefinitions.getQuotationExcelSummaryWithRealPayments(
        towerId,
        propertyId,
      ),
      {
        responseType: 'blob',
      },
    );
  }
}
