import { MoveCardPoint, MoveCords } from '../types/Stages.types';

const defaultPoint = {
  row: -1,
  column: -1,
};

const defaultCords = {
  from: defaultPoint,
  to: defaultPoint,
};

type MoveCardCords = MoveCords<MoveCardPoint>;

class StagesDragAndDropStore {
  private _fromRow = -1;
  private _fromColumn = -1;
  private _cardId = '';
  private _cords: MoveCardCords = defaultCords;

  get fromRow(): number {
    return this._fromRow;
  }

  setFromRow(row: number) {
    this._fromRow = row;
  }

  get fromColumn(): number {
    return this._fromColumn;
  }

  setFromColumn(column: number) {
    this._fromColumn = column;
  }

  get cardId(): string {
    return this._cardId;
  }

  setCardId(cardId: string) {
    this._cardId = cardId;
  }

  get cords(): MoveCardCords {
    return this._cords;
  }

  setCords(cords: MoveCardCords) {
    this._cords = cords;
  }

  reset() {
    this._fromRow = -1;
    this._fromColumn = -1;
    this._cardId = '';
    this._cords = defaultCords;
  }
}

export default StagesDragAndDropStore;
