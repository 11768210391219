import { useState } from 'react';

function useExcelExpanded() {
  const [isExpanded, setExpansion] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  function updateEditionState(isEditing) {
    setIsEditable(isEditing);
    if (isEditing) setExpansion(false);
  }

  function onChangeAccordion(_, expanded) {
    setExpansion(expanded);
    if (expanded) setIsEditable(false);
  }

  return {
    isExpanded,
    isEditable,
    setIsEditable: updateEditionState,
    setExpansion: onChangeAccordion,
  };
}

export default useExcelExpanded;
