import React from 'react';
import styled from 'styled-components';
import editIcon from '../../assets/icons/Edit.svg'
import deleteIcon from '../../assets/icons/delete.png'
import styles from './CustomIcons.module.scss'


const CustomIcons = (props) => {
  const { variant} = props;

  return (
    <div>
    {
      variant === 'edit' &&
      <img src={editIcon} alt="icono" />
    }
    {
      variant === 'delete' &&
      <img src={deleteIcon}
      alt="icono" 
      className={styles.delete}
      />
    }
    </div>
  );
};

export default CustomIcons;
