import EnumBase from '../../../../helpers/EnumBase';

class ClientIDType {
  static STANDARD = new EnumBase(1, 'Cédula de Ciudadanía');

  static NIT = new EnumBase(2, 'NIT');

  static NIUP = new EnumBase(3, 'NIUP-Registro Único');

  static TI = new EnumBase(4, 'Tarjeta de Identidad');

  static EX = new EnumBase(5, 'Cédula de Extranjería');

  static LM = new EnumBase(6, 'Libreta Militar');

  static PASSPORT = new EnumBase(7, 'Pasaporte');

  static VISA = new EnumBase(8, 'Visa');

  static RC = new EnumBase(9, 'Registro Civil');

  static TE = new EnumBase(10, 'Tarjeta Extranjería');

  static PEP = new EnumBase(11, 'Permiso especial de permanencia');
}

export default ClientIDType;
