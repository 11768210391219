import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import RealPaymentsServices from "../../Services/RealPaymentsServices"
import { updateClientIsPurchaseConfirmed } from '../actions'

const service = new RealPaymentsServices()

const useIsPurchaseConfirmed = () => {
    const dispatch = useDispatch();
    const { currentClient } = useSelector((state) => state.buyerCostumers.root);
    const { isPurchaseConfirmed } = useSelector((state) => state.realPayments.root);

    const call = async () => {
        const { clientId, propertyId } = currentClient
        const { data } = await service.getClientIsPurchaseConfirmedProperty(clientId, propertyId)
        dispatch(updateClientIsPurchaseConfirmed(data.isPurchaseConfirmed));
    }

    const setPurchaseConfirmed = async () => {
        if (currentClient) {
            const { clientId, propertyId } = currentClient
            await service.setClientPurchaseConfirmedProperty(clientId, propertyId)
            dispatch(updateClientIsPurchaseConfirmed(true));
        }
    }

    useEffect(() => {
        if (currentClient && currentClient.clientId && currentClient.propertyId)
            call()
    }, [currentClient])

    return {
        isPurchaseConfirmed,
        setPurchaseConfirmed
    }
}

export default useIsPurchaseConfirmed