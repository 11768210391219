import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { DataTypeProvider, Column } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import colors from '../../../../../assets/styles/settings/colors.module.scss';

interface TableCellTypographyProps {
  value?: string | number;
  children?: ReactNode;
  row?: any;
  column: Column;
}

const TableCellTypography = styled.span<TableCellTypographyProps>`
  color: ${(props) => {
    return Number(props.value) < 0
      ? colors.stateSuccess600
      : Number(props.value) > 0
      ? colors.stateError600
      : colors.stateNeutral600;
  }};
`;

const ContractPaymentScheduleGridDifferenceFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  value,
  column,
}) => {
  return (
    <div style={{ textAlign: 'right' }}>
      <TableCellTypography value={value} column={column}>
        <NumberFormat
          displayType="text"
          value={value}
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          prefix="$"
        />
      </TableCellTypography>
    </div>
  );
};

export default ContractPaymentScheduleGridDifferenceFormatter;
