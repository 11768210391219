import { combineReducers } from 'redux';
import { ON_PAYMENT_CASH_FLOW_IS_LOAD } from './actions';

const initialState = { table: [] };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_PAYMENT_CASH_FLOW_IS_LOAD:
      return {
        ...state,
        table: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({ root: reducer });
