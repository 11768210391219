import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';
import styles from '../../TowerBuilder.module.scss';
import { setDateCost, setIpc } from '../../actions';
import { setCurrentTower } from '../../../../containers/TowerBuilder/action';
import Services from '../../../../services/towerBuilding/TowerBuildingServices';

const services = new Services();

const DateCost = ({
  dateCost,
  ipc,
  onSetDateCost,
  onSetIpc,
  datesBuild,
  towerId,
  projectId,
  onSetCurrentTower,
  currentTower,
  builder,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSetNewConstructionFlow = async () => {
    try {
      const constructionValue = await services.getConstructionCal(
        projectId,
        datesBuild.id,
      );
      onSetCurrentTower({
        ...currentTower,
        constructionFlow: {
          ...currentTower.constructionFlow,
          ...constructionValue.data,
        },
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleChangeDate = async (e) => {
    try {
      await services.createBuilder(projectId, towerId, {
        datesBuild: { ...datesBuild, dateCost: moment(e).valueOf(), towerId },
        financialRows: [],
      });
      onSetDateCost(moment(e).valueOf());
      if (builder.length > 0) onSetNewConstructionFlow();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleChangeIpc = (e) => {
    const fetch = async () => {
      try {
        if (e.target.value !== '') {
          const value = e.target.value.split('');
          value.pop();
          const ipc = Number(value.join(''));
          await services.createBuilder(projectId, towerId, {
            datesBuild: { ...datesBuild, ipc, towerId },
            financialRows: [],
          });
          onSetIpc(ipc);
          if (builder.length > 0) onSetNewConstructionFlow();
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };
    fetch();
  };
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={styles.gridContainer}
    >
      <Grid item xs={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            autoOk
            error={false}
            helperText=""
            label="Fecha de costos"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={dateCost}
            onChange={handleChangeDate}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <NumberFormat
          placeholder="0"
          label="Reajuste sobre IPC"
          variant="outlined"
          value={ipc}
          customInput={TextField}
          suffix={'%'}
          onBlur={handleChangeIpc}
        />
      </Grid>
    </Grid>
  );
};

DateCost.propTypes = {
  dateCost: PropTypes.number.isRequired,
  currentTower: PropTypes.object.isRequired,
  builder: PropTypes.object.isRequired,
  ipc: PropTypes.number.isRequired,
  onSetDateCost: PropTypes.func.isRequired,
  onSetIpc: PropTypes.func.isRequired,
  datesBuild: PropTypes.number.isRequired,
  towerId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  onSetCurrentTower: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dateCost: state.towerBuilder.builder.dateCost,
  ipc: state.towerBuilder.builder.ipc,
  datesBuild: state.towerBuilder.builder.datesBuild,
  builder: state.towerBuilder.builder.builder,
  towerId: state.towerBuilder.root.currentTower.id,
  projectId: state.towerBuilder.root.project.id,
  currentTower: state.towerBuilder.root.currentTower,
});

const mapDispatchToProps = {
  onSetDateCost: setDateCost,
  onSetIpc: setIpc,
  onSetCurrentTower: setCurrentTower,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateCost);
