import { useEffect, useState, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import reducer, { initialState } from '../../reducer';
import {
  changeDistributionIndex,
  changeDistribution,
  fetchQuotationSuccess,
  changeFinalFeePercentage,
  changeReservePercentage,
  changeEditionType,
  changeInitialDate,
  changeDiscountType,
} from '../../actions';
import { validateMissingPrice } from './useAssignmentQuotation';
import QuotationsServices from '../../../../../../services/Quotations/Quotations.services';
import { quotationMonthDate } from '../../../../../Quotation/Core/customHooks/useQuotationMonthDate';
import { splitPaymentsDistribution } from '../../../../Core/customHooks/usePaymentsMerge';
import QuotationDiscountType from '../../../../../Quotation/Core/enums/QuotationDiscountType';
import QuotationFunctions from '../../../../../../components/Client2/List/Row/QuotationViewer/Dialog/helpers/quotationFunctions';

const services = new QuotationsServices();

const distributionWithRealPayments = (
  periods,
  startDate,
  initialDate,
  distribution,
  realPayments,
) => {
  const lastPayment = realPayments[realPayments.length - 1];
  const lastDate = lastPayment ? lastPayment.date : null;

  const datesListWithPrice = Array.from(Array(Math.max(periods, 0))).reduce(
    (accumulated, _, index) => {
      const paymentMonth = quotationMonthDate(
        index - 1,
        startDate,
        initialDate,
      );
      const date = paymentMonth.clone().hours(0);
      let price = 0;
      let indexToKeep = null;
      const currentQuotationPayment = distribution.find((payment) =>
        payment.date.isSame(date, 'M'),
      );
      if (currentQuotationPayment) {
        price = currentQuotationPayment.value;
      }

      if (lastDate && date.isSameOrBefore(lastDate, 'M')) {
        const pricesByCurrentMonth = realPayments.reduce((summary, payment) => {
          if (payment.date.isSame(date, 'M')) {
            return summary + Number(payment.price);
          }
          return summary;
        }, 0);
        price = pricesByCurrentMonth;
        indexToKeep = index;
      }

      const indexes =
        indexToKeep !== null
          ? [...accumulated.indexes, indexToKeep]
          : accumulated.indexes;

      return { distribution: [...accumulated.distribution, price], indexes };
    },
    { distribution: [], indexes: [] },
  );

  return { ...datesListWithPrice, lastRealPaymentDate: lastDate };
};

const useOpenQuotationDialogDidMount = (id, closeHandler, isEditingReal) => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const [quotation, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [enableDiscount, setLoadedDiscount] = useState(false);

  const dispatchFetchQuotationSuccess = (quotationUpdated) => {
    dispatch(fetchQuotationSuccess(quotationUpdated));
  };

  const handleReservePercentageChange = (name, value, saveIndex = true) => {
    dispatch(changeReservePercentage(name, value, saveIndex));
  };

  const handleFinalFeeChange = (value, saveIndex = true) => {
    dispatch(changeFinalFeePercentage(Number(value), saveIndex));
  };

  const handleDistributionChange = (index, value, indexToSelect) => {
    dispatch(changeDistribution(index, Number(value), indexToSelect));
  };

  const handleIndexSelection = (index) => {
    dispatch(changeDistributionIndex(index));
  };

  const handleEditionTypeChange = (isLockedType) => {
    setLoadedDiscount(false);
    dispatch(changeEditionType(isLockedType));
  };

  const handleEditionInitialDate = (date) => {
    dispatch(changeInitialDate(date));
  };

  const handleEditionDiscountType = (discountType) => {
    dispatch(changeDiscountType(discountType));
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await services.getQuotationWithRealPayments(id, towerId);
      const { quotation: loadedQuotation, realPayments } = response.data;
      loadedQuotation.realPayments = realPayments;
      loadedQuotation.isEditingReal = isEditingReal;
      const { realPayments: mappedPayments, distribution } =
        splitPaymentsDistribution(
          loadedQuotation.realPayments,
          loadedQuotation.periods,
          loadedQuotation.distribution,
          loadedQuotation.paymentStartDate,
          loadedQuotation.initialDate,
          loadedQuotation.paymentTypes,
        );

      const newDistribution = distributionWithRealPayments(
        loadedQuotation.periods,
        loadedQuotation.paymentStartDate,
        loadedQuotation.initialDate,
        distribution,
        mappedPayments,
      );

      const distributionPrice = QuotationFunctions.calculatePropertyPrice(
        newDistribution.distribution,
        0,
        0,
      );

      loadedQuotation.distribution = newDistribution.distribution;
      loadedQuotation.initialDistribution = newDistribution.distribution;
      loadedQuotation.lastRealPaymentDate = newDistribution.lastRealPaymentDate;
      loadedQuotation.indexSelected = newDistribution.indexes;
      loadedQuotation.defaultIndexSelected = newDistribution.indexes;
      loadedQuotation.discountType = QuotationDiscountType.ASSIGNED.code;
      loadedQuotation.reservePercentage =
        realPayments.length > 0 ? newDistribution?.distribution[0] : 0;
      loadedQuotation.distributionPrice = distributionPrice;

      dispatchFetchQuotationSuccess(loadedQuotation);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      closeHandler();
    } finally {
      setLoading(false);
    }
  };

  const getQuotationPDF = async () => {
    try {
      validateMissingPrice(quotation);
      const name = `Cot_${quotation.property.tower.project.name} ${quotation.property.tower.name} ${quotation.client.name} ${quotation.property.name}.pdf`;

      const newDistribution = [...quotation.distribution];
      const maxDistributionLength = Math.max(newDistribution.length, 1);
      const newDistributionWithoutReserve = newDistribution.splice(
        1,
        maxDistributionLength,
      );
      const reserve = newDistribution[0] ?? 0;
      const response = await services.downloadPaymentPlanPdf(
        {
          ...quotation,
          distribution: newDistributionWithoutReserve,
          periods: quotation.periods - 1,
          reservePercentage: reserve,
        },
        towerId,
      );
      const url = window.URL.createObjectURL(
        new Blob([Buffer.from(response.data, 'binary')], {
          type: 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      link.click();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetch();
    }
  }, [isOpen]);

  return {
    loading,
    quotation,
    enableDiscount,
    setLoadedDiscount,
    fetch,
    setIsOpen,
    handleReservePercentageChange,
    handleFinalFeeChange,
    handleDistributionChange,
    dispatchFetchQuotationSuccess,
    handleIndexSelection,
    handleEditionTypeChange,
    handleEditionInitialDate,
    handleEditionDiscountType,
    getQuotationPDF,
  };
};

export default useOpenQuotationDialogDidMount;
