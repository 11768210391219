import { useState } from 'react';
import { TOTAL_CASH_FLOW_TABS, GENERAL, DETAIL } from '../constants/TotalCashFlowConstants';
import { ITabOption } from '../interfaces/ITabOption';

const selectTabs =
  (selectedTab: ITabOption) =>
  (tab: ITabOption): ITabOption => ({ ...tab, selected: tab.id === selectedTab.id });

const useTotalCashFlowViewController = () => {
  const [selectedTab, setSelectedTab] = useState(TOTAL_CASH_FLOW_TABS.find((tab) => tab.selected));
  const [tabs, setTabs] = useState(TOTAL_CASH_FLOW_TABS);

  const changeTab = (tab: ITabOption) => {
    setTabs((preTabs) => preTabs.map(selectTabs(tab)));
    setSelectedTab(() => tab);
  };

  return {
    tabs,
    selectedTab,
    changeTab,
  };
};

export default useTotalCashFlowViewController;
