import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { Button } from '../../../../../shared/components/Button/Button';
import useAreaEditionActions from '../../../Core/customHooks/useAreaEditionActions';
import { AreaEditionActionsProps } from '../../../Core/customHooks/AreaEditionActionsProps';

const AreaEditionActions: FC<AreaEditionActionsProps> = (props) => {
  const { areaEditionHaveChanges, close, saveChanges } = useAreaEditionActions(props);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box marginLeft="16px">
        <Button size="small" disabled={!areaEditionHaveChanges} onClick={saveChanges} variant="outlined">
          <span className="button-label">Guardar cambios</span>
        </Button>
      </Box>
      <Box marginLeft="16px">
        <Button size="small" startIcon={<CloseOutlined />} onClick={close}>
          <span className="button-label">Cancelar</span>
        </Button>
      </Box>
    </Box>
  );
};

export default AreaEditionActions;
