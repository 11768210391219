import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import BalancePointType from '../../../../components/Schedule/SalesStartDate/BalancePointType';
import SalesDateRange from '../../UI/views/SalesDateRange';
import Styles from '../../UI/views/styles/ProjectSchedulesMainView.module.scss';

import agent from '../../../../config/config';
import { Role } from '../../../../helpers';
import Services from '../../Services/ProjectSchedulesServices';

const services = new Services();

function useProjectSchedulesHandler() {
  const { enqueueSnackbar } = useSnackbar();
  const [projectSchedules, setProjectSchedules] = useState(null);
  const [towers, setTowers] = useState(null)
  const [isAnySold, setIsAnySold] = useState(true);

  const orderToTowers = (towers) => {
    return _.orderBy(
      towers,
      [(tower) => tower.name.toLowerCase()],
      ['asc'],
    ).map((tower) => {
      return tower;
    });
  };

  const getProjectSchedules = async (projectId) => {
    try {
      const getData = await services.getProjectSchedules(projectId);
      setProjectSchedules(getData.data.projectSchedules[0]);
      setTowers(getData.data.projectSchedules[0].towers)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const putConstructionStartDate = async (displacement, towerId) => {
    try {
      const findTower = await towers.find((tower) => 
        tower.id === towerId
      )
      const { balancePointDate } = findTower.schedule
      const constructionStartDate = moment(Number(balancePointDate))
        .add(displacement, 'M')
        .toDate()
        .getTime();
      
      const response = await services.putConstructionStartDate(towerId, {
        constructionStartDate,
      });

      const newTowerInfo = {
        ...findTower,
        schedule: {
          ...response.data
        }
      }

      const oldTowersData = await towers.filter((tower) => 
        tower.id !== towerId
      )

      const newTowersData = [...oldTowersData, newTowerInfo]

      setTowers(newTowersData)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const putSalesStartDate = async (salesStartDate, towerId) => {
    try {
      const findTower = await towers.find((tower) => 
        tower.id === towerId
      )

      await services.putSalesStartDate(towerId, {
        salesStartDate,
      });

      const newTowerInfo = {
        ...findTower,
        schedule: {
          ...findTower.schedule,
          salesStartDate
        }
      }

      const oldTowersData = await towers.filter((tower) => 
        tower.id !== towerId
      )

      const newTowersData = [...oldTowersData, newTowerInfo]

      setTowers(newTowersData)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const putEndOfSalesDate = async (displacement, towerId) => {
    try {
      const findTower = await towers.find((tower) => 
        tower.id === towerId
      )
      const { constructionStartDate } = findTower.schedule
      const endOfSalesDate = moment(Number(constructionStartDate))
        .endOf('month')
        .add(displacement - 1, 'M')
        .toDate()
        .getTime();
      const response = await services.putEndOfSalesDate(towerId, {
        endOfSalesDate,
      });

      const newTowerInfo = {
        ...findTower,
        schedule: {
          ...response.data
        }
      }

      const oldTowersData = await towers.filter((tower) => 
        tower.id !== towerId
      )

      const newTowersData = [...oldTowersData, newTowerInfo]

      setTowers(newTowersData)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const putMaximumCollectionDate = async (displacement, towerId) => {
    try {
      const findTower = await towers.find((tower) => 
        tower.id === towerId
      )
      const { endOfSalesDate } = findTower.schedule
      const maximumCollectionDate = moment(Number(endOfSalesDate))
        .add(displacement, 'M')
        .toDate()
        .getTime();
      await services.putMaximumCollectionDate(towerId, {
        maximumCollectionDate,
      });

      const newTowerInfo = {
        ...findTower,
        schedule: {
          ...findTower.schedule,
          maximumCollectionDate
        }
      }

      const oldTowersData = await towers.filter((tower) => 
        tower.id !== towerId
      )

      const newTowersData = [...oldTowersData, newTowerInfo]

      setTowers(newTowersData)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const putAverageDeliveryDate = async (displacement, towerId) => {
    try {
      const findTower = await towers.find((tower) => 
        tower.id === towerId
      )
      const { endOfSalesDate } = findTower.schedule
      const averageDeliveryDate = moment(Number(endOfSalesDate))
        .add(displacement, 'M')
        .toDate()
        .getTime();

      const response = await services.putAverageDeliveryDate(towerId, {
        averageDeliveryDate,
      });

      const { endStageDate } = response.data

      const newTowerInfo = {
        ...findTower,
        schedule: {
          ...findTower.schedule,
          averageDeliveryDate,
          endStageDate
        }
      }

      const oldTowersData = await towers.filter((tower) => 
        tower.id !== towerId
      )

      const newTowersData = [...oldTowersData, newTowerInfo]

      setTowers(newTowersData)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const putBalancePointDate = async (balancePointDate, towerId) => {
    try {
      const response = await services.putBalancePointDate(towerId, {
        balancePointDate,
      });

      const findTower = await towers.find((tower) => 
        tower.id === towerId
      )

      const newTowerInfo = {
        ...findTower,
        schedule: {
          ...response.data
        }
      }

      const oldTowersData = await towers.filter((tower) => 
        tower.id !== towerId
      )

      const newTowersData = [...oldTowersData, newTowerInfo]

      setTowers(newTowersData)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const updateScheduleData = (newData) => {
    setProjectSchedules({ ...projectSchedules, ...newData });
  };

  const isAuthorizedForEdit = () =>  {
    return agent.isAuthorized([Role.Super, Role.Admin, Role.SalesDirector]);
  };

  const towersInfo = () => {
    return (
      <Box>
        <Typography variant="h3" className={Styles.title}>
          {`Proyecto: ${projectSchedules.name}`}
        </Typography>
        <Box className={Styles.schedulesContainer}>
          {projectSchedules &&
            orderToTowers(towers).map((tower) => {
              const { name } = tower;
              const { salesStartDate } = tower.schedule;
              const { endOfSalesDate } = tower.schedule;
              const { startStageDate } = tower.schedule;
              const { endStageDate } = tower.schedule;
              const { averageDeliveryDate } = tower.schedule;
              const { balancePointDate } = tower.schedule;
              const { constructionStartDate } = tower.schedule;
              const { maximumCollectionDate } = tower.schedule;
              const { balancePointType } = tower.schedule;
              const { balancePercentage } = tower.schedule;
              const { id } = tower;

              return (
                <Box key={`${name}/${id}`}>
                  <Typography className={Styles.towerName} variant="h4">
                    {`${name}`}
                  </Typography>
                  <SalesDateRange
                    towerId={id}
                    salesStartDate={salesStartDate}
                    endOfSalesDate={endOfSalesDate}
                    startStageDate={startStageDate}
                    endStageDate={endStageDate}
                    averageDeliveryDate={averageDeliveryDate}
                    balancePointDate={balancePointDate}
                    constructionStartDate={constructionStartDate}
                    maximumCollectionDate={maximumCollectionDate}
                    constructionStartDateHandler={putConstructionStartDate}
                    salesStartDateHandler={putSalesStartDate}
                    endOfSalesDateHandler={putEndOfSalesDate}
                    maximumCollectionDateHandler={putMaximumCollectionDate}
                    averageDeliveryDateHandler={putAverageDeliveryDate}
                    balancePointDateHandler={putBalancePointDate}
                    updateScheduleData={updateScheduleData}
                    isAnySold={isAnySold}
                    balancePointType={balancePointType}
                    balancePercentage={balancePercentage}
                    isAuthorizedForEdit={isAuthorizedForEdit()}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  };

  return {
    towersInfo,
    projectSchedules,
    getProjectSchedules,
  };
}

export default useProjectSchedulesHandler;
