import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

import styles from './Tooltip.module.scss';

const Tooltip: React.FC<TooltipProps> = ({ classes, ...restProps }) => (
  <MuiTooltip
    classes={{
      ...classes,
      /* @ts-ignore */
      tooltip: styles.root,
      /* @ts-ignore */
      arrow: styles.arrow,
    }}
    {...restProps}
  />
);

export default Tooltip;
