import React from 'react';
import { TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';

import TableCellTooltipTypography from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellTooltipTypography';
import TableCellCurrencyTypography from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellCurrencyTypography';

const ContractsPaymentScheduleGridSummaryItemComponent: React.ComponentType<TableSummaryRow.ItemProps> = ({
  type,
  value,
}) => {
  const cellValue = value || 0;
  switch (type) {
    case 'title':
      //@ts-ignore
      return <TableCellTooltipTypography value={cellValue} />;

    default:
      //@ts-ignore
      return <TableCellCurrencyTypography value={cellValue} />;
  }
};

export default ContractsPaymentScheduleGridSummaryItemComponent;
