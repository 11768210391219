import EnumBase from '../../../../helpers/EnumBase';

class MainStreetType {
  static DEFAULT = new EnumBase(-1, '');

  static AVENUE_STREET = new EnumBase(0, 'Avenida calle');

  static AVENUE_RACE = new EnumBase(1, 'Avenida carrera');

  static HIGHWAY = new EnumBase(2, 'Autopista');

  static AVENUE = new EnumBase(3, 'Avenida');

  static RING = new EnumBase(4, 'Anillo Vial');

  static CIRCULAR = new EnumBase(5, 'Circular');

  static STREET = new EnumBase(6, 'Calle');

  static ALLEY = new EnumBase(7, 'Callejón');

  static PATH = new EnumBase(8, 'Camino');

  static RACE = new EnumBase(9, 'Carrera');

  static ROAD = new EnumBase(10, 'Carretera');

  static ENCIRCLE = new EnumBase(11, 'Encircle');

  static DIAGONAL = new EnumBase(12, 'Diagonal');

  static WALK = new EnumBase(13, 'Paseo');

  static PARK_WAY = new EnumBase(14, 'Park way');

  static TRANSVERSAL = new EnumBase(15, 'Transversal');

  static VARIANT = new EnumBase(16, 'Variante');

  static findOneByValue(value) {
    const keyForValue = Object.keys(MainStreetType).find(
      (key) => MainStreetType[key].value === value,
    );
    return MainStreetType[keyForValue];
  }

  static findOneByCode(code) {
    const keyForValue = Object.keys(MainStreetType).find(
      (key) => MainStreetType[key].code === code,
    );
    return MainStreetType[keyForValue];
  }
}

export default MainStreetType;
