import React from 'react';
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import useBuyerCustomerPropertySelector from '../../../Core/customHooks/useBuyerCustomerPropertySelector';
import { CustomBadgeForState } from '../customViews/StyledBuyerCustomer';
import Styles from '../../BuyerCustomersMainView.module.scss';

const BuyerCustomerListCell = ({ row }) => {
  const {
    onClick,
    isCurrentSelected,
    propertyHasPaymentPlan,
  } = useBuyerCustomerPropertySelector(row);

  return (
    <ListItem
      button
      classes={{
        root: Styles.menuItem,
        selected: Styles.menuItemSelected,
      }}
      selected={isCurrentSelected}
      key={`buyer-item-${row.clientId}`}
      onClick={onClick}
      divider
    >
      <ListItemIcon>
        <CustomBadgeForState
          currentState={propertyHasPaymentPlan ? 'completed' : 'missing'}
        />
      </ListItemIcon>
      <ListItemText
        primary={row.title}
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              {row.subtitle}
            </Typography>
          </React.Fragment>
        }
      />
      {isCurrentSelected && (
        <ListItemSecondaryAction>
          <IconButton edge="end" size="small" disabled>
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

BuyerCustomerListCell.propTypes = {
  row: PropTypes.object,
};

export default BuyerCustomerListCell;
