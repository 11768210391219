import React from 'react';
import { TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';

import TableCellPercentTypography from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellPercentTypography';
import TableCellTooltipTypography from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellTooltipTypography';
import TableCellCurrencyTypography from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellCurrencyTypography';

const ContractsEvalproGridSummaryItemComponent: React.ComponentType<TableSummaryRow.ItemProps> = ({ type, value }) => {
  const cellValue = value || 0;
  switch (type) {
    case 'title':
      //@ts-ignore
      return <TableCellTooltipTypography value={cellValue} />;

    case 'percent':
      //@ts-ignore
      return <TableCellPercentTypography value={cellValue} />;

    default:
      //@ts-ignore
      return <TableCellCurrencyTypography value={cellValue} />;
  }
};

export default ContractsEvalproGridSummaryItemComponent;
