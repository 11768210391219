import { combineReducers } from 'redux';
import { ON_ROWS_FOR_PROJECT_RESULTS_STATE_IS_LOAD } from './actions';

const initialState = {
  projectResultsStateRows: [],
};

const mapProjectResultsStateFromNewData = (state, payload) => {
  const projectResultsStateRows = payload;
  return {
    ...state,
    projectResultsStateRows,
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ON_ROWS_FOR_PROJECT_RESULTS_STATE_IS_LOAD:
      return mapProjectResultsStateFromNewData(state, payload)
    default:
      return state;
  }
};

export default combineReducers({ root: reducer });
