import React, { FC } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { ClientActionsProps } from 'components/Client2/List/Core/interfaces/IActions';
import ClientListPopover from '../../Popover/ClientListPopover/ClientListPopover';
import styles from './ClientListCreatedAtFormatter.module.scss';

const ClientListCreatedAtFormatter: FC<
  DataTypeProvider.ValueFormatterProps & { actions: ClientActionsProps }
> = (props) => {
  return (
    <div className={styles.container}>
      <div>{props.value}</div>
      <div className={styles.icon}>
        <ClientListPopover client={props.row} actions={props.actions} />
      </div>
    </div>
  );
};

const ClientListCreatedAtFormatterContainer =
  (actions: ClientActionsProps): FC<DataTypeProvider.ValueFormatterProps> =>
  (props) =>
    <ClientListCreatedAtFormatter {...props} actions={actions} />;

export default ClientListCreatedAtFormatterContainer;
