import moment from 'moment';

export enum MonthlyEventType {
  balance = 'balance',
  fee = 'fee',
  placeholder = '',
}

type MonthlyEventAggregated = {
  date: string | null;
  commitment: number;
  byBalance: number;
  balances: number;
  value: number;
};

export interface WalletPaymentDistribution {
  date: string;
  price: number;
}

export interface FinancialForMonthlyPayment {
  value: number;
  isChecked: boolean;
}

export interface MonthlyPaymentRow {
  id: string;
  paymentName: string;
  options: string;
  type: MonthlyEventType;
  commitment: number;
  commitmentDate: Date;
  assignedPayment: number;
  aggregatedCommitment: number;
  financialApplied: number;
  balancePayment: number | null;
  balanceFinancial: number;
  days: number;
  financialFeeForDate: number;
  financialPeriod: number;
  financial: number;
  financialAppliedValue: FinancialForMonthlyPayment;
}

export interface MonthlyPaymentForReport {
  type: MonthlyEventType;
  commitment: number;
  commitmentDate: string;
  aggregated: MonthlyEventAggregated | null;
  assignedPayment: number;
  balancePayment: number | null;
  balanceFinancial: number;
  days: number;
  financialPeriod: number;
  financialApplied: number;
  dateConsignment: string | null;
  consignment: number;
  condone: number;
  financial: number;
  financialFeeForDate: number;
  id: string;
  financialAppliedValue: FinancialForMonthlyPayment;
}

export interface WalletPaymentFees {
  financialFee: number;
  recognitionFee: number;
  financialFeeForDay: number;
  recognitionFeeForDay: number;
}

interface WalletMonthlyReportDTO {
  commitmentPayments: WalletPaymentDistribution[];
  propertyPlan: WalletPaymentDistribution[];
  distribution: MonthlyPaymentForReport[];
  walletFees: WalletPaymentFees;
  isArrears: boolean;
  arrearsDays: number;
  projectName: string;
  towerName: string;
  propertyName: string;
  chargeNumber: string;
  clientName: string;
  clientIdentity: string;
  generated: number;
  condoned: number;
  toPay: number;
  alreadyPayed: number;
  stillInDebt: number;
  arrearsPrice: number;
  accumulatedPayments: number;
  commitment: number;
  commercialPrice: number;
  minPayment: number;
  commitmentForToday: number;
  financialAccumulated: number;
  summaryAppliedIncrement: number;
  isAllInPropertyChecked: boolean;
}

export default WalletMonthlyReportDTO;
