import React, { FC } from 'react';
import { Box, Card, IconButton } from '@material-ui/core';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';
import Typography from 'shared/components/Typography/Typography';

import styles from './QuotationConfigCollapseHeader.module.scss';

interface Props {
  name: string;
  isOpen: boolean;
  state?: 'success' | 'warning' | 'error';
  label?: string;
  toggleIsOpenCollapse: () => void;
}

const stateStyles = {
  success: styles.IconSuccess,
  warning: styles.IconWarning,
  error: styles.IconError,
};

const QuotationConfigCollapseHeader: FC<Props> = ({ name, isOpen, state, label, toggleIsOpenCollapse }) => {
  return (
    <Card className={styles.Card}>
      <Box display="flex" alignItems="center" justifyContent="space-between" paddingY="4px" paddingX="20px">
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          {state && <div className={stateStyles[state]} />}
          <Typography fontWeight="500">{name}</Typography>
        </Box>
        <Box>
          <Typography fontWeight="400" className={styles.Label}>
            {label}
          </Typography>
          <IconButton className={styles.Button} onClick={toggleIsOpenCollapse}>
            {isOpen ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export default QuotationConfigCollapseHeader;
