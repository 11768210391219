import styled from 'styled-components';

import colors from '../../../assets/styles/settings/colors.module.scss';
import fonts from '../../../assets/styles/settings/fonts.module.scss';

export type TypographyProps = {
  variant?: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: string;
  lineHeight?: string;
  letterSpacing?: string;
};

const getVariantProp = (variant: string | undefined, propName: string) => `${variant}-${propName}`;

const getVariant = (propName: string) => (props: TypographyProps) => fonts[getVariantProp(props.variant, propName)];

const overrideColor = (props: TypographyProps) => props.color && colors[props.color];

const overrideFontFamily = (props: TypographyProps) => props.fontFamily && fonts[props.fontFamily];

const getOverrideProp = (propName: string) => (props: TypographyProps) => props[propName];

const Typography = styled.span<TypographyProps>`
  color: ${getVariant('color')};
  font-family: ${getVariant('font-family')};
  font-size: ${getVariant('font-size')};
  font-style: ${getVariant('font-style')};
  font-weight: ${getVariant('font-weight')};
  line-height: ${getVariant('line-height')};
  letter-spacing: ${getVariant('letter-spacing')};

  color: ${overrideColor};
  font-family: ${overrideFontFamily};
  font-size: ${getOverrideProp('fontSize')};
  font-style: ${getOverrideProp('fontStyle')};
  font-weight: ${getOverrideProp('fontWeight')};
  line-height: ${getOverrideProp('lineHeight')};
  letter-spacing: ${getOverrideProp('letterSpacing')};
`;

export default Typography;
