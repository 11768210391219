import { useState } from 'react';
import IncomePaymentOptionEnum from '../models/IncomePaymentOption.enum';

function usePaymentOptionsForIncomeSegments() {
  const [segment, updateSegment] = useState<string>(
    IncomePaymentOptionEnum.Single,
  );
  const onSegmentChange = (segmentId: string) => updateSegment(segmentId);

  const options = [
    {
      label: 'Ingreso pago individual',
      value: IncomePaymentOptionEnum.Single,
    },
    {
      label: 'Ingreso pagos masivo',
      value: IncomePaymentOptionEnum.Several,
    },
    { label: 'Especiales', value: IncomePaymentOptionEnum.Special },
  ];

  return { onSegmentChange, options, segment };
}

export default usePaymentOptionsForIncomeSegments;
