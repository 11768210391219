import React, { FC } from 'react';
import Styles from './MonthlyInterestArrearsIndicator.module.scss';
import Typography from 'shared/components/Typography/Typography';
import Numbers from '../../../../../helpers/numbers';
import WalletMonthlyReportDTO from 'App/MonthlyInterestReport/core/DTO/WalletMonthlyReportDTO';
import styled from 'styled-components';
import colors from 'assets/styles/settings/colors.module.scss';

const ContainerDiv = styled.div<Partial<WalletMonthlyReportDTO>>`
  display: flex;
  background-color: ${(props) => {
    return props.isArrears ? colors.destructive100 : '#E2F7ED';
  }};
  border-radius: 6px;
  margin-top: 16px;
  margin-left: 20px;
  margin-right: 28px;
  padding: 10px;
  justify-content: space-between;
`;

const DividerDiv = styled.div<Partial<WalletMonthlyReportDTO>>`
  width: 0px;
  height: 35px;
  border: ${(props) => `1px solid ${props.isArrears ? colors.stateError600 : colors.success600}`};
  margin-left: 38px;
  margin-right: 23px;
`;

const MonthlyInterestArrearsIndicator: FC<Partial<WalletMonthlyReportDTO>> = ({
  arrearsPrice,
  arrearsDays,
  isArrears,
}) => {
  return (
    <ContainerDiv isArrears={isArrears}>
      <div className={Styles.LeftItem}>
        <div className={Styles.DescriptionContainer}>
          <Typography variant="subtitle1" color={isArrears ? 'stateError600' : 'success600'}>
            {Numbers.currencyFormat(arrearsPrice)}
          </Typography>
          <Typography variant="body2" color={isArrears ? 'destructive700' : 'success700'}>
            Cartera en mora
          </Typography>
        </div>
      </div>
      <DividerDiv isArrears={isArrears} />
      <div className={Styles.RightItem}>
        <div className={Styles.DescriptionContainer}>
          <Typography variant="subtitle1" color={isArrears ? 'stateError600' : 'success600'}>
            {Numbers.cleanNumber(arrearsDays)}
          </Typography>
          <Typography variant="body2" color={isArrears ? 'destructive700' : 'success700'}>
            Días de mora
          </Typography>
        </div>
      </div>
    </ContainerDiv>
  );
};

export default MonthlyInterestArrearsIndicator;
