import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import Services from '../../Services/ProjectionTypesServices';
import { setProjections } from '../actions';

const services = new Services();

export default function () {
  // @ts-ignore
  const { towerId } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const projections = await services.getProjections(towerId);
        dispatch(setProjections(projections.data));
        setLoading(false);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    fetch();
  }, []);

  return { loading };
}
