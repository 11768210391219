import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, CircularProgress, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ConfirmIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Styles from './UploadButton.module.scss';
import Typography from '../../../../../../../shared/components/Typography/Typography';
import Icon from '../../../../../../../shared/components/Icon/Icon';
import clsx from 'clsx';

export default function UploadButton({
  fileName,
  className,
  key,
  buttonTitle,
  isUploadingFile,
  onChangeHandler,
  onCancel,
  onAccept,
}) {
  return (
    <>
      <input
        name="file"
        accept="xlsx/*"
        hidden
        id={`raised-button-file-for-${buttonTitle}`}
        type="file"
        onChange={onChangeHandler}
        key={key}
      />
      <label
        htmlFor={`raised-button-file-for-${buttonTitle}`}
        className={className}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            component="span"
            className={Styles.root}
            disableElevation
            startIcon={<Icon variant="Upload" color="primary600" />}
            disabled={isUploadingFile}
          >
            <Typography variant="subtitle1" color="primary600">
              {fileName || buttonTitle}
            </Typography>
          </Button>
          {fileName && (
            <Box display="flex" alignItems="center">
              <IconButton
                color="primary"
                onClick={onAccept}
                disabled={isUploadingFile}
              >
                <ConfirmIcon />
              </IconButton>
              <IconButton
                color="default"
                onClick={onCancel}
                disabled={isUploadingFile}
              >
                <CancelIcon />
              </IconButton>
              {isUploadingFile && <CircularProgress />}
            </Box>
          )}
        </Box>
      </label>
    </>
  );
}

UploadButton.defaultProps = {
  buttonTitle: 'Cargar',
};

UploadButton.propTypes = {
  fileName: PropTypes.string,
  className: PropTypes.string,
  buttonTitle: PropTypes.string,
  key: PropTypes.any,
  isUploadingFile: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};
