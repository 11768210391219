import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './ProjectResultsStateCustomRow.module.scss';
import Numbers from '../../../../../helpers/numbers';
import useProjectResultsStateCustomCellData from 'App/ProjectResultsState/Core/customHooks/useProjectResultsStateCustomCellData';

const ProjectResultsStateCustomRow = (props) => {
  const { tableRow, tableColumn } = props;
  const { row } = tableRow;
  const cellData = row[tableColumn.column.name];
  const { name } = tableColumn.column;
  const utilityBeforeTaxName = 'Utilidad antes de impuestos';

  const { cellDataFormatted } = useProjectResultsStateCustomCellData(cellData);

  if (!cellData) {
    return (
      <VirtualTable.Cell className={classes.virtualTableCell}>
        <Typography className={classes.typographyRow}>
          {Numbers.currencyFormat(0, true, 2)}
        </Typography>
      </VirtualTable.Cell>
    );
  }

  if (row.name === utilityBeforeTaxName && typeof cellData !== 'number') {
    return (
      <VirtualTable.Cell className={classes.utilityRow}>
        <Typography className={classes.utilityTypography}>
          {cellData}
        </Typography>
      </VirtualTable.Cell>
    );
  }

  if (row.name === utilityBeforeTaxName && typeof cellData === 'number') {
    return (
      <VirtualTable.Cell className={classes.utilityRow}>
        <Typography className={classes.utilityTypography}>
          {cellDataFormatted}
        </Typography>
      </VirtualTable.Cell>
    );
  }

  if (typeof cellData === 'number') {
    return (
      <VirtualTable.Cell className={classes.virtualTableCell}>
        <Typography className={classes.typographyRow}>
          {cellDataFormatted}
        </Typography>
      </VirtualTable.Cell>
    );
  }

  if (name === 'name') {
    return (
      <VirtualTable.Cell className={classes.leftColumn}>
        <Typography className={classes.leftTypography}>{cellData}</Typography>
      </VirtualTable.Cell>
    );
  }

  return (
    <VirtualTable.Cell className={classes.virtualTableCell}>
      <Typography className={classes.typographyRow}>{cellData}</Typography>
    </VirtualTable.Cell>
  );
};

ProjectResultsStateCustomRow.propTypes = {
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
};

export default ProjectResultsStateCustomRow;
