import React, { FC } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { LocationOnOutlined } from "@material-ui/icons";
import styles from './ClientField.module.scss'

const AddressField: FC<TextFieldProps> = (props) => <TextField
    variant="outlined"
    className={styles.address}
    InputProps={{
        endAdornment: (
            <InputAdornment position="start">
                <LocationOnOutlined />
            </InputAdornment>
        ),
        autoComplete: 'off',
        readOnly: true,
    }}
    {...props}
/>

export default AddressField