import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import styles from './QuotationQuestionTooltip.module.scss';

const QuotationQuestionTooltip: FC<{ title: string }> = ({ title }) => {
  return (
    <Tooltip title={title} aria-label={title}>
      <div className={styles.QuotationQuestionTooltip}>
        <span>?</span>
      </div>
    </Tooltip>
  );
};

export default QuotationQuestionTooltip;
