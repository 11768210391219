import { useMutation, useQueryClient } from 'react-query';
import { GeneralWalletDetailsService } from '../../services/GeneralWalletDetailsService';
import { GENERAL_WALLET_THREADS } from '../constants/GeneralWalletDetailsReactQuery';

const service = new GeneralWalletDetailsService();

const useGeneralWalletDetailsThreadCreate = (clientId: string, propertyId: number) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (value: string | File) => {
      if (typeof value === 'string') {
        return service.createThreadsWithComment(clientId, propertyId, value);
      }
      const form = new FormData();
      form.append('file', value);
      return service.createThreadsWithFile(clientId, propertyId, form);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GENERAL_WALLET_THREADS]);
      },
    },
  );

  const onSubmit = (value: string | File) => mutation.mutate(value);

  return {
    ...mutation,
    onSubmit,
  };
};

export default useGeneralWalletDetailsThreadCreate;
