import { ClientDetail } from 'App/GeneralWallet/core/interfaces/generalWallet.types';
import { useState } from 'react';

const GeneralWalletDetailsClient = () => {
  const [client, setClient] = useState<ClientDetail | undefined>();

  const removeClient = () => {
    setClient(undefined);
  };

  const handleGeneralWalletDetailsClicked = (clientSelected: ClientDetail) => {
    setClient(clientSelected);
  };

  return {
    clientDetails: client,
    handleGeneralWalletDetailsClicked,
    removeClient,
  };
};

export default GeneralWalletDetailsClient;
