import React, { FC } from 'react';
import { Box, Card } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';

import styles from './QuotationConfigCollapseHeader.module.scss';

interface Props {
  name: string;
  state?: 'success' | 'warning' | 'error';
  label?: string;
}

const stateStyles = {
  success: styles.IconSuccess,
  warning: styles.IconWarning,
  error: styles.IconError,
};

const QuotationConfigElement: FC<Props> = ({ name, state, children }) => {
  return (
    <Card className={styles.Card}>
      <Box display="flex" alignItems="center" justifyContent="space-between" paddingY="4px" paddingX="20px">
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          {state && <div className={stateStyles[state]} />}
          <Typography fontWeight="500">{name}</Typography>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Card>
  );
};

export default QuotationConfigElement;
