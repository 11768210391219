import { Workbook, Worksheet } from 'exceljs/dist/exceljs';
import * as fs from 'file-saver';

class XLSXUtils {
  createBook = (): Workbook => {
    return new Workbook();
  };

  createSheet = (book: Workbook, name: string): Worksheet => {
    return book.addWorksheet(name);
  };

  downloadXLSX = async (book: Workbook, name: string): Promise<void> => {
    const buff = await book.xlsx.writeBuffer();
    const blob = new Blob([buff], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fs.saveAs(blob, `${name}.xlsx`);
  };
}

export default XLSXUtils;
