import { useEffect } from 'react';
import moment from 'moment';
import QuotationDiscountType from '../../../../../../../../App/Quotation/Core/enums/QuotationDiscountType';

function useEnableQuotationDiscount(
  quotation,
  dispatchFetchQuotationSuccess,
  handleEditionDiscountType,
) {
  const isAutomaticDiscountType =
    quotation.discountType === QuotationDiscountType.ASSIGNED.code;
  const isSameOrAfterCurrentMonth = moment(
    Number(quotation.paymentStartDate),
  ).isSameOrAfter(moment(), 'month');

  const isThereAvailablePeriods = quotation.distribution.length > 0;

  const enableDiscountCalculation =
    isSameOrAfterCurrentMonth &&
    (isAutomaticDiscountType || !quotation.discountType) &&
    !quotation.isAssigned &&
    isThereAvailablePeriods;

  const onChangeType = (event) => {
    const discountType = event.target.checked
      ? QuotationDiscountType.MANUAL.code
      : QuotationDiscountType.ASSIGNED.code;
    if (
      discountType === QuotationDiscountType.ASSIGNED.code &&
      quotation.isAssigned
    ) {
      return;
    }
    handleEditionDiscountType(discountType);
  };

  useEffect(() => {
    if (
      quotation.discountType === QuotationDiscountType.ASSIGNED.code &&
      quotation.isAssigned
    ) {
      dispatchFetchQuotationSuccess({
        ...quotation,
        discountType: QuotationDiscountType.MANUAL.code,
      });
    }
  }, [quotation.discountType, quotation.isAssigned]);

  const onChangeManualQuotationDiscount = (values) => {
    dispatchFetchQuotationSuccess({
      ...quotation,
      discountPrice: values.floatValue,
      priceWithDiscount:
        quotation.priceWithAdditionalAreas -
        quotation.discount -
        values.floatValue,
    });
  };

  return {
    enableDiscountCalculation,
    onChangeType,
    onChangeManualQuotationDiscount,
  };
}

export default useEnableQuotationDiscount;
