import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateTypeOfViewStateForTotalCashFlow } from '../actions';

const useStateForTypeSelector = () => {

  const [typeOfView, setTypeOfView] = useState('millions');

  const dispatch = useDispatch();
  
  const onChange =  (event) => {
    setTypeOfView(event.target.value);
    dispatch(updateTypeOfViewStateForTotalCashFlow(event.target.value));
  }


  return {onChange, typeOfView}
}

export default useStateForTypeSelector