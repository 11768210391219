const stateTypes = {
  Available: {
    code: 'AVAILABLE',
    value: 'Disponible',
  },
  Optional: {
    code: 'OPTIONAL',
    value: 'Opcionado',
  },
  Sold: {
    code: 'SOLD',
    value: 'Vendido',
  },
};

export default stateTypes;
