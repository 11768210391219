const { API_PATH } = require('../../../config/config');

const BuyerCustomerServicesDefinitions = {
  getClients: (towerId) => `${API_PATH}clients/tower/only-buyers/${towerId}`,
  getClientByProperty: (clientId, propertyId) =>
    `${API_PATH}clients/only-one-buyer/client/${clientId}/property/${propertyId}`,
  getPropertyForWord: (propertyId, towerId) =>
    `${API_PATH}clients/property/${propertyId}/tower/${towerId}`,
  getWord: () => `${API_PATH}clients/property/word`,
  getAvailableDocuments: (towerId) =>
    `${API_PATH}documents/${towerId}/documentTemplates`,
  downloadDocument: (documentId, propertyId, clientId) =>
    `${API_PATH}documents/${documentId}/${propertyId}/${clientId}`,
};

export default BuyerCustomerServicesDefinitions;
