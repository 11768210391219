import React, { FC } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid';
import { CalendarTodayOutlined, MoreVertOutlined } from '@material-ui/icons';
import TableHeaderRowCell from 'shared/tables/TableHeaderRowCell';
import styles from './DeedExpensesSalesMadeTableHederCell.module.scss';
import { HandlePopoverOpenType } from 'shared/customHooks/useCustomPopover';

interface Props {
  displacement: number;
  onClickDisplacement: HandlePopoverOpenType<{}>;
}

const DeedExpensesSalesMadeTableHederCell =
  ({ displacement, onClickDisplacement }: Props): FC<TableHeaderRow.CellProps> =>
  (props) => {
    const { column } = props;
    if (column.name === 'deedDisplacement') {
      return (
        <TableHeaderRowCell {...props}>
          {column.title}({displacement})
          <MoreVertOutlined className={styles.More} onClick={onClickDisplacement} />
        </TableHeaderRowCell>
      );
    }
    if (column.name === 'finalInstallmentDate' || column.name === 'deedDate') {
      return (
        <TableHeaderRowCell {...props}>
          <div className={styles.DeedExpensesSalesMadeTableHederCellContent}>
            <CalendarTodayOutlined className={styles.IconLeft} />
            {column.title}
          </div>
        </TableHeaderRowCell>
      );
    }
    return <TableHeaderRowCell {...props} />;
  };

export default DeedExpensesSalesMadeTableHederCell;
