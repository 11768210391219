import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';
import Typography from '../../../../shared/components/Typography/Typography';

const ContractsEvalproGridPercentFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  return (
    <Typography color="primary600">
      <NumberFormat displayType="text" value={value} thousandSeparator decimalScale={2} fixedDecimalScale suffix="%" />
    </Typography>
  );
};

export default ContractsEvalproGridPercentFormatter;
