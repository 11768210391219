import React, { FC, ReactNode } from 'react';
import { Box } from '@material-ui/core';

interface IContractDetailTabPanel {
  children?: ReactNode;
  index: number;
  value: number;
}

const ContractDetailTabPanel: FC<IContractDetailTabPanel> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default ContractDetailTabPanel;
