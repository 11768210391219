import StagesDTO, { StageDTO, StagesRecord } from '../DTO/StagesDTO';
import { StageTowersRecord } from '../DTO/StageTowersDTO';
import StagesOnboardingStates from '../enums/StagesOnboardingStates';

class StagesOnboardingState {
  private static readonly hasItems = (records: StagesRecord | StageTowersRecord): boolean =>
    Object.keys(records).length > 0;

  private static readonly hasStages = (stages: StagesDTO) => this.hasItems(stages.records);

  private static readonly getFirstStage = (stages: StagesDTO): StageDTO => stages.records[stages.order[0]];

  private static readonly hasTowers = (stages: StagesDTO) => this.hasItems(this.getFirstStage(stages).towers.records);

  static isDone(stages: StagesDTO): boolean {
    return this.hasStages(stages) && this.hasTowers(stages);
  }

  private static readonly getOnboardingState = (stages: StagesDTO): StagesOnboardingStates =>
    this.hasStages(stages) ? StagesOnboardingStates.Step2 : StagesOnboardingStates.Step1;

  static get(stages: StagesDTO): StagesOnboardingStates {
    return this.isDone(stages) ? StagesOnboardingStates.Done : this.getOnboardingState(stages);
  }
}

export default StagesOnboardingState;
