import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Services from '../../Services/ForgotPasswordServices';

const services = new Services();

const useChangePasswordBehavior = () => {
  const { recoveryToken } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmPasswordDoesNotMatch, setConfirmPasswordDoesNotMatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dismissError = () => setErrorMessage('');
  const dismissSuccess = () => setSuccess(false);

  const submit = async () => {
    if (loading || !password || confirmPasswordDoesNotMatch) {
      return;
    }
    try {
      setLoading(true);
      await services.changePassword(password, confirmPassword, recoveryToken);
      setSuccess(true);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (password && password !== confirmPassword) {
      setConfirmPasswordDoesNotMatch(true);
    } else if (confirmPasswordDoesNotMatch) {
      setConfirmPasswordDoesNotMatch(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    const checkEnter = event => {
      if (event.key === 'Enter') {
        submit();
      }
    }
		document.addEventListener('keypress', checkEnter);
		return () => document.removeEventListener('keypress', checkEnter);
	}, [password, confirmPassword, confirmPasswordDoesNotMatch, loading]);

  return {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordDoesNotMatch,
    submit,
    loading,
    errorMessage,
    success,
    dismissError,
    dismissSuccess
  };
};

export default useChangePasswordBehavior;