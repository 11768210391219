import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BankFinancialDetailForQuotationCell from '../customViews/BankFinancialDetailForQuotationCell';
import Numbers from '../../../../../helpers/numbers';
import useBankFinancialStatesForQuotation from '../../../Core/customHooks/useBankFinancialStatesForQuotation';
import QuotationBankCreditDialog from '../../dialogs/QuotationBankCreditDialog';

const ExpandableTypographyTile = styled(Typography)`
  font-size: '0.9375rem';
  font-weight: 'regular';
  color: #29339b;
`;

const BankFinancialDetailForQuotation = ({
  quotation,
  updateQuotation,
  setExpansion,
  isExpanded,
  initialFeePercentage,
  finalFee,
  finalPrice,
}) => {
  const {
    isBankDialogOpened,
    currentAnnualRate,
    monthlyRate,
    currentTermLimit,
    months,
    bankCredit,
    setOpen,
  } = useBankFinancialStatesForQuotation(quotation, finalPrice);

  return (
    <Box pt={3} pb={2}>
      <Accordion onChange={setExpansion} expanded={isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ExpandableTypographyTile variant="">
            {`Financiación en ${quotation.termLimit ||
              quotation.quotationSetup.termLimit} años`}
          </ExpandableTypographyTile>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={3}>
            <BankFinancialDetailForQuotationCell title="Modalidad de Financiación">
              {`Credito hipotecario ${Numbers.toFixed(
                finalFee,
                0,
              )}/${Numbers.toFixed(initialFeePercentage * 100, 0)}`}
            </BankFinancialDetailForQuotationCell>

            <BankFinancialDetailForQuotationCell title="Valor máximo del prestamo">
              {Numbers.currencyFormat(finalPrice)}
            </BankFinancialDetailForQuotationCell>

            <BankFinancialDetailForQuotationCell title="Cuota mensual aprox.">
              {Numbers.currencyFormat(bankCredit)}
            </BankFinancialDetailForQuotationCell>

            <BankFinancialDetailForQuotationCell title="Entidad que financia">
              Sin definir
            </BankFinancialDetailForQuotationCell>

            <BankFinancialDetailForQuotationCell title="Años deuda">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography>
                  {quotation.termLimit || quotation.quotationSetup.termLimit}
                </Typography>
                <Button
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Editar
                </Button>
              </Box>
            </BankFinancialDetailForQuotationCell>

            <BankFinancialDetailForQuotationCell title="Ingresos requeridos">
              {Numbers.currencyFormat(
                bankCredit / (quotation.quotationSetup.requiredEntryRate / 100),
              )}
            </BankFinancialDetailForQuotationCell>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <QuotationBankCreditDialog
        open={isBankDialogOpened}
        totalPaymentCredit={bankCredit}
        totalYears={currentTermLimit}
        months={months}
        annualEffectiveRate={currentAnnualRate}
        monthlyRate={monthlyRate}
        closeHandler={() => {
          setOpen(false);
        }}
        quotation={quotation}
        updateQuotation={updateQuotation}
      />
    </Box>
  );
};

BankFinancialDetailForQuotation.propTypes = {
  quotation: PropTypes.object,
  updateQuotation: PropTypes.func,
  isExpanded: PropTypes.bool.isRequired,
  setExpansion: PropTypes.func.isRequired,
  initialFeePercentage: PropTypes.number,
  finalFee: PropTypes.number,
  finalPrice: PropTypes.number,
};

export default BankFinancialDetailForQuotation;
