import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Input, { NUMBER } from '../../../Shared/Input';
import Widget, { LG, Type } from '../../../Shared/Widget';
import { fetchDataSuccess } from '../../../../../containers/StrategyV2/actions';
import IncrementServices from '../../../../../services/increments/IncrementsServices';
import Increment2Services from '../../../../../services/incrementsV2/incrementsService';
import generateDataset from '../../../../../containers/StrategyV2/helpers/dataset';
import { startLoading, stopLoading } from '../../../Loader/actions';
import { Numbers } from '../../../../../helpers';
import { MAIN_VIEW } from '../../reducer';
import validateSelectedGroup from '../../../Shared/Validator';
import SalesSpeedType from '../manualSalesSpeedModal/salesSpeedType.enum';

const services = {
  increments: new IncrementServices(),
  increments2: new Increment2Services(),
};

const getMinSaleSpeed = (rotationMonths, units) => {
  let numberToValidation = Numbers.cleanNumber(units / rotationMonths);

  if (rotationMonths > 98) {
    numberToValidation = units / 98;
  }

  return numberToValidation;
};

const SaleSpeed = ({
  groupId,
  saleSpeed,
  futureSaleSpeed,
  units,
  rotationMonths,
  type,
  onFetchedData,
  startApiLoading,
  stopApiLoading,
  strategy,
  isReset,
  realSalesSpeed,
  openHandler,
  openManualSalesSpeed,
  manualSalesSpeedType,
  manualRotation,
}) => {
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();

  let title = '';
  let component = null;
  if (groupId === 0 && type == Type.objective) {
    type = null;
  }
  switch (type) {
    case Type.objective: {
      component = Numbers.toFixed(saleSpeed);
      title = 'Velocidad de ventas objetivo';
      break;
    }
    case Type.real:
      component = Numbers.toFixed(realSalesSpeed);
      title = 'Velocidad de ventas real';
      break;
    default:
      component = Numbers.toFixed(Number(futureSaleSpeed));
      title = `Velocidad de ventas ${
        groupId === 0 ? 'objetivo' : 'inventario'
      }`;
      break;
  }

  return (
    <Widget
      title={
        <>
          {title}
          {openHandler !== undefined && (
            <>
              {isReset === false && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={strategy === null}
                    onClick={openHandler}
                    size="small"
                    fullWidth
                  >
                    Objetivo
                  </Button>
                </>
              )}
              <Button
                color="primary"
                variant="outlined"
                onClick={openManualSalesSpeed}
                size="small"
                fullWidth
              >
                Editar
              </Button>
            </>
          )}
        </>
      }
    >
      {component}
    </Widget>
  );
};

SaleSpeed.propTypes = {
  groupId: PropTypes.number.isRequired,
  saleSpeed: PropTypes.number.isRequired,
  futureSaleSpeed: PropTypes.number.isRequired,
  units: PropTypes.number.isRequired,
  rotationMonths: PropTypes.number.isRequired,
  onFetchedData: PropTypes.func.isRequired,
  startApiLoading: PropTypes.func.isRequired,
  stopApiLoading: PropTypes.func.isRequired,
  isReset: PropTypes.bool,
  strategy: PropTypes.number,
  realSalesSpeed: PropTypes.number.isRequired,
  type: PropTypes.string,
  manualRotation: PropTypes.number.isRequired,
  manualSalesSpeedType: PropTypes.string,
  openHandler: PropTypes.func.isRequired,
  openManualSalesSpeed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const currentGroup =
    state.strategy.root.strategyLines[state.strategy.root.selectedGroup];
  if (validateSelectedGroup(state.strategy.root)) {
    return {};
  }
  const {
    id,
    inventory,
    initialFee,
    isReset,
    strategy,
    sales,
  } = state.strategy.root.groups[state.strategy.root.selectedGroup];

  let manualSalesSpeedType = SalesSpeedType.AUTOMATIC.code;
  let manualRotation = 0;

  if (currentGroup) {
    manualSalesSpeedType =
      currentGroup.manualSalesSpeedType || SalesSpeedType.AUTOMATIC.code;
    manualRotation = currentGroup.manualRotation || manualRotation;
  }

  return {
    groupId: id,
    futureSaleSpeed: inventory.saleSpeed,
    saleSpeed: inventory.futureSaleSpeed,
    realSalesSpeed: sales.saleSpeed,
    units: inventory.units,
    rotationMonths: initialFee,
    strategy,
    isReset,
    manualSalesSpeedType,
    manualRotation,
  };
};

const mapDispatchToProps = {
  onFetchedData: fetchDataSuccess,
  startApiLoading: startLoading,
  stopApiLoading: stopLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleSpeed);
