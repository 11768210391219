export const UPDATE_BUYER_CUSTOMER_LIST = 'UPDATE_BUYER_CUSTOMER_LIST';
export const SET_CURRENT_BUYER_CUSTOMER = 'SET_CURRENT_BUYER_CUSTOMER';
export const SET_CURRENT_BUYER_IS_LOADING = 'SET_CURRENT_BUYER_IS_LOADING';
export const SET_CURRENT_BUYER_CUSTOMER_PROPERTY_FILES =
  'SET_CURRENT_BUYER_CUSTOMER_PROPERTY_FILES';
export const SET_CURRENT_BUYER_CUSTOMER_PROPERTY_INFO =
  'SET_CURRENT_BUYER_CUSTOMER_PROPERTY_INFO';

export const SET_CURRENT_FILTER_FOR_BUYERS = 'SET_CURRENT_FILTER_FOR_BUYERS';

export const updateBuyerCostumerList = (clients) => ({
  type: UPDATE_BUYER_CUSTOMER_LIST,
  payload: clients,
});

export const setCurrentBuyerCustomer = (currentClient) => ({
  type: SET_CURRENT_BUYER_CUSTOMER,
  payload: currentClient,
});

export const setCurrentBuyerCustomerPropertyInfo = (propertyInfo) => ({
  type: SET_CURRENT_BUYER_CUSTOMER_PROPERTY_INFO,
  payload: propertyInfo,
});

export const setCurrentBuyerCustomerPropertyFiles = (propertyInfo) => ({
  type: SET_CURRENT_BUYER_CUSTOMER_PROPERTY_FILES,
  payload: propertyInfo,
});

export const setCurrentBuyerIsLoading = (isLoading) => ({
  type: SET_CURRENT_BUYER_IS_LOADING,
  payload: isLoading,
});

export const setCurrentFilterForBuyers = (currentFilter) => ({
  type: SET_CURRENT_FILTER_FOR_BUYERS,
  payload: currentFilter,
});
