import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import SalesStartDate from '../../components/Schedule/SalesStartDate/SalesDateRange';
import AbsoluteCircularProgress from '../../App/TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import InitialFees from '../../components/Schedule/InitialFees/InitialFees';
import ScheduleServices from '../../services/schedule/ScheduleServices';
import GeneralStrategySetup from '../../components/Schedule/StrategySetup/GeneralStrategySetup';
import BalancePointType from '../../components/Schedule/SalesStartDate/BalancePointType';
import GeneralQuotationSetup from '../../components/Schedule/QuotationSetup/GeneralQuotationSetup';
import GeneralWalletSetup from '../../App/GeneralSetup/Core/UI/views/customContainers/GeneralWalletSetup';
import agent from '../../config/config';
import { Role } from '../../helpers';
import QuotationIconsSetup from '../../App/GeneralSetup/Core/UI/views/customContainers/QuotationIconsSetup';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.services = new ScheduleServices();
  }

  state = {
    isLoading: true,
    salesStartDate: new Date().getTime(),
    endOfSalesDate: new Date().getTime(),
    averageDeliveryDate: new Date().getTime(),
    balancePointDate: new Date().getTime(),
    constructionStartDate: new Date().getTime(),
    maximumCollectionDate: new Date().getTime(),
    startStageDate: new Date().getTime(),
    endStageDate: new Date().getTime(),
    firstSale: 0,
    isAnySold: true,
    strategySetup: {},
    quotationSetup: { deedExpense: {} },
    balancePointType: BalancePointType.MANUAL.code,
    walletSetup: {},
  };

  getSalesHistory = () => {
    this.services
      .getSalesChecker(this.props.match.params.towerId)
      .then((response) => {
        const isAnySold = response.data;
        this.setState({ isAnySold });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get isAuthorizedForEdit() {
    return agent.isAuthorized([Role.Super, Role.Admin]);
  }

  componentDidMount() {
    this.getSalesHistory();
    this.services
      .getDates(this.props.match.params.towerId)
      .then((response) => {
        let {
          salesStartDate,
          endOfSalesDate,
          averageDeliveryDate,
          balancePointDate,
          constructionStartDate,
          maximumCollectionDate,
          strategySetup,
          quotationSetup,
          balancePointType,
          balancePercentage,
          startStageDate,
          endStageDate,
          walletSetup,
        } = response.data;
        const { firstSale } = response.data;
        if (salesStartDate === null) {
          salesStartDate = new Date().getTime();
        }
        if (endOfSalesDate === null) {
          endOfSalesDate = new Date().getTime();
        }
        if (averageDeliveryDate === null) {
          averageDeliveryDate = new Date().getTime();
        }
        if (balancePointDate === null) {
          balancePointDate = new Date().getTime();
        }
        if (constructionStartDate === null) {
          constructionStartDate = new Date().getTime();
        }
        if (maximumCollectionDate === null) {
          maximumCollectionDate = new Date().getTime();
        }

        this.setState({
          salesStartDate,
          endOfSalesDate,
          firstSale,
          averageDeliveryDate,
          balancePointDate,
          constructionStartDate,
          maximumCollectionDate,
          strategySetup,
          balancePointType,
          balancePercentage,
          quotationSetup,
          startStageDate,
          endStageDate,
          walletSetup,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putSalesStartDate = (salesStartDate) => {
    if (
      moment(Number(this.state.balancePointDate)).isBefore(
        Number(salesStartDate),
      )
    ) {
      this.props.enqueueSnackbar(
        'Debe seleccionar una fecha menor al punto de equilibrio',
        { variant: 'error' },
      );
      return;
    }
    this.services
      .putSalesStartDate(this.props.match.params.towerId, { salesStartDate })
      .then((_) => {
        this.setState({ salesStartDate });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  putBalancePointDate = (balancePointDate) => {
    if (
      moment(Number(balancePointDate)).isBefore(
        Number(this.state.salesStartDate),
      )
    ) {
      this.props.enqueueSnackbar(
        'Debe seleccionar una fecha mayor al inicio de preventas',
        { variant: 'error' },
      );
      return;
    }

    this.services
      .putBalancePointDate(this.props.match.params.towerId, {
        balancePointDate,
      })
      .then((response) => {
        this.setState(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  putConstructionStartDate = (displacement) => {
    const { balancePointDate } = this.state;
    const constructionStartDate = moment(Number(balancePointDate))
      .add(displacement, 'M')
      .toDate()
      .getTime();
    this.services
      .putConstructionStartDate(this.props.match.params.towerId, {
        constructionStartDate,
      })
      .then((response) => {
        this.setState(response.data);
      })
      .then(() => this.setState({ state: this.state }))
      .catch((error) => {
        console.error(error);
      });
  };

  putAverageDeliveryDate = (displacement) => {
    const { endOfSalesDate } = this.state;
    const averageDeliveryDate = moment(Number(endOfSalesDate))
      .add(displacement, 'M')
      .toDate()
      .getTime();

    this.services
      .putAverageDeliveryDate(this.props.match.params.towerId, {
        averageDeliveryDate,
      })
      .then((response) => {
        this.setState({ ...this.state, ...response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  putEndOfSalesDate = (displacement) => {
    const { constructionStartDate } = this.state;
    const endOfSalesDate = moment(Number(constructionStartDate))
      .endOf('month')
      .add(displacement - 1, 'M')
      .toDate()
      .getTime();

    this.services
      .putEndOfSalesDate(this.props.match.params.towerId, { endOfSalesDate })
      .then((response) => {
        this.setState(response.data);
      })
      .then(() => this.setState({ state: this.state }))
      .catch((error) => {
        console.error(error);
      });
  };

  putMaximumCollectionDate = (displacement) => {
    const { endOfSalesDate } = this.state;
    const maximumCollectionDate = moment(Number(endOfSalesDate))
      .add(displacement, 'M')
      .toDate()
      .getTime();
    this.services
      .putMaximumCollectionDate(this.props.match.params.towerId, {
        maximumCollectionDate,
      })
      .then((_) => {
        this.setState({ maximumCollectionDate });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  firstSaleHandler = (firstSale) => {
    this.services
      .putFirstSaleHandler(this.props.match.params.towerId, { firstSale })
      .then((_) => {
        this.setState({ firstSale });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  updateStrategySetupHandler = async (event) => {
    const { name } = event.target;
    const value = !this.state.strategySetup[name];
    const initialStatus = { ...this.state.strategySetup };
    const setup = { ...initialStatus, [name]: value };
    this.setState({ strategySetup: setup });
    try {
      await this.services.putStrategySetup(
        this.props.match.params.towerId,
        setup,
      );
    } catch (error) {
      this.setState({ strategySetup: initialStatus });
      this.props.enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  updateScheduleData = (data) => {
    this.setState({ ...data });
  };

  render() {
    return (
      <div>
        {this.isAuthorizedForEdit && (
          <Typography variant="h4">Configuración inicial</Typography>
        )}
        {this.state.isLoading && <AbsoluteCircularProgress />}
        <SalesStartDate
          salesStartDate={this.state.salesStartDate}
          endOfSalesDate={this.state.endOfSalesDate}
          startStageDate={this.state.startStageDate}
          endStageDate={this.state.endStageDate}
          averageDeliveryDate={this.state.averageDeliveryDate}
          balancePointDate={this.state.balancePointDate}
          constructionStartDate={this.state.constructionStartDate}
          maximumCollectionDate={this.state.maximumCollectionDate}
          constructionStartDateHandler={this.putConstructionStartDate}
          salesStartDateHandler={this.putSalesStartDate}
          endOfSalesDateHandler={this.putEndOfSalesDate}
          maximumCollectionDateHandler={this.putMaximumCollectionDate}
          averageDeliveryDateHandler={this.putAverageDeliveryDate}
          balancePointDateHandler={this.putBalancePointDate}
          updateScheduleData={this.updateScheduleData}
          isAnySold={this.state.isAnySold}
          balancePointType={this.state.balancePointType}
          balancePercentage={this.state.balancePercentage}
          isAuthorizedForEdit={this.isAuthorizedForEdit}
        />
        {this.isAuthorizedForEdit && (
          <GeneralStrategySetup
            strategySetup={this.state.strategySetup}
            update={this.updateStrategySetupHandler}
          />
        )}
        <GeneralQuotationSetup
          quotationSetup={this.state.quotationSetup}
          isAuthorizedForEdit={this.isAuthorizedForEdit}
        />
        <GeneralWalletSetup
          walletSetup={this.state.walletSetup}
          isAuthorizedForEdit={this.isAuthorizedForEdit}
        />
      </div>
    );
  }
}

export default withSnackbar(Schedule);
