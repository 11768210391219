import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GroupTypesServices from '../../Services/GroupTypesServices';

function useGroupTypesLoader(loadAllGroups) {
  const services = new GroupTypesServices();
  const { towerId } = useParams();
  const [currentName, setName] = useState();
  const [allTypes, setTypes] = useState([]);
  const [isAlreadyLoaded, setLoaded] = useState(false);

  const setTypesFromGroups = (types) => {
    const newTypes = types.map((type) => ({
      id: type.id,
      value: type.name,
    }));
    setTypes(newTypes);
  };

  const loadCurrentTypes = async () => {
    const types = await services.getAllByTowerId(towerId);
    setTypesFromGroups(types.data);
  };

  useEffect(() => {
    if (!isAlreadyLoaded) {
      loadCurrentTypes();
      setLoaded(true);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const groups = await services.createWithName(towerId, currentName);
    setTypesFromGroups(groups.data);
    await loadAllGroups();
  };

  const updateType = async ({ id, value }) => {
    const groups = await services.updateNameById(towerId, id, value);
    setTypesFromGroups(groups.data);
    await loadAllGroups();
  };

  const removeType = async ({ id }) => {
    const groups = await services.deleteById(towerId, id);
    setTypesFromGroups(groups.data);
    await loadAllGroups();
  };

  return { onSubmit, setName, updateType, removeType, allTypes, currentName };
}

export default useGroupTypesLoader;
