import React, { FC } from 'react';
import styled from 'styled-components';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';
import colors from '../../../../../assets/styles/settings/colors.module.scss';

const TableCellTypography = styled.span<TableCellTypographyProps>`
  color: ${(props) => {
    return Number(props.value) >= 0 && Number(props.value) < 95
      ? colors.stateSuccess600
      : Number(props.value) > 100
      ? colors.stateError600
      : colors.stateWarning500;
  }};
`;

const TableCellSemaphoreTypography: FC<TableCellTypographyProps> = ({ value, children, column }) => {
  return (
    <TableCellTypography value={value} column={column}>
      {children || value}
    </TableCellTypography>
  );
};

export default TableCellSemaphoreTypography;
