import Select, { SelectProps } from '@material-ui/core/Select';
import styled from 'styled-components';

import stylesSettings from '../styles/settings.module.scss';

const FilterSelect = styled(Select)<SelectProps>`
  .MuiSelect-root {
    /* Box-model */
    padding: 12px 16px;
    min-height: 1rem;

    /* Typography */
    color: ${stylesSettings.selectColor};

    /* Visual */
    background-color: white;
  }
`;

export default FilterSelect;
