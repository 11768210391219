import React, { FC } from 'react';
import { Box, Card, Grid, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Typography from 'shared/components/Typography/Typography';
import useContractsLotsInterestRates from '../../core/customHooks/useContractsLotsInterestRates';

import styles from '../styles/ContractsLotsInterestRates.module.scss';
import ContractsLotsInterestRatesFormItem from './components/ContractsLotsInterestRatesFormItem';
import IconButton from 'shared/components/IconButton/IconButton';
import InterestRateOutlinedInput from './components/InterestRateOutlinedInput/InterestRateOutlinedInput';
import TextField from 'shared/components/TextField/TextField';

interface IContractsLotsInterestRates {
  currentContractId: number;
}

const ContractsLotsInterestRates: FC<IContractsLotsInterestRates> = ({ currentContractId }) => {
  const {
    isValidPercentage,
    onEditInterestRate,
    lotInterestRate,
    handleInterestRateChange,
    monthlyInterestRate,
    dailyInterestRate,
  } = useContractsLotsInterestRates({
    currentContractId,
  });

  return (
    <>
      <Box paddingY={3}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" fontSize="1.5rem" fontWeight="600">
              Tasas de interés
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Card className={styles.Container}>
        <Box display="flex" justifyContent="center" padding="40px">
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <ContractsLotsInterestRatesFormItem item xs={6}>
              <Typography fontWeight="500">Tasa efectiva anual</Typography>
            </ContractsLotsInterestRatesFormItem>
            <ContractsLotsInterestRatesFormItem item xs={6}>
              <NumberFormat
                thousandSeparator
                value={lotInterestRate}
                onValueChange={handleInterestRateChange}
                customInput={InterestRateOutlinedInput}
                suffix="%"
                isAllowed={isValidPercentage}
                decimalScale={2}
                fixedDecimalScale
                // @ts-ignore
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="save interest rate"
                      onClick={onEditInterestRate}
                      variant="ghost"
                      size="xsmall"
                      iconName="Done"
                    />
                  </InputAdornment>
                }
              />
            </ContractsLotsInterestRatesFormItem>
            <ContractsLotsInterestRatesFormItem item xs={6}>
              <Typography fontWeight="500">Tasa efectiva mensual</Typography>
            </ContractsLotsInterestRatesFormItem>
            <ContractsLotsInterestRatesFormItem item xs={6}>
              <Box pl={2}>
                <Typography variant="body1">
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={monthlyInterestRate}
                    suffix=" %"
                  />
                </Typography>
              </Box>
            </ContractsLotsInterestRatesFormItem>
            <ContractsLotsInterestRatesFormItem item xs={6}>
              <Typography fontWeight="500">Tasa efectiva diaria</Typography>
            </ContractsLotsInterestRatesFormItem>
            <ContractsLotsInterestRatesFormItem item xs={6}>
              <Box pl={2}>
                <Typography variant="body1">
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={dailyInterestRate}
                    suffix=" %"
                  />
                </Typography>
              </Box>
            </ContractsLotsInterestRatesFormItem>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default ContractsLotsInterestRates;
