import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../../../../UI2/Loader';
import QuotationClient from './Client';
import CustomQuotationProperty from './Property/CustomQuotationProperty';
import QuotationPayments from './Payments';
import Context from './context';
import useOpenQuotationDialogDidMount from './helpers/customHooks/useOpenQuotationDialogDidMount';
import useAssignmentQuotation, {
  validateRealPriceAndCurrentOne,
} from './helpers/customHooks/useAssignmentQuotation';
import ImportFromExcelView from './ImportFromExcel/ImportFromExcelView';
import useExcelExpanded from './helpers/customHooks/useExpanded';
import QuotationFunctions from './helpers/quotationFunctions';
import {
  calculateInitialFee,
  getFinalPayment,
} from '../../../../../../helpers/quotations';
import QuotationDiscountSummary from './Payments/EditDashboard/QuotationDiscountSummary';
import Numbers from '../../../../../../helpers/numbers';
import useEnableQuotationDiscount from './helpers/customHooks/useEnableQuotationDiscount';
import useDiscountGoalSeek from '../../../../../../App/Quotation/Core/customHooks/useDiscountGoalSeek';
import BankFinancialDetailForQuotation from '../../../../../../App/Quotation/UI/views/accordion/BankFinancialDetailForQuotation';
import ConfirmDialog from '../../../../../StrategyV2/Overviews/Graph/Controls/Strategies/ConfirmDialog/ConfirmDialog';
import AssignedPaymentEditionMainView from '../../../../../../App/AssignedPaymentEdition/UI/AssignedPaymentEditionMainView';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  redButton: {
    background: theme.palette.secondary.main,
    color: 'white',
  },
  greenButton: {
    background: '#00a3ab',
    color: 'white',
  },
}));

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({
  tempQuotation,
  id,
  open,
  closeHandler,
  fetchUserList,
  onSave,
  openQuotationListHandler,
  clientId,
  propertyId,
}) => {
  const {
    loading,
    quotation,
    openAssignedEdition,
    fetch,
    setIsOpen,
    setOpenEdition,
    handleReservePercentageChange,
    handleFinalFeeChange,
    handleDistributionChange,
    dispatchFetchQuotationSuccess,
    handleIndexSelection,
    handleEditionTypeChange,
    handleEditionInitialDate,
    handleEditionDiscountType,
    getQuotationPDF,
  } = useOpenQuotationDialogDidMount(id, closeHandler, tempQuotation);

  const {
    onAssignmentQuotation,
    isConfirmDialogOpen,
    onConfirmAssignment,
    onCancelAssignment,
  } = useAssignmentQuotation(fetchUserList, closeHandler);

  const { isEditable, setIsEditable, isExpanded, setExpansion } =
    useExcelExpanded();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const {
    enableDiscountCalculation,
    onChangeType,
    onChangeManualQuotationDiscount,
  } = useEnableQuotationDiscount(
    quotation,
    dispatchFetchQuotationSuccess,
    handleEditionDiscountType,
  );
  const { onClickDiscount, enableDiscount } = useDiscountGoalSeek(
    quotation,
    enableDiscountCalculation,
    dispatchFetchQuotationSuccess,
    handleReservePercentageChange,
    handleFinalFeeChange,
  );

  const propertyPrice =
    quotation.priceWithAdditionalAreas -
    quotation.discount -
    quotation.discountPrice;

  const classes = useStyles();

  const { isError, message } = validateRealPriceAndCurrentOne(quotation);

  return (
    <MuiDialog
      id="box_dialog_quotations"
      fullScreen
      open={open}
      TransitionComponent={Transition}
    >
      <Context.Provider
        value={{
          quotation,
          finalFeeHandler: handleFinalFeeChange,
          reserveHandler: handleReservePercentageChange,
          distributionHandler: handleDistributionChange,
          updateQuotation: dispatchFetchQuotationSuccess,
          updateDistributionIndex: handleIndexSelection,
          handleEditionTypeChange,
          onClickDiscount,
          handleEditionInitialDate,
        }}
      >
        <ConfirmDialog
          open={isConfirmDialogOpen}
          title="¿Esta seguro que quiere asignar esta cotización como plan de pagos?"
          content={`Se eliminara el resto de cotizaciones para esta propiedad. 
          Recuerde: tiene un ${
            quotation.discountPrice > 1 ? 'descuento' : 'incremento'
          } financiero de ${Numbers.currencyFormat(
            Math.abs(quotation.discountPrice),
          )}`}
          handleAccept={() => onConfirmAssignment(quotation, setIsOpen)}
          handleClose={onCancelAssignment}
        />
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {quotation.isAssigned
                ? `Forma de pago ${quotation.index}`
                : `Cotización ${quotation.index}`}
            </Typography>
            {quotation.isAssigned && (
              <Button
                autoFocus
                onClick={() => setOpenEdition(!openAssignedEdition)}
                color="inherit"
              >
                Recrear forma de pago
              </Button>
            )}
            <Button autoFocus onClick={getQuotationPDF} color="inherit">
              Obtener PDF
            </Button>
            {onSave && (
              <Button
                autoFocus
                className={
                  enableDiscount ? classes.greenButton : classes.redButton
                }
                variant="contained"
                disableElevation
                onClick={() => {
                  onSave(quotation);
                  if (openQuotationListHandler)
                    openQuotationListHandler(clientId, propertyId, true);
                }}
              >
                Guardar cotización
              </Button>
            )}
            {!quotation.temp && !quotation.isAssigned && (
              <Button onClick={onAssignmentQuotation} autoFocus color="inherit">
                Asignar cotización
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Loader isLoading={loading}>
          <Box p={3}>
            {quotation && (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <QuotationClient
                    client={quotation.client}
                    projectName={`${quotation.property.tower.project.name} ${quotation.property.tower.name}`}
                    propertyName={quotation.property.name}
                    date={quotation.createdAt}
                  />
                  <ImportFromExcelView
                    isExpanded={isExpanded}
                    setExpansion={setExpansion}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <QuotationDiscountSummary
                    realPrice={Number(quotation.propertyPrice)}
                    discountPrice={Number(quotation.discountPrice)}
                    discountPercentage={Number(quotation.discountPercentage)}
                    priceWithDiscount={Number(
                      quotation.priceWithAdditionalAreas -
                        quotation.discount -
                        quotation.discountPrice,
                    )}
                    propertyPrice={quotation.propertyPrice}
                    onChangeType={onChangeType}
                    discountType={quotation.discountType}
                    isEdition={isEditable}
                    onChangeManualQuotationDiscount={
                      onChangeManualQuotationDiscount
                    }
                  />
                  <BankFinancialDetailForQuotation
                    quotation={quotation}
                    updateQuotation={dispatchFetchQuotationSuccess}
                    isExpanded={isExpanded}
                    setExpansion={setExpansion}
                    initialFeePercentage={quotation.initialFeePercentage}
                    finalFee={QuotationFunctions.finalFeePercentage(
                      quotation.finalPayment,
                      quotation.priceWithAdditionalAreas -
                        quotation.discount -
                        quotation.discountPrice,
                    )}
                    finalPrice={quotation.finalPayment}
                  />
                  {isError && (
                    <Typography color="secondary">{message}</Typography>
                  )}
                </Grid>
                {!quotation.isAssigned && (
                  <Grid item xs={12} lg={5}>
                    <CustomQuotationProperty
                      name="Propuesta constructor"
                      priceWithDiscount={
                        quotation.proposalPropertyPrice -
                        quotation.proposalDiscount
                      }
                      basePrice={quotation.proposalBasePrice}
                      additionalAreas={
                        quotation.propertyWithAreas.additionalAreas
                      }
                      totalArea={quotation.propertyWithAreas.totalArea}
                      privateArea={quotation.propertyWithAreas.privateArea}
                      propertyPrice={quotation.proposalPropertyPrice}
                      commercialPrice={
                        quotation.proposalPropertyPrice -
                        quotation.proposalDiscount
                      }
                      commercialDiscount={quotation.proposalDiscount}
                      discountPrice={0}
                      initialFeePercentage={
                        quotation.proposalInitialFeePercentage
                      }
                      initialFeePrice={calculateInitialFee(
                        quotation.proposalPropertyPrice -
                          quotation.proposalDiscount,
                        quotation.proposalInitialFeePercentage,
                      )}
                      periods={quotation.proposalPeriods}
                      monthlyFeesPercentage={QuotationFunctions.monthlyFeesPercentage(
                        quotation.proposalInitialFeePercentage,
                        quotation.proposalPropertyPrice -
                          quotation.proposalDiscount,
                        quotation.proposalReservePercentage,
                      )}
                      monthlyFeesPrice={QuotationFunctions.monthlyFeesPrice(
                        quotation.proposalInitialFeePercentage,
                        quotation.proposalPropertyPrice -
                          quotation.proposalDiscount,
                        quotation.proposalReservePercentage,
                      )}
                      reservePercentage={QuotationFunctions.reserveFeePercentage(
                        quotation.proposalReservePercentage,
                        quotation.proposalPropertyPrice -
                          quotation.proposalDiscount,
                      )}
                      reservePrice={quotation.proposalReservePercentage}
                      finalFee={QuotationFunctions.finalFeePercentage(
                        getFinalPayment(
                          quotation.proposalPropertyPrice -
                            quotation.proposalDiscount,
                          quotation.proposalInitialFeePercentage,
                        ),
                        quotation.proposalPropertyPrice -
                          quotation.proposalDiscount,
                      )}
                      finalPrice={getFinalPayment(
                        quotation.proposalPropertyPrice -
                          quotation.proposalDiscount,
                        quotation.proposalInitialFeePercentage,
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={quotation.isAssigned ? 12 : 7}>
                  <CustomQuotationProperty
                    name="Propuesta cliente"
                    priceWithDiscount={propertyPrice}
                    propertyPrice={quotation.priceWithAdditionalAreas}
                    basePrice={quotation.basePrice}
                    additionalAreas={
                      quotation.propertyWithAreas.additionalAreas
                    }
                    commercialPrice={
                      quotation.priceWithAdditionalAreas - quotation.discount
                    }
                    totalArea={quotation.propertyWithAreas.totalArea}
                    privateArea={quotation.propertyWithAreas.privateArea}
                    commercialDiscount={quotation.discount}
                    discountPrice={quotation.discountPrice}
                    discountType={quotation.discountType}
                    discount={quotation.discount}
                    initialFeePercentage={quotation.initialFeePercentage}
                    initialFeePrice={calculateInitialFee(
                      propertyPrice,
                      quotation.initialFeePercentage,
                    )}
                    periods={quotation.periods}
                    monthlyFeesPercentage={QuotationFunctions.monthlyFeesPercentage(
                      quotation.initialFeePercentage,
                      propertyPrice,
                      quotation.reservePercentage,
                    )}
                    monthlyFeesPrice={QuotationFunctions.monthlyFeesPrice(
                      quotation.initialFeePercentage,
                      propertyPrice,
                      quotation.reservePercentage,
                    )}
                    reservePercentage={QuotationFunctions.reserveFeePercentage(
                      quotation.reservePercentage,
                      propertyPrice,
                    )}
                    reservePrice={quotation.reservePercentage}
                    finalFee={QuotationFunctions.finalFeePercentage(
                      quotation.finalPayment,
                      propertyPrice,
                    )}
                    finalPrice={quotation.finalPayment}
                  />
                </Grid>
                {!quotation.isAssigned && (
                  <Grid item xs={12} lg={5}>
                    <QuotationPayments
                      dispatchFetchQuotationSuccess={
                        dispatchFetchQuotationSuccess
                      }
                      quotation={{
                        ...quotation,
                        propertyPrice: quotation.proposalPropertyPrice,
                        initialFeePercentage:
                          quotation.proposalInitialFeePercentage,
                        reservePercentage: quotation.proposalReservePercentage,
                        periods: quotation.proposalPeriods,
                        distribution: quotation.proposalDistribution,
                        finalFee: getFinalPayment(
                          quotation.proposalPropertyPrice -
                            quotation.proposalDiscount,
                          quotation.proposalInitialFeePercentage,
                        ),
                        finalPayment: getFinalPayment(
                          quotation.proposalPropertyPrice -
                            quotation.proposalDiscount,
                          quotation.proposalInitialFeePercentage,
                        ),
                      }}
                      fetch={() => {}}
                      setIsEditable={() => {}}
                      isEditable={isEditable}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={quotation.isAssigned ? 12 : 7}>
                  <QuotationPayments
                    dispatchFetchQuotationSuccess={
                      dispatchFetchQuotationSuccess
                    }
                    quotation={quotation}
                    fetch={fetch}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    showEditableButtons
                    enableDiscountCalculation={enableDiscountCalculation}
                    enableDiscount={enableDiscount}
                    onClickDiscount={onClickDiscount}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Loader>
        <AssignedPaymentEditionMainView
          id={id}
          open={openAssignedEdition}
          closeHandler={() => {
            setOpenEdition(!openAssignedEdition);
          }}
        />
      </Context.Provider>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  fetchUserList: PropTypes.func,
  onSave: PropTypes.func,
  tempQuotation: PropTypes.object,
  openQuotationListHandler: PropTypes.func,
  clientId: PropTypes.string,
  propertyId: PropTypes.string,
};

export default Dialog;
