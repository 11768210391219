import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  FormControl,
  TextField,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import RemoveTwoToneIcon from '@material-ui/icons/RemoveTwoTone';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Button, MuiIconButton } from '../../../shared/components/Button/Button';
import Typography from '../../../shared/components/Typography/Typography';
import { Event } from '../../../models/ContractPayments';
import useContractEventForm from '../Core/customHooks/useContractEventForm';

import Styles from './ContractEventFormMainView.module.scss';

interface IContractEventFormMainView {
  open: boolean;
  currentContractId: number;
  currentContractEvent: Event | undefined;
  contractEventType?: string;
  handleClose: () => void;
  handleCreate: (contractEvent: Event) => void;
}

const ContractEventFormMainView: FC<IContractEventFormMainView> = ({
  open,
  currentContractEvent,
  handleClose,
  handleCreate,
}) => {
  const {
    data,
    errors,
    handleSubmit,
    handleChange,
    handleChangeDate,
    handleAddDisplacement,
    handleSubtractDisplacement,
  } = useContractEventForm({
    currentContractEvent,
    handleCreate,
    handleClose,
  });

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Box className={Styles.BoxTitle} p={2}>
              <Typography fontWeight="600">Hito</Typography> <br />
              <Typography variant="subtitle">Programa pagos eventuales en el transcurso del contrato</Typography>
            </Box>
            <Box m={2}>
              <FormControl fullWidth>
                <TextField
                  id="description"
                  name="description"
                  label="Titulo del hito"
                  variant="outlined"
                  multiline
                  value={data?.description || ''}
                  size="small"
                  error={!!errors?.description}
                  helperText={errors?.description}
                  onChange={handleChange('description')}
                />
              </FormControl>
            </Box>
            <Box m={3}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography color="gray500" fontWeight="600">
                    Fecha
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id="customDate"
                        name="customDate"
                        inputVariant="outlined"
                        size="small"
                        margin="normal"
                        label=""
                        format="dd/MM/yyyy"
                        value={Number(data?.customDate) || ''}
                        KeyboardButtonProps={{
                          size: 'small',
                          className: Styles.outlineNone,
                          'aria-label': 'change date',
                        }}
                        error={!!errors?.customDate}
                        helperText={errors?.customDate}
                        onChange={(date) => {
                          handleChangeDate('customDate')(date?.valueOf() || null);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
            </Box>
            <Box m={2}>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ContractEventFormMainView;
