import { API_PATH } from '../../config/config';

const TowerServiceDefinitions = {
  towerForProject: (towerId) => {
    return `${API_PATH}tower-builder/${towerId}`;
  },
  towerForConstruction: (projectId, datesBuildId) => {
    return `${API_PATH}tower-builder/construction-cal/${projectId}/${datesBuildId}`;
  },
  towerForConstructionContract: (towerId) => {
    return `${API_PATH}tower-builder/construction-cal-contract/${towerId}`;
  },
  towerForProjectAndTower: (projectId, towerId) => {
    return `${API_PATH}tower-builder/${projectId}/${towerId}`;
  },
};

export default TowerServiceDefinitions;
