import { ProjectionTypeEnum, ProjectionTypes } from '../projectionType.enum';

function useProjectionsArray({ name, switchState }) {
  const individualSelection = () => {
    switch (name) {
      case 'construction':
        return [ProjectionTypes.EXECUTION, ProjectionTypes.EVALPRO];
      case 'financialExpenses':
        return [ProjectionTypes.EXECUTION];
      case 'sales':
        return [ProjectionTypes.EXECUTION, ProjectionTypes.EVALPRO, ProjectionTypes.EXTERNAL_SOURCE];

      default:
        return [ProjectionTypes.EXECUTION, ProjectionTypes.EVALPRO];
    }
  };

  return {
    individualSelection,
  };
}

export default useProjectionsArray;
