import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import useGoToSalesRoom, {
    GoToSalesRoomProps,
} from 'App/Clients/Core/customHooks/useGoToSalesRoom';
import buttonStyles from 'App/Clients/UI/ClientButtons/ClientButtons.module.scss';

const GoToSalesRoom: FC<GoToSalesRoomProps> = (props) => {
    const { goToSalesRoom } = useGoToSalesRoom(props);
    return (
        <Button
            className={buttonStyles.buttonOutline}
            fullWidth
            onClick={goToSalesRoom}
        >
            Ir a sala de ventas
        </Button>
    );
};
export default GoToSalesRoom;
