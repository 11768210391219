import { combineReducers } from 'redux';
import { Numbers } from '../../../helpers';
import {
  ON_CONTRACT_CASH_FLOW_IS_LOAD,
  ON_SALES_CASH_FLOW_IS_LOAD,
  ON_UPDATE_SALES_ROWS,
  CHANGING_TYPE_OF_VIEW,
  CHANGING_TYPE_OF_VIEW_FOR_TOTAL_CASH_FLOW,
  SET_ROWS_FOR_RESULTS_STATE,
  ON_SELECTED_TAB_IS_LOAD,
  ON_SELECTED_BUDGET_IS_LOAD,
  SET_EDIT_CELLS_FOR_BUDGET,
  SET_SAVE_BUDGET_STATE,
  SET_BUDGET_ROWS,
  SET_EDITABLE_ROWS,
  SET_CUTS_ROWS,
  ON_SALES_CASH_FLOW_TOTALS,
  ON_UPDATE_ROWS_FOR_BUDGET_CONTROL
} from './actions';

const initialState = {
  contractRows: [],
  salesRows: [],
  initialDate: null,
  lastDate: null,
  financing: [],
  investorFlow: [],
  cxp: [],
  trust: [],
  financialPerformance: [],
  typeOfView: 'millions',
  resultsStateRows: [],
  selectedTab: {},
  selectedBudget: 1,
  editCellsState: false,
  editableBudgetRows: [],
  cutsControl: [],
  budgetControl: [],
  towerWithoutCuts: null,
  saveBudgetState: null,
  totalsReport: {},
};

const mapCashflowFroNewData = (state, payload) => {
  const {
    rows,
    dates,
    financing,
    investorFlow,
    cxp,
    trust,
    financialPerformance,
    spreadIPC,
    ClientWithoutQuotation,
    cutsControl,
    budgetControl,
    budgetRows,
    cutsRows,
    towerWithoutCuts,
    speedSalesIsMissing,
    saveBudgetState,
    contractRowsForBudgetContol
  } = payload;
  return {
    ...state,
    financing,
    investorFlow,
    cxp,
    trust,
    financialPerformance,
    spreadIPC,
    ClientWithoutQuotation,
    cutsControl,
    budgetControl,
    budgetRows,
    cutsRows,
    towerWithoutCuts,
    saveBudgetState,
    contractRowsForBudgetContol,
    salesRows: rows,
    initialDate: Numbers.cleanNumber(dates.first),
    lastDate: Numbers.cleanNumber(dates.last),
    constructionStartDate: Numbers.cleanNumber(dates.constructionStartDate),
    endOfSalesDate: Numbers.cleanNumber(dates.endOfSalesDate),
    speedSalesIsMissing,
  };
};

const rowsFilteredFunction = (newRows, budgetRows) => {
  const rowsFiltered =
    budgetRows &&
    budgetRows.map((row) => {
      const currentRow = newRows.find(
        (newRow) =>
          newRow.budgetId === row.budgetId &&
          newRow.budgetIndex === row.budgetIndex,
      );

      return currentRow || row;
    });

  return rowsFiltered;
};

const updateEditableRows = (state, payload) => {
  const budgetRows = rowsFilteredFunction(payload, state.budgetRows);
  return {
    ...state,
    budgetRows,
    editableBudgetRows: payload,
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_CONTRACT_CASH_FLOW_IS_LOAD:
      return {
        ...state,
        contractRows: payload,
      };
    case ON_SALES_CASH_FLOW_TOTALS:
      return {
        ...state,
        totalsReport: payload,
      };
    case ON_UPDATE_SALES_ROWS:
      return {
        ...state,
        salesRows: payload,
      };
    case ON_SALES_CASH_FLOW_IS_LOAD:
      return mapCashflowFroNewData(state, payload);
    case CHANGING_TYPE_OF_VIEW:
      return {
        ...state,
        typeOfView: payload,
      };
    case CHANGING_TYPE_OF_VIEW_FOR_TOTAL_CASH_FLOW:
      return {
        ...state,
        typeOfView: payload,
      };
    case SET_ROWS_FOR_RESULTS_STATE:
      return {
        ...state,
        resultsStateRows: payload,
      };
    case ON_SELECTED_TAB_IS_LOAD:
      return {
        ...state,
        selectedTab: payload,
      };
    case ON_SELECTED_BUDGET_IS_LOAD:
      return {
        ...state,
        selectedBudget: payload,
      };
    case SET_EDIT_CELLS_FOR_BUDGET:
      return {
        ...state,
        editCellsState: payload,
      };
    case SET_SAVE_BUDGET_STATE:
      return {
        ...state,
        saveBudgetState: payload,
      };
    case SET_BUDGET_ROWS:
      return {
        ...state,
        budgetRows: payload,
      };
    case SET_EDITABLE_ROWS:
      return updateEditableRows(state, payload);
    case SET_CUTS_ROWS:
      return {
        ...state,
        cutsRows: payload,
      };
    case ON_UPDATE_ROWS_FOR_BUDGET_CONTROL:
      return {
        ...state,
        contractRowsForBudgetContol: payload,
      }
    default:
      return state;
  }
};

export default combineReducers({ root: reducer });
