import { QueryClient } from 'react-query';
import { DataUpdateFunction, Updater } from 'react-query/types/core/utils';
import { DeedExpenseType, QuotationConfigDto } from './DTO/QuotationConfigDto';
import { QUOTATION_CONFIG_SCHEDULE } from './QuotationConfigConstants';

const queryClient = new QueryClient();

type QuotationSetup = QuotationConfigDto['quotationSetup'];

const updaterQuotationConfigQueryData =
  (
    newData: Partial<QuotationConfigDto>,
  ): DataUpdateFunction<Partial<QuotationConfigDto> | undefined, Partial<QuotationConfigDto>> =>
  (oldQuery?: Partial<QuotationConfigDto>) => {
    return {
      ...oldQuery,
      ...newData,
    };
  };

const updaterQuotationConfigQuotationSetupQueryData =
  (newData: QuotationSetup): DataUpdateFunction<Partial<QuotationConfigDto> | undefined, Partial<QuotationConfigDto>> =>
  (oldQuery?: Partial<QuotationConfigDto>) => {
    return {
      ...oldQuery,
      quotationSetup: {
        ...oldQuery?.quotationSetup,
        ...newData,
      },
    };
  };

const updaterQuotationConfigDeedExpenseType =
  (
    newData: DeedExpenseType,
  ): DataUpdateFunction<Partial<QuotationConfigDto> | undefined, Partial<QuotationConfigDto>> =>
  (oldQuery?: Partial<QuotationConfigDto>) => {
    const quotationSetup = oldQuery?.quotationSetup;
    const deedExpense = quotationSetup?.deedExpense;
    const newDeedExpense = {
      ...deedExpense,
      ...newData,
    };
    const newQuotationSetup = {
      ...quotationSetup,
      deedExpense: newDeedExpense,
    };

    return updaterQuotationConfigQuotationSetupQueryData(newQuotationSetup as QuotationSetup)(oldQuery);
  };

export const updateQuotationConfigQueryData = (newValues: Partial<QuotationConfigDto>) => {
  const previousData = queryClient.getQueryData(QUOTATION_CONFIG_SCHEDULE);

  queryClient.setQueryData<Partial<QuotationConfigDto>>(
    QUOTATION_CONFIG_SCHEDULE,
    updaterQuotationConfigQueryData(newValues),
  );

  return () => queryClient.setQueryData(QUOTATION_CONFIG_SCHEDULE, previousData);
};

export const updateQuotationConfigQuotationSetupQueryData = (newValues: Partial<QuotationSetup>) => {
  const previousData = queryClient.getQueryData(QUOTATION_CONFIG_SCHEDULE);

  queryClient.setQueryData<Partial<QuotationConfigDto>>(
    QUOTATION_CONFIG_SCHEDULE,
    updaterQuotationConfigQuotationSetupQueryData(newValues as QuotationSetup),
  );

  return () => queryClient.setQueryData(QUOTATION_CONFIG_SCHEDULE, previousData);
};

export const updateQuotationConfigDeedExpenseType = (newValues: Partial<DeedExpenseType>) => {
  const previousData = queryClient.getQueryData(QUOTATION_CONFIG_SCHEDULE);

  queryClient.setQueryData<Partial<QuotationConfigDto>>(
    QUOTATION_CONFIG_SCHEDULE,
    updaterQuotationConfigDeedExpenseType(newValues as DeedExpenseType),
  );

  return () => queryClient.setQueryData(QUOTATION_CONFIG_SCHEDULE, previousData);
};

export default queryClient;
