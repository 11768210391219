import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import ScheduleServices from 'services/schedule/ScheduleServices';
import { ScheduleServicesGetDatesResponse } from '../interfaces/ScheduleServicesGetDatesResponse';
import { FIND_TOWER_SCHEDULE_FUTURE } from '../reactQuery/RequestConstants';
const scheduleService = new ScheduleServices();

const useDeedExpensesAVGDeliveryDisplacement = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    FIND_TOWER_SCHEDULE_FUTURE,
    () => scheduleService.getDates(towerId).then((response) => response.data as ScheduleServicesGetDatesResponse),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate, isLoading } = useMutation(
    (displacement: number) => scheduleService.putAverageDeliveryDisplacement(towerId, displacement),
    {
      onSuccess() {
        queryClient.invalidateQueries(FIND_TOWER_SCHEDULE_FUTURE);
      },
    },
  );

  const onChange = (newDisplacement: number) => {
    if (data) {
      const { averageDeliveryDate } = data;
      const momentAVG = moment(Number(averageDeliveryDate));
      const momentAVGDisplacement = momentAVG.clone().add(newDisplacement, 'M');

      if (momentAVG.isSameOrBefore(momentAVGDisplacement)) mutate(newDisplacement);
    }
  };

  return {
    data,
    isLoading,
    onChange,
  };
};

export default useDeedExpensesAVGDeliveryDisplacement;
