import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import useProjectSchedulesHandler from '../Core/customHooks/useProjectSchedulesHandler';


const ProjectSchedulesMainView = () => {
  const { projectId } = useParams();
  const {
    towersInfo,
    projectSchedules,
    getProjectSchedules,
  } = useProjectSchedulesHandler();

  useEffect(() => {
    getProjectSchedules(projectId);
  }, []);

  return (
    <Box>
      { projectSchedules && towersInfo() }
    </Box>
  );
};

export default ProjectSchedulesMainView;
