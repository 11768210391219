import React, { FC, RefObject } from 'react';
import { Divider } from '@material-ui/core';
import CustomPopover, { CustomPopoverContentProps } from 'shared/components/CustomPopover/CustomPopover';
import PopoverAction from 'shared/components/PopoverAction/PopoverAction';
import { GeneralWalletThreadInteractionDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';

interface ExtraProps {
  onClicketEdit: (interaction: GeneralWalletThreadInteractionDTO) => void;
  onClicketDelete: (interaction: GeneralWalletThreadInteractionDTO) => void;
}

interface Props extends ExtraProps {
  elementRef: RefObject<HTMLDivElement>;
}

type GeneralWalletPopoverContentProps = CustomPopoverContentProps<ExtraProps, GeneralWalletThreadInteractionDTO>;

const PopoverContent: FC<GeneralWalletPopoverContentProps> = ({ onClose, payload, extraProps }) => (
  <>
    <PopoverAction
      onClick={() => {
        if (payload?.id) extraProps.onClicketEdit(payload);
        onClose();
      }}
    >
      Editar
    </PopoverAction>
    <Divider />
    <PopoverAction
      onClick={() => {
        if (payload?.id) extraProps.onClicketDelete(payload);
        onClose();
      }}
    >
      Eliminar
    </PopoverAction>
  </>
);

const GeneralWallletDetailsThreadInteractionActions: FC<Props> = ({ elementRef, ...props }) => {
  return (
    <CustomPopover<ExtraProps, GeneralWalletThreadInteractionDTO>
      elementRef={elementRef}
      contentComponent={PopoverContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      extraProps={props}
    />
  );
};

export default GeneralWallletDetailsThreadInteractionActions;
