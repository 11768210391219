export const UPDATE_REAL_PAYMENTS_FOR_PROPERTY =
  'UPDATE_REAL_PAYMENTS_FOR_PROPERTY';

export const UPDATE_MONTHLY_PAYMENTS_FOR_PROPERTY =
  'UPDATE_MONTHLY_PAYMENTS_FOR_PROPERTY';

export const UPDATE_CLIENT_HUB_FOR_WALLET_PROPERTY =
  'UPDATE_CLIENT_HUB_FOR_WALLET_PROPERTY';

export const UPDATE_CLIENT_IS_PURCHASE_CONFIRMED =
  'UPDATE_CLIENT_IS_PURCHASE_CONFIRMED';

export const UPDATE_CHARGE_NUMBER = 'UPDATE_CHARGE_NUMBER';

export const UPDATE_PROPERTY_ID_FOR_PAYMENTS =
  'UPDATE_PROPERTY_ID_FOR_PAYMENTS';

export const updateMonthlyPaymentsForProperty = (events) => ({
  type: UPDATE_MONTHLY_PAYMENTS_FOR_PROPERTY,
  payload: events,
});

export const updateClientIsPurchaseConfirmed = (isPurchaseConfirmed) => ({
  type: UPDATE_CLIENT_IS_PURCHASE_CONFIRMED,
  payload: isPurchaseConfirmed,
});

export const updateRealPaymentsForProperty = (payments) => ({
  type: UPDATE_REAL_PAYMENTS_FOR_PROPERTY,
  payload: payments,
});

export const updateClientHubForWalletProperty = (clientWallet) => ({
  type: UPDATE_CLIENT_HUB_FOR_WALLET_PROPERTY,
  payload: clientWallet,
});

export const updateChargeNumber = (chargeNumber) => ({
  type: UPDATE_CHARGE_NUMBER,
  payload: chargeNumber,
});

export const updatePropertyIdForPayments = (propertyId) => ({
  type: UPDATE_PROPERTY_ID_FOR_PAYMENTS,
  payload: propertyId,
});
