import { isLength } from 'lodash';
import styles from '../../../assets/styles/variables.scss';
import Colors from '../../../commons/Colors';

const PAST_GRAPH_ID = 10000000;
export const HISTORY_GRAPH_ID = 'priceHistory';
export const MARKET_GRAPH_ID = 'MARKET_LINE';

const GRAPH_BASE = [
  {
    borderColor: '',
    id: MARKET_GRAPH_ID,
  },
  {
    id: 1,
    borderColor: styles.mainColor,
    backgroundColor: styles.softMainColor,
    fill: null,
  },
  {
    id: PAST_GRAPH_ID,
    label: ['Pasado'],
    borderColor: styles.mainColor,
    backgroundColor: styles.softMainColor,
    fill: null,
  },
  {
    id: 3,
    borderColor: styles.greenColor,
    backgroundColor: styles.softGreenColor,
    fill: null,
  },
  {
    id: 9,
    borderColor: styles.redColor,
    backgroundColor: styles.softRedColor,
    fill: null,
  },
  {
    id: 18,
    borderColor: styles.yellowColor,
    backgroundColor: styles.softYellowColor,
    fill: null,
  },
  {
    id: null,
    name: 'Personalizada',
    borderColor: styles.purpleColor,
    backgroundColor: styles.softPurpleColor,
    fill: null,
  },
  {
    id: HISTORY_GRAPH_ID,
    borderColor: styles.orangeColor,
    backgroundColor: styles.softOrangeColor,
    fill: null,
  },
];

const validateCustomId = (line) => {
  const isCustom =
    line.id !== 1 &&
    line.id !== PAST_GRAPH_ID &&
    line.id !== 3 &&
    line.id !== 9 &&
    line.id !== 18 &&
    line.id !== null &&
    line.id !== MARKET_GRAPH_ID &&
    line.id !== HISTORY_GRAPH_ID;
  return isCustom && typeof line.id === 'number';
};

const makeArrayDataSets = (groupId, line) => {
  let base = GRAPH_BASE.find((item) => item.id === line.id);
  if (validateCustomId(line)) {
    base = GRAPH_BASE.find((item) => item.name === 'Personalizada');
    base.id = line.id;
  }

  if (
    groupId === 0 &&
    line.id !== null &&
    line.id !== MARKET_GRAPH_ID &&
    line.id !== HISTORY_GRAPH_ID
  ) {
    const color = Colors.randomRgba();
    base.borderColor = color;
    base.backgroundColor = color;
  }

  const incrementsFixed = line.increments.map(
    (increment) => increment && increment.toFixed(2),
  );
  return {
    id: base.id,
    data: [...incrementsFixed],
    label: line.label,
    borderColor: base.borderColor,
    backgroundColor: base.backgroundColor,
    fill: base.fill,
    lineTension: 0.05,
    percentage: line.percentage,
    EARate: line.ear,
    soldInCurrentStrategy: line.soldInCurrentStrategy,
  };
};

const generateDataset = (increments) => {
  const strategyLines = {};
  increments.forEach((increment) => {
    strategyLines[increment.type] = {
      ...increment,
      strategies: increment.strategies.map((g) => {
        return makeArrayDataSets(increment.id, g);
      }),
    };
  });
  return strategyLines;
};

export default generateDataset;
