import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Card from '@material-ui/core/Card';
import { Box, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router';
import CompanySchedulesTable from './CompanySchedulesTable';
import Services from '../../../Services/CompanySchedulesServices';
import Context from '../../../../../Context';
import { DashboardRoutes } from '../../../../../routes/local/routes';
import ProjectRoutes from '../../../../../routes/local/projectRoutes';

const services = new Services();

const BoldTypography = styled(Typography)`
  /* width: 60px; */
  padding: 2px 25px;
`;

const CompanySelector = () => {
  const history = useHistory();
  const { sessionCompany, loadSessionCompany } = useContext(Context.Shared);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [projects, setProjects] = useState(null);
  const [initialDateForColumn, setInitialDateForColumn] = useState(
    moment().valueOf(),
  );

  const projectsAndTowers = async () => {
    const arrayOfProjects = await services.getCompanySchedules(
      selectedCompany || 0,
      initialDateForColumn,
    );
    setProjects(arrayOfProjects.data);
  };

  useEffect(() => {
    const companyId = sessionCompany || loadSessionCompany();
    if (!companyId) history.push(DashboardRoutes.base + ProjectRoutes.base);
    setSelectedCompany(companyId);
  });

  useEffect(() => {
    projectsAndTowers();
  }, [selectedCompany, initialDateForColumn]);

  const changeStartDate = (value) => {
    const date = value.valueOf();
    setInitialDateForColumn(date);
  };

  const minDateOnDatesSelector = projects
    ? projects.minDateOnDatesSelector
    : null;

  return (
    <Card>
      <CardContent>
        {selectedCompany && (
          <Box display="flex" pb={2}>
            <BoldTypography variant="subtitle1">Corte:</BoldTypography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                openTo="year"
                format="MMM YY"
                label=""
                views={['year', 'month', 'date']}
                value={initialDateForColumn}
                onChange={changeStartDate}
                minDate={minDateOnDatesSelector}
              />
            </MuiPickersUtilsProvider>
          </Box>
        )}
        <CompanySchedulesTable projects={projects} />
      </CardContent>
    </Card>
  );
};

export default CompanySelector;
