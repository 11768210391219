import { useEffect, useRef, useState } from 'react';

import StageProjectDTO from '../DTO/StageProjectDTO';
import StagesDTO from '../DTO/StagesDTO';
import { MoveTowerHandler } from '../types/Stages.types';
import StagesHandling from '../utils/StagesHandling';

const defaultStages = {
  records: {},
  order: [],
};

const scrollToLastList = (lastStageList: HTMLDivElement | null) => {
  if (lastStageList) {
    lastStageList.scrollIntoView();
  }
};

function useStagesState(stageProject: StageProjectDTO) {
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [stages, setStages] = useState<StagesDTO>(defaultStages);
  const lastStageList = useRef<HTMLDivElement>(null);

  const showProgress = () => {
    setIsProgressVisible(true);
  };

  const hideProgress = () => {
    setIsProgressVisible(false);
  };

  useEffect(() => {
    setProjectName(stageProject.name);
    setStages(stageProject.stages);
  }, [stageProject]);

  const addStage = (stages: StagesDTO) => {
    setStages(stages);
    scrollToLastList(lastStageList.current);
  };

  const updateStages = (stages: StagesDTO) => {
    setStages(stages);
  };

  const moveTower: MoveTowerHandler = (cors) => {
    setStages(StagesHandling.moveTower(stages, cors));
  };

  return {
    isProgressVisible,
    projectName,
    stages,
    lastStageList,
    showProgress,
    hideProgress,
    addStage,
    updateStages,
    moveTower,
  };
}

export default useStagesState;
