import { Box } from '@material-ui/core';
import { ITabOption } from '../../../Core/interfaces/ITabOptionForResultsState';
import React, { FC } from 'react';
import ResultsStateTabOption from '../ResultsStateTabOption/ResultsStateTabOption';

interface Props {
  options: ITabOption[];
  onClick: (ITabOption) => void;
}

const ResultsStateTabNavigator: FC<Props> = ({ options, onClick }) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {options.map(ResultsStateTabOption(onClick))}
    </Box>
  );
};

export default ResultsStateTabNavigator;