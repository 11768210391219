import Services from '../../../services/services';
import CompanySchedulesServicesDefinition from './CompanySchedulesServicesDefinition';

class CompanySchedules extends Services { 
  getCompanySchedules(companyId, initialDateForColumn){
    return this.get(CompanySchedulesServicesDefinition.CompanySchedules(companyId, initialDateForColumn));
  }

  getProjects() {
    return this.get(CompanySchedulesServicesDefinition.getProjects());
  }
}

export default CompanySchedules;