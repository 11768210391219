import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import {
  fetchOptionsStart,
  fetchOptionsFailure,
  fetchOptionsSuccess,
} from './actions';
import Input from './Input';
import Option from './Option/Option';
import Services from '../../../../../services/client/ClientsServices';

const services = new Services();

const Search = ({
  onFetchClients,
  onFetchOptionsSuccess,
  onFetchOptionsFailure,
  onSelectHandler,
  towerId,
  selectedClient,
  allClients,
  isLoading,
  clientEditing,
  property,
}) => {
  const [inputValue, setInputValue] = useState(selectedClient);
  const [clientOptions, setClientOptions] = useState(allClients);

  useEffect(() => {
    let active = true;
    let clientsToSet = allClients.filter(
      (client) =>
        !client.properties.some((prop) => prop.propertyId === property.id),
    );
    async function fetchClients() {
      onFetchClients();
      try {
        const res = await services.searchClientsWithProperty(
          towerId,
          property.id,
          inputValue,
        );
        if (active) {
          if (res.data && res.data.length > 0) {
            clientsToSet = res.data.filter(
              (client) =>
                !client.properties.some(
                  (prop) => prop.propertyId === property.id,
                ),
            );
            setClientOptions(clientsToSet);
          } else {
            setClientOptions(clientsToSet);
          }
          onFetchOptionsSuccess(clientsToSet);
        }
      } catch (error) {
        onFetchOptionsFailure();
      }
    }
    if (inputValue && inputValue.length > 0) {
      fetchClients();
    } else {
      setClientOptions(clientsToSet);
    }
    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <div>
      <Autocomplete
        noOptionsText="No hay clientes"
        loading={isLoading}
        loadingText="Obteniendo clientes..."
        filterOptions={(x) => x}
        options={clientOptions}
        disabled={clientEditing}
        value={{
          name: inputValue,
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <Input
            params={params}
            value={inputValue}
            onChangeHandler={(event) => {
              setInputValue(event.target.value || '');
            }}
          />
        )}
        renderOption={(option, { innerInputValue }) => (
          <Option value={option} inputValue={innerInputValue} />
        )}
        onChange={(_, value) => {
          onSelectHandler(value);
          setInputValue(value ? value.name : '');
        }}
      />
    </div>
  );
};

Search.propTypes = {
  onSelectHandler: PropTypes.func.isRequired,
  onFetchClients: PropTypes.func.isRequired,
  onFetchOptionsSuccess: PropTypes.func.isRequired,
  onFetchOptionsFailure: PropTypes.func.isRequired,
  towerId: PropTypes.string.isRequired,
  selectedClient: PropTypes.string.isRequired,
  allClients: PropTypes.array.isRequired,
  clientEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  property: PropTypes.object,
};

const mapStateToProps = (state) => ({
  towerId: state.clientProperty.root.towerId,
  allClients: state.clientProperty.root.allClients,
  isLoading: state.clientProperty.searcher.isLoading,
});

const mapDispatchToProps = {
  onFetchClients: fetchOptionsStart,
  onFetchOptionsFailure: fetchOptionsFailure,
  onFetchOptionsSuccess: fetchOptionsSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
