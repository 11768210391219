import { useSelector } from 'react-redux';
import moment from 'moment';
import useMapperForNewRows from './useMapperForNewRowsForDetails';
import useColumnsForDetails from './useColumnsForDetails';

const useRowsForExcelDownload = ({ rows }) => {
  const { initialDate, lastDate } = useSelector((state) => state.cashFlow.root);

  const orderedRows = useMapperForNewRows({ rows });

  const { columns } = useColumnsForDetails(orderedRows, initialDate, lastDate);

  const dates = columns
    .filter((column) => column.name !== 'name')
    .reduce((acc, column) => {
      acc[column.name] = moment(column.name, 'MMM. YYYY')
        .startOf('month')
        .format();

      return acc;
    }, {});


  return {
    dates,
    orderedRows,
  };
};

export default useRowsForExcelDownload;
