import { API_PATH } from '../../config/config';

const ContractsServicesDefinition = {
  getAllContractsLots: (towerId: string) => `${API_PATH}contracts/lots/${towerId}`,
  createLot: (towerId: string) => `${API_PATH}contracts/lots/${towerId}`,
  editContractLot: (contracId: number) => `${API_PATH}contracts/lots/id/${contracId}`,
  editInterestRate: (contracId: number) => `${API_PATH}contracts/lots/interest-rate/${contracId}`,
};

export default ContractsServicesDefinition;
