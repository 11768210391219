import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { QueryClientProvider, QueryClient } from 'react-query';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import { Contract } from '../../../models/Contracts';
import useContracts from '../Core/customHooks/useContracts';

import Typography from 'shared/components/Typography/Typography';

import ContractListToolbar from './views/customComponents/ContractListToolbar';
import CategoryContractList from './views/customComponents/CategoryContractList';
import ContractFormModal from '../../ContractFormModal/UI/ContractFormMainView';
import ContractDetailMainView from '../../ContractDetail/UI/ContractDetailMainView';
import ContractsFlowGrid from './views/tables/ContractsFlowGrid';
import AsyncConfirmModal from '../../../shared/components/AsyncModal/AsyncConfirmModal';
import ContractMenuList from './views/customComponents/ContractMenuList';

const Contracts: FC = () => {
  const {
    contracts,
    isLoading,
    handleChangeSearch,
    currentContract,
    setCurrentContract,
    openContractsFlow,
    setOpenContractsFlow,
    openContractForm,
    setOpenContractForm,
    openContractDetail,
    setOpenContractDetail,
    getContractsFlow,
    contractsFlowIsLoading,
    downloadFC,
    handleDelete,
    asyncConfirmDeleteContractModalRef,
    isNewContract,
    setIsNewContract,
  } = useContracts();

  const [contractsView, setContractsView] = React.useState('detailed');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newContractsView: string) => {
    if (newContractsView !== null) {
      setContractsView(newContractsView);
      if (newContractsView === 'detailed') {
        setOpenContractsFlow(false);
      } else {
        getContractsFlow();
        setOpenContractsFlow(true);
      }
    }
  };

  return (
    <>
      <ContractListToolbar
        handleChangeSearch={handleChangeSearch}
        handleNewContract={() => {
          setIsNewContract(true);
          setCurrentContract(undefined);
          setOpenContractForm(true);
        }}
        contractsView={contractsView}
        handleChangeView={handleChange}
        download={downloadFC}
      />
      {isLoading ? (
        <AbsoluteCircularProgress />
      ) : (
        <Box my={1} height="75vh">
          {!openContractsFlow ? (
            contracts && contracts?.length > 0 ? (
              <CategoryContractList
                categories={contracts || []}
                handleDeleteContract={(contract: Contract) => {
                  handleDelete(contract);
                }}
                handleEditContract={(contract: Contract) => {
                  setIsNewContract(true);
                  setCurrentContract(contract);
                  setOpenContractForm(true);
                }}
                handleSelectContract={(contract: Contract) => {
                  setCurrentContract({ ...contract });
                  setOpenContractDetail(true);
                }}
              />
            ) : (
              <ContractMenuList
                handleClose={() => {}}
                handleNewContract={() => {
                  setIsNewContract(true);
                  setOpenContractForm(true);
                }}
              />
            )
          ) : (
            <ContractsFlowGrid isLoading={contractsFlowIsLoading} />
          )}
        </Box>
      )}
      {!!currentContract && (
        <ContractDetailMainView
          key={'key-Detail-Contract-Dialog'}
          open={openContractDetail}
          currentContractId={currentContract.id}
          handleClose={() => {
            setCurrentContract(undefined);
            setOpenContractDetail(false);
          }}
          handleEditContract={() => {
            setIsNewContract(false);
            setOpenContractForm(true);
          }}
          handleDeleteContract={(): void => {
            handleDelete(currentContract);
          }}
        />
      )}
      <ContractFormModal
        key={'key-Contract-Form-Dialog'}
        currentContract={currentContract}
        open={openContractForm}
        handleUpdate={() => {
          setOpenContractForm(false);
        }}
        handleClose={() => {
          if (isNewContract === true) {
            setCurrentContract(undefined);
          }
          setOpenContractForm(false);
        }}
        handleCreate={(contract: Contract) => {
          if (!!contract) {
            setCurrentContract(contract);
            setOpenContractDetail(true);
          }
          setOpenContractForm(false);
        }}
      />
      <AsyncConfirmModal ref={asyncConfirmDeleteContractModalRef} open={true} acceptText="Eliminar">
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box marginTop={2}>
            <Typography variant="headline5">¿Eliminar contrato?</Typography>
          </Box>
          <Box marginY={2}>
            <Box>
              <Typography variant="body1">¿Seguro que deseas eliminar este contrato?</Typography>
            </Box>
          </Box>
        </Box>
      </AsyncConfirmModal>
    </>
  );
};

const queryClient = new QueryClient();

const ContractsMainView: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Contracts />
    </QueryClientProvider>
  );
};

export default ContractsMainView;
