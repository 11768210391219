import React, { Component } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import withDefaultLayout from '../../HOC/Layouts/Default/withDefaultLayout';
import TableCashFlow from '../../components/CashFlow/TableCashFlow';
import CashFlowServices from '../../services/cashFlow/CashFlowService';
import LoadableContainer from '../../components/UI/Loader';
import Card, {
  CardHeader,
  CardBody,
  CardFooter,
} from '../../components/UI/Card/Card';
import ExportExcel from '../../components/CashFlow/Imports/Imports';

class ReportContainer extends Component {
  constructor(props) {
    super(props);
    this.services = new CashFlowServices(this);
  }

  state = {
    data: [],
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.services
      .getCashFlow(this.props.match.params.towerId)
      .then((response) => {
        this.setState({ data: response.data, isLoading: false });
      });
  }

  render() {
    return (
      <LoadableContainer isLoading={this.state.isLoading}>
        <Card>
          <CardHeader>
            <span>Recaudo Ventas Futuras</span>
            {this.state.data.map((row) => {
              if (
                (row.lengthIsWrong || row.speedSalesIsMissing) &&
                row.name === 'Total'
              ) {
                return (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {row.speedSalesIsMissing &&
                      'Alguno de los grupos no tiene la velocidad de ventas de acuerdo a sus unidades \n'}
                    {row.lengthIsWrong &&
                      `la fecha máxima de recaudo no corresponde con la cantidad de meses en la rotacion de inventario del grupo "${row.type}", revisalo en la pestaña "configuración" y en "estrategia"`}
                  </Alert>
                );
              }
              return null;
            })}
          </CardHeader>
          <CardBody>
            <ExportExcel data={this.state.data} />
            {this.state.data.length > 0 && (
              <TableCashFlow data={this.state.data} />
            )}
          </CardBody>
        </Card>
      </LoadableContainer>
    );
  }
}

export default withDefaultLayout(ReportContainer);
