import { SET_PROJECTIONS } from './actions';

const initialState = {
  towerId: null,
  sales: 'EXECUTION',
  construction: 'EXECUTION',
  contracts: 'EXECUTION',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROJECTIONS:
      return { ...state, ...payload };

    default:
      return state;
  }
};
