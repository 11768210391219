export const FETCH_OPTIONS_START = 'FOST';
export const FETCH_OPTIONS_SUCCESS = 'FOSS';
export const FETCH_OPTIONS_FAILURE = 'FOF';

export const fetchOptionsStart = () => ({
  type: FETCH_OPTIONS_START,
});

export const fetchOptionsSuccess = (options, inputValue) => ({
  type: FETCH_OPTIONS_SUCCESS,
  payload: { options, inputValue },
});

export const fetchOptionsFailure = () => ({
  type: FETCH_OPTIONS_FAILURE,
});
