import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import useResultsStateViewController from '../../../../../Core/customHooks/useResultsStateViewController';
import CutsControlBandCell from './CutsControlBandCell/CutsControlBandCell';
import EventsControlBandCell from './EventsControlBandCell/EventsControlBandCell';
import styles from './BandCell.module.scss';

const BandCell = ({
  children,
  tableRow,
  tableColumn,
  column,
  classes,
  ...restProps
}) => {
  const [tab, setTab] = useState({});
  const { selectedTab, saveBudgetState } = useSelector((state) => state.cashFlow.root);

  const updateTab = (selectedTab) => {
    if (selectedTab.id !== tab.id) {
      setTab(selectedTab);
    }
  };

  useEffect(() => {
    updateTab(selectedTab);
  }, [selectedTab]);

  if (column && column.title === 'empty') {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        className={`${styles.emptyCell}`}
      />
    );
  }

  if (tab.id === 'CUT') {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        className={`${styles.cutCell} ${styles.borderTop}`}
      >
        <CutsControlBandCell children={children} column={column} />
      </TableBandHeader.Cell>
    );
  }

  if (tab.id === 'BUDGET') {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        className={`${saveBudgetState === false? styles.errorCell : styles.cutCell} ${styles.borderTop} `}
      >
        <EventsControlBandCell children={children} column={column} />
      </TableBandHeader.Cell>
    );
  }

  return (
    <TableBandHeader.Cell
      {...restProps}
      column={column}
      className={`${styles.emptyCell}`}
    />
  );
};

export default BandCell;
