import ExternalResourceForSalesType from '../../App/SoldReportForExternalResource/Core/interfaces/ExternalResourceForSalesType';
import Services from '../services';
import ExternalResourcesServicesDefinitions from './ExternalResourcesServicesDefinitions';

export default class ExternalResourcesServices extends Services {
  downloadSoldSummary = (towerId: string) =>
    this.get<Blob>(ExternalResourcesServicesDefinitions.downloadSummaryExcel(towerId), {
      responseType: 'blob',
    });

  getSellsSummary = (towerId: string) =>
    this.get<ExternalResourceForSalesType>(ExternalResourcesServicesDefinitions.getSummary(towerId));

  uploadSoldSummary = (towerId: string, form: FormData) =>
    this.post(ExternalResourcesServicesDefinitions.uploadExcel(towerId), form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
}
