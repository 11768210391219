import React from 'react'
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import { Typography, Box } from '@material-ui/core';
import styles from './ProjectResultsStateBandCell.module.scss'

const ProjectResultsStateBandCell = ({
  children,
  tableRow,
  tableColumn,
  column,
  classes,
  ...restProps
}) => {

  if (column && column.title === ' ') {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
        className={`${styles.emptyCell}`}
      />
    );
  }

  return (
    <TableBandHeader.Cell
      {...restProps}
      column={column}
      className={`${styles.emptyCell}`}
    >
      <Typography variant="body2" className={styles.bandCell}>
        {children}
      </Typography>
    </TableBandHeader.Cell>
  );
}

export default ProjectResultsStateBandCell