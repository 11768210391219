import React from 'react';
import { Box } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputWithTitleForCredit from './InputWithTitleForCredit';
import BuilderCreditFormHead from './BuilderCreditFormHead';
import SelectWithTitleForCredit from './SelectWithTitleForCredit';
import BuilderCreditTypes from '../../Core/enums/BuilderCreditType.enum';
import BuilderCreditFrequency from '../../Core/enums/BuilderCreditFrequency.enum';
import CustomTypography from '../../../../components/Typography/CustomTypography';
import Styles from './AddClientModalMainView.module.scss';
import Numbers from '../../../../helpers/numbers';
import BuilderCreditModel from '../../Core/models/BuilderCreditModel';

const BuilderCreditSetupForm = ({ update, updateDate, states }) => {
  return (
    <Box mb={2}>
      <InputWithTitleForCredit
        title="Costos financiables (CF)"
        id="bankableCosts"
        label="Costos"
        value={Numbers.cleanNumber(states.bankableCosts)}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="% Aprobación Consolidado / CF"
        id="consolidatedApproval"
        label="Porcentaje"
        isPercentage
        value={states.consolidatedApproval}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="% Cr. Preop / Cr. Consolidado"
        id="consolidatedOperative"
        label="Porcentaje"
        isPercentage
        value={states.consolidatedOperative}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="% Cr. Const / Cr. Consolidado"
        id="consolidatedBuilder"
        label=""
        isPercentage
        value={states.consolidatedBuilder}
        onChange={update}
      />
      <BuilderCreditFormHead title="Valor sugerido" />
      <InputWithTitleForCredit
        title="Crédito Consolidado"
        id="suggestedConsolidatedCredit"
        label=""
        value={states.suggestedConsolidatedCredit}
        disabled={true}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="Crédito Pre-Operativo"
        id="suggestedOperativeCredit"
        label="Valor "
        value={states.suggestedOperativeCredit}
        disabled={true}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="Crédito Constructor"
        id="suggestedBuilderCredit"
        label="Valor "
        value={states.suggestedBuilderCredit}
        disabled={true}
        onChange={update}
      />
      <BuilderCreditFormHead title="Valor aprobado" />
      <InputWithTitleForCredit
        title="Crédito Consolidado"
        id="approvedConsolidatedCredit"
        label=""
        value={states.approvedConsolidatedCredit}
        disabled={true}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="Crédito Pre-Operativo"
        id="approvedOperativeCredit"
        label="Valor "
        value={states.approvedOperativeCredit}
        onChange={update}
      />
      <InputWithTitleForCredit
        title="Crédito Constructor"
        id="approvedBuilderCredit"
        label="Valor "
        value={states.approvedBuilderCredit}
        pb={3}
        onChange={update}
      />
      <BuilderCreditFormHead title="" />
      <SelectWithTitleForCredit
        title="Tipo de tasa"
        id="feeType"
        value={states.feeType ?? BuilderCreditTypes.UVR.code}
        classObjectForOptions={BuilderCreditTypes}
        defaultValue={BuilderCreditTypes.UVR.code}
        onChange={update}
      />
      <InputWithTitleForCredit
        title={
          states.feeType === BuilderCreditTypes.UVR.code
            ? 'Spread UVR e.a'
            : 'Interés Pesos e.a'
        }
        id="annualFee"
        label="Valor "
        value={states.annualFee}
        isPercentage
        pb={3}
        onChange={update}
      />
      <SelectWithTitleForCredit
        title="Periocidad Intereses"
        id="frequency"
        classObjectForOptions={BuilderCreditFrequency}
        value={states.frequency}
        onChange={update}
      />
      <Box display="flex" mb={3}>
        <CustomTypography
          className={Styles.indicator}
          variant="description"
          color="#000000"
        >
          Fecha Primer Desembolso
        </CustomTypography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="firstDisbursementDate"
            label="Fecha Primer Desembolso"
            format="dd/MM/yyyy"
            value={moment(Numbers.cleanNumber(states.firstDisbursementDate))}
            onChange={updateDate('firstDisbursementDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

BuilderCreditSetupForm.propTypes = {
  states: PropTypes.objectOf(BuilderCreditModel),
  update: PropTypes.func,
  updateDate: PropTypes.func,
};

export default BuilderCreditSetupForm;
