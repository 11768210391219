import React, { FC } from 'react';
import useQuotationConfigFinalFeeForm from 'App/QuotationConfig/core/customHooks/useQuotationConfigFinalFeeForm';
import { QuotationConfigFormatStringDate } from 'App/QuotationConfig/core/QuotationConfigUtils';
import { QuotationConfigDto } from '../../../core/DTO/QuotationConfigDto';
import QuotationConfigCollapse from '../../components/QuotationConfigCollapse/QuotationConfigCollapse';
import QuotationConfigCollapseContentDivider from '../../components/QuotationConfigCollapseContentDivider/QuotationConfigCollapseContentDivider';
import QuotationConfigFinalFeeLimitDate from './QuotationConfigFinalFeeLimitDate';
import QuotationConfigFinalFeeFormInitialFeeTypes from './QuotationConfigFinalFeeFormInitialFeeTypes';

const QuotationConfigFinalFeeForm: FC<QuotationConfigDto> = (quotationConfig) => {
  const {
    maximumCollectionDate,
    quotationSetup,
    averageDeliveryDate,
    averageDeliveryDateIsBeforeOfMaximumCollectionDate,
  } = useQuotationConfigFinalFeeForm(quotationConfig);
  return (
    <QuotationConfigCollapse
      name="Fecha Cuota Final"
      state={averageDeliveryDateIsBeforeOfMaximumCollectionDate ? 'warning' : 'success'}
      label={QuotationConfigFormatStringDate(averageDeliveryDate)}
    >
      <QuotationConfigFinalFeeLimitDate
        maximumCollectionDate={maximumCollectionDate}
        averageDeliveryDate={averageDeliveryDate}
      />
      <QuotationConfigCollapseContentDivider content="Tipos de Cuota Final" />
      <QuotationConfigFinalFeeFormInitialFeeTypes
        quotationFinalPaymentTypes={quotationSetup?.quotationFinalPaymentTypes}
        defaulFinalPaymentType={quotationSetup?.defaulFinalPaymentType}
      />
    </QuotationConfigCollapse>
  );
};

export default QuotationConfigFinalFeeForm;
