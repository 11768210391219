import useFetchAreaDisabled from './useFetchAreaDisabled';
import useFetchAreas from './useFetchAreas';

interface IUseAreasProps {
  towerId: string;
}

const useAreas = (props: IUseAreasProps) => {
  const { towerId } = props;
  const { areasResponse, isLoading, isSuccess } = useFetchAreas({ towerId });
  const {
    response: isDisabledResponse,
    isLoading: isLoadingDisabled,
    isSuccess: isSuccessDisabled,
  } = useFetchAreaDisabled(towerId);

  return {
    areasResponse,
    isDisabledResponse,
    isLoading: isLoading || isLoadingDisabled,
    isSuccess: isSuccess && isSuccessDisabled,
  };
};

export default useAreas;
