import { ITabOption } from '../../interfaces/ITabOptionForResultsState';

export const BASE = 'BASE';
export const CUT = 'CUT';
export const BUDGET = 'BUDGET';
export const BASE_CONTROL_NAME = 'Control base'
export const CUT_CONTROL_NAME = 'Control cortes'
export const BUDGET_CONTROL_NAME = 'Control presupuesto'

export const NAME = 'Nombre';
export const TOTAL = 'Total';
export const PERCENTAGE = '%Ventas';
export const ACCUMULATED = 'Acumulado';
export const PROJECTED = 'Proyectado';
export const PREVIOUS_CONTROL = 'Corte anterior';
export const CONTROL_PERCENTAGE = '%Ventas Corte';
export const VARIATION = 'Variación';
export const CURRENT_CONTROL = 'Corte actual';
export const FIRST_BUDGET = 'Corte 1';
export const FIRST_PERCENTAGE = '%Ventas 1';
export const FIRST_VARIATION = 'Variación 1';
export const SECOND_BUDGET = 'Corte 2';
export const SECOND_PERCENTAGE = '%Ventas 2';
export const SECOND_VARIATION = 'Variación 2';
export const THIRD_BUDGET = 'Corte 3';
export const THIRD_PERCENTAGE = '%Ventas 3';
export const THIRD_VARIATION = 'Variación 3';
export const EMPTYCELL = 0;
export const PERCENTAGE_EMPTY_CELL = '0.00%';

export const expensesNames = [
  'Total Ventas',
  'Total ventas realizadas ',
  'Total ventas futuras',
  'Rendimientos Financieros',
  'Utilidad antes de impuestos',
  'Intereses financieros',
  'Intereses',
  'Corrección monetaria',
  '4 * mil',
  'Construcción'
]

export const variationColumnsNames = [
  VARIATION,
  FIRST_VARIATION,
  SECOND_VARIATION,
  THIRD_VARIATION,
]

export const RESULTS_STATE_TABS: ITabOption[] = [
  {
    id: BASE,
    label: BASE_CONTROL_NAME,
    selected: true,
  },
  {
    id: CUT,
    label: CUT_CONTROL_NAME,
  },
  {
    id: BUDGET,
    label: BUDGET_CONTROL_NAME,
  },
];

export const columnsForBaseControl = [
  { key: NAME, header: NAME },
  { key: TOTAL, header: TOTAL },
  { key: PERCENTAGE, header: PERCENTAGE },
  { key: ACCUMULATED, header: ACCUMULATED },
  { key: PROJECTED, header: PROJECTED },
]

export const baseControlHeadersForFilterRows = [
  NAME,
  TOTAL,
  PERCENTAGE,
  ACCUMULATED,
  PROJECTED,
]

export const columnsForCutControl = [
  { key: NAME, header: NAME },
  { key: CURRENT_CONTROL, header: CURRENT_CONTROL },
  { key: PERCENTAGE, header: PERCENTAGE },
  { key: PREVIOUS_CONTROL, header: PREVIOUS_CONTROL },
  { key: CONTROL_PERCENTAGE, header: CONTROL_PERCENTAGE },
  { key: VARIATION, header: VARIATION },
]

export const cutHeadersForFilterRows = [
  NAME,
  CURRENT_CONTROL,
  PERCENTAGE,
  PREVIOUS_CONTROL,
  CONTROL_PERCENTAGE,
  VARIATION
]

export const columnsForFirstBudgetControl = [
  { key: NAME, header: NAME },
  { key: CURRENT_CONTROL, header: CURRENT_CONTROL },
  { key: PERCENTAGE, header: PERCENTAGE },
  { key: FIRST_BUDGET, header: FIRST_BUDGET },
  { key: FIRST_PERCENTAGE, header: FIRST_PERCENTAGE },
  { key: FIRST_VARIATION, header: FIRST_VARIATION },
]

export const budgetHeadersForFirstFilterRows = [
  NAME,
  CURRENT_CONTROL,
  PERCENTAGE,
  FIRST_BUDGET,
  FIRST_PERCENTAGE,
  FIRST_VARIATION,
]

export const columnsForSecondBudgetControl = [
  { key: NAME, header: NAME },
  { key: CURRENT_CONTROL, header: CURRENT_CONTROL },
  { key: PERCENTAGE, header: PERCENTAGE },
  { key: SECOND_BUDGET, header: SECOND_BUDGET },
  { key: SECOND_PERCENTAGE, header: SECOND_PERCENTAGE },
  { key: SECOND_VARIATION, header: SECOND_VARIATION },
]

export const budgetHeadersForSecondFilterRows = [
  NAME,
  CURRENT_CONTROL,
  PERCENTAGE,
  SECOND_BUDGET,
  SECOND_PERCENTAGE,
  SECOND_VARIATION,
]

export const columnsForThirdBudgetControl = [
  { key: NAME, header: NAME },
  { key: CURRENT_CONTROL, header: CURRENT_CONTROL },
  { key: PERCENTAGE, header: PERCENTAGE },
  { key: THIRD_BUDGET, header: THIRD_BUDGET },
  { key: THIRD_PERCENTAGE, header: THIRD_PERCENTAGE },
  { key: THIRD_VARIATION, header: THIRD_VARIATION },
]

export const budgetHeadersForThirdFilterRows = [
  NAME,
  CURRENT_CONTROL,
  PERCENTAGE,
  THIRD_BUDGET,
  THIRD_PERCENTAGE,
  THIRD_VARIATION
]
