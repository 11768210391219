import React, { FC } from 'react';
import {
  Grid,
  TableHeaderRow,
  TableSummaryRow,
  TableFixedColumns,
  Table,
  TableInlineCellEditing,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { EditingCell, EditingState, IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import TableHeaderRowCell from '../../../../shared/tables/TableHeaderRowCell';
import TableContainer from '../../../../shared/tables/TableContainer';
import TableCell from '../../../../shared/tables/TableCell';
import TableCellEditing from '../../../../shared/tables/TableCellEditing';

import useColumnsAndRowsForContractsEvalproGroups from '../../core/customHooks/useColumnsAndRowsForContractsEvalproGroups';
import { EvalproGroupDto } from '../../core/DTO/ContractsEvalpro.dto';
import TableSummaryRowCell from '../../../../shared/tables/TableSummaryRowCell';
import useContractsEvalProTowerStatistics from '../../core/customHooks/useContractsEvalProTowerStatistics';
import ContractsEvalproGridPercentEditProvider from './ContractsEvalproGridPercentEditProvider';
import ContractsEvalproGridNumberProvider from './ContractsEvalproGridNumberProvider';
import ContractsEvalproGridSummaryItemComponent from './ContractsEvalproGridSummaryItemComponent';
import ContractsEvalproGridTitleProvider from './ContractsEvalproGridTitleProvider';
import ContractsEvalproGridToolbar from './ContractsEvalproGridToolbar';
import ContractsEvalproGridToolbarRoot from './ContractsEvalproGridToolbarRoot';

const ContractsEvalproGroupsGrid: FC = () => {
  const {
    rows,
    isLoading,
    columns,
    totalSummaryItems,
    editingStateColumnExtensions,
    commitChanges,
    summaryCalculator,
  } = useColumnsAndRowsForContractsEvalproGroups();

  const { totalSales } = useContractsEvalProTowerStatistics();

  const [editingCells, setEditingCells] = React.useState<EditingCell[]>([]);
  const [rowChanges, setRowChanges] = React.useState({});

  const getRowId = (row: EvalproGroupDto) => row.contractCategory.id;

  return (
    <>
      {isLoading ? (
        <AbsoluteCircularProgress />
      ) : (
        <Grid rows={rows || []} columns={columns} getRowId={getRowId}>
          <ContractsEvalproGridTitleProvider for={['categoryName']} />
          <ContractsEvalproGridNumberProvider for={['total']} />
          <ContractsEvalproGridPercentEditProvider for={['percentage']} />
          <EditingState
            columnExtensions={editingStateColumnExtensions}
            editingCells={editingCells}
            onEditingCellsChange={setEditingCells}
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            onCommitChanges={commitChanges}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary calculator={summaryCalculator} />
          <Table columnExtensions={columns} containerComponent={TableContainer} cellComponent={TableCell} />
          <TableHeaderRow cellComponent={TableHeaderRowCell} />
          <Toolbar rootComponent={ContractsEvalproGridToolbarRoot} />
          <ContractsEvalproGridToolbar totalSales={totalSales} />
          <TableInlineCellEditing startEditAction="click" cellComponent={TableCellEditing} />
          <TableSummaryRow
            itemComponent={ContractsEvalproGridSummaryItemComponent}
            totalCellComponent={TableSummaryRowCell}
          />
          <TableFixedColumns leftColumns={['categoryName']} />
        </Grid>
      )}
    </>
  );
};

export default ContractsEvalproGroupsGrid;
