const QuotationDiscountType = {
  MANUAL: {
    code: 'M',
    value: 'Manual',
  },
  ASSIGNED: {
    code: 'A',
    value: 'Automática',
  },
};

export default QuotationDiscountType;
