import React, { FC } from 'react';
import { Drawer, DrawerProps } from '@material-ui/core';
import styles from './CustomDrawer.module.scss';

const CustomDrawer: FC<DrawerProps> = ({ children, ...props }) => {
  return (
    <Drawer {...props} className={`${styles.drawer} ${props.className || ''}`}>
      <div className={styles.container}>{children}</div>
    </Drawer>
  );
};

export default CustomDrawer;
