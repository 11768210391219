import React from 'react';
import { PropTypes } from 'prop-types';
import styles from '../views/AddDocumentTemplate.module.scss';

const DocumentTemplateVariableNamesCell = ({ name, description }) => {
  return (
    <li>
      <span className={styles.variableName}>{name}</span>
      <span className={styles.variableDescription}>{description}</span>
    </li>
  );
};

DocumentTemplateVariableNamesCell.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

export default DocumentTemplateVariableNamesCell;
