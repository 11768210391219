import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from '@material-ui/core';
import InputWithTitleForClient from '../../../AddClientModal/UI/Views/input/InputWithTitleForClient';
import SelectWithTitleForClient from '../../../AddClientModal/UI/Views/input/SelectWithTitleForClient';
import MainStreetType from '../../Core/enums/MainStreetType.enum';
import CardinalityType from '../../Core/enums/CardinalityType.enum';
import ClientIdentityType from '../../Core/enums/ClientIdentityType.enum';

const HelperAddressForClientType = ({ update, clientAddress }) => {
  return (
    <>
      <Grid item md={6}>
        <SelectWithTitleForClient
          title="Tipo vía principal"
          id="mainStreetType"
          required
          classObjectForOptions={MainStreetType}
          defaultValue={clientAddress?.mainStreetType}
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <InputWithTitleForClient
          title="Número vía principal"
          id="mainStreetNumber"
          label="Ingrese el número"
          defaultValue={clientAddress?.mainStreetNumber}
          multiline
          required
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <SelectWithTitleForClient
          title="Cardinalidad vía principal"
          id="mainStreetCardinality"
          classObjectForOptions={CardinalityType}
          defaultValue={clientAddress?.mainStreetCardinality}
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <InputWithTitleForClient
          title="Número vía secundaria"
          id="secondaryStreetNumber"
          required
          label="Ingrese el número"
          defaultValue={clientAddress?.secondaryStreetNumber}
          multiline
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <SelectWithTitleForClient
          title="Cardinalidad vía secundaria"
          id="secondaryStreetCardinality"
          classObjectForOptions={CardinalityType}
          defaultValue={clientAddress?.secondaryStreetCardinality}
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <InputWithTitleForClient
          title="Distancia"
          id="distance"
          label="Ingrese una distancia"
          defaultValue={clientAddress?.distance}
          multiline
          onChange={update}
        />
      </Grid>
      <Grid item md={6} />
      <Grid item md={6}>
        <SelectWithTitleForClient
          title="Unidad identidad 1"
          id="firstIdentityType"
          classObjectForOptions={ClientIdentityType}
          defaultValue={clientAddress?.firstIdentityType}
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <InputWithTitleForClient
          title="Número Unidad identidad"
          id="firstIdentityNumber"
          label="Ingrese un número"
          defaultValue={clientAddress?.firstIdentityNumber}
          multiline
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <SelectWithTitleForClient
          title="Unidad identidad 2"
          id="secondIdentityType"
          classObjectForOptions={ClientIdentityType}
          defaultValue={clientAddress?.secondIdentityType}
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <InputWithTitleForClient
          title="Número Unidad identidad 2"
          id="secondIdentityNumber"
          label="Ingrese un número"
          defaultValue={clientAddress?.secondIdentityNumber}
          multiline
          onChange={update}
        />
      </Grid>
      <Grid item md={6}>
        <InputWithTitleForClient
          title="Barrio"
          id="neighborhood"
          label="Ingrese un barrio"
          defaultValue={clientAddress?.neighborhood}
          multiline
          onChange={update}
        />
      </Grid>
    </>
  );
};

HelperAddressForClientType.propTypes = {
  update: PropTypes.func.isRequired,
  clientAddress: PropTypes.object,
};

export default HelperAddressForClientType;
