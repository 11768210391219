import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomButton from '../../../../components/Buttons/CustomButton';
import ResultsStateTabNavigation from '../components/ResultsStateTabNavigation/ResultsStateTabNavigation';
import { BASE } from '../../Core/customHooks/constants/ResultsStateConstants';
import useDownloadForResultsState from '../../Core/customHooks/useDownloadForResultsState';
import TypeSelector from './TypeSelector';
import Styles from '../styles/ResultStateHeader.module.scss';
import RestCacheButton from 'shared/components/ResetCacheButton/ResetCacheButton';

const ResultStateHeader = ({
  tabs,
  selectedTab,
  changeTab,
  isLoading,
  forceUpdate,
}) => {
  const { resultsStateRows, cutsControl, budgetControl } = useSelector((state) => state.cashFlow.root || {});

  const { downloadExcel } = useDownloadForResultsState({
    resultsStateRows, 
    cutsControl, 
    budgetControl, 
    selectedTab
  });

  return (
    <Box>
      <Box className={Styles.headerContainer}>
        <Box className={Styles.titleAndSelectorContainer}>
          <Box className={Styles.titleAndHeaderContainer}>
            <Box>
              <Typography className={Styles.title}>
                Estado de resultados
              </Typography>
            </Box>
          </Box>
          <Box className={Styles.typeSelectorContainer}>
            <TypeSelector />
          </Box>
        </Box>
        {!isLoading && (
          <ResultsStateTabNavigation
            isLoading={isLoading}
            onClick={changeTab}
            options={tabs}
          />
        )}
      </Box>
      <Box>
        <RestCacheButton callback={() => { forceUpdate() }}>Recargar tabla</RestCacheButton>
      </Box>
      <Box className={Styles.reportButtonContainer}>
        <CustomButton
          type="primary"
          onClick={downloadExcel}
        >
          Generar reporte
        </CustomButton>
      </Box>
    </Box>
  );
};

ResultStateHeader.propTypes = {
  towerWithoutQuotation: PropTypes.bool,
  speedSalesIsMissing: PropTypes.bool,
};

export default ResultStateHeader;
