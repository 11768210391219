import { useRecoilState } from 'recoil';
import useAlert from '../../../../../shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from '../../../../../shared/components/Alert/Core/interfaces/ModalProps';
import { AreaEditionHaveChangesAtom } from '../../recoil/AreaEditionHaveChangesAtom';
import { AreaEditionTablePopoverProps } from './AreaEditionTablePopoverProps';

const useAreaEditionTablePopover = (props: AreaEditionTablePopoverProps) => {
  const [_, setAreaEditionHaveChangesState] = useRecoilState(AreaEditionHaveChangesAtom);

  const closeAction = async () => {
    await props.removeColumn(props.areaType);
    setAreaEditionHaveChangesState(true);
  };

  const { showAlert: showRemoveColumnAlert } = useAlert({
    isOpen: false,
    title: 'Eliminar columna',
    acceptAction: closeAction,
    variant: AlertVariants.Destructive,
  });

  const handleClickRemoveColumn = () => {
    showRemoveColumnAlert(`¿Desea eliminar la columna ${props.areaType?.name} permanentemente?`);
  };

  return {
    handleClickRemoveColumn,
  };
};

export default useAreaEditionTablePopover;
