import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { ITabOption } from 'App/DeedExpenses/core/interfaces/ITabOption';
import Typography from 'shared/components/Typography/Typography';

import styles from './DeedExpensesTabOption.module.scss';

const DeedExpensesTabOption =
  (onClick: (option: ITabOption) => void): FC<ITabOption> =>
  (option: ITabOption) =>
    (
      <Box
        key={option.id}
        className={`${styles.DeedExpensesTabOption} ${option.selected ? styles.Selected : ''}`}
        onClick={() => onClick(option)}
      >
        <Box>
          <Typography>{option.label}</Typography>
        </Box>
      </Box>
    );

export default DeedExpensesTabOption;
