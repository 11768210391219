import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {useParams} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import Loader from '../../components/UI2/Loader/Loader';
import ConstructionFlow from '../../components/TowerBuilder/ConstructionFlow';
import { setTableWatcher, setProject, setLoading, setTowers } from './action';
import Services from '../../services/towerBuilding/TowerBuildingServices';
import styles from './towerBuilder.module.scss';

const services = new Services();

const TowerBuilder = ({
  open,
  apiLoading,
  match,
  onSetProject,
  onSetTowers,
  onSetLoading,
}) => {
  const {towerId} = useParams();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function fetch() {
      try {
        onSetLoading(true);
        const response = await services.getTowers(towerId);
        const { id, name, towers } = response.data;
        onSetProject({ id, name });
        onSetTowers(towers);
        onSetLoading(false);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        onSetLoading(false);
      }
    }
    fetch();
  }, [open]);
  return (
    <>
      <Loader isLoading={apiLoading}>
        <ConstructionFlow />
      </Loader>
    </>
  );
};

TowerBuilder.propTypes = {
  projectId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  apiLoading: PropTypes.bool.isRequired,
  isTableVisible: PropTypes.bool.isRequired,
  onSetProject: PropTypes.func.isRequired,
  onSetTowers: PropTypes.func.isRequired,
  onSetLoading: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  open: state.towerBuilder.root.open,
  apiLoading: state.towerBuilder.root.apiLoading,
});

const mapDispatchToProps = {
  onSetTableWatcher: setTableWatcher,
  onSetProject: setProject,
  onSetTowers: setTowers,
  onSetLoading: setLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(TowerBuilder);
