import { useSelector } from 'react-redux';
import moment from 'moment';
import Numbers from '../../../../helpers/numbers';

const useDataFormatterForChart = () => {
  const realData = useSelector((state) => state.budget.chart.data) || [];
  const { units: totalUnits, balancePointDate } =
    useSelector((state) => state.budget.distribution) || {};

  const dataFormatted = realData.reduce(
    (dataListAndCounter, line) => {
      const counter = line.realSales + dataListAndCounter.counter;

      if (counter <= totalUnits && dataListAndCounter.counter < totalUnits) {
        const lineDate = moment(line.date, 'MMM-YY');
        const isBeforeCurrentMonth = lineDate.isSameOrBefore(moment(), 'month');
        const isCurrentMonth = lineDate.isSame(moment(), 'month');
        const isNextMonth = lineDate.isSame(moment().add(1, 'month'), 'month');
        const backgroundToCurrentMonth = isBeforeCurrentMonth ? totalUnits : 0;
        const isBalancedPoint = moment(Number(balancePointDate)).isSame(
          lineDate,
          'month',
        );
        return {
          all: [
            ...dataListAndCounter.all,
            {
              date: line.date,
              realSales: Numbers.floor(counter),
              backgroundToCurrentMonth,
              isBalancedPoint,
              isCurrentMonth,
              isNextMonth,
            },
          ],
          counter,
        };
      }
      return dataListAndCounter;
    },
    { all: [], counter: 0 },
  );

  return { dataFormatted, totalUnits };
};

export default useDataFormatterForChart;
