import Services from '../../../services/services';
import Definition from './PropertyTypeServiceDefinition';

class PropertyTypeService extends Services {
  getTypes({ towerId }) {
    return this.get(`${Definition.base()}/${towerId}`);
  }
  create({ name, towerId }) {
    return this.post(Definition.base(), { name, towerId });
  }
  setTypeToProperty({ propertyId, typeId }) {
    return this.put(Definition.setTypeToProperty({ propertyId, typeId }));
  }
}

export default PropertyTypeService;
