// @ts-nocheck
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MaterialTable, { MTableCell } from 'material-table';
import NumberFormat from 'react-number-format';
import MaterialIcons from '../../../UI2/MaterialTableIcons';
import styles from '../../TowerBuilder.module.scss';

const BuilderTable = ({ addRow, updateRow, deleteRow, builder }) => {
  const [builders, setBuilders] = useState(builder);
  useEffect(() => {
    setBuilders(builder);
  }, [builder]);
  const areaField = (props) => (
    <NumberFormat
      placeholder="área"
      value={props.value}
      customInput={TextField}
      thousandSeparator
      suffix={'mt2'}
      inputProps={{ maxLength: 10 }}
      allowNegative={false}
      decimalSeparator={false}
      onValueChange={(e) => props.onChange(e.value)}
    />
  );
  const areaRender = (rowData) => (
    <NumberFormat
      value={Number(rowData.area)}
      thousandSeparator
      suffix={'mt2'}
      displayType={'text'}
      allowNegative={false}
      decimalSeparator={false}
      renderText={(value) => <div>{value}</div>}
    />
  );

  const currencyFormat = (props) => {
    return (
      <NumberFormat
        placeholder={props.columnDef.title}
        value={props.value}
        customInput={TextField}
        thousandSeparator
        prefix={'$'}
        allowNegative={false}
        decimalSeparator={false}
        onValueChange={(e) => props.onChange(e.value)}
      />
    );
  };
  const currencyTotalFormat = (props) => {
    return (
      <NumberFormat
        placeholder={props.columnDef.title}
        value={Number(props.rowData.area) * Number(props.rowData.price)}
        customInput={TextField}
        thousandSeparator
        prefix={'$'}
        allowNegative={false}
        decimalSeparator={false}
        onValueChange={(e) => props.onChange(e.value)}
      />
    );
  };
  const currencyFormatRender = (rowData, name) => (
    <NumberFormat
      value={Number(rowData[name])}
      thousandSeparator
      prefix={'$'}
      allowNegative={false}
      decimalSeparator={false}
      displayType={'text'}
      renderText={(value) => <div>{value}</div>}
    />
  );

  const [columns] = useState([
    { field: 'name', title: 'Tipo' },
    {
      field: 'area',
      title: 'área',
      editComponent: areaField,
      render: areaRender,
    },
    {
      field: 'price',
      title: 'Precio',
      editComponent: currencyFormat,
      render: (rowData) => currencyFormatRender(rowData, 'price'),
    },
    {
      field: 'totalCost',
      title: 'Costo Total',
      editComponent: currencyTotalFormat,
      render: (rowData) => currencyFormatRender(rowData, 'totalCost'),
    },
  ]);

  const data = (prevData) => {
    return prevData.filter((row) => !row.total && !row.pricesArray);
  };

  return (
    <Grid container direction="row" className={styles.gridContainer}>
      <MaterialTable
        minRows={0}
        icons={MaterialIcons}
        columns={columns}
        data={data(builders)}
        editable={{
          isEditable: (rowData) => rowData.name !== 'totalCost',
          onRowAdd: addRow,
          onRowUpdate: updateRow,
          onRowDelete: deleteRow,
        }}
        options={{
          showTitle: false,
          rowStyle: {
            backgroundColor: '#FFF',
          },
          pageSize: 5,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Parece que aún no has puesto datos 🧐',
            addTooltip: 'Agregar',
            deleteTooltip: 'Borrar registro',
            editTooltip: 'Editar registro',
            filterRow: {
              filterTooltip: 'Flitrar',
            },
            editRow: {
              deleteText: '¿Estás seguro de borrar esta fila?',
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Guardar',
            },
          },
          header: {
            actions: '',
          },
          toolbar: {
            searchTooltip: 'Buscar',
            addRemoveColumns: 'Agregar o quitar columnas',
            searchPlaceholder: 'Buscar',
          },
          pagination: {
            labelRowsSelect: 'filas',
            labelDisplayedRows: '{count} de {from}-{to}',
            firstTooltip: 'Primera pág.',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
        style={{ width: '100vw' }}
      />
    </Grid>
  );
};

BuilderTable.propTypes = {
  builder: PropTypes.func,
  addRow: PropTypes.func,
  updateRow: PropTypes.func,
  deleteRow: PropTypes.func,
};

const mapStateToProps = (state) => ({
  builder: state.towerBuilder.builder.builder,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderTable);
