import Services from '../../../services/services';
import ProjectSchedulesServicesDefinition from './ProjectSchedulesServicesDefinition';

class ProjectSchedulesServices extends Services {
  getProjectSchedules(projectId) {
    return this.get(
      ProjectSchedulesServicesDefinition.getProjectSchedules(projectId),
    );
  }

  putConstructionStartDate(towerId, body) {
    return this.put(
      ProjectSchedulesServicesDefinition.putConstructionStartDate(towerId),
      body,
    );
  }

  putSalesStartDate(towerId, body) {
    return this.put(
      ProjectSchedulesServicesDefinition.putSalesStartDate(towerId),
      body,
    );
  }

  putEndOfSalesDate(towerId, body) {
    return this.put(
      ProjectSchedulesServicesDefinition.putEndOfSalesDate(towerId),
      body,
    );
  }

  putMaximumCollectionDate(towerId, body) {
    return this.put(
      ProjectSchedulesServicesDefinition.putMaximumCollectionDate(towerId),
      body,
    );
  }

  putAverageDeliveryDate(towerId, body) {
    return this.put(
      ProjectSchedulesServicesDefinition.putAverageDeliveryDate(towerId),
      body,
    );
  }

  putBalancePointDate(towerId, body) {
    return this.put(
      ProjectSchedulesServicesDefinition.putBalancePointDate(towerId),
      body,
    );
  }
}

export default ProjectSchedulesServices;
