export const DashboardRoutes = {
  base: '/dashboard',
  towers: { value: '/towers/', withIndicator: '/towers/:projectId' },
  construction: {
    value: '/construction/',
    withIndicator: '/construction/:towerId',
  },
  salesEvalpro: {
    value: '/salesEvalpro/',
    withIndicator: '/salesEvalpro/:towerId',
  },
  salesSpeed: {
    value: '/salesSpeed/',
    withIndicator: '/salesSpeed/:towerId',
  },
  salesSpeedEvalpro: {
    value: '/salesSpeedEvalpro/',
    withIndicator: '/salesSpeedEvalpro/:towerId',
  },
  building: { value: '/building/', withIndicator: '/building/:towerId' },
  areas: { value: '/areas/', withIndicator: '/areas/:towerId' },
  areasv2: { value: '/areas/', withIndicator: '/areas/:towerId' },
  areasAdditional: {
    value: '/areas-additional/',
    withIndicator: '/areas-additional/:towerId',
  },
  prime: { value: '/prime/', withIndicator: '/prime/:towerId' },
  user: '/user',
  companySchedules: '/companySchedules',
  summary: { value: '/summary/', withIndicator: '/summary/:towerId' },
  detailAdmin: {
    value: '/detailAdmin/',
    withIndicator: '/detailAdmin/:towerId',
  },
  detail: { value: '/detail/', withIndicator: '/detail/:towerId' },
  rackAreas: { value: '/rackAreas/', withIndicator: '/rackAreas/:towerId' },
  clustering: { value: '/clustering/', withIndicator: '/clustering/:towerId' },
  propertyEdition: {
    value: '/property-edition/',
    withIndicator: '/property-edition/:towerId',
  },
  graphicMaterial: {
    value: '/graphic-material/',
    withIndicator: '/graphic-material/:towerId',
  },
  documentTemplates: {
    value: '/document-templates/',
    withIndicator: '/document-templates/:towerId',
  },
  quotationConfig: {
    value: '/quotation-config/',
    withIndicator: '/quotation-config/:towerId',
  },
  buyerCostumers: {
    value: '/buyer-customers/',
    withIndicator: '/buyer-customers/:towerId',
  },
  projectionTypes: {
    value: '/projection-types/',
    withIndicator: '/projection-types/:towerId',
  },
  projectStages: {
    value: '/project-stages/',
    withIndicator: '/project-stages/:towerId',
  },
  salesSpeedHelper: {
    value: '/sales-speed-helper/',
    withIndicator: '/sales-speed-helper/:towerId',
  },
  strategy: { value: '/strategy/', withIndicator: '/strategy/:towerId' },
  salesRoom: { value: '/salesRoom/', withIndicator: '/salesRoom/:towerId' },
  salesRoomClient: {
    value: '/salesRoom/',
    withIndicator: '/salesRoom/:towerId/:clientId',
  },
  clients: { value: '/clients/', withIndicator: '/clients/:towerId' },
  soldCollection: {
    value: '/soldCollection/',
    withIndicator: '/soldCollection/:towerId',
  },
  clients2: { value: '/clients2/', withIndicator: '/clients2/:towerId' },
  futureSalesSpeed: {
    value: '/futureSalesSpeed/',
    withIndicator: '/futureSalesSpeed/:towerId',
  },
  schedule: { value: '/schedule/', withIndicator: '/schedule/:towerId' },
  deed: { value: '/deed/', withIndicator: '/deed/:towerId' },
  deedEvalpro: {
    value: '/evalpro-deed/',
    withIndicator: '/evalpro-deed/:towerId',
  },
  report: { value: '/report/', withIndicator: '/report/:towerId' },
  contracts: { value: '/contract/', withIndicator: '/contract/:towerId' },
  contractsLots: { value: '/lot/', withIndicator: '/lot/:towerId' },

  cashFlow: { value: '/cash-flow/', withIndicator: '/cash-flow/:towerId' },
  cashFlowEvalpro: {
    value: '/cash-flow-evalpro/',
    withIndicator: '/cash-flow-evalpro/:towerId',
  },
  totalCashFlow: {
    value: '/total-cash-flow/',
    withIndicator: '/total-cash-flow/:towerId',
  },
  resultsState: {
    value: '/results-state/',
    withIndicator: '/results-state/:towerId',
  },
  saleRequests: {
    value: '/sale-requests/',
    withIndicator: '/sale-requests/:towerId',
  },
  saleRequestsWithId: {
    value: '/sale-requests/',
    withIndicator: '/sale-requests/:towerId/:id',
  },
  pairing: {
    value: '/pairing/',
    withIndicator: '/pairing/:towerId',
  },
  quotations: {
    value: '/quotations/',
    withIndicator: '/quotations/:towerId',
  },
  clientProperty: {
    value: '/client-property/',
    withIndicator: '/client-property/:towerId',
  },
  budget: {
    value: '/budget/',
    withIndicator: '/budget/:towerId',
  },
  saleDateSelector: {
    value: '/sale-date-selector/',
    withIndicator: '/sale-date-selector/:towerId',
  },
  propertyCharacteristic: {
    value: '/property-characteristic/',
    withIndicator: '/property-characteristic/:towerId',
  },
  salesReportOne: {
    value: '/sales-report-one/',
    withIndicator: '/sales-report-one/:towerId',
  },
  weeklySalesDetail: {
    value: '/weekly-sales-detail/',
    withIndicator: '/weekly-sales-detail/:towerId',
  },
  businessPartners: {
    value: '/business-partners',
  },
  paymentCashFlow: {
    value: '/payment-cash-flow/',
    withIndicator: '/payment-cash-flow/:towerId',
    macroeconomicMainView: {
      value: '/macroeconomic/',
      withIndicator: '/macroeconomic/:projectId',
    },
  },
  generalWallet: {
    value: '/wallet/',
    withIndicator: '/wallet/:towerId',
  },
  createRealPayment: {
    value: '/create-real-payment/',
    withIndicator: '/create-real-payment/:towerId',
  },
  arrearsSummaryReport: {
    value: '/arrears-summary-report/',
    withIndicator: '/arrears-summary-report/:towerId',
  },
  towerBuilder: {
    value: '/tower-builder/',
    withIndicator: '/tower-builder/:towerId',
  },
  salesStatus: {
    value: '/sales-status/',
    withIndicator: '/sales-status/:towerId',
  },
};

export const ContractRoutes = {
  base: DashboardRoutes.contracts,
};

export const ContractLotsRoutes = {
  base: DashboardRoutes.contractsLots,
};

export const ProjectRoutes = {
  base: '/projects',
};

export const AreasAndPrices = {
  array: [
    DashboardRoutes.base + DashboardRoutes.building.value,
    DashboardRoutes.base + DashboardRoutes.areas.value,
    DashboardRoutes.base + DashboardRoutes.areasAdditional.value,
    DashboardRoutes.base + DashboardRoutes.pairing.value,
    DashboardRoutes.base + DashboardRoutes.prime.value,
    DashboardRoutes.base + DashboardRoutes.clustering.value,
    DashboardRoutes.base + DashboardRoutes.propertyEdition.value,
    DashboardRoutes.base + DashboardRoutes.graphicMaterial.value,
  ],
};

export const InitialData = {
  array: [DashboardRoutes.base + DashboardRoutes.schedule.value],
};

export const Increments = {
  array: [
    DashboardRoutes.base + DashboardRoutes.salesSpeedHelper.value,
    DashboardRoutes.base + DashboardRoutes.strategy.value,
    DashboardRoutes.base + DashboardRoutes.futureSalesSpeed.value,
  ],
};

export const SalesRoom = {
  array: [
    DashboardRoutes.base + DashboardRoutes.clients.value,
    DashboardRoutes.base + DashboardRoutes.clientProperty.value,
    DashboardRoutes.base + DashboardRoutes.saleDateSelector.value,
    DashboardRoutes.base + DashboardRoutes.buyerCostumers.value,
    DashboardRoutes.base + DashboardRoutes.saleRequests.value,
  ],
};

export const SalesSetup = {
  array: [DashboardRoutes.base + DashboardRoutes.soldCollection.value],
};

export const Construction = {
  base: DashboardRoutes.towerBuilder,
};

export const SalesEvalpro = {
  base: DashboardRoutes.salesEvalpro,
};

export const Reports = {
  array: [
    DashboardRoutes.base + DashboardRoutes.summary.value,
    DashboardRoutes.base + DashboardRoutes.rackAreas.value,
    DashboardRoutes.base + DashboardRoutes.detailAdmin.value,
    DashboardRoutes.base + DashboardRoutes.detail.value,
    DashboardRoutes.base + DashboardRoutes.report.value,
  ],
};

export const CashFlows = {
  array: [
    DashboardRoutes.base + DashboardRoutes.cashFlow.value,
    DashboardRoutes.base + DashboardRoutes.paymentCashFlow.value,
    DashboardRoutes.base + DashboardRoutes.totalCashFlow.value,
    DashboardRoutes.base + DashboardRoutes.resultsState.value,
  ],
};

export const Contracts = {
  array: [DashboardRoutes.base + ContractRoutes.base.value],
};

export const ContractsLots = {
  array: [DashboardRoutes.base + ContractLotsRoutes.base.value],
};

export const Quotation = {
  array: [DashboardRoutes.base + DashboardRoutes.quotations.value],
};

export const Wallet = {
  array: [
    DashboardRoutes.base + DashboardRoutes.generalWallet.value,
    DashboardRoutes.base + DashboardRoutes.createRealPayment.value,
    DashboardRoutes.base + DashboardRoutes.arrearsSummaryReport.value,
  ],
};

export const Group = {
  base: '/groups',
};

export const ClientPropertyRoute = {
  base: '/client-property/',
};

export const UserRoutes = {
  base: DashboardRoutes.base + DashboardRoutes.user,
  slideProjectsOnly: DashboardRoutes.base + ProjectRoutes.base,
  login: '/login',
  forgotPassword: '/forgotPassword',
  changePassword: '/changePassword/:recoveryToken',
  profile: '/profile',
  create: '/create',
  assignProjects: '/assignProjects',
  assignCompanies: '/assignCompanies',
};

export const CRMRoutes = {
  base: '/crm',
};

export const GroupsRoutes = {
  base: DashboardRoutes.base + Group.base,
};

export const CompanySchedules = {
  base: DashboardRoutes.companySchedules,
};
