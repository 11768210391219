import { DragEventHandler, useEffect, useState } from 'react';

import { FeatureFieldDragAndDropHook } from '../interfaces/useFeatureFieldDragAndDrop.types';

const getDataIndex = (element: HTMLElement): number => +(element.dataset.index || -1);

const isDifferentIndex = (fromIndex: number, toIndex: number) => fromIndex !== toIndex;

const moveArrayItem = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const newArray = [...array];

  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, array[fromIndex]);

  return newArray;
};

function useFeatureFieldDragAndDrop<T>({
  itemsOrder,
  grabbingClassName,
  onSortedItems,
}: FeatureFieldDragAndDropHook<T>) {
  const [draggableItems, setDraggableItems] = useState<T[]>(itemsOrder);
  const [fromIndex, setFromIndex] = useState(-1);

  useEffect(() => {
    setDraggableItems(itemsOrder);
  }, [itemsOrder]);

  const handleDragStart: DragEventHandler<HTMLElement> = (event) => {
    event.dataTransfer.effectAllowed = 'move';
    event.currentTarget.classList.add(grabbingClassName);
    setFromIndex(getDataIndex(event.currentTarget));
  };

  const handleDragEnter: DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    const currentIndex = getDataIndex(event.currentTarget);

    if (isDifferentIndex(fromIndex, currentIndex)) {
      setDraggableItems(moveArrayItem<T>(draggableItems, fromIndex, currentIndex));
      setFromIndex(currentIndex);
    }
  };

  const handleDragOver: DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
  };

  const handleDrop: DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    onSortedItems(draggableItems);
  };

  const handleDragEnd: DragEventHandler<HTMLElement> = (event) => {
    event.currentTarget.classList.remove(grabbingClassName);
  };

  return { draggableItems, handleDragStart, handleDragEnter, handleDragOver, handleDrop, handleDragEnd };
}

export default useFeatureFieldDragAndDrop;
