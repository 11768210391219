import { API_PATH } from '../../config/config';

const ClientsServiceDefinitions = {
  searchClients: (towerId, query) =>
    `${API_PATH}clients/tower/${towerId}/suggestions/?query=${query}`,
  searchClientsWithProperty: (towerId, propertyId, query) =>
    `${API_PATH}clients/tower/${towerId}/${propertyId}/suggestions/?query=${query}`,

  postClient: (towerId) => `${API_PATH}clients/tower/${towerId}`,

  putClient: (identityDocument, towerId) =>
    `${API_PATH}clients/client/${identityDocument}/tower/${towerId}`,

  addClient: (towerId) => `${API_PATH}clients/tower/${towerId}`,

  getClients: (towerId) => `${API_PATH}clients/tower/${towerId}`,

  getClientWithId: (towerId, clientId) =>
    `${API_PATH}clients/tower/find/${towerId}/${clientId}`,

  getClient: (text, type, towerId) =>
    `${API_PATH}clients/tower/${towerId}/${text}?type=${type}`,

  getPropertyInfo: (propertyId) => `${API_PATH}v2/properties/${propertyId}`,

  getPropertyFilesAndInfo: (clientId, propertyId) =>
    `${API_PATH}v2/properties/${clientId}/${propertyId}`,

  addClientToTower: (clientId, towerId) =>
    `${API_PATH}clients/tower-clients/client/${clientId}/tower/${towerId}`,

  updateAddressForClient: (towerId) =>
    `${API_PATH}clients/update-address/client/${towerId}`,

  updateAddressForAdditionalInfo: (towerId) =>
    `${API_PATH}clients/update-address/additional-info/${towerId}`,

  deleteClient: (clientId, towerId) =>
    `${API_PATH}clients/${clientId}/towers/${towerId}`,

  deletePropertyFile: (propertyFileId) =>
    `${API_PATH}clientProperty/deletePropertyFile/${propertyFileId}`,

  postPropertyFile: (clientId, propertyId) =>
    `${API_PATH}clientProperty/template/${clientId}/${propertyId}`,

  downloadPropertyFile: (propertyFileId) =>
    `${API_PATH}clientProperty/download-property-file/${propertyFileId}`,
};

export default ClientsServiceDefinitions;
