import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';
import {
  changeStrategy,
  fetchDataSuccess,
} from '../../../../../../containers/StrategyV2/actions';
import { startLoading, stopLoading } from '../../../../Loader/actions';
import StrategyServices from '../../../../../../services/strategy/StrategyService';
import IncrementServices from '../../../../../../services/increments/IncrementsServices';
import Increment2Services from '../../../../../../services/incrementsV2/incrementsService';
import ConfirmDialog from './ConfirmDialog';
import generateDataset, {
  HISTORY_GRAPH_ID,
  MARKET_GRAPH_ID,
} from '../../../../../../containers/StrategyV2/helpers/dataset';
import { DashboardRoutes } from '../../../../../../routes/local/routes';
import Styles from './Strategies.module.scss';
import validateSelectedGroup from '../../../../Shared/Validator';
import useUpdateStrategyGroups from '../../../../../../containers/StrategyV2/customHooks/useUpdateStrategyGroups';
import numbers from '../../../../../../helpers/numbers';
import debouncedInput from '../../../../../UI/TextFields/debouncedInput';
import { Numbers } from '../../../../../../helpers';

const DebouncedNumberFormat = debouncedInput(NumberFormat, { timeout: 400 });
const PAST_GRAPH_ID = 10000000;

const services = {
  strategy: new StrategyServices(),
  increment: new IncrementServices(),
  increment2: new Increment2Services(),
};

const Strategies = ({
  units,
  locked,
  strategy,
  onChangeStrategy,
  isReset,
  strategies,
  groupId,
  onFetchedData,
  startApiLoading,
  stopApiLoading,
  rows,
  baseValueToToday,
  customFrequency,
  customStrategy,
  projectedIncrement,
}) => {
  const history = useHistory();
  const { towerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [
    selectStrategyConfirmationDialogOpen,
    setSelectStrategyConfirmationDialogOpen,
  ] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [validation, setValidation] = useState(false);
  const [message, setMessage] = useState('');
  const [
    resetStrategyConfirmationDialogOpen,
    setResetStrategyConfirmationDialogOpen,
  ] = useState(false);
  const { activateBadgeIncrementWithGroups } = useUpdateStrategyGroups();

  const changeStrategyHandler = async (id) => {
    try {
      startApiLoading();
      const lineSelected = strategies.find((s) => s.id === Number(id));
      const arrayIncrementList = strategies.slice(1).map((s) => s.percentage);
      await services.increment2.putStrategy({
        id: groupId,
        strategy: Number(id),
        incrementList: lineSelected.percentage,
        arrayIncrementList,
        baseValueToToday,
      });
      const response = await services.increment2.getIncrementsAndStrategy(
        towerId,
      );
      onFetchedData({
        strategyLines: generateDataset(response.data.increments),
        groups: response.data.summary.increments,
      });
      activateBadgeIncrementWithGroups(response.data.summary.increments);
      onChangeStrategy(Number(id));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    stopApiLoading();
  };

  const putCustomFrequency = async (e) => {
    try {
      startApiLoading();
      const value = Numbers.cleanNumber(e.target.value);
      let validationTemp = false;
      let messageTemp = '';

      if (value >= units) {
        validationTemp = true;
        messageTemp = `El valor ingresado debe ser mayor que 0 y menor que ${units} `;
      } else if (value <= 0) {
        messageTemp = `El valor ingresado debe ser mayor que 0 y menor que ${units} `;
        validationTemp = true;
      } else if (value === 1 || value === 3 || value === 9 || value === 18) {
        messageTemp = `El valor ingresado no puede ser una de las estrategias predeterminadas`;
        validationTemp = true;
      }
      setValidation(validationTemp);
      setMessage(messageTemp);

      if (value < units && value > 0 && !validationTemp) {
        await services.increment2.putCustomFrequency({
          groupId,
          customFrequency: value,
        });

        const response = await services.increment2.getIncrementsAndStrategy(
          towerId,
        );
        onFetchedData({
          strategyLines: generateDataset(response.data.increments),
          groups: response.data.summary.increments,
        });
        activateBadgeIncrementWithGroups(response.data.summary.increments);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    stopApiLoading();
  };

  const resetStrategyHandler = async () => {
    try {
      startApiLoading();
      await services.increment2.resetStrategy(groupId);
      const response = await services.increment2.getIncrementsAndStrategy(
        towerId,
      );
      onFetchedData({
        strategyLines: generateDataset(response.data.increments),
        groups: response.data.summary.increments,
      });
      activateBadgeIncrementWithGroups(response.data.summary.increments);
      onChangeStrategy(null);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    stopApiLoading();
  };

  const confirmStrategySelection = () => {
    changeStrategyHandler(selectedStrategy);
  };

  const redirectToSalesRequest = () => {
    history.push(
      `${DashboardRoutes.base}${DashboardRoutes.saleRequests.value}${towerId}`,
    );
  };

  if (groupId === 0) {
    return null;
  }

  return (
    <>
      {units > 1 || projectedIncrement > 1 ? (
        <>
          <Paper>
            <Box p={3}>
              <Typography variant="h5">Estrategias</Typography>
            </Box>
            {locked && isReset && (
              <Box p={3}>
                <Typography variant="overline" component="span">
                  * No se puede seleccionar la estrategia sin resolver los casos
                  de desistimiento pendientes
                </Typography>{' '}
                <Link
                  component="button"
                  onClick={redirectToSalesRequest}
                  variant="overline"
                >
                  Ir a solicitudes
                </Link>
              </Box>
            )}
            <RadioGroup
              row
              aria-label="estrategia"
              value={isReset ? null : strategy}
              onChange={(event) => {
                if (isReset || !strategy) {
                  setSelectedStrategy(event.target.value);
                  setSelectStrategyConfirmationDialogOpen(true);
                }
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      <TableCell>Estrategia</TableCell>
                      <TableCell>Tasa e.a</TableCell>
                      <TableCell>Frecuencia Inc</TableCell>
                      <TableCell>Tasa Fr</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      if (
                        (customFrequency !== 0 &&
                          customStrategy &&
                          customStrategy.id === row.frequencyId) ||
                        row.frequencyId === HISTORY_GRAPH_ID ||
                        row.frequencyId === MARKET_GRAPH_ID
                      ) {
                        return null;
                      }
                      return (
                        <TableRow key={`strategy-${index}`}>
                          <TableCell classes={{ root: Styles.radioButtonCell }}>
                            <FormControlLabel
                              disabled={(strategy && !isReset) || locked}
                              value={row.frequencyId}
                              control={<Radio />}
                            />
                          </TableCell>
                          <TableCell>{row.strategy}</TableCell>
                          <TableCell>{row.AER}</TableCell>
                          <TableCell>{row.frequency}</TableCell>
                          <TableCell>{row.frequencyRate}</TableCell>
                        </TableRow>
                      );
                    })}
                    {(customStrategy || (!strategy && isReset)) && (
                      <TableRow key={`strategy-other`}>
                        <TableCell classes={{ root: Styles.radioButtonCell }}>
                          <FormControlLabel
                            disabled={
                              (strategy && !isReset) ||
                              locked ||
                              customFrequency === 0
                            }
                            value={customFrequency}
                            control={<Radio />}
                          />
                        </TableCell>
                        <TableCell>
                          <DebouncedNumberFormat
                            customInput={TextField}
                            label="Personalizado"
                            defaultValue={customFrequency}
                            disabled={(strategy && !isReset) || locked}
                            size="small"
                            onChange={async (e) => {
                              putCustomFrequency(e);
                            }}
                            error={validation}
                            helperText={validation ? message : null}
                            thousandSeparator
                            isNumericString
                            decimalSeparator={false}
                          />
                        </TableCell>
                        <TableCell>
                          {customStrategy
                            ? numbers.toFixed(
                                customStrategy.EARate * 100,
                                2,
                                true,
                              )
                            : 0}
                        </TableCell>
                        <TableCell>
                          {customStrategy
                            ? `${customFrequency} (${
                                customStrategy.soldInCurrentStrategy || 0
                              })`
                            : 0}
                        </TableCell>
                        <TableCell>
                          {customStrategy
                            ? numbers.toFixed(
                                customStrategy.percentage * 100,
                                2,
                                true,
                              )
                            : 0}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </RadioGroup>
            <Box p={3}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setResetStrategyConfirmationDialogOpen(true);
                }}
                fullWidth
                disabled={isReset}
              >
                Reiniciar estrategia
              </Button>
            </Box>
          </Paper>
          <ConfirmDialog
            open={selectStrategyConfirmationDialogOpen}
            title="Seleccionar estrategia"
            content="¿Esta seguro de que desea selecionar esa estrategia?"
            handleAccept={() => {
              confirmStrategySelection();
              setSelectStrategyConfirmationDialogOpen(false);
            }}
            handleClose={() => {
              setSelectStrategyConfirmationDialogOpen(false);
            }}
          />
          <ConfirmDialog
            open={resetStrategyConfirmationDialogOpen}
            title="Reinciar estrategia"
            content="¿Esta seguro de que desea reiniciar la estrategia?"
            handleAccept={() => {
              resetStrategyHandler();
              setResetStrategyConfirmationDialogOpen(false);
            }}
            handleClose={() => {
              setResetStrategyConfirmationDialogOpen(false);
            }}
          />
        </>
      ) : null}
    </>
  );
};

Strategies.propTypes = {
  locked: PropTypes.bool.isRequired,
  strategies: PropTypes.array.isRequired,
  customStrategy: PropTypes.array.isRequired,
  isReset: PropTypes.bool.isRequired,
  strategy: PropTypes.number.isRequired,
  onChangeStrategy: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  units: PropTypes.number.isRequired,
  projectedIncrement: PropTypes.number,
  onFetchedData: PropTypes.func.isRequired,
  startApiLoading: PropTypes.func.isRequired,
  stopApiLoading: PropTypes.func.isRequired,
  rows: PropTypes.array,
  baseValueToToday: PropTypes.number.isRequired,
  customFrequency: PropTypes.number.isRequired,
};

const mapStrategyForSelector = (strategy) => {
  if (!strategy.id) return [];
  return {
    strategy: strategy.label[0],
    AER: `${Number(strategy.EARate * 100).toFixed(2)}%`,
    frequency: `${strategy.id} (${strategy.soldInCurrentStrategy})`,
    frequencyId: strategy.id,
    frequencyRate: `${Number(strategy.percentage * 100).toFixed(2)}%`,
  };
};

const mapStateToProps = (state) => {
  let group =
    state.strategy.root.strategyLines[state.strategy.root.selectedGroup];
  if (validateSelectedGroup(state.strategy.root)) {
    group = state.strategy.root.strategyLines
      ? state.strategy.root.strategyLines[0]
      : {};
    if (!group) return {};
  }
  const { strategy, isReset, inventory, locked } =
    state.strategy.root.groups[state.strategy.root.selectedGroup];
  const strategies = group ? group.strategies : [];
  let customStrategy = [];
  if (group && group?.customFrequency !== 0) {
    const customIndex = group.strategies.findIndex(
      (strategy) =>
        strategy.id !== 1 &&
        strategy.id !== PAST_GRAPH_ID &&
        strategy.id !== 3 &&
        strategy.id !== 9 &&
        strategy.id !== 18 &&
        strategy.id !== null &&
        strategy.id !== MARKET_GRAPH_ID &&
        strategy.id !== HISTORY_GRAPH_ID &&
        typeof strategy.id === 'number',
    );
    customStrategy = group.strategies[customIndex];
  }
  const rows = strategies.flatMap(mapStrategyForSelector);

  return {
    units: inventory.units,
    strategy,
    locked,
    isReset,
    strategies,
    groupId: group ? group.id : null,
    rows,
    baseValueToToday: inventory.baseValueToToday,
    projectedIncrement: inventory.projectedIncrement,
    customFrequency: group?.customFrequency,
    customStrategy,
  };
};

const mapDispatchToProps = {
  onChangeStrategy: changeStrategy,
  onFetchedData: fetchDataSuccess,
  startApiLoading: startLoading,
  stopApiLoading: stopLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Strategies);
