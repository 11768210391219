import React, { FC } from 'react';
import { FormControl, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { IUseFormResult } from '../../../../../shared/customHooks/useForm';
import Typography from '../../../../../shared/components/Typography/Typography';
import TextField from '../../../../../shared/components/TextField/TextField';
import { ContractPayment, MeasurementUnit } from '../../../../../models/ContractPayments';
import useMeasurementUnit from '../../../Core/customHooks/useMeasurementUnit';

interface IMeasurementField extends Partial<IUseFormResult<ContractPayment>> {
  handleChangeValue: <G>(key: keyof ContractPayment, value: G) => void;
  measurementUnits: MeasurementUnit[] | undefined;
  currentContractPayment: ContractPayment | undefined;
}

const MeasurementField: FC<IMeasurementField> = ({
  handleChangeValue,
  measurementUnits,
  currentContractPayment,
  errors,
}) => {
  const {
    inputMeasurementUnitValue,
    setInputMeasurementUnitValue,
    autocompleteMeasurementUnitValue,
    setAutocompleteMeasurementUnitValue,
  } = useMeasurementUnit({
    measurementUnits,
    currentContractPayment,
  });

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="measurementUnit"
        options={measurementUnits as MeasurementUnit[]}
        blurOnSelect
        getOptionLabel={(option: MeasurementUnit) => `${option.id} - ${option.name}`}
        value={autocompleteMeasurementUnitValue}
        onChange={(event, newValue) => {
          const val = !!newValue ? `${newValue?.id}` : '';
          handleChangeValue('measurementUnitId', val);
          setAutocompleteMeasurementUnitValue(newValue);
        }}
        inputValue={inputMeasurementUnitValue}
        onInputChange={(_, newInputValue) => {
          setInputMeasurementUnitValue(newInputValue);
        }}
        renderOption={(option: MeasurementUnit) => (
          <React.Fragment>
            <ListItem component={'div'}>
              <ListItemAvatar>
                <Typography color="gray500" fontWeight="600">
                  {option.id}
                </Typography>
              </ListItemAvatar>
              <ListItemText primary={option.name} />
            </ListItem>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Unidad"
            variant="outlined"
            fullWidth
            size="small"
            name="measurementUnitId"
            error={!!errors?.measurementUnitId}
          />
        )}
      />
    </FormControl>
  );
};

export default MeasurementField;
