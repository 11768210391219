import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Services from '../Services/BuildingServices';
import { setHeaderEditable } from './actions';

const services = new Services();

export default function useBuilding() {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const {
    isHeaderEditable,
    headers,
    labels,
    verticalHeaders,
    verticalLabels,
  } = useSelector((state) => state.buildingHeader);
  const dispatch = useDispatch();

  const onSetHeaderEditable = () => {
    dispatch(setHeaderEditable());
  };

  const sendHeaders = async () => {
    try {
      await services.setHeaders(towerId, { headers, verticalHeaders });
      onSetHeaderEditable();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return {
    isHeaderEditable,
    headers,
    labels,
    onSetHeaderEditable,
    sendHeaders,
    verticalHeaders,
    verticalLabels,
  };
}
