import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Numbers } from '../../../../../helpers';

const CustomTypography = styled(Typography)`
  width: 250px;
`;

const ArrearsReportTotalCell = ({ title, value, isPercentage }) => {
  return (
    <Box display="flex" p={0.2}>
      <CustomTypography>{title}</CustomTypography>
      <CustomTypography>
        {isPercentage
          ? Numbers.toFixed(value, 1, true)
          : Numbers.currencyFormat(value)}
      </CustomTypography>
    </Box>
  );
};

ArrearsReportTotalCell.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  isPercentage: PropTypes.bool,
};

export default ArrearsReportTotalCell;
