import { Numbers } from '../../../../helpers';

const CONTRACT_ID = '-200';
const CONSTRUCTION_ID = '-100';
const isAContract = (row) => {
  return row && row.group && row.item;
};

const isAnItem = (row) => {
  return row && row.group && !row.item;
};

const isAGroup = (row) => {
  return row && !row.group && !row.item;
};

const isParent = (row) => {
  return row && row.id && !row.group && !row.item && !row.parentId;
};

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((r) => {
    if (isAGroup(row)) {
      if (isAnItem(r)) return r.group === row.id;
    }
    if (isAnItem(row)) {
      return isAContract(r) && r.item === row.id;
    }

    if (isParent(row)) {
      return r.parentId === row.id;
    }

    return r.group === row && r.item === row && r.parentId === row;
  });
  return childRows.length ? childRows : null;
};

const makeObjectWithDates = (object, group = null, item = null) => {
  const rowForDates = object.dates.reduce(
    (currentRow, dateAndPrice) => {
      return {
        ...currentRow,
        [dateAndPrice.date]: Numbers.currencyFormat(Number(dateAndPrice.price)),
      };
    },
    {
      id: `contract${object.id}`,
      name: object.name,
      total: object.total * -1,
      percentage: Number(object.total),
      group: group ? `contract${group}` : null,
      item: item ? `contract${item}` : null,
      parentId:
        !group &&
        !item &&
        object.id !== CONTRACT_ID &&
        object.id !== CONSTRUCTION_ID
          ? `contract${CONTRACT_ID}`
          : null,
    },
  );
  return rowForDates;
};

export const generateRows = (groups) => {
  const rows = groups.reduce((arrayGroups, group) => {
    const groupRow = makeObjectWithDates(group);
    const itemRows = group.items.reduce((arrayItems, item) => {
      const itemRow = makeObjectWithDates(item, group.id);
      const contractRows = item.contracts.reduce((arrayContracts, contract) => {
        const contractRow = makeObjectWithDates(contract, group.id, item.id);
        return [...arrayContracts, contractRow];
      }, []);
      return [...arrayItems, itemRow, ...contractRows];
    }, []);
    return [...arrayGroups, groupRow, ...itemRows];
  }, []);
  return rows;
};

function useRowsForContracts(contractRows) {
  const contracts = generateRows(contractRows);

  return { contracts };
}

export default useRowsForContracts;
