import React, { FC } from 'react';
import { IconButton, InputBase } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import styles from './PlusInput.module.scss';

interface Props {
  value: number;
  numberFormatProps?: NumberFormatProps;
  isDisabled?: boolean;
  fullWidth?: boolean;
  size?: 'small';
  onChange: (value: number) => void;
}

const PlusInput: FC<Props> = ({ value, fullWidth, numberFormatProps, isDisabled, size, onChange }) => {
  return (
    <div
      className={`${fullWidth === true ? styles.PlusInputFullWidth : styles.PlusInput}  ${
        size === 'small' ? styles.Small : ''
      }`}
    >
      {isDisabled && <div className={styles.Loading}></div>}
      <IconButton className={size === 'small' ? styles.IconSmall : styles.Icon} onClick={() => onChange(+value - 1)}>
        <Remove />
      </IconButton>
      <NumberFormat
        defaultValue={value}
        className={`${styles.Input} ${size === 'small' ? styles.SmallInput : ''}`}
        value={value}
        onBlur={(e) => onChange(+e.target.value)}
        {...numberFormatProps}
        customInput={InputBase}
        // @ts-ignore
        fullWidth
        variant="outlined"
      />
      <IconButton
        className={size === 'small' ? styles.IconSmall : styles.Icon}
        size={size === 'small' ? 'small' : 'medium'}
        onClick={() => {
          onChange(+value + 1);
        }}
      >
        <Add />
      </IconButton>
    </div>
  );
};

export default PlusInput;
