export const CHANGE_STATE_INTO_HEADER_EDITABLE =
  'CHANGE_STATE_INTO_HEADER_EDITABLE';
export const ON_SET_INITIAL_HEADERS = 'ON_SET_INITIAL_HEADERS';
export const ON_SET_NEW_HEADERS = 'ON_SET_NEW_HEADERS';
export const ON_SET_NEW_VERTICAL_HEADERS = 'ON_SET_NEW_VERTICAL_HEADERS';
export const ON_SET_INITIAL_VERTICAL_HEADERS =
  'ON_SET_INITIAL_VERTICAL_HEADERS';

export const setHeaderEditable = () => ({
  type: CHANGE_STATE_INTO_HEADER_EDITABLE,
});

export const setInitialHeaders = (heads) => ({
  payload: heads,
  type: ON_SET_INITIAL_HEADERS,
});

export const setInitialVerticalHeaders = (heads) => ({
  payload: heads,
  type: ON_SET_INITIAL_VERTICAL_HEADERS,
});

export const setEditedHeaders = (heads) => ({
  payload: heads,
  type: ON_SET_NEW_HEADERS,
});

export const setEditedVerticalHeaders = (heads) => ({
  payload: heads,
  type: ON_SET_NEW_VERTICAL_HEADERS,
});
