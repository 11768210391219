import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SwitchCard from './Views/SwitchCard';
import useProjectionsType from '../Core/customHooks/useProjectionsType';
import styles from './ProjectionStyles.module.scss';
import { ProjectionTypeEnum } from '../Core/projectionType.enum';

const ProjectionTypesMainView = ({ projections }) => {
  const { loading } = useProjectionsType();

  return (
    <Paper elevation={0} variant="outlined" className={styles.paperContainer}>
      <Typography className={styles.title} gutterBottom>
        Configuración de proyecciones
      </Typography>
      <Typography className={styles.subtitle} gutterBottom>
        Elige la fuente de los datos para tus estados financieros.
      </Typography>
      {loading ? (
        <div className={styles.circularProgress}>
          <CircularProgress />
        </div>
      ) : (
        <Box className={styles.switchContainer}>
          <SwitchCard
            title="Ventas"
            name="sales"
            switchState={projections.sales}
          />
          <SwitchCard
            title="Costos construcción"
            name="construction"
            switchState={projections.construction}
          />
          <SwitchCard
            title="Costos indirectos"
            name="contracts"
            switchState={projections.contracts}
          />
          <SwitchCard
            title="Gastos financieros"
            name="financialExpenses"
            switchState={ProjectionTypeEnum.EXECUTION}
          />
          {/* <SwitchCard
            title="Contratos"
            name="contracts"
            switchState={projections.contracts}
          /> */}
        </Box>
      )}
    </Paper>
  );
};

ProjectionTypesMainView.propTypes = {
  projections: PropTypes.shape({
    sales: PropTypes.bool,
    construction: PropTypes.bool,
    contracts: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { sales, construction, contracts } = state.projectionTypes;
  return { projections: { sales, construction, contracts } };
};

export default connect(mapStateToProps, null)(ProjectionTypesMainView);
