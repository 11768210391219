import Services from '../services';
import SharedServiceDefinitions from './SharedServiceDefinitions';

class SharedServices extends Services {
  postResetCache(towerId) {
    return this.post(SharedServiceDefinitions.resetCache(towerId));
  }
}

export default SharedServices;
