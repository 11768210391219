import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import PropertyCharacteristicServices from '../../services/PropertyCharacteristicServices';
import { updatePropertiesWithCharacteristics } from '../../../GraphicMaterial/Core/actions';

const services = new PropertyCharacteristicServices();

export default function useCharacteristic(property) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();
  const { properties } = useSelector((state) => state.salesRoom.root);

  const [characteristics, setCharacteristics] = useState(
    property.propertyCharacteristic,
  );

  const updatePropertiesCharacteristics = (propertyCharacteristic) => {
    const propertiesRows = properties.flatMap((prevProp) => prevProp);
    const index = propertiesRows.findIndex((prop) => prop.id === property.id);
    const props = [...propertiesRows];
    props[index] = {
      ...props[index],
      propertyCharacteristic,
    };
    setCharacteristics(propertyCharacteristic);
    dispatch(updatePropertiesWithCharacteristics(props));
  };

  const updateRowValue = async (name, value) => {
    try {
      const currentCharacteristics = {
        ...characteristics,
        [name]: value,
        propertyId: property.id,
      };
      await services.setCharacteristic(towerId, currentCharacteristics);
      updatePropertiesCharacteristics(currentCharacteristics);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return { characteristics, updateRowValue };
}
