import { useSnackbar } from 'notistack';

import FieldCellCreator from 'App/FeatureField/core/FieldCellCreator';
import { FieldCell } from 'App/FeatureField/core/interfaces/featureField.types';
import { TagCellChange } from 'App/FeatureField/core/interfaces/TableTagProvider';
import { GeneralWalletRow } from '../interfaces/generalWallet.types';
import { GeneralWalletRows, GeneralWalletTagChangeHook } from '../interfaces/useGeneralWalletTagChange.types';

const fieldCellCreator = new FieldCellCreator();

const isChangedRow = (changedRow: GeneralWalletRow, row: GeneralWalletRow) => changedRow.propertyId === row.propertyId;

const insertChangedCell = (row: GeneralWalletRow, cell: FieldCell): GeneralWalletRow => ({
  ...row,
  [cell.featureFieldId]: cell,
});

const getChangedRow =
  ({ cell, row: changedRow }: TagCellChange) =>
  (row: GeneralWalletRow): GeneralWalletRow =>
    isChangedRow(changedRow, row) ? insertChangedCell(row, cell) : row;

const getChangedRows = (change: TagCellChange, rows: GeneralWalletRows): GeneralWalletRows =>
  rows.map(getChangedRow(change));

function useGeneralWalletTagChange({ rows, setRows }: GeneralWalletTagChangeHook) {
  const { enqueueSnackbar } = useSnackbar();

  const updateCellChange = async (changedCell: FieldCell) => {
    try {
      await fieldCellCreator.upsert(changedCell);
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
      setRows(rows);
    }
  };

  const commitTagChange = (change: TagCellChange) => {
    setRows(getChangedRows(change, rows));
    updateCellChange(change.cell);
  };

  return commitTagChange;
}

export default useGeneralWalletTagChange;
