import PropTypes from 'prop-types';
import useCellsForCustomCell from '../../../Core/customHooks/useCellsForCustomCell';

const ResultsStateCustomCell = (props) => {
  const { tableRow, tableColumn } = props;

  const { row } = tableRow;
  const cellData = row[tableColumn.column.name];
  const { name } = tableColumn.column;

  return useCellsForCustomCell(cellData, tableColumn, row, name);
};

ResultsStateCustomCell.propTypes = {
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
};

export default ResultsStateCustomCell;
