import { API_PATH } from '../../../config/config';

const CompanySchedulesServicesDefinition = {
  CompanySchedules: (companyId, initialDateForColumn) => {
    return `${API_PATH}schedules/company-schedules/${companyId}/${initialDateForColumn}`
  },
  getProjects: () => {
    return `${API_PATH}project/`
  }
}

export default CompanySchedulesServicesDefinition;