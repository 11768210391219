import clsx from 'clsx';
import React from 'react';

import { ModalTitleProps } from '../../interfaces/ModalTitle.types';
import IconButton from '../IconButton/IconButton';
import Typography from '../Typography/Typography';

import styles from './ModalTitle.module.scss';

const ModalTitle: React.FC<ModalTitleProps> = ({ className, children, disabled, onClickClose, ...restProps }) => (
  <div className={clsx(styles.root, className)} {...restProps}>
    <Typography as="h2" variant="headline6">
      {children}
    </Typography>

    {onClickClose && (
      <IconButton
        className={styles.closeButton}
        iconName="Clear"
        variant="ghost"
        size="xsmall"
        disabled={disabled}
        onClick={onClickClose}
      />
    )}
  </div>
);

export default ModalTitle;
