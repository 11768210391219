import React from 'react'
import CashFlowRowChangeValue, {
} from '../models/CashFlowRowChangeValue';
import { Numbers } from '../../../../helpers';

const commitChanges = ({ added, changed, deleted, salesRows, onRowChange }) => {
  if (changed) {
    const keys = Object.keys(changed).filter((key) => changed[key]);

    if (keys.length) {
      const key = keys[0];
      const columnObject = changed[key];
      const columnObjectKey = Object.keys(columnObject)[0];
      const columnObjectValue = Numbers.parseLocaleNumber(
        columnObject[columnObjectKey],
      );

      if (columnObjectValue === '$NaN') return;
      const row = salesRows[key];
      onRowChange(
        row.id,
        columnObjectKey,
        new CashFlowRowChangeValue(columnObjectValue),
      );
    }
  }
};

export default  commitChanges 