import React, { FC } from 'react';
import useDeedExpensesSalesMade from 'App/DeedExpenses/core/customHooks/useDeedExpensesSalesMade';
import DeedExpensesInformation from '../../components/DeedExpensesInformation/DeedExpensesInformation';
import DeedExpensesSalesMadeTable from '../../tables/DeedExpensesSalesMadeTable/DeedExpensesSalesMadeTable';
import DeedExpensesPropertiesSoldConfig from '../DeedExpensesPropertiesSoldConfig/DeedExpensesPropertiesSoldConfig';
import useDeedExpensesSalesMadeData from 'App/DeedExpenses/core/customHooks/useDeedExpensesSalesMadeData';
import { useParams } from 'react-router-dom';
import useScheduleServicesGetDates from 'App/DeedExpenses/core/customHooks/useScheduleServicesGetDates';

const DeedExpensesSalesMade: FC = () => {
  const { towerId } = useParams<{ towerId: string }>();
  const { scheduleDatesResponse } = useScheduleServicesGetDates(towerId);
  const { data } = useDeedExpensesSalesMadeData(towerId);
  const { displacement, isOpenConfiguration, openConfiguration, onCloseConfig } = useDeedExpensesSalesMade({
    quotationSetup: scheduleDatesResponse?.quotationSetup,
  });
  return (
    <>
      {data && (
        <>
          <DeedExpensesInformation
            endOfSalesDate={`${data.endOfSalesDate ?? 0}`}
            deedsUnitsSold={data.deedsUnitsSold}
          />
          <DeedExpensesSalesMadeTable
            displacement={displacement}
            propertiesSold={data.properties}
            openConfiguration={openConfiguration}
          />
          <DeedExpensesPropertiesSoldConfig
            isOpen={isOpenConfiguration}
            onClose={onCloseConfig}
            quotationSetup={scheduleDatesResponse?.quotationSetup}
            averageDeliveryDate={scheduleDatesResponse?.averageDeliveryDate}
          />
        </>
      )}
    </>
  );
};

export default DeedExpensesSalesMade;
