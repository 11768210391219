import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

const QuotationSetupTypography = styled(Typography)`
  width: 250px;
`;

const QuotationSetupTitle = styled(Typography)`
  width: 130px;
  padding-left: 30px;
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
`;

const WalletSetupFeesTitles = () => {
  return (
    <Box display="flex">
      <QuotationSetupTypography variant="subtitle1"> </QuotationSetupTypography>
      <QuotationSetupTitle>Tasa anual</QuotationSetupTitle>
      <QuotationSetupTitle>Tasa mensual</QuotationSetupTitle>
      <QuotationSetupTitle>Tasa diaria</QuotationSetupTitle>
    </Box>
  );
};

export default WalletSetupFeesTitles;
