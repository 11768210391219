import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Services from '../../Services/BuyerCustomerServices';

const services = new Services();

const useDownloadDocuments = (propertyId, clientId) => {
  const { towerId } = useParams();
  const [openDocumentsMenu, setOpenDocumentsMenu] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const toggleDocumentsMenu = () => setOpenDocumentsMenu(!openDocumentsMenu);

  const getAvailableDocuments = async () => {
    try {
      const { data } = await services.getAvailableDocuments(towerId);
      setDocuments(data || []);
    } catch (err) {
      console.error('Error al consultar documentos disponibles', err.message);
      enqueueSnackbar('Error al consultar documentos disponibles', {
        variant: 'error',
      });
    }
  };

  const downloadDocument = async (documentId, name) => {
    setOpenDocumentsMenu(false);
    try {
      const response = await services.downloadDocument(
        documentId,
        propertyId,
        clientId,
      );
      const documentName = `${name}.docx`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      link.click();
    } catch (err) {
      const decodedString = String.fromCharCode.apply(
        null,
        new Uint8Array(err),
      );
      const obj = JSON.parse(decodedString);
      const { message } = obj;
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getAvailableDocuments();
  }, []);

  return {
    openDocumentsMenu,
    toggleDocumentsMenu,
    documents,
    downloadDocument,
  };
};

export default useDownloadDocuments;
