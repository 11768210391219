import React from 'react';
import Dialog from './Dialog/Dialog';

const AssignedPaymentEditionMainView = ({ id, open, closeHandler }) => {
  return (
    <Dialog
      id={id}
      open={open}
      closeHandler={closeHandler}
      isEditingReal={true}
    />
  );
};

export default AssignedPaymentEditionMainView;
