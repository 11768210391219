import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { Button } from 'shared/components/Button/Button';
import Modal from 'shared/components/Modal/Modal';
import ModalActions from 'shared/components/ModalActions/ModalActions';
import ModalTitle from 'shared/components/ModalTitle/ModalTitle';
import Tab from 'shared/components/Tab/Tab';
import Tabs from 'shared/components/Tabs/Tabs';
import useCloseFeatureFieldEditor from '../../../core/customHooks/useCloseFeatureFieldEditor';
import useSaveFeatureField from '../../../core/customHooks/useSaveFeatureField';
import useFeatureFieldEditorTabs from '../../../core/customHooks/useFeatureFieldEditorTabs';
import { FeatureFieldEditorProps } from '../../../core/interfaces/FeatureFieldEditor.types';
import FeatureFieldEditorTabPanel from './FeatureFieldEditorTabPanel';
import FeatureFieldSingleSelectEditor from './FeatureFieldSingleSelectEditor';

import styles from './FeatureFieldEditor.module.scss';

const getTitle = (isEditing: boolean) => (isEditing ? 'Editar Columna' : 'Agregar Columna');

const getSaveButtonText = (isEditing: boolean) => (isEditing ? 'Guardar' : 'Crear');

const LoadingProgress = (
  <div className={styles.loadingProgress}>
    <CircularProgress className={styles.circularProgress} size="30px" />
  </div>
);

const a11yProps = (index: number) => ({
  id: `feature-field-editor-tab-${index}`,
  'aria-controls': `feature-field-editor-tabpanel-${index}`,
});

const FeatureFieldEditor: React.FC<FeatureFieldEditorProps> = ({
  isOpen,
  isEditing,
  feature,
  editableField,
  onClose,
  onChangeField,
  onFieldSaved,
}) => {
  const { value, handleTabChange } = useFeatureFieldEditorTabs();
  const { isSavingField, handleSubmit } = useSaveFeatureField({
    isOpen,
    feature,
    editableField,
    onFieldSaved,
  });
  const handleCloseModal = useCloseFeatureFieldEditor({ isSavingField, onClose });

  return (
    <Modal
      className={styles.modal}
      open={isOpen}
      aria-labelledby="feature-field-editor-title"
      onClose={handleCloseModal}
    >
      <form onSubmit={handleSubmit} noValidate>
        <ModalTitle
          id="feature-field-editor-title"
          className={styles.title}
          disabled={isSavingField}
          onClickClose={onClose}
        >
          {getTitle(isEditing)}
        </ModalTitle>
        <Tabs
          className={styles.tabs}
          value={value}
          disabled={isSavingField}
          onChange={handleTabChange}
          aria-label="Feature Field Editor Tabs"
        >
          <Tab label="Crear Nuevo" {...a11yProps(0)} />
        </Tabs>
        <FeatureFieldEditorTabPanel className={styles.tabPanel} value={value} index={0}>
          <FeatureFieldSingleSelectEditor
            editableField={editableField}
            disabled={isSavingField}
            onChange={onChangeField}
          />
        </FeatureFieldEditorTabPanel>
        <ModalActions>
          <Button variant="outlined" size="small" disabled={isSavingField} onClick={onClose}>
            Cancelar
          </Button>
          <Button className={styles.saveButton} variant="contained" size="small" type="submit" disabled={isSavingField}>
            <>
              {getSaveButtonText(isEditing)}
              {isSavingField && LoadingProgress}
            </>
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

export default FeatureFieldEditor;
