import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Table } from '@devexpress/dx-react-grid';
import React from 'react';

import { FeatureFields } from '../../../core/interfaces/featureField.types';
import { TableTagProps } from '../../../core/interfaces/TableTag';
import { OnValueChange, TableTagProviderProps } from '../../../core/interfaces/TableTagProvider';
import TableCellTag from './TableCellTag';
import TableTag from './TableTag';

const TABLE_DATA_TYPE = 'data';

const isTableDataType = (type: Symbol) => type.description === TABLE_DATA_TYPE;

const isDataTableCell =
  (columnNames: string[]) =>
  ({ tableRow, tableColumn }: any) =>
    isTableDataType(tableRow.type) &&
    isTableDataType(tableColumn.type) &&
    columnNames.includes(tableColumn.column?.name);

const TableTagPlaceholderContent = (params: Table.CellProps, value) => (content) =>
  (
    <TableCellTag {...params} row={params.tableRow.row} column={params.tableColumn.column!} value={value}>
      {content}
    </TableCellTag>
  );

const TableCellTemplateConector = (placeholderName: string) => (params: Table.CellProps) =>
  (
    <TemplateConnector>
      {({ getCellValue }) => {
        const columnName = params.tableColumn.column!.name;
        const value = getCellValue(params.tableRow.row, columnName);

        return (
          <TemplatePlaceholder
            name={placeholderName}
            params={{
              value,
              row: params.tableRow.row,
              column: params.tableColumn.column,
            }}
          >
            {TableTagPlaceholderContent(params, value)}
          </TemplatePlaceholder>
        );
      }}
    </TemplateConnector>
  );

const shouldRenderTemplate =
  (columnNames: string[]) =>
  ({ column }: any) =>
    columnNames.includes(column.name);

const tableTagTemplate =
  (featureFields: FeatureFields, onValueChange: OnValueChange) =>
  ({ featureFields: _, onValueChange: __, ...restParams }: TableTagProps) =>
    <TableTag featureFields={featureFields} onValueChange={onValueChange} {...restParams} />;

const TableTagProvider: React.FC<TableTagProviderProps> = ({ featureFields, for: columnNames, onValueChange }) => (
  <Plugin name="TableTagProvider" key={columnNames.join('_')}>
    <Template name="tableCell" predicate={isDataTableCell(columnNames)}>
      {TableCellTemplateConector('valueFormatter')}
    </Template>
    <Template name="tableCell" predicate={isDataTableCell(columnNames)}>
      {TableCellTemplateConector('valueEditor')}
    </Template>
    <Template name="valueFormatter" predicate={shouldRenderTemplate(columnNames)}>
      {tableTagTemplate(featureFields, onValueChange)}
    </Template>
    <Template name="valueEditor" predicate={shouldRenderTemplate(columnNames)}>
      {tableTagTemplate(featureFields, onValueChange)}
    </Template>
  </Plugin>
);

export default TableTagProvider;
