import CashFlowEvalproServiceDefinition from './CashFlowEvalproServiceDefinition';
import Services from '../../../services/services';

export default class CashFlowEvalproServices extends Services {
  getCashFlowEvalpro(towerId) {
    return this.get(
      CashFlowEvalproServiceDefinition.getCashFlowEvalpro(towerId),
    );
  }
}
