import SalesRoomDefinitions from './salesRoomServiceDefinitions';
import Services from '../services';

export default class SalesRoomService extends Services {
  getProperties(towerId, clientId) {
    return this.get(SalesRoomDefinitions.salesRoom(towerId, clientId));
  }

  getAdditionalArea(id, towerId) {
    return this.get(SalesRoomDefinitions.getAdditionalArea(id, towerId));
  }

  getHistory({ state, propertyId, clientId }) {
    return this.get(
      SalesRoomDefinitions.getHistory({ state, propertyId, clientId }),
    );
  }

  getWaitingList({ propertyId }) {
    return this.get(SalesRoomDefinitions.getWaitingList({ propertyId }));
  }

  deleteClienToWaitingList({ propertyId, clientId }) {
    return this.delete(
      SalesRoomDefinitions.deleteClienToWaitingList({ propertyId, clientId }),
    );
  }

  deleteWaitingList({ propertyId, clientId }) {
    return this.delete(
      SalesRoomDefinitions.deleteWaitingList({ propertyId }),
      null,
      {
        params: { clientId },
      },
    );
  }

  getAdditionalAreas(towerId) {
    return this.get(SalesRoomDefinitions.getAdditionalAreas(towerId));
  }

  addAdditionalArea(propertyId, areaId) {
    return this.put(SalesRoomDefinitions.addAdditionalArea(propertyId, areaId));
  }

  putState(data, towerId, clientId) {
    return this.put(SalesRoomDefinitions.putState(towerId, clientId), data);
  }

  putPropertyPrice(towerId, propertyId, price) {
    return this.put(SalesRoomDefinitions.putPropertyPrice(towerId), {
      price,
      propertyId,
    });
  }

  downloadApartmentList(towerId) {
    return this.get(SalesRoomDefinitions.currentPropertyList(towerId), {
      responseType: 'blob',
    });
  }

  downloadAllLists(towerId) {
    return this.get(SalesRoomDefinitions.allLists(towerId), {
      responseType: 'blob',
    });
  }

  downloadSoldLists(towerId) {
    return this.get(SalesRoomDefinitions.soldApartments(towerId), {
      responseType: 'blob',
    });
  }

  downloadAvailableLists(towerId) {
    return this.get(SalesRoomDefinitions.availableApartments(towerId), {
      responseType: 'blob',
    });
  }
}
