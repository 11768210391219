import Services from '../services';
import NotificationsServicesDefinitions from './NotificationsServicesDefinitions';
import SalesRoomNotificationsDTO from '../../components/Sidebar/interfaces/SalesRoomPendingNotificationsDTO';

class NotificationsServices extends Services {
  getApprovals = (towerId: string) =>
    this.get<SalesRoomNotificationsDTO>(NotificationsServicesDefinitions.getApprovals(towerId));
}

export default NotificationsServices;
