import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import useWaitingList from '../../customHooks/useWaitingList';
import WaitingListModal from './WaitingListModal';

const useStyles = makeStyles({
  inline: {
    display: 'inline',
  },
  progress: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: 0,
    height: '10px',
  },
  gridItem: {
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
  },
});

const WaitingList = ({
  property,
  clientId,
  setClientInQueue,
  setWaitingListSize,
  clientInQueue,
  isOpen
}) => {
  const classes = useStyles();
  const {
    waitingList,
    resolveExpiresPercentageDays,
    resolveExpiresDays,
    forceModalOpen,
    closeForceModal,
    openForceModal,
    onConfirmForceModal,
    modalActionLoading,
    openDeleteClientModal,
    closeDeleteClientModal,
    deleteClientModalOpen,
    onConfirmDeleteClient,
    clientForDelete,
  } = useWaitingList({
    propertyId: property?.id,
    clientId,
    setClientInQueue,
    setWaitingListSize,
    isOpen
  });

  return (
    <>
      <WaitingListModal
        title="Borrar lista de espera."
        loading={modalActionLoading}
        open={forceModalOpen}
        onConfirm={onConfirmForceModal}
        onCancel={closeForceModal}
      >
        Esta acción eliminará a todos los clientes de la lista de espera para
        esta propiedad exceptuando al cliente actual <br />
        <b>{clientInQueue?.client?.name}</b>.
      </WaitingListModal>
      <WaitingListModal
        title="Borrar cliente de la lista de espera."
        loading={modalActionLoading}
        open={deleteClientModalOpen}
        onConfirm={onConfirmDeleteClient}
        onCancel={closeDeleteClientModal}
      >
        Esta acción eliminará al cliente <b>{clientForDelete?.client?.name}</b>{' '}
        de la lista de espera para esta propiedad.
      </WaitingListModal>
      {waitingList.length ? (
        <span style={{ color: '#aeaeae', fontSize: '0.9em'}}>
          Se deben eliminar los clientes de la lista de espera antes de pasar a
          Disponible
        </span>
      ) : null}
      <Grid container spacing={2}>
        <Grid item>
          {waitingList.length ? (
            <div>
              <h3>
                {waitingList.length}{' '}
                {waitingList.length === 1 ? 'Cliente' : 'Clientes'} en lista de
                espera
              </h3>
              {clientInQueue && (
                <small>
                  <b>{clientInQueue?.client?.name}</b> <br />
                  Actualmente se encuentra en la posición
                  <b> # {clientInQueue?.index + 1}</b>
                </small>
              )}
            </div>
          ) : (
            <h3>No hay lista de espera</h3>
          )}
        </Grid>
        {!!waitingList.length && (
          <Grid item className={classes.gridItem}>
            <List dense>
              {waitingList.map((el, index) => {
                return (
                  <div
                    style={{
                      position: 'relative',
                    }}
                    key={`waitinglist-${index}`}
                  >
                    <div
                      className={classes.progress}
                      style={{
                        width: `${resolveExpiresPercentageDays(el.clientId)}%`,
                        background: '#27ae60',
                      }}
                    ></div>
                    <ListItem key={index}>
                      <ListItemText
                        primary={`${index + 1}. ${el.client.name}`}
                        secondary={
                          <React.Fragment>
                            {el?.client?.email} <br />
                            <b>
                              <small>
                                {resolveExpiresDays(el.clientId)}{' '}
                                {resolveExpiresDays(el.clientId) === 1
                                  ? 'Día restante'
                                  : 'Días restantes'}
                              </small>
                            </b>
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => openDeleteClientModal(el)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
          </Grid>
        )}
        {waitingList.length > 1 && clientInQueue && (
          <Grid item>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              onClick={openForceModal}
            >
              Seleccionar como comprador
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

WaitingList.propTypes = {
  property: PropTypes.object,
  clientId: PropTypes.string,
  setClientInQueue: PropTypes.func,
  setWaitingListSize: PropTypes.func,
  clientInQueue: PropTypes.object,
  isOpen: PropTypes.bool,
};

export default WaitingList;
