import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ContainerContext from '../../../../containers/Client/context';
import {
  updateClient,
  updateSelectedClient,
  updateAddressForOffice,
} from '../../../../containers/Client/actions';
import ClientsServices from '../../../../services/client/ClientsServices';

function useClientAddressLoaderForOffice(
  onClose,
  currentAddress,
  selectedClient,
  addressDescription,
  currentType,
) {
  const { dispatch } = useContext(ContainerContext);

  const { enqueueSnackbar } = useSnackbar();

  const { towerId } = useParams();

  const services = new ClientsServices();
  const onAccept = async () => {
    const address = {
      ...currentAddress,
      addressType: currentType,
    };
    const client = {
      ...selectedClient,
      clientAdditionalInfo: {
        ...selectedClient.clientAdditionalInfo,
        clientAddress: address,
        companyAddress: addressDescription,
      },
      companyAddress: addressDescription,
    };

    try {
      if (client.clientAdditionalInfo.id)
        await services.putAddressForAdditionalInfo(
          towerId,
          client.clientAdditionalInfo.id,
          address,
        );
      dispatch(updateClient(client));
      dispatch(updateSelectedClient(client));
      dispatch(updateAddressForOffice(address));
      onClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return { onAccept };
}

export default useClientAddressLoaderForOffice;
