import { Numbers } from 'helpers';
import FinancialNeighborhoodAlgorithms from 'helpers/FinancialNeighborhoodAlgorithms';
import { NumberFormatValues } from 'react-number-format';
import { QuotationConfigDto } from '../DTO/QuotationConfigDto';
import useUpdateQuotationSetupQuery from './useUpdateQuotationSetup';

export const calculateMonthlyRate = (annualRate) => {
  return Numbers.cleanNumber(
    FinancialNeighborhoodAlgorithms.totalIncrementRateToListIncrementRate(annualRate, 12) * 100,
  );
};

const useBankInformationForm = (props: QuotationConfigDto) => {
  const { updateQuotationSetup: updateQuotationSetupTermLimit } = useUpdateQuotationSetupQuery('termLimit');
  const { updateQuotationSetup: updateQuotationSetupAnnualRate } = useUpdateQuotationSetupQuery('annualRate');
  const { updateQuotationSetup: updateQuotationSetupRequiredEntryRate } =
    useUpdateQuotationSetupQuery('requiredEntryRate');

  const handleChangeTermLimit = (values: NumberFormatValues) => {
    const newValue = values.floatValue ?? 0;
    if (newValue !== props.quotationSetup.termLimit) updateQuotationSetupTermLimit(newValue);
  };

  const handleChangeAnnualRate = (values: NumberFormatValues) => {
    const newValue = values.floatValue ?? 0;
    if (newValue !== props.quotationSetup.annualRate) updateQuotationSetupAnnualRate(newValue / 100);
  };

  const handleChangeRequiredEntryRate = (values: NumberFormatValues) => {
    const newValue = values.floatValue ?? 0;
    if (newValue !== props.quotationSetup.requiredEntryRate) updateQuotationSetupRequiredEntryRate(newValue);
  };

  const isAllowed =
    (start: number, end: number) =>
    ({ floatValue }: NumberFormatValues) => {
      return floatValue === undefined || (floatValue > start && floatValue < end);
    };

  const monthlyRate = calculateMonthlyRate(props.quotationSetup.annualRate);

  const isValidBankInformation =
    props.quotationSetup.termLimit > 0 && monthlyRate > 0 && props.quotationSetup.requiredEntryRate > 0;

  return {
    monthlyRate,
    isValidBankInformation,
    handleChangeRequiredEntryRate,
    handleChangeAnnualRate,
    handleChangeTermLimit,
    isAllowed,
  };
};

export default useBankInformationForm;
