import styled from 'styled-components';
import colors from '../../../assets/styles/settings/colors.module.scss';

interface OptionalIconProps {
  width: string;
  height: string;
  color: string;
}

interface IconProps extends Partial<OptionalIconProps> {
  variant: string;
}

const resolveUrl = (props: IconProps) => require(`../../../assets/icons/${props.variant}.svg`);

const resolveColor = (props: IconProps) => (props.color && colors[props.color]) || 'currentColor';

const Icon = styled.div<IconProps>`
  background-color: ${resolveColor};
  -webkit-mask: url(${resolveUrl}) no-repeat center;
  mask: url(${resolveUrl}) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  width: ${(props: IconProps) => props.width || '30px'};
  height: ${(props: IconProps) => props.height || '30px'};
`;

export default Icon;
