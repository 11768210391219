import { TableInlineCellEditing } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import styled from 'styled-components';

import { tableCellMixin } from './TableCell';

const StyledTableCellEditing = styled(TableInlineCellEditing.Cell)<TableInlineCellEditing.CellProps>`
  ${tableCellMixin}
`;

const TableCellEditing: React.FC<TableInlineCellEditing.CellProps> = (props) => <StyledTableCellEditing {...props} />;

export default TableCellEditing;
