import { useState, useEffect } from 'react';
import { useQuery, useQueryClient, QueryClient } from 'react-query';
import { find } from 'lodash';

import { Contract, Category, ContractLot } from '../../../../models/Contracts';
import Services from '../../../../services/Contracts/ContractsServices';

const services = new Services();

const useContractData = (contractId: number) => {
  const queryClient: QueryClient = useQueryClient();

  return useQuery<Contract | ContractLot>(
    ['contract', contractId],
    async () => {
      const { data } = await services.getContract(contractId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      initialData: () => {
        const data = queryClient.getQueryData<Category[]>('contracts');
        let contract: Contract | undefined;

        find(data, (category: Category) => {
          const { contracts } = category;
          contract = contracts.find((row: Contract) => row.id === contractId);
          return contract;
        });

        return contract;
      },
    },
  );
};

type HookType = (currentContractId: number) => {
  currentContract: Contract | undefined;
  isLoading: boolean;
  selectedIndex: number;
  handleListItemChange: (index: number) => void;
  contractTitle: string;
  currentContractIsLot: boolean;
};

const useContractDetail: HookType = (currentContractId: number) => {
  const { data: currentContract, isLoading } = useContractData(currentContractId);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [contractTitle, setContractTitle] = useState<string>('');
  const [currentContractIsLot, setCurrentContractIsLot] = useState<boolean>(false);

  useEffect(() => {
    if (currentContract) {
      const isLot = currentContract.isLot === true;
      const title = `${isLot ? 'Lote' : 'Contrato'} ${currentContract.id}`;
      setContractTitle(title);
      setCurrentContractIsLot(isLot);
    }
  }, [currentContract]);

  const handleListItemChange = (newValue: number) => {
    setSelectedIndex(newValue);
  };

  return { contractTitle, selectedIndex, handleListItemChange, currentContract, isLoading, currentContractIsLot };
};

export default useContractDetail;
