import React from 'react';
import CompanySchedules from './views/Table/CompanySchedules';

const CompanySchedulesMainView = () => {
  return (
    <>
      <CompanySchedules />
    </>
  );
};

export default CompanySchedulesMainView;
