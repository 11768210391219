import React, { FC } from 'react';
import { QuotationConfigDto } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import QuotationConfigElement from '../../components/QuotationConfigCollapseHeader/QuotationConfigElement';
import { Box } from '@material-ui/core';
import ConfigureDocumentTemplateModal from '../../modals/ConfigureDocumentTemplateModal/ConfigureDocumentTemplateModal';

const ConfigureDocumentTemplateForm: FC<QuotationConfigDto> = (props) => {
  const { projectIconPath, companyIconPath } = props.quotationSetup;
  return (
    <QuotationConfigElement
      name="Configurar plantilla de documentos"
      state={!!projectIconPath && !!companyIconPath ? 'success' : 'warning'}
    >
      <Box paddingY="4px">
        <ConfigureDocumentTemplateModal {...props} />
      </Box>
    </QuotationConfigElement>
  );
};

export default ConfigureDocumentTemplateForm;
