import React, { FC } from 'react';
import { GeneralWalletThreadInteractionDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import GeneralWalletInput from './GeneralWalletInput';

import styles from './GeneralWalletDetailsThread.module.scss';

interface Props {
  onChange: (value: string | File) => void;
}

const GeneralWallletDetailsThreadInteractionCommentEditable: FC<GeneralWalletThreadInteractionDTO & Props> = ({
  comment,
  onChange,
}) => {
  return (
    <div className={styles.ThreadInteractionCommentEdit}>
      <GeneralWalletInput isStatic initialValue={comment} hiddenFile onSubmit={onChange} isSuccess={false} />
    </div>
  );
};

export default GeneralWallletDetailsThreadInteractionCommentEditable;
