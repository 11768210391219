import { DataTypeProvider } from '@devexpress/dx-react-grid';
import React from 'react';

import useAutoFocus from '../../customHooks/useAutoFocus';
import styles from './TableCellNonEditingComponent.module.scss';

const TableCellNonEditingComponent = (CellFormaterComponent: React.FC<DataTypeProvider.ValueFormatterProps>) => {
  // @ts-ignore
  const TableCellNonEditing: React.FC<DataTypeProvider.ValueEditorProps> = ({ onFocus, onBlur, ...restProps }) => {
    const { focusableElement } = useAutoFocus<HTMLDivElement>();

    return (
      <div className={styles.root} ref={focusableElement} tabIndex={0} onFocus={onFocus} onBlur={onBlur}>
        <CellFormaterComponent {...restProps} />
      </div>
    );
  };
  return TableCellNonEditing;
};

export default TableCellNonEditingComponent;
