import React, { FC } from 'react';
import useAreas from 'App/Area/Core/customHooks/useAreas';
import styles from './AreaMainContainer.module.scss';
import { RecoilRoot } from 'recoil';
import AreaDashboardContainer from '../AreaDashboardContainer/AreaDashboardContainer';
import AreasNonIdealState from '../../sections/AreasNonIdealState/AreasNonIdealState';

interface IAreaMainContainerProps {
  towerId: string;
}

const AreaMainContainer: FC<IAreaMainContainerProps> = ({ towerId }) => {
  const { isLoading, isSuccess, areasResponse, isDisabledResponse } = useAreas({ towerId });

  return (
    <div className={styles.MainContainer}>
      <RecoilRoot>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {isSuccess && !!areasResponse?.customColumns.length ? (
              <AreaDashboardContainer areasResponse={areasResponse} towerId={towerId} isDisabled={isDisabledResponse} />
            ) : (
              <AreasNonIdealState towerId={towerId} />
            )}
          </>
        )}
      </RecoilRoot>
    </div>
  );
};

export default AreaMainContainer;
