import { useState } from 'react';

function useMaskForPhoneNumber(onChange, id) {
  const defaultMask = '+# (###) ###-####';
  const newMask = '+## (###) ###-####';

  const [mask, setMask] = useState(defaultMask);

  const onChangeValue = (values) => {
    onChange({
      target: {
        name: id,
        value: values.floatValue,
      },
    });

    if (`${values.floatValue}`.length > 10) {
      console.log('Enter');
      setMask(newMask);
    }
  };

  return { mask, onChangeValue };
}

export default useMaskForPhoneNumber;
