import React, { FC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import {
  Grid,
  TableHeaderRow,
  TableInlineCellEditing,
  TableSummaryRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import { SummaryState, IntegratedSummary, EditingState } from '@devexpress/dx-react-grid';

import VirtualTableContainer from '../../../../../shared/tables/VirtualTableContainer';
import TableCell from '../../../../../shared/tables/TableCell';
import TableHeaderRowCell from '../../../../../shared/tables/TableHeaderRowCell';
import TableCellEditing from '../../../../../shared/tables/TableCellEditing';
import TableSummaryRowCell from '../../../../../shared/tables/TableSummaryRowCell';

import useColumnsAndRowsForContractPaymentsSchedule from '../../../Core/customHooks/useColumnsAndRowsForContractPaymentsSchedule';

import { ContractPaymentScheduleFlow } from '../../../../../models/ContractPayments';
import ContractPaymentScheduleGridProvider from './ContractPaymentScheduleGridProvider';
import ContractPaymentScheduleGridTitleProvider from './ContractPaymentScheduleGridTitleProvider';
import ContractPaymentScheduleGridStateProvider from './ContractPaymentScheduleGridStateProvider';
import ContractPaymentScheduleGridEditProvider from './ContractPaymentScheduleGridEditProvider';
import ContractPaymentScheduleGridDifferenceProvider from './ContractPaymentScheduleGridDifferenceProvider';
import ContractsPaymentScheduleGridSummaryItemComponent from './ContractsPaymentScheduleGridSummaryItemComponent';

interface IContractPaymentScheduleGrid {
  rows: ContractPaymentScheduleFlow[] | undefined;
  isLoading: boolean;
  currentContractId: number;
}

const getRowId = (row: ContractPaymentScheduleFlow) => row.origDate;

const ContractPaymentScheduleGrid: FC<IContractPaymentScheduleGrid> = ({
  rows: originalRows,
  isLoading,
  currentContractId,
}) => {
  const {
    columns,
    currencyColumns,
    currencyEditColumns,
    differenceColumns,
    chipColumns,
    dateColumns,
    totalSummarySchedules,
    tableColumnExtensions,
    editingStateColumnExtensions,
    commitChanges,
    summaryCalculator,
  } = useColumnsAndRowsForContractPaymentsSchedule({ originalRows, currentContractId });

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box mt={2}>
          {!!originalRows && (
            <Grid rows={originalRows} columns={columns} getRowId={getRowId}>
              <ContractPaymentScheduleGridTitleProvider for={dateColumns} />
              <ContractPaymentScheduleGridProvider for={currencyColumns} />
              <ContractPaymentScheduleGridEditProvider for={currencyEditColumns} />
              <ContractPaymentScheduleGridStateProvider for={chipColumns} />
              <ContractPaymentScheduleGridDifferenceProvider for={differenceColumns} />
              <EditingState columnExtensions={editingStateColumnExtensions} onCommitChanges={commitChanges} />
              <SummaryState totalItems={totalSummarySchedules} />
              <IntegratedSummary calculator={summaryCalculator} />
              <VirtualTable
                height="73vh"
                columnExtensions={tableColumnExtensions}
                containerComponent={VirtualTableContainer}
                cellComponent={TableCell}
              />
              <TableHeaderRow cellComponent={TableHeaderRowCell} />
              <TableInlineCellEditing startEditAction="click" cellComponent={TableCellEditing} />

              <TableSummaryRow
                totalCellComponent={TableSummaryRowCell}
                itemComponent={ContractsPaymentScheduleGridSummaryItemComponent}
              />
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};

export default ContractPaymentScheduleGrid;
