import Services from 'services/services';
import { AreaSaveBulkPayload } from '../Core/interfaces/AreaSaveBulkPayload';
import IAreasResponse from '../Core/interfaces/IAreasResponse';
import { ICheckIfAreaTypeExistsResponse } from '../Core/interfaces/ICheckIfAreaTypeExistsResponse';
import { AreaServiceDefinitions } from './AreaServiceDefinitions';

const def = AreaServiceDefinitions;

export class AreaService extends Services {
  getAreas = (towerId: string) => this.get<IAreasResponse>(def.areasByTower(towerId));
  checkAlreadyExistAreaTypeName = (towerId: string, name: string) =>
    this.get<ICheckIfAreaTypeExistsResponse>(def.areasTypeCheckName(towerId, name));
  saveBulk = (towerId: string, payload: AreaSaveBulkPayload) => this.put(def.areasSaveBulk(towerId), payload);
  uploadFile = (towerId: string, formData: FormData) =>
    this.put(def.areasUploadFile(towerId), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
}
