import { API_PATH } from '../../../config/config';

const ForgotPasswordServicesDefinition = {
  forgotPassword: () => {
    return `${API_PATH}user/forgotPassword`
  },
  changePassword: () => {
    return `${API_PATH}user/changePassword`
  }
}

export default ForgotPasswordServicesDefinition;