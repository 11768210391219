export const Role = {
  Super: 'SUPER',
  Admin: 'ADMIN',
  User: 'STAFF',
  Sales: 'SALES',
  SalesDirector: 'SALES_DIRECTOR',
  Wallet: 'WALLET'
};

export const RoleName = {
  SUPER: 'Super Administrador',
  ADMIN: 'Administrador',
  STAFF: 'Personal',
  SALES: 'Ventas',
  SALES_DIRECTOR: 'Director ventas',
  WALLET: 'Cartera'
}