import React from 'react';

import Typography from '../../../../shared/components/Typography/Typography';
import stylesSettings from '../styles/settings.module.scss';

const FilterMenuTypography: React.FC = ({ children }) => (
  <Typography variant="body1"
    color={stylesSettings.selectColorName} >
      {children}
  </Typography>
);

export default FilterMenuTypography