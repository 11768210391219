import { IconButton as MuiIconButton } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { IconButtonProps } from '../../interfaces/IconButton.types';
import Icon from '../Icon/Icon';

import styles from './IconButton.module.scss';

const buttonVariantClasses = {
  fill: styles.buttonFill,
  outline: styles.buttonOutline,
  ghost: styles.buttonGhost,
  default: '',
};

const buttonSizeClasses = {
  xsmall: styles.buttonXSmall,
  small: styles.buttonSmall,
  medium: styles.buttonMedium,
  large: styles.buttonLarge,
  default: '',
};

const iconSizeClasses = {
  xsmall: styles.iconXSmall,
  small: styles.iconSmall,
  medium: styles.iconMedium,
  large: styles.iconLarge,
  default: '',
};

const getButtonVariantClass = (variant = 'default') => buttonVariantClasses[variant];

const getButtonSizeClass = (size = 'default') => buttonSizeClasses[size];

const getIconSizeClass = (size = 'default') => iconSizeClasses[size];

const IconButton: React.FC<IconButtonProps> = React.forwardRef(
  ({ variant, size, className, iconName, ...restProps }, ref) => (
    <MuiIconButton
      className={clsx(getButtonVariantClass(variant), getButtonSizeClass(size), className)}
      ref={ref}
      {...restProps}
    >
      <Icon className={getIconSizeClass(size)} variant={iconName} />
    </MuiIconButton>
  ),
);

export default IconButton;
