import { Contract } from '../../../../models/Contracts';

export interface UseContractsPopoverActionsProps {
  handleActionDetailsClicked: (contract: Contract) => void;
  handleActionEditClicked: (contract: Contract) => void;
  handleActionDeleteClicked: (contract: Contract) => void;
}

interface UseContractsPopoverActionsParams {
  contract?: Contract;
  extraProps: UseContractsPopoverActionsProps;
  cb: () => void;
}

type HookType = (params: UseContractsPopoverActionsParams) => {
  goDetails: () => void;
  goEdit: () => void;
  goDelete: () => void;
};

const useContractsPopoverActions: HookType = ({ contract, extraProps, cb }) => {
  const goDetails = () => {
    if (contract) extraProps.handleActionDetailsClicked(contract);
    cb();
  };

  const goEdit = () => {
    if (contract) extraProps.handleActionEditClicked(contract);
    cb();
  };

  const goDelete = () => {
    if (contract) extraProps.handleActionDeleteClicked(contract);
    cb();
  };

  return {
    goDetails,
    goEdit,
    goDelete,
  };
};

export default useContractsPopoverActions;
