import clsx from 'clsx';
import React, { Ref } from 'react';

import useStageTowerEditorModal from '../../../core/customHooks/useStageTowerEditorModal';
import StagesDTO from '../../../core/DTO/StagesDTO';
import {
  AddStageHandler,
  DeleteStageHandler,
  DeleteTowerHandler,
  MoveCardHandler,
  OnGoToTower,
  OnUpdateStages,
} from '../../../core/types/Stages.types';
import StagesBoard from '../StagesBoard/StagesBoard';
import StagesOnboarding from '../StagesOnboarding/StagesOnboarding';
import StagesTowerEditor from '../StagesTowerEditor/StagesTowerEditor';

import styles from './StagesContent.module.scss';

type Props = {
  className?: string;
  stages: StagesDTO;
  lastStageList: Ref<HTMLDivElement>;
  onAddStage: AddStageHandler;
  onDeleteStage: DeleteStageHandler;
  onUpdateStages: OnUpdateStages;
  onGoToTower: OnGoToTower;
  onDeleteTower: DeleteTowerHandler;
  onMoveCard: MoveCardHandler;
};

const StagesContent: React.FC<Props> = ({
  className,
  stages,
  lastStageList,
  onAddStage,
  onDeleteStage,
  onUpdateStages,
  onGoToTower,
  onDeleteTower,
  onMoveCard,
}) => {
  const { isEditorOpen, editorOptions, handleAddNewTower, handleEditTower, handleCloseEditor } =
    useStageTowerEditorModal();

  return (
    <>
      <div className={clsx(styles.root, className)}>
        <StagesOnboarding className={styles.onboarding} stages={stages} onAddStage={onAddStage} />
        <StagesBoard
          stages={stages}
          lastStageList={lastStageList}
          onDeleteStage={onDeleteStage}
          onAddTower={handleAddNewTower}
          onGoToTower={onGoToTower}
          onEditTower={handleEditTower}
          onDeleteTower={onDeleteTower}
          onMoveCard={onMoveCard}
        />
      </div>
      <StagesTowerEditor
        isOpen={isEditorOpen}
        options={editorOptions}
        onUpdateStages={onUpdateStages}
        onClose={handleCloseEditor}
      />
    </>
  );
};

export default StagesContent;
