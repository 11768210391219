import ContractsLotsServiceDefinition from './ContractsLotsServicesDefinition';
import Services from '../services';
import { ContractLot } from '../../models/Contracts';

export default class ContractsService extends Services {
  getAllContractsLots(towerId: string) {
    return this.get<ContractLot[]>(ContractsLotsServiceDefinition.getAllContractsLots(towerId));
  }
  createLot(towerId: string, data) {
    return this.post<ContractLot>(ContractsLotsServiceDefinition.createLot(towerId), data);
  }

  editContractLot(contractId: number, data) {
    return this.put<ContractLot>(ContractsLotsServiceDefinition.editContractLot(contractId), data);
  }

  editInterestRate(contractId: number, data: Partial<ContractLot>) {
    return this.put<ContractLot>(ContractsLotsServiceDefinition.editInterestRate(contractId), data);
  }
}
