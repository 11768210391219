import {
  MonthlyEventType,
  MonthlyPaymentForReport,
  MonthlyPaymentRow,
  WalletPaymentDistribution,
} from '../DTO/WalletMonthlyReportDTO';
import { useState } from 'react';
import { useEffect } from 'react';
import useMonthlyInterestValueChanged, { MonthlyInterestOnValueChange } from './useMonthlyInterestValueChanged';

function useMonthlyInterestRowsMapper(
  crudeRows: MonthlyPaymentForReport[],
  propertyPlan: WalletPaymentDistribution[],
  propertyId: number,
  reload: () => Promise<void>,
) {
  const [rows, setRows] = useState<MonthlyPaymentRow[]>([]);
  const { commitChanges } = useMonthlyInterestValueChanged(setRows, propertyId);

  const placeholderRow: MonthlyPaymentRow = {
    paymentName: '',
    options: '',
    type: MonthlyEventType.placeholder,
    commitment: 0,
    commitmentDate: new Date(),
    assignedPayment: 0,
    balanceFinancial: 0,
    days: 0,
    financialPeriod: 0,
    financialApplied: 0,
    financial: 0,
    financialFeeForDate: 0,
    balancePayment: 0,
    aggregatedCommitment: 0,
    financialAppliedValue: { value: 0, isChecked: false },
    id: '',
  };

  const mapToNewRow: (crude: MonthlyPaymentForReport, paymentName: string) => MonthlyPaymentRow = (
    crude,
    paymentName,
  ) => {
    const date =
      crude.type === MonthlyEventType.balance ? crude.aggregated?.date ?? crude.commitmentDate : crude.commitmentDate;
    const newRow: MonthlyPaymentRow = {
      ...crude,
      paymentName,
      options: '',
      aggregatedCommitment: crude.aggregated?.commitment ?? 0,
      commitmentDate: new Date(date),
      financialAppliedValue: crude.financialAppliedValue
        ? { ...crude.financialAppliedValue, value: crude.financialAppliedValue.value * -1 }
        : { value: 0, isChecked: false },
      financialApplied: crude.financialApplied * -1,
      financial: crude.financial * -1,
      financialPeriod: crude.financialPeriod * 100,
      financialFeeForDate: crude.financialFeeForDate * 100,
      id: crude.id,
    };
    return newRow;
  };

  const getPaymentNameAndUpdateIndex: (
    type: MonthlyEventType,
    lastIndex: number,
    length: number,
  ) => { paymentName: string; lastIndex: number } = (type, index, length) => {
    let paymentName = '';
    let lastIndex = index;
    if (type === MonthlyEventType.fee) {
      paymentName = 'Cuota ' + lastIndex;
      if (lastIndex === 0) {
        paymentName = 'Separación';
      } else if (lastIndex + 1 === length) {
        paymentName = 'Cuota Final';
      }

      lastIndex += 1;
    }
    return { paymentName, lastIndex };
  };

  useEffect(() => {
    const initialReduce: { rows: MonthlyPaymentRow[]; lastIndex: number } = { rows: [], lastIndex: 0 };
    const reducedRows = crudeRows.reduce((newRows, row, index) => {
      const { lastIndex, paymentName } = getPaymentNameAndUpdateIndex(row.type, newRows.lastIndex, propertyPlan.length);
      const newRow = mapToNewRow(row, paymentName);
      const nextRow = crudeRows[index + 1];
      if (nextRow && nextRow.type === MonthlyEventType.fee && newRow.type === MonthlyEventType.balance) {
        return { rows: [...newRows.rows, newRow, { ...placeholderRow }], lastIndex };
      }

      return { rows: [...newRows.rows, newRow], lastIndex };
    }, initialReduce);

    setRows(reducedRows.rows);
  }, [crudeRows]);

  const onValueChanged: MonthlyInterestOnValueChange = (row) => {
    commitChanges(row, rows);
    reload();
  };

  return { rows, onValueChanged };
}

export default useMonthlyInterestRowsMapper;
