import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import classes from '../../styles/LeftColumnCustomCell.module.scss';
import clsx from 'clsx';

const LeftColumnCustomCell = (props) => {
  const { tableRow, className, ...restProps } = props;
  const { row } = tableRow;

  if (row.id === 'TOTAL_SALES' || row.id === 'EXPENSES') {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.salesAndExpenses, className)}
      />
    );
  }
  if (row.id === 'ACCUMULATED_ID') {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(
          classes.cashBalance,
          classes.cellConfiguration,
          className,
        )}
      />
    );
  }
  if (row.id === 'OPERATING-FLOW' || row.id === 'MONTH_BALANCED_ID') {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.boldRows, classes.cellConfiguration, className)}
      />
    );
  }
  if (row.id === 'financing-credit-balance' || row.id === 'investmentBalance') {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.builderCreditBalance, className)}
      />
    );
  }
  if (row.subParentId === 'financing-credit-balance') {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.subBuilderCreditBalance, className)}
      />
    );
  }
  if (row.parentId === null && row.subParentId === null) {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.groupRow, className)}
      />
    );
  }
  if (row.parentId && row.subParentId === null) {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.subGroupRow, className)}
      />
    );
  }
  if (row.parentId && row.subParentId) {
    return (
      <VirtualTable.Cell
        {...restProps}
        className={clsx(classes.itemRow, className)}
      />
    );
  }
  return <VirtualTable.Cell {...restProps} />;
};

LeftColumnCustomCell.propTypes = {
  tableRow: PropTypes.object,
};

export default LeftColumnCustomCell;
