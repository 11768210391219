import React, { FC } from 'react';
import styled from 'styled-components';
import { VirtualTable, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import Typography, { TypographyProps } from '../../../../shared/components/Typography/Typography';
import { Checkbox } from '@material-ui/core';
import Styles from './MonthlyInterestHeaderCell.module.scss';

const CustomCell: React.FC = styled(TableHeaderRow.Cell)<TableHeaderRow.CellProps>`
  border: 0px;
  height: 2.188rem;
  padding: 0px;
  color: black;
  background-color: #f2fafb;
  vertical-align: middle;
`;

const CenterTypography: FC<TypographyProps> = styled(Typography)`
  margin: auto;
`;

const MonthlyInterestHeaderCell: (
  updater: (isChecked: boolean) => void,
  isChecked: boolean,
) => FC<TableHeaderRow.CellProps> = (updater, isChecked) => (props) => {
  return (
    <CustomCell {...props}>
      <CenterTypography variant="subtitle1">{props.children}</CenterTypography>
      {props.column.name === 'financialAppliedValue' && (
        <div>
          <Checkbox
            color="primary"
            checked={isChecked}
            className={Styles.check}
            onChange={() => {
              updater(!isChecked);
            }}
          />
        </div>
      )}
    </CustomCell>
  );
};

export default MonthlyInterestHeaderCell;
