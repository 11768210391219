import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import ExternalResourcesServices from '../../../../services/externalResources/ExternalResourcesServices';

function useUploadSoldExternalResources() {
  const services = new ExternalResourcesServices();
  const { towerId } = useParams<{ towerId: string }>();

  const { mutate, isSuccess, isLoading, isError } = useMutation(async (formData: FormData) =>
    services.uploadSoldSummary(towerId, formData),
  );
  return { isSuccess, isError, isLoading, mutate };
}

export default useUploadSoldExternalResources;
