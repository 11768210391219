import { useState, useEffect, MouseEventHandler } from 'react';

import ModalProps from '../interfaces/ModalProps';

function useModalHandlers(modalProps: ModalProps) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(modalProps.isOpen);
  }, [modalProps.isOpen]);

  const acceptAction: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (modalProps.acceptAction) modalProps.acceptAction(event);
    setOpen(false);
  };

  const cancelAction: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (modalProps.cancelAction) modalProps.cancelAction(event);
    setOpen(false);
  };

  return { isOpen, setOpen, acceptAction, cancelAction };
}

export default useModalHandlers;
