import React, { FC } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Typography from '../../../../shared/components/Typography/Typography';
import BorderLinearProgress from '../linearProgress/BorderLinearProgress';

const ContractsEvalproGridProgressFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography>
          <NumberFormat
            displayType="text"
            value={value}
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            suffix="%"
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default ContractsEvalproGridProgressFormatter;
