import React, { FC } from 'react';
import { GeneralWalletThreadDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import GeneralWalletThreadProvider from 'App/GeneralWalletDetails/core/contexts/GeneralWalletThreadContext';
import GeneralWallletDetailsThreadContainer from './GeneralWallletDetailsThreadContainer';

const GeneralWallletDetailsThread: FC<GeneralWalletThreadDTO> = (thread) => (
  <GeneralWalletThreadProvider key={'GeneralWallletDetailsThread' + thread.id} thread={thread}>
    <GeneralWallletDetailsThreadContainer {...thread} />
  </GeneralWalletThreadProvider>
);

export default GeneralWallletDetailsThread;
