import { useState } from 'react';

import { FeatureFieldTypes } from '../enums/featureField.enum';
import {
  EditableFeatureField,
  FeatureField,
  FeatureFieldsRepository,
  FeatureFieldOptions,
  EditableFieldOption,
} from '../interfaces/featureField.types';
const defaultEditableField = {
  title: '',
  index: 0,
  isEnabled: true,
  type: FeatureFieldTypes.SingleSelect,
};

const getLastField = (fieldsOrder: number[]) => fieldsOrder.slice(-1)[0];

const getLastIndex = (featureFieldsRepo: FeatureFieldsRepository) =>
  featureFieldsRepo.data[getLastField(featureFieldsRepo.order)].index;

const getNextIndex = (featureFieldsRepo: FeatureFieldsRepository) => getLastIndex(featureFieldsRepo) + 1;

const getNewIndex = (featureFieldsRepo: FeatureFieldsRepository) =>
  featureFieldsRepo.order.length ? getNextIndex(featureFieldsRepo) : 0;

const getDefaultSingleSelectField = (index: number): EditableFeatureField => ({
  ...defaultEditableField,
  index,
  options: [
    {
      value: 'Opción 1',
      color: '#00DD6D',
    },
    {
      value: 'Opción 2',
      color: '#FFAA3A',
    },
  ],
});

const getEditableFieldOption =
  (options: FeatureFieldOptions) =>
  (optionId: number): EditableFieldOption =>
    options[optionId];

const getEditableFieldOptions = (options: FeatureFieldOptions, optionsOrder: number[]): EditableFieldOption[] =>
  optionsOrder.map(getEditableFieldOption(options));

const getEditableField = ({ options, optionsOrder, ...rest }: FeatureField): EditableFeatureField => ({
  ...rest,
  options: getEditableFieldOptions(options, optionsOrder),
});

function useFeatureFieldEditorStatus(featureFieldsRepo: FeatureFieldsRepository) {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableField, setEditableField] = useState<EditableFeatureField>(defaultEditableField);

  const handleAddNewField = () => {
    const newIndex = getNewIndex(featureFieldsRepo);

    setEditableField(getDefaultSingleSelectField(newIndex));
    setIsEditing(false);
    setIsEditorOpen(true);
  };

  const handleStartEditField = (field: FeatureField) => {
    setEditableField(getEditableField(field));
    setIsEditing(true);
    setIsEditorOpen(true);
  };

  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  const handleChangeField = (field: EditableFeatureField) => {
    setEditableField(field);
  };

  return {
    isEditorOpen,
    isEditing,
    editableField,
    handleAddNewField,
    handleStartEditField,
    handleCloseEditor,
    handleChangeField,
  };
}

export default useFeatureFieldEditorStatus;
