import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropertyTypeService from '../../services/PropertyTypeService';

const service = new PropertyTypeService();

const usePropertyType = () => {
  const [propertyTypes, setPropertyTypes] = useState([]);
  const { towerId } = useParams();

  const resolveType = (type) => {
    if (type) 
      return propertyTypes.find(t => t.id == type)
    else return { id: '', name: ''}
  }

  const call = async () => {
    const { data } = await service.getTypes({ towerId });
    setPropertyTypes(data.types);
  };

  useEffect(() => {
    call();
  }, []);

  return {
    propertyTypes,
    setPropertyTypes,
    resolveType
  };
};

export default usePropertyType;
