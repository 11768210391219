import _ from 'lodash';
import { useEffect, useState } from 'react';

export default function useSlider(salesRoom) {
  const [minRangeInBudget, setMinRangeInBudget] = useState(0);
  const [maxRangeInBudget, setMaxRangeInBudget] = useState(0);
  const [maxRangeInM2, setMaxRangeInM2] = useState(0);
  const [minRangeInM2, setMinRangeInM2] = useState(0);

  const valuesInSalesRoom = (properties, selector) => {
    const minRangeInProperties = _.minBy(properties, (property) =>
      Number(property[selector]),
    );
    return minRangeInProperties ? minRangeInProperties[selector] : 0;
  };

  const maxValuesInSalesRoom = (properties, selector) => {
    const minRangeInProperties = _.maxBy(properties, (property) =>
      Number(property[selector]),
    );
    return minRangeInProperties ? minRangeInProperties[selector] : 0;
  };

  const main = () => {
    const properties = salesRoom.properties
      ? salesRoom.properties.flatMap((p) => p)
      : [];
    const valueForMinRangeInBudget = valuesInSalesRoom(
      properties,
      'priceWithIncrement',
    );
    const valueForMaxRangeInBudget = maxValuesInSalesRoom(
      properties,
      'priceWithIncrementSold',
    );
    const minValueForRangeInM2 = valuesInSalesRoom(properties, 'mts2');
    const maxValueForRangeInM2 = maxValuesInSalesRoom(properties, 'mts2');
    setMinRangeInM2(minValueForRangeInM2);
    setMinRangeInBudget(valueForMinRangeInBudget);
    setMaxRangeInBudget(valueForMaxRangeInBudget);
    setMaxRangeInM2(maxValueForRangeInM2);
  };

  useEffect(() => {
    main();
  }, [salesRoom]);

  return { minRangeInBudget, maxRangeInM2, minRangeInM2, maxRangeInBudget };
}
