import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { RootState } from '../../../../../../store/store';

import { Contractor } from '../../../../../../models/Contractors';
import Services from '../../../../Services/BusinessPartnersServices';

import {
  IUseFormResult,
  useForm,
} from '../../../../../../shared/customHooks/useForm';

import { useContractorContext } from '../../Context/ContractorContext';

const services = new Services();

export interface UseContractorFormParams {
  name: string;
}

export interface UseContractorFormResult extends IUseFormResult<Contractor> {
  currentContractor?: Contractor;
}

type HookType = (param?: UseContractorFormParams) => UseContractorFormResult;

const useContractorForm: HookType = () => {
  const {
    currentContractor,
    handleCreateContractor,
    handleUpdateContractor,
  } = useContractorContext();

  const { enqueueSnackbar } = useSnackbar();

  const { companyId } = useSelector(
    (state: RootState) => state.businessPartnerCreator,
  );

  const createContractor = async (
    contractor: Contractor,
    reset: () => void,
  ): Promise<void> => {
    const { data } = await services.createBusinessPartner({
      ...contractor,
      companyId,
    });
    handleCreateContractor(data);
    reset();
  };

  const updateContractor = async (contractor: Contractor): Promise<void> => {
    if (currentContractor) {
      const updatedData = {} as Record<string, unknown>;

      Object.keys(contractor).forEach((keyString) => {
        const key = keyString as keyof Partial<Contractor>;
        if (contractor[key] !== currentContractor[key])
          updatedData[key] = contractor[key];
      });

      if (Object.keys(updatedData).length) {
        const data = {
          id: contractor.id,
          ...updatedData,
        };
        await services.editBusinessPartner(contractor.id, data);
        handleUpdateContractor(contractor.id, updatedData);
      }
    }
  };

  const form = useForm<Contractor>(
    {
      validations: {
        patnerName: {
          required: {
            value: true,
            message: 'Nombre es requerido',
          },
        },
        patnerEmail: {
          custom: {
            isValid: (value: unknown): boolean => {
              if (!value) return true;
              const regex = new RegExp(
                '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$',
              );
              return regex.test(value as string);
            },
            message: 'Correo electrónico no válido',
          },
        },
      },
      initialValues: {
        id: currentContractor?.id || undefined,
        patnerAdress: currentContractor?.patnerAdress || '',
        patnerCity: currentContractor?.patnerCity || '',
        patnerContactPerson: currentContractor?.patnerContactPerson || '',
        patnerCountry: currentContractor?.patnerCountry || '',
        patnerEmail: currentContractor?.patnerEmail || '',
        patnerFiscalInformation:
          currentContractor?.patnerFiscalInformation || '',
        patnerName: currentContractor?.patnerName || '',
        patnerPhone: currentContractor?.patnerPhone || '',
        patnerPostalCode: currentContractor?.patnerPostalCode || '',
        patnerVatNumber: currentContractor?.patnerVatNumber || '',
        patnerWebsite: currentContractor?.patnerWebsite || '',
      },
      async onSubmit(newData, resetForm) {
        try {
          if (currentContractor?.id) await updateContractor(newData);
          else await createContractor(newData, resetForm);
        } catch (error) {
          enqueueSnackbar('Error interno servidor');
        }
      },
    },
    [currentContractor],
  );
  return {
    ...form,
    currentContractor,
  };
};

export default useContractorForm;
