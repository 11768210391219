import { useEffect } from 'react';
import useBoolean from 'shared/customHooks/useBoolean';
import { ClientDetailsType } from '../interfaces/ClientDetails.type';

export interface GeneralWalletDetailsProps {
  client: ClientDetailsType;
  onClose: () => void;
}

const useGeneralWalletDetailsDrawer = (props: GeneralWalletDetailsProps) => {
  const { client, onClose } = props;
  const { value: isOpen, setTrue: open, setFalse } = useBoolean(false);

  const close = () => setTimeout(onClose, 500);

  const closeGeneralWalletDetails = () => {
    setFalse();
    close();
  };

  useEffect(() => {
    if (client?.clientId) open();
    else close();
  }, [client]);

  return { isOpen, closeGeneralWalletDetails };
};

export default useGeneralWalletDetailsDrawer;
