import { useEffect, useState } from 'react';
import useClientHubForPropertyRequest from '../../../RealPaymentsForProperty/Core/customHooks/useClientHubForPropertyRequest';
import useClientHubMapper from '../../../RealPaymentsForProperty/Core/customHooks/useClientHubMapper';
import useIsPurchaseConfirmed from '../../../RealPaymentsForProperty/Core/customHooks/useIsPurchaseConfirmed';

const useBuyerCustomerPaymentsState = ({ propertyId }) => {
  const [label, setLabel] = useState('Pendiente');
  const [isComplete, setIsComplete] = useState(false);
  const [isInArrears, setIsInArrears] = useState(false);

  const { isPurchaseConfirmed: isConfirmed } = useIsPurchaseConfirmed();
  useClientHubForPropertyRequest(propertyId);
  const { isArrears, chargeNumber } = useClientHubMapper();

  useEffect(() => {
    if (isConfirmed && !isArrears) setLabel('Completo');
    if (isConfirmed && isArrears) setLabel('En mora');
    if (!isConfirmed && !isArrears) setLabel('Falta confirmar');
    if (!isConfirmed && isArrears) setLabel('Venta sin confirmar');
    if (isConfirmed && !chargeNumber) setLabel('Falta número de encargo');

    setIsComplete(isConfirmed && !isArrears);
    setIsInArrears(isConfirmed && isArrears);
  }, [isArrears, isConfirmed, chargeNumber]);

  return { label, isComplete, isInArrears };
};

export default useBuyerCustomerPaymentsState;
