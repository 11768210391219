/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { disableClear } from '../actions';

export default function useFilter(makePropertyArr, salesRoom) {
  const { enqueueSnackbar } = useSnackbar();
  const salesRoomFilter = useSelector((state) => state.salesRoomFilter);
  const dispatch = useDispatch();
  const [salesRoomState, setDefaultSalesRoom] = useState(salesRoom);
  const [firstIn, setFirstIn] = useState(true);

  const notFoundFilter = (rooms, filt, newProps, { active }) => {
    const newProperties = newProps;
    const {
      m2,
      budget,
      bedrooms,
      bathrooms,
      orientation,
      availability,
      group,
      m2Active,
      budgetActive,
      bedroomsActive,
      bathroomsActive,
      orientationActive,
      availabilityActive,
      groupActive,
      clear,
    } = rooms;

    const evalString = [availability, group].some((s) => s !== '');
    const evalNumber = [budget].some((s) => s !== 0);
    const evalArray = [bedrooms, bathrooms, orientation, m2].some(
      (s) => s.length !== 0,
    );
    const evalActive = [
      m2Active,
      budgetActive,
      bedroomsActive,
      bathroomsActive,
      orientationActive,
      availabilityActive,
      groupActive,
    ].some((s) => s) && active;

    if (
      (evalString && filt.length === 0 && evalActive) ||
      (evalNumber && filt.length === 0 && evalActive) ||
      (evalArray && filt.length === 0 && evalActive)
    ) {
      newProperties.properties = [];
      enqueueSnackbar('No se encontró resultados para el filtro', {
        variant: 'warning',
        preventDuplicate: true,
      });
      makePropertyArr(newProperties, 'priceWithIncrements');
    } else if (!evalActive) {
      makePropertyArr(salesRoomState, salesRoomFilter.filterTableName);
    }
  };

  const filterAction = (arrOfOptions) => {
    const actives = Object.keys(arrOfOptions).filter(
      (key) =>
        salesRoomFilter[`${key}Active`] &&
        salesRoomFilter[key].toString().length > 0,
    );
    const conditionsToUse = actives.reduce((prev, current) => {
      switch (current) {
        case 'm2':
          return `${prev}&&m2[1]>=property.mts2&&m2[0]<=property.mts2`;
        case 'budget':
          return `${prev}&&budget>=property.priceWithIncrementSold`;
        case 'bedrooms':
          return `${prev}&&_.includes(bedrooms,property.propertyCharacteristic?property.propertyCharacteristic.bedrooms:[])`;
        case 'bathrooms':
          return `${prev}&&_.includes(bathrooms,property.propertyCharacteristic?property.propertyCharacteristic.bathrooms:[])`;
        case 'orientation':
          return `${prev}&&_.includes(orientation,property.propertyCharacteristic?property.propertyCharacteristic.orientation:[])`;
        case 'availability':
          return `${prev}&&_.includes(availability, property ? property.status : '')`;
        case 'propertyTypes':
          return `${prev}&&property&&propertyTypes==property.type`;
        default:
          return `${prev}&& group === (property ? property.groupId : 0) `;
      }
    }, '');
    return conditionsToUse.slice(2);
  };

  const applyFilter = () => {
    const {
      m2,
      budget,
      bedrooms,
      bathrooms,
      orientation,
      availability,
      group,
      clear,
      filterTableName,
      propertyTypes,
    } = salesRoomFilter;
    if (clear) {
      makePropertyArr(salesRoomState, filterTableName);
      setFirstIn(true);
      dispatch(disableClear());
    }
    const actions = filterAction({
      m2,
      budget,
      bedrooms,
      bathrooms,
      orientation,
      availability,
      group,
      propertyTypes,
    });
    const props = salesRoomState.properties
      ? salesRoomState.properties.flatMap((p) => p)
      : [];
    // eslint-disable-next-line no-eval
    const filters = _.filter(props, (property) => eval(actions));
    const newProperties = { ...salesRoom };
    newProperties.properties = [filters];
    if (filters.length > 0) {
      makePropertyArr(newProperties, filterTableName);
    } else {
      notFoundFilter(salesRoomFilter, filters, newProperties, {
        active: actions && !!actions.length
      });
    }
  };

  useEffect(() => {
    applyFilter();
  }, [salesRoomFilter, salesRoomState]);

  useEffect(() => {
    setDefaultSalesRoom(salesRoom);
  }, [salesRoom]);

  return { applyFilter };
}
