import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import ClientForm from './ClientForm';
import HoverContainer, { options } from './HoverContainer';
import Services from '../../../services/clientProperty/clientPropertyServices';
import Styles from '../styles.module.scss';
import {
  setApiLoading,
  setClients,
  setAllClients,
} from '../../../containers/ClientProperty/actions';

const services = new Services();

const ClientCell = ({
  currentClient,
  status,
  property,
  towerId,
  onSetApiLoading,
  onSetClients,
  onSetAllClients,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [clientEditing, setClientEditing] = useState(false);

  const cleanClients = (clients) => {
    const tempClean = clients.flatMap((current) => {
      if (current.properties.length >= 1) {
        return current;
      }
      return [];
    });
    return tempClean;
  };

  const isEditingHandler = (state) => {
    setIsEditing(state);
  };

  const orderAndSetClients = (clientsRecived) => {
    onSetAllClients(clientsRecived.data);
    const cleanedClients = cleanClients(clientsRecived.data);
    const sortedClients = _.orderBy(
      cleanedClients,
      [
        (current) =>
          Number(current.name) ? Number(current.name) : current.name,
      ],
      ['asc'],
    );
    onSetClients(sortedClients);
  };

  const sendClientProperty = async (clientData) => {
    try {
      const addPropertyToClient = {
        client: {
          id: clientData.clientId,
          percentage: clientData.percentage,
          isContact: clientData.isContact,
        },
        property: property.id,
      };

      await services.postClientsFrom(towerId, addPropertyToClient);
      const clientsRecived = await services.getClientsFrom(towerId);
      const setAll = orderAndSetClients(clientsRecived);
      enqueueSnackbar('Asociado con éxito', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      onSetApiLoading(false);
    }
  };
  const removeClientHandler = async (clientId) => {
    try {
      const response = await services.deleteClientsFrom(towerId, {
        clientId,
        propertyId: property.id,
      });
      const clientsRecived = await services.getClientsFrom(towerId);
      const setAll = orderAndSetClients(clientsRecived);
      enqueueSnackbar('Desasociado con éxito', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      onSetApiLoading(false);
    }
  };

  const sendEditClientProperty = async (clientData) => {
    try {
      const addPropertyToClient = {
        client: {
          id: clientData.clientId,
          percentage: clientData.percentage,
          isContact: clientData.isContact,
        },
        property: property.id,
      };

      await services.updateClientsFrom(towerId, addPropertyToClient);
      const clientsRecived = await services.getClientsFrom(towerId);
      const setAll = orderAndSetClients(clientsRecived);
      enqueueSnackbar('Actualizado', { variant: 'success' });
      setClientEditing(false);
      setIsEditing(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      onSetApiLoading(false);
      setClientEditing(true);
      setIsEditing(true);
    }
  };

  const percentageOfClient = () => {
    const clientProperty = currentClient
      ? currentClient.properties.find((prop) => prop.propertyId === property.id)
      : 0;

    return clientProperty;
  };

  const clientProperty = percentageOfClient();

  return (
    <TableCell>
      {isEditing ? (
        <ClientForm
          isEditingHandler={isEditingHandler}
          sendClientProperty={sendClientProperty}
          currentClient={currentClient}
          clientEditing={clientEditing}
          currentClientProperty={clientProperty}
          sendEditClientProperty={sendEditClientProperty}
          property={property}
        />
      ) : (
        <HoverContainer
          status={status}
          updateHandler={() => {
            setIsEditing(true);
          }}
          updateAllHandler={() => {
            setClientEditing(true);
            setIsEditing(true);
          }}
          removeClientHandler={() => {
            setIsEditing(false);
            removeClientHandler(currentClient.id);
          }}
          option={currentClient ? options.DELETE : options.EDIT}
        >
          <span>
            {currentClient ? (
              <div>
                <span>{`${currentClient.name ||
                  ''} ${currentClient.middleName ||
                  ''} ${currentClient.surname ||
                  ''} ${currentClient.secondSurname || ''}`}</span>
                <br />
                <span>Doc: {currentClient.identityDocument}</span>
              </div>
            ) : (
              <span className={Styles.noAreasSelected}>Sin cliente</span>
            )}
          </span>
        </HoverContainer>
      )}
    </TableCell>
  );
};

ClientCell.propTypes = {
  currentClient: PropTypes.object,
  status: PropTypes.bool,
  property: PropTypes.object,
  towerId: PropTypes.string,
  onSetApiLoading: PropTypes.func,
  onSetClients: PropTypes.func,
  onSetAllClients: PropTypes.func,
};

const mapStateToProps = (state) => ({
  towerId: state.clientProperty.root.towerId,
});

const mapDispatchToProps = {
  onSetApiLoading: setApiLoading,
  onSetClients: setClients,
  onSetAllClients: setAllClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCell);
