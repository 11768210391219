import React, { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import buttonStyles from '../../ClientButtons/ClientButtons.module.scss';
import clientModalStyles from '../ClientModal.module.scss';

interface Props {
  isOpen: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

const CloseAlertModal: FC<Props> = (props) => {
  const { isOpen, handleConfirm, handleClose } = props;
  return (
    <Dialog open={isOpen} className={clientModalStyles.clientModal}>
      <Box padding={2}>
        <DialogContent>
          <Box
            marginY={2}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box marginTop={1}>
              <Typography>
                <b>¿Seguro que desea cancelar?</b>
              </Typography>
            </Box>
            <Box marginTop={1}>
              <Typography>Se perderán todos los datos no guardados.</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className={buttonStyles.button} onClick={handleClose}>
            No
          </Button>
          <Button className={buttonStyles.buttonEnd} onClick={handleConfirm}>
            Si
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CloseAlertModal;
