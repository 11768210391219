import React, { FC, useContext } from 'react';
import { Box, Card, CardContent, LinearProgress } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import Styles from '../../ContractDetailMainView.module.scss';
import { Contract } from '../../../../../models/Contracts';
import ContractDetailDifferenceFormatter from './ContractDetailDifferenceFormatter';
import { ContractDetailContext, ContractDetailContextState } from '../../ContractDetailMainView';

const ContractDetailStatistics: FC = () => {
  const { currentContract } = useContext(ContractDetailContext) as ContractDetailContextState;

  return (
    <Box p={2}>
      <Box paddingY={1}>
        <Card variant="outlined" elevation={1}>
          <CardContent>
            <dl className={Styles.dlHorizontalValues}>
              <dt className={Styles.labelBold}>Presupuesto</dt>
              <dd className={Styles.labelBold}>Proyectado</dd>
              <dt>
                <NumberFormat
                  className={Styles.titleBold}
                  value={currentContract?.total}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  fixedDecimalScale={true}
                  decimalScale={0}
                />
              </dt>
              <dd>
                <NumberFormat
                  className={Styles.titleBold}
                  value={currentContract?.totalProjected}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  fixedDecimalScale={true}
                  decimalScale={0}
                />
              </dd>
              <ContractDetailDifferenceFormatter value={currentContract?.cumulativeVariation || 0} />
              <dt className={Styles.title}>Ejecución</dt>
              <dd className={Styles.title}>
                <NumberFormat
                  value={currentContract?.percentExecuted}
                  displayType="text"
                  thousandSeparator
                  suffix="%"
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              </dd>
            </dl>
          </CardContent>
        </Card>
      </Box>
      <Card variant="outlined" elevation={1}>
        <CardContent>
          <Box width="100%">
            <LinearProgress
              variant="determinate"
              value={currentContract?.percentInvoiced}
              classes={{
                barColorPrimary: Styles.bgColorPrimary,
              }}
            />
          </Box>
          <Box>
            <dl className={Styles.dlHorizontalValues}>
              <dt className={Styles.labelBoldSuccess}>Facturado</dt>
              <dd className={Styles.labelBold}>Por facturar</dd>
              <dt>
                <NumberFormat
                  className={Styles.titleSuccess}
                  value={currentContract?.totalInvoiced}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  fixedDecimalScale={true}
                  decimalScale={0}
                />
              </dt>
              <dd>
                <NumberFormat
                  className={Styles.title}
                  value={Number(currentContract?.totalProjected) - Number(currentContract?.totalInvoiced)}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  fixedDecimalScale={true}
                  decimalScale={0}
                />
              </dd>
            </dl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContractDetailStatistics;
