const CellType = {
  EMPTY_CELL: {
    value: "EmptyCell",
  },
  TOTAL_AND_UTILITY_PERCENTAGE: {
    value: "TotalAndUtilityPercentage",
  },
  TOTAL_FOR_CLIENTS_WITHOUT_QUOTATION: {
    value: "TotalForClientWithoutQuotation",
  },
  UTILITY_BEFORE_TAX: {
    value: "UtilityBeforeTax",
  },
  UTILITY_BEFORE_TAX_PERCENTAGE: {
    value: "UtilityBeforeTaxPercentage"
  },
  TOTAL_AND_UTILITY: {
    value: "TotalAndUtility",
  },
  CONTRACTS_PERCENTAGE: {
    value: "ContractsPercentage"
  },
  CONTRACTS: {
    value: "Contracts"
  },
  ITEMS_PERCENTAGE: {
    value: "ItemsPercentage"
  },
  ITEMS: {
    value: "Items"
  },
  GROUPS_PERCENTAGE: {
    value: "GroupsPercentage"
  },
  CONTROL_PERCENTAGE: {
    value: "ControlPercentage"
  },
  PREVIOUS_CONTROL: {
    value: "PreviousControl"
  },
  GROUPS: {
    value: "Groups"
  },
};

export default CellType;
