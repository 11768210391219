import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Context from '../../../../Context';
import agent from '../../../../config/config';
import { Role } from '../../../../helpers';
import {
  DashboardRoutes,
  ProjectRoutes,
} from '../../../../routes/local/routes';

const noEmptyValidation = (value) =>
  value && value.trim().length > 0 ? false : 'No puede estar vacío este campo';

const validations = {
  email: (value) => {
    let emailExp =
      /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const pattern = new RegExp(emailExp);
    return !pattern.test(value) ? 'Debe ser un email válido' : false;
  },
  name: (value) => noEmptyValidation(value),
  password: (password, comparePassword) => {
    const error = 'Las contraseñas no coinciden';
    const empty = noEmptyValidation(password);
    if (empty) return error;
    return password && comparePassword === password ? false : error;
  },
};

const useCreateUserFrom = ({ projects, companies, createUser }) => {
  const history = useHistory();

  const { loadSessionCompany, sessionCompany } = useContext(Context.Shared);

  const [company, setCompany] = useState('');
  const [formatCompanies, setFormatCompanies] = useState([]);
  const [currentCompany, setCurrentCompany] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [companyProjects, setCompanyProjects] = useState([]);
  const [isInvalidForm, setIsInvalidForm] = useState(true);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [formValues, setFormValues] = useState({
    role: Role.Sales,
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const companyId = sessionCompany || loadSessionCompany();
    if (!companyId && !agent.isAuthorized([Role.Super]))
      return history.push(DashboardRoutes.base + ProjectRoutes.base);
    setCurrentCompany(companyId);
  }, []);

  useEffect(() => {
    if (company?.id && projects)
      setCompanyProjects(
        projects.filter((project) => project.companyId === company?.id),
      );
    else setCompanyProjects([]);
  }, [company]);

  useEffect(() => {
    const format = companies.map((company) => ({
      id: company.id,
      name: company.name,
    }));
    setFormatCompanies(format);
  }, [companies]);

  useEffect(() => {
    if (formValues.role !== Role.Admin && !companyProjects.length) {
      const company = formatCompanies.find(
        (company) => company.id === currentCompany,
      );
      setCompany(company);
    }
  }, [formValues.role, companyProjects, formatCompanies]);

  useEffect(() => {
    change('project')(null, companyProjects);
  }, [companyProjects]);

  useEffect(() => {
    if (formValues.role === Role.Admin)
      setFormatCompanies(
        formatCompanies.concat({
          name: 'Usario sin compañia',
          id: '__',
        }),
      );
    else if (formatCompanies.length > 0)
      setFormatCompanies(formatCompanies.filter((e) => e.id !== '__'));
  }, [formValues.role]);

  useEffect(() => {
    setIsInvalidForm(
      Object.keys(errors).filter((e) => typeof errors[e] === 'string').length,
    );
  }, [errors]);

  const change = (name) => {
    const onChange = (event) => {
      const value = event && event.target ? event.target.value : false;
      if (name)
        setFormValues({
          ...formValues,
          [name]: value,
        });
      applyValidations(name, value);
    };
    return onChange;
  };

  const applyValidations = (name, value) => {
    if (
      (name === 'password' || name === 'confirmPassword') &&
      validations['password']
    )
      setErrors({
        ...errors,
        password: validations['password'](
          value,
          name === 'password'
            ? formValues.confirmPassword
            : formValues.password,
        ),
      });
    else if (validations[name]) {
      setErrors({
        ...errors,
        [name]: validations[name](value),
      });
    }
  };

  const onCompanyChange = (e) => {
    if (e && e.target && e.target.value) setCompany(e.target.value);
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    createUser({ ...formValues, project: companyProjects });
  };

  return {
    onCompanyChange,
    isInvalidForm,
    change,
    companyProjects,
    showPassword,
    toggleShowPassword,
    errors,
    formatCompanies: [...formatCompanies],
    company,
    handleSubmit,
    ...formValues,
  };
};

export default useCreateUserFrom;
