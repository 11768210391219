import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@material-ui/core';
import classes from '../../styles/ResultsStateHeaderCustomCell.module.scss';
import { useSelector } from 'react-redux';

const ResultsStateHeaderCustomCell = (props) => {
  const { saveBudgetState } = useSelector((state) => state.cashFlow.root) || {};
  const { ...restProps } = props;
  const { tableColumn } = props;
  const { column } = tableColumn;
  const { title, name } = column;

  const controlPercentage = 'controlPercentage'
  const previousControl = 'previousControl';

  const virtualTableCell = [
    classes.headerCell, 
    name === previousControl && saveBudgetState === false ? classes.errorLeftLine : '',
    name === previousControl ? classes.leftLine : '',
    name === controlPercentage && saveBudgetState === false ? classes.errorRightLine : '',
    name === controlPercentage ? classes.rightLine : ''
  ]

  return (
    <VirtualTable.Cell {...restProps} className={virtualTableCell}>
      <Typography variant="body2" className={classes.headerTypography}>
        {title}
      </Typography>
    </VirtualTable.Cell>
  );
};

export default ResultsStateHeaderCustomCell;
