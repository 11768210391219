import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import ProviderColumnType from '../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { AreaTypeStates } from './customHooks/useAreasSaveChanges';
import { AreaColumnType } from './interfaces/AreaColumnType';
import { IAreaCustomColumn } from './interfaces/IAreaCustomColumn';
import IPropertyArea from './interfaces/IPropertyArea';

interface CellValue<T> {
  [key: string]: T;
}

type RowCellValueFunc<T, R> = (row: CellValue<T>, columnName: string) => R;
type CellValueFunc<T, R> = (row: T, columnName: string) => R;

export class AreasTableCustomColumn {
  static getMeasureValue: RowCellValueFunc<number, number> = (row, columnName) => {
    return row[columnName] ?? 0;
  };

  static getColumns = (customColumns: IAreaCustomColumn[]): AreaColumnType[] =>
    customColumns.map((customColumn) => ({
      name: `${customColumn.id}`,
      title: `${customColumn.name} ${customColumn.unit}`,
      type: ProviderColumnType.numeric,
      getCellValue: AreasTableCustomColumn.getMeasureValue,
    }));

  static getGroupName: CellValueFunc<IPropertyArea, string> = (row, columnName) => {
    return row[columnName]?.name ?? 'Sin grupo';
  };

  private filterAreaTypesWithoutStateRemoved = (customColumns: IAreaCustomColumn[]) =>
    customColumns.filter((areaType) => areaType.state !== AreaTypeStates.REMOVED);

  getCustomColumnsIds = (customColumns: IAreaCustomColumn[]): string[] =>
    this.filterAreaTypesWithoutStateRemoved(customColumns).map((column) => `${column.id}`);

  getDefaultLeftColumns = (
    customColumns: IAreaCustomColumn[],
    extraColumns: AreaColumnType[] = [],
  ): AreaColumnType[] => [
    { name: 'name', title: 'Nomenclatura' },
    { name: 'group', title: 'Grupos', getCellValue: AreasTableCustomColumn.getGroupName },
    ...AreasTableCustomColumn.getColumns(this.filterAreaTypesWithoutStateRemoved(customColumns)),
    ...extraColumns,
  ];

  getColumnExtentions = (
    customColumns: IAreaCustomColumn[],
    extraColumnsExtentions: VirtualTable.ColumnExtension[] = [],
  ): VirtualTable.ColumnExtension[] => {
    return customColumns
      .map<VirtualTable.ColumnExtension>((column) => {
        return {
          columnName: `${column.id}`,
          width: '200px',
        };
      })
      .concat(...extraColumnsExtentions);
  };
}
