import React, { useState, useRef } from 'react';
import { Box, Card, Grid, CardContent } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import PropTypes from 'prop-types';
import { Button } from '../../../../../shared/components/Button/Button';
import Typography from '../../../../../shared/components/Typography/Typography';
import Styles from '../../styles/BuyerCustomerTitleView.module.scss';
import GraphicMaterial from '../../../../GraphicMaterial/UI/views/dialogs/GraphicMaterialDialog';
import TodayPrice from '../../../../TodayPrice/UI/views/dialogs/TodayPriceDialog';
import useDownloadDocuments from '../../../Core/customHooks/useDownloadDocument';
import DocumentTemplatesMenu from './DocumentTemplatesMenu';

const BuyerCustomerTitleView = ({
  name,
  clientId,
  identityDocument,
  phoneNumber,
  email,
  property,
}) => {
  const {
    openDocumentsMenu,
    toggleDocumentsMenu,
    documents,
    downloadDocument,
  } = useDownloadDocuments(property && property.id, clientId);
  const [openGraphicMaterial, setOpenGraphicMaterial] = useState(false);
  const [openTodayPrice, setOpenTodayPrice] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = () => {
    setOpenGraphicMaterial(false);
  };

  const handleCloseTodayPrice = () => {
    setOpenTodayPrice(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4" color="gray800" fontWeight="600">
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="small"
                startIcon={<GetAppOutlinedIcon />}
                onClick={() => !openDocumentsMenu && toggleDocumentsMenu()}
                ref={anchorRef}
              >
                Descargar word
              </Button>
              <DocumentTemplatesMenu
                open={openDocumentsMenu}
                toggleMenu={toggleDocumentsMenu}
                anchorRef={anchorRef}
                documents={documents}
                downloadDocument={downloadDocument}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box pt={2}>
                <dl className={Styles.dlHorizontal}>
                  {identityDocument && (
                    <>
                      <dt>Documento</dt>
                      <dd>{identityDocument}</dd>
                    </>
                  )}
                  <dt>Teléfono</dt>
                  <dd>{phoneNumber}</dd>
                  <dt>Correo</dt>
                  <dd>{email}</dd>
                </dl>
              </Box>
            </Grid>
            <Grid item>
              <Button
                size="small"
                style={{
                  margin: '0 10px',
                }}
                onClick={() => {
                  setOpenTodayPrice(true);
                }}
              >
                Consultar valor a hoy
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setOpenGraphicMaterial(true);
                }}
              >
                Material gráfico
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {property && (
        <>
          <GraphicMaterial
            openGrapicMaterial={openGraphicMaterial}
            closeFunction={handleClose}
            propertyInfo={property}
            showAddAndDelete={false}
          />
          <TodayPrice
            open={openTodayPrice}
            closeFunction={handleCloseTodayPrice}
            property={property}
          />
        </>
      )}
    </>
  );
};

BuyerCustomerTitleView.propTypes = {
  name: PropTypes.string,
  identityDocument: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  clientId: PropTypes.string,
  property: PropTypes.object,
};

export default BuyerCustomerTitleView;
