import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { DashboardRoutes } from '../../../routes/local/routes';
import { useLocalStorage } from '../../../shared/customHooks/localStorage/useLocalStorage';
import LocalStorageEnum from '../../../shared/customHooks/localStorage/LocalStorage.enum';

const GotoSalesRoom = ({ open, towerId }) => {
  const { storedValue } = useLocalStorage(
    LocalStorageEnum.externalSourceMenu,
    '',
  );

  const subMenuType = storedValue;
  const history = useHistory();
  const gotoSalesRoom = () => {
    history.push(
      `${DashboardRoutes.base}${DashboardRoutes.clients.value}${towerId}`,
    );
  };

  const gotoSalesState = () => {
    history.push(
      `${DashboardRoutes.base}${DashboardRoutes.salesStatus.value}${towerId}`,
    );
  };

  return (
    <MuiDialog open={open}>
      <DialogTitle>ATENCIÓN</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Debes vender apartamentos para poder continuar
        </DialogContentText>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {subMenuType !== 'ExternalSource' ? (
                <Button
                  fullWidth
                  onClick={gotoSalesRoom}
                  color="primary"
                  autoFocus
                >
                  Ir a sala de ventas
                </Button>
              ) : (
                <Button
                  fullWidth
                  onClick={gotoSalesState}
                  color="primary"
                  autoFocus
                >
                  Ir a estado de ventas
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </MuiDialog>
  );
};

GotoSalesRoom.propTypes = {
  open: PropTypes.bool,
  towerId: PropTypes.string,
};

export default GotoSalesRoom;
