import React, { FC } from 'react';
import Close from '@material-ui/icons/Close';
import { Box, IconButton } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';

import styles from './CustomDrawer.module.scss';

interface Props {
  title: string;
  onClose?: () => void;
}

const CustomDrawerHeader: FC<Props> = ({ title, onClose }) => (
  <Box className={styles.Header}>
    <Typography variant="headline6">{title}</Typography>
    {onClose && (
      <IconButton className={styles.CloseButton} onClick={() => onClose()}>
        <Close />
      </IconButton>
    )}
  </Box>
);

export default CustomDrawerHeader;
