import { ChangeEvent } from 'react';

import { FeatureField } from '../interfaces/featureField.types';
import { UpdateVisibilityData } from '../interfaces/FeatureFieldAdminListItem.types';

function useUpdateFeatureFieldVisibility({ field, onUpdateVisibility }: UpdateVisibilityData) {
  const updateIsEnabled = (previousField: FeatureField, isEnabled: boolean) => ({
    ...previousField,
    isEnabled,
  });

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateVisibility(updateIsEnabled(field, event.target.checked));
  };

  return handleOnChange;
}

export default useUpdateFeatureFieldVisibility;
