import { Box, Grid } from '@material-ui/core';
import React, { Dispatch, FC, SetStateAction } from 'react';
import Typography from 'shared/components/Typography/Typography';
import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import useContractsEvalproDetails from '../../core/customHooks/useContractsEvalProDetails';
import ContractsEvalproDetailGrid from '../tables/ContractsEvalproDetailGrid';
import ContractsEvalproDetailRangeGrid from '../tables/ContractsEvalproDetailRangeGrid';
import Styles from './ContractsEvalproMain.module.scss';
import ContractsEvalproDetailPercentageGrid from '../tables/ContractsEvalproDetailPercentageGrid';

interface IContractsEvalproDetail {
  setTotalDistributed: Dispatch<SetStateAction<number>>;
}

const ContractsEvalproDetail: FC<IContractsEvalproDetail> = ({ setTotalDistributed }) => {
  const { data, isLoading, getEventTitle } = useContractsEvalproDetails();

  return (
    <Box px={0.2} py={0.5} my={2} mx={1} className={Styles.boxDetail}>
      {isLoading ? (
        <AbsoluteCircularProgress />
      ) : (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={Styles.textCenter}>
          <Grid item xl={12}>
            <Box p={0.5}>
              <Typography color="gray000" variant="subtitle1">
                Distribución del costo
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={12} className={Styles.flex}>
            {data?.map((event, index) => {
              const eventTitle = getEventTitle(event);
              const gridComponent =
                event.type === 'RANGE' ? (
                  <ContractsEvalproDetailRangeGrid
                    rows={event.contractsEvalproDetails}
                    eventTitle={eventTitle}
                    isLoading={isLoading}
                    eventId={event.id}
                  />
                ) : (
                  <ContractsEvalproDetailGrid
                    rows={event.contractsEvalproDetails}
                    eventTitle={eventTitle}
                    isLoading={isLoading}
                    event={event}
                  />
                );
              return data.length - 1 === index
                ? gridComponent
                : [gridComponent, <Box pr={1} className={Styles.separator} />];
            })}
            <ContractsEvalproDetailPercentageGrid isLoading={isLoading} setTotalDistributed={setTotalDistributed} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ContractsEvalproDetail;
