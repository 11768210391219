import { API_PATH } from '../../../config/config';

const PATH = `${API_PATH}areas`;

export class AreaServiceDefinitions {
  static areasByTower = (towerId: string) => `${PATH}/dashboard/${towerId}/areas`;
  static areasSaveBulk = (towerId: string) => `${PATH}/dashboard/bulk/${towerId}`;
  static areasUploadFile = (towerId: string) => `${PATH}/dashboard/file/${towerId}`;
  static areasTypeCheckName = (towerId: string, name: string) => `${PATH}/v2/${towerId}/area-type/check-name/${name}`;
}
