import { useState } from 'react';

import { AddTowerHandler, EditTowerHandler, TowerEditorOptions } from '../types/Stages.types';

const defaultTowerValues = {
  name: '',
  description: '',
};

const defaultOptions = {
  isEditing: false,
  stageId: -1,
  towerId: '',
  towerValues: defaultTowerValues,
};

function useStageTowerEditorModal() {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editorOptions, setEditorOptions] = useState<TowerEditorOptions>(defaultOptions);

  const handleAddNewTower: AddTowerHandler = (event) => {
    setEditorOptions({
      isEditing: false,
      stageId: event.currentTarget.dataset.stageId,
      towerId: '',
      towerValues: defaultTowerValues,
    });
    setIsEditorOpen(true);
  };

  const handleEditTower: EditTowerHandler = (options) => {
    setEditorOptions(options);
    setIsEditorOpen(true);
  };

  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  return { isEditorOpen, editorOptions, handleAddNewTower, handleEditTower, handleCloseEditor };
}

export default useStageTowerEditorModal;
