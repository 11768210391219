import StagesServices from 'App/Stages/services/StagesServices';
import { useSnackbar } from 'notistack';

import StagesDTO from '../DTO/StagesDTO';
import { DeleteTowerHandler } from '../types/Stages.types';

const services = new StagesServices();

const getSuccessMessage = (towerName: string) => `${towerName} eliminada correctamente`;

type UpdateStages = (stages: StagesDTO) => void;

type UseDeleteTowerOptions = {
  showProgress: () => void;
  hideProgress: () => void;
  updateStages: UpdateStages;
};

function useDeleteTower({ showProgress, hideProgress, updateStages }: UseDeleteTowerOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessMessage = (towerName: string) =>
    enqueueSnackbar(getSuccessMessage(towerName), {
      autoHideDuration: 6000,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });

  const requestDeleteTower = async (towerId: string, towerName: string) => {
    try {
      showProgress();
      const { data } = await services.deleteTower(towerId);
      updateStages(data);
      showSuccessMessage(towerName);
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      hideProgress();
    }
  };

  const handleDeleteTower: DeleteTowerHandler = (tower) => {
    requestDeleteTower(tower.id, tower.name);
  };

  return handleDeleteTower;
}

export default useDeleteTower;
