import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { setCurrentBuyerCustomer } from '../actions';


export function useCustomerListMapperByClients() {
  const mapCustomers = (clients, currentFilter) => {
    const propertyRows = (clients || []).reduce(
      (accumulatedProperties, client) => {
        const properties = client.properties.map((property) => {
          return {
            clientId: client.id,
            propertyId: property.id,
            title: property.name || '',
            subtitle: `${client.name || ''} ${client.middleName || ''} ${
              client.surname || ''
            } ${client.secondSurname || ''}`,
            phoneNumber: client.phoneNumber || '',
            email: client.email || '',
            identityDocument: client.identityDocument || '',
            floor: property.floor,
            location: property.location,
            quotations: property.quotations,
            basePriceValidated: property.basePriceValidated,
            discount: property.discount,
            tradeDiscount: property.tradeDiscount,
            isContact: property.clientProperty.isContact,
            priceWithAdditionalAreas: property.priceWithAdditionalAreas,
            responseQuestionOne:
              client?.clientAdditionalInfo?.responseQuestionOne || '',
          };
        });

        return [...accumulatedProperties, ...properties];
      },
      [],
    );

    const applyFilterIfNeededByProperty = (clientProperty) => {
      if (currentFilter) {
        const formatted = (string) => {
          return string.toLowerCase().replace(/\s/g, '');
        };
        const lowerCasedFilter = formatted(currentFilter);
        return (
          formatted(clientProperty.title).includes(lowerCasedFilter) ||
          formatted(clientProperty.subtitle).includes(lowerCasedFilter) ||
          formatted(clientProperty.phoneNumber).includes(lowerCasedFilter) ||
          formatted(clientProperty.identityDocument).includes(
            lowerCasedFilter,
          ) ||
          formatted(clientProperty.email).includes(lowerCasedFilter)
        );
      }

      return true;
    };

    const filterDuplicatedCustomers = _.filter(
      propertyRows,
      (property, i, iteratee) => {
        const findDuplicated =
          !_.find(
            iteratee,
            (currentProperty) =>
              currentProperty.propertyId === property.propertyId &&
              currentProperty.clientId !== property.clientId,
          ) || property.isContact;

        const applyFilterIfNeeded = applyFilterIfNeededByProperty(property);

        return findDuplicated && applyFilterIfNeeded;
      },
    );

    const sortedProperties = _.sortBy(
      filterDuplicatedCustomers,
      ['floor', 'location'],
      ['asc', 'asc'],
    );

    return sortedProperties;
  };

  return { mapCustomers };
}

function useCustomerListMapper() {
  const {
    clients,
    currentClientIsLoading,
    currentClient,
    currentPropertyInfo,
    currentFilter,
  } = useSelector((state) => state.buyerCostumers.root) || {};

  const dispatch = useDispatch();

  const { mapCustomers } = useCustomerListMapperByClients()

  const sortedProperties = mapCustomers(clients, currentFilter);

  if (
    sortedProperties.length > 0 &&
    !currentClientIsLoading &&
    !currentClient &&
    !currentPropertyInfo
  ) {
    dispatch(setCurrentBuyerCustomer(sortedProperties[0]));
  }

  return { propertyRows: sortedProperties };
}

export default useCustomerListMapper;
