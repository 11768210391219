import SalesStatusServices from '../../services/SalesStatusServices';
import {
  QueueData,
  UpdateSalesStatusError,
  UpdateSalesStatusErrorHandler,
  UpdateSalesStatusResponse,
} from '../types/SalesStatus.types';

const services = new SalesStatusServices();

class UpdateSalesStatusQueue {
  private isRequesting = false;

  private queue: Array<QueueData> = [];

  private errorHandler: UpdateSalesStatusErrorHandler = () => {};

  private readonly addData = (data: QueueData) => this.queue.splice(0, 0, data);

  private readonly hasData = (): boolean => this.queue.length > 0;

  private readonly popQueue = (): QueueData | undefined => this.queue.pop();

  private readonly clear = () => {
    this.queue = [];
  };

  private readonly handleRequestError = (data: QueueData) => (error: unknown) =>
    Promise.reject({
      error: error as Error,
      data,
    });

  private readonly requestWithErrorHandling = ({
    towerId,
    properties,
    changeData,
  }: QueueData): Promise<UpdateSalesStatusResponse> =>
    services
      .updateSalesStatus(towerId, {
        isSold: changeData.isSold,
        propertyId: changeData.property.id,
      })
      .catch(this.handleRequestError({ towerId, properties, changeData }));

  private readonly requestWithValidData = (data: QueueData | undefined): Promise<UpdateSalesStatusResponse | null> =>
    data ? this.requestWithErrorHandling(data) : Promise.resolve(null);

  private readonly requestUpdateSalesStatus = async (): Promise<UpdateSalesStatusResponse | null> => {
    let response: UpdateSalesStatusResponse | null = null;
    this.isRequesting = true;

    while (this.hasData()) {
      const data = this.popQueue();
      response = await this.requestWithValidData(data);
    }

    this.isRequesting = false;

    return response;
  };

  private readonly tryRequestMoveTower = async (): Promise<UpdateSalesStatusResponse | null> => {
    let response: UpdateSalesStatusResponse | null = null;

    try {
      response = await this.requestUpdateSalesStatus();
    } catch (error) {
      this.clear();
      this.errorHandler(error as UpdateSalesStatusError);
    }

    return response;
  };

  onError(errorHandler: UpdateSalesStatusErrorHandler) {
    this.errorHandler = errorHandler;
  }

  updateSalesStatus(data: QueueData): Promise<UpdateSalesStatusResponse | null> {
    this.addData(data);

    return this.isRequesting ? Promise.resolve(null) : this.tryRequestMoveTower();
  }
}

export default UpdateSalesStatusQueue;
