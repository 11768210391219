import { useEffect, useState } from 'react';

function useCurrentAssignedQuotation(isOpen) {
  const [currentQuotation, updateCurrentQuotation] = useState(null);

  useEffect(() => {
    updateCurrentQuotation(null);
  }, [isOpen]);

  return {
    updateCurrentQuotation,
    currentQuotation,
    quotationDiscount: currentQuotation
      ? currentQuotation.currentQuotationDiscount
      : 0,
  };
}

export default useCurrentAssignedQuotation;
