import { useMutation, useQueryClient } from 'react-query';
import { GeneralWalletDetailsService } from '../../services/GeneralWalletDetailsService';
import { GENERAL_WALLET_THREADS } from '../constants/GeneralWalletDetailsReactQuery';
import { useGeneralWalletThreadContext } from '../contexts/GeneralWalletThreadContext';
import { GeneralWalletThreadInteractionDTO } from '../DTO/GeneralWalletComment.dto';

const service = new GeneralWalletDetailsService();

const useGeneralWallletDetailsThreadInteractionComment = (props: GeneralWalletThreadInteractionDTO) => {
  const queryClient = useQueryClient();
  const { handlePopoverOpen, closeEditInteraction, isEditing, interaction } = useGeneralWalletThreadContext();

  const mutation = useMutation((value: string) => service.updateInteractionComment(props.id, value), {
    onSuccess: () => {
      queryClient.invalidateQueries([GENERAL_WALLET_THREADS]);
      closeEditInteraction();
    },
    onError: () => {
      closeEditInteraction();
    },
  });

  const handleChange = (value: string | File) => {
    if (typeof value === 'string') {
      mutation.mutate(value);
    } else closeEditInteraction();
  };

  return {
    handlePopoverOpen,
    handleChange,
    isInteractionInEdition: isEditing && interaction && interaction.id === props.id,
    ...mutation,
  };
};

export default useGeneralWallletDetailsThreadInteractionComment;
