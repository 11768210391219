import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../../../components/UI2/Loader';
import QuotationClient from './Client';
import CustomQuotationProperty from './Property/CustomQuotationProperty';
import QuotationPayments from './Payments';
import Context from './context';
import useOpenQuotationDialogDidMount from './helpers/customHooks/useOpenQuotationDialogDidMount';
import useAssignmentQuotation from './helpers/customHooks/useAssignmentQuotation';
import useExcelExpanded from './helpers/customHooks/useExpanded';
import QuotationFunctions from './helpers/quotationFunctions';
import QuotationDiscountSummary from './Payments/EditDashboard/QuotationDiscountSummary';
import Numbers from '../../../../helpers/numbers';
import useEnableQuotationDiscount from './helpers/customHooks/useEnableQuotationDiscount';
import BankFinancialDetailForQuotation from '../../../Quotation/UI/views/accordion/BankFinancialDetailForQuotation';
import ConfirmDialog from '../../../../components/StrategyV2/Overviews/Graph/Controls/Strategies/ConfirmDialog/ConfirmDialog';
import AssignedPayments from './Payments/AssignedPayments';
import useAssignedPlanDiscountGoalSeek from '../../Core/customHooks/useAssignedPlanDiscountGoalSeek';
import useSaveAssignedCopy from '../../Core/customHooks/useSaveAssignedCopy';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  redButton: {
    background: theme.palette.secondary.main,
    color: 'white',
  },
  greenButton: {
    background: '#00a3ab',
    color: 'white',
  },
}));

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({
  isEditingReal,
  id,
  open,
  closeHandler,
  fetchUserList,
  onSave,
}) => {
  const {
    loading,
    quotation,

    setLoadedDiscount,
    fetch,
    setIsOpen,
    handleReservePercentageChange,
    handleFinalFeeChange,
    handleDistributionChange,
    dispatchFetchQuotationSuccess,
    handleIndexSelection,
    handleEditionTypeChange,
    handleEditionInitialDate,
    handleEditionDiscountType,
    getQuotationPDF,
  } = useOpenQuotationDialogDidMount(id, closeHandler, isEditingReal);

  const {
    onAssignmentQuotation,
    isConfirmDialogOpen,
    onConfirmAssignment,
    onCancelAssignment,
  } = useAssignmentQuotation(fetchUserList, closeHandler);

  const { isEditable, setIsEditable, isExpanded, setExpansion } =
    useExcelExpanded();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const {
    enableDiscountCalculation,
    onChangeType,
    onChangeManualQuotationDiscount,
  } = useEnableQuotationDiscount(
    quotation,
    dispatchFetchQuotationSuccess,
    handleEditionDiscountType,
  );

  const { onClickDiscount, enableDiscount } = useAssignedPlanDiscountGoalSeek(
    quotation,
    enableDiscountCalculation,
    dispatchFetchQuotationSuccess,
    setLoadedDiscount,
  );

  const propertyPrice =
    quotation.priceWithAdditionalAreas -
    quotation.discount -
    quotation.discountPrice;

  const oldPropertyPrice =
    quotation.old.priceWithAdditionalAreas -
    quotation.old.discount -
    quotation.old.discountPrice;

  const classes = useStyles();

  const { onSaveButtonClick } = useSaveAssignedCopy(closeHandler, quotation);

  return (
    <MuiDialog
      id="box_dialog_quotations"
      fullScreen
      open={open}
      TransitionComponent={Transition}
    >
      <Context.Provider
        value={{
          quotation,
          finalFeeHandler: handleFinalFeeChange,
          reserveHandler: handleReservePercentageChange,
          distributionHandler: handleDistributionChange,
          updateQuotation: dispatchFetchQuotationSuccess,
          updateDistributionIndex: handleIndexSelection,
          handleEditionTypeChange,
          onClickDiscount,
          handleEditionInitialDate,
        }}
      >
        <ConfirmDialog
          open={isConfirmDialogOpen}
          title="¿Esta seguro que quiere asignar esta cotización como plan de pagos?"
          content={`Se eliminara el resto de cotizaciones para esta propiedad. 
          Recuerde: tiene un ${
            quotation.discountPrice > 1 ? 'descuento' : 'incremento'
          } financiero de ${Numbers.currencyFormat(
            Math.abs(quotation.discountPrice),
          )}`}
          handleAccept={() => onConfirmAssignment(quotation, setIsOpen)}
          handleClose={onCancelAssignment}
        />
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Forma de pago
            </Typography>
            <Button autoFocus onClick={getQuotationPDF} color="inherit">
              Obtener PDF
            </Button>
            {quotation.temp && quotation.isAssigned && (
              <Button
                autoFocus
                className={classes.greenButton}
                variant="contained"
                disableElevation
                onClick={onSaveButtonClick}
              >
                Guardar
              </Button>
            )}
            {!quotation.temp && quotation.isPaymentEdition && (
              <Button
                autoFocus
                className={classes.greenButton}
                variant="contained"
                disableElevation
                onClick={onAssignmentQuotation}
              >
                Asignar como forma de pago
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Loader isLoading={loading}>
          <Box p={3}>
            {quotation && (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <QuotationClient
                    client={quotation.client}
                    projectName={`${quotation.property.tower.project.name} ${quotation.property.tower.name}`}
                    propertyName={quotation.property.name}
                    date={quotation.createdAt}
                  />
                  {/* <ImportFromExcelView
                    isExpanded={isExpanded}
                    setExpansion={setExpansion}
                  /> */}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <QuotationDiscountSummary
                    realPrice={Number(quotation.propertyPrice)}
                    discountPrice={Number(quotation.discountPrice)}
                    discountPercentage={Number(quotation.discountPercentage)}
                    priceWithDiscount={Number(
                      quotation.priceWithAdditionalAreas -
                        quotation.discount -
                        quotation.discountPrice,
                    )}
                    propertyPrice={quotation.propertyPrice}
                    onChangeType={onChangeType}
                    discountType={quotation.discountType}
                    isEdition={isEditable}
                    onChangeManualQuotationDiscount={
                      onChangeManualQuotationDiscount
                    }
                  />
                  <BankFinancialDetailForQuotation
                    quotation={quotation}
                    updateQuotation={dispatchFetchQuotationSuccess}
                    isExpanded={isExpanded}
                    setExpansion={setExpansion}
                    initialFeePercentage={quotation.initialFeePercentage}
                    finalFee={QuotationFunctions.finalFeePercentage(
                      quotation.finalPayment,
                      quotation.priceWithAdditionalAreas -
                        quotation.discount -
                        quotation.discountPrice,
                    )}
                    finalPrice={quotation.finalPayment}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <CustomQuotationProperty
                    name="Forma de pago actual"
                    priceWithDiscount={oldPropertyPrice}
                    propertyPrice={quotation.old.priceWithAdditionalAreas}
                    basePrice={
                      quotation.old.propertyWithAreas.basePriceValidated
                    }
                    additionalAreas={
                      quotation.old.propertyWithAreas.additionalAreas
                    }
                    commercialPrice={
                      quotation.old.priceWithAdditionalAreas -
                      quotation.old.discount
                    }
                    totalArea={quotation.old.propertyWithAreas.totalArea}
                    privateArea={quotation.old.propertyWithAreas.privateArea}
                    commercialDiscount={quotation.old.discount}
                    discountPrice={quotation.old.discountPrice}
                    discountType={quotation.old.discountType}
                    discount={quotation.old.discount}
                    initialFeePercentage={QuotationFunctions.initialFeePercentage(
                      quotation.old.finalPayment,
                      oldPropertyPrice,
                    )}
                    initialFeePrice={QuotationFunctions.initialFeePrice(
                      quotation.old.finalPayment,
                      oldPropertyPrice,
                    )}
                    periods={quotation.old.periods}
                    monthlyFeesPercentage={QuotationFunctions.monthlyFeePercentageWithPrices(
                      quotation.old.finalPayment,
                      oldPropertyPrice,
                      quotation.old.reservePercentage,
                    )}
                    monthlyFeesPrice={QuotationFunctions.monthlyFeesPriceWithPrices(
                      quotation.old.finalPayment,
                      oldPropertyPrice,
                      quotation.old.reservePercentage,
                    )}
                    reservePercentage={QuotationFunctions.reserveFeePercentage(
                      quotation.old.reservePercentage,
                      oldPropertyPrice,
                    )}
                    reservePrice={quotation.old.reservePercentage}
                    finalFee={QuotationFunctions.finalFeePercentage(
                      quotation.old.finalPayment,
                      oldPropertyPrice,
                    )}
                    finalPrice={quotation.old.finalPayment}
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <CustomQuotationProperty
                    name="Forma de pago propuesta"
                    priceWithDiscount={propertyPrice}
                    propertyPrice={quotation.priceWithAdditionalAreas}
                    basePrice={quotation.propertyWithAreas.basePriceValidated}
                    additionalAreas={
                      quotation.propertyWithAreas.additionalAreas
                    }
                    commercialPrice={
                      quotation.priceWithAdditionalAreas - quotation.discount
                    }
                    totalArea={quotation.propertyWithAreas.totalArea}
                    privateArea={quotation.propertyWithAreas.privateArea}
                    commercialDiscount={quotation.discount}
                    discountPrice={quotation.discountPrice}
                    discountType={quotation.discountType}
                    discount={quotation.discount}
                    initialFeePercentage={QuotationFunctions.initialFeePercentage(
                      quotation.finalPayment,
                      propertyPrice,
                    )}
                    initialFeePrice={QuotationFunctions.initialFeePrice(
                      quotation.finalPayment,
                      propertyPrice,
                    )}
                    periods={quotation.periods - 1}
                    monthlyFeesPercentage={QuotationFunctions.monthlyFeePercentageWithPrices(
                      quotation.finalPayment,
                      propertyPrice,
                      quotation.distribution[0],
                    )}
                    monthlyFeesPrice={QuotationFunctions.monthlyFeesPriceWithPrices(
                      quotation.finalPayment,
                      propertyPrice,
                      quotation.distribution[0],
                    )}
                    reservePrice={quotation.distribution[0]}
                    reservePercentage={QuotationFunctions.reserveFeePercentage(
                      quotation.distribution[0],
                      propertyPrice,
                    )}
                    finalFee={QuotationFunctions.finalFeePercentage(
                      quotation.finalPayment,
                      propertyPrice,
                    )}
                    finalPrice={quotation.finalPayment}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <QuotationPayments
                    dispatchFetchQuotationSuccess={
                      dispatchFetchQuotationSuccess
                    }
                    quotation={quotation.old}
                    fetch={fetch}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    showEditableButtons
                    enableDiscountCalculation={enableDiscountCalculation}
                    enableDiscount={enableDiscount}
                    onClickDiscount={onClickDiscount}
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <AssignedPayments
                    dispatchFetchQuotationSuccess={
                      dispatchFetchQuotationSuccess
                    }
                    quotation={quotation}
                    fetch={fetch}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    showEditableButtons
                    enableDiscountCalculation={enableDiscountCalculation}
                    enableDiscount={enableDiscount}
                    onClickDiscount={onClickDiscount}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Loader>
      </Context.Provider>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  isEditingReal: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  fetchUserList: PropTypes.func,
  onSave: PropTypes.func,
  tempQuotation: PropTypes.object,
};

export default Dialog;
