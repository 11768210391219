import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import MaterialIcons from '../../../UI2/MaterialTableIcons';
import styles from '../../TowerBuilder.module.scss';

const BuilderTableManual = ({
  manualDates,
  rowData,
  addRow,
  updateRow,
  deleteRow,
  updateCell,
}) => {
  const currencyFormat = (props) => (
    <NumberFormat
      required
      placeholder={props.columnDef.title}
      className={styles.currencyField}
      value={props.value}
      customInput={TextField}
      thousandSeparator
      prefix={'$'}
      allowNegative={false}
      decimalSeparator={false}
      onValueChange={(e) => props.onChange(e.value)}
    />
  );
  const currencyFormatRender = (row, name) => (
    <NumberFormat
      required
      value={Number(row[name])}
      thousandSeparator
      prefix={'$'}
      className={styles.currencyFieldRender}
      displayType={'text'}
      allowNegative={false}
      decimalSeparator={false}
      renderText={(value) => <div>{value}</div>}
    />
  );
  const [columns, setColumns] = useState([
    {
      field: 'name',
      title: 'Nombre',
      width: 150,
    },
    {
      field: 'accumulated',
      width: 150,
      title: 'Acumulado',
      editable: 'never',
      initialEditValue: 0,
      render: (row) => currencyFormatRender(row, 'accumulated'),
    },
    {
      field: 'projected',
      width: 150,
      title: 'Proyectado',
      editable: 'never',
      initialEditValue: 0,
      render: (row) => currencyFormatRender(row, 'projected'),
    },
    {
      field: 'total',
      width: 150,
      title: 'Total',
      editable: 'never',
      initialEditValue: 0,
      render: (row) => currencyFormatRender(row, 'total'),
    },
  ]);

  useEffect(() => {
    const dates = manualDates.flatMap((date, index) => ({
      field: `${index}`,
      title: date,
      editComponent: currencyFormat,
      width: 150,
      render: (row) => currencyFormatRender(row, `${index}`),
      initialEditValue: 0,
    }));
    setColumns([...columns, ...dates]);
  }, [manualDates]);

  return (
    <Grid container direction="row" className={styles.gridContainerComplete}>
      <MaterialTable
        className={styles.gridContainerTable}
        minRows={0}
        icons={MaterialIcons}
        columns={columns}
        data={rowData}
        editable={{
          onRowAdd: addRow,
          onRowUpdate: updateRow,
          onRowDelete: deleteRow,
        }}
        cellEditable={{
          onCellEditApproved: updateCell,
        }}
        options={{
          showTitle: false,
          rowStyle: {
            backgroundColor: '#FFF',
          },
          pageSize: 5,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Parece que aún no has puesto datos 🧐',
            addTooltip: 'Agregar',
            deleteTooltip: 'Borrar registro',
            editTooltip: 'Editar registro',
            filterRow: {
              filterTooltip: 'Flitrar',
            },
            editRow: {
              deleteText: '¿Estás seguro de borrar esta fila?',
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Guardar',
            },
          },
          header: {
            actions: '',
          },
          toolbar: {
            searchTooltip: 'Buscar',
            addRemoveColumns: 'Agregar o quitar columnas',
            searchPlaceholder: 'Buscar',
          },
          pagination: {
            labelRowsSelect: 'filas',
            labelDisplayedRows: '{count} de {from}-{to}',
            firstTooltip: 'Primera pág.',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
        style={{ width: '65vw' }}
      />
    </Grid>
  );
};

BuilderTableManual.propTypes = {
  manualDates: PropTypes.array.isRequired,
  manualDatesValues: PropTypes.array.isRequired,
  addRow: PropTypes.func,
  updateRow: PropTypes.func,
  updateCell: PropTypes.func,
  deleteRow: PropTypes.func,
  rowData: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  manualDates: state.towerBuilder.builder.manualDates,
  rowData: state.towerBuilder.builder.rowData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderTableManual);
