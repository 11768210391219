import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import PricesCard from './Prices/PricesCard';
import AreasCard from './Areas/AreasCard';
import SaleCard from './Sale/SaleCard';
import AdditionalAreasCard from './Areas/AdditionalAreasCard';
import PropertyFiles from './PropertyFiles';

const Details = ({
  property,
  clientId,
  propertyId,
  propertyFiles,
  clientResponseQuestionOne,
}) => {
  return (
    <>
      {property && (
        <>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={12} md={12} lg={8} style={{ display: 'flex' }}>
              <PricesCard property={property} />
            </Grid>
            <Grid item xs={12} md={12} lg={4} style={{ display: 'flex' }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={12}>
                  <AreasCard property={property} />
                </Grid>
                <Grid item xs={6} sm={12}>
                  <SaleCard
                    property={property}
                    clientResponseQuestionOne={clientResponseQuestionOne}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AdditionalAreasCard property={property} />
            </Grid>
            <Grid item xs={12}>
              <PropertyFiles
                clientId={clientId}
                propertyId={propertyId}
                propertyFiles={propertyFiles}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Details.propTypes = {
  property: PropTypes.shape({
    basePrice: PropTypes.number,
    additionalAreasPrice: PropTypes.number,
    totalArea: PropTypes.number,
    pricePerM2: PropTypes.number,
    pricePerM2WithAdditionalAreas: PropTypes.number,
    areas: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        price: PropTypes.number,
        measure: PropTypes.number,
        unit: PropTypes.oneOf(['MT2', 'UNT']),
      }),
    ),
    additionalAreas: PropTypes.arrayOf(
      PropTypes.shape({
        nomenclature: PropTypes.string,
        price: PropTypes.number,
        measure: PropTypes.number,
        unit: PropTypes.oneOf(['MT2', 'UNT']),
      }),
    ),
  }),
  clientResponseQuestionOne: PropTypes.string,
  clientId: PropTypes.string,
  propertyId: PropTypes.number,
  propertyFiles: PropTypes.array,
};

export default Details;
