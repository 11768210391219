export default class Numbers {
  static toFixed = (
    number,
    decimal = 2,
    isPercentage = false,
    returnNumber = false,
  ) => {
    if (
      (number && number !== Infinity && !Number.isNaN(number)) ||
      number === 0
    ) {
      if (returnNumber) {
        return Numbers.cleanNumber(Number(number).toFixed(decimal));
      }
      return `${Numbers.cleanNumber(number).toFixed(decimal)}${
        isPercentage ? '% ' : ''
      }`;
    }
    if (isPercentage) {
      return '0%';
    }
    return 0;
  };

  static cleanNumber = (number) => {
    if (
      (number || Number(number)) &&
      number !== Infinity &&
      !Number.isNaN(number)
    ) {
      return Number(number);
    }
    return 0;
  };

  static currencyFormat = (number, symbol = true, decimal = 0) => {
    const currentNumber = decimal
      ? number.toFixed(decimal)
      : Math.ceil(Numbers.cleanNumber(number));
    return `${symbol ? '$ ' : ''}${Intl.NumberFormat('en-US').format(
      currentNumber,
    )}`;
  };

  /**
   * @param {string} stringNumber
   */
  static parseLocaleNumber = (stringNumber) => {
    const thousandSeparator = Intl.NumberFormat('en-US')
      .format(11111)
      .replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat('en-US')
      .format(1.1)
      .replace(/\p{Number}/gu, '');

    return Numbers.cleanNumber(
      parseFloat(
        stringNumber
          .replace('$', '')
          .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
          .replace(new RegExp('\\' + decimalSeparator), '.'),
      ),
    );
  };

  static floor = (number) => {
    if ((number % 1) * 100 > 98) {
      return Numbers.cleanNumber(Math.round(number));
    }

    return Math.floor(number);
  };
}
