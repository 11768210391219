import { useCallback, useState } from 'react';
import moment from 'moment';
import CashFlowRowChangeValue, {
  CashFlowRowChangeValueType,
} from '../../../TotalCashFlow/Core/models/CashFlowRowChangeValue';
import { FORMAT_YEAR_MONTH2 } from '../../../../helpers/dates';

export function anchorPositionByAnchorEl(event) {
  const elementDetailedPosition = event.currentTarget.getBoundingClientRect();
  const anchorPosition = {
    left: elementDetailedPosition.left + elementDetailedPosition.width / 2,
    top: elementDetailedPosition.top + elementDetailedPosition.height,
  };
  return anchorPosition;
}

function usePopoverForCashflowTable(onRowChange) {
  const [anchorElement, setAnchorElement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [date, updateDate] = useState(null);
  const [columnAndRow, updateColumnAndRow] = useState(null);

  // TODO: Update date with service and add new info
  const onSelect = useCallback(
    (row, columnName) => (event) => {
      event.preventDefault();
      const anchorPosition = anchorPositionByAnchorEl(event);
      setAnchorElement(anchorPosition);
      setIsOpen(true);
      const defaultDateForPayments = moment(columnName, FORMAT_YEAR_MONTH2)
        .endOf('month')
        .valueOf();
      const currentTimestamp = row.timestamps[columnName]?.dateValue;
      updateDate(currentTimestamp || defaultDateForPayments);
      updateColumnAndRow({ column: columnName, row });
    },
    [],
  );

  const updateDateWithEvent = (momentDate) => {
    const newDate = momentDate.valueOf();
    updateDate(newDate);
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
    setAnchorElement(null);
    onRowChange(
      columnAndRow.row.id,
      columnAndRow.column,
      new CashFlowRowChangeValue(
        date,
        CashFlowRowChangeValueType.timestamp.code,
        new CashFlowRowChangeValue(columnAndRow.row[columnAndRow.column]),
      ),
    );
  }, [columnAndRow, date]);

  const idForPopover = isOpen ? 'simple-popover-id-for-anchor' : undefined;

  return {
    anchorElement,
    isOpen,
    idForPopover,
    editingDate: date,
    onSelect,
    onClose,
    updateDateWithEvent,
    date,
  };
}

export default usePopoverForCashflowTable;
