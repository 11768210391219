import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Slide,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { SegmentedControl } from 'segmented-control';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import usePaymentSegmentedControlState, {
  PAYMENT_SEGMENT,
  MONTHLY_SEGMENT,
  CLIENT_HUB_SEGMENT,
} from '../Core/customHooks/usePaymentSegmentedControlState';
import useRealPaymentsForPropertyRequest from '../Core/customHooks/useRealPaymentsForPropertyRequest';
import ClientHubSegmentedContainer from './views/customViews/segments/ClientHubSegmentedContainer';
import QuotationSummaryMainView from '../../QuotationSummary/UI/QuotationSummaryMainView';
import Styles from './RealPaymentsForPropertyMainView.module.scss';
import Colors from '../../../assets/styles/settings/colors.module.scss';
import Typography from '../../../shared/components/Typography/Typography';
import MonthlyInterestContent from 'App/MonthlyInterestReport/UI/components/MonthlyInterestContent/MonthlyInterestContent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RealPaymentsForPropertyMainView = ({
  isOpen,
  propertyId,
  clientId,
  onCloseHandler,
}) => {
  const { updateSegment, currentSegment } = usePaymentSegmentedControlState();
  const { isLoading } = useRealPaymentsForPropertyRequest(propertyId);

  return (
    <Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCloseHandler}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={Styles.content}>
        <Box mt="46px">
          <Typography className={Styles.title} variant="headline3">
            Informes de cartera
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb="24px"
          >
            <SegmentedControl
              name="SegmentedControl-payment"
              options={[
                {
                  label: 'Hub del cliente',
                  value: CLIENT_HUB_SEGMENT,
                  default: true,
                },
                // { label: 'Resumen Mensual', value: MONTHLY_SEGMENT },
                { label: 'Resumen Cuotas', value: PAYMENT_SEGMENT },
              ]}
              variant="base"
              style={{
                color: Colors.primary600,
                width: '573px',
                borderRadius: '10px',
              }}
              setValue={async (newValue) => updateSegment(newValue)}
            />
          </Box>
        </Box>

        {/* {currentSegment === MONTHLY_SEGMENT && (
          <MonthlyPaymentsSummarySegmentedContainer propertyId={propertyId} />
        )} */}

        {currentSegment === PAYMENT_SEGMENT && (
          <div className={Styles.paymentSegmentContainer}>
            <MonthlyInterestContent
              propertyId={propertyId}
              isOpen={isOpen}
              hasToolbar={false}
            />
          </div>
        )}

        {currentSegment === CLIENT_HUB_SEGMENT && (
          <ClientHubSegmentedContainer propertyId={propertyId} />
        )}
      </DialogContent>
    </Dialog>
  );
};

RealPaymentsForPropertyMainView.propTypes = {
  isOpen: PropTypes.bool,
  propertyId: PropTypes.number,
  clientId: PropTypes.string,
  onCloseHandler: PropTypes.func,
};

export default RealPaymentsForPropertyMainView;
