import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useFilterSubscribe from '../../Core/CustomHooks/useFilterSubscribe';
import styles from './SelectsSquare.module.scss';

export default function SelectsSquare({ name, options }) {
  const salesRoomFilter = useSelector((state) => state.salesRoomFilter);
  const [selectedOption, setSelectedOption] = useState(
    salesRoomFilter[name] || 'none',
  );

  const { subscribeFilter } = useFilterSubscribe();

  const handleSelectOption = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== 'none') {
      subscribeFilter(name, e.target.value);
    } else {
      subscribeFilter(name, '');
    }
  };

  return (
    <FormControl fullWidth className={styles.container} variant="outlined">
      <Select value={selectedOption} onChange={handleSelectOption} fullWidth>
        <MenuItem value="none">---NINGUNA---</MenuItem>
        {options.map((option) => {
          const code =
            name === 'availability' || name === 'group'
              ? option.code
              : name === 'propertyTypes'
              ? option.id
              : option;
          const val =
            name === 'availability' || name === 'group'
              ? option.value
              : name === 'propertyTypes'
              ? option.name
              : option;
          return (
            <MenuItem value={code} key={code}>
              {val}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectsSquare.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};
