import React from 'react';

import { Button } from 'shared/components/Button/Button';
import ButtonGroup from 'shared/components/ButtonGroup/ButtonGroup';
import SubMenuTypes from '../enums/SubMenuTypes';
import { ControlClickHandler } from '../types/sidebar.types';

import styles from './SideMenuControl.module.scss';

type Props = {
  subMenuType: SubMenuTypes;
  onControlClick: ControlClickHandler;
};

const SideMenuControl: React.FC<Props> = ({ subMenuType, onControlClick }) => (
  <div className={styles.root}>
    <ButtonGroup size="small" currentIndex={subMenuType}>
      <Button className={styles.controlButton} data-index={SubMenuTypes.Execution} onClick={onControlClick}>
        Ejecución
      </Button>
      <Button className={styles.controlButton} data-index={SubMenuTypes.Evalpro} onClick={onControlClick}>
        Evalpro
      </Button>
      <Button className={styles.controlButton} data-index={SubMenuTypes.ExternalSource} onClick={onControlClick}>
        Fuente ext
      </Button>
    </ButtonGroup>
  </div>
);

export default SideMenuControl;
