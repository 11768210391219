import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const AbsoluteCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: ${(props) => 50}%;
  top: ${(props) => 50}%;
  z-index: 1000;
`;

const AbsoluteCircularProgressContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
`;

const CustomProgress = () => {
  return (
    <AbsoluteCircularProgressContainer>
      <AbsoluteCircularProgress />
    </AbsoluteCircularProgressContainer>
  );
};

export default CustomProgress;
