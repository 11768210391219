export const OPEN_BUILDER_DIALOG = 'OPEN_BUILDER_DIALOG';
export const CHANGE_TABLE_WATCHER = 'CHANGE_TABLE_WATCHER';
export const CHANGE_PROJECT_DATA = 'CHANGE_PROJECT_DATA';
export const CHANGE_TOWERS_ARRAY = 'CHANGE_TOWERS_ARRAY';
export const SET_CURRENT_TOWER = 'SET_CURRENT_TOWER';
export const FETCH_LOADER = 'FETCH_LOADER';
export const RESET_SELECTOR = 'RESET_SELECTOR';

export const setOpen = (payload) => ({
  type: OPEN_BUILDER_DIALOG,
  payload,
});

export const setLoading = (payload) => ({
  type: FETCH_LOADER,
  payload,
});

export const setTableWatcher = (payload) => ({
  type: CHANGE_TABLE_WATCHER,
  payload,
});

export const setProject = (payload) => ({
  payload,
  type: CHANGE_PROJECT_DATA,
});
export const setTowers = (payload) => ({
  payload,
  type: CHANGE_TOWERS_ARRAY,
});

export const setCurrentTower = (payload) => ({
  payload,
  type: SET_CURRENT_TOWER,
});

export const setResetSelector = () => ({
  type: RESET_SELECTOR,
});
