import { useState } from 'react';
import { DEED_EXPENSES_TABS, FUTURE_SALES, SALES_MADE } from '../constants/DeedExpensesConstants';
import { ITabOption } from '../interfaces/ITabOption';

const selectTabs =
  (selectedTab: ITabOption) =>
  (tab: ITabOption): ITabOption => ({ ...tab, selected: tab.id === selectedTab.id });

const useDeedExpensesMainContainer = () => {
  const [selectedTab, setSelectedTab] = useState(DEED_EXPENSES_TABS.find((tab) => tab.selected));
  const [tabs, setTabs] = useState(DEED_EXPENSES_TABS);

  const changeTab = (tab: ITabOption) => {
    setTabs((preTabs) => preTabs.map(selectTabs(tab)));
    setSelectedTab(() => tab);
  };

  const isFutureSales = selectedTab?.id === FUTURE_SALES;
  const isSalesMade = selectedTab?.id === SALES_MADE;

  return {
    tabs,
    isSalesMade,
    isFutureSales,
    changeTab,
  };
};

export default useDeedExpensesMainContainer;
