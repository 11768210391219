import ServiceDefinitions from '../ServicesDefinitions';

class BuilderCreditServicesDefinitions {
  static getBase() {
    return new ServiceDefinitions('builder-credit/');
  }

  static updateFlow() {
    return this.getBase().createRoute({ route: 'update/flow' });
  }

  static setup = (towerId) =>
    this.getBase().createRoute({ route: 'setup/', params: towerId });

  static updateSetup = this.getBase().createRoute({ route: 'update/setup' });

  static updateFlow = this.getBase().createRoute({ route: 'update/flow' });

  static getFlow = (towerId) =>
    this.getBase().createRoute({
      route: 'flow/',
      params: towerId,
    });
}

export default BuilderCreditServicesDefinitions;
