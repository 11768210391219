import { ITabOption } from '../interfaces/ITabOption';

export const SALES_MADE = 'SALES_MADE';
export const FUTURE_SALES = 'FUTURE_SALES';

export const DEED_EXPENSES_TABS: ITabOption[] = [
  {
    id: SALES_MADE,
    label: 'Ventas realizadas',
    selected: true,
  },
  {
    id: FUTURE_SALES,
    label: 'Ventas futuras',
  },
];
