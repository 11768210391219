import Services from '../../../services/services';
import SalesEvalproServicesDefinitions from './SalesEvalproServicesDefinitions';

class SalesEvalproServices extends Services {
  putGroup(towerId, data) {
    const route = SalesEvalproServicesDefinitions.putGroup(towerId);
    return this.put(route, data);
  }

  putGroupsOrder(towerId, data) {
    const route = SalesEvalproServicesDefinitions.putGroupsOrder(towerId);
    return this.put(route, data);
  }

  putAdditional(towerId, data) {
    const route = SalesEvalproServicesDefinitions.putAdditional(towerId);
    return this.put(route, data);
  }

  destroyGroup(groupId) {
    const route = SalesEvalproServicesDefinitions.destroyGroup(groupId);
    return this.delete(route);
  }

  getSales(towerId) {
    const route = SalesEvalproServicesDefinitions.getSales(towerId);
    return this.get(route);
  }

  getTowers(towerId) {
    const route = SalesEvalproServicesDefinitions.getTowers(towerId);
    return this.get(route);
  }

  getIncrementSuggestion(groupId, ear, frequency) {
    const route = SalesEvalproServicesDefinitions.getIncrementSuggestion(
      groupId,
      ear,
      frequency,
    );
    return this.get(route);
  }
}

export default SalesEvalproServices;
