import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import useFeatureFieldDragAndDrop from 'App/FeatureField/core/customHooks/useFeatureFieldDragAndDrop';
import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import useUpdateFeatureFieldOptions from '../../../core/customHooks/useUpdateFeatureFieldOptions';
import { IndexedFieldOption } from '../../../core/interfaces/featureField.types';
import {
  FeatureFieldOptionsControlProps,
  GetOptionInput,
} from '../../../core/interfaces/FeatureFieldOptionsControl.types';
import FeatureFieldOptionInput from './FeatureFieldOptionInput';

import itemStyles from './FeatureFieldOptionInput.module.scss';
import styles from './FeatureFieldOptionsControl.module.scss';

const getEmptyClass = (options: IndexedFieldOption[]) => ({
  [styles.isOptionsEmpty]: options.length === 0,
});

const getOptionInput =
  ({
    disabled,
    handleChangeOption,
    handleDeleteOption,
    handleDragStart,
    handleDragEnter,
    handleDragOver,
    handleDrop,
    handleDragEnd,
  }: GetOptionInput) =>
  (option: IndexedFieldOption, index: number) =>
    (
      <FeatureFieldOptionInput
        key={option.index}
        option={option}
        index={index}
        disabled={disabled}
        onChange={handleChangeOption(index)}
        onDelete={handleDeleteOption(index)}
        onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
      />
    );

const EmptyMessage = (
  <Typography className={styles.emptyMessage} as="p" variant="body1" color="gray800">
    Aún no has agregado opciones de selección
  </Typography>
);

const FeatureFieldOptionsControl: React.FC<FeatureFieldOptionsControlProps> = ({ options, disabled, onChange }) => {
  const { indexedOptions, handleChangeOption, handleDeleteOption, handleAddOption, handleSortOptions } =
    useUpdateFeatureFieldOptions({
      options,
      onChange,
    });
  const { draggableItems, handleDragStart, handleDragEnter, handleDragOver, handleDrop, handleDragEnd } =
    useFeatureFieldDragAndDrop<IndexedFieldOption>({
      itemsOrder: indexedOptions,
      grabbingClassName: itemStyles.isGrabbing,
      onSortedItems: handleSortOptions,
    });
  const optionInputProps = {
    disabled,
    handleChangeOption,
    handleDeleteOption,
    handleDragStart,
    handleDragEnter,
    handleDragOver,
    handleDrop,
    handleDragEnd,
  };

  return (
    <div className={styles.root}>
      {draggableItems?.length ? draggableItems.map(getOptionInput(optionInputProps)) : EmptyMessage}
      <Button
        className={clsx(getEmptyClass(draggableItems), styles.addButton)}
        variant="text"
        size="small"
        disabled={disabled}
        onClick={handleAddOption}
      >
        <Icon className={styles.addIcon} variant="Add" width="1.5rem" height="1.5rem" />
        <span>Agregar una opción</span>
      </Button>
    </div>
  );
};

export default FeatureFieldOptionsControl;
