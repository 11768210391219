import { Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { FC } from 'react';
import styled from 'styled-components';

import colors from '../../../../assets/styles/settings/colors.module.scss';

const ContractsEvalproGridDetailToolbarRoot: FC = styled(Toolbar.Root)<Toolbar.RootProps>`
  /* Box-model */
  padding: 0;
  min-height: 0;
  background-color: ${colors.gray600};
  display: grid;

  /* Visual */
  border-bottom: none;
`;

export default ContractsEvalproGridDetailToolbarRoot;
