import { useEffect, useState } from 'react';
import { Column, IntegratedSummary, SummaryItem, Table } from '@devexpress/dx-react-grid';
import { sortBy } from 'lodash';

import { ContractPayment, ContractPaymentSchedule } from '../../../../models/ContractPayments';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { DEFAULT_DATE_FORMAT } from '../../../../helpers/dates';

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

const getColumns = (contractPayment: ContractPayment): ColumnType[] => {
  return [
    {
      width: 130,
      title: '',
      name: 'index',
      columnName: 'index',
      getCellValue: (row) => {
        return `Couta ${row.index}`;
      },
    },
    {
      width: 210,
      title: 'Fecha de pago',
      name: 'finalDate',
      columnName: 'finalDate',
      type: ProviderColumnType.dateFormat,
    },
    {
      title: 'Descripción',
      name: 'description',
      columnName: 'description',
      type: ProviderColumnType.tooltip,
      getCellValue: (row: ContractPaymentSchedule) => {
        let desc = '';
        const { event, finalDate, displacement } = row;
        const monthDesc = displacement > 1 ? 'meses' : 'mes';
        if (row.scheduleId) {
          desc = `Fecha vinculada a ${row.scheduleLabelDescription}`;
          if (displacement !== 0) {
            desc += displacement > 0 ? ` (+${displacement} ${monthDesc}) ` : ` (${displacement} ${monthDesc}) `;
          }
          return desc;
        }
        if (event) {
          desc = `Fecha vinculada a ${event?.description}`;
          const eventDisplacement = Number(displacement) + Number(event.displacement);

          if (eventDisplacement !== 0) {
            desc +=
              eventDisplacement > 0
                ? ` (+${eventDisplacement} ${monthDesc}) `
                : ` (${eventDisplacement} ${monthDesc}) `;
          }

          return desc;
        }
        if (!!!finalDate && displacement > 0) {
          desc += ` (+${displacement} ${monthDesc}) `;
        }
        return '';
      },
    },
    {
      width: 130,
      title: 'Porcentaje',
      name: 'percentage',
      columnName: 'percentage',
      type: ProviderColumnType.percent,
    },

    {
      width: 170,
      title: 'Valor',
      name: 'subtotal',
      columnName: 'subtotal',
      type: ProviderColumnType.currency,
      getCellValue: (row: ContractPaymentSchedule) => {
        if ((contractPayment.type === 'PP' || contractPayment.type === 'PX') && row.percentage > 0) {
          return contractPayment?.subtotal * (row.percentage / 100);
        }
        return row.amount;
      },
    },
    {
      width: 130,
      title: 'Iva',
      name: 'tax',
      columnName: 'tax',
      type: ProviderColumnType.currency,
      getCellValue: (row: ContractPaymentSchedule) => {
        let subtotal = row.amount;
        if ((contractPayment.type === 'PP' || contractPayment.type === 'PX') && row.percentage > 0) {
          subtotal = contractPayment?.subtotal * (row.percentage / 100);
        }
        return contractPayment?.tax > 0 ? subtotal * (contractPayment?.tax / 100) : 0;
      },
    },
    {
      width: 170,
      title: 'Valor + IVA',
      name: 'total',
      columnName: 'total',
      type: ProviderColumnType.currency,
      getCellValue: (row: ContractPaymentSchedule) => {
        let subtotal = row.amount;
        if ((contractPayment.type === 'PP' || contractPayment.type === 'PX') && row.percentage > 0) {
          subtotal = contractPayment?.subtotal * (row.percentage / 100);
        }
        return contractPayment?.tax > 0 ? subtotal * (1 + contractPayment?.tax / 100) : subtotal;
      },
    },
  ];
};

interface UseColumnsAndRowsForContractPaymentsParams {
  contractPayment: ContractPayment;
  contractPaymentSchedules: ContractPaymentSchedule[];
}

type HookType = (param: UseColumnsAndRowsForContractPaymentsParams) => {
  columns: ColumnType[];
  rows: ContractPaymentSchedule[] | [];
  totalSummaryItems: SummaryItem[];
  hiddenColumns: string[];
  summaryCalculator: (
    type: string,
    rows: ContractPaymentSchedule[],
    getValue: (row: ContractPaymentSchedule) => ContractPaymentSchedule,
  ) => number | string | undefined;
};

const useColumnsAndRowsForContractPayments: HookType = ({ contractPayment, contractPaymentSchedules }) => {
  const [columns, setColumns] = useState(getColumns(contractPayment));
  const tableSummaryItems: SummaryItem[] = [
    { columnName: 'percentage', type: 'sumPercent' },
    { columnName: 'total', type: 'sum' },
    { columnName: 'subtotal', type: 'sum' },
    { columnName: 'tax', type: 'sum' },
  ];

  const [totalSummaryItems] = useState(tableSummaryItems);

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [rows, setRows] = useState<ContractPaymentSchedule[]>([]);

  useEffect(() => {
    let hidden = hiddenColumns;
    setColumns(getColumns(contractPayment));
    if (contractPayment.type !== 'PP') {
      hidden.push('percentage');
    }

    setHiddenColumns(hidden);
  }, [contractPayment]);

  useEffect(() => {
    const sorted = sortBy(contractPaymentSchedules, ['finalDate'], ['asc']);
    const gridRows = (sorted || []).map((payment, index) => {
      return {
        ...payment,
        index: index + 1,
        dateFormat: DEFAULT_DATE_FORMAT,
      };
    });
    setRows(gridRows);
  }, [contractPaymentSchedules]);

  const summaryCalculator = (
    type: string,
    rows: ContractPaymentSchedule[],
    getValue: (row: ContractPaymentSchedule) => ContractPaymentSchedule,
  ) => {
    if (type === 'title') {
      if (!rows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', rows, getValue);
  };

  return {
    columns,
    rows,
    totalSummaryItems,
    hiddenColumns,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractPayments;
