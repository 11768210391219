import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

const CustomInput = ({ id, defaultValueP, onBlur, prefix = '' }) => {
  const [value, setValue] = useState(defaultValueP || 0);
  const [defaultValue, setDefaultValue] = useState(defaultValueP);

  useEffect(() => {
    if (Number(defaultValueP) !== defaultValue) {
      setValue(Number(defaultValueP) || 0);
      setDefaultValue(Number(defaultValueP) || 0);
    }
  });

  return (
    <NumberFormat
      id={id}
      value={value}
      onValueChange={(e) => {
        setValue(e.floatValue);
      }}
      onBlur={() => onBlur(value)}
      customInput={TextField}
      thousandSeparator
      isNumericString
      prefix={prefix}
    />
  );
};

export default CustomInput;
