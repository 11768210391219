import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import debouncedInput from '../../../../../../components/UI/TextFields/debouncedInput';

const DebouncedTextField = debouncedInput(NumberFormat, { timeout: 400 });

const DebouncedQuotationSetupTextField = styled(DebouncedTextField)`
  width: 130px;
  padding-left: 30px;
  text-align: center;
  input {
    text-align: center;
  }
`;

const QuotationSetupTypography = styled(Typography)`
  width: 250px;
`;

const TitleWithAlignedFieldSetupView = ({
  title,
  inputValue,
  suffix,
  isEnabled,
  onChange,
}) => {
  return (
    <Box display="flex">
      <QuotationSetupTypography variant="subtitle1">
        {title}
      </QuotationSetupTypography>
      <DebouncedQuotationSetupTextField
        customInput={TextField}
        suffix={suffix}
        thousandSeparator
        value={inputValue}
        disabled={!isEnabled}
        onValueChange={onChange}
      />
    </Box>
  );
};

TitleWithAlignedFieldSetupView.propTypes = {
  title: PropTypes.string.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEnabled: PropTypes.bool,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
};

export default TitleWithAlignedFieldSetupView;
