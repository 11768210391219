import React, { FC } from 'react';
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid';
import { Plugin, Template } from '@devexpress/dx-react-core';
import { AreaTypeParsedFunc } from '../../../Core/types/AreaTypeParsedFunc';
import { isDataTableCell, isHeadingTableCell } from '../../../../../shared/tables/helpers/TableTemplateHelpers';
import { NewAreaColumnName } from '../../../Core/constants/AreasConstants';
import NewAreaColumnCell from '../Cells/NewAreaColumnCell/NewAreaColumnCell';
import TableHeaderRowCellButtonNewArea from '../Cells/TableHeaderRowCellButtonNewArea/TableHeaderRowCellButtonNewArea';
import AddColumnButton from '../AddColumnButton/AddColumnButton';

const AreaEditionNewAreaTableProvider: FC<{ onClick: AreaTypeParsedFunc }> = ({ onClick }) => {
  return (
    <Plugin name="AreaEditionNewAreaTableProvider">
      <Template name="tableCell" predicate={isDataTableCell([NewAreaColumnName])}>
        {(params: Table.CellProps) => <NewAreaColumnCell {...params} />}
      </Template>
      <Template name="tableCell" predicate={isHeadingTableCell([NewAreaColumnName])}>
        {(params: TableHeaderRow.CellProps) => (
          <TableHeaderRowCellButtonNewArea {...params}>
            <AddColumnButton onClick={() => onClick()} />
          </TableHeaderRowCellButtonNewArea>
        )}
      </Template>
    </Plugin>
  );
};

export default AreaEditionNewAreaTableProvider;
