import { useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import useAlert from '../../../../shared/components/Alert/Core/customHooks/useAlert';
import useAreasSaveChanges from './useAreasSaveChanges';
import { AreasDB } from '../indexedDB/AreaIndexedDB';
import { GET_QUERY_AREAS } from '../ReactQuery/AreaReactQueryConstants';
import { AreaEditionAreaTypes } from '../recoil/AreaEditionAreaTypes';
import { AreaEditionHaveChangesAtom } from '../recoil/AreaEditionHaveChangesAtom';
import { AreaEditionActionsProps } from './AreaEditionActionsProps';

const useAreaEditionActions = (props: AreaEditionActionsProps) => {
  const queryClient = useQueryClient();
  const [areaEditionHaveChanges, setAreaEditionHaveChangesState] = useRecoilState(AreaEditionHaveChangesAtom);
  const [areaTypes] = useRecoilState(AreaEditionAreaTypes);

  const handleSuccessPersistData = async () => {
    setAreaEditionHaveChangesState(false);
    await queryClient.invalidateQueries(GET_QUERY_AREAS);
    await props.onEditorSaveChanges();
  };

  const { saveChanges: persistData } = useAreasSaveChanges(
    props.towerId,
    handleSuccessPersistData,
    handleSuccessPersistData,
  );

  const saveChanges = async () => {
    const properties = await AreasDB.properties.toArray();
    persistData(properties, areaTypes);
  };

  const closeAction = async () => {
    await props.onClickCancelEdit();
    setAreaEditionHaveChangesState(false);
  };

  const { showAlert } = useAlert({
    isOpen: false,
    title: 'Datos no guardados',
    acceptAction: closeAction,
  });

  const close = async () => {
    if (areaEditionHaveChanges) showAlert('Se perderán todos los cambios realizados no guardados.');
    else closeAction();
  };

  return { areaEditionHaveChanges, close, saveChanges };
};
export default useAreaEditionActions;
