import moment from 'moment';
import { Column, GridColumnExtension } from '@devexpress/dx-react-grid';
import { ClientModel } from 'App/Clients/Core/models/ClientModel';

export const ClientListColumnNames: Column[] = [
  {
    name: 'associated',
    title: 'Comprador',
  },
  {
    name: 'name',
    title: 'Nombre',
    getCellValue: (row: ClientModel) =>
      `${row?.name || ''} ${row?.middleName || ''} ${row?.surname || ''} ${row?.secondSurname || ''}`,
  },
  { name: 'identityDocument', title: 'N° Identificación' },
  { name: 'email', title: 'Correo' },
  {
    name: 'createdAt',
    title: 'Fecha de creación',
    getCellValue: (rowData: ClientModel) => moment(rowData.createdAt).format('DD/MM/YYYY'),
  },
];

export const ColumnWidths: GridColumnExtension[] = [{ columnName: 'associated', width: '140px' }];
