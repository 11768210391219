import React, { FC } from 'react';
import { Box, Divider } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';

interface IAreasHeaderProps {
  hasProperties: boolean;
}

const AreasHeader: FC<IAreasHeaderProps> = ({ children, hasProperties }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="headline2">Áreas</Typography>
        </Box>
        <Box>{children}</Box>
      </Box>
      {hasProperties && (
        <Box marginTop="13px" marginBottom="40px">
          <Divider />
        </Box>
      )}
    </>
  );
};

export default AreasHeader;
