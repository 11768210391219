import ContractsServiceDefinition from './ContractsServicesDefinition';
import Services from '../services';
import { Contract, ForeignData, Category } from '../../models/Contracts';

export default class ContractsService extends Services {
  getAllContractors(towerId: string) {
    return this.get(ContractsServiceDefinition.getAllContractors(towerId));
  }

  getAllContractStates(towerId: string) {
    return this.get(ContractsServiceDefinition.getAllContractStates(towerId));
  }

  getAllContractCategories(towerId: string) {
    return this.get(ContractsServiceDefinition.getAllContractCategories(towerId));
  }

  getContractsFlow(towerId: string) {
    return this.get<[]>(ContractsServiceDefinition.getContractsFlow(towerId));
  }

  downloadContractsFlow(towerId: string) {
    return this.get<Blob>(ContractsServiceDefinition.downloadContractsFlow(towerId), {
      responseType: 'blob',
    });
  }

  getAllContracts(towerId: string) {
    return this.get<Category[]>(ContractsServiceDefinition.getAllContracts(towerId));
  }

  getAllContractForeignList(towerId: string) {
    return this.get<ForeignData>(ContractsServiceDefinition.getAllContractForeignList(towerId));
  }

  getContract(contractId: number) {
    return this.get<Contract>(ContractsServiceDefinition.getContract(contractId));
  }

  createContract(towerId: string, data) {
    return this.post<Contract>(ContractsServiceDefinition.createContract(towerId), data);
  }

  editContract(contractId: number, data) {
    return this.put<Contract>(ContractsServiceDefinition.editContract(contractId), data);
  }

  deleteContract(id: number) {
    return this.delete(ContractsServiceDefinition.deleteContract(id), {
      id,
    });
  }
}
