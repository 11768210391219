import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Typography from 'shared/components/Typography/Typography';
import styles from './DeedExpensesSalesMadeEmptyState.module.scss';
import { Button } from 'shared/components/Button/Button';

const DeedExpensesSalesMadeEmptyState: FC<{ openConfiguration: () => void }> = ({ openConfiguration }) => {
  return (
    <div className={styles.DeedExpensesSalesMadeEmptyState}>
      <Typography fontStyle="italic">
        Aún no hay unidades vendidas pero puedes configurar el desplazamiento de las nuevas por vender.
      </Typography>
      <Box marginTop="20px">
        <Button variant="contained" onClick={openConfiguration}>
          Configurar
        </Button>
      </Box>
    </div>
  );
};

export default DeedExpensesSalesMadeEmptyState;
