import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FinancialNeighborhoodAlgorithms from '../../../../helpers/FinancialNeighborhoodAlgorithms';
import Numbers from '../../../../helpers/numbers';
import { calculateMonthlyRate } from './useBankTermLimitForQuotationSetup';
import GeneralWalletServices from '../../../GeneralWallet/services/GeneralWalletServices';

function useUpdateWalletSetup(propertyName, walletSetup) {
  const [yearlyValue, setWalletProperty] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { towerId } = useParams();

  useEffect(() => {
    if (walletSetup) {
      setWalletProperty(Numbers.cleanNumber(walletSetup[propertyName] * 100));
    }
  }, [walletSetup]);

  const monthly = Numbers.cleanNumber(
    yearlyValue ? calculateMonthlyRate(yearlyValue / 100) : 0,
  );

  const daily = Numbers.cleanNumber(
    yearlyValue
      ? FinancialNeighborhoodAlgorithms.totalIncrementRateToListIncrementRate(
          yearlyValue / 100,
          365,
        ) * 100
      : 0,
  );

  const updateWalletSetup = async (newValue, previousValue) => {
    try {
      const services = new GeneralWalletServices();
      await services.updateWalletSetup(towerId, propertyName, newValue / 100);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setWalletProperty(previousValue);
    }
  };

  const onChange = (values) => {
    const previousValue = yearlyValue;
    setWalletProperty(values.floatValue);
    updateWalletSetup(values.floatValue, previousValue);
  };

  return { daily, monthly, yearly: yearlyValue, onChange };
}

export default useUpdateWalletSetup;
