import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import Style from './ProjectResultsStateMainView.module.scss';
import ProjectResultsStateTable from './views/table/ProjectResultsStateTable';
import ProjectResultsStateHeader from './views/header/ProjectResultsStateHeader';
import useProjectResultsStateToFetchRows from '../Core/customHooks/useProjectResultsStateToFetchRows';
import { updateProjectResultState } from '../Core/actions';
import AbsoluteCircularProgress from './views/AbsoluteCircularProgress/AbsoluteCircularProgress';

const ProjectResultsStateMainView = ({ onChangeProjectResultsState }) => {
  const { isLoading } = useProjectResultsStateToFetchRows(onChangeProjectResultsState);

  return (
    <Paper>
      <Box className={Style.projectResultStateContainer}>
        {isLoading && <AbsoluteCircularProgress />}
        <Box className={Style.projectResultStateTableContainer}>
          <ProjectResultsStateHeader />
          <ProjectResultsStateTable isLoading={isLoading} />
        </Box>
      </Box>
    </Paper>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  onChangeProjectResultsState: updateProjectResultState,
};

ProjectResultsStateMainView.propTypes = {
  onChangeProjectResultsState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectResultsStateMainView);
