import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import ContractPaymentScheduleGridStateFormatter from './ContractPaymentScheduleGridStateFormatter';

const TableCellNonEditing = TableCellNonEditingComponent(ContractPaymentScheduleGridStateFormatter);

const ContractPaymentScheduleGridStateProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractPaymentScheduleGridStateFormatter}
    editorComponent={TableCellNonEditing}
    {...props}
  />
);

export default ContractPaymentScheduleGridStateProvider;
