import React from 'react';
import { useSelector } from 'react-redux';
import Display from './Display';
import InputHeader from './InputHeader';

export default function Headers() {
  const { isHeaderEditable, headers } = useSelector(
    (state) => state.buildingHeader,
  );

  const editableHeader = headers.map((header, index) => (
    <InputHeader key={index} header={header} position={index} />
  ));

  const display = Display();

  if (isHeaderEditable) {
    return editableHeader;
  }
  return display;
}
