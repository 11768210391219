import React, { FC } from 'react';
import { Menu, MenuItem, ListItemText, Divider } from '@material-ui/core';

import Typography from '../../../../../shared/components/Typography/Typography';
import Styles from '../../styles/ContractPaymentListGrid.module.scss';
import ContractPaymentType from '../../../Core/types/contractPaymentType';

const ContractPaymentMenu: FC<{
  anchorEl: null | HTMLElement;
  onMenuClick: (paymentType: string) => void;
  handleClose: () => void;
  currentContractIsLot: boolean;
}> = ({ anchorEl, onMenuClick, handleClose, currentContractIsLot }) => {
  return (
    <Menu
      id="contract-payment-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={1}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: Styles.buttonMenu,
      }}
    >
      <MenuItem key="new-contract-menu-title" disabled>
        <Typography variant="h6" color="gray800" fontWeight="600">
          Selecciona un tipo de pago
        </Typography>
      </MenuItem>
      <Divider />
      {ContractPaymentType.map(
        (type) =>
          (type.isOnlyForLots === currentContractIsLot || type.isOnlyForLots === false) && (
            <MenuItem
              key={`new-payment-menu-item-${type.id}`}
              classes={{
                root: Styles.menuItem,
                selected: Styles.menuItemSelected,
              }}
              onClick={() => {
                onMenuClick(type.id);
              }}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="button" fontWeight="600">
                      {type.name}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption"></Typography>
                  </React.Fragment>
                }
              />
            </MenuItem>
          ),
      )}
    </Menu>
  );
};

export default ContractPaymentMenu;
