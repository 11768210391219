export const ON_CONTRACT_CASH_FLOW_IS_LOAD = 'ON_CONTRACT_CASH_FLOW_IS_LOAD';
export const ON_SALES_CASH_FLOW_IS_LOAD = 'ON_SALES_CASH_FLOW_IS_LOAD';
export const ON_UPDATE_SALES_ROWS = 'ON_UPDATE_SALES_ROWS';
export const CHANGING_TYPE_OF_VIEW = 'CHANGING_TYPE_OF_VIEW';
export const CHANGING_TYPE_OF_VIEW_FOR_TOTAL_CASH_FLOW =
  'CHANGING_TYPE_OF_VIEW';
export const SET_ROWS_FOR_RESULTS_STATE = 'SET_ROWS_FOR_RESULTS_STATE';
export const ON_SELECTED_TAB_IS_LOAD = 'ON_SELECTED_TAB_IS_LOAD';
export const ON_SELECTED_BUDGET_IS_LOAD = 'ON_SELECTED_BUDGET_IS_LOAD';
export const SET_EDIT_CELLS_FOR_BUDGET = 'SET_EDIT_CELLS_FOR_BUDGET';
export const SET_SAVE_BUDGET_STATE = 'SET_SAVE_BUDGET_STATE';
export const SET_BUDGET_ROWS = 'SET_BUDGET_ROWS';
export const SET_EDITABLE_ROWS = 'SET_EDITABLE_ROWS';
export const SET_CUTS_ROWS = 'SET_CUTS_ROWS';
export const ON_SALES_CASH_FLOW_TOTALS = 'ON_SALES_CASH_FLOW_TOTALS';
export const ON_UPDATE_ROWS_FOR_BUDGET_CONTROL = 'ON_UPDATE_ROWS_FOR_BUDGET_CONTROL'

export const updateContractCashflow = (cashFlowRows) => ({
  type: ON_CONTRACT_CASH_FLOW_IS_LOAD,
  payload: cashFlowRows,
});
export const updateSalesRows = (salesRows) => ({
  type: ON_UPDATE_SALES_ROWS,
  payload: salesRows,
});

export const updateSalesCashflow = (cashFlowRowsWithInitialDates) => ({
  type: ON_SALES_CASH_FLOW_IS_LOAD,
  payload: cashFlowRowsWithInitialDates,
});

export const updateTypeOfViewState = (typeOfView) => ({
  type: CHANGING_TYPE_OF_VIEW,
  payload: typeOfView,
});

export const updateTypeOfViewStateForTotalCashFlow = (typeOfView) => ({
  type: CHANGING_TYPE_OF_VIEW_FOR_TOTAL_CASH_FLOW,
  payload: typeOfView,
});

export const updateResultsStateRows = (rows) => ({
  type: SET_ROWS_FOR_RESULTS_STATE,
  payload: rows,
});

export const updateSelectedTab = (selectedTab) => ({
  type: ON_SELECTED_TAB_IS_LOAD,
  payload: selectedTab,
});

export const updateBudgetSelector = (selectedBudget) => ({
  type: ON_SELECTED_BUDGET_IS_LOAD,
  payload: selectedBudget,
});

export const cellEditorState = (cellEditorState) => ({
  type: SET_EDIT_CELLS_FOR_BUDGET,
  payload: cellEditorState,
});

export const updateSaveBudgetState = (budgetState) => ({
  type: SET_SAVE_BUDGET_STATE,
  payload: budgetState,
});

export const updateBudgetRows = (budgetRows) => ({
  type: SET_BUDGET_ROWS,
  payload: budgetRows,
});

export const updateEditableRows = (editableBudgetRows) => ({
  type: SET_EDITABLE_ROWS,
  payload: editableBudgetRows,
});

export const updateCutsRows = (cutsRows) => ({
  type: SET_CUTS_ROWS,
  payload: cutsRows,
});

export const updateTotalReport = (totals) => ({
  type: ON_SALES_CASH_FLOW_TOTALS,
  payload: totals,
});

export const updateRowsForBudgetContol = (rows) => ({
  type: ON_UPDATE_ROWS_FOR_BUDGET_CONTROL,
  payload: rows
})