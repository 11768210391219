import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import UserServices from '../../services/user/UserServices';
import { CreateUserForm } from '../../components/User';
import agent from '../../config/config';
import { DashboardRoutes, ProjectRoutes } from '../../routes/local/routes';
import styles from './CreateUser.module.scss';
import errorHandling from '../../services/commons/errorHelper';
import { Role } from '../../helpers';
import withDefaultLayout from '../../HOC/Layouts/Default/withDefaultLayout';
import ProjectServices from '../../services/Projects/ProjectServices';

const initialState = {
  isLoading: false,
  projects: [],
  companies: [],
};

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.services = new UserServices(this);
    this.projectServices = new ProjectServices();
  }

  componentDidMount() {
    if (this.props.changeTower) {
      this.props.changeTower(null);
    }
    this.loadCurrentProjects();
  }

  loadCurrentProjects = () => {
    this.setState({ isLoading: true });
    this.projectServices
      .getProjects()
      .then((response) => {
        this.setState(
          {
            projects: response.data.projects ? response.data.projects : [],
            isLoading: false,
          },
          () => {
            if (Array.isArray(this.state.projects)) {
              const companiesWithProject = this.state.projects.reduce(
                (init, project) => ({ ...init, [project.companyId]: true }),
                {},
              );
              this.setState({
                companies: response.data.companies.filter(
                  (company) => companiesWithProject[company.id],
                ),
              });
            }
          },
        );
      })
      .catch((error) => {
        this.setState({
          projects: [],
          companies: [],
        });
        this.setState({ isLoading: false });
      });
  };

  state = Object.assign({}, initialState);

  createUserHandler = (values) => {
    const { role, name, email, password, project } = values;
    this.setState({ isLoading: true });
    this.services
      .signup({
        userType: role,
        name,
        email,
        password,
      })
      .then(async (user) => {
        if (
          Array.isArray(project) &&
          project.length &&
          user &&
          user.data &&
          user.data.user &&
          user.data.user.id
        ) {
          for (const p of project)
            await this.services.addProjectToUser({
              userId: user.data.user.id,
              projectId: p.id,
            });
        }
        this.props.spawnMessage('Usuario creado correctamente', 'success');
        if (user.email) {
          this.props.history.push(DashboardRoutes.base + ProjectRoutes.base);
        }
        this.setState({ isLoading: false });
        this.loadCurrentProjects();
      })
      .catch((error) => {
        this.props.spawnMessage(error.message, 'error');
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    return this.state.isLoading ? (
      <div className="Container">
        <Loader type="Puff" color={styles.mainColor} height="100" width="100" />
      </div>
    ) : (
      <div>
        <CreateUserForm
          createUser={this.createUserHandler}
          projects={this.state.projects}
          companies={this.state.companies}
        />
      </div>
    );
  }
}

export default withDefaultLayout(CreateUser);
