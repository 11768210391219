import React, { FC } from 'react';

import { FormControl, Grid, TextField, Box } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { FORMAT_YEAR_MONTH2 } from '../../../../../helpers/dates';
import Typography from '../../../../../shared/components/Typography/Typography';
import { IUseFormResult } from '../../../../../shared/customHooks/useForm';
import PlusInput from '../../../../../shared/components/PlusInput/PlusInput';
import { ContractPaymentSchedule } from '../../../../../models/ContractPayments';
import useDateDisplacement from '../../../Core/customHooks/useDateDisplacement';

import Styles from '../../ContractPaymentScheduleFormMainView.module.scss';

interface IPaymentDateDisplacement extends Partial<IUseFormResult<ContractPaymentSchedule>> {
  handleChangeDisplacement: (displacement: number) => void;
}

const PaymentDateDisplacement: FC<IPaymentDateDisplacement> = ({ handleChangeDisplacement, data }) => {
  const { isAllowed, handleChange } = useDateDisplacement({ handleChangeDisplacement });
  return (
    <>
      <Box mx={5} my={2}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">Meses a desplazar</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <PlusInput
                fullWidth
                value={data?.displacement ?? 0}
                onChange={handleChange}
                numberFormatProps={{
                  isAllowed,
                  decimalScale: 0,
                  name: 'displacement',
                  suffix: ' meses',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Fecha</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disabled
                  value={data?.finalDate || null}
                  format={FORMAT_YEAR_MONTH2}
                  onChange={() => {}}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      label=""
                      variant="outlined"
                      className={Styles.TextFieldDisabled}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PaymentDateDisplacement;
