import React, { FC } from 'react';
import { Box, ButtonProps } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Button } from 'shared/components/Button/Button';
import styles from './AddColumnButton.module.scss';

const AddColumnButton: FC<ButtonProps> = (props) => {
  return (
    <Box minWidth="60px" className={styles.container}>
      <div className={styles.verticalLine}></div>
      <Button color="primary" variant="contained" className={styles.AddColumnButton} {...props}>
        <Add />
      </Button>
    </Box>
  );
};

export default AddColumnButton;
