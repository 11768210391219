import React, { FC } from 'react';
import { Box, BoxProps, Card } from '@material-ui/core';
import styles from './DeedExpensesCard.module.scss';

const DeedExpensesCard: FC<BoxProps> = (props) => {
  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box {...props}>
        <Card className={styles.DeedExpensesCard}>
          <Box paddingX="50px" paddingY="30px">
            {props.children}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default DeedExpensesCard;
