import React, { FC } from 'react';
import { InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import QuotationConfigFormItem from '../../forms/QuotationConfigFormItem';
import QuotationConfigOutlinedInput from '../QuotationConfigOutlinedInput/QuotationConfigOutlinedInput';
import useUpdateQuotationSetup from 'components/Schedule/QuotationSetup/customHooks/useUpdateQuotationSetup';

interface Props {
  id: string;
  initialValue: number;
  handleChange?: (newFee: number) => void;
}

const QuotationConfigFeeItem: FC<Props> = ({ id, initialValue, handleChange }) => {
  const { fee, calculatedDiscount, onChange, isAllowed } = useUpdateQuotationSetup(id, initialValue, handleChange);
  return (
    <>
      <QuotationConfigFormItem item xs={12}>
        <NumberFormat
          thousandSeparator
          value={fee || 0}
          onValueChange={onChange}
          decimalScale={2}
          fixedDecimalScale
          customInput={QuotationConfigOutlinedInput}
          // @ts-ignore
          fullWidth
          isAllowed={isAllowed}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </QuotationConfigFormItem>
      <QuotationConfigFormItem item xs={12}>
        <NumberFormat
          thousandSeparator
          customInput={QuotationConfigOutlinedInput}
          value={calculatedDiscount}
          // @ts-ignore
          fullWidth
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          disabled
        />
      </QuotationConfigFormItem>
    </>
  );
};

export default QuotationConfigFeeItem;
