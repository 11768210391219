import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Switch from 'shared/components/Switch/Switch';
import Typography from 'shared/components/Typography/Typography';
import styles from './EvalproDeedExpensesSwitch.module.scss';

const EvalproDeedExpensesSwitch: FC<{ isAVGDeedDate: boolean; onChange: () => void }> = ({
  isAVGDeedDate,
  onChange,
}) => {
  return (
    <div className={styles.EvalproDeedExpensesSwitch}>
      <Box>
        <Typography variant="headline6">Escriturar en la fecha promedio de escrituración</Typography>
      </Box>
      <Box>
        <Switch checked={isAVGDeedDate} color="primary" onChange={onChange} />
      </Box>
    </div>
  );
};

export default EvalproDeedExpensesSwitch;
