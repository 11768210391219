import React, { FC } from 'react';
import { IconButton, Input } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendOutlined from '@material-ui/icons/SendOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import useGeneralWalletInput from 'App/GeneralWalletDetails/core/customHooks/useGeneralWalletInput';

import styles from './GeneralWalletInput.module.scss';

interface Props {
  isStatic?: boolean;
  initialValue?: string;
  hiddenFile?: boolean;
  isSuccess?: boolean;
  onSubmit: (value: string | File) => void;
}

const GeneralWalletInput: FC<Props> = ({ isStatic, initialValue, hiddenFile, onSubmit, isSuccess }) => {
  const { file, value, isError, onClickUpload, onChange, removeFile, handleClick } = useGeneralWalletInput(
    onSubmit,
    isSuccess,
    initialValue,
  );

  return (
    <div className={`${styles.InputContainer} ${isStatic ? styles.InputStaticContainer : ''}`}>
      <div
        className={`${styles.ContentField} ${isStatic ? styles.ContentStaticField : ''} ${
          isError ? styles.ErrorTextField : ''
        }`}
      >
        <Input
          multiline
          maxRows={7}
          className={styles.TextField}
          disableUnderline
          defaultValue={hiddenFile ? value : file?.name || value || ''}
          value={hiddenFile ? value : file?.name || value || ''}
          onChange={onChange}
          disabled={!!file}
          autoFocus
        />
        {!hiddenFile && (
          <>
            {file ? (
              <IconButton className={styles.Icon} onClick={removeFile}>
                <DeleteForeverIcon />
              </IconButton>
            ) : (
              <IconButton className={styles.Icon} onClick={onClickUpload}>
                <AttachFileIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
      <IconButton className={styles.IconSend} onClick={handleClick}>
        <SendOutlined />
      </IconButton>
    </div>
  );
};

export default GeneralWalletInput;
