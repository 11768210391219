import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import TableCellTooltipTypography from '../../../../shared/tables/VirtualTableDataTypeProvider/UI/customViews/TableCellTooltipTypography';

const TableCellNonEditing = TableCellNonEditingComponent(TableCellTooltipTypography);

const ContractsEvalproGridTitleProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={TableCellTooltipTypography} editorComponent={TableCellNonEditing} {...props} />
);

export default ContractsEvalproGridTitleProvider;
