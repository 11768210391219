import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import NumberFormat from 'react-number-format';

const ContractPaymentScheduleGridNumberFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  return (
    <div style={{ textAlign: 'right' }}>
      <NumberFormat displayType="text" value={value} thousandSeparator decimalScale={0} fixedDecimalScale prefix="$" />
    </div>
  );
};

export default ContractPaymentScheduleGridNumberFormatter;
