import { useSnackbar } from 'notistack';

import StagesServices from '../../services/StagesServices';
import StageProjectDTO from '../DTO/StageProjectDTO';
import StagesDTO from '../DTO/StagesDTO';
import { AddStageHandler } from '../types/Stages.types';

type AddStage = (stages: StagesDTO) => void;

const services = new StagesServices();

type UseAddStageOptions = {
  stageProject: StageProjectDTO;
  showProgress: () => void;
  hideProgress: () => void;
  addStage: AddStage;
};

function useAddStage({ stageProject, showProgress, hideProgress, addStage }: UseAddStageOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const postAddStage = async () => {
    try {
      showProgress();
      const { data } = await services.create(stageProject.id);
      addStage(data);
    } catch (error) {
      const message = (error as Error).message;
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      hideProgress();
    }
  };

  const handleAddStage: AddStageHandler = () => {
    postAddStage();
  };

  return handleAddStage;
}

export default useAddStage;
