import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { Category, Contract } from '../../../../../models/Contracts';
import ContractList from './ContractList';
import useCategoryContractList from '../../../Core/customHooks/useCategoryContractList';
import Styles from '../../styles/CategoryContractList.module.scss';

interface ICategoryContractList {
  categories: Category[];
  handleSelectContract: (contract: Contract) => void;
  handleEditContract: (contract: Contract) => void;
  handleDeleteContract: (contract: Contract) => void;
}

const CategoryContractList: FC<ICategoryContractList> = ({
  categories,
  handleEditContract,
  handleSelectContract,
  handleDeleteContract,
  ...extraProps
}) => {
  const { setExpanded } = useCategoryContractList();
  return (
    <Box overflow="false" className={Styles.scrollbar}>
      {categories.map((category) => (
        <ContractList
          setExpanded={() => setExpanded(category.categoryId)}
          category={category}
          key={category.categoryId}
          onDeleteContract={(contract) => {
            handleDeleteContract(contract);
          }}
          onEditContract={(contract) => {
            handleEditContract(contract);
          }}
          onSeleccionChange={(contract) => {
            handleSelectContract(contract);
          }}
          {...extraProps}
        />
      ))}
    </Box>
  );
};

export default CategoryContractList;
