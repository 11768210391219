export const events = [
  {
    value: 'evento 1 de 3'
  },
  {
    value: 'evento 2 de 3'
  },
  {
    value: 'evento 3 de 3'
  },
];
