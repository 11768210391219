import React, { FC } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import useModal from 'shared/customHooks/useModal';
import { ClientInformationProps } from 'App/Clients/Core/interfaces/ClientInformationProps';
import ClientAddressModal from 'App/ClientAddressModal/UI/modal/ClientAddressModal';
import useClientInformationForm from 'App/Clients/Core/customHooks/useClientInformationForm';
import ClientAddressType from 'App/ClientAddressModal/Core/models/ClientAddressType';
import AddressField from '../../Fields/AddressField';

const LaboralInformation: FC<ClientInformationProps> = (props) => {
  const {
    data,
    errors,
    addressDescription,
    address,
    onChangeType,
    onFinishAddressEdition,
    updateAddress,
    handleChange,
    handleChangeValue,
    handleSubmit,
  } = useClientInformationForm(props, 'companyAddress');

  const { isOpen, open, close } = useModal({
    handleClose: onFinishAddressEdition,
  });

  return (
    <>
      {props.title && <Typography variant="h5">{props.title}</Typography>}
      <form onSubmit={handleSubmit} id={props.formId} />
      <Box display="flex" justifyContent="center" marginY={4}>
        <Grid container item md={8} spacing={2}>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nombre de la empresa"
              value={data.companyName}
              onChange={handleChange('companyName')}
              helperText={errors.companyName}
              error={!!errors.companyName}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Ciudad"
              value={data.officeCity}
              onChange={handleChange('officeCity')}
              helperText={errors.officeCity}
              error={!!errors.officeCity}
            />
          </Grid>
          <Grid item md={6}>
            <AddressField
              fullWidth
              label="Dirección"
              value={data.companyAddress || ''}
              onClick={open}
              helperText={errors.companyAddress}
              error={!!errors.companyAddress}
            />
          </Grid>
          <Grid item md={6}>
            <NumberFormat
              customInput={TextField}
              defaultValue={data.companyPhone}
              value={data.companyPhone}
              onValueChange={(values) =>
                handleChangeValue('companyPhone', values.value)
              }
              // @ts-ignore
              fullWidth
              label="Teléfono"
              variant="outlined"
              helperText={errors.companyPhone}
              error={!!errors.companyPhone}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Profesión"
              value={data.profession}
              onChange={handleChange('profession')}
              helperText={errors.profession}
              error={!!errors.profession}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Ocupación"
              value={data.position}
              onChange={handleChange('position')}
              helperText={errors.position}
              error={!!errors.position}
            />
          </Grid>
          <Grid item md={6}>
            <NumberFormat
              customInput={TextField}
              defaultValue={data.monthlyIncome}
              value={data.monthlyIncome}
              prefix={'$'}
              thousandSeparator
              onValueChange={(values) =>
                handleChangeValue('monthlyIncome', values.floatValue)
              }
              isAllowed={({ floatValue }) => `${floatValue}`.length <= 10}
              // @ts-ignore
              fullWidth
              label="Ingreso mensual"
              variant="outlined"
              helperText={errors.monthlyIncome}
              error={!!errors.monthlyIncome}
            />
          </Grid>
        </Grid>

        <ClientAddressModal
          isOpen={isOpen}
          onClose={close}
          addressDescription={addressDescription || ''}
          currentType={address?.addressType || ClientAddressType.OPEN.code}
          onChangeType={onChangeType}
          update={updateAddress}
          clientAddress={address}
          onAccept={close}
        />
      </Box>
    </>
  );
};
export default LaboralInformation;
