import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { sample, sumBy } from 'lodash';
import { Column, Table } from '@devexpress/dx-react-grid';
import { QueryClient, useQueryClient } from 'react-query';

import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import { EvalproDetailDto, EvalproEventDto } from '../DTO';
import constants from './constants';
import { TotalPercentage } from '../interfaces/TotalPercentage';

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

type HookType = (param: { setTotalDistributed: Dispatch<SetStateAction<number>> }) => {
  columns: ColumnType[];
  rows: EvalproDetailDto[];
};

const getColumns = (): ColumnType[] => {
  return [
    {
      width: 170,
      title: 'Porcentaje',
      name: 'totalPercentage',
      columnName: 'totalPercentage',
    },
  ];
};

const getRows = (evalproDetails: EvalproEventDto[]) => {
  const { contractsEvalproDetails: categories } = sample(evalproDetails);

  const arrPercents = evalproDetails.reduce((acc: TotalPercentage[], curr: EvalproEventDto) => {
    const { contractsEvalproDetails } = curr;
    const rows: TotalPercentage[] = contractsEvalproDetails.map((evalproDetail: EvalproDetailDto) => ({
      id: curr.id,
      totalPercentage: evalproDetail.percentage || 0,
      categoryId: evalproDetail.contractCategoryId,
    }));

    return [...acc, ...rows];
  }, []);

  const arr = categories.reduce((acc: TotalPercentage[], curr: EvalproDetailDto) => {
    const row: TotalPercentage = {
      categoryId: curr.contractCategoryId,
      totalPercentage: sumBy(arrPercents, (item: TotalPercentage) => {
        return item.categoryId === curr.contractCategoryId ? item.totalPercentage : 0;
      }),
      id: curr.contractCategoryId,
    };
    return [...acc, row];
  }, []);

  return arr;
};

const useColumnsAndRowsForContractsEvalproDetailPercentage: HookType = ({ setTotalDistributed }) => {
  const queryClient: QueryClient = useQueryClient();
  const evalproDetails = queryClient.getQueryData<EvalproEventDto[]>(constants.CONTRACTS_EVALPRO_GROUPS_DETAILS);

  const [columns] = useState<ColumnType[]>(getColumns());
  const [rows, setRows] = useState<EvalproDetailDto[]>([]);

  useEffect(() => {
    if (evalproDetails) {
      const newRows = getRows(evalproDetails);
      setRows(newRows);
      const sumDistributed = sumBy(newRows, (item: TotalPercentage) => item.totalPercentage);
      setTotalDistributed(sumDistributed / newRows.length);
    }
  }, [evalproDetails]);

  return {
    columns,
    rows,
  };
};

export default useColumnsAndRowsForContractsEvalproDetailPercentage;
