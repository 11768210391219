import { useSnackbar } from 'notistack';
import { ChangeEvent, MutableRefObject, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import useAlert from '../../../../../shared/components/Alert/Core/customHooks/useAlert';
import { AlertVariants } from '../../../../../shared/components/Alert/Core/interfaces/ModalProps';
import { GET_QUERY_AREAS } from '../../ReactQuery/AreaReactQueryConstants';
import AreaErrorDescription from './AreaErrorDesctiption';
import { AreaUploadFileActionProps } from './AreaUploadFileActionProps';
import useAreaUploadFile from './useAreaUploadFile';

const useAreaUploadFileAction = (props: AreaUploadFileActionProps) => {
  const accept = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File>();
  const { mutate } = useAreaUploadFile(props.towerId);

  const acceptAction = () => {
    if (file?.name) {
      const formData = new FormData();
      formData.append('file', file);
      mutate(formData, {
        onError: handleRequestError,
        onSuccess: handleRequestSuccess,
      });
    }
  };

  const cancelAction = () => {
    setFile(undefined);
  };

  const { showAlert } = useAlert({
    isOpen: false,
    title: 'Sobreescribir Áreas',
    acceptAction,
    cancelAction,
  });

  const { showAlert: showAlertError } = useAlert({
    isOpen: false,
    title: 'Error de archivo',
    variant: AlertVariants.AcceptOnly,
  });

  const handleClick = () => inputRef.current.click();

  const handleRequestError = (err: unknown) => {
    const error = err as Record<string, string | undefined>;
    const message = `${error.message ?? 'Error al cargar el archivo no se pudo procesar'}`;
    console.log('message', message);
    showAlertError(AreaErrorDescription(message));
    cancelAction();
  };

  const handleRequestSuccess = async () => {
    await queryClient.invalidateQueries(GET_QUERY_AREAS);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files?.length) return;
    const file = e.currentTarget.files.item(0);
    if (!file) return;
    if (!accept.includes(file.type)) return;

    e.currentTarget.files = new DataTransfer().files;
    setFile(file);
    showAlert(
      'Al subir la plantilla se sobreescribirán las áreas existentes por las subidas en la plantilla. ¿Desea continuar?',
    );
  };

  return {
    inputRef,
    accept: accept.join(', '),
    handleClick,
    handleChange,
  };
};

export default useAreaUploadFileAction;
