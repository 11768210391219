import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  onChangeContracts?: string;
  onChangeSalesCashflow?: string;
  variant?: 'title' | 'subtitle' | 'form' | 'description' | 'alert';
  color: string;
  font?: string;
}

const CustomTypography: FC<Props> = (props) => {
  const { variant, color, font } = props;

  const CustomDiv = styled.div`
    font-family: ${() => {
      switch (font) {
        case 'quicksand':
          return 'quicksand';
        default:
          return 'quicksand';
      }
    }};
    color: ${() => {
      switch (color) {
        case 'gray':
          return '#434C53';
        case 'black':
          return '#232E39';
        case 'white':
          return '#FFFFFF';
        case 'green':
          return '#018e42';
        default:
          return color;
      }
    }};

    font-weight: ${() => {
      switch (variant) {
        case 'title':
          return '600';
        case 'subtitle':
          return '600';
        case 'alert':
          return '600';
        default:
          return 'initial';
      }
    }};

    font-size: ${() => {
      switch (variant) {
        case 'title':
          return '32px';
        case 'subtitle':
          return '20px';
        case 'form':
          return '18px';
        case 'description':
          return '14px';
        case 'alert':
          return '20px';
        default:
          return '1rem';
      }
    }};
  `;

  return <CustomDiv {...props}>{props.children}</CustomDiv>;
};

export default CustomTypography;
