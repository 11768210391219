import React from 'react';
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import useCharacteristics from '../../../Core/customHooks/useCharacteristic';
import PropertyType from './PropertyType';

function GraphicMaterialRow({ property, propertyTypes, updatePropertyTypes, updateRows, type }) {
  const { characteristics, updateRowValue } = useCharacteristics(property);
  return (
    <TableRow>
      <TableCell align="center">{property.name}</TableCell>
      <TableCell>
        <NumberFormat
          customInput={TextField}
          allowNegative={false}
          defaultValue={characteristics ? characteristics.bedrooms : 0}
          onChange={(e) => updateRowValue('bedrooms', Number(e.target.value))}
        />
      </TableCell>
      <TableCell>
        <NumberFormat
          customInput={TextField}
          allowNegative={false}
          defaultValue={characteristics ? characteristics.bathrooms : 0}
          onChange={(e) => updateRowValue('bathrooms', Number(e.target.value))}
        />
      </TableCell>
      <TableCell>
        <FormControl fullWidth>
          <Select
            value={characteristics ? characteristics.orientation || 'N' : 'N'}
            onChange={(e) => updateRowValue('orientation', e.target.value)}
          >
            <MenuItem value="N">Norte</MenuItem>
            <MenuItem value="S">Sur</MenuItem>
            <MenuItem value="E">Este</MenuItem>
            <MenuItem value="W">Oeste</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <PropertyType property={property} type={type} types={propertyTypes} updatePropertyTypes={updatePropertyTypes} updateRows={updateRows}/>
      </TableCell>
    </TableRow>
  );
}

GraphicMaterialRow.propTypes = {
  property: PropTypes.object,
  propertyTypes: PropTypes.array,
  updatePropertyTypes: PropTypes.func,
  updateRows: PropTypes.func,
  type: PropTypes.object
};

export default GraphicMaterialRow;
