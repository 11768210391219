import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import agent from '../../../../config/config';
import UserServices from '../../../../services/user/UserServices';

const service = new UserServices();

const validators = {
  phoneNumber: (value) => {
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(value))
      return 'No parece ser un número de teléfono';
    return false;
  },
  name: (value) => (/^\s*$/g.test(value) ? 'No puede estar vacío' : false),
};

const useProfileInfo = () => {
  const userBoolean = Object.keys(agent.currentUser).reduce(
    (init, key) => ({ ...init, [key]: false }),
    {},
  );
  const [user, setUser] = useState(agent.currentUser);
  const [errors, setErrors] = useState(userBoolean);
  const [loadingUserField, setLoadingUserField] = useState(userBoolean);
  const { enqueueSnackbar } = useSnackbar();

  const loadCurrentUserInfo = async () => {
    if (agent.currentToken) {
      const response = await service.currentUser();

      setUser(response.data.user);
    }
  };

  useEffect(() => {
    loadCurrentUserInfo();
  }, []);

  const updateUserField = async (name, newValue) => {
    setLoadingUserField({
      ...loadingUserField,
      [name]: true,
    });

    try {
      await service.updateField(name, newValue);
      agent.saveUser({ ...agent.currentUser, ...user, [name]: newValue });
      setUser({
        ...user,
        [name]: newValue,
      });
      setLoadingUserField({
        ...loadingUserField,
        [name]: false,
      });
      enqueueSnackbar('El usuario se ha actualizado', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onChange = (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    if (name) {
      setUser({
        ...user,
        [name]: value,
      });
      if (validators[name])
        setErrors({
          ...errors,
          [name]: validators[name](value),
        });
    }
  };

  const onBlur = (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    if (name && value !== agent.currentUser[name] && !errors[name]) {
      updateUserField(name, value.trim());
    } else {
      setUser({
        ...user,
        [name]: agent.currentUser[name],
      });
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  return {
    user,
    onBlur,
    onChange,
    loadingUserField,
    errors,
  };
};
export default useProfileInfo;
