import React, { FC } from 'react';
import { InsertInvitationOutlined } from '@material-ui/icons';
import DeedExpensesInformationLabel from '../DeedExpensesInformationLabel/DeedExpensesInformationLabel';
import styles from './DeedExpensesInformation.module.scss';
import moment from 'moment';
import NumberFormat from 'react-number-format';

interface Props {
  endOfSalesDate: string;
  deedsUnitsSold: number;
}

const DeedExpensesInformation: FC<Props> = ({ endOfSalesDate, deedsUnitsSold }) => {
  return (
    <div className={styles.DeedExpensesInformation}>
      <DeedExpensesInformationLabel
        label="Fecha fin de construcción"
        value={moment(Number(endOfSalesDate)).format('MMM-YYYY')}
        InfoIcon={InsertInvitationOutlined}
      />
      <DeedExpensesInformationLabel
        label="Escrituración unidades vendidas"
        value={
          <NumberFormat
            value={deedsUnitsSold}
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            displayType="text"
            prefix="$ "
          />
        }
      />
    </div>
  );
};

export default DeedExpensesInformation;
