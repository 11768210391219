import Services from '../../../services/services';
import PropertyCharacteristicServicesDefinition from './PropertyCharacteristicServicesDefinition';

class PropertyCharacteristicServices extends Services {
  setCharacteristic(towerId, data) {
    return this.put(
      PropertyCharacteristicServicesDefinition.setCharacteristic(towerId),
      data,
    );
  }
}

export default PropertyCharacteristicServices;
