import { useState, useEffect } from 'react';
import {
  ChangeSet,
  Column,
  EditingColumnExtension,
  EditingState,
  IntegratedSummary,
  SummaryItem,
  Table,
} from '@devexpress/dx-react-grid';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { rightAlign, centerAlign } from '../../../../shared/tables/TableColumnFormat';
import { ContractPaymentScheduleFlow, ContractPaymentInvoice } from '../../../../models/ContractPayments';
import { IStateChip } from '../../../../shared/components/StateChip/StateChip';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import Services from '../../../../services/ContractPaymentsInvoice/ContractPaymentsInvoiceServices';

const services = new Services();

const useAddContractPaymentInvoice = (contractId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (contractPayment: ContractPaymentInvoice) =>
      services.createContractPaymentInvoice(contractId, {
        ...contractPayment,
        contractId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        queryClient.invalidateQueries(['contract', contractId]);
        queryClient.invalidateQueries(['contract-payments-schedule', contractId]);
      },
    },
  );
};

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

const getColumns = (): ColumnType[] => [
  {
    title: 'Fecha de pago',
    name: 'finalDate',
    columnName: 'finalDate',
  },
  {
    title: 'Presupuesto',
    name: 'amountBudget',
    columnName: 'amountBudget',
  },
  {
    title: 'Real / Proyectado',
    name: 'amount',
    columnName: 'amount',
  },
  {
    title: 'Diferencia',
    name: 'difference',
    columnName: 'difference',
    getCellValue: (row: ContractPaymentScheduleFlow) => {
      return Math.ceil(row.amount - row.amountBudget);
    },
  },
  {
    title: 'Estado',
    name: 'state',
    columnName: 'state',
    getCellValue: (row: ContractPaymentScheduleFlow): IStateChip => {
      const paymentState: IStateChip = {
        color: 'gray400',
        label: 'Proyectado',
      };

      if (row.state === 'INVOICED') {
        paymentState.color = 'primary200';
        paymentState.label = 'Facturado';
      }
      return paymentState;
    },
  },
];

interface UseColumnsAndRowsForContractPaymentsScheduleParams {
  currentContractId: number;
  originalRows: ContractPaymentScheduleFlow[] | undefined;
}

type HookType = (param: UseColumnsAndRowsForContractPaymentsScheduleParams) => {
  rows: ContractPaymentScheduleFlow[] | undefined;
  columns: Column[];
  dateColumns: string[];
  currencyColumns: string[];
  currencyEditColumns: string[];
  differenceColumns: string[];
  chipColumns: string[];
  totalSummarySchedules: SummaryItem[];
  tableColumnExtensions: Table.ColumnExtension[];
  editingStateColumnExtensions: EditingState.ColumnExtension[];
  commitChanges: (changes: ChangeSet) => void;
  summaryCalculator: (
    type: string,
    rows: ContractPaymentScheduleFlow[],
    getValue: (row: ContractPaymentScheduleFlow) => ContractPaymentScheduleFlow,
  ) => number | string | undefined;
};

const useColumnsAndRowsForContractPaymentsSchedule: HookType = ({ originalRows, currentContractId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: addContractPaymentInvoice } = useAddContractPaymentInvoice(currentContractId);

  const [columns] = useState(getColumns());
  const [dateColumns] = useState(['finalDate']);
  const [currencyColumns] = useState(['amountBudget']);
  const [differenceColumns] = useState(['difference']);
  const [currencyEditColumns] = useState(['amount']);
  const [chipColumns] = useState(['state']);
  const [totalSummarySchedules] = useState([
    { columnName: 'finalDate', type: 'title' },
    { columnName: 'amount', type: 'sum' },
    { columnName: 'amountBudget', type: 'sum' },
    { columnName: 'difference', type: 'difference' },
  ]);

  const tableColumnFormat: Table.ColumnExtension[] = [
    { columnName: 'finalDate', align: centerAlign },
    { columnName: 'state', align: centerAlign, width: 150 },
    { columnName: 'amount', align: rightAlign },
    { columnName: 'amountBudget', align: rightAlign },
    { columnName: 'difference', align: rightAlign },
  ];

  const [editingStateColumnExtensions] = useState<EditingColumnExtension[]>([
    { columnName: 'finalDate', editingEnabled: false },
    { columnName: 'state', editingEnabled: false },
    { columnName: 'amount', editingEnabled: true },
    { columnName: 'amountBudget', editingEnabled: false },
    { columnName: 'difference', editingEnabled: false },
  ]);

  const [rows, setRows] = useState<ContractPaymentScheduleFlow[] | undefined>(originalRows);

  useEffect(() => {
    return setRows(originalRows);
  }, [originalRows]);

  const [tableColumnExtensions] = useState(tableColumnFormat);

  const commitChanges = async ({ changed }: ChangeSet): Promise<void> => {
    let changedRows: ContractPaymentScheduleFlow[] = [];
    if (changed) {
      const rowIndex = Object.keys(changed)[0];
      const rowUpdated: ContractPaymentScheduleFlow = changed[rowIndex];

      if (rowUpdated && (!!rowUpdated.amount || rowUpdated.amount === 0)) {
        const invoice: ContractPaymentInvoice = {
          invoiceDate: Number(rowIndex),
          amount: rowUpdated.amount,
          tax: 0,
          contractId: currentContractId,
          invoiceNumber: '0',
        };

        try {
          await addContractPaymentInvoice(invoice);
          if (originalRows) {
            changedRows = originalRows.map((row) =>
              changed[row.finalDate] ? { ...row, ...changed[row.finalDate] } : row,
            );
          }
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }

    return setRows(changedRows || originalRows);
  };

  const summaryCalculator = (
    type: string,
    rows: ContractPaymentScheduleFlow[],
    getValue: (row: ContractPaymentScheduleFlow) => ContractPaymentScheduleFlow,
  ) => {
    if (type === 'title') {
      if (!rows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', rows, getValue);
  };

  return {
    rows,
    columns,
    currencyColumns,
    currencyEditColumns,
    differenceColumns,
    chipColumns,
    dateColumns,
    totalSummarySchedules,
    tableColumnExtensions,
    editingStateColumnExtensions,
    commitChanges,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractPaymentsSchedule;
