import Services from '../../../services/services';
import GroupTypesServicesDefinitions from './GroupTypesServicesDefinitions';

class GroupTypesServices extends Services {
  createWithName(towerId, name) {
    return this.post(GroupTypesServicesDefinitions.create(towerId), {
      name,
    });
  }

  updateNameById(towerId, groupId, newName) {
    return this.put(GroupTypesServicesDefinitions.updateName(towerId), {
      groupId,
      newName,
    });
  }

  deleteById(towerId, groupId) {
    return this.delete(GroupTypesServicesDefinitions.updateName(towerId), {
      groupId,
    });
  }

  getAllByTowerId(towerId) {
    return this.get(GroupTypesServicesDefinitions.all(towerId));
  }
}

export default GroupTypesServices;
