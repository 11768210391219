import { API_PATH } from 'config/config';

class GeneralWalletDetailsServiceDefinitions {
  static threads = (clientId: string, propertyId: number) =>
    `${API_PATH}wallet-transaction/client/${clientId}/property/${propertyId}/threads`;
  static viewReport = (towerId: string, propertyId: number) =>
    `${API_PATH}wallet-transaction/view-report/tower/${towerId}/property/${propertyId}`;
  static updateReport = () => `${API_PATH}wallet-transaction/update-report/interest`;
  static checkAllReport = () => `${API_PATH}wallet-transaction/update-report/interest/all`;
  static excelSummary = (towerId: string, propertyId: number) =>
    `${API_PATH}wallet-transaction/view-report/excel/tower/${towerId}/property/${propertyId}`;
  static inteactionUpdate = (interactionId: number) =>
    `${API_PATH}wallet-transaction/threads/interactions/${interactionId}`;
  static threadInteraction = (threadId: number) => `${API_PATH}wallet-transaction/threads/${threadId}/interactions`;
}

export default GeneralWalletDetailsServiceDefinitions;
