import React from 'react';
import Table from './views/Table/SalesSpeedTable';

const CashFlowEvalproMainView = () => {
  return (
    <>
      <Table />
    </>
  );
};

export default CashFlowEvalproMainView;
