import { useState } from 'react';
import { Column, Table } from '@devexpress/dx-react-grid';
import { useParams } from 'react-router-dom';

import { Event } from '../../../../models/ContractPayments';
import { centerAlign } from '../../../../shared/tables/TableColumnFormat';
import { QueryClient, useQueryClient } from 'react-query';

const getColumns = (): Column[] => [
  {
    title: 'Titulo de hito',
    name: 'description',
  },
  {
    title: 'Fecha',
    name: 'customDate',
  },
];

type HookType = () => {
  rows: Event[] | undefined;
  columns: Column[];
  titleColumns: string[];
  dateColumns: string[];
  tableColumnExtensions: Table.ColumnExtension[];
};

const useColumnsAndRowsForContractEvents: HookType = () => {
  const { towerId }: { towerId: string } = useParams();

  const [columns] = useState(getColumns());
  const [titleColumns] = useState(['description']);
  const [dateColumns] = useState(['customDate']);

  const tableColumnFormat: Table.ColumnExtension[] = [
    { columnName: 'description', wordWrapEnabled: true, width: 500 },
    { columnName: 'customDate', align: centerAlign },
  ];

  const [tableColumnExtensions] = useState(tableColumnFormat);

  const queryClient: QueryClient = useQueryClient();

  const rows = queryClient.getQueryData<Event[]>(['contract-events', towerId]);

  return {
    rows,
    columns,
    titleColumns,
    dateColumns,
    tableColumnExtensions,
  };
};

export default useColumnsAndRowsForContractEvents;
