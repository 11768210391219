import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Button, CircularProgress } from '@material-ui/core';
import styles from './Naming.module.scss';
import Card, { CardHeader, CardBody, CardFooter } from '../../UI/Card/Card';
import Input from '../../UI/Input/Input';
import Table from '../../UI/Table/Table';
import commonStyles from '../../../assets/styles/variables.scss';
import Modal from '../../UI/Modal/Modal';
import LoadableContainer from '../../UI/Loader';
import Imports from '../Imports';
import Headers from '../../../App/Building/UI/Views/Headers/Headers';
import VerticalHeaders from '../../../App/Building/UI/Views/Headers/VerticalHeaders';
import useBuilding from '../../../App/Building/Core/useBuilding';
import { setInitialHeaders, setInitialVerticalHeaders } from '../../../App/Building/Core/actions';

const Naming = ({
  names,
  checkDuplicates,
  disableSold,
  lowestFloor,
  onPropertyNameChange,
  onPropertyEmpty,
  loadingNaming,
  headers,
  columns,
  updateSchema,
  isLoading,
}) => {
  const [hidden, setHidden] = useState(true);
  const [id, setId] = useState();
  const [FloorState, setFloorState] = useState();
  const [propertyIndexState, setPropertyIndexState] = useState();
  const [property, setProperty] = useState('');
  const { onSetHeaderEditable, isHeaderEditable, sendHeaders } = useBuilding();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialHeaders(headers));
    dispatch(setInitialVerticalHeaders(columns));
  }, [headers]);

  const getInputs = () => {
    return names.map((floor, floorIndex) =>
      floor.map((property, propertyIndex) => {
        const floorStateCal = floorIndex + lowestFloor;
        const locationCal = propertyIndex + 1;
        return (
          <Input
            key={`floor-${floorIndex}-property-${propertyIndex}`}
            className={styles.Input}
            validations={[
              {
                fn: checkDuplicates,
                message: 'Nombres únicos',
              },
            ]}
            disable={disableSold}
            location={locationCal}
            floor={floorStateCal}
            updateWithProp
            onChange={(target) => {
              if (target.value === '' && property !== null) {
                setProperty(property.name);
                setFloorState(floorStateCal);
                setPropertyIndexState(locationCal);
                setId(property.id);
                setHidden(false);
              } else {
                onPropertyNameChange(
                  property ? property.id : null,
                  floorStateCal,
                  locationCal,
                  target.value,
                );
              }
            }}
            value={property ? property.name : ''}
            clearValue
          />
        );
      }),
    );
  };
  const confirm = () => {
    setHidden(true);
    onPropertyEmpty(id);
    onPropertyNameChange(id, FloorState, propertyIndexState, '');
  };

  const cancel = () => {
    setHidden(true);
    onPropertyNameChange(id, FloorState, propertyIndexState, property);
  };

  return (
    <Card>
      <CardHeader>
        <p>
          Nomenclatura
          {loadingNaming && (
            <div className={styles.Loader}>
              <Loader
                type="ThreeDots"
                color={commonStyles.mainColor}
                height="50"
                width="100"
              />
            </div>
          )}
        </p>
      </CardHeader>
      <CardBody>
        <div className={styles.buttonsContainer}>
          <Imports updateInformation={updateSchema} />
          <Button
            color={isHeaderEditable ? 'secondary' : 'primary'}
            variant="contained"
            onClick={isHeaderEditable ? sendHeaders : onSetHeaderEditable}
          >
            {isHeaderEditable ? 'Guardar cambios' : 'Editar cabeceras'}
          </Button>
        </div>
        <LoadableContainer isLoading={isLoading}>
          <Table
            intersect="Propiedades"
            headers={Headers()}
            columns={VerticalHeaders()}
            data={getInputs()}
          />
        </LoadableContainer>
      </CardBody>
      <CardFooter />
      {!hidden && (
        <Modal
          title={`Eliminar Propiedad ${property}`}
          hidden={hidden}
          onConfirm={confirm}
          onCancel={cancel}
        >
          Esta propiedad se eliminara y no se podra recuperar <br /> deseas
          continuar?
        </Modal>
      )}
    </Card>
  );
};

Naming.propTypes = {
  names: PropTypes.array,
  checkDuplicates: PropTypes.func,
  disableSold: PropTypes.bool,
  lowestFloor: PropTypes.number,
  onPropertyNameChange: PropTypes.func,
  onPropertyEmpty: PropTypes.func,
  loadingNaming: PropTypes.bool,
  headers: PropTypes.array,
  columns: PropTypes.array,
  updateSchema: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Naming;
