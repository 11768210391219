import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RealPaymentsServices from '../../../RealPaymentsForProperty/Services/RealPaymentsServices';
import { updateRealPaymentsForProperty } from '../../../RealPaymentsForProperty/Core/actions';
import LikemetricError from 'shared/types/LikemetricError';

function useRealPaymentsExcel(propertyId, clientId) {
  const { towerId } = useParams<{ towerId?: string }>();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isUploadingFile, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | string>('');
  const services = new RealPaymentsServices();

  const onDownload = async () => {
    try {
      const name = 'Plantillas de pagos.xlsx';
      const response = await services.downloadRealPayments(towerId, propertyId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar('Excel descargado correctamente', {
        variant: 'success',
      });
    } catch (e) {
      const error = e as LikemetricError;
      if (!error.statusCode) {
        enqueueSnackbar('No se ha seleccionado ningun cliente')
      } else {
        enqueueSnackbar(error.message);
      }
    }
  };

  const onChangeFileHandler = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const uploadTemplate = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await services.uploadRealPayments(towerId, propertyId, clientId, formData);

      dispatch(updateRealPaymentsForProperty(response.data));
      setFile('');
      enqueueSnackbar('Plantilla cargada correctamente', {
        variant: 'success',
      });
      setIsLoading(false);
    } catch (e) {
      const error = e as LikemetricError;
      setIsLoading(false);
      setFile('');
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onCancel = () => {
    setFile('');
  };

  return {
    file,
    isUploadingFile,
    onDownload,
    uploadTemplate,
    onChangeFileHandler,
    onCancel,
  };
}

export default useRealPaymentsExcel;
