import { useSelector } from 'react-redux';
import { Numbers } from '../../../../helpers';

function useArrearsTotalSummaryForWallet() {
  
  const { summaryReport } = useSelector(
    (state) => state.arrearsReport || {},
  );
  const { commitments, payments } = summaryReport.total;
  const wallet = Numbers.cleanNumber(commitments - payments);
  const arrearsPercent = Numbers.cleanNumber((wallet / commitments) * 100);

  return {
    commitments,
    payments,
    wallet,
    arrearsPercent,
  };
}

export default useArrearsTotalSummaryForWallet;
