import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Grid, TableHeaderRow, TableSummaryRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { CondensedTableHeaderRowCell } from '../../../../../shared/tables/CondensedTable';
import TableSummaryRowCell from '../../../../../shared/tables/TableSummaryRowCell';
import TableCell from '../../../../../shared/tables/TableCell';
import VirtualTableDataTypeProvider from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import StateChip from '../../../../../shared/components/StateChip/StateChip';
import useCustomPopover, { HandlePopoverOpenType } from '../../../../../shared/customHooks/useCustomPopover';
import VirtualTableContainer from '../../../../../shared/tables/VirtualTableContainer';
import { ContractLot } from '../../../../../models/Contracts';

import useColumnsAndRowsForContractsLots from '../../../core/customHooks/useColumnsAndRowsForContractsLots';

import AbsoluteCircularProgress from 'App/ResultsState/UI/styles/AbsoluteCircularProgress';
import ContractsGridSummaryItemComponent from './ContractsGridSummaryItemComponent';
import ContractsListPopover from './ContractsListPopover';
import ContractsListDetailsProvider from './ContractsGridDetailsProvider';

const ContractStateFormatter = ({ value }) => (!!value ? <StateChip label={value.name} color={value.color} /> : null);

const ContractStateProvider = (props) => <DataTypeProvider formatterComponent={ContractStateFormatter} {...props} />;

const getRowId = (row: ContractLot) => row.id;

interface IContractsLotsGrid {
  onHandlePopoverOpen: HandlePopoverOpenType<ContractLot>;
  contracts: ContractLot[];
}

const ContractsLotsGrid: FC<IContractsLotsGrid> = ({ onHandlePopoverOpen, contracts }) => {
  const { columns, totalSummaryItems, tableDefaultSort, integratedSortingColumnExtensions, summaryCalculator } =
    useColumnsAndRowsForContractsLots();

  return (
    <Box>
      {!!contracts ? (
        <Box>
          <Grid rows={contracts} columns={columns} getRowId={getRowId}>
            <SortingState defaultSorting={tableDefaultSort} />
            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
            <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.tooltip} />
            <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
            <VirtualTableDataTypeProvider
              columns={columns}
              type={[ProviderColumnType.percent, ProviderColumnType.semaphore]}
            />
            <ContractStateProvider for={['state']} />
            <ContractsListDetailsProvider for={['options']} onClick={onHandlePopoverOpen} />
            <SummaryState totalItems={totalSummaryItems} />
            <IntegratedSummary calculator={summaryCalculator} />
            <VirtualTable
              columnExtensions={columns}
              containerComponent={VirtualTableContainer}
              cellComponent={TableCell}
              height="80vh"
            />

            <TableHeaderRow cellComponent={CondensedTableHeaderRowCell} showSortingControls />
            <TableSummaryRow
              totalCellComponent={TableSummaryRowCell}
              itemComponent={ContractsGridSummaryItemComponent}
            />
          </Grid>
        </Box>
      ) : (
        <AbsoluteCircularProgress />
      )}
    </Box>
  );
};

export default ContractsLotsGrid;
