import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import styled from 'styled-components';

import styles from './FilterMenuItem.module.scss';

const FilterMenuItem = styled(MenuItem)<MenuItemProps>`
  /* Box-model */
  padding: 14px 16px;

  &.Mui-selected,
  &.Mui-selected:hover {
    /* Visual */
    background-color: ${styles.selectedBackground};
    
    span {
      /* Tyypography */
      color: ${styles.selectedColor};
    }
  }

  &:focus {
    /* Visual */
    outline: none;
  }
`;

export default FilterMenuItem;