import React from 'react';
import PropTypes from 'prop-types';
import ButtonSquare from './ButtonSquare';
import styles from './SelectionSquare.module.scss';

export default function SelectionSquare({ name, schema }) {
  return (
    <div className={styles.container}>
      {schema.map((field) => (
        <ButtonSquare key={field.label} name={name} field={field} />
      ))}
    </div>
  );
}

SelectionSquare.propTypes = {
  name: PropTypes.string,
  schema: PropTypes.array,
};
