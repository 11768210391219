import React from 'react';
import AbsoluteCircularProgress from '../../TotalCashFlow/UI/styles/AbsoluteCircularProgress';
import usePropertyCharacteristicRequest from '../Core/customHooks/usePropertyCharacteristicRequest';
import GraphicMaterialTable from './views/table/GraphicMaterialTable';

const PropertyCharacteristic = () => {
  const { isLoading } = usePropertyCharacteristicRequest();
  
  return (
    <>
      <GraphicMaterialTable />
      {isLoading && <AbsoluteCircularProgress topPercentage={0} />}
    </>
  );
};

export default PropertyCharacteristic;
