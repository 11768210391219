import React from 'react';
import { Grid } from '@material-ui/core';
import BuyerCustomerPaymentPlanState from './buyerCustomerStatesViews/BuyerCustomerPaymentPlanState';
import BuyerCustomerPaymentsState from './buyerCustomerStatesViews/BuyerCustomerPaymentsState';
import BuyerCustomerScheduleState from './buyerCustomerStatesViews/BuyerCustomerScheduleState';

const BuyerCustomerAdditionalStates = () => {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item sm={4} xs={12}>
        <BuyerCustomerScheduleState />
      </Grid>
      <Grid item sm={4} xs={12}>
        <BuyerCustomerPaymentsState />
      </Grid>
      <Grid item sm={4} xs={12}>
        <BuyerCustomerPaymentPlanState />
      </Grid>
    </Grid>
  );
};

export default BuyerCustomerAdditionalStates;
