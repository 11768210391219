import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Numbers from '../../../../helpers/numbers';
import SalesRoomService from '../../../../services/salesRoom/salesRoomService';
import { updatePropertiesWithPrices } from '../actions';

function useUpdatePropertyPrice(property, isLoading, updateLoading) {
  const { towerId } = useParams();
  const services = new SalesRoomService();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setEditionState] = useState(false);
  const [customProperty, setProperty] = useState(property);
  const [initialPrice, setPrice] = useState(property.priceWithIncrement);

  useEffect(() => {
    setProperty({
      ...property,
      priceWithIncrement: Numbers.toFixed(
        property.priceWithIncrement,
        0,
        false,
        true,
      ),
    });
    setPrice(property.priceWithIncrement);
  }, [property]);

  const editionToggle = () => {
    setEditionState(!isEditing);
  };

  const updatePrice = (priceWithIncrement) => {
    setProperty({ ...customProperty, priceWithIncrement });
  };

  const onSave = async () => {
    if (isLoading) {
      enqueueSnackbar('Aún se está procesando un cambio de precio', {
        variant: 'warning',
      });
      return;
    }

    updateLoading(true);
    try {
      const properties = await services.putPropertyPrice(
        towerId,
        customProperty.id,
        customProperty.priceWithIncrement,
      );
      dispatch(updatePropertiesWithPrices(properties.data));
    } catch (error) {
      updatePrice(Numbers.toFixed(initialPrice, 0, false, true));
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    updateLoading(false);
    editionToggle();
  };

  const onCancel = () => {
    updatePrice(initialPrice);
    editionToggle();
  };

  const onChangePrice = (values) => {
    updatePrice(values.floatValue);
  };

  return {
    isEditing,
    customProperty,
    onCancel,
    onSave,
    editionToggle,
    onChangePrice,
  };
}

export default useUpdatePropertyPrice;
