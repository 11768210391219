import React, { FC } from 'react';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../../../../helpers/dates';

const TableCellDateFormat: FC<TableCellTypographyProps> = ({ value, row }) => {
  return <span>{moment(value).format(row.dateFormat ?? DEFAULT_DATE_FORMAT)}</span>;
};

export default TableCellDateFormat;
