import React from 'react';

import { FeatureFieldEditorTabPanelProps } from '../../../core/interfaces/FeatureFieldEditorTabPanel.types';

const isHiddenPanel = (value: number, index: number) => value !== index;

const showPanelContent = (value: number, index: number) => value === index;

const FeatureFieldEditorTabPanel: React.FC<FeatureFieldEditorTabPanelProps> = ({
  className,
  value,
  index,
  children,
  ...restProps
}) => (
  <div
    className={className}
    role="tabpanel"
    hidden={isHiddenPanel(value, index)}
    id={`feature-field-editor-tabpanel-${index}`}
    aria-labelledby={`feature-field-editor-tab-${index}`}
    {...restProps}
  >
    {showPanelContent(value, index) && children}
  </div>
);

export default FeatureFieldEditorTabPanel;
