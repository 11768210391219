import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import useFilterSubscribe from '../../Core/CustomHooks/useFilterSubscribe';

export default function InputBudget({ defaultValue }) {
  const { subscribeFilter } = useFilterSubscribe();

  const handleChangeFilter = (e) => {
    subscribeFilter(
      'budget',
      Number(
        e.target.value
          .split('$')
          .join('')
          .split(',')
          .join(''),
      ),
    );
  };

  return (
    <>
      <NumberFormat
        customInput={TextField}
        allowNegative={false}
        prefix="$"
        thousandSeparator
        defaultValue={defaultValue || 0}
        onChange={handleChangeFilter}
      />
    </>
  );
}

InputBudget.propTypes = {
  defaultValue: PropTypes.number,
};
