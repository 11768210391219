import { useEffect, useState } from 'react';
import NotificationsServices from '../../../services/Notifications/NotificationsServices';

function useNotificationsLoader(tower: { id: string }, active) {
  const [towerId, setTowerId] = useState<string | null>(null);
  const [isApprovalsNotificationEnabled, setApprovals] = useState(false);

  const loadApprovals = async (towerId: string) => {
    const notificationServices = new NotificationsServices();
    const { data } = await notificationServices.getApprovals(towerId);

    setApprovals(data.hasAdditionalAreas || data.hasWithdrawal);
  };

  useEffect(() => {
    if (tower && tower.id) {
      setTowerId(tower.id);
      loadApprovals(tower.id);
    }
  }, [tower, active]);

  return { isApprovalsNotificationEnabled };
}

export default useNotificationsLoader;
