import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

import TableCellNonEditingComponent from '../../../../shared/tables/TableCellNonEditingComponent/TableCellNonEditingComponent';
import ContractsEvalproGridNumberFormatter from './ContractsEvalproGridNumberFormatter';

const TableCellNonEditing = TableCellNonEditingComponent(ContractsEvalproGridNumberFormatter);

const ContractsEvalproGridNumberProvider: React.FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider
    formatterComponent={ContractsEvalproGridNumberFormatter}
    editorComponent={TableCellNonEditing}
    {...props}
  />
);

export default ContractsEvalproGridNumberProvider;
