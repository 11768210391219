import React from 'react'
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Style from '../styles/SalesRoomTableHeaderCustomCell.module.scss';

const SalesRoomTableHeaderCustomCell = (props) => {
  const { tableColumn } = props;
  const {column} = tableColumn;
  const {title} = column;

  if (column.name === "properties") {
    return (
      <VirtualTable.Cell 
      className={Style.propertiesCellHeader}
      {...props}
      />
      
    )
  }

  return (
    <VirtualTable.Cell className={Style.headerCell}>
        <Typography variant="body2" className={Style.headerTypography}>
          {title}
        </Typography>
      </VirtualTable.Cell>
  )
}

SalesRoomTableHeaderCustomCell.propTypes = {
  tableColumn: PropTypes.object,
}

export default SalesRoomTableHeaderCustomCell
