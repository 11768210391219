import React, { FC, ReactNode } from 'react';
import { Box, makeStyles, Paper, Theme } from '@material-ui/core';
import { Contractor } from '../../../../../models/Contractors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: (props: { isContractor: boolean }) =>
      props.isContractor ? 'initial' : 'pointer',
    borderRadius: 0,
    boxShadow: (props: { isContractor: boolean }) =>
      props.isContractor
        ? `${theme.shadows[1]}, -4px 0 ${theme.palette.secondary.main} inset`
        : theme.shadows[1],
    '&:hover': {
      boxShadow: (props: { isContractor: boolean }) =>
        props.isContractor
          ? `${theme.shadows[1]}, -4px 0 ${theme.palette.secondary.main} inset`
          : theme.shadows[3],
    },
  },
  title: {
    fontSize: '1em',
    fontWeight: 'bold',
  },
  section: {
    '& span': {
      fontWeight: 'bold',
    },
  },
}));

export interface IContractorItem {
  contractor: Contractor;
  onClick: (contractor: Contractor) => void;
  isSelect?: (contractor: Contractor) => boolean;
  action?: (contractor: Contractor) => ReactNode;
}

const ContractorItem: FC<IContractorItem> = ({
  contractor,
  onClick,
  isSelect,
  action,
}) => {
  const classes = useStyles({
    isContractor: isSelect ? !!isSelect(contractor) : false,
  });
  return (
    <Box mb={2}>
      <Paper className={classes.root} onClick={() => onClick(contractor)}>
        <Box p={2}>
          <Box className={classes.title} pb={1}>
            {contractor.patnerName}
          </Box>
          <Box className={classes.section} pb={1}>
            <span>Correo:</span>{' '}
            {contractor.patnerEmail || 'Sin correo electrónico'}
          </Box>
          <Box className={classes.section} pb={1}>
            <span>N° Documento:</span>{' '}
            {contractor.patnerVatNumber || 'No hay número de identificación'}
          </Box>
          {action ? (
            <Box className={classes.section}>{action(contractor)}</Box>
          ) : null}
        </Box>
      </Paper>
    </Box>
  );
};

export default ContractorItem;
