import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { NumberFormatValues } from 'react-number-format';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { ContractLot } from '../../../../models/Contracts';
import Services from '../../../../services/ContractsLots/ContractsLotsServices';
import constants from './constants';

const services = new Services();
const useEditInterestRate = (contractId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (contract: Partial<ContractLot>) => {
      const { data: editedContract } = await services.editInterestRate(contractId, contract);
      return editedContract;
    },
    {
      onSuccess: (editedContract) => {
        queryClient.setQueryData([constants.CONTRACT_LOT, contractId], editedContract);
        queryClient.invalidateQueries([constants.CONTRACTS_LOTS]);
      },
    },
  );
};

type Props = {
  currentContractId: number;
};

type HookType = (props: Props) => {
  isValidPercentage: (value: NumberFormatValues) => boolean;
  handleInterestRateChange: (value: NumberFormatValues) => void;
  onEditInterestRate: () => void;
  lotInterestRate: number;
  monthlyInterestRate: number;
  dailyInterestRate: number;
};

const useContractsLotsInterestRates: HookType = ({ currentContractId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient: QueryClient = useQueryClient();

  const contract = queryClient.getQueryData<ContractLot>([constants.CONTRACT_LOT, currentContractId]);

  const { mutateAsync: editInterestRate } = useEditInterestRate(currentContractId || 0);

  const [lotInterestRate, setLotInterestRate] = useState<number>(0);
  const [monthlyInterestRate, setMonthlyInterestRate] = useState<number>(0);
  const [dailyInterestRate, setDailyInterestRate] = useState<number>(0);

  useEffect(() => {
    if (!!contract) {
      setLotInterestRate(contract?.lotInterestRate);
    }
  }, [contract]);

  const handleInterestRateChange = (inputValue: NumberFormatValues) => {
    setLotInterestRate(inputValue.floatValue);
    const monthlyRate = (Math.pow(1 + inputValue.floatValue / 100, 1 / 12) - 1) * 100;
    const dailyRate = (Math.pow(1 + monthlyRate / 100, 1 / 12) - 1) * 100;
    setMonthlyInterestRate(monthlyRate);
    setDailyInterestRate(dailyRate);
  };

  const onEditInterestRate = async () => {
    try {
      await editInterestRate({
        lotInterestRate,
      });
      enqueueSnackbar('Tasa de interes actualizada con éxito', {
        variant: 'success',
      });
    } catch ({ message }) {
      enqueueSnackbar(`${message}`, { variant: 'error' });
    }
  };

  const isValidPercentage = (inputValue: NumberFormatValues) => {
    const { floatValue } = inputValue;
    if (Number(floatValue) >= 0 && Number(floatValue) <= 100) return true;
    return false;
  };

  return {
    isValidPercentage,
    onEditInterestRate,
    handleInterestRateChange,
    lotInterestRate,
    monthlyInterestRate,
    dailyInterestRate,
  };
};

export default useContractsLotsInterestRates;
