import { useState } from 'react';
import { Table, Column } from '@devexpress/dx-react-grid';

import { rightAlign } from '../../../../../../shared/tables/TableColumnFormat';

type HookType = () => {
  columns: Column[];
  titleColumns: string[];
  currencyColumns: string[];
  tableColumnExtensions: Table.ColumnExtension[];
};

const getColumns = (): Column[] => {
  return [
    {
      title: 'Nombre',
      name: 'nomenclature',
    },
    {
      title: 'Unidad',
      name: 'unit',
    },
    {
      title: 'Cant.',
      name: 'measure',
    },
    {
      title: 'Precio Unitario',
      name: 'subtotal',
      getCellValue: (row) => {
        if (row.measure > 0) {
          return Math.ceil(row.price / row.measure);
        }
        return 0;
      },
    },
    {
      title: 'Precio Total',
      name: 'price',
    },
  ];
};

const useColumnsAndRowsForAdditionalAreas: HookType = () => {
  const tableColumnFormat: Table.ColumnExtension[] = [
    { columnName: 'nomenclature', width: 300 },
    { columnName: 'subtotal', align: rightAlign },
    { columnName: 'price', align: rightAlign },
    { columnName: 'measure', align: rightAlign },
  ];

  const [columns] = useState(getColumns());
  const [titleColumns] = useState(['nomenclature']);
  const [currencyColumns] = useState(['price', 'subtotal']);
  const [tableColumnExtensions] = useState(tableColumnFormat);

  return { columns, titleColumns, currencyColumns, tableColumnExtensions };
};

export default useColumnsAndRowsForAdditionalAreas;
