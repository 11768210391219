import React, { FC } from 'react';
import { GeneralWalletThreadInteractionDTO } from 'App/GeneralWalletDetails/core/DTO/GeneralWalletComment.dto';
import GeneralWallletDetailsThreadInteractionComment from './GeneralWallletDetailsThreadInteractionComment';
import GeneralWallletDetailsThreadInteractionFile from './GeneralWallletDetailsThreadInteractionFile';

import styles from './GeneralWalletDetailsThread.module.scss';

const GeneralWallletDetailsThreadInteraction: FC<GeneralWalletThreadInteractionDTO> = (interaction) => {
  return (
    <div className={styles.ThreadInteractionContainer} key={'GeneralWallletDetailsThreadInteraction' + interaction.id}>
      {interaction.comment && <GeneralWallletDetailsThreadInteractionComment {...interaction} />}
      {interaction.urlAttachedFileURL && <GeneralWallletDetailsThreadInteractionFile {...interaction} />}
    </div>
  );
};

export default GeneralWallletDetailsThreadInteraction;
