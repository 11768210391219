export const BASE_CONTROL = [
  { name: 'name', title: 'Nombre' },
  { name: 'total', title: 'Total' },
  { name: 'percentage', title: '%Ventas' },
  { name: 'accumulated', title: 'Total acumulado' },
  { name: 'projected', title: 'Valor pendiente' },
];

export const CUT_CONTROL_WITHOUT_CUT_DATE = [
  { name: 'name', title: 'Nombre', columnName: 'name', parentColumn: 'empty' },
  { name: 'total', title: 'Corte actual', columnName: 'total', parentColumn: 'empty' },
  { name: 'percentage', title: '%Ventas', columnName: 'percentage', parentColumn: 'empty' },
  { name: 'previousControl', title: 'Corte anterior', columnName: 'previousControl', parentColumn: 'Corte Vacío' },
  { name: 'controlPercentage', title: '%Ventas', columnName: 'controlPercentage', parentColumn: 'Corte Vacío' },
  { name: 'variation', title: 'Variación', columnName: 'variation', parentColumn: 'empty' },
];

export const CUT_CONTROL_WITH_CUT_DATE = (cutDateString) => {
  return [
    { name: 'name', title: 'Nombre', columnName: 'name', parentColumn: 'empty' },
    { name: 'total', title: 'Corte actual', columnName: 'total', parentColumn: 'empty' },
    { name: 'percentage', title: '%Ventas', columnName: 'percentage', parentColumn: 'empty' },
    { name: 'previousControl', title: 'Corte anterior', columnName: 'previousControl', parentColumn: cutDateString },
    { name: 'controlPercentage', title: '%Ventas', columnName: 'controlPercentage', parentColumn: cutDateString },
    { name: 'variation', title: 'Variación', columnName: 'variation', parentColumn: 'empty' },
  ];
};

export const BUDGET_CONTROL_WITH_EVENT = (budgetName) => {
  return [
    { name: 'name', title: 'Nombre', columnName: 'name', parentColumn: 'empty' },
    { name: 'total', title: 'Corte actual', columnName: 'total', parentColumn: 'empty' },
    { name: 'percentage', title: '%Ventas', columnName: 'percentage', parentColumn: 'empty' },
    { name: 'previousControl', title: 'Corte anterior', columnName: 'previousControl', parentColumn: budgetName || "Evento vacio" },
    { name: 'controlPercentage', title: '%Ventas', columnName: 'controlPercentage', parentColumn: budgetName  || "Evento vacio" },
    { name: 'variation', title: 'Variación', columnName: 'variation', parentColumn: 'empty' },
  ];
};

