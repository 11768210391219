import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import TotalValue from './TotalValue';
import AssignedValue from './AssignedValue';
import Edit from './Edit';
import useQuotationEditionType from '../../../../../../../../App/Quotation/Core/customHooks/useQuotationEditionType';

const Container = styled.div`
  position: fixed;
  bottom: 8px;
  left: 8px;
`;

const EditDashboard = ({
  editableToggleModeHandler,
  propertyPrice,
  distribution,
  reservationDeposit,
  finalFee,
  discountPrice,
  enableDiscount,
}) => {
  const { isEditingMode: edit, temp, isAssigned } = useQuotationEditionType();
  return (
    <Container>
      <Grid container direction="column" spacing={3}>
        {edit && (
          <>
            <Grid item>
              <TotalValue propertyPrice={propertyPrice} />
            </Grid>
            <Grid item>
              <AssignedValue
                discountPrice={discountPrice}
                distribution={distribution}
                reservationDeposit={reservationDeposit}
                finalFee={finalFee}
                propertyPrice={propertyPrice}
                enableDiscount={enableDiscount}
              />
            </Grid>
          </>
        )}
        {!temp && isAssigned && (
          <Grid item>
            <Edit editableToggleModeHandler={editableToggleModeHandler} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

EditDashboard.propTypes = {
  editableToggleModeHandler: PropTypes.func.isRequired,
  distribution: PropTypes.array.isRequired,
  propertyPrice: PropTypes.number.isRequired,
  reservationDeposit: PropTypes.number.isRequired,
  finalFee: PropTypes.number.isRequired,
  discountPrice: PropTypes.number,
  discountPercentage: PropTypes.number,
  priceWithDiscount: PropTypes.number,
  enableDiscount: PropTypes.bool,
};

export default EditDashboard;
