import { Table, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import React, { FC } from 'react';
import TableCell from 'shared/tables/TableCell';
import styled from 'styled-components';
import styles from './NewAreaColumnCell.module.scss';
import colors from 'assets/styles/settings/colors.module.scss';

const TableCellTag: React.FC<Table.CellProps> = styled(TableCell)<Table.DataCellProps>`
  /* Box-model */
  padding: 0;
  position: relative;
  border: 0;
  border-left: 1px solid ${colors.gray300};
  border-right: 1px solid ${colors.gray300};
`;

const NewAreaColumnCell: FC<Table.CellProps> = (props) => {
  return (
    <TableCellTag {...props}>
      <div className={styles.verticalLine}></div>
    </TableCellTag>
  );
};

export default NewAreaColumnCell;
