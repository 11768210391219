import React, { FC } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateRangeOutlined } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import MomentUtils from '@date-io/moment';

import { ClientProps } from 'App/Clients/Core/interfaces/ClientProps';
import usePersonalInformation from 'App/Clients/Core/customHooks/usePersonalInformation';
import IdentityDocumentType from 'App/Clients/Core/types/IdentityDocumentType';

const PersonalInformation: FC<ClientProps> = (props) => {
  const {
    data,
    errors,
    handleChange,
    handleChangeDate,
    handleChangeSelect,
    handleChangeValue,
    handleSubmit,
  } = usePersonalInformation(props);
  return (
    <>
      {props.title && (
        <Box marginBottom={2}>
          <Typography variant="h5">{props.title}</Typography>
        </Box>
      )}
      <form onSubmit={handleSubmit} id={props.formId} />
      <Box display="flex" justifyContent="center">
        <Grid container item md={8} spacing={2}>
          {props.children}
          <Grid item md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Primer nombre *"
              value={data.name || ''}
              onChange={handleChange('name')}
              helperText={errors.name}
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Segundo nombre"
              value={data.middleName || ''}
              onChange={handleChange('middleName')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Primer apellido *"
              value={data.surname || ''}
              onChange={handleChange('surname')}
              helperText={errors.surname}
              error={!!errors.surname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Segundo apellido"
              value={data.secondSurname || ''}
              onChange={handleChange('secondSurname')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors?.identityDocumentType}>
              <InputLabel id="identityDocumentTypeLabel">
                Tipo de documento *
              </InputLabel>
              <Select
                variant="outlined"
                labelId="identityDocumentTypeLabel"
                id="identityDocumentType"
                value={data?.identityDocumentType || ''}
                label="Seleccionar"
                onChange={handleChangeSelect('identityDocumentType')}
              >
                {IdentityDocumentType.map((item, index) => (
                  <MenuItem
                    value={item.id}
                    key={index}
                    selected={item.id === data.identityDocumentType}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors?.identityDocumentType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Número de documento *"
              value={data.identityDocument || ''}
              onChange={handleChange('identityDocument')}
              helperText={errors.identityDocument}
              error={!!errors.identityDocument}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                // defaultValue={data.expeditionDate}
                value={data.expeditionDate || null}
                format="DD/MM/YYYY"
                onChange={(date) =>
                  handleChangeDate('expeditionDate')(date?.toDate() || null)
                }
                maxDate={new Date()}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    label="Fecha de expedición"
                    fullWidth
                    helperText={errors.expeditionDate}
                    error={!!errors.expeditionDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Correo electrónico *"
              value={data.email || ''}
              onChange={handleChange('email')}
              helperText={errors.email}
              error={!!errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              customInput={TextField}
              defaultValue={data.phoneNumber}
              value={data.phoneNumber}
              onValueChange={(values) =>
                handleChangeValue('phoneNumber', values.value)
              }
              // @ts-ignore
              fullWidth
              label="Teléfono de contacto *"
              variant="outlined"
              helperText={errors.phoneNumber}
              error={!!errors.phoneNumber}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PersonalInformation;
