import { useEffect, useState } from 'react';
import IntegrationService from '../../Integrations/Services/IntegrationService';
import CRMServices from '../Services/CRMServices';
import useResolveCompany from './useResolveCompany';

const useCRMList = (forceLoad = false) => {
  const { company } = useResolveCompany();
  const service = new CRMServices();
  const hubspotService = IntegrationService.Hubspot();
  const [hubspotIsEnabled, setHubspotIsEnabled] = useState(false);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [closingReasons, setClosingReasons] = useState([]);
  const [questionsOne, setQuestionsOne] = useState([]);

  const filterFieldsByType = (type) => fields.filter((field) => field?.type === type);

  useEffect(() => {
    async function call() {
      setLoading(true);
      if (forceLoad) {
        const { data: hubspotData } = await hubspotService.isEnabled(company);
        setHubspotIsEnabled(hubspotData?.isEnabled);
        if (hubspotData?.isEnabled)
          await hubspotService.refreshProperties(company);
      }

      const { data } = await service.getFields(company);
      if (Array.isArray(data)) setFields(data);
      setLoading(false);
    }

    if (company) call();
  }, [company]);

  useEffect(() => {
    if (Array.isArray(fields)) {
      setClosingReasons(filterFieldsByType(CRMServices.CRMOptionTypes.BUSINESS_CLOSING_REASON.code));
      setQuestionsOne(filterFieldsByType(CRMServices.CRMOptionTypes.QUESTION_1.code));
    }
  }, [fields]);

  const removeField = async ({ id }) => {
    const { data } = await service.deleteField({ id });
    if (data.deleted) setFields(fields.filter((field) => field.id !== id));
  };

  const updateField = async ({ id, value }) => {
    const { data } = await service.updateField({
      id,
      value,
    });
    if (data.updated)
      setFields(
        fields.map((field) => {
          return field.id === id ? { ...field, value } : field;
        }),
      );
  };

  const createField = async (value, type) => {
    const { data } = await service.createField(company, type, {
      value,
    });
    const { id } = data;
    if (id) setFields([{ id, value, type, new: true }, ...fields]);
  };

  return {
    closingReasons,
    questionsOne,
    removeField,
    updateField,
    createField,
    hubspotIsEnabled,
    loading,
    crmFieldTypes: CRMServices.CRMOptionTypes,
  };
};

export default useCRMList;
