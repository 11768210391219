import { Box } from '@material-ui/core';
import { ITabOption } from 'App/DeedExpenses/core/interfaces/ITabOption';
import React, { FC } from 'react';
import TotalCashFlowTabOption from '../TotalCashFlowTabOption/TotalCashFlowTabOption';

interface Props {
  options: ITabOption[];
  onClick: (ITabOption) => void;
}

const TotalCashFlowTabNavigator: FC<Props> = ({ options, onClick }) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {options.map(TotalCashFlowTabOption(onClick))}
    </Box>
  );
};

export default TotalCashFlowTabNavigator;