import React, { FC } from "react";
import { Alignment, Navbar, Tab, Tabs } from "@blueprintjs/core";
import { Box, Paper } from "@material-ui/core";
import useStyles from "./styles/styles";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { useContractorContext } from "../Context/ContractorContext";
import useContractorInformation from "../../../Core/customHooks/useContractorInformation";
import ContractorForm from "./ContractorForm/ContractorForm";

const ContractorInformation: FC<{ onClose: () => void, onDelete: () => void }> = ({ onClose, onDelete }) => {
  const classes = useStyles();
  const { currentContractor } = useContractorContext();
  const { tab, setTab, deleteContractor } = useContractorInformation();
  return (
    <Paper elevation={2}>
      <Navbar>
        {currentContractor?.id ? (
          <Navbar.Group align={Alignment.LEFT}>
            <Tabs
              animate
              id="navbar"
              large
              onChange={(id) => setTab(id.toString())}
              selectedTabId={tab}
            >
              <Tab id="Home" title="Información" />
            </Tabs>
          </Navbar.Group>
        ) : null}
        <Navbar.Group
          align={currentContractor?.id ? Alignment.RIGHT : Alignment.LEFT}
        >
          <Navbar.Heading>
            <Box display="flex" alignItems="center">
              <span className={classes.title}>
                {currentContractor?.patnerName || "Nuevo contratista"}
              </span>
              {currentContractor?.id ? (
                <Box ml={2}>
                  <ButtonGroup fill>
                    <Button
                      icon="trash"
                      onClick={() => {
                        setTab("Home");
                        deleteContractor(currentContractor);
                        onClose();
                      }}
                    />
                    <Button
                      icon="cross"
                      onClick={() => {
                        setTab("Home");
                        onClose();
                      }}
                    />
                  </ButtonGroup>
                </Box>
              ) : null}
            </Box>
          </Navbar.Heading>
        </Navbar.Group>
      </Navbar>
      {tab === "Home" ? <ContractorForm /> : null}
    </Paper>
  );
};

export default ContractorInformation;
