import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import React, { FC, MouseEvent } from 'react';

import { ClientDetail } from 'App/GeneralWallet/core/interfaces/generalWallet.types';

import styles from './GeneralWalletDetailsProvider.module.scss';

type onClick = (e: MouseEvent, payload?: ClientDetail) => void;

const GeneralWalletClientFormatterComponent: FC<DataTypeProvider.ValueFormatterProps & { onClick: onClick }> = ({
  value,
  row,
  onClick,
}) => {
  return (
    <div className={styles.fakeComponent} onClick={(e) => e.stopPropagation()}>
      <IconButton
        className={styles.icon}
        id={`details-button-${row.clientId}-${row.propertyId}`}
        onClick={(e) => onClick(e, row)}
      >
        <MoreVert />
      </IconButton>
      <span>{value}</span>
    </div>
  );
};

const GeneralWalletClientFormatter =
  (onClick: onClick): FC<DataTypeProvider.ValueFormatterProps> =>
  (props) =>
    <GeneralWalletClientFormatterComponent {...props} onClick={onClick} />;

interface GeneralWalletDetailsProviderProps extends DataTypeProviderProps {
  onClick: onClick;
}

const GeneralWalletDetailsProvider: FC<GeneralWalletDetailsProviderProps> = (props) => {
  return <DataTypeProvider {...props} formatterComponent={GeneralWalletClientFormatter(props.onClick)} />;
};

export default GeneralWalletDetailsProvider;
