import React from 'react';

import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import useArrearsReportRowsMapper from '../../../Core/customHooks/useArrearsReportRowsMapper';
import ArrearsClientsReportNumberCell from '../customViews/ArrearsClientsReportNumberCell';

const ArrearsClientsReportTable = () => {
  const {
    rows,
    columns,
    leftColumns,
    tableColumnExtensions,
    defaultSorting,
  } = useArrearsReportRowsMapper();

  return (
    <Grid rows={rows} columns={columns}>
      <SortingState defaultSorting={defaultSorting} />
      <IntegratedSorting />
      <VirtualTable
        height="90vh"
        columnExtensions={tableColumnExtensions}
        cellComponent={ArrearsClientsReportNumberCell}
      />
      <TableHeaderRow showSortingControls />
      <TableFixedColumns leftColumns={leftColumns} />
    </Grid>
  );
};

export default ArrearsClientsReportTable;
