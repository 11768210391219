import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import StagesDTO from '../DTO/StagesDTO';
import MoveCardTypes from '../enums/MoveCardTypes';
import {
  MoveCords,
  MoveCardHandler,
  MoveTowerHandler,
  MoveTowerPoint,
  MoveCardPoint,
  MoveTowerErrorHandler,
  OnUpdateStages,
  QueueData,
} from '../types/Stages.types';
import MoveTowerQueue from '../utils/MoveTowerQueue';

const requestQueue = new MoveTowerQueue();

type MoveCardCords = MoveCords<MoveCardPoint>;

type MoveTowerCords = MoveCords<MoveTowerPoint>;

const isMovedType = (type: MoveCardTypes) => type === MoveCardTypes.Moved;

const getMoveTowerPoint = ({ row, column }: MoveCardPoint): MoveTowerPoint => ({
  index: row,
  stageId: column,
});

const getMoveTowerCords = ({ from, to }: MoveCardCords): MoveTowerCords => ({
  from: getMoveTowerPoint(from),
  to: getMoveTowerPoint(to),
});

const isValidMovement = ({ to }: MoveCardCords) => to.column !== -1;

type UseMoveStageTower = {
  stages: StagesDTO;
  moveTower: MoveTowerHandler;
  updateStages: OnUpdateStages;
};

function useMoveStageTower({ stages: currentStages, moveTower, updateStages }: UseMoveStageTower) {
  const { enqueueSnackbar } = useSnackbar();

  const handleRequestError: MoveTowerErrorHandler = (error) => {
    enqueueSnackbar(error.data.message, { variant: 'error' });
    updateStages(error.stages);
  };

  useEffect(() => {
    requestQueue.onError(handleRequestError);
  }, []);

  const requestMoveTower = ({ towerId, cords, stages }: QueueData) => {
    requestQueue.moveTower({ towerId, cords, stages });
  };

  const handleMoved = (cords: MoveCardCords) => {
    moveTower(getMoveTowerCords(cords));
  };

  const handleMoveEnd = (cardId: string, cords: MoveCardCords) => {
    if (isValidMovement(cords)) {
      requestMoveTower({
        towerId: cardId,
        cords: getMoveTowerCords(cords),
        stages: currentStages,
      });
    }
  };

  const handleMoveCardEnd: MoveCardHandler = ({ type, cardId, cords }) => {
    isMovedType(type) ? handleMoved(cords) : handleMoveEnd(cardId, cords);
  };

  return handleMoveCardEnd;
}

export default useMoveStageTower;
