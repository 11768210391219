import MainStreetType from '../enums/MainStreetType.enum';
import CardinalityType from '../enums/CardinalityType.enum';
import ClientIdentityType from '../enums/ClientIdentityType.enum';

class AddressForClientFunctions {
  static getDescriptionForAddress(address) {
    if (!address) return '';

    const withSpace = (field, dash = false) =>
      field ? `${field}${dash ? ' - ' : ' '}` : '';
    const validatedWithDefault = (value) => {
      if (value === 0) return value;
      return value || -1;
    };

    const mainStreetType = MainStreetType.findOneByCode(
      validatedWithDefault(address.mainStreetType),
    ).value;
    const mainStreetCardinality = CardinalityType.findOneByCode(
      validatedWithDefault(address.mainStreetCardinality),
    ).value;
    const secondaryStreetCardinality = CardinalityType.findOneByCode(
      validatedWithDefault(address.secondaryStreetCardinality),
    ).value;

    const firstIdentityType = ClientIdentityType.findOneByCode(
      validatedWithDefault(address.firstIdentityType),
    ).value;

    const secondIdentityType = ClientIdentityType.findOneByCode(
      validatedWithDefault(address.secondIdentityType),
    ).value;

    return (
      withSpace(mainStreetType) +
      withSpace(address.mainStreetNumber, true) +
      withSpace(mainStreetCardinality) +
      withSpace(address.secondaryStreetNumber, true) +
      withSpace(address.distance) +
      withSpace(secondaryStreetCardinality) +
      withSpace(firstIdentityType) +
      withSpace(address.firstIdentityNumber) +
      withSpace(secondIdentityType) +
      withSpace(address.secondIdentityNumber) +
      withSpace(address.neighborhood)
    );
  }
}

export default AddressForClientFunctions;
