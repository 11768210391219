import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import useAutocompletePropertyType from '../../../Core/customHooks/useAutocompletePropertyType';

const PropertyType = (props) => {
  const {
    value,
    onChange,
    filterOptions,
    onBlur,
    types,
    loading,
    getOptionLabel,
  } = useAutocompletePropertyType(props);

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      filterOptions={filterOptions}
      handleHomeEndKeys
      onBlur={onBlur}
      options={types}
      clearOnBlur={true}
      loading={loading}
      getOptionLabel={getOptionLabel}
      renderOption={(type) => type.name}
      noOptionsText="Creé su primer tipo de propiedad"
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

PropertyType.propTypes = {
  property: PropTypes.object,
  type: PropTypes.object,
  types: PropTypes.array,
  updatePropertyTypes: PropTypes.func,
  updateRows: PropTypes.func,
};

export default PropertyType;
