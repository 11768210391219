export const calculateInitialFee = (propertyPrice, initialFeeRate) => {
  return propertyPrice * initialFeeRate;
};

export const calculateInitialFeePercentageWithDistribution = (
  distribution,
  separatePrice,
  propertyPrice,
) => {
  const initialDistributionPrice = distribution.reduce(
    (total, number) => total + number,
    0,
  );
  const initialFee = initialDistributionPrice + separatePrice;
  const initialFeePercentage = initialFee / propertyPrice;
  return initialFeePercentage;
};

export const calculateReservationDeposit = (
  propertyPrice,
  initialFeeRate,
  reservationRate,
) => {
  return calculateInitialFee(propertyPrice, initialFeeRate) * reservationRate;
};

export const getMonthlyPayment = (
  propertyPrice,
  initialFeeRate,
  reservationRate,
  periods,
) => {
  return (
    (calculateInitialFee(propertyPrice, initialFeeRate) -
      calculateReservationDeposit(
        propertyPrice,
        initialFeeRate,
        reservationRate,
      )) /
    periods
  );
};

export const getFinalPayment = (propertyPrice, initialFeeRate) => {
  return propertyPrice - calculateInitialFee(propertyPrice, initialFeeRate);
};

export const getFinalPercentage = (propertyPrice, finalPayment) => {
  return finalPayment / propertyPrice;
};

export const getPayments = () => {
  return [];
};
