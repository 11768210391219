import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Box, TextField } from '@material-ui/core';
import Numbers from '../../../../../../helpers/numbers';
import classes from '../../../styles/ResultsStateCustomCell.module.scss';
import { useEffect } from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

interface State {
  numberformat: string;
}

export default function NumberFormatInput({ cellData, divider, decimals, handleChange, row }) {
  const [values, setValues] = React.useState<State>({
    numberformat: '',
  });

  useEffect(() => {
    if (values.numberformat != cellData) {
      setValues({
        ...values,
        numberformat: cellData,
      });
    }
  }, [cellData]);

  return (
    <Box>
      <TextField
        className={classes.editableCell}
        value={values.numberformat}
        onBlur={(event) => {
          handleChange(event, row);
          setValues({
            ...values,
            [event.target.name]: Numbers.parseLocaleNumber(event.target.value),
          });
        }}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        variant="outlined"
        size="small"
      />
    </Box>
  );
}
