import { Column, ColumnBands, Table } from '@devexpress/dx-react-grid';
import { useEffect, useState } from 'react';

import {
  faseColumns,
  phasesColumnsBands,
  phasesFormattedColumns,
  preSalesUnitsColumns,
  salesSpeedColumnExtensions,
} from '../constants/salesSpeedHelperTableColumns';
import {
  SalesSpeedBaseTablesDTO,
  SalesSpeedHelperPayload,
  SalesSpeedTableRow,
  SalesSpeedTablesDTO,
} from '../DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperTables from '../enums/SalesSpeedHelperTables';

const getTables = (currentTab: SalesSpeedHelperTables, salesSpeedPayload = {}): SalesSpeedTablesDTO =>
  salesSpeedPayload[currentTab];

type ColumnBandsByFases = {
  preSales: ColumnBands[];
  sales: ColumnBands[];
  total: ColumnBands[];
};

type UseSalesSpeedHelperTableProps = {
  currentTab: SalesSpeedHelperTables;
  salesSpeedPayload?: SalesSpeedHelperPayload;
};

function useSalesSpeedHelperTable({ currentTab, salesSpeedPayload }: UseSalesSpeedHelperTableProps) {
  const currentTables = getTables(currentTab, salesSpeedPayload);

  const [primaryColumns] = useState<Column[]>(preSalesUnitsColumns);
  const [secondaryColumns] = useState<Column[]>(faseColumns);
  const [formattedColumns] = useState<string[]>(phasesFormattedColumns);
  const [columnsBands] = useState<ColumnBandsByFases>(phasesColumnsBands);
  const [columnExtensions] = useState<Table.ColumnExtension[]>(salesSpeedColumnExtensions);
  const [preSalesRows, setPreSalesRows] = useState<SalesSpeedTableRow[]>(currentTables.preSales);
  const [salesRows, setSalesRows] = useState<SalesSpeedTableRow[]>(currentTables.sales);
  const [totalRows, setTotalRows] = useState<SalesSpeedTableRow[] | undefined>(currentTables.total);

  useEffect(() => {
    setPreSalesRows(currentTables.preSales);
    setSalesRows(currentTables.sales);
    setTotalRows(currentTables.total);
  }, [salesSpeedPayload, currentTab]);

  return {
    primaryColumns,
    secondaryColumns,
    formattedColumns,
    columnsBands,
    columnExtensions,
    preSalesRows,
    salesRows,
    totalRows,
  };
}

export default useSalesSpeedHelperTable;
