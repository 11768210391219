import Dexie, { Table } from 'dexie';
import { TotalsID } from '../constants/AreasConstants';
import IPropertyArea from '../interfaces/IPropertyArea';
import { ColumnTotals } from '../types/ColumnTotals';

export const AREAS_DB_NAME = 'dbAreas';

export class AreaIndexedDB extends Dexie {
  properties!: Table<IPropertyArea>;
  totals!: Table<ColumnTotals>;
  running: boolean;
  constructor() {
    super(AREAS_DB_NAME);
    this.version(1).stores({
      properties: 'id, name, location, floor, status, privateArea, total, group',
      totals: 'id, privateArea, total',
    });
    this.running = false;
  }

  cleanDBProperties = () => this.properties.clear();

  cleanDBTotals = () => this.totals.clear();

  async bulkPropertiesIntoDB(properties: IPropertyArea[]) {
    await this.cleanDBProperties();
    await this.properties.bulkAdd(properties ?? []);
  }

  async bulkColumnTotalsIntoDB(totals: ColumnTotals) {
    await this.cleanDBTotals();
    await this.totals.add({ id: TotalsID, ...totals });
  }

  async initialize(properties: IPropertyArea[], totals: ColumnTotals) {
    await this.bulkPropertiesIntoDB(properties ?? []);
    await this.bulkColumnTotalsIntoDB(totals ?? {});
    this.running = true;
  }

  async cleanDB() {
    await this.cleanDBProperties();
    await this.cleanDBTotals();
  }
}

export const AreasDB = new AreaIndexedDB();
