import React, { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@material-ui/core';
import { SegmentedControl } from 'segmented-control';
import QuotationsList from 'components/Client2/List/Row/QuotationsList/QuotationsList';
import useUpdateClientModalContent, {
  UpdateClientModalContentProps,
} from 'App/Clients/Core/customHooks/useUpdateClientModalContent';
import UpdateAccordion from '../Accordion/UpdateAccordion/UpdateAccordion';
import PersonalInformation from '../Sections/PersonalInformation/PersonalInformation';
import AssignedAgent from '../Sections/AssignedAgent/AssignedAgent';
import HowDidFindAboutProject from '../Sections/HowDidFindAboutProject/HowDidFindAboutProject';
import AdditionalInformation from '../Sections/AdditionalInformation/AdditionalInformation';
import GoToSalesRoom from '../ClientButtons/GoToSalesRoom/GoToSalesRoom';
import LaboralInformation from '../Sections/LaboralInformation/LaboralInformation';
import useAddCientToTower from 'App/Clients/Core/customHooks/useAddCientToTower';

import clientModalStyles from '../Modals/ClientModal.module.scss';
import buttonStyles from '../ClientButtons/ClientButtons.module.scss';
import styles from './UpdateClientModalContent.module.scss';

const UpdateClientModalContent: FC<UpdateClientModalContentProps> = (props) => {
  const { openDetails } = props;
  const {
    towerId,
    originalClient,
    client,
    clientCompany,
    clientCompanyAgent,
    clientInformation,
    infoType,
    isFetch,
    setInfoType,
    handleClientChange,
    handleClientCompanyChange,
    handleClientInformationChange,
    handleClientAddressSectionChange,
  } = useUpdateClientModalContent(props);
  const { addClientToTower, isLoading: isLoadingAddClientToTower } =
    useAddCientToTower(towerId, client?.id);

  return (
    <>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <Grid item xs={6}>
            <SegmentedControl
              className={styles.switch}
              name="infoType"
              options={[
                {
                  label: 'Información personal',
                  value: 'personalInformation',
                  default: true,
                },
                { label: 'Información laboral', value: 'laborData' },
              ]}
              setValue={setInfoType}
              variant="base"
            />
          </Grid>
        </Box>
        <Box
          className={clientModalStyles.content}
          paddingY={4}
          display="flex"
          justifyContent="center"
        >
          <Grid item md={10}>
            {infoType === 'personalInformation' ? (
              <>
                <UpdateAccordion
                  id="general"
                  title="Datos General"
                  formIds={[
                    'AssignedAgentForm',
                    'howDidFindAboutProjectForm',
                    'PersonalInformationForm',
                  ]}
                >
                  <PersonalInformation
                    onChangeModel={handleClientChange}
                    model={client}
                    formId="PersonalInformationForm"
                  >
                    <>
                      <Grid item md={12}>
                        <AssignedAgent
                          formId="AssignedAgentForm"
                          model={clientCompanyAgent}
                          onChangeModel={handleClientCompanyChange(
                            'assignedAgentId',
                          )}
                          agents={props.agents}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <HowDidFindAboutProject
                          formId="howDidFindAboutProjectForm"
                          model={clientCompany}
                          onChangeModel={handleClientCompanyChange(
                            'howDidFindAboutProjectId',
                          )}
                        />
                      </Grid>
                    </>
                  </PersonalInformation>
                </UpdateAccordion>
                <UpdateAccordion
                  id="aditional"
                  title="Datos Adicional"
                  formIds={['AdditionalInformationForm']}
                >
                  <AdditionalInformation
                    onChangeModel={handleClientInformationChange}
                    onChangeAddress={handleClientAddressSectionChange}
                    model={clientInformation}
                    formId="AdditionalInformationForm"
                  />
                </UpdateAccordion>
              </>
            ) : (
              <UpdateAccordion
                id="laboral"
                title="Datos Laborales"
                formIds={['LaboralInformationForm']}
              >
                <LaboralInformation
                  onChangeModel={handleClientInformationChange}
                  onChangeAddress={handleClientAddressSectionChange}
                  model={clientInformation}
                  formId="LaboralInformationForm"
                />
              </UpdateAccordion>
            )}
          </Grid>
          {isFetch && (
            <Box className={clientModalStyles.loader}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          className={clientModalStyles.footer}
          width="100%"
          padding={2}
        >
          {isFetch ||
            (isLoadingAddClientToTower && (
              <Box className={clientModalStyles.loader}>
                <CircularProgress />
              </Box>
            ))}
          {client?.id && (
            <>
              {originalClient && (
                <Box paddingTop={1} width="200px" display="flex">
                  <Button
                    className={buttonStyles.buttonSimple}
                    fullWidth
                    onClick={() => openDetails(originalClient)}
                  >
                    Ver detalles
                  </Button>
                </Box>
              )}
              <Box paddingTop={1} width="200px" display="flex">
                <QuotationsList
                  clientId={client?.id}
                  fullWidth={true}
                  buttonProps={{
                    className: buttonStyles.buttonSimple,
                  }}
                />
              </Box>
              <Box paddingTop={1} width="200px" display="flex">
                <GoToSalesRoom clientId={client?.id || ''} towerId={towerId} />
              </Box>
            </>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default UpdateClientModalContent;
