import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import ContractsLotsMain from './views/ContractsLotsMain';

const queryClient = new QueryClient();

const ContractsLotsMainView = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ContractsLotsMain />
    </QueryClientProvider>
  );
};

export default ContractsLotsMainView;
