import { API_PATH } from '../../../config/config';

const PATH = `${API_PATH}integrations`;
const HUBSPOT_PATH = `${PATH}/hubspot`;

export const HubspotDefinitions = {
  isEnabled: (companyId) => `${HUBSPOT_PATH}/${companyId}/is-enabled`,
  refreshProperties: (companyId) => `${HUBSPOT_PATH}/${companyId}/refresh-properties`,
};

const IntegrationServiceDefinitions = {
  ...HubspotDefinitions,
};

export default IntegrationServiceDefinitions;
