import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "../styles/ReactImageGalleryOverrides.scss";

export default function Gallery({ graphicMaterialFiles, customRef }) {

  const images = graphicMaterialFiles.map((file) => {
    return {
      original: `${file.path}`,
      thumbnail: `${file.path}`,
      id: file.id,
    };
  });

  return (
    <>
      <ImageGallery
        items={images}
        showPlayButton={false}
        ref={customRef}
      />
    </>
  );
}

