import { useEffect, useState } from 'react';
import { Numbers } from '../../../../helpers';

function useRowsForPaymentFlow(properties) {
  const [rows, setRows] = useState([]);

  const [defaultExpandedRowIds] = useState([]);

  const isProperty = (row) => {
    return row && !row.parentId;
  };

  const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter((nextRow) => {
      if (isProperty(row)) {
        return nextRow.parentId === row.id;
      }
      return nextRow.parentId === row;
    });
    return childRows.length ? childRows : null;
  };

  useEffect(() => {
    const newRows = properties.map((row) => {
      const currentRow = Object.keys(row).reduce((customRow, key) => {
        if (key === 'id' || key === 'parentId' || key === 'name') {
          return customRow;
        }

        return { ...customRow, [key]: Numbers.currencyFormat(row[key]) };
      }, row);
      return currentRow;
    });
    if (rows.length !== newRows.length) {
      setRows(newRows);
    }
  }, [properties]);

  return { rows, defaultExpandedRowIds, getChildRows };
}

export default useRowsForPaymentFlow;
