import EnumBase from '../../../../helpers/EnumBase';

class BuilderCreditFrequency {
  static MONTHLY = new EnumBase(1, 'Mensual');

  static QUARTERLY = new EnumBase(3, 'Trimestral');

  static BIANNUAL = new EnumBase(6, 'Semestral');
}

export default BuilderCreditFrequency;
