import React, { FC } from 'react';
import { InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Typography from 'shared/components/Typography/Typography';
import useUpdateQuotationDeedExpense from 'App/GeneralSetup/Core/customHooks/useUpdateQuotationDeedExpense';
import { DeedExpenseType } from 'App/QuotationConfig/core/DTO/QuotationConfigDto';
import QuotationConfigFormItem from '../../forms/QuotationConfigFormItem';
import QuotationConfigOutlinedInput from '../QuotationConfigOutlinedInput/QuotationConfigOutlinedInput';

interface Props {
  title: string;
  deedExpense: DeedExpenseType;
  soldName: keyof DeedExpenseType;
  buyerName: keyof DeedExpenseType;
  handleChangeValue: (propertyId: keyof DeedExpenseType, newValue: number) => void;
}

const QuotationConfigDeedExpensesItem: FC<Props> = (props) => {
  const { title, deedExpense, soldName, buyerName, handleChangeValue } = props;
  const { sold, buyer, finalPercentage, onChangeSold, onChangeBuyer, isAllowed } = useUpdateQuotationDeedExpense(
    soldName,
    buyerName,
    deedExpense,
    handleChangeValue,
  );
  return (
    <>
      <QuotationConfigFormItem item xs={3}>
        <Typography variant="body1">{title}</Typography>
      </QuotationConfigFormItem>
      <QuotationConfigFormItem item xs={3}>
        <NumberFormat
          thousandSeparator
          value={sold || 0}
          onValueChange={onChangeSold}
          customInput={QuotationConfigOutlinedInput}
          // @ts-ignore
          fullWidth
          isAllowed={isAllowed}
          decimalScale={2}
          fixedDecimalScale
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </QuotationConfigFormItem>
      <QuotationConfigFormItem item xs={3}>
        <NumberFormat
          thousandSeparator
          value={buyer || 0}
          onValueChange={onChangeBuyer}
          customInput={QuotationConfigOutlinedInput}
          // @ts-ignore
          fullWidth
          isAllowed={isAllowed}
          decimalScale={2}
          fixedDecimalScale
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </QuotationConfigFormItem>
      <QuotationConfigFormItem item xs={3}>
        <NumberFormat
          thousandSeparator
          value={finalPercentage || 0}
          customInput={QuotationConfigOutlinedInput}
          // @ts-ignore
          fullWidth
          decimalScale={2}
          fixedDecimalScale
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          disabled
        />
      </QuotationConfigFormItem>
    </>
  );
};

export default QuotationConfigDeedExpensesItem;
