import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Grid, IconButton, Switch, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import NumberFormat from 'react-number-format';
import { BoldTypography } from '../../Client/Client';
import QuotationDiscountType from '../../../../../Quotation/Core/enums/QuotationDiscountType';
import debouncedInput from '../../../../../../components/UI/TextFields/debouncedInput';
import useRealPaymentEditionDiscountType from '../../../../Core/customHooks/useRealPaymentEditionDiscountType';

const DebouncedTextField = debouncedInput(NumberFormat, { timeout: 400 });
const QuotationDiscountSummary = ({
  discountPrice,
  onChangeType,
  discountType,
  onChangeManualQuotationDiscount,
}) => {
  const {
    isEdition,
    canEdit,
    editionToggle,
  } = useRealPaymentEditionDiscountType();

  return (
    <Paper>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <BoldTypography>Tipo de Descuento Financiero</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box pb={2} display="flex" alignItems="center">
              <Typography component="div">
                {!isEdition &&
                  (discountType === QuotationDiscountType.MANUAL.code
                    ? QuotationDiscountType.MANUAL.value
                    : QuotationDiscountType.ASSIGNED.value)}
                {isEdition && (
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>{QuotationDiscountType.ASSIGNED.value}</Grid>
                    <Grid item>
                      <Switch
                        checked={
                          discountType === QuotationDiscountType.MANUAL.code
                        }
                        onChange={onChangeType}
                      />
                    </Grid>
                    <Grid item>{QuotationDiscountType.MANUAL.value}</Grid>
                  </Grid>
                )}
              </Typography>
              {canEdit && (
                <IconButton onClick={editionToggle}>
                  {isEdition ? <CancelIcon /> : <EditIcon />}
                </IconButton>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <BoldTypography>{`${
              discountPrice >= 0 ? 'Descuento' : 'Incremento'
            } Financiero $ COP`}</BoldTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            {(discountType === QuotationDiscountType.ASSIGNED.code ||
              !isEdition) && (
              <NumberFormat
                thousandSeparator
                prefix="$"
                displayType="text"
                value={Math.ceil(Math.abs(discountPrice))}
              />
            )}
            {discountType === QuotationDiscountType.MANUAL.code &&
              isEdition && (
                <DebouncedTextField
                  customInput={TextField}
                  prefix="$"
                  thousandSeparator
                  value={Math.ceil(discountPrice)}
                  onValueChange={onChangeManualQuotationDiscount}
                />
              )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

QuotationDiscountSummary.propTypes = {
  realPrice: PropTypes.number,
  discountPrice: PropTypes.number,
  priceWithDiscount: PropTypes.number,
  onChangeType: PropTypes.func,
  onChangeManualQuotationDiscount: PropTypes.func,
  discountType: PropTypes.any,
};

export default QuotationDiscountSummary;
