import React, { createRef, useState } from 'react';
import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';
import AsyncConfirmModal from '../../shared/components/AsyncModal/AsyncConfirmModal';
import EditableListFormItem from './EditableListFormItem';

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: '2px 4px',
    marginRight: '4px',
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: '0.7em',
    borderRadius: '2px',
    opacity: '0.9',
  },
}));

const EditableList = ({ values = [], onDelete, onEdit }) => {
  const modalRef = createRef();
  const classes = useStyles();
  const [editableItem, setEditableItem] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCancelEdit = () => {
    setEditableItem({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await onEdit(editableItem);
    handleCancelEdit();
    setLoading(false);
  };

  const handleClickDelete = async (id) => {
    await modalRef.current.show(() => onDelete({ id }));
  };

  const onSelectEditableItem = ({ id, value, index }) => {
    setEditableItem({ id, value, index });
  };

  return (
    <List>
      <AsyncConfirmModal title="Borrar elemento" ref={modalRef} />
      <LinearProgress hidden={!loading} />
      {values.map(({ id, value, isHubspot }, index) => {
        return (
          <ListItem key={`edit-list-${index}`}>
            <ListItemText
              primary={
                editableItem && index === editableItem.index ? (
                  <React.Fragment>
                    <EditableListFormItem
                      onSubmit={handleSubmit}
                      loading={loading}
                      defaultValue={editableItem.value}
                      onChange={(e) =>
                        setEditableItem({
                          ...editableItem,
                          value: e.target.value,
                        })
                      }
                      onCancelEdit={handleCancelEdit}
                    />
                  </React.Fragment>
                ) : (
                  <Typography>
                    {isHubspot ? (
                      <span className={classes.badge}>hubspot</span>
                    ) : null}
                    {value}
                  </Typography>
                )
              }
            />
            <ListItemSecondaryAction>
              {!isHubspot &&
                onEdit &&
                editableItem &&
                typeof editableItem.index !== 'number' && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() =>
                      !loading &&
                      onSelectEditableItem({
                        id,
                        index,
                        value,
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                )}
              {!isHubspot &&
                onDelete &&
                editableItem &&
                index !== editableItem.index && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => !loading && handleClickDelete(id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

EditableList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any,
    }),
  ),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default EditableList;
