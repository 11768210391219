import React from 'react';
import { Box } from '@material-ui/core';
import ArrearsClientsReportTable from '../tables/ArrearsClientsReportTable';

const ArrearsTableReportContainer = () => {
  
  return (
    <Box p={1} style={{ width: '68%' }}>
      <ArrearsClientsReportTable/>
    </Box>
  );
};

export default ArrearsTableReportContainer;
