import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const BankFinancialDetailForQuotationCell = ({ title, children }) => {
  return (
    <Grid item container spacing={1} lg={12}>
      <Grid item xs={6}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        {children}
      </Grid>
    </Grid>
  );
};

BankFinancialDetailForQuotationCell.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BankFinancialDetailForQuotationCell;
