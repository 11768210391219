import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import Sidebar from '../../../components/Sidebar/Sidebar';
import SideMenu from '../../../components/Sidebar/SideMenu';
import CollapseAndExpand from '../../../components/CollapseAndExpand/CollapseAndExpand';
import UserSideMenu from '../../../components/Sidebar/UserSideMenu';
import ProjectSideMenu from '../../../components/Sidebar/ProjectSideMenu';
import styles from './Dashboard.module.scss';
import {
  UserRoutes,
  GroupsRoutes,
  DashboardRoutes,
  CRMRoutes,
} from '../../../routes/local/routes';
import ProjectRoutes from '../../../routes/local/projectRoutes';
import INTEGRATIONS from '../../../config/integrations';

const routesWithoutPadding = [
  `${ProjectRoutes.base}${ProjectRoutes.towers.value}`,
  `${DashboardRoutes.base}${DashboardRoutes.salesSpeedHelper.value}`,
  `${DashboardRoutes.base}${DashboardRoutes.salesStatus.value}`,
];

const isRouteWithoutPadding = (pathname) => (route) => pathname.includes(route);

const isViewWithoutPadding = (pathname) =>
  routesWithoutPadding.some(isRouteWithoutPadding(pathname));

const getRemovePaddingClass = (pathname) => ({
  [styles.removePadding]: isViewWithoutPadding(pathname),
});

const Dashboard = (props) => {
  const [resizableWidth, setResizableWidth] = useState(250);
  const [isHidenArrow, setIsHidenArrow] = useState(false);
  const [isMenuHidden, setHideMenu] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [projectId, setProjectId] = useState('');

  useEffect(() => {
    const project = props.location.pathname.split('/').splice(4).join();
    setProjectId(project);
  }, [props.location.pathname]);

  const onChangeSize = (expandibleValue) => {
    setResizableWidth(expandibleValue);
    setHideMenu(expandibleValue <= 17);
  };

  const onChangeShowContent = (value) => {
    setShowContent(value);
  };

  const onHideArrow = (arrowValue) => {
    setIsHidenArrow(arrowValue);
  };

  const hideLetter = () => {
    if (isHidenArrow) {
      return true;
    }
    return false;
  };

  const userValidation =
    props.location.pathname.includes(UserRoutes.base) ||
    props.location.pathname.includes(GroupsRoutes.base) ||
    props.location.pathname.includes(CRMRoutes.base);

  const projectValidation =
    props.location.pathname.includes(UserRoutes.slideProjectsOnly) ||
    props.location.pathname.includes(UserRoutes.base) ||
    props.location.pathname.includes(DashboardRoutes.businessPartners.value) ||
    props.location.pathname.includes(DashboardRoutes.companySchedules) ||
    props.location.pathname.includes(GroupsRoutes.base) ||
    props.location.pathname.includes(CRMRoutes.base);

  const isProject = props.location.pathname.includes(`${ProjectRoutes.base}/`);

  const backgroundColor = props.location.pathname;

  const ENV = process.env.NODE_ENV;

  const sidebarConditionView = () => {
    if (INTEGRATIONS[ENV].uniqueSideBar) {
      if (projectValidation) {
        return <Sidebar />;
      }
      return <></>;
    }
    return <Sidebar />;
  };

  return (
    <div className={styles.Dashboard}>
      <nav className={styles.Navigation}>
        {sidebarConditionView()}
        {userValidation && (
          <Fragment>
            <UserSideMenu
              onHideArrow={onHideArrow}
              isHidenArrow={isHidenArrow}
              resizableWidth={resizableWidth}
              onChange={onChangeSize}
              isMenuHidden={isMenuHidden}
            />
          </Fragment>
        )}
        {!projectValidation && (
          <Fragment>
            <SideMenu
              onHideArrow={onHideArrow}
              hideLetter={hideLetter}
              resizableWidth={resizableWidth}
              tower={props.tower}
              isBadgeIncrement={props.isBadgeIncrement}
              onChange={onChangeSize}
              showContent={showContent}
              onChangeShowContent={onChangeShowContent}
              projectId={props.tower ? props.tower.projectId : null}
            />
          </Fragment>
        )}
        {isProject && (
          <Fragment>
            <ProjectSideMenu
              onHideArrow={onHideArrow}
              isHidenArrow={isHidenArrow}
              resizableWidth={resizableWidth}
              onChange={onChangeSize}
              isMenuHidden={isMenuHidden}
              projectId={projectId}
            />
          </Fragment>
        )}
        <div className="containerNoRezise">
          <CollapseAndExpand
            onHideArrow={onHideArrow}
            isHidenArrow={isHidenArrow}
            onChange={onChangeSize}
            isMenuHidden={isMenuHidden}
            onChangeShowContent={onChangeShowContent}
          />
        </div>
      </nav>

      <main
        className={clsx(
          styles.Content,
          {
            [styles.blueBackground]: backgroundColor === '/dashboard/projects',
            [styles.grayBackground]: isProject,
          },
          getRemovePaddingClass(props.location.pathname),
        )}
      >
        {props.children}
      </main>
    </div>
  );
};
export default Dashboard;
