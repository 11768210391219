import React, { FC, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { InsertInvitationOutlined } from '@material-ui/icons';
import QuotationConfigFormItem from '../QuotationConfigFormItem';
import Typography from 'shared/components/Typography/Typography';
import TextFieldDisabled from '../../components/TextFieldDisabled/TextFieldDisabled';
import PlusInput from 'shared/components/PlusInput/PlusInput';

import styles from '../QuotationConfigForm.module.scss';
import { QuotationConfigFormatStringDate } from 'App/QuotationConfig/core/QuotationConfigUtils';
import QuotationQuestionTooltip from '../../components/QuotationQuestionTooltip/QuotationQuestionTooltip';
import useQuotationConfigLimitDateFormEndConstruction from 'App/QuotationConfig/core/customHooks/useQuotationConfigLimitDateFormEndConstruction';
import QuotationConfigAlert from '../../components/QuotationConfigAlert/QuotationConfigAlert';

interface Props {
  endOfSalesDate: string;
  maximumCollectionDate: string;
  isAfterOfEndOfSalesDate: boolean;
}

const QuotationConfigLimitDateFormEndConstruction: FC<Props> = (props) => {
  const { maximumCollectionDate, endOfSalesDate, isAfterOfEndOfSalesDate } = props;
  const { diffValue, isLoading, handleChange, isAllowed } = useQuotationConfigLimitDateFormEndConstruction(props);
  return (
    <Box display="flex" justifyContent="center" padding="40px">
      <Grid container item xs={10}>
        <QuotationConfigFormItem item xs={6} className={styles.ItemWithIcon}>
          <InsertInvitationOutlined className={styles.ItemIcon} />
          <Typography fontWeight="500">Fin de construcción</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography fontWeight="500">{QuotationConfigFormatStringDate(endOfSalesDate)}</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1">Desplazar</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <PlusInput
            onChange={handleChange}
            isDisabled={isLoading}
            value={diffValue}
            numberFormatProps={{
              isAllowed,
              decimalScale: 0,
            }}
          />
          <QuotationQuestionTooltip title="Desplazamiento en meses respecto a la fecha de fin de la construcción" />
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <Typography variant="body1">Cuota inicial - Fecha límite</Typography>
        </QuotationConfigFormItem>
        <QuotationConfigFormItem item xs={6}>
          <TextFieldDisabled disabled value={QuotationConfigFormatStringDate(maximumCollectionDate)} />
        </QuotationConfigFormItem>
        {isAfterOfEndOfSalesDate && (
          <QuotationConfigFormItem item xs={12}>
            <QuotationConfigAlert
              state="warning"
              text="La fecha de cuota inicial - fecha límite es mayor a la fecha fin de construcción."
            />
          </QuotationConfigFormItem>
        )}
      </Grid>
    </Box>
  );
};

export default QuotationConfigLimitDateFormEndConstruction;
