import React, { FC, useState } from 'react';
import {
  Grid,
  Table,
  TableColumnVisibility,
  TableHeaderRow,
  TableSelection,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSummary, SelectionState, SummaryState } from '@devexpress/dx-react-grid';

import { ContractPayment, ContractPaymentSchedule } from '../../../../../models/ContractPayments';
import TableCell from '../../../../../shared/tables/TableCell';
import useColumnsAndRowsForContractPayments from '../../../Core/customHooks/useColumnsAndRowsForContractPayments';
import { CondensedTableHeaderRowCell } from '../../../../../shared/tables/CondensedTable';
import VirtualTableDataTypeProvider from '../../../../../shared/tables/VirtualTableDataTypeProvider/UI/VirtualTableDataTypeProvider';
import ProviderColumnType from '../../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import TableSummaryRowCell from '../../../../../shared/tables/TableSummaryRowCell';
import ContractPaymentScheduleSummary from './ContractPaymentScheduleSummary';

const getRowId = (row: ContractPaymentSchedule) => row.id;

interface IScheduleByPaymentGrid {
  contractPayment: ContractPayment;
  contractPaymentSchedules: ContractPaymentSchedule[];
  onSeleccionChange: (contractPaymentSchedule: ContractPaymentSchedule) => void;
}

const ScheduleByPaymentGrid: FC<IScheduleByPaymentGrid> = ({
  contractPayment,
  onSeleccionChange,
  contractPaymentSchedules,
}) => {
  const { columns, rows, hiddenColumns, totalSummaryItems, summaryCalculator } = useColumnsAndRowsForContractPayments({
    contractPayment,
    contractPaymentSchedules,
  });

  const [selection] = useState([]);

  return (
    <>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SummaryState totalItems={totalSummaryItems} />
        <IntegratedSummary calculator={summaryCalculator} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.currency} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.tooltip} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.percent} />
        <VirtualTableDataTypeProvider columns={columns} type={ProviderColumnType.dateFormat} />
        <SelectionState
          selection={selection}
          onSelectionChange={(sel) => {
            const [selectedId] = sel;
            const contractEvent =
              contractPaymentSchedules.find((row: ContractPaymentSchedule) => row.id === selectedId) || null;

            if (!!contractEvent) {
              onSeleccionChange(contractEvent);
            }
          }}
        />
        <Table columnExtensions={columns} cellComponent={TableCell} />
        <TableHeaderRow cellComponent={CondensedTableHeaderRowCell} />
        <TableColumnVisibility hiddenColumnNames={hiddenColumns} />
        <TableSummaryRow totalCellComponent={TableSummaryRowCell} itemComponent={ContractPaymentScheduleSummary} />
        <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
      </Grid>
    </>
  );
};

export default ScheduleByPaymentGrid;
