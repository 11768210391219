import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const FORMAT_YEAR_MONTH = 'YYYY-MM';
// MARK: Don’t use a different format
export const FORMAT_YEAR_MONTH2 = 'MMM YYYY';
export const FORMAT_YEAR_MONTH3 = 'MMM YY';
export const LOCAL_MONTH_NAMES = {
  ene: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  abr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  ago: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dic: 'Dec',
};
/**
 * Calculates the period difference (Integer) between 2 string dates
 *
 * @param String date1
 * @param String date2
 * @returns Number
 */
export const periodDifference = (date1, date2) => {
  const moment1 = moment(date1).startOf('month');
  const moment2 = moment(date2).endOf('month');

  const periodDiff = moment2.diff(moment1, 'months');
  return Math.abs(periodDiff);
};

/**
 * Adds periods to a date in string format
 *
 * @param {String} date
 * @param {Number} periodCount
 * @returns {String} newDate
 */
export const addPeriods = (date, periodCount) => {
  const momentDate = moment(date).add(periodCount, 'months');
  return momentDate.format(DEFAULT_DATE_FORMAT);
};

export const getMonthlyDatesFrom = (
  initialDateP,
  finalDateP,
  format = FORMAT_YEAR_MONTH,
) => {
  const initialDate = moment(initialDateP);
  const endDate = moment(finalDateP);
  const objectDates = {};

  for (
    let q = initialDate;
    moment(q.format(format)).isSameOrBefore(endDate);
    q = moment(q).add(1, 'months')
  ) {
    const key = q.format(format);
    objectDates[key] = 0;
  }

  return objectDates;
};

/**
 * Array from moment dates
 *
 * @param {moment.Moment} initialDateP
 * @param {moment.Moment} finalDateP
 * @returns {Object} key: YYYY-MM value: moment
 */

export const getMonthlyDatesFromMoment = (
  initialDateP,
  finalDateP,
  format = FORMAT_YEAR_MONTH2,
) => {
  const initialDate = initialDateP;
  const endDate = finalDateP;
  const objectDates = {};

  for (
    let q = initialDate;
    moment(q.format(FORMAT_YEAR_MONTH)).isSameOrBefore(endDate);
    q = moment(q).add(1, 'months')
  ) {
    const key = q.format(format);
    objectDates[key] = q;
  }

  return objectDates;
};

/**
 * Get month number
 *
 * @param {Number} month
 *
 */
export const getMonthNumber = (month) => {
  const monthString = moment(month).format('MMMM');
  const momentDate = moment().month(monthString).format('M');
  return momentDate;
};

/**
 * Get month number
 *
 * @param {Number} fullDate
 *
 */
export const getYearFromDate = (fullDate) => {
  const momentDate = moment(fullDate).format('YYYY');
  return momentDate;
};

export const getMonthlyDatesFromMomentColumns = (
  initialDateP,
  finalDateP,
  format = FORMAT_YEAR_MONTH2,
) => {
  const initialDate = initialDateP;
  const endDate = finalDateP;
  const objectDates = {};
  const arrayOfDates = [];

  for (
    let q = initialDate;
    moment(q).isSameOrBefore(endDate, 'month');
    q = moment(q).add(1, 'months')
  ) {
    const key = moment(q).format(format);
    objectDates[key] = key
    arrayOfDates.push(key)
  }

  return {objectDates, arrayOfDates};
}
