import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  ChangeSet,
  Column,
  EditingColumnExtension,
  IntegratedSummary,
  SummaryItem,
  Table,
} from '@devexpress/dx-react-grid';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { FORMAT_YEAR_MONTH3 } from '../../../../helpers/dates';
import VirtualTableDataTypeProviderColumn from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/interfaces/VirtualTableDataTypeProviderColumn';
import ProviderColumnType from '../../../../shared/tables/VirtualTableDataTypeProvider/Core/types/ProviderColumnType';
import { TowerStatistics } from '../../../../models/Tower/TowerStatistics';
import Services from '../../../../services/ContractsEvalpro/ContractsEvalproServices';
import { CreateEvalproDetailDto, EvalproDetailDto, EvalproEventDto } from '../DTO';
import constants from './constants';

const services = new Services();

const useAddContractEvalproDetailValue = (towerId: string, queryClient: QueryClient) => {
  return useMutation(
    (evalproGroup: CreateEvalproDetailDto) => services.createContractEvalproDetailValue(towerId, evalproGroup),
    {
      onSettled: () => {
        queryClient.invalidateQueries(constants.CONTRACTS_EVALPRO_GROUPS);
        queryClient.invalidateQueries(constants.CONTRACTS_EVALPRO_GROUPS_DETAILS);
      },
    },
  );
};

type ColumnType = Table.ColumnExtension & Column & VirtualTableDataTypeProviderColumn;

type HookType = ({ event, originalRows }) => {
  rows: EvalproDetailDto[];
  columns: ColumnType[];
  totalSummaryItems: SummaryItem[];
  editingStateColumnExtensions: EditingColumnExtension[];
  commitChanges: (changes: ChangeSet) => void;
  summaryCalculator: (
    type: string,
    rows: EvalproDetailDto[],
    getValue: (row: EvalproDetailDto) => EvalproDetailDto,
  ) => number | string | undefined;
};

const getColumns = (totalSales: number, event: EvalproEventDto): ColumnType[] => {
  return [
    {
      width: 170,
      title: 'Valor',
      name: 'total',
      columnName: 'total',
      type: ProviderColumnType.currency,
      getCellValue: (row: EvalproDetailDto) => {
        const parent = row.contractsEvalpro;
        const parentPercentage = parent?.percentage;
        return row.percentage && parentPercentage ? totalSales * (row.percentage / 100) * (parentPercentage / 100) : 0;
      },
    },
    {
      width: 130,
      title: '%',
      name: 'percentage',
      columnName: 'percentage',
      type: ProviderColumnType.percent,
      align: 'center',
      getCellValue: (row: EvalproDetailDto) => (row.percentage ? row.percentage : 0),
    },
    {
      width: 130,
      title: 'Desp.',
      name: 'displacement',
      columnName: 'displacement',
      align: 'center',
    },
    {
      width: 130,
      title: 'Fecha',
      name: 'customDate',
      columnName: 'customDate',
      type: ProviderColumnType.dateFormat,
      align: 'center',
    },
  ];
};

const useColumnsAndRowsForContractsEvalproDetail: HookType = ({ event, originalRows }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient: QueryClient = useQueryClient();
  const { towerId }: { towerId: string } = useParams();
  const { mutateAsync: addContractEvalproGroupValue } = useAddContractEvalproDetailValue(towerId, queryClient);
  const towerStatistics = queryClient.getQueryData<TowerStatistics>(constants.CONTRACTS_EVALPRO_TOWER_STATISTICS);
  const eventId = event?.id;

  const tableSummaryItems: SummaryItem[] = [
    { columnName: 'total', type: 'sum' },
    { columnName: 'percentage', type: 'percent' },
  ];

  const [editingStateColumnExtensions] = useState<EditingColumnExtension[]>([
    { columnName: 'displacement', editingEnabled: true },
    { columnName: 'total', editingEnabled: false },
    { columnName: 'percentage', editingEnabled: true },
    { columnName: 'customDate', editingEnabled: false },
  ]);

  const [columns, setColumns] = useState<ColumnType[]>(getColumns(0, event));
  const [rows, setRows] = useState<EvalproDetailDto[]>([]);

  useEffect(() => {
    if (!!towerStatistics) {
      const cm = getColumns(towerStatistics?.totalSales, event);
      setColumns(cm);
    }
  }, [towerStatistics]);

  useEffect(() => {
    const newRows = originalRows.map((row: EvalproDetailDto) => ({ ...row, dateFormat: FORMAT_YEAR_MONTH3 }));
    setRows(newRows);
  }, [originalRows]);

  const [totalSummaryItems] = useState<SummaryItem[]>(tableSummaryItems);

  const commitChanges = async ({ changed }: ChangeSet): Promise<void> => {
    if (changed) {
      const rowIndex = Object.keys(changed)[0];
      const rowUpdated: EvalproDetailDto = changed[rowIndex];

      if (
        rowUpdated &&
        (!!rowUpdated.percentage ||
          rowUpdated.percentage === 0 ||
          rowUpdated.displacement ||
          rowUpdated.displacement === 0)
      ) {
        const percentage = rowUpdated.percentage;
        const displacement = rowUpdated.displacement;

        const evalproGroupValue: CreateEvalproDetailDto = {
          ...((percentage || percentage === 0) && { percentage }),
          ...((displacement || displacement === 0) && { displacement }),
          contractCategoryId: Number(rowIndex),
          contractsEvalproEventId: eventId,
        };

        try {
          await addContractEvalproGroupValue(evalproGroupValue);
        } catch ({ message }) {
          enqueueSnackbar(`${message}`, { variant: 'error' });
        }
      }
    }
  };

  const summaryCalculator = (
    type: string,
    rows: EvalproDetailDto[],
    getValue: (row: EvalproDetailDto) => EvalproDetailDto,
  ) => {
    if (type === 'title') {
      if (!rows.length) {
        return null;
      }

      return 'Total';
    }
    return IntegratedSummary.defaultCalculator('sum', rows, getValue);
  };

  return {
    rows,
    columns,
    totalSummaryItems,
    editingStateColumnExtensions,
    commitChanges,
    summaryCalculator,
  };
};

export default useColumnsAndRowsForContractsEvalproDetail;
