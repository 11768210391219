import React from 'react';

import { Button } from 'shared/components/Button/Button';
import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import { AddStageHandler } from '../../../core/types/Stages.types';
import StagesOnboardingHeader from '../StagesOnboardingHeader/StagesOnboardingHeader';

import styles from './StagesOnboardingStep1.module.scss';

type Props = {
  onAddStage: AddStageHandler;
};

const StagesOnboardingStep1: React.FC<Props> = ({ onAddStage }) => (
  <>
    <StagesOnboardingHeader step={1} />
    <Button className={styles.addStageButton} variant="contained" onClick={onAddStage}>
      <>
        <div className={styles.buttonTitle}>
          <Icon className={styles.addStageIcon} variant="Add" />
          <Typography variant="headline6" color="gray000">
            Nueva Etapa
          </Typography>
        </div>
        <Typography className={styles.addStageDescription} as="p" variant="body1" color="gray80">
          Cada etapa puede contener múltiples torres y estas pueden ordenarse fácilmente entre etapas.
        </Typography>
      </>
    </Button>
  </>
);

export default StagesOnboardingStep1;
