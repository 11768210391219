import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ResultsStateServices from '../../Services/ResultsStateServices';
import { updateSaveBudgetState, updateBudgetRows } from '../../../TotalCashFlow/Core/actions';

interface RouteParams {
  towerId: string;
}

function useAddEventForResultsState() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { resultsStateRows, budgetRows } = useSelector((state) => state.cashFlow.root || {});

  const resultsStateServices = new ResultsStateServices();
  const { towerId } = useParams<RouteParams>();

  const [open, setOpen] = useState(false);
  const [eventName, setEventName] = useState('');
  const [selectedBudget, setSelectedBudget] = useState(null);

  const resultsStateRowsMapper = resultsStateRows.map((row) => {
    return {
      previousBudget: row.total,
      budgetId: row.id,
      budgetPercentage: row.percentage,
      budgetName: eventName,
      budgetIndex: selectedBudget,
    };
  });

  const updateRowsValidation = (newRows) => {
    if (newRows.length > 0) {
      const duplicateRowsValidation = budgetRows.filter(row => row.budgetIndex !== newRows[0].budgetIndex)

      return duplicateRowsValidation.concat(newRows)
    }

    return newRows
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandler = async (eventName: string) => {
    setOpen(false);
    const updateCutsControl = await resultsStateServices.putEventsControl({ towerId, rows: resultsStateRowsMapper });
    const { data } = updateCutsControl;
    dispatch(updateSaveBudgetState(data))
    data && 
      enqueueSnackbar(`el evento ${eventName} ha sido copiado exitosamente`, {
        variant: 'success',
      });

    const newRows = updateRowsValidation(resultsStateRowsMapper)

    data && dispatch(updateBudgetRows(newRows))

    data === false && enqueueSnackbar(`Error en la copia del evento ${eventName}`, { variant: 'error' });
  };

  const handleChange = (e, selectedBudget) => {
    setEventName(e.target.value);
    setSelectedBudget(selectedBudget);
  };

  return {
    open,
    handleClose,
    setOpen,
    onSubmitHandler,
    handleChange,
    eventName,
  };
}

export default useAddEventForResultsState;
