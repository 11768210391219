export const FILTER_SELECTION_UP = 'FILTER_SELECTION_UP';
export const FILTER_SELECTION_DOWN = 'FILTER_SELECTION_DOWN';
export const FILTER_SELECTION_TO_DEFAULT = 'FILTER_SELECTION_TO_DEFAULT';
export const ACTIVE_OR_DEACTIVATE_FILTER = 'ACTIVE_OR_DEACTIVATE_FILTER';
export const DISABLE_CLEAR = 'DISABLE_CLEAR';
export const SELECTOR_NAME_ON_DISPLAYED_FILTER =
  'SELECTOR_NAME_ON_DISPLAYED_FILTER';

export const filterUp = (selection) => ({
  type: FILTER_SELECTION_UP,
  payload: selection,
});

export const filterDown = (selection) => ({
  type: FILTER_SELECTION_DOWN,
  payload: selection,
});

export const filterClean = () => ({
  type: FILTER_SELECTION_TO_DEFAULT,
});

export const activeFilter = (nameToActive) => ({
  type: ACTIVE_OR_DEACTIVATE_FILTER,
  payload: nameToActive,
});

export const disableClear = () => ({
  type: DISABLE_CLEAR,
});

export const setSelectorOfDisplayedFilter = (name) => ({
  payload: name,
  type: SELECTOR_NAME_ON_DISPLAYED_FILTER,
});
