import React, { FC } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui';

import { Property } from '../../../../../models/Properties';

import { TitleTypeProvider } from '../../../../../shared/tables/TableColumnFormat';

import {
  CondensedTableContainer,
  CondensedTableCell,
} from '../../../../../shared/tables/CondensedTable';

import Styles from '../PropertyDetails.module.scss';
import useColumnsAndRowsForSale from '../Core/customHooks/useColumnsAndRowsForSale';

interface ISaleCard {
  property: Property;
}

const SaleCard: FC<ISaleCard> = ({ property }) => {
  const { columns, data, titleColumns, tableColumnExtensions } =
    useColumnsAndRowsForSale({ property });

  return (
    <Card>
      <CardHeader
        title="Venta"
        titleTypographyProps={{
          className: Styles.cardHeader,
        }}
      />
      <CardContent className={Styles.cardSmallContent}>
        <Grid rows={data} columns={columns}>
          <TitleTypeProvider for={titleColumns} />
          <Table
            columnExtensions={tableColumnExtensions}
            containerComponent={CondensedTableContainer}
            cellComponent={CondensedTableCell}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SaleCard;
