import { useEffect, useState } from 'react';
import { EditableFieldOption, IndexedFieldOption } from '../interfaces/featureField.types';
import { UpdateFeatureFieldOptionsHook } from '../interfaces/useUpdateFeatureFieldOptions.types';

const setOptionIndex = (option: EditableFieldOption, index: number): IndexedFieldOption => ({
  ...option,
  index,
});

const setOptionsIndex = (options: EditableFieldOption[]): IndexedFieldOption[] => options.map(setOptionIndex);

const filterOptionIndex = ({ index, ...restValues }: IndexedFieldOption): EditableFieldOption => ({
  ...restValues,
});

const updateOptions = (
  options: EditableFieldOption[],
  index: number,
  option: IndexedFieldOption,
): EditableFieldOption[] => {
  const updatedOptions = [...options];

  updatedOptions[index] = filterOptionIndex(option);

  return updatedOptions;
};

const deleteOption = (options: EditableFieldOption[], index: number) => {
  const newOptions = [...options];

  newOptions.splice(index, 1);

  return newOptions;
};

const newOption = {
  value: '',
  color: '#FF5D3A',
};

const addOption = (options: EditableFieldOption[], option: EditableFieldOption) => options.concat([option]);

const removeOptionsIndex = (indexedOptions: IndexedFieldOption[]): EditableFieldOption[] =>
  indexedOptions.map(filterOptionIndex);

function useUpdateFeatureFieldOptions({ options, onChange }: UpdateFeatureFieldOptionsHook) {
  const [indexedOptions, setIndexedOptions] = useState<IndexedFieldOption[]>([]);

  useEffect(() => {
    setIndexedOptions(setOptionsIndex(options));
  }, [options]);

  const handleChangeOption = (index: number) => (option: IndexedFieldOption) => {
    onChange(updateOptions(options, index, option));
  };

  const handleDeleteOption = (index: number) => () => {
    onChange(deleteOption(options, index));
  };

  const handleAddOption = () => {
    onChange(addOption(options, newOption));
  };

  const handleSortOptions = (indexedOptions: IndexedFieldOption[]) => {
    onChange(removeOptionsIndex(indexedOptions));
  };

  return { indexedOptions, handleChangeOption, handleDeleteOption, handleAddOption, handleSortOptions };
}

export default useUpdateFeatureFieldOptions;
