import React from 'react';
import { Grid } from '@material-ui/core';
import useBuyerPropertyDetailWithQuotationsStates from '../../../Core/customHooks/useBuyerPropertyDetailWithQuotationsStates';
import PropertyDetails from '../../../../../components/Client2/DetailsDialog/PropertyDetails';
import BuyerCustomerTitleView from './BuyerCustomerTitleView';
import BuyerCustomerAdditionalStates from './BuyerCustomerAdditionalStates';

const BuyerCustomerClientDetailView = () => {
  const {
    currentClient: client,
    currentPropertyInfo,
    currentPropertyFiles,
  } = useBuyerPropertyDetailWithQuotationsStates();

  const currentClient = client || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BuyerCustomerTitleView
          name={currentClient.subtitle}
          phoneNumber={currentClient.phoneNumber}
          identityDocument={currentClient.identityDocument}
          email={currentClient.email}
          clientId={currentClient.clientId}
          property={currentPropertyInfo}
        />
      </Grid>
      <Grid item xs={12}>
        <BuyerCustomerAdditionalStates />
      </Grid>
      <Grid item xs={12}>
        <PropertyDetails
          property={currentPropertyInfo}
          clientId={currentClient.clientId}
          propertyId={currentPropertyInfo ? currentPropertyInfo.id : null}
          propertyFiles={currentPropertyFiles}
          clientResponseQuestionOne={currentClient.responseQuestionOne}
        />
      </Grid>
    </Grid>
  );
};

export default BuyerCustomerClientDetailView;
