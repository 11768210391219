import Numbers from './numbers';

class FinancialAlgorithms {
  static PMT(ir, np, pv, fv = 0, type = 0) {
    let pmt;

    if (ir === 0) return -(pv + fv) / np;

    const pvif = Math.pow(1 + ir, np);
    pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return Numbers.cleanNumber(pmt);
  }

  static PV(rate, nper, vf) {
    const pv = vf / Math.pow(1 + rate, nper);
    return Numbers.cleanNumber(pv);
  }
}

export default FinancialAlgorithms;
