import React, { createContext, FC, useContext } from 'react';
import useGeneralWallletDetailsThread, {
  UseGeneralWalletThreadContextProps,
} from '../customHooks/useGeneralWalletThreadContext';

type GeneralWalletThreadContextState = ReturnType<typeof useGeneralWallletDetailsThread>;

const GeneralWalletThreadContext = createContext<GeneralWalletThreadContextState | null>(null);

export const useGeneralWalletThreadContext = () =>
  useContext(GeneralWalletThreadContext) as GeneralWalletThreadContextState;

const GeneralWalletThreadProvider: FC<UseGeneralWalletThreadContextProps> = ({ children, ...props }) => {
  const value = useGeneralWallletDetailsThread(props);
  return <GeneralWalletThreadContext.Provider value={value}>{children}</GeneralWalletThreadContext.Provider>;
};

export default GeneralWalletThreadProvider;
