import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card, {
  CardBody,
  CardHeader,
} from '../../../../../../components/UI/Card/Card';
import WalletSetupItem from '../customViews/WalletSetupItem';
import WalletSetupFeesTitles from '../customViews/WalletSetupFeesTitles';

const GeneralWalletSetup = ({ walletSetup, isAuthorizedForEdit }) => {
  return (
    <Card>
      <CardHeader>
        <span>{isAuthorizedForEdit && 'Configuración de '} Cartera</span>
      </CardHeader>
      <CardBody>
        <Box display="flex" flexDirection="column" pb={6}>
          <Typography variant="h6">Tasas</Typography>
          <WalletSetupFeesTitles />
          <WalletSetupItem
            title="Tasa financiación"
            walletSetup={walletSetup}
            propertyName="financialFee"
            isAuthorizedForEdit={isAuthorizedForEdit}
          />
          <WalletSetupItem
            title="Tasa reconocimiento"
            walletSetup={walletSetup}
            propertyName="recognitionFee"
            isAuthorizedForEdit={isAuthorizedForEdit}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

GeneralWalletSetup.propTypes = {
  walletSetup: PropTypes.object,
};

export default GeneralWalletSetup;
