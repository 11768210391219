import { useMutation, useQueryClient } from 'react-query';
import { EvalproDeedExpensesService } from 'App/DeedExpenses/services/EvalproDeedExpensesService';
import { EVALPRO_DEED_EXPENSES_MONTHS } from '../reactQuery/RequestConstants';
import { EvalproDeedExpensesCellNumberPayload } from '../interfaces/EvalproDeedExpensesCellNumberProps';
import { EvalproDeedExpensesMonth } from '../interfaces/EvalproDeedExpensesMonth';

const service = new EvalproDeedExpensesService();

const useUpdateEvalproDeedExpensesMonth = <T extends keyof EvalproDeedExpensesMonth>(key: T) => {
  const queryClient = useQueryClient();

  const execServiceStock = async (payload: EvalproDeedExpensesCellNumberPayload) => {
    const { data } = await service.updateMonth(payload.monthId, { [key]: payload.value });
    return data.isSuccess;
  };

  const onFetchSuccess = (isSuccess: boolean) => {
    if (isSuccess) queryClient.invalidateQueries(EVALPRO_DEED_EXPENSES_MONTHS);
  };

  const { mutate: fetch } = useMutation(execServiceStock, {
    onSuccess: onFetchSuccess,
  });

  return {
    fetch,
  };
};

export default useUpdateEvalproDeedExpensesMonth;
