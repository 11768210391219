import React, { FC } from 'react';
import { Box, FormControl, Grid, InputAdornment } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { SegmentedControl } from 'segmented-control';

import Styles from '../../ContractPaymentFormMainView.module.scss';

import { IUseFormResult } from '../../../../../shared/customHooks/useForm';
import Typography from '../../../../../shared/components/Typography/Typography';
import TextField from '../../../../../shared/components/TextField/TextField';
import { ContractPayment, MeasurementUnit, PaymentProjection } from '../../../../../models/ContractPayments';

import MeasurementField from './MeasurementField';
import usePaymentProjection from '../../../Core/customHooks/usePaymentProjections';

interface IPaymentValueFields extends Partial<IUseFormResult<ContractPayment>> {
  handleChangePaymentValues: <G>(key: keyof ContractPayment, value: G) => void;
  handleChangeValue: <G>(key: keyof ContractPayment, value: G) => void;
  paymentProjectionsList?: PaymentProjection[];
  measurementUnits: MeasurementUnit[] | undefined;
  currentContractPayment: ContractPayment | undefined;
  contractPaymentType: string;
  subtotal: number;
  total: number;
}

const PaymentValueFields: FC<IPaymentValueFields> = ({
  currentContractPayment,
  contractPaymentType,
  measurementUnits,
  handleChangePaymentValues,
  handleChangeValue,
  paymentProjectionsList,
  errors,
  data,
  subtotal,
  total,
}) => {
  const { paymentProjectionOptions, statistics } = usePaymentProjection({
    currentContractPayment,
    paymentProjectionsList,
  });
  return (
    <>
      {contractPaymentType !== 'PC' && contractPaymentType !== 'PR' ? (
        <Box display="flex" justifyContent="center">
          <Grid item xs={10}>
            <SegmentedControl
              className={Styles.switch}
              name="projectionType"
              options={paymentProjectionOptions}
              setValue={(value: string) => {
                handleChangePaymentValues('projectionType', value);
              }}
              variant="base"
            />
          </Grid>
        </Box>
      ) : (
        <Box marginTop={2}> </Box>
      )}
      {(() => {
        switch (data?.projectionType) {
          case 'M':
            return (
              <>
                <Box mx={2} my={2} px={5}>
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item sm={4}>
                      <FormControl fullWidth>
                        <NumberFormat
                          customInput={TextField}
                          defaultValue={data?.qty}
                          value={data?.qty}
                          onValueChange={(values) => handleChangePaymentValues('qty', values.value)}
                          label="Cantidad"
                          variant="outlined"
                          // @ts-ignore
                          size="small"
                          inputProps={{
                            className: Styles.textRight,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">{data?.measurementUnitId || ''}</InputAdornment>
                            ),
                          }}
                          helperText={errors?.qty}
                          error={!!errors?.qty}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={8}>
                      <MeasurementField
                        handleChangeValue={handleChangeValue}
                        measurementUnits={measurementUnits}
                        currentContractPayment={currentContractPayment}
                        data={data}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mx={2} my={2} px={5}>
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item sm={4}>
                      <FormControl fullWidth>
                        <NumberFormat
                          customInput={TextField}
                          defaultValue={data?.unitPrice}
                          value={data?.unitPrice}
                          onValueChange={(values) => handleChangePaymentValues('unitPrice', values.value)}
                          label="Valor unitario"
                          variant="outlined"
                          thousandSeparator
                          // @ts-ignore
                          size="small"
                          inputProps={{
                            className: Styles.textRight,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          helperText={errors?.unitPrice}
                          error={!!errors?.unitPrice}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl fullWidth>
                        <NumberFormat
                          customInput={TextField}
                          defaultValue={data?.tax}
                          value={data?.tax}
                          onValueChange={(values) => handleChangePaymentValues('tax', values.value)}
                          label="IVA"
                          variant="outlined"
                          thousandSeparator
                          // @ts-ignore
                          size="small"
                          inputProps={{
                            className: Styles.textRight,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          helperText={errors?.tax}
                          error={!!errors?.tax}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
                        <Grid item>
                          <Typography variant="subtitle1" color="gray900">
                            <NumberFormat
                              displayType="text"
                              id="subtotal"
                              thousandSeparator
                              fixedDecimalScale={true}
                              decimalScale={0}
                              prefix="$ "
                              value={subtotal || 0}
                            />
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Total Unitario + IVA</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </>
            );
          case 'D':
          case 'S':
            return (
              <Box mx={2} my={2} px={5}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item sm={6}>
                    <NumberFormat
                      displayType="text"
                      value={statistics ? statistics[data?.projectionType].value : 0}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      prefix="$"
                      className={Styles.amount}
                    />
                    <br />
                    <Typography fontWeight="400" variant="caption">
                      {statistics ? statistics[data?.projectionType].label : ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={contractPaymentType !== 'PR' ? 3 : 6}>
                    <FormControl fullWidth>
                      <NumberFormat
                        customInput={TextField}
                        defaultValue={data?.percentage}
                        value={data?.percentage}
                        onValueChange={(values) => handleChangePaymentValues('percentage', values.value)}
                        label="Porcentage"
                        variant="outlined"
                        // @ts-ignore
                        size="small"
                        inputProps={{
                          className: Styles.textRight,
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        helperText={errors?.percentage}
                        error={!!errors?.percentage}
                      />
                    </FormControl>
                  </Grid>
                  {contractPaymentType !== 'PR' && (
                    <Grid item sm={3}>
                      <FormControl fullWidth>
                        <NumberFormat
                          customInput={TextField}
                          defaultValue={data?.tax}
                          value={data?.tax}
                          onValueChange={(values) => handleChangePaymentValues('tax', values.value)}
                          label="IVA"
                          variant="outlined"
                          thousandSeparator
                          // @ts-ignore
                          size="small"
                          inputProps={{
                            className: Styles.textRight,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          helperText={errors?.tax}
                          error={!!errors?.tax}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Box>
            );
        }
      })()}

      <Box mx={2} my={2} px={5}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Typography variant="headline6" fontWeight="700">
              <NumberFormat
                displayType="text"
                id="total"
                thousandSeparator
                fixedDecimalScale={true}
                decimalScale={0}
                prefix="TOTAL: $"
                value={total || 0}
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PaymentValueFields;
