import ContractsEvalproServiceDefinition from './ContractsEvalproServicesDefinition';
import Services from '../services';
import { EvalproGroupDto, EvalproEventDto, EvalproDetailDto, EvalproFlowDto } from 'App/ContractsEvalpro/core/DTO';
import { TowerStatistics } from '../../models/Tower/TowerStatistics';

export default class ContractsEvalproService extends Services {
  getAllEvalproGroups(towerId: string) {
    return this.get<EvalproGroupDto[]>(ContractsEvalproServiceDefinition.getAllEvalproGroups(towerId));
  }
  getAllEvalproDetailsGroups(towerId: string) {
    return this.get<EvalproEventDto[]>(ContractsEvalproServiceDefinition.getAllEvalproDetailsGroups(towerId));
  }
  getEvalproTowerStatistics(towerId: string) {
    return this.get<TowerStatistics>(ContractsEvalproServiceDefinition.getEvalproTowerStatistics(towerId));
  }
  getEvalproFlow(towerId: string) {
    return this.get<EvalproFlowDto[]>(ContractsEvalproServiceDefinition.getEvalproFlow(towerId));
  }
  downloadEvalproFlow(towerId: string) {
    return this.get<Blob>(ContractsEvalproServiceDefinition.downloadEvalproFlow(towerId), {
      responseType: 'blob',
    });
  }
  createContractEvalproGroupValue(towerId: string, data) {
    return this.post<EvalproGroupDto[]>(
      ContractsEvalproServiceDefinition.createContractEvalproGroupValue(towerId),
      data,
    );
  }
  createContractEvalproDetailValue(towerId: string, data) {
    return this.post<EvalproDetailDto[]>(
      ContractsEvalproServiceDefinition.createContractEvalproDetailValue(towerId),
      data,
    );
  }
}
