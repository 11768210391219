import React, { ChangeEvent, FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core';
import CustomTypography from '../../../../components/Typography/CustomTypography';
import SelectWithTitleForClient from '../../../AddClientModal/UI/Views/input/SelectWithTitleForClient';
import ClientAddressType from '../../Core/models/ClientAddressType';
import AddressContainerForClientWithType from '../customViews/AddressContainerForClientWithType';
import { ClientAddressModel } from 'App/Clients/Core/models/ClientAddressModel';
import buttonStyles from 'App/Clients/UI/ClientButtons/ClientButtons.module.scss';

interface Props {
  isOpen: boolean;
  addressDescription: string;
  currentType: ClientAddressType;
  clientAddress: ClientAddressModel;
  onClose: () => void;
  onAccept: () => void;
  onChangeType: (event: ChangeEvent<HTMLInputElement>) => void;
  update: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ClientAddressModal: FC<Props> = ({
  isOpen,
  addressDescription,
  currentType,
  clientAddress,
  onChangeType,
  onClose,
  update,
  onAccept,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth title='Dirección'>
      <Box p={2}>

        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant='h5'>
              Dirección
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form id="clientAddressModalForm" onSubmit={(e) => { e.preventDefault(); onAccept() }}>
            <Box display="flex" justifyContent="center" marginY={2}>
              <Grid container item md={8} spacing={2}>
                <Grid item md={currentType === ClientAddressType.OPEN.code ? 12 : 6}>
                  <SelectWithTitleForClient
                    title="Tipo de selección"
                    classObjectForOptions={ClientAddressType}
                    defaultValue={currentType}
                    value={currentType}
                    required
                    onChange={onChangeType}
                  />
                </Grid>
                <AddressContainerForClientWithType
                  currentType={currentType}
                  update={update}
                  clientAddress={clientAddress}
                  addressDescription={addressDescription}
                />
              </Grid>
            </Box>
          </form>
          <Box p={2}>
            <CustomTypography variant="subtitle" color="black">
              Dirección ingresada
            </CustomTypography>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Grid container item md={8}>
                <Grid item md={12}>
                  <CustomTypography color="black">
                    {addressDescription}
                  </CustomTypography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" className={buttonStyles.button}>
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            form="clientAddressModalForm"
            className={buttonStyles.buttonEnd}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ClientAddressModal;
