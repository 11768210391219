import React, { FC } from 'react';
import DeedExpensesCard from '../../components/DeedExpensesCard/DeedExpensesCard';
import DeedExpensesDisplacementDate from '../../components/DeedExpensesDisplacementDate/DeedExpensesDisplacementDate';
import useEvalproDeedExpensesConstructionEnd from 'App/DeedExpenses/core/customHooks/useEvalproDeedExpensesConstructionEnd';

interface Props {
  endOfSalesDate: number;
  averageDeliveryDisplacement: number;
  towerId: string;
}

const EvalproDeedExpensesConstructionEnd: FC<Props> = ({ endOfSalesDate, averageDeliveryDisplacement, towerId }) => {
  const { onChange, isLoading } = useEvalproDeedExpensesConstructionEnd(towerId, endOfSalesDate);
  return (
    <DeedExpensesCard maxWidth="520px" marginTop="24px">
      <DeedExpensesDisplacementDate
        title="Fin de construcción"
        displacementLabel="Desplazamiento"
        displacementLabelInfo="Fecha promedio de entrega"
        displacementDateLabel={`Fecha promedio de escrituración`}
        firstDate={endOfSalesDate}
        displacement={averageDeliveryDisplacement}
        isDisabled={isLoading}
        onChange={onChange}
      />
    </DeedExpensesCard>
  );
};

export default EvalproDeedExpensesConstructionEnd;
