import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useClientHubMapper from '../../../RealPaymentsForProperty/Core/customHooks/useClientHubMapper';

function useBuyerCustomerPaymentPlanState(isPaymentPlan = false) {
  const { isAssigned, ...extra } = useClientHubMapper();
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (open) => () => {
    if (!isAssigned && !isPaymentPlan) {
      enqueueSnackbar('Primero debe asignar una forma de pago', {
        variant: 'warning',
      });
    } else {
      setOpen(open);
    }
  };

  return { isOpen, onClick };
}

export default useBuyerCustomerPaymentPlanState;
