import React, { FC } from 'react';
import { Collapse } from '@material-ui/core';
import useBoolean from 'shared/customHooks/useBoolean';
import QuotationConfigCollapseContent from '../QuotationConfigCollapseContent/QuotationConfigCollapseContent';
import QuotationConfigCollapseHeader from '../QuotationConfigCollapseHeader/QuotationConfigCollapseHeader';
import QuotationConfigCollapseFooter from '../QuotationConfigCollapseFooter/QuotationConfigCollapseFooter';

interface Props {
  name: string;
  state?: 'success' | 'warning' | 'error';
  isDefaultOpen?: boolean;
  label?: string;
}

const QuotationConfigCollapse: FC<Props> = ({ name, state, label, isDefaultOpen, children }) => {
  const { value: isOpen, toggle: toggleIsOpenCollapse } = useBoolean(isDefaultOpen);
  return (
    <div>
      <QuotationConfigCollapseHeader
        name={name}
        toggleIsOpenCollapse={toggleIsOpenCollapse}
        isOpen={isOpen}
        state={state}
        label={label}
      />
      <Collapse in={isOpen}>
        <QuotationConfigCollapseContent>{children}</QuotationConfigCollapseContent>
        <QuotationConfigCollapseFooter toggleIsOpenCollapse={toggleIsOpenCollapse} />
      </Collapse>
    </div>
  );
};

export default QuotationConfigCollapse;
