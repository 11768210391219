import React from 'react';
import { useSelector } from 'react-redux';

export default function Display(vertical = false) {
  const { labels, verticalHeaders } = useSelector(
    (state) => state.buildingHeader,
  );
  const arrayToUse = vertical ? verticalHeaders : labels;
  return arrayToUse.map((label) => (
    <p key={`label-${label}-building`}>{label}</p>
  ));
}
