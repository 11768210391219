import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';

import Agent from 'config/config';
import Icon from 'shared/components/Icon/Icon';
import IconButton from 'shared/components/IconButton/IconButton';
import Menu from 'shared/components/Menu/Menu';
import MenuItem from 'shared/components/MenuItem/MenuItem';
import Typography from 'shared/components/Typography/Typography';
import useStageTowerMenu from '../../../core/customHooks/useStageTowerMenu';
import useTowerClick from '../../../core/customHooks/useTowerClick';
import { StageTowerDTO } from '../../../core/DTO/StageTowersDTO';
import {
  DeleteTowerHandler,
  EditTowerHandler,
  OnGoToTower,
  StagesDragAndDropEvents,
} from '../../../core/types/Stages.types';
import StagesUserRole from '../../../core/utils/StagesUserRole';

import styles from './StagesTowerCard.module.scss';

const getIsOpenClass = (isOpen: boolean) => ({
  [styles.isOpen]: isOpen,
});

const getMenuButton = (isOpen: boolean, openMenu: MouseEventHandler<HTMLButtonElement>) => (
  <IconButton
    className={clsx(styles.moreIcon, getIsOpenClass(isOpen))}
    iconName="MoreVert"
    variant="ghost"
    size="xsmall"
    aria-controls="stages-tower-menu"
    onClick={openMenu}
  />
);

const getIsDropZoneClass = (movedCardId: string, towerId: string) => ({
  [styles.isDropZone]: movedCardId === towerId,
});

type Props = StagesDragAndDropEvents & {
  className?: string;
  stageId: number;
  index: number;
  tower: StageTowerDTO;
  movedCardId: string;
  onGoToTower: OnGoToTower;
  onEditTower: EditTowerHandler;
  onDeleteTower: DeleteTowerHandler;
};

const StagesTowerCard: React.FC<Props> = ({
  className,
  stageId,
  index,
  tower,
  movedCardId,
  onGoToTower,
  onEditTower,
  onDeleteTower,
  ...dndHandlers
}) => {
  const handleTowerClick = useTowerClick(tower, onGoToTower);
  const { anchorEl, openMenu, closeMenu, handleEditClick, handleDeleteClick } = useStageTowerMenu<HTMLButtonElement>({
    stageId,
    tower,
    onEditTower,
    onDeleteTower,
  });
  const isSuperOrAdminUser = StagesUserRole.isSuperOrAdminUser(Agent.currentUser.userType);
  const isDropZoneClass = getIsDropZoneClass(movedCardId, tower.id);
  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <div
        className={clsx(styles.root, isDropZoneClass, className)}
        onClick={handleTowerClick}
        data-card-id={tower.id}
        data-index={index}
        data-stage-id={stageId}
        draggable={isSuperOrAdminUser}
        {...dndHandlers}
      >
        <Icon className={styles.dragIcon} variant="Drag" />
        <Typography className={styles.name} as="h3" variant="subtitle1">
          {tower.name}
        </Typography>
        <Typography className={styles.description} as="p" variant="body2">
          {tower.description}
        </Typography>
        {isSuperOrAdminUser && getMenuButton(isMenuOpen, openMenu)}
      </div>
      <Menu
        id="stages-tower-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeMenu}
        hasArrow={true}
      >
        <MenuItem onClick={handleEditClick}>Editar</MenuItem>
        <MenuItem className={styles.deleteItem} onClick={handleDeleteClick}>
          Eliminar
        </MenuItem>
      </Menu>
    </>
  );
};

export default StagesTowerCard;
