import React, { FC } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Style from './RealPaymentAutocompleteField.module.scss';
import useMapBuyersForRealPayment from '../../Core/customHooks/useMapBuyersForRealPayment';

export interface RealPaymentAutocompleteFieldValue {
  clientId: string;
  propertyId: string;
  title: string;
}

export type updateAutoComplete = (
  event: React.ChangeEvent<{}>,
  newValue: RealPaymentAutocompleteFieldValue | null,
) => void;

interface RealPaymentAutocompleteFieldProp {
  updateAutoComplete: updateAutoComplete;
}

const RealPaymentAutocompleteField: FC<RealPaymentAutocompleteFieldProp> = ({
  updateAutoComplete,
}) => {
  const { propertyRows } = useMapBuyersForRealPayment();
  return (
    <>
      <Autocomplete
        id="autocomplete-box-property"
        options={propertyRows}
        getOptionLabel={(option: RealPaymentAutocompleteFieldValue) =>
          option.title
        }
        className={Style.wholeWidth}
        onChange={updateAutoComplete}
        renderInput={(params) => (
          <TextField
            {...params}
            className={Style.wholeWidth}
            label="Seleccionar cliente"
            variant="outlined"
            InputLabelProps={{
              className: Style.floatingLabelFocusStyle,
            }}
          />
        )}
      />
    </>
  );
};

export default RealPaymentAutocompleteField;
