import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Rows from './Rows';
import { setMaxClientLength } from '../../containers/ClientProperty/actions';
import Styles from './styles.module.scss';

export const TablePairing = ({
  maxClientLength,
  properties,
  onSetMaxClientLength,
}) => {
  const addNewClientToProperty = () => {
    onSetMaxClientLength(maxClientLength + 1);
  };
  return (
    <div>
      <div className={Styles.ContainerSquare}>
        <div className={Styles.YellowHelperSquare} />
        <div className={Styles.Label}>Opcionado</div>
        <div className={Styles.BlueHelperSquare} />
        <div className={Styles.Label}>Vendido</div>
      </div>
      <div className={Styles.container}>
        <div className={Styles.tableContainer}>
          <Table
            stickyHeader
            classes={{
              root: Styles.tableRoot,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Estado de apartamento</TableCell>
                <TableCell>Nomenclatura</TableCell>
                <TableCell classes={{ root: Styles.status }} align="center">
                  Estado
                </TableCell>
                {Array(maxClientLength)
                  .fill(null)
                  .map((_, headerIndex) => {
                    return (
                      <TableCell
                        key={`header-${headerIndex}`}
                        classes={{ root: Styles.client }}
                        align="center"
                      >
                        Cliente {headerIndex + 1}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((property) => {
                return <Rows key={property.id} property={property} />;
              })}
            </TableBody>
          </Table>
        </div>
        <div className={Styles.actionContainer}>
          <Button
            className={Styles.button}
            variant="contained"
            color="primary"
            onClick={addNewClientToProperty}
          >
            Agregar cliente
          </Button>
        </div>
      </div>
    </div>
  );
};

TablePairing.propTypes = {
  maxClientLength: PropTypes.number,
  properties: PropTypes.array,
  onSetMaxClientLength: PropTypes.func,
};

const mapStateToProps = (state) => ({
  maxClientLength: state.clientProperty.root.maxClientLength,
  properties: state.clientProperty.root.properties,
});

const mapDispatchToProps = {
  onSetMaxClientLength: setMaxClientLength,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePairing);
