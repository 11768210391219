import React from 'react';
import classes from './CellContentForVirtualTableCell.module.scss';
import ReactTooltip from 'react-tooltip';
import { Typography } from '@material-ui/core';
import Numbers from '../../../../../../helpers/numbers';
import clsx from 'clsx';

interface CellContentForVirtualTableCellProps {
  row: {
    name: string;
    id: string;
    parentId: string;
  };
  name: string;
  value: number;
  divider: number;
  cellData: number;
  decimals: number;
  typographyStyle: string;
}

const CellContentForVirtualTableCell: React.FC<CellContentForVirtualTableCellProps> = ({
  typographyStyle,
  cellData,
  decimals,
  row,
  name,
  value,
  divider,
}) => {
  const incomeRow =
    row.id === 'financialPerformance' ||
    row.id === 'UTILITY-BEFORE-TAX' ||
    row.id === 'TOTAL_SALES' ||
    row.parentId === 'TOTAL_SALES';

  return (
    <span>
      <Typography
        data-tip
        data-for={`typographyToolTip ${row.name} ${name}`}
        className={clsx({
          [classes.marginCell]: typographyStyle,
          [classes.utilityTypography]: typographyStyle === 'utilityTypography',
          [classes.withoutQuote]: typographyStyle === 'withoutQuote',
          [classes.groupTypography]: typographyStyle === 'groupTypography',
          [classes.itemsTypography]: typographyStyle === 'itemsTypography',
          [classes.contractsTypography]: typographyStyle === 'contractsTypography',
          [classes.expensesProfits]: name === 'variation' && value < 0,
          [classes.expensesLosses]: name === 'variation' && value > 0,
          [classes.incomeProfits]: name === 'variation' && value > 0 && incomeRow,
          [classes.incomeLosses]: name === 'variation' && value < 0 && incomeRow,
        })}
      >
        {Numbers.currencyFormat(cellData / divider, true, decimals)}
      </Typography>

      <ReactTooltip
        type="ligth"
        id={`typographyToolTip ${row.name} ${name}`}
        effect="solid"
        className={classes.tooltip}
      >
        <Typography className={classes.text}>{`Valor en pesos:`}</Typography>

        <Typography className={classes.values}>
          {`${name === 'name' ? value : Numbers.currencyFormat(value)}`}
        </Typography>
      </ReactTooltip>
    </span>
  );
};

export default CellContentForVirtualTableCell;
