import React, { Dispatch, FC, SetStateAction } from 'react';
import { ListItemText, Divider, List, ListItem } from '@material-ui/core';

import Typography from '../../../../../shared/components/Typography/Typography';
import Styles from '../../styles/ContractPaymentListGrid.module.scss';
import ContractPaymentType from '../../../Core/types/contractPaymentType';

const ContractPaymentMenuList: FC<{
  setOpenContractPaymentForm: Dispatch<SetStateAction<boolean>>;
  setContractPaymentType: Dispatch<SetStateAction<string>>;
  currentContractIsLot: boolean;
}> = ({ setOpenContractPaymentForm, setContractPaymentType, currentContractIsLot }) => {
  return (
    <List
      classes={{
        root: Styles.buttonMenu,
      }}
    >
      <ListItem button={false} key="contract-payment-schedule-menu-item" onClick={() => {}}>
        <ListItemText
          primary={
            <Typography variant="h6" color="gray800" fontWeight="600">
              El contrato no tiene pagos asignados, para empezar selecciona el tipo de pago a ingresar
            </Typography>
          }
        />
      </ListItem>
      <Divider />
      {ContractPaymentType.map(
        (type) =>
          (type.isOnlyForLots === currentContractIsLot || type.isOnlyForLots === false) && (
            <ListItem
              button
              classes={{
                root: Styles.menuItem,
                selected: Styles.menuItemSelected,
              }}
              key={`new-payment-menu-item-${type.id}`}
              onClick={() => {
                setOpenContractPaymentForm(true);
                setContractPaymentType(type.id);
              }}
              divider
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="button" fontWeight="600">
                      {type.name}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption"></Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ),
      )}
    </List>
  );
};

export default ContractPaymentMenuList;
