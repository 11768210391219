import React, { FC } from 'react';
import styled from 'styled-components';
import Check from '@material-ui/icons/Check';
import { StepIconProps } from '@material-ui/core';

const ModalStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: 'lightgray',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#00A3A9',
      fontWeight: 'bold',
    }),
    '& .AddModalStepIconComponentIcon-completedIcon': {
      color: '#00A3A9',
      zIndex: 1,
      fontSize: 24,
      fontWeight: 'bold',
    },
    '& .AddModalStepIconComponentIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const ModalStepIconComponent: FC<StepIconProps> = (props) => {
  const { active, completed, className } = props;

  return (
    <ModalStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="AddModalStepIconComponentIcon-completedIcon" />
      ) : (
        <div className="AddModalStepIconComponentIcon-circle" />
      )}
    </ModalStepIconRoot>
  );
};

export default ModalStepIconComponent;
