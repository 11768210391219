import React, { useState, Fragment } from 'react';
import sideMenuStyle from '../SideMenu.module.scss';
import menuStyle from '../menu.module.scss';
import INTEGRATIONS from '../../../config/integrations';

const Header = ({ title, validation }) => {
  const ENV = process.env.NODE_ENV;
  const style = INTEGRATIONS[ENV].uniqueSideBar ? menuStyle : sideMenuStyle;
  const [showTitle, setShowTitle] = useState(validation);

  return (
    <Fragment>
      {showTitle && <div className={style.header}>{title}</div>}
    </Fragment>
  );
};

export default Header;
