import { useState } from 'react';

function useFeatureFieldAdminStatus() {
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const handleOpenAdmin = () => {
    setIsAdminOpen(true);
  };

  const handleCloseAdmin = () => {
    setIsAdminOpen(false);
  };

  return { isAdminOpen, handleOpenAdmin, handleCloseAdmin };
}

export default useFeatureFieldAdminStatus;
