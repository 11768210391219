import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import LikemetricError from '../../../../shared/types/LikemetricError';
import ProjectResultsStateServices from '../../Services/ProjectResultsStateServices';

type StagesParams = {
  projectId: string;
};

function useProjectResultsStateToFetchRows(onChangeProjectResultsState) {
  const services = new ProjectResultsStateServices();
  const { projectId } = useParams<StagesParams>();
  const [projectResultState, setProjectResultState] = useState();
  const [isLoading, setLoad] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const getProjectResultsState = async () => {
    try {
      const { data } = await services.getProjectResultsState(projectId);
      setProjectResultState(data);
      onChangeProjectResultsState(data)
    } catch (e) {
      const error = e as LikemetricError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!projectResultState) getProjectResultsState();
    setLoad(true);
  }, []);

  useEffect(() => {
    if (projectResultState) {
      setLoad(false);
      onChangeProjectResultsState(projectResultState);
    }
  }, [projectResultState]);

  return {
    projectResultState,
    isLoading,
  };
}

export default useProjectResultsStateToFetchRows;
