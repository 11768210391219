import moment from 'moment';
import QuotationDiscountType from '../../../../../Quotation/Core/enums/QuotationDiscountType';

function useEnableQuotationDiscount(
  quotation,
  dispatchFetchQuotationSuccess,
  handleEditionDiscountType,
) {
  const isAutomaticDiscountType =
    quotation.discountType === QuotationDiscountType.ASSIGNED.code;

  const isThereAvailablePeriods = quotation.distribution.length > 0;

  const enableDiscountCalculation =
    (isAutomaticDiscountType || !quotation.discountType) &&
    quotation.isEditingReal &&
    isThereAvailablePeriods;

  const onChangeType = (event) => {
    const discountType = event.target.checked
      ? QuotationDiscountType.MANUAL.code
      : QuotationDiscountType.ASSIGNED.code;
    handleEditionDiscountType(discountType);
  };

  const onChangeManualQuotationDiscount = (values) => {
    dispatchFetchQuotationSuccess({
      ...quotation,
      discountPrice: values.floatValue,
      priceWithDiscount:
        quotation.priceWithAdditionalAreas -
        quotation.discount -
        values.floatValue,
    });
  };

  return {
    enableDiscountCalculation,
    onChangeType,
    onChangeManualQuotationDiscount,
  };
}

export default useEnableQuotationDiscount;
