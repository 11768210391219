import React from 'react'
import { Box } from '@material-ui/core';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import CellContentForResultsStateTable from '../CellContentForVirtualTableCell/CellContentForResultsStateTable';
import classes from '../../../styles/ResultsStateCustomCell.module.scss'

const Contracts = ({cellData, divider, decimals, row, name}) => {
  return (
    <VirtualTable.Cell className={classes.virtualTableCell}>
        <Box className={classes.itemsFloor}>
          <CellContentForResultsStateTable 
          typographyStyle={"itemsTypography"}
          cellData={cellData}
          decimals={decimals}
          row={row}
          name={name}
          value={cellData}
          divider={divider}
          />
        </Box>
      </VirtualTable.Cell>
  )
}

Contracts.propTypes = {
  cellData: PropTypes.number,
  divider: PropTypes.number,
  decimals: PropTypes.number,
  row: PropTypes.object,
  name: PropTypes.string,
};

export default Contracts
