import { ModalProps, Popover } from '@material-ui/core';
import React from 'react';

import Icon from 'shared/components/Icon/Icon';
import Typography from 'shared/components/Typography/Typography';
import { PhasesDetail } from '../../../core/DTO/SalesSpeedHelperDTO';
import SalesSpeedHelperPhasesDetailCard from '../SalesSpeedHelperPhasesDetailCard/SalesSpeedHelperPhasesDetailCard';

import styles from './SalesSpeedHelperPhasesDetail.module.scss';

const hasStarted = (currentTime: number, startTime: number): boolean => currentTime >= startTime;

const errorMessage = (
  <div className={styles.errorMessage}>
    <Icon className={styles.errorIcon} variant="ErrorOutline" color="informative600" />
    <Typography variant="body1" color="gray800">
      La fecha actual es inferior a la fecha inicial de preventas.
    </Typography>
  </div>
);

type Props = {
  anchorEl: HTMLElement | null;
  phasesDetail: PhasesDetail;
  onClose: ModalProps['onClose'];
};

const SalesSpeedHelperPhasesDetail: React.FC<Props> = ({ anchorEl, phasesDetail, onClose }) => {
  const showErrorMessage = !hasStarted(phasesDetail.currentTime, phasesDetail.preSales.total.fromTime);

  return (
    <Popover
      className={styles.root}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <div className={styles.arrow}></div>
      {showErrorMessage && errorMessage}
      <div className={styles.cards}>
        <SalesSpeedHelperPhasesDetailCard
          faseName="Preventas"
          currentTime={phasesDetail.currentTime}
          phaseDates={phasesDetail.preSales}
          isFaseCard
        />
        <SalesSpeedHelperPhasesDetailCard
          faseName="Ventas"
          currentTime={phasesDetail.currentTime}
          phaseDates={phasesDetail.sales}
          isFaseCard
        />
        <SalesSpeedHelperPhasesDetailCard
          faseName="Total"
          currentTime={phasesDetail.currentTime}
          phaseDates={phasesDetail.total}
        />
      </div>
    </Popover>
  );
};

export default SalesSpeedHelperPhasesDetail;
