import React from 'react';
import {
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Box,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Styles from './ExcelSalesRoomPopover.module.scss';
import usePopoverUpdaterForExcelInSalesRoom from '../Core/customHooks/usePopoverUpdaterForExcelInSalesRoom';
import useSalesRoomExcelDownloader from '../Core/customHooks/useSalesRoomExcelDownloader';

const ExcelSalesRoomPopover = () => {
  const {
    handleClick,
    isOpen,
    anchorElement,
  } = usePopoverUpdaterForExcelInSalesRoom();

  const {
    isLoading,
    downloadPropertyList,
    downloadAllLists,
    downloadSoldApartments,
    downloadAvailableApartments,
  } = useSalesRoomExcelDownloader();

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorElement}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <Box display="flex" justifyContent="center">
            {isLoading && <CircularProgress />}
          </Box>
          <ListItem disablePadding>
            <Button
              disabled={isLoading}
              className={Styles.buttonsText}
              onClick={downloadAllLists}
            >
              <ListItemText primary="Descargar todas las listas" />
            </Button>
          </ListItem>
          <ListItem disablePadding>
            <Button
              disabled={isLoading}
              className={Styles.buttonsText}
              onClick={downloadPropertyList}
            >
              <ListItemText primary="Descargar lista actual" />
            </Button>
          </ListItem>
          <ListItem disablePadding>
            <Button
              disabled={isLoading}
              className={Styles.buttonsText}
              onClick={downloadSoldApartments}
            >
              <ListItemText primary="Descargar apartamentos vendidos" />
            </Button>
          </ListItem>
          <ListItem disablePadding>
            <Button
              disabled={isLoading}
              className={Styles.buttonsText}
              onClick={downloadAvailableApartments}
            >
              <ListItemText primary="Descargar apartamentos disponibles" />
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default ExcelSalesRoomPopover;
