import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import TowerBuilderDetail from './TowerBuilderDetail';
import {
  setTableWatcher,
  setCurrentTower,
  setOpen,
} from '../../containers/TowerBuilder/action';
import styles from './TowerBuilder.module.scss';
import { Numbers } from '../../helpers';
import {
  setBuilderRows,
  setDateCost,
  setIpc,
  setContructionType,
  setDatesBuild,
  setManualDates,
  setManualDatesValues,
  setRowData,
} from './actions';
import moment from 'moment';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box } from '@material-ui/core';

const ConstructionFlow = ({
  isTableVisible,
  onSetTableWatcher,
  onSetOpen,
  towers,
  onSetCurrentTower,
  onSetBuilderRows,
  onSetDateCost,
  onSetIpc,
  onSetConstructionType,
  onSetDateBuilder,
  onSetManualDates,
  onSetRowData,
}) => {
  const datesValues = (builders) => {
    return builders.reduce((prev, current) => {
      if (current.pricesArray && current.pricesArray.length >= 1) {
        const row = {
          id: current.id,
          name: current.name,
          ...current.pricesArray,
          ...current.totals,
        };
        return [...prev, row];
      }
      return [...prev];
    }, []);
  };

  const numberFormater = (number) => {
    return number === 0 ? (
      <NumberFormat
        value={Numbers.toFixed(number, 0)}
        thousandSeparator
        displayType={'text'}
        isNumericString
        decimalSeparator={false}
        prefix="$"
        className={styles.zerosAtTable}
      />
    ) : (
      <NumberFormat
        value={Numbers.toFixed(number, 0)}
        thousandSeparator
        displayType={'text'}
        isNumericString
        decimalSeparator={false}
        prefix="$"
      />
    );
  };

  const currentRowSelected = (row) => {
    onSetCurrentTower(row);
    if (row.towerBuilder) {
      const { towerBuilder, arrayOfDates } = row;
      const { builders } = towerBuilder;
      delete towerBuilder.builders;
      onSetDateCost(
        Number(
          towerBuilder
            ? towerBuilder.dateCost
            : row.schedule.constructionStartDate,
        ),
      );
      onSetIpc(towerBuilder.ipc);
      onSetConstructionType(towerBuilder.type);
      onSetDateBuilder({
        ...towerBuilder,
        dateCost: Number(
          towerBuilder
            ? towerBuilder.dateCost
            : row.schedule.constructionStartDate,
        ),
      });
      onSetBuilderRows(builders);
      const dates = datesValues(builders);
      onSetManualDates(arrayOfDates);
      onSetRowData(dates);
    }
    const { arrayOfDates } = row;
    onSetDateCost(
      Number(
        row.towerBuilder
          ? row.towerBuilder.dateCost
          : row.schedule.constructionStartDate,
      ),
    );
    onSetManualDates(arrayOfDates);
    onSetOpen(true);
  };

  const [columns, setColumns] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const [tableColumnExtensions] = useState([
    { columnName: 'name', width: 300 },
    { columnName: 'accumulated', width: 150 },
    { columnName: 'projected', width: 150 },
    { columnName: 'total', width: 150 },
  ]);

  const [data, setData] = useState([]);

  const formatFields = (arrayOfRows) => {
    return Object.keys(arrayOfRows).reduce((prev, row) => {
      const valueFormatted = numberFormater(Number(arrayOfRows[row] || 0));
      const formatThis = { [row]: valueFormatted };
      return { ...prev, ...formatThis };
    }, {});
  };

  const formatFieldsAutomatic = (arrayOfRows, arrayOfDates) => {
    const col = { ...towers[0] }.generalColumns;
    const keysOfDates = Object.keys(arrayOfRows);
    const dateBaseIndex = col.findIndex((column) => {
      return column.title === arrayOfDates[0];
    });
    let dateBase = Number(col[dateBaseIndex].name.slice(4));
    return keysOfDates.reduce((prev, row) => {
      const valueFormatted = numberFormater(Number(arrayOfRows[row] || 0));
      const formatThis = { [`date${dateBase}`]: valueFormatted };
      dateBase += 1;
      return { ...prev, ...formatThis };
    }, {});
  };

  const usingData = () => {
    const unsortedTowers = towers.splice(1).flatMap((row) => {
      if (row.towerBuilder && row.towerBuilder.type === 'M') {
        const rowTotals = [];
        let projected = 0;
        let accumulated = 0;
        let total = 0;
        const preRowWithManual = row.towerBuilder.builders
          .filter(
            (internalRow) =>
              internalRow.pricesArray && internalRow.pricesArray.length >= 1,
          )
          .flatMap((props) => props);
        const rowWithManualArrays = preRowWithManual || { pricesArray: [] };
        rowWithManualArrays.forEach((current) => {
          const rowWithManual = current;
          projected += rowWithManual.totals
            ? rowWithManual.totals.projected
            : 0;
          accumulated += rowWithManual.totals
            ? rowWithManual.totals.accumulated
            : 0;
          total += rowWithManual.totals ? rowWithManual.totals.total : 0;
          rowWithManual.pricesArray.forEach((innerData, idx) => {
            const added = (rowTotals[idx] || 0) + Number(innerData || 0);
            rowTotals[idx] = added;
          });
        });
        const manualRows = row.arrayOfDates.reduce((prev, date, index) => {
          rowTotals[index] += Number(0);
          if (index === 0) {
            return {
              ...prev,
              [`date${index}`]: rowTotals[index].toFixed(0) || 0,
            };
          }
          return {
            ...prev,
            [`date${index}`]: rowTotals[index].toFixed(0) || 0,
          };
        }, {});
        const manualRowsFormatted = formatFields({
          projected,
          accumulated,
          total,
          ...manualRows,
        });
        return { ...row, ...manualRowsFormatted };
      }
      const formattedFields = formatFieldsAutomatic(
        row.constructionFlow
          ? row.constructionFlow.constructionWithCurve[0]
          : {},
        row.arrayOfDates,
      );
      return {
        ...row,
        ...formattedFields,
      };
    });
    return _.orderBy(unsortedTowers, (e) => e.name);
  };

  const generateEmpty = (rows, newColumns) => {
    const arrWithZeros = rows.reduce((prev, current) => {
      const unknownDates = newColumns.filter((columnDate) => {
        const res = current.arrayOfDates.find(
          (existingDate) => existingDate === columnDate.title,
        );
        return res === undefined || res === null;
      });
      const zeroDates = unknownDates
        .splice(4, unknownDates.length - 1)
        .flatMap((number) => ({ [number.name]: numberFormater(0) }));
      const objectDates = zeroDates.reduce(
        (prevT, currentT) => ({ ...prevT, ...currentT }),
        {},
      );
      return [...prev, { ...current, ...objectDates }];
    }, []);
    return arrWithZeros.reduce((prev, current) => {
      if (!current.accumulated && !current.projected && !current.total) {
        const { projected, accumulated, total } = current.constructionFlow
          ? current.constructionFlow &&
            current.constructionFlow.constructionWithCurve[0]
          : { projected: 0, accumulated: 0, total: 0 };
        return [
          ...prev,
          {
            ...current,
            projected: numberFormater(projected),
            accumulated: numberFormater(accumulated),
            total: numberFormater(total),
          },
        ];
      }
      return [...prev, { ...current }];
    }, []);
  };

  useEffect(() => {
    const newColumns = { ...towers[0] }.generalColumns;
    setColumns(newColumns);
    const rows = usingData();
    const completeRows = generateEmpty(rows, newColumns);

    const arrayAlerts = completeRows.reduce((current, row) => {
      const buildDateDifference = row.schedule
        ? moment(Number(row.schedule.endOfSalesDate))
            .add(1, 'M')
            .startOf('month')
            .diff(
              moment(Number(row.schedule.constructionStartDate)).startOf(
                'month',
              ),
              'months',
            )
        : 0;
      if (buildDateDifference < 12) {
        return [...current, row.name];
      }
      return current;
    }, []);

    const groups = arrayAlerts.join(', ');


    const alert = arrayAlerts.length > 0 ? [<Box m={2}>
    <Alert severity="Warning">
      <AlertTitle>Cuidado</AlertTitle>
      El número de meses debe ser minimo de 12 para poder reajustar en {groups}
    </Alert>
    ,
  </Box>] : [];

    setAlerts(alert);
    setData(completeRows);
  }, []);

  const [leftColumns] = useState(['name', 'accumulated', 'projected', 'total']);

  const tableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => currentRowSelected(row)}
      style={{
        cursor: 'pointer',
      }}
    />
  );

  return (
    <>
      <Paper className={styles.container}>
        <Typography variant="h4" gutterBottom>
          Flujo de Construcción
        </Typography>
        <Paper>
          {alerts}
          <Grid rows={data} columns={columns}>
            <SortingState
              defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
            />
            <IntegratedSorting />
            <Table
              columnExtensions={tableColumnExtensions}
              rowComponent={tableRow}
            />
            <TableHeaderRow />
            <TableFixedColumns leftColumns={leftColumns} />
          </Grid>
        </Paper>
      </Paper>
      <TowerBuilderDetail />
    </>
  );
};

ConstructionFlow.propTypes = {
  isTableVisible: PropTypes.bool.isRequired,
  onSetTableWatcher: PropTypes.func.isRequired,
  onSetCurrentTower: PropTypes.func.isRequired,
  towers: PropTypes.array.isRequired,
  onSetBuilderRows: PropTypes.func.isRequired,
  onSetDateCost: PropTypes.func.isRequired,
  onSetIpc: PropTypes.func.isRequired,
  onSetConstructionType: PropTypes.func.isRequired,
  onSetDateBuilder: PropTypes.func.isRequired,
  onSetOpen: PropTypes.func.isRequired,
  onSetManualDates: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isTableVisible: state.towerBuilder.root.isTableVisible,
  towers: state.towerBuilder.root.towers,
});

const mapDispatchToProps = {
  onSetOpen: setOpen,
  onSetTableWatcher: setTableWatcher,
  onSetCurrentTower: setCurrentTower,
  onSetBuilderRows: setBuilderRows,
  onSetDateCost: setDateCost,
  onSetIpc: setIpc,
  onSetConstructionType: setContructionType,
  onSetDateBuilder: setDatesBuild,
  onSetManualDates: setManualDates,
  onSetRowData: setRowData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionFlow);
