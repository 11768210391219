import { useState } from 'react';
import {
  RealPaymentAutocompleteFieldValue,
  updateAutoComplete,
} from '../../../RealPayment/UI/customViews/RealPaymentAutocompleteField';

function useCurrentPropertyForRealPayments() {
  const [propertySelected, setPropertySelected] = useState<
    Partial<RealPaymentAutocompleteFieldValue>
  >({});

  const onUpdateAutoComplete: updateAutoComplete = (event, newValue) => {
    setPropertySelected(newValue || {});
  };

  return {
    propertySelected,
    onUpdateAutoComplete,
  };
}

export default useCurrentPropertyForRealPayments;
