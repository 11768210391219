import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import Services from '../../Services/ProjectionTypesServices';
import { setProjections } from '../actions';

const services = new Services();

export default function (name, switchState) {
  // @ts-ignore
  const { towerId } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [projectionState, setProjectionState] = useState(switchState);

  const changeProjectionTypes = async (projection, value) => {
    try {
      if (towerId) {
        await services.setProjections({ [projection]: value }, towerId);
        dispatch(setProjections({ [projection]: value }));
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onChange = (value) => {
    setProjectionState(value);
    changeProjectionTypes(name, value);
  };

  return { onChange, projectionState };
}
