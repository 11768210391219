import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import StateChip from '../../../../../shared/components/StateChip/StateChip';

const ContractPaymentScheduleGridStateFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  return (
    <div style={{ textAlign: 'center' }}>{!!value ? <StateChip label={value.label} color={value.color} /> : null}</div>
  );
};

export default ContractPaymentScheduleGridStateFormatter;
