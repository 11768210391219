import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Row from './Row';
import Styles from './styles.module.scss';

export const SaleDateSelectorTable = ({ properties }) => {
  return (
    <div>
      <div className={Styles.ContainerSquare}>
        <div className={Styles.YellowHelperSquare} />
        <div className={Styles.Label}>Opcionado</div>
        <div className={Styles.BlueHelperSquare} />
        <div className={Styles.Label}>Vendido</div>
      </div>
      <div className={Styles.container}>
        <div className={Styles.tableContainer}>
          <Table
            stickyHeader
            classes={{
              root: Styles.tableRoot,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Estado de apartamento</TableCell>
                <TableCell>Nomenclatura</TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center" id="action"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((property) => {
                const positionIndex =
                  property.length > 1 ? property.length - 1 : 0;
                const historical = property.length
                  ? property[positionIndex]
                  : property;
                return <Row key={property.id} historical={historical} />;
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

SaleDateSelectorTable.propTypes = {
  properties: PropTypes.func,
};

const mapStateToProps = (state) => ({
  properties: state.saleDateSelector.root.properties,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaleDateSelectorTable);
