import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import TableCellTypographyProps from '../../Core/interfaces/TableCellTypographyProps';

const TableCellPercentTypography: FC<TableCellTypographyProps> = ({ value }) => {
  return (
    <NumberFormat displayType="text" value={value} thousandSeparator decimalScale={2} fixedDecimalScale suffix="%" />
  );
};

export default TableCellPercentTypography;
